import PropTypes from 'prop-types';

export default function Column() {
  return null;
}

Column.displayName = 'Column';

Column.propTypes = {
  name: PropTypes.string,
  dataKey: PropTypes.string,
  align: PropTypes.oneOf(['left', 'center', 'right']),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  th: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  td: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  thClassName: PropTypes.string,
  tdClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  iconClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

Column.defaultProps = {
  align: 'left',
  dataKey: null,
};
