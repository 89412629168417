import styled from 'styled-components';
import { CustomScrollbarView } from '../components';

export const PanelContainer = styled.div`
  padding: 36px 24px 30px;
  background: #ffffff;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  > div:first-child {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    align-items: center;
    color: #1d1e2b;
  }
`;

export const SchoolSelectionIconContainer = styled.div`
  width: 60px;
  height: 60px;
  background: #f0f6ff;
  border-radius: 999px;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -30px;
  margin-left: -30px;
  align-items: center;
  justify-content: center;
  img {
    width: 35px;
    height: 35px;
  }
`;
const PanelScrollableView = styled(CustomScrollbarView)`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin: 0 -10px;
  padding: 5px 10px 20px;

  & > div {
    transition: box-shadow 0.4s;
    &:hover {
      box-shadow: 3px 4px 20px rgba(0, 0, 0, 0.1);
    }
  }
`;

export const SchoolSelectionItemsContainer = styled(PanelScrollableView)`
  overflow-y: auto;
  gap: 8px;
  & > div {
    padding: 8px 12px;
    border-radius: 8px;
  }
`;
export const AlertsContainer = styled(SchoolSelectionItemsContainer)`
  & > div:nth-child(2n + 1) {
    background: #f4f5ff;
  }
`;

export const MeetingProgressBarContainer = styled(PanelScrollableView)`
  gap: 16px;
  margin-top: 24px;
  & > div {
    &:hover {
      box-shadow: none;
    }
  }
`;
