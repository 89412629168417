import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Dotdotdot from 'react-dotdotdot';
import { formatTimestamp } from 'utils/utils';
import { returnNotificationIcon } from 'utils/notificationConstructor';

import css from './styles.scss';
import { handleEnter } from '../../../../utils/accessibility';

export default function NotificationItem(props) {
  const {
    notificationTime,
    notificationDesc,
    notificationType,
    onNotificationItemClick,
    notificationRead,
    isNotificationPage,
  } = props;
  return (
    <div
      role="button"
      tabIndex={0}
      data-ga-label="Notification Item"
      className={classNames(css.notification, {
        [css.seen]: notificationRead,
        [css.notificationPage]: !!isNotificationPage,
      })}
      onClick={onNotificationItemClick}
      onKeyDown={handleEnter(onNotificationItemClick)}
      data-test-id={`notificationType${notificationType}`}
    >
      <div className={classNames(css.notificationIcon, { [css.iconForPage]: isNotificationPage })}>
        <i className={`zmdi ${returnNotificationIcon(notificationType)}`} />
      </div>
      <div className={css.notificationDetails}>
        <div className={classNames(css.notificationText)} title={notificationDesc}>
          <Dotdotdot clamp={2}>{notificationDesc}</Dotdotdot>
        </div>
        <div className={css.timestamp}>{formatTimestamp(notificationTime)}</div>
      </div>
    </div>
  );
}

NotificationItem.propTypes = {
  isNotificationPage: PropTypes.bool,
  notificationTime: PropTypes.number.isRequired,
  notificationDesc: PropTypes.string.isRequired,
  notificationType: PropTypes.string.isRequired,
  notificationRead: PropTypes.bool.isRequired,
  onNotificationItemClick: PropTypes.func.isRequired,
};

NotificationItem.displayName = 'NotificationItem';
