import React from 'react';
import PropTypes from 'prop-types';
import SubItem from 'components/unique/ProfilePage/SubItem';
import css from 'components/unique/ProfilePage/profileItem.scss';

export default function NotificationView(props) {
  const { subscribedEmail } = props;

  return (
    <div className={css.itemBody}>
      <SubItem
        name="Email Notifications"
        item={subscribedEmail ? 'You will be emailed notifications' : 'You will not be emailed notifications'}
      />
    </div>
  );
}

NotificationView.displayName = 'NotificationView';

NotificationView.propTypes = {
  subscribedEmail: PropTypes.bool,
};
