import React from 'react';
import styled, { keyframes } from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';

// styled component
export const NotificationSwitchContainer = styled.div``;

export const NotificationSwitchContainerHeader = styled.div`
  font-family: Montserrat;
  font-size: 1.3rem;
  font-weight: bold;
  color: #1d1e2a;
`;

export const NotificationSwitchContainerDescription = styled.div`
  min-height: 38px;
  font-family: Montserrat;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.43;
  color: #a9acc0;
  margin-top: 15px;
`;

export const NotificationSwitchSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${(props) => (props.isMobile ? '0px' : '30px 27px 0px 32px')};
  border: ${(props) => (props.isMobile ? '0px' : '1px solid #e4e7ec')};
  border-radius: 8px;
  margin-top: 26px;
  margin-bottom: 26px;
`;

export const NotificationSwitchSectionHeader = styled.div`
  height: 19px;
  font-family: Montserrat;
  font-size: 1.3rem;
  font-weight: bold;
  color: #1d1e2a;
  margin-bottom: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  .header-description {
    height: 18px;
    font-family: Montserrat;
    font-size: 1.2rem;
    font-weight: 500;
    color: #a9acbe;
    margin-left: 10px;
  }
`;

export const NotificationSwitchSectionItemLine = styled.div`
  display: flex;
  align-items: center;
  margin-top: 3px;
  margin-bottom: 3px;
  font-family: Montserrat;
  font-size: 1.2rem;
  font-weight: 500;
  color: #73747c;
  margin-bottom: 25px;
`;

const loadingCircle = keyframes`
   from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
`;

export const RightPartContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  &.disable {
    animation: ${fadeIn} 20s cubic-bezier(0.215, 0.61, 0.355, 1) 1s both;
  }
`;

export const SpinLoading = styled.div`
  border: 2px solid #ffffff;
  border-left-color: ${(porps) => porps.color};
  width: 12px;
  height: 12px;
  border-radius: 50%;
  animation: ${loadingCircle} 1s infinite linear;
`;

export const SpinContainer = styled.div`
  padding: 2px;
  border-radius: 50%;
  background-color: #ffffff;
`;

export const NoSpinContainer = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #ffffff;
`;

export const StyledSwitch = withStyles((theme) => ({
  root: {
    width: 40,
    height: 24,
    padding: 0,
    overflow: 'inherit',
  },
  switchBase: {
    padding: 2,
    '&$checked': {
      transform: 'translateX(14px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#12c39a',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 20,
    height: 20,
  },
  track: {
    borderRadius: 26 / 2,
    backgroundColor: '#a9acbe',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
    boxShadow: '0px 0px 7px 1px rgb(0 0 0 / 7%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
  },
  checked: {
    padding: 2,
  },
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});
