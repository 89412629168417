import React from 'react';
import PropTypes from 'prop-types';

export default function Ghost({ className }) {
  return (
    <svg className={className} width="100%" height="107px" viewBox="0 0 152.46 107.19">
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <ellipse cx="71.89" cy="102.61" rx="36.04" ry="4.58" fill="#f2f3f6" />
          <path
            d="M115.56,36.18c0-.89-.08-1.9-1.11-2.11a4.74,4.74,0,0,0-2.29.65l-2.26.89c0-2-.11-3.78-.19-4.3a30.81,30.81,0,1,0-61.62,0s0,.08,0,.13c0,1.45.34,16-1.37,19.19-1.32,2.46-7.38,12.86-8.59,18.57-.42,2,.67,4.6,2.91,4.65,4.27.1,4.55-1.94,7.76-2s4.75,3.2,9.6,3.2c5.24,0,7.54-3.19,12.46-3.19,2.28,0,6.36,3.77,11.88,3.77,3.52,0,6.11-3.59,10.82-3.59,4.16,0,4.22,3.12,8.3,3,2.74-.09,3.19-2.07,4.08-5.19,1.55-5.42,3-16.13,3.7-24.65C112.86,43.19,115.6,40.17,115.56,36.18Z"
            fill="#e3e7ed"
          />
          <circle cx="83.73" cy="31.66" r="2.1" fill="#506a88" />
          <circle cx="100.32" cy="31.66" r="2.1" fill="#506a88" />
          <path
            d="M88.87,38a4.74,4.74,0,0,0,6.7,0"
            fill="none"
            stroke="#506a88"
            strokeLinecap="round"
            strokeMiterlimit="10"
          />
          <path
            d="M75.84,57.18c.23-3.28-.25-6.76-.37-10,0-.64-1-.67-1,0,.06,1.78.13,3.57.2,5.35,0,1.29.9,5.73-1,6.16-1.45.32-3.05-.8-3.93-1.78a12.86,12.86,0,0,1-1.65-2.54,26.36,26.36,0,0,1-2-5.63.5.5,0,0,0-1,.24c.91,3.63,3.18,11,8,10.78A2.58,2.58,0,0,0,75.84,57.18Z"
            fill="#506a88"
          />
          <path
            d="M12.88,58.59H11V56.75a.38.38,0,1,0-.75,0v1.84H8.45a.38.38,0,1,0,0,.75h1.84v1.84a.38.38,0,1,0,.75,0V59.34h1.84a.38.38,0,1,0,0-.75Z"
            fill="#e3e7ed"
          />
          <path
            d="M4.81,34H3V32.12a.38.38,0,1,0-.75,0V34H.38a.38.38,0,1,0,0,.75H2.22v1.84a.38.38,0,1,0,.75,0V34.71H4.81a.38.38,0,1,0,0-.75Z"
            fill="#83a0bc"
          />
          <path
            d="M29.28,20.82H27.43V19a.36.36,0,0,0-.37-.37.37.37,0,0,0-.38.37v1.84H24.84a.37.37,0,0,0-.37.38.36.36,0,0,0,.37.37h1.84v1.85a.37.37,0,0,0,.38.37.36.36,0,0,0,.37-.37V21.57h1.85a.36.36,0,0,0,.37-.37A.37.37,0,0,0,29.28,20.82Z"
            fill="#dadae0"
          />
          <path
            d="M130.08,13.8h-1.91V11.88a.38.38,0,0,0-.38-.37.37.37,0,0,0-.37.37V13.8h-1.93a.38.38,0,0,0-.37.38.37.37,0,0,0,.37.37h1.93v1.92a.37.37,0,0,0,.37.38.38.38,0,0,0,.38-.38V14.55h1.91a.37.37,0,0,0,.38-.37A.38.38,0,0,0,130.08,13.8Z"
            fill="#e3e7ed"
          />
          <path
            d="M152.08,39h-1.92V37a.38.38,0,1,0-.75,0V39h-1.92a.38.38,0,1,0,0,.75h1.92v1.92a.38.38,0,0,0,.75,0V39.71h1.92a.38.38,0,1,0,0-.75Z"
            fill="#83a0bc"
          />
          <path
            d="M134.68,56.61h-1.92V54.69a.38.38,0,0,0-.38-.38.37.37,0,0,0-.37.38v1.92h-1.93a.38.38,0,0,0,0,.75H132v1.92a.37.37,0,0,0,.37.38.38.38,0,0,0,.38-.38V57.36h1.92a.38.38,0,0,0,0-.75Z"
            fill="#dadae0"
          />
          <path
            d="M109.52,45.16c-.72,8.52-2.16,19.23-3.71,24.65-.89,3.12-1.33,5.1-4.08,5.19-4.07.13-4.14-3-8.3-3-4.71,0-7.3,3.59-10.82,3.59-5.52,0-9.6-3.77-11.88-3.77-4.92,0-7.22,3.19-12.46,3.19-4.85,0-6.41-3.3-9.6-3.2s-3.49,2.13-7.76,2c-2.23-.06-3.33-2.63-2.9-4.66,1.2-5.71,7.32-14,8.59-18.58,1-3.47,1.39-17.73,1.36-19.18v-.13A30.8,30.8,0,0,1,78.76.5c17,0,30.32,13.44,30.81,30.81,0,.51.15,2.21.18,4.12l2.55-1a4.74,4.74,0,0,1,2.29-.65c1,.21,1.1,1.22,1.11,2.11a9.28,9.28,0,0,1-2.91,6.63A15.46,15.46,0,0,1,109.52,45.16Z"
            fill="none"
            stroke="#506a88"
            strokeMiterlimit="10"
          />
          <path
            d="M54.89,26.17l-.21,0A1,1,0,0,1,53.91,25a27.14,27.14,0,0,1,2.43-6.61,35.38,35.38,0,0,1,4.78-6.26,1,1,0,1,1,1.43,1.39,33.91,33.91,0,0,0-4.47,5.86,25.21,25.21,0,0,0-2.22,6A1,1,0,0,1,54.89,26.17Z"
            fill="#fff"
          />
          <path
            d="M65.32,10.56a1,1,0,0,1-.57-1.82l.76-.53a1,1,0,1,1,1.15,1.64l-.77.53A1,1,0,0,1,65.32,10.56Z"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
}

Ghost.propTypes = {
  className: PropTypes.string,
};
