import Immutable from 'immutable';
import createReducer from 'utils/createReducer';
import { toasterMessages } from '@crimson-education/common-config';
import roadmapApi from 'graphql/api/roadmap';
import missionType from 'constants/missionType';
import { ADD_ENTITIES, addEntitiesWithNormalisation } from 'ducks/normalizr';
import {
  fetchTasksByUserId,
  deleteTasksForMission,
  bulkDeleteTasksSucceeded,
  bulkInsertTasksSucceded,
  deleteTasksForRoadmap,
} from 'ducks/task';
import { roadmapEntity, taskEntity } from 'schema';
import taskStatus from 'constants/taskStatus';
import { getMissionsByCategoryId } from 'selectors/roadmap';
import {
  modalSuccess,
  formFail,
  modalLoading,
  resetModal,
  successForm,
  updateMeta,
  formLoading,
  formLoaded,
} from 'ducks/meta';
import componentKeys from 'constants/componentKeys';
import { formatGraphQLRequestError } from 'utils/graphql';

export const UNCATEGORISED_GROUP_ID = 'UNCATEGORISED_GROUP_ID';

const ADD_MISSION = 'roadmap/ADD_MISSION';
const UPDATE_MISSION = 'roadmap/UPDATE_MISSION';
const DELETE_MISSION = 'roadmap/DELETE_MISSION';
const PUBLISH_ROADMAP = 'roadmap/PUBLISH_ROADMAP';
const ADD_CATEGORY = 'roadmap/ADD_CATEGORY';
const UPDATE_CATEGORY = 'roadmap/UPDATE_CATEGORY';
const DELETE_CATEGORY = 'roadmap/DELETE_CATEGORY';
const DELETE_ROADMAP = 'roadmap/DELETE_ROADMAP';
const DELETE_ROADMAP_ELEMENTS = 'roadmap/DELETE_ROADMAP_ELEMENTS';
const RENAME_TEMPLATE = 'roadmap/RENAME_TEMPLATE';
const UPDATE_ROADMAP_PROPERTIES = 'roadmap/UPDATE_ROADMAP_PROPERTIES';

const initialState = new Immutable.Map();

export default createReducer(initialState, {
  [ADD_ENTITIES]: (state, action) => {
    return state.mergeDeep(action.payload.entities.roadmap);
  },
  [ADD_MISSION]: (state, { payload: { mission, roadmapId } }) => {
    const existingMissions = state.getIn([roadmapId, 'missions']);
    const existingOpenElements = state.getIn([roadmapId, 'openElements']);
    return state.withMutations((state) => {
      state.setIn([roadmapId, 'missions'], existingMissions.push(Immutable.fromJS(mission)));
      state.setIn([roadmapId, 'openElements'], existingOpenElements.concat([mission.id, mission.category.id]));
      state.setIn([roadmapId, 'focusedMission'], Immutable.fromJS({ id: mission.id, isNew: true }));
    });
  },
  [UPDATE_ROADMAP_PROPERTIES]: (state, { payload: { roadmapId, properties } }) => {
    return state.withMutations((state) => {
      Object.keys(properties).forEach((k) => state.setIn([roadmapId, k], Immutable.fromJS(properties[k])));
    });
  },
  [UPDATE_MISSION]: (state, { payload: { mission, roadmapId } }) => {
    const updatedMission = Immutable.fromJS(mission);
    const existingMissions = state.getIn([roadmapId, 'missions']);
    const index = existingMissions.findIndex((item) => {
      return item.get('id') === updatedMission.get('id');
    });
    return state.withMutations((state) => {
      state.setIn([roadmapId, 'focusedMission'], Immutable.fromJS({ id: mission.id, isNew: false }));
      state.setIn([roadmapId, 'missions', index], updatedMission);
    });
  },
  [DELETE_MISSION]: (state, { payload: { missionId, roadmapId } }) => {
    return state.updateIn([roadmapId, 'missions'], (missions) => {
      const index = missions.findIndex((item) => {
        return item.get('id') === missionId;
      });
      return missions.deleteIn([index]);
    });
  },
  [PUBLISH_ROADMAP]: (state, { payload: { roadmapId } }) => {
    return state.setIn([roadmapId, 'status'], 'active');
  },
  [RENAME_TEMPLATE]: (state, { payload: { roadmapId, templateName } }) => {
    return state.setIn([roadmapId, 'templateName'], templateName);
  },
  [ADD_CATEGORY]: (state, { payload: { category, roadmapId } }) => {
    return state.updateIn([roadmapId, 'categories'], (categories) => {
      return categories.push(Immutable.fromJS(category));
    });
  },
  [UPDATE_CATEGORY]: (state, { payload: { category, roadmapId } }) => {
    const updatedCategory = Immutable.fromJS(category);
    return state.updateIn([roadmapId, 'categories'], (categories) => {
      const index = categories.findIndex((item) => {
        return item.get('id') === updatedCategory.get('id');
      });
      return categories.setIn([index], updatedCategory);
    });
  },
  [DELETE_CATEGORY]: (state, { payload: { categoryId, roadmapId } }) => {
    return state.updateIn([roadmapId, 'categories'], (missions) => {
      const index = missions.findIndex((item) => {
        return item.get('id') === categoryId;
      });
      return missions.deleteIn([index]);
    });
  },
  [DELETE_ROADMAP]: (state, { payload: { roadmapId } }) => {
    return state.delete(roadmapId);
  },
  [DELETE_ROADMAP_ELEMENTS]: (state, { payload: { roadmapId, missionIds, categoryIds } }) => {
    const updatedState = state.setIn(
      [roadmapId, 'categories'],
      state.getIn([roadmapId, 'categories']).filter((category) => {
        return categoryIds && categoryIds.length ? !categoryIds.includes(category.get('id')) : true;
      }),
    );

    return updatedState.setIn(
      [roadmapId, 'missions'],
      state.getIn([roadmapId, 'missions']).filter((mission) => {
        return missionIds && missionIds.length ? !missionIds.includes(mission.get('id')) : true;
      }),
    );
  },
});

export function createMissionSucceeded(mission, roadmapId) {
  return { type: ADD_MISSION, payload: { mission, roadmapId } };
}

export function deleteRoadmapSucceeded(roadmapId) {
  return { type: DELETE_ROADMAP, payload: { roadmapId } };
}

export function updateMissionSucceeded(mission, roadmapId) {
  return { type: UPDATE_MISSION, payload: { mission, roadmapId } };
}

export function deleteMissionSucceeded(missionId, roadmapId) {
  return { type: DELETE_MISSION, payload: { missionId, roadmapId } };
}

export function publishRoadmapSucceeded(roadmapId) {
  return { type: PUBLISH_ROADMAP, payload: { roadmapId } };
}

export function createCategorySucceeded(category, roadmapId) {
  return { type: ADD_CATEGORY, payload: { category, roadmapId } };
}

export function updateCategorySucceeded(category, roadmapId) {
  return { type: UPDATE_CATEGORY, payload: { category, roadmapId } };
}

export function deleteCategorySucceeded(categoryId, roadmapId) {
  return { type: DELETE_CATEGORY, payload: { categoryId, roadmapId } };
}

export function deleteRoadmapElementsSucceeded({ roadmapId, missionIds, categoryIds }) {
  return { type: DELETE_ROADMAP_ELEMENTS, payload: { roadmapId, missionIds, categoryIds } };
}

export function renameTemplateSucceeded(roadmapId, templateName) {
  return { type: RENAME_TEMPLATE, payload: { roadmapId, templateName } };
}

export function updateRoadmapProperties(roadmapId, properties) {
  return { type: UPDATE_ROADMAP_PROPERTIES, payload: { roadmapId, properties } };
}

export function fetchRoadmapByUserId(userId) {
  return async (dispatch) => {
    dispatch(updateMeta(componentKeys.ROADMAP_FETCHED, false));
    return Promise.all([
      dispatch(fetchTasksByUserId(userId)),
      roadmapApi.fetchRoadmapByUserId(userId).then((res) => {
        if (res.roadmap) {
          const roadmap = res.roadmap;
          const categories = roadmap.categories.map((category) => {
            return category.id;
          });
          const missions = roadmap.missions.map((mission) => {
            return mission.id;
          });
          const openElements = categories.concat(missions);

          roadmap.openElements = openElements.concat([UNCATEGORISED_GROUP_ID]);
          roadmap.focusedMission = '';
          dispatch(addEntitiesWithNormalisation(roadmap, roadmapEntity));
        }
        dispatch(updateMeta(componentKeys.ROADMAP_FETCHED, true));
      }),
    ]);
  };
}

export function fetchRoadmapIdByUserId(userId) {
  return (dispatch) => {
    return roadmapApi.fetchRoadmapIdByUserId(userId).then((res) => {
      if (res.roadmap) {
        dispatch(addEntitiesWithNormalisation(res.roadmap, roadmapEntity));
      }
    });
  };
}

export function createRoadmap(userId) {
  return (dispatch) => {
    dispatch(modalLoading());
    return roadmapApi
      .createRoadmap(userId)
      .then((res) => {
        dispatch(modalSuccess(toasterMessages.roadmapCreated()));
        if (res.roadmap) {
          res.roadmap.openElements = [UNCATEGORISED_GROUP_ID];
          dispatch(addEntitiesWithNormalisation(res.roadmap, roadmapEntity));
        }
      })
      .catch((errors) => {
        dispatch(formFail(formatGraphQLRequestError(errors)));
        dispatch(resetModal());
      });
  };
}

export function publishRoadmap(roadmapId) {
  return (dispatch) => {
    dispatch(modalLoading());
    return roadmapApi
      .publishRoadmap(roadmapId)
      .then((res) => {
        dispatch(modalSuccess(toasterMessages.roadmapPublished()));
        dispatch(publishRoadmapSucceeded(res.publishRoadmap && res.publishRoadmap.id));
      })
      .catch((errors) => {
        dispatch(formFail(formatGraphQLRequestError(errors)));
      });
  };
}

export function deleteRoadmap(roadmapId) {
  return (dispatch) => {
    dispatch(modalLoading());
    return roadmapApi
      .deleteRoadmap(roadmapId)
      .then(async (res) => {
        dispatch(updateMeta(componentKeys.LOADED_ROADMAP_TEMPLATE, null));
        await dispatch(deleteTasksForRoadmap(roadmapId));
        res.deleteRoadmap.isTemplate
          ? dispatch(modalSuccess(toasterMessages.roadmapTemplateDeleted()))
          : dispatch(modalSuccess(toasterMessages.roadmapDeleted()));

        dispatch(deleteRoadmapSucceeded(res.deleteRoadmap.id));
      })
      .catch((errors) => {
        dispatch(formFail(formatGraphQLRequestError(errors)));
        dispatch(resetModal());
      });
  };
}

export function createMission(mission) {
  return (dispatch) => {
    dispatch(modalLoading());
    return roadmapApi
      .createMission(mission)
      .then((res) => {
        if (res.createMission.tasks.length) {
          dispatch(bulkInsertTasksSucceded(res.createMission.tasks));
        }

        dispatch(createMissionSucceeded(res.createMission, mission.roadmapId));
        res.createMission.type === missionType.DEADLINE
          ? dispatch(modalSuccess(toasterMessages.roadmapDeadlineCreated()))
          : dispatch(modalSuccess(toasterMessages.roadmapMissionCreated()));

        dispatch(resetModal());
        dispatch(successForm());
        return res.createMission;
      })
      .catch((errors) => {
        dispatch(formFail(formatGraphQLRequestError(errors)));
        dispatch(resetModal());
      });
  };
}

export function updateMissionTask(mission, roadmapId, showSuccessToastr = false) {
  return (dispatch) => {
    return roadmapApi
      .updateMissionTask(mission)
      .then((res) => {
        dispatch(updateMissionSucceeded(res.updateMission, roadmapId));
        if (showSuccessToastr) {
          dispatch(modalSuccess(toasterMessages.roadmapTaskCompleted()));
        }
      })
      .catch((errors) => {
        dispatch(formFail(formatGraphQLRequestError(errors)));
      });
  };
}

export function updateMission(mission) {
  return (dispatch) => {
    dispatch(modalLoading());
    return roadmapApi
      .updateMission(mission)
      .then((res) => {
        const deletedTasks = mission.tasks.filter((task) => task.status === taskStatus.DELETED).map((task) => task.id);

        if (deletedTasks.length) {
          dispatch(bulkDeleteTasksSucceeded(deletedTasks));
        }

        if (res.updateMission.tasks.length) {
          dispatch(bulkInsertTasksSucceded(res.updateMission.tasks));
        }

        res.updateMission.type === missionType.DEADLINE
          ? dispatch(modalSuccess(toasterMessages.roadmapDeadlineUpdated()))
          : dispatch(modalSuccess(toasterMessages.roadmapMissionUpdated()));

        dispatch(updateMissionSucceeded(res.updateMission, mission.roadmapId));
        dispatch(resetModal());
        dispatch(successForm());
        dispatch(resetModal());
        return res.updateMission;
      })
      .catch((errors) => {
        dispatch(formFail(formatGraphQLRequestError(errors)));
        dispatch(resetModal());
      });
  };
}

export function deleteMission(missionId, roadmapId) {
  return (dispatch) => {
    dispatch(modalLoading());
    return roadmapApi
      .deleteMission(missionId)
      .then(async (res) => {
        await dispatch(deleteTasksForMission(missionId));
        res.deleteMission.type === missionType.DEADLINE
          ? dispatch(modalSuccess(toasterMessages.roadmapDeadlineDeleted()))
          : dispatch(modalSuccess(toasterMessages.roadmapMissionDeleted()));

        dispatch(deleteMissionSucceeded(missionId, roadmapId));
        dispatch(successForm());
        dispatch(resetModal());
      })
      .catch((errors) => {
        dispatch(formFail(formatGraphQLRequestError(errors)));
        dispatch(resetModal());
      });
  };
}

export function createCategory(category) {
  return (dispatch) => {
    dispatch(modalLoading());
    return roadmapApi
      .createCategory(category)
      .then((res) => {
        dispatch(createCategorySucceeded(res.createCategory, category.roadmapId));
        dispatch(modalSuccess(toasterMessages.roadmapCategoryCreated()));
        dispatch(resetModal());
        dispatch(successForm());
        dispatch(resetModal());
      })
      .catch((errors) => {
        dispatch(formFail(formatGraphQLRequestError(errors)));
        dispatch(resetModal());
      });
  };
}

export function updateCategory(category) {
  return (dispatch) => {
    dispatch(modalLoading());
    return roadmapApi
      .updateCategory(category)
      .then((res) => {
        dispatch(updateCategorySucceeded(res.updateCategory, category.roadmapId));
        dispatch(modalSuccess(toasterMessages.roadmapCategoryUpdated()));
        dispatch(resetModal());
        dispatch(successForm());
        dispatch(resetModal());
      })
      .catch((errors) => {
        dispatch(formFail(formatGraphQLRequestError(errors)));
        dispatch(resetModal());
      });
  };
}

export function deleteCategory(roadmapId, categoryId) {
  return (dispatch, getState) => {
    dispatch(modalLoading());
    const missions = getMissionsByCategoryId(getState())(roadmapId, categoryId);
    return roadmapApi
      .deleteCategory(roadmapId, categoryId)
      .then(() => {
        missions.forEach(async (m) => dispatch(deleteTasksForMission(m.id)));
        missions.forEach(async (m) => dispatch(deleteMissionSucceeded(m.id, roadmapId)));
        dispatch(modalSuccess(toasterMessages.roadmapCategoryDeleted()));
        dispatch(deleteCategorySucceeded(categoryId, roadmapId));
        dispatch(successForm());
        dispatch(resetModal());
      })
      .catch((errors) => {
        dispatch(formFail(formatGraphQLRequestError(errors)));
        dispatch(resetModal());
      });
  };
}

export function fetchRoadmapTemplates() {
  return (dispatch) => {
    dispatch(formLoading());
    return roadmapApi
      .fetchRoadmapTemplates()
      .then((res) => {
        if (res.roadmaps) {
          dispatch(addEntitiesWithNormalisation(res.roadmaps, [roadmapEntity]));
          dispatch(updateMeta(componentKeys.ROADMAP_FETCHED, true));
          dispatch(formLoaded());
        }
      })
      .catch((errors) => {
        dispatch(formFail(formatGraphQLRequestError(errors)));
      });
  };
}

export function fetchRoadmapById(id) {
  return (dispatch) => {
    dispatch(formLoading());
    return roadmapApi
      .fetchRoadmapById(id)
      .then((res) => {
        if (res.roadmap) {
          const roadmap = res.roadmap;
          const categories = roadmap.categories.map((category) => {
            return category.id;
          });
          const missions = roadmap.missions.map((mission) => {
            return mission.id;
          });
          const tasks = roadmap.tasks;
          roadmap.openElements = categories.concat(missions).concat([UNCATEGORISED_GROUP_ID]);
          delete roadmap.tasks;

          dispatch(addEntitiesWithNormalisation(roadmap, roadmapEntity));
          dispatch(addEntitiesWithNormalisation(tasks, [taskEntity]));
          dispatch(updateMeta(componentKeys.ROADMAP_FETCHED, true));
          dispatch(updateMeta(componentKeys.LOADED_ROADMAP_TEMPLATE, id));
          dispatch(formLoaded());
        }
      })
      .catch((errors) => {
        dispatch(formFail(formatGraphQLRequestError(errors)));
      });
  };
}

export function createRoadmapFromTemplate(userId, templateId, endYear) {
  return (dispatch) => {
    return roadmapApi
      .createRoadmapFromTemplate(userId, templateId, endYear)
      .then((res) => {
        const newRoadmap = res.createRoadmapFromTemplate;
        if (newRoadmap) {
          dispatch(modalSuccess(toasterMessages.roadmapTemplateLoaded()));
          const tasks = newRoadmap.tasks;
          delete newRoadmap.tasks;
          const categories = newRoadmap.categories.map((category) => {
            return category.id;
          });
          const missions = newRoadmap.missions.map((mission) => {
            return mission.id;
          });
          newRoadmap.openElements = categories.concat(missions).concat([UNCATEGORISED_GROUP_ID]);
          newRoadmap.focusedMission = '';
          dispatch(addEntitiesWithNormalisation(tasks, [taskEntity]));
          dispatch(addEntitiesWithNormalisation(newRoadmap, roadmapEntity));
          dispatch(resetModal());
          dispatch(successForm());
          dispatch(resetModal());
        }
      })
      .catch((errors) => {
        dispatch(formFail(formatGraphQLRequestError(errors)));
        dispatch(resetModal());
      });
  };
}

export function createRoadmapTemplate(templateName) {
  return (dispatch) => {
    dispatch(modalLoading());
    return roadmapApi
      .createRoadmapTemplate(templateName)
      .then((res) => {
        if (res.roadmap) {
          res.roadmap.isTemplate
            ? dispatch(modalSuccess(toasterMessages.roadmapTemplateCreated()))
            : dispatch(modalSuccess(toasterMessages.roadmapCreated()));

          res.roadmap.openElements = [UNCATEGORISED_GROUP_ID];
          dispatch(addEntitiesWithNormalisation(res.roadmap, roadmapEntity));
          dispatch(updateMeta(componentKeys.LOADED_ROADMAP_TEMPLATE, res.roadmap.id));
        }
      })
      .catch((errors) => {
        dispatch(formFail(formatGraphQLRequestError(errors)));
        dispatch(resetModal());
      });
  };
}

export function duplicateRoadmapTemplate(templateId, templateName) {
  return (dispatch) => {
    dispatch(modalLoading());
    return roadmapApi
      .duplicateRoadmapTemplate(templateId, templateName)
      .then((res) => {
        if (res.roadmap) {
          const newRoadmap = res.roadmap;
          const tasks = newRoadmap.tasks;
          delete newRoadmap.tasks;
          dispatch(addEntitiesWithNormalisation(tasks, [taskEntity]));

          const categories = newRoadmap.categories.map((category) => {
            return category.id;
          });
          const missions = newRoadmap.missions.map((mission) => {
            return mission.id;
          });
          res.roadmap.openElements = categories.concat(missions).concat([UNCATEGORISED_GROUP_ID]);
          res.roadmap.focusedMission = '';
          dispatch(addEntitiesWithNormalisation(res.roadmap, roadmapEntity));
          dispatch(updateMeta(componentKeys.LOADED_ROADMAP_TEMPLATE, res.roadmap.id));
          dispatch(modalSuccess(toasterMessages.roadmapTemplateCreated()));
        }
      })
      .catch((errors) => {
        dispatch(formFail(formatGraphQLRequestError(errors)));
        dispatch(resetModal());
      });
  };
}

export function deleteRoadmapElements({ roadmapId, taskIds, missionIds, categoryIds }) {
  return (dispatch) => {
    return roadmapApi
      .deleteRoadmapElements({ roadmapId, taskIds, missionIds, categoryIds })
      .then(async (res) => {
        if (res.deleteRoadmapElements) {
          const deletedElements = res.deleteRoadmapElements;
          const tasks = deletedElements.deletedTaskIds;
          dispatch(modalSuccess('Roadmap items have been deleted'));

          await dispatch(bulkDeleteTasksSucceeded(tasks));
          dispatch(deleteRoadmapElementsSucceeded({ roadmapId, missionIds, categoryIds }));
        }
      })
      .catch((errors) => {
        dispatch(formFail(formatGraphQLRequestError(errors)));
      });
  };
}

export function renameRoadmapTemplate(templateId, templateName) {
  return (dispatch) => {
    return roadmapApi
      .renameRoadmapTemplate(templateId, templateName)
      .then((res) => {
        if (res.roadmap) {
          dispatch(modalSuccess('Template has been renamed'));
          dispatch(renameTemplateSucceeded(res.roadmap.id, res.roadmap.templateName));
        }
      })
      .catch((errors) => {
        dispatch(formFail(formatGraphQLRequestError(errors)));
        dispatch(resetModal());
      });
  };
}
