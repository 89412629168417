import { Acl, roleTypes } from '@crimson-education/common-config';

// User is immutable type
export const userIdsOfRelationshipForUser = (user, relationshipType) => {
  const relationships = user && user.get('relationships');
  if (relationships && relationships.size) {
    const result = relationships.filter((r) => r.get('type') === relationshipType);
    return result.toJS();
  }
  return null;
};

export const userCanHaveEduco = (roles) => {
  const isStudent = Acl.checkRole(roles, roleTypes.STUDENT);
  const isExternalStudent = Acl.checkRole(roles, roleTypes.EXTERNAL_STUDENT);

  return isStudent || isExternalStudent;
};

export const userCanHaveStrategist = (roles) => {
  const isStudent = Acl.checkRole(roles, roleTypes.STUDENT);

  return isStudent;
};

export const userCanHaveReviewer = (roles) => {
  const isStudent = Acl.checkRole(roles, roleTypes.STUDENT);
  const isExternalStudent = Acl.checkRole(roles, roleTypes.EXTERNAL_STUDENT);

  return isStudent || isExternalStudent;
};

export const userCanHaveHeadTutor = (roles) => {
  const isTutor = Acl.checkRole(roles, roleTypes.TUTOR);

  return isTutor;
};
