/**
 * IMPORTANT NOTICE
 * ----------------
 * This is entry point of the site.
 * It is important to keep this file lean and mean, so that if the user is not logged in, we can redirect the user to Auth0 without loading megabytes of JS.
 * You will have none of the usual facilties that one would expect, e.g. React, Redux, etc, and that is intentional.
 * Let's write Javascript here like it's 1995.
 */
/* eslint-disable no-use-before-define */
/* eslint-disable no-void */
/* eslint-disable camelcase */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import 'regenerator-runtime/runtime';
import * as Logger from '@crimson-education/browser-logger';
import { getEnvironmentConfig } from '@crimson-education/common-config/lib/environment';
import { logout, authorize, auth0Authorize, impersonateUser, parseHash, handleWechatAuthCallback } from 'utils/auth';
import { logutFromIM } from 'tim/tim';

const config = getEnvironmentConfig();
// Init Logging and Analytics
Logger.init({
  service: 'crimson-app-frontend',
  environment: config.environment,
  version: config.buildVersion,
  defaultMetadata: {
    application: 'crimson-app',
    namespace: config.namespace,
  },

  reporters: {
    log: true,
    gtm: config.china ? undefined : true,
    datadog: config.datadog.applicationId
      ? {
          applicationId: config.datadog.applicationId,
          clientToken: config.datadog.clientToken,
          site: config.datadog.site,
          proxyUrl: 'https://proxy.crimsoneducation.org/ddog',
          sampleRate: 50,
          replaySampleRate: 50,

          trackInteractions: true,
          forwardConsoleLogs: true,
          allowedTracingOrigins: [
            config.api.endpoint,
            config.api.websocketEndpoint,
            config.pushNotifications.endpoint,
            config.pushNotifications.websocketEndpoint,
          ],
        }
      : undefined,
    amplify: config.awsAmplifyConfig.analyticsAPPId
      ? {
          region: config.awsAmplifyConfig.region,
          identityPoolId: config.awsAmplifyConfig.identityPoolId,
          analyticsAppId: config.awsAmplifyConfig.analyticsAPPId,
          proxyUrl: config.awsAmplifyConfig.proxyUrl,
          autoTrackEvents: true,
          autoTrackPageViews: true,
          autoTrackSessions: true,
        }
      : undefined,
  },
});

if (config.datadog.applicationId) {
  console.debug('Crimson App Datadog enabled');
}

if (config.awsAmplifyConfig.analyticsAPPId) {
  console.debug('Crimson App Amplify enabled');
}

declare global {
  // eslint-disable-next-line no-var
  var actionQueue: any[];
}

/*
 * Queue for action to perform once React/Redux is loaded
 * Format: {
 *  action: <redux action name>
 *  data: {
 *    <params for action>
 *  }
 * }
 */
window.actionQueue = window.actionQueue || [];

function showLoading() {
  const loading = document.getElementById('loading');
  loading?.setAttribute('style', '');
}

function hideLoading() {
  const loading = document.getElementById('loading');
  loading?.setAttribute('style', 'display:none');
}

async function loadCrimsonApp() {
  await import('app' /* webpackChunkName: 'App' */);
}

function hideError() {
  const errorElement = document.getElementById('error');
  errorElement?.setAttribute('style', 'display:none');

  const fallbackErrorElement = document.getElementById('fallbackError');
  fallbackErrorElement?.setAttribute('style', 'display:none');

  const technicalDetailsContainer = document.getElementById('error-technical-details-container');
  technicalDetailsContainer?.setAttribute('style', 'display:none');

  const requestIdContainer = document.getElementById('error-request-id');
  requestIdContainer?.setAttribute('style', 'display:none');
}

async function showError(type = 'SystemError', options?: any) {
  hideError();

  // Attempt first to render the ErrorView.
  try {
    const errorElement = document.getElementById('error');
    // Show Error Element. Wait 250ms for layout to figure itself out.
    const showErrorElement = () => {
      setTimeout(() => {
        errorElement?.setAttribute('style', '');
        hideLoading();
      }, 250);
    };

    // Attempt to Load the ErrorView Loader Chunk, then render to the #error element.
    // If failed, fallback to the fallback presenter.
    const errorView = await import('error/index' /* webpackChunkName: 'Error' */);
    errorView.RenderPreset(errorElement, type, options, showErrorElement);
  } catch (e: any) {
    // Fallback to built in error element.
    // Report reason why we couldn't show errorView.
    Logger.reportError(e);
    hideLoading();

    // Set Error Button Handler. Voiding the Promise.
    const errorButton = document.getElementById('error-button');
    if (errorButton) {
      errorButton.onclick = () => void load();
    }

    // Show Fallback Element.
    const fallbackErrorElement = document.getElementById('fallbackError');
    fallbackErrorElement?.setAttribute('style', '');

    // Show the Request Id in the Fallback.
    if (options.requestId) {
      const requestIdContainer = document.getElementById('error-request-id');
      requestIdContainer?.setAttribute('style', '');

      const requestIdSpan = requestIdContainer?.querySelector('span');
      if (requestIdSpan) {
        requestIdSpan.innerHTML = options.requestId;
      }
    }

    // Show Technical Details in the Fallback.
    const technicalDetailsInfo = options.technicalDetails || (e && e.message);
    if (technicalDetailsInfo) {
      const technicalDetailsContainer = document.getElementById('error-technical-details-container');
      technicalDetailsContainer?.setAttribute('style', '');

      const technicalDetails = document.getElementById('error-technical-details');
      if (technicalDetails) {
        technicalDetails.innerHTML = technicalDetailsInfo;
      }
    }
  }
}

function handleErrorRoute() {
  const options = Object.fromEntries(new URLSearchParams(window.location.search));
  const errorData = {
    type: options.type || 'SystemError',
    error: options.error,
    technicalDetails: options.technicalDetails,
    requestId: options.requestId || options.request_id,
  };

  switch (errorData.type) {
    // An Error from Auth0
    // Not necessarily attempting to log in to the Crimson App.
    // Map the Auth0 attributes to error page attributes.
    case 'AuthenticationError':
      if (options.error_description === 'Access expired.') {
        errorData.type = 'AccessExpired';
      } else {
        errorData.technicalDetails = options.error_description;
        errorData.requestId = options.tracking;
      }

      // Report Error to Error Reporter.
      Logger.reportError(new Error(errorData.error || errorData.type), {
        errorFrom: 'Auth0',
        ...errorData,
      });
      break;

    case 'IncompleteRecords':
      // Report Error to Error Reporter.
      Logger.reportError(new Error('Records incomplete'), {
        ...errorData,
      });
      break;

    default:
      break;
  }

  // Show Error UI.
  showError(errorData.type, {
    ...errorData,
  });
}

async function handlePartnershipsSignUp() {
  const signUpView = await import('components/pages/PartnershipsSignUp' /* webpackChunkName: 'PartnershipsSignUp' */);
  signUpView.Render();
  hideLoading();
}

async function handleSchoolConfirmation(confirmType: string, id: string, hashstr: string) {
  const schoolConfirmation = await import(
    'components/pages/SchoolConfirmation' /* webpackChunkName: 'SchoolConfirmation' */
  );
  schoolConfirmation.Render({ confirmType: confirmType as 'approve' | 'reject', id, hashstr });
  hideLoading();
}

async function handlePurchaseCallback(queryString: string) {
  const urlParams = new URLSearchParams(queryString);
  // @TODO: Replace with config
  const id = urlParams.get('spsid');
  const payResult = urlParams.get('spr');
  const isValidCallback = !!payResult && ['0', '1'].includes(payResult);
  if (!id || !isValidCallback) {
    return window.history.replaceState(null, '', '/');
  }
  const purchaseConfirmation = await import(
    'components/pages/PurchaseConfirmation' /* webpackChunkName: 'PurchaseConfirmation' */
  );
  purchaseConfirmation.Render({ payId: id, successful: payResult === '1' });
  hideLoading();
}

async function load() {
  try {
    hideError();
    showLoading();

    const { pathname, hash, search } = window.location;
    const urlParams = new URLSearchParams(search);
    let isAuthorized = true;
    switch (pathname) {
      // Logs out the User by sending the user to API Service to log out.
      case '/logout':
        logutFromIM();
        logout();
        return;

      // Receives auth callback from wechat https://developers.weixin.qq.com/doc/offiaccount/OA_Web_Apps/Wechat_webpage_authorization.html
      // scope = snsapi_base
      case '/wechat-auth-callback':
        await handleWechatAuthCallback(search);
        isAuthorized = await authorize();
        if (isAuthorized) {
          history.replaceState(null, '', '/'); // eslint-disable-line no-restricted-globals
        }
        break;

      // Receives an Authentication Callback from Auth0. Pass it on to API Service.
      case '/auth-callback':
        if (hash) {
          parseHash(hash.substr(1), urlParams.get('redirect') ?? undefined);
        } else {
          await auth0Authorize();
        }
        break;

      case '/impersonate':
        await impersonateUser(urlParams.get('sub'));
        return;

      case '/error':
        // Handle Error Route and Return, Don't load the Crimson App.
        handleErrorRoute();
        return;

      case '/partnerships':
        handlePartnershipsSignUp();
        return;

      case '/school-confirmation':
        const type = urlParams.get('type');
        const id = urlParams.get('id');
        const hashstr = urlParams.get('ref');
        const isValidType = !!type && ['approve', 'reject'].includes(type) && !!hashstr;
        if (!isValidType || !id) return window.history.replaceState(null, '', '/');
        handleSchoolConfirmation(type!, id, hashstr!);
        return;

      case '/purchase-complete':
        handlePurchaseCallback(search);
        return;
      // Default path handling, check if Authenticated.
      default:
        isAuthorized = await authorize();
        break;
    }

    try {
      if (isAuthorized) {
        await loadCrimsonApp();
      } else {
        await auth0Authorize();
      }
    } catch (e: any) {
      // Report Error.
      Logger.reportError(e);

      // delete cache if exists then reload
      if ('caches' in window) {
        caches.keys().then((names) => {
          names.forEach((nm) => {
            caches.delete(nm);
          });
        });
        window.location.reload();
      }
    }

    // Completed.
    hideLoading();
    Logger.trackEvent({ message: 'app-ready' });
  } catch (err: any) {
    // Report Error.
    Logger.reportError(err);

    // Show Error UI.
    showError('SystemError', {
      detailsType: 'TryAgainReload',
      actionButton: {
        content: 'Reload',
        // Voiding the Promise to avoid button progress indicator.
        action: () => void load(),
      },
      technicalDetails: err && err.message,
    });
  }
}

load();
