import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import classNames from 'classnames';
import styles from './styles.scss';
import { asButton } from '../../../utils/accessibility';

/* Accordion
 * A collapsible content section. Given summary and content components as props,
 * the accordion will initially show just the summary. Once clicked, it will
 * show both summary and content. The initial state can be overridden with
 * the isExpanded prop if you need it to be expanded by default.
 */

export default class Accordion extends Component {
  constructor(props) {
    super(props);

    const { isExpanded } = props;

    this.state = { isExpanded };

    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    const { isExpanded } = this.state;

    this.setState({ isExpanded: !isExpanded });
  }

  render() {
    const { isExpanded } = this.state;
    const { summary, content, className } = this.props;

    const dropdownStyle = classNames(styles.icon, { [styles.expanded]: isExpanded });

    const accordionStyle = classNames(styles.accordion, className);

    return (
      <div role="button" tabIndex={0} data-ga-label="Accordion" className={accordionStyle}>
        <div className={styles.summary} {...asButton(this.onClick)}>
          <ArrowDropDown className={dropdownStyle} />
          {summary}
        </div>
        {isExpanded && <div className={styles.content}>{content}</div>}
      </div>
    );
  }
}

Accordion.defaultProps = {
  isExpanded: false,
};

Accordion.propTypes = {
  isExpanded: PropTypes.bool,
  className: PropTypes.string,
  summary: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
};
