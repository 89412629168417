import React from 'react';
import Modal, { OtherModalProps } from './BasicModal';
import { ListContainer } from './index.style';

type ListModalProps<T> = {
  title?: string;
  data: T[];
  renderItem: (item: T, idx: number) => JSX.Element;
  isOpen: boolean;
  onCancel: () => void;
  listContainerStyle?: React.CSSProperties;
} & OtherModalProps;

const ListModal = <T,>({
  title,
  data,
  renderItem,
  isOpen,
  onCancel,
  listContainerStyle,
  ...restProps
}: ListModalProps<T>) => {
  return (
    <Modal isOpen={isOpen} onCancel={onCancel} title={title} {...restProps}>
      <ListContainer style={listContainerStyle}>{data.map(renderItem)}</ListContainer>
    </Modal>
  );
};

export default ListModal;
