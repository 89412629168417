import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import oms from 'graphql/oms';
import React, { FC, useEffect, useMemo, useState } from 'react';
import getSymbolFromCurrency from 'currency-symbol-map';

import SubjectPromotionLoadingView from './PromotionLoadingView';
import * as Styled from './styles';
import { ISubject } from '../../types';
import _ from 'lodash';
import { parseDateRangeString } from '../../util';
import { PurchaseItem } from 'types';
import { useSelector } from 'react-redux';
import { getUserPackageId } from 'selectors/package';
import { useUserId } from 'selectors/hooks';
import { useHorizontalScroll } from 'utils/horizontalScroll';

type TPromotionalSubject = Omit<ISubject, 'id'> & {
  pending?: boolean;
  imageUrl?: string;
  rate: PurchaseItem['rate'];
  metadata: PurchaseItem['metadata'];
};

interface ISubjectPromotionsPros {
  userSubjects: ISubject[];
  onClickPurchase: (item: PurchaseItem) => void;
  pendingSubjects: Set<string>;
}

const SubjectPromotions: FC<ISubjectPromotionsPros> = ({ onClickPurchase, userSubjects, pendingSubjects }) => {
  // Horizontal Scroll Configuration
  const { scroll, ref: scrollContainer, reset: resetArrows, canScrollLeft, canScrollRight } = useHorizontalScroll();

  // Component Business Logic State Values
  const [loading, setLoading] = useState(true);
  const [subjects, setSubjects] = useState<Array<TPromotionalSubject>>([]);
  const userId = useUserId();
  const userPackageId = useSelector(getUserPackageId(userId));
  const availableSubjects = useMemo(() => {
    return subjects.filter((sub) => !_.find(userSubjects, { subjectId: sub.subjectId }));
  }, [userSubjects, subjects]);

  const onClickApply = (subject: TPromotionalSubject) => {
    onClickPurchase({
      name: subject.subject,
      itemId: subject.subjectId,
      rate: subject.rate,
      source: 'PROMOTIONAL_SUBJECTS',
      metadata: subject.metadata,
    });
  };

  // Adjust Arrows of Headless Horizontal Container when Available Subjects change
  useEffect(() => {
    resetArrows();
  }, [availableSubjects]);

  useEffect(() => {
    const fetchPromotions = async () => {
      const { data } = await oms.fetchProductsByType('PROMOTIONAL_SUBJECTS');
      if (data.products?.length > 0) {
        const items = data.products.map((item) => ({
          subject: item.name,
          subjectId: item.sourceId,
          rate: { ...item.rate, symbol: getSymbolFromCurrency(item.rate.currency) },
          metadata: item.metadata,
          imageUrl: item.imageUrl,
          pending: item.hasPendingOrder,
        }));
        setSubjects(items);
        setLoading(false);
      }
    };

    fetchPromotions();
  }, []);

  if (!userPackageId) return <></>;
  if (loading) return <SubjectPromotionLoadingView />;
  if (availableSubjects?.length < 1) return <></>;
  return (
    <Styled.PromotionRow gutter={16} onScroll={resetArrows} ref={scrollContainer} wrap={false}>
      {canScrollLeft ? (
        <Styled.LeftArrow disabled={!canScrollLeft} onClick={() => scroll(-300)}>
          <KeyboardArrowLeft style={{ color: canScrollLeft ? '#464AC9' : '#E3E7ED', height: '24px', width: '24px' }} />
        </Styled.LeftArrow>
      ) : null}
      {canScrollRight ? (
        <Styled.RightArrow disabled={!canScrollRight} onClick={() => scroll(300)}>
          <KeyboardArrowRight
            style={{ color: canScrollRight ? '#464AC9' : '#E3E7ED', height: '24px', width: '24px' }}
          />
        </Styled.RightArrow>
      ) : null}
      {availableSubjects.map((subject, index) => {
        // Build UI Properties
        const showElipses = subject.subject.length > 48;
        const name = subject.subject.slice(0, 48);
        const startDate = _.find(subject.metadata, ['name', 'startDate']);
        const endDate = _.find(subject.metadata, ['name', 'endDate']);
        const category = _.find(subject.metadata, ['name', 'category']);
        const attachment = _.find(subject.metadata, ['name', 'attachment']);
        const formattedDate = parseDateRangeString(startDate?.value, endDate?.value);
        return (
          <Styled.CardContainer key={index} span={24} lg={12} xl={9} xxl={7}>
            <Styled.IconContainer>
              {subject.imageUrl ? <img width={48} src={subject.imageUrl} alt={subject.subject} /> : null}
            </Styled.IconContainer>
            <div>
              <Styled.CardContent>
                <h3>{name + (showElipses ? '...' : '')}</h3>
                {formattedDate ? <p>{formattedDate}</p> : category ? <Styled.Pill>{category.value}</Styled.Pill> : null}
              </Styled.CardContent>
              <div>
                {subject.pending || pendingSubjects.has(subject.subjectId) ? (
                  <Styled.PendingLabel>Pending Request</Styled.PendingLabel>
                ) : (
                  <Styled.ActionButton onClick={() => onClickApply(subject)}>Apply</Styled.ActionButton>
                )}
                {attachment ? (
                  <Styled.ActionLink target="_blank" href={attachment.value}>
                    <span>Learn More</span>
                    <KeyboardArrowRight />
                  </Styled.ActionLink>
                ) : null}
              </div>
            </div>
          </Styled.CardContainer>
        );
      })}
    </Styled.PromotionRow>
  );
};

export default SubjectPromotions;
