import React, { useContext, useMemo } from 'react';
import { Column, Row } from '../index.style';
import { Title, SubTitle } from './index.style';
import { ApplicationGroup } from '../type';
import UniversityList, { UniversityListProps } from '../UniversityList';
import { groupApplications, isUCSchool } from '../util';
import { GROUP_PLACEHOLDER_TEXT } from '../constants';
import { MAP_GROUP_TEXT } from '../constants';
import { AppTrackerContext } from '../viewController';
import Placeholder from './Placeholder';
import Prompt from './Prompt';
import ParentApprovalButton from '../ParentApprovalModal/ParentApprovalButton';
import { useApplicationsWithRegionAndGroup, useIsAccessibleStaff } from '../helper';

type UniversityGroupProps = {
  group: ApplicationGroup;
  prompt: string;
  showParentApprovalButton?: boolean;
} & Pick<
  UniversityListProps,
  'getExtraActionButtonProps' | 'getExtraUniversityNameProps' | 'getExtraDeleteAppButtonProps'
>;

const UniversityGroup = ({ group, prompt, showParentApprovalButton, ...restProps }: UniversityGroupProps) => {
  const isApplying = group === 'APPLYING';
  const { state } = useContext(AppTrackerContext);
  const { applications, listGroupBy, listRegion } = state;
  const _applications = useApplicationsWithRegionAndGroup(applications, listRegion, group);
  const groupedApplications = useMemo(() => groupApplications(_applications, listGroupBy, listRegion), [
    _applications,
    listGroupBy,
    listRegion,
  ]);
  const isAccessibleStaff = useIsAccessibleStaff();
  const _showParentApprovalButton = useMemo(
    () => isAccessibleStaff && showParentApprovalButton && state.listRegion !== 'Other',
    [isAccessibleStaff, showParentApprovalButton, state.listRegion],
  );

  const numOfCrimsonSupport = useMemo(() => {
    const withCrimsonSupport = _applications.filter((o) => o.hadCrimsonSupport);
    const noneUCSchools = withCrimsonSupport.filter((o) => !isUCSchool(o));
    return noneUCSchools.length + +(noneUCSchools.length !== withCrimsonSupport.length);
  }, [_applications]);
  return (
    <Column>
      <Row rowcenter justifycontent={'space-between'}>
        <Row alignitems={'flex-end'}>
          <Title>{MAP_GROUP_TEXT[group]}</Title>
          <Prompt prompt={prompt} />
          {isApplying && <SubTitle>{`（${numOfCrimsonSupport} with Crimson Support）`}</SubTitle>}
        </Row>
        <ParentApprovalButton show={_showParentApprovalButton} />
      </Row>
      {!_applications.length && <Placeholder text={GROUP_PLACEHOLDER_TEXT[group]} />}
      {groupedApplications.map(({ applications, label }, index) => (
        <UniversityList
          index={index}
          key={label}
          applications={applications}
          count={applications.length}
          groupText={label}
          {...restProps}
        />
      ))}
    </Column>
  );
};

export default UniversityGroup;
