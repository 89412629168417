import React from 'react';
import { GroupByButton, GroupByButtonText } from './index.style';
import DropdownMenu from '../Dropdown/DropdownMenu';
import { MoreLessIcon } from '../index.style';

type GroupByButtonProps = {
  menuData: { label: string; value: string }[];
  groupBy: string;
  onClickGroupByMenuItem: (value: string) => void;
};
const GroupBy = ({ menuData, groupBy, onClickGroupByMenuItem }: GroupByButtonProps) => {
  return (
    <DropdownMenu data={menuData} onClickItem={onClickGroupByMenuItem}>
      {(isOpen) => (
        <GroupByButton
          style={isOpen ? { border: '1px solid #464AC9' } : undefined}
          endIcon={<MoreLessIcon open={isOpen} style={isOpen ? { color: '#464AC9' } : undefined} />}
        >
          {'Group by: '}
          <GroupByButtonText>{groupBy}</GroupByButtonText>
        </GroupByButton>
      )}
    </DropdownMenu>
  );
};

export default GroupBy;
