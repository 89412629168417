import createReducer from 'utils/createReducer';
import Immutable from 'immutable';
import { toasterMessages } from '@crimson-education/common-config';
import admissionService from 'graphql/admissionService';
import { formSuccess, formFail } from 'ducks/meta';

const ADD_USER_ADMISSION_SUCCESS = 'admissionResult/ADD_USER_ADMISSION_SUCCESS';
const UPDATE_USER_ADMISSION_SUCCESS = 'admissionResult/UPDATE_USER_ADMISSION_SUCCESS';
const DELETE_USER_ADMISSION_RESULT_SUCCESS = 'admissionsResult/DELETE_USER_ADMISSION_RESULT_SUCCESS';
const FETCH_USER_ADMISSION_RESULTS_SUCCESS = 'admissionResult/FETCH_USER_ADMISSION_RESULTS_SUCCESS';

const ADMISSION_RESULT_DELETED = 'Succesfully deleted admission result';
const ADMISSION_RESULT_NOT_DELETED = 'Failed to delete admission result';

const defaultState = Immutable.fromJS({
  users: {},
});

export default createReducer(defaultState, {
  [ADD_USER_ADMISSION_SUCCESS]: (state, { payload: { createAdmission } }) => {
    const { studentId } = createAdmission;
    const user = Object.assign({ studentId }, createAdmission);
    const allUsers = state.get('users');
    const userState = allUsers.get(studentId);
    return !userState
      ? state.setIn(['users', studentId], Immutable.fromJS(user))
      : state.setIn(['users', studentId], userState.unshift(Immutable.fromJS(createAdmission)));
  },
  [UPDATE_USER_ADMISSION_SUCCESS]: (state, { payload: { updateAdmission } }) => {
    const admission = Immutable.fromJS(updateAdmission);
    return state.updateIn(['users', admission.get('studentId')], (admissions) => {
      const index = admissions.findIndex((result) => result.get('id') === admission.get('id'));
      return admissions.setIn([index], admission);
    });
  },
  [FETCH_USER_ADMISSION_RESULTS_SUCCESS]: (state, { payload: { admissions, studentId } }) => {
    return state.setIn(['users', studentId], Immutable.fromJS(admissions));
  },
  [DELETE_USER_ADMISSION_RESULT_SUCCESS]: (state, { payload: { id, studentId } }) => {
    return state.updateIn(['users', studentId], (results) => {
      const index = results.findIndex((result) => {
        return result.get('id') === id;
      });
      return results.deleteIn([index]);
    });
  },
});

export function addUserAdmissionResultSuccess(payload) {
  return { type: ADD_USER_ADMISSION_SUCCESS, payload };
}

export function updateUserAdmissionResultSuccess(payload) {
  return { type: UPDATE_USER_ADMISSION_SUCCESS, payload };
}

export function deleteUserAdmissionResultSuccess(payload) {
  return { type: DELETE_USER_ADMISSION_RESULT_SUCCESS, payload };
}

export function fetchUserAdmissionResultsSuccess(payload) {
  return { type: FETCH_USER_ADMISSION_RESULTS_SUCCESS, payload };
}

export function addUserAdmissionResult(results) {
  return (dispatch) =>
    admissionService
      .addUserAdmissionResult(results)
      .then(({ createAdmission }) => {
        dispatch(formSuccess(toasterMessages.admissionResultAdded()));
        dispatch(addUserAdmissionResultSuccess({ createAdmission }));
      })
      .catch(() => dispatch(formFail(toasterMessages.admissionResultNotAdded())));
}

export function updateUserAdmissionResult(results) {
  return (dispatch) =>
    admissionService
      .updateUserAdmissionResult(results)
      .then(({ updateAdmission }) => {
        dispatch(formSuccess(toasterMessages.admissionResultUpdated()));
        dispatch(updateUserAdmissionResultSuccess({ updateAdmission }));
      })
      .catch(() => dispatch(formFail(toasterMessages.admissionResultNotUpdated())));
}

export function deleteUserAdmissionResult(id, studentId) {
  return (dispatch) =>
    admissionService
      .deleteUserAdmissionResult(id)
      .then(() => {
        dispatch(deleteUserAdmissionResultSuccess({ id, studentId }));
        dispatch(formSuccess(ADMISSION_RESULT_DELETED));
      })
      .catch(() => dispatch(formFail(ADMISSION_RESULT_NOT_DELETED)));
}

export function fetchUserAdmissionResults(studentId) {
  return (dispatch) =>
    admissionService
      .fetchUserAdmissionResults(studentId)
      .then(({ admissions }) => dispatch(fetchUserAdmissionResultsSuccess({ admissions, studentId })))
      .catch(() => dispatch(formFail(toasterMessages.admissionResultNotFetched())));
}
