import React from 'react';
import PropTypes from 'prop-types';

export default function Robot({ className }) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 61 61">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#4f6987"
          d="M58.241 43.572c0 1.654-1.346 3-3 3s-3-1.346-3-3v-23h3c1.654 0 3 1.346 3 3v20zm-8 6c0 1.654-1.346 3-3 3h-34c-1.654 0-3-1.346-3-3v-29h40v29zm-5 7.5c0 .827-.673 1.5-1.5 1.5s-1.5-.673-1.5-1.5v-2.5h3v2.5zm-27 0c0 .827-.673 1.5-1.5 1.5s-1.5-.673-1.5-1.5v-2.5h3v2.5zm-13-10.5c-1.654 0-3-1.346-3-3v-20c0-1.654 1.346-3 3-3h3v23c0 1.654-1.346 3-3 3zm21-28h8v-2h-8v2zm-3-16h14c6.831 0 12.45 5.297 12.962 12H10.279c.512-6.703 6.13-12 12.962-12zm32 16h-19v-2h15a1 1 0 0 0 1-1c0-8.271-6.729-15-15-15h-14c-8.271 0-15 6.729-15 15a1 1 0 0 0 1 1h15v2h-19c-2.757 0-5 2.243-5 5v20c0 2.757 2.243 5 5 5 1.13 0 2.162-.391 3-1.026v2.026c0 2.757 2.243 5 5 5v2.5c0 1.93 1.57 3.5 3.5 3.5s3.5-1.57 3.5-3.5v-2.5h20v2.5c0 1.93 1.57 3.5 3.5 3.5s3.5-1.57 3.5-3.5v-2.5c2.757 0 5-2.243 5-5v-2.026a4.948 4.948 0 0 0 3 1.026c2.757 0 5-2.243 5-5v-20c0-2.757-2.243-5-5-5z"
        />
        <path
          fill="#cd4b53"
          d="M26.241 9.572c0 .551-.449 1-1 1-.551 0-1-.449-1-1v-1c0-.551.449-1 1-1 .551 0 1 .449 1 1v1zm-1-4c-1.654 0-3 1.346-3 3v1c0 1.654 1.346 3 3 3s3-1.346 3-3v-1c0-1.654-1.346-3-3-3zM36.241 9.572c0 .551-.449 1-1 1-.551 0-1-.449-1-1v-1c0-.551.449-1 1-1 .551 0 1 .449 1 1v1zm-1-4c-1.654 0-3 1.346-3 3v1c0 1.654 1.346 3 3 3s3-1.346 3-3v-1c0-1.654-1.346-3-3-3z"
        />
        <path
          fill="#4f6987"
          d="M39.241 41.572c0 1.654-1.346 3-3 3h-12c-1.654 0-3-1.346-3-3v-13c0-1.654 1.346-3 3-3h12c1.654 0 3 1.346 3 3v13zm-3-18h-12c-2.757 0-5 2.243-5 5v13c0 2.757 2.243 5 5 5h12c2.757 0 5-2.243 5-5v-13c0-2.757-2.243-5-5-5z"
        />
        <path
          fill="#4f6987"
          d="M25.241 36.572h10v-7h-10v7zm11-9h-12a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-9a1 1 0 0 0-1-1z"
        />
        <path
          fill="#cd4b53"
          d="M23.241 42.572h2v-2h-2zM27.241 42.572h2v-2h-2zM31.241 42.572h2v-2h-2zM35.241 42.572h2v-2h-2zM23.241 50.572h6v-2h-6zM31.241 50.572h6v-2h-6z"
        />
      </g>
    </svg>
  );
}

Robot.propTypes = {
  className: PropTypes.string,
};
