import React, { useState, useEffect } from 'react';
import { ColorBlockContainer } from './style';
const COLORS = ['#FDAA02', '#3F8CFF'];

const ColorBlock = () => {
  const [color, setColor] = useState('');
  useEffect(() => {
    const randomIdx = Math.round(Math.random() * (COLORS.length - 1));
    setColor(COLORS[randomIdx]);
  }, []);
  return (
    <ColorBlockContainer>
      <div style={{ backgroundColor: color }} />
    </ColorBlockContainer>
  );
};

export default ColorBlock;
