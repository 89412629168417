export const threadStatus = {
  LOADING: 'LOADING',
  FAILED: 'FAILED',
  EMPTY: 'EMPTY',
  SUCCESS: 'SUCCESS',
};

export const messageStatus = {
  SENDING: 'SENDING',
  SENT: 'SENT',
  ERROR: 'ERROR',
  TIM_ERROR: 'TIM_ERROR',
};

export const messageTypes = {
  TEXT: 'TEXT',
  FILE: 'FILE',
  IMAGE: 'IMAGE',
  BOT_INVITE: 'BOT_INVITE',
  BOT_REMOVE: 'BOT_REMOVE',
};
