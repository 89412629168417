import { compose } from 'redux';
import { connect } from 'react-redux';
import { uploadFile, downloadMessageFile } from 'ducks/file';
import { PermissionTypes } from '@crimson-education/common-config';
import AuthorisationCheck from 'components/wrappers/AuthorisationCheck';
import { unwrapImmutable } from 'utils/utils';
import { withAppContext } from 'components/enhancers/AppContext';
import {
  getThreadName,
  getActiveThread,
  getOtherThreadParticipants,
  getOtherTypingThreadParticipants,
  getMessagesGroupedByDay,
  sendMessage,
  retryMessage,
  fetchMessages,
  consumeThread,
  updateTypingStatus,
  getLastSeenMessageId,
  messageConsumed,
  sendFileError,
  sendMessageError,
  getLatestMessageStatus,
  getThreadGroupName,
  editGroupName,
  withdrawMessage,
  addMorePeople,
  removePeopleFromGroup,
  getAllThreadActiveParticipants,
} from 'ducks/messaging';
import { getLoginUser } from 'selectors/user';
import { formSuccess } from 'ducks/meta';
import { getFilesUploaded } from 'selectors/meta';
import { fetchPeopleForBookingWith } from 'ducks/user';
import FEEDBACK_RECEIVED from 'constants/botText';
import { withFeatureFlag } from 'utils/withFeatureFlag';

import MessageBar from './MessageBar';

export const VIEW_MESSAGES_PERIMSSION = PermissionTypes.VIEW_MESSAGES;

const withAuthorisation = AuthorisationCheck(VIEW_MESSAGES_PERIMSSION);

const mapStateToProps = (state, props) => {
  const { location } = props;

  const skeleton = location.pathname === '/messages';
  const isNew = location.pathname.endsWith('new');
  const thread = unwrapImmutable(getActiveThread(state));
  const threadId = thread ? thread.id : undefined;

  return {
    skeleton,
    isNew,
    thread,
    messages: unwrapImmutable(getMessagesGroupedByDay(threadId)(state)),
    currentUser: unwrapImmutable(getLoginUser(state)),
    threadName: getThreadName(state, threadId),
    otherParticipants: unwrapImmutable(getOtherThreadParticipants(threadId)(state)),
    otherTypingParticipants: unwrapImmutable(getOtherTypingThreadParticipants(threadId)(state)),
    lastSeenMessageId: getLastSeenMessageId(state, threadId),
    getLatestMessageStatus: getLatestMessageStatus(threadId)(state),
    chatName: getThreadGroupName(threadId)(state),
    uploadedFile: getFilesUploaded(state),
    allActiveParticipants: unwrapImmutable(getAllThreadActiveParticipants(threadId)(state)),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadMessages: (threadId, source) => dispatch(fetchMessages(threadId, source)),
  sendMessage: (threadId, senderId, message, messageType, messageMetadata, conversationId, percent, transfer) =>
    dispatch(
      sendMessage(
        ownProps.history,
        threadId,
        senderId,
        message,
        messageType,
        messageMetadata,
        conversationId,
        percent,
        transfer,
      ),
    ),
  retrySendMessage: (id) => dispatch(retryMessage(ownProps.history, id)),
  onBotMessage: () => dispatch(formSuccess(FEEDBACK_RECEIVED)),
  fetchBookingWith: (filter, pagination) => dispatch(fetchPeopleForBookingWith(filter, pagination)),
  consumeThread: (threadId) => dispatch(consumeThread(threadId)),
  updateTypingStatus: (userId, threadId) => dispatch(updateTypingStatus(userId, threadId)),
  messageConsumed: (data) => dispatch(messageConsumed(data)),
  sendFileError: (conversationId) => dispatch(sendFileError(conversationId)),
  sendMessageError: (conversationId, messageId, err) => dispatch(sendMessageError(conversationId, messageId, err)),
  editGroupName: (threadId, chatName) => dispatch(editGroupName(threadId, chatName)),
  withdrawMessage: (messageId, call) => dispatch(withdrawMessage(messageId, call)),
  uploadFile: (file, tag, refType, threadId) => dispatch(uploadFile(file, tag, refType, threadId)),
  downloadMessageFile: (fileId, source) => dispatch(downloadMessageFile(fileId, source)),
  addMorePeople: (userIds, threadId) => dispatch(addMorePeople(userIds, threadId)),
  removePeopleFromGroup: (userId, threadId, currentUserId) =>
    dispatch(removePeopleFromGroup(ownProps.history, userId, threadId, currentUserId)),
});

export default compose(
  withAuthorisation,
  connect(mapStateToProps, mapDispatchToProps),
)(withAppContext(withFeatureFlag(['FLAG_STUDENT_CENTER_TAB'], MessageBar)));
