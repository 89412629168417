import { useEffect } from 'react';
import { useStaffDashboardContext } from '../helper';
import { StaffDashboardRegion } from '../type';

const EmptyHookComp = () => {
  const { state, controller } = useStaffDashboardContext();
  const { applications, approvals, sessionReports, students, alerts, today } = state;
  // initial query
  useEffect(() => {
    controller.queryData();
  }, [controller]);
  // calculate alerts
  useEffect(() => {
    if (!applications.length && !approvals.length && !sessionReports.length && !students.length) return;
    // setTimeout on purpose, start calculation after state updated
    setTimeout(controller.calculateAlerts);
    setTimeout(controller.calculateSchoolSelectionData);
    setTimeout(controller.calculateMilestoneMeetingData);
  }, [controller, applications, approvals, sessionReports, students, today]);
  // group alerts by student user ids
  // { US: {[userId]: [alertValues]} }
  useEffect(() => {
    const userIdAlertsMap: Record<StaffDashboardRegion, Record<string, string[]>> = {
      US: {},
      UK: {},
      EU: {},
    };
    Object.entries(alerts).map(([region, value]) => {
      userIdAlertsMap[region as StaffDashboardRegion] = {};
      value.forEach((alert) => {
        alert.userIds.forEach((userId) => {
          if (!userIdAlertsMap[region as StaffDashboardRegion][userId]) {
            userIdAlertsMap[region as StaffDashboardRegion][userId] = [];
          }
          userIdAlertsMap[region as StaffDashboardRegion][userId].push(alert.value);
        });
      });
    });
    controller.setUserAlertsMap(userIdAlertsMap);
  }, [alerts, controller]);
  return null;
};

export default EmptyHookComp;
