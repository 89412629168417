import styled from 'styled-components';

export const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  font-family: 'Montserrat';
  line-height: 22px;
  color: #1f2937;
  display: flex;
  flex: 1;
  box-sizing: border-box;
  margin-right: 10px;
`;

export const StyledImg = styled.img`
  width: 36px;
  margin: 0 20px 0 16px;
  background-size: contain;
  object-fit: contain;
`;
