import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ModalHeader } from 'components/atoms/typography';
import IconButton from 'components/molecules/IconButton';
import Button from 'components/molecules/Button';

import css from './style.scss';

export default class ProfileSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isEditMode: false,
      showEditButton: false,
    };

    this.toggleEditMode = this.toggleEditMode.bind(this);
    this.onSave = this.onSave.bind(this);
    this.showEditButton = this.showEditButton.bind(this);
    this.hideEditButton = this.hideEditButton.bind(this);
  }

  onSave() {
    this.toggleEditMode();
    this.props.onSave && this.props.onSave();
  }

  toggleEditMode() {
    const { canEdit } = this.props;
    const { isEditMode } = this.state;
    if (canEdit) {
      this.setState({
        isEditMode: !isEditMode,
        showEditButton: false,
      });
    }
  }

  showEditButton() {
    const { canEdit } = this.props;
    if (canEdit) this.setState({ showEditButton: true });
  }

  hideEditButton() {
    this.setState({ showEditButton: false });
  }

  render() {
    const { title, displayComponent, editComponent, canEdit, highlightOnHover } = this.props;

    const { isEditMode, showEditButton } = this.state;

    return (
      <div
        className={classNames(css.wrapper, { [css.wrapperCanEdit]: canEdit || highlightOnHover })}
        onMouseOver={this.showEditButton}
        onMouseLeave={this.hideEditButton}
        onFocus={this.showEditButton}
        onBlur={this.hideEditButton}
      >
        <div className={css.mainSection}>
          <div className={css.header}>
            <ModalHeader className={css.temporaryConsistencyStylingTitle}>{title}</ModalHeader>
            {canEdit && !isEditMode && showEditButton && (
              <IconButton icon="Edit" className={css.editButton} onClick={this.toggleEditMode} />
            )}
          </div>
          <div className={css.content}>
            {isEditMode ? (
              <div>
                {editComponent}
                <div className={css.editModeFooter}>
                  <Button secondary onClick={this.toggleEditMode}>
                    Cancel
                  </Button>
                  <div className={css.buttonSpacer} />
                  <Button onClick={this.onSave}>Save</Button>
                </div>
              </div>
            ) : (
              displayComponent
            )}
          </div>
        </div>
      </div>
    );
  }
}

ProfileSection.defaultProps = {
  canEdit: false,
};

ProfileSection.propTypes = {
  title: PropTypes.string.isRequired,
  displayComponent: PropTypes.node.isRequired,
  editComponent: PropTypes.node,
  canEdit: PropTypes.bool,
  highlightOnHover: PropTypes.bool,
  onSave: PropTypes.func,
};
