import React from 'react';
import {
  Container,
  BackgroundImg,
  Title,
  Content,
  TextContainer,
  Graph4,
  PeopleImg,
  Graph1,
  Graph2,
  Graph3,
} from './index.style';

const BANNER_GRAPH4 = '/static/applicationTracker/bannerGraph4.png';
const BANNER_GRAPH3 = '/static/applicationTracker/bannerGraph3.png';
const BANNER_PEOPLE = '/static/applicationTracker/bannerPeople.png';
const BANNER_BACKGROUND = '/static/applicationTracker/bannerBackground.png';

const Banner = () => {
  return (
    <Container>
      <BackgroundImg src={BANNER_BACKGROUND} />
      <PeopleImg src={BANNER_PEOPLE} />
      {/* purple ripple */}
      <Graph4 src={BANNER_GRAPH4} />
      {/* wave */}
      <Graph3 src={BANNER_GRAPH3} />
      {/* arrows */}
      <Graph1 />
      {/* dots */}
      <Graph2 />
      <TextContainer>
        <Title>{'Applications'}</Title>
        <Content>{'Manage your school list and track the application process easily.'}</Content>
      </TextContainer>
    </Container>
  );
};

export default Banner;
