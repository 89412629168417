import React from 'react';
import PropTypes from 'prop-types';
import RadioGroup from 'components/molecules/RadioGroup';
import RadioButton from 'components/molecules/RadioButton';
import { InputField } from 'components/generic/Forms';
import Select from 'react-select';
import ErrorIcon from '@material-ui/icons/Error';
import css from './styles.scss';

const customSelectStyles = {
  container: (provided) => ({
    ...provided,
    width: 183,
  }),
  control: (provided) => ({
    ...provided,
    border: 'solid 1px #babcc5',
    height: 42,
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: 4,
    width: 228,
  }),
};

function RecurringSession(props) {
  const {
    selectedType,
    setSelectedType,
    freqItems,
    errorMessage,
    remainingHours,
    setFrequency,
    setOccurrences,
    dataTestId,
    isEditMode,
    occurrences,
    frequency,
  } = props;

  const typeOptions = [
    { text: 'Individual Session', value: 'individual' },
    { text: 'Recurring Session', value: 'recurring' },
  ];

  return (
    <div className={css.recurringSessionRoot} style={{ display: isEditMode ? 'none' : 'block' }}>
      <div>Type</div>
      <RadioGroup
        className={css.rGroup}
        name="recurring-session"
        selectedValue={selectedType}
        onChange={(val) => {
          setSelectedType(val);
        }}
      >
        {typeOptions.map((option, i) => (
          <div className={css.rButtonContainer} key={`options_${i}`}>
            <RadioButton
              dataTestId={`${dataTestId}_${option.value}`}
              key={`options_${i}`}
              option={{ text: option.text, value: option.value }}
              isChecked={option.value === selectedType}
            />
          </div>
        ))}
      </RadioGroup>
      {selectedType === 'recurring' && (
        <div>
          <div className={css.recurringSettings}>
            <div className={css.recurringSettingItem}>
              <div className={css.settingLabel}>Frequency</div>
              <Select
                styles={customSelectStyles}
                options={freqItems}
                isSearchable={false}
                value={frequency}
                onChange={(item) => setFrequency(item)}
              />
            </div>
            <div className={css.recurringSettingItem} style={{ marginTop: 16 }}>
              <div className={css.settingLabel}>Ends After</div>
              <InputField
                dataTestId={`${dataTestId}_input`}
                placeholder="Input a number"
                type="number"
                className={css.occurrencesInput}
                onValueChange={(val) => setOccurrences(Number(val))}
              />
              Occurrences
            </div>
          </div>
          <div
            className={errorMessage?.length > 0 ? css.errorMessage : css.normalMessage}
            style={{ display: errorMessage || remainingHours !== null ? 'flex' : 'none', height: 15 }}
          >
            <ErrorIcon
              style={{
                fontSize: 'small',
                color: errorMessage ? 'var(--salmon)' : 'grey',
                marginRight: 4,
                height: '100%',
              }}
            />
            {errorMessage?.length > 0 ? errorMessage : `Current hours remaining: ${remainingHours}`}
          </div>
        </div>
      )}
    </div>
  );
}

RecurringSession.propTypes = {
  selectedType: PropTypes.string,
  setSelectedType: PropTypes.func,
  freqItems: PropTypes.array,
  setFrequency: PropTypes.func,
  setOccurrences: PropTypes.func,
  isEditMode: PropTypes.bool,
  remainingHours: PropTypes.any,
  errorMessage: PropTypes.string,
  dataTestId: PropTypes.string,
  occurrences: PropTypes.number,
  frequency: PropTypes.object,
};

export default RecurringSession;
