import React from 'react';
import { Row } from '../../index.style';
import { Option } from './index.style';

type BooleanSelectorProps = {
  value: boolean;
  onChange: (v: boolean) => void;
};

const BooleanSelector = ({ value, onChange }: BooleanSelectorProps) => {
  return (
    <Row rowcenter gap={'8px'}>
      <Option selected={value} onClick={() => onChange(true)}>
        {'Yes'}
      </Option>
      <Option selected={!value} onClick={() => onChange(false)}>
        {'No'}
      </Option>
    </Row>
  );
};

export default BooleanSelector;
