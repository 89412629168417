import React from 'react';
import Skeleton from 'antd/lib/skeleton';
import { Column } from '../../index.style';

const SchoolSelectionPanelLoadingView = () => {
  return (
    <Column gap={'25px'} style={{ marginTop: 30 }} columncenter>
      <Skeleton.Avatar style={{ alignSelf: 'center', marginBottom: 15 }} active size={180} shape={'circle'} />
      {new Array(5).fill(null).map((_, idx) => (
        <Skeleton
          style={{ marginBottom: 10 }}
          key={'schoolseletionpanel' + idx}
          active
          title={true}
          paragraph={false}
        />
      ))}
    </Column>
  );
};

export default SchoolSelectionPanelLoadingView;
