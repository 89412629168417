// https://redux.js.org/recipes/reducing-boilerplate#generating-reducers

export default function createReducer(initialState, handlers) {
  return function reducer(state = initialState, action) {
    const handler = action && action.type && handlers[action.type];
    if (handler) {
      return handler(state, action);
    }
    return state;
  };
}
