import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import css from './styles.scss';

export default function ToggleSwitch(props) {
  const { on, className = '', handleToggle, dataTestId, dataGaLabel, toggleOnClassname } = props;

  return (
    <button
      role="checkbox"
      data-ga-label={dataGaLabel}
      aria-checked={on}
      aria-label="Toggle"
      onClick={handleToggle}
      data-test-id={dataTestId}
      className={css.toggleContainer}
    >
      <span
        className={classnames(
          className,
          css.toggleButton,
          {
            [css.toggleButtonOn]: on,
          },
          on && toggleOnClassname,
        )}
      />
    </button>
  );
}

ToggleSwitch.propTypes = {
  on: PropTypes.bool.isRequired,
  handleToggle: PropTypes.func.isRequired,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  dataGaLabel: PropTypes.string,
  toggleOnClassname: PropTypes.string,
};
