import getGraphQLClient from '..';
import { gql } from '@apollo/client';
import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';
import { PurchaseItemRate } from 'types';

const { coreOmsConfig } = getConfig();
const client = getGraphQLClient(coreOmsConfig.graphqlEndpoint);

export const getRegionalRateCard = () => {
  const query = gql`
    query FetchRateCards {
      rateCard: fetchRegionalRateCard {
        currency
        price
      }
    }
  `;
  return client.query({ query });
};

export const purchaseAdditionalHours = (itemId: string, quantity: number, payerEmail: string, source: string) => {
  const mutation = gql`
    mutation PurchaseAdditionalHours(
      $itemId: String!
      $quantity: Int
      $payerEmail: String
      $source: OrderLineItemSource
    ) {
      order: createOrder(itemId: $itemId, quantity: $quantity, payerEmail: $payerEmail, source: $source) {
        status
      }
    }
  `;
  return client.mutate({ mutation, variables: { itemId, quantity, payerEmail, source } });
};

export const fetchUserOrders = (statuses: string[], sources: string[]) => {
  const query = gql`
    query QueryUserOrders($statuses: [OrderStatus], $sources: [OrderLineItemSource]) {
      orders: queryOrders(statuses: $statuses, sources: $sources) {
        id
        status
        createdAt
        lineItems {
          source
          itemId
          quantity
        }
      }
    }
  `;
  return client.query({ query, variables: { statuses, sources } });
};

interface IFetchProductsByTypeResponse {
  sourceId: string;
  source: string;
  name: string;
  imageUrl?: string;
  hasPendingOrder: boolean;
  rate: Omit<PurchaseItemRate, 'symbol'>;
  metadata: { name: string; type: string; value: string }[];
}
export const fetchProductsByType = (type: string) => {
  const query = gql`
    query FetchProductsByType($type: OrderLineItemSource!) {
      products: fetchProductsByType(type: $type) {
        sourceId
        source
        name
        imageUrl
        hasPendingOrder
        rate {
          price
          currency
        }
        metadata {
          name
          type
          value
        }
      }
    }
  `;
  return client.query<{ products: IFetchProductsByTypeResponse[] }>({ query, variables: { type } });
};

export const closeUserOrder = (orderId: string) => {
  const mutation = gql`
    mutation CloseOrder($orderId: String!) {
      closeOrder(orderId: $orderId) {
        success
      }
    }
  `;
  return client.mutate({ mutation, variables: { orderId } });
};
