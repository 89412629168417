import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';
import { gql } from '@apollo/client';
import { createClient } from '../utils';

const { api } = getConfig();

const client = createClient(`${api.endpoint}/graphql`);

export const setUserRoles = gql`
  mutation setUserRoles($userId: String!, $roles: [String]!, $primaryRole: String!) {
    setUserRoles(userId: $userId, roles: $roles)
    setUserPrimaryRole(userId: $userId, roleId: $primaryRole) {
      userRoles
    }
  }
`;

export const setUserPrimaryRole = gql`
  mutation setUserPrimaryRole($userId: String!, $primaryRole: String!) {
    setUserPrimaryRole(userId: $userId, roleId: $primaryRole) {
      userRoles
    }
  }
`;

export const isAccessibleByRole = gql`
  query isAccessibleByRole($targetUserIds: [String]!, $roleIds: [String]!) {
    isAccessibleByRole(targetUserIds: $targetUserIds, roleIds: $roleIds)
  }
`;

export const actionableRoles = gql`
  query actionableRoles($action: String!) {
    actionableRoles(action: $action) {
      id
      name
      description
    }
  }
`;

export default {
  setUserRoles: (userId, roles, primaryRole) => client.query(setUserRoles, { userId, roles, primaryRole }),
  setUserPrimaryRole: (userId, primaryRole) => client.query(setUserPrimaryRole, { userId, primaryRole }),
  isAccessibleByRole: (targetUserIds, roleIds) => client.query(isAccessibleByRole, { targetUserIds, roleIds }),
  /**
   * @param {import('@crimson-education/common-config/lib/authorization').PermissionAction} action
   * @returns {Promise<{id: string, name: string, description: string | undefined}[]>}
   */
  actionableRoles: (action) => client.query(actionableRoles, { action }),
};
