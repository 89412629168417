import React, { Component } from 'react';
import Header from 'components/molecules/Header';
import moment from 'moment';

import PropTypes from 'prop-types';
import { SubHeader } from 'components/atoms/typography';
import font from 'components/atoms/typography/font.scss';
import AutosaveTextArea from 'components/molecules/AutosaveTextArea';
import Tabs from 'components/molecules/Tabs';
import Caption from 'components/atoms/typography/Caption';
import css from './styles.scss';

const ratingTabs = ['Below average', 'Satisfactory', 'Good', 'Excellent', 'Outstanding'];

export default class ProgressReportSummary extends Component {
  componentDidMount() {
    const { report, reportId, fetchProgressReportById, canEdit, history } = this.props;

    if (!canEdit && history) {
      history.replace(`${window.location.pathname}/preview`);
    }

    if (!report && reportId) {
      fetchProgressReportById(reportId);
    }
  }

  onSuccessesUpdate = async (successes) => {
    const { updateProgressReport, reportId } = this.props;
    await updateProgressReport({ id: reportId, successes });
  };

  onChallengesUpdate = async (challenges) => {
    const { updateProgressReport, reportId } = this.props;
    await updateProgressReport({ id: reportId, challenges });
  };

  onScoreUpdate = async (score) => {
    const { updateProgressReport, reportId } = this.props;
    await updateProgressReport({ id: reportId, score });
  };

  render() {
    const { report, student, canEdit } = this.props;

    if (!canEdit) {
      return null;
    }

    let period;
    if (report) {
      if (moment(report.startDate).isSame(report.endDate, 'year')) {
        period = `${moment(report.startDate).format('DD MMM')} - ${moment(report.endDate).format('DD MMM YYYY')}`;
      } else {
        period = `${moment(report.startDate).format('DD MMM YYYY')} - ${moment(report.endDate).format('DD MMM YYYY')}`;
      }
    }

    const descriptionText =
      'Once the summary has been completed, you can see a full preview of the student\'s progress report by clicking on the "Preview report" button. That will be the final version sent to the student\'s key contacts.';
    return (
      <div>
        {report && (
          <div>
            <Header
              title={period}
              isBackButtonVisible
              backButtonText="Reports"
              backButtonLink={`/users/${student.get('userId')}/reports`}
              actionButtons={[
                {
                  buttonLink: `/users/${student.get('userId')}/reports/${report.id}/preview`,
                  buttonText: 'Preview report',
                },
              ]}
            />
            <div className={css.container}>
              <SubHeader>
                {`This is a progress report for ${student.get('firstName')} ${student.get(
                  'lastName',
                )} for the ${period} period.`}
              </SubHeader>
              <div className={css.rating}>
                <Caption>
                  How is {student.get('firstName')} performing against their primary and secondary goals?
                </Caption>
                <Tabs onTabChange={this.onScoreUpdate} tabs={ratingTabs} defaultActive={report.score} />
              </div>
              <AutosaveTextArea
                caption={"Please describe the student's success"}
                placeholder="Highlight accomplishments in this past period facilitating achievement of primary and secondary goals."
                value={report.successes}
                updatedAt={report.updatedAt}
                onUpdateValue={this.onSuccessesUpdate}
              />
              <AutosaveTextArea
                caption={"Please describe the student's challenges"}
                placeholder="Highlight difficulties in this past period preventing achievement of primary and secondary goals."
                value={report.challenges}
                updatedAt={report.updatedAt}
                onUpdateValue={this.onChallengesUpdate}
              />
              <div className={css.description}>
                <span className={font.body}>{descriptionText}</span>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

ProgressReportSummary.propTypes = {
  student: PropTypes.object,
  report: PropTypes.object,
  reportId: PropTypes.string.isRequired,
  fetchProgressReportById: PropTypes.func.isRequired,
  updateProgressReport: PropTypes.func,
  canEdit: PropTypes.bool,
  history: PropTypes.object,
};

ProgressReportSummary.defaultProps = {
  canEdit: true,
};
