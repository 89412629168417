import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Person from '../Person';

import css from './styles.scss';

export default function SelectedPeople(props) {
  const { people, togglePersonSelected, canSelectMorePeople } = props;

  return (
    <div className={css.selectedPeople}>
      {people &&
        people.map((person) => {
          return (
            <Person
              key={person.get('userId')}
              person={person}
              selected
              togglePersonSelected={togglePersonSelected}
              canSelectMorePeople={canSelectMorePeople}
            />
          );
        })}
    </div>
  );
}

SelectedPeople.propTypes = {
  people: ImmutablePropTypes.list.isRequired,
  togglePersonSelected: PropTypes.func.isRequired,
  canSelectMorePeople: PropTypes.bool.isRequired,
};
