import { DateTime } from 'luxon';

export const parseDateRangeString = (start?: string, end?: string): string | undefined => {
  if (!start && !end) return;
  const startDate = start ? DateTime.fromFormat(start, 'yyyy-MM-dd') : null;
  const endDate = end ? DateTime.fromFormat(end, 'yyyy-MM-dd') : null;
  if (startDate && endDate) {
    return `${startDate.toFormat('MMM dd')} - ${endDate.toFormat('dd, yyyy')}`;
  }
  if (startDate) {
    return startDate.toFormat('MMM dd, yyyy');
  }
  if (endDate) {
    return endDate.toFormat('MMM dd, yyyy');
  }
};
