import styled from 'styled-components';
export { Row, Column } from '../index.style';
export const RootContainer = styled.div`
  * {
    margin: 0;
    padding: 0;
    font-family: 'Montserrat';
    font-style: normal;
    box-sizing: border-box;
  }
  padding: 40px 60px 100px;
  background: #f3f6fa;

  scrollbar-gutter: stable;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const Board1Container = styled.div`
  display: grid;
  gap: 12px 16px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 4fr 5fr;
  height: 555px;
  box-sizing: border-box;

  > div:nth-child(-n + 2) {
    grid-area: 1 / auto / span 2 / span 1;
  }

  > div:nth-child(3) {
    grid-area: 1 / auto / span 1 / span 1;
  }

  > div:nth-child(4) {
    grid-area: 2 / auto / span 1 / span 1;
  }
`;

export const HelloStaffText = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
`;

export const HaveANiceDay = styled.div`
  font-family: 'Patrick Hand';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #fdaa02;
`;
