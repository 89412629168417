import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactModal from 'react-modal';

import { ModalHeader } from 'components/atoms/typography';
import IconButton from 'components/molecules/IconButton';
import Button from 'components/molecules/Button';

import ModalAlert from 'components/molecules/ModalAlert';

import css from './style.scss';

export default class MobileModal extends Component {
  render() {
    const {
      isOpen,
      onClose,
      onSubmit,
      onSubmitDataTestId,
      onSecondarySubmit,
      onSecondarySubmitDataTestId,
      className,
      children,
      title,
      submitText,
      secondarySubmitText,
      isSubmitDisabled,
      enableOverFlow,
      overlayClassName,
      onTertiarySubmit,
      tertiarySubmitText,
      alert,
      renderHeaderImage,
      dataTestId,
    } = this.props;

    return (
      isOpen && (
        <ReactModal
          data={{
            'data-test-id': dataTestId,
          }}
          isOpen
          onRequestClose={onClose}
          className={classNames(css.content, className)}
          overlayClassName={classNames(css.overlay, overlayClassName)}
          shouldCloseOnOverlayClick={false}
          contentRef={(node) => {
            if (node) node.setAttribute('data-ga-category', `modal - ${title}`);
          }}
        >
          {onClose && (
            <IconButton
              icon="Close"
              onClick={onClose}
              dataTestId="closeModalIcon"
              className={classNames(css.closeAction, {
                [css.hideClose]: !onClose,
                [css.withHeaderImage]: renderHeaderImage,
              })}
            />
          )}

          {!renderHeaderImage && (
            <div className={css.header}>
              <ModalHeader className={css.title}>{title}</ModalHeader>
            </div>
          )}

          {renderHeaderImage && <div className={css.headerImage}>{renderHeaderImage()}</div>}

          <div
            className={classNames(
              css.main,
              { [css.enableOverFlow]: enableOverFlow },
              { [css.noFooter]: !onSubmit },
              { [css.withHeaderImage]: renderHeaderImage },
            )}
          >
            {alert && Object.keys(alert).length && (
              <ModalAlert message={alert.message} icon={alert.icon} className={alert.className} />
            )}
            <div className={css.mainContent}>{children}</div>
          </div>

          {onSubmit && (
            <div className={classNames(css.footer, css.buttonGroup, { [css.withHeaderImage]: renderHeaderImage })}>
              {onTertiarySubmit && (
                <Button
                  secondary
                  dataTestId="tertiaryModalButton"
                  onClick={onTertiarySubmit}
                  className={css.tertiaryButton}
                >
                  {tertiarySubmitText}
                </Button>
              )}
              {onSecondarySubmit && (
                <Button
                  secondary
                  dataTestId={onSecondarySubmit ? onSecondarySubmitDataTestId : 'closeModalButton'}
                  onClick={onSecondarySubmit || onClose}
                >
                  {secondarySubmitText || 'Close'}
                </Button>
              )}
              <Button
                dataTestId={onSubmitDataTestId}
                className={css.footerButton}
                onClick={onSubmit}
                disabled={isSubmitDisabled}
              >
                {submitText}
              </Button>
            </div>
          )}
        </ReactModal>
      )
    );
  }
}

MobileModal.defaultProps = {
  submitText: 'Submit',
};

MobileModal.propTypes = {
  dataTestId: PropTypes.string,
  isOpen: PropTypes.bool,
  children: PropTypes.node,

  className: PropTypes.string,
  overlayClassName: PropTypes.string,

  title: PropTypes.string,

  enableOverFlow: PropTypes.bool,

  onClose: PropTypes.func,

  onSubmit: PropTypes.func,
  submitText: PropTypes.string,
  onSubmitDataTestId: PropTypes.string,
  isSubmitDisabled: PropTypes.bool,

  onSecondarySubmit: PropTypes.func,
  secondarySubmitText: PropTypes.string,
  onSecondarySubmitDataTestId: PropTypes.string,

  onTertiarySubmit: PropTypes.func,
  tertiarySubmitText: PropTypes.string,

  alert: PropTypes.shape({
    message: PropTypes.string,
    icon: PropTypes.string,
    className: PropTypes.string,
  }),
  renderHeaderImage: PropTypes.func,
};
