import Immutable from 'immutable';
import createReducer from 'utils/createReducer';
import userAPI from 'graphql/api/user';
import { getNewUserCountFilters } from 'utils/ourPeople';
import { ADD_ENTITIES, addAggregate, addEntity, compose } from 'ducks/normalizr';
import { formLoading, formLoaded, formFail } from 'ducks/meta';
import { UserCountMap } from 'constants/ourPeople';
import { getMetaItem } from 'selectors/meta';
import componentKeys from 'constants/componentKeys';

/**
 * Aggregate Duck
 * Stores aggregate information about other entities, such as counts
 * of users of a specific type stored in the server database.
 */

const initialState = new Immutable.Map();

export default createReducer(initialState, {
  [ADD_ENTITIES]: (state, action) => {
    return state.mergeDeep(action.payload.aggregate);
  },
});

export function queryUserCount(params, type, user) {
  return (dispatch, getState) => {
    dispatch(formLoading());
    const viewableRoles = getMetaItem(componentKeys.VIEWABLE_ROLES)(getState());

    return userAPI
      .queryUsers(params, getNewUserCountFilters(type, user, viewableRoles || []))
      .then((response) => {
        const count = response.queryUsers.pagination.totalCount || 0;
        const aggregateKey = UserCountMap[type].key;
        dispatch(compose(addEntity(), addAggregate(aggregateKey, count)));
        return dispatch(formLoaded());
      })
      .catch((error) => dispatch(formFail(error.message)));
  };
}
