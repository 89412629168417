import React from 'react';
import PropTypes from 'prop-types';
import colours from 'components/atoms/colours.scss';
import Select from '../Select';

import MultiValueRemove from './MultiValueRemove';

const selectStyles = {
  multiValueRemove: (base, state) => ({
    ...base,
    color: state.isDisabled ? colours.hint : colours.primary,
    background: colours.background,
    borderRadius: '20rem',
    display: 'flex',
    justifyContent: 'center',
    width: '2.6rem',
    '&:hover': {
      color: colours.primary,
      background: colours.backgroundHover,
    },
  }),
  multiValue: (base) => ({
    ...base,
    borderRadius: '20rem',
    height: '2.6rem',
    background: colours.background,
  }),
  multiValueLabel: (base, state) => ({
    color: state.isDisabled ? colours.hint : colours.primary,
    display: 'flex',
    alignItems: 'center',
    padding: '0 1rem',
  }),
};

export default function MultiSelect(props) {
  const { components, styles, isClearable } = props;

  const componentOverrides = Object.assign(
    {},
    {
      MultiValueRemove,
    },
    components,
  );

  const styleOverrides = Object.assign({}, selectStyles, styles);

  return (
    <Select {...props} isMulti components={componentOverrides} styles={styleOverrides} isClearable={isClearable} />
  );
}

MultiSelect.defaultProps = {
  isClearable: true,
};

MultiSelect.propTypes = {
  styles: PropTypes.object,
  components: PropTypes.object,
  isClearable: PropTypes.bool,
};
