import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import { compose } from 'redux';
import { connect } from 'react-redux';
import { Acl, permissionTypes } from '@crimson-education/common-config';
import { withAppContext } from 'components/enhancers/AppContext';
import { updateInvoiceFilter } from 'ducks/invoicing';
import { getFilterableBillingCountries, getFilterableTutors, getFilterablePeriods } from 'selectors/invoicing';
import { getLoginUser } from 'selectors/user';
import { getInvoiceFilter } from 'selectors/meta';
import invoiceStatus from 'constants/invoiceStatus';
import { formatStatusLabel, formatPeriodValue } from 'utils/invoiceUtils';
import AuthorisationCheck from 'components/wrappers/AuthorisationCheck';
import InvoiceFilters from './InvoiceFilters';

class InvoiceFiltersContainer extends Component {
  static getStatusOptions() {
    return Object.keys(invoiceStatus).map((status) => {
      return { value: status, label: formatStatusLabel(status) };
    });
  }

  static canViewTutorFilter(userRoles) {
    return Acl.isSuperAdmin(userRoles) || Acl.isAccounts(userRoles);
  }

  constructor(props) {
    super(props);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleFilterReset = this.handleFilterReset.bind(this);
  }

  getCountriesOptions() {
    const { filterableCountries } = this.props;

    return filterableCountries.map(({ name }) => ({ value: name, label: name }));
  }

  getTutorsOptions() {
    const { filterableTutors } = this.props;

    return filterableTutors.map(({ userId, firstName, lastName }) => ({
      value: userId,
      label: `${firstName} ${lastName}`,
    }));
  }

  getPeriodsOptions() {
    const { filterablePeriods, loginUser } = this.props;

    const timezone = loginUser.get('timezone');

    return filterablePeriods.map((period) => ({
      value: period,
      label: `${formatPeriodValue(period.periodStart, timezone)} - ${formatPeriodValue(period.periodEnd, timezone)}`,
    }));
  }

  handleFilterReset() {
    const { updateFilter } = this.props;

    updateFilter({});
  }

  handleFilterChange(key, filter) {
    const { updateFilter, filter: oldFilter } = this.props;

    let newFilter = oldFilter;

    if (filter === null) {
      // Remove filter
      delete newFilter[key];
    } else {
      // Add filter
      newFilter = {
        ...oldFilter,
        [key]: filter,
      };
    }

    updateFilter(newFilter);
  }

  render() {
    const { filter, loginUser } = this.props;

    const countries = this.getCountriesOptions();
    const tutors = this.getTutorsOptions();
    const periods = this.getPeriodsOptions();
    const statuses = InvoiceFiltersContainer.getStatusOptions();

    return (
      <InvoiceFilters
        {...this.props}
        showTutorFilter={InvoiceFiltersContainer.canViewTutorFilter(loginUser.get('userRoles'))}
        countries={this.getCountriesOptions()}
        tutors={this.getTutorsOptions()}
        periods={this.getPeriodsOptions()}
        activeFilter={filter}
        handleChange={this.handleFilterChange}
        handleReset={this.handleFilterReset}
        filters={{
          statuses,
          countries,
          tutors,
          periods,
        }}
      />
    );
  }
}

InvoiceFiltersContainer.propTypes = {
  loginUser: PropTypes.object,
  filter: PropTypes.object.isRequired,
  updateFilter: PropTypes.func.isRequired,
  filterableCountries: PropTypes.array,
  filterableTutors: PropTypes.array,
  filterablePeriods: PropTypes.array,
};

const mapStateToProps = (state) => ({
  loginUser: getLoginUser(state),
  filter: getInvoiceFilter(state),
  filterableCountries: getFilterableBillingCountries(state),
  filterableTutors: getFilterableTutors(state),
  filterablePeriods: getFilterablePeriods(state),
});

const mapDispatchToProps = (dispatch) => ({
  updateFilter: (filter) => dispatch(updateInvoiceFilter(filter)),
  handleFilterReset: (filter) => dispatch(updateInvoiceFilter(filter)),
});

const withAuthorisation = AuthorisationCheck(permissionTypes.VIEW_INVOICE);

export default withAuthorisation(connect(mapStateToProps, mapDispatchToProps)(withAppContext(InvoiceFiltersContainer)));
