/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

export default function NoDocument({ className }) {
  const style = {
    mixblendMode: 'multiply',
  };
  
  return (
    <svg className={className} width="99px" height="79px" viewBox="0 0 99 79" version="1.1">
      <g id="Flow" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="create-new-copy" transform="translate(-744.000000, -354.000000)">
          <g id="empty-state-/-1-line-header" transform="translate(603.000000, 285.000000)">
            <g id="illustration-/-file-uploader" transform="translate(116.000000, 0.000000)">
              <g id="Asset-342@1x" transform="translate(25.000000, 68.000000)">
                <rect id="Rectangle" fill="#DDA75F" x="0" y="20.6796117" width="23.2038835" height="23.2330097" rx="4.4"></rect>
                <path d="M35.310873,9.44857175 L85.734174,9.44857175 C88.1642269,9.44857175 90.134174,11.4185189 90.134174,13.8485717 L90.134174,67.9126494 C90.134174,70.3427023 88.1642269,72.3126494 85.734174,72.3126494 L35.310873,72.3126494 C32.8808201,72.3126494 30.910873,70.3427023 30.910873,67.9126494 L30.910873,13.8485717 C30.910873,11.4185189 32.8808201,9.44857175 35.310873,9.44857175 Z" id="Rectangle" fill="#DADAE1" transform="translate(60.522524, 40.880611) rotate(22.100000) translate(-60.522524, -40.880611) "></path>
                <path d="M66.6599423,41.1072062 L76.7919811,41.1072062 C79.222034,41.1072062 81.1919811,43.0771533 81.1919811,45.5072062 L81.1919811,53.1052644 C81.1919811,55.5353173 79.222034,57.5052644 76.7919811,57.5052644 L66.6599423,57.5052644 C64.2298894,57.5052644 62.2599423,55.5353173 62.2599423,53.1052644 L62.2599423,45.5072062 C62.2599423,43.0771533 64.2298894,41.1072062 66.6599423,41.1072062 Z" id="Rectangle" fill="#A5A7AF" transform="translate(71.725962, 49.306235) rotate(22.100000) translate(-71.725962, -49.306235) "></path>
                <path d="M60.9091158,58.4810943 L71.0411546,58.4810943 C73.4712075,58.4810943 75.4411546,60.4510414 75.4411546,62.8810943 L75.4411546,64.4014826 C75.4411546,66.8315355 73.4712075,68.8014826 71.0411546,68.8014826 L60.9091158,68.8014826 C58.4790629,68.8014826 56.5091158,66.8315355 56.5091158,64.4014826 L56.5091158,62.8810943 C56.5091158,60.4510414 58.4790629,58.4810943 60.9091158,58.4810943 Z" id="Rectangle" fill="#BABCC4" transform="translate(65.975135, 63.641288) rotate(22.100000) translate(-65.975135, -63.641288) "></path>
                <path d="M49.5246856,14.6660511 L64.6800254,14.6660511 C65.9374116,14.6660511 66.9567244,15.6853639 66.9567244,16.9427501 L66.9567244,16.9427501 C66.9567244,18.2001362 65.9374116,19.2194491 64.6800254,19.2194491 L49.5246856,19.2194491 C48.2672995,19.2194491 47.2479866,18.2001362 47.2479866,16.9427501 L47.2479866,16.9427501 C47.2479866,15.6853639 48.2672995,14.6660511 49.5246856,14.6660511 Z" id="Rectangle" fill="#A5A7AF" transform="translate(57.102356, 16.942750) rotate(22.100000) translate(-57.102356, -16.942750) "></path>
                <path d="M71.6997779,21.4273179 L75.9813313,21.4273179 C77.2387174,21.4273179 78.2580303,22.4466308 78.2580303,23.704017 L78.2580303,23.704017 C78.2580303,24.9614031 77.2387174,25.980716 75.9813313,25.980716 L71.6997779,25.980716 C70.4423917,25.980716 69.4230789,24.9614031 69.4230789,23.704017 L69.4230789,23.704017 C69.4230789,22.4466308 70.4423917,21.4273179 71.6997779,21.4273179 Z" id="Rectangle" fill="#A5A7AF" transform="translate(73.840555, 23.704017) rotate(22.100000) translate(-73.840555, -23.704017) "></path>
                <path d="M82.4128929,26.0381542 L87.8594949,26.0381542 C89.116881,26.0381542 90.1361939,27.057467 90.1361939,28.3148532 L90.1361939,28.3148532 C90.1361939,29.5722393 89.116881,30.5915522 87.8594949,30.5915522 L82.4128929,30.5915522 C81.1555068,30.5915522 80.1361939,29.5722393 80.1361939,28.3148532 L80.1361939,28.3148532 C80.1361939,27.057467 81.1555068,26.0381542 82.4128929,26.0381542 Z" id="Rectangle" fill="#BABCC4" transform="translate(85.136194, 28.314853) rotate(22.100000) translate(-85.136194, -28.314853) "></path>
                <path d="M70.0498706,31.7075064 L85.2052104,31.7075064 C86.4625966,31.7075064 87.4819095,32.7268192 87.4819095,33.9842054 L87.4819095,33.9842054 C87.4819095,35.2415915 86.4625966,36.2609044 85.2052104,36.2609044 L70.0498706,36.2609044 C68.7924845,36.2609044 67.7731716,35.2415915 67.7731716,33.9842054 L67.7731716,33.9842054 C67.7731716,32.7268192 68.7924845,31.7075064 70.0498706,31.7075064 Z" id="Rectangle" fill="#A5A7AF" transform="translate(77.627541, 33.984205) rotate(-157.900000) translate(-77.627541, -33.984205) "></path>
                <path d="M58.9311252,24.937101 L63.2126786,24.937101 C64.4700647,24.937101 65.4893776,25.9564139 65.4893776,27.2138 L65.4893776,27.2138 C65.4893776,28.4711862 64.4700647,29.490499 63.2126786,29.490499 L58.9311252,29.490499 C57.673739,29.490499 56.6544261,28.4711862 56.6544261,27.2138 L56.6544261,27.2138 C56.6544261,25.9564139 57.673739,24.937101 58.9311252,24.937101 Z" id="Rectangle" fill="#BABCC4" transform="translate(61.071902, 27.213800) rotate(-157.900000) translate(-61.071902, -27.213800) "></path>
                <path d="M46.9186605,20.4582502 L52.3652624,20.4582502 C53.6226486,20.4582502 54.6419614,21.4775631 54.6419614,22.7349493 L54.6419614,22.7349493 C54.6419614,23.9923354 53.6226486,25.0116483 52.3652624,25.0116483 L46.9186605,25.0116483 C45.6612743,25.0116483 44.6419614,23.9923354 44.6419614,22.7349493 L44.6419614,22.7349493 C44.6419614,21.4775631 45.6612743,20.4582502 46.9186605,20.4582502 Z" id="Rectangle" fill="#BABCC4" transform="translate(49.641961, 22.734949) rotate(-157.900000) translate(-49.641961, -22.734949) "></path>
                <rect id="Rectangle" fill="#DDA75F" x="16.407767" y="25.7281553" width="40.3883495" height="20.1941748" rx="4.4"></rect>
                <rect id="Rectangle" fill="#DADAE1" style={style} x="20.8737864" y="10.9708738" width="59.223301" height="62.8640777" rx="4.4"></rect>
                <rect id="Rectangle" fill="#FFFFFF" x="17.961165" y="7.18446602" width="59.223301" height="62.8640777" rx="4.4"></rect>
                <rect id="Rectangle" fill="#DADAE1" x="51.6504854" y="33.8834951" width="18.9320388" height="16.3980583" rx="4.4"></rect>
                <rect id="Rectangle" fill="#BABCC4" x="51.6504854" y="52.4271845" width="18.9320388" height="10.3203883" rx="4.4"></rect>
                <rect id="Rectangle" fill="#DADAE1" x="25.5339806" y="15.3398058" width="19.7087379" height="4.55339806" rx="2.27669903"></rect>
                <rect id="Rectangle" fill="#DADAE1" x="48.9320388" y="15.3398058" width="8.83495146" height="4.55339806" rx="2.27669903"></rect>
                <rect id="Rectangle" fill="#BABCC4" x="60.5825243" y="15.3398058" width="10" height="4.55339806" rx="2.27669903"></rect>
                <rect id="Rectangle" fill="#DADAE1" transform="translate(60.728155, 25.878641) rotate(180.000000) translate(-60.728155, -25.878641) " x="50.8737864" y="23.6019417" width="19.7087379" height="4.55339806" rx="2.27669903"></rect>
                <rect id="Rectangle" fill="#BABCC4" transform="translate(42.766990, 25.878641) rotate(180.000000) translate(-42.766990, -25.878641) " x="38.3495146" y="23.6019417" width="8.83495146" height="4.55339806" rx="2.27669903"></rect>
                <rect id="Rectangle" fill="#BABCC4" transform="translate(30.533981, 25.878641) rotate(180.000000) translate(-30.533981, -25.878641) " x="25.5339806" y="23.6019417" width="10" height="4.55339806" rx="2.27669903"></rect>
                <rect id="Rectangle" fill="#DADAE1" style={style} x="3.98058252" y="26.6019417" width="56.407767" height="44.0194175" rx="4.4"></rect>
                <rect id="Rectangle" fill="#FDE863" x="0" y="30.3883495" width="56.7961165" height="45.5533981" rx="4.4"></rect>
                <circle id="Oval" fill="#FFFFFF" opacity="0.62" cx="62.815534" cy="51.4563107" r="13.9805825"></circle>
                <g id="Group" style={style} transform="translate(43.689320, 34.951456)" fill="#DADAE1">
                  <path d="M26.888881,31.141604 L40.2383956,31.141604 C42.3429758,31.141604 44.0490752,32.8477034 44.0490752,34.9522836 L44.0490752,34.9522836 C44.0490752,37.0568639 42.3429758,38.7629632 40.2383956,38.7629632 L26.888881,38.7629632 C24.7843008,38.7629632 23.0782014,37.0568639 23.0782014,34.9522836 L23.0782014,34.9522836 C23.0782014,32.8477034 24.7843008,31.141604 26.888881,31.141604 Z" id="Rectangle" transform="translate(33.563638, 34.952284) rotate(45.000000) translate(-33.563638, -34.952284) "></path>
                  <path d="M26.2135922,27.5728155 L26.2135922,27.5728155 C24.7448691,29.0497696 24.7448691,31.4356673 26.2135922,32.9126214 L35.631068,42.4271845 C37.1411398,43.907351 39.5578893,43.907351 41.0679612,42.4271845" id="Path"></path>
                  <path d="M18.2524272,0.194174757 C11.0576459,0.154858426 4.54941904,4.45863007 1.76880997,11.09449 C-1.01179911,17.7303499 0.484211115,25.3881185 5.55780816,30.4895925 C10.6314052,35.5910664 18.2808616,37.1290142 24.931858,34.3848075 C31.5828544,31.6406008 35.9222227,25.1560537 35.9223301,17.961165 C35.922476,8.18651753 28.0269288,0.247588131 18.2524272,0.194174757 Z M18.2524272,30.0970874 C13.335261,30.136426 8.88035336,27.2046756 6.9713145,22.6730488 C5.06227564,18.1414219 6.07647212,12.9057016 9.53955289,9.41469278 C13.0026337,5.92368394 18.2300391,4.86746688 22.7768531,6.74004613 C27.3236672,8.61262539 30.2911048,13.0438415 30.2912621,17.961165 C30.2914754,24.6259172 24.9169661,30.0437694 18.2524272,30.0970874 L18.2524272,30.0970874 Z" id="Shape" fillRule="nonzero"></path>
                  <path d="M18.2524272,4.27184466 C10.6920243,4.27184466 4.5631068,10.4007622 4.5631068,17.961165 C4.5631068,25.5215679 10.6920243,31.6504854 18.2524272,31.6504854 C25.8128301,31.6504854 31.9417476,25.5215679 31.9417476,17.961165 C31.9417476,14.3305315 30.499484,10.8485993 27.9322385,8.28135377 C25.3649929,5.7141082 21.8830607,4.27184466 18.2524272,4.27184466 L18.2524272,4.27184466 Z M18.2524272,30 C11.6035622,30 6.21359223,24.61003 6.21359223,17.961165 C6.21359223,11.3123001 11.6035622,5.9223301 18.2524272,5.9223301 C24.9012921,5.9223301 30.2912621,11.3123001 30.2912621,17.961165 C30.2912621,21.1540626 29.0228885,24.2161874 26.765169,26.4739069 C24.5074495,28.7316264 21.4453247,30 18.2524272,30 Z" id="Shape" fillRule="nonzero"></path>
                </g>
                <path d="M71.7639493,64.3219302 L85.1134639,64.3219302 C87.2180441,64.3219302 88.9241435,66.0280295 88.9241435,68.1326098 L88.9241435,68.1326098 C88.9241435,70.23719 87.2180441,71.9432894 85.1134639,71.9432894 L71.7639493,71.9432894 C69.6593691,71.9432894 67.9532697,70.23719 67.9532697,68.1326098 L67.9532697,68.1326098 C67.9532697,66.0280295 69.6593691,64.3219302 71.7639493,64.3219302 Z" id="Rectangle" fill="#63BACE" transform="translate(78.438707, 68.132610) rotate(45.000000) translate(-78.438707, -68.132610) "></path>
                <path d="M71.0679612,60.776699 L71.0679612,60.776699 C69.599238,62.2536531 69.599238,64.6395508 71.0679612,66.1165049 L80.4854369,75.631068 C81.9955088,77.1112345 84.4122582,77.1112345 85.9223301,75.631068" id="Path" fill="#3F7A8C"></path>
                <path d="M63.1067961,33.3980583 C55.9120148,33.3587419 49.403788,37.6625136 46.6231789,44.2983735 C43.8425698,50.9342334 45.33858,58.592002 50.4121771,63.693476 C55.4857741,68.7949499 63.1352306,70.3328977 69.7862269,67.588691 C76.4372233,64.8444843 80.7765916,58.3599372 80.776699,51.1650485 C80.776845,41.390401 72.8812977,33.4514716 63.1067961,33.3980583 Z M63.1067961,63.3009709 C58.1896299,63.3403095 53.7347223,60.4085591 51.8256834,55.8769323 C49.9166446,51.3453054 50.9308411,46.1095851 54.3939218,42.6185763 C57.8570026,39.1275674 63.084408,38.0713504 67.6312221,39.9439296 C72.1780361,41.8165089 75.1454737,46.247725 75.1456311,51.1650485 C75.1458443,57.8298007 69.771335,63.2476529 63.1067961,63.3009709 Z" id="Shape" fill="#D72027" fillRule="nonzero"></path>
                <path d="M63.1067961,37.4757282 C55.5463932,37.4757282 49.4174757,43.6046457 49.4174757,51.1650485 C49.4174757,58.7254514 55.5463932,64.8543689 63.1067961,64.8543689 C70.667199,64.8543689 76.7961165,58.7254514 76.7961165,51.1650485 C76.7961165,47.534415 75.353853,44.0524828 72.7866074,41.4852373 C70.2193618,38.9179917 66.7374296,37.4757282 63.1067961,37.4757282 Z M63.1067961,63.2038835 C56.4579312,63.2038835 51.0679612,57.8139135 51.0679612,51.1650485 C51.0679612,44.5161836 56.4579312,39.1262136 63.1067961,39.1262136 C69.7556611,39.1262136 75.1456311,44.5161836 75.1456311,51.1650485 C75.1456311,57.8139135 69.7556611,63.2038835 63.1067961,63.2038835 Z" id="Shape" fill="#9E302C" fillRule="nonzero"></path>
                <polygon id="Path" fill="#DBA65E" points="37.0873786 50.4854369 37.0873786 54.7572816 31.6504854 54.7572816 31.6504854 60.1941748 27.4757282 60.1941748 27.4757282 54.7572816 21.9417476 54.7572816 21.9417476 50.4854369 27.4757282 50.4854369 27.4757282 45.0485437 31.6504854 45.0485437 31.6504854 50.4854369"></polygon>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

NoDocument.propTypes = {
  className: PropTypes.string,
};