import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';
import { gql } from '@apollo/client';
import { createClient } from '../utils';

const { api } = getConfig();

const client = createClient(api.graphQLEndpoint);

const notifyLessonAction = gql`
  mutation notifyLessonAction(
    $tutorId: String!
    $studentId: String!
    $itemId: Int!
    $lessonId: Int!
    $lessonName: String!
    $notificationType: lessonNotifType!
    $fileName: String
    $comment: String
    $authoredUser: String
  ) {
    notification: lessonNotification(
      tutorId: $tutorId
      studentId: $studentId
      itemId: $itemId
      lessonId: $lessonId
      lessonName: $lessonName
      notificationType: $notificationType
      fileName: $fileName
      comment: $comment
      authoredUser: $authoredUser
    )
  }
`;

export default {
  /**
   * Send a notification to participants of a lesson depending on
   * the action taking place.
   *
   * @param {String!} tutorId
   * @param {String!} studentId
   * @param {Int!} itemId
   * @param {Int!} lessonId
   * @param {String!} lessonName
   * @param {lessonNotifType!} notificationType
   * @param {String} fileName
   * @param {String} comment
   * @param {String} authoredUser
   */
  notifyLesson: (
    tutorId,
    studentId,
    itemId,
    lessonId,
    lessonName,
    notificationType,
    fileName,
    comment,
    authoredUser,
  ) => {
    return client.query(notifyLessonAction, {
      tutorId,
      studentId,
      itemId,
      lessonId,
      lessonName,
      notificationType,
      fileName,
      comment,
      authoredUser,
    });
  },
};
