import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Acl, permissionTypes } from '@crimson-education/common-config';

import css from './styles.scss';
import BookingAs from './BookingAs';
import BookingWith from './BookingWith';

export default function PeopleSearch(props) {
  return (
    <div className={css.peopleSearch}>
      {Acl.checkPermission(props.loginUserPermissions, permissionTypes.CRUD_BOOKING_ON_BEHALF) && (
        <BookingAs
          bookFor={props.bookFor}
          bookAs={props.bookAs}
          userId={props.userId}
          selectBookingAs={props.selectBookingAs}
          bookingForPagination={props.bookingForPagination}
          loadMoreContacts={props.loadMoreContacts}
        />
      )}
      {<BookingWith />}
    </div>
  );
}

PeopleSearch.propTypes = {
  bookFor: ImmutablePropTypes.list.isRequired,
  bookAs: ImmutablePropTypes.map,
  userId: PropTypes.string.isRequired,
  selectBookingAs: PropTypes.func.isRequired,
  bookingForPagination: PropTypes.object,
  loadMoreContacts: PropTypes.func,
  loginUserPermissions: PropTypes.array,
};
