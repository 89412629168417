export const bookingModalModes = {
  SENDER_TENTATIVE_BEFORE: 'SENDER_TENTATIVE_BEFORE',
  SENDER_TENTATIVE_AFTER_STUDENT: 'SENDER_TENTATIVE_AFTER_STUDENT',
  SENDER_TENTATIVE_AFTER: 'SENDER_TENTATIVE_AFTER',

  RECEIVER_TENTATIVE_BEFORE: 'RECEIVER_TENTATIVE_BEFORE',
  RECEIVER_TENTATIVE_AFTER_STUDENT: 'RECEIVER_TENTATIVE_AFTER_STUDENT',
  RECEIVER_TENTATIVE_AFTER_TUTOR: 'RECEIVER_TENTATIVE_AFTER_TUTOR',
  RECEIVER_TENTATIVE_AFTER_ADMIN: 'RECEIVER_TENTATIVE_AFTER_ADMIN',

  TENTATIVE_VIEW_ONLY: 'TENTATIVE_VIEW_ONLY',

  SENDER_CONFIRMED: 'SENDER_CONFIRMED',
  SENDER_CONFIRMED_DURING: 'SENDER_CONFIRMED_DURING',

  RECEIVER_CONFIRMED: 'RECEIVER_CONFIRMED',
  RECEIVER_CONFIRMED_DURING: 'RECEIVER_CONFIRMED_DURING',

  SENDER_CONFIRMED_VIEW_ONLY: 'SENDER_CONFIRMED_VIEW_ONLY',
  RECEIVER_CONFIRMED_VIEW_ONLY: 'RECEIVER_CONFIRMED_VIEW_ONLY',

  SUMMARY: 'SUMMARY',
  CANCEL_REQUEST: 'CANCEL_REQUEST',
  CANCEL_BOOKING: 'CANCEL_BOOKING',
  BOOKING_CANCELLED: 'BOOKING_CANCELLED',
  DECLINE: 'DECLINE',
  SUGGEST_NEW_TIME: 'SUGGEST_NEW_TIME',
};

export const footerType = {
  SENDER_CANCEL: 'SENDER_CANCEL',
  RECEIVER_CONFIRM: 'RECEIVER_CONFIRM',
  RECEIVER_DECLINE: 'RECEIVER_DECLINE',
  RECEIVER_SUGGEST_NEW_TIME: 'RECEIVER_SUGGEST_NEW_TIME',
  RECEIVER_CONFIRM_IN_THE_PAST: 'RECEIVER_CONFIRM_IN_THE_PAST',
  RECEIVER_CONFIRM_IN_THE_PAST_STUDENT: 'RECEIVER_CONFIRM_IN_THE_PAST_STUDENT',
  RECEIVER_CONFIRM_IN_THE_PAST_ADMIN: 'RECEIVER_CONFIRM_IN_THE_PAST_ADMIN',
  SENDER_DELETE_IN_THE_PAST_TUTOR: 'SENDER_DELETE_IN_THE_PAST',
  CONFIRMED: 'CONFIRMED',
  CLOSE: 'CLOSE',
};

const supervisor = 'Student Success Manager';

export const messages = {
  AwaitingConfirmation: 'Awaiting confirmation.',
  IConfirmed: 'You confirmed this booking',
  OtherPersonConfirmed: 'Confirmed session',
  BookingInProgressWarning: `This session has already started. You are unable to reschedule or cancel this session. If you are unable to attend at this time please contact your ${supervisor}.`,
  CancelledBooking: 'This booking has been cancelled.',
  PendingBookingWarning:
    'Awaiting confirmation from the other user. Any changes made will also appear on the other user’s session invite.',
  TutorToConfirm: 'Your tutor must confirm if this session happened.',
  ConfirmPendingBookingWithin24Hours:
    'The start time for this session request is within 24 hours. Once confirmed, you can no longer decline the session.',
  UpdateBookingWarning:
    'This booking is confirmed. If you change the date or time, the other user will have to re-confirm the booking again.',
  Within24HoursWarning: `Your session is due to start within 24 hours. You are now unable to reschedule or cancel this session. If you are unable to attend at this time please contact your ${supervisor}.`,
  DeleteConfirmedSessionWarningTutor:
    'You are about to permanently delete this session from the calendar. The other user will be notified. Are you sure you want to do this?',
  Within24HoursWarningTutor: `Your session is due to start within 24 hours. You are now unable to reschedule or cancel this session. If you are unable to attend at this time please contact your ${supervisor}.`,
  DeleteSessionWarning:
    'You are about to permanently delete this session from the calendar. Are you sure you want to do this?',
  TutorDeletePastPending:
    'This request was left pending. These package hours are on hold until you delete this session or your student confirms.',
  StudentPastPending: 'This request was left pending by your tutor.',
  PastPending: 'You left this request pending.',
};

export const alertMessageStatus = {
  DEFAULT: 'default',
  ERROR: 'error',
  CONFIRMED: 'confirmed',
  PENDING: 'pending',
  EDIT_CONFIRMED: 'editConfirmed',
};
