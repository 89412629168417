import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';
import { gql } from '@apollo/client';
import { createClient } from '../utils';

const { api } = getConfig();

const client = createClient(api.graphQLEndpoint);

const questionInfo = gql`
  fragment questionInfo on questionType {
    externalQuestionId
    text
    answerType
    imageUrl
    givenAnswer
    skillCategory
    questionType
    answerExplanation
    options {
      optionLabel
      text
      imageUrl
    }
  }
`;

const questionGroupInfo = gql`
  ${questionInfo}
  fragment questionGroupInfo on questionGroupType {
    imageUrl
    externalQuestionGroupId
    text
    questions {
      ...questionInfo
    }
  }
`;

const sectionInfo = gql`
  fragment sectionInfo on sectionType {
    name
    sectionId
    externalSectionId
    instructions
    expiryTime
    timeLimitMinutes
    timeCompleted
    instructionsOnly
  }
`;

const examInfo = gql`
  fragment examInfo on studentExamType {
    userId
    examPrepSessionId
    externalExamId
    name
    instructions
    examType
    status
    isAvailable
    timeCompleted
  }
`;

const fetchExamAnswerDataBySessionId = gql`
  ${examInfo}
  ${sectionInfo}
  ${questionInfo}
  query fetchExamAnswerDataBySessionId($examPrepSessionId: String!) {
    exam: getExamBySessionId(sessionId: $examPrepSessionId) {
      ...examInfo
      sections {
        ...sectionInfo
        questionsAndQuestionGroups {
          ... on questionType {
            ...questionInfo
            correctAnswer
            minAnswer
            maxAnswer
          }
          ... on questionGroupType {
            imageUrl
            externalQuestionGroupId
            text
            questions {
              ...questionInfo
              correctAnswer
              minAnswer
              maxAnswer
            }
          }
        }
      }
    }
  }
`;

const fetchExamBySessionId = gql`
  ${examInfo}
  ${sectionInfo}
  ${questionInfo}
  ${questionGroupInfo}
  query getExamBySessionId($examPrepSessionId: String!) {
    exam: getExamBySessionId(sessionId: $examPrepSessionId) {
      ...examInfo
      sections {
        ...sectionInfo
        questionsAndQuestionGroups {
          ... on questionType {
            ...questionInfo
          }
          ... on questionGroupType {
            ...questionGroupInfo
          }
        }
      }
    }
  }
`;

const fetchAllExamIdsByUserId = gql`
  query fetchAllExamIdsByUserId($userId: String!) {
    exams: getAllExamsByUserId(userId: $userId) {
      externalExamId
    }
  }
`;

const fetchAllExamsByUserId = gql`
  ${examInfo}
  query fetchAllExamsByUserId($userId: String!) {
    exams: getAllExamsByUserId(userId: $userId) {
      ...examInfo
    }
  }
`;

const fetchAvailableExamsByUserId = gql`
  ${examInfo}
  query fetchAvailableExamsByUserId($userId: String!) {
    exams: getAvailableExamsByUserId(userId: $userId) {
      ...examInfo
    }
  }
`;

const setExamAvailability = gql`
  mutation setExamAvailability($userId: String!, $externalExamId: String!, $isAvailable: Boolean!) {
    setExamAvailability(userId: $userId, externalExamId: $externalExamId, isAvailable: $isAvailable) {
      externalExamId
      name
    }
  }
`;

const startExam = gql`
  ${examInfo}
  ${sectionInfo}
  mutation startExam($externalExamId: String!) {
    startExam(externalExamId: $externalExamId) {
      ...examInfo
      sections {
        ...sectionInfo
      }
    }
  }
`;

const completeSection = gql`
  ${examInfo}
  ${sectionInfo}
  ${questionInfo}
  ${questionGroupInfo}
  mutation completeSection($examPrepSectionId: String!) {
    completeSection(examPrepSectionId: $examPrepSectionId) {
      ...examInfo
      sections {
        ...sectionInfo
        questionsAndQuestionGroups {
          ... on questionType {
            ...questionInfo
          }
          ... on questionGroupType {
            ...questionGroupInfo
          }
        }
      }
    }
  }
`;

const fetchAvailableExamIdsByUserId = gql`
  query fetchAvailableExamIdsByUserId($userId: String!) {
    exams: getAvailableExamsByUserId(userId: $userId) {
      examPrepSessionId
      examType
      name
      status
      userId
      externalExamId
    }
  }
`;

const resetExam = gql`
  ${examInfo}
  mutation resetExam($examPrepSessionId: String!) {
    resetExam(examPrepSessionId: $examPrepSessionId) {
      ...examInfo
    }
  }
`;

const submitAnswer = gql`
  mutation submitAnswer(
    $examPrepSectionId: String!
    $externalQuestionGroupId: String
    $externalQuestionId: String!
    $givenAnswer: String!
  ) {
    submitAnswer(
      examPrepSectionId: $examPrepSectionId
      externalQuestionGroupId: $externalQuestionGroupId
      externalQuestionId: $externalQuestionId
      givenAnswer: $givenAnswer
    ) {
      id
      userId
      examPrepSessionId
      examPrepSectionId
      externalQuestionId
      externalQuestionGroupId
      givenAnswer
    }
  }
`;

const fetchSATExamResult = gql`
  query getSATExamResult($examPrepSessionId: String!) {
    report: getSATExamResult(sessionId: $examPrepSessionId) {
      examPrepSessionId
      maxRawScoreForChart
      overallMaxScore
      overallUserScore
      sections {
        name
        userRawScore
        maxScore
        userScore
        timeCompleted
        questionTypeResults {
          questionType
          maxRawScore
          userRawScore
        }
        skillCategoryResults {
          skillCategory
          maxRawScore
          userRawScore
        }
        questionTypesImprovements
        skillCategoryImprovements
      }
    }
  }
`;

const fetch30MinSATExamResult = gql`
  query get30MinSATExamResult($examPrepSessionId: String!) {
    report: get30MinSATExamResult(sessionId: $examPrepSessionId) {
      examPrepSessionId
      overallMaxScore
      overallUserScoreMin
      overallUserScoreMax
      overallMaxRawScore
      overallUserRawScore
      sections {
        maxScore
        userRawScore
        maxRawScore
        userMinRangeScore
        userMaxRangeScore
        name
      }
    }
  }
`;

const fetchACTExamResult = gql`
  query getACTExamResult($examPrepSessionId: String!) {
    report: getACTExamResult(sessionId: $examPrepSessionId) {
      examPrepSessionId
      maxRawScoreForChart
      overallBreakdown {
        overallMaxScore
        overallUserScore
        compositeMaxScore
        compositeUserScore
        sections {
          name
          maxScore
          userScore
        }
      }
      skillCategoryBreakdown {
        name
        scores {
          name
          userScore
          maxScore
        }
        skillCategories {
          skillCategory
          userRawScore
          maxRawScore
        }
        skillCategoryImprovements
      }
      questionTypeBreakdown {
        name
        scores {
          name
          userScore
          maxScore
        }
        questionTypes {
          questionType
          userRawScore
          maxRawScore
        }
        questionTypeImprovements
      }
    }
  }
`;

const fetchUMATExamResult = gql`
  query getUMATExamResult($examPrepSessionId: String!) {
    report: getUMATExamResult(sessionId: $examPrepSessionId) {
      examPrepSessionId
      overallMaxScore
      overallUserScore
      sections {
        name
        maxScore
        userScore
      }
    }
  }
`;

const fetchGeneralExamResult = gql`
  query getGeneralExamResult($examPrepSessionId: String!) {
    report: getGeneralExamResult(sessionId: $examPrepSessionId) {
      examPrepSessionId
      overallMaxScore
      overallUserScore
      sections {
        name
        maxScore
        userScore
      }
    }
  }
`;

export default {
  fetchExamBySessionId: (examPrepSessionId) => client.query(fetchExamBySessionId, { examPrepSessionId }),
  fetchAvailableExamsByUserId: (userId) => client.query(fetchAvailableExamsByUserId, { userId }),
  fetchAvailableExamIdsByUserId: (userId) => client.query(fetchAvailableExamIdsByUserId, { userId }),
  fetchAllExamIdsByUserId: (userId) => client.query(fetchAllExamIdsByUserId, { userId }),
  fetchAllExamsByUserId: (userId) => client.query(fetchAllExamsByUserId, { userId }),
  setExamAvailability: ({ userId, externalExamId, isAvailable }) =>
    client.query(setExamAvailability, { userId, externalExamId, isAvailable }),
  startExam: (externalExamId) => client.query(startExam, { externalExamId }),
  completeSection: (examPrepSectionId) => client.query(completeSection, { examPrepSectionId }),
  resetExam: (examPrepSessionId) => client.query(resetExam, { examPrepSessionId }),
  submitAnswer: (examPrepSectionId, externalQuestionGroupId, externalQuestionId, givenAnswer) =>
    client.query(submitAnswer, { examPrepSectionId, externalQuestionGroupId, externalQuestionId, givenAnswer }),
  fetchExamAnswerDataBySessionId: (examPrepSessionId) =>
    client.query(fetchExamAnswerDataBySessionId, { examPrepSessionId }),
  fetchSATExamResult: (examPrepSessionId) => client.query(fetchSATExamResult, { examPrepSessionId }),
  fetchACTExamResult: (examPrepSessionId) => client.query(fetchACTExamResult, { examPrepSessionId }),
  fetchUMATExamResult: (examPrepSessionId) => client.query(fetchUMATExamResult, { examPrepSessionId }),
  fetch30MinSATExamResult: (examPrepSessionId) => client.query(fetch30MinSATExamResult, { examPrepSessionId }),
  fetchGeneralExamResult: (examPrepSessionId) => client.query(fetchGeneralExamResult, { examPrepSessionId }),
};
