/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import { CALENDAR_FUTURE_LIMIT } from 'utils/calendarUtils';
import IconButton from 'components/molecules/IconButton';
import 'flatpickr/dist/flatpickr.css';
import Flatpickr from 'react-flatpickr';
import autoBind from 'auto-bind';

import css from './styles.scss';
import { handleEnter } from '../../../../../utils/accessibility';

export default class CalendarHeader extends Component {
  static formatWeekDisplay(currentDate) {
    const start = moment(currentDate);
    return `${start.local().format('dddd MMM D YYYY')}`;
  }

  constructor(props) {
    super(props);
    this.state = {
      headerWidth: null,
    };

    autoBind(this);
  }

  onDateChange(selectedDates, dateStr) {
    this.props.updateSelectedSpecificDay(moment(dateStr).startOf('day'));
  }

  handleMouseEnter() {
    const header = this.dayHeader;

    this.setState({
      headerWidth: header.getBoundingClientRect().width,
    });
  }

  handleMouseOut() {
    setTimeout(() => {
      this.setState({
        headerWidth: null,
      });
    }, 125);
  }

  renderPrevArrow() {
    return (
      <IconButton
        icon="ArrowBack"
        data-test-id="goToPreviousDay"
        className={css.arrowButton}
        onClick={this.props.prevDay}
      />
    );
  }

  renderNextArrow() {
    const { selectedDay } = this.props;
    const showNext = moment(selectedDay).isBefore(moment().add(CALENDAR_FUTURE_LIMIT, 'd'), 'd');

    return (
      <IconButton
        icon="ArrowForward"
        data-test-id="goToNextDay"
        className={classNames(css.arrowButton, { [css.hidden]: !showNext })}
        onClick={this.props.nextDay}
      />
    );
  }

  renderDropArrow() {
    const options = Object.assign(
      {},
      {
        mode: 'single',
        allowInput: true,
        altInput: true,
        wrap: true,
        static: true,
        altInputClass: css.dayFlatPickrInput,
        dateFormat: 'Y-m-d',
      },
    );

    return (
      <div className={css.date}>
        <Flatpickr options={options} onChange={this.onDateChange}>
          <div className={css.flatpickr}>
            <input type="text" data-input className={css.dayFlatPickrInput} />
            <a
              data-test-id="popupDayDateCalendar"
              data-ga-label="DayDatePicker"
              className={css.dayCalendarIcon}
              data-toggle
            >
              <IconButton
                icon="ExpandMore"
                data-test-id="goToNextDay"
                iconSize={{ height: '2.5rem', width: '2.5rem' }}
              />
            </a>
          </div>
        </Flatpickr>
      </div>
    );
  }

  render() {
    const { headerWidth } = this.state;
    const { selectedDay, app } = this.props;
    const isMobile = app.isMobile;
    return (
      <React.Fragment>
        {isMobile ? (
          <div className={css.calendarHeader}>
            <div className={css.calendarMobile}>
              <IconButton
                icon="AddBox"
                data-test-id="addPeople"
                iconSize={{ height: '2.5rem', width: '2.5rem' }}
                color="secondary"
              />
              <span>Add people</span>
            </div>
            <div data-appcues-id="calendar-header" className={css.header}>
              <div
                data-test-id="currentDayHeader"
                className={css.currentDay}
                style={{ width: headerWidth || 'auto' }}
                ref={(div) => {
                  this.dayHeader = div;
                }}
              >
                {CalendarHeader.formatWeekDisplay(selectedDay)}
              </div>
              {this.renderDropArrow()}
            </div>
          </div>
        ) : (
          <div className={css.calendarHeader}>
            <div
              role="button"
              data-ga-label="Today"
              tabIndex={0}
              onClick={this.props.goToday}
              onKeyDown={handleEnter(this.props.goToday)}
            >
              <div className={css.todayButton} data-test-id="goToday">
                <div className={css.todayText}>Today</div>
              </div>
            </div>
            <div
              data-appcues-id="calendar-header"
              className={css.header}
              onMouseEnter={this.handleMouseEnter}
              onMouseLeave={this.handleMouseOut}
            >
              {this.renderPrevArrow()}
              {this.renderNextArrow()}
              <div
                data-test-id="currentDayHeader"
                className={css.currentDay}
                style={{ width: headerWidth || 'auto' }}
                ref={(div) => {
                  this.dayHeader = div;
                }}
              >
                {CalendarHeader.formatWeekDisplay(selectedDay)}
              </div>
              {this.renderDropArrow()}
            </div>
            <div className={css.closeButton}>
              <IconButton
                icon="Close"
                data-test-id="closeDayCalendar"
                iconSize={{ height: '2.5rem', width: '2.5rem' }}
                onClick={() => this.props.updateDayCalendarViewHidden(false)}
              />
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

CalendarHeader.propTypes = {
  selectedDay: PropTypes.object,
  nextDay: PropTypes.func.isRequired,
  prevDay: PropTypes.func.isRequired,
  goToday: PropTypes.func.isRequired,
  updateSelectedSpecificDay: PropTypes.func.isRequired,
  updateDayCalendarViewHidden: PropTypes.func.isRequired,
  app: PropTypes.object,
};

CalendarHeader.defaultProps = {
  calendarDays: 7,
};

CalendarHeader.displayName = 'CalendarHeader';
