import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';
import { gql } from '@apollo/client';
import { createClient } from './utils';

const { api } = getConfig();

const client = createClient(api.graphQLEndpoint);

const addUserAdmissionResult = gql`
  mutation addUserAdmissionResult(
    $studentId: String
    $university: String
    $degree: String
    $major: String
    $round: String
    $status: String
    $hadInterview: Boolean
    $receivedAid: String
    $totalAid: String
  ) {
    createAdmission(
      studentId: $studentId
      university: $university
      degree: $degree
      major: $major
      round: $round
      status: $status
      hadInterview: $hadInterview
      receivedAid: $receivedAid
      totalAid: $totalAid
    ) {
      id
      studentId
      university
      degree
      major
      status
      round
      hadInterview
      receivedAid
      totalAid
    }
  }
`;

const updateUserAdmissionResult = gql`
  mutation updateUserAdmissionResult(
    $id: String
    $university: String
    $degree: String
    $major: String
    $round: String
    $status: String
    $hadInterview: Boolean
    $receivedAid: String
    $totalAid: String
  ) {
    updateAdmission(
      id: $id
      university: $university
      degree: $degree
      major: $major
      round: $round
      status: $status
      hadInterview: $hadInterview
      receivedAid: $receivedAid
      totalAid: $totalAid
    ) {
      id
      studentId
      university
      degree
      major
      status
      round
      hadInterview
      receivedAid
      totalAid
    }
  }
`;

const fetchUserAdmissionResults = gql`
  query fetchUserAdmissionResults($studentId: String) {
    admissions(studentId: $studentId) {
      id
      studentId
      university
      degree
      major
      round
      status
      receivedAid
      totalAid
      hadInterview
    }
  }
`;

const deleteUserAdmissionResult = gql`
  mutation deleteUserAdmissionResult($id: String!) {
    deleteAdmission(id: $id)
  }
`;

const fetchAdmissionsUniversities = gql`
  query fetchAdmissionsUniversities {
    getAdmissionsUniversities {
      id
      name
      country {
        id
        name
        region
        shortName
      }
    }
  }
`;

export default {
  addUserAdmissionResult: (results) => client.query(addUserAdmissionResult, results),
  updateUserAdmissionResult: (results) => client.query(updateUserAdmissionResult, results),
  fetchUserAdmissionResults: (studentId) => client.query(fetchUserAdmissionResults, { studentId }),
  deleteUserAdmissionResult: (id) => client.query(deleteUserAdmissionResult, { id }),
  fetchAdmissionsUniversities: () => client.query(fetchAdmissionsUniversities),
};
