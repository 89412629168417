/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Acl, permissionTypes, roleTypes } from '@crimson-education/common-config';
import ReactTooltip from 'react-tooltip';

import Assignment from '@material-ui/icons/Assignment';
import AttachMoney from '@material-ui/icons/AttachMoney';
import Chat from '@material-ui/icons/Chat';
import CheckCircle from '@material-ui/icons/CheckCircle';
import CollectionsBookmark from '@material-ui/icons/CollectionsBookmark';
import ContactSupport from '@material-ui/icons/ContactSupport';
import Email from '@material-ui/icons/Email';
import Event from '@material-ui/icons/Event';
import ExitToApp from '@material-ui/icons/ExitToApp';
import ForumIcon from '@material-ui/icons/Forum';
import Description from '@material-ui/icons/Description';
import Grade from '@material-ui/icons/Grade';
import Map from '@material-ui/icons/Map';
import People from '@material-ui/icons/People';
import { Running } from 'components/atoms/graphics';
import Settings from '@material-ui/icons/Settings';
import Notifications from '@material-ui/icons/Notifications';
import MoveToInboxIcon from '@material-ui/icons/MoveToInbox';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import Explore from '@material-ui/icons/Explore';
import Dashboard from '@material-ui/icons/Dashboard';
import AssessmentIcon from '@material-ui/icons/Assessment';
import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';
import CGAReports from 'components/atoms/graphics/CGAReports';
import { featureSwitches } from '../../../featureSwitches';

import Header from './Header';
import { SwitchAppPopover, ProfilePopover, MetaBasePopover } from './MenuPopovers';
import css from './Navigation.scss';
import { handleEnter } from '../../../utils/accessibility';
import { useFeatureFlag } from 'featureSwitches';
const config = getConfig();

export default function Navigation(props) {
  const {
    app,
    user,
    loginUserPermissions,
    loginUserRoles,
    roadmapId,
    hasUnReadMessage,
    hasReports,
    lastThread,
    location,
    toggleMenu,
    hasExams,
    showMenu,
    isCalendarView,
    newNotificationCount,
    hasNewFriend,
  } = props;
  const STUDENT_CENTER_BETA = useFeatureFlag('STUDENT_CENTER_BETA');
  const ROADMAP_BETA = useFeatureFlag('ROADMAP_BETA');
  const { isMobile } = app;
  const hasSubject = (subject) => {
    const subjects = user.tutorInfo?.subjects?.map((item) => item.subject) || [];
    return subjects.includes(subject);
  };

  const canViewCalendar = Acl.checkPermission(loginUserPermissions, permissionTypes.VIEW_CALENDAR);
  const canViewSubjects = Acl.checkPermission(loginUserPermissions, permissionTypes.VIEW_MY_SUBJECTS);
  const canViewStudents =
    !Acl.checkRole(loginUserRoles, roleTypes.TUTOR) &&
    Acl.checkPermission(loginUserPermissions, permissionTypes.VIEW_MY_STUDENTS);
  const canViewRoadmap = Acl.checkPermission(loginUserPermissions, permissionTypes.VIEW_ROADMAP);
  const canViewExamPrep = Acl.checkPermission(loginUserPermissions, permissionTypes.VIEW_EXAM_PREP);
  const canViewPeople = Acl.checkPermission(loginUserPermissions, permissionTypes.VIEW_OUR_PEOPLE);
  const canViewInvoices = Acl.checkPermission(loginUserPermissions, permissionTypes.VIEW_INVOICES);
  const canViewGrades =
    !STUDENT_CENTER_BETA && Acl.checkPermission(loginUserPermissions, permissionTypes.GRADE_VIEW_OWN);
  const canViewEcl = !STUDENT_CENTER_BETA && Acl.checkPermission(loginUserPermissions, permissionTypes.VIEW_OWN_ECL);
  const canViewReports =
    Acl.checkRole(loginUserRoles, roleTypes.TUTOR) ||
    Acl.checkRole(loginUserRoles, roleTypes.STRATEGIST) ||
    Acl.checkRole(loginUserRoles, roleTypes.CASE_MANAGER);
  const canViewTasks =
    !STUDENT_CENTER_BETA && Acl.checkPermission(loginUserPermissions, permissionTypes.VIEW_OWN_TASKS);
  const canViewMessages = Acl.checkPermission(loginUserPermissions, permissionTypes.VIEW_MESSAGES);
  const canViewApplications = Acl.checkPermission(loginUserPermissions, permissionTypes.VIEW_OWN_APPLICATIONS);
  const canViewCGAReports =
    Acl.checkRole(loginUserRoles, roleTypes.CGA_STAFF) || Acl.checkRole(loginUserRoles, roleTypes.SUPER_ADMIN);
  const canViewDaVinciReports =
    featureSwitches.DAVINCI_REPORTS_TEMPLATE() &&
    (Acl.checkRole(loginUserRoles, roleTypes.TUTOR) ||
      Acl.checkRole(loginUserRoles, roleTypes.HEAD_TUTOR) ||
      Acl.checkRole(loginUserRoles, roleTypes.SUPER_ADMIN));
  const canViewContacts = Acl.checkPermission(loginUserPermissions, permissionTypes.VIEW_CONTACTS);
  const canViewCGAStudentProgress = Acl.checkRole(loginUserRoles, roleTypes.CGA_STUDENT);
  const canViewStrategistDashboard = Acl.checkRole(loginUserRoles, roleTypes.STRATEGIST);
  const canViewSSMDashboard = Acl.checkRole(loginUserRoles, roleTypes.CASE_MANAGER);
  const canViewCommunity = !Acl.checkRole(loginUserRoles, roleTypes.COMMUNITY_BLOCKED_USER);
  const canViewPathfinder = !STUDENT_CENTER_BETA && Acl.checkRole(loginUserRoles, roleTypes.STUDENT);
  const canViewUsMentorOps =
    featureSwitches.US_MENTOR_OPS_TAB() &&
    (Acl.checkRole(loginUserRoles, roleTypes.SUPER_ADMIN) ||
      Acl.checkRole(loginUserRoles, roleTypes.US_OPS_ADMIN) ||
      Acl.checkRole(loginUserRoles, roleTypes.REVIEWER) ||
      Acl.checkRole(loginUserRoles, roleTypes.EDITOR_US_ESSAYS) ||
      Acl.checkRole(loginUserRoles, roleTypes.CASE_MANAGER) ||
      (Acl.checkRole(loginUserRoles, roleTypes.TUTOR) &&
        hasSubject('US University Undergraduate Admissions Application Mentor Regular Application Support')) ||
      Acl.checkRole(loginUserRoles, roleTypes.STRATEGIST));
  const canViewUsEclOps =
    featureSwitches.US_ECL_OPS_TAB() &&
    (Acl.checkRole(loginUserRoles, roleTypes.SUPER_ADMIN) ||
      Acl.checkRole(loginUserRoles, roleTypes.ECL_OPS_ADMIN) ||
      Acl.checkRole(loginUserRoles, roleTypes.REVIEWER) ||
      Acl.checkRole(loginUserRoles, roleTypes.EDITOR_ECL) ||
      Acl.checkRole(loginUserRoles, roleTypes.CASE_MANAGER) ||
      (Acl.checkRole(loginUserRoles, roleTypes.TUTOR) && hasSubject('US Extracurricular and Leadership Consulting')) ||
      Acl.checkRole(loginUserRoles, roleTypes.STRATEGIST));
  const canViewStudentCenter = STUDENT_CENTER_BETA && Acl.checkRole(loginUserRoles, roleTypes.STUDENT);
  const canViewStudentDashboard = STUDENT_CENTER_BETA && Acl.checkRole(loginUserRoles, roleTypes.STUDENT);

  const currentPathName = location.pathname;

  let messagesLink = '/messages';
  if (lastThread && !isMobile) {
    messagesLink += `/${lastThread}`;
  }

  const onLinkClick = (ev) => {
    ev.stopPropagation();

    if (isMobile) {
      toggleMenu();
    }
  };

  return (
    <div
      data-ga-category="Left Navigation"
      className={classNames(css.navigationWrapper, { [css.mobileScrollbar]: isMobile })}
    >
      {!showMenu && !isMobile && <ReactTooltip id="navigationToolTip" effect="solid" offset={{ right: 0 }} />}
      <div
        className={classNames(css.navigation, {
          [css.navigationOpened]: showMenu,
          [css.transparentScrollbar]: !isMobile,
        })}
      >
        <div className={css.navTop}>
          <Header isMobile={isMobile} showMenu={showMenu} toggleMenu={toggleMenu} user={user} />
          {!isMobile && canViewStudentDashboard && (
            <Link
              to="/student-center/dashboard"
              data-test-id="navStudentDashboard"
              onClick={onLinkClick}
              className={classNames(css.navItem, {
                [css.selected]: currentPathName.includes('/student-center/dashboard'),
              })}
              data-appcues-id="studentDashboard"
            >
              <div className={css.navItemContent} data-tip="Student Dashboard" data-for="navigationToolTip">
                <AssessmentIcon />
                <span>Dashboard</span>
              </div>
            </Link>
          )}
          {!isMobile && canViewStudentCenter && (
            <Link
              to="/student-center"
              data-test-id="navStudentCenter"
              onClick={onLinkClick}
              className={classNames(css.navItem, {
                [css.selected]:
                  currentPathName.includes('/student-center') && !currentPathName.includes('/student-center/dashboard'),
              })}
              data-appcues-id="studentCenter"
            >
              <div className={css.navItemContent} data-tip="Mission Control" data-for="navigationToolTip">
                <Dashboard />
                <span>Mission Control</span>
              </div>
            </Link>
          )}
          {canViewRoadmap && (
            <Link
              to="/roadmap"
              data-test-id="navRoadmap"
              onClick={onLinkClick}
              className={classNames(
                css.navItem,
                { [css.selected]: currentPathName.startsWith('/roadmap') },
                ROADMAP_BETA && Acl.checkRole(loginUserRoles, roleTypes.STUDENT) ? {} : { [css.hideNav]: !roadmapId },
              )}
              data-appcues-id="roadmap"
            >
              <div className={css.navItemContent} data-tip="Roadmap" data-for="navigationToolTip">
                <Map />
                <span>Roadmap</span>
              </div>
            </Link>
          )}
          {canViewCommunity && (
            <Link
              to="/community"
              data-test-id="navCommunity"
              onClick={onLinkClick}
              className={classNames(css.navItem, { [css.selected]: currentPathName.startsWith('/community') })}
              data-appcues-id="community"
            >
              <div className={css.navItemContent} data-tip="Community" data-for="navigationToolTip">
                <ForumIcon />
                <span>Community</span>
              </div>
            </Link>
          )}

          {canViewCalendar && (
            <Link
              onClick={onLinkClick}
              to={isCalendarView ? '/calendar/full' : '/calendar/schedule'}
              data-test-id="navCalendar"
              className={classNames(css.navItem, { [css.selected]: currentPathName.startsWith('/calendar') })}
              data-appcues-id="calendar"
            >
              <div className={css.navItemContent} data-tip="Bookings" data-for="navigationToolTip">
                <Event />
                <span>Bookings</span>
              </div>
            </Link>
          )}

          {canViewMessages && (
            <Link
              to={messagesLink}
              data-test-id="navMessages"
              onClick={onLinkClick}
              className={classNames(
                css.navItem,
                { [css.selected]: currentPathName.startsWith('/messages') },
                { [css.relativePosition]: !showMenu },
              )}
            >
              <div className={css.navItemContent} data-tip="Messages" data-for="navigationToolTip">
                <Chat />
                <span>Messages</span>
                {hasUnReadMessage && (
                  <i
                    className={classNames('zmdi zmdi-circle', css.indicatorDot, {
                      [css.indicatorDotMenuClosed]: !showMenu,
                    })}
                  />
                )}
              </div>
            </Link>
          )}

          {canViewSubjects && (
            <Link
              to="/subjects"
              data-test-id="navSubjects"
              onClick={onLinkClick}
              className={classNames(css.navItem, { [css.selected]: currentPathName.startsWith('/subjects') })}
              data-appcues-id="my-subjects"
            >
              <div className={css.navItemContent} data-tip="Program Usage" data-for="navigationToolTip">
                <CollectionsBookmark />
                <span>Program Usage</span>
              </div>
            </Link>
          )}

          {!isMobile && canViewPeople && (
            <Link
              to="/our-people"
              data-test-id="navOurPeople"
              onClick={onLinkClick}
              className={classNames(css.navItem, { [css.selected]: currentPathName.startsWith('/our-people') })}
            >
              <div className={css.navItemContent} data-tip="Our People" data-for="navigationToolTip">
                <People />
                {hasNewFriend > 0 && !showMenu && (
                  <span className={css.miniCircle}>
                    <span className={css.number}>{hasNewFriend}</span>
                  </span>
                )}
                <span>Our People</span>
                {hasNewFriend > 0 && showMenu && (
                  <span className={css.circle}>
                    <span className={css.number}>{hasNewFriend}</span>
                  </span>
                )}
              </div>
            </Link>
          )}

          <Link
            to="/resource"
            data-test-id="navResource"
            onClick={onLinkClick}
            className={classNames(css.navItem, { [css.selected]: currentPathName.startsWith('/resource') })}
            data-appcues-id="resource"
          >
            <div className={css.navItemContent} data-tip="Resource Center" data-for="navigationToolTip">
              <MoveToInboxIcon />
              <span>Resource Center</span>
            </div>
          </Link>

          {canViewPathfinder && (
            <Link
              to={`/pathfinder/?userId=${user.userId}`}
              data-test-id="navPathfinder"
              onClick={onLinkClick}
              className={classNames(css.navItem, { [css.selected]: currentPathName.startsWith('/pathfinder') })}
              data-appcues-id="pathfinder"
            >
              <div className={css.navItemContent} data-tip="Pathfinder" data-for="navigationToolTip">
                <Explore />
                <span>Pathfinder</span>
              </div>
            </Link>
          )}

          {!isMobile && canViewGrades && (
            <Link
              to="/grades"
              data-test-id="navGrades"
              onClick={onLinkClick}
              className={classNames(css.navItem, { [css.selected]: currentPathName.startsWith('/grades') })}
              data-appcues-id="my-grades"
            >
              <div className={css.navItemContent} data-tip="Grades" data-for="navigationToolTip">
                <Grade />
                <span>Grades</span>
              </div>
            </Link>
          )}

          {canViewEcl && (
            <Link
              to="/ecl"
              data-test-id="navEcl"
              onClick={onLinkClick}
              className={classNames(css.navItem, { [css.selected]: currentPathName.startsWith('/ecl') })}
            >
              <div className={css.navItemContent} data-tip="ECL" data-for="navigationToolTip">
                <Running />
                <span>ECL</span>
              </div>
            </Link>
          )}

          {!isMobile && canViewExamPrep && (
            <Link
              to="/test-prep"
              data-test-id="navTestPrep"
              onClick={onLinkClick}
              className={classNames(
                css.navItem,
                { [css.selected]: currentPathName.startsWith('/test-prep') },
                { [css.hideNav]: !hasExams },
              )}
              data-appcues-id="test-prep"
            >
              <div className={css.navItemContent} data-tip="Crimson Testing" data-for="navigationToolTip">
                <Assignment />
                <span>Crimson Testing</span>
              </div>
            </Link>
          )}

          {canViewStudents && (
            <Link
              to="/students"
              data-test-id="navStudents"
              onClick={onLinkClick}
              className={classNames(css.navItem, { [css.selected]: currentPathName.startsWith('/students') })}
              data-appcues-id="my-students"
            >
              <div className={css.navItemContent} data-tip="Students" data-for="navigationToolTip">
                <People />
                {hasNewFriend > 0 && !showMenu && (
                  <span className={css.miniCircle}>
                    <span className={css.number}>{hasNewFriend}</span>
                  </span>
                )}
                <span>Students</span>
                {hasNewFriend > 0 && showMenu && (
                  <span className={css.circle}>
                    <span className={css.number}>{hasNewFriend}</span>
                  </span>
                )}
              </div>
            </Link>
          )}

          {canViewReports && (
            <Link
              to="/reports"
              data-test-id="navReports"
              onClick={onLinkClick}
              className={classNames(css.navItem, { [css.selected]: currentPathName.startsWith('/reports') })}
            >
              <div className={css.navItemContent} data-tip="Reports" data-for="navigationToolTip">
                <Description />
                <span>Reports</span>
                {hasReports && (
                  <i
                    className={classNames('zmdi zmdi-circle', css.indicatorDot, {
                      [css.indicatorDotMenuClosed]: !showMenu,
                    })}
                  />
                )}
              </div>
            </Link>
          )}

          {canViewInvoices && (
            <Link
              to="/invoicing"
              data-test-id="navInvoicingNew"
              onClick={onLinkClick}
              className={classNames(css.navItem, { [css.selected]: currentPathName.startsWith('/invoicing') })}
            >
              <div className={css.navItemContent} data-tip="Invoicing" data-for="navigationToolTip">
                <AttachMoney />
                <span>Invoicing</span>
              </div>
            </Link>
          )}

          {canViewTasks && (
            <Link
              to="/tasks"
              data-test-id="navTasks"
              onClick={onLinkClick}
              className={classNames(css.navItem, { [css.selected]: currentPathName.startsWith('/tasks') })}
              data-appcues-id="tasks"
            >
              <div className={css.navItemContent} data-tip="Tasks" data-for="navigationToolTip">
                <CheckCircle />
                <span>Tasks</span>
              </div>
            </Link>
          )}

          {canViewApplications && (
            <Link
              to="/applications"
              data-test-id="navApplications"
              onClick={onLinkClick}
              className={classNames(css.navItem, { [css.selected]: currentPathName.startsWith('/applications') })}
              data-appcues-id="applications"
            >
              <div className={css.navItemContent} data-tip="Applications" data-for="navigationToolTip">
                <i className={classNames('zmdi zmdi-graduation-cap', css.leftNavIcon)} />
                <span>Applications</span>
              </div>
            </Link>
          )}

          {canViewCGAStudentProgress && (
            <Link
              to="/cga-student-progress-reports"
              data-test-id="navCGAStudentProgress"
              onClick={onLinkClick}
              className={classNames(css.navItem, {
                [css.selected]: currentPathName.startsWith('/cga-student-progress-reports'),
              })}
              data-appcues-id="cga-student-progress-reports"
            >
              <div className={css.navItemContent} data-tip="CGA Student Progress" data-for="navigationToolTip">
                <CGAReports />
                <span>CGA</span>
              </div>
            </Link>
          )}

          {canViewCGAReports && (
            <Link
              to="/cga-reports"
              data-test-id="navCGAReports"
              onClick={onLinkClick}
              className={classNames(css.navItem, { [css.selected]: currentPathName.startsWith('/cga-reports') })}
              data-appcues-id="cga-reports"
            >
              <div className={css.navItemContent} data-tip="CGA Reports" data-for="navigationToolTip">
                <CGAReports />
                <span>CGA Reports</span>
              </div>
            </Link>
          )}

          {canViewDaVinciReports && (
            <Link
              to="/da-vinci-reports"
              data-test-id="navDaVinciReports"
              onClick={onLinkClick}
              className={classNames(css.navItem, { [css.selected]: currentPathName.startsWith('/da-vinci-reports') })}
              data-appcues-id="da-vinci-reports"
            >
              <div className={css.navItemContent} data-tip="Da Vinci Reports" data-for="navigationToolTip">
                <CGAReports />
                <span>Da Vinci Reports</span>
              </div>
            </Link>
          )}

          {canViewUsMentorOps && (
            <Link
              to="/us-mentor-ops"
              data-test-id="navUsMentorOps"
              onClick={onLinkClick}
              className={classNames(css.navItem, { [css.selected]: currentPathName.startsWith('/us-mentor-ops') })}
              data-appcues-id="us-mentor-ops"
            >
              <div className={css.navItemContent} data-tip="US Mentor Ops" data-for="navigationToolTip">
                <TrackChangesIcon />
                <span>US Mentor Ops</span>
              </div>
            </Link>
          )}

          {canViewUsEclOps && (
            <Link
              to="/us-ecl-ops"
              data-test-id="navUsEclOps"
              onClick={onLinkClick}
              className={classNames(css.navItem, { [css.selected]: currentPathName.startsWith('/us-ecl-ops') })}
              data-appcues-id="us-ecl-ops"
            >
              <div className={css.navItemContent} data-tip="US ECL Ops" data-for="navigationToolTip">
                <TrackChangesIcon />
                <span>US ECL Ops</span>
              </div>
            </Link>
          )}

          {!isMobile && (canViewStrategistDashboard || canViewSSMDashboard) && (
            <div className={classNames(css.navItems, css.navBottom)}>
              <MetaBasePopover className={css.navItem} linkClassName={css.navItemContent} onClick={onLinkClick} />
            </div>
          )}
          {canViewContacts && (
            <Link
              to="/contacts"
              data-test-id={Acl.checkRole(loginUserRoles, roleTypes.TUTOR) ? 'navStudents' : 'navContacts'}
              onClick={onLinkClick}
              className={classNames(css.navItem, { [css.selected]: currentPathName.startsWith('/contacts') })}
              data-appcues-id={Acl.checkRole(loginUserRoles, roleTypes.TUTOR) ? 'my-students' : 'contacts'}
            >
              <div
                className={css.navItemContent}
                data-tip={Acl.checkRole(loginUserRoles, roleTypes.TUTOR) ? 'Students' : 'Contacts'}
                data-for="navigationToolTip"
              >
                <People />
                {hasNewFriend > 0 && !showMenu && (
                  <span className={css.miniCircle}>
                    <span className={css.number}>{hasNewFriend}</span>
                  </span>
                )}
                <span>Contacts</span>
                {hasNewFriend > 0 && showMenu && (
                  <span className={css.circle}>
                    <span className={css.number}>{hasNewFriend}</span>
                  </span>
                )}
              </div>
            </Link>
          )}
        </div>

        <div className={classNames(css.navItems, css.navBottom)}>
          {!isMobile && (
            <ProfilePopover
              className={classNames(css.navItem, { [css.selected]: currentPathName.startsWith('/users') })}
              linkClassName={css.navItemContent}
              onClick={onLinkClick}
            />
          )}

          {!isMobile && (
            <div
              role="button"
              tabIndex={0}
              onClick={(e) => {
                props.toggleNotificationMenu(true);
                onLinkClick(e);
              }}
              onKeyDown={handleEnter((e) => {
                props.toggleNotificationMenu(true);
                onLinkClick(e);
              })}
              className={classNames(
                css.navItem,
                { [css.selected]: currentPathName.startsWith('/tasks') },
                { [css.relativePosition]: !showMenu },
              )}
              data-appcues-id="tasks"
            >
              <div className={css.navItemContent} data-tip="Notifications" data-for="navigationToolTip">
                <Notifications />
                <span>Notifications</span>
                {newNotificationCount > 0 && (
                  <i
                    className={classNames('zmdi zmdi-circle', css.indicatorDot, {
                      [css.indicatorDotMenuClosed]: !showMenu,
                    })}
                  />
                )}
              </div>
            </div>
          )}

          {!isMobile && (
            <SwitchAppPopover
              className={css.navItem}
              linkClassName={css.navItemContent}
              onClick={onLinkClick}
              loginUserPermissions={loginUserPermissions}
            />
          )}

          {!config.dulwich && (
            <a
              role="button"
              tabIndex={0}
              onClick={(e) => {
                zE.activate();
                onLinkClick(e);
              }}
              onKeyDown={handleEnter((e) => {
                zE.activate();
                onLinkClick(e);
              })}
              className={css.navItem}
            >
              <div className={css.navItemContent} data-tip="Support" data-for="navigationToolTip">
                <Email />
                <span>Support</span>
              </div>
            </a>
          )}
          {!config.dulwich && (
            <a
              role="button"
              tabIndex={0}
              onClick={() => window.open('https://crimsonapp.zendesk.com/', '_blank')}
              onKeyDown={handleEnter(() => window.open('https://crimsonapp.zendesk.com/', '_blank'))}
              className={css.navItem}
            >
              <div className={css.navItemContent} data-tip="Help Desk" data-for="navigationToolTip">
                <ContactSupport />
                <span>Help Desk</span>
              </div>
            </a>
          )}

          {isMobile && (
            <Link
              onClick={onLinkClick}
              to="/settings"
              data-test-id="navSettings"
              className={classNames(css.navItem, { [css.selected]: currentPathName.startsWith('/settings') })}
              data-appcues-id="settings"
            >
              <div className={css.navItemContent} data-tip="Settings" data-for="navigationToolTip">
                <Settings />
                <span>Settings</span>
              </div>
            </Link>
          )}
          {isMobile && (
            <Link
              onClick={onLinkClick}
              data-test-id="navLogout"
              to="/logout"
              className={classNames(css.navItem, { [css.selected]: currentPathName.startsWith('/logout') })}
              data-appcues-id="logout"
            >
              <div className={css.navItemContent} data-tip="Logout" data-for="navigationToolTip">
                <ExitToApp />
                <span>Logout</span>
              </div>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}

Navigation.propTypes = {
  app: PropTypes.object,
  user: PropTypes.object,
  loginUserPermissions: PropTypes.array.isRequired,
  loginUserRoles: PropTypes.array.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  roadmapId: PropTypes.string,
  lastThread: PropTypes.string,
  hasReports: PropTypes.bool,
  location: PropTypes.object.isRequired,
  hasExams: PropTypes.bool,
  showMenu: PropTypes.bool,
  isCalendarView: PropTypes.bool,
  toggleNotificationMenu: PropTypes.func,
  newNotificationCount: PropTypes.number,
  hasUnReadMessage: PropTypes.bool,
  hasNewFriend: PropTypes.number,
};
