import Immutable from 'immutable';
import createReducer from 'utils/createReducer';
import { toasterMessages } from '@crimson-education/common-config';
import lessonService from 'graphql/api/lesson';
import { formLoading, formLoaded, formSuccess, formFail, updateMeta } from 'ducks/meta';
import { processLessons, processLesson } from 'utils/lessonUtils';
import { ADD_ENTITIES, addEntitiesWithNormalisation } from 'ducks/normalizr';
import componentKeys from 'constants/componentKeys';
import { lessonEntity } from 'schema';

const initialState = Immutable.fromJS({});

export default createReducer(initialState, {
  [ADD_ENTITIES]: (state, action) => {
    return state.mergeDeep(action.payload.entities.lesson);
  },
});

export function fetchLessons(clientId, tutorId, subjectId) {
  return (dispatch) => {
    return lessonService
      .fetchLessons(subjectId, clientId, tutorId)
      .then((res) => {
        dispatch(addEntitiesWithNormalisation(processLessons(res.allLessons), [lessonEntity]));
      })
      .catch(() => {
        dispatch(formFail('Could not fetch lessons'));
      });
  };
}

export function fetchLessonsBySubjectId(subjectId, penalty) {
  return (dispatch) => {
    dispatch(updateMeta(componentKeys.LESSONS_FETCHED, false));
    return lessonService
      .fetchLessonsByItemId(subjectId, penalty)
      .then((res) => {
        dispatch(addEntitiesWithNormalisation(processLessons(res.allLessons), [lessonEntity]));
        dispatch(updateMeta(componentKeys.LESSONS_FETCHED, true));
      })
      .catch(() => {
        dispatch(formFail('Could not fetch lesson'));
      });
  };
}

export function fetchLessonById(lessonId) {
  return (dispatch) => {
    return lessonService
      .fetchLessonById(lessonId)
      .then((res) => {
        dispatch(addEntitiesWithNormalisation(processLesson(res.lesson), lessonEntity));
      })
      .catch(() => {
        dispatch(formFail('Could not fetch lesson'));
      });
  };
}

export function fetchLessonsByEventId(eventId) {
  return (dispatch) => {
    return lessonService
      .fetchLessonsByEventId(eventId)
      .then((res) => {
        dispatch(addEntitiesWithNormalisation(processLessons(res.allLessons), [lessonEntity]));
      })
      .catch(() => {
        dispatch(formFail('Could not fetch lesson'));
      });
  };
}

export function editLessonById(lessonId, lesson) {
  return (dispatch) => {
    dispatch(formLoading());
    return lessonService
      .editLessonById(lessonId, lesson.title, lesson.summary)
      .then((res) => {
        if (res.errors || !res.lesson) {
          dispatch(formFail(res.errors[0].message));
        } else {
          dispatch(formSuccess(toasterMessages.lessonEdited()));
          dispatch(addEntitiesWithNormalisation(processLesson(res.lesson), lessonEntity));
        }
      })
      .catch(() => {
        dispatch(formFail(toasterMessages.lessonNotEdited()));
      });
  };
}

export function navigateToLessonFromBooking(bookingId, history) {
  if (!bookingId) {
    return () => Promise.reject(new Error('Cannot navigate to lesson with invalid parameters'));
  }
  return (dispatch) => {
    dispatch(formLoading());
    return lessonService
      .fetchLessonsByEventId(bookingId)
      .then((res) => {
        if (res.allLessons && res.allLessons[0]) {
          dispatch(formLoaded());
          history.replace(`/lessons/${res.allLessons[0].id}`);
          return;
        }
        dispatch(formFail(toasterMessages.lessonNavigationFailed()));
      })
      .catch(() => {
        dispatch(formFail(toasterMessages.lessonNavigationFailed()));
      });
  };
}
