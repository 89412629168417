export const CommentFieldHeadings = [
  { key: 'goal', label: 'What was the goal of this session?' },
  { key: 'content', label: 'What did you cover in this session?' },
  { key: 'progress', label: 'Is your student making progress? Please provide detail.' },
  { key: 'todo', label: 'What does your student need to do for the next session?' },
];

export const RatingHeadings = [
  {
    key: 'assignmentCompletion',
    label: 'Assignment Completion',
    buttons: ['A', 'B', 'C', 'N/A'],
    criteria: [
      'The student completed all assigned work with \n diligence. Action Plan was closely followed.',
      'The student completed some assigned work to \n expected standards. Action Plan was followed.',
      'The student did not complete assigned work and has \n not adhered to Action Plan.',
      'No requirement to complete an assignment.',
    ],
  },
  {
    key: 'enthusiasm',
    label: 'Enthusiasm for Learning',
    buttons: ['A', 'B', 'C', 'D'],
    criteria: [
      'The student is proactive and focused during \n lesson. Student is motivated to learn.',
      'The student shows interest in the learning \n process but is sometimes disengaged.',
      'The student is often disengaged.',
      'The student is passive and does not take initiative \n in the learning process.',
    ],
  },
  {
    key: 'behavior',
    label: 'Conduct and Behaviour',
    buttons: ['A', 'B', 'C', 'D'],
    criteria: [
      'The student is polite and courteous, and clearly \n communicates needs in a timely manner.',
      'The student is cooperative and complies with \n instructions.',
      'The student’s work ethic has room for improvement. \n Student does not communicate needs in time.',
      'The student is unresponsive or discourteous, impeding \n the learning process.',
    ],
  },
];
