import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { isNotEmpty } from 'utils/validation';
import Datepicker from 'components/molecules/Datepicker';

import css from '../styles.scss';
import {
  TextField,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
  Button,
  Divider,
} from '@material-ui/core';

const ReportDataInput = (props) => {
  const { setReportState } = props;

  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [preparedDate, setPreparedDate] = useState(new Date());
  const [studentFirstNameLegal, setStudentFirstNameLegal] = useState('');
  const [studentMiddleNameLegal, setStudentMiddleNameLegal] = useState('');
  const [studentLastNameLegal, setStudentLastNameLegal] = useState('');
  const [studentFirstNamePreferred, setStudentFirstNamePreferred] = useState('');
  const [studentMiddleNamePreferred, setStudentMiddleNamePreferred] = useState('');
  const [studentLastNamePreferred, setStudentLastNamePreferred] = useState('');
  const [studentId, setStudentId] = useState('');
  const [curriculumType, setCurriculumType] = useState('');
  const [subjectName, setSubjectName] = useState('');
  const [attendanceType, setAttendanceType] = useState('Da Vinci');
  const [teacherName, setTeacherName] = useState('');
  const [examinationGrade, setExaminationGrade] = useState('');
  const [sessionEngagement, setSessionEngagement] = useState('');
  const [homework, setHomework] = useState('');
  const [comment, setComment] = useState('');

  const validInput = useMemo(
    () =>
      isNotEmpty(title) &&
      isNotEmpty(subtitle) &&
      isNotEmpty(preparedDate) &&
      isNotEmpty(studentFirstNameLegal) &&
      isNotEmpty(studentLastNameLegal) &&
      isNotEmpty(curriculumType) &&
      isNotEmpty(subjectName) &&
      isNotEmpty(attendanceType) &&
      isNotEmpty(teacherName) &&
      isNotEmpty(examinationGrade) &&
      isNotEmpty(sessionEngagement) &&
      isNotEmpty(homework) &&
      isNotEmpty(comment),
    [
      comment,
      examinationGrade,
      curriculumType,
      homework,
      preparedDate,
      sessionEngagement,
      studentFirstNameLegal,
      studentLastNameLegal,
      subjectName,
      attendanceType,
      subtitle,
      teacherName,
      title,
    ],
  );

  const clearState = () => {
    setTitle('');
    setSubtitle('');
    setPreparedDate(new Date());
    setStudentFirstNameLegal('');
    setStudentMiddleNameLegal('');
    setStudentLastNameLegal('');
    setStudentFirstNamePreferred('');
    setStudentId('');
    setCurriculumType('');
    setSubjectName('');
    setAttendanceType('Da Vinci');
    setTeacherName('');
    setExaminationGrade('');
    setSessionEngagement('');
    setHomework('');
    setComment('');
  };

  return (
    <div className={css.inputColumn}>
      <div>
        <div className={css.dataInputTitleContainer}>
          <Typography className={css.dataInputTitle} variant="h3">
            Data Input
          </Typography>
          <Button variant="contained" color="secondary" onClick={() => clearState()}>
            Clear Data
          </Button>
        </div>
      </div>
      <Divider />
      <div className={css.inputSection}>
        <Typography variant="h4">Front Page</Typography>
        <TextField
          variant="outlined"
          error={!isNotEmpty(title)}
          helperText="Required"
          onChange={(e) => setTitle(e.target.value)}
          label="Title"
          value={title}
          placeholder="First Report"
        />
        <TextField
          variant="outlined"
          error={!isNotEmpty(subtitle)}
          helperText="Required"
          onChange={(e) => setSubtitle(e.target.value)}
          label="Subtitle"
          value={subtitle}
          placeholder="X/50 Sessions Completed"
        />
        <Datepicker
          isValid={isNotEmpty(preparedDate)}
          allowEmpty={false}
          onChange={(date) => setPreparedDate(date.toDate())}
          label="Date of Report"
          value={preparedDate}
        />
      </div>
      <Divider />
      <div className={css.inputSection}>
        <Typography variant="h4">Student Information</Typography>
        <TextField
          variant="outlined"
          error={!isNotEmpty(studentFirstNameLegal)}
          helperText="Required"
          onChange={(e) => setStudentFirstNameLegal(e.target.value)}
          label="First Name (Legal)"
          value={studentFirstNameLegal}
          placeholder="Rebecca"
        />
        <TextField
          variant="outlined"
          onChange={(e) => setStudentMiddleNameLegal(e.target.value)}
          label="Middle Name(s) (Legal)"
          value={studentMiddleNameLegal}
          placeholder="Jane"
        />
        <TextField
          variant="outlined"
          error={!isNotEmpty(studentLastNameLegal)}
          helperText="Required"
          onChange={(e) => setStudentLastNameLegal(e.target.value)}
          label="Last Name (Legal)"
          value={studentLastNameLegal}
          placeholder="Doe"
        />
        <TextField
          variant="outlined"
          onChange={(e) => setStudentFirstNamePreferred(e.target.value)}
          label="First Name (Preferred)"
          value={studentFirstNamePreferred}
          placeholder="Becca"
        />
        <TextField
          variant="outlined"
          onChange={(e) => setStudentMiddleNamePreferred(e.target.value)}
          label="Middle Name(s) (Preferred)"
          value={studentMiddleNamePreferred}
        />
        <TextField
          variant="outlined"
          onChange={(e) => setStudentLastNamePreferred(e.target.value)}
          label="Last Name (Preferred)"
          value={studentLastNamePreferred}
        />
        <TextField
          variant="outlined"
          onChange={(e) => setStudentId(e.target.value)}
          label="Student ID"
          value={studentId}
          placeholder="rdoe101"
        />
      </div>
      <Divider />
      <div className={css.inputSection}>
        <FormControl fullWidth>
          <InputLabel variant="outlined">Curriculum</InputLabel>
          <Select
            variant="outlined"
            error={!isNotEmpty(curriculumType)}
            label="Curriculum"
            value={curriculumType}
            onChange={(e) => setCurriculumType(e.target.value)}
          >
            <MenuItem value="US">US</MenuItem>
            <MenuItem value="EdExcel">EdExcel</MenuItem>
            <MenuItem value="iPrimary">iPrimary</MenuItem>
          </Select>
          <FormHelperText>Required</FormHelperText>
        </FormControl>
      </div>
      <Divider />
      <div className={css.inputSection}>
        <Typography variant="h4">Subject Information</Typography>
        <TextField
          variant="outlined"
          error={!isNotEmpty(subjectName)}
          helperText="Required"
          onChange={(e) => setSubjectName(e.target.value)}
          label="Subject Name"
          value={subjectName}
          placeholder="IA2 Biology"
        />
        <TextField
          variant="outlined"
          error={!isNotEmpty(attendanceType)}
          helperText="Required"
          onChange={(e) => setAttendanceType(e.target.value)}
          label="Attendance Type"
          value={attendanceType}
        />
        <TextField
          variant="outlined"
          error={!isNotEmpty(teacherName)}
          helperText="Required"
          onChange={(e) => setTeacherName(e.target.value)}
          label="Teacher Name"
          value={teacherName}
          placeholder="Adam Smith"
        />
        <TextField
          variant="outlined"
          error={!isNotEmpty(examinationGrade)}
          helperText="Required"
          onChange={(e) => setExaminationGrade(e.target.value)}
          label="Examination Grade"
          value={examinationGrade}
          placeholder="A"
        />
        <FormControl fullWidth>
          <InputLabel variant="outlined">Session Engagement</InputLabel>
          <Select
            variant="outlined"
            error={!isNotEmpty(sessionEngagement)}
            label="Session Engagement"
            value={sessionEngagement}
            onChange={(e) => setSessionEngagement(e.target.value)}
          >
            <MenuItem value="CauseForConcern">(C) Cause for Concern</MenuItem>
            <MenuItem value="Satisfactory">(S) Satisfactory</MenuItem>
            <MenuItem value="Good">(G) Good</MenuItem>
            <MenuItem value="Excellent">(E) Excellent</MenuItem>
          </Select>
          <FormHelperText>Required</FormHelperText>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel variant="outlined">Homework Grade</InputLabel>
          <Select
            variant="outlined"
            error={!isNotEmpty(homework)}
            label="Homework Grade"
            value={homework}
            onChange={(e) => setHomework(e.target.value)}
          >
            <MenuItem value="NeverDoneWhenChecked">(0) Never Done When Checked</MenuItem>
            <MenuItem value="SometimesAttempted">(1) Sometimes Attempted</MenuItem>
            <MenuItem value="MostTasksCompleted">(2) Most Tasks Completed</MenuItem>
            <MenuItem value="AllTasksCompleted">(3) All Tasks Completed</MenuItem>
          </Select>
          <FormHelperText>Required</FormHelperText>
        </FormControl>
        <div>
          <TextField
            variant="outlined"
            error={!isNotEmpty(comment)}
            multiline
            fullWidth
            minRows={4}
            helperText="Required"
            onChange={(e) => setComment(e.target.value)}
            value={comment}
            label="Teacher Comments And Recommendations"
            placeholder={'Rebecca is a great student.'}
          />
        </div>
      </div>
      <Divider />
      <div className={css.buttonContainer}>
        <Button
          variant="contained"
          color="primary"
          disabled={!validInput}
          onClick={() => {
            setReportState({
              title: title,
              subtitle: subtitle,
              preparedDate: preparedDate,
              student: {
                publicId: studentId,
                name: {
                  firstNameLegal: studentFirstNameLegal,
                  middleNamesLegal: studentMiddleNameLegal,
                  lastNameLegal: studentLastNameLegal,
                  firstNamePreferred: studentFirstNamePreferred,
                  middleNamesPreferred: studentMiddleNamePreferred,
                  lastNamePreferred: studentLastNamePreferred,
                },
              },
              subjects: [
                {
                  name: subjectName,
                  curriculumType: curriculumType,
                  attendanceType: attendanceType,
                  teachers: [teacherName],
                  comments: [comment],
                  examinationGrade: examinationGrade,
                  sessionEngagement: sessionEngagement,
                  homework: homework,
                },
              ],
            });
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
        >
          Generate Report PDF
        </Button>
        <Typography variant="body2">
          This may take a minute or two. Please be patient and do not close the page.
        </Typography>
      </div>
    </div>
  );
};

ReportDataInput.propTypes = {
  reportState: PropTypes.object,
  setReportState: PropTypes.func,
};

export default ReportDataInput;
