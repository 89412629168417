import { StyledButton } from 'components/organisms/MissionControlUpdates/GetUpdatesButton/style';
import React, { useState } from 'react';
import styled from 'styled-components';

const SessionContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100vh;
  background: #f3f6fa;
  position: fixed;
  right: 0px;
  bottom: 0;
  z-index: 1200 !important;
  border-radius: 8px 8px 0px 0px;
  box-shadow: 1px -5px 50px rgb(115 116 125 / 25%);
`;

const McUpdatesContainer = styled.div`
  background: white;
  height: 100vh;
  width: 570px;
`;

const FloatingSessionContainer = styled.div`
  height: 100vh;
  width: 600px;
`;

export const AutomaticFloatingSession = (): JSX.Element => {
  const [visibleMcUpdates, setMcUpdatesVisible] = useState(false);
  return (
    <SessionContainer>
      {visibleMcUpdates && (
        <McUpdatesContainer onClick={() => setMcUpdatesVisible(false)}>McUpdatesContainer</McUpdatesContainer>
      )}
      <FloatingSessionContainer>
        Session container
        {!visibleMcUpdates && (
          <StyledButton onClick={() => setMcUpdatesVisible(true)}>Get updates from Mission Control</StyledButton>
        )}
      </FloatingSessionContainer>
    </SessionContainer>
  );
};
