import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from 'components/molecules/Autocomplete/Default';
import missionType from 'constants/missionType';
import RoadmapCategoryLabel from 'components/molecules/RoadmapCategoryLabel';
import colours from 'components/atoms/colours.scss';
import InputWarningMessage from 'components/molecules/InputWarningMessage';
import classNames from 'classnames';

import css from './styles.scss';

const missionRenderer = (item, isHighlighted) => {
  const { id, name, category, dateText } = item;

  return (
    <div
      key={id}
      data-test-id={`roadmapCategoryLabel-${name}`}
      className={classNames(css.menuItem, { [css.highlighted]: isHighlighted })}
    >
      <RoadmapCategoryLabel
        mission={name}
        category={category ? category.name : ''}
        categoryColor={category ? category.color : colours.primary}
        dateText={dateText}
        className={css.roadmapCategoryLabelContainer}
      />
    </div>
  );
};

const matchStateToSearchTerm = (state, value) => {
  return (
    state.type === missionType.MISSION &&
    (state.name.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
      state.category.name.toLowerCase().indexOf(value.toLowerCase()) !== -1)
  );
};

export default class SearchMission extends Component {
  constructor(props) {
    super(props);

    this.state = {
      missionSearchValue: props.missionSelected ? props.missionSelected.name : '',
      missionSelected: props.missionSelected,
    };

    this.onClearAutocomplete = this.onClearAutocomplete.bind(this);
    this.onChangeMission = this.onChangeMission.bind(this);
    this.onSelectMission = this.onSelectMission.bind(this);
  }

  onChangeMission(_, value) {
    this.setState({ missionSearchValue: value, missionSelected: null });
  }

  onSelectMission(value, mission) {
    const { onMissionSelected } = this.props;
    this.setState({ missionSearchValue: value, missionSelected: mission });
    onMissionSelected(mission);
  }

  onClearAutocomplete() {
    const { onMissionSelected } = this.props;
    this.setState({ missionSearchValue: '', missionSelected: null });
    onMissionSelected(null);
  }

  render() {
    const { missions, dataTestId, isValid, validationMessage } = this.props;
    const { missionSearchValue } = this.state;

    return (
      <React.Fragment>
        <Autocomplete
          error={!isValid}
          inputDataTestId={dataTestId}
          getItemValue={(mission) => mission.name}
          items={missions || []}
          value={missionSearchValue}
          onSelect={this.onSelectMission}
          onChange={this.onChangeMission}
          renderItem={missionRenderer}
          keySelector="id"
          placeholder="No mission selected"
          disabled={false}
          onClear={this.onClearAutocomplete}
          shouldItemRender={matchStateToSearchTerm}
          hasMore={false}
          emptyText="No missions available."
        />
        {!isValid && <InputWarningMessage message={validationMessage} />}
      </React.Fragment>
    );
  }
}

SearchMission.defaultProps = {
  isValid: true,
};

SearchMission.propTypes = {
  fetchRoadmapByUserId: PropTypes.func,
  onMissionSelected: PropTypes.func,
  missions: PropTypes.array,
  missionSelected: PropTypes.object,
  dataTestId: PropTypes.string,
  isValid: PropTypes.bool,
  validationMessage: PropTypes.string,
};
