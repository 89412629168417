import { Application, ApplicationApproval, SessionReport, ApplicationRegion, AppYearStudent } from '../../type';
import moment, { Moment } from 'moment';
import { mapApplicationRegion } from '../../util';
import { StaffDashboardRegion } from '../type';
import Holidays from 'date-holidays';

let __today = moment();
export const __setToday = (d: Moment) => {
  __today = d;
};
export const TODAY = () => {
  return __today;
};

type EventPriority = 0 | 1 | 2 | 3;
export type EventRegionType = StaffDashboardRegion | '*' | StaffDashboardRegion[];
export type Event<T = string[]> = {
  priority: EventPriority;
  label: string;
  value: string;
  region: EventRegionType;
  filterInput?: (input: DataInputs, event: Event) => DataInputs;
  filterAppYearStudents?: (students: AppYearStudent[]) => AppYearStudent[];
  availability: () => boolean;
  resolve(dataInputs: DataInputs): T;
};
/**
 *
 *
 * @param {string} dateLike
 * @return {*}
 * normalize string date
 */
export const stringToMoment = (dateLike?: string) => {
  if (!dateLike) return TODAY();
  try {
    const dates = dateLike.split('-').map((o) => +o);
    if (dates.some((o) => isNaN(o))) return TODAY();
    if (![2, 3].includes(dates.length)) return TODAY();
    const reversed = dates.reverse();
    const unit = reversed.reduce((prev, curr, idx) => {
      if (idx === 0) return { ...prev, date: curr, year: currentYear() };
      if (idx === 1) return { ...prev, month: curr - 1 };
      if (idx === 2) return { ...prev, year: curr };
      return prev;
    }, {});
    const _moment = moment();
    _moment.set(unit);
    return _moment;
  } catch (err) {
    return TODAY();
  }
};
export const currentYear = () => TODAY().year();
// April -> 4
export const currentMonth = () => TODAY().month() + 1;
/**
 *
 *
 * App Year. e.g. before 2022-09-01, app year = 2021
 *  after 2022-09-01, app year = 2022
 */
export const appYear = () => {
  const currYear = currentYear();
  const isAfterSep1 = isInDateRange('09-01');
  return isAfterSep1 ? currYear : currYear - 1;
};
/**
 *
 *
 * Intake Year. e.g. before 2023-09-01 intake year = 2023
 * after 2023-09-01 intake year = 2024
 */
export const intakeYear = () => {
  const currYear = currentYear();
  const isAfterSep1 = isInDateRange('09-01');
  return isAfterSep1 ? currYear + 1 : currYear;
};
/**
 *
 *
 * @param {string} startDay MM-DD / YYYY-MM-DD
 * @param {string} endDay   MM-DD / YYYY-MM-DD
 */
export const isInDateRange: {
  (startDay: string): boolean;
  (startDay: undefined, endDay: string): boolean;
  (startDay: string, endDay: string): boolean;
} = (startDay?: string, endDay?: string) => {
  if (!startDay && !endDay) return false;
  const _startDay = stringToMoment(startDay);
  const isAfterStartDay = TODAY().isSameOrAfter(_startDay, 'day');
  const _endDay = stringToMoment(endDay);
  const isBeforeEndDay = TODAY().isBefore(_endDay, 'day');
  // check if today is after startDay
  if (!!startDay && !endDay) return isAfterStartDay;
  // check if today is before endDay
  if (!!endDay && !startDay) return isBeforeEndDay;
  if (_startDay.format('YYYY-MM-DD') === _endDay.format('YYYY-MM-DD')) return true;
  // check if today is between startDay and endDay
  return isAfterStartDay && isBeforeEndDay;
};

/**
 *
 *
 * @param {number[]} months
 * e.g. April -> 4
 */
export const isInMonths = (months: number[]) => {
  return months.includes(TODAY().month() + 1);
};

/**
 *
 *
 * @param {AppYearStudent[]} students
 * @param {string} [afterDate]  should after 09-01
 * @return {*}
 *
 * get App Year students
 * e.g. before 2022-09-01, will return students with appy year 2021/2022, 2022/2023, app year students will be 2021/2022
 * after 2022-09-01, and before 2023-09-01, app year students will be 2022/2023
 */
export const getAppYearStudents = (students: AppYearStudent[], afterDate?: string) => {
  // if afterDate is before 09-01, return
  if (afterDate && stringToMoment(afterDate).isBefore(stringToMoment('09-01'))) return students;
  const _isAfter = isInDateRange(afterDate || '09-01');
  const appYear = _isAfter ? `${currentYear()}/` : `${currentYear() - 1}/`;
  return students.filter((o) => o.studentInfo?.yearOfApplication?.includes(appYear));
};

export const getStudentsWithStartYear = (students: AppYearStudent[], startYear: number) => {
  return students.filter((o) => o.studentInfo.yearOfApplication.includes(`${startYear}/`));
};

export type DataInputs = {
  approvals: ApplicationApproval[];
  applications: Application[];
  sessionReports: SessionReport[];
  studentUserIds: string[];
  region: ApplicationRegion;
  appYearStudents: AppYearStudent[];
};
/**
 *
 *
 * @template T
 * @param {T[]} data
 * @param {string[]} studentUserIds
 * @param {(item: T) => boolean} shouldIncrease
 * @param {keyof T} [userIdKey='userId']
 * @return {*}  {Record<string, number>}
 * Loop data, check if item is matched, if true, increase userCount[userId]
 */
export const processor = <T extends Record<string, any>>(
  data: T[],
  studentUserIds: string[],
  shouldIncrease: (item: T) => boolean,
  userIdKey: keyof T = 'userId',
  skipIfUserIdNotInList = true,
): Record<string, number> => {
  const userCount: Record<string, number> = {};
  studentUserIds.forEach((userId) => (userCount[userId] = 0));
  data.forEach((item) => {
    if (shouldIncrease(item)) {
      const userId = item[userIdKey];
      if (typeof userCount[userId] !== 'number') {
        if (skipIfUserIdNotInList) {
          return;
        } else {
          userCount[userId] = 0;
        }
      }
      userCount[userId] += 1;
    }
  });
  return userCount;
};

/**
 *
 *
 * @param {Record<string, number>} userCountMap
 * @param {(value: number) => boolean} [desiredValue=(v) => !!v]
 * @return {*}
 * filter keys of userCountMap, default return keys that have value === 0
 */
export const filterUserCountMap = (
  userCountMap: Record<string, number>,
  desiredValue: (value: number) => boolean = (v) => !v,
) => {
  return Object.keys(userCountMap).filter((key) => desiredValue(userCountMap[key]));
};

type InputFilter = (input: DataInputs, event: Event) => DataInputs;
const emptyInputFilter: InputFilter = (arg1, arg2) => arg1;

const filterByRegion: InputFilter = (input, event) => {
  const _filter = <T>(data: T[], region: EventRegionType, getItemRegion: (item: T) => string) => {
    return data.filter((item) => {
      if (region === '*') return true;
      const itemRegion = getItemRegion(item);
      if (Array.isArray(region)) return region.includes(itemRegion as StaffDashboardRegion);
      return itemRegion === region;
    });
  };
  const { applications, approvals } = input;
  return {
    ...input,
    applications: _filter(applications, event.region, mapApplicationRegion),
    approvals: _filter(approvals, event.region, (item) => item.region),
  };
};

const filterAppYearStudents: InputFilter = (input, event) => {
  if (!event.filterAppYearStudents) return input;
  const filteredStudents = event.filterAppYearStudents(input.appYearStudents);
  const studentUserIds = filteredStudents.map((o) => o.userId);
  return {
    ...input,
    studentUserIds,
    appYearStudents: filteredStudents,
  };
};

export const resolveEvent = (input: Omit<DataInputs, 'studentUserIds'>) => async <T>(event: Event<T>) => {
  const _inputData = {
    ...input,
    studentUserIds: input.appYearStudents.map((o) => o.userId),
  };
  const _input = [filterAppYearStudents, filterByRegion, event.filterInput].reduce(
    (prev, curr) => (curr ? curr(prev, event as Event<any>) : prev),
    _inputData,
  );
  return event.resolve(_input);
};
export const getHolidaysInNextXDays = (country: string, nextXDays: number) => {
  try {
    const hd = new Holidays({
      country,
    });
    const today = TODAY();
    let count = 0;
    for (let i = 0; i < nextXDays; i++) {
      if (!!hd.isHoliday(today.add(i, 'day').toDate())) count += 1;
    }
    return count;
  } catch (err) {
    return 0;
  }
};
