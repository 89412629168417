import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectUserProfile } from 'selectors/meta';
import { roleTypes } from '@crimson-education/common-config';

import { withAppContext } from 'components/enhancers/AppContext';
import Unauthorised from 'components/pages/Unauthorised';
import CGAReportsProvider from './context';
import ReportViewPage from './components/Page';

const ReportView = (props) => {
  const loginUser = useSelector(selectUserProfile);

  const isAuthorised = loginUser
    .get('roleInfo')
    .find((r) => [roleTypes.SUPER_ADMIN, roleTypes.CGA_STAFF].includes(r.get('roleId')));

  if (!isAuthorised) {
    return <Unauthorised />;
  }

  return (
    <CGAReportsProvider>
      <ReportViewPage app={props.app} />
    </CGAReportsProvider>
  );
};

ReportView.propTypes = {
  app: PropTypes.object,
};

export default withAppContext(ReportView);
