import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background: #f3f6fa;
  width: 100vw;
  height: 100vh;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 64px;
  align-items: center;
  background-color: #464ac9;
  padding: 0 30px;
  img:first-child {
    width: 75px;
    cursor: pointer;
  }
`;

export const ContentContainer = styled.div`
  max-width: 620px;
  margin: 15vh auto;
  display: flex;
  flex-direction: column;
`;
