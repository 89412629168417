import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';

import css from './styles.scss';

const simpleSingular = (str, num) => (num === 1 ? str.slice(0, -1) : str);

const prettyPrintDuration = (duration) => {
  const units = ['years', 'months', 'days', 'hours', 'minutes', 'seconds'];
  const components = units.map((u) => [duration.get(u), u]);
  const englishRules = components.map(([num, str]) => [num, simpleSingular(str, num)]);

  const withoutZeros = englishRules.filter(([num]) => num > 0);
  const twoUnitsOfPrecision = withoutZeros.slice(0, 2);

  const prettyPrint = twoUnitsOfPrecision.map((arr) => arr.join(' ')).join(' ');

  return prettyPrint;
};

const CountdownSimple = ({ end, bold, className, onFinish }) => {
  const now = moment();
  const duration = moment.duration(moment(end).diff(now));

  const [display, updateDisplay] = React.useState(prettyPrintDuration(duration));
  const [finished, setFinished] = React.useState(false);

  React.useEffect(() => {
    if (onFinish && !finished && duration.asMilliseconds() < 0) {
      setFinished(true);
      onFinish();
    }
  }, [onFinish, duration, finished, setFinished]);

  React.useEffect(() => {
    const timer = setTimeout(() => updateDisplay(prettyPrintDuration(duration)), 1000);
    return () => clearTimeout(timer);
  });

  return <span className={classNames(css.countdownSimple, { [css.bold]: bold }, className)}>{display}</span>;
};

CountdownSimple.defaultProps = {
  end: moment().add(1, 'minutes'),
};

CountdownSimple.propTypes = {
  end: PropTypes.object,
  onFinish: PropTypes.func,
  className: PropTypes.string,
  bold: PropTypes.bool,
};

export default CountdownSimple;
