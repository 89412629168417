import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ErrorIcon from 'components/generic/Icons/Error';
import CriteriaTooltip from 'components/generic/CriteriaTooltip';
import RadioButtonArray from 'components/generic/RadioButtonArray';
import CommentField from 'components/generic/Forms/CommentField';
import Modal from 'components/molecules/Modal';
import css from './styles.scss';

import { CommentFieldHeadings, RatingHeadings } from './data';

const SUBMIT_BUTTON_TEXT = 'Save summary';
const SESSION_REPORT_HEADING = 'SESSION REPORT';

const RatingMap = {
  A: 3,
  B: 2,
  C: 1,
  D: 0,
  'N/A': -1,
};

export default class SessionReport extends Component {
  constructor() {
    super();
    this.state = {
      submitted: false,
      selectedRating: {
        assignmentCompletion: {
          value: '',
          isOpen: false,
        },
        enthusiasm: {
          value: '',
          isOpen: false,
        },
        behavior: {
          value: '',
          isOpen: false,
        },
      },
      commentText: {
        goal: '',
        content: '',
        progress: '',
        todo: '',
      },
    };
    this.onChangeCommentText = this.onChangeCommentText.bind(this);
    this.selectRating = this.selectRating.bind(this);
    this.togglePopover = this.togglePopover.bind(this);
    this.checkFields = this.checkFields.bind(this);
  }

  onChangeCommentText(key, val) {
    const commentText = Object.assign({}, this.state.commentText);
    commentText[key] = val;
    this.setState({ commentText });
  }

  selectRating(key, val) {
    const selectedRating = Object.assign({}, this.state.selectedRating);
    selectedRating[key].value = val;
    this.setState({ selectedRating });
  }

  togglePopover(key, state) {
    const selectedRating = Object.assign({}, this.state.selectedRating);
    selectedRating[key].isOpen = state;
    this.setState({ selectedRating });
  }

  checkFields() {
    const { commentText, selectedRating } = this.state;
    let error = true;
    Object.keys(commentText).forEach((key) => {
      if (!commentText[key].length) error = false;
    });
    Object.keys(selectedRating).forEach((key) => {
      if (!selectedRating[key].value) error = false;
    });
    return error;
  }

  render() {
    const { submitted, commentText, selectedRating } = this.state;
    const { isOpen, onSubmit, studentId, eventId, tutorId, type } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        title={SESSION_REPORT_HEADING}
        submitText={SUBMIT_BUTTON_TEXT}
        isSubmitDisabled={!selectedRating}
        onSubmit={() => {
          this.setState({ submitted: true });
          if (this.checkFields()) {
            const report = {
              goal: commentText.goal,
              content: commentText.content,
              progress: commentText.progress,
              todo: commentText.todo,
              assignmentCompletion: RatingMap[selectedRating.assignmentCompletion.value],
              enthusiasm: RatingMap[selectedRating.enthusiasm.value],
              behavior: RatingMap[selectedRating.behavior.value],
            };
            onSubmit(studentId, tutorId, eventId, report, type);
          }
        }}
      >
        <div className={css.feedback}>
          {CommentFieldHeadings.map(({ label, key }) => {
            return (
              <div key={key}>
                <CommentField
                  autoSize
                  required
                  className={classNames({
                    [css.commentBox]: true,
                    [css.commentInvalid]: submitted && !commentText[key].length,
                  })}
                  key={key}
                  label={label}
                  onValueChange={(val) => this.onChangeCommentText(key, val)}
                />
                <div className={css.errorMessageContainer}>
                  {submitted && !commentText[key].length && (
                    <div className={css.errorMessage}>
                      <ErrorIcon className={css.alertIcon} />
                      {'Please complete this field'}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
          {RatingHeadings.map(({ buttons, key, label, criteria }) => {
            return (
              <div key={key}>
                <RadioButtonArray
                  name={label}
                  stateKey={key}
                  key={key}
                  selectRating={this.selectRating}
                  selectedRating={selectedRating[key].value}
                  buttons={buttons}
                >
                  <CriteriaTooltip
                    isOpen={selectedRating[key].isOpen}
                    arrowSize={10}
                    preferPlace="right"
                    targetStyling={css.seeCriteria}
                    onMouseEnter={() => this.togglePopover(key, true)}
                    onMouseLeave={() => this.togglePopover(key, false)}
                    targetText="See criteria"
                    body={criteria || []}
                    buttons={buttons}
                  />
                </RadioButtonArray>
                <div className={css.errorMessageContainer}>
                  {submitted && !selectedRating[key].value && (
                    <div className={css.errorMessage}>
                      <ErrorIcon className={css.alertIcon} />
                      {'Please select a rating'}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </Modal>
    );
  }
}

SessionReport.propTypes = {
  isOpen: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  tutorId: PropTypes.string.isRequired,
  studentId: PropTypes.string.isRequired,
  eventId: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
};
