import React, { useEffect, useMemo, useState } from 'react';
import { Acl, permissionTypes } from '@crimson-education/common-config';
import { trackEvent } from '@crimson-education/browser-logger';
import { Row, Col } from 'antd';

import { FeatureSwitchContext } from 'featureSwitches';
import Header from 'components/molecules/Header';
import Subjects from 'components/unique/Subjects';
import css from './styles.scss';
import { SubjectPromotions } from './components/SubjectPromotions';
import { PurchaseHoursModal } from 'components/organisms/PurchaseHoursModal';
import { InAppPurchaseEvents } from '../Subjects/util';
import { ISubject } from './types';
import { LoggedInUser, PurchaseItem } from 'types';

interface MySubjectsProps {
  allSubjects: ISubject[];
  loggedInUser: LoggedInUser;
  loginUserPermissions: object[];
  fetchMyPackageItems: () => void;
  getUserKeyContacts: (userId: string) => void;
}

export default function MySubjects(props: MySubjectsProps) {
  const { allSubjects, loggedInUser, loginUserPermissions, fetchMyPackageItems, getUserKeyContacts } = props;
  const [isPurchaseHoursModalOpen, setIsPurchaseModalOpen] = useState(false);
  const [itemToPurchase, setItemToPurchase] = useState<PurchaseItem | null>(null);
  const [pendingSubjects, setPendingSubjects] = useState<Set<string>>(new Set());
  const subjects = useMemo(() => {
    if (!allSubjects) return [];
    return Object.values(allSubjects);
  }, [allSubjects]);

  useEffect(() => {
    getUserKeyContacts(loggedInUser.userId);
  }, []);

  const openPurchaseHoursModal = (item: PurchaseItem) => {
    trackEvent({
      message: InAppPurchaseEvents.OpenedPurchaseModal,
      metadata: {
        itemId: item.itemId,
        name: item.name,
        source: item.source,
      },
    });
    setIsPurchaseModalOpen(true);
    setItemToPurchase(item);
  };

  const closePurchaseHoursModal = (successful?: boolean) => {
    const pending = pendingSubjects;
    const wasSuccessful = typeof successful === 'boolean' && successful;
    if (wasSuccessful && itemToPurchase) {
      pending.add(itemToPurchase.itemId);
      setPendingSubjects((prev) => new Set(prev).add(itemToPurchase.itemId));
    }
    trackEvent({
      message: InAppPurchaseEvents.ClosedPurchaseModal,
      metadata: {
        successful: wasSuccessful,
        itemId: itemToPurchase?.itemId,
        name: itemToPurchase?.name,
        source: itemToPurchase?.source,
      },
    });
    setItemToPurchase(null);
    setIsPurchaseModalOpen(false);
  };

  return (
    <FeatureSwitchContext.Consumer>
      {({ featureSwitches }) => (
        <>
          <div data-ga-category="Subjects" className={css.mySubjects}>
            <Header title="Program Usage" />
            {featureSwitches.IN_APP_PURCHASES_PROMOTIONS ? (
              <Row>
                <Col span={18} style={{ margin: '40px auto 24px auto', position: 'relative' }}>
                  <SubjectPromotions
                    pendingSubjects={pendingSubjects}
                    userSubjects={subjects}
                    onClickPurchase={openPurchaseHoursModal}
                  />
                </Col>
              </Row>
            ) : null}
            <Subjects
              fetchPackageByUser={fetchMyPackageItems}
              subjects={subjects}
              studentUser={loggedInUser}
              loginUserPermissions={loginUserPermissions}
              canEditSubjectTutor={Acl.checkPermission(loginUserPermissions, permissionTypes.EDIT_SUBJECT_TUTOR)}
              onClickPurchase={openPurchaseHoursModal}
              pendingSubjects={pendingSubjects}
            />
          </div>
          {featureSwitches.IN_APP_PURCHASES ? (
            <PurchaseHoursModal
              item={itemToPurchase}
              onClose={() => closePurchaseHoursModal(false)}
              closeModal={closePurchaseHoursModal}
              open={isPurchaseHoursModalOpen}
            />
          ) : null}
        </>
      )}
    </FeatureSwitchContext.Consumer>
  );
}
