import moment from 'moment';
import { BookingStatusTypes } from '@crimson-education/common-config';

export const MINUTES_BEFORE = 10;

export const findSessionStartingSoon = (sessions) => {
  return sessions.find(
    (session) =>
      session.status === BookingStatusTypes.CONFIRMED &&
      (moment(session.start).isBetween(moment(), moment().add(MINUTES_BEFORE, 'minutes')) ||
        moment().isBetween(moment(session.start), moment(session.end))),
  );
};
