import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Header from 'components/molecules/Header';

import { Acl, roleTypes } from '@crimson-education/common-config';
import { Tab, Tabs, TabList, TabPanel } from 'components/molecules/ReactTabs';
import { columnNames } from 'components/unique/UserQuery/UserQueryTable/columnMap'; // TODO: Move to generic
// import { filterNames } from 'components/unique/UserQuery/UserQueryFilter/filterMap'; // TODO: Move to generic

import { trimSpecialCharacters } from 'utils/utils';
import FriendsQuery from './FriendsQuery';
// import TeamQuery from './TeamQuery';
import css from './styles.scss';

function getAvailableTabs(loginUserId, loginUserRoles, defaultActive) {
  const allAvailableTabs = [
    {
      requiredRoles: [roleTypes.STUDENT, roleTypes.CGA_STUDENT, roleTypes.TUTOR],
      tabName: 'My Friends',
      type: 'MY_FRIEND',
      count: true,
      component: (
        <FriendsQuery
          defaultActive={defaultActive}
          columnsToShow={[
            columnNames.ProfileColumn,
            columnNames.UserType,
            columnNames.Location,
            columnNames.University,
            columnNames.FriendActionButton,
          ]}
        />
      ),
    },
    // {
    //   requiredRoles: [roleTypes.STUDENT],
    //   tabName: 'My Team',
    //   type: 'MY_TEAM',
    //   component: <TeamQuery
    //     columnsToShow={[
    //     columnNames.ProfileColumn,
    //     columnNames.UserType,
    //     columnNames.Location,
    //     columnNames.University,
    //     columnNames.TutorSubjects,
    //     columnNames.TeamActionButton
    //   ]}
    //   />,
    // }
  ];

  return allAvailableTabs.filter(
    (tab) => !tab.isDisabled && tab.requiredRoles.some((role) => Acl.checkRole(loginUserRoles, role)),
  );
}

export default class Contacts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: props.activeTab || 0,
    };

    this.handleSelect = this.handleSelect.bind(this);
  }

  handleSelect(index) {
    const { setActiveTab, setActivePage } = this.props;

    this.setState({
      tabIndex: index,
    });
    setActiveTab(index);
    setActivePage(0);
  }

  render() {
    const { loginUserId, loginUserRoles, hasNewFriend, defaultActive } = this.props;

    const { tabIndex } = this.state;

    const tabs = getAvailableTabs(loginUserId, loginUserRoles, defaultActive);

    return (
      <div data-ga-category="Contacts" className={css.ourPeople}>
        <Header title="Contacts" isBorderVisible={false} />
        {tabs.length ? (
          <Tabs selectedIndex={tabIndex} onSelect={this.handleSelect}>
            <TabList>
              {tabs.map((tab, i) => {
                return (
                  <Tab
                    key={i}
                    title={tab.tabName}
                    count={tab.count ? hasNewFriend : null}
                    dataTestId={`contactsTab_${trimSpecialCharacters(tab.tabName)}`}
                  />
                );
              })}
            </TabList>
            {tabs.map((t, i) => (
              <TabPanel key={i}>{t.component}</TabPanel>
            ))}
          </Tabs>
        ) : (
          <div className={css.emptyMessage}>Sorry, you do not have permission to view users.</div>
        )}
      </div>
    );
  }
}

Contacts.displayName = 'Contacts';

Contacts.propTypes = {
  loginUserId: PropTypes.string.isRequired,
  loginUserRoles: PropTypes.array.isRequired,
  // userCountQuery: PropTypes.object.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  setActivePage: PropTypes.func.isRequired,
  activeTab: PropTypes.number,
  hasNewFriend: PropTypes.number,
  defaultActive: PropTypes.bool,
};
