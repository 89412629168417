import React, { useEffect, useRef, useState } from 'react';
import WithTitle from '../WithTitle';
import { Option } from '../BooleanSelector/index.style';
import { AidCurrencyContainer, OptionContainer } from './index.style';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InputText from 'components/molecules/InputText';
import Select from '../../components/Select';
import TextArea from 'components/molecules/TextArea';
import currencies from 'constants/currencies';

const CURRENCIES = Object.keys(currencies);
const topCurrencies = ['USD', 'NZD', 'AUD', 'EUR', 'GBP', 'CNY'];
const CURRENCY_OPTIONS = topCurrencies
  .concat(CURRENCIES.filter((c) => !topCurrencies.includes(c)))
  .map((c) => ({ label: c, value: c }));

type AidProps = {
  hasAid: boolean;
  setHasAid: (v: boolean) => void;
  aidType: 'scholarship' | 'financial_aid';
  setAidType: (v: 'scholarship' | 'financial_aid') => void;

  totalAid: string | null;
  setTotalAid: (v: string) => void;

  currency: { label: string; value: string } | null;
  setCurrency: (v: { label: string; value: string }) => void;

  aidDetails: string | null;
  setAidDetails: (v: string) => void;
};

const Aid = ({
  hasAid,
  setHasAid,
  aidType,
  setAidType,
  totalAid,
  setTotalAid,
  currency,
  setCurrency,
  aidDetails,
  setAidDetails,
}: AidProps) => {
  const onClickSholarship = () => {
    setHasAid(true);
    setAidType('scholarship');
  };
  const onClickFinancialAid = () => {
    setHasAid(true);
    setAidType('financial_aid');
  };
  const onClickNo = () => {
    setHasAid(false);
  };
  const totalAidRef = useRef<HTMLInputElement>(null);
  const [isTotalAidValid, setValid] = useState(false);
  useEffect(() => {
    if (!totalAid) return setValid(true);
    setValid(totalAid.length <= 12);
  }, [totalAid]);
  return (
    <>
      <WithTitle title={'Scholarships / Financial Aid received?'}>
        <OptionContainer rowcenter>
          <Option onClick={onClickSholarship} selected={hasAid && aidType === 'scholarship'}>
            {'Scholarship'}
          </Option>
          <Option onClick={onClickFinancialAid} selected={hasAid && aidType === 'financial_aid'}>
            {'Financial Aid'}
          </Option>
          <Option onClick={onClickNo} selected={!hasAid}>
            {'No'}
          </Option>
        </OptionContainer>
      </WithTitle>
      {hasAid && (
        <AidCurrencyContainer style={{ paddingBottom: isTotalAidValid ? 22 : 0, marginBottom: -23 }}>
          <div>
            <WithTitle title={'Total package value'}>
              <InputText
                type={'number'}
                forwardedRef={totalAidRef}
                placeholder={'E.g. 5000'}
                value={totalAid}
                isValid={isTotalAidValid}
                validationMessage={'Package value cannot exceed 12 digits'}
                onChange={(e: any) => setTotalAid(e.target.value)}
                onWheel={() => totalAidRef.current && totalAidRef.current.blur()}
              />
            </WithTitle>
          </div>
          <div>
            <WithTitle title={'Currency'}>
              <Select
                options={CURRENCY_OPTIONS}
                value={currency}
                onChange={setCurrency}
                DropdownIndicatorProps={{
                  searchIcon: ExpandMoreIcon,
                  searchIconStyle: {
                    color: '#A9ACC0',
                  },
                }}
              />
            </WithTitle>
          </div>
        </AidCurrencyContainer>
      )}

      {hasAid && (
        <WithTitle title={'Scholarships / Financial Aid details (optional)'}>
          <TextArea
            rows={4}
            charLimit={254}
            dataTestId={'aidDetails'}
            placeholder={
              'Please break down the financial aid offer between scholarships, grant money, aid, work study, etc...'
            }
            value={aidDetails}
            onChange={setAidDetails}
          />
        </WithTitle>
      )}
    </>
  );
};

export default Aid;
