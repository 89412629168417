/* eslint-disable no-underscore-dangle */
import React, {
  forwardRef,
  useImperativeHandle,
  useMemo,
  createElement
} from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import CheckBox from '@material-ui/core/Checkbox';
import useForSelectionList from '../../utils/useForSelectionList';

const MultiSelectList = forwardRef((props, ref) => {
  const { map, color, disabled, itemEndIcon: EndIcon } = props;
  const { entries, toggle, toggleAll } = useForSelectionList(props, true);

  const id = useMemo(() => Math.random(), []);

  useImperativeHandle(ref, () => ({
    toggleAll,
    toggle
  }));

  const options = useMemo(
    () => entries && entries.map(({ value, selected }, i) => {
      const canIUse = EndIcon &&
        EndIcon.unchecked &&
        EndIcon.checked;

      const custom = canIUse && {
        icon: createElement(EndIcon.unchecked, null),
        checkedIcon: createElement(EndIcon.checked, null)
      };

      return (
        <ListItem
          key={`${id}-${i}`}
          button
          disabled={disabled}
          selected={selected}
          onClick={() => toggle(value, i)}
        >
          { map(value) }
          <ListItemSecondaryAction>
            <CheckBox
              className="ds-checkbox"
              color={color}
              edge="end"
              onChange={() => toggle(value, i)}
              checked={selected}
              {...custom}
            />
          </ListItemSecondaryAction>
        </ListItem>
      );
    }),
    [entries, EndIcon, id, map, color, toggle]
  );

  return (
    <List dense className="ds-multiselect-list">
      {options}
    </List>
  );
});

MultiSelectList.propTypes = {
  disabled: PropTypes.bool,
  color: PropTypes.string,
  map: PropTypes.func,
  itemEndIcon: PropTypes.object,
};

export default MultiSelectList;
