export default {
  1: {
    // 😤
    text: 'Extremely unsatisfied',
    default_path: '/static/feedback/rating_star_empty.svg',
    active_path: '/static/feedback/rating_star_fullfilled.svg',
  },
  2: {
    // 🙁
    text: 'Unsatisfied',
    default_path: '/static/feedback/rating_star_empty.svg',
    active_path: '/static/feedback/rating_star_fullfilled.svg',
  },
  3: {
    // 😐
    text: 'Neutral',
    default_path: '/static/feedback/rating_star_empty.svg',
    active_path: '/static/feedback/rating_star_fullfilled.svg',
  },
  4: {
    // 😃
    text: 'Satisfied',
    default_path: '/static/feedback/rating_star_empty.svg',
    active_path: '/static/feedback/rating_star_fullfilled.svg',
  },
  5: {
    // 🤩
    text: 'Extremely satisfied',
    default_path: '/static/feedback/rating_star_empty.svg',
    active_path: '/static/feedback/rating_star_fullfilled.svg',
  },
};
