import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect, Prompt, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import { Acl, roleTypes, permissionTypes } from '@crimson-education/common-config';
import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';
import usePermissionCheck from 'hooks/usePermissionCheck';
import {
  PermissionAction,
  PermissionResourceQualifier,
  PermissionResourceType,
} from '@crimson-education/common-config/lib/authorization';

import TabNavLink from 'components/molecules/TabNavLink';

import sessionService from 'graphql/api/session';
import SessionHeader from './Header';
import SessionAgendaNew from './SessionAgendaNew/SessionAgenda';
import Workspace from './Workspace';
import css from './Session.scss';
import AutomaticSession from 'components/pages/AutomaticSession';
import { featureSwitches } from 'featureSwitches';

function hasWorkspacePermission(loginUser, session) {
  const workspacePermissions = ['SESSION_RESOURCE:CREATE', 'SESSION_RESOURCE:EDIT', 'SESSION_RESOURCE:DELETE'];
  return (
    (session.participants.some((p) => p.userId === loginUser.userId) &&
      session.participants.some((p) => Acl.checkRole(p.userRoles, roleTypes.STUDENT))) || // check if loginUser is a participant and check if at least one participant is a STUDENT
    (workspacePermissions.some((p) => Acl.checkPermission(loginUser.permissions, p)) &&
      session.participants.some((p) => Acl.checkRole(p.userRoles, roleTypes.STUDENT)))
  ); // check if loginUser has workspace permissions and check if at least one participant is a STUDENT
}

const config = getConfig();

const Session = (props) => {
  const {
    history,
    match: {
      params: { sessionId },
      url,
      path,
    },
    loginUser,
    session,
    bookAsUser,
    setSelectedSessionId,
    fetchLessonsByEventId,
    fetchEventForSessionSummary,
    fetchReasonsForCancellation,
    isHeaderHidden,
    updateDayCalendarViewHidden,
  } = props;
  const location = useLocation();
  const [isDirty, setDirty] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [lastLocation, setLastLocation] = useState(location);
  const [leavePage, setLeavePage] = useState(false);
  const [isBack, setIsBack] = useState(true);
  const [viewVideo, setViewVideo] = useState(false);
  /**
   * Event listeners only read the initial state values
   * so we use React's useRef to be able read current state
   */
  const dirtyRef = useRef(isDirty);
  const setSessionDirty = (dirty) => {
    setDirty(dirty);
    dirtyRef.current = dirty;
  };

  const beforeUnload = (event) => {
    if (dirtyRef.current) {
      event.preventDefault();
      event.returnValue = '';
    }
    removeItemFromLocalStorage(sessionId);
  };

  const canViewRecordingCheck = usePermissionCheck(
    {
      action: PermissionAction.View,
      resourceType: PermissionResourceType.SessionRecording,
      resource: {
        identifier: loginUser.userId,
        qualifier: PermissionResourceQualifier.User,
      },
    },
    [loginUser],
  );
  const canViewRecording = !canViewRecordingCheck.loading && canViewRecordingCheck.isPermitted;

  useEffect(() => {
    window.addEventListener('beforeunload', beforeUnload);

    return () => {
      window.removeEventListener('beforeunload', beforeUnload);
    };
  }, []);

  const onClickBack = (e) => {
    if (isDirty) {
      e.preventDefault();
      setModalOpen(true);
      return;
    }
    updateDayCalendarViewHidden(false);
    removeItemFromLocalStorage(sessionId);
    history.goBack();
  };

  useEffect(() => {
    (async () => {
      // Hide it temporarily
      // const hasPermission = await sessionService.hasViewRecordingPermission(sessionId);
      if (canViewRecording) {
        const url = await sessionService.getSessionRecordingUrl(sessionId);
        if (url.getSessionRecordingUrl) setViewVideo(true);
      }
    })();
  }, [sessionId, canViewRecording]);

  // Fetch session
  useEffect(() => {
    setSelectedSessionId(parseInt(sessionId, 10));

    fetchEventForSessionSummary(sessionId);
    fetchReasonsForCancellation(loginUser.userId, sessionId);

    if (!Acl.checkRole(loginUser.userRoles, roleTypes.STUDENT)) {
      fetchLessonsByEventId(sessionId);
    }
    return () => {
      setSelectedSessionId(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId]);

  useEffect(() => {
    if (window.Appcues) {
      window.Appcues.identify(loginUser.userId, {
        current_session_id: sessionId,
      });
    }
  }, [loginUser.userId, sessionId]);

  useEffect(() => {
    if (leavePage) {
      // Navigate to the previous blocked location with your navigate function
      history.push(lastLocation.pathname);
      setLeavePage(false);
    }
  }, [leavePage, lastLocation, history]);

  if (!session) {
    return null;
  }

  let bookAs = bookAsUser;
  const loadSessionAs = session.participants.find((x) => Acl.checkRole(x.userRoles, roleTypes.STUDENT));

  // Set bookAs to null if they are not a participant, this may have been set from somewhere else for a different purpose
  if (bookAs && (bookAs.userId === loginUser.userId || session.participants.every((x) => x.userId !== bookAs.userId))) {
    bookAs = null;
  }

  // If user is not a participant but has permission to book as, set them to the non student participant
  if (
    !bookAs &&
    session.participants.every((x) => x.userId !== loginUser.userId) &&
    (Acl.checkPermission(loginUser.permissions, permissionTypes.CRUD_BOOKING_ON_BEHALF) ||
      Acl.checkPermission(loginUser.permissions, permissionTypes.VIEW_SESSION_REPORT))
  ) {
    bookAs = session.participants.find((x) => !Acl.checkRole(x.userRoles, roleTypes.STUDENT));
  }

  // Redirect user if not part of the session
  if (session.participants.every((x) => x.userId !== loginUser.userId)) {
    if (!bookAs || session.participants.every((x) => x.userId !== bookAs.userId)) {
      history.push('/calendar');
      return null;
    }
  }

  const draftAgenda = ({ eventId, id, agenda, updatedAt, isDraft = true }) => {
    if (!eventId || !id || !agenda || !agenda.html || !agenda.text) {
      return;
    }
    localStorage.setItem(
      `event-agenda-${eventId}`,
      JSON.stringify({ agenda, id, updatedAt: updatedAt || new Date(), isDraft }),
    );
  };

  const draftNotes = ({ eventId, notes, updatedAt, isDraft = true }) => {
    if (!eventId || !notes || !notes.html || !notes.text) {
      return;
    }
    localStorage.setItem(
      `event-notes-${eventId}`,
      JSON.stringify({ notes, updatedAt: updatedAt || new Date(), isDraft }),
    );
  };

  const isDraft = (eventId) => {
    const { isDraft: isAgendaDraft } = JSON.parse(localStorage.getItem(`event-agenda-${eventId}`) || '{}');
    const { isDraft: isNotesDraft } = JSON.parse(localStorage.getItem(`event-notes-${eventId}`) || '{}');
    return isAgendaDraft || isNotesDraft;
  };

  const saveSessionSummary = async (noBanner) => {
    const {
      setAgendaItem,
      setFailForm,
      setSuccessForm,
      setNotes,
      session: { id: eventId },
    } = props;

    if (!isDraft(eventId)) {
      return true;
    }

    setLoading(true);
    const { agenda, id: agendaId } = JSON.parse(localStorage.getItem(`event-agenda-${eventId}`) || '{}');
    const { notes } = JSON.parse(localStorage.getItem(`event-notes-${eventId}`) || '{}');

    let agendaRes = {};
    let notesRes = {};
    if (agenda && agenda.html && agenda.text && agendaId) {
      agendaRes = await setAgendaItem(eventId, agendaId, agenda.text, agenda.html);
      if (!agendaRes.error) {
        const { html, text, updatedAt } = agendaRes;
        draftAgenda({ eventId, id: agendaId, agenda: { html, text }, updatedAt, isDraft: false });
      }
    }

    if (notes && notes.html && notes.text) {
      notesRes = await setNotes(eventId, notes);
      if (!notesRes.error) {
        const { html, text, updatedAt } = notesRes;
        draftNotes({ eventId, notes: { html, text }, updatedAt, isDraft: false });
      }
    }

    setLoading(false);
    if (agendaRes.error || notesRes.error) {
      const agendaError = typeof agendaRes.message === 'string' ? agendaRes.message : null;
      const notesError = typeof notesRes.message === 'string' ? notesRes.message : null;
      !noBanner && setFailForm(agendaError || notesError || 'Something went wrong. Please try again later.');
      return false;
    }
    !noBanner && setSuccessForm('Successfully saved session data.');
    setSessionDirty(false);
    return true;
  };

  const isWorkspaceLinkVisible = hasWorkspacePermission(loginUser, session);

  const { userId } = bookAs || loginUser;

  const removeItemFromLocalStorage = (sessionId) => {
    // localStorage.removeItem(`event-agenda-${sessionId}`);
    // localStorage.removeItem(`event-notes-${sessionId}`);
  };

  const handleBlockedNavigation = (nextLocation) => {
    if (isDirty && nextLocation?.state?.floatingVisible) {
      setLastLocation(nextLocation);
      if (nextLocation?.state?.floatingVisible === undefined) {
        setModalOpen(true);
      }
      setLeavePage(true);
      setIsBack(false);
      return false;
    }
    removeItemFromLocalStorage(sessionId);
    return true;
  };

  const leaveAnyway = () => {
    setModalOpen(false);
    setDirty(false);
    removeItemFromLocalStorage(sessionId);
    if (isBack) {
      history.goBack();
    } else {
      history.push(lastLocation.pathname);
    }
  };

  return (
    <div className={css.session} data-ga-category="Session">
      <Prompt when message={handleBlockedNavigation} />
      <div className={css.layoutHeader}>
        {!isHeaderHidden && (
          <SessionHeader
            session={session}
            userId={userId}
            bookAs={bookAs}
            loginUser={loginUser}
            onClickBack={onClickBack}
            saveSessionSummary={() => saveSessionSummary(true)}
            loadSessionAs={loadSessionAs}
          />
        )}
      </div>
      <div className={css.layoutLinks}>
        <div className={css.layoutCss}>
          <div>&nbsp;</div>
          {!isHeaderHidden && isWorkspaceLinkVisible && (
            <div className={css.links}>
              <TabNavLink
                url={`${url}/agenda`}
                text={`Session summary${isDirty ? ' (Draft)' : ''}`}
                className={css.link}
                dataTestId="agenda"
              />
              {!config.china && (
                <TabNavLink url={`${url}/workspace`} text="Workspace" className={css.link} dataTestId="workspace" />
              )}
            </div>
          )}
          {viewVideo && (
            <div className={css.layoutRecording}>
              <button
                className={css.recording}
                onClick={() => {
                  const appAddress = config.getServiceAddress('', 'app', { protocol: 'http' });
                  window.open(`${appAddress}/recording/${sessionId}`, '_blank');
                }}
              >
                <i className={classnames('zmdi zmdi-play-circle', css.palyIcon)} />
                <span>Recording</span>
              </button>
            </div>
          )}
        </div>
      </div>
      <div className={css.layoutBody}>
        <Switch>
          <Route
            path={`${path}/agenda`}
            render={(props) =>
              featureSwitches.NEW_SESSION() ? (
                <AutomaticSession />
              ) : (
                <SessionAgendaNew
                  {...props}
                  bookAsFirstName={bookAs?.firstName}
                  bookAsId={bookAs?.userId}
                  loading={loading}
                  draftAgenda={(args) => draftAgenda(args)}
                  draftNotes={(args) => draftNotes(args)}
                  onChangeSessionData={(dirty) => setSessionDirty(dirty)}
                  isModalOpen={isModalOpen}
                  setModalOpen={() => setModalOpen(false)}
                  leaveAnyway={() => leaveAnyway()}
                />
              )
            }
          />
          <Route path={`${path}/workspace`} component={Workspace} />
          <Redirect to={`${url}/agenda`} />
        </Switch>
      </div>
    </div>
  );
};

Session.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  loginUser: PropTypes.object,
  bookAsUser: PropTypes.object,
  session: PropTypes.object,
  fetchEventForSessionSummary: PropTypes.func,
  fetchReasonsForCancellation: PropTypes.func,
  setSelectedSessionId: PropTypes.func,
  fetchLessonsByEventId: PropTypes.func,
  isHeaderHidden: PropTypes.bool,
  updateDayCalendarViewHidden: PropTypes.func,
  setAgendaItem: PropTypes.func,
  setNotes: PropTypes.func,
  setFailForm: PropTypes.func,
  setSuccessForm: PropTypes.func,
};

export default Session;
