import React from 'react';
import PropTypes from 'prop-types';
import css from './styles';

export default function TypingIndicator(props) {
  const { otherTypingParticipants } = props;

  const typing = otherTypingParticipants.map((x) => x.user.firstName).join(', ');

  return otherTypingParticipants.length ? (
    <div className={css.typingIndicator}>
      {typing} {otherTypingParticipants.length > 1 ? 'are' : 'is'} typing...
    </div>
  ) : null;
}

TypingIndicator.propTypes = {
  otherTypingParticipants: PropTypes.array.isRequired,
};
