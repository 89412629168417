import { TAppTrackerState, Actions } from './state';
import { Dispatch } from 'redux';
import admissionService from 'graphql/admissionService';
import applicationConnector from 'graphql/api/application';
import uploadedFileService from 'graphql/api/uploadedFile';
import { formFail, formSuccess } from 'ducks/meta';
import { ChangeEvent } from 'react';
import {
  University,
  Application,
  UniveristyRegion,
  ApplicationApproval,
  ApplicationApprovalClause,
  ApplicationApprovalRecipient,
  ApplicationRegion,
  RegionValue,
  ApplicationOverview,
} from '../type';
import applicationService from 'graphql/api/application';
import { editYearOfApplicationByUserId } from 'ducks/profile';
import {
  mapUniversityRegion,
  ANIMATION_DURATION,
  groupApplications,
  getCurrent,
  isUCASSchool,
  mapApplicationRegion,
  filterApplicationsWithCondition,
} from '../util';
import { AppTrackerEventType } from '../constants';
import { trackEvent } from '@crimson-education/browser-logger';
import { get, omit } from 'lodash';
import { featureSwitches } from 'featureSwitches';
export class AppTrackerController {
  private state?: TAppTrackerState;
  private dispatch?: <A extends Actions>(name: A['name'], payload?: A['payload']) => void;
  private reduxDispatch?: Dispatch<any>;

  setParams = (
    state: TAppTrackerState,
    dispatch: <A extends Actions>(name: A['name'], payload?: A['payload']) => void,
    reduxDispatch: Dispatch<any>,
  ) => {
    this.state = state;
    this.dispatch = dispatch;
    this.reduxDispatch = reduxDispatch;
  };

  openAddUniversityModal = () => {
    this.dispatch?.('appTracker/toggleAddUniversityPopup', { isAddUniversityPopupOpen: true });
  };
  closeAddUniversityModal = () => {
    this.dispatch?.('appTracker/toggleAddUniversityPopup', { isAddUniversityPopupOpen: false });
  };

  openSelectAppCycleHint = () => {
    this.dispatch?.('appTracker/setSelectAppCycleHint', { showSelectAppCycleHint: true });
  };

  closeSelectAppCycleHint = () => {
    if (!this.state?.showSelectAppCycleHint) return;
    this.dispatch?.('appTracker/setSelectAppCycleHint', { showSelectAppCycleHint: false });
  };

  onTabChange = (event: ChangeEvent<any>, value: any) => {
    if (value === this.state?.tabValue) return;
    this.dispatch?.('appTracker/switchTab', { tabValue: value });
  };

  openAdmissionDrawer = (application: Application) => {
    this.dispatch?.('appTracker/toggleAdmissionDrawer', { isAdmissionDrawerOpen: true });
    this.dispatch?.('appTracker/setAdmissionDrawerApplication', { admissionDrawerApplication: application });
  };
  closeAdmissionDrawer = () => {
    this.dispatch?.('appTracker/toggleAdmissionDrawer', { isAdmissionDrawerOpen: false });
    this.dispatch?.('appTracker/setAdmissionDrawerApplication', { admissionDrawerApplication: null });
  };

  onClickListGroupByMenuItem = (listGroupBy: 'Round' | 'Goal') => {
    if (listGroupBy === this.state?.listGroupBy) return;
    this.dispatch?.('appTracker/setListGroupBy', { listGroupBy });
  };

  onClickTrackerGroupByMenuItem = (trackerGroupBy: 'Round' | 'Status' | 'Deadline') => {
    if (trackerGroupBy === this.state?.trackerGroupBy) return;
    this.dispatch?.('appTracker/setTrackerGroupBy', { trackerGroupBy });
  };

  selectListRegion = (listRegion: ApplicationRegion) => {
    this.dispatch?.('appTracker/setListRegion', { listRegion });
  };

  selectTrackerRegion = (trackerRegion: ApplicationRegion) => {
    this.dispatch?.('appTracker/setTrackerRegion', { trackerRegion });
  };

  removeApplicationFromStore = (applicationId: string) => {
    this.dispatch?.('appTracker/setApplications', {
      applications: this.state!.applications.filter((o) => o.id !== applicationId),
    });
  };

  openParentApprovalModal = () => {
    this.dispatch?.('appTracker/setParentApprovalModalOpen', { isParentApprovalModalOpen: true });
  };

  closeParentApprovalModal = () => {
    this.dispatch?.('appTracker/setParentApprovalModalOpen', { isParentApprovalModalOpen: false });
  };

  setCurrentSelectionHash = (payload: Record<ApplicationRegion, string>) => {
    this.dispatch?.('appTracker/setCurrentSelectionHash', { ...payload });
  };

  openConfirmEditingModal = () => {
    this.dispatch?.('appTracker/setConfirmEditingModalOpen', { isConfirmEditingModalOpen: true });
  };

  closeConfirmEditingModal = () => {
    this.dispatch?.('appTracker/setConfirmEditingModalOpen', { isConfirmEditingModalOpen: false });
  };

  setGrantEditingTrue = (region: ApplicationRegion) => {
    this.dispatch?.('appTracker/setGrantEditing', { [region]: true } as RegionValue<boolean>);
  };

  setGrantEditingFalse = (region: ApplicationRegion) => {
    this.dispatch?.('appTracker/setGrantEditing', { [region]: false } as RegionValue<boolean>);
  };

  // ------------ separator -------------

  getUniversities = async (): Promise<University[]> => {
    try {
      const { getAdmissionsUniversities } = await admissionService.fetchAdmissionsUniversities();
      return getAdmissionsUniversities;
    } catch (err: any) {
      this.reduxDispatch?.(formFail('Network error: failed to fetch university list ' + err.message));
      return [];
    }
  };

  queryAllApplications = async (userId: string) => {
    try {
      const { getApplicationsByUserId } = await applicationService.getApplicationsByUserId(userId, [
        'APPLYING',
        'SHORTLISTED',
        'PROSPECTIVE',
      ]);
      this.dispatch?.('appTracker/setApplications', { applications: getApplicationsByUserId });
      return true;
    } catch (err: any) {
      this.reduxDispatch?.(formFail(`${err.message}`));
      return false;
    }
  };

  /**
   *
   *
   * @param {string} userId
   * @param {string} universityName
   * @param {string} universityId
   * @memberof AppTrackerController
   * create application with university only
   */
  createApplication = async (userId: string, universityName: string, universityId: string) => {
    try {
      const { createApplication } = await applicationService.createApplication({
        university: universityName,
        universityId,
        group: 'PROSPECTIVE',
        userId,
        hadCrimsonSupport: true,
      });
      this.reduxDispatch?.(formSuccess('University has been added'));
      this.onNewApplication(createApplication);
      const region = createApplication.universityData?.country?.region;
      this.selectListRegion(mapUniversityRegion(region?.toUpperCase()) as UniveristyRegion);
      return true;
    } catch (err: any) {
      this.reduxDispatch?.(formFail('Network error: failed to create application ' + err.message));
      return false;
    }
  };

  createMultiApplications = async (
    userId: string,
    universities: { universityName: string; universityId: string }[],
    list: string,
    metadata?: {
      addedBy: string;
      addedFrom: 'school_list_tab';
    },
  ) => {
    try {
      if (!universities.length) return true;
      const applications = universities.map((u) => ({
        group: list,
        university: u.universityName,
        universityId: u.universityId,
        userId,
        hadCrimsonSupport: true,
      }));
      const { createMultiApplications } = await applicationService.createMultiApplications(applications);
      this.reduxDispatch?.(formSuccess('Universities has been added'));
      this.onNewApplication(createMultiApplications);
      trackEvent({
        message: AppTrackerEventType.addUniversities,
        metadata: {
          count: applications.length,
          universities,
          group: list,
          applicationIds: createMultiApplications.map((o: any) => o?.id),
          ...metadata,
        },
      });
      const firstUniversity = createMultiApplications.find(
        (o: Application) => o.universityId === universities[0].universityId,
      );
      if (firstUniversity) {
        this.selectListRegion(mapApplicationRegion(firstUniversity) as UniveristyRegion);
      }
      return true;
    } catch (err: any) {
      this.reduxDispatch?.(formFail('Network error: failed to create applications ' + err.message));
      return false;
    }
  };

  /**
   *
   *
   * @param {(Application | Application[])} application
   * @memberof AppTrackerController
   * add new application to list
   */
  onNewApplication = (application: Application | Application[]) => {
    this.dispatch?.('appTracker/setApplications', { applications: this.state!.applications.concat(application) });
  };

  private shouldShowUnmountingAnimation = (prevApps: Application[], nextApps: Application[]) => {
    const { tabValue, trackerGroupBy, trackerRegion, listGroupBy, listRegion } = this.state!;
    const currentGroupBy = getCurrent(tabValue, listGroupBy, trackerGroupBy);
    const currentRegion = getCurrent(tabValue, listRegion, trackerRegion);
    const prevGrouped = groupApplications(prevApps, currentGroupBy, currentRegion).reduce(
      (prev, curr) => ({ ...prev, [curr.label]: curr.applications }),
      {} as Record<string, Application[]>,
    );
    const currGrouped = groupApplications(nextApps, currentGroupBy, currentRegion).reduce(
      (prev, curr) => ({ ...prev, [curr.label]: curr.applications }),
      {} as Record<string, Application[]>,
    );
    return !Object.keys(prevGrouped).every((key) => {
      const prev = prevGrouped[key] || [];
      const curr = currGrouped[key] || [];
      return (
        prev
          .map((o) => o.id)
          .sort()
          .join(',') ===
        curr
          .map((o) => o.id)
          .sort()
          .join(',')
      );
    });
  };
  /**
   *
   *
   * @param {string} applicationId
   * @param {Partial<Application>} updatedAttrs
   * @memberof AppTrackerController
   * update partial attributes of application
   */
  updateApplicationInStore = (
    applicationId: string | string[],
    updatedAttrs: Partial<Application>,
    forceShowAnimation = false,
  ) => {
    const { applications } = this.state!;
    const _ids = Array.isArray(applicationId) ? applicationId : [applicationId];
    const nextApplications = applications.map((o) => (_ids.includes(o.id) ? { ...o, ...updatedAttrs } : o));
    const shouldShowAnimation = this.shouldShowUnmountingAnimation(applications, nextApplications);
    const invokeStateUpdate = () =>
      this.dispatch?.('appTracker/setApplications', {
        applications: nextApplications,
      });
    if (shouldShowAnimation || forceShowAnimation) {
      return this.unmountingApplication(applicationId, invokeStateUpdate);
    }
    invokeStateUpdate();
  };

  /**
   *
   *
   * @param {Application[]} applications
   * @param {boolean} [showAnimation=true]
   * @memberof AppTrackerController
   * restore applications after updating failed
   */
  restoreApplications = (applications: Application[], showAnimation = false) => {
    const { applications: prevApps } = this.state!;
    const nextApps = prevApps.map((o) => {
      const found = applications.find((a) => a.id === o.id);
      if (found) {
        return {
          ...o,
          ...found,
        };
      }
      return o;
    });
    const invokeStateUpdate = () =>
      this.dispatch?.('appTracker/setApplications', {
        applications: nextApps,
      });

    const shouldShowAnimation = this.shouldShowUnmountingAnimation(applications, nextApps);
    if (shouldShowAnimation && showAnimation) {
      return this.unmountingApplication(
        applications.map((o) => o.id),
        invokeStateUpdate,
      );
    }
    invokeStateUpdate();
  };
  preUpdateApplication = (application: Application, payload: Partial<Application>) => {
    const attrsToUpdate = Object.keys(payload);
    if (attrsToUpdate.includes('major')) {
      const { applications } = this.state!;
      const found = applications.find(
        (o) => o.id !== application.id && o.universityId === application.universityId && o.major === payload.major,
      );
      if (found) {
        this.reduxDispatch?.(
          formFail(
            'An application to this university with the set major already. Update the existing application or set a different major',
          ),
        );
        return false;
      }
      return true;
    }
    return true;
  };
  updateApplication = async (
    applicationId: string,
    payload: Partial<Application>,
    metadata?: {
      updatedBy: string;
    },
  ) => {
    const { applications } = this.state!;
    const found = applications.find((o) => o.id === applicationId);
    if (!found) return false;
    try {
      const shouldContine = this.preUpdateApplication(found, payload);
      if (!shouldContine) return false;
      this.updateApplicationInStore(applicationId, payload);
      const { updateApplication } = await applicationService.updateApplication({
        id: applicationId,
        ...payload,
      });
      trackEvent({
        message: AppTrackerEventType.updateUniversity,
        metadata: {
          applicationId,
          updatedAttributes: Object.keys(payload),
          updated: payload,
          ...metadata,
        },
      });
      return true;
    } catch (err: any) {
      this.reduxDispatch?.(formFail('Network error: failed to update application ' + err.message));
      this.updateApplicationInStore(applicationId, { ...found });
      return false;
    }
  };

  /**
   *
   *
   * @param {Application} application
   * @param {Partial<Application>} updatedAttrs
   * @memberof AppTrackerController
   * @deprecated
   * after updating an application, check if need some extra Actions
   * currently only  UCAS schools will trigger action, if submitted data updated, will sync to other UCAS schools
   */
  postUpdateApplication = (application: Application, updatedAttrs: Partial<Application>) => {
    if (!featureSwitches.NEW_APPLICATION_TRACKER_PHASE_3()) return;
    const isUCAS = isUCASSchool(application);
    if (!isUCAS) return;
    const { applications } = this.state!;
    // filter applications: isUCAS && applying && !== current application
    const otherUCASSchools = applications.filter(
      (o) => o.id !== application.id && isUCASSchool(o) && o.group === 'APPLYING',
    );
    if (!otherUCASSchools.length) return;
    const targetAttributes: (keyof Application)[] = ['isSubmitted', 'extraApplicationStatus'];
    const matchedAttrKeys = Object.keys(updatedAttrs).filter((k) => targetAttributes.includes(k as keyof Application));
    if (!matchedAttrKeys.length) return;
    const payload = matchedAttrKeys.reduce(
      (prev, curr) => ({
        ...prev,
        [curr]: updatedAttrs[curr as keyof Application],
      }),
      {} as Partial<Application>,
    );
    this.updateMultiApplicationsWithSameData(
      payload,
      otherUCASSchools.map((o) => o.id),
    );
  };

  /**
   *
   *
   * @param {Partial<Application>} payload
   * @param {string[]} applicationIds
   * @memberof AppTrackerController
   * for specific usecase, when one UCAS submitted status updated, sync changes to other UCAS schools
   */
  updateMultiApplicationsWithSameData = async (
    payload: Partial<Application>,
    applicationIds: string[],
    errMsg = 'Failed to synchronize status to other UCAS schools',
  ) => {
    const { applications } = this.state!;
    const prevApps = applications.filter((o) => applicationIds.includes(o.id));
    try {
      this.updateApplicationInStore(applicationIds, payload, true);
      const {} = await applicationService.updateMultiApplicationsWithSameData(
        { ...payload, id: applicationIds[0] },
        applicationIds,
      );
      return true;
    } catch (err: any) {
      this.reduxDispatch?.(formFail(errMsg + ' ' + err.message));
      // restore applications
      this.restoreApplications(prevApps);
      return false;
    }
  };

  unmountingApplication = (applicationId: string | string[], callback?: () => void) => {
    const { unmountingAppIds } = this.state!;
    const _ids = Array.isArray(applicationId) ? applicationId : [applicationId];
    this.dispatch?.('appTracker/setUnmountingAppIds', { unmountingAppIds: unmountingAppIds.concat(_ids) });
    setTimeout(() => {
      this.dispatch?.('appTracker/setUnmountingAppIds', {
        unmountingAppIds: this.state!.unmountingAppIds.filter((o) => !_ids.includes(o)),
      });
      callback && callback();
    }, ANIMATION_DURATION);
  };

  editYearOfApplicationByUserId = async (
    userId: string,
    yearOfApplication: string,
    displayToaster: 'added' | 'updated',
  ) => {
    const success = await this.reduxDispatch?.(
      editYearOfApplicationByUserId(userId, yearOfApplication, displayToaster),
    );
  };

  deleteApplication = async (applicationId: string) => {
    try {
      await applicationConnector.deleteApplication(applicationId);
      this.reduxDispatch?.(formSuccess('University has been removed'));
      return true;
    } catch (err: any) {
      this.reduxDispatch?.(formFail('Failed to delete university ' + err.message));
      return false;
    }
  };

  /**
   *
   *
   * @param {string} applicationId
   * @param {string} value
   * @memberof AppTrackerController
   * The button of action cell.
   */
  onClickTrackerPageActionButtonItem = async (
    applicationId: string,
    value: string,
    metadata?: {
      updatedBy: string;
    },
  ) => {
    const application = this.state?.applications.find((o) => o.id === applicationId);
    if (!application) return false;
    const isInProgress = !application.isSubmitted && !application.extraApplicationStatus;
    const isInvitedToInterview =
      !application.isSubmitted && application.extraApplicationStatus === 'INVITED_TO_INTERVIEW';
    const isSubmitted = application.isSubmitted;
    switch (value) {
      case 'IN_PROGRESS':
        // do nothing if isSubmitted is false
        if (isInProgress) return true;
        const res1 = await this.updateApplication(
          applicationId,
          {
            isSubmitted: false,
            extraApplicationStatus: null,
          },
          metadata,
        );
        return res1;
      case 'INVITED_TO_INTERVIEW':
        if (isInvitedToInterview) return true;
        return this.updateApplication(
          applicationId,
          {
            isSubmitted: false,
            extraApplicationStatus: 'INVITED_TO_INTERVIEW',
          },
          metadata,
        );
      case 'SUBMITTED':
        // do nothing if isSubmitted is true
        if (isSubmitted) return true;
        const success = await this.updateApplication(applicationId, { isSubmitted: true }, metadata);
        return success;
      case 'GOT_RESULT':
        // set to true if isSubmitted is false
        if (!isSubmitted) await this.updateApplication(applicationId, { isSubmitted: true }, metadata);
        this.openAdmissionDrawer(application);
        return true;
      default:
        return false;
    }
  };

  uploadAdmissionOffer = async (applicationId: string, fileId: string, userId: string) => {
    try {
      const { uploadAdmissionOffer } = await applicationService.uploadAdmissionOffer(applicationId, fileId, userId);
      this.updateApplicationInStore(applicationId, { offerFiles: uploadAdmissionOffer?.offerFiles });
      const { admissionDrawerApplication } = this.state!;
      if (admissionDrawerApplication?.id === applicationId) {
        this.dispatch?.('appTracker/setAdmissionDrawerApplication', {
          admissionDrawerApplication: {
            ...admissionDrawerApplication,
            offerFiles: uploadAdmissionOffer?.offerFiles,
          },
        });
      }
      return true;
    } catch (err: any) {
      this.reduxDispatch?.(formFail(`Failed to upload offer ${err.message}`));
      return false;
    }
  };

  deleteAdmissionOffer = async (applicationId: string, fileId: string, userId: string) => {
    try {
      const { deleteAdmissionOffer } = await applicationService.deleteAdmissionOffer(applicationId, fileId, userId);
      const { applications, admissionDrawerApplication } = this.state!;
      const found = applications.find((o) => o.id === applicationId);
      if (found) {
        const newOfferFiles = found.offerFiles.filter((o) => o.id !== fileId);
        const newOfferFileIds = found.offerFileIds.filter((id) => id !== fileId);
        this.updateApplicationInStore(applicationId, { offerFiles: newOfferFiles, offerFileIds: newOfferFileIds });
        if (admissionDrawerApplication?.id === applicationId) {
          this.dispatch?.('appTracker/setAdmissionDrawerApplication', {
            admissionDrawerApplication: {
              ...admissionDrawerApplication,
              offerFiles: newOfferFiles,
              offerFileIds: newOfferFileIds,
            },
          });
        }
      }
      return true;
    } catch (err: any) {
      this.reduxDispatch?.(formFail('Failed to delete admission offer ' + err.message));
      return false;
    }
  };

  submitAdmission = async (payload: {
    applicationId: string;
    degree: string;
    hadInterview: boolean;
    applicationStatus: string[];
    receivedAid: boolean;
    totalAid?: number | null;
    currency?: string | null;
    aidDetails?: string;
    aidType?: string;
  }) => {
    const { applicationId, ...restParams } = payload;
    try {
      const { createAdmissionResult } = await applicationService.createAdmissionResult(payload);
      this.reduxDispatch?.(formSuccess('Admission result has been added'));
      this.updateApplicationInStore(applicationId, {
        ...(restParams as any),
      });
      return true;
    } catch (err: any) {
      this.reduxDispatch?.(formFail('Failed to add admission result ' + err.message));
      return false;
    }
  };

  deleteAdmission = async (applicationId: string) => {
    try {
      await applicationService.deleteAdmissionResult(applicationId);
      this.reduxDispatch?.(formSuccess('Admission result has been removed'));
      return true;
    } catch (err: any) {
      this.reduxDispatch?.(formFail('Failed to delete admission result ' + err.message));
      return false;
    }
  };

  onConfirmDeleteItem = async (value: string, applicationId: string) => {
    if (value === 'remove_application') {
      const success = await this.deleteApplication(applicationId);
      if (success) this.unmountingApplication(applicationId, () => this.removeApplicationFromStore(applicationId));
      return success;
    }
    if (value === 'remove_admission_result') {
      const success = await this.deleteAdmission(applicationId);
      if (success)
        this.updateApplicationInStore(applicationId, {
          applicationStatus: undefined,
          degree: undefined,
          hadInterview: undefined,
          receivedAid: undefined,
          offerFileIds: undefined,
          offerFiles: undefined,
        });
      return success;
    }
    return true;
  };

  getStudentAllApprovalApplications = async (userId: string) => {
    try {
      const { getStudentApprovalApplications } = (await applicationService.getStudentApprovalApplications(
        userId,
      )) as Record<string, ApplicationApproval[]>;
      const grouped = (getStudentApprovalApplications as ApplicationApproval[]).reduce((prev, curr) => {
        if (!prev[curr.region]) prev[curr.region] = curr;
        return prev;
      }, {} as Record<ApplicationRegion, ApplicationApproval>);
      this.dispatch?.('appTracker/setApprovals', {
        ...grouped,
      });
    } catch (err) {
      //
    }
  };

  /**
   *
   *
   * @param {{
   *     userId: string;
   *     region: string;
   *     template?: string;
   *     recipients?: ApplicationApprovalRecipient[];
   *     clause?: string;
   *   }} {
   *     userId,
   *     region,
   *     template,
   *     recipients,
   *     clause,
   *   }
   *
   *  send application approval email to parents
   *
   * if its a resend action, recipients, template, clause are not required
   * @memberof AppTrackerController
   */
  requestApplicationApproval = async ({
    userId,
    region,
    template,
    recipients,
    clause,
  }: {
    userId: string;
    region: string;
    template?: string;
    recipients?: ApplicationApprovalRecipient[];
    clause?: string;
  }) => {
    try {
      const { requestApplicationApproval } = await applicationService.requestApplicationApproval({
        userId,
        region,
        recipients,
        template,
        clauses: !clause ? [] : [{ key: 'additional', value: clause, additional: true } as ApplicationApprovalClause],
      });
      this.dispatch?.('appTracker/setApprovals', {
        [region]: requestApplicationApproval,
      } as Record<ApplicationRegion, ApplicationApproval>);
      this.reduxDispatch?.(formSuccess('School Choice Confirmation Agreement email has been sent'));
      return true;
    } catch (err: any) {
      this.reduxDispatch?.(
        formFail('Failed to send application approval email: ' + err.message || err.apolloError?.message || ''),
      );
      return false;
    }
  };

  public canEditApplication = (region?: ApplicationRegion) => {
    const { approvals, currentSelectionHash, listRegion, grantEditing, tabValue, trackerRegion } = this.state!;
    const currentRegion = region || getCurrent(tabValue, listRegion, trackerRegion);
    // user has confirmed they want to edit the application
    if (grantEditing[currentRegion]) return true;
    const prevHash = get(approvals, [currentRegion, 'selectionHash']);
    const respType = get(approvals, [currentRegion, 'responseType']);
    const approved = !!respType && respType === 'approved';
    const rejected = !!respType && respType === 'rejected';
    const currHash = get(currentSelectionHash, [currentRegion]);
    const edited = prevHash !== currHash;
    if (rejected || !prevHash) return true; // rejected or no approval record
    if (edited) return true;
    if (approved) return false;
    // if hash is same, restrict user from editing
    return edited;
  };

  checkIfEditable = (
    isStudent: boolean,
    isAccessibleStaff: boolean,
    application: Application,
    isEditingCrimsonSupport?: boolean,
  ) => {
    // if its a non-crimson-support application, return true, if user is editing hadCrimsonSupport, should skip this check
    if (!application.hadCrimsonSupport && !isEditingCrimsonSupport) return true;
    // user has confirmed they want to edit the application
    const editable = this.canEditApplication();
    if (editable) return true;
    if (isStudent) {
      // show snack bar
      this.reduxDispatch?.(
        formFail(
          'The final school list has already been sent out to parents/primary contacts for approval. Please contact your strategist.',
        ),
      );
    }
    // strategist & SA
    if (isAccessibleStaff) {
      // show confirm editing modal
      this.openConfirmEditingModal();
    }
    // other staff
    if (!isStudent && !isAccessibleStaff) {
      this.reduxDispatch?.(
        formFail(
          'The final school list has already been sent out to parents/primary contacts for approval. You cannot edit this field.',
        ),
      );
    }
    return false;
  };

  updateApplicationOverview = async (id: string, input: ApplicationOverview) => {
    try {
      const { updateApplicationOverview } = await applicationService.updateApplicationOverview(id, {
        ...omit(input, ['createdAt']),
      });
      this.dispatch?.('appTracker/setOverviews', {
        [input.region]: updateApplicationOverview,
      } as Record<ApplicationRegion, ApplicationOverview>);
      return true;
    } catch (err: any) {
      this.reduxDispatch?.(formFail(`${err.message}`));
      return false;
    }
  };

  createApplicationOverview = async (input: ApplicationOverview) => {
    try {
      const { createApplicationOverview } = await applicationService.createApplicationOverview(input);
      this.dispatch?.('appTracker/setOverviews', {
        [input.region]: createApplicationOverview,
      } as Record<ApplicationRegion, ApplicationOverview>);
      return true;
    } catch (err: any) {
      this.reduxDispatch?.(formFail(`${err.message}`));
      return false;
    }
  };

  getApplicationOverviewsByUserId = async (userId: string) => {
    try {
      const { getApplicationOverviewsByUserId } = await applicationService.getApplicationOverviewsByUserId(userId);
      const grouped = (getApplicationOverviewsByUserId as ApplicationOverview[]).reduce((prev, curr) => {
        if (!prev[curr.region]) prev[curr.region] = curr;
        return prev;
      }, {} as Record<ApplicationRegion, ApplicationOverview>);
      this.dispatch?.('appTracker/setOverviews', {
        ...grouped,
      } as Record<ApplicationRegion, ApplicationOverview>);
      return true;
    } catch (err: any) {
      this.reduxDispatch?.(formFail(`${err.message}`));
      return false;
    }
  };

  checkIfHasApplicationsToSendApproval = () => {
    const { listRegion, applications } = this.state!;
    const apps = filterApplicationsWithCondition(applications, {
      region: listRegion,
      hadCrimsonSupport: true,
      group: 'APPLYING',
    });
    if (!apps.length) {
      this.reduxDispatch?.(
        formFail(
          'There is no university in this final list yet. Please carefully check the final list before you send out school confirmation email.',
        ),
      );
      return false;
    }
    return true;
  };

  getFilesByTypeAndRefId = async (refId: string, refType: string, tag = '') => {
    try {
      const { getFilesByTypeAndRefId } = await uploadedFileService.getFilesByTypeAndRefId(refType, refId, tag);
      this.dispatch?.('appTracker/setUploadedEssays', { essays: getFilesByTypeAndRefId });
      return true;
    } catch (err: any) {
      this.reduxDispatch?.(formFail(`${err.message}`));
      return false;
    }
  };

  deleteFileByFileId = async (fileId: string) => {
    try {
      const { deleteFileByFileId } = await uploadedFileService.deleteFileByFileId(fileId);
      this.dispatch?.('appTracker/setUploadedEssays', {
        essays: this.state!.essays.filter((e) => e.id !== deleteFileByFileId),
      });
      return true;
    } catch (err: any) {
      this.reduxDispatch?.(formFail(`${err.message}`));
      return false;
    }
  };

  showError = (message: string) => {
    this.reduxDispatch?.(formFail(message));
  };
}
