import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';
import { gql } from '@apollo/client';
import { createClient } from 'graphql/utils';
import { salesPackageType } from './common/types';

const { api } = getConfig();

const client = createClient(api.graphQLEndpoint);

const getAllSalesPackage = gql`
  ${salesPackageType}
  query getSalesPackages($userId: String!) {
    getSalesPackages(userId: $userId) {
      ...salesPackageType
    }
  }
`;

export default {
  getAllSalesPackages: (userId) => client.query(getAllSalesPackage, { userId }),
};
