export type TDispatch<A extends { name: string; payload: unknown }> = (name: A['name'], payload?: A['payload']) => void;

export class Controller<S extends Record<string, unknown>, Actions extends { name: string; payload: unknown }> {
  public state?: S;
  public dispatch?: TDispatch<Actions>;

  setParams = (state: S, dispatch: TDispatch<Actions>): void => {
    this.state = state;
    this.dispatch = dispatch;
  };
}
