import styled from 'styled-components';

export const DoubleConfirmText = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  margin-top: 36px;
  margin-bottom: 32px;
`;
