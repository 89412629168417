import React from 'react';
import Header from './Header';
import TableRow from './TableRow';
import { StudentInList } from '../../type';
type GroupTableProps = {
  groupName: string;
  students: StudentInList[];
  tint: {
    color: string;
    background: string;
  };
};

const GroupTable = ({ groupName, students, tint }: GroupTableProps) => {
  return (
    <table>
      <tbody>
        <Header groupName={groupName} count={students.length} tintColor={tint.color} />
        {students.map((student) => (
          <TableRow key={student.userId + student.region} student={student} />
        ))}
      </tbody>
    </table>
  );
};

export default GroupTable;
