import usePermissionCheck from 'hooks/usePermissionCheck';
import { PermissionAction, PermissionResourceType } from '@crimson-education/common-config/lib/authorization/types';
import PropTypes from 'prop-types';

/**
 * Validates the logged in user has the specified permissions to access a resource, with target users if requesting other user's resources.
 * Uses the fallback if not loaded, or not permitted.
 * @param {{
 *   children: JSX.ElementChildrenAttribute
 *   permissions: import('@crimson-education/common-config/lib/authorization/types').PermissionValidation[] | import('@crimson-education/common-config/lib/authorization/types').PermissionValidation
 *   targetUserIds: string[]?
 *   not: boolean
 *   fallback: JSX.Element
 *   showFallbackDuringLoad: boolean
 * }} param0 props
 * @returns Children if loaded and permitted, otherwise use the fallback components.
 */
export default function PermissionCondition({
  children,
  permissions,
  targetUserIds,
  not = false,
  fallback = null,
  showFallbackDuringLoad = true,
}) {
  const { isPermitted, loading } = usePermissionCheck(permissions, targetUserIds);

  if (!loading) {
    if ((isPermitted && !not) || (not && !isPermitted)) {
      return children;
    }
  }

  return showFallbackDuringLoad ? fallback : null;
}
PermissionCondition.propTypes = {
  children: PropTypes.element,
  permissions: PropTypes.array | PropTypes.string,
  targetUserIds: PropTypes.array,
  not: PropTypes.boolean,
  fallback: PropTypes.element,
  showFallbackDuringLoad: PropTypes.boolean,
};

/**
 * Shows components if the user has access to dev tools.
 * @param {*} param0 props
 * @returns Children if loaded and permitted, otherwise use the fallback components.
 */
export function HasDevTools({ children }) {
  return PermissionCondition({
    children,
    permissions: [
      {
        action: PermissionAction.Access,
        resourceType: PermissionResourceType.DevTools,
      },
    ],
  });
}
