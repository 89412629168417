import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ComponentSwitcher from 'components/generic/Forms/ComponentSwitcher';
import InputField from 'components/generic/Forms/InputField';

import css from './profileItem.scss';

export default class ChatName extends Component {
  constructor(props) {
    super(props);
    const { chatName, canEdit } = props;
    this.state = {
      chatName,
      editing: false,
      canEdit,
    };

    this.handleEditingToggle = this.handleEditingToggle.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleChatNameChange = this.handleChatNameChange.bind(this);
    this.renderView = this.renderView.bind(this);
    this.renderForm = this.renderForm.bind(this);
  }

  handleEditingToggle() {
    this.setState(() => ({ editing: !this.state.editing }));
  }

  handleCancelClick() {
    // when the user cancels editing.
    this.setState(() => ({
      editing: false,
    }));
  }

  handleChatNameChange(value) {
    this.setState({ chatName: value });
  }

  handleSaveClick() {
    this.setState(() => ({ editing: false }));
  }

  handleBlur() {
    const { threadId, editGroupName } = this.props;
    this.setState(() => ({ editing: false }));
    const chatName = this.state.chatName;
    if (chatName) {
      editGroupName(threadId, chatName);
    }
  }

  handleKeyPress(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.handleBlur();
    }
  }

  renderView(canEdit) {
    const { chatName } = this.state;

    return (
      <div className={css.itemBodyCompact}>
        <button onClick={canEdit ? this.handleEditingToggle : undefined}>{chatName || 'Set a group name'}</button>
      </div>
    );
  }

  renderForm() {
    const { chatName } = this.state;
    return (
      <div className={css.itemBodyCompact}>
        <InputField
          className={css.chatInput}
          defaultValue={chatName}
          value={chatName}
          dataTestId="chatNameInputField"
          onValueChange={this.handleChatNameChange}
          onKeyDown={this.handleKeyPress}
          onBlur={this.handleBlur}
        />
      </div>
    );
  }

  render() {
    const { canEdit } = this.state;

    return (
      <div className={classNames(css.profileItem)}>
        <ComponentSwitcher
          className={classNames(
            css.itemSwitcher,
            css.itemSwitcherCompact,
            { [css.readonly]: !canEdit },
            { [css.itemSwitcherEditing]: this.state.editing },
          )}
          dataGaLabel="ChatName"
          primary={this.renderView(canEdit)}
          alternate={this.renderForm()}
          toggleButtonShowOnHover
          toggleButtonClassName={css.toggleButton}
          canEdit={canEdit}
          signalOnEdit={this.handleEditingToggle}
          isAlternateVisible={this.state.editing}
          hideToggleButtonInEditMode
          dataTestId="editChatName"
        />
      </div>
    );
  }
}

ChatName.propTypes = {
  chatName: PropTypes.string,
  threadId: PropTypes.string,
  editGroupName: PropTypes.func.isRequired,
  canEdit: PropTypes.bool,
};
