import React from 'react';
import PropTypes from 'prop-types';
import Header from 'components/molecules/Header';
import InvoiceList from 'components/organisms/Invoicing/InvoiceList';
import InvoiceDetails from 'components/organisms/Invoicing/InvoiceDetails';
import InvoiceFilters from 'components/organisms/Invoicing/InvoiceFilters';

import css from './styles.scss';

export default function Invoicing({ app, selectedInvoiceId }) {
  const isMobile = app && app.isMobile;

  return (
    <div className={css.scrollableContainer} data-ga-category="Invoicing">
      <Header className={css.invoicingHeader} title="Invoicing" />
      {(!isMobile || !selectedInvoiceId) && <InvoiceFilters />}
      <div className={css.invoicingPageWrapper}>
        {(!isMobile || !selectedInvoiceId) && <InvoiceList />}
        {(!isMobile || selectedInvoiceId) && <InvoiceDetails />}
      </div>
    </div>
  );
}

Invoicing.propTypes = {
  app: PropTypes.object,
  selectedInvoiceId: PropTypes.string,
};
