import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
import { Link } from 'react-router-dom';
import LoadingIndicator from 'components/molecules/LoadingIndicator';
import { useDispatch, useSelector } from 'react-redux';
import * as Logger from '@crimson-education/browser-logger';
import {
  fetchCommunityThreads,
  getCommunityThreads,
  getCommunityThreadsHasNextPage,
  getCommunityThreadsEndCursor,
} from 'ducks/messaging';
import ThreadListItem from '../../Conversations/Threads/ThreadListItem';
import css from './styles';

function sortThreads(a, b) {
  const lastSent = new Date(a.lastMessageSentAt) < new Date(b.lastMessageSentAt) ? 1 : -1;
  return lastSent;
}

export default function CommunityThreadsNew(props) {
  const { userId, updateCurrentThreadId, searchText, activeThread, consumeThread } = props;
  const sortedThreads = useSelector(getCommunityThreads).toJS().sort(sortThreads);
  const hasMore = useSelector(getCommunityThreadsHasNextPage);
  const endCursor = useSelector(getCommunityThreadsEndCursor);
  const dispatch = useDispatch();

  const loadMore = async () => {
    dispatch(fetchCommunityThreads(userId, searchText, endCursor));
  };

  const clickThread = (thread) => {
    updateCurrentThreadId(thread.id);
    if (thread && thread.unreadMessagesCount > 0) {
      consumeThread(thread.id);
    }
    Logger.trackEventSinceLastAction({ message: 'click thread item' });
  };

  useEffect(() => {
    dispatch(fetchCommunityThreads(userId, searchText));
  }, [dispatch, searchText]);

  return (
    <InfiniteScroll
      loadMore={loadMore}
      hasMore={hasMore}
      useWindow={false}
      loader={<LoadingIndicator key="loader" colour="hint" />}
    >
      {sortedThreads.slice(0, 200).map((thread) => {
        return (
          <Link
            data-ga-label="Message Thread"
            to={`/messages/${thread.id}`}
            key={thread.id}
            className={css.thread}
            onClick={() => clickThread(thread)}
          >
            <ThreadListItem
              {...thread}
              selected={activeThread && activeThread.id === thread.id}
              source={thread.source}
              icon={thread.icon}
              threadType={thread.type}
              dataTestId={`thread-${thread.id}`}
            />
          </Link>
        );
      })}
    </InfiniteScroll>
  );
}
CommunityThreadsNew.propTypes = {
  userId: PropTypes.string,
  updateCurrentThreadId: PropTypes.func,
  searchText: PropTypes.string,
  activeThread: PropTypes.object,
  consumeThread: PropTypes.func,
};

CommunityThreadsNew.defaultProps = {};
