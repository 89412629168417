import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';
import { gql } from '@apollo/client';
import { createClient } from '../utils';
import {
  tutorInfoType,
  loginUserType,
  // consentType,
  publicUserType,
  detailedUserType,
  paginationType,
  filterType,
  userContactType,
  tutorNoteType,
  studentNoteType,
  detailedTutorOfUserType,
  detailedFriendUserType,
  studentQuestionType,
  studentAnswerType,
  quizQuestionType,
  quizResultType,
  keyContactInfoType,
  teamMemberType,
  appYearStudentType,
  staffStudentsUserRelationType,
} from './common/types';
import getGraphQLClient from '../';

const { api } = getConfig();

const client = createClient(api.graphQLEndpoint);
const gqlClient = getGraphQLClient(api.graphQLEndpoint);

export const setUserStatus = gql`
  mutation setUserStatus($userId: String!, $active: Boolean!) {
    setUserStatus(userId: $userId, active: $active) {
      userId
      firstName
      lastName
    }
  }
`;

export const updateLoginUser = gql`
  ${loginUserType}
  mutation updateLoginUser(
    $userId: String!
    $timezone: String
    $autoTimezone: Boolean
    $subscribedEmail: Boolean
    $isTest: Boolean
  ) {
    editUser(
      userId: $userId
      timezone: $timezone
      autoTimezone: $autoTimezone
      subscribedEmail: $subscribedEmail
      isTest: $isTest
    ) {
      ...loginUserType
    }
  }
`;

export const editTutorExpertise = gql`
  mutation editTutorExpertise($userId: String!, $tutorInfo: tutorInfoInputType) {
    editUser(userId: $userId, tutorInfo: $tutorInfo) {
      tutorInfo {
        expertise
      }
    }
  }
`;

export const editStudentInterests = gql`
  mutation editStudentInterests($userId: String!, $studentInfo: studentInfoInputType) {
    editUser(userId: $userId, studentInfo: $studentInfo) {
      studentInfo {
        interests
      }
    }
  }
`;

export const editTutorEducation = gql`
  mutation editTutorEducation($userId: String!, $tutorInfo: tutorInfoInputType) {
    editUser(userId: $userId, tutorInfo: $tutorInfo) {
      tutorInfo {
        schoolName
        schoolGraduateYear
        curriculumId
        curriculum {
          id
          name
          shortName
          region
          description
        }
        university
        degree
        universityStartYear
        universityGraduateYear
      }
    }
  }
`;

export const editUser = gql`
  ${tutorInfoType}
  mutation editUser(
    $userId: String!
    $email: String
    $firstName: String
    $lastName: String
    $phone: String
    $skype: String
    $address: String
    $city: String
    $state: String
    $location: String
    $ethnicity: String
    $gender: String
    $citizenships: [String]
    $dob: String
    $country: String
    $bio: String
    $primaryLanguage: Int
    $languages: [Int]
    $timezone: String
    $autoTimezone: Boolean
    $subscribedEmail: Boolean
    $studentInfo: studentInfoInputType
    $tutorInfo: tutorInfoInputType
    $meetingRecordingConsent: Boolean
    $isTest: Boolean
  ) {
    editUser(
      userId: $userId
      email: $email
      firstName: $firstName
      lastName: $lastName
      phone: $phone
      skype: $skype
      address: $address
      city: $city
      state: $state
      location: $location
      ethnicity: $ethnicity
      gender: $gender
      citizenships: $citizenships
      dob: $dob
      country: $country
      bio: $bio
      primaryLanguage: $primaryLanguage
      languages: $languages
      timezone: $timezone
      autoTimezone: $autoTimezone
      subscribedEmail: $subscribedEmail
      studentInfo: $studentInfo
      tutorInfo: $tutorInfo
      meetingRecordingConsent: $meetingRecordingConsent
      isTest: $isTest
    ) {
      userId
      email
      firstName
      lastName
      fullName
      email
      phone
      skype
      address
      city
      country
      state
      location
      bio
      timezone
      autoTimezone
      subscribedEmail
      role
      userRoles
      roles {
        isPrimary
        roleId
        role {
          name
        }
      }
      status
      ethnicity
      gender
      citizenships
      dob
      primaryLanguage
      languages
      studentInfo {
        userId
        schoolName
        schoolYearLevel
        schoolGraduateYear
        yearOfApplication
        curriculumId
        curriculum {
          id
          name
          shortName
        }
        hookStatement
        essayFolder
        university
        subjects
        interests
        intendedCareer
        academicGoals
        extracurricularGoals
        aidRequirements
        aidPayRequirements
        admissionGoals
        targetCountries
        siblings
        requirement
        preparedness
        parentIntensity
        englishLevel
        parentEnglishLevel
        degree
        major
        minor
        academicAdvisorName
      }
      meetingRecordingConsent
      isTest
      tutorInfo {
        ...tutorInfoType
      }
    }
  }
`;

export const saveAppVersion = gql`
  mutation saveAppVersion($name: String!, $version: String!, $deviceId: String!) {
    saveAppVersion(name: $name, version: $version, deviceId: $deviceId) {
      id
      userId
      name
      version
      createdAt
    }
  }
`;

export const updatePreferredPronoun = gql`
  mutation updatePreferredPronoun($userId: String!, $pronoun: String!) {
    updatePreferredPronoun(userId: $userId, pronoun: $pronoun)
  }
`;

export const fetchLoginUser = gql`
  ${loginUserType}
  query fetchLoginUser($userId: String!) {
    user: user(userId: $userId) {
      ...loginUserType
    }
  }
`;

const fetchLoginUserAuthorization = gql`
  query fetchLoginUserAuthorization {
    user {
      userId
      status
      roles {
        isPrimary
        roleId
        role {
          name
        }
      }
      permissions {
        legacyId
        action
        resourceType
        resource
      }
      isAllTags
      isMultiTenant
      myTags {
        id
        tagName
      }
    }
  }
`;

export const fetchUserById = gql`
  ${detailedUserType}
  query userById($userId: String!) {
    user: user(userId: $userId) {
      ...detailedUserType
    }
  }
`;

export const fetchUsersByIds = gql`
  ${detailedUserType}
  query fetchUsersByIds($userIds: [String]!) {
    users(userIds: $userIds) {
      ...detailedUserType
    }
  }
`;

export const fetchTutorNote = gql`
  ${tutorNoteType}
  query fetchTutorNote($tutorId: String!) {
    user(userId: $tutorId) {
      userId
      tutorInfo {
        ...tutorNoteType
      }
    }
  }
`;

export const fetchStudentNote = gql`
  ${studentNoteType}
  query fetchStudentNote($studentId: String!) {
    user(userId: $studentId) {
      userId
      studentInfo {
        studentType
        ...studentNoteType
      }
    }
  }
`;

export const fetchStudentsByIds = gql`
  ${detailedUserType}
  query userByIds($userIds: [String]!) {
    users: users(userIds: $userIds) {
      ...detailedUserType
    }
  }
`;

export const fetchTutorNotes = gql`
  ${tutorNoteType}
  query fetchTutorNotes($tutorIds: [String]!) {
    users(userIds: $tutorIds) {
      userId
      tutorInfo {
        ...tutorNoteType
      }
    }
  }
`;

export const fetchTutorsWithNotes = gql`
  ${detailedTutorOfUserType}
  query fetchTutorsWithNotes($tutorIds: [String]!) {
    users(userIds: $tutorIds) {
      ...detailedTutorOfUserType
    }
  }
`;

export const queryUsers = gql`
  ${detailedUserType}
  ${paginationType}
  ${filterType}
  query queryUsers($pagination: paginationParams, $filter: filterParams, $userId: String) {
    queryUsers: pagedUsers(userId: $userId, pagination: $pagination, filter: $filter) {
      results {
        ...detailedUserType
      }
      pagination {
        ...paginationType
      }
      filter {
        ...filterType
      }
    }
  }
`;

export const queryUserCount = gql`
  ${paginationType}
  query queryUserCount($userId: String, $pagination: paginationParams, $filter: filterParams) {
    queryUsers: pagedUsers(userId: $userId, pagination: $pagination, filter: $filter) {
      pagination {
        ...paginationType
      }
    }
  }
`;

export const fetchPeopleForBookingWithPaged = gql`
  ${paginationType}
  query fetchPeopleForBookingWithPaged($userId: String, $pagination: paginationParams, $filter: filterParams) {
    pagedUsers(userId: $userId, pagination: $pagination, filter: $filter) {
      results {
        userId
        firstName
        lastName
        fullName
        role
        userRoles
        roles {
          isPrimary
          roleId
          role {
            name
          }
        }
        profileImageUrl
        timezone
        city
        studentInfo {
          studentType
          packageSubjects(tutorId: $userId) {
            id
            name
            start
          }
        }
        tutorInfo {
          contractSubjects(studentId: $userId) {
            id
            name
            start
          }
        }
      }
      pagination {
        ...paginationType
      }
    }
  }
`;

export const fetchPeopleForBookingForPaged = gql`
  ${paginationType}
  query fetchPeopleForBookingForPaged($pagination: paginationParams, $filter: genericFilterParams) {
    pagedUsersBookFor(pagination: $pagination, filter: $filter) {
      results {
        userId
        firstName
        lastName
        fullName
        role
        userRoles
        roles {
          isPrimary
          roleId
          role {
            name
          }
        }
        timezone
        profileImageUrl
        studentInfo {
          studentType
        }
      }
      pagination {
        ...paginationType
      }
    }
  }
`;

export const fetchAssignableUsers = gql`
  ${paginationType}
  query assignableUsers($pagination: paginationParams, $filter: genericFilterParams, $userId: String) {
    assignableUsers(pagination: $pagination, filter: $filter, userId: $userId) {
      results {
        userId
        firstName
        lastName
        fullName
        role
        userRoles
        roles {
          isPrimary
          roleId
          role {
            name
          }
        }
        profileImageUrl
      }
      pagination {
        ...paginationType
      }
    }
  }
`;

export const fetchPublicUserInfoQuery = gql`
  ${publicUserType}
  query fetchPublicUserInfoQuery($userIds: [String]!) {
    usersInfo: publicUserInfo(userIds: $userIds) {
      ...publicUserType
    }
  }
`;

export const fetchUsersByRole = gql`
  ${detailedUserType}
  query usersByRole($roleId: String!) {
    users: usersByRole(roleId: $roleId) {
      ...detailedUserType
    }
  }
`;

export const fetchAuthUserByEmail = gql`
  query fetchAuthUserByEmail($email: String!) {
    fetchAuthUserByEmail(email: $email) {
      userId
      firstName
      lastName
      email
      phone
      country
    }
  }
`;

export const fetchZendeskLoginUrl = gql`
  query fetchZendeskLoginUrl {
    fetchZendeskLoginUrl
  }
`;

export const getContactsForUser = gql`
  ${userContactType}
  query getContactsForUser($filterText: String, $first: Int, $after: String) {
    getContactsForUser(filterText: $filterText, first: $first, after: $after) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      onlineUsers
      edges {
        node {
          ...userContactType
        }
        cursor
        index
      }
    }
  }
`;

export const assignPrincipalsToRelation = gql`
  ${publicUserType}
  mutation assignPrincipalsToRelation($relationUserId: String!, $principals: [principalRelationArgument]!) {
    relationships: assignPrincipalsToRelation(relationUserId: $relationUserId, principals: $principals) {
      principalUser {
        ...publicUserType
      }
      relationUser {
        ...publicUserType
      }
      type
    }
  }
`;

export const queryMyFriendsByStatus = gql`
  ${detailedFriendUserType}
  ${paginationType}
  query queryMyFriendsByStatus($pagination: paginationParams, $status: [String!], $name: String) {
    getMyFriendsByStatus(status: $status, name: $name, pagination: $pagination) {
      results {
        ...detailedFriendUserType
      }
      pagination {
        ...paginationType
      }
    }
  }
`;

export const acceptFriendRequest = gql`
  mutation acceptFriendRequest($requestId: String!) {
    acceptFriendRequest(requestId: $requestId)
  }
`;

export const blockFriend = gql`
  mutation blockFriend($friendId: String!) {
    blockFriend(friendId: $friendId)
  }
`;

export const ignoreFriendRequest = gql`
  mutation ignoreFriendRequest($requestId: String!) {
    ignoreFriendRequest(requestId: $requestId)
  }
`;

export const rolesForUser = gql`
  query rolesForUser($userId: String!) {
    rolesForUser: user(userId: $userId) {
      roles {
        role {
          id
          name
          description
        }
      }
    }
  }
`;

export const getStudentQuestions = gql`
  ${studentQuestionType}
  query getStudentQuestions($userId: String, $category: String) {
    questions(userId: $userId, category: $category) {
      ...studentQuestionType
    }
  }
`;

export const editStudentExtraInfo = gql`
  ${studentAnswerType}
  mutation editStudentExtraInfo($answers: [answerInputType]) {
    upsertAnswer(answers: $answers) {
      ...studentAnswerType
    }
  }
`;

export const updatePreferredName = gql`
  mutation updatePreferredName($userId: String!, $preferredName: String!) {
    updatePreferredName(userId: $userId, preferredName: $preferredName)
  }
`;

export const queryFriendPendingRequestCount = gql`
  query queryFriendPendingRequestCount {
    getFriendPendingRequestCount
  }
`;

const setupOnboardingQuiz = gql`
  ${quizResultType}
  ${quizQuestionType}
  mutation setupOnboardingQuiz {
    setupOnboardingQuiz {
      result {
        ...quizResult
      }
      questions {
        ...quizQuestion
      }
    }
  }
`;

const getOnboardingQuizResult = gql`
  query getOnboardingQuizResult($userId: String) {
    getOnboardingQuizResult(userId: $userId) {
      result {
        id
        status
        content
      }
      questions {
        id
        answer
        text
        subjectText
        primarySubject
        groupId
        category
        parentQuestionId
        showOptionsByParentSubject {
          id
          text
          value
        }
        showOptionsByPrimarySubject {
          id
          text
          value
        }
        options {
          id
          text
          value
        }
        children {
          id
          answer
          text
          subjectText
          primarySubject
          groupId
          parentQuestionId
          showOptionsByParentSubject {
            id
            text
            value
          }
          showOptionsByPrimarySubject {
            id
            text
            value
          }
          options {
            id
            text
            value
          }
        }
      }
    }
  }
`;

export const answerOnboardingQuestion = gql`
  mutation answerOnboardingQuizQuestion($questionId: UUID!, $optionId: UUID!, $resultId: UUID!) {
    answerOnboardingQuizQuestion(questionId: $questionId, optionId: $optionId, resultId: $resultId) {
      message
      answer {
        id
        questionId
        optionId
        resultId
        createdAt
        updatedAt
      }
    }
  }
`;

export const submitOnboardingQuiz = gql`
  mutation submitOnboardingQuiz($resultId: String!) {
    submitOnboardingQuiz(resultId: $resultId) {
      result {
        id
        status
        content
      }
    }
  }
`;

export const getTeamMembersByStudentUserId = gql`
  ${teamMemberType}
  query getTeamMembersByStudentUserId($studentUserId: String!) {
    getTeamMembersByStudentUserId(studentUserId: $studentUserId) {
      ...teamMemberType
    }
  }
`;

export const getAppYearStudentsByStaffUserId = gql`
  ${appYearStudentType}
  query getAppYearStudentsByStaffUserId {
    getAppYearStudentsByStaffUserId {
      ...appYearStudentType
    }
  }
`;

export const getStudentSupervisorsByRequester = gql`
  ${staffStudentsUserRelationType}
  query getStudentSupervisorsByRequester {
    getStudentSupervisorsByRequester {
      ...staffStudentsUserRelationType
    }
  }
`;

export const getUserKeyContacts = gql`
  ${keyContactInfoType}
  query fetchUser($userId: String!) {
    user(userId: $userId) {
      userId
      studentInfo {
        keyContacts {
          ...keyContactInfoType
        }
      }
    }
  }
`;
export const getFeatureFlagsByUserId = gql`
  query getFeatureFlagsByUserId($userId: String, $flags: [String!]!) {
    res: getFeatureFlagsByUserId(userId: $userId, flags: $flags)
  }
`;

export default {
  setUserStatus: (userId, active) => client.query(setUserStatus, { userId, active }),
  updateLoginUser: (userId, userInfo = {}) => client.query(updateLoginUser, { userId, ...userInfo }),
  editTutorExpertise: (userId, expertise) =>
    client.query(editTutorExpertise, {
      userId,
      tutorInfo: {
        expertise,
      },
    }),
  editStudentInterests: (userId, interests) =>
    client.query(editStudentInterests, {
      userId,
      studentInfo: {
        interests,
      },
    }),
  editTutorEducation: (userId, tutorInfo) =>
    client.query(editTutorEducation, {
      userId,
      tutorInfo,
    }),
  editUser: (userId, generalInfo, studentInfo, tutorInfo) =>
    client.query(editUser, {
      userId,
      ...generalInfo,
      studentInfo,
      tutorInfo,
    }),
  saveAppVersion: (name, version, deviceId) => client.query(saveAppVersion, { name, version, deviceId }),
  updatePreferredPronoun: (userId, pronoun) => client.query(updatePreferredPronoun, { userId, pronoun }),
  fetchLoginUserAuthorization: () => client.query(fetchLoginUserAuthorization, {}),
  fetchLoginUser: (userId) => client.query(fetchLoginUser, { userId }),
  fetchUserById: (userId) => client.query(fetchUserById, { userId }),
  fetchUsersByIds: (userIds) => client.query(fetchUsersByIds, { userIds }),
  fetchStudentsByIds: (userIds) => client.query(fetchStudentsByIds, { userIds }),
  fetchTutorNote: (tutorId) => client.query(fetchTutorNote, { tutorId }),
  fetchStudentNote: (studentId) => client.query(fetchStudentNote, { studentId }),
  fetchTutorNotes: (tutorIds) => client.query(fetchTutorNotes, { tutorIds }),
  fetchTutorsWithNotes: (tutorIds) => client.query(fetchTutorsWithNotes, { tutorIds }),
  queryUsers: (pagination, filter, userId) => client.query(queryUsers, { pagination, filter, userId }),
  queryUserCount: (pagination, filter, userId) => client.query(queryUserCount, { pagination, filter, userId }),
  fetchPeopleForBookingWith: ({ userId, pagination, filter }) =>
    client.query(fetchPeopleForBookingWithPaged, { userId, pagination, filter }),
  fetchPeopleForBookingFor: ({ userId, pagination, filter }) =>
    client.query(fetchPeopleForBookingForPaged, { userId, pagination, filter }),
  fetchAssignableUsers: ({ userId, pagination, filter }) =>
    client.query(fetchAssignableUsers, { userId, pagination, filter }),
  fetchPublicUserInfo: (userIds) => {
    return client.query(fetchPublicUserInfoQuery, { userIds }).then((res) => {
      if (!res.usersInfo || res.errors) {
        return Promise.reject(new Error('failed to fetch users'));
      }
      return res.usersInfo;
    });
  },
  fetchUsersByRole: (roleId) => client.query(fetchUsersByRole, { roleId }),
  fetchAuthUserByEmail: (email) => client.query(fetchAuthUserByEmail, { email }),
  getContactsForUser: (filterText, first, after) =>
    client.query(getContactsForUser, { filterText, after, first }).then(({ getContactsForUser }) => getContactsForUser),
  fetchZendeskLoginUrl: () => client.query(fetchZendeskLoginUrl),
  assignPrincipalsToRelation: (relationUserId, principals) =>
    client.query(assignPrincipalsToRelation, { relationUserId, principals }),
  getMyFriendsByStatus: (pagination, status, name) =>
    client.query(queryMyFriendsByStatus, { pagination, status, name }),
  acceptFriendRequest: (requestId) => client.query(acceptFriendRequest, { requestId }),
  blockFriend: (friendId) => client.query(blockFriend, { friendId }),
  ignoreFriendRequest: (requestId) => client.query(ignoreFriendRequest, { requestId }),
  rolesForUser: (userId) => client.query(rolesForUser, { userId }),
  fetchStudentQuestions: (userId, category) => client.query(getStudentQuestions, { userId, category }),
  editStudentExtraInfo: (answers) => client.query(editStudentExtraInfo, { answers }),
  updatePreferredName: (userId, preferredName) => client.query(updatePreferredName, { userId, preferredName }),
  getFriendPendingRequestCount: () => client.query(queryFriendPendingRequestCount),
  setupOnboardingQuiz: () =>
    gqlClient.mutate({
      mutation: setupOnboardingQuiz,
    }),
  getOnboardingQuizResult: (userId, noCache = false) =>
    gqlClient.query({
      query: getOnboardingQuizResult,
      ...(noCache ? { fetchPolicy: 'no-cache' } : null),
      variables: { userId },
    }),
  answerOnboardingQuestion: (questionId, optionId, resultId) =>
    gqlClient.mutate({
      mutation: answerOnboardingQuestion,
      variables: {
        questionId,
        optionId,
        resultId,
      },
    }),
  submitOnboardingQuiz: (resultId) => {
    return gqlClient.mutate({
      mutation: submitOnboardingQuiz,
      variables: {
        resultId,
      },
    });
  },
  getTeamMembersByStudentUserId: (studentUserId) => client.query(getTeamMembersByStudentUserId, { studentUserId }),

  getAppYearStudentsByStaffUserId: () =>
    gqlClient.query({
      query: getAppYearStudentsByStaffUserId,
    }),
  getStudentSupervisorsByRequester: () =>
    gqlClient.query({
      query: getStudentSupervisorsByRequester,
    }),
  getUserKeyContacts: (userId) => client.query(getUserKeyContacts, { userId }),
  getFeatureFlagsByUserId: (userId, flags) =>
    client.query(getFeatureFlagsByUserId, {
      userId,
      flags,
    }),
};
