import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import css from './style.scss';

const gradeMapping = {
  [-1]: 'N/A',
  0: 'D',
  1: 'C',
  2: 'B',
  3: 'A',
};

function transformGrade(val) {
  return gradeMapping[val] || 'Invalid grade';
}

function transformBoolean(val) {
  return val ? 'Yes' : 'No';
}

export default function VersionTwo(props) {
  const { report } = props;
  const reportCategoryList = [
    { key: 'goal', label: 'What was the aim of the session?' },
    {
      key: 'isProgressing',
      label: 'Is your student making progress?',
      transform: (val) => transformBoolean(val),
      combine: 'progress',
    },
    { key: 'effort', label: 'Effort grade for the session', transform: (val) => transformGrade(val) },
    {
      key: 'isRoadblocked',
      label: 'Are you facing any road blocks?',
      transform: (val) => transformBoolean(val),
      combine: 'roadblockDetail',
    },
  ];
  const createdDate = moment(report.createdAt).format('ddd, D MMM, YYYY [at] h:mma');
  return (
    <div className={css.report}>
      <div className={css.topFieldsContainer}>
        <div className={css.topField}>
          <h4>Created</h4>
          <p className={css.preText}>{createdDate}</p>
        </div>
      </div>
      {reportCategoryList.map((category, index) => {
        if (report[category.key] !== undefined && report[category.key] !== null) {
          return (
            <div className={css.contentWrapper} key={index}>
              <h4>{category.label}</h4>
              <p className={css.preText}>
                {category.transform ? category.transform(report[category.key]) : report[category.key]}

                {category.combine && report[category.combine] && ` — ${report[category.combine]}`}
              </p>
            </div>
          );
        }
        return null;
      })}
    </div>
  );
}

VersionTwo.propTypes = {
  report: PropTypes.object.isRequired,
};
