import Immutable from 'immutable';
import createReducer from 'utils/createReducer';
import { ADD_ENTITIES } from 'ducks/normalizr';

const initialState = new Immutable.Map();

export default createReducer(initialState, {
  [ADD_ENTITIES]: (state, action) => {
    return state.mergeDeep(action.payload.entities.userRelationship);
  },
});
