import styled from 'styled-components';
import Modal from 'antd/lib/modal';
import { CustomScrollbarView } from '../index.style';

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 8px;
    overflow: hidden;
  }
  .ant-modal-header {
    border-bottom: none;
    text-align: center;

    padding-top: 40px;

    .ant-modal-title {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: #1d1e2b;
    }
  }
  .ant-modal-close {
    color: #73747d;
  }
`;

export const ListContainer = styled(CustomScrollbarView)`
  margin: 20px auto 40px;
  max-width: 450px;
  padding: 0 15px;
  align-self: center;

  display: flex;
  flex-direction: column;
  gap: 15px;

  max-height: 420px;

  overflow-y: auto;
`;
