import { connect } from 'react-redux';

import { selectUserPermissions, getBookingErrors, getModalState } from 'selectors/meta';

import { getLoginUser, getBookingAs, getPeopleForBookingWith } from 'selectors/user';

import { cancelBooking, confirmBooking, declineBooking } from 'ducks/booking';

import { clearBookingError, resetModal } from 'ducks/meta';

import BookingSummaryModal from './BookingSummaryModal';

export default connect(
  (state) => ({
    loginUser: getLoginUser(state),
    loginUserPermissions: selectUserPermissions(state),
    modalState: getModalState(state),
    bookingError: getBookingErrors(state),
    bookAs: getBookingAs(state),
    bookWith: getPeopleForBookingWith(state),
  }),
  (dispatch) => ({
    clearBookingErrors: () => dispatch(clearBookingError()),
    resetModal: () => {
      dispatch(resetModal());
    },
    cancelBooking: (id, studentId) => {
      dispatch(cancelBooking(id, studentId));
    },
    confirmBooking: (id, participantUserId, isInThePast) => {
      dispatch(confirmBooking(id, participantUserId, isInThePast));
    },
    declineBooking: (id, participantUserId, reason) => dispatch(declineBooking(id, participantUserId, reason)),
  }),
)(BookingSummaryModal);
