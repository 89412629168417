import moment from 'moment';
import missionType from 'constants/missionType';
import { List, Map } from 'immutable';
import taskStatus from 'constants/taskStatus';
import isEmpty from 'lodash/isEmpty';
import { UNCATEGORISED_GROUP_ID } from 'ducks/roadmap';

export const findStartAndDuration = (missions) => {
  if (!missions.size) {
    return null;
  }
  const earliest = missions.reduce((pre, cur) =>
    moment(pre.get('startDate')).isBefore(cur.get('startDate')) ? pre : cur,
  );
  const latest = missions.reduce((pre, cur) => (moment(pre.get('endDate')).isAfter(cur.get('endDate')) ? pre : cur));

  let days = moment(latest.get('endDate')).diff(moment(earliest.get('startDate')), 'days');
  days = days === 0 ? null : days;
  return new Map({
    start: moment(earliest.get('startDate')),
    days,
    end: moment(latest.get('endDate')),
  });
};

const createCategoryLeftTableObject = (item, childrenLength = 0, durationData) => {
  return new Map({
    id: item.get('id'),
    title: item.get('name'),
    color: item.get('color'),
    description: item.get('description'),
    root: true,
    parent: null,
    start: durationData ? durationData.get('start').format('DD MMM YY') : '',
    days: durationData ? durationData.get('days') : null,
    childrenLength,
    isDefault: item.get('default'),
    startDate: durationData ? durationData.get('start').valueOf() : null,
    endDate: durationData ? durationData.get('end').valueOf() : null,
  });
};

const createCategoryBarObject = (item, durationData) => {
  return new Map({
    id: item.get('id'),
    title: item.get('name'),
    itemDivTitle: '',
    description: item.get('description'),
    color: item.get('color'),
    group: item.get('id'),
    isCategory: true,
    className: 'chart-category',
    canMove: false,
    canResize: false,
    canChangeGroup: false,
    start_time: durationData ? moment(durationData.get('start')).valueOf() : '',
    end_time: durationData ? moment(durationData.get('end')).valueOf() : '',
  });
};

const createMissionLeftTableObject = (item, childrenLength = 0, parent, category) => {
  let days = moment(item.get('endDate')).diff(moment(item.get('startDate')), 'days');
  days = days === 0 ? null : days;
  return new Map({
    id: item.get('id'),
    title: item.get('name'),
    description: item.get('description'),
    color: category.get('color'),
    categoryName: category.get('name'),
    categoryId: category.get('id'),
    root: false,
    parent,
    start: moment(item.get('startDate')).format('DD MMM YY'),
    days,
    startDate: moment(item.get('startDate')).valueOf(),
    endDate: moment(item.get('endDate')).valueOf(),
    type: item.get('type'),
    childrenLength,
  });
};

const createMissionBarObject = (item, category) => {
  let className = 'chart-mission';
  if (item.get('type') === missionType.DEADLINE) {
    className = 'chart-deadline';
  }

  return new Map({
    id: item.get('id'),
    title: item.get('name'),
    itemDivTitle: '',
    description: item.get('description'),
    color: category.get('color'),
    group: item.get('id'),
    isCategory: false,
    className,
    canMove: false,
    canResize: false,
    canChangeGroup: false,
    start_time: moment(item.get('startDate')).valueOf(),
    end_time: moment(item.get('endDate')).valueOf(),
  });
};

const createTaskLeftTableObject = (item, mission, category) => {
  return new Map({
    id: item.get('id'),
    group: item.get('id'),
    title: item.get('name'),
    className: 'chart-task',
    isComplete: item.get('isComplete'),
    root: false,
    parent: mission.get('id'),
    color: category.get('color'),
    categoryId: category.get('id'),
    categoryName: category.get('name'),
    missionName: mission.get('name'),
    missionId: mission.get('id'),
    date: item.get('date') ? moment(item.get('date')) : null,
    startDate: item.get('date') ? moment(item.get('date')).valueOf() : null,
    isTask: true,
    createdAt: item.get('createdAt'),
  });
};

const createTaskBarObject = (item, parent) => {
  const date = item.get('date') ? moment(item.get('date')) : moment(parent.get('startDate'));
  const id = item.get('id');
  const title = item.get('name');
  const isComplete = item.get('isComplete');
  return new Map({
    id,
    title,
    itemDivTitle: '',
    className: 'chart-task',
    group: id,
    parent: parent.get('id'),
    canMove: false,
    canResize: false,
    canChangeGroup: false,
    date,
    start_time: date.valueOf(),
    end_time: date.valueOf(),
    isTask: true,
    isComplete,
    createdAt: item.get('createdAt'),
  });
};

export const mergeMissionsAndCategories = (categories, missions, tasksMap) => {
  if (!categories.size) {
    return {};
  }
  const tasks = tasksMap.toList().filter((t) => t.get('status') !== taskStatus.DELETED);
  if (!missions && !categories) return {};
  const result = categories.reduce(
    ({ groupsData, items }, category) => {
      const categoryId = category.get('id');
      const categoryMissions = missions.filter((mission) => mission.getIn(['category', 'id']) === categoryId);

      const durationData = findStartAndDuration(categoryMissions);
      const categoryMissionsObjects = categoryMissions.reduce(
        ({ missionGroupsData, missionItems }, mission) => {
          const missionTasks = tasks.filter((t) => t.get('roadmapMissionId') === mission.get('id'));
          return {
            missionGroupsData: missionGroupsData
              .push(createMissionLeftTableObject(mission, missionTasks.size, categoryId, category))
              .push(...missionTasks.map((task) => createTaskLeftTableObject(task, mission, category))),
            missionItems: missionItems
              .push(createMissionBarObject(mission, category))
              .push(...missionTasks.map((task) => createTaskBarObject(task, mission))),
          };
        },
        { missionGroupsData: new List(), missionItems: new List() },
      );
      return {
        groupsData: groupsData
          .push(createCategoryLeftTableObject(category, categoryMissions.size, durationData))
          .push(...categoryMissionsObjects.missionGroupsData),
        items: items
          .push(createCategoryBarObject(category, durationData))
          .push(...categoryMissionsObjects.missionItems),
      };
    },
    { groupsData: new List(), items: new List() },
  );
  return new Map(result);
};

export const formatDatesToString = (startDate, days) => {
  if (startDate == null || !startDate.isValid()) {
    return null;
  }
  const startDateText = startDate.format("DD MMM 'YY");
  if (!days) {
    return startDateText;
  }
  const endDateText = startDate.add(days, 'days').format("DD MMM 'YY");
  return `${startDateText} - ${endDateText}`;
};

export const calculateDays = (startDate, endDate) => {
  const days = startDate && endDate ? endDate.diff(startDate, 'days') : null;
  const daysValue = Math.abs(days);
  const daysText = daysValue ? `${daysValue} day${daysValue > 1 ? 's' : ''}` : '';
  return {
    value: daysValue,
    text: daysText,
  };
};

export const orderByDateAndName = (aDate, bDate, aName, bName) => {
  const aDateObj = new Date(aDate);
  const bDateObj = new Date(bDate);
  if (aDateObj - bDateObj === 0) {
    return aName.localeCompare(bName);
  }
  return aDateObj - bDateObj;
};

export const orderByDateAndCreatedAt = (aDate, bDate, aCreatedAt, bCreatedAt) => {
  const aDateObj = new Date(aDate);
  const bDateObj = new Date(bDate);
  if (aDateObj - bDateObj === 0) {
    const aCreatedDate = new Date(aCreatedAt);
    const bCreatedDate = new Date(bCreatedAt);
    return aCreatedDate - bCreatedDate;
  }
  return aDateObj - bDateObj;
};

/**
 * Filters missions and sorts them by start date
 * @param roadmap - roadmap object from getRoadmapData selector
 * @param openCategories - array of open categories
 */
export const filterRoadmapMissions = (roadmap, openCategories) => {
  if (!roadmap.size) {
    return {
      groupsData: [],
      items: [],
    };
  }

  const orderedGroups = roadmap
    .get('groupsData')
    .filter((c) => c.get('root'))
    .sort((a, b) => {
      if (a.get('id') === UNCATEGORISED_GROUP_ID) return 1;
      const start = a.get('startDate');
      const compareStart = b.get('startDate');
      if (!start) return 1;
      if (!compareStart) return -1;
      return new Date(start) - new Date(compareStart);
    });

  const filteredGroups = orderedGroups.reduce((categoriesAndMissions, group) => {
    const filteredMissions = roadmap
      .get('groupsData')
      .filter(
        (g) => !g.get('root') && g.get('parent') === group.get('id') && openCategories.indexOf(g.get('parent')) !== -1,
      )
      .sort((a, b) => {
        const aDate = new Date(a.get('startDate'));
        const bDate = new Date(b.get('startDate'));
        if (aDate - bDate === 0) {
          return a.get('endDate') - b.get('endDate');
        }
        return aDate - bDate;
      });

    const missionsWithTasks = filteredMissions.reduce((missions, mission) => {
      const missionTasks = roadmap
        .get('groupsData')
        .filter((g) => g.get('parent') === mission.get('id') && openCategories.indexOf(g.get('parent')) !== -1);
      const missionTasksWithDate = missionTasks
        .filter((g) => g.get('startDate'))
        .sort((a, b) =>
          orderByDateAndCreatedAt(a.get('startDate'), b.get('startDate'), a.get('createdAt'), b.get('createdAt')),
        );

      const missionTasksNoDate = missionTasks
        .filter((g) => !g.get('startDate'))
        .sort((a, b) => orderByDateAndName(a.get('createdAt'), b.get('createdAt'), a.get('title'), b.get('title')));

      return missions.push(mission).concat([...missionTasksWithDate, ...missionTasksNoDate]);
    }, new List());

    return categoriesAndMissions.push(group).concat(missionsWithTasks);
  }, new List());

  return {
    groupsData: filteredGroups.toJS(),
    items: roadmap.get('items').toJS(),
  };
};

export const appendTasksWithoutMissions = (roadmapData, tasksWithoutMissions, isUncategorisedTasksOpen) => {
  if (isEmpty(roadmapData)) return roadmapData;

  roadmapData.groupsData = roadmapData.groupsData.concat([
    {
      group: UNCATEGORISED_GROUP_ID,
      root: true,
      isTasksCategory: true,
      parent: null,
      childrenLength: 0,
      id: UNCATEGORISED_GROUP_ID,
    },
  ]);

  if (isUncategorisedTasksOpen) {
    roadmapData.groupsData = roadmapData.groupsData.concat(
      tasksWithoutMissions.map((t) => {
        return {
          id: t.id,
          group: t.id,
          title: t.name,
          className: 'chart-task',
          isComplete: !!t.isComplete,
          root: false,
          parent: UNCATEGORISED_GROUP_ID,
          date: t.date && moment(t.date).valueOf(),
          startDate: t.date && moment(t.date).valueOf(),
          isTask: true,
        };
      }),
    );

    roadmapData.items = roadmapData.items.concat(
      tasksWithoutMissions.map((t) => {
        return {
          id: t.id,
          parent: UNCATEGORISED_GROUP_ID,
          group: t.id,
          title: t.name,
          className: 'chart-task',
          isComplete: !!t.isComplete,
          date: t.date && moment(t.date),
          start_time: t.date && moment(t.date).valueOf(),
          end_time: t.date && moment(t.date).valueOf(),
          isTask: true,
        };
      }),
    );
  }
  return roadmapData;
};
