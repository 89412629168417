import React from 'react';

export default function Telescope() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#4F6987"
          d="M45.833 10.4c1.225 1.128 2.5 4.578 2.5 9.6 0 5.022-1.275 8.472-2.5 9.6V10.4zm-1.666 21.267c0 .919-.748 1.666-1.667 1.666-.92 0-1.667-.747-1.667-1.666V8.333c0-.919.748-1.666 1.667-1.666.92 0 1.667.747 1.667 1.666v23.334zM30 10.715l9.167-1.41v21.39L30 29.285v-18.57zm-1.667 20.118a.834.834 0 0 1-1.666 0V9.167a.834.834 0 0 1 1.666 0v21.666zm-9.166-3.689v-3.061A4.174 4.174 0 0 0 22.5 20a4.171 4.171 0 0 0-4.167-4.167A4.171 4.171 0 0 0 14.167 20a4.174 4.174 0 0 0 3.333 4.082v2.768l-5-.883V14.034L25 11.827v16.346l-5.833-1.029zm-.834-4.644a2.503 2.503 0 0 1-2.5-2.5c0-1.378 1.122-2.5 2.5-2.5 1.379 0 2.5 1.122 2.5 2.5s-1.121 2.5-2.5 2.5zM5 22.5h5.833v-5H5v5zm-2.5 1.667a.834.834 0 0 1-.833-.834v-6.666a.834.834 0 0 1 1.666 0v6.666c0 .46-.373.834-.833.834zM50 20c0-5.148-1.456-10.575-4.167-11.51v-.157A3.337 3.337 0 0 0 42.5 5a3.335 3.335 0 0 0-3.248 2.605l-9.266 1.426A2.496 2.496 0 0 0 27.5 6.667a2.503 2.503 0 0 0-2.5 2.5v.967l-13.478 2.378a.835.835 0 0 0-.689.821v2.5H4.846A2.494 2.494 0 0 0 2.5 14.167a2.503 2.503 0 0 0-2.5 2.5v6.666c0 1.379 1.122 2.5 2.5 2.5 1.085 0 2-.698 2.346-1.666h5.987v2.5c0 .404.291.75.689.82l5.33.941L6.775 46.257l1.45.82 9.275-16.41V50h1.667V30.668l9.275 16.409 1.45-.82-9.749-17.248 4.857.857v.967c0 1.379 1.122 2.5 2.5 2.5a2.496 2.496 0 0 0 2.486-2.364l9.266 1.426A3.335 3.335 0 0 0 42.5 35a3.337 3.337 0 0 0 3.333-3.333v-.156C48.544 30.575 50 25.148 50 20z"
        />
        <path
          fill="#CD4B53"
          d="M8.333 3.594A5.04 5.04 0 0 0 9.74 5a5.04 5.04 0 0 0-1.406 1.406A5.04 5.04 0 0 0 6.928 5a5.04 5.04 0 0 0 1.405-1.406zm-4.166 2.24A3.337 3.337 0 0 1 7.5 9.166a.833.833 0 1 0 1.667 0A3.337 3.337 0 0 1 12.5 5.833a.833.833 0 1 0 0-1.666A3.337 3.337 0 0 1 9.167.833a.833.833 0 1 0-1.667 0 3.337 3.337 0 0 1-3.333 3.334.833.833 0 1 0 0 1.666z"
        />
      </g>
    </svg>
  );
}
