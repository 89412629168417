import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';
import { gql } from '@apollo/client';
import { createClient } from '../utils';

const { api } = getConfig();

const client = createClient(api.graphQLEndpoint);

const register = gql`
  mutation register($userId: ID!, $deviceToken: String!) {
    registerAppleDeviceToken(userId: $userId, deviceToken: $deviceToken) {
      deviceToken
    }
  }
`;

const registerAppleDeviceToken = async ({ userId, deviceToken }) => {
  const { register: data } = await client.query(register, {
    userId,
    deviceToken,
  });
  return data;
};

export default registerAppleDeviceToken;
