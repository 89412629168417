import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import Thumbnail from 'components/molecules/CGAReportThumbnailPreview';
import Details from 'components/molecules/CGAReportDetailsPreview';

import css from './styles.scss';

export default function CGAReportCard(props) {
  const { publish_date: reportDate, name: title, type: subtitle, student_count: studentCount, id: reportId } = props;
  const [raised, setRaised] = useState(false);

  const parsedReportDate = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }).format(new Date(reportDate));

  const toggleRaised = () => setRaised(!raised);

  return (
    <Card className={css.card} onMouseOver={toggleRaised} onMouseOut={toggleRaised} raised={raised}>
      <Link to={`/cga-reports/${reportId}`}>
        <Thumbnail title={title} reportDate={parsedReportDate} className={css.thumbnail} />
        <Details
          title={title}
          subtitle={subtitle}
          studentCount={studentCount}
          reportDate={parsedReportDate}
          className={css.details}
        />
      </Link>
    </Card>
  );
}

CGAReportCard.propTypes = {
  publish_date: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  student_count: PropTypes.number,
  id: PropTypes.string,
};
