const sizes = {
  p50: '50',
  p100: '100',
  p200: '200',
};

/**
 * Returns a sized Url of a Profile Url.
 * @param {string} url to get size of.
 * @param {string} size to get.
 * @returns {string} Resized Image Url.
 */
function getProfileUrlOfSize(url, size) {
  const urlParts = url.split('/');
  const lastPartIndex = urlParts.length - 1;
  urlParts[lastPartIndex] = `${size}/${urlParts[lastPartIndex]}`;
  return urlParts.join('/');
}

export default {
  sizes,
  getProfileUrlOfSize,
};
