/* eslint-disable jsx-a11y/no-onchange */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import immutable from 'immutable';
import { trimSpecialCharacters } from 'utils/utils';
import ImmutablePropTypes from 'react-immutable-proptypes';
import MultiSelectDropdown from 'components/generic/Forms/MultiSelectDropdown';
import dropdown from 'components/generic/Icons/Dropdown';
import css from './styles.scss';

export default function Filter(props) {
  const {
    className,
    filterName,
    label,
    placeholder,
    inputClassName,
    inputType,
    selectOptions,
    defaultValue,
    onChange,
    roles,
    defaultTextValue,
    related,
  } = props;

  const textType = inputType === 'text';
  const dropType = inputType === 'select';
  const multiSelectType = inputType === 'multiSelect';
  const radioButtonType = inputType === 'radioButton';

  let selectedRole = 'All';
  if (roles && roles.length === 1) {
    selectedRole = roles[0];
  }

  let showTutors = 'My tutors';
  if (typeof related !== 'undefined' && !related) {
    showTutors = 'All tutors';
  }

  return (
    // If the input type is set to text return a text box,
    // otherwise if the type is a select input return
    // select element with props as values
    <div className={classNames(className, css.filter)}>
      <label htmlFor={filterName}>{label}</label>
      {textType && (
        <input
          className={classNames(inputClassName, css.filterInput)}
          type="text"
          placeholder={placeholder}
          onChange={onChange}
          name={filterName}
          data-test-id={filterName}
          defaultValue={defaultTextValue || ''}
        />
      )}
      {dropType && (
        <div className={css.selectWrapper}>
          <select
            className={classNames(inputClassName, css.filterInput, css.selectInput)}
            onChange={onChange}
            name={filterName}
            data-test-id={filterName}
            defaultValue={selectedRole}
          >
            <option value="All" data-test-id={`${filterName}_AllOptions`}>
              All
            </option>
            {selectOptions.map((option, i) => {
              const isMap = immutable.Map.isMap(option);
              const optionLabel = isMap ? option.get('label') : option;
              return (
                <option
                  key={i}
                  value={isMap ? option.get('value') : option}
                  data-test-id={`${filterName}_${trimSpecialCharacters(optionLabel)}`}
                >
                  {optionLabel}
                </option>
              );
            })}
          </select>
          {dropdown({ className: css.dropdownIcon })}
        </div>
      )}
      {multiSelectType && (
        <MultiSelectDropdown
          id={filterName}
          dataTestId={filterName}
          options={selectOptions}
          optionLabelSelector="label"
          placeholder={placeholder}
          onValueChange={onChange}
          multiple
          defaultValue={defaultValue}
          className={css.multiSelect}
        />
      )}
      {radioButtonType && (
        <div className={css.radioButtons}>
          {selectOptions.map((option) => (
            <label className={css.radio} key={option.get('value')}>
              <input
                type="radio"
                value={option.get('value')}
                name={option.get('value')}
                checked={option.get('value') === showTutors}
                onChange={onChange}
              />
              {option.get('value')}
            </label>
          ))}
        </div>
      )}
    </div>
  );
}

Filter.displayName = 'Filter';

Filter.propTypes = {
  className: PropTypes.string,
  filterName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  inputClassName: PropTypes.string,
  inputType: PropTypes.string.isRequired,
  selectOptions: ImmutablePropTypes.list,
  defaultValue: ImmutablePropTypes.list,
  onChange: PropTypes.func,
  roles: PropTypes.array,
  defaultTextValue: PropTypes.string,
  related: PropTypes.bool,
};
