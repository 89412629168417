import React from 'react';
import TableBody from '@material-ui/core/TableBody';
import UniversityCard, { UniversityCardProps } from '../UniversityCard';
import TableHeader, { UniversityNameHeaderProps } from './TableHeader';
import { StyledTable } from './index.style';
import { Application } from '../type';

export type UniversityListProps = {
  applications: Application[];
} & UniversityNameHeaderProps &
  Pick<
    UniversityCardProps,
    'getExtraDeleteAppButtonProps' | 'getExtraActionButtonProps' | 'getExtraUniversityNameProps'
  >;

const UniversityList = ({ index, applications, groupText, count, ...restProps }: UniversityListProps) => {
  if (!applications.length) return null;
  return (
    <StyledTable>
      <TableHeader index={index} groupText={groupText} count={count} />
      <TableBody>
        {applications.map((application) => (
          <UniversityCard key={application.id} application={application} {...restProps} />
        ))}
      </TableBody>
    </StyledTable>
  );
};

export default UniversityList;
