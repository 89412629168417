import {
  fetchUserOrders,
  getRegionalRateCard,
  purchaseAdditionalHours,
  closeUserOrder,
  fetchProductsByType,
} from './orderService';

export default {
  getRegionalRateCard,
  purchaseAdditionalHours,
  fetchUserOrders,
  closeUserOrder,
  fetchProductsByType,
};
