import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'auto-bind';
import TransitionGroup from 'react-transition-group/TransitionGroup';
import Header from 'components/molecules/Header';
import Task from 'components/organisms/Tasks/Task';
import CollapsiblePanel from 'components/molecules/CollapsiblePanel';
import Button from 'components/molecules/Button';
import SearchMissionModal from 'components/organisms/SearchMissionModal';
import TaskModal from 'components/organisms/TaskModal';
import AddNew from 'components/molecules/AddNew';
import moment from 'moment';
import EmptyTaskList from 'components/pages/Tasks/TaskList/EmptyTaskList';
import * as Logger from '@crimson-education/browser-logger';
import classnames from 'classnames';
import TaskTransition from 'components/organisms/Tasks/TaskTransition';
import css from './styles.scss';

const SECTION_OVERDUE = 'OVERDUE';
const SECTION_IN_7_DAYS = '7DAYS';
const SECTION_IN_30_DAYS = '30DAYS';
const SECTION_LATER = 'LATER';
const SECTION_COMPLETED = 'COMPLETED';
const TASK_HEIGHT = 50;

export default class TaskList extends Component {
  constructor(props) {
    super(props);

    this.numOfTasksToShow = 10;
    this.taskInputRefs = [];

    this.state = {
      isTagMissionModalOpen: false,
      isTaskModalOpen: false,
      numOfTasksShowing: this.numOfTasksToShow,
      taskCurrentlyEditing: null,
      openSections: [SECTION_OVERDUE, SECTION_IN_7_DAYS, SECTION_IN_30_DAYS, SECTION_LATER],
    };

    autoBind(this);
  }

  componentDidMount() {
    const { fetchTasksByUserId, fetchRoadmapByUserId, setTasksListUser, userId } = this.props;
    fetchTasksByUserId(userId);
    fetchRoadmapByUserId(userId);
    setTasksListUser(userId);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { tasksOverdue, tasksInSevenDays, tasksInThirtyDays, tasksInLater, tasksCompleted } = this.props;

    const {
      tasksOverdue: nextTasksOverdue,
      tasksInSevenDays: nextTasksInSevenDays,
      tasksInThirtyDays: nextTasksInThirtyDays,
      tasksInLater: nextTasksInLater,
      tasksCompleted: nextTasksCompleted,
    } = nextProps;

    const { openSections } = this.state;

    let sectionToOpen;

    if (nextTasksOverdue.length > tasksOverdue.length) {
      sectionToOpen = SECTION_OVERDUE;
    } else if (nextTasksInSevenDays.length > tasksInSevenDays.length) {
      sectionToOpen = SECTION_IN_7_DAYS;
    } else if (nextTasksInThirtyDays.length > tasksInThirtyDays.length) {
      sectionToOpen = SECTION_IN_30_DAYS;
    } else if (nextTasksInLater.length > tasksInLater.length) {
      sectionToOpen = SECTION_LATER;
    } else if (nextTasksCompleted.length > tasksCompleted.length) {
      sectionToOpen = SECTION_COMPLETED;
    }

    if (sectionToOpen && !openSections.includes(sectionToOpen)) {
      this.setState({
        openSections: openSections.concat(sectionToOpen),
      });
    }
  }

  onSubmitTask(task) {
    const { createTask, updateTask } = this.props;
    if (task.id) {
      updateTask(task);
    } else {
      createTask(task);
    }
    this.setState(({ isTaskModalOpen }) => ({ isTaskModalOpen: !isTaskModalOpen }));
  }

  onEnterDown(taskListPosition, isLastTaskInGroup, isQuickAdd) {
    if (isQuickAdd && isLastTaskInGroup) {
      this.createTask();
      Logger.trackEvent({ message: 'task created on enter' });
    } else if (taskListPosition !== this.taskInputRefs.filter((taskRef) => taskRef.ref.current).length - 1) {
      this.selectNextTask(taskListPosition);
    }
  }

  getNextTaskRef(taskListPosition) {
    if (taskListPosition === this.taskInputRefs.length - 1) {
      return null;
    }
    return this.taskInputRefs.slice(taskListPosition + 1).find((taskRef) => taskRef.ref.current);
  }

  getPreviousTaskRef(taskListPosition) {
    if (taskListPosition === 0) {
      return null;
    }
    return this.taskInputRefs
      .slice(0, taskListPosition)
      .reverse()
      .find((taskRef) => taskRef.ref.current);
  }

  createTask() {
    const { createTaskRedux, userId, eventId } = this.props;
    const task = {
      userId,
      name: '',
      date: null,
      autoFocus: true,
      eventId: eventId ? eventId.toString() : eventId,
    };

    createTaskRedux(task);
  }

  selectNextTask(taskListPosition) {
    const nextTaskRef = this.getNextTaskRef(taskListPosition);
    nextTaskRef && nextTaskRef.ref.current.select();
  }

  focusNextTask(taskListPosition) {
    const nextTaskRef = this.getNextTaskRef(taskListPosition);
    nextTaskRef && nextTaskRef.ref.current.focus();
  }

  focusPreviousTask(taskListPosition) {
    const nextTaskRef = this.getPreviousTaskRef(taskListPosition);
    nextTaskRef && nextTaskRef.ref.current.focus();
  }

  updateTask(taskUpdates) {
    const { updateTask, canEdit } = this.props;
    if (canEdit && updateTask) {
      updateTask(taskUpdates);
    }
  }

  deleteTask(taskId) {
    const { deleteTask, canDelete } = this.props;
    if (canDelete && deleteTask) {
      this.taskInputRefs = this.taskInputRefs.filter((ref) => ref.taskId !== taskId);
      deleteTask(taskId);
    }
  }

  deleteTaskFromModal() {
    const { taskToEdit } = this.state;
    const { deleteTask, canDelete } = this.props;
    if (canDelete && deleteTask) {
      deleteTask(taskToEdit.id);
    }
    this.toggleTaskModal();
  }

  deleteTaskUsingKeyboard(taskId, taskListPosition) {
    if (taskListPosition === 0) {
      this.focusNextTask(taskListPosition);
    } else {
      this.focusPreviousTask(taskListPosition);
    }
    this.deleteTask(taskId);
  }

  openTaskDetailsModal(taskToEdit) {
    this.setState({ taskToEdit }, this.toggleTaskModal);
  }

  toggleTaskModal() {
    this.setState(({ isTaskModalOpen, taskToEdit }) => ({
      isTaskModalOpen: !isTaskModalOpen,
      taskToEdit: !isTaskModalOpen ? taskToEdit : null,
    }));
  }

  openTagMissionModal(task) {
    this.setState({
      isTagMissionModalOpen: true,
      taskCurrentlyEditing: task,
    });
  }

  closeTagMissionModal() {
    this.setState({
      isTagMissionModalOpen: false,
      taskCurrentlyEditing: null,
    });
  }

  submitSelectMission(mission) {
    const { taskCurrentlyEditing } = this.state;
    const roadmapMissionId = mission.id;

    this.updateTask({ id: taskCurrentlyEditing.id, roadmapMissionId, mission });
    this.setState({
      isTagMissionModalOpen: false,
      taskCurrentlyEditing: null,
    });
  }

  showMore() {
    const { numOfTasksShowing } = this.state;
    this.setState({
      numOfTasksShowing: numOfTasksShowing + this.numOfTasksToShow,
    });
  }

  handleDropdownToggled(section) {
    const { openSections } = this.state;

    const index = openSections.indexOf(section);
    const newOpenSections = index > -1 ? openSections.filter((item) => item !== section) : openSections.concat(section);

    this.setState({
      openSections: newOpenSections,
    });
  }

  renderTaskSection(tasks, startPosition, isQuickAdd) {
    const {
      app: { isMobile },
      canEdit,
      canDelete,
    } = this.props;
    return tasks.length ? (
      <div className={css.taskListSection} style={{ minHeight: `${tasks.length * TASK_HEIGHT}px` }}>
        <TransitionGroup>
          {tasks.map((task, indexInSection) => {
            const { id, name, date, isComplete, mission, userId, oldOptimisticId } = task;

            // Index of task in order as shown on the UI.
            const taskListPosition = startPosition + indexInSection;

            const textInputRef = React.createRef();
            this.taskInputRefs[taskListPosition] = { ref: textInputRef, taskId: task.id };

            return (
              <TaskTransition key={oldOptimisticId || id} index={indexInSection} taskHeight={TASK_HEIGHT}>
                <Task
                  index={taskListPosition}
                  taskId={id}
                  name={name}
                  date={date && moment(date)}
                  mission={mission}
                  isComplete={isComplete}
                  inputTextRef={textInputRef}
                  onEnterDown={() =>
                    this.onEnterDown(taskListPosition, indexInSection === tasks.length - 1, isQuickAdd)
                  }
                  onArrowDown={() => this.focusNextTask(taskListPosition)}
                  onArrowUp={() => this.focusPreviousTask(taskListPosition)}
                  onToggleComplete={() => this.updateTask({ id, isComplete: !isComplete })}
                  onDateChange={(date) => this.updateTask({ id, date: date ? date.format() : null })}
                  onNameChange={(name) => this.updateTask({ id, name, userId })}
                  onDeleteTaskFromKeyBoard={() => this.deleteTaskUsingKeyboard(id, taskListPosition)}
                  onDeleteTask={() => this.deleteTask(id)}
                  onClickTagMission={() => this.openTagMissionModal(task)}
                  onClickDetails={() => this.openTaskDetailsModal(task)}
                  onClearMission={() => this.updateTask({ id, roadmapMissionId: null, mission: null })}
                  isCompactView={isMobile}
                  canEdit={canEdit}
                  canDelete={canDelete}
                />
              </TaskTransition>
            );
          })}
        </TransitionGroup>
      </div>
    ) : null;
  }

  render() {
    const {
      openSections,
      numOfTasksShowing,
      isTagMissionModalOpen,
      isTaskModalOpen,
      taskToEdit,
      taskCurrentlyEditing,
    } = this.state;

    const {
      userId,
      tasksWithNoDate,
      tasksOverdue,
      tasksInSevenDays,
      tasksInThirtyDays,
      tasksInLater,
      tasksCompleted,
      roadmapId,
      isStudentView,
      tasksFetched,
      canEdit,
      simple,
      eventId,
    } = this.props;

    let tasks = tasksWithNoDate.concat(tasksOverdue, tasksInSevenDays, tasksInThirtyDays, tasksInLater, tasksCompleted);
    if (eventId) {
      tasks = tasks.filter((x) => x.eventId === eventId.toString());
    }
    const tasksExist = tasks.length > 0;

    const tasksWithNoDateStartIndex = 0;
    const tasksOverdueStartIndex = tasksWithNoDate.length;
    const tasksInSevenDaysStartIndex = tasksOverdueStartIndex + tasksOverdue.length;
    const tasksInThirtyDaysStartIndex = tasksInSevenDaysStartIndex + tasksInSevenDays.length;
    const tasksInLaterStartIndex = tasksInThirtyDaysStartIndex + tasksInThirtyDays.length;
    const tasksCompletedStartIndex = tasksInLaterStartIndex + tasksInLater.length;

    const actionButtons =
      canEdit && tasksExist
        ? [
            {
              buttonText: 'Add new task',
              buttonAction: this.toggleTaskModal,
              icon: 'Add',
            },
          ]
        : [];

    if (!tasksExist && !tasksFetched) {
      return null;
    }
    return (
      <div data-ga-category="Tasks">
        {!simple && canEdit && !isStudentView && tasksExist && (
          <div className={css.optionsBar}>
            <div className={css.optionsRight}>
              <Button
                dataTestId="headerButton_Addnewtask"
                dataGaLabel="Add new task (top)"
                small
                onClick={this.toggleTaskModal}
              >
                Add new task
              </Button>
            </div>
          </div>
        )}
        {!simple && isStudentView && <Header title="Tasks" actionButtons={actionButtons} />}
        <div className={classnames(css.tasksListTab, { [css.simple]: simple })}>
          {!tasksExist && tasksFetched && !simple ? (
            <EmptyTaskList onAddTask={this.toggleTaskModal} canEdit={canEdit} />
          ) : (
            <div>
              {!simple && (
                <div className={classnames(css.tasksHeading, { [css.tasksHeadingNoRoadmap]: !roadmapId })}>
                  <div className={css.heading}>Task</div>
                  <div className={classnames(css.heading, css.dueDate)}>Due date</div>
                  {roadmapId && <div className={css.heading}>Mission and category</div>}
                </div>
              )}
              <div className={css.tasksListContainer}>
                <div className={css.tasksNoDate}>
                  {simple
                    ? this.renderTaskSection(tasks, 0, true)
                    : !!tasksWithNoDate.length &&
                      this.renderTaskSection(tasksWithNoDate, tasksWithNoDateStartIndex, true)}
                  {canEdit && (
                    <AddNew
                      text="Add new task"
                      dataTestId="addNewTask"
                      onClick={this.createTask}
                      className={css.addNew}
                    />
                  )}
                  {!canEdit && !tasksExist && <div className={css.noTasks}>No tasks have been added</div>}
                </div>

                {!simple && (
                  <React.Fragment>
                    {tasksOverdue.length > 0 && (
                      <CollapsiblePanel
                        handleToggle={() => this.handleDropdownToggled(SECTION_OVERDUE)}
                        isOpen={openSections.includes(SECTION_OVERDUE)}
                        title="Overdue"
                        className={css.tasksOverdue}
                      >
                        {this.renderTaskSection(tasksOverdue, tasksOverdueStartIndex)}
                      </CollapsiblePanel>
                    )}
                    <CollapsiblePanel
                      handleToggle={() => this.handleDropdownToggled(SECTION_IN_7_DAYS)}
                      isOpen={openSections.includes(SECTION_IN_7_DAYS)}
                      title="In 7 days"
                      className={css.tasksInSevenDays}
                    >
                      {this.renderTaskSection(tasksInSevenDays, tasksInSevenDaysStartIndex)}
                    </CollapsiblePanel>
                    <CollapsiblePanel
                      handleToggle={() => this.handleDropdownToggled(SECTION_IN_30_DAYS)}
                      isOpen={openSections.includes(SECTION_IN_30_DAYS)}
                      title="In 30 days"
                      className={css.tasksInThirtyDays}
                    >
                      {this.renderTaskSection(tasksInThirtyDays, tasksInThirtyDaysStartIndex)}
                    </CollapsiblePanel>
                    <CollapsiblePanel
                      handleToggle={() => this.handleDropdownToggled(SECTION_LATER)}
                      isOpen={openSections.includes(SECTION_LATER)}
                      title="Later"
                      className={css.tasksInLater}
                    >
                      {this.renderTaskSection(tasksInLater, tasksInLaterStartIndex)}
                    </CollapsiblePanel>
                    <CollapsiblePanel
                      handleToggle={() => this.handleDropdownToggled(SECTION_COMPLETED)}
                      isOpen={openSections.includes(SECTION_COMPLETED)}
                      title="Completed"
                      className={css.tasksCompleted}
                    >
                      {tasksCompleted.length > 0 && (
                        <div>
                          {this.renderTaskSection(tasksCompleted.slice(0, numOfTasksShowing), tasksCompletedStartIndex)}
                          {tasksCompleted.length > numOfTasksShowing && (
                            <Button onClick={this.showMore} secondary>
                              Show more
                            </Button>
                          )}
                        </div>
                      )}
                    </CollapsiblePanel>
                  </React.Fragment>
                )}
              </div>
            </div>
          )}
          {isTagMissionModalOpen && (
            <SearchMissionModal
              userId={userId}
              roadmapId={roadmapId}
              isOpen={isTagMissionModalOpen}
              onSubmit={this.submitSelectMission}
              onClose={this.closeTagMissionModal}
              onSecondarySubmit={this.closeTagMissionModal}
              missionSelected={taskCurrentlyEditing.mission}
            />
          )}
          {isTaskModalOpen && (
            <TaskModal
              userId={userId}
              roadmapId={roadmapId}
              isOpen={isTaskModalOpen}
              onSubmit={this.onSubmitTask}
              onClose={this.toggleTaskModal}
              onDelete={this.deleteTaskFromModal}
              taskToEdit={taskToEdit}
            />
          )}
        </div>
      </div>
    );
  }
}

TaskList.propTypes = {
  app: PropTypes.object,
  userId: PropTypes.string.isRequired,
  roadmapId: PropTypes.string,
  fetchTasksByUserId: PropTypes.func.isRequired,
  tasksFetched: PropTypes.bool.isRequired,
  fetchRoadmapByUserId: PropTypes.func,
  createTask: PropTypes.func,
  createTaskRedux: PropTypes.func,
  updateTask: PropTypes.func,
  deleteTask: PropTypes.func,
  setTasksListUser: PropTypes.func,
  isStudentView: PropTypes.bool,
  tasksWithNoDate: PropTypes.array,
  tasksOverdue: PropTypes.array,
  tasksInSevenDays: PropTypes.array,
  tasksInThirtyDays: PropTypes.array,
  tasksInLater: PropTypes.array,
  tasksCompleted: PropTypes.array,
  canEdit: PropTypes.bool.isRequired,
  canDelete: PropTypes.bool.isRequired,
  simple: PropTypes.bool,
  eventId: PropTypes.number,
};
