import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import Header from 'components/molecules/Header';
import IncompleteReportCard from 'components/organisms/IncompleteReportCard';
import Banner from 'components/molecules/Banner';
import GirlWoohoo from 'components/atoms/graphics/GirlWoohoo';
import Info from '@material-ui/icons/Info';
import Button from 'components/molecules/Button/index';
import Body from 'components/atoms/typography/Body';
import { Acl, roleTypes } from '@crimson-education/common-config';

import css from './styles.scss';

function EmptyState() {
  return (
    <div className={css.emptyState}>
      <GirlWoohoo className={css.graphic} />
      <div className={css.emptyContent}>
        <Body className={css.yourDone}>You’re all done!</Body>
        <Body>Well done completing your session reports.</Body>
        <Body>Would you like to book another session?</Body>
        <Link to="/calendar">
          <Button className={css.button}>Yes, book a session</Button>
        </Link>
      </div>
    </div>
  );
}
class IncompleteReport extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { reports, showCompleteReportModal, nextBillingPeriod, bookings, loginUser } = this.props;
    const durationInDays = nextBillingPeriod && nextBillingPeriod.diff(moment(), 'days');
    const timeToCompleteText = durationInDays
      ? `You have ${durationInDays} days left to complete them before your next billing cycle.`
      : 'You need to complete them before the next billing cycle.';
    return (
      <div>
        {Acl.checkRole(loginUser.get('userRoles'), roleTypes.STRATEGIST) ||
        Acl.checkRole(loginUser.get('userRoles'), roleTypes.CASE_MANAGER) ? (
          <React.Fragment>
            <Header title="Session Reports" />
            <div className={css.contents} data-ga-category="Session Reports">
              {reports.length ? (
                reports
                  .sort((a, b) => moment(a.start).isAfter(b.start))
                  .map((report, i) => (
                    <IncompleteReportCard
                      booking={bookings[report.eventId]}
                      lesson={report}
                      feedback
                      key={`report-${i}`}
                      onClick={showCompleteReportModal}
                    />
                  ))
              ) : (
                <EmptyState />
              )}
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Header title="Session Reports" />
            {reports.length ? (
              <Banner
                icon={
                  <div className={css.bannerIcon}>
                    <Info style={{ height: '2rem', width: '2rem' }} />
                  </div>
                }
                backgroundColor="#cd4b53"
                text={
                  (reports.length > 1 &&
                    `You have ${reports.length} incomplete session reports. ${timeToCompleteText}`) ||
                  (reports.length === 1 &&
                    `You have ${reports.length} incomplete session report. ${timeToCompleteText}`) ||
                  'You have no more incomplete session reports.'
                }
              />
            ) : null}
            <div className={css.contents} data-ga-category="Session Reports">
              {reports.length ? (
                reports
                  .sort((a, b) => moment(a.start).isAfter(b.start))
                  .map((report, i) => (
                    <IncompleteReportCard
                      booking={bookings[report.eventId]}
                      lesson={report}
                      feedback={false}
                      key={`report-${i}`}
                      onClick={showCompleteReportModal}
                    />
                  ))
              ) : (
                <EmptyState />
              )}
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

IncompleteReport.propTypes = {
  reports: PropTypes.array,
  bookings: PropTypes.object,
  showCompleteReportModal: PropTypes.func,
  nextBillingPeriod: PropTypes.object,
  loginUser: PropTypes.object,
};

export default IncompleteReport;
