import moment from 'moment';
import { BookingStatusTypes, Acl } from '@crimson-education/common-config';
import { ModalTextTypes } from 'utils/bookingSummary';
import { bookingModalModes, footerType, messages, alertMessageStatus } from './constants';

const EDIT = 'EDIT';
const CANCEL = 'CANCEL';
const ADJUST = 'ADJUST';

const modeOverrideExceptions = {
  DECLINE_BOOKING: bookingModalModes.DECLINE_BOOKING,
  CANCEL_BOOKING: bookingModalModes.CANCEL_BOOKING,
  CANCEL_REQUEST: bookingModalModes.CANCEL_REQUEST,
  SUGGEST_NEW_TIME: bookingModalModes.SUGGEST_NEW_TIME,
};

export function getMode(mode, booking, userRoles, loginUser, bookAs) {
  if (booking) {
    if (modeOverrideExceptions[mode]) return mode;

    const isCreator = loginUser.userId === booking.creatorUserId;
    const isParticipant = booking.creatorUserId === booking.otherUserId;

    const isOnBehalfOfCreator = bookAs && bookAs.userId === booking.creatorUserId;
    const isOnBehalfOfParticipant = bookAs && loginUser.userId === booking.otherUserId;

    const start = moment(booking.start);
    const end = moment(booking.end);

    const minutesUntilEndOfSession = moment.duration(end.diff(moment())).asMinutes();
    const sessionLength = moment.duration(end.diff(start)).asMinutes();

    const isInThePast = minutesUntilEndOfSession < 0;
    const isInProgress = !isInThePast && minutesUntilEndOfSession < sessionLength;

    if (booking.status === BookingStatusTypes.TENTATIVE) {
      if (isCreator) {
        if (isInThePast || isInProgress) {
          if (Acl.isStudent(userRoles)) return bookingModalModes.SENDER_TENTATIVE_AFTER_STUDENT;
          return bookingModalModes.SENDER_TENTATIVE_AFTER;
        }
        return bookingModalModes.SENDER_TENTATIVE_BEFORE;
      } else if (isParticipant) {
        if (isInThePast || isInProgress) {
          if (Acl.isStudent(userRoles)) return bookingModalModes.RECEIVER_TENTATIVE_AFTER_STUDENT;
          if (Acl.isTutor(userRoles)) return bookingModalModes.RECEIVER_TENTATIVE_AFTER_TUTOR;
          return bookingModalModes.RECEIVER_TENTATIVE_AFTER_ADMIN;
        }
        return bookingModalModes.RECEIVER_TENTATIVE_BEFORE;
      } else if (isOnBehalfOfCreator) {
        if (isInThePast || isInProgress) {
          if (Acl.isStudent(bookAs.userRoles)) return bookingModalModes.SENDER_TENTATIVE_AFTER_STUDENT;
          return bookingModalModes.SENDER_TENTATIVE_AFTER;
        }
        return bookingModalModes.SENDER_TENTATIVE_BEFORE;
      } else if (isOnBehalfOfParticipant) {
        if (isInThePast || isInProgress) {
          if (Acl.isStudent(userRoles)) return bookingModalModes.RECEIVER_TENTATIVE_AFTER_STUDENT;
          if (Acl.isTutor(userRoles)) return bookingModalModes.RECEIVER_TENTATIVE_AFTER_TUTOR;
          return bookingModalModes.RECEIVER_TENTATIVE_AFTER_ADMIN;
        }
        return bookingModalModes.RECEIVER_TENTATIVE_BEFORE;
      }
      return bookingModalModes.TENTATIVE_VIEW_ONLY;
    } else if (booking.status === BookingStatusTypes.CONFIRMED) {
      if (isCreator || isOnBehalfOfCreator) {
        if (isInProgress) return bookingModalModes.SENDER_CONFIRMED_DURING;
        return bookingModalModes.SENDER_CONFIRMED;
      }
      if (isInProgress) return bookingModalModes.RECEIVER_CONFIRMED_DURING;
      return bookingModalModes.RECEIVER_CONFIRMED;
    } else if (booking.status === BookingStatusTypes.CANCELLED) {
      return bookingModalModes.BOOKING_CANCELLED;
    }
    return bookingModalModes.SENDER_CONFIRMED_VIEW_ONLY;
  }

  return bookingModalModes.NEW_BOOKING;
}

function buildAwaitingConfirmationMessage(otherUserFirstName) {
  return `Awaiting confirmation from ${otherUserFirstName}`;
}

function buildConfirmedByOtherUserMessage(otherUserFirstName) {
  return `${otherUserFirstName} confirmed this booking`;
}

function buildDeletePendingInThePastMessage(otherUserFirstName) {
  return `${otherUserFirstName} left this request pending. These package hours are on hold until you delete this session or your student confirms.`;
}

function buildStudentPendingInThePastMessage(otherUserFirstName) {
  return `${otherUserFirstName} left this request pending.`;
}

export function getBookingModalSettings(props, mode) {
  const booking = props.booking;
  const otherUserFirstName = booking.otherUser && booking.otherUser.firstName;

  const defaultSettings = {
    mode,
    modalHeader: '',
    bodyText: '',
    modalClassName: '',
    alertMessage: '',
    alertMessageStatus: alertMessageStatus.DEFAULT,
    options: [],
    footerType: '',
    eventSummary: true,
    extraFields: {},
  };

  const modeSettings = {
    [bookingModalModes.SENDER_TENTATIVE_BEFORE]: {
      modalHeader: booking.name,
      alertMessage: buildAwaitingConfirmationMessage(otherUserFirstName),
      alertMessageStatus: alertMessageStatus.PENDING,
      options: [EDIT, CANCEL],
      footerType: footerType.CLOSE,
    },
    [bookingModalModes.SENDER_TENTATIVE_AFTER_STUDENT]: {
      modalHeader: booking.name,
      alertMessage: buildStudentPendingInThePastMessage(otherUserFirstName),
      alertMessageStatus: alertMessageStatus.ERROR,
      footerType: footerType.CLOSE,
    },
    [bookingModalModes.SENDER_TENTATIVE_AFTER]: {
      modalHeader: 'Delete this request?',
      alertMessage: buildDeletePendingInThePastMessage(otherUserFirstName),
      alertMessageStatus: alertMessageStatus.ERROR,
      footerType: footerType.SENDER_DELETE_IN_THE_PAST,
    },
    [bookingModalModes.RECEIVER_TENTATIVE_BEFORE]: {
      modalHeader: 'Can you attend?',
      options: [ADJUST],
      footerType: footerType.RECEIVER_CONFIRM,
    },
    [bookingModalModes.RECEIVER_TENTATIVE_AFTER_STUDENT]: {
      modalHeader: 'Did this session happen?',
      alertMessage: `You left this request pending. The package hours are on hold until you confirm this session or ${otherUserFirstName} deletes it.`,
      alertMessageStatus: alertMessageStatus.ERROR,
      footerType: footerType.RECEIVER_CONFIRM_IN_THE_PAST_STUDENT,
    },
    [bookingModalModes.RECEIVER_TENTATIVE_AFTER_TUTOR]: {
      modalHeader: 'Did this session happen?',
      footerType: footerType.RECEIVER_CONFIRM_IN_THE_PAST,
      alertMessage: messages.PastPending,
      alertMessageStatus: alertMessageStatus.ERROR,
    },
    [bookingModalModes.RECEIVER_TENTATIVE_AFTER_ADMIN]: {
      modalHeader: 'Did this session happen?',
      footerType: footerType.RECEIVER_CONFIRM_IN_THE_PAST_ADMIN,
      alertMessage: messages.PastPending,
      alertMessageStatus: alertMessageStatus.ERROR,
    },
    [bookingModalModes.TENTATIVE_VIEW_ONLY]: {
      modalHeader: booking.name,
      footerType: footerType.CLOSE,
    },
    [bookingModalModes.SENDER_CONFIRMED]: {
      modalHeader: booking.name,
      alertMessage: buildConfirmedByOtherUserMessage(otherUserFirstName),
      alertMessageStatus: alertMessageStatus.CONFIRMED,
      options: [EDIT, CANCEL],
      footerType: footerType.CONFIRMED,
    },
    [bookingModalModes.SENDER_CONFIRMED_DURING]: {
      modalHeader: booking.name,
      alertMessage: messages.BookingInProgressWarning,
      footerType: footerType.CONFIRMED,
    },
    [bookingModalModes.RECEIVER_CONFIRMED]: {
      modalHeader: booking.name,
      alertMessage: messages.IConfirmed,
      alertMessageStatus: alertMessageStatus.CONFIRMED,
      options: [CANCEL],
      footerType: footerType.CONFIRMED,
    },
    [bookingModalModes.RECEIVER_CONFIRMED_DURING]: {
      modalHeader: booking.name,
      alertMessage: messages.BookingInProgressWarning,
      footerType: footerType.CONFIRMED,
    },
    [bookingModalModes.SENDER_CONFIRMED_VIEW_ONLY]: {
      modalHeader: booking.name,
      modalClassName: ModalTextTypes.CONFIRMED,
      footerType: footerType.CONFIRMED,
    },
    [bookingModalModes.BOOKING_CANCELLED]: {
      modalHeader: 'Session Cancelled',
      modalClassName: ModalTextTypes.CONFIRMED,
      footerType: footerType.CONFIRMED,
    },
    [bookingModalModes.RECEIVER_CONFIRMED_VIEW_ONLY]: {
      modalHeader: booking.name,
      modalClassName: ModalTextTypes.CONFIRMED,
      footerType: footerType.CONFIRMED,
    },
    [bookingModalModes.DECLINE_BOOKING]: {
      modalHeader: 'Why are you declining this session?',
      extraFields: { declineRadioButtons: true, reasonBox: true },
      footerType: footerType.RECEIVER_DECLINE,
      eventSummary: false,
    },
    [bookingModalModes.CANCEL_REQUEST]: {
      modalHeader: 'Cancel request',
      bodyText: 'Are you sure you want to cancel this booking request?',
      footerType: footerType.SENDER_CANCEL,
      eventSummary: false,
    },
    [bookingModalModes.CANCEL_BOOKING]: {
      modalHeader: 'Cancel booking',
      bodyText: 'Are you sure you want to cancel this booking?',
      footerType: footerType.SENDER_CANCEL,
      eventSummary: false,
    },
    [bookingModalModes.SUGGEST_NEW_TIME]: {
      modalHeader: 'Suggest a new time',
      footerType: footerType.RECEIVER_SUGGEST_NEW_TIME,
      extraFields: { adjustTime: true, reasonBox: true },
    },
  };

  return Object.assign({}, defaultSettings, modeSettings[mode]);
}
