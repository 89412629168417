export default [
  // ielts (leaf node, no curriculum)
  '4f71c288-ec6d-4ed5-8b88-24d90b147e33',

  // toefl (leaf node, no curriculum)
  'faffbd62-e5f5-455f-b59b-3d82bef04546',

  // IB
  '0fb06771-d31f-4e43-88e4-034328fbeefa',

  // CIE
  '616bdaac-0ffa-4b6e-b11a-f0ffc72d5a11',

  // NCEA
  '73436cfe-da19-4202-9d00-e40a4d691fb9',

  // HSC
  '426c0962-ffb4-4a26-814c-e56015da0b62',

  // VCE
  '223f4dd6-94da-4d01-97a9-ef1dfe90fb48',

  // NZQA Scholarship
  '0b54ad61-65be-48bd-8c24-b4cdd5bc4128',

  // SAT Reasoning
  '8bdca88d-70bc-4e14-bb45-b23e756a8229',

  // SAT Subjects
  '47231aab-a82e-42e0-9dae-9de26c238c84',

  // ACT
  '9b5be1f8-afd3-4260-af6c-a181dc875236',

  // AP
  'f7308d83-d1b6-4d3e-8caf-06a8225556cc',

  // UCAT (leaf node, no curriculum)
  '9176a3dd-22c6-4fd0-9f29-d0fd954a2aff',

  // GAMSAT (leaf node, no curriculum)
  'd74379ae-1640-4f3a-ab62-2c535f768378',

  // QCE
  '34ce5377-87b4-4ddf-9550-ca2b12ab89fb',

  // WACE
  '2d7d1c56-4dc4-4b05-80ab-ab11004ba31b',

  // SACE
  'f81ea48d-7602-4695-92f8-e0f4d7dc5dab',

  // CELS191
  '03189d1b-7d04-4102-9f4b-577870fb001c',

  // CHEM191
  '9f57ed0e-adf5-45b6-96a9-a05c0a4ddd23',

  // PHSI191
  '31b0068b-818f-405f-b1d6-06542995f4a6',

  // BIOC192
  '4f2023c8-0aec-4a53-a38e-8e99d2731837',

  // HUBS191
  'cfa041b3-3bd4-48bf-966f-1bc7376bf2f2',

  // HUBS192
  '544c0396-a9c5-4050-839c-e25a324b442e',

  // POPH192
  'a195140a-db4f-4a18-975c-bb62da47c0c2',

  // Optional 8th Paper
  '3c5a1bf2-fc1b-47e5-8555-a272ecdf1052',

  // ISAT
  'a4f7db96-a15b-46c7-a80f-e0fdd7ad5343',

  // BMAT
  'e73291b9-5ce7-4665-a595-07baa3d4099a',

  // BIOSCI107
  'f12695eb-4b5b-419a-a518-e75e6e4e8507',

  // CHEM110
  'ad11ad29-b0eb-4cd0-8043-dc48a1f0b010',

  // POPLHLTH111
  'c74e2c3b-165c-4110-8d8b-05ee01d8953b',

  // MEDSCI142
  '684f4296-9ce7-4aea-ba11-8ee1c9653286',

  // ATAR
  'd720ba44-734d-4ed4-a17d-b3b08a5e62b2',

  // Auckland Core CPA Paper Overall GPA
  'fbf58faa-07ed-48fe-a843-8eb0e80e2951',

  // Otago Paper Average (Best 7)
  'eb613f2f-9450-4bc4-a68f-f344256ee1e4',

  // CIE/CAIE A-Level
  'ca00d47c-a1ec-4eea-9812-9f52d1ead758',

  // School GPA
  '29ed35ed-1952-4b76-963b-e8b5cd0e7ab7',

  // Practice SAT
  'a80e6b12-0416-4447-9326-93d076d5869f',

  // Practice ACT
  'ba1a2c82-66cf-4834-9f11-f4ae6e41d96c',

  // Duolingo
  '3b51de04-9ddb-482f-9682-47ee95673a5b',

  // TOEFL_PBT
  '1e4c0173-b529-4ac0-8c81-7f8e13b98064',
];
