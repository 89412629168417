import React from 'react';
import PropTypes from 'prop-types';

export default function PaperAirplane({ className }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="249.967" height="164.501" viewBox="0 0 249.967 164.501">
      <g id="Group_41" data-name="Group 41" transform="translate(-322.866 -456.865)">
        <g id="Group_40" data-name="Group 40" transform="translate(462.041 456.865)">
          <path
            id="Path_3"
            data-name="Path 3"
            d="M680.456,96.942l7.878,24.93,79.128-59.861Z"
            transform="translate(-656.67 -62.011)"
            fill="#d10638"
          />
          <path
            id="Path_4"
            data-name="Path 4"
            d="M702.408,104.6l-4.784,17.111,79.109-59.7Z"
            transform="translate(-665.941 -62.011)"
            fill="#a7062d"
          />
          <path
            id="Path_5"
            data-name="Path 5"
            d="M739.554,127.04l42.793-65.029L708.022,104.6Z"
            transform="translate(-671.556 -62.011)"
            fill="#f80a44"
          />
          <path
            id="Path_6"
            data-name="Path 6"
            d="M652.537,96.942l87.006-34.931L628.751,89.83Z"
            transform="translate(-628.751 -62.011)"
            fill="#f80a44"
          />
        </g>
        <path
          id="Path_10"
          data-name="Path 10"
          d="M9378.311,331.692s62,8.381,77.879-46.17-53.027-67.069-53.361-27.244,71.862,46.37,102.269,27.244,44.4-59.583,44.4-59.583"
          transform="translate(-9055.311 288.209)"
          fill="none"
          stroke="#1d1e2b"
          strokeWidth="2"
          strokeDasharray="6 8"
        />
      </g>
    </svg>
  );
}

PaperAirplane.propTypes = {
  className: PropTypes.string,
};
