import { connect } from 'react-redux';
import {
  getSubjectCategoryById,
  getSubjectsByCurriculums,
  getGradeTableConfigForSubject,
} from 'selectors/subjectCategory';
import { getGradeById } from 'selectors/grade';
import { getGradeResultById } from 'selectors/gradeResult';
import GradesModal from './GradesModal';

const mapStateToProps = (state) => ({
  getGradeById: (id) => getGradeById(id)(state),
  getGradeResultById: (id) => getGradeResultById(id)(state),
  getSubjectsByCurriculums: (ids) => getSubjectsByCurriculums(ids)(state),
  getSubjectCategoryById: (id) => getSubjectCategoryById(id)(state),
  getGradeTableConfigForSubject: (id, config) => getGradeTableConfigForSubject(id, config)(state),
});

export default connect(mapStateToProps)(GradesModal);
