import styled from 'styled-components';
import { PrimaryButton, SecondaryButton } from '../index.style';

export const AddUniversityButton = styled(PrimaryButton)`
  width: 196px;
  height: 38px;
  background: #12c39a;
  box-shadow: 0px 4px 18px rgba(18, 195, 154, 0.2);
  border-radius: 28px;
  padding: 6px 32px;
  &:hover {
    background: #12c39a;
  }
`;

export const GroupByButton = styled(SecondaryButton)`
  width: 167px;
  height: 38px;
  padding: 11px 9px;
  background: #ffffff;
  border: 1px solid #e3e7ed;
  border-radius: 33px;
  font-weight: 500;
  font-size: 12px;
  font-family: 'Montserrat';
  color: #a9acc0;
`;

export const GroupByButtonText = styled.div`
  font-weight: 500;
  font-size: 12px;
  font-family: 'Montserrat';
  line-height: 15px;
  margin-left: 3px;
  color: #73747d;
`;
