import React, { Component } from 'react';
import { withAppContext } from 'components/enhancers/AppContext';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import autoBind from 'auto-bind';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { EventTypes } from 'utils/blockMapper';
import { isToday } from 'utils/calendarUtils';

import TimeBlock from './TimeBlock';
import CalendarBooking from './CalendarBooking';
import UnavailabilityBlock from './UnavailabilityBlock';

import css from './styles.scss';
import { handleEnter } from '../../../../../../utils/accessibility';

const TOTAL_HOUR_BLOCKS = 48;

function calculateBlockStyle(start, end, date, overlapIndex, overlapArray, source) {
  const lengthOfDayInMinutes = 24 * 60;
  const durationInMinutes = moment.duration(moment(end).diff(moment(start))).asMinutes();

  const startTimeInMinutes = moment.duration(moment(start).diff(moment(date).startOf('day'))).asMinutes();
  const display = durationInMinutes / lengthOfDayInMinutes > 0.99 ? 'none' : 'inline-block';
  const height =
    durationInMinutes / lengthOfDayInMinutes > 0.99
      ? '0'
      : `calc(${(durationInMinutes / lengthOfDayInMinutes) * 100}% - 2px)`;
  let top = 0;
  if (durationInMinutes / lengthOfDayInMinutes < 0.99) {
    top = durationInMinutes ? `calc(${(startTimeInMinutes / lengthOfDayInMinutes) * 100}%)` : '-2rem';
  }
  let width = '100%';
  let left = '0';
  if (overlapArray) {
    width = `${100 / overlapArray.toJS().length}%`;
    left = `${(100 * overlapIndex) / overlapArray.toJS().length}%`;
  }
  let cursor = 'pointer';
  if (source && source !== 'crimson-app') {
    cursor = 'default';
  }
  return {
    height,
    top,
    width,
    left,
    cursor,
    display,
  };
}

function renderUnavailability(start, end, date) {
  // eslint-disable-line react/prop-types
  const blockStyle = calculateBlockStyle(start, end, date);
  return <UnavailabilityBlock key={`${start}-unavailable`} style={blockStyle} />;
}

function renderBooking(booking, onBookingClicked, date, overlapArray, overlapIndex) {
  let startTimeToUse = booking.get('start');
  let endTimeToUse = booking.get('end');

  const isSameDay = moment(startTimeToUse).isSame(endTimeToUse, 'day');
  if (!isSameDay) {
    if (moment(startTimeToUse).isSame(date, 'day')) {
      endTimeToUse = moment(date).endOf('day');
    } else if (moment(endTimeToUse).isSame(date, 'day')) {
      startTimeToUse = moment(date).startOf('day');
    } else {
      startTimeToUse = moment(date).startOf('day');
      endTimeToUse = moment(date).endOf('day');
    }
  }
  const source = booking.get('source');

  return (
    <CalendarBooking
      key={booking.get('id')}
      person={booking.get('person')}
      status={booking.get('status')}
      description={booking.get('name')}
      source={source}
      displayStartTime={moment(startTimeToUse)}
      displayEndTime={moment(endTimeToUse)}
      realStartTime={moment(booking.get('start'))}
      realEndTime={moment(booking.get('end'))}
      clickHandler={() => onBookingClicked(booking.toJS())}
      blockStyle={calculateBlockStyle(startTimeToUse, endTimeToUse, date, overlapIndex, overlapArray, source)}
    />
  );
}

class Day extends Component {
  constructor(props) {
    super(props);

    this.state = {
      posY: -1,
    };

    autoBind(this);
  }

  componentDidMount() {
    this.passHeightToParent(this.calendarDiv);
  }

  onDayOverlayClick(e) {
    const weekGrid = e.target.parentElement.parentElement;

    const scrollOffset = weekGrid.scrollTop;

    const posY = e.clientY - weekGrid.offsetTop + scrollOffset; // get mouseclick pos on calendar grid
    const percentageY = posY / weekGrid.scrollHeight;
    const blockPosition = Math.floor(TOTAL_HOUR_BLOCKS * percentageY); // get timeblock position (max 48)
    const positionToDisplay = (weekGrid.scrollHeight / TOTAL_HOUR_BLOCKS) * blockPosition; // get actual Y coor to render block

    const offsetTime = blockPosition * 30; // 30mins multiply by the count of half an hour blocks (48 blocks total in a day)
    const startDate = moment(this.props.date).add(offsetTime, 'minutes');

    this.setState({
      posY: positionToDisplay,
      blockPosition,
      startDate,
    });
  }

  passHeightToParent(ref) {
    this.props.setHeight(ref);
  }

  hideTimeBlock() {
    this.setState({ posY: -1 });
  }

  openBookingModal() {
    this.props.openModalViaCalendar(this.state.startDate);
  }

  render() {
    const { onBookingClicked, date, dayElements } = this.props;

    return (
      <div
        className={classNames(css.day, css.columnWidth)}
        ref={(ref) => {
          this.calendarDiv = ref;
        }}
      >
        <div
          role="button"
          tabIndex={0}
          className={classNames(css.dayOverlay, { [css.today]: isToday(this.props.date) })}
          onClick={this.onDayOverlayClick}
          onKeyDown={handleEnter(this.onDayOverlayClick)}
        >
          {isToday(this.props.date) && (
            <div
              className={css.currentTimeLine}
              style={{ top: `${(moment().diff(moment().startOf('day'), 'minutes') / 1440) * 100}%` }}
            />
          )}
          {this.state.posY > -1 && (
            <TimeBlock
              posY={this.state.posY}
              onClickHandler={this.openBookingModal}
              onOutsideClick={this.hideTimeBlock}
              displayTime={this.state.startDate && this.state.startDate.format('LT')}
              isHalf={this.state.blockPosition === TOTAL_HOUR_BLOCKS - 1}
            />
          )}
        </div>

        {dayElements &&
          dayElements.map((e) => {
            const start = e.get('start');
            const end = e.get('end');
            switch (e.get('type')) {
              case EventTypes.UNAVAILABILITY:
                return renderUnavailability(start, end, date);
              case EventTypes.BOOKING:
                return renderBooking(
                  e.get('booking'),
                  onBookingClicked,
                  date,
                  e.get('overlapArray'),
                  e.get('overlapIndex'),
                );
              default:
                return null;
            }
          })}
      </div>
    );
  }
}

Day.displayName = 'Day';

Day.propTypes = {
  date: PropTypes.object.isRequired,
  dayElements: ImmutablePropTypes.list,
  setHeight: PropTypes.func.isRequired,
  onBookingClicked: PropTypes.func.isRequired,
  openModalViaCalendar: PropTypes.func,
};

export default withAppContext(Day);
