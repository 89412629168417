import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import IconTextButton from 'components/molecules/IconTextButton';
import css from './style.scss';
import { asButton } from '../../../utils/accessibility';

export default function BackButton(props) {
  const { backButtonText, backButtonLink, className, history, onClickBack } = props;
  if (backButtonLink) {
    return (
      <Link
        to={backButtonLink}
        data-test-id="backButton"
        className={classNames(css.linkStyle, {
          [className]: className,
        })}
      >
        <IconTextButton icon="ArrowBack">{backButtonText}</IconTextButton>
      </Link>
    );
  }
  return (
    <div
      className={classNames(css.linkStyle, className)}
      data-test-id="backButton"
      {...asButton((e) => (onClickBack ? onClickBack(e) : history.goBack()))}
    >
      <IconTextButton icon="ArrowBack">Back</IconTextButton>
    </div>
  );
}

BackButton.propTypes = {
  backButtonText: PropTypes.string,
  backButtonLink: PropTypes.string,
  className: PropTypes.string,
  history: PropTypes.object.isRequired,
  onClickBack: PropTypes.func,
};
