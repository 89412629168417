export const actions = {
  DISABLE: 'disable',
  DISPLAY_REPORT: 'display_report',
  SENDING_REPORTS: 'sending_reports',
  SET_STATUS: 'set_state',
  SET_STREAM_DIRTY: 'set_stream_dirty',
  SET_INTAKES: 'set_intakes',
  SET_REPORT_GROUP: 'set_report_group',
  SET_STUDENT_REPORT: 'set_student_report',
  UPDATING_STATUS_START: 'updating_status:start',
  UPDATING_STATUS_SUCCESS: 'updating_status:success',
  UPDATING_REPORT_START: 'updating_report:start',
  UPDATING_REPORT_SUCCESS: 'updating_report:success',
};

export const defaultReportViewState = {
  studentStreams: [],
  dirtyStudentStreams: [],
  studentReports: [],
  activeStudentReport: null,
  reportGroup: null,
  intakes: [],
  student: null,
  statusId: '',
  uiFlow: {
    loading: false,
    disabled: true,
    isSendingReports: false,
    hiddenToolbar: false,
  },
};

function reportViewReducer(state, { type, payload }) {
  switch (type) {
    case actions.DISABLE:
      return {
        ...state,
        student: null,
        statusId: '',
        activeStudentReport: null,
        uiFlow: {
          ...state.uiFlow,
          disabled: true,
        },
      };
    case actions.DISPLAY_REPORT:
      return {
        ...state,
        uiFlow: {
          ...state.uiFlow,
          disabled: true,
          loading: true,
        },
        statusId: payload.status,
        student: payload.student,
        dirtyStudentStreams: [],
      };
    case actions.SENDING_REPORTS:
      return {
        ...state,
        uiFlow: {
          ...state.uiFlow,
          isSendingReports: true,
        },
      };
    case actions.SET_STREAM_DIRTY:
      return {
        ...state,
        dirtyStudentStreams: payload.dirtyStudentStreams,
      };
    case actions.SET_INTAKES:
      return {
        ...state,
        intakes: payload.intakes,
      };
    case actions.SET_REPORT_GROUP:
      return {
        ...state,
        studentReports: payload.studentReports,
        reportGroup: payload.reportGroup,
      };
    case actions.SET_STUDENT_REPORT:
      return {
        ...state,
        activeStudentReport: payload.studentReport,
        studentStreams: payload.studentStreams,
        uiFlow: {
          ...state.uiFlow,
          loading: false,
          disabled: payload.hiddenToolbar,
          hiddenToolbar: payload.hiddenToolbar,
        },
      };
    case actions.UPDATING_STATUS_START:
      return {
        ...state,
        uiFlow: {
          ...state.uiFlow,
          loading: true,
          disabled: true,
        },
      };
    case actions.UPDATING_STATUS_SUCCESS:
      return {
        ...state,
        studentReports: payload.reports,
        uiFlow: {
          ...state.uiFlow,
          loading: false,
          disabled: false,
        },
      };
    case actions.UPDATING_REPORT_START:
      return {
        ...state,
        uiFlow: {
          ...state.uiFlow,
          loading: true,
          disabled: true,
        },
      };
    case actions.UPDATING_REPORT_SUCCESS:
      return {
        ...state,
        studentReports: payload.reports,
        student: null,
        statusId: '',
        activeStudentReport: null,
        uiFlow: {
          ...state.uiFlow,
          loading: false,
        },
      };
    case actions.SET_STATUS:
      return {
        ...state,
        statusId: payload.statusId,
      };
    default:
      return state;
  }
}

export default reportViewReducer;
