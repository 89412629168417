import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';
import { gql } from '@apollo/client';
import { createClient } from '../utils';
import { educationType, keyContactInfoType, hexacoType, staffInfoType } from './common/types';

const { api } = getConfig();

const client = createClient(`${api.endpoint}/graphql`);

export const editTutorNote = gql`
  mutation editTutorNote($note: String!, $tutorId: String!) {
    editTutorNote(note: $note, tutorId: $tutorId) {
      note
      noteEditedBy
      noteEditedAt
    }
  }
`;

export const updateStudentContractStatus = gql`
  mutation updateStudentContractStatus($userId: String!, $studentContractStatus: String!) {
    editStudentContractStatus(userId: $userId, contractStatus: $studentContractStatus) {
      userId
      contractStatus
    }
  }
`;

export const updateStudentContractSubStatus = gql`
  mutation updateStudentContractStatus($userId: String!, $studentContractSubStatus: String!) {
    editStudentContractSubStatus(userId: $userId, contractSubStatus: $studentContractSubStatus) {
      userId
      contractSubStatus
    }
  }
`;

export const editStudentEssayFolder = gql`
  mutation editStudentEssayFolder($userId: String!, $essayFolder: String!) {
    editStudentEssayFolder(userId: $userId, essayFolder: $essayFolder) {
      userId
      essayFolder
    }
  }
`;

export const editStudentHookStatement = gql`
  mutation editStudentHookStatement($userId: String!, $hookStatement: String!) {
    editStudentHookStatement(userId: $userId, hookStatement: $hookStatement) {
      userId
      hookStatement
    }
  }
`;

export const editStudentNote = gql`
  mutation editStudentNote($note: String!, $studentId: String!) {
    editStudentNote(note: $note, studentId: $studentId) {
      note
      noteEditedBy
      noteEditedAt
    }
  }
`;

export const createUniversity = gql`
  ${educationType}
  mutation createUniversity(
    $userId: String!
    $name: String!
    $college: String
    $degree: String
    $major: String
    $minor: String
    $yearStarted: Int
    $yearOfGraduation: Int
    $isGraduated: Boolean
    $currentYearLevel: Int
  ) {
    createUniversity(
      university: {
        userId: $userId
        name: $name
        college: $college
        degree: $degree
        major: $major
        minor: $minor
        yearStarted: $yearStarted
        yearOfGraduation: $yearOfGraduation
        isGraduated: $isGraduated
        currentYearLevel: $currentYearLevel
      }
    ) {
      ...educationType
    }
  }
`;

export const updateUniversity = gql`
  ${educationType}
  mutation updateUniversity(
    $id: String!
    $name: String
    $college: String
    $degree: String
    $major: String
    $minor: String
    $yearStarted: Int
    $yearOfGraduation: Int
    $isGraduated: Boolean
    $currentYearLevel: Int
  ) {
    updateUniversity(
      universityUpdates: {
        id: $id
        name: $name
        college: $college
        degree: $degree
        major: $major
        minor: $minor
        yearStarted: $yearStarted
        yearOfGraduation: $yearOfGraduation
        isGraduated: $isGraduated
        currentYearLevel: $currentYearLevel
      }
    ) {
      ...educationType
    }
  }
`;

export const deleteUniversity = gql`
  mutation deleteUniversity($universityId: String!) {
    deleteUniversity(universityId: $universityId) {
      id
      status
    }
  }
`;

export const createSchool = gql`
  ${educationType}
  mutation createSchool(
    $userId: String!
    $name: String!
    $yearStarted: Int
    $yearOfGraduation: Int
    $isGraduated: Boolean
    $currentYearLevel: Int
    $curriculumId: String
  ) {
    createSchool(
      school: {
        userId: $userId
        name: $name
        yearStarted: $yearStarted
        yearOfGraduation: $yearOfGraduation
        isGraduated: $isGraduated
        currentYearLevel: $currentYearLevel
        curriculumId: $curriculumId
      }
    ) {
      ...educationType
    }
  }
`;

export const updateSchool = gql`
  ${educationType}
  mutation updateSchool(
    $id: String!
    $name: String
    $yearStarted: Int
    $yearOfGraduation: Int
    $isGraduated: Boolean
    $currentYearLevel: Int
    $curriculumId: String
  ) {
    updateSchool(
      schoolUpdates: {
        id: $id
        name: $name
        yearStarted: $yearStarted
        yearOfGraduation: $yearOfGraduation
        isGraduated: $isGraduated
        currentYearLevel: $currentYearLevel
        curriculumId: $curriculumId
      }
    ) {
      ...educationType
    }
  }
`;

export const deleteSchool = gql`
  mutation deleteSchool($schoolId: String!) {
    deleteSchool(schoolId: $schoolId) {
      id
      status
    }
  }
`;

export const createKeyContact = gql`
  ${keyContactInfoType}
  mutation createKeyContact(
    $userId: String!
    $firstName: String!
    $lastName: String!
    $email: String!
    $phone: String
    $primaryLanguage: Int
    $languages: [Int]
    $isPrimary: Boolean
    $gender: String
    $location: String
    $relationship: String
  ) {
    createKeyContact(
      keyContact: {
        userId: $userId
        firstName: $firstName
        lastName: $lastName
        email: $email
        phone: $phone
        primaryLanguage: $primaryLanguage
        languages: $languages
        isPrimary: $isPrimary
        gender: $gender
        location: $location
        relationship: $relationship
      }
    ) {
      ...keyContactInfoType
    }
  }
`;

export const saveKeyContactInfo = gql`
  ${keyContactInfoType}
  mutation saveKeyContactInfo($keyContact: editKeyContactInput) {
    saveKeyContactInfo(keyContact: $keyContact) {
      ...keyContactInfoType
    }
  }
`;

export const deleteKeyContact = gql`
  mutation deleteKeyContact($keyContactId: Int) {
    deleteKeyContact(keyContactId: $keyContactId) {
      id
    }
  }
`;

export const submitHexacoTest = gql`
  ${hexacoType}
  mutation submitHexacoTest($userId: String!, $answer: [Int]!) {
    submitHexacoTest(userId: $userId, answer: $answer) {
      ...hexacoType
    }
  }
`;

export const fetchProfileImageUrl = gql`
  query getProfileImageUrl($userId: String!) {
    getProfileImageUrl(userId: $userId) {
      awsUrl
    }
  }
`;

export const profileImageUploaded = gql`
  query ackProfileImageUploaded($userId: String!) {
    ackProfileImageUploaded(userId: $userId) {
      profileImageUrl
    }
  }
`;

export const fetchAllStudentInterests = gql`
  query fetchAllStudentInterests {
    allStudentInterests {
      id
      name
    }
  }
`;

export const fetchAllTutorExpertise = gql`
  query fetchAllTutorExpertise {
    getAllTutorExpertise {
      id
      name
    }
  }
`;

export const updateYearOfApplication = gql`
  mutation updateYearOfApplication($userId: String!, $yearOfApplication: String!) {
    updateYearOfApplication(userId: $userId, yearOfApplication: $yearOfApplication)
  }
`;

export const createStaffInfo = gql`
  ${staffInfoType}
  mutation createStaffInfo($staffInfo: editStaffInput) {
    createStaffInfo(staffInfo: $staffInfo) {
      ...staffInfoType
    }
  }
`;

export const getStaffInfo = gql`
  ${staffInfoType}
  query getStaffInfo($userId: String!) {
    getStaffInfo(userId: $userId) {
      ...staffInfoType
    }
  }
`;

export default {
  editTutorNote: (note, tutorId) => client.query(editTutorNote, { note, tutorId }),
  updateStudentContractStatus: (userId, studentContractStatus) =>
    client.query(updateStudentContractStatus, { userId, studentContractStatus }),
  updateStudentContractSubStatus: (userId, studentContractSubStatus) =>
    client.query(updateStudentContractSubStatus, { userId, studentContractSubStatus }),
  editStudentEssayFolder: (userId, essayFolder) => client.query(editStudentEssayFolder, { userId, essayFolder }),
  editStudentHookStatement: (userId, hookStatement) =>
    client.query(editStudentHookStatement, { userId, hookStatement }),
  editStudentNote: (note, studentId) => client.query(editStudentNote, { note, studentId }),
  createUniversity: (university) => client.query(createUniversity, university),
  updateUniversity: (universityUpdates) => client.query(updateUniversity, universityUpdates),
  deleteUniversity: (universityId) => client.query(deleteUniversity, { universityId }),
  createSchool: (school) => client.query(createSchool, school),
  updateSchool: (schoolUpdates) => client.query(updateSchool, schoolUpdates),
  deleteSchool: (schoolId) => client.query(deleteSchool, { schoolId }),
  createKeyContact: (keyContactInfo) => client.query(createKeyContact, keyContactInfo),
  saveKeyContactInfo: (keyContact) => client.query(saveKeyContactInfo, { keyContact }),
  /**
   * @param {number} keyContact
   */
  deleteKeyContact: (keyContactId) => client.query(deleteKeyContact, { keyContactId: parseInt(keyContactId, 10) }),
  submitHexacoTest: (userId, answer) => client.query(submitHexacoTest, { userId, answer }),
  fetchProfileImageUrl: ({ userId }) => client.query(fetchProfileImageUrl, { userId }),
  profileImageUploaded: ({ userId }) => client.query(profileImageUploaded, { userId }),
  fetchAllStudentInterests: () => client.query(fetchAllStudentInterests),
  fetchAllTutorExpertise: () => client.query(fetchAllTutorExpertise),
  updateYearOfApplication: (userId, yearOfApplication) =>
    client.query(updateYearOfApplication, { userId, yearOfApplication }),
  createStaffInformation: (staffInfo) => client.query(createStaffInfo, { staffInfo }),
  fetchStaffInformation: (userId) => client.query(getStaffInfo, { userId }),
};
