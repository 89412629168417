import React, { useRef, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { featureSwitches } from 'featureSwitches';

import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import api from 'graphql/api/cga';

import LoadingIndicator from 'components/molecules/LoadingIndicator';
import { PaperAirplane } from 'components/atoms/graphics';
import { Dialog, SelectList } from 'design-system';
import 'design-system/styles/themes/default/index.scss';
import 'design-system/styles/index.scss';
import css from '../styles.scss';

import StudentReportItem from './StudentReportItem';
import { ReportsContext } from '../context';
import { actions } from '../reducer';

const Sidebar = (props) => {
  const { isMobile } = props;
  const { reportId } = useParams();
  const confirmationRef = useRef();
  const dialogRef = useRef();

  const { state, dispatch, updateAllReports, reportType } = useContext(ReportsContext);
  const { studentReports, uiFlow, student } = state;

  const { id: studentId } = student || {};

  const onStudentChange = (selected) => {
    if (selected.item.status === 'generating') {
      dispatch(actions.DISABLE);
      return;
    }
    const { item } = selected;
    if (student && item.student?.id === studentId) {
      return;
    }

    dispatch(actions.DISPLAY_REPORT, item);
  };

  const showConfirmationModal = () => {
    confirmationRef.current?.show();
  };

  const closeConfirmationModal = () => {
    confirmationRef.current?.hide();
  };

  const confirmSend = async () => {
    dispatch(actions.SENDING_REPORTS);
    await api.sendReports({ reportId });
    confirmationRef.current?.hide();
    dialogRef.current && dialogRef.current.show();
  };

  return (
    <div className={isMobile ? 'grow vert' : 'u-1-4 vert'}>
      <div className="grow">
        <h2 className={css.studentHeader}>Student List</h2>
        <div
          className={classnames('typography', 'bread-crumb', css.studentList, { [css.hidden]: !studentReports.length })}
        >
          <SelectList
            items={studentReports}
            onChange={onStudentChange}
            map={(item) => StudentReportItem(item)}
            disabled={uiFlow.loading}
          />
        </div>
        <LoadingIndicator className={classnames(css.bg, { [css.hidden]: studentReports.length })} colour="hint" />
      </div>
      <div className="align-items end">
        <div className="horiz align-items center grow">
          <Button
            data-testid="update-all-reports-button"
            variant="outlined"
            color="primary"
            size="large"
            className={classnames('ds-button', 'u-5-6 typography', css.mobileButton)}
            disabled={uiFlow.loading || !studentReports || (studentReports && !studentReports.length)}
            onClick={() => updateAllReports(reportId)}
          >
            <h5 className={classnames('typography', 'bread-crumb', css.btnText)}>UPDATE ALL REPORTS</h5>
          </Button>
          <Button
            data-testid="send-reports-button"
            variant="outlined"
            color="primary"
            size="large"
            className={classnames('ds-button', 'u-5-6 typography')}
            disabled={!studentReports?.length}
            onClick={showConfirmationModal}
          >
            <h5 className={classnames('typography', 'bread-crumb', css.btnText)}>SEND READY REPORTS</h5>
          </Button>
        </div>
      </div>
      <Dialog ref={dialogRef} maxWidth="xs" classes={{ paper: css.dialogPaper }} noHeader>
        <DialogContent className={classnames(css.contentWrapper, 'vertical', 'grow', 'align-items')}>
          <div className={classnames('align-items', 'center', 'grow', css.center)}>
            <div>
              <h1 className="typography">{reportType?.label} reports are on its way!</h1>
              <div className={css.svgContainer}>
                <PaperAirplane />
              </div>
            </div>
          </div>
          <DialogActions className={classnames('grow', 'horizontal', 'align-items', 'center', css.dialogActions)}>
            <Button variant="contained" className="ds-button grow" color="primary">
              <Link to="/cga-reports" className={css.linkText}>
                <h5 className={classnames('typography', 'bread-crumb', css.btnText)}>BACK TO REPORTS OVERVIEW</h5>
              </Link>
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <Dialog ref={confirmationRef} maxWidth="xs" disableBackdropClick disableEscapeKeyDown noHeader>
        <DialogContent className={classnames(css.contentWrapper, 'vertical', 'grow', 'align-items')}>
          {!uiFlow.isSendingReports && (
            <div>
              <div className={classnames('align-items', 'center', 'grow', css.center)}>
                <div>
                  <h1 className="typography">Are you sure you want to send ready reports?</h1>
                </div>
              </div>
              <DialogActions className={classnames('grow', 'horizontal', 'align-items', 'center', css.dialogActions)}>
                <Button
                  variant="contained"
                  className="ds-button u-5-12"
                  onClick={() => closeConfirmationModal()}
                  color="secondary"
                >
                  <h5 className={classnames('typography', 'bread-crumb', css.btnText)}>CANCEL</h5>
                </Button>
                <Button variant="contained" className="ds-button u-5-12" onClick={() => confirmSend()} color="primary">
                  <h5 className={classnames('typography', 'bread-crumb', css.btnText)}>YES</h5>
                </Button>
              </DialogActions>
            </div>
          )}
          {uiFlow.isSendingReports && (
            <div>
              <div className={classnames('align-items', 'center', 'grow', css.center)}>
                <div>
                  <h1 className="typography">Sending reports...</h1>
                </div>
              </div>
              <LoadingIndicator className={classnames(css.bg, { [css.hidden]: studentReports.length })} colour="hint" />
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

Sidebar.propTypes = {
  isMobile: PropTypes.bool,
};

export default Sidebar;
