import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CommentField from 'components/generic/Forms/CommentField';
import ErrorIcon from 'components/generic/Icons/Error';
import classnames from 'classnames';
import Modal from 'components/molecules/Modal';
import { RadioGroup, Radio } from 'react-radio-group';
import css from './styles.scss';

const OTHER_OPTION = {
  id: 'other_option',
  value: 'OTHER',
  label: 'Other',
};

const VALIDATION_MESSAGE = 'Please tell us what happened';

export default class OptionSelectForm extends Component {
  constructor() {
    super();

    this.state = {
      selectedValue: '',
      comment: '',
      commentInvalid: false,
    };

    this.onSubmitClick = this.onSubmitClick.bind(this);
    this.handleCommentChange = this.handleCommentChange.bind(this);
    this.selectOption = this.selectOption.bind(this);
  }

  onSubmitClick() {
    const otherOption = this.state.selectedValue === OTHER_OPTION.value;
    const noComment = this.state.comment === '';

    if (otherOption && noComment) {
      // Comment field can't be empty for 'other' option.
      this.setState({
        commentInvalid: true,
      });
    } else {
      const { options, onSubmit } = this.props;
      const { selectedValue, comment } = this.state;
      const option =
        selectedValue === OTHER_OPTION.value
          ? OTHER_OPTION.label
          : options.find((option) => option.value === selectedValue).label;
      onSubmit(comment, option);
    }
  }

  handleCommentChange(newComment) {
    this.setState({
      comment: newComment,
      commentInvalid: false,
    });
  }

  selectOption(newOption) {
    this.setState({
      selectedValue: newOption,
      commentInvalid: newOption === OTHER_OPTION.value ? this.state.commentInvalid : false,
    });
  }

  render() {
    const { options, radioGroupName, hasOtherOption, onBack, title } = this.props;

    const { selectedValue } = this.state;

    return (
      <Modal
        isOpen
        title={title}
        onBack={onBack}
        onSubmit={this.onSubmitClick}
        isSubmitDisabled={!selectedValue}
        submitText="Submit"
      >
        <div>
          <div className={css.radios}>
            <RadioGroup name={radioGroupName} selectedValue={selectedValue} onChange={this.selectOption}>
              {options.map((option) => {
                return (
                  <div key={option.id}>
                    <Radio id={option.id} value={option.value} />
                    <label className="inline" htmlFor={option.id}>
                      {option.label}
                    </label>
                  </div>
                );
              })}
              {hasOtherOption && (
                <div>
                  <Radio id={OTHER_OPTION.id} value={OTHER_OPTION.value} />
                  <label className="inline" htmlFor={OTHER_OPTION.id}>
                    {OTHER_OPTION.label}
                  </label>
                </div>
              )}
            </RadioGroup>
          </div>

          <CommentField
            className={classnames({
              [css.commentBox]: true,
              [css.commentInvalid]: this.state.commentInvalid,
            })}
            label="Add a comment"
            onValueChange={this.handleCommentChange}
          />

          <div className={css.errorMessageContainer}>
            {this.state.commentInvalid && (
              <div className={css.errorMessage}>
                <ErrorIcon className={css.alertIcon} />
                {VALIDATION_MESSAGE}
              </div>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

OptionSelectForm.propTypes = {
  radioGroupName: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  hasOtherOption: PropTypes.bool,
  title: PropTypes.string,
  onBack: PropTypes.func,
};
