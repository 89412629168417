import * as US_ALERTS from './US';
import * as UK_ALERTS from './UK';
import * as EU_ALERTS from './EU';
import { Event } from './util';
import { omit } from 'lodash';

const sortAlerts = (alerts: Event<any>[]) => {
  return alerts.sort((a, b) => a.priority - b.priority);
};

const filterAlerts = (alerts: Event<any>[]) => alerts.filter((o) => o.availability());

const preprocessAlerts = <T extends object, K extends keyof T>(alerts: T, keys: K[]) => omit(alerts, keys);

const constructAlerts = <T extends Record<string, Event<any>>>(alerts: T): Event<any>[] => {
  const _alerts = Object.values(preprocessAlerts(alerts, ['schoolSelectionData', 'milestoneMeeting']));
  const funcs = [filterAlerts, sortAlerts];
  return funcs.reduce((prev, curr) => curr(prev), _alerts);
};

export const USAlerts = () => constructAlerts(US_ALERTS);
export const UKAlerts = () => constructAlerts(UK_ALERTS);
export const EUAlerts = () => constructAlerts(EU_ALERTS);

export { resolveEvent, DataInputs, Event } from './util';

export const REGION_ALERTS = () => ({
  US: USAlerts(),
  UK: UKAlerts(),
  EU: EUAlerts(),
});

export const SCHOOL_SELECTION_DATA = {
  US: US_ALERTS.schoolSelectionData,
  EU: EU_ALERTS.schoolSelectionData,
  UK: UK_ALERTS.schoolSelectionData,
};

export const MILESTONE_MEETING_DATA = {
  US: US_ALERTS.milestoneMeeting,
  EU: EU_ALERTS.milestoneMeeting,
  UK: UK_ALERTS.milestoneMeeting,
};

export { US_ALERTS, UK_ALERTS, EU_ALERTS };
