import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { cancelBooking } from 'ducks/booking';
import CancelPendingRequestModal from './CancelPendingRequestModal';

const mapDispatchToProps = (dispatch, props) => ({
  cancelBooking: () => dispatch(cancelBooking(props.session.id, props.userId)),
});

export default compose(withRouter, connect(null, mapDispatchToProps))(CancelPendingRequestModal);
