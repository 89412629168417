import { ApolloClient, InMemoryCache, HttpLink, ApolloLink, from } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import * as Logger from '@crimson-education/browser-logger';
import { getFeatureSwitchesAsString } from '../featureSwitches';
import { getBearer, getCurrentUserId, refreshAccessTokens } from '../utils/auth/auth';
import { MessageEventType } from '../utils/MessageEventType';
import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';

// share cache.
const cache = new InMemoryCache({
  addTypename: false,
});
const { isDev } = getConfig();

/**
 * Creates a GraphQL Client for talking to the API.
 * @param {string} endpoint Endpoint of service.
 * @param {boolean} isImmutable Is the client in immutable mode? This is disabled in the legacy client due to non-compliant code.
 * @returns {ApolloClient}
 */
export default function getGraphQLClient(endpoint) {
  const httpLink = new HttpLink({
    uri: endpoint,
  });

  const authLink = new ApolloLink((operation, forward) => {
    operation.setContext(() => {
      const headers = {
        authorization: `bearer ${getBearer()}`,
        app: 'web',
        'x-feature-switches': getFeatureSwitchesAsString(),
      };
      if (isDev) {
        headers['x-user'] = JSON.stringify({ userId: getCurrentUserId() });
      }
      return { headers };
    });
    return forward(operation);
  });
  const getNewToken = () => {
    refreshAccessTokens();
    return `bearer ${getBearer()}`;
  };

  const errorLink = onError(({ networkError, operation, forward }) => {
    if (networkError && networkError.statusCode === 401) {
      const oldHeaders = operation.getContext().headers;
      operation.setContext({
        headers: {
          ...oldHeaders,
          authorization: getNewToken(),
        },
      });
      const userId = localStorage.getItem('apc_user_id');
      Logger.trackEvent({ message: MessageEventType.AuthExpired, metadata: { userId } });
      return forward(operation);
    }
  });

  return new ApolloClient({
    link: from([errorLink, authLink, httpLink]),
    cache,
    defaultOptions: {
      errorPolicy: 'all',
    },
  });
}
