import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import { getCurrentUserJS } from 'selectors/user';
import * as zoid from 'zoid/dist/zoid.frameworks.js';
import { getEnvironmentConfig } from '@crimson-education/common-config';
import { history } from '../../../app';
import { getBearer, authorize } from '../../../utils/auth/auth';
import { featureSwitches, getFeatureSwitchesAsString } from '../../../featureSwitches';
import _ from 'lodash';
import styled from 'styled-components';

const StyledContainer = styled.div`
  & .zoid-visible {
    height: 100vh !important;
  }
`;

const { environment } = getEnvironmentConfig();

const environmentHostnames = {
  local: 'http://localhost:3013',
  staging: 'https://staging.core-progress.crimsoneducation.org',
  production: 'https://core-progress.crimsoneducation.org',
};
const { protocol, hostname, port } = window.location;

const domain = `${protocol}//${hostname}${port ? `:${port}` : ''}`;

const pathfinderDomain = environmentHostnames[featureSwitches.PATHFINDER_CONNECT_LOCAL() ? 'local' : environment];
// store all the registered event callbacks.
const eventEmitters = {};
// store the pathfinder current url
let pathfinderUrl;
// tag the url change original source from iframe of navbar.
// if it's true, the iframe will not refresh
let innerChange = false;

const PathfinderComponent = zoid.create({
  tag: 'pathfinder-component',
  url: ({ props }) => {
    if (props && props.history) {
      const { location } = props.history;
      const { pathname } = location;
      return pathfinderDomain + pathname.replace('/pathfinder', '') + location.search;
    }
    return pathfinderDomain;
  },
  dimensions: {
    width: '100%',
    height: '99vh',
  },
  style: {
    height: '100vh',
  },
  props: {
    token: {
      type: 'string',
      isRequired: false,
    },
    userId: {
      type: 'string',
      isRequired: true,
    },
    authorize: {
      type: 'function',
      isRequired: false,
    },
    getBearer: {
      type: 'function',
      isRequired: false,
    },
  },
});

const DynamicReactComponent = PathfinderComponent.driver('react', {
  React,
  ReactDOM,
});

// registerEventEmitter
// a function to add more callbacks from iframe
const registerEventEmitter = (eventName, callback) => {
  eventEmitters[eventName] = callback;
};

const unregisterEventEmitter = (eventName) => {
  delete eventEmitters[eventName];
};

// invoke the event callbacks
// conditionally, if the innerChange is true, we should do something special
const performEvent = (eventName, data) => {
  if (innerChange) {
    innerChange = false;
    return;
  }
  eventEmitters[eventName] && eventEmitters[eventName](data);
};

const onMessage = ({ event, data }) => {
  // accept the route event from the url routing change of the iframe.
  if (event === 'route') {
    if (pathfinderUrl === data) {
      return;
    }
    innerChange = true;
    pathfinderUrl = data;
    window.history.pushState({}, '', `/pathfinder${data}`);
  }
};

function Pathfinder() {
  const user = useSelector(getCurrentUserJS);

  // fix about the navbar button doesn't work issue.
  useEffect(() => {
    // console.log('current', history.location.pathname);
    performEvent('refresh', `${history.location.pathname}${history.location.search}`);
  });
  return (
    <StyledContainer>
      <DynamicReactComponent
        registerEventEmitter={registerEventEmitter}
        unregisterEventEmitter={unregisterEventEmitter}
        history={history}
        onMessage={onMessage}
        token={getBearer() || ''}
        getBearer={getBearer}
        authorize={authorize}
        featureSwitches={JSON.parse(getFeatureSwitchesAsString())}
        userId={user.userId}
        user={_.pick(user, ['userId', 'firstName', 'lastName', 'email'])}
        domain={domain}
      />
    </StyledContainer>
  );
}

export default Pathfinder;
