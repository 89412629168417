import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextArea from 'components/molecules/TextArea';
import { Caption } from 'components/atoms/typography';
import CheckCircle from '@material-ui/icons/CheckCircle';
import LoadingCircle from 'components/molecules/LoadingCircle';
import moment from 'moment';
import debounce from 'lodash/debounce';

import css from './styles.scss';

export default class AutosaveTextArea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      autoSaved: false,
      loading: false,
    };
    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
    this.debouncedFunction = debounce(this.onUpdate, 500);
  }

  onChange(value) {
    this.setState({
      value,
      autoSaved: false,
      loading: true,
    });
    this.debouncedFunction(value);
  }

  onBlur(e) {
    this.debouncedFunction.cancel();
    this.onUpdate(e.currentTarget.value);
  }

  async onUpdate(value) {
    const { onUpdateValue } = this.props;
    await onUpdateValue(value);
    this.setState({
      autoSaved: true,
      loading: false,
    });
  }

  render() {
    const { caption, placeholder, updatedAt } = this.props;

    const { value, autoSaved, loading } = this.state;

    return (
      <div className={css.summaryBox}>
        <Caption className={css.summaryCaption}>{caption}</Caption>
        <TextArea value={value} rows={9} onChange={this.onChange} placeholder={placeholder} onBlur={this.onBlur} />
        {autoSaved && (
          <Caption className={css.autoSaved}>
            <CheckCircle className={css.saveIcon} />
            Saved! Last modified at {moment(updatedAt).format('h:mma, DD/MM/YY')}.
          </Caption>
        )}
        {loading && <LoadingCircle color="#babcc5" />}
      </div>
    );
  }
}

AutosaveTextArea.defaultTypes = {
  value: '',
  placeholder: '',
};

AutosaveTextArea.propTypes = {
  caption: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  updatedAt: PropTypes.string,
  onUpdateValue: PropTypes.func.isRequired,
};
