import React, { forwardRef, useImperativeHandle, useMemo } from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import useForSelectionList from '../../utils/useForSelectionList';

const DsSelectList = forwardRef((props, ref) => {
  const { map, disabled } = props;
  const { entries, select } = useForSelectionList(props);

  const id = useMemo(() => Math.random(), []);

  useImperativeHandle(ref, () => ({
    select
  }));

  const options = useMemo(() => {
    return entries && entries.map((entry, i) => (
      <ListItem
        dense
        button
        key={`${id}-${i}`}
        disabled={disabled}
        selected={entry.selected}
        onClick={() => select(entry.value, i)}
      >
        {map(entry.value)}
      </ListItem>
    )
    );
  }, [disabled, entries, id, map, select]);

  return (
    <List className="ds-select-list">
      {options}
    </List>
  );
});

DsSelectList.propTypes = {
  map: PropTypes.func,
  disabled: PropTypes.bool,
};

export default DsSelectList;