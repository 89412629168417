import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';
import { getBearer } from 'utils/auth';
import websocket from 'utils/websocket';

import { loadNotifications, clearNotifications, setNotificationRead, processIncomingMessage } from 'ducks/notification';
import {
  getAllNotifications,
  getNewNotificationCount,
  getLatestNotificationKey,
  hasMoreNotifications,
} from 'selectors/notification';
import { selectUserProfile } from 'selectors/meta';

import Notification from './Notification';

const config = getConfig();

class NotificationContainer extends Component {
  componentDidMount() {
    const { processIncomingMessage } = this.props;
    const opts = {
      url: `${config.pushNotifications.websocketEndpoint}?auth=Bearer%20${getBearer()}`,
      onmessage: processIncomingMessage,
    };
    this.notificationsWS = websocket.connect(opts);
  }

  render() {
    const {
      className,
      newNotificationCount,
      clearNotifications,
      setNotificationRead,
      notifications,
      lastLoadedKey,
      hasMore,
      loadNotifications,
      mobile,
    } = this.props;

    const loadKey = !newNotificationCount ? lastLoadedKey : '';

    return (
      <Notification
        className={className}
        notifications={notifications}
        hasMore={hasMore}
        unseen={newNotificationCount}
        mobile={mobile}
        loadNotifications={() => loadNotifications(loadKey, loadKey ? 6 : 50)}
        clearNotifications={() => clearNotifications()}
        setNotificationRead={(notificationId) => setNotificationRead(notificationId)}
      />
    );
  }
}

NotificationContainer.propTypes = {
  className: PropTypes.string,
  mobile: PropTypes.bool,
  newNotificationCount: PropTypes.number.isRequired,
  lastLoadedKey: PropTypes.string.isRequired,
  hasMore: PropTypes.bool.isRequired,
  notifications: ImmutablePropTypes.map.isRequired,
  loadNotifications: PropTypes.func.isRequired,
  clearNotifications: PropTypes.func.isRequired,
  setNotificationRead: PropTypes.func.isRequired,
  processIncomingMessage: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    newNotificationCount: getNewNotificationCount(state),
    notifications: getAllNotifications(state),
    loginUser: selectUserProfile(state),
    lastLoadedKey: getLatestNotificationKey(state),
    hasMore: hasMoreNotifications(state),
  }),
  (dispatch) => ({
    loadNotifications: (...args) => dispatch(loadNotifications(...args)),
    clearNotifications: () => dispatch(clearNotifications()),
    setNotificationRead: (notificationId) => dispatch(setNotificationRead(notificationId)),
    processIncomingMessage: (message) => dispatch(processIncomingMessage(message)),
  }),
)(NotificationContainer);
