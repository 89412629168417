import React from 'react';
// interactions icons
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import InsertCommentIcon from '@material-ui/icons/InsertComment';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
// community updates icons
import ForumIcon from '@material-ui/icons/Forum';
import EventIcon from '@material-ui/icons/Event';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
// email notification icons
import EmailIcon from '@material-ui/icons/Email';

const iconStyle = {
  fontSize: '24px',
  marginRight: '13px',
  color: '#a9acbe',
};

export const getListForInteractions = (interactions) => {
  return [
    {
      title: 'Mentioned in a post or comment',
      icon: <AlternateEmailIcon style={{ ...iconStyle }} />,
      key: 'MentionedInPostOrComment',
      value: interactions.MentionedInPostOrComment,
    },
    {
      title: 'Comment on your post / event',
      icon: <InsertCommentIcon style={{ ...iconStyle }} />,
      key: 'CommentOnYourPostEvent',
      value: interactions.CommentOnYourPostEvent,
    },
    // TODO: Maybe we need this after discuss with Zac
    // {
    //   title: 'Some liked your post / event',
    //   icon: <ThumbUpIcon style={{ ...iconStyle }} />,
    //   key: 'LikeOnPost',
    //   value: interactions.LikeOnPost
    // },
    {
      title: 'Friend request',
      icon: <PersonAddIcon style={{ ...iconStyle }} />,
      key: 'FriendRequest',
      value: interactions.FriendRequest,
    },
  ];
};

export const getListForCommunityUpdates = (communityUpdates) => {
  return [
    {
      title: 'New post in your groups',
      icon: <ForumIcon style={{ ...iconStyle }} />,
      key: 'NewPost',
      value: communityUpdates.NewPost,
    },
    {
      title: 'New event in your groups',
      icon: <EventIcon style={{ ...iconStyle }} />,
      key: 'NewEventMember',
      value: communityUpdates.NewEventMember,
    },
    {
      title: 'New event in the community',
      icon: <EventIcon style={{ ...iconStyle }} />,
      key: 'NewEventOthers',
      value: communityUpdates.NewEventOthers,
    },
    {
      title: 'Upcoming event reminder',
      icon: <EventIcon style={{ ...iconStyle }} />,
      key: 'UpcomingEvent',
      value: communityUpdates.UpcomingEvent,
    },
    // TODO: Maybe we need this after discuss with Zac
    // {
    //   title: 'Event moderator has changed',
    //   icon: <PermContactCalendarIcon style={{ ...iconStyle }} />,
    //   key: 'ChangeModeratorNew',
    //   value: communityUpdates.ChangeModeratorNew
    // },
    {
      title: 'Community weekly update email',
      icon: <EmailIcon style={{ ...iconStyle }} />,
      key: 'CommunityWeeklyEmail',
      value: communityUpdates.CommunityWeeklyEmail,
    },
  ];
};

export const getListForEmailNotifications = (value) => {
  return [
    {
      title: 'I want to get email notifications for my activities on Crimson app',
      icon: <EmailIcon style={{ ...iconStyle }} />,
      key: 'emailNotifications',
      value,
    },
  ];
};

export const settingCombination = [
  // {
  //   settingNameArray: ['LikeOnPost'],
  //   displayNameForSetting: 'LikeOnPost',
  //   type: 'interactions',
  // },
  {
    settingNameArray: ['FriendRequest'],
    displayNameForSetting: 'FriendRequest',
    type: 'interactions',
  },
  {
    settingNameArray: ['AskAnExpert', 'MentionOnPostComment'],
    displayNameForSetting: 'MentionedInPostOrComment',
    type: 'interactions',
  },
  {
    settingNameArray: ['CommentOnPost', 'CommentOnEvent'],
    displayNameForSetting: 'CommentOnYourPostEvent',
    type: 'interactions',
  },
  {
    settingNameArray: ['EventReminder30', 'EventReminder'],
    displayNameForSetting: 'UpcomingEvent',
    type: 'communityUpdates',
  },
  {
    settingNameArray: ['NewPost'],
    displayNameForSetting: 'NewPost',
    type: 'communityUpdates',
  },
  {
    settingNameArray: ['NewEventMember'],
    displayNameForSetting: 'NewEventMember',
    type: 'communityUpdates',
  },
  {
    settingNameArray: ['NewEventOthers'],
    displayNameForSetting: 'NewEventOthers',
    type: 'communityUpdates',
  },
  // {
  //   settingNameArray: ['ChangeModeratorNew'],
  //   displayNameForSetting: 'ChangeModeratorNew',
  //   type: 'communityUpdates',
  // },
  {
    settingNameArray: ['CommunityWeeklyEmail'],
    displayNameForSetting: 'CommunityWeeklyEmail',
    type: 'communityUpdates',
  },
];

const getRealValueForCurrentSetting = (value) => {
  return value === undefined ? true : value === 'Each';
};

const getRealValueForParsedSetting = (value) => {
  return value === undefined || value;
};

export const setValueForDisplay = ({ displayData, data, settingCombination }) => {
  if (!data.length) {
    return { ...displayData };
  }
  const newDisplayData = { ...displayData };
  const settingDataFromData = data.reduce((result, item) => {
    result[item.action] = getRealValueForCurrentSetting(item.options);
    return result;
  }, {});
  settingCombination.map(({ type, displayNameForSetting, settingNameArray }) => {
    newDisplayData[type][displayNameForSetting] = settingNameArray.reduce(
      (result, settingName) => result && getRealValueForParsedSetting(settingDataFromData[settingName]),
      true,
    );
    return type;
  });
  return newDisplayData;
};

const generateSettingItem = (displayData, type, settingCombination) => {
  const combineItems = settingCombination.filter((item) => item.type === type);
  let settingsResult = [];
  if (combineItems.length) {
    // eslint-disable-next-line
    settingsResult = combineItems.reduce((result, item) => displayData[type][item.displayNameForSetting] ? [...result, ...item.settingNameArray.map(settingName => ({
                id: '',
                action: settingName,
                options: 'Each',
                // eslint-disable-next-line
    }))] : [...result, ...item.settingNameArray.map(settingName => ({
                id: '',
                action: settingName,
                options: 'Never',
              })),
            ],
      [],
    );
  }
  const unCombinedKeys = Object.keys(displayData[type]).filter(
    (key) => settingCombination.findIndex((combineItem) => combineItem.displayNameForSetting === key) === -1,
  );
  // eslint-disable-next-line
  settingsResult = [...settingsResult, ...unCombinedKeys.map(key => ({
      id: '',
      action: key,
      options: displayData[type][key] ? 'Each' : 'Never',
    })),
  ];
  return settingsResult;
};

export const setValueForSaving = ({ displayData, data }) => {
  // eslint-disable-next-line
  const settingDataFromDisplay = Object.keys(displayData).reduce((result, key) => result = [...result, ...generateSettingItem(displayData, key, settingCombination)], []);
  settingDataFromDisplay.map((setting) => {
    const currentSettingInData = data.find((item) => item.action === setting.action);
    setting.id = currentSettingInData ? currentSettingInData.id : '';
    return setting;
  });
  return settingDataFromDisplay;
};

export const communityNotificationsDefault = {
  interactions: {
    MentionedInPostOrComment: true,
    CommentOnYourPostEvent: true,
    // LikeOnPost: true,
    FriendRequest: true,
  },
  communityUpdates: {
    NewPost: true,
    NewEventMember: true,
    NewEventOthers: false,
    UpcomingEvent: true,
    // ChangeModeratorNew: true,
    CommunityWeeklyEmail: true,
  },
};

export const emailNotificationsDefault = {
  getEmailNotificationForMyActivityFromApp: true,
};

export const useGetNotificationList = ({ data, displayData }) => {
  const [interactions, setInteractions] = React.useState({ ...displayData.interactions });
  const [communityUpdates, setCommunityUpdates] = React.useState({ ...displayData.communityUpdates });
  const savingData = React.useMemo(() => setValueForSaving({ data, displayData: { interactions, communityUpdates } }), [
    interactions,
    communityUpdates,
    data,
  ]);
  return { interactions, setInteractions, communityUpdates, setCommunityUpdates, savingData };
};
