import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classnames from 'classnames';
import debounce from 'lodash/debounce';

import Filter from 'components/molecules/TextFilter';
import LoadingCircle from 'components/molecules/LoadingCircle';
import Header from '../Header';
import People from './People';
import SelectedPeople from './SelectedPeople';
import css from './styles.scss';
import { handleEnter } from '../../../../../../utils/accessibility';

const HEADER_TEXT = 'BOOK WITH';
const PLACEHOLDER_TEXT = 'Search a name';
const UNSELECT_ALL = 'Unselect all';

export default class BookingWith extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filterText: '',
      isLoading: false,
    };

    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onClearHandler = this.onClearHandler.bind(this);
    this.debounceLoadContacts = debounce(props.loadMoreContacts, 200);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // If we have changed the person we are booking as, always clear the
    // search filter for the people we can book with.
    const currentBookAs = this.props.bookAs && this.props.bookAs.get('userId');
    const nextBookAs = nextProps.bookAs && nextProps.bookAs.get('userId');
    if (nextBookAs !== currentBookAs) {
      this.setState({
        filterText: '',
      });
    }

    if (this.props.bookingWithFetched) {
      this.setState({ isLoading: false });
    }
  }

  // Update the filter text and people when the user types in the input.
  onChangeHandler(newText) {
    this.setState({
      filterText: newText,
      isLoading: true,
    });

    this.debounceLoadContacts({ text: newText });
  }

  // Deselect all selected and update filterText.
  onClearHandler() {
    this.props.selectedBookWith.forEach((person) => {
      this.props.togglePersonSelected(person.get('userId'));
    });

    this.setState({
      filterText: '',
    });
    this.props.updateDayCalendarViewHidden(false);
    this.props.loadMoreContacts();
  }

  render() {
    const {
      bookWith,
      bookingWithFetched,
      selectedBookWith,
      togglePersonSelected,
      canSelectMorePeople,
      bookingWithPagination,
      loadMoreContacts,
    } = this.props;

    const peopleSelected = selectedBookWith.size > 0;
    const unselectAll = peopleSelected ? classnames(css.unselectAll, css.unselectAllActive) : css.unselectAll;
    return (
      <div className={css.bookingWith}>
        <Header headerText={HEADER_TEXT} />
        <Filter
          value={this.state.filterText}
          onChangeHandler={(event) => this.onChangeHandler(event.target.value)}
          placeholder={PLACEHOLDER_TEXT}
          dataTestId="searchBookingWithUserInput"
        />
        <div
          role="button"
          tabIndex={0}
          className={unselectAll}
          onClick={peopleSelected ? this.onClearHandler : undefined}
          onKeyDown={peopleSelected ? handleEnter(this.onClearHandler) : undefined}
        >
          {UNSELECT_ALL}
        </div>
        <SelectedPeople
          people={selectedBookWith}
          togglePersonSelected={togglePersonSelected}
          canSelectMorePeople={canSelectMorePeople}
        />
        {(bookWith.size || bookingWithFetched) && !this.state.isLoading ? (
          <People
            people={this.props.bookWith}
            canSelectMorePeople={canSelectMorePeople}
            togglePersonSelected={togglePersonSelected}
            selectedBookWith={selectedBookWith}
            bookingWithPagination={bookingWithPagination}
            loadMoreContacts={loadMoreContacts}
            filter={{ text: this.state.filterText }}
            bookingWithFetched={bookingWithFetched}
          />
        ) : (
          <div className={css.loader}>
            <LoadingCircle color="#aa1e23" />
          </div>
        )}
      </div>
    );
  }
}

BookingWith.propTypes = {
  bookAs: ImmutablePropTypes.map,
  bookWith: ImmutablePropTypes.list.isRequired,
  bookingWithFetched: PropTypes.bool.isRequired,
  canSelectMorePeople: PropTypes.bool.isRequired,
  togglePersonSelected: PropTypes.func.isRequired,
  selectedBookWith: ImmutablePropTypes.list.isRequired,
  bookingWithPagination: ImmutablePropTypes.map.isRequired,
  loadMoreContacts: PropTypes.func.isRequired,
  updateDayCalendarViewHidden: PropTypes.func,
};
