import React from 'react';
import { UpdatesCardContainer, Title, TitleContainer, StyledCheck, ActionsContainer } from './style';
import ActionNormal from './ActionNormal';
import ColorBlock from './ColorBlock';

const UpdatesCard = () => {
  return (
    <UpdatesCardContainer>
      <TitleContainer>
        <ColorBlock />
        <Title>{'Grade 10 School Work'}</Title>
        <StyledCheck $checked={true} />
      </TitleContainer>
      <ActionsContainer>
        <ActionNormal actionName={'Added Actual GPA'} actionDetail={'3.8'} />
        <ActionNormal
          actionName={'Uploaded a transcript file'}
          actionDetail={'Grade 10_yolanda.pdf'}
          detailLink={'http://baidu.com'}
        />
        <ActionNormal actionName={'Added Subjects'} actionDetail={'IB History, IB Chemisty'} />
        <ActionNormal
          actionName={'Added a task'}
          tasks={[
            {
              name: 'Have a talk with Bio teacher after class',
              link: 'http://baidu.com',
            },
            {
              name: 'Draft an application email',
              link: 'http://baidu.com',
            },
            {
              name: 'Research on the courses to see which ones you’re interested',
              link: 'http://baidu.com',
            },
            {
              name: 'Prepare your school transcrip',
              link: 'http://baidu.com',
            },
          ]}
        />
      </ActionsContainer>
    </UpdatesCardContainer>
  );
};

export default UpdatesCard;
