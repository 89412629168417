const DEFINED_ACTION_NAME = {
  CommentOnPost: 'comment_on_post',
  PostExpertCommentOnPost: 'post_expert_comment_on_post',
  CommentOnPostComment: 'comment_on_post_coment',
  MentionOnPost: 'metion_on_post',
  AdminGroupNameAChair: 'group_name_chair',
  AssignAModerator: 'group_assign_moderator',
  RsvpEvent: 'group_rsvp_event',
  ReceiveInviteGroup: 'group_receive_invite_group',
  AskAnExpert: 'ask_an_expert',
};

export function analyzeNotificationData(data) {
  let shouldShow = true;
  try {
    if (data.payload) {
      const { payload, redirect } = data;
      let { actionName } = payload;
      if (actionName === 'ReceiveInviteGroup') {
        shouldShow = false;
      }
      if (actionName === 'CommentOnPost') {
        const { extraData } = payload;
        if (extraData) {
          const { isPostExpert } = extraData;
          if (isPostExpert) {
            actionName = 'PostExpertCommentOnPost';
          }
        }
      }
      const appcuesEvent = DEFINED_ACTION_NAME[actionName];
      if (appcuesEvent && window.Appcues) {
        window.Appcues.track(appcuesEvent);
        const currentUserId = window.localStorage.currentUserId;
        if (currentUserId) {
          const appcuesPropertyKey = `${appcuesEvent}_redirect`;
          window.Appcues.identify(currentUserId, {
            [appcuesPropertyKey]: redirect,
          });
        }
      }
    }
  } catch (err) {
    return true;
  }
  return shouldShow;
}

export function triggerAppcuesByFilterNotification(notificationsData) {
  const newNotificationData = {};
  Object.keys(notificationsData).forEach((key) => {
    const data = notificationsData[key];
    const shouldShow = analyzeNotificationData(data);
    if (shouldShow) {
      newNotificationData[key] = data;
    }
  });
  return newNotificationData;
}
