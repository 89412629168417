import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import WarningIcon from '@material-ui/icons/Warning';
import { threadStatus } from 'constants/messaging';
import ThreeDots from 'components/molecules/ThreeDots';
import { Image, Description } from 'components/atoms/icons';
import css from './styles.scss';

export default function ThreadStatusText(props) {
  const { message, status, otherTypingParticipants, type } = props;

  function renderMessage() {
    if (type === 'FILE') {
      return (
        <div>
          <div className={css.image}>
            <Description />
          </div>
          <div className={css.message}>{message}</div>
        </div>
      );
    } else if (type === 'IMAGE') {
      return (
        <div>
          <div className={css.image}>
            <Image />
          </div>
          <div className={css.message}>{message}</div>
        </div>
      );
    }
    return message;
  }

  return (
    <div className={classNames(css.descriptionText, { [css.errorState]: status === threadStatus.FAILED })}>
      <span>
        {status === threadStatus.FAILED && <WarningIcon />}
        {otherTypingParticipants.length > 0 ? <ThreeDots /> : renderMessage()}
      </span>
    </div>
  );
}

ThreadStatusText.propTypes = {
  message: PropTypes.string,
  status: PropTypes.oneOf(Object.values(threadStatus)),
  otherTypingParticipants: PropTypes.array.isRequired,
  type: PropTypes.string,
};
