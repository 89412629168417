import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import InfiniteScroll from 'react-infinite-scroller';
import LoadingIndicator from 'components/molecules/LoadingIndicator';
import { Telescope } from 'components/atoms/graphics';
import Body from 'components/atoms/typography/Body';
import ContactItem from './ContactItem';
import css from './styles.scss';

export default function ContactList(props) {
  const {
    loadMore,
    contacts,
    onSelect,
    highlightedIndex,
    className,
    contactPageInfo: { hasNextPage },
  } = props;
  return (
    <div className={classNames(css.contactListContainer, className)}>
      <InfiniteScroll loadMore={loadMore} hasMore={hasNextPage} useWindow={false}>
        {contacts.map((contact, idx) => (
          <ContactItem
            onClick={(e) => {
              e.preventDefault();
              onSelect(contact);
            }}
            key={idx}
            user={contact}
            selected={highlightedIndex === idx}
          />
        ))}
        {!contacts.length && !hasNextPage && (
          <div className={css.telescopeContainer}>
            <div className={css.telescope}>
              <Telescope />
            </div>
            <Body className={css.telescopeText}>{'We couldn’t find anyone that matches \n your search.'}</Body>
          </div>
        )}
        {hasNextPage && <LoadingIndicator className={css.loadingIndicator} colour="hint" />}
      </InfiniteScroll>
    </div>
  );
}

ContactList.propTypes = {
  contacts: PropTypes.array,
  contactPageInfo: PropTypes.object,
  loadMore: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  highlightedIndex: PropTypes.number,
  className: PropTypes.string,
};
