import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { KeyboardArrowLeft, KeyboardArrowRight } from 'components/atoms/icons';

import css from './layout.scss';
import { handleEnter } from '../../../../utils/accessibility';

const Sidebar = ({ children }) => (
  <div className={css.sidebarScroll}>
    <div className={css.sidebar}>{children}</div>
  </div>
);

const Container = ({ children }) => {
  const [sidebarCollapsed, setSidebarCollapsed] = React.useState(false);
  return (
    <div className={classNames(css.container, { [css.collapsed]: sidebarCollapsed })}>
      <div className={css.sidebarDividerArea}>
        <div className={css.sidebarDividerLine} />
        <div
          role="button"
          tabIndex={0}
          data-ga-label={`SidebarCollapse-${setSidebarCollapsed ? 'open' : 'close'}`}
          className={css.sidebarDividerButton}
          onClick={() => setSidebarCollapsed((s) => !s)}
          onKeyDown={handleEnter(() => setSidebarCollapsed((s) => !s))}
        >
          {sidebarCollapsed ? (
            <KeyboardArrowRight style={{ width: 'auto', height: 'auto' }} />
          ) : (
            <KeyboardArrowLeft style={{ width: 'auto', height: 'auto' }} />
          )}
        </div>
      </div>
      {children}
    </div>
  );
};

const Header = ({ children }) => <div className={css.header}>{children}</div>;

const Main = ({ children }) => <div className={css.main}>{children}</div>;

Container.propTypes = { children: PropTypes.node.isRequired };
Header.propTypes = { children: PropTypes.node.isRequired };
Sidebar.propTypes = { children: PropTypes.node.isRequired };
Main.propTypes = { children: PropTypes.node.isRequired };

export default { Container, Header, Sidebar, Main };
