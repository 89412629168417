import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Immutable from 'immutable';
import TutorCapacityDisplay from 'components/molecules/TutorCapacityDisplay';
import {
  Description,
  Mail,
  AccessTime,
  LocationOn,
  People,
  School,
  Edit,
  Grade,
  AttachMoney,
  ThumbsUpDown,
  HourglassEmptySharpIcon,
} from 'components/atoms/icons';
import Language from 'components/atoms/graphics/Language';
import Button from 'components/molecules/Button';
import PayRateScale from 'components/organisms/PayRateScale';
import TextWithIcon from 'components/molecules/TextWithIcon';
import AverageRatingBadge from 'components/molecules/AverageRatingBadge';
import { Body, SubHeader, Caption } from 'components/atoms/typography';
import moment from 'moment-timezone';
import Modal from 'components/molecules/Modal';
import InputWarningMessage from 'components/molecules/InputWarningMessage';
import TutorRatingSentiment from 'components/organisms/TutorRatingSentiment';

import { connect } from 'react-redux';
import { fetchUserBilling, fetchAllBillingCountries } from 'ducks/billing';
import { deleteOrRetractContract } from 'ducks/contract';
import { getUserBillingByUserId, getCurrencyByUserId, getBillingCountries } from 'selectors/billing';
import { getAllTutorExpertise } from 'selectors/meta';
import { fetchAllTutorExpertise } from 'ducks/meta';
import { allLanguages } from 'selectors/language';
import { fetchUserRating } from 'ducks/feedback';
import { feedbackRating } from 'selectors/feedback';
import { daysList } from 'utils/options';

import css from './TutorDetail.scss';
import { tutorCostIndicator } from '../InviteTutor';
import { handleEnter } from '../../../../utils/accessibility';

const getTimezoneDifference = (t1, t2) => {
  const now = moment.utc();
  const offset1 = moment.tz.zone(t1).utcOffset(now);
  const offset2 = moment.tz.zone(t2).utcOffset(now);
  const timezoneOffset = (offset1 - offset2) / 60;

  return timezoneOffset > 0 ? `+${timezoneOffset}` : timezoneOffset;
};

const getTutorAction = (user, selected, status, onSelect, onCancel, isModalOpen, toggleModal) => {
  if (status === 'active') {
    return (
      <div>
        <Modal
          isOpen={isModalOpen}
          title="Remove Tutor?"
          submitText="Yes, remove this tutor"
          onSubmit={onCancel}
          onSubmitDataTestId="removeTutorRequestButton"
          onClose={toggleModal}
        >
          <div>
            <Body>
              Are you sure you want to remove {user.firstName} {user.lastName}?
            </Body>
            <Body>They will no longer be allocated to this student.</Body>
          </div>
        </Modal>
        <Button dataTestId="removeTutor" small secondary onClick={toggleModal}>
          Remove Tutor
        </Button>
      </div>
    );
  }

  if (status === 'pending') {
    return (
      <div>
        <Modal
          isOpen={isModalOpen}
          title="Cancel Request?"
          submitText="Yes, cancel this request"
          onSubmit={onCancel}
          onSubmitDataTestId="senderCancelRequestButton"
          onClose={toggleModal}
        >
          <div>
            <Body>Are you sure you want to cancel this allocation request?</Body>
            <Body>
              {user.firstName} {user.lastName} will no longer be able to accept this request.
            </Body>
          </div>
        </Modal>
        <Button dataTestId="cancelRequest" small secondary onClick={toggleModal}>
          Cancel Request
        </Button>
      </div>
    );
  }

  if (selected) {
    return (
      <Button dataTestId="deselectTutor" small onClick={onSelect}>
        <TextWithIcon text="Selected" icon="Check" iconSize="small" />
      </Button>
    );
  }

  if (!selected) {
    return (
      <Button dataTestId="selectTutor" small secondary onClick={onSelect}>
        <TextWithIcon text="Select" icon="Add" iconSize="small" />
      </Button>
    );
  }

  return null;
};

const getDayName = (value) => {
  return (
    (
      daysList.find((d) => {
        return d.value === value;
      }) || {}
    ).label || ''
  );
};
class TutorDetail extends Component {
  constructor() {
    super();
    this.state = {
      isModalOpen: false,
    };
  }

  componentDidMount() {
    const {
      allExpertise,
      fetchAllTutorExpertise,
      userBilling,
      fetchUserBilling,
      billingCountries,
      fetchAllBillingCountries,
      tutor,
      fetchUserRating,
      ratings,
    } = this.props;

    if (allExpertise.length === 0) {
      fetchAllTutorExpertise();
    }
    if (userBilling === undefined && tutor !== undefined) {
      fetchUserBilling(tutor.userId);
    }
    if (billingCountries.length === 0) {
      fetchAllBillingCountries();
    }
    if (ratings === undefined && tutor !== undefined) {
      fetchUserRating(tutor.userId);
    }
  }

  componentDidUpdate() {
    const { userBilling, tutor, fetchUserBilling, fetchUserRating, ratings } = this.props;
    if (userBilling === undefined && tutor !== undefined) {
      fetchUserBilling(tutor.userId);
    }
    if (ratings === undefined && tutor !== undefined) {
      fetchUserRating(tutor.userId);
    }
  }

  toggleModal = () => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  };

  cancelContract = () => {
    this.setState({ isModalOpen: false });
    this.props.deleteOrRetractContract(this.props.tutor.invitation.contractId);
  };

  render() {
    const {
      tutor,
      onclick,
      student,
      allExpertise,
      languages,
      selected,
      onSelect,
      subjectPayRate,
      subjectPayRates,
      subjectPayPercentiles,
      ratings,
    } = this.props;
    const { isModalOpen } = this.state;
    if (!tutor || !tutor.tutorInfo) {
      return null;
    }

    let timezoneString;
    if (tutor && student) {
      if (!tutor.timezone) {
        timezoneString = 'Tutor timezone not set.';
      } else if (!student.timezone) {
        timezoneString = 'Student timezone not set';
      } else {
        const diff = getTimezoneDifference(tutor.timezone, student.timezone);
        timezoneString = `${diff}h time difference from student`;
      }
    }
    const primaryLanguageString =
      tutor.primaryLanguage && languages.find(({ id }) => id === tutor.primaryLanguage).language;
    const secondaryLanguagesString = tutor.languages
      .map((language) => languages.find(({ id }) => id === language).language)
      .join(', ');

    const expertiseStrings = tutor.tutorInfo.expertise
      .map((expertise) => {
        const entry = allExpertise.find(({ id }) => id === expertise);
        return entry && entry.name;
      })
      .filter((str) => str && str.length > 0);

    const tutorRate = subjectPayRate && subjectPayRate.rateInUSD;
    const hasTutorRate = tutorRate !== null && tutorRate !== undefined;
    const tutorRateFormatted = hasTutorRate ? tutorRate.toString() : null;

    // Show indication of Rate against percentiles, only if there is a difference in values.
    let tutorRateClassification;
    if (hasTutorRate && subjectPayPercentiles[0] !== subjectPayPercentiles[3]) {
      const indicator = tutorCostIndicator(tutorRate, subjectPayPercentiles);
      switch (indicator) {
        case 'high':
          tutorRateClassification = 'This is a high cost tutor!';
          break;

        case 'above average':
          tutorRateClassification = "This tutor's cost is above average.";
          break;

        case 'average':
          tutorRateClassification = 'This tutor is average cost.';
          break;

        default:
          tutorRateClassification = null;
          break;
      }
    }
    const canShowRatings = ratings && ratings.sessionCount > 2 && ratings.sentiment && ratings.sentiment.length > 0;
    const preferredTimeRaw = JSON.parse(tutor.tutorInfo.preferredTime);
    const preferredTime = (preferredTimeRaw && preferredTimeRaw.map((p) => Immutable.fromJS(p))) || [];

    return (
      <div role="button" tabIndex={0} onClick={onclick} onKeyDown={handleEnter(onclick)} className={css.tutor}>
        <div className={css.header}>
          <Link to={`/users/${tutor.userId}/about`} className={css.name}>
            <SubHeader>{tutor.fullName}</SubHeader>
          </Link>
          <div className={css.tutorAction} data-test-id="applyTutorAction">
            {getTutorAction(
              tutor,
              selected,
              tutor.invitation && tutor.invitation.status,
              onSelect,
              this.cancelContract,
              isModalOpen,
              this.toggleModal,
            )}
          </div>
          <div className={css.rating}>
            <AverageRatingBadge averageRating={tutor.tutorInfo.averageRating} />
            <Body>
              {tutor.tutorInfo.totalSessions} session{tutor.tutorInfo.totalSessions !== 1 ? 's' : ''}
            </Body>
          </div>
          <div className={css.availability}>
            <TutorCapacityDisplay iconPosition="after" type={tutor.tutorInfo.capacity} />
          </div>
        </div>
        <div className={css.main}>
          {tutor.bio && (
            <React.Fragment>
              <Description />
              <div>
                <Caption>Bio</Caption>
                <Body>{tutor.bio}</Body>
              </div>
            </React.Fragment>
          )}
          {tutor.email && (
            <React.Fragment>
              <Mail />
              <div>
                <Caption>Email</Caption>
                <Body>{tutor.email}</Body>
              </div>
            </React.Fragment>
          )}
          {!!timezoneString && (
            <React.Fragment>
              <AccessTime />
              <div>
                <Caption>Time difference</Caption>
                <Body>{timezoneString}</Body>
              </div>
            </React.Fragment>
          )}

          <React.Fragment>
            <div />
            <div>
              <Caption>Available Hours per Week</Caption>
              <Body>{tutor.tutorInfo.availableHoursPerWeek || 'Availabel Hours not set'}</Body>
            </div>
          </React.Fragment>

          <React.Fragment>
            <div />
            <div>
              <Caption>Tutor Preferred Time</Caption>
              <Body>
                {preferredTime.length > 0 ? (
                  <ul className={css.preferredTimes}>
                    {preferredTime.map((d, index) => {
                      return (
                        <li key={index}>
                          {getDayName(d.get('dayOfWeek'))} {d.get('start').substr(0, 5)} - {d.get('end').substr(0, 5)}{' '}
                          {tutor.timezone}
                        </li>
                      );
                    })}
                  </ul>
                ) : (
                  'Tutor Preferred Time not set'
                )}
              </Body>
            </div>
          </React.Fragment>
          {(tutor.city || tutor.country) && (
            <React.Fragment>
              <LocationOn />
              <div>
                <Caption>Location</Caption>
                <Body>
                  {tutor.city}
                  {tutor.city && tutor.country ? ', ' : ''}
                  {tutor.country}
                </Body>
              </div>
            </React.Fragment>
          )}
          {(tutor.primaryLanguage || tutor.languages.length > 0) && (
            <React.Fragment>
              <Language className={css.safariIconFix} />
              <div>
                <Caption>Languages</Caption>
                {/* Show (Primary) if has Secondary language, show (Secondary) if has Secondary Language */}
                {primaryLanguageString && <Body>{primaryLanguageString} (Primary)</Body>}
                {secondaryLanguagesString && <Body>{secondaryLanguagesString} (Secondary)</Body>}
              </div>
            </React.Fragment>
          )}
          <React.Fragment>
            <People />
            <div>
              <Caption>Active Students</Caption>
              <Body>
                {tutor.tutorInfo.studentCount} Student{tutor.tutorInfo.studentCount !== 1 && 's'}
              </Body>
            </div>
          </React.Fragment>
          <React.Fragment>
            <HourglassEmptySharpIcon />
            <div>
              <Caption>Weekly Average Tutoring hours</Caption>
              <Body>{tutor.tutorInfo.averageHours}</Body>
            </div>
          </React.Fragment>
          {tutor.tutorInfo.universities.length > 0 && (
            <React.Fragment>
              <School />
              <div>
                <Caption>Universities</Caption>
                {tutor.tutorInfo.universities.map((university, index) => [
                  <div key={`university-${index}-text`}>
                    {index !== 0 && <br />}
                    {(university.name || university.college) && (
                      <Body>
                        {university.name}
                        {university.name && university.college ? ', ' : ''}
                        {university.college}
                      </Body>
                    )}
                    {(university.degree || university.major) && (
                      <Body>
                        {university.degree}
                        {university.degree && university.major ? ', ' : ''}
                        {university.major}
                      </Body>
                    )}
                  </div>,
                ])}
              </div>
            </React.Fragment>
          )}
          {subjectPayRates && subjectPayRates.length > 0 && (
            <React.Fragment>
              <AttachMoney />
              <div>
                <Caption>Pricing</Caption>
                {!subjectPayRate && (
                  <InputWarningMessage message="This tutor does not have a pay rate for this subject." />
                )}
                {subjectPayRate && !subjectPayRate.currency && (
                  <InputWarningMessage message="This tutor does not have a billing country set." />
                )}
                {hasTutorRate && (
                  <Body>
                    ${tutorRateFormatted} USD/hour. {tutorRateClassification}
                  </Body>
                )}
                <PayRateScale rate={subjectPayRate} rates={subjectPayRates} percentiles={subjectPayPercentiles} />
              </div>
            </React.Fragment>
          )}

          {tutor.tutorInfo.expertise.length > 0 && (
            <React.Fragment>
              <Grade />
              <div>
                {expertiseStrings.map((expertise, index) => (
                  <Body key={`expertise-${index}-text`}>{expertise}</Body>
                ))}
              </div>
            </React.Fragment>
          )}
          {tutor.tutorInfo.note && (
            <React.Fragment>
              <Edit />
              <div>
                <Caption>Crimson Notes</Caption>
                <Body>{tutor.tutorInfo.note.note}</Body>
              </div>
            </React.Fragment>
          )}
          {canShowRatings && (
            <React.Fragment>
              <ThumbsUpDown />
              <div>
                <Caption>Session Ratings</Caption>
                <div className={css.ratingcontainer}>
                  <TutorRatingSentiment sentiment={ratings.sentiment} />
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

TutorDetail.propTypes = {
  tutor: PropTypes.object, // .isRequired,
  student: PropTypes.object, // .isRequired,
  onclick: PropTypes.func,
  userBilling: PropTypes.object,
  fetchUserBilling: PropTypes.func,
  fetchUserRating: PropTypes.func,
  ratings: PropTypes.object,
  billingCountries: PropTypes.array,
  fetchAllBillingCountries: PropTypes.func,
  fetchAllTutorExpertise: PropTypes.func,
  allExpertise: PropTypes.array,
  languages: PropTypes.array,
  onSelect: PropTypes.func,
  selected: PropTypes.bool,
  deleteOrRetractContract: PropTypes.func,
  subjectPayRate: PropTypes.object,
  subjectPayRates: PropTypes.array,
  subjectPayPercentiles: PropTypes.array,
};

export default connect(
  (state, props) => ({
    userBilling: props.tutor && getUserBillingByUserId(props.tutor.userId)(state),
    currency: props.tutor && getCurrencyByUserId(props.tutor.userId)(state),
    ratings: props.tutor && feedbackRating('all', props.tutor.userId, true)(state),
    allExpertise: getAllTutorExpertise(state),
    languages: allLanguages(state),
    billingCountries: getBillingCountries(state),
  }),
  (dispatch) => ({
    fetchUserBilling: (userId) => dispatch(fetchUserBilling(userId)),
    fetchUserRating: (userId) => dispatch(fetchUserRating(userId, 'all')),
    fetchAllBillingCountries: () => dispatch(fetchAllBillingCountries()),
    fetchAllTutorExpertise: () => dispatch(fetchAllTutorExpertise()),
    deleteOrRetractContract: (contractId) => dispatch(deleteOrRetractContract(contractId)),
  }),
)(TutorDetail);
