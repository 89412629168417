import React, { useState, useMemo } from 'react';
import { Row, Column } from '../../index.style';
import { useStaffDashboardContext } from '../../helper';
import { ToolContainer, StyledDatePicker } from './index.style';
import { __setToday } from '../../alerts/util';
import { REGION_ALERTS } from '../../alerts';
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import { MoreLessIcon } from '../../../index.style';
import Draggable from './DraggableWrapper';
import Button from 'antd/lib/button';

const ChangeToday = () => {
  const [collapsed, setCollapsed] = useState(true);
  const [minimized, setMinimized] = useState(false);
  const { state, dispatch } = useStaffDashboardContext();

  const onClickItem = (value: Date[]) => {
    const selectedData = value[0];
    __setToday(moment(selectedData));
    dispatch('staffDashboard/setToday', {
      today: moment(selectedData).format('YYYY-MM-DD'),
    });
  };
  const availableAlerts = useMemo(
    () =>
      REGION_ALERTS()
        [state.region].filter((o) => o.availability())
        .map((o) => o.label),
    [state.region, state.today],
  );
  return (
    <Draggable targetElementId={'staffdashboardDevtool'}>
      {(props) => (
        <ToolContainer style={minimized ? { width: 150 } : undefined} id={'staffdashboardDevtool'} {...props}>
          <Button
            style={{ backgroundColor: 'transparent', width: 100, height: 30, border: undefined }}
            onClick={() => setMinimized((v) => !v)}
          >
            {minimized ? 'Expand' : 'Minimize'}
          </Button>
          {!minimized && (
            <>
              <Row gap={'20px'} rowcenter>
                <div style={{ fontSize: 20, fontWeight: 'bold' }}>{'Today:'}</div>
                <StyledDatePicker
                  value={state.today}
                  onChange={onClickItem}
                  options={{
                    mode: 'single',
                    clickOpens: true,
                    static: true,
                    formatDate: (date) => moment(date).format('YYYY-MM-DD'),
                  }}
                />
                <IconButton onClick={() => setCollapsed((v) => !v)}>
                  <MoreLessIcon style={{ fontSize: 25 }} open={collapsed} />
                </IconButton>
              </Row>

              {!collapsed && (
                <Column gap={'7px'}>
                  <div style={{ fontSize: 23, fontWeight: 'bold' }}>{'Available alerts today:'}</div>
                  {availableAlerts.map((label) => (
                    <div style={{ border: '0 solid white', borderBottomWidth: 1 }} key={'devtool' + label}>
                      {label}
                    </div>
                  ))}
                </Column>
              )}
            </>
          )}
        </ToolContainer>
      )}
    </Draggable>
  );
};

export default ChangeToday;
