import React from 'react';
import PropTypes from 'prop-types';
import Select from 'components/molecules/Select';
import { Caption } from 'components/atoms/typography';
import styles from './styles.scss';

const MAX_OPTS = 30;

/* SubjectSelector
 * Renders an input field for selecting a subject from a list.
 */

export default function SubjectSelector(props) {
  const { className, label, subjects, selectedSubject, onChange, isDisabled } = props;

  // Create options for subject selector.
  const options = Object.values(subjects).map((subject) => ({
    label: subject.name,
    value: subject.id,
  }));

  // Create selected option for subject selector.
  const value = options.find((option) => option.value === selectedSubject);

  const loadOptions = (input, cb) =>
    cb(
      input
        ? options.filter((opt) => opt.label.toLowerCase().includes(input.toLowerCase())).slice(0, MAX_OPTS)
        : options.slice(0, MAX_OPTS),
    );

  const filterOption = (option, inputValue) => {
    const label = option.label;
    return label && label.toLowerCase().includes(inputValue.toLowerCase());
  };

  return (
    <div>
      {label && <Caption className={styles.subjectCaption}>{label}</Caption>}
      <Select
        className={className}
        value={value}
        onChange={onChange}
        loadOptions={loadOptions}
        isDisabled={isDisabled}
        filterOption={filterOption}
        isClearable
      />
    </div>
  );
}

SubjectSelector.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  isDisabled: PropTypes.bool,
  selectedSubject: PropTypes.string,
  subjects: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};
