import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import { getCurrentUserJS, getUserWithId } from 'selectors/user';
import { selectUserProfile } from 'selectors/meta';
import * as zoid from 'zoid/dist/zoid.frameworks.js';
import { getEnvironmentConfig } from '@crimson-education/common-config';
import { history } from '../../../app';
import { getBearer, authorize } from '../../../utils/auth/auth';
import { featureSwitches, getFeatureSwitchesAsString } from '../../../featureSwitches';
import _ from 'lodash';
import styled from 'styled-components';

const StyledContainer = styled.div`
  & .zoid-visible {
    height: 100vh !important;
  }
`;

const { environment } = getEnvironmentConfig();

const environmentHostnames = {
  local: 'http://localhost:3014',
  staging: 'https://staging.core-student-center.crimsoneducation.org',
  production: 'https://core-student-center.crimsoneducation.org',
};
const { protocol, hostname, port } = window.location;

const domain = `${protocol}//${hostname}${port ? `:${port}` : ''}`;

const studentCenterDomain =
  environmentHostnames[featureSwitches.STUDENT_CENTER_CONNECT_LOCAL() ? 'local' : environment];
// store all the registered event callbacks.
const eventEmitters = {};
// store the student center current url
let studentCenterUrl;
// tag the url change original source from iframe of navbar.
// if it's true, the iframe will not refresh
let innerChange = false;

const StudentCenterComponent = zoid.create({
  tag: 'student-center-component',
  url: ({ props }) => {
    if (props && props.history) {
      const { location } = props.history;
      const { pathname } = location;
      const regex = /(\/student-center|\/users.*student-center)/i;
      return studentCenterDomain + pathname.replace(regex, '') + location.search;
    }
    return studentCenterDomain;
  },
  style: {
    height: '100vh',
  },
  dimensions: {
    width: '100%',
    height: '99vh',
  },
  props: {
    token: {
      type: 'string',
      isRequired: false,
    },
    userId: {
      type: 'string',
      isRequired: true,
    },
    authorize: {
      type: 'function',
      isRequired: false,
    },
    getBearer: {
      type: 'function',
      isRequired: false,
    },
    download: {
      type: 'function',
      isRequired: true,
    },
  },
});

const DynamicReactComponent = StudentCenterComponent.driver('react', {
  React,
  ReactDOM,
});

// registerEventEmitter
// a function to add more callbacks from iframe
const registerEventEmitter = (eventName, callback) => {
  eventEmitters[eventName] = callback;
};

const unregisterEventEmitter = (eventName) => {
  delete eventEmitters[eventName];
};

// invoke the event callbacks
// conditionally, if the innerChange is true, we should do something special
const performEvent = (eventName, data) => {
  if (innerChange) {
    innerChange = false;
    return;
  }
  eventEmitters[eventName] && eventEmitters[eventName](data);
};

const onMessage = ({ event, data }, userId) => {
  // accept the route event from the url routing change of the iframe.
  if (event === 'route') {
    if (studentCenterUrl === data) {
      return;
    }
    innerChange = true;
    studentCenterUrl = data;
    if (data.includes('/student-center')) {
      window.history.pushState({}, '', `/users/${userId}${data}`);
    } else {
      window.history.pushState({}, '', `/users/${userId}/student-center${data}`);
    }
  }
};

const download = (name, url) => {
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const blobUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
};

function StudentCenter() {
  const user = useSelector(getCurrentUserJS);
  const loggedInUser = useSelector(selectUserProfile);
  const loggedInUserDetail = useSelector(getUserWithId(loggedInUser.get('userId')));
  useEffect(() => {
    performEvent('refresh', `${history.location.pathname}${history.location.search}`);
  });
  return (
    <StyledContainer>
      <DynamicReactComponent
        registerEventEmitter={registerEventEmitter}
        unregisterEventEmitter={unregisterEventEmitter}
        history={history}
        onMessage={({ event, data }) => onMessage({ event, data }, user.userId)}
        token={getBearer() || ''}
        getBearer={getBearer}
        download={download}
        loggedInUser={{
          userId: loggedInUser.get('userId'),
          email: loggedInUser.get('email'),
          firstName: loggedInUserDetail.get('firstName'),
          lastName: loggedInUserDetail.get('lastName'),
          profileImageUrl: loggedInUserDetail.get('profileImageUrl'),
          role: loggedInUser.get('role'),
          roleInfo: loggedInUser.get('roleInfo'),
        }}
        authorize={authorize}
        featureSwitches={JSON.parse(getFeatureSwitchesAsString())}
        userId={user.userId}
        user={_.pick(user, ['userId', 'firstName', 'lastName', 'email'])}
        domain={domain}
      />
    </StyledContainer>
  );
}

export default StudentCenter;
