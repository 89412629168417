import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/molecules/Modal';
import css from './styles.scss';

export default function UnbindPopupModal(props) {
  const { isOpen, onCancel, unbindCalAccount, calSource } = props;
  return (
    <Modal
      isOpen={isOpen}
      title="Unbind the account"
      onClose={onCancel}
      onSubmit={() => unbindCalAccount(calSource)}
      submitText="UNBIND"
    >
      <div className={css.front}>
        <div className={css.modalBody}>
          <div className={css.bookingWithWrapper}>
            <div className={css.centerText}>Are you sure you want to unsync? </div>
            <div className={css.centerText}>
              This will stop the Crimson calendar syncing with your calendar, and synced data will be removed from both
              calendars.
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

UnbindPopupModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  unbindCalAccount: PropTypes.func.isRequired,
  calSource: PropTypes.string,
};
