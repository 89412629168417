import React from 'react';
import { Column } from 'components/molecules/Table';
import { columnNames } from '../columnMap';

export default function CaseManagerStudentCount() {
  return (
    <Column
      key={columnNames.CaseManagerStudentCount}
      name="Number of Students"
      renderer={({ rowData }) => {
        return rowData.get('relationships')
          ? `${rowData
              .get('relationships')
              .filter((relationship) => {
                if (!relationship.get('principalUser') || !relationship.get('relationUser')) {
                  return false;
                }
                return (
                  relationship.get('type') === 'CaseManagerStudent' &&
                  relationship.get('relationUser').get('status') === 'active'
                );
              })
              .count()}`
          : 0;
      }}
    />
  );
}
