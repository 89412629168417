import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';
import { gql } from '@apollo/client';
import { createClient } from '../utils';

const { api } = getConfig();

const client = createClient(api.graphQLEndpoint);

const eclActivity = gql`
  fragment eclActivity on Activity {
    id
    userId
    type
    activity
    sport
    participationGradeLevels
    participationPeriod
    clubName
    position
    descriptionOfActivity
    hoursPerWeek
    weeksPerYear
    aspirations
    involvementSteps
    leadershipPlan
    reasonsForInvolvement
    collegeParticipation
    status
    order
    createdAt
    year
  }
`;

const createCCReport = gql`
  mutation createCCReport($type: String!, $name: String!, $content: String!) {
    createCCReport(type: $type, name: $name, content: $content)
  }
`;

const getECActivitiesByUserId = gql`
  ${eclActivity}
  query getECActivitiesByUserId($userId: String!) {
    getECActivitiesByUserId(userId: $userId) {
      ...eclActivity
    }
  }
`;

const createECActivity = gql`
  ${eclActivity}
  mutation createECActivity($activity: NewActivity!) {
    createECActivity(activity: $activity) {
      ...eclActivity
    }
  }
`;

const updateECActivity = gql`
  ${eclActivity}
  mutation updateECActivity($activity: EditActivity!) {
    updateECActivity(activity: $activity) {
      ...eclActivity
    }
  }
`;

const deleteECActivity = gql`
  ${eclActivity}
  mutation deleteECActivity($activityId: String!) {
    deleteECActivity(activityId: $activityId) {
      ...eclActivity
    }
  }
`;

export default {
  getECActivitiesByUserId: async (userId) => client.query(getECActivitiesByUserId, { userId }),
  createECActivity: async (activity) => client.query(createECActivity, { activity }),
  updateECActivity: async (activityUpdates) => client.query(updateECActivity, { activity: activityUpdates }),
  deleteECActivity: async (activityId) => client.query(deleteECActivity, { activityId }),
  createCCReport: async (type, name, content) => client.query(createCCReport, { type, name, content }),
};
