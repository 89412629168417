import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Info from '@material-ui/icons/Info';
import { Caption } from 'components/atoms/typography';

import fontColour from 'components/atoms/typography/fontColour.scss';
import css from './style.scss';
import { asButton } from '../../../utils/accessibility';

export default function InputWarningMessage(props) {
  const { message, messageAction, messageActionText, className, fontColourClass, renderIcon } = props;
  const isActionable = messageAction && messageActionText;
  return (
    <div className={classNames(css.validationMessage, className)}>
      {renderIcon ? (
        renderIcon()
      ) : (
        <Info
          className={classNames(fontColourClass, css.validationIcon)}
          style={{ height: '1.4rem', width: '1.2rem' }}
        />
      )}
      <Caption className={fontColourClass}>
        {message}
        {isActionable && ' '}
        {isActionable && (
          <span className={css.linkText} {...asButton(messageAction)}>
            {messageActionText}
          </span>
        )}
      </Caption>
    </div>
  );
}

InputWarningMessage.defaultProps = {
  fontColourClass: fontColour.warning,
};

InputWarningMessage.propTypes = {
  message: PropTypes.node,
  messageAction: PropTypes.func,
  messageActionText: PropTypes.string,
  className: PropTypes.string,
  fontColourClass: PropTypes.string,
  renderIcon: PropTypes.func,
};
