import React from 'react';
import PropTypes from 'prop-types';
import Header from 'components/molecules/Header';
import UserQueryContainer from 'components/unique/UserQuery';
import { Acl, roleTypes, permissionTypes, tutorCapacityTypes } from '@crimson-education/common-config';
import { Tab, Tabs, TabList, TabPanel } from 'components/molecules/ReactTabs';
import { columnNames } from 'components/unique/UserQuery/UserQueryTable/columnMap'; // TODO: Move to generic
import { filterNames } from 'components/unique/UserQuery/UserQueryFilter/filterMap'; // TODO: Move to generic
import {
  UserCountMap,
  RELATION_STUDENTS_AND_CONTRACT,
  RELATION_STUDENTS_AND_CONTRACT_AS_HEAD_TUTOR,
  RELATION_STUDENTS_AND_CONTRACT_AS_TUTOR,
  EXTERNAL_STUDENTS,
  RELATION_TUTORS,
  ALL_TUTORS,
  ALL_CASE_MANAGERS,
  ALL_USERS_DEACTIVATED,
  ALL_USERS_ACTIVE,
  MY_FRIENDS,
  CGA_STUDENTS,
} from 'constants/ourPeople';

import { trimSpecialCharacters } from 'utils/utils';
import Students from 'components/unique/Students';
import { featureSwitches } from 'featureSwitches';

import FriendsQuery from '../Contacts/FriendsQuery';
import css from './styles.scss';

function getViewableTabs(userId, userRoles) {
  const myStudentTabs = [
    {
      hasAccess: Acl.isStrategist(userRoles),
      tabName: 'As Strategist',
      component: (
        <UserQueryContainer
          userId={userId}
          activeUsers
          fixedFilters={{
            principalRole: roleTypes.STRATEGIST,
            roles: [roleTypes.STUDENT],
            related: true,
          }}
          columnsToShow={[
            columnNames.ProfileColumn,
            columnNames.UserSince,
            columnNames.StudentPackageSubjects,
            columnNames.Location,
            columnNames.ApplicationYear,
            columnNames.QuickLink,
            columnNames.EditUserButton,
          ]}
          filtersToShow={[filterNames.Name, filterNames.SubjectName, filterNames.Location, filterNames.ApplicationYear]}
          tabType={RELATION_STUDENTS_AND_CONTRACT}
        />
      ),
    },
    {
      hasAccess: Acl.isCaseManager(userRoles),
      tabName: 'As Student Success Manager',
      component: (
        <UserQueryContainer
          userId={userId}
          activeUsers
          fixedFilters={{
            principalRole: roleTypes.CASE_MANAGER,
            roles: [roleTypes.STUDENT],
            related: true,
          }}
          columnsToShow={[
            columnNames.ProfileColumn,
            columnNames.UserSince,
            columnNames.StudentPackageSubjects,
            columnNames.Location,
            columnNames.ApplicationYear,
            columnNames.QuickLink,
            columnNames.EditUserButton,
          ]}
          filtersToShow={[filterNames.Name, filterNames.SubjectName, filterNames.Location, filterNames.ApplicationYear]}
          tabType={RELATION_STUDENTS_AND_CONTRACT}
        />
      ),
    },
    {
      hasAccess: Acl.isTutor(userRoles),
      tabName: 'As Tutor',
      component: (
        <div className={css.studentsTable}>
          <Students userId={userId} />
        </div>
      ),
    },
    {
      hasAccess: Acl.isReviewer(userRoles),
      tabName: 'As Reviewer',
      component: (
        <UserQueryContainer
          userId={userId}
          activeUsers
          fixedFilters={{
            principalRole: roleTypes.REVIEWER,
            getQueryAs: roleTypes.REVIEWER,
            roles: [roleTypes.STUDENT],
            related: true,
          }}
          columnsToShow={[
            columnNames.ProfileColumn,
            columnNames.UserSince,
            columnNames.StudentPackageSubjects,
            columnNames.Location,
            columnNames.ApplicationYear,
          ]}
          filtersToShow={[filterNames.Name, filterNames.SubjectName, filterNames.Location, filterNames.ApplicationYear]}
          tabType={RELATION_STUDENTS_AND_CONTRACT}
        />
      ),
    },
  ];
  return myStudentTabs.filter((tab) => tab.hasAccess);
}

function getAvailableTabs(loginUserId, loginUserRoles, defaultActive) {
  const myStudentTabs = getViewableTabs(loginUserId, loginUserRoles);
  const allAvailableTabs = [
    {
      requiredRoles: [roleTypes.HEAD_TUTOR],
      tabName: 'My Tutors',
      type: RELATION_TUTORS,
      component: (
        <UserQueryContainer
          userId={loginUserId}
          activeUsers
          fixedFilters={{
            roles: [roleTypes.TUTOR],
            related: true,
          }}
          columnsToShow={[
            columnNames.ProfileColumn,
            columnNames.UserSince,
            columnNames.TutorSubjects,
            columnNames.University,
          ]}
          filtersToShow={[filterNames.Name, filterNames.SubjectName, filterNames.University, filterNames.TutorCapacity]}
        />
      ),
    },
    {
      requiredRoles: [roleTypes.HEAD_TUTOR],
      tabName: 'All Students',
      type: RELATION_STUDENTS_AND_CONTRACT_AS_HEAD_TUTOR,
      component: (
        <UserQueryContainer
          userId={loginUserId}
          activeUsers
          fixedFilters={{
            getQueryAs: roleTypes.HEAD_TUTOR,
            roles: [roleTypes.STUDENT],
            related: true,
            contractRelated: Acl.isTutor(loginUserRoles),
          }}
          columnsToShow={[
            columnNames.ProfileColumn,
            columnNames.UserSince,
            columnNames.StudentPackageSubjects,
            columnNames.Location,
            columnNames.QuickLink,
          ]}
          filtersToShow={[filterNames.Name, filterNames.SubjectName, filterNames.Location]}
        />
      ),
    },
    {
      requiredRoles: [roleTypes.CASE_MANAGER, roleTypes.STRATEGIST, roleTypes.TUTOR, roleTypes.REVIEWER],
      tabName: 'My Students',
      type:
        Acl.isCaseManager(loginUserRoles) || Acl.isStrategist(loginUserRoles) || Acl.isReviewer(loginUserRoles)
          ? RELATION_STUDENTS_AND_CONTRACT
          : RELATION_STUDENTS_AND_CONTRACT_AS_TUTOR,
      component: (
        <div>
          {(myStudentTabs.length > 1 && (
            <div className={css.studentsTable}>
              <Tabs>
                <TabList>
                  {myStudentTabs.map((tab, i) => (
                    <Tab key={i} title={tab.tabName} />
                  ))}
                </TabList>
                {myStudentTabs.map((tab, i) => (
                  <TabPanel key={i}>{tab.component}</TabPanel>
                ))}
              </Tabs>
            </div>
          )) || <div className={css.noTabs}>{myStudentTabs.length === 1 && myStudentTabs[0].component}</div>}
        </div>
      ),
    },
    {
      requiredRoles: [roleTypes.SYSTEM_OPERATOR],
      tabName: 'External Students',
      type: EXTERNAL_STUDENTS,
      component: (
        <UserQueryContainer
          userId={loginUserId}
          activeUsers
          fixedFilters={{
            roles: [roleTypes.EXTERNAL_STUDENT],
          }}
          columnsToShow={[
            columnNames.ProfileColumn,
            columnNames.UserSince,
            columnNames.Location,
            columnNames.EditUserButton,
          ]}
          filtersToShow={[filterNames.Name, filterNames.Email, filterNames.Location]}
        />
      ),
    },
    {
      requiredRoles: [roleTypes.SUPER_ADMIN, roleTypes.CASE_MANAGER, roleTypes.HEAD_TUTOR, roleTypes.STRATEGIST],
      tabName: 'Tutors/Mentors',
      type: ALL_TUTORS,
      component: (
        <UserQueryContainer
          userId={loginUserId}
          activeUsers
          fixedFilters={{
            roles: [roleTypes.TUTOR, roleTypes.HEAD_TUTOR],
            ...(Acl.isCaseManager(loginUserRoles) && { related: true }),
          }}
          changeableFilters={{
            tutorCapacity: [
              tutorCapacityTypes.AVAILABLE,
              tutorCapacityTypes.SEEKING_HOURS,
              tutorCapacityTypes.FULL,
              tutorCapacityTypes.ONBOARDING,
            ],
          }}
          columnsToShow={[
            columnNames.ProfileColumn,
            columnNames.UserSince,
            columnNames.TutorSubjects,
            columnNames.University,
            columnNames.EditUserButton,
          ]}
          filtersToShow={[
            ...(Acl.isCaseManager(loginUserRoles) ? [filterNames.Relationship] : []),
            filterNames.Name,
            filterNames.SubjectName,
            filterNames.University,
            filterNames.TutorCapacity,
          ]}
        />
      ),
    },
    {
      requiredRoles: [roleTypes.REVIEWER],
      tabName: 'Tutors/Mentors',
      type: RELATION_TUTORS,
      component: (
        <UserQueryContainer
          userId={loginUserId}
          activeUsers
          fixedFilters={{
            getQueryAs: roleTypes.REVIEWER,
            roles: [roleTypes.TUTOR],
            related: true,
          }}
          columnsToShow={[
            columnNames.ProfileColumn,
            columnNames.UserSince,
            columnNames.TutorSubjects,
            columnNames.University,
          ]}
          filtersToShow={[filterNames.Name, filterNames.SubjectName, filterNames.University, filterNames.TutorCapacity]}
        />
      ),
    },
    {
      requiredRoles: [roleTypes.SUPER_ADMIN, roleTypes.CASE_MANAGER],
      tabName: 'Student Success Managers',
      type: ALL_CASE_MANAGERS,
      component: (
        <UserQueryContainer
          userId={loginUserId}
          activeUsers
          fixedFilters={{
            roles: [roleTypes.CASE_MANAGER],
          }}
          columnsToShow={[
            columnNames.ProfileColumn,
            columnNames.CaseManagerStudentCount,
            columnNames.Location,
            columnNames.EditUserButton,
          ]}
          filtersToShow={[filterNames.Name, filterNames.Email, filterNames.Location]}
        />
      ),
    },
    {
      requiredRoles: [
        roleTypes.SUPER_ADMIN,
        roleTypes.CASE_MANAGER,
        roleTypes.HEAD_TUTOR,
        roleTypes.STRATEGIST,
        roleTypes.CGA_STAFF,
      ],
      tabName: 'My Friends',
      type: MY_FRIENDS,
      count: true,
      component: (
        <FriendsQuery
          defaultActive={defaultActive}
          columnsToShow={[
            columnNames.ProfileColumn,
            columnNames.UserType,
            columnNames.Location,
            columnNames.University,
            columnNames.FriendActionButton,
          ]}
        />
      ),
    },
    {
      requiredRoles: [roleTypes.CGA_STAFF, roleTypes.SUPER_ADMIN],
      tabName: 'CGA Students',
      type: CGA_STUDENTS,
      component: (
        <UserQueryContainer
          userId={loginUserId}
          activeUsers
          fixedFilters={{
            getQueryAs: roleTypes.CGA_STAFF,
            roles: [roleTypes.CGA_STUDENT],
          }}
          filtersToShow={[filterNames.Name, filterNames.Email, filterNames.Location]}
        />
      ),
    },
    {
      requiredRoles: [roleTypes.SUPER_ADMIN, roleTypes.OPERATIONAL_SUPPORT],
      tabName: 'All Users',
      type: ALL_USERS_ACTIVE,
      component: (
        <UserQueryContainer
          userId={loginUserId}
          activeUsers
          fixedFilters={{
            includeSelf: true,
          }}
          filtersToShow={[filterNames.Name, filterNames.Email, filterNames.UserType]}
        />
      ),
    },
    {
      requiredRoles: [roleTypes.SUPER_ADMIN, roleTypes.CASE_MANAGER, roleTypes.OPERATIONAL_SUPPORT],
      tabName: 'Deactivated Users',
      type: ALL_USERS_DEACTIVATED,
      component: (
        <UserQueryContainer
          userId={loginUserId}
          activeUsers={false}
          filtersToShow={[filterNames.Name, filterNames.Email, filterNames.UserType]}
          columnsToShow={[
            columnNames.ProfileColumn,
            columnNames.UserSince,
            columnNames.UserType,
            columnNames.EditUserButton,
          ]}
        />
      ),
    },
  ];

  return allAvailableTabs.filter(
    (tab) => !tab.isDisabled && tab.requiredRoles.some((role) => Acl.checkRole(loginUserRoles, role)),
  );
}

const OurPeople = (props) => {
  const {
    activeTab,
    setActiveTab,
    setActivePage,
    userCountQuery,
    loginUserId,
    loginUserRoles,
    loginUserPermissions,
    hasNewFriend,
    showMyFriendsTab,
  } = props;
  const tabs = getAvailableTabs(loginUserId, loginUserRoles, !showMyFriendsTab);
  const [tabIndex, setTabIndex] = React.useState(
    showMyFriendsTab ? tabs.findIndex((tab) => tab.type === 'MY_FRIENDS') || 0 : activeTab || 0,
  );
  const buttons = Acl.checkPermission(loginUserPermissions, permissionTypes.CREATE_USER)
    ? [
        {
          buttonText: 'Onboard new user',
          buttonLink: 'users/onboard',
          dataTestId: 'onboardNewUserButton',
        },
      ]
    : [];
  return (
    <div data-ga-category="Our People" className={css.ourPeople}>
      <Header title="Our People" actionButtons={buttons} isBorderVisible={false} />
      {tabs.length ? (
        <Tabs
          selectedIndex={tabIndex}
          onSelect={(index) => {
            setTabIndex(index);
            setActiveTab(index);
            setActivePage(0);
          }}
        >
          <TabList>
            {tabs.map((tab, i) => {
              const userCountText =
                userCountQuery &&
                tab.type &&
                userCountQuery[UserCountMap[tab.type].key] !== undefined &&
                `(${userCountQuery[UserCountMap[tab.type].key]})`;
              return (
                <Tab
                  key={i}
                  title={`${tab.tabName} ${userCountText || ''}`}
                  count={tab.count ? hasNewFriend : null}
                  dataTestId={`ourPeopleTab_${trimSpecialCharacters(tab.tabName)}`}
                />
              );
            })}
          </TabList>
          {tabs.map((t, i) => (
            <TabPanel key={i}>{t.component}</TabPanel>
          ))}
        </Tabs>
      ) : (
        <div className={css.emptyMessage}>Sorry, you do not have permission to view users.</div>
      )}
    </div>
  );
};

OurPeople.displayName = 'OurPeople';

OurPeople.propTypes = {
  loginUserId: PropTypes.string.isRequired,
  loginUserRoles: PropTypes.array.isRequired,
  loginUserPermissions: PropTypes.array.isRequired,
  userCountQuery: PropTypes.object.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  setActivePage: PropTypes.func.isRequired,
  activeTab: PropTypes.number,
  hasNewFriend: PropTypes.number,
  showMyFriendsTab: PropTypes.bool,
};

export default OurPeople;
