import { StudentInList } from '../type';
import { StaffStudentRelationship } from '../../type';
export const filterBySearchText = (searchText: string) => (students: StudentInList[]) => {
  return students.filter((o) => `${o.firstName}${o.lastName}`.toLowerCase().includes(searchText.toLowerCase()));
};

export const filterByRegion = (selectedRegion: string | null) => (students: StudentInList[]) => {
  if (!selectedRegion || selectedRegion === '*') return students;
  return students.filter((o) => o.region === selectedRegion);
};

export const filterByStaff = (selectedStaff: string | null, relationships: StaffStudentRelationship) => (
  students: StudentInList[],
) => {
  if (!selectedStaff || selectedStaff === '*') return students;
  return students.filter((o) => relationships[selectedStaff]?.studentUserIds.includes(o.userId));
};

export const applyFilters = (filters: ((s: StudentInList[]) => StudentInList[])[], students: StudentInList[]) => {
  return filters.reduce((prev, curr) => curr(prev), students);
};
