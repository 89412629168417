import React from 'react';

export const MulticolorFailed = () => (
  <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle opacity="0.1" cx="50" cy="50" r="50" fill="#5C6AC4" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51.5625 18.75C68.8214 18.75 82.8125 32.7411 82.8125 50C82.8125 67.2589 68.8214 81.25 51.5625 81.25C34.3036 81.25 20.3125 67.2589 20.3125 50C20.3125 32.7411 34.3036 18.75 51.5625 18.75Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M71.192 25.6831C78.2799 31.4119 82.8125 40.1766 82.8125 50C82.8125 67.2589 68.8214 81.25 51.5625 81.25C41.7391 81.25 32.9744 76.7174 27.2456 69.6295C32.6106 73.9657 39.4396 76.5625 46.875 76.5625C64.1339 76.5625 78.125 62.5714 78.125 45.3125C78.125 37.8771 75.5282 31.0481 71.192 25.6831V25.6831Z"
      fill="#FFC200"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M84.375 50C84.375 31.8782 69.6843 17.1875 51.5625 17.1875C33.4407 17.1875 18.75 31.8782 18.75 50C18.75 68.1218 33.4407 82.8125 51.5625 82.8125C69.6843 82.8125 84.375 68.1218 84.375 50ZM21.875 50C21.875 33.604 35.1665 20.3125 51.5625 20.3125C67.9585 20.3125 81.25 33.604 81.25 50C81.25 66.396 67.9585 79.6875 51.5625 79.6875C35.1665 79.6875 21.875 66.396 21.875 50Z"
      fill="#154E84"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M71.192 25.6831C78.2799 31.4119 82.8125 40.1766 82.8125 50C82.8125 67.2589 68.8214 81.25 51.5625 81.25C41.7391 81.25 32.9744 76.7174 27.2456 69.6295C32.6106 73.9657 39.4396 76.5625 46.875 76.5625C64.1339 76.5625 78.125 62.5714 78.125 45.3125C78.125 37.8771 75.5282 31.0481 71.192 25.6831V25.6831Z"
      fill="#ED4B53"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51.5625 17.1875C69.6843 17.1875 84.375 31.8782 84.375 50C84.375 68.1218 69.6843 82.8125 51.5625 82.8125C33.4407 82.8125 18.75 68.1218 18.75 50C18.75 31.8782 33.4407 17.1875 51.5625 17.1875ZM51.5625 20.3125C35.1665 20.3125 21.875 33.604 21.875 50C21.875 66.396 35.1665 79.6875 51.5625 79.6875C67.9585 79.6875 81.25 66.396 81.25 50C81.25 33.604 67.9585 20.3125 51.5625 20.3125Z"
      fill="#464AC9"
    />
    <g clipPath="url(#clip0_21_66)">
      <path
        d="M64.5999 37.4199C63.8199 36.6399 62.5599 36.6399 61.7799 37.4199L51.9999 47.1799L42.2199 37.3999C41.4399 36.6199 40.1799 36.6199 39.3999 37.3999C38.6199 38.1799 38.6199 39.4399 39.3999 40.2199L49.1799 49.9999L39.3999 59.7799C38.6199 60.5599 38.6199 61.8199 39.3999 62.5999C40.1799 63.3799 41.4399 63.3799 42.2199 62.5999L51.9999 52.8199L61.7799 62.5999C62.5599 63.3799 63.8199 63.3799 64.5999 62.5999C65.3799 61.8199 65.3799 60.5599 64.5999 59.7799L54.8199 49.9999L64.5999 40.2199C65.3599 39.4599 65.3599 38.1799 64.5999 37.4199Z"
        fill="#ED4B53"
      />
    </g>
    <defs>
      <clipPath id="clip0_21_66">
        <rect width="48" height="48" fill="white" transform="translate(28 26)" />
      </clipPath>
    </defs>
  </svg>
);
