import React from 'react';
import PropTypes from 'prop-types';

import css from './style.css';

export default function EmptyState(props) {
  const { showIf, children } = props;
  return (
    showIf && (
      <div className={css.emptyStateOuter}>
        <div className={css.emptyStateInner}>{children}</div>
      </div>
    )
  );
}

EmptyState.defaultProps = {
  showIf: false,
};

EmptyState.propTypes = {
  showIf: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};
