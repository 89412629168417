import React from 'react';

export default function LogoFull(props) {
  return (
    <svg height="64" viewBox="0 0 144 64" {...props}>
      <g fill="#fff">
        <path d="m18.7034483 12.8621359-2.2965517-3.99741098c-.3517242-.62135922-1.262069-.62135922-1.6137932 0l-5.06896547 8.63689318c-.33103448.5799353-.33103448 1.2841424 0 1.8640777l2.54482757 4.3495146c1.3862069-4.038835 3.5793104-7.7255664 6.4344828-10.8530745z" />
        <path d="m63.3103448 45.3799353c-.7655172.3521035-1.6344827.5177993-2.6068965.5177993-.8896552 0-1.7379311-.1449838-2.4827586-.4349514-.7655173-.2899677-1.4275863-.7249191-1.9862069-1.2634304-.5586207-.5385114-.9931035-1.2012945-1.324138-1.9469256-.3103448-.7663431-.475862-1.594822-.475862-2.5061489 0-.9320388.1655172-1.7812298.475862-2.5268608.3310345-.7663431.7655173-1.4084143 1.3448276-1.9262136.5793104-.5385113 1.2413793-.9320389 2.0068966-1.2220065.7655172-.2899676 1.5931034-.4349514 2.4827586-.4349514.8275862 0 1.6344827.1449838 2.4206896.4349514s1.4482759.7249191 1.924138 1.2841424l-1.9034483 1.9055016c-.2689655-.3521035-.6-.6213592-1.0344828-.807767-.4344827-.1656958-.8689655-.2692556-1.3241379-.2692556-.4965517 0-.9517241.0828479-1.3655172.2692556-.4137931.1864078-.7655173.4349515-1.0551724.7456311-.2896552.3106796-.5172414.7042071-.6827587 1.1184466-.1655172.4142395-.2482758.9113269-.2482758 1.4291262 0 .5177994.0827586 1.0148867.2482758 1.4498382.1655173.4349515.3931035.807767.6827587 1.1184466.2896551.3106796.6413793.5592233 1.0344827.7249191.3931035.1656958.8482759.2692557 1.3241379.2692557.5586207 0 1.0551725-.1035599 1.4689656-.3313916s.7448275-.4970874.9931034-.849191l1.9655172 1.8433657c-.4965517.5799353-1.137931 1.0563107-1.8827586 1.4084143z" />
        <path d="m74.5655172 45.587055-2.5241379-4.6187702h-.9517241v4.6187702h-2.7517242v-11.6608414h4.4482759c.5586207 0 1.0965517.0621359 1.6344828.1656958.537931.1035599 1.0137931.3106796 1.4275862.5799353.4137931.2692556.7655172.6420712 1.0137931 1.0770226.2482758.4556634.3724138 1.0148867.3724138 1.6776699 0 .787055-.2068966 1.4498382-.6413794 1.9883495-.4344827.5385114-1.0137931.9113269-1.7793103 1.1598706l3.0413793 4.9915857h-3.2896552zm-.1034482-8.0983819c0-.2692556-.062069-.4970873-.1655173-.6627831-.1241379-.1656958-.2689655-.3106796-.4551724-.3935275-.1862069-.1035599-.3931034-.1656958-.6206896-.1864078-.2275863-.020712-.4344828-.041424-.662069-.041424h-1.5103448v2.7132687h1.3448275c.2275863 0 .4758621-.020712.7034483-.062136.2482759-.0414239.4551724-.1035598.662069-.2071197.2068965-.1035599.3517241-.2485437.4965517-.4349515.1448276-.1864077.2068966-.4142394.2068966-.7249191z" />
        <path d="m81.1448276 45.587055v-11.6608414h2.8344827v11.6608414z" />
        <path d="m98.8965517 45.587055.062069-8.2433657h-.0413793l-3.0206897 8.2433657h-1.9655172l-2.9379311-8.2433657h-.0413793l.062069 8.2433657h-2.6689655v-11.6608414h3.9931034l2.6482759 7.4770226h.062069l2.5448275-7.4770226h4.0758626v11.6608414z" />
        <path d="m112.117241 36.9501618c-.248275-.3106796-.55862-.5592233-.951724-.7249191-.393103-.1864077-.765517-.2692556-1.117241-.2692556-.186207 0-.372414.0207119-.558621.0414239-.186207.041424-.372414.1035599-.517241.1864078-.144828.1035598-.289655.2071197-.393104.3521035-.103448.1449838-.165517.3313916-.165517.5592233 0 .2071198.041379.3521036.124138.4970874.082759.1242719.206897.2485437.351724.3521036.165517.1035598.351724.1864077.558621.2692556.206896.0828479.475862.1656958.744827.2485437.393104.1242719.806897.2692557 1.24138.4349515s.806896.3728155 1.15862.6420712c.351725.2692556.64138.5799353.868966.9734628s.351724.8699029.351724 1.4498381c0 .6627832-.124138 1.2427185-.372414 1.7398059s-.57931.8906149-.993103 1.2012945-.889655.5592233-1.427586.7042071-1.096552.2278317-1.655173.2278317c-.827586 0-1.634483-.1449838-2.420689-.4349515-.786207-.2899676-1.427587-.7042071-1.944828-1.2427184l1.841379-1.8847896c.289655.3521035.662069.6420712 1.117242.8906148.475862.2278318.931034.3521036 1.386207.3521036.206896 0 .413793-.020712.6-.0621359.206896-.041424.372413-.1242719.517241-.2071198.144828-.1035598.268965-.2278317.351724-.3935275s.124138-.3521035.124138-.6006472c0-.2278317-.062069-.4142395-.165517-.5592233s-.268966-.2899677-.475862-.4142395c-.206897-.1242718-.455173-.2485437-.765518-.3521036-.310344-.1035598-.641379-.2071197-1.034482-.3313915-.372414-.1242719-.744828-.2692557-1.096552-.4349515s-.682759-.3728155-.951724-.6420712c-.289655-.2485437-.496552-.5799353-.682759-.9320388-.165517-.3728156-.248276-.807767-.248276-1.3462784 0-.6420711.124138-1.2012944.393104-1.6569579.268965-.4556634.6-.8491909 1.034483-1.1391586.434482-.2899676.910344-.5177993 1.448275-.6420712.537931-.1449838 1.075863-.2071197 1.634483-.2071197.662069 0 1.324138.1242719 2.006897.3521036.682758.2485437 1.282758.6006472 1.8 1.0770226z" />
        <path d="m129.6 39.7048544c0 .9113268-.165517 1.7605178-.475862 2.5268608-.310345.7663431-.765517 1.4084142-1.324138 1.9676376-.558621.5385113-1.241379.9527508-2.006897 1.2634304-.786206.2899676-1.613793.4349514-2.524137.4349514-.910345 0-1.758621-.1449838-2.524138-.4349514-.765518-.2899677-1.448276-.7249191-2.006897-1.2634304-.558621-.5385114-1.013793-1.2012945-1.324138-1.9676376-.310345-.766343-.475862-1.594822-.475862-2.5268608 0-.9320389.165517-1.7812298.475862-2.5268609.310345-.745631.765517-1.3877022 1.324138-1.9262136.558621-.5177993 1.241379-.9320388 2.006897-1.2220064.765517-.2899677 1.613793-.4349515 2.524138-.4349515.910344 0 1.75862.1449838 2.524137.4349515.786207.2899676 1.448276.6834951 2.006897 1.2220064.558621.5177994 1.013793 1.1598706 1.324138 1.9262136.331034.7456311.475862 1.594822.475862 2.5268609zm-3 0c0-.4970874-.082759-.9734628-.248276-1.4084143-.165517-.4349514-.393103-.807767-.682758-1.1184466-.289656-.3106796-.64138-.5592233-1.055173-.745631-.413793-.1864078-.868965-.2692557-1.365517-.2692557s-.951724.0828479-1.344828.2692557c-.413793.1864077-.765517.4349514-1.055172.745631s-.517242.6834952-.682759 1.1184466c-.165517.4349515-.248276.9113269-.248276 1.4084143 0 .5177993.082759 1.0148867.248276 1.4498382.165517.4349514.393104.8284789.682759 1.1391585s.641379.5592233 1.034483.7456311c.413793.1864078.848275.2692557 1.344827.2692557s.951724-.0828479 1.344828-.2692557c.413793-.1864078.765517-.4349515 1.055172-.7456311s.517242-.7042071.682759-1.1391585c.206896-.4349515.289655-.9320389.289655-1.4498382z" />
        <path d="m140.772414 45.587055-4.675862-7.6220065h-.04138l.062069 7.6220065h-2.731034v-11.6608414h3.206896l4.675863 7.6012945h.041379l-.062069-7.6012945h2.731034v11.6608414z" />
        <path d="m8.46206897.5592233 3.04137933 5.2815534c.3310345.57993527.3310345 1.28414239 0 1.86407767l-3.04137933 5.28155343c-.3724138.6213592-1.26206897.6420712-1.63448276 0l-3.10344828-5.28155343c-.33103448-.57993528-.33103448-1.30485437 0-1.88478965l3.08275862-5.26084142c.37241379-.6420712 1.28275862-.62135922 1.65517242 0z" />
        <path d="m3.88965517 14.0427184 1.94482759 3.3760518c.33103448.5799353.33103448 1.2841424 0 1.8640777l-1.94482759 3.3760518c-.37241379.6213592-1.26206896.6420712-1.63448276 0l-1.98620689-3.3760518c-.33103449-.5799353-.33103449-1.3048544 0-1.8847896l1.96551724-3.3760518c.37241379-.6213593 1.28275862-.6213593 1.65517241.0207119z" />
        <path d="m67.3241379 18.0401294c-6.2068965-10.58381872-18.4965517-16.79741095-31.3448276-14.62265367-6.8689655 1.15987055-12.8482758 4.61877022-17.275862 9.44466017l3.7034483.9320389c.475862.1242718.7034482.6627831.4551724 1.0977346l-5.4827587 9.4860841c-.2068965.3521036-.6413793.5385114-1.0344827.4349515l-4.0965517-1.0977346c.1655172-.4970874.3517241-.9734628.537931-1.4498382-1.8206897 4.5359223-2.5655172 9.568932-1.9655172 14.7055016 2.0275862 17.2530744 17.9172413 29.2245955 34.9034482 26.5734628 2.6896552-.4142395 5.2344828-1.1805826 7.5931035-2.2368932.8689655-.3728156 1.2206896-1.4084143.7862069-2.2576052l-3.5793104-7.0627832c-.3724138-.7456311-1.2413793-1.0770226-2.0275862-.787055-.9103448.3313916-1.8620689.6006472-2.8344827.807767-11.6896552 2.5061489-6-2.402589-9.0206897-5.0744337-1.6965517-1.4912621-5.7724138-2.7961165-8.9172414-2.9203883-7.0551724-.2899677-6.9724138-2.8582524-5.6275862-5.7165049.9310345-1.9883495.4965517-3.0860841.6827586-3.9352751.1862069-.8699029.2068966-1.1805825.6206897-1.6362459.6413793-.6627832.1241379-1.5533981-.4137931-2.3197411-1.262069-1.7398058-.9310345-2.8582524.8275862-3.231068 1.737931-.3521035 5.3172414-2.2161812 5.5655172-3.6867314.2689656-1.4498381 2.5448276-7.4563106 7.4068966-8.6368932 8.0068965-1.9469255 16.2413793.5592233 20.8344828 8.3883496.4344827.766343 1.3862069 1.0563106 2.1724137.6420711l6.8482759-3.5003236c.8482759-.4556634 1.1586207-1.5119741.6827586-2.3404531z" />
      </g>
    </svg>
  );
}
