import React from 'react';
import PropTypes from 'prop-types';

import css from './styles.scss';

export default function Header(props) {
  return (
    <div className={css.header}>
      <span className={css.headerTitle}>{props.headerText}</span>
    </div>
  );
}

Header.propTypes = {
  headerText: PropTypes.string,
};
