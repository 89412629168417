import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import InfoOutlined from '@material-ui/icons/InfoOutlined';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Montserrat';
  margin: 25px 60px 0px;
`;

export const Overview = styled.div`
  font-weight: 700;
  font-size: 28px;
  color: #1d1e2b;
  padding-left: 15px;
  margin-bottom: 12px;
`;

export const StripContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #3a3c85;
  border-radius: 8px;
  height: 100px;
  align-items: center;
  padding-left: 18px;
  margin-top: 6px;
`;

export const IconContainer = styled.div`
  background-color: rgba(244, 245, 255, 0.2);
  width: 60px;
  height: 60px;
  border-radius: 8px;
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotateY(180deg);
`;

export const IconImg = styled.img`
  width: 28px;
  background-size: contain;
  object-fit: contain;
  opacity: 1;
`;

export const RightContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  height: 60px;
  justify-content: space-between;
  margin: 0px 5px 0px 25px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  height: 60px;
  margin-right: 10px;
  justify-content: space-between;
`;

export const LargeLabel = styled.span`
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
  line-height: 24px;
`;

export const MediumLabel = styled.span`
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  line-height: 20px;
`;

export const Row = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const SmallLabel = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 14.63px;
  color: #a9acc0;
`;

export const TinyLabel = styled.span`
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  color: #a9acc0;
`;

export const HighlightLabel = styled.span`
  background-color: #464ac9;
  border-radius: 4px;
  color: #ffffff;
  padding: 3px;
  font-size: 12px;
  margin-left: 5px;
  line-height: 14.63px;
`;

export const HSplit = styled.div`
  height: 100%;
  width: 1px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0.5) 46.63%,
    rgba(255, 255, 255, 0.1) 100%
  );
`;

export const DropdownButton = styled(Button)`
  font-weight: 500;
  font-size: 12px;
  line-height: 14.63px;
  color: #ffffff;
  text-transform: none;
  text-align: left;
`;

export const StyledTipIcon = styled(InfoOutlined)`
  font-size: 14px;
  color: #a9acc0;
`;

export const PromptContainer = styled.div`
  display: flex;
  position: relative;
  margin-left: 2px;
  padding-bottom: 1px;
`;

export const HintContainer = styled.div`
  width: 284px;
  padding: 16px;
  background: rgba(29, 30, 43, 0.8);
  border-radius: 4px;
  display: flex;
  position: absolute;
  left: -129px;
  bottom: 40px;
  box-sizing: border-box;
  &:before {
    width: 0;
    height: 0;
    border-bottom: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid rgba(29, 30, 43, 0.8);
    border-left: 10px solid transparent;
    content: '';
    position: absolute;
    top: 100%;
    left: 45%;
  }
  & div {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
  }
`;
