import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Drawer } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import NotificationPage from 'components/unique/NotificationPage';
import css from './NotificationDrawer.scss';

export default function NotificationDrawer(props) {
  const { showMenu, toggleMenu } = props;

  return (
    <Drawer
      variant="temporary"
      anchor="left"
      open={showMenu}
      onClose={() => toggleMenu(false)}
      className={classNames(css.drawer, { [css.disableClick]: !showMenu })}
    >
      <div className={css.notificationPageWrapper}>
        <NotificationPage showBackButton={false} closeDrawer={() => toggleMenu(false)} />
        <CloseIcon className={css.closeIcon} onClick={() => toggleMenu(false)} />
      </div>
    </Drawer>
  );
}

NotificationDrawer.propTypes = {
  showMenu: PropTypes.bool,
  toggleMenu: PropTypes.func,
};
