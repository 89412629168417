import { Tab as ReactTab } from 'react-tabs';
import { Body } from 'components/atoms/typography';
import React from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';

import css from './style.scss';

export default function Tab(props) {
  const passThroughProps = omit(props, Object.keys(Tab.propTypes));
  return (
    <ReactTab
      className={css.tab}
      data-test-id={props.dataTestId}
      selectedClassName={css.selectedTab}
      {...passThroughProps}
    >
      {props.title && (
        <Body bold className={css.tabTitle}>
          {props.title}
          {props.count ? (
            <span className={css.circle}>
              <span className={css.number}>{props.count}</span>
            </span>
          ) : null}
        </Body>
      )}
    </ReactTab>
  );
}

Tab.propTypes = {
  title: PropTypes.string,
  dataTestId: PropTypes.string,
  count: PropTypes.number,
};

Tab.tabsRole = 'Tab';
