/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

export default function Planet({ className }) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="134" height="66" viewBox="0 0 134 66">
      <g fill="none" fillRule="evenodd" transform="translate(-2)">
          <path fill="#63BACE" fillRule="nonzero" d="M95.613 44.009c-.288-.058-.559.179-.606.527-.047.35.148.678.435.735 6.863 1.367 19.236.457 19.201.106-.034-.35-12.26-.02-19.03-1.368zM48.047 15.868c.27.04.461.323.427.634-.033.312-.28.532-.549.494-12.306-1.776-31.374-.826-31.447-1.128-.073-.302 13.524-2.325 31.57 0z"/>
          <path fill="#63BACE" d="M21.332 4.905l-1.369-1.37 1.37-1.369a.302.302 0 0 0-.424-.423l-1.37 1.37-1.373-1.374a.302.302 0 0 0-.423.423l1.373 1.374-1.373 1.373a.302.302 0 0 0 .423.423L19.54 3.96l1.37 1.37a.302.302 0 0 0 .423-.424zM135.3 29.872l-1.376-1.376 1.369-1.37a.3.3 0 0 0-.024-.392.3.3 0 0 0-.393-.024l-1.37 1.369-1.321-1.321a.296.296 0 0 0-.302-.11.306.306 0 0 0-.23.23.296.296 0 0 0 .109.303l1.321 1.322-1.373 1.373a.3.3 0 0 0 .024.393.3.3 0 0 0 .393.024l1.377-1.377 1.376 1.376a.3.3 0 0 0 .394-.03.3.3 0 0 0 .03-.394l-.004.004z"/>
          <path fill="#BABBC3" d="M119.352 15.885l-1.405-1.405 1.352-1.352a.3.3 0 0 0-.03-.394.3.3 0 0 0-.393-.03l-1.353 1.353-1.337-1.338a.3.3 0 0 0-.394.024.3.3 0 0 0-.023.393l1.337 1.338-1.345 1.345a.296.296 0 0 0-.109.302.306.306 0 0 0 .23.23.296.296 0 0 0 .303-.109l1.345-1.345 1.405 1.405a.3.3 0 0 0 .393-.023.3.3 0 0 0 .024-.394zM29.332 42.91l-1.349-1.348 1.347-1.346a.293.293 0 0 0 .004-.418.298.298 0 0 0-.417.004L27.57 41.15l-1.352-1.353a.298.298 0 1 0-.418.418l1.353 1.352-1.354 1.354a.293.293 0 0 0 0 .414.286.286 0 0 0 .413 0l1.354-1.355 1.349 1.35a.298.298 0 0 0 .417-.418z"/>
          <path fill="#FDA502" d="M123.234 53.823l-1.313-1.314 1.377-1.377a.3.3 0 0 0-.024-.393.3.3 0 0 0-.393-.024l-1.381 1.381-1.384-1.384a.3.3 0 0 0-.394.03.3.3 0 0 0-.03.394l1.384 1.384-1.361 1.361a.3.3 0 0 0 .024.393.3.3 0 0 0 .393.024l1.365-1.365 1.314 1.313c.07.093.188.136.303.11a.306.306 0 0 0 .23-.23.296.296 0 0 0-.11-.303zM6.297 22.87l-1.374-1.374 1.37-1.37a.302.302 0 0 0-.424-.423L4.5 21.073l-1.37-1.37a.302.302 0 0 0-.423.424L4.08 21.5l-1.373 1.373a.302.302 0 0 0 .424.424l1.373-1.374 1.37 1.37a.302.302 0 0 0 .423-.424z"/>
          <circle cx="43" cy="3" r="1" fill="#207A8D"/>
          <circle cx="102" cy="52" r="1" fill="#207A8D"/>
          <ellipse cx="70.283" cy="64.5" fill="#F2F3F6" rx="31" ry="1.5" transform="matrix(1 0 0 -1 0 129)"/>
          <ellipse cx="73.022" cy="27.5" fill="#BABCC5" rx="27.413" ry="27.5"/>
          <ellipse cx="73.022" cy="27.5" fill="#E3E7ED" stroke="#F7F8FA" rx="26.913" ry="27"/>
          <path stroke="#F4F6FA" strokeLinecap="round" strokeWidth="3" d="M69.79 5.84l1.582-.418"/>
          <path fill="#63BACE" d="M46.816 19.064a42.09 42.09 0 0 0-.592 3.943c-8.748-.801-14.376-.69-14.511.514-.244 2.165 17.372 7.045 39.346 10.9 21.973 3.854 39.985 5.224 40.228 3.058.119-1.05-3.971-2.741-10.725-4.644.225-1.08.366-2.403.417-4.007 15.892 4.19 26.334 8.561 26.014 11.406-.488 4.33-25.728 3.483-56.376-1.893-30.648-5.376-55.097-13.245-54.61-17.575.342-3.034 12.827-3.526 30.809-1.702z"/>
          <path fill="#212851" d="M72.5 23a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm16 1a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm-8.749 6.45c-1.556-.277-2.371-1.758-2.445-4.442l6.705.126c-.127 3.47-1.547 4.908-4.26 4.317z"/>
          <path stroke="#F4F6FA" strokeLinecap="round" strokeWidth="3" d="M56 15.318c2.09-3.18 7.038-6.473 9.142-7.318"/>
      </g>
  </svg>
  );
}

Planet.propTypes = {
  className: PropTypes.string,
};