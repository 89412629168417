/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import * as Logger from '@crimson-education/browser-logger';

/**
 * Adds Tracking of Page History Changes.
 * @param history History Object to Track.
 */
export function listenToHistory(history: any) {
  if (history) {
    history.listen((location: any) => {
      const pathName = location.pathname;
      Logger.setPageName(pathName);
    });
  }
}

/**
 * High Order Component to Track Route Matching Information from. e.g. /users/:id.
 * Used in some Trackers.
 * @param WrappedComponent React Component to Render.
 */
export function trackReactRoute(WrappedComponent: (props: any) => JSX.Element) {
  let tracked = false;
  const RouteTracker = (props: any) => {
    // This is because it thinks this is a callback, when it is in fact the component.
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (!tracked && props.match) {
        const route = props.match.path;
        Logger.setRouteName(route);
        tracked = true;
      }
    }, [props.match]);

    return <WrappedComponent {...props} />;
  };
  return RouteTracker;
}
