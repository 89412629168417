import createReducer from 'utils/createReducer';
import Immutable from 'immutable';
import { toasterMessages } from '@crimson-education/common-config';
import billingService from 'graphql/billingService';
import { formSuccess, formFail } from 'ducks/meta';
import { getUserWithId } from 'selectors/user';

const FETCH_ALL_BILLING_COUNTRIES_SUCCESS = 'billing/FETCH_ALL_BILLING_COUNTRIES_SUCCESS';
const FETCH_USER_BILLING_SUCCESS = 'billing/FETCH_USER_BILLING_SUCCESS';
const EDIT_USER_BILLING_SUCCESS = 'billing/EDIT_USER_BILLING_SUCCESS';
const EDIT_USER_SUBJECT_PAY_RATES_SUCCESS = 'billing/EDIT_USER_SUBJECT_PAY_RATES_SUCCESS';
const FETCH_TUTOR_PAY_RATES_SUCCESS = 'billing/FETCH_TUTOR_PAY_RATES_SUCCESS';

const defaultState = Immutable.fromJS({
  countries: [],
  users: {},
});

export default createReducer(defaultState, {
  [FETCH_ALL_BILLING_COUNTRIES_SUCCESS]: (state, { payload: countries }) => {
    return state.set('countries', Immutable.fromJS(countries));
  },
  [FETCH_USER_BILLING_SUCCESS]: (state, { payload: { userId, userBilling, userSubjectPayRate } }) => {
    const payRates = {};
    userSubjectPayRate &&
      userSubjectPayRate.forEach((rate) => {
        payRates[rate.subjectId] = rate;
      });

    const user = Object.assign({ userId }, userBilling, { userSubjectPayRate: payRates });
    return state.setIn(['users', user.userId], Immutable.fromJS(user));
  },
  [FETCH_TUTOR_PAY_RATES_SUCCESS]: (state, { payload: { subjectId, subjectAllPayRates } }) => {
    if (!subjectAllPayRates) {
      return state;
    }

    return state.withMutations((state) => {
      subjectAllPayRates.forEach((rate) => {
        state.mergeIn(['users', rate.userId, 'userSubjectPayRate', subjectId], Immutable.fromJS(rate));
      });
    });
  },
  [EDIT_USER_BILLING_SUCCESS]: (state, { payload: { editUserBilling } }) => {
    const { id: userBillingId, userId, billingCountryId, taxRate, businessNumber } = editUserBilling;

    return state.mergeIn(['users', userId], {
      userBillingId,
      billingCountryId,
      taxRate,
      businessNumber,
    });
  },
  [EDIT_USER_SUBJECT_PAY_RATES_SUCCESS]: (state, { payload: { userId, editUserSubjectPayRates } }) => {
    const payRates = {};
    editUserSubjectPayRates.forEach((rate) => {
      payRates[rate.subjectId] = rate;
    });

    return state.mergeIn(['users', userId, 'userSubjectPayRate'], Immutable.fromJS(payRates));
  },
});

export function fetchAllBillingCountriesSuccess(payload) {
  return { type: FETCH_ALL_BILLING_COUNTRIES_SUCCESS, payload };
}

export function fetchUserBillingSuccess(payload) {
  return { type: FETCH_USER_BILLING_SUCCESS, payload };
}

export function editUserBillingSuccess(payload) {
  return { type: EDIT_USER_BILLING_SUCCESS, payload };
}

export function editUserSubjectPayRatesSuccess(payload) {
  return { type: EDIT_USER_SUBJECT_PAY_RATES_SUCCESS, payload };
}

export function fetchTutorPayRatesForSubjectSuccess(payload) {
  return { type: FETCH_TUTOR_PAY_RATES_SUCCESS, payload };
}

export function fetchAllBillingCountries() {
  return (dispatch) =>
    billingService
      .fetchAllBillingCountries()
      .then(({ billingCountries }) => dispatch(fetchAllBillingCountriesSuccess(billingCountries)));
}

export function fetchUserBilling(userId) {
  return (dispatch, getState) =>
    billingService
      .fetchUserBilling(userId)
      .then(({ userBilling, userSubjectPayRate }) =>
        dispatch(fetchUserBillingSuccess({ userId, userBilling, userSubjectPayRate })),
      )
      .catch(() => {
        const state = getState();
        const user = getUserWithId(userId)(state).get('firstName');
        dispatch(formFail(toasterMessages.billingInfoNotFetched(user)));
      });
}

export function editUserBilling({ userId, billingCountryId, taxRate, businessNumber }) {
  return (dispatch, getState) =>
    billingService
      .editUserBilling({ userId, billingCountryId, taxRate, businessNumber })
      .then(({ editUserBilling }) => {
        dispatch(formSuccess(toasterMessages.billingCountryUpdated()));
        dispatch(editUserBillingSuccess({ editUserBilling }));
      })
      .catch(() => {
        const state = getState();
        const user = getUserWithId(userId)(state).get('firstName');
        dispatch(formFail(toasterMessages.billingCountryNotUpdated(user)));
      });
}

export function editUserSubjectPayRates({ userId, subjectsPayRates }) {
  return (dispatch, getState) =>
    billingService
      .editUserSubjectPayRates(subjectsPayRates)
      .then(({ editUserSubjectPayRates }) => {
        dispatch(formSuccess(toasterMessages.billingPayRatesUpdated()));
        dispatch(editUserSubjectPayRatesSuccess({ userId, editUserSubjectPayRates }));
      })
      .catch(() => {
        const state = getState();
        const user = getUserWithId(userId)(state).get('firstName');
        dispatch(formFail(toasterMessages.billingPayRatesNotUpdated(user)));
      });
}

export function fetchTutorPayRatesForSubject(subjectId) {
  return (dispatch) =>
    billingService
      .fetchTutorPayRatesForSubject(subjectId)
      .then(({ subjectAllPayRates }) => {
        dispatch(fetchTutorPayRatesForSubjectSuccess({ subjectId, subjectAllPayRates }));
      })
      .catch(() => {
        dispatch(formFail(toasterMessages.billingPayRatesForSubjectFailed()));
      });
}
