import React, { useState } from 'react';

import Header from 'components/molecules/CGAHeader';

import ReportDataInput from './ReportDataInput';
import ReportDisplay from './ReportDisplay';

import css from '../styles.scss';

const DaVinciReportsViewPage = () => {
  const [reportState, setReportState] = useState();

  return (
    <div>
      <Header title="Da Vinci Report Generation" />
      <div className={css.daVinciPage}>
        <ReportDataInput reportState={reportState} setReportState={setReportState} />
        <ReportDisplay reportState={reportState} />
      </div>
    </div>
  );
};

export default DaVinciReportsViewPage;
