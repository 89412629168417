import React from 'react';
import PropTypes from 'prop-types';
import { Body, Caption } from 'components/atoms/typography';
import Card from 'components/molecules/Card';
import { formatCurrencyValue, formatPeriodDate, formatDate, formatDuration } from 'utils/invoiceUtils';
import InvoiceStatusTag from '../../InvoiceStatusTag';

import css from './styles.scss';
import { handleEnter } from '../../../../../utils/accessibility';

export default function InvoiceListItem(props) {
  const { invoice, selected, loginUserTimezone, onSelect, showTutor } = props;

  const {
    statusDate,
    periodStart,
    grossPay,
    totalHours,
    completedLessons,
    status,
    tutor,
    totalLessons,
    periodEnd,
    billingCountry,
  } = invoice;

  return (
    <Card selected={selected}>
      <div
        data-ga-label="Invoice Card"
        className={css.invoiceCard}
        onClick={onSelect}
        onKeyDown={handleEnter(onSelect)}
        role="button"
        tabIndex={0}
      >
        <div className={css.invoiceCardBody}>
          {showTutor && (
            <Body bold className={css.tutorName}>
              {tutor.firstName} {tutor.lastName}
            </Body>
          )}
          <Body bold className={css.invoicePeriod}>
            {formatPeriodDate(periodStart, periodEnd)}
          </Body>
          <div className={css.invoiceHours}>
            <Caption className={css.totalHours}>{formatDuration(totalHours)}</Caption>
            <div className={css.lessonStatus}>
              {completedLessons === totalLessons ? (
                <i className={`zmdi zmdi-check ${css.iconDone}`} />
              ) : (
                <i className={`zmdi zmdi-alert-triangle ${css.iconIncomplete}`} />
              )}
              <Caption className={css.lessons}>
                {completedLessons} of {totalLessons} completed
              </Caption>
            </div>
          </div>
        </div>
        <div className={css.invoiceCardFooter}>
          <div className={css.invoiceStatus}>
            <InvoiceStatusTag status={status} />
            {status !== 'PENDING' && <Caption>{formatDate(statusDate, loginUserTimezone, 'D MMM YYYY')}</Caption>}
          </div>
          <div className={css.invoiceValue}>
            <Body bold>
              {formatCurrencyValue({
                currency: billingCountry.currency,
                amount: grossPay,
              })}
            </Body>
          </div>
        </div>
      </div>
    </Card>
  );
}

InvoiceListItem.propTypes = {
  invoice: PropTypes.object,
  selected: PropTypes.bool,
  loginUserTimezone: PropTypes.string,
  onSelect: PropTypes.func,
  showTutor: PropTypes.bool,
};
