import styled from 'styled-components';
import InfoOutlined from '@material-ui/icons/InfoOutlined';

export const Title = styled.div`
  font-weight: 700;
  font-size: 32px;
  line-height: 29px;
  color: #1d1e2b;
  font-family: 'Montserrat';
`;

export const SubTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #73747d;
  margin-left: 4px;
  font-family: 'Montserrat';
`;

export const PlaceholderText = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  font-family: 'Montserrat';
  color: #a9acc0;
`;

export const StyledErrorIcon = styled(InfoOutlined)`
  font-size: 22px;
  color: #464ac9;
`;

export const PromptContainer = styled.div`
  display: flex;
  position: relative;
  margin-left: 8px;
  padding-bottom: 1px;
`;

export const HintContainer = styled.div`
  width: 284px;
  padding: 16px;
  background: rgba(29, 30, 43, 0.8);
  border-radius: 4px;
  display: flex;
  position: absolute;
  left: -127px;
  bottom: 40px;
  box-sizing: border-box;
  &:before {
    width: 0;
    height: 0;
    border-bottom: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid rgba(29, 30, 43, 0.8);
    border-left: 10px solid transparent;
    content: '';
    position: absolute;
    top: 100%;
    left: 45%;
  }
  & div {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
  }
`;
