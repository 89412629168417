import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import NotificationContainer from 'components/unique/Notification';
import NavIconLink from 'components/generic/NavIconLink';
import { LogoColored as LogoIcon } from 'components/atoms/graphics';
import GlobalMessageBanner from 'components/molecules/GlobalMessageBanner';
import css from './styles.scss';

export default function TopNav(props) {
  const { className, toggleMenu } = props;

  return (
    <React.Fragment>
      <div data-ga-category="Top Nav" className={classNames(className, css.topBar)}>
        <div className={css.topNav}>
          <div className={css.hamburger}>
            <NavIconLink
              mobile
              iconClass="zmdi-menu"
              text="Menu"
              onClickHandler={() => toggleMenu(true)}
              dataTestId="menu"
            />
          </div>
          <div className={css.logo}>
            <Link to="/" onClick={() => toggleMenu(false)}>
              <LogoIcon className={css.logoIcon} height={40} width={40} />
            </Link>
          </div>
          <NotificationContainer className={css.navItem} mobile />
        </div>
        <GlobalMessageBanner isMobile={props.isMobile} />
      </div>
      <div className={css.mobileSpacer} />
    </React.Fragment>
  );
}

TopNav.displayName = 'TopNav';

TopNav.propTypes = {
  className: PropTypes.string,
  toggleMenu: PropTypes.func,
  isMobile: PropTypes.bool,
};
