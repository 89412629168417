import React from 'react';
import PropTypes from 'prop-types';
import Avatar from 'components/molecules/Avatar';
import Body from 'components/atoms/typography/Body';

import css from './style.scss';

export default function InfoPanel(props) {
  const { firstName, lastName, colourIndex, image, textTop, textBottom, userId } = props;

  return (
    <div className={css.infoPanel}>
      <Avatar
        firstName={firstName}
        lastName={lastName}
        colourIndex={colourIndex}
        image={image}
        size="normal"
        showStudentType
        userId={userId}
      />
      <div className={css.fullName}>
        <Body bold>{`${firstName} ${lastName}`}</Body>
      </div>
      <div className={css.text}>
        <Body>{textTop}</Body>
      </div>
      <div className={css.text}>
        <Body>{textBottom}</Body>
      </div>
    </div>
  );
}

InfoPanel.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  image: PropTypes.string,
  textTop: PropTypes.string.isRequired,
  textBottom: PropTypes.string,
  colourIndex: PropTypes.number,
  userId: PropTypes.string.isRequired,
};
