import React, { useMemo } from 'react';
import { TextCell, StyledTableRow } from '../index.style';
import NameCell from './NameCell';
import ActionCell from './ActionCell';
import { StudentInList } from '../../../type';
import { useStaffDashboardContext } from '../../../helper';
import { getDeadlineTextAndColor } from '../../../../util';
type TableRowProps = {
  student: StudentInList;
};
const TableRow = ({ student }: TableRowProps) => {
  const { appCountByRound, totalAppCount, submittedAppCount, resultReceivedCount, upcomingAppId } = student;
  const { state } = useStaffDashboardContext();
  const app = useMemo(() => state.applications.find((o) => o.id === upcomingAppId), [
    state.applications,
    upcomingAppId,
  ]);
  const { color, text } = useMemo(() => (!app ? { color: undefined, text: undefined } : getDeadlineTextAndColor(app)), [
    app,
  ]);
  return (
    <StyledTableRow>
      <NameCell student={student} />
      <TextCell>{appCountByRound.map((o) => `${o.count} ${o.round}`).join(',')}</TextCell>
      <TextCell>{`${submittedAppCount}/${totalAppCount}`}</TextCell>
      <TextCell>{resultReceivedCount}</TextCell>
      <TextCell style={{ color }}>{text || '-'}</TextCell>
      <ActionCell student={student} />
    </StyledTableRow>
  );
};

export default TableRow;
