import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import componentKeys from 'constants/componentKeys';

import {
  selectUserPermissions,
  getBookingErrors,
  getModalState,
  getBookingWithPagination,
  getMetaItem,
} from 'selectors/meta';

import { getLoginUser, getBookingAs, getPeopleForBookingWith } from 'selectors/user';

import { fetchPeopleForBookingWith } from 'ducks/user';

import { makeBooking, editBooking, makeRecurringBookings } from 'ducks/booking';
import { clearBookingError, resetModal } from 'ducks/meta';

import RequestBookingModalWrapper from './RequestBookingModalWrapper';

export default withRouter(
  connect(
    (state) => ({
      loginUser: getLoginUser(state),
      loginUserPermissions: selectUserPermissions(state),
      modalState: getModalState(state),
      bookingError: getBookingErrors(state),
      bookAsUser: getBookingAs(state),
      bookingWithUsers: getPeopleForBookingWith(state).toList(),
      bookingWithPagination: getBookingWithPagination(state),
      sessionRedirectId: getMetaItem(componentKeys.SESSION_REDIRECT_ID)(state),
    }),
    (dispatch) => ({
      clearBookingErrors: () => dispatch(clearBookingError()),
      resetModal: () => dispatch(resetModal()),
      requestBooking: (booking) => dispatch(makeBooking(booking)),
      requestRecurringBookings: (bookings) => dispatch(makeRecurringBookings(bookings)),
      editBooking: (booking) => dispatch(editBooking(booking)),
      loadMoreContacts: (filter, pagination) => dispatch(fetchPeopleForBookingWith(filter, pagination)),
    }),
  )(RequestBookingModalWrapper),
);
