import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Acl, PermissionTypes, roleTypes } from '@crimson-education/common-config';
import { fetchAllClients } from 'ducks/contract';
import { selectUserRoles, selectUserPermissions, selectUserProfile } from 'selectors/meta';
import { hasNewFriend } from 'selectors/globalState';

import UserQueryContainer from 'components/unique/UserQuery';
import { columnNames } from 'components/unique/UserQuery/UserQueryTable/columnMap';
import AuthorisationCheck from 'components/wrappers/AuthorisationCheck';
import Header from 'components/molecules/Header';
import Students from 'components/unique/Students';
import { Tab, Tabs, TabList, TabPanel } from 'components/molecules/ReactTabs';
import FriendsQuery from '../Contacts/FriendsQuery';
import css from './styles';

function getViewableTabs(userId, userRoles) {
  const studentTabs = [
    {
      hasAccess: Acl.isStrategist(userRoles),
      tabName: 'As Strategist',
      component: (
        <UserQueryContainer
          userId={userId}
          activeUsers
          fixedFilters={{
            principalRole: roleTypes.STRATEGIST,
            roles: [roleTypes.STUDENT],
            related: true,
          }}
          columnsToShow={[
            columnNames.ProfileColumn,
            columnNames.UserSince,
            columnNames.StudentPackageSubjects,
            columnNames.Location,
            columnNames.ApplicationYear,
          ]}
          filtersToShow={[]}
        />
      ),
    },
    {
      hasAccess: Acl.isCaseManager(userRoles),
      tabName: 'As Student Success Manager',
      component: (
        <UserQueryContainer
          userId={userId}
          activeUsers
          fixedFilters={{
            principalRole: roleTypes.CASE_MANAGER,
            roles: [roleTypes.STUDENT],
            related: true,
          }}
          columnsToShow={[
            columnNames.ProfileColumn,
            columnNames.UserSince,
            columnNames.StudentPackageSubjects,
            columnNames.Location,
            columnNames.ApplicationYear,
          ]}
          filtersToShow={[]}
        />
      ),
    },
    {
      hasAccess: Acl.isTutor(userRoles),
      tabName: 'As Tutor',
      component: (
        <div className={css.studentsTable}>
          <Students userId={userId} />
        </div>
      ),
    },
    {
      hasAccess: Acl.isReviewer(userRoles),
      tabName: 'As Reviewer',
      component: (
        <UserQueryContainer
          userId={userId}
          activeUsers
          fixedFilters={{
            principalRole: roleTypes.REVIEWER,
            getQueryAs: roleTypes.REVIEWER,
            roles: [roleTypes.STUDENT],
            related: true,
          }}
          columnsToShow={[
            columnNames.ProfileColumn,
            columnNames.UserSince,
            columnNames.StudentPackageSubjects,
            columnNames.Location,
            columnNames.ApplicationYear,
          ]}
          filtersToShow={[]}
        />
      ),
    },
  ];
  return studentTabs.filter((tab) => tab.hasAccess);
}

function getAvailableTabs(userId, userRoles, defaultActive) {
  const myStudentTabs = getViewableTabs(userId, userRoles);
  const allAvailableTabs = [
    {
      requiredRoles: [roleTypes.STRATEGIST, roleTypes.CASE_MANAGER, roleTypes.TUTOR, roleTypes.REVIEWER],
      tabName: 'My Students',
      component: (
        <div>
          {(myStudentTabs.length > 0 && (
            <div className={css.studentsTable}>
              <Tabs>
                <TabList className={css.tabList}>
                  {myStudentTabs.map((tab, i) => (
                    <Tab key={i} title={tab.tabName} />
                  ))}
                </TabList>
                {myStudentTabs.map((tab, i) => (
                  <TabPanel key={i}>{tab.component}</TabPanel>
                ))}
              </Tabs>
            </div>
          )) || <div className={css.noTabs}>{myStudentTabs.length === 1 && myStudentTabs[0].component}</div>}
        </div>
      ),
    },
    {
      requiredRoles: [
        roleTypes.SUPER_ADMIN,
        roleTypes.CASE_MANAGER,
        roleTypes.HEAD_TUTOR,
        roleTypes.TUTOR,
        roleTypes.STRATEGIST,
      ],
      tabName: 'My Friends',
      count: true,
      component: (
        <FriendsQuery
          defaultActive={defaultActive}
          columnsToShow={[
            columnNames.ProfileColumn,
            columnNames.UserType,
            columnNames.Location,
            columnNames.University,
            columnNames.FriendActionButton,
          ]}
        />
      ),
    },
  ];
  return allAvailableTabs.filter(
    (tab) => !tab.isDisabled && tab.requiredRoles.some((role) => Acl.checkRole(userRoles, role)),
  );
}

const MyStudentsContainer = (props) => {
  const { fetchAllClients, loginUserRoles, loggedInUser, hasNewFriend, defaultActive } = {
    defaultActive: true,
    ...props,
  };
  const tabs = getAvailableTabs(loggedInUser.get('userId'), loginUserRoles, defaultActive);
  const [tabIndex, setTabIndex] = React.useState(
    defaultActive ? 0 : tabs.findIndex((tab) => tab.tabName === 'My Friends') || 0,
  );
  const handleSelect = (index) => {
    setTabIndex(index);
  };
  React.useEffect(() => {
    if (Acl.isTutor(loginUserRoles)) {
      fetchAllClients();
    }
  }, [loggedInUser, loginUserRoles, fetchAllClients]);
  return (
    <div className={css.contents}>
      <Header title="Contacts" isBorderVisible={false} />

      {(tabs.length > 1 && (
        <Tabs selectedIndex={tabIndex} onSelect={handleSelect}>
          <TabList>
            {tabs.map((tab, i) => (
              <Tab key={i} title={tab.tabName} count={tab.count ? hasNewFriend : null} />
            ))}
          </TabList>
          {tabs.map((tab, i) => (
            <TabPanel key={i}>{tab.component}</TabPanel>
          ))}
        </Tabs>
      )) ||
        (tabs.length === 1 && tabs[0].component)}
    </div>
  );
};

MyStudentsContainer.propTypes = {
  loginUserRoles: PropTypes.array,
  loggedInUser: ImmutablePropTypes.map.isRequired,
  fetchAllClients: PropTypes.func.isRequired,
  hasNewFriend: PropTypes.number,
  defaultActive: PropTypes.bool,
};

const withAuthorisation = AuthorisationCheck(PermissionTypes.VIEW_MY_STUDENTS);

export default withAuthorisation(
  connect(
    (state) => ({
      loggedInUser: selectUserProfile(state),
      loginUserRoles: selectUserRoles(state),
      loginUserPermissions: selectUserPermissions(state),
      hasNewFriend: hasNewFriend(state),
    }),
    (dispatch) => ({
      fetchAllClients: () => dispatch(fetchAllClients()),
    }),
  )(MyStudentsContainer),
);
