import React, { useContext, useMemo } from 'react';
import TextCell from '../TextCell';
import { AppTrackerContext } from '../../viewController';
import { Application } from '../../type';
import { useRequesterUserId } from '../../helper';
import { StyledFlatpickr } from './index.style';
import { getDeadlineTextAndColor } from '../../util';
import moment from 'moment';

type DeadlineProps = {
  application: Application;
};

const Deadline = ({ application }: DeadlineProps) => {
  const { controller } = useContext(AppTrackerContext);
  const requesterUserId = useRequesterUserId();
  const onClickItem = async (value: Date[]) => {
    const selectedData = value[0];
    await controller.updateApplication(
      application.id,
      {
        deadline: moment(selectedData).utc(false).format(),
        deadlineType: 'custom_deadline',
      },
      { updatedBy: requesterUserId },
    );
  };
  const { color, formatDate } = useMemo(() => getDeadlineTextAndColor(application), [application]);
  return (
    <TextCell>
      <StyledFlatpickr
        placeholder={application.deadline || 'Select'}
        value={application.deadline}
        placeholdercolor={!!application.deadline ? undefined : '#A9ACC0'}
        textcolor={color}
        onChange={onClickItem}
        options={{
          mode: 'single',
          clickOpens: true,
          static: true,
          formatDate,
        }}
      />
    </TextCell>
  );
};

export default Deadline;
