import React from 'react';
import PropTypes from 'prop-types';
import { Notes } from 'components/atoms/graphics';
import { SubHeader, Body } from 'components/atoms/typography';
import Button from 'components/molecules/Button';
import css from './styles.scss';

export default function EmptyTaskList(props) {
  return (
    <div className={css.emptyState}>
      <Notes className={css.emptyStateIcon} />
      {props.canEdit && (
        <>
          <SubHeader className={css.emptyStateHeading}>Get organised, get more done!</SubHeader>
          <Body className={css.emptyStateBody}>
            You can quickly add new tasks and tag them to a mission here, give it a go!
          </Body>
          <Button onClick={props.onAddTask} className={css.emptyStateButton} dataTestId="taskAddNewTaskButton">
            Add a new task
          </Button>
        </>
      )}
    </div>
  );
}

EmptyTaskList.propTypes = {
  onAddTask: PropTypes.func.isRequired,
  canEdit: PropTypes.bool,
};

EmptyTaskList.defaultProps = {
  canEdit: true,
};
