import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { updateMeta, nextWeek, prevWeek } from 'ducks/meta';
import componentKeys from 'constants/componentKeys';
import { selectUserProfile, getCalScroll } from 'selectors/meta';
import getCalendarWeek from 'selectors/calendarWeek';
import { fetchBookingAsEvents } from 'ducks/user';

import CalendarGrid from './CalendarGrid';

function CalendarGridContainer(props) {
  return <CalendarGrid {...props} />;
}

CalendarGridContainer.displayName = 'CalendarGridContainer';

CalendarGridContainer.propTypes = {
  week: ImmutablePropTypes.list.isRequired,
  selectedScroll: PropTypes.number.isRequired,
  updateScroll: PropTypes.func.isRequired,
  onAvailabilityClicked: PropTypes.func.isRequired,
  onBookingClicked: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    week: getCalendarWeek(state),
    selectedScroll: getCalScroll(state),
    userProfile: selectUserProfile(state),
  }),
  (dispatch) => ({
    updateScroll: (newHeight) => dispatch(updateMeta(componentKeys.CALENDAR_SCROLL, newHeight)),
    updateScrollHeight: (newHeight) => dispatch(updateMeta(componentKeys.CALENDAR_SCROLL_HEIGHT, newHeight)),
    nextWeek: () => dispatch(nextWeek()),
    prevWeek: () => dispatch(prevWeek()),
    fetchBookingAsEvents: () => dispatch(fetchBookingAsEvents()),
  }),
)(CalendarGridContainer);
