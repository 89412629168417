import React from 'react';
import PropTypes from 'prop-types';
import { icon } from './styles.css';

const FileUpload = ({ style }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className={icon} style={style}>
    <path d="M9 16h6v-6h4l-7-7-7 7h4zm-4 2h14v2H5z" />
  </svg>
);

FileUpload.propTypes = {
  style: PropTypes.string,
};

export default FileUpload;
