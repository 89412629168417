import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './styles.scss';

export default function CGAReportThumbnailPreview(props) {
  const { title, reportDate } = props;

  return (
    <div className={classNames(css.thumbnail)}>
      <div className={classNames(css.logo, css.thumbnailItem)}>
        {' '}
        {/* [resource] cga logo */}
        <img src="/static/CGA_LogoWhite.png" alt="Crimson Global Academy" />
      </div>
      <div className={classNames(css.title, css.thumbnailItem)}>{title}</div>
      <label className={classNames(css.reportDate, css.thumbnailItem)}>{reportDate}</label>
    </div>
  );
}

CGAReportThumbnailPreview.propTypes = {
  title: PropTypes.string,
  reportDate: PropTypes.string,
};
