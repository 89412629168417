import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { selectUserProfile } from 'selectors/meta';
import { fetchPublicUsers } from 'ducks/user';
import { getCachedUserNames } from 'selectors/user';
import { fetchLessonById, editLessonById } from 'ducks/lesson';
import {
  fetchSharedFiles,
  fetchComments,
  postComment,
  postReply,
  deleteFile,
  deleteComment,
  editComment,
} from 'ducks/comment';
import { getLesson } from 'selectors/lesson';
import { getAllCommentsForLesson } from 'selectors/comment';
import lesson from 'graphql/api/lesson';
// import lessonNotificationService from 'graphql/lessonNotificationService';

import LessonDetails from './LessonDetails';

const NOT_LOADED = 'NOT_LOADED';
const LOADING = 'LOADING';
const LOADED = 'LOADED';

class LessonDetailsContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lessonLoadingState: NOT_LOADED,
      participantsLoadingState: NOT_LOADED,
    };

    this.downloadCommentFile = this.downloadCommentFile.bind(this);
    this.fetchUserInfo = this.fetchUserInfo.bind(this);
    this.loadLessonDetail = this.loadLessonDetail.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.setState({ lessonLoadingState: LOADING }, () => this.loadLessonDetail());
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { comments } = nextProps;
    const { lessonId } = nextProps.match.params;

    if (lessonId && lessonId !== this.props.match.params.lessonId) {
      this.setState(
        {
          lessonLoadingState: LOADING,
          participantsLoadingState: NOT_LOADED,
        },
        () => this.loadLessonDetail(),
      );
    }

    if (this.state.participantsLoadingState === NOT_LOADED && comments && comments.get('conversations')) {
      this.setState({ participantsLoadingState: LOADING }, () => this.fetchUserInfo(comments.get('conversations')));
    }
  }

  loadLessonDetail() {
    const { fetchAllSharedFiles, fetchLesson, fetchComments, match } = this.props;
    const { lessonId } = match.params;

    const promises = [];
    // load all comments and user info associated
    promises.push(fetchComments(lessonId));
    // load all shared files
    promises.push(fetchAllSharedFiles(lessonId));
    // load lesson details
    promises.push(fetchLesson(parseInt(lessonId, 10)));

    return Promise.all(promises).then(() => {
      this.setState({ lessonLoadingState: LOADED });
    });
  }

  downloadCommentFile(fileId) {
    const { lessonId } = this.props.match.params;

    lesson.getFileUrl(lessonId, fileId).then((url) => {
      const openedWin = window.open(url);
      if (!openedWin) {
        window.location.href = url;
      }
    });
  }

  fetchUserInfo(comments) {
    const { fetchPublicUsers, getCachedUsers } = this.props;
    const userIds = new Set();

    comments.forEach((c) => {
      const userId = c.get('user_id');
      const replies = c.get('replies');
      getCachedUsers.get(userId) || userIds.add(userId);
      replies &&
        replies.forEach((r) => {
          const userId = r.get('user_id');
          getCachedUsers.get(userId) || userIds.add(userId);
        });
    });

    return (
      userIds.size &&
      fetchPublicUsers([...userIds]).then(() => this.setState({ participantsLoaded: true, participantsLoading: false }))
    );
  }

  render() {
    const {
      loginUser,
      lesson,
      editLesson,
      comments,
      postComment,
      editComment,
      postReply,
      deleteFile,
      deleteComment,
      getCachedUsers,
      match,
    } = this.props;
    const { lessonId } = match.params;

    const { lessonLoadingState } = this.state;

    return (
      lessonLoadingState === LOADED &&
      lesson &&
      lesson.size && (
        <LessonDetails
          loginUser={loginUser}
          lessonId={lessonId}
          lesson={lesson}
          onSaveLessonInfo={(detail) => editLesson(lessonId, detail)}
          comments={comments}
          postComment={postComment}
          editComment={editComment}
          postReply={postReply}
          downloadFile={this.downloadCommentFile}
          deleteFile={deleteFile}
          deleteComment={deleteComment}
          getCachedUsers={getCachedUsers}
        />
      )
    );
  }
}

LessonDetailsContainer.propTypes = {
  match: PropTypes.object.isRequired,
  lesson: ImmutablePropTypes.map,
  loginUser: PropTypes.object,
  fetchLesson: PropTypes.func.isRequired,
  editLesson: PropTypes.func.isRequired,
  fetchComments: PropTypes.func.isRequired,
  comments: ImmutablePropTypes.map,
  postComment: PropTypes.func.isRequired,
  editComment: PropTypes.func.isRequired,
  postReply: PropTypes.func.isRequired,
  fetchAllSharedFiles: PropTypes.func.isRequired,
  deleteFile: PropTypes.func.isRequired,
  deleteComment: PropTypes.func.isRequired,
  getCachedUsers: ImmutablePropTypes.map.isRequired,
  fetchPublicUsers: PropTypes.func.isRequired,
};

export default connect(
  (state, ownProps) => ({
    loginUser: selectUserProfile(state),
    lesson: getLesson(ownProps.match.params.lessonId)(state),
    comments: getAllCommentsForLesson(ownProps.match.params.lessonId)(state),
    getCachedUsers: getCachedUserNames(state),
  }),
  (dispatch, ownProps) => ({
    fetchAllSharedFiles: () => dispatch(fetchSharedFiles(ownProps.match.params.lessonId)),
    fetchLesson: () => dispatch(fetchLessonById(ownProps.match.params.lessonId)),
    fetchComments: () => dispatch(fetchComments(ownProps.match.params.lessonId)),
    editLesson: (...args) => dispatch(editLessonById(...args)),
    postComment: (...args) => dispatch(postComment(...args)),
    editComment: (...args) => dispatch(editComment(...args)),
    postReply: (...args) => dispatch(postReply(...args)),
    deleteFile: (...args) => dispatch(deleteFile(...args)),
    deleteComment: (...args) => dispatch(deleteComment(...args)),
    fetchPublicUsers: (userIds) => dispatch(fetchPublicUsers(userIds)),
  }),
)(LessonDetailsContainer);
