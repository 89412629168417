import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import appcuesAPI from 'graphql/api/appcues';
import moment from 'moment';

export default function AppcuesLoader(props) {
  useEffect(() => {
    (async () => {
      const appcuesInfo = await appcuesAPI.fetchAppcuesInfo(props.userId);
      const sortedSessions = [...appcuesInfo.getSessionByUserId, ...appcuesInfo.getBookingsByUserId].sort((a, b) => {
        return moment(a.createdAt).diff(b.createdAt);
      });
      const assignedStudents = appcuesInfo.getAssignedStudents;

      const appcuesIdentifier = {
        name: appcuesInfo.user.fullName,
        email: appcuesInfo.user.email,
        user_roles: appcuesInfo.user.userRoles.join('|'),
        created_at: appcuesInfo.user.createdAt,
        pronoun: appcuesInfo.user.pronoun,
        bio: appcuesInfo.user.bio,
        added_applications: Boolean(appcuesInfo.getApplicationsExistByUserId),
        added_about: Boolean(
          appcuesInfo.user.gender ||
            appcuesInfo.user.primaryLanguage ||
            appcuesInfo.user.dob ||
            appcuesInfo.user.citizenships.join('|'),
        ),
        uploaded_profile_image: Boolean(appcuesInfo.user.profileImageUrl),
        added_contact_details: Boolean(
          appcuesInfo.user.city || appcuesInfo.user.phone || appcuesInfo.user.skype || appcuesInfo.user.email,
        ),
        added_key_contact: Boolean(appcuesInfo.getKeyContactsInfoByUserId),
        added_subject:
          appcuesInfo.myPackages.length > 0 &&
          appcuesInfo.myPackages[0].items &&
          appcuesInfo.myPackages[0].items.length > 0,
        added_schools: appcuesInfo.getSchoolsByUserId.length > 0,
        added_universities: appcuesInfo.getUniversitiesByUserId.length > 0,
        added_grades: appcuesInfo.getGradesByUserId.filter((g) => g !== null).length > 0,
        added_ecl: appcuesInfo.getECActivitiesByUserId.length > 0,
        taken_psychometric_test: appcuesInfo.getHexacoResultExistByUserId,
        first_session_received:
          appcuesInfo.getBookingsByUserId.filter((b) => b.participantType === 'participant' && b.status === 'tentative')
            .length === 1,
        first_session_accepted:
          appcuesInfo.getBookingsByUserId.filter((b) => b.participantType === 'participant' && b.status === 'confirmed')
            .length === 1,
        first_session_booked:
          appcuesInfo.getBookingsByUserId.filter((b) => b.participantType === 'creator').length === 1,
        first_student_allocated: Boolean(appcuesInfo.getContractsExistByUserId),
        roadmap_loaded: Boolean(appcuesInfo.getActiveRoadmapExistByUserId),
        roadmap_meeting_completed:
          appcuesInfo.getSessionByUserId.filter((s) => s.sessionType === 'Roadmap Meeting').length > 0,
        first_session_id: sortedSessions.length > 0 ? sortedSessions[0].eventId : null,
        latest_session_id: sortedSessions.length > 0 ? sortedSessions[sortedSessions.length - 1].eventId : null,
        roadmap_session_id:
          appcuesInfo.getSessionByUserId.filter((s) => s.sessionType === 'Roadmap Meeting').length > 0
            ? appcuesInfo.getSessionByUserId.filter((s) => s.sessionType === 'Roadmap Meeting')[0].eventId
            : null,
        student_id: assignedStudents.length > 0 ? assignedStudents[0] : null,
      };

      if (window.Appcues) {
        window.Appcues.identify(props.userId, appcuesIdentifier);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <></>;
}

AppcuesLoader.propTypes = {
  userId: PropTypes.string,
};
