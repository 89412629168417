import moment from 'moment';

const getPrettyDaysRemaining = (dueDate) => {
  const date = dueDate.startOf('day');
  const today = moment().startOf('day');
  if (date.isSame(today, 'day')) {
    return 'Today';
  }

  if (date.isBefore(today, 'day')) {
    return date.from(today);
  }

  return today.to(date);
};

const isDateInWarningState = (date) => {
  const today = moment().startOf('day');
  const days = today.diff(date, 'days');
  return days >= -1;
};

export { getPrettyDaysRemaining, isDateInWarningState };
