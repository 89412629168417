import React from 'react';
import ReactDOM from 'react-dom';

import { CallbackContainer } from 'components/unique/CallbackContainer';
import * as Styled from './index.style';
import { MulticolorFailed, MulticolorSuccess } from 'components/atoms/icons';

type PurchaseConfirmationProps = {
  payId: string;
  successful: boolean;
};
const failedCopy = `The payment failed due to some issues.
You can place a new order from the Crimson App or contact your assigned student success manager if you require further assistance.`;

const successCopy = `Thank you for completing the payment. The additional support will be added to your child's program immediately.
If you have any further questions, please contact your student success manager for assistance.`;

const PurchaseConfirmation = ({ successful }: PurchaseConfirmationProps) => {
  return (
    <CallbackContainer>
      <Styled.Content>
        <Styled.IconContainer>{successful ? <MulticolorSuccess /> : <MulticolorFailed />}</Styled.IconContainer>
        <Styled.Title>{successful ? 'Enrollment Successful' : 'Enrollment Failed'}</Styled.Title>
        <Styled.Body>{successful ? successCopy : failedCopy}</Styled.Body>
        <Styled.Button onClick={() => (window.location.href = '/')}>Got It</Styled.Button>
      </Styled.Content>
    </CallbackContainer>
  );
};

export const Render = (props: PurchaseConfirmationProps) => {
  ReactDOM.render(<PurchaseConfirmation {...props} />, document.getElementById('root'));
};
