import React from 'react';
import PropTypes from 'prop-types';
import Popover from 'react-popover';
import css from './styles';

export default function Tooltip(props) {
  const { target, mobile, isOpen } = props;

  return (
    <Popover {...props} isOpen={mobile ? false : isOpen} className={css.popover}>
      {target}
    </Popover>
  );
}

Tooltip.propTypes = {
  target: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  mobile: PropTypes.bool,
};
