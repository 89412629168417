import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import IconButton from 'components/molecules/IconButton';
import css from './styles.scss';
import { asButton } from '../../../../utils/accessibility';

export default class ComponentSwitcher extends Component {
  constructor(props) {
    super(props);
    this.toggleVisibility = this.toggleVisibility.bind(this);
  }

  toggleVisibility() {
    const { signalOnEdit } = this.props;
    signalOnEdit();
  }

  render() {
    const {
      dataAppCues,
      className,
      isAlternateVisible,
      toggleButtonClassName,
      toggleButtonShowOnHover,
      hideToggleButtonInEditMode,
      primary,
      alternate,
      canEdit,
      dataTestId,
      dataGaLabel,
    } = this.props;
    return (
      <div
        data-appcues-id={dataAppCues}
        className={classnames(
          css.switcherContainer,
          { [css.showtoggleButtonOnHover]: toggleButtonShowOnHover },
          className,
        )}
      >
        {canEdit && !(isAlternateVisible && hideToggleButtonInEditMode) && (
          <div
            className={classnames(css.toggleButton, toggleButtonClassName)}
            data-test-id={dataTestId}
            {...asButton(this.toggleVisibility)}
          >
            <IconButton dataGaLabel={dataGaLabel} icon="Edit" />
          </div>
        )}
        {isAlternateVisible ? alternate : primary}
      </div>
    );
  }
}

ComponentSwitcher.displayName = 'ComponentSwitcher';

ComponentSwitcher.propTypes = {
  className: PropTypes.string,
  isAlternateVisible: PropTypes.bool,
  toggleButtonClassName: PropTypes.string,
  dataAppCues: PropTypes.string,
  toggleButtonShowOnHover: PropTypes.bool,
  hideToggleButtonInEditMode: PropTypes.bool,
  primary: PropTypes.node.isRequired,
  alternate: PropTypes.node.isRequired,
  canEdit: PropTypes.bool,
  signalOnEdit: PropTypes.func.isRequired,
  dataTestId: PropTypes.string,
  dataGaLabel: PropTypes.string,
};
