import React, { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import { TutorStatusTypes } from '@crimson-education/common-config';

import filterMap, { filterNames } from './filterMap';

import css from './styles.scss';

class UserQueryFilter extends Component {
  constructor(props) {
    super(props);

    const tutorCapacityCriteria = Object.keys(TutorStatusTypes).map((key) => ({
      label: TutorStatusTypes[key],
      value: key,
    }));

    // ideally would be loaded from @crimson-education/common-config
    const tutorRelationship = [
      {
        value: 'My tutors',
        selected: true,
      },
      {
        value: 'All tutors',
        selected: false,
      },
    ];

    this.state = {
      userRoles: [],
      roles: [],
      tutorCapacityCriteria,
      tutorRelationship,
      filter: {
        roles: [],
      },
    };
  }

  componentDidMount() {
    const {
      activePage,
      filtersToShow,
      prevFilteredQuery,
      fixedFilters,
      prevFilters,
      viewableRoles,
      fetchViewableRoles,
    } = this.props;
    const query = () => {
      if (
        !(activePage && activePage > 0) ||
        !(filtersToShow && filtersToShow.length) ||
        !prevFilteredQuery ||
        !prevFilters ||
        (fixedFilters &&
          prevFilters &&
          fixedFilters.principalRole &&
          prevFilters.principalRole &&
          fixedFilters.principalRole !== prevFilters.principalRole)
      ) {
        this.filterUsers(this.state.filter);
      }
    };

    if (!viewableRoles || viewableRoles.length === 0) {
      fetchViewableRoles();
      query();
    } else {
      this.setRoleEntries(viewableRoles, query);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      (!prevProps.viewableRoles || prevProps.viewableRoles.length === 0) &&
      this.props.viewableRoles &&
      this.props.viewableRoles.length > 0
    ) {
      this.setRoleEntries(this.props.viewableRoles, () => this.filterUsers(this.state.filter));
    }
  }

  setRoleEntries(viewableRoles, callback) {
    const roleEntries = viewableRoles.map((r) => ({ value: r.id, label: r.name }));
    const roles = roleEntries.map((r) => r.value);

    this.setState((state) => {
      return {
        ...state,
        userRoles: roleEntries,
        roles,
        filter: {
          ...state.filter,
          roles,
        },
      };
    }, callback);
  }

  filterUsers = (newfilter) => {
    this.props.onSearchParamsChange(newfilter);
  };

  // Maybe find a way to make this more generic so it's easier to add filters?
  constructFilters = (inputName, value) => {
    // Set up existing filters
    const currentFilters = Object.keys(this.state.filter).reduce((p, v) => {
      p[v] = this.state.filter[v];
      return p;
    }, {});

    const newFilter = Object.assign(currentFilters, { [inputName]: value });

    this.filterUsers(newFilter);
    this.setState({
      filter: newFilter,
    });
  };

  handleInputChange = debounce((inputName, target) => {
    const { roles, tutorRelationship } = this.state;

    switch (inputName) {
      case 'roles':
        return this.constructFilters(inputName, target.value === 'All' ? roles : [target.value]);
      case 'tutorCapacity':
        return this.constructFilters(inputName, target);
      case 'tutorRelationship':
        this.setState({
          tutorRelationship: tutorRelationship.map((relationship) => ({
            value: relationship.value,
            selected: relationship.value === target.value.trim(),
          })),
        });
        return this.constructFilters('relatedTutors', target.value.trim() === 'My tutors');
      default:
        return this.constructFilters(inputName, target.value.trim());
    }
  }, 300);

  initTutorCapacityValue = () => {
    return this.state.tutorCapacityCriteria.filter((criterion) => criterion.value !== TutorStatusTypes.RESIGNED);
  };

  render() {
    const { tutorCapacityCriteria, tutorRelationship, userRoles } = this.state;
    const { filtersToShow, prevFilters, activePage } = this.props; // remember to check for active page
    const filterArgs = {
      // Is there a better way to do this without passing all arguments to all filters?
      userRoles,
      tutorCapacityCriteria,
      tutorCapacityDefault: this.initTutorCapacityValue(),
      tutorRelationship,
      ...(activePage > 0 && { prevFilters }),
    };
    return (
      <div className={css.filters}>
        {filtersToShow.map((filterName) => filterMap[filterName](filterArgs, this.handleInputChange))}
      </div>
    );
  }
}

UserQueryFilter.propTypes = {
  onSearchParamsChange: PropTypes.func.isRequired,
  filtersToShow: PropTypes.array.isRequired,
  activePage: PropTypes.number,
  prevFilters: PropTypes.object,
  prevFilteredQuery: PropTypes.bool,
  fixedFilters: PropTypes.object,
  viewableRoles: PropTypes.array,
  fetchViewableRoles: PropTypes.func.isRequired,
};

UserQueryFilter.defaultProps = {
  filtersToShow: [filterNames.Name, filterNames.Email, filterNames.UserType],
};

UserQueryFilter.displayName = 'UserQueryFilter';

export default UserQueryFilter;
