/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

export default function GirlOnSofa({ className }) {
  return (
    <svg viewBox='0 0 375 164' preserveAspectRatio="xMidYMin slice" xmlns='http://www.w3.org/2000/svg'
xmlnsXlink='http://www.w3.org/1999/xlink'>
    <defs>
        <path id='girl-on-sofa-a' d='m0 0h375v164h-375z' />
        <path id='girl-on-sofa-b' d='m5.00488281 0h502.49023419c2.764121 0 5.004883 2.24076236 5.004883 5.00488281v159.15527319h-512.5v-159.15527319c0-2.76412045 2.24076236-5.00488281 5.00488281-5.00488281z'
        />
        <filter id='girl-on-sofa-c' height='179.9%' width='219.9%' x='-59.9%' y='-40%'>
            <feOffset in='SourceAlpha' result='shadowOffsetOuter1' />
            <feGaussianBlur in='shadowOffsetOuter1' result='shadowBlurOuter1' stdDeviation='6'
            />
            <feColorMatrix in='shadowBlurOuter1' values='0 0 0 0 0.424847147 0 0 0 0 0.424847147 0 0 0 0 0.424847147 0 0 0 0.5 0'
            />
        </filter>
        <linearGradient id='girl-on-sofa-d' x1='31.503%' x2='107.2%' y1='26.638%' y2='78.404%'>
            <stop offset='0' stopColor='#fff' />
            <stop offset='0.31' stopColor='#fcfcfc' />
            <stop offset='0.58' stopColor='#f1f1f2' />
            <stop offset='0.82' stopColor='#e0e0e1' />
            <stop offset='1' stopColor='#cecfd0' />
        </linearGradient>
        <mask id='girl-on-sofa-e' fill='#fff'>
            <use fillRule='evenodd' xlinkHref='#girl-on-sofa-a' />
        </mask>
        <mask id='girl-on-sofa-f' fill='#fff'>
            <use fillRule='evenodd' xlinkHref='#girl-on-sofa-b' />
        </mask>
    </defs>
    <g fill='none' fillRule='evenodd'>
        <use fill='#d8d8d8' xlinkHref='#girl-on-sofa-a' />
        <g mask='url(#girl-on-sofa-e)'>
            <g transform='translate(-23)'>
                <use fill='#d8d8d8' xlinkHref='#girl-on-sofa-b' />
                <g mask='url(#girl-on-sofa-f)'>
                    <g transform='translate(-12.012 -1.001)'>
                        <g fillRule='nonzero'>
                            <path d='m0 0h534.981529v165.161133h-534.981529z' fill='#9bc8d2' />
                            <path d='m187.162395 165.161133h239.542623c37.594759-20.487201 24.522241-40.523142 14.064226-47.653048-14.154381-9.656963-8.294287-3.880836-20.555407-12.996286-25.333639-18.9529171 15.506711-60.3785783-17.760594-72.8333522-21.727426-8.0324267-45.257958 17.4186331-71.222684-1.3537798-27.948142-20.1261927-53.822712-23.19476017-75.369828 2.7075596-16.588574 20.1261926-41.20097 9.1154505-56.887992 7.1299068-21.817581-2.7075596-40.750194 22.3824923-19.653854 48.284812 12.711896 15.5233416-66.173988 38.7181016 7.84351 76.7141876z'
                            fill='#5eaab7' />
                            <ellipse cx='362.424' cy='30.956' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='347.278' cy='30.866' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='332.132' cy='30.866' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='369.997' cy='30.956' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='354.851' cy='30.956' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='339.705' cy='30.866' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='324.559' cy='30.776' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='309.413' cy='30.776' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='316.986' cy='30.776' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='301.84' cy='30.776' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='362.424' cy='37.996' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='347.278' cy='37.906' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='332.132' cy='37.906' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='369.997' cy='37.996' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='354.851' cy='37.906' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='339.705' cy='37.906' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='324.559' cy='37.816' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='309.413' cy='37.816' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='316.986' cy='37.816' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='301.84' cy='37.725' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='362.424' cy='45.036' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='347.278' cy='44.945' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='332.132' cy='44.855' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='369.997' cy='45.036' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='354.851' cy='44.945' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='339.705' cy='44.945' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='324.559' cy='44.855' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='309.413' cy='44.855' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='316.986' cy='44.855' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='301.84' cy='44.765' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='362.334' cy='51.985' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='347.188' cy='51.985' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='332.042' cy='51.895' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='369.907' cy='52.075' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='354.761' cy='51.985' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='339.615' cy='51.895' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='324.469' cy='51.895' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='309.413' cy='51.805' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='316.986' cy='51.895' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='301.84' cy='51.805' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='362.334' cy='59.025' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='347.188' cy='59.025' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='332.042' cy='58.935' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='369.907' cy='59.025' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='354.761' cy='59.025' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='339.615' cy='58.935' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='324.469' cy='58.935' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='309.323' cy='58.844' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='316.896' cy='58.844' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='301.75' cy='58.844' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='362.334' cy='66.064' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='347.188' cy='65.974' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='332.042' cy='65.974' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='369.907' cy='66.064' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='354.761' cy='66.064' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='339.615' cy='65.974' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='324.469' cy='65.974' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='309.323' cy='65.884' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='316.896' cy='65.884' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='301.75' cy='65.884' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='362.334' cy='73.104' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='347.188' cy='73.014' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='332.042' cy='73.014' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='369.907' cy='73.104' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='354.761' cy='73.104' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='339.615' cy='73.014' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='324.469' cy='72.924' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='309.323' cy='72.924' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='316.896' cy='72.924' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='301.75' cy='72.924' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='362.244' cy='80.144' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='347.098' cy='80.054' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='331.952' cy='79.963' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='369.817' cy='80.144' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='354.671' cy='80.054' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='339.525' cy='80.054' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='324.379' cy='79.963' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='309.233' cy='79.963' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='316.806' cy='79.963' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='301.75' cy='79.873' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='362.244' cy='87.093' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='347.098' cy='87.093' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='331.952' cy='87.003' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='369.817' cy='87.183' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='354.671' cy='87.093' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='339.525' cy='87.093' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='324.379' cy='87.003' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='309.233' cy='86.913' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='316.806' cy='87.003' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='301.66' cy='86.913' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='362.244' cy='94.133' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='347.098' cy='94.133' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='331.952' cy='94.043' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='369.817' cy='94.223' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='354.671' cy='94.133' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='339.525' cy='94.043' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='324.379' cy='94.043' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='309.233' cy='93.952' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='316.806' cy='94.043' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <ellipse cx='301.66' cy='93.952' fill='#d9d9e0' rx='1' ry='1' stroke='#d9d9e0'
                            strokeWidth='0.901' />
                            <path d='m416.247004 94.7645844c-1.172655-.0112665-2.29851-.4620913-3.155436-1.2635278-1.346564-1.3905973-3.56237-1.4309279-4.958541-.090252h-.090155c-1.752815 1.7199451-4.558056 1.7199451-6.310871 0-1.346565-1.3905973-3.56237-1.4309279-4.958541-.090252h-.090156c-.846858.8171647-1.979235 1.2706012-3.155435 1.2635278v-.9025198c.909092-.0034544 1.781513-.3592664 2.434193-.9927719.859976-.8638896 2.02729-1.350792 3.245591-1.3537797 1.200107-.0384223 2.355568.4573065 3.155435 1.3537797.694595.6243998 1.59094.9769127 2.524348.9927719.940214.0203463 1.849385-.3372109 2.524349-.9927719 1.662062-1.7018178 4.371457-1.7815913 6.13056-.1805039l.18031.1805039c.694596.6243998 1.59094.9769127 2.524349.9927719.940214.0203463 1.849385-.3372109 2.524348-.9927719h.090155c1.777263-1.6951251 4.582704-1.6550044 6.310871.090252.657743.6256994 1.526928.9801917 2.434193.9927719v.9025198c-1.172655-.0112665-2.29851-.4620913-3.155435-1.2635278-1.346565-1.3905973-3.562371-1.4309279-4.958542-.090252 0 .090252 0 .090252-.090155.090252-.826481.8586672-1.964321 1.3468356-3.155435 1.3537798z'
                            fill='#fff' />
                            <path d='m124.053688 34.2055024c-1.1762.0070734-2.308577-.4463631-3.155435-1.2635278-1.377577-1.4013281-3.622021-1.4414504-5.048697-.090252-1.788695 1.6296176-4.522176 1.6296176-6.310871 0-1.377577-1.4013281-3.62202-1.4414504-5.048696-.090252-.856926.8014365-1.982781 1.2522613-3.155436 1.2635278v-.9025199c.907265-.0125801 1.77645-.3670724 2.434193-.9927718.841733-.89073 2.021098-1.3826593 3.245591-1.3537798 1.195494-.0145896 2.34151.477087 3.155435 1.3537798.674964.6555609 1.584135 1.0131182 2.524349.9927718.933408-.0158592 1.829752-.368372 2.524348-.9927718 1.662063-1.7018178 4.371457-1.7815914 6.13056-.180504l.180311.180504c.674963.6555609 1.584134 1.0131182 2.524348.9927718.933409-.0158592 1.829753-.368372 2.524348-.9927718h.090156c1.777262-1.6951252 4.582703-1.6550045 6.31087.090252.641473.6504843 1.521265 1.0093022 2.434193.9927718v.9025199c-1.1762.0070734-2.308577-.4463631-3.155435-1.2635278-1.377577-1.4013281-3.62202-1.4414504-5.048697-.090252-.833928.8601191-1.960042 1.3754661-3.155435 1.4440318z'
                            fill='#fff' />
                        </g>
                        <ellipse cx='48.594' cy='34.386' rx='1.803' ry='1.805' stroke='#fac1c2'
                        strokeWidth='0.946' />
                        <ellipse cx='469.078' cy='40.162' rx='1.803' ry='1.805' stroke='#646980'
                        strokeWidth='0.946' />
                        <ellipse cx='408.764' cy='19.855' rx='1.803' ry='1.805' stroke='#fac1c2'
                        strokeWidth='0.946' />
                        <ellipse cx='95.374' cy='68.231' rx='1.803' ry='1.805' stroke='#646980'
                        strokeWidth='0.946' />
                        <g fillRule='nonzero' opacity='0.7'>
                            <path d='m.18031059 37.7253298c0 20.3969486 14.69531331 37.1838179 14.69531331 37.1838179s14.6953133-16.7868693 14.6953133-37.1838179c0-20.3969487-14.6953133-37.36432186-14.6953133-37.36432186s-14.69531331 16.78686926-14.69531331 37.36432186z'
                            fill='#1e5f59' transform='translate(457.087 69.494)' />
                            <path d='m13.79376 33.122479h2.344038v62.535601h-2.344038z' fill='#333456'
                            transform='translate(457.087 69.494)' />
                        </g>
                        <path d='m440.408623 115.522541c0 16.516113 11.720189 29.783155 11.720189 29.783155s11.810344-13.267042 11.810344-29.783155c0-16.5161133-11.810344-29.6929031-11.810344-29.6929031s-11.720189 13.2670418-11.720189 29.6929031z'
                        fill='#1e5f59' fillRule='nonzero' />
                        <path d='m451.227259 114.890777h1.803106v49.891297h-1.803106z' fill='#333456'
                        fillRule='nonzero' />
                        <g fillRule='nonzero' opacity='0.7'>
                            <path d='m.27046589 37.0935659c0 20.1261927 14.42484741 36.461802 14.42484741 36.461802s14.4248475-16.3356093 14.4248475-36.461802-14.4248475-36.28129803-14.4248475-36.28129803-14.42484741 16.15510533-14.42484741 36.28129803z'
                            fill='#1e5f59' transform='translate(52.29 71.3)' />
                            <path d='m13.523295 32.761471h2.344038v61.073518h-2.344038z' fill='#333456'
                            transform='translate(52.29 71.3)' />
                        </g>
                        <path d='m36.1522739 117.417833c0 16.064853 11.5398779 29.151391 11.5398779 29.151391s11.539878-13.086538 11.539878-29.151391c0-16.064854-11.539878-29.0611395-11.539878-29.0611395s-11.5398779 13.0865375-11.5398779 29.0611395z'
                        fill='#1e5f59' fillRule='nonzero' />
                        <path d='m46.700444 116.425061h1.712951v48.736072h-1.712951z' fill='#333456'
                        fillRule='nonzero' />
                        <path d='m96.3760119 122.652448c0 14.259813 10.2777041 25.90232 10.2777041 25.90232s10.187548-11.642507 10.187548-25.90232c0-14.259814-10.187548-25.9023199-10.187548-25.9023199s-10.2777041 11.6425059-10.2777041 25.9023199z'
                        fill='#1e5f59' fillRule='nonzero' />
                        <path d='m105.752163 121.749928h1.53264v43.40218h-1.53264z' fill='#333456'
                        fillRule='nonzero' />
                        <path d='m172.286772 149.96598h24.973017v10.875364h-24.973017z' fill='#babcc4'
                        fillRule='nonzero' />
                        <path d='m323.387048 149.96598h24.973017v10.875364h-24.973017z' fill='#babcc4'
                        fillRule='nonzero' />
                        <path d='m159.033943 110.739186h203.210038v32.680244h-203.210038z' fill='#40464e'
                        fillRule='nonzero' />
                        <rect fill='#2c333a' fillRule='nonzero' height='24.142' rx='5.225' width='203.21'
                        x='159.034' y='133.663' />
                        <rect fill='#babcc4' fillRule='nonzero' height='37.31' rx='4.955' width='180.761'
                        x='170.303' y='85.288' />
                        <rect fill='#e7e7e7' fillRule='nonzero' height='33.339' rx='6.126' width='12.982'
                        x='159.034' y='97.382' />
                        <rect fill='#e7e7e7' fillRule='nonzero' height='33.339' rx='6.126' width='12.982'
                        x='349.262' y='97.382' />
                        <path d='m196.26808 81.6371503s20.555408-7.8804727 50.757432-2.5392634c0 0-1.172019 38.8769991 1.622796 44.3057691 0 0-30.742956 9.193885-52.831004-.700487 0 0 0-22.678249.450776-41.0660187z'
                        fill='#e2e0de' />
                        <path d='m312.838879 81.36515s7.753355 15.0295715 2.614503 42.816803c0 0-29.210316 4.369061-44.176095.524287 0 0-8.835219-22.806501.811398-43.1663276 0 0 22.80929-.5242874 40.750194-.1747624z'
                        fill='#e2e0de' />
                        <ellipse cx='246.24' cy='104.602' fill='#000' filter='url(#girl-on-sofa-c)' rx='15.015'
                        ry='22.522' />
                        <path d='m261.270049 99.0064277c-.539864-1.2052266-.904197-2.4817605-1.081863-3.7905834-.721243-5.5956231-6.130561-18.8626649-6.491182-19.0431688-.360621-.180504 3.696367-.270756 5.499473.5415119 1.803106.8122678 1.622796 1.0830238 2.434193 3.9710873.811398 2.8880635 4.237299 12.7255299 4.056989 13.9890577'
                        fill='#ff764c' />
                        <path d='m261.270049 99.0064277c.77385 1.7611993 2.596414 2.8194203 4.507765 2.6173073 2.253882-.180504 9.19584-.180504 9.19584-.180504s.991708-1.8050394.631087-1.7147874-9.917082-5.0541112-9.917082-5.0541112-2.794815-.3610079-3.876678 1.6245357c-1.081864 1.9855437-.540932 2.7075596-.540932 2.7075596z'
                        fill='#ff764c' />
                        <path d='m274.613033 101.623735s-1.442485 1.714788-1.53264 1.534284c-.053435-1.070969.338159-2.116342 1.081864-2.888064 1.172018-1.0830233 4.507764-1.1732753 2.344037.722016'
                        fill='#dd6d54' />
                        <path d='m286.513532 155.865178s11.269412 12.454774 1.622795 7.942175c-9.646616-4.512599-6.040404-7.400663-6.040404-7.400663'
                        fill='#f9c0c0' />
                        <path d='m271.728064 128.157819c-.17593-.957507-.771501-1.785577-1.622796-2.2563-2.163727-1.173276-8.294287-.722016-15.506711-6.678647s-6.761647-10.830238 8.745064-12.454774l13.342984 9.025199s2.344038 2.527056 3.696367 7.851923c-.348168 1.553232-1.473317 2.816116-2.975125 3.339323-1.849242.580489-3.752055.973555-5.679783 1.173276z'
                        fill='#60b9ce' />
                        <path d='m279.571574 113.898005c-4.147143-6.768899-16.498419-.541512-21.366805-4.241843s-7.302579 3.158819-14.515003 2.888063c0 0-2.975124 7.129907 2.88497 9.747215 11.179257 4.873607 26.595812-.180504 26.595812-.180504'
                        fill='#60b9ce' />
                        <path d='m280.202661 122.110936.540932 3.068567c1.172019 6.317639 4.056988 25.270556 5.499473 29.422148 1.442485 4.151591-2.975125 4.422347-4.147143 1.805039-1.172019-2.617307-7.48289-14.801325-9.646617-19.313925-1.262174-2.527055-2.434193-15.162333-2.88497-16.516113'
                        fill='#3b7a8c' />
                        <path d='m279.571574 113.898005 16.49842 26.35358 1.352329 3.158819-3.335746-1.714787c-2.434193-1.714788-7.032113-5.144363-9.466306-6.678647-9.736772-6.317639-12.531586-13.989058-12.531586-13.989058s2.073572-2.617307 3.966833-4.602851c.967776-1.09577 2.169484-1.959461 3.516056-2.527056z'
                        fill='#60b9ce' />
                        <path d='m276.686605 111.009942-10.818636.45126c-.397593-1.276721.076557-2.664185 1.172019-3.429576 2.794814-2.617307 9.01553-7.400663 11.900499-9.6569622l1.442485.4512599s-4.327454 2.9783153-5.138852 6.3176393c-.811398 3.339323 1.442485 5.866379 1.442485 5.866379z'
                        fill='#423150' />
                        <path d='m261.179894 104.421547s-.991708-2.527056-11.269412-1.263528c0 0-5.589629 4.693103-5.499473 1.714788.090155-2.978316-1.622796-10.7399865-1.983417-11.0107425-.360621-.2707559-5.860094-3.7905833-5.138852-8.4836866l1.172019-.9927718s.090155-2.2562996 3.245591-4.3320953c3.155435-2.0757956 3.786522-3.2490714 8.654908-3.9710873s4.778231 5.2346151 4.778231 5.2346151l7.212424 9.9277184c-.149619 1.5858875-.545029 3.1387771-1.172019 4.6028512-1.081864 3.1588195-1.53264 7.4909148 0 8.5739388z'
                        fill='#7e2706' />
                        <path d='m265.867969 111.461202 10.818636-.45126s-2.073572 1.895291-3.06528 6.317639c-.991709 4.422347-.991709 8.573938-2.794814 10.378978l-14.424848-6.678647c-.270466-.180504-.631087-1.173276-.721242-1.173276 1.156465-4.752553 5.30629-8.171559 10.187548-8.393434z'
                        fill='#423150' />
                        <path d='m263.343621 106.768098s-3.516057 3.339324-13.883916 4.602852c0 0-1.622795 1.624535-2.434193.722016-.811397-.90252-2.704659-4.241844-2.614503-7.220159.090155-2.978316 1.262174-10.1984746.901553-10.4692305-.360622-.270756-8.745064-4.3320953-8.023822-9.0251986l1.172019-.9927718s3.155435-5.4151191 5.319163-2.3465516c2.163727 3.0685675 7.933666 7.4909148 9.466306 6.6786469s1.622795-9.9277184 1.622795-9.9277184l7.48289 12.454774c-.149619 1.5858875-.545029 3.1387771-1.172019 4.6028512-1.081864 3.1588195.540932 9.8374668 2.163727 10.9204898z'
                        fill='#fff' />
                        <path d='m242.427592 104.421547c-.081563-3.299544-.413084-6.5881383-.991708-9.8374666 0 0 3.06528 5.9566306 2.884969 9.0251986-.18031 3.068567-2.434193 4.602851-1.71295 10.649734.18031 1.35378 9.826927 1.714788 11.539878 1.444032 1.71295-.270756-.721243-3.610079-1.712951-9.115451-1.712951-9.11545-3.335746-13.7183013-4.147144-15.342837-.811397-1.6245358-1.53264-2.4368036-3.06528-5.5956231s-3.606212-8.5739386 4.868386-9.4764584c0 0 3.245591-3.7003314 4.41761-1.5342838 1.172019 2.1660477-3.425901.5415119.090155 5.5956231 3.516057 5.0541112 4.056989 13.8085537 4.41761 16.8771212.360621 3.068568 2.794814 11.732758 6.671492 12.815782 3.876677 1.083024 1.893261-2.436804 1.893261-2.436804s-5.409318-3.158819-5.138852-8.4836863c.270466-5.3248671 1.622795-6.6786469.540932-9.1154505-1.081864-2.4368036-1.983417-3.2490715-2.88497-4.7833552-1.893261-3.5198274-1.081863-6.4981429-3.425901-8.0324267 0 0-7.212424-.8122679-9.376151-.2707559-2.163727.5415119-10.908791 3.6100794-12.170965 8.8446945-.901553 3.4295754-.18031 4.0613393.090155 11.7327581.180311 2.978315.360622 4.693103.450777 5.866379'
                        fill='#ff764c' />
                        <path d='m235.665945 103.248271c-.090155-1.173276-.270466-2.888064-.450777-5.866379-.270465-7.6714188-.991708-8.3031827-.090155-11.7327581 0 0 2.344038-6.046883 6.310871 8.9349465.578624 3.2493283.910145 6.5379226.991708 9.8374666'
                        fill='#ff764c' />
                        <path d='m276.686605 111.009942-10.818636.45126c-.397593-1.276721.076557-2.664185 1.172019-3.429576 3.779591-3.44358 7.752918-6.667838 11.900499-9.6569622 0 0-4.59792 3.1588192-4.77823 7.7616712-.180311 4.602851 2.524348 4.873607 2.524348 4.873607z'
                        fill='#babcc4' />
                        <path d='m276.686605 111.009942-10.818636.45126c-.397593-1.276721.076557-2.664185 1.172019-3.429576l10.187549-9.5667102s-3.606212 3.0685672-3.786523 7.5811672c-.18031 4.512599 3.245591 4.963859 3.245591 4.963859z'
                        fill='#fff' />
                        <path d='m265.867969 111.461202 10.818636-.45126c-2.287775 1.355056-3.893465 3.618555-4.41761 6.227387-.991708 4.422347-.360621 6.949403-2.253882 8.754442l-14.334692-6.137135c1.156465-4.752553 5.30629-8.171559 10.187548-8.393434z'
                        fill='url(#girl-on-sofa-d)' />
                        <path d='m265.867969 111.461202 10.818636-.45126s-4.41761 1.263528-5.319163 5.685875-.991708 7.310411-2.884969 9.025198l-12.802052-5.866379c1.156465-4.752553 5.30629-8.171559 10.187548-8.393434z'
                        fill='#babcc4' />
                        <path d='m265.867969 111.461202 10.818636-.45126s-4.868386.361008-5.769939 4.783355-1.803106 6.678647-3.696367 8.483687l-11.720189-5.595624s1.712951-6.588394 10.367859-7.220158z'
                        fill='#fff' />
                        <path d='m296.069994 140.251585c1.537146.019065 3.060458.293555 4.507764.812268 2.794815.992772 6.671492 1.083024 3.606212 2.978315-3.06528 1.895292-11.630033 2.166048-10.097393-2.346551.567784.073298 1.136602-.127676 1.53264-.541512.303165-.19627.475828-.541966.450777-.90252z'
                        fill='#f9c0c0' />
                        <path d='m255.409955 69.9452885s.721242-.270756 1.081863 0c.360622.2707559.901553 2.2562996-.721242 3.9710873-1.622795 1.7147878-.360621-3.9710873-.360621-3.9710873z'
                        fill='#7e2706' fillRule='nonzero' />
                        <path d='m242.698058 69.5842805s-.721242-.2707559-1.081864 0c-.360621.270756-.991708 2.0757957.540932 3.9710874s.540932-3.9710874.540932-3.9710874z'
                        fill='#7e2706' fillRule='nonzero' />
                        <path d='m242.788213 65.5229412c.180311-1.8952917 2.794815-4.9638592 7.032113-4.8736072 4.237299.090252 6.581337 3.1588195 6.040405 8.0324267 0 0-.450776 3.6100794-.901553 5.956631-.450776 2.3465516-3.425901 6.137135-5.860094 6.137135s-5.319162-3.6100794-6.040405-5.6858751c-.721242-2.0757956-.540932-7.7616707-.270466-9.5667104z'
                        fill='#94312f' fillRule='nonzero' />
                        <path d='m242.517747 69.6745325s5.409318-2.4368036 5.769939-3.6100794c.360622-1.1732758 1.53264-5.4151191 1.53264-5.4151191l-.991708 5.5053711c1.561717 2.1360045 3.951777 3.5125824 6.581337 3.7905834 4.507765.4512599-1.081864-8.4836866-1.081864-8.4836866s-3.155435-3.7003314-6.941958-1.3537798c-3.786522 2.3465516-6.761647 2.7978115-4.868386 9.5667104z'
                        fill='#dca55e' fillRule='nonzero' />
                        <path d='m236.043061 107.129106c-3.855111-4.602851-9.112079-12.4547736-8.849231-13.9890573.262849-1.5342838 1.489475-2.6173076 2.365636-3.3393235s6.045514 6.407891 9.725392 10.3789788c1.664707 1.805039 1.752323 2.166047 2.803717 2.707559 1.051393.541512.963777 3.700331-2.015172 4.783355s-4.030342-.541512-4.030342-.541512z'
                        fill='#ff764c' />
                        <path d='m231.187808 91.8765209s-.175615 1.8050397-2.897656 1.1732758c0 0-3.951349-2.3465516-4.478196-3.3393235-.526846-.9927718-2.107386-4.2418433-1.492731-4.8736072.614654-.6317639 3.51231 1.9855437 5.180657 3.4295755l1.141501.9025198s.351231-.1805039-.351231-1.0830238-1.492732-1.0830238-1.58054-1.4440318c-.087807-.3610079 1.141501.180504 2.458618 1.0830239 1.288194.9655132 2.041003 2.5130414 2.019578 4.1515913z'
                        fill='#9e2e2c' fillRule='nonzero' />
                        <ellipse cx='242.698' cy='59.837' fill='#dca55e' fillRule='nonzero' rx='4.418'
                        ry='4.422' />
                        <ellipse cx='257.754' cy='62.184' fill='#dca55e' fillRule='nonzero' rx='4.418'
                        ry='4.422' />
                        <path d='m504.959815 142.688389v-2.888064h2.88497v-2.888064h2.884969v-2.888063h2.794815v2.888063 8.664191h-8.564754-2.884969v-2.888063z'
                        stroke='#fff' strokeWidth='0.901' />
                    </g>
                </g>
                <g mask='url(#girl-on-sofa-f)' opacity='0.4'>
                    <g stroke='#00a896' strokeWidth='1.001' transform='translate(97.095 24.023)'>
                        <rect height='42.041' rx='1.001' width='53.052' x='0.5' y='18.518' />
                        <rect height='29.028' rx='1.001' transform='rotate(90 80.078 19.019)'
                        width='37.036' x='61.56' y='4.504' />
                        <rect height='21.021' rx='1.001' transform='rotate(90 80.078 19.019)'
                        width='29.992' x='65.082' y='8.508' />
                        <rect height='34.033' rx='1.001' width='43.042' x='5.505' y='22.522' />
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
  );
}

GirlOnSofa.propTypes = {
  className: PropTypes.string,
};

