import styled from 'styled-components';
import Radio from '@material-ui/core/Radio';
import Select from '../components/Select';

export const StyledSelect = styled(Select)`
  margin-top: 16px;
`;
export const Content = styled.div`
  font-weight: 600;
  font-size: 14px;
  font-family: 'Montserrat';
  line-height: 17px;
  color: #1d1e2b;
  margin-top: 40px;
`;

export const StyledRadio = styled(Radio)`
  &.MuiRadio-root {
    color: #73747d;
    padding: 4px;
    &.Mui-checked {
      color: #464ac9;
    }
  }
`;

export const ListText = styled.div`
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 14px;
  color: #73747d;
`;

export const HintText = styled.div`
  font-family: 'Montserrat';
  font-size: 14px;
  line-height: 20px;
  margin-top: 8px;
  margin-bottom: 25px;
  color: #a9acc0;
`;
