import React from 'react';
import PropTypes from 'prop-types';

export default function Sad({ className }) {
  return (
    <svg viewBox="0 0 64 64" enableBackground="new 0 0 64 64" className={className}>
      <circle fill="#ffdd67" cx="32" cy="32" r="30" />
      <g fill="#664e27">
        <path d="m42.684 47c-1.801-1.061-3.541-1.701-5.322-2.127-1.775-.428-3.566-.609-5.361-.613-1.799.008-3.588.189-5.361.615-1.781.428-3.52 1.068-5.322 2.125.771-1.979 2.484-3.533 4.396-4.527 1.932-1 4.119-1.486 6.287-1.484 2.166.004 4.354.486 6.283 1.488 1.909.996 3.624 2.548 4.4 4.523" />
        <circle cx="20.5" cy="26.592" r="4.5" />
        <circle cx="43.5" cy="26.592" r="4.5" />
      </g>
    </svg>
  );
}

Sad.propTypes = {
  className: PropTypes.string,
};
