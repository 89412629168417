const keys = {
  OUR_PEOPLE_RELATION_STUDENTS: 'ourPeople/relationStudentsCount',
  OUR_PEOPLE_RELATION_STUDENTS_AND_CONTRACT: 'ourPeople/relationStudentsAndContractCount',
  OUR_PEOPLE_RELATION_STUDENTS_AND_CONTRACT_AS_HEAD_TUTOR: 'ourPeople/relationStudentsAndContractCountAsHeadTutor',
  OUR_PEOPLE_RELATION_STUDENTS_AND_CONTRACT_AS_TUTOR: 'ourPeople/relationStudentsAndContractCountAsTutor',
  OUR_PEOPLE_EXTERNAL_STUDENTS: 'ourPeople/externalStudentsCount',
  OUR_PEOPLE_RELATION_TUTORS: 'ourPeople/relationTutorsCount',
  OUR_PEOPLE_ALL_TUTORS: 'ourPeople/allTutorsCount',
  OUR_PEOPLE_ALL_CASE_MANAGERS: 'ourPeople/allCaseManagersCount',
  OUR_PEOPLE_ALL_USERS_ACTIVE: 'ourPeople/allUsersActiveCount',
  OUR_PEOPLE_ALL_USERS_DEACTIVATED: 'ourPeople/allUsersDeactivatedCount',
  OUR_PEOPLE_MY_FRIENDS: 'ourPeople/myFriends',
  OUR_PEOPLE_CGA_STUDENTS: 'ourPeople/CGAStudents',
};

const keyProxy = new Proxy(keys, {
  get: function getProperty(target, property) {
    const isKeyPresent = Reflect.has(target, property);
    if (!isKeyPresent) throw new Error(`An unknown key ${property} was provided.`);
    return Reflect.get(target, property);
  },
});

export default keyProxy;
