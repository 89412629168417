import { connect } from 'react-redux';
import { getLoginUserToJs, getBookingAsToJs } from 'selectors/user';

import SessionList from './SessionList';

export default connect(
  (state) => ({
    currentUser: getLoginUserToJs(state),
    bookAs: getBookingAsToJs(state),
  }),
  null,
)(SessionList);
