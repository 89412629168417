import React, { useState } from 'react';
import { ApprovalStatusText, ResendPrompt } from './index.style';

type ApprovalStatusProps = {
  status: string;
  prompt: string;
};
const ApprovalStatus = ({ status, prompt }: ApprovalStatusProps) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <ApprovalStatusText onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
      {status}
      {isOpen && (
        <ResendPrompt
          $backgroundColor={'rgba(29, 30, 43, 0.8)'}
          style={{ textAlign: 'left', textTransform: 'none' }}
          $bottom={'50px'}
        >
          {prompt}
        </ResendPrompt>
      )}
    </ApprovalStatusText>
  );
};

export default ApprovalStatus;
