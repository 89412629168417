import React, { Component } from 'react';
import PropTypes from 'prop-types';
import css from 'components/unique/ProfilePage/profileItem.scss';
import { CheckboxField } from 'components/generic/Forms';
import Button from 'components/molecules/Button';

export default class NotificationForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      subscribedEmail: props.subscribedEmail,
    };

    this.handleSaveClicked = this.handleSaveClicked.bind(this);
    this.handleCancelClicked = this.handleCancelClicked.bind(this);
    this.handleSubscribeEmailChecked = this.handleSubscribeEmailChecked.bind(this);
  }

  handleSaveClicked() {
    const { onSaveDetails } = this.props;
    const { subscribedEmail } = this.state;

    const details = {
      subscribedEmail,
    };

    onSaveDetails(details);
  }

  handleCancelClicked() {
    this.props.onCancel();
  }

  handleSubscribeEmailChecked(value) {
    this.setState({
      subscribedEmail: value,
    });
  }

  render() {
    const { subscribedEmail } = this.state;

    return (
      <div className={css.itemBody}>
        <CheckboxField
          id="subscribedEmail"
          label="I want to be emailed notifications"
          isChecked={subscribedEmail}
          onClick={this.handleSubscribeEmailChecked}
          className={css.formRow}
        />
        <footer className={css.footer}>
          <Button secondary onClick={this.handleCancelClicked}>
            Cancel
          </Button>
          <Button primary onClick={this.handleSaveClicked}>
            Save
          </Button>
        </footer>
      </div>
    );
  }
}

NotificationForm.displayName = 'NotificationForm';

NotificationForm.propTypes = {
  subscribedEmail: PropTypes.bool,
  onSaveDetails: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
};
