import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import autoBind from 'auto-bind';
import Fuse from 'fuse.js';
import debounce from 'lodash/debounce';

import * as Logger from '@crimson-education/browser-logger';
import Header from 'components/molecules/Header';
import SearchInput from 'components/generic/SearchInput';
import CreateMessageButton from 'components/molecules/CreateMessageButton';
import { Pane, FlowLayout } from 'components/molecules/Layout';
import IconButton from 'components/molecules/IconButton';
import MessageBar from 'components/unique/Conversations/MessageBar';
import Threads from './Threads';
import CommunityThreadsNew from './CommunityThreadsNew/CommunityThreadsNew';
import CreateNewChatModal from './CreateNewChatModal/main';

import css from './styles';
import { createGroupIM } from '../../../tim/tim';

const fuseOptions = {
  threshold: 0.2,
  keys: ['name', 'participants.user.email', 'participants.user.firstName', 'participants.user.lastName', 'chatName'],
};

const filterContacts = (threads) => {
  if (threads) {
    const privateThreadsUsersArrays = threads
      .filter((t) => t.type === 'private')
      .map((t) => t.participants.map((x) => x.userId));

    return new Set([].concat(...privateThreadsUsersArrays));
  }
  return new Set([]);
};

export default class Conversations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchText: '',
      createChatModalOpen: false,
      searchStatus: 'initial',
      showDirect: true,
      showCommunity: true,
      showMessage: false,
    };

    this.fuse = new Fuse(this.props.threads, fuseOptions);
    autoBind(this);

    // When page refreshed on new thread, redirect them back to messages as we have lost the optimistic thread
    const { location, history, activeThread } = props;
    if (location.pathname.endsWith('new') && !activeThread) {
      history.replace('/messages');
    }
  }

  componentDidMount() {
    this.loadMoreThreads('DIRECT');
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.threads !== this.props.threads) {
      this.fuse.setCollection(nextProps.threads);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.searchText !== this.state.searchText || nextState.searchStatus !== this.state.searchStatus) {
      return false;
    }
    if (this.state.createChatModalOpen && nextState.createChatModalOpen) {
      return false;
    }
    return true;
  }

  componentDidUpdate() {
    const { activeThread } = this.props;
    if (!activeThread) {
      return;
    }
    const unReadMessage = this.props.threads.filter((s) => s.unreadMessagesCount > 0);
    if (unReadMessage.length === 0) {
      this.props.updateUnReadMessageStatus(false);
    } else {
      this.props.updateUnReadMessageStatus(true);
    }
  }

  componentWillUnmount() {
    sessionStorage.setItem('filterText', '');
  }

  onContactSelect(contactId) {
    this.props.prepareThread([contactId]);
    Logger.trackEvent({ message: 'contact click' });
    this.setState({ showMessage: true });
  }

  onSearch(searchText) {
    let searchStatus = null;
    if (searchText === '') {
      searchStatus = 'initial';
    } else {
      searchStatus = 'start';
    }
    this.setState({
      searchStatus,
    });
    this.setState({ searchText });
    if (!this.onSearchFunc) {
      this.onSearchFunc = debounce((text) => {
        this.props.setContactsFilter(text);
      }, 500);
    }
    this.onSearchFunc(searchText);
  }

  getFilteredThreads(source) {
    const { searchText } = this.state;
    if (searchText) {
      const result = this.fuse.search(searchText);
      if (source === 'DIRECT') {
        return result.filter(
          (thread) => thread.type === 'private' || (thread.type === 'group' && thread.source !== 'COMMUNITY'),
        );
      }
      return result.filter((thread) => thread.source === 'COMMUNITY' && thread.type === 'group');
    }
    if (source === 'DIRECT') {
      return this.props.threads.filter(
        (thread) => thread.type === 'private' || (thread.type === 'group' && thread.source !== 'COMMUNITY'),
      );
    }
    return this.props.threads.filter((thread) => thread.source === 'COMMUNITY' && thread.type === 'group');
  }

  getFilteredContacts() {
    const privateThreadsUsers = filterContacts(this.props.threads);
    const contacts = this.props.filteredContacts.filter((c) => !privateThreadsUsers.has(c.userId));
    return contacts;
  }

  getMinusContactCount() {
    const privateThreadsUsers = filterContacts(this.props.threads);
    const contacts = this.props.filteredContacts.filter((c) => privateThreadsUsers.has(c.userId));

    return contacts.length;
  }

  handleSearchStatus(searchStatus) {
    this.setState({
      searchStatus,
    });
  }

  loadMoreThreads(source) {
    const { currentUser, fetchThreads } = this.props;
    fetchThreads(currentUser.userId, source);
  }
  loadMoreDirectThreads() {
    const { currentUser, fetchThreads } = this.props;
    fetchThreads(currentUser.userId, 'DIRECT');
  }

  openCreateNewChat() {
    this.setState({ createChatModalOpen: true });
    Logger.trackEventSinceLastAction({ message: 'new thread button' });
  }

  createThread(users, groupNam) {
    const { prepareThread, activeThread, currentUser } = this.props;

    const userIds = users.map((user) => user.userId);
    this.setState({ createChatModalOpen: false });
    prepareThread(userIds, groupNam);
    if (activeThread) {
      createGroupIM(activeThread.id, currentUser.userId);
    }
  }

  render() {
    const {
      setContactsFilter,
      contactPageInfo,
      children,
      app: { isMobile },
      activeThread,
      threadsHasNextPage,
      onlineUsers,
      app,
      consumeThread,
      currentUser,
    } = this.props;

    const { searchText } = this.state;

    const threadMode = window.location.pathname === '/messages';

    const actionButtons = [
      {
        buttonText: 'New conversation',
        buttonAction: this.openCreateNewChat,
        dataTestId: 'createMessageButton',
        icon: 'Message',
        className: css.newMessageButton,
      },
    ];
    const directActiveThread =
      activeThread &&
      (activeThread.type === 'private' || (activeThread.type === 'group' && activeThread.source !== 'COMMUNITY'))
        ? activeThread
        : null;
    const communityActiveThread =
      activeThread && activeThread.type === 'group' && activeThread.source === 'COMMUNITY' ? activeThread : null;
    return (
      <div data-ga-category="Messages" className={css.conversationWrapper}>
        <CreateNewChatModal
          isOpen={this.state.createChatModalOpen}
          onConfirm={this.createThread}
          onCancel={() => this.setState({ createChatModalOpen: false })}
        />
        <Header
          title="Messages"
          isBackButtonVisibleMobile={!threadMode}
          backButtonLink="/messages"
          actionButtons={actionButtons}
          className={css.conversationHeader}
        />
        <FlowLayout resizeable={!isMobile} className={css.panes}>
          <Pane className={classNames(css.threadsSideBar, !threadMode && css.hideBar)}>
            <div className={css.control}>
              <SearchInput
                placeholder="Search by name"
                onChangeHandler={this.onSearch}
                dataTestId="searchAvailableContactsOrConversations"
                className={css.searchInput}
              />
              <CreateMessageButton
                onClick={this.openCreateNewChat}
                dataTestId="createMessageButton"
                className={css.createMessageButton}
              />
            </div>
            <div className={css.threadType}>
              <div className={css.source}>Direct messages </div>
              <IconButton
                className={css.iconButton}
                icon={this.state.showDirect ? 'ArrowDropDown' : 'ArrowLeft'}
                iconSize={{ height: '2.4rem', width: '2.4rem' }}
                onClick={() => this.setState({ showDirect: !this.state.showDirect })}
              />
            </div>
            <div className={classNames(css.threadsWrapper, !this.state.showDirect && css.hideThread)}>
              {/* <IconButton icon="ArrowDropDown" iconSize={{ height: '3rem', width: '3rem' }} /> */}
              <Threads
                activeThread={directActiveThread}
                threadsHasNextPage={threadsHasNextPage}
                contactsHasNextPage={contactPageInfo.hasNextPage}
                loadMoreThreads={() => this.loadMoreThreads('DIRECT')}
                loadMoreContacts={() => setContactsFilter(searchText)}
                threads={this.getFilteredThreads('DIRECT')}
                contacts={this.getFilteredContacts()}
                onContactSelect={this.onContactSelect}
                onlineUsers={onlineUsers}
                app={app}
                handleSearchStatus={this.handleSearchStatus}
                searchStatus={this.state.searchStatus}
                type="DIRECT"
                consumeThread={consumeThread}
                updateCurrentThreadId={this.props.updateCurrentThreadId}
              />
            </div>
            <div className={css.threadType}>
              <div className={css.source}>Community Group chat </div>
              <IconButton
                className={css.iconButton}
                icon={this.state.showCommunity ? 'ArrowDropDown' : 'ArrowLeft'}
                iconSize={{ height: '2.4rem', width: '2.4rem' }}
                onClick={() => this.setState({ showCommunity: !this.state.showCommunity })}
              />
            </div>
            <div className={classNames(css.threadsWrapper, !this.state.showCommunity && css.hideThread)}>
              <CommunityThreadsNew
                userId={currentUser.userId}
                updateCurrentThreadId={this.props.updateCurrentThreadId}
                searchText={searchText}
                activeThread={communityActiveThread}
                consumeThread={consumeThread}
              />
            </div>
          </Pane>
          <Pane className={classNames(css.messages, threadMode && css.hideBar)}>
            {this.state.showMessage ? (
              <MessageBar history={this.props.history} location={this.props.location} />
            ) : (
              children
            )}
          </Pane>
        </FlowLayout>
      </div>
    );
  }
}

Conversations.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  children: PropTypes.object,
  threads: PropTypes.array,
  activeThread: PropTypes.object,
  currentUser: PropTypes.object.isRequired,
  fetchThreads: PropTypes.func.isRequired,
  filteredContacts: PropTypes.array,
  prepareThread: PropTypes.func,
  setContactsFilter: PropTypes.func,
  threadsHasNextPage: PropTypes.bool,
  contactPageInfo: PropTypes.object,
  app: PropTypes.object,
  onlineUsers: PropTypes.array,
  updateUnReadMessageStatus: PropTypes.func,
  consumeThread: PropTypes.func,
  updateCurrentThreadId: PropTypes.func,
};
