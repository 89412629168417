import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';
import { gql } from '@apollo/client';
import { createClient } from '../utils';

const { api } = getConfig();

const client = createClient(`${api.endpoint}/graphql`);

export const allLanguages = gql`
  query getAllLanguages {
    getAllLanguages {
      id
      language
    }
  }
`;

export default {
  allLanguages: () => client.query(allLanguages),
};
