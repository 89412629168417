import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as Icon from 'components/atoms/icons';
import { Body, Caption } from 'components/atoms/typography';

import fontColour from 'components/atoms/typography/fontColour.scss';
import css from './style.scss';

export default function FilledModalSection({
  heading,
  subHeading,
  icon,
  bannerClassName,
  headingClassName,
  subHeadingClassName,
  isHeadingBold,
  children,
}) {
  const BannerIcon = icon && Icon[icon];
  return (
    <div className={classNames(css.infoBanner, bannerClassName)}>
      <div className={css.heading}>
        {icon && <BannerIcon className={fontColour.contrast} />}
        <Body bold={isHeadingBold} className={classNames(css.heading, headingClassName)}>
          {heading}
        </Body>
      </div>
      {subHeading && <Caption className={classNames(css.subheading, subHeadingClassName)}>{subHeading}</Caption>}
      {children}
    </div>
  );
}

FilledModalSection.defaultProps = {
  isHeadingBold: true,
};

FilledModalSection.propTypes = {
  heading: PropTypes.string.isRequired,
  subHeading: PropTypes.string,
  icon: PropTypes.string,
  bannerClassName: PropTypes.string,
  headingClassName: PropTypes.string,
  subHeadingClassName: PropTypes.string,
  isHeadingBold: PropTypes.bool,
  children: PropTypes.node,
};
