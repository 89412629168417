import Dialogue from '../../Dialogue';
import styled from 'styled-components';
import { Column } from '../../index.style';

export const StyledDialogue = styled(Dialogue)`
  width: 528px;
  padding-left: 63px;
  padding-right: 63px;
`;

export const InfoText = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  white-space: pre-wrap;
  color: #1d1e2b;
  margin-top: 30px;
`;

export const OptionTextContainer = styled(Column)`
  gap: 2px;

  * {
    user-select: none;
  }
  div:nth-child(1) {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    color: #000000;
  }

  div:nth-child(2) {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    height: 20px;
    color: #73747d;
  }
`;

export const HintText = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  white-space: pre-wrap;
  color: #a9acc0;
`;

export const ContactContainer = styled.div`
  max-height: 250px;
  overflow-y: auto;
`;
