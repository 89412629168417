import { connect } from 'react-redux';
import { PermissionTypes } from '@crimson-education/common-config';
import componentKeys from 'constants/componentKeys';
import { showPageLoader, fetchReasonsForCancellation, updateMeta } from 'ducks/meta';
import { getMetaItem, getShowDayCalendar } from 'selectors/meta';
import { getBooking } from 'ducks/booking';
import { getBookingByIdToJS, getReasonsForCancellation } from 'selectors/booking';
import { fetchPeopleForBookingFor, fetchPeopleForBookingWith, fetchBookingAsEvents } from 'ducks/user';
import AuthorisationCheck from 'components/wrappers/AuthorisationCheck';
import { getBookingAs } from 'selectors/user';
import Bookings from './Bookings';

export const VIEW_CALENDAR_PERMISSION = PermissionTypes.VIEW_CALENDAR;

const withAuthorisation = AuthorisationCheck(VIEW_CALENDAR_PERMISSION);

export default withAuthorisation(
  connect(
    (state, ownProps) => ({
      bookingBeingViewed: getBookingByIdToJS(ownProps.match.params.eventId)(state),
      bookingAs: getBookingAs(state),
      reasonsForCancellation: getReasonsForCancellation(state),
      isCalendarView: getMetaItem(componentKeys.SESSION_IS_CALENDAR_VIEW)(state),
      showDayCalendar: getShowDayCalendar(state),
    }),
    (dispatch) => ({
      fetchReasonsForCancellation: showPageLoader(
        (userId, eventId) => dispatch(fetchReasonsForCancellation(userId, eventId)),
        dispatch,
      ),
      fetchBookingBeingViewed: (eventId) => dispatch(getBooking(eventId)),
      fetchBookFor: showPageLoader(
        (filter, pagination) => dispatch(fetchPeopleForBookingFor(filter, pagination)),
        dispatch,
      ),
      fetchBookingWith: (filter, pagination) => dispatch(fetchPeopleForBookingWith(filter, pagination)),
      toggleCalendarView: (isActive) => dispatch(updateMeta(componentKeys.SESSION_IS_CALENDAR_VIEW, isActive)),
      fetchBookingAsEvents: () => dispatch(fetchBookingAsEvents()),
    }),
  )(Bookings),
);
