import { connect } from 'react-redux';
import { getReasonsForCancellation } from 'selectors/booking';
import { getBookingAs } from 'selectors/user';
import { showPageLoader } from 'ducks/meta';
import { cancelBooking } from 'ducks/booking';
import CancelBookingModal from './CancelBookingModal';

const mapStateToProps = (state) => {
  const bookingAs = getBookingAs(state);
  const userId = bookingAs && bookingAs.get('userId');

  return {
    reasonsForCancellation: getReasonsForCancellation(state),
    userId,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  cancelBooking:
    ownProps.cancelBooking ||
    showPageLoader(
      (eventId, participantId, reasonId, comments, isPenaltySession) =>
        dispatch(cancelBooking(eventId, participantId, reasonId, comments, isPenaltySession)),
      dispatch,
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(CancelBookingModal);
