import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { ModalReturnType, popupModalInfo } from '../Modal/commonModal';
import { AutomaticFloatingSession } from './AutomaticFloatingSession';
import { CloseFull } from 'components/pages/FloatingSession/Icon';
import { Plate, PlateProps, PlateProvider } from '@udecode/plate';
// import { MarkBalloonToolbar } from './editor-balloon-toolbar/MarkBalloonToolbar';
import { BasicElementToolbarButtons } from './editor-basic-elements/BasicElementToolbarButtons';
import { BasicMarkToolbarButtons } from './editor-basic-marks/BasicMarkToolbarButtons';
import { basicNodesPlugins } from './editor-basic-nodes/basicNodesPlugins';
import { Toolbar } from './toolbar/Toolbar';
import { MyValue, MyParagraphElement } from './types/plateTypes';
import { Collapse } from 'antd';
const { Panel } = Collapse;

const COLORS = {
  mist: '#F3F6FA',
  darkNavy: '#1D1E2B',
};

const EditorTitle = styled.div`
  display: flex;
  align-items: center;
  height: 24px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
  color: ${COLORS.darkNavy};
  & > img {
    margin-right: 8px;
  }
`;

const EditorContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: white;
  min-height: 240px;
  [data-slate-editor] {
    width: 100%;
    padding: 21px auto 16px 25px;
    border-bottom: 1px solid ${COLORS.mist};
  }
`;

const SummaryPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PAGE_TEXT = {
  title: {
    agenda: 'Session Agenda',
    notes: 'Notes',
  },
  placeholder: {
    agenda: 'Choose from the meeting types below to get an agenda template or create your own agenda from stratch. ',
  },
};

const initialValue = [
  {
    type: 'p',
    children: [
      {
        text: 'This is editable plain text with react and history plugins, just like a <textarea>!',
      },
    ],
  } as MyParagraphElement,
];

const SessionContainer = styled.div``;

const Editor = (props: PlateProps<MyValue>) => <Plate {...props} />;

const Session = ({
  editorStyle = {
    width: '900px',
  },
}: {
  editorStyle?: React.CSSProperties;
}): JSX.Element => {
  const [s1, setS1] = useState<MyValue | null>(null);
  const [s2, setS2] = useState<MyValue | null>(null);
  const [s3, setS3] = useState<MyValue | null>(null);

  useEffect(() => {
    if (s1) {
      console.log('s1:', s1);
    }
  }, [s1]);
  useEffect(() => {
    if (s2) {
      console.log('s2:', s2);
    }
  }, [s2]);
  useEffect(() => {
    if (s3) {
      console.log('s3:', JSON.stringify(s3));
    }
  }, [s3]);
  const floatingWindowRef = useRef<ModalReturnType>();
  const onOpenFloatingWindow = async () => {
    console.log('onOpenFloatingWindow: ');
    if (document.getElementsByClassName('crimson-app-auto-floating-modal').length === 0) {
      console.log('onOpenFloatingWindow:if ');
      try {
        floatingWindowRef.current = popupModalInfo({
          width: '0px',
          wrapClassName: 'crimson-app-auto-floating-modal component-crimson-app-modal',
          zIndex: 1600,
          maskClosable: false,
          mask: false,
          keyboard: false,
          getContainer: () => document.getElementById('auto-floating-modal-root') as HTMLElement,
          content: <AutomaticFloatingSession />,
        });
      } catch (error) {
        console.log('error: ', error);
      }
    } else {
      floatingWindowRef.current?.destroy();
    }
  };
  const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;
  return (
    <SessionContainer>
      <PlateProvider<MyValue>
        id="Academics"
        plugins={basicNodesPlugins}
        initialValue={initialValue}
        onChange={(newValue) => {
          setS1(newValue);
          // save newValue...
        }}
      >
        <PlateProvider<MyValue>
          id="Testing"
          plugins={basicNodesPlugins}
          initialValue={initialValue}
          onChange={(newValue) => {
            setS2(newValue);
            // save newValue...
          }}
        >
          <PlateProvider<MyValue>
            id="Activities"
            plugins={basicNodesPlugins}
            initialValue={initialValue}
            onChange={(newValue) => {
              setS3(newValue);
              // save newValue...
            }}
          >
            <PlateProvider<MyValue>
              id="Honors"
              plugins={basicNodesPlugins}
              initialValue={initialValue}
              onChange={(newValue) => {
                setS3(newValue);
                // save newValue...
              }}
            >
              <PlateProvider<MyValue>
                id="Personal-Development"
                plugins={basicNodesPlugins}
                initialValue={initialValue}
                onChange={(newValue) => {
                  setS3(newValue);
                  // save newValue...
                }}
              >
                <PlateProvider<MyValue>
                  id="Careers"
                  plugins={basicNodesPlugins}
                  initialValue={initialValue}
                  onChange={(newValue) => {
                    setS3(newValue);
                    // save newValue...
                  }}
                >
                  <PlateProvider<MyValue>
                    id="Additional-Information"
                    plugins={basicNodesPlugins}
                    initialValue={initialValue}
                    onChange={(newValue) => {
                      setS3(newValue);
                      // save newValue...
                    }}
                  >
                    <SummaryPageContainer>
                      <EditorTitle
                        style={{
                          width: '900px',
                        }}
                      >
                        <img src="/static/automaticSession/agendaIcon.svg" alt="agenda-title-icon" />
                        {PAGE_TEXT.title.agenda}
                      </EditorTitle>
                      <EditorContainer style={editorStyle}>
                        <Editor id="agenda" />
                      </EditorContainer>
                      <EditorTitle style={editorStyle}>
                        <img src="/static/automaticSession/notesIcon.svg" alt="agenda-title-icon" />
                        {PAGE_TEXT.title.notes}
                      </EditorTitle>
                      <Collapse
                        defaultActiveKey={['Academics']}
                        ghost
                        style={{
                          ...editorStyle,
                          background: 'white',
                          paddingTop: '57px',
                          position: 'relative',
                        }}
                      >
                        {[
                          'Academics',
                          'Testing',
                          'Activities',
                          'Honors',
                          'Personal-Development',
                          'Careers',
                          'Additional-Information',
                        ].map((category) => (
                          <Panel header={category} key={category}>
                            <EditorContainer style={editorStyle}>
                              <Editor id={category} />
                            </EditorContainer>
                          </Panel>
                        ))}
                        <Toolbar>
                          <BasicMarkToolbarButtons />
                          <BasicElementToolbarButtons />
                        </Toolbar>
                      </Collapse>
                    </SummaryPageContainer>
                  </PlateProvider>
                </PlateProvider>
              </PlateProvider>
            </PlateProvider>
          </PlateProvider>
        </PlateProvider>
      </PlateProvider>
      <CloseFull onClick={onOpenFloatingWindow} />
    </SessionContainer>
  );
};

export default Session;
