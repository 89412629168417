import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import autoBind from 'auto-bind';

import css from './FlowLayout.scss';

export default class FlowLayout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activated: false,
      isDragging: false,
    };

    autoBind(this);
  }

  componentDidMount() {
    const { resizeable } = this.props;

    if (!resizeable) {
      return;
    }

    this.element.addEventListener('mousemove', this.resizePanel);
    this.element.addEventListener('mouseup', this.stopResize);
    this.element.addEventListener('mouseleave', this.stopResize);
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.vertical !== nextProps.vertical) {
      this.setState({ activated: false });
    }

    return true;
  }

  startResize(event, index) {
    const { vertical } = this.props;

    const initialPos = vertical ? event.clientY : event.clientX;
    const paneSizes = [...this.element.children]
      .filter((x, i) => i % 2 === 0)
      .map((x) => (vertical ? x.offsetHeight : x.offsetWidth));

    this.setState({
      activated: true,
      isDragging: true,
      currentPane: index,
      initialPos,
      paneSizes,
    });
  }

  stopResize() {
    if (!this.state.isDragging) {
      return;
    }

    this.setState((state) => {
      const { paneSizes, currentPane, delta } = state;

      paneSizes[currentPane - 1] += delta;
      paneSizes[currentPane] -= delta;

      return {
        isDragging: false,
        paneSizes,
        delta: 0,
      };
    });
  }

  resizePanel(event) {
    const { isDragging, initialPos } = this.state;
    if (!isDragging) {
      return;
    }

    const currentPos = this.props.vertical ? event.clientY : event.clientX;
    const delta = currentPos - initialPos;

    this.setState({ delta });
  }

  applySize(index, pane) {
    const { resizeable, vertical } = this.props;
    const { activated, paneSizes, currentPane, delta } = this.state;

    if (
      !resizeable ||
      !activated || // Don't modify the panes if not resized by user
      paneSizes.length - 1 === index // Don't set the last pane to cater for window resize
    ) {
      return pane;
    }

    const props = { ...pane.props };
    props.style = { ...props.style };

    let size = paneSizes[index];
    if (index === currentPane - 1) {
      size += delta;
    } else if (index === currentPane) {
      size -= delta;
    }

    if (vertical) {
      props.style.minHeight = size || 3;
      props.style.maxHeight = size;
    } else {
      props.style.minWidth = size || 3;
      props.style.maxWidth = size;
    }

    return {
      ...pane,
      props,
    };
  }

  render() {
    const { resizeable, vertical, className, children } = this.props;
    const { currentPane } = this.state;

    const direction = vertical ? css.vertical : css.horizontal;
    return (
      <div
        ref={(el) => {
          this.element = el;
        }}
        className={classNames(css.flowLayout, direction, className)}
      >
        {children.map((x, i) => {
          const pane = this.applySize(i, x);

          if (!resizeable || i === 0) {
            return pane;
          }

          return [
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <div
              key={`pane-${i}`}
              className={classNames(css.resizer, { [css.dragging]: currentPane === i })}
              onMouseDown={(e) => this.startResize(e, i)}
            />,
            pane,
          ];
        })}
      </div>
    );
  }
}

FlowLayout.propTypes = {
  className: PropTypes.string,
  resizeable: PropTypes.bool,
  vertical: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
};
