import React from 'react';
type BannerRightGraph1Props = {
  className?: string;
};
const BannerRightGraph2 = ({ className }: BannerRightGraph1Props) => {
  return (
    <svg
      className={className}
      width="116"
      height="115"
      viewBox="0 0 116 115"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_940_31876)">
        <path
          d="M45.6826 21.9577C46.2993 22.1927 46.9902 21.8819 47.2258 21.2637C47.4614 20.6454 47.1524 19.9537 46.5356 19.7187C45.9189 19.4837 45.228 19.7944 44.9924 20.4127C44.7568 21.031 45.0658 21.7227 45.6826 21.9577Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M51.1463 20.158C51.3117 19.9831 51.525 19.8612 51.7593 19.8077C51.9936 19.7541 52.2384 19.7713 52.4627 19.8571C52.687 19.9429 52.8808 20.0934 53.0196 20.2897C53.1584 20.4859 53.2359 20.719 53.2424 20.9596C53.2488 21.2002 53.184 21.4374 53.056 21.6413C52.928 21.8452 52.7426 22.0067 52.5233 22.1052C52.304 22.2037 52.0605 22.2349 51.8237 22.1949C51.5868 22.1549 51.3673 22.0454 51.1927 21.8803C51.0767 21.7705 50.9834 21.6389 50.9183 21.4929C50.8531 21.3468 50.8174 21.1894 50.8131 21.0294C50.8087 20.8694 50.836 20.7101 50.8932 20.5606C50.9503 20.4111 51.0364 20.2742 51.1463 20.158Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M56.9717 20.1938C57.1421 20.0166 57.3611 19.8938 57.601 19.8411C57.8408 19.7883 58.0908 19.808 58.3192 19.8976C58.5477 19.9871 58.7444 20.1426 58.8845 20.3443C59.0246 20.546 59.1018 20.7849 59.1063 21.0308C59.1108 21.2767 59.0425 21.5185 58.9099 21.7257C58.7773 21.9329 58.5865 22.0962 58.3615 22.1949C58.1365 22.2936 57.8874 22.3233 57.6458 22.2803C57.4042 22.2372 57.1809 22.1234 57.0041 21.9531C56.8866 21.8399 56.7925 21.7046 56.7273 21.555C56.6621 21.4053 56.627 21.2442 56.624 21.0808C56.621 20.9174 56.6501 20.755 56.7098 20.6028C56.7695 20.4506 56.8585 20.3116 56.9717 20.1938Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M62.6485 20.5256C62.8247 20.331 63.0549 20.1935 63.3095 20.1309C63.5641 20.0682 63.8315 20.0833 64.0772 20.1741C64.3229 20.265 64.5358 20.4274 64.6885 20.6406C64.8411 20.8537 64.9266 21.1079 64.9338 21.3703C64.9411 21.6327 64.8698 21.8915 64.7292 22.1133C64.5886 22.3351 64.385 22.5098 64.1447 22.6149C63.9044 22.72 63.6383 22.7508 63.3807 22.7032C63.123 22.6557 62.8856 22.532 62.6989 22.3481C62.4572 22.11 62.317 21.7873 62.3076 21.4476C62.2982 21.1079 62.4204 20.7775 62.6485 20.5256Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M59.9802 14.2893C60.1593 14.1008 60.39 13.9694 60.6433 13.912C60.8966 13.8546 61.161 13.8736 61.4033 13.9666C61.6455 14.0597 61.8547 14.2226 62.0044 14.4348C62.1541 14.647 62.2377 14.899 62.2444 15.159C62.2512 15.419 62.181 15.6753 62.0425 15.8955C61.9041 16.1158 61.7037 16.2901 61.4667 16.3965C61.2296 16.5029 60.9665 16.5366 60.7106 16.4933C60.4547 16.45 60.2175 16.3318 60.0288 16.1534C59.9032 16.0346 59.8022 15.892 59.7317 15.7339C59.6612 15.5759 59.6226 15.4053 59.6181 15.2321C59.6136 15.0589 59.6432 14.8865 59.7054 14.7247C59.7675 14.5629 59.8609 14.415 59.9802 14.2893Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M54.0353 14.3629C54.2102 14.1822 54.4346 14.0574 54.6801 14.0045C54.9257 13.9515 55.1812 13.9728 55.4144 14.0656C55.6477 14.1584 55.848 14.3185 55.99 14.5257C56.1321 14.7329 56.2095 14.9777 56.2124 15.2292C56.2153 15.4808 56.1436 15.7276 56.0063 15.9385C55.8691 16.1493 55.6725 16.3147 55.4415 16.4136C55.2105 16.5126 54.9555 16.5406 54.7088 16.4942C54.4622 16.4478 54.2349 16.329 54.0559 16.1529C53.8188 15.9173 53.6838 15.5976 53.6799 15.2628C53.6761 14.9279 53.8037 14.6048 54.0353 14.3629Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M48.1244 16.9501C48.7581 17.1915 49.468 16.8722 49.71 16.237C49.9521 15.6017 49.6346 14.891 49.0009 14.6496C48.3672 14.4081 47.6573 14.7274 47.4153 15.3626C47.1732 15.9979 47.4907 16.7086 48.1244 16.9501Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M96.0606 40.4199C96.762 40.6871 97.5479 40.3337 97.8158 39.6305C98.0837 38.9273 97.7323 38.1406 97.0308 37.8733C96.3294 37.6061 95.5435 37.9595 95.2756 38.6627C95.0077 39.3659 95.3591 40.1526 96.0606 40.4199Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M93.0918 35.3581C93.8 35.6279 94.5935 35.2711 94.864 34.5611C95.1345 33.8511 94.7797 33.0568 94.0714 32.7869C93.3632 32.5171 92.5698 32.8739 92.2992 33.5839C92.0287 34.2939 92.3836 35.0882 93.0918 35.3581Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M89.4638 37.8005C89.645 37.617 89.8759 37.4907 90.1279 37.4374C90.3799 37.3842 90.6418 37.4062 90.8811 37.5008C91.1204 37.5955 91.3266 37.7585 91.474 37.9697C91.6214 38.1809 91.7036 38.431 91.7103 38.6888C91.7169 38.9466 91.6478 39.2008 91.5116 39.4199C91.3753 39.6389 91.1779 39.8131 90.9438 39.9209C90.7097 40.0286 90.4493 40.0651 90.1949 40.0258C89.9405 39.9866 89.7034 39.8732 89.513 39.7C89.382 39.5807 89.2764 39.4361 89.2026 39.2748C89.1289 39.1135 89.0885 38.9389 89.0839 38.7614C89.0793 38.584 89.1106 38.4074 89.1759 38.2422C89.2412 38.0771 89.3391 37.9268 89.4638 37.8005Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M92.5201 45.5652C93.2046 45.826 93.9715 45.4811 94.233 44.7949C94.4944 44.1087 94.1515 43.3409 93.467 43.0801C92.7824 42.8193 92.0156 43.1642 91.7541 43.8504C91.4927 44.5366 91.8356 45.3044 92.5201 45.5652Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M97.7997 43.4915C97.9843 43.2975 98.2221 43.1625 98.483 43.1036C98.744 43.0447 99.0163 43.0645 99.2657 43.1606C99.5152 43.2567 99.7304 43.4247 99.8844 43.6434C100.038 43.8622 100.124 44.1218 100.131 44.3896C100.138 44.6573 100.065 44.9212 99.9223 45.1479C99.7795 45.3746 99.5729 45.554 99.3287 45.6634C99.0844 45.7727 98.8134 45.8072 98.5499 45.7624C98.2864 45.7176 98.0421 45.5956 97.848 45.4118C97.5883 45.1634 97.4375 44.8221 97.4284 44.4623C97.4194 44.1025 97.5529 43.7534 97.7997 43.4915Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M100.372 48.4511C100.553 48.2676 100.784 48.1413 101.036 48.0881C101.288 48.0348 101.55 48.0568 101.789 48.1515C102.029 48.2461 102.235 48.4092 102.382 48.6204C102.53 48.8316 102.612 49.0816 102.618 49.3394C102.625 49.5973 102.556 49.8515 102.42 50.0705C102.284 50.2896 102.086 50.4638 101.852 50.5715C101.618 50.6792 101.357 50.7157 101.103 50.6765C100.849 50.6372 100.612 50.5239 100.421 50.3506C100.29 50.2314 100.185 50.0867 100.111 49.9254C100.037 49.7641 99.9967 49.5895 99.9921 49.4121C99.9875 49.2346 100.019 49.058 100.084 48.8929C100.149 48.7277 100.247 48.5775 100.372 48.4511Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M100.31 55.7883C100.984 56.0453 101.74 55.7055 101.997 55.0295C102.255 54.3535 101.917 53.5971 101.242 53.3402C100.568 53.0833 99.8127 53.423 99.5551 54.0991C99.2975 54.7751 99.6354 55.5314 100.31 55.7883Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M101.247 58.9705C101.423 58.7886 101.649 58.663 101.896 58.6098C102.143 58.5565 102.4 58.578 102.634 58.6715C102.869 58.7649 103.07 58.9262 103.213 59.1347C103.356 59.3432 103.433 59.5896 103.436 59.8426C103.438 60.0957 103.366 60.3438 103.227 60.5557C103.089 60.7676 102.891 60.9336 102.658 61.0326C102.426 61.1316 102.169 61.1592 101.921 61.1118C101.673 61.0645 101.445 60.9443 101.265 60.7666C101.028 60.5296 100.893 60.2087 100.89 59.8729C100.887 59.5371 101.015 59.2131 101.247 58.9705Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M99.9643 65.3071C100.131 65.1311 100.345 65.0084 100.581 64.9545C100.817 64.9006 101.063 64.918 101.289 65.0044C101.514 65.0909 101.709 65.2425 101.849 65.4401C101.988 65.6377 102.066 65.8724 102.072 66.1145C102.078 66.3566 102.013 66.5952 101.883 66.8001C101.754 67.005 101.567 67.167 101.346 67.2656C101.125 67.3643 100.88 67.395 100.642 67.3541C100.404 67.3131 100.183 67.2023 100.008 67.0356C99.7741 66.8124 99.6378 66.5051 99.6296 66.1811C99.6213 65.857 99.7417 65.5427 99.9643 65.3071Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M98.0874 70.8049C98.2474 70.6348 98.454 70.5157 98.6811 70.4628C98.9083 70.4098 99.1458 70.4253 99.364 70.5073C99.5822 70.5892 99.7712 70.734 99.9073 70.9234C100.043 71.1129 100.12 71.3385 100.129 71.5719C100.137 71.8053 100.076 72.036 99.9543 72.2352C99.8322 72.4344 99.6541 72.593 99.4424 72.6912C99.2307 72.7893 98.9948 72.8226 98.7645 72.7869C98.5342 72.7512 98.3196 72.648 98.1479 72.4903C98.0333 72.384 97.9408 72.2559 97.8757 72.1136C97.8106 71.9712 97.7742 71.8174 97.7686 71.6608C97.7629 71.5042 97.7882 71.348 97.8429 71.2012C97.8976 71.0543 97.9807 70.9197 98.0874 70.8049Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M96.3062 76.0504C96.5173 75.8303 96.8064 75.7024 97.1107 75.6943C97.415 75.6863 97.71 75.7989 97.9316 76.0076C98.0962 76.1643 98.2107 76.3666 98.2604 76.5887C98.3102 76.8108 98.2931 77.0429 98.2113 77.2555C98.1295 77.4681 97.9867 77.6517 97.8008 77.7832C97.615 77.9146 97.3945 77.988 97.1673 77.994C96.94 77.9999 96.7162 77.9383 96.5242 77.8168C96.3321 77.6953 96.1804 77.5195 96.0882 77.3114C95.9961 77.1034 95.9676 76.8726 96.0064 76.6481C96.0452 76.4237 96.1495 76.2157 96.3062 76.0504Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M91.8287 75.1331C92.4082 75.3538 93.0574 75.0619 93.2787 74.481C93.5 73.9001 93.2097 73.2502 92.6303 73.0294C92.0508 72.8086 91.4016 73.1006 91.1803 73.6815C90.959 74.2624 91.2493 74.9123 91.8287 75.1331Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M93.5815 66.531C93.7415 66.3608 93.948 66.2418 94.1752 66.1888C94.4023 66.1359 94.6399 66.1513 94.8581 66.2333C95.0763 66.3153 95.2653 66.4601 95.4013 66.6495C95.5374 66.8389 95.6145 67.0645 95.6229 67.2979C95.6312 67.5313 95.5705 67.7621 95.4484 67.9613C95.3263 68.1604 95.1482 68.3191 94.9365 68.4172C94.7248 68.5154 94.4889 68.5487 94.2586 68.513C94.0283 68.4772 93.8137 68.3741 93.642 68.2164C93.5266 68.1105 93.4334 67.9826 93.3678 67.8402C93.3022 67.6978 93.2655 67.5438 93.2599 67.387C93.2543 67.2301 93.2798 67.0737 93.335 66.9268C93.3902 66.7798 93.474 66.6453 93.5815 66.531Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M88.3571 68.126C88.5129 67.9619 88.7136 67.8478 88.9339 67.7978C89.1543 67.7479 89.3843 67.7645 89.595 67.8454C89.8058 67.9264 89.9877 68.0681 90.118 68.2527C90.2482 68.4373 90.3209 68.6565 90.3268 68.8827C90.3327 69.1088 90.2716 69.3318 90.1512 69.5234C90.0308 69.715 89.8565 69.8667 89.6503 69.9593C89.4441 70.0519 89.2153 70.0812 88.9927 70.0436C88.77 70.0061 88.5636 69.9033 88.3995 69.7482C88.1801 69.5385 88.0525 69.2503 88.0446 68.9464C88.0366 68.6425 88.149 68.3476 88.3571 68.126Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M84.9314 73.0971C85.0791 72.9414 85.2694 72.8329 85.4784 72.7854C85.6875 72.7379 85.9057 72.7535 86.1057 72.8303C86.3056 72.907 86.4783 73.0415 86.6018 73.2166C86.7254 73.3917 86.7943 73.5997 86.7999 73.8143C86.8054 74.0288 86.7474 74.2403 86.6331 74.422C86.5188 74.6037 86.3533 74.7475 86.1577 74.8352C85.962 74.9229 85.7448 74.9506 85.5337 74.9148C85.3225 74.8789 85.1268 74.7811 84.9713 74.6338C84.867 74.5362 84.7832 74.4189 84.7246 74.2885C84.6661 74.1581 84.6341 74.0173 84.6304 73.8743C84.6267 73.7313 84.6514 73.589 84.703 73.4556C84.7547 73.3221 84.8323 73.2003 84.9314 73.0971Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M87.2249 80.4862C87.7671 80.6928 88.3745 80.4196 88.5816 79.8761C88.7887 79.3325 88.5171 78.7244 87.9749 78.5178C87.4327 78.3113 86.8253 78.5844 86.6182 79.128C86.4111 79.6715 86.6827 80.2796 87.2249 80.4862Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M92.8079 82.1346C93.3636 82.3463 93.9862 82.0663 94.1985 81.5092C94.4107 80.9521 94.1323 80.3288 93.5766 80.117C93.0208 79.9053 92.3982 80.1853 92.186 80.7424C91.9737 81.2995 92.2521 81.9228 92.8079 82.1346Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M88.3017 86.9545C88.8202 87.152 89.401 86.8908 89.599 86.3711C89.7971 85.8513 89.5373 85.2698 89.0188 85.0723C88.5004 84.8747 87.9195 85.1359 87.7215 85.6557C87.5235 86.1755 87.7832 86.757 88.3017 86.9545Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M82.2505 85.5927C82.7453 85.7812 83.2995 85.532 83.4885 85.036C83.6775 84.54 83.4296 83.9851 82.9349 83.7966C82.4401 83.6081 81.8859 83.8574 81.6969 84.3534C81.5079 84.8494 81.7558 85.4042 82.2505 85.5927Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M82.9899 90.3827C83.1143 90.2515 83.2746 90.1602 83.4507 90.1202C83.6268 90.0802 83.8106 90.0934 83.979 90.158C84.1474 90.2227 84.2929 90.3359 84.397 90.4835C84.501 90.631 84.5591 90.8062 84.5638 90.9869C84.5685 91.1676 84.5196 91.3458 84.4233 91.4989C84.3271 91.6519 84.1877 91.7731 84.0229 91.847C83.8581 91.9209 83.6752 91.9442 83.4973 91.9141C83.3194 91.8839 83.1546 91.8016 83.0235 91.6775C82.9363 91.5949 82.8662 91.4959 82.8173 91.3861C82.7684 91.2763 82.7416 91.1578 82.7385 91.0375C82.7354 90.9173 82.7559 90.7975 82.7991 90.6851C82.8422 90.5728 82.907 90.47 82.9899 90.3827Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M78.29 96.7067C78.7102 96.8668 79.1809 96.6551 79.3414 96.2339C79.5019 95.8126 79.2914 95.3413 78.8712 95.1812C78.451 95.0211 77.9802 95.2328 77.8197 95.6541C77.6592 96.0753 77.8698 96.5466 78.29 96.7067Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M72.3332 99.6355C72.7059 99.7776 73.1235 99.5898 73.2659 99.2161C73.4083 98.8424 73.2215 98.4243 72.8488 98.2823C72.476 98.1403 72.0584 98.3281 71.916 98.7018C71.7737 99.0754 71.9604 99.4935 72.3332 99.6355Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M76.2236 88.8895C76.3837 88.734 76.5973 88.6461 76.82 88.6441C77.0427 88.642 77.2576 88.7259 77.42 88.8784C77.5824 89.0308 77.6799 89.2402 77.6922 89.463C77.7046 89.6858 77.6309 89.9049 77.4864 90.075C77.3263 90.2305 77.1128 90.3183 76.8901 90.3204C76.6673 90.3225 76.4525 90.2386 76.2901 90.0861C76.1277 89.9337 76.0302 89.7243 76.0178 89.5015C76.0054 89.2787 76.0791 89.0596 76.2236 88.8895Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M70.1901 91.6483C70.2952 91.5363 70.4311 91.4581 70.5806 91.4234C70.73 91.3888 70.8863 91.3993 71.0296 91.4537C71.1729 91.508 71.2968 91.6038 71.3857 91.7288C71.4746 91.8539 71.5244 92.0026 71.5288 92.1561C71.5333 92.3096 71.4921 92.4611 71.4107 92.5914C71.3292 92.7217 71.211 92.8249 71.0711 92.8879C70.9312 92.951 70.7758 92.9711 70.6246 92.9457C70.4735 92.9203 70.3333 92.8505 70.2218 92.7452C70.0726 92.6041 69.9854 92.4094 69.9794 92.2037C69.9734 91.998 70.0492 91.7983 70.1901 91.6483Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M63.9388 95.296C64.029 95.2037 64.1444 95.14 64.2705 95.1128C64.3965 95.0856 64.5277 95.0962 64.6477 95.1433C64.7677 95.1903 64.8711 95.2717 64.9452 95.3773C65.0192 95.4829 65.0606 95.608 65.0641 95.7371C65.0676 95.8662 65.0331 95.9935 64.9649 96.1032C64.8968 96.2129 64.7979 96.3002 64.6807 96.3541C64.5634 96.408 64.433 96.4262 64.3057 96.4064C64.1783 96.3865 64.0597 96.3296 63.9645 96.2427C63.8994 96.1831 63.8469 96.1111 63.8102 96.0308C63.7735 95.9504 63.7534 95.8635 63.751 95.7751C63.7486 95.6868 63.764 95.5988 63.7962 95.5165C63.8285 95.4342 63.877 95.3592 63.9388 95.296Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M65.7143 102.178C66.0328 102.3 66.3897 102.139 66.5113 101.82C66.633 101.501 66.4734 101.143 66.1549 101.022C65.8363 100.901 65.4795 101.061 65.3578 101.38C65.2362 101.7 65.3957 102.057 65.7143 102.178Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M58.0261 104.342C58.2769 104.437 58.5578 104.311 58.6536 104.059C58.7494 103.808 58.6237 103.527 58.373 103.431C58.1222 103.336 57.8413 103.462 57.7455 103.713C57.6497 103.965 57.7754 104.246 58.0261 104.342Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M56.9785 95.694C57.2869 95.8115 57.6323 95.6562 57.7501 95.347C57.8679 95.0379 57.7134 94.692 57.405 94.5746C57.0967 94.4571 56.7512 94.6124 56.6334 94.9216C56.5156 95.2307 56.6701 95.5766 56.9785 95.694Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M50.1304 100.298C50.1685 100.253 50.2152 100.216 50.2678 100.19C50.3203 100.164 50.3777 100.149 50.4362 100.145C50.4948 100.142 50.5535 100.151 50.6086 100.171C50.6637 100.191 50.7142 100.222 50.757 100.262C50.7998 100.302 50.834 100.351 50.8575 100.404C50.881 100.458 50.8933 100.516 50.8938 100.575C50.8942 100.634 50.8828 100.692 50.8601 100.746C50.8374 100.801 50.8039 100.85 50.7618 100.891C50.7237 100.935 50.677 100.972 50.6244 100.998C50.5718 101.024 50.5145 101.04 50.4559 101.043C50.3974 101.046 50.3387 101.038 50.2836 101.018C50.2284 100.998 50.178 100.967 50.1352 100.927C50.0924 100.886 50.0582 100.838 50.0347 100.784C50.0112 100.73 49.9988 100.672 49.9984 100.613C49.9979 100.555 50.0094 100.496 50.0321 100.442C50.0548 100.388 50.0882 100.339 50.1304 100.298Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M42.0821 97.0766C42.1311 97.0246 42.1944 96.9883 42.264 96.9723C42.3336 96.9563 42.4063 96.9613 42.473 96.9867C42.5397 97.012 42.5973 97.0566 42.6387 97.1148C42.6801 97.173 42.7033 97.2422 42.7054 97.3136C42.7075 97.3851 42.6885 97.4556 42.6507 97.5163C42.6129 97.577 42.558 97.6252 42.493 97.6547C42.4279 97.6842 42.3556 97.6938 42.2852 97.6822C42.2148 97.6706 42.1495 97.6384 42.0974 97.5896C42.0618 97.5576 42.0332 97.5186 42.013 97.4751C41.9929 97.4316 41.9818 97.3845 41.9804 97.3366C41.979 97.2886 41.9872 97.2409 42.0047 97.1962C42.0222 97.1515 42.0485 97.1109 42.0821 97.0766Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M33.6485 92.4542C33.6745 92.4188 33.7077 92.3892 33.7459 92.3676C33.7841 92.346 33.8265 92.3328 33.8702 92.3288C33.9139 92.3249 33.9579 92.3303 33.9993 92.3448C34.0407 92.3592 34.0785 92.3823 34.1103 92.4126C34.1421 92.4429 34.167 92.4796 34.1835 92.5203C34.1999 92.561 34.2075 92.6048 34.2058 92.6487C34.204 92.6926 34.193 92.7357 34.1733 92.775C34.1537 92.8143 34.1259 92.849 34.0918 92.8767C34.0354 92.9351 33.9585 92.969 33.8775 92.9711C33.7965 92.9732 33.718 92.9433 33.6589 92.8879C33.6293 92.8604 33.6056 92.8273 33.589 92.7905C33.5724 92.7537 33.5633 92.7139 33.5624 92.6734C33.5614 92.633 33.5685 92.5928 33.5833 92.5551C33.5981 92.5175 33.6203 92.4832 33.6485 92.4542Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M27.121 84.8184C27.1508 84.7865 27.1867 84.7608 27.2265 84.7429C27.2663 84.7251 27.3092 84.7153 27.3528 84.7142C27.3964 84.7131 27.4398 84.7207 27.4803 84.7366C27.5209 84.7524 27.558 84.7762 27.5892 84.8066C27.6504 84.8672 27.6859 84.9491 27.6884 85.0352C27.6909 85.1214 27.6601 85.2053 27.6026 85.2695C27.5727 85.3014 27.5369 85.3271 27.4971 85.345C27.4573 85.3629 27.4143 85.3727 27.3708 85.3738C27.3272 85.3749 27.2838 85.3672 27.2432 85.3514C27.2026 85.3355 27.1656 85.3117 27.1343 85.2813C27.0732 85.2208 27.0377 85.1389 27.0352 85.0527C27.0327 84.9665 27.0634 84.8826 27.121 84.8184Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M28.6043 76.7403C28.855 76.8359 29.1359 76.7095 29.2317 76.4581C29.3275 76.2068 29.2019 75.9255 28.9511 75.83C28.7004 75.7344 28.4194 75.8608 28.3236 76.1122C28.2279 76.3635 28.3535 76.6448 28.6043 76.7403Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M36.4418 74.4438C36.7604 74.5651 37.1172 74.4047 37.2389 74.0853C37.3605 73.766 37.201 73.4087 36.8824 73.2874C36.5639 73.166 36.207 73.3265 36.0854 73.6458C35.9637 73.9652 36.1233 74.3224 36.4418 74.4438Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M35.3911 82.1086C35.4596 82.0399 35.5466 81.9929 35.6415 81.9735C35.7363 81.954 35.8347 81.963 35.9244 81.9992C36.0141 82.0355 36.0911 82.0974 36.1458 82.1773C36.2005 82.2572 36.2305 82.3515 36.232 82.4484C36.2335 82.5454 36.2065 82.6407 36.1543 82.7224C36.1022 82.8041 36.0271 82.8687 35.9386 82.9081C35.8501 82.9474 35.752 82.9598 35.6566 82.9436C35.5612 82.9275 35.4727 82.8835 35.4022 82.8172C35.3541 82.772 35.3156 82.7176 35.2889 82.6572C35.2622 82.5968 35.248 82.5316 35.2469 82.4655C35.2459 82.3994 35.2581 82.3338 35.2829 82.2725C35.3077 82.2112 35.3445 82.1554 35.3911 82.1086Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M42.9193 79.6095C43.2446 79.7335 43.6091 79.5696 43.7333 79.2435C43.8576 78.9173 43.6946 78.5525 43.3693 78.4285C43.044 78.3046 42.6795 78.4685 42.5553 78.7946C42.431 79.1207 42.594 79.4856 42.9193 79.6095Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M49.2669 84.1203C49.599 84.2468 49.9711 84.0795 50.0979 83.7466C50.2248 83.4136 50.0584 83.0412 49.7263 82.9146C49.3942 82.7881 49.0222 82.9554 48.8953 83.2883C48.7685 83.6213 48.9349 83.9937 49.2669 84.1203Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M41.9214 87.4849C42.0146 87.3907 42.1405 87.3361 42.2727 87.3326C42.405 87.3292 42.5334 87.3772 42.631 87.4665C42.702 87.5349 42.7511 87.6227 42.7723 87.7191C42.7934 87.8154 42.7856 87.9159 42.7499 88.0079C42.7142 88.0999 42.6521 88.1793 42.5714 88.2362C42.4908 88.293 42.3953 88.3246 42.2968 88.3272C42.1984 88.3298 42.1014 88.3031 42.0181 88.2505C41.9349 88.198 41.869 88.1219 41.8289 88.0318C41.7887 87.9418 41.7761 87.8418 41.7926 87.7445C41.809 87.6472 41.8538 87.5569 41.9214 87.4849Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M49.3124 91.4348C49.4129 91.33 49.5504 91.269 49.6953 91.2648C49.8402 91.2607 49.9808 91.3139 50.0867 91.4128C50.1842 91.5141 50.2388 91.6492 50.2392 91.79C50.2396 91.9307 50.1857 92.0663 50.0888 92.1684C49.9919 92.2705 49.8594 92.3313 49.7191 92.338C49.5787 92.3446 49.4413 92.2968 49.3354 92.2043C49.2325 92.1044 49.1725 91.9682 49.1682 91.8245C49.1639 91.6808 49.2156 91.5411 49.3124 91.4348Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M55.9382 86.6407C55.9978 86.5729 56.0706 86.5182 56.1521 86.4798C56.2336 86.4414 56.3221 86.4202 56.4121 86.4175C56.5021 86.4148 56.5916 86.4306 56.6752 86.4641C56.7587 86.4975 56.8345 86.5478 56.8978 86.6118C57.0292 86.7366 57.1056 86.9086 57.1102 87.09C57.1149 87.2715 57.0474 87.4475 56.9227 87.5793C56.7979 87.7111 56.6261 87.7879 56.445 87.7929C56.2639 87.7979 56.0883 87.7306 55.957 87.6058C55.8894 87.5456 55.8351 87.4721 55.7972 87.3898C55.7594 87.3075 55.739 87.2183 55.7373 87.1277C55.7355 87.0371 55.7524 86.947 55.787 86.8632C55.8216 86.7794 55.8731 86.7037 55.9382 86.6407Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M63.3234 89.8279C63.7029 89.9725 64.1281 89.7813 64.273 89.4008C64.418 89.0203 64.2279 88.5947 63.8483 88.4501C63.4688 88.3055 63.0436 88.4967 62.8986 88.8771C62.7537 89.2576 62.9438 89.6833 63.3234 89.8279Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M62.2695 83.1713C62.6829 83.3288 63.1461 83.1205 63.304 82.7061C63.4619 82.2916 63.2547 81.828 62.8413 81.6705C62.4279 81.5129 61.9648 81.7212 61.8069 82.1357C61.6489 82.5501 61.8561 83.0138 62.2695 83.1713Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M55.3686 78.8023C55.4726 78.6906 55.6074 78.6124 55.7558 78.5776C55.9042 78.5427 56.0595 78.5529 56.202 78.6067C56.3444 78.6605 56.4677 78.7555 56.556 78.8797C56.6444 79.0039 56.6938 79.1517 56.6981 79.3043C56.7024 79.4568 56.6613 79.6073 56.58 79.7366C56.4988 79.8658 56.3811 79.9681 56.2418 80.0303C56.1026 80.0924 55.9481 80.1118 55.798 80.0858C55.6479 80.0599 55.509 79.9898 55.3989 79.8845C55.3257 79.8158 55.2668 79.7331 55.2257 79.6414C55.1845 79.5497 55.1619 79.4507 55.159 79.3501C55.1562 79.2495 55.1733 79.1494 55.2093 79.0553C55.2452 78.9613 55.2994 78.8753 55.3686 78.8023Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M49.5134 74.8245C49.6149 74.7156 49.7464 74.6394 49.8911 74.6056C50.0358 74.5718 50.1872 74.5818 50.3261 74.6344C50.4649 74.687 50.585 74.7799 50.6709 74.9011C50.7568 75.0224 50.8048 75.1665 50.8086 75.3153C50.8125 75.4641 50.7721 75.6107 50.6926 75.7365C50.6131 75.8624 50.4981 75.9617 50.3622 76.022C50.2263 76.0823 50.0756 76.1007 49.9294 76.075C49.7831 76.0492 49.6479 75.9804 49.5409 75.8774C49.4699 75.8103 49.4129 75.7298 49.3731 75.6405C49.3333 75.5511 49.3115 75.4548 49.3089 75.357C49.3064 75.2592 49.3231 75.1618 49.3582 75.0704C49.3933 74.979 49.4461 74.8954 49.5134 74.8245Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M43.3346 70.5894C43.4337 70.4843 43.5617 70.4109 43.7023 70.3786C43.8429 70.3462 43.9898 70.3564 44.1246 70.4077C44.2593 70.4591 44.3757 70.5493 44.4591 70.6671C44.5425 70.7848 44.5891 70.9248 44.5931 71.0692C44.597 71.2136 44.5581 71.356 44.4813 71.4784C44.4045 71.6009 44.2932 71.6978 44.1616 71.7569C44.0299 71.816 43.8837 71.8347 43.7416 71.8106C43.5994 71.7866 43.4676 71.7208 43.3629 71.6216C43.2226 71.4887 43.1408 71.3053 43.1355 71.1117C43.1302 70.9182 43.2018 70.7303 43.3346 70.5894Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M44.665 64.6088C45.0852 64.7689 45.5559 64.5572 45.7164 64.136C45.8769 63.7147 45.6664 63.2434 45.2462 63.0833C44.826 62.9232 44.3552 63.1349 44.1947 63.5562C44.0342 63.9774 44.2448 64.4487 44.665 64.6088Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M50.139 67.3002C50.2509 67.1769 50.3969 67.0898 50.5583 67.0501C50.7197 67.0104 50.8892 67.0199 51.0451 67.0774C51.2009 67.1348 51.336 67.2376 51.433 67.3726C51.53 67.5076 51.5845 67.6686 51.5896 67.835C51.5946 68.0013 51.55 68.1655 51.4613 68.3064C51.3727 68.4474 51.2441 68.5586 51.092 68.626C50.9399 68.6933 50.7714 68.7137 50.6078 68.6844C50.4443 68.6551 50.2933 68.5776 50.1742 68.4617C50.0175 68.3116 49.9261 68.1058 49.9195 67.8886C49.9129 67.6713 49.9917 67.46 50.139 67.3002Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M55.9413 70.828C56.0576 70.7051 56.2075 70.6195 56.3723 70.5819C56.537 70.5444 56.7091 70.5566 56.8667 70.617C57.0243 70.6775 57.1605 70.7834 57.2579 70.9215C57.3553 71.0595 57.4096 71.2235 57.4139 71.3926C57.4183 71.5617 57.3725 71.7284 57.2823 71.8716C57.1921 72.0148 57.0616 72.1281 56.9073 72.1971C56.753 72.2661 56.5818 72.2878 56.4154 72.2593C56.249 72.2309 56.0948 72.1536 55.9723 72.0373C55.891 71.9601 55.8257 71.8675 55.7802 71.7649C55.7346 71.6624 55.7097 71.5518 55.7068 71.4395C55.7039 71.3272 55.7231 71.2154 55.7634 71.1105C55.8036 71.0055 55.864 70.9095 55.9413 70.828Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M61.4319 76.2027C61.8759 76.3719 62.3732 76.1482 62.5427 75.7032C62.7123 75.2582 62.4899 74.7603 62.046 74.5912C61.6021 74.422 61.1047 74.6457 60.9352 75.0907C60.7656 75.5357 60.988 76.0336 61.4319 76.2027Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M67.5764 77.9099C67.6986 77.7793 67.8568 77.6881 68.0308 77.6477C68.2047 77.6074 68.3867 77.6198 68.5534 77.6833C68.7202 77.7468 68.8642 77.8587 68.9672 78.0045C69.0702 78.1504 69.1275 78.3238 69.1319 78.5026C69.1362 78.6813 69.0874 78.8575 68.9916 79.0085C68.8958 79.1596 68.7574 79.2788 68.5939 79.3509C68.4305 79.423 68.2494 79.4449 68.0737 79.4136C67.8979 79.3824 67.7356 79.2995 67.6072 79.1754C67.5222 79.0945 67.454 78.9976 67.4064 78.8902C67.3589 78.7828 67.3329 78.667 67.3301 78.5495C67.3272 78.432 67.3475 78.315 67.3898 78.2052C67.432 78.0955 67.4955 77.9951 67.5764 77.9099Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M69.2407 84.5523C69.3954 84.3906 69.6075 84.2965 69.8308 84.2906C70.0541 84.2847 70.2706 84.3673 70.4332 84.5206C70.5957 84.676 70.6899 84.8898 70.6951 85.115C70.7002 85.3402 70.6158 85.5583 70.4605 85.7214C70.3051 85.8846 70.0916 85.9793 69.8668 85.9848C69.642 85.9903 69.4244 85.9061 69.2618 85.7508C69.1007 85.5948 69.008 85.381 69.004 85.1563C69 84.9317 69.0851 84.7145 69.2407 84.5523Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M75.6519 82.8462C75.7779 82.715 75.9398 82.6241 76.1172 82.5848C76.2945 82.5455 76.4794 82.5598 76.6486 82.6256C76.8177 82.6915 76.9635 82.8062 77.0676 82.9551C77.1717 83.104 77.2294 83.2805 77.2334 83.4624C77.2374 83.6442 77.1876 83.8233 77.0902 83.977C76.9929 84.1308 76.8523 84.2522 76.6863 84.3261C76.5202 84.4 76.3361 84.4231 76.1572 84.3923C75.9783 84.3616 75.8125 84.2784 75.6809 84.1534C75.5929 84.0698 75.5223 83.9696 75.4732 83.8586C75.4242 83.7475 75.3975 83.6278 75.3948 83.5063C75.3921 83.3848 75.4134 83.2639 75.4576 83.1506C75.5017 83.0374 75.5677 82.9339 75.6519 82.8462Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M80.5149 77.9581C80.652 77.8135 80.8286 77.7128 81.0226 77.6687C81.2166 77.6247 81.4192 77.6392 81.6047 77.7106C81.7902 77.7819 81.9504 77.9069 82.0649 78.0696C82.1794 78.2322 82.243 78.4254 82.2479 78.6245C82.2527 78.8237 82.1984 79.0199 82.0919 79.1883C81.9855 79.3567 81.8316 79.4898 81.6497 79.5707C81.4679 79.6515 81.2663 79.6766 81.0704 79.6427C80.8745 79.6087 80.6932 79.5173 80.5493 79.38C80.4532 79.2894 80.376 79.1807 80.3222 79.0599C80.2684 78.9392 80.2391 78.809 80.2359 78.6767C80.2327 78.5445 80.2557 78.4129 80.3036 78.2895C80.3515 78.1662 80.4233 78.0535 80.5149 77.9581Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M73.7496 77.402C73.8801 77.264 74.0484 77.1678 74.2333 77.1255C74.4182 77.0832 74.6113 77.0968 74.7883 77.1644C74.9654 77.2321 75.1183 77.3508 75.2279 77.5057C75.3374 77.6605 75.3986 77.8444 75.4038 78.0343C75.409 78.2241 75.3579 78.4113 75.257 78.5723C75.156 78.7332 75.0098 78.8607 74.8367 78.9385C74.6637 79.0163 74.4716 79.041 74.2847 79.0095C74.0978 78.9781 73.9245 78.8918 73.7867 78.7616C73.695 78.6749 73.6213 78.571 73.5699 78.4558C73.5184 78.3406 73.4901 78.2162 73.4867 78.09C73.4832 77.9637 73.5047 77.8379 73.5498 77.7199C73.5949 77.6018 73.6628 77.4938 73.7496 77.402Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M72.1475 71.4452C72.2842 71.3029 72.4597 71.2041 72.6521 71.1614C72.8445 71.1187 73.0451 71.134 73.2287 71.2053C73.4122 71.2766 73.5705 71.4007 73.6837 71.5621C73.7968 71.7234 73.8598 71.9148 73.8645 72.1121C73.8693 72.3093 73.8157 72.5037 73.7105 72.6707C73.6053 72.8378 73.4532 72.97 73.2733 73.0507C73.0934 73.1314 72.8938 73.157 72.6996 73.1244C72.5054 73.0917 72.3253 73.0022 72.1819 72.8671C71.9894 72.6833 71.8776 72.4304 71.8712 72.1638C71.8647 71.8972 71.9641 71.6388 72.1475 71.4452Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M78.722 74.0138C79.2574 74.2178 79.8573 73.948 80.0618 73.4113C80.2663 72.8745 79.998 72.274 79.4626 72.0701C78.9272 71.8661 78.3274 72.1358 78.1229 72.6725C77.9184 73.2093 78.1866 73.8098 78.722 74.0138Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M82.0659 67.5814C82.2173 67.4242 82.4116 67.3152 82.6244 67.2683C82.8372 67.2214 83.059 67.2387 83.2618 67.3179C83.4647 67.397 83.6395 67.5347 83.7643 67.7134C83.889 67.892 83.9581 68.1038 83.9629 68.322C83.9677 68.5402 83.9079 68.755 83.791 68.9394C83.6742 69.1237 83.5056 69.2695 83.3064 69.3581C83.1072 69.4468 82.8864 69.4745 82.6717 69.4376C82.4571 69.4008 82.2582 69.3012 82.1002 69.1512C81.9945 69.0509 81.9097 68.9306 81.8507 68.7972C81.7918 68.6638 81.7597 68.52 81.7566 68.374C81.7534 68.2281 81.7791 68.0829 81.8322 67.9468C81.8853 67.8108 81.9647 67.6866 82.0659 67.5814Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M80.6402 63.5586C81.2265 63.7819 81.8832 63.4866 82.1072 62.8989C82.3311 62.3112 82.0374 61.6537 81.4511 61.4303C80.8649 61.2069 80.2081 61.5023 79.9842 62.09C79.7603 62.6777 80.054 63.3352 80.6402 63.5586Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M78.4995 56.0652C78.6047 55.954 78.7306 55.8646 78.8701 55.8021C79.0096 55.7396 79.16 55.7053 79.3127 55.701C79.4654 55.6967 79.6173 55.7225 79.7599 55.7771C79.9025 55.8317 80.033 55.9139 80.1438 56.019C80.2546 56.1241 80.3436 56.2501 80.4058 56.3898C80.4679 56.5295 80.502 56.6801 80.506 56.833C80.51 56.986 80.484 57.1383 80.4293 57.2813C80.3746 57.4242 80.2923 57.555 80.1872 57.6662C79.9749 57.8908 79.6824 58.0218 79.3741 58.0305C79.0657 58.0392 78.7668 57.9248 78.543 57.7125C78.3192 57.5001 78.1889 57.2073 78.1807 56.8984C78.1726 56.5895 78.2873 56.2898 78.4995 56.0652Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M76.561 50.7693C76.721 50.5991 76.9275 50.4801 77.1547 50.4271C77.3818 50.3742 77.6194 50.3896 77.8376 50.4716C78.0558 50.5536 78.2448 50.6984 78.3808 50.8878C78.5169 51.0772 78.594 51.3028 78.6023 51.5362C78.6107 51.7696 78.55 52.0004 78.4279 52.1995C78.3058 52.3987 78.1277 52.5573 77.9159 52.6555C77.7042 52.7537 77.4684 52.787 77.2381 52.7512C77.0077 52.7155 76.7932 52.6123 76.6215 52.4547C76.5061 52.3488 76.4129 52.2209 76.3473 52.0785C76.2817 51.9361 76.245 51.782 76.2394 51.6252C76.2337 51.4684 76.2593 51.312 76.3145 51.1651C76.3697 51.0181 76.4535 50.8836 76.561 50.7693Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M75.3676 47.5954C75.9877 47.8316 76.6825 47.5192 76.9193 46.8975C77.1562 46.2759 76.8455 45.5803 76.2254 45.3441C75.6053 45.1078 74.9105 45.4202 74.6737 46.0419C74.4368 46.6636 74.7475 47.3591 75.3676 47.5954Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M74.8871 40.0587C75.055 39.8826 75.2712 39.7603 75.5083 39.7072C75.7453 39.654 75.9927 39.6724 76.2191 39.7601C76.4455 39.8478 76.6407 40.0008 76.7802 40.1998C76.9196 40.3988 76.997 40.6348 77.0025 40.878C77.008 41.1213 76.9414 41.3608 76.8111 41.5664C76.6808 41.7719 76.4927 41.9343 76.2705 42.0329C76.0483 42.1315 75.8021 42.162 75.5629 42.1204C75.3236 42.0789 75.1022 41.9672 74.9265 41.7995C74.8097 41.688 74.7161 41.5546 74.6509 41.4067C74.5857 41.2589 74.5503 41.0996 74.5466 40.9379C74.543 40.7762 74.5712 40.6154 74.6296 40.4645C74.688 40.3136 74.7755 40.1758 74.8871 40.0587Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M80.1462 41.3227C80.3176 41.1442 80.5379 41.0207 80.7792 40.9676C81.0205 40.9146 81.272 40.9344 81.5018 41.0247C81.7316 41.1149 81.9294 41.2715 82.0701 41.4745C82.2109 41.6776 82.2883 41.9181 82.2925 42.1654C82.2967 42.4128 82.2275 42.656 82.0938 42.8642C81.96 43.0724 81.7676 43.2363 81.5411 43.3351C81.3145 43.4339 81.0638 43.4631 80.8209 43.4192C80.5779 43.3752 80.3536 43.2599 80.1762 43.088C79.939 42.8581 79.8026 42.5431 79.797 42.2122C79.7913 41.8812 79.9169 41.5613 80.1462 41.3227Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M81.9134 48.7652C82.5471 49.0067 83.257 48.6874 83.4991 48.0522C83.7411 47.4169 83.4236 46.7062 82.79 46.4648C82.1563 46.2233 81.4464 46.5426 81.2043 47.1778C80.9623 47.8131 81.2798 48.5238 81.9134 48.7652Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M83.2414 52.0113C83.4058 51.8376 83.6178 51.7165 83.8507 51.6633C84.0835 51.61 84.3268 51.6271 84.5497 51.7122C84.7727 51.7974 84.9654 51.9468 85.1035 52.1417C85.2416 52.3366 85.3189 52.5681 85.3257 52.8072C85.3325 53.0463 85.2684 53.2822 85.1417 53.485C85.0149 53.6879 84.831 53.8487 84.6132 53.9472C84.3955 54.0456 84.1536 54.0773 83.9182 54.0382C83.6827 53.9991 83.4642 53.891 83.2902 53.7275C83.1741 53.6184 83.0807 53.4874 83.0154 53.342C82.9501 53.1966 82.9142 53.0396 82.9096 52.8801C82.9051 52.7207 82.9321 52.5618 82.989 52.4127C83.046 52.2636 83.1317 52.1272 83.2414 52.0113Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M85.3164 59.4372C85.9331 59.6721 86.624 59.3614 86.8596 58.7431C87.0952 58.1249 86.7862 57.4332 86.1694 57.1982C85.5527 56.9632 84.8618 57.2739 84.6262 57.8922C84.3906 58.5105 84.6996 59.2022 85.3164 59.4372Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M86.5474 65.0205C87.1472 65.249 87.8191 64.9468 88.0482 64.3455C88.2773 63.7442 87.9768 63.0715 87.377 62.843C86.7772 62.6145 86.1053 62.9166 85.8762 63.5179C85.6471 64.1192 85.9476 64.7919 86.5474 65.0205Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M90.6936 60.2606C90.858 60.0869 91.07 59.9658 91.3028 59.9125C91.5357 59.8593 91.7789 59.8763 92.0019 59.9615C92.2248 60.0466 92.4175 60.1961 92.5556 60.3909C92.6937 60.5858 92.7711 60.8174 92.7779 61.0565C92.7846 61.2956 92.7206 61.5314 92.5938 61.7343C92.467 61.9372 92.2831 62.098 92.0654 62.1964C91.8476 62.2949 91.6058 62.3265 91.3703 62.2874C91.1348 62.2483 90.9163 62.1402 90.7423 61.9767C90.6263 61.8677 90.5329 61.7367 90.4676 61.5913C90.4023 61.4459 90.3663 61.2889 90.3618 61.1294C90.3573 60.9699 90.3842 60.8111 90.4412 60.662C90.4981 60.5129 90.5839 60.3764 90.6936 60.2606Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M96.1184 61.1088C96.2857 60.9316 96.5016 60.8081 96.7389 60.7539C96.9761 60.6998 97.2239 60.7173 97.4509 60.8044C97.6779 60.8915 97.8739 61.0442 98.014 61.2432C98.1541 61.4421 98.232 61.6784 98.2379 61.922C98.2437 62.1656 98.1773 62.4055 98.0469 62.6115C97.9166 62.8174 97.7282 62.98 97.5056 63.0787C97.2831 63.1774 97.0364 63.2077 96.7969 63.1659C96.5573 63.124 96.3357 63.0118 96.1601 62.8434C95.9274 62.618 95.7926 62.3098 95.7848 61.9853C95.777 61.6608 95.8968 61.346 96.1184 61.1088Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M94.8581 55.4723C95.0295 55.2939 95.2498 55.1703 95.4911 55.1173C95.7324 55.0642 95.9839 55.0841 96.2137 55.1743C96.4435 55.2646 96.6413 55.4211 96.782 55.6242C96.9228 55.8273 97.0002 56.0677 97.0044 56.3151C97.0086 56.5625 96.9394 56.8057 96.8057 57.0139C96.6719 57.2221 96.4796 57.386 96.253 57.4848C96.0264 57.5835 95.7757 57.6128 95.5328 57.5688C95.2898 57.5248 95.0655 57.4096 94.8881 57.2377C94.6509 57.0077 94.5145 56.6928 94.5089 56.3618C94.5033 56.0309 94.6288 55.711 94.8581 55.4723Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M94.3345 49.2293C94.5116 49.0366 94.7418 48.901 94.9959 48.8399C95.25 48.7788 95.5163 48.795 95.761 48.8863C96.0056 48.9777 96.2173 49.1401 96.3692 49.3527C96.5211 49.5654 96.6061 49.8187 96.6135 50.0802C96.6209 50.3418 96.5503 50.5998 96.4107 50.8212C96.2711 51.0426 96.0688 51.2174 95.8298 51.3232C95.5907 51.4291 95.3257 51.4612 95.0686 51.4154C94.8115 51.3697 94.574 51.2482 94.3864 51.0664C94.1402 50.8279 93.997 50.5023 93.9873 50.159C93.9776 49.8158 94.1022 49.4822 94.3345 49.2293Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M89.4787 53.7117C89.6475 53.5345 89.865 53.4114 90.1035 53.3579C90.3421 53.3045 90.591 53.3231 90.8187 53.4114C91.0464 53.4998 91.2428 53.6538 91.3829 53.8542C91.523 54.0545 91.6005 54.2921 91.6057 54.5368C91.6109 54.7816 91.5435 55.0225 91.412 55.229C91.2806 55.4356 91.0909 55.5985 90.8672 55.6972C90.6434 55.7959 90.3955 55.826 90.155 55.7835C89.9144 55.741 89.6919 55.628 89.5157 55.4587C89.2815 55.2312 89.1463 54.9204 89.1394 54.5934C89.1324 54.2665 89.2543 53.9497 89.4787 53.7117Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M88.0737 48.013C88.2461 47.8334 88.4677 47.7091 88.7104 47.6557C88.9532 47.6024 89.2062 47.6224 89.4373 47.7133C89.6685 47.8042 89.8674 47.9619 90.0088 48.1663C90.1502 48.3707 90.2278 48.6127 90.2316 48.8616C90.2355 49.1105 90.1656 49.355 90.0306 49.5643C89.8957 49.7735 89.7018 49.9379 89.4736 50.0368C89.2454 50.1357 88.9932 50.1645 88.7489 50.1196C88.5046 50.0746 88.2792 49.958 88.1014 49.7845C87.8657 49.5523 87.7308 49.2364 87.7256 48.905C87.7204 48.5737 87.8455 48.2533 88.0737 48.013Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M85.9632 42.4684C86.1424 42.2753 86.3753 42.1405 86.6316 42.0813C86.8879 42.0222 87.1559 42.0414 87.4009 42.1366C87.646 42.2317 87.8567 42.3984 88.006 42.615C88.1552 42.8316 88.236 43.0883 88.238 43.3517C88.24 43.6151 88.163 43.8732 88.0171 44.0925C87.8711 44.3119 87.6629 44.4824 87.4193 44.582C87.1758 44.6817 86.9081 44.7059 86.6509 44.6515C86.3938 44.597 86.1589 44.4665 85.9768 44.2768C85.7443 44.0346 85.6134 43.7122 85.6109 43.3759C85.6083 43.0396 85.7344 42.7148 85.9632 42.4684Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M83.5154 36.668C83.6944 36.4794 83.9252 36.3481 84.1784 36.2907C84.4317 36.2332 84.6962 36.2522 84.9384 36.3453C85.1807 36.4383 85.3899 36.6012 85.5396 36.8135C85.6893 37.0257 85.7728 37.2777 85.7796 37.5377C85.7864 37.7976 85.7161 38.0539 85.5777 38.2742C85.4393 38.4944 85.2389 38.6687 85.0018 38.7751C84.7648 38.8815 84.5017 38.9152 84.2458 38.872C83.9899 38.8287 83.7526 38.7104 83.564 38.5321C83.4383 38.4133 83.3374 38.2707 83.2669 38.1126C83.1964 37.9545 83.1578 37.784 83.1533 37.6108C83.1487 37.4376 83.1784 37.2652 83.2405 37.1034C83.3027 36.9416 83.3961 36.7936 83.5154 36.668Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M86.6229 32.1255C86.8076 31.9315 87.0454 31.7965 87.3063 31.7376C87.5672 31.6787 87.8396 31.6986 88.089 31.7947C88.3384 31.8907 88.5537 32.0587 88.7077 32.2775C88.8616 32.4962 88.9474 32.7558 88.9541 33.0236C88.9609 33.2914 88.8883 33.5553 88.7455 33.782C88.6027 34.0087 88.3962 34.188 88.1519 34.2974C87.9077 34.4068 87.6367 34.4412 87.3731 34.3965C87.1096 34.3517 86.8654 34.2297 86.6712 34.0458C86.4116 33.7975 86.2607 33.4561 86.2517 33.0963C86.2426 32.7365 86.3761 32.3875 86.6229 32.1255Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M90.1855 30.0033C90.8938 30.2732 91.6872 29.9164 91.9577 29.2064C92.2282 28.4964 91.8734 27.702 91.1652 27.4322C90.4569 27.1624 89.6635 27.5192 89.393 28.2292C89.1225 28.9392 89.4773 29.7335 90.1855 30.0033Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M84.9629 27.8158C85.6711 28.0857 86.4646 27.7289 86.7351 27.0189C87.0056 26.3089 86.6508 25.5145 85.9425 25.2447C85.2343 24.9749 84.4409 25.3317 84.1703 26.0417C83.8998 26.7517 84.2547 27.546 84.9629 27.8158Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M81.6152 23.1945C82.3235 23.4643 83.1169 23.1075 83.3874 22.3975C83.6579 21.6875 83.3031 20.8932 82.5949 20.6234C81.8866 20.3535 81.0932 20.7103 80.8227 21.4203C80.5522 22.1303 80.907 22.9247 81.6152 23.1945Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M75.5216 19.9151C75.707 19.7174 75.9468 19.5793 76.2105 19.5181C76.4742 19.457 76.7499 19.4757 77.0028 19.5718C77.2556 19.6679 77.4741 19.8371 77.6307 20.058C77.7872 20.2788 77.8746 20.5414 77.882 20.8123C77.8893 21.0833 77.8161 21.3505 77.6718 21.58C77.5274 21.8095 77.3183 21.991 77.0711 22.1015C76.8238 22.2119 76.5495 22.2464 76.2829 22.2006C76.0163 22.1547 75.7695 22.0306 75.5736 21.8439C75.3125 21.5948 75.1604 21.2523 75.1506 20.8909C75.1409 20.5295 75.2742 20.1787 75.5216 19.9151Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M72.042 18.4269C72.7435 18.6942 73.5293 18.3408 73.7972 17.6376C74.0652 16.9344 73.7137 16.1477 73.0123 15.8804C72.3108 15.6131 71.525 15.9665 71.2571 16.6698C70.9891 17.373 71.3406 18.1597 72.042 18.4269Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M65.7529 15.404C65.9341 15.2205 66.165 15.0942 66.417 15.0409C66.6689 14.9877 66.9309 15.0097 67.1702 15.1044C67.4095 15.199 67.6157 15.362 67.7631 15.5732C67.9105 15.7844 67.9927 16.0345 67.9993 16.2923C68.006 16.5501 67.9369 16.8044 67.8006 17.0234C67.6644 17.2425 67.4669 17.4167 67.2328 17.5244C66.9988 17.6321 66.7383 17.6686 66.484 17.6293C66.2296 17.5901 65.9925 17.4768 65.8021 17.3035C65.671 17.1842 65.5654 17.0396 65.4917 16.8783C65.418 16.717 65.3776 16.5424 65.373 16.3649C65.3684 16.1875 65.3997 16.0109 65.465 15.8457C65.5303 15.6806 65.6282 15.5303 65.7529 15.404Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M69.1529 23.1516C69.8375 23.4124 70.6043 23.0675 70.8658 22.3813C71.1272 21.6951 70.7843 20.9274 70.0998 20.6666C69.4153 20.4058 68.6484 20.7506 68.3869 21.4368C68.1255 22.1231 68.4684 22.8908 69.1529 23.1516Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M71.7974 25.5962C72.0382 25.3433 72.3693 25.1964 72.7178 25.1878C73.0663 25.1793 73.4037 25.3097 73.6557 25.5506C73.9078 25.7914 74.0539 26.1228 74.0618 26.472C74.0698 26.8211 73.939 27.1593 73.6982 27.4123C73.5808 27.5433 73.4384 27.6494 73.2795 27.7243C73.1205 27.7993 72.9482 27.8416 72.7728 27.8488C72.5974 27.8559 72.4223 27.8278 72.2581 27.766C72.0938 27.7042 71.9436 27.61 71.8164 27.489C71.6891 27.368 71.5874 27.2227 71.5173 27.0615C71.4472 26.9004 71.41 26.7268 71.408 26.5509C71.406 26.375 71.4393 26.2004 71.5057 26.0375C71.5722 25.8746 71.6706 25.7266 71.7951 25.6024L71.7974 25.5962Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M78.3516 27.8681C79.0463 28.1328 79.8245 27.7828 80.0899 27.0864C80.3552 26.39 80.0072 25.6109 79.3125 25.3462C78.6178 25.0815 77.8396 25.4315 77.5742 26.1279C77.3089 26.8243 77.6569 27.6034 78.3516 27.8681Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M80.9925 30.5648C81.1735 30.3804 81.4046 30.2534 81.657 30.1996C81.9094 30.1458 82.1719 30.1676 82.4118 30.2623C82.6517 30.3571 82.8583 30.5205 83.0058 30.7322C83.1534 30.944 83.2354 31.1946 83.2416 31.453C83.2478 31.7114 83.1779 31.966 83.0407 32.185C82.9035 32.4041 82.705 32.578 82.47 32.6849C82.2349 32.7919 81.9738 32.8272 81.7191 32.7864C81.4645 32.7457 81.2275 32.6307 81.0379 32.4558C80.9084 32.3364 80.8042 32.1921 80.7316 32.0314C80.659 31.8708 80.6193 31.6971 80.6151 31.5207C80.6109 31.3442 80.6421 31.1687 80.7069 31.0045C80.7718 30.8403 80.8689 30.6908 80.9925 30.5648Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M77.753 35.3601C77.9275 35.1758 78.1525 35.0474 78.3996 34.9911C78.6466 34.9348 78.9047 34.9531 79.1412 35.0437C79.3776 35.1344 79.5818 35.2933 79.7279 35.5004C79.874 35.7075 79.9555 35.9534 79.962 36.2071C79.9686 36.4608 79.8998 36.7108 79.7646 36.9256C79.6293 37.1404 79.4336 37.3103 79.2021 37.4138C78.9707 37.5173 78.7139 37.5498 78.4642 37.5071C78.2146 37.4645 77.9833 37.3486 77.7997 37.1741C77.561 36.9359 77.423 36.6147 77.4144 36.277C77.4057 35.9394 77.5269 35.6111 77.753 35.3601Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M74.4007 30.8075C74.5799 30.6144 74.8128 30.4796 75.0691 30.4204C75.3254 30.3613 75.5934 30.3805 75.8384 30.4757C76.0835 30.5708 76.2942 30.7375 76.4435 30.9541C76.5927 31.1708 76.6735 31.4274 76.6755 31.6908C76.6775 31.9542 76.6005 32.2123 76.4546 32.4316C76.3086 32.651 76.1004 32.8215 75.8568 32.9212C75.6133 33.0208 75.3456 33.045 75.0884 32.9906C74.8313 32.9361 74.5964 32.8056 74.4143 32.6159C74.1818 32.3737 74.0509 32.0513 74.0484 31.715C74.0458 31.3787 74.1719 31.0539 74.4007 30.8075Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M70.8621 36.1297C71.0304 35.9514 71.2476 35.8271 71.4863 35.7726C71.725 35.7181 71.9743 35.7358 72.2027 35.8236C72.431 35.9113 72.6281 36.0651 72.7689 36.2654C72.9096 36.4657 72.9878 36.7035 72.9933 36.9486C72.9988 37.1937 72.9316 37.4351 72.8 37.642C72.6685 37.849 72.4786 38.0122 72.2544 38.111C72.0303 38.2097 71.782 38.2396 71.5411 38.1968C71.3003 38.154 71.0776 38.0404 70.9015 37.8705C70.6673 37.6445 70.5317 37.3348 70.5243 37.0088C70.517 36.6828 70.6384 36.3669 70.8621 36.1297Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M64.4772 37.8745C64.6391 37.702 64.8482 37.5813 65.0783 37.5277C65.3084 37.4741 65.549 37.4899 65.7699 37.5731C65.9908 37.6564 66.182 37.8033 66.3194 37.9955C66.4568 38.1876 66.5343 38.4163 66.5421 38.6527C66.5499 38.8892 66.4876 39.1227 66.3631 39.3239C66.2386 39.5251 66.0574 39.685 65.8425 39.7833C65.6276 39.8817 65.3885 39.9141 65.1555 39.8765C64.9224 39.839 64.7058 39.7331 64.533 39.5722C64.4168 39.4653 64.3231 39.3363 64.2574 39.1926C64.1916 39.049 64.155 38.8937 64.1498 38.7356C64.1446 38.5776 64.1709 38.4201 64.2271 38.2722C64.2833 38.1243 64.3683 37.9891 64.4772 37.8745Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M61.8449 32.0955C62.0093 31.9218 62.2213 31.8007 62.4542 31.7475C62.687 31.6943 62.9303 31.7113 63.1532 31.7964C63.3762 31.8816 63.5689 32.031 63.707 32.2259C63.8451 32.4208 63.9224 32.6524 63.9292 32.8915C63.936 33.1305 63.8719 33.3664 63.7451 33.5693C63.6183 33.7721 63.4345 33.9329 63.2167 34.0314C62.999 34.1298 62.7571 34.1615 62.5216 34.1224C62.2862 34.0833 62.0677 33.9752 61.8937 33.8117C61.7768 33.7031 61.6827 33.5723 61.6169 33.4268C61.5511 33.2814 61.5149 33.1242 61.5103 32.9645C61.5058 32.8047 61.5331 32.6456 61.5905 32.4964C61.6479 32.3473 61.7344 32.211 61.8449 32.0955Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M67.9861 31.3574C68.1559 31.179 68.3747 31.0551 68.6147 31.0013C68.8547 30.9475 69.1051 30.9663 69.3342 31.0553C69.5633 31.1443 69.7607 31.2995 69.9014 31.5012C70.0422 31.7029 70.1199 31.942 70.1248 32.1883C70.1297 32.4345 70.0615 32.6768 69.9288 32.8844C69.7962 33.0919 69.605 33.2555 69.3797 33.3543C69.1543 33.453 68.9048 33.4826 68.6629 33.4392C68.421 33.3958 68.1975 33.2814 68.0208 33.1105C67.785 32.8825 67.6491 32.5702 67.6426 32.2417C67.6361 31.9132 67.7596 31.5953 67.9861 31.3574Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M65.3578 26.2139C65.5302 26.0343 65.7518 25.91 65.9945 25.8566C66.2373 25.8033 66.4903 25.8233 66.7214 25.9142C66.9526 26.0051 67.1515 26.1628 67.2929 26.3672C67.4343 26.5716 67.5119 26.8136 67.5157 27.0625C67.5196 27.3114 67.4497 27.5559 67.3147 27.7652C67.1798 27.9744 66.9859 28.1389 66.7577 28.2377C66.5295 28.3366 66.2773 28.3654 66.033 28.3205C65.7887 28.2756 65.5633 28.159 65.3855 27.9855C65.1483 27.7541 65.0122 27.4379 65.0071 27.106C65.0019 26.7741 65.128 26.4534 65.3578 26.2139Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M59.3224 26.2318C59.4896 26.0546 59.7056 25.9311 59.9428 25.877C60.18 25.8228 60.4278 25.8403 60.6548 25.9274C60.8819 26.0145 61.0778 26.1672 61.2179 26.3662C61.358 26.5651 61.436 26.8014 61.4418 27.045C61.4477 27.2886 61.3812 27.5285 61.2509 27.7345C61.1205 27.9404 60.9321 28.103 60.7096 28.2017C60.487 28.3004 60.2403 28.3307 60.0008 28.2889C59.7613 28.247 59.5397 28.1348 59.3641 27.9664C59.2473 27.8557 59.1535 27.7229 59.0882 27.5756C59.0229 27.4284 58.9872 27.2696 58.9834 27.1084C58.9795 26.9472 59.0074 26.7868 59.0656 26.6364C59.1238 26.4859 59.211 26.3485 59.3224 26.2318Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M53.2675 25.9677C53.43 25.7963 53.6394 25.6768 53.8694 25.6242C54.0993 25.5717 54.3395 25.5883 54.5597 25.6722C54.78 25.756 54.9705 25.9033 55.1072 26.0955C55.244 26.2876 55.3209 26.5161 55.3283 26.7522C55.3357 26.9883 55.2733 27.2214 55.1489 27.4222C55.0244 27.623 54.8436 27.7826 54.629 27.8809C54.4145 27.9791 54.1758 28.0117 53.9431 27.9744C53.7103 27.9372 53.4939 27.8318 53.321 27.6716C53.2041 27.5644 53.1098 27.4349 53.0437 27.2906C52.9775 27.1464 52.9409 26.9903 52.9359 26.8315C52.9309 26.6727 52.9577 26.5145 53.0147 26.3662C53.0716 26.2178 53.1576 26.0823 53.2675 25.9677Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M48.9506 31.212C49.1037 31.0467 49.3019 30.9302 49.5206 30.8772C49.7392 30.8242 49.9685 30.8369 50.1798 30.9138C50.391 30.9908 50.5748 31.1285 50.7081 31.3097C50.8414 31.4909 50.9184 31.7076 50.9293 31.9326C50.9402 32.1576 50.8845 32.381 50.7694 32.5747C50.6542 32.7684 50.4846 32.9239 50.2818 33.0216C50.0791 33.1194 49.8521 33.155 49.6294 33.1242C49.4067 33.0933 49.1981 32.9973 49.0298 32.8482C48.9158 32.7471 48.8231 32.6242 48.7572 32.4867C48.6914 32.3492 48.6536 32.1998 48.6463 32.0474C48.6389 31.8949 48.662 31.7425 48.7143 31.599C48.7665 31.4556 48.8469 31.324 48.9506 31.212Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M51.4635 38.854C52.0362 39.0722 52.6778 38.7837 52.8966 38.2096C53.1153 37.6355 52.8284 36.9932 52.2557 36.775C51.683 36.5568 51.0414 36.8453 50.8227 37.4194C50.6039 37.9935 50.8909 38.6358 51.4635 38.854Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M55.4625 31.7347C55.6209 31.5646 55.8259 31.4452 56.0517 31.3915C56.2775 31.3379 56.5141 31.3523 56.7315 31.433C56.9489 31.5137 57.1375 31.6571 57.2736 31.8452C57.4097 32.0332 57.4871 32.2574 57.4962 32.4897C57.5052 32.7219 57.4455 32.9518 57.3244 33.1503C57.2034 33.3488 57.0266 33.5071 56.8161 33.6053C56.6056 33.7034 56.371 33.737 56.1417 33.7019C55.9124 33.6668 55.6988 33.5644 55.5276 33.4078C55.413 33.3029 55.3202 33.1761 55.2548 33.035C55.1894 32.8938 55.1526 32.741 55.1466 32.5854C55.1405 32.4298 55.1653 32.2745 55.2195 32.1285C55.2738 31.9825 55.3563 31.8486 55.4625 31.7347Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M57.7732 37.4334C57.9288 37.2668 58.13 37.1499 58.3514 37.0972C58.5728 37.0445 58.8048 37.0585 59.0181 37.1373C59.2315 37.2161 59.4168 37.3563 59.5508 37.5403C59.6848 37.7242 59.7616 37.9438 59.7715 38.1715C59.7814 38.3992 59.7241 38.6249 59.6066 38.8203C59.4892 39.0157 59.3168 39.1721 59.1112 39.27C58.9055 39.3678 58.6757 39.4028 58.4506 39.3704C58.2254 39.338 58.0149 39.2398 57.8454 39.088C57.7307 38.9853 57.6377 38.8606 57.5718 38.7212C57.5059 38.5818 57.4685 38.4307 57.4618 38.2765C57.455 38.1224 57.4791 37.9684 57.5326 37.8236C57.5861 37.6788 57.6679 37.5461 57.7732 37.4334Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M59.5543 45.043C60.1338 45.2637 60.783 44.9718 61.0043 44.3909C61.2256 43.81 60.9353 43.1601 60.3558 42.9393C59.7764 42.7185 59.1272 43.0105 58.9059 43.5914C58.6845 44.1723 58.9749 44.8222 59.5543 45.043Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M64.7137 44.5931C64.8714 44.4268 65.0748 44.311 65.298 44.2604C65.5213 44.2098 65.7544 44.2268 65.9678 44.309C66.1812 44.3913 66.3654 44.5352 66.4969 44.7225C66.6285 44.9098 66.7015 45.1322 66.7068 45.3613C66.7121 45.5905 66.6494 45.8162 66.5266 46.0098C66.4039 46.2035 66.2266 46.3563 66.0172 46.4491C65.8078 46.5419 65.5757 46.5703 65.3504 46.5309C65.1251 46.4915 64.9166 46.3859 64.7514 46.2276C64.6413 46.1232 64.5529 45.9981 64.4913 45.8592C64.4298 45.7204 64.3963 45.5707 64.3927 45.4188C64.3892 45.2668 64.4158 45.1156 64.4709 44.9739C64.526 44.8322 64.6085 44.7028 64.7137 44.5931Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M69.5915 42.2109C69.7534 42.0384 69.9625 41.9177 70.1926 41.8641C70.4227 41.8105 70.6633 41.8263 70.8842 41.9096C71.1051 41.9928 71.2963 42.1398 71.4337 42.3319C71.5711 42.524 71.6486 42.7527 71.6564 42.9892C71.6642 43.2256 71.6019 43.4591 71.4774 43.6603C71.3529 43.8616 71.1717 44.0214 70.9568 44.1198C70.7419 44.2181 70.5028 44.2505 70.2698 44.213C70.0367 44.1754 69.8201 44.0695 69.6473 43.9086C69.5319 43.8012 69.4389 43.6721 69.3736 43.5285C69.3084 43.3849 69.2721 43.2297 69.2669 43.072C69.2617 42.9142 69.2877 42.7569 69.3434 42.6091C69.3991 42.4613 69.4834 42.326 69.5915 42.2109Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M69.3088 48.985C69.4656 48.8198 69.6676 48.7049 69.8894 48.6546C70.1112 48.6044 70.3428 48.6211 70.5548 48.7027C70.7669 48.7843 70.95 48.9271 71.0809 49.1131C71.2118 49.2991 71.2846 49.5198 71.2902 49.7475C71.2958 49.9751 71.2339 50.1995 71.1123 50.3921C70.9908 50.5847 70.815 50.737 70.6072 50.8297C70.3994 50.9223 70.1689 50.9513 69.945 50.9128C69.721 50.8743 69.5135 50.7701 69.3489 50.6134C69.2395 50.5093 69.1517 50.3846 69.0905 50.2464C69.0292 50.1082 68.9958 49.9593 68.9921 49.8081C68.9884 49.6569 69.0144 49.5063 69.0688 49.3651C69.1231 49.2239 69.2047 49.0947 69.3088 48.985Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M71.8592 54.5708C72.013 54.4082 72.2113 54.2949 72.4291 54.2451C72.6469 54.1953 72.8745 54.2113 73.0831 54.291C73.2916 54.3708 73.4718 54.5107 73.6008 54.6931C73.7298 54.8756 73.8019 55.0923 73.808 55.316C73.814 55.5397 73.7538 55.7602 73.6348 55.9498C73.5158 56.1394 73.3435 56.2895 73.1396 56.3811C72.9357 56.4727 72.7093 56.5018 72.4891 56.4646C72.269 56.4274 72.0648 56.3257 71.9025 56.1722C71.6861 55.9652 71.5602 55.6809 71.5521 55.381C71.544 55.0811 71.6544 54.7899 71.8592 54.5708Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M74.448 62.291C75.0139 62.5067 75.6478 62.2216 75.864 61.6542C76.0802 61.0869 75.7966 60.4522 75.2307 60.2366C74.6648 60.021 74.0308 60.3061 73.8147 60.8734C73.5985 61.4407 73.882 62.0754 74.448 62.291Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M76.1653 68.0382C76.7143 68.2473 77.3293 67.9707 77.539 67.4204C77.7487 66.8701 77.4736 66.2544 76.9246 66.0452C76.3757 65.8361 75.7607 66.1126 75.551 66.663C75.3413 67.2133 75.6164 67.829 76.1653 68.0382Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M68.9785 65.4691C69.1625 65.276 69.4149 65.1631 69.681 65.1551C69.9472 65.147 70.2056 65.2444 70.4002 65.4262C70.549 65.5634 70.6535 65.742 70.7005 65.9391C70.7474 66.1363 70.7345 66.3431 70.6636 66.5331C70.5926 66.7232 70.4668 66.8878 70.3021 67.006C70.1375 67.1242 69.9415 67.1906 69.7392 67.1967C69.5368 67.2028 69.3374 67.1484 69.1663 67.0403C68.9952 66.9323 68.8602 66.7756 68.7786 66.5901C68.6969 66.4047 68.6724 66.199 68.708 65.9994C68.7436 65.7997 68.8378 65.6151 68.9785 65.4691Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M66.7252 73.0071C67.2132 73.193 67.7598 72.9471 67.9462 72.458C68.1326 71.9688 67.8881 71.4215 67.4001 71.2356C66.9122 71.0496 66.3655 71.2955 66.1791 71.7847C65.9927 72.2739 66.2372 72.8211 66.7252 73.0071Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M62.1301 67.0507C62.258 66.9124 62.424 66.815 62.607 66.7709C62.79 66.7269 62.9818 66.7381 63.1583 66.8031C63.3348 66.8682 63.488 66.9842 63.5986 67.1365C63.7092 67.2888 63.7722 67.4705 63.7797 67.6589C63.7872 67.8472 63.7389 68.0336 63.6409 68.1946C63.5428 68.3557 63.3994 68.4841 63.2287 68.5636C63.058 68.6432 62.8677 68.6703 62.6818 68.6417C62.4959 68.613 62.3227 68.5299 62.1841 68.4026C62.0909 68.3182 62.0154 68.216 61.9621 68.102C61.9088 67.988 61.8788 67.8644 61.8737 67.7385C61.8687 67.6126 61.8888 67.487 61.9328 67.3689C61.9768 67.2508 62.0439 67.1426 62.1301 67.0507Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M62.7148 62.1596C63.2333 62.3571 63.8141 62.0959 64.0121 61.5761C64.2102 61.0564 63.9504 60.4749 63.4319 60.2774C62.9135 60.0798 62.3326 60.341 62.1346 60.8608C61.9366 61.3805 62.1963 61.962 62.7148 62.1596Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M68.1302 59.6021C68.2748 59.4506 68.461 59.3454 68.6651 59.2998C68.8693 59.2541 69.0822 59.2701 69.2771 59.3457C69.4719 59.4213 69.64 59.5531 69.7599 59.7245C69.8799 59.8959 69.9464 60.0991 69.951 60.3085C69.9556 60.5179 69.8982 60.7241 69.786 60.901C69.6737 61.0779 69.5117 61.2176 69.3204 61.3025C69.1291 61.3873 68.917 61.4134 68.7111 61.3776C68.5052 61.3417 68.3146 61.2455 68.1634 61.1011C67.962 60.9062 67.8455 60.6396 67.8393 60.3589C67.8331 60.0783 67.9376 59.8063 68.1302 59.6021Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M65.0799 54.2558C65.2275 54.1001 65.4179 53.9916 65.6269 53.9441C65.8359 53.8966 66.0542 53.9122 66.2541 53.989C66.4541 54.0657 66.6267 54.2001 66.7503 54.3753C66.8738 54.5504 66.9428 54.7584 66.9483 54.9729C66.9539 55.1875 66.8959 55.399 66.7815 55.5807C66.6672 55.7624 66.5018 55.9062 66.3061 55.9939C66.1104 56.0816 65.8933 56.1093 65.6821 56.0734C65.471 56.0376 65.2753 55.9398 65.1197 55.7925C65.0162 55.6945 64.9331 55.5769 64.8751 55.4466C64.8171 55.3163 64.7853 55.1757 64.7816 55.033C64.7779 54.8902 64.8023 54.7481 64.8535 54.6147C64.9047 54.4814 64.9816 54.3594 65.0799 54.2558Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M61.5154 49.7067C61.6646 49.5509 61.8565 49.4428 62.0668 49.396C62.2771 49.3492 62.4965 49.3659 62.6971 49.4439C62.8978 49.5219 63.0708 49.6577 63.1944 49.8342C63.3179 50.0107 63.3865 50.22 63.3914 50.4357C63.3963 50.6514 63.3373 50.8638 63.2219 51.0462C63.1065 51.2286 62.9398 51.3727 62.7429 51.4604C62.546 51.5481 62.3277 51.5754 62.1155 51.539C61.9033 51.5026 61.7067 51.404 61.5505 51.2556C61.4463 51.1567 61.3627 51.038 61.3045 50.9064C61.2463 50.7748 61.2146 50.633 61.2113 50.489C61.2081 50.3451 61.2333 50.2018 61.2854 50.0676C61.3376 49.9333 61.4157 49.8106 61.5154 49.7067Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M55.6995 50.5125C56.2417 50.7191 56.8491 50.446 57.0562 49.9024C57.2633 49.3589 56.9917 48.7508 56.4495 48.5442C55.9073 48.3376 55.2999 48.6108 55.0928 49.1543C54.8857 49.6979 55.1573 50.306 55.6995 50.5125Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M53.1057 44.7064C53.6615 44.9181 54.2841 44.6381 54.4963 44.081C54.7086 43.5239 54.4302 42.9006 53.8744 42.6888C53.3187 42.4771 52.6961 42.7571 52.4838 43.3142C52.2715 43.8713 52.55 44.4946 53.1057 44.7064Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M46.3885 43.6898C46.9206 43.8925 47.5166 43.6245 47.7198 43.0911C47.923 42.5578 47.6565 41.9611 47.1244 41.7584C46.5924 41.5557 45.9964 41.8237 45.7932 42.3571C45.59 42.8904 45.8565 43.4871 46.3885 43.6898Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M44.1786 35.8249C44.3222 35.6666 44.5096 35.5547 44.7169 35.5037C44.9242 35.4527 45.1419 35.4649 45.342 35.5387C45.5421 35.6125 45.7156 35.7446 45.8401 35.918C45.9646 36.0913 46.0345 36.2981 46.0408 36.5118C46.0471 36.7254 45.9895 36.9362 45.8755 37.1171C45.7615 37.2979 45.5961 37.4406 45.4007 37.5268C45.2054 37.613 44.9888 37.6388 44.7789 37.6009C44.569 37.5629 44.3753 37.463 44.2226 37.3139C44.0242 37.1201 43.9087 36.8565 43.9005 36.5788C43.8923 36.3011 43.992 36.0308 44.1786 35.8249Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M42.2782 29.6558C42.4298 29.4963 42.6252 29.3854 42.8397 29.3371C43.0541 29.2889 43.2779 29.3054 43.4827 29.3847C43.6875 29.4639 43.8641 29.6023 43.9902 29.7824C44.1163 29.9624 44.1862 30.176 44.191 30.396C44.1958 30.616 44.1353 30.8327 44.0172 31.0184C43.8991 31.2042 43.7287 31.3508 43.5276 31.4396C43.3264 31.5285 43.1036 31.5555 42.8873 31.5174C42.671 31.4793 42.4709 31.3778 42.3124 31.2256C42.2067 31.1253 42.1219 31.0049 42.063 30.8716C42.004 30.7382 41.972 30.5943 41.9688 30.4484C41.9656 30.3024 41.9913 30.1573 42.0444 30.0212C42.0975 29.8852 42.177 29.761 42.2782 29.6558Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M47.0996 25.5053C47.2584 25.3387 47.4629 25.2228 47.6872 25.1724C47.9115 25.1221 48.1455 25.1394 48.3598 25.2222C48.574 25.305 48.7589 25.4496 48.891 25.6378C49.0231 25.8259 49.0965 26.0491 49.102 26.2793C49.1074 26.5094 49.0447 26.7361 48.9217 26.9308C48.7987 27.1254 48.6209 27.2793 48.4109 27.3729C48.2008 27.4666 47.9678 27.4958 47.7414 27.4569C47.515 27.418 47.3053 27.3127 47.1388 27.1544C47.0271 27.0495 46.9374 26.9233 46.8749 26.7833C46.8125 26.6432 46.7784 26.4921 46.7748 26.3386C46.7711 26.1852 46.7979 26.0325 46.8537 25.8894C46.9095 25.7464 46.9931 25.6158 47.0996 25.5053Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M40.5749 16.7007C40.7352 16.5283 40.9429 16.4073 41.1716 16.3529C41.4003 16.2986 41.6399 16.3133 41.86 16.3954C42.0802 16.4774 42.271 16.6229 42.4085 16.8137C42.5459 17.0044 42.6237 17.2318 42.6322 17.467C42.6406 17.7023 42.5793 17.9349 42.4559 18.1355C42.3325 18.3361 42.1526 18.4956 41.9389 18.5939C41.7253 18.6923 41.4874 18.725 41.2554 18.6881C41.0234 18.6511 40.8076 18.546 40.6354 18.3861C40.52 18.2802 40.4268 18.1523 40.3612 18.01C40.2956 17.8676 40.2589 17.7135 40.2533 17.5567C40.2477 17.3999 40.2732 17.2434 40.3284 17.0965C40.3836 16.9496 40.4674 16.815 40.5749 16.7007Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M35.4635 22.2412C36.043 22.462 36.6921 22.17 36.9135 21.5891C37.1348 21.0082 36.8445 20.3583 36.265 20.1376C35.6856 19.9168 35.0364 20.2087 34.8151 20.7896C34.5937 21.3705 34.8841 22.0204 35.4635 22.2412Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M40.6033 23.467C40.7586 23.3043 40.9584 23.1914 41.1776 23.1423C41.3967 23.0932 41.6254 23.1102 41.8346 23.1912C42.0439 23.2722 42.2245 23.4135 42.3535 23.5974C42.4825 23.7812 42.5542 23.9992 42.5596 24.2241C42.565 24.4489 42.5038 24.6704 42.3837 24.8606C42.2636 25.0508 42.0901 25.2012 41.8849 25.2929C41.6798 25.3845 41.4522 25.4132 41.231 25.3755C41.0098 25.3377 40.8048 25.2351 40.6419 25.0807C40.424 24.8717 40.2977 24.5847 40.2904 24.2824C40.2832 23.98 40.3957 23.6869 40.6033 23.467Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M35.7047 27.25C35.8009 27.1469 35.9166 27.0639 36.045 27.0058C36.1734 26.9478 36.312 26.9158 36.4527 26.9118C36.5935 26.9077 36.7336 26.9317 36.8649 26.9823C36.9963 27.033 37.1162 27.1092 37.2178 27.2067C37.3716 27.3523 37.4787 27.5404 37.5256 27.7472C37.5725 27.9541 37.5572 28.1703 37.4815 28.3685C37.4057 28.5668 37.2731 28.7382 37.1002 28.8611C36.9274 28.9839 36.7222 29.0528 36.5105 29.0588C36.2988 29.0649 36.0902 29.0079 35.911 28.8951C35.7318 28.7823 35.5901 28.6188 35.5039 28.4252C35.4176 28.2315 35.3907 28.0166 35.4264 27.8074C35.4622 27.5982 35.559 27.4042 35.7047 27.25Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M29.9866 25.7335C30.5288 25.9401 31.1362 25.6669 31.3433 25.1234C31.5504 24.5798 31.2788 23.9717 30.7366 23.7652C30.1944 23.5586 29.587 23.8317 29.3799 24.3753C29.1728 24.9188 29.4445 25.5269 29.9866 25.7335Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M25.2325 30.4304C25.7306 30.6202 26.2887 30.3692 26.4789 29.8698C26.6692 29.3705 26.4196 28.8118 25.9215 28.622C25.4233 28.4322 24.8653 28.6831 24.675 29.1825C24.4848 29.6819 24.7343 30.2406 25.2325 30.4304Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M30.9222 31.45C31.0592 31.3054 31.2359 31.2047 31.4299 31.1607C31.6239 31.1166 31.8265 31.1312 32.012 31.2025C32.1975 31.2739 32.3577 31.3988 32.4721 31.5615C32.5866 31.7242 32.6503 31.9173 32.6551 32.1165C32.6599 32.3156 32.6057 32.5118 32.4992 32.6802C32.3927 32.8487 32.2388 32.9817 32.057 33.0626C31.8751 33.1435 31.6735 33.1685 31.4777 33.1346C31.2818 33.1007 31.1005 33.0093 30.9566 32.8719C30.7641 32.6881 30.6523 32.4352 30.6459 32.1686C30.6394 31.902 30.7388 31.6436 30.9222 31.45Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M32.2214 38.4398C32.3514 38.3032 32.5188 38.2082 32.7025 38.1667C32.8862 38.1253 33.078 38.1393 33.2535 38.207C33.4291 38.2747 33.5806 38.393 33.689 38.5471C33.7973 38.7011 33.8576 38.8839 33.8623 39.0724C33.8669 39.261 33.8157 39.4467 33.7151 39.6063C33.6145 39.7658 33.469 39.892 33.297 39.9689C33.125 40.0457 32.9341 40.0698 32.7486 40.0382C32.5631 40.0065 32.3913 39.9204 32.2547 39.7908C32.1638 39.7046 32.0908 39.6012 32.0399 39.4865C31.989 39.3719 31.9612 39.2482 31.9581 39.1227C31.955 38.9972 31.9767 38.8723 32.0219 38.7551C32.0671 38.6379 32.1349 38.5308 32.2214 38.4398Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M27.0101 44.7237C27.4472 44.8903 27.937 44.6701 28.1039 44.2318C28.2709 43.7936 28.0519 43.3033 27.6148 43.1368C27.1776 42.9702 26.6879 43.1905 26.5209 43.6287C26.3539 44.0669 26.573 44.5572 27.0101 44.7237Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M25.7607 36.2178C25.9277 36.0441 26.1562 35.9433 26.3966 35.9373C26.637 35.9313 26.8699 36.0206 27.0447 36.1857C27.174 36.3097 27.2637 36.4693 27.3025 36.6444C27.3413 36.8196 27.3273 37.0024 27.2625 37.1698C27.1977 37.3371 27.0848 37.4816 26.9382 37.5849C26.7915 37.6882 26.6177 37.7458 26.4386 37.7502C26.2596 37.7547 26.0833 37.7059 25.932 37.61C25.7808 37.5142 25.6614 37.3755 25.5888 37.2115C25.5163 37.0476 25.4939 36.8657 25.5245 36.6888C25.5551 36.5119 25.6373 36.348 25.7607 36.2178Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M19.5066 33.7855C19.6667 33.6429 19.8743 33.5658 20.0883 33.5693C20.3023 33.5727 20.507 33.6566 20.662 33.8042C20.8169 33.9519 20.9108 34.1525 20.925 34.3664C20.9392 34.5803 20.8727 34.7919 20.7387 34.9593C20.6646 35.0517 20.5723 35.1278 20.4675 35.1826C20.3626 35.2375 20.2476 35.27 20.1297 35.2781C20.0118 35.2861 19.8935 35.2695 19.7824 35.2294C19.6713 35.1893 19.5697 35.1265 19.4841 35.045C19.3985 34.9634 19.3308 34.865 19.2852 34.7558C19.2397 34.6466 19.2172 34.5291 19.2193 34.4107C19.2215 34.2923 19.2481 34.1756 19.2975 34.068C19.347 33.9604 19.4182 33.8642 19.5066 33.7855Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M19.5044 40.4481C19.6145 40.3296 19.7572 40.2465 19.9144 40.2094C20.0716 40.1722 20.2362 40.1827 20.3873 40.2395C20.5384 40.2963 20.6692 40.3969 20.763 40.5284C20.8568 40.6599 20.9094 40.8164 20.9142 40.9781C20.919 41.1398 20.8756 41.2994 20.7897 41.4365C20.7038 41.5736 20.5792 41.6821 20.4318 41.7482C20.2843 41.8143 20.1206 41.8351 19.9615 41.8078C19.8024 41.7806 19.655 41.7065 19.5381 41.5951C19.3829 41.4471 19.2923 41.2437 19.286 41.029C19.2797 40.8142 19.3582 40.6055 19.5044 40.4481Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M14.4617 46.1768C14.8107 46.3098 15.2017 46.134 15.3351 45.7841C15.4684 45.4342 15.2935 45.0427 14.9445 44.9097C14.5954 44.7768 14.2044 44.9526 14.0711 45.3025C13.9378 45.6524 14.1127 46.0439 14.4617 46.1768Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M15.1625 54.0934C15.4811 54.2148 15.8379 54.0543 15.9596 53.735C16.0813 53.4157 15.9217 53.0584 15.6031 52.937C15.2846 52.8157 14.9277 52.9762 14.8061 53.2955C14.6844 53.6148 14.844 53.9721 15.1625 54.0934Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M21.4161 49.2068C21.8024 49.354 22.2352 49.1593 22.3827 48.7721C22.5303 48.3848 22.3368 47.9515 21.9504 47.8043C21.5641 47.6572 21.1314 47.8518 20.9838 48.2391C20.8362 48.6263 21.0298 49.0596 21.4161 49.2068Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M22.9578 56.6126C23.3068 56.7456 23.6978 56.5698 23.8312 56.2199C23.9645 55.87 23.7896 55.4785 23.4406 55.3455C23.0915 55.2125 22.7005 55.3884 22.5672 55.7383C22.4339 56.0882 22.6088 56.4797 22.9578 56.6126Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M28.6069 52.1508C29.0169 52.307 29.4763 52.1005 29.6329 51.6894C29.7895 51.2784 29.5841 50.8185 29.1741 50.6623C28.764 50.506 28.3047 50.7126 28.1481 51.1237C27.9914 51.5347 28.1969 51.9946 28.6069 52.1508Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M35.1354 52.1659C35.2509 52.0466 35.3989 51.9642 35.5608 51.9289C35.7227 51.8935 35.8914 51.9069 36.0456 51.9673C36.1998 52.0277 36.3327 52.1325 36.4276 52.2684C36.5224 52.4043 36.5751 52.5653 36.5788 52.7313C36.5826 52.8972 36.5374 53.0606 36.4488 53.201C36.3602 53.3415 36.2322 53.4526 36.0809 53.5205C35.9296 53.5883 35.7617 53.61 35.5984 53.5826C35.435 53.5553 35.2835 53.4801 35.1627 53.3667C35.0822 53.2898 35.0175 53.1977 34.9726 53.0958C34.9276 52.9938 34.9031 52.8839 34.9006 52.7724C34.8981 52.6608 34.9175 52.5499 34.9578 52.4458C34.9981 52.3417 35.0584 52.2466 35.1354 52.1659Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M33.6431 45.2575C33.7649 45.1267 33.9226 45.035 34.0963 44.9941C34.27 44.9532 34.4518 44.965 34.6186 45.0278C34.7855 45.0906 34.9299 45.2018 35.0334 45.3471C35.137 45.4924 35.195 45.6653 35.2002 45.844C35.2054 46.0226 35.1575 46.1988 35.0626 46.3502C34.9677 46.5016 34.83 46.6215 34.6671 46.6945C34.5042 46.7675 34.3234 46.7904 34.1477 46.7603C33.9719 46.7302 33.8091 46.6484 33.68 46.5254C33.5084 46.3618 33.4082 46.1369 33.4013 45.8995C33.3944 45.6621 33.4813 45.4314 33.6431 45.2575Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M37.7469 35.5013C38.279 35.704 38.875 35.436 39.0782 34.9027C39.2814 34.3693 39.0149 33.7726 38.4828 33.5699C37.9508 33.3672 37.3548 33.6352 37.1516 34.1686C36.9484 34.7019 37.2149 35.2986 37.7469 35.5013Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M38.9757 40.168C39.1618 39.9938 39.4072 39.8972 39.6617 39.8981C39.9162 39.8989 40.1606 39.9971 40.3449 40.1726C40.5293 40.3481 40.6397 40.5876 40.6535 40.8421C40.6674 41.0967 40.5837 41.3471 40.4195 41.5422C40.2334 41.7165 39.988 41.813 39.7335 41.8121C39.479 41.8113 39.2346 41.7131 39.0503 41.5376C38.8659 41.3621 38.7555 41.1226 38.7417 40.8681C38.7278 40.6135 38.8115 40.3631 38.9757 40.168Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M40.6402 46.3107C40.7707 46.1727 40.939 46.0765 41.1239 46.0342C41.3088 45.9919 41.502 46.0055 41.679 46.0731C41.856 46.1408 42.0089 46.2595 42.1185 46.4143C42.228 46.5692 42.2893 46.7531 42.2945 46.943C42.2996 47.1328 42.2485 47.32 42.1476 47.481C42.0467 47.6419 41.9004 47.7693 41.7274 47.8472C41.5543 47.925 41.3622 47.9497 41.1753 47.9182C40.9884 47.8868 40.8151 47.8005 40.6773 47.6702C40.5857 47.5836 40.512 47.4797 40.4605 47.3645C40.409 47.2493 40.3807 47.1249 40.3773 46.9987C40.3738 46.8724 40.3953 46.7466 40.4404 46.6286C40.4855 46.5105 40.5534 46.4025 40.6402 46.3107Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M41.9887 52.2132C42.112 52.0823 42.2713 51.991 42.4463 51.9509C42.6213 51.9107 42.8042 51.9235 42.9718 51.9876C43.1394 52.0517 43.2841 52.1642 43.3876 52.3109C43.4912 52.4576 43.5489 52.6319 43.5534 52.8116C43.5579 52.9914 43.5091 53.1685 43.413 53.3206C43.317 53.4727 43.1781 53.5928 43.014 53.6658C42.8498 53.7389 42.6678 53.7614 42.491 53.7307C42.3142 53.7 42.1506 53.6174 42.0209 53.4934C41.9344 53.4119 41.8649 53.314 41.8164 53.2053C41.7679 53.0967 41.7414 52.9795 41.7384 52.8605C41.7354 52.7414 41.756 52.6229 41.799 52.5118C41.8419 52.4008 41.9064 52.2992 41.9887 52.2132Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M47.8929 48.3907C48.0299 48.2461 48.2066 48.1454 48.4006 48.1014C48.5946 48.0573 48.7972 48.0719 48.9827 48.1432C49.1682 48.2146 49.3284 48.3395 49.4428 48.5022C49.5573 48.6649 49.621 48.858 49.6258 49.0571C49.6306 49.2563 49.5764 49.4525 49.4699 49.6209C49.3634 49.7893 49.2095 49.9224 49.0277 50.0033C48.8458 50.0842 48.6442 50.1092 48.4484 50.0753C48.2525 50.0413 48.0712 49.9499 47.9273 49.8126C47.7348 49.6288 47.623 49.3759 47.6166 49.1093C47.6101 48.8427 47.7095 48.5843 47.8929 48.3907Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M51.9654 55.2958C52.4669 55.4869 53.0287 55.2343 53.2203 54.7315C53.4119 54.2287 53.1606 53.6662 52.6591 53.4751C52.1575 53.2841 51.5957 53.5367 51.4041 54.0395C51.2126 54.5423 51.4638 55.1048 51.9654 55.2958Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M58.2138 57.4301C58.7323 57.6276 59.3131 57.3664 59.5111 56.8467C59.7092 56.3269 59.4494 55.7454 58.931 55.5479C58.4125 55.3503 57.8316 55.6115 57.6336 56.1313C57.4356 56.6511 57.6953 57.2325 58.2138 57.4301Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M56.5653 63.5286C56.7368 63.3473 56.9731 63.2417 57.2221 63.2348C57.4711 63.228 57.7125 63.3205 57.8931 63.4921C58.0737 63.6637 58.1788 63.9002 58.1852 64.1497C58.1916 64.3992 58.0989 64.6411 57.9273 64.8224C57.7564 65.002 57.5214 65.1063 57.2739 65.1124C57.0265 65.1186 56.7869 65.026 56.608 64.855C56.429 64.6841 56.3252 64.4488 56.3195 64.2009C56.3138 63.953 56.4066 63.7129 56.5775 63.5333L56.5653 63.5286Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M51.7648 61.7422C52.2358 61.9217 52.7635 61.6844 52.9434 61.2122C53.1234 60.74 52.8874 60.2117 52.4163 60.0322C51.9453 59.8528 51.4176 60.0901 51.2377 60.5623C51.0578 61.0345 51.2938 61.5628 51.7648 61.7422Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M45.8792 56.6467C46.0014 56.5162 46.1595 56.4249 46.3335 56.3845C46.5075 56.3442 46.6894 56.3566 46.8561 56.4201C47.0229 56.4836 47.1669 56.5955 47.2699 56.7414C47.373 56.8872 47.4303 57.0606 47.4346 57.2394C47.439 57.4181 47.3901 57.5943 47.2943 57.7453C47.1985 57.8964 47.0601 58.0156 46.8966 58.0877C46.7332 58.1598 46.5521 58.1817 46.3764 58.1504C46.2007 58.1192 46.0383 58.0363 45.9099 57.9123C45.8249 57.8313 45.7567 57.7344 45.7092 57.627C45.6616 57.5196 45.6357 57.4038 45.6328 57.2863C45.63 57.1688 45.6502 57.0518 45.6925 56.9421C45.7348 56.8323 45.7982 56.732 45.8792 56.6467Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M38.5017 58.527C38.6124 58.4095 38.7554 58.3276 38.9125 58.2915C39.0695 58.2554 39.2337 58.2668 39.3842 58.3242C39.5346 58.3816 39.6647 58.4824 39.7578 58.614C39.8509 58.7455 39.903 58.9018 39.9074 59.0632C39.9118 59.2245 39.8683 59.3836 39.7825 59.5203C39.6967 59.6571 39.5724 59.7653 39.4252 59.8313C39.2781 59.8974 39.1149 59.9182 38.9561 59.8913C38.7973 59.8644 38.6501 59.7909 38.5331 59.6801C38.3764 59.5315 38.285 59.3266 38.2791 59.1104C38.2732 58.8942 38.3533 58.6843 38.5017 58.527Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M30.8332 58.8457C30.899 58.776 30.9778 58.72 31.0653 58.681C31.1527 58.642 31.247 58.6208 31.3426 58.6185C31.4382 58.6163 31.5333 58.6331 31.6223 58.668C31.7113 58.7029 31.7925 58.7552 31.8612 58.8218C31.9518 58.8803 32.0282 58.9582 32.085 59.0499C32.1418 59.1417 32.1776 59.2449 32.1898 59.3523C32.2019 59.4596 32.1901 59.5683 32.1553 59.6706C32.1204 59.7729 32.0634 59.8663 31.9883 59.9439C31.9131 60.0215 31.8217 60.0815 31.7207 60.1195C31.6197 60.1575 31.5116 60.1726 31.4041 60.1637C31.2967 60.1548 31.1926 60.1222 31.0993 60.0681C31.006 60.014 30.9259 59.9398 30.8648 59.8509C30.7305 59.7204 30.6522 59.5426 30.6463 59.355C30.6404 59.1675 30.7074 58.9849 30.8332 58.8457Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M37.4679 66.607C37.8407 66.749 38.2583 66.5612 38.4007 66.1875C38.543 65.8138 38.3563 65.3958 37.9835 65.2537C37.6108 65.1117 37.1932 65.2995 37.0508 65.6732C36.9084 66.0469 37.0952 66.4649 37.4679 66.607Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M30.2268 67.335C30.3108 67.2462 30.4191 67.1842 30.5381 67.1569C30.6571 67.1296 30.7814 67.1383 30.8954 67.1818C31.0094 67.2253 31.1079 67.3016 31.1784 67.4012C31.249 67.5008 31.2885 67.6192 31.292 67.7414C31.2954 67.8636 31.2626 67.9841 31.1978 68.0878C31.1329 68.1914 31.0389 68.2736 30.9275 68.3238C30.8162 68.374 30.6926 68.3901 30.5722 68.37C30.4519 68.3499 30.3403 68.2945 30.2515 68.2108C30.1923 68.1551 30.1448 68.0882 30.1115 68.014C30.0783 67.9398 30.06 67.8597 30.0577 67.7783C30.0554 67.697 30.0691 67.6159 30.0982 67.5398C30.1272 67.4638 30.1709 67.3942 30.2268 67.335Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M23.7677 62.7114C23.822 62.6536 23.8872 62.6071 23.9596 62.5747C24.0319 62.5422 24.11 62.5245 24.1892 62.5226C24.2684 62.5207 24.3471 62.5345 24.4209 62.5633C24.4946 62.5922 24.5619 62.6354 24.6188 62.6906C24.6765 62.7442 24.7229 62.8088 24.7553 62.8806C24.7877 62.9525 24.8054 63.0301 24.8075 63.1089C24.8095 63.1878 24.7958 63.2663 24.7671 63.3398C24.7384 63.4133 24.6954 63.4804 24.6406 63.5371C24.5869 63.595 24.5223 63.6417 24.4506 63.6745C24.3789 63.7073 24.3014 63.7256 24.2227 63.7283C24.1439 63.731 24.0655 63.7182 23.9918 63.6904C23.9181 63.6626 23.8506 63.6205 23.7932 63.5665C23.6768 63.4566 23.6087 63.3048 23.6039 63.1445C23.5991 62.9842 23.658 62.8285 23.7677 62.7114Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M22.1594 70.3822C22.204 70.333 22.2582 70.2936 22.3187 70.2665C22.3792 70.2393 22.4446 70.225 22.5108 70.2244C22.5771 70.2239 22.6427 70.237 22.7035 70.2631C22.7644 70.2893 22.8191 70.3277 22.8644 70.3761C22.9568 70.4636 23.0107 70.5844 23.0142 70.7119C23.0177 70.8394 22.9705 70.9631 22.8829 71.0559C22.7954 71.1486 22.6748 71.2028 22.5475 71.2065C22.4203 71.2102 22.2968 71.1631 22.2044 71.0756C22.1555 71.0334 22.1155 70.9819 22.0867 70.9241C22.0579 70.8662 22.0409 70.8032 22.0367 70.7386C22.0325 70.6741 22.0412 70.6093 22.0623 70.5481C22.0834 70.4869 22.1164 70.4305 22.1594 70.3822Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M18.2557 80.0739C18.3946 80.1268 18.5503 80.0568 18.6033 79.9175C18.6564 79.7782 18.5868 79.6224 18.4479 79.5695C18.3089 79.5165 18.1533 79.5865 18.1002 79.7258C18.0471 79.8651 18.1167 80.0209 18.2557 80.0739Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M13.8587 69.1147C13.9113 69.0587 13.9792 69.0196 14.0539 69.0023C14.1287 68.9849 14.2068 68.9902 14.2784 69.0174C14.3501 69.0445 14.4121 69.0924 14.4565 69.1549C14.5009 69.2175 14.5258 69.2918 14.5281 69.3686C14.5303 69.4454 14.5097 69.5211 14.469 69.5862C14.4283 69.6514 14.3692 69.703 14.2992 69.7345C14.2293 69.766 14.1516 69.7761 14.076 69.7634C14.0004 69.7507 13.9303 69.7158 13.8746 69.6631C13.8368 69.6287 13.8064 69.587 13.7851 69.5405C13.7638 69.494 13.752 69.4436 13.7506 69.3925C13.7491 69.3413 13.7579 69.2903 13.7765 69.2426C13.795 69.1949 13.823 69.1514 13.8587 69.1147Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
        <path
          d="M15.6954 60.8472C15.794 60.7622 15.9205 60.7168 16.0505 60.7197C16.1805 60.7226 16.3046 60.7738 16.399 60.8632C16.4934 60.9526 16.5514 61.0739 16.5616 61.2038C16.5718 61.3336 16.5336 61.4627 16.4543 61.5661C16.4095 61.6246 16.3529 61.6731 16.2883 61.7083C16.2236 61.7436 16.1523 61.7648 16.079 61.7707C16.0057 61.7766 15.932 61.7671 15.8626 61.7426C15.7933 61.7182 15.7298 61.6794 15.6764 61.6288C15.623 61.5782 15.5808 61.517 15.5526 61.449C15.5243 61.381 15.5107 61.3078 15.5125 61.2341C15.5142 61.1604 15.5314 61.0879 15.5629 61.0213C15.5944 60.9547 15.6396 60.8954 15.6954 60.8472Z"
          fill="#FF764C"
          fillOpacity="0.8"
        />
      </g>
      <defs>
        <clipPath id="clip0_940_31876">
          <rect width="90" height="88" fill="white" transform="translate(31.332) rotate(20.8574)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BannerRightGraph2;
