import styled from 'styled-components';
import { Button } from '@material-ui/core';

export const Container = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  padding-top: 15vh;
  flex-direction: column;
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  font-family: 'Montserrat';
  margin-top: 28px;
`;

export const Content = styled.div`
  font-weight: 500;
  font-size: 14px;
  font-family: 'Montserrat';
  line-height: 20px;
  color: #73747d;
  margin-top: 18px;
  white-space: pre-wrap;
  text-align: center;
`;

export const AddUniversityButton = styled(Button)`
  margin-top: 36px;
  background: linear-gradient(90deg, #ed4b53 0%, #ff764c 100%);
  border-radius: 20px;
  box-shadow: none;
  transition: box-shadow 0.5s;
  &:hover {
    box-shadow: 1px 2px 10px #ed4b53;
  }
`;
