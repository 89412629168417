import React from 'react';
import PropTypes from 'prop-types';

export default function Neutral({ className }) {
  return (
    <svg viewBox="0 0 64 64" enableBackground="new 0 0 64 64" className={className}>
      <circle fill="#ffdd67" cx="32" cy="32" r="30" />
      <g fill="#664e27">
        <path d="m38.34 45.999h-12.69c-.873 0-.873-3 0-3h12.689c.876 0 .876 3 .001 3" />
        <circle cx="20.5" cy="27.592" r="4.5" />
        <circle cx="43.5" cy="27.592" r="4.5" />
      </g>
    </svg>
  );
}

Neutral.propTypes = {
  className: PropTypes.string,
};
