import React from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { StyledTableCell } from './index.style';
import UniversityNameHeader, { UniversityNameHeaderProps } from './UniversityNameHeader';
export { UniversityNameHeaderProps };
const TableHeader = (props: UniversityNameHeaderProps) => {
  return (
    <TableHead>
      <TableRow>
        <UniversityNameHeader {...props} />
        <StyledTableCell style={{ width: 100 }} align={'left'}>
          {'Goal'}
        </StyledTableCell>
        <StyledTableCell style={{ width: 100 }} align={'left'}>
          {'Round'}
        </StyledTableCell>
        <StyledTableCell style={{ width: 150 }} align={'left'}>
          {'Major'}
        </StyledTableCell>
        <StyledTableCell style={{ width: 150 }} align={'left'}>
          {'Deadline'}
        </StyledTableCell>
        <StyledTableCell style={{ width: '25%' }} align={'left'}>
          {'Supported by Crimson'}
        </StyledTableCell>
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
