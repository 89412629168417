import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { trackReactRoute } from 'utils/routeTracker';
import { reportError } from '@crimson-education/browser-logger';
import AppContainer from 'components/unique/AppContainer';
import AppWrapper from 'components/unique/AppWrapper';
import EditUser from 'components/unique/EditUser';
import LessonDetailsContainer from 'components/unique/LessonDetails';
import MyLessons from 'components/unique/MyLessons';
import MyStudentsContainer from 'components/unique/MyStudents';
import MySubjects from 'components/unique/MySubjects';
import NotificationPageContainer from 'components/unique/NotificationPage';
import OnboardUser from 'components/unique/OnboardUser';
import OurPeopleContainer from 'components/unique/OurPeople';
import SettingsContainer from 'components/unique/Pages/Settings';
import UserProfile from 'components/pages/UserProfile/loader';
import InviteTutorContainer from 'components/pages/InviteTutor';
import Invoicing from 'components/pages/Invoicing';
import Conversations from 'components/unique/Conversations';
import MessageBar from 'components/unique/Conversations/MessageBar';
import Unauthorised from 'components/pages/Unauthorised';
import AppCuesRedirect from 'components/pages/AppCuesRedirect';
import Logout from 'components/pages/Logout';
import NotFound from 'components/pages/NotFound';
import GradesStudentView from 'components/pages/Grades/StudentView';
import ProgressReportSummaryPage from 'components/pages/ProgressReportSummaryPage';
import ProgressReportPreviewPage from 'components/pages/ProgressReportPreviewPage';
import IncompleteReport from 'components/unique/IncompleteReport';
import Session from 'components/pages/Session';
import Bookings from 'components/pages/Bookings';
import Dashboard from 'components/pages/Dashboard';
import DocumentLibrary from 'components/pages/Documents';
import CGAReports from 'components/pages/CGAReports';
import ReportView from 'components/pages/CGAReports/ReportView';
import Community from 'components/pages/Community';
import Resource from 'components/pages/Resource';
import UsMentorOps from 'components/pages/UsMentorOps';
import UsEclOps from 'components/pages/UsEclOps';
import MetabaseDashboard from 'components/pages/MetabaseDashboard';
import Pathfinder from 'components/pages/Pathfinder';
import Contacts from 'components/unique/Contacts';
// import RoadmapV2 from 'components/pages/Roadmap';
// import MyRoadmap from 'components/unique/MyRoadmap/loader';
import { RoadmapSwitch } from 'components/pages/RoadmapSwitch';
// Chunk loaders
import ExamPrepListPageStudentView from 'components/pages/ExamPrep/ExamList/StudentView/loader';
import ExamPrepSession from 'components/pages/ExamPrep/Session/loader';
import ExamPrepReport from 'components/pages/ExamPrep/Report/loader';
import ExamPrepReportStudentView from 'components/pages/ExamPrep/Report/StudentView/loader';
import ExamPrepAnswerSheetStudentView from 'components/pages/ExamPrep/AnswerSheet/StudentView/loader';
import ExamPrepAnswerSheet from 'components/pages/ExamPrep/AnswerSheet/loader';

import TasksStudentView from 'components/pages/Tasks/StudentView/loader';
import EclStudentView from 'components/pages/Ecl/StudentView/loader';
import ApplicationsStudentView from 'components/pages/Applications/StudentView/loader';
import CGAStudentProgressReports from 'components/pages/CGAStudentProgressReports/loader';
import ErrorView, { ErrorActions } from 'components/generic/ErrorView';
import SessionRecording from 'components/pages/SessionRecording';
import { featureSwitches, useFeatureFlag } from '../featureSwitches';
import ApplicationTracker from 'components/organisms/ApplicationTracker';
import AppTrackerStaffDashboard from 'components/organisms/ApplicationTracker/StaffDashboard';
import StudentCenterView from 'components/pages/StudentCenter';
import DaVinciReports from 'components/pages/DaVinciReports';

/*
 * routingComponents.js
 * This file describes all React Router 4 routing components for the app.
 * This can be maintained as an intermediate step until we are ready to
 * consolidate on a specific routes architecture.
 */

/**
 * These routes remain here because even though they use the user profile route,
 * the content doesn't go inside the user profile
 * They've got their own page
 */
function UserRoute(props) {
  const { path } = props.match;

  return (
    <Switch>
      <Route path={`${path}/edit-role`} component={trackReactRoute(EditUser)} />
      <Route path={`${path}/package/:packageItemId/invite-tutor`} component={trackReactRoute(InviteTutorContainer)} />
      <Route path={`${path}/test-prep/report/:examPrepSessionId`} component={trackReactRoute(ExamPrepReport)} />
      <Route
        path={`${path}/test-prep/answersheet/:examPrepSessionId`}
        component={trackReactRoute(ExamPrepAnswerSheet)}
      />
      <Route path={`${path}/reports/:reportId/preview`} component={trackReactRoute(ProgressReportPreviewPage)} />
      <Route path={`${path}/reports/:reportId`} component={trackReactRoute(ProgressReportSummaryPage)} />
      <Route component={trackReactRoute(UserProfile)} />
    </Switch>
  );
}

UserRoute.propTypes = {
  match: PropTypes.object.isRequired,
};

function TestPrepRoute(props) {
  const { path, url } = props.match;

  return (
    <Switch>
      <Route path={`${path}/list`} component={ExamPrepListPageStudentView} />
      <Route path={`${path}/session/:examPrepSessionId`} component={ExamPrepSession} />
      <Route path={`${path}/report/:examPrepSessionId`} component={ExamPrepReportStudentView} />
      <Route path={`${path}/answersheet/:examPrepSessionId`} component={ExamPrepAnswerSheetStudentView} />
      <Redirect to={`${url}/list`} />
    </Switch>
  );
}

TestPrepRoute.propTypes = {
  match: PropTypes.object.isRequired,
};

function StudentsRoute(props) {
  const { path, url } = props.match;

  return (
    <Switch>
      <Route path={`${path}/:studentsTab/:contractId?`} component={MyStudentsContainer} />
      <Redirect to={`${url}/current`} />
    </Switch>
  );
}

StudentsRoute.propTypes = {
  match: PropTypes.object.isRequired,
};

function LessonsRoute(props) {
  const { path, url } = props.match;

  return (
    <Switch>
      <Route path={`${path}/lessons/:lessonId`} component={MyLessons} />
      <Route path={`${path}/lessons`} component={MyLessons} />
      <Redirect to={`${url}/lessons`} />
    </Switch>
  );
}

LessonsRoute.propTypes = {
  match: PropTypes.object.isRequired,
};

function MessagingRoute(props) {
  const { path } = props.match;
  return (
    <Conversations {...props}>
      <Switch>
        <Route path={`${path}/new-thread`} component={trackReactRoute(MessageBar)} />
        <Route path={`${path}/:threadId`} component={trackReactRoute(MessageBar)} />
        <Route component={trackReactRoute(MessageBar)} />
      </Switch>
    </Conversations>
  );
}

MessagingRoute.propTypes = {
  match: PropTypes.object.isRequired,
};

function EclRoute(props) {
  const { path } = props.match;

  return (
    <Switch>
      <Route path={`${path}`} component={trackReactRoute(EclStudentView)} />
      <Redirect to={`${path}`} />
    </Switch>
  );
}

EclRoute.propTypes = {
  match: PropTypes.object.isRequired,
};

function StudentCenterRoute(props) {
  const { path } = props.match;

  return (
    <Switch>
      <Route path={`${path}`} component={trackReactRoute(StudentCenterView)} />
      <Redirect to={`${path}`} />
    </Switch>
  );
}

StudentCenterRoute.propTypes = {
  match: PropTypes.object.isRequired,
};
function ApplicationRoute(props) {
  const { path } = props.match;
  const Comp = () => <ApplicationTracker showBanner />;
  return (
    <Switch>
      <Route path={`${path}`} component={trackReactRoute(Comp)} />
      <Redirect to={`${path}`} />
    </Switch>
  );
}

ApplicationRoute.propTypes = {
  match: PropTypes.object.isRequired,
};

function ContactsRoute(props) {
  const { path } = props.match;

  return (
    <Switch>
      <Route path={`${path}`} component={trackReactRoute(Contacts)} />
      <Redirect to={`${path}`} />
    </Switch>
  );
}

ContactsRoute.propTypes = {
  match: PropTypes.object.isRequired,
};

function InvoicingRoute(props) {
  const { path } = props.match;

  return (
    <Switch>
      <Route path={`${path}`} component={trackReactRoute(Invoicing)} />
      <Redirect to={`${path}`} />
    </Switch>
  );
}

InvoicingRoute.propTypes = {
  match: PropTypes.object.isRequired,
};

function SessionRoute(props) {
  const { path } = props.match;

  return (
    <Switch>
      <Route path={`${path}/:sessionId(\\d+)`} component={trackReactRoute(Session)} />
      <Redirect to="/calendar/schedule" />
    </Switch>
  );
}

SessionRoute.propTypes = {
  match: PropTypes.object.isRequired,
};

function CGAReportsRoute(props) {
  const { path } = props.match;

  return (
    <Switch>
      <Route path={`${path}/:reportId`} component={trackReactRoute(ReportView)} />
      <Route path={`${path}`} component={trackReactRoute(CGAReports)} />
      <Redirect to={`${path}`} />
    </Switch>
  );
}

CGAReportsRoute.propTypes = {
  match: PropTypes.object.isRequired,
};

function DaVinciReportsRoute(props) {
  const { path } = props.match;

  return (
    <Switch>
      <Route path={`${path}`} component={trackReactRoute(DaVinciReports)} />
      <Redirect to={`${path}`} />
    </Switch>
  );
}

DaVinciReportsRoute.propTypes = {
  match: PropTypes.object.isRequired,
};

function CommunityRoute(props) {
  const { path } = props.match;
  return (
    <Switch>
      <Route path={`${path}/`} component={Community} />
    </Switch>
  );
}
CommunityRoute.propTypes = {
  match: PropTypes.object.isRequired,
};

function ResourceRoute(props) {
  const { path } = props.match;
  return (
    <Switch>
      <Route path={`${path}/`} component={Resource} />
    </Switch>
  );
}

ResourceRoute.propTypes = {
  match: PropTypes.object.isRequired,
};

function UsMentorOpsRoute(props) {
  const { path } = props.match;
  return (
    <Switch>
      <Route path={`${path}/`} component={UsMentorOps} />
    </Switch>
  );
}

UsMentorOpsRoute.propTypes = {
  match: PropTypes.object.isRequired,
};

function UsEclOpsRoute(props) {
  const { path } = props.match;
  return (
    <Switch>
      <Route path={`${path}/`} component={UsEclOps} />
    </Switch>
  );
}

UsEclOpsRoute.propTypes = {
  match: PropTypes.object.isRequired,
};

const createInternalRoute = (internalComponent) => {
  const InternalRoute = (props) => {
    const { path } = props.match;
    return (
      <Switch>
        <Route path={`${path}/`} component={internalComponent} />
      </Switch>
    );
  };

  InternalRoute.propTypes = {
    match: PropTypes.object.isRequired,
  };

  return InternalRoute;
};

function CGAStudentProgressReportsRoute(props) {
  const { path } = props.match;

  return (
    <Switch>
      <Route path={`${path}`} component={trackReactRoute(CGAStudentProgressReports)} />
      <Redirect to={`${path}`} />
    </Switch>
  );
}

CGAStudentProgressReportsRoute.propTypes = {
  match: PropTypes.object.isRequired,
};

// 3 levels of error boundary, depending on the location of the error, we try to display as much as possible.
function TopLevelErrorBoundary(props) {
  return (
    <ErrorBoundary
      onError={(error, info) => reportError(error, info)}
      fallbackRender={({ error, resetErrorBoundary }) => (
        <ErrorView
          preset="UIError"
          technicalDetails={error && `${error.message || 'Unknown Error'}${error.stack && `\n\n${error.stack}`}`}
          actionButton={{
            ...ErrorActions.Reload,
            action: resetErrorBoundary,
          }}
        />
      )}
    >
      {
        // eslint-disable-next-line react/prop-types
        props.children
      }
    </ErrorBoundary>
  );
}

function AppContainerRoute(props) {
  // Only Track non-functional routes, as they are tracked at lower routes.
  const STAFF_DASHBOARD = useFeatureFlag('NEW_APPLICATION_TRACKER_PHASE_5');
  const STUDENT_CENTER_BETA = useFeatureFlag('STUDENT_CENTER_BETA');

  return (
    <AppContainer {...props}>
      <TopLevelErrorBoundary>
        <Switch>
          <Route path="/messages" component={MessagingRoute} />
          <Route path="/reports" component={trackReactRoute(IncompleteReport)} />
          <Route path="/appcues-redirect-to-user" component={AppCuesRedirect} />
          <Route path="/settings" component={trackReactRoute(SettingsContainer)} />
          <Route path="/notifications" component={trackReactRoute(NotificationPageContainer)} />
          <Route path="/calendar" component={trackReactRoute(Bookings)} />
          <Route path="/our-people" component={trackReactRoute(OurPeopleContainer)} />
          <Route path="/users/onboard" component={trackReactRoute(OnboardUser)} />
          <Route path="/users/:userId" component={UserRoute} />
          <Route path="/my-profile" component={trackReactRoute(UserProfile)} />
          <Route path="/subjects/:itemId" component={LessonsRoute} />
          <Route path="/subjects" component={trackReactRoute(MySubjects)} />
          <Route path="/lessons/:lessonId" component={trackReactRoute(LessonDetailsContainer)} />
          <Route path="/students" component={StudentsRoute} />
          {/* <Route path="/roadmap" component={featureSwitches.ROADMAP_TAB() ? RoadmapV2 : trackReactRoute(MyRoadmap)} /> */}
          <Route path="/roadmap" component={RoadmapSwitch} />
          <Route path="/tasks" component={trackReactRoute(TasksStudentView)} />
          <Route path="/test-prep" component={TestPrepRoute} />
          <Route path="/grades" component={GradesStudentView} />
          <Route path="/ecl" component={EclRoute} />
          <Route path="/applications" component={ApplicationRoute} />
          <Route path="/invoicing" component={InvoicingRoute} />
          <Route path="/session" component={SessionRoute} />
          <Route path="/dashboard" component={trackReactRoute(Dashboard)} />
          <Route path="/documents" component={trackReactRoute(DocumentLibrary)} />
          <Route path="/cga-reports" component={trackReactRoute(CGAReportsRoute)} />
          <Route path="/da-vinci-reports" component={trackReactRoute(DaVinciReportsRoute)} />
          <Route path="/community" component={CommunityRoute} />
          <Route path="/resource" component={ResourceRoute} />
          {featureSwitches.US_MENTOR_OPS_TAB() && <Route path="/us-mentor-ops" component={UsMentorOpsRoute} />}
          {featureSwitches.US_ECL_OPS_TAB() && <Route path="/us-ecl-ops" component={UsEclOpsRoute} />}
          <Route path="/pathfinder" component={createInternalRoute(Pathfinder)} />
          {STUDENT_CENTER_BETA && <Route path="/student-center" component={StudentCenterRoute} />}
          <Route path="/metabaseDashboard" component={trackReactRoute(MetabaseDashboard)} />
          <Route path="/contacts" component={ContactsRoute} />
          <Route path="/cga-student-progress-reports" component={CGAStudentProgressReportsRoute} />
          <Route path="/recording/:sessionId" component={trackReactRoute(SessionRecording)} />
          {STAFF_DASHBOARD && (
            <Route path="/app-tracker-staff-dashboard/:userId" component={trackReactRoute(AppTrackerStaffDashboard)} />
          )}
          <Route component={NotFound} />
        </Switch>
      </TopLevelErrorBoundary>
    </AppContainer>
  );
}

function AppWrapperRoute(props) {
  return (
    <TopLevelErrorBoundary>
      <AppWrapper {...props}>
        <TopLevelErrorBoundary>
          <Switch>
            <Route path="/logout" component={trackReactRoute(Logout)} />
            <Route path="/unauthorized" component={trackReactRoute(Unauthorised)} />
            <Route component={AppContainerRoute} />
          </Switch>
        </TopLevelErrorBoundary>
      </AppWrapper>
    </TopLevelErrorBoundary>
  );
}

export default AppWrapperRoute;
