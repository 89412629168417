import { Controller } from './type';
import { Actions, TStore } from './store';

export class MissionControlUpdatesController extends Controller<TStore, Actions> {
  openUpdatesModal = () => {
    this.dispatch?.('MSU/setUpdatesModalOpen', {
      updatesModalOpen: true,
    });
  };
  closeUpdatesModal = () => {
    this.dispatch?.('MSU/setUpdatesModalOpen', {
      updatesModalOpen: false,
    });
  };
}
