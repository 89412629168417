import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import InfiniteScroll from 'react-infinite-scroller';
import CircularProgress from '@material-ui/core/CircularProgress';
import ThreadStartsHere from 'components/unique/Conversations/ThreadStartsHere';
import MessageGroup from '../MessageGroup';
import DateDivider from './DateDivider';
import TypingIndicator from './TypingIndicator';
import css from './styles';

const getMessageCount = (messages) => {
  return Object.values(messages).reduce(
    (count, arr) => count + arr.reduce((_count, obj) => _count + obj.messages.length, 0),
    0,
  );
};
export default class MessageWindow extends Component {
  constructor(props) {
    super(props);
    this.scrollWindow = React.createRef();
    this.state = {
      activeItem: '',
      activeOption: '',
    };
  }

  componentDidMount() {
    this.scrollToBottom();
  }

  UNSAFE_componentWillUpdate(nextProps) {
    const { messages } = this.props;

    const messageCount = Object.keys(messages).reduce((total, currValue) => {
      return total + Object.keys(messages[currValue]).reduce((t) => t + 1, 0);
    }, 0);

    this.newMessagesAdded = Object.keys(nextProps.messages).length !== messageCount;
    if (this.newMessagesAdded) {
      const { scrollTop, scrollHeight, clientHeight } = this.scrollWindow.current;
      const scrollBottom = scrollHeight - clientHeight;
      this.scrollAtBottom = scrollBottom <= 0 || scrollTop === scrollBottom;
      if (!this.scrollAtBottom) {
        this.previousScrollHeight = this.scrollWindow.current.scrollHeight;
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { thread, isNew, consumeThread } = this.props;
    if (!isNew && thread.unreadMessagesCount > 0) {
      consumeThread(thread.id);
    }

    if (thread.id !== prevProps.thread.id || this.scrollAtBottom) {
      this.scrollToBottom();
    }

    if (this.newMessagesAdded) {
      if (this.scrollAtBottom) {
        this.scrollToBottom();
      } else {
        const offset = this.scrollWindow.current.scrollHeight - this.previousScrollHeight;
        this.scrollWindow.current.scrollTop = this.scrollWindow.current.scrollTop + offset;
      }
    }
  }

  setActiveItem = (activeItem) => {
    this.setState({
      activeItem,
    });
  };

  hasMoreHistory = () => {
    const { thread } = this.props;
    return thread.id && !thread.isHistoryComplete && !thread.isFetchingMessages;
  };

  scrollToBottom = () => {
    if (!this.scrollWindow.current) return;
    const { scrollHeight, clientHeight } = this.scrollWindow.current;
    const maxScrollTop = scrollHeight - clientHeight;
    this.scrollWindow.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
  };
  toggleOption = (id) => {
    if (this.state.activeOption === id) {
      this.setState({
        activeOption: '',
      });
    } else {
      this.setState({
        activeOption: id,
      });
    }
  };
  onImageLoaded = () => {
    const { scrollHeight, scrollTop, clientHeight } = this.scrollWindow.current;
    const diff = scrollHeight - clientHeight - scrollTop;
    // if list less than 30px from the bottom, scroll to bottom, 30px is a bias
    if (diff < 30) {
      setTimeout(this.scrollToBottom, 0);
    }
  };

  renderDateDividedMessages = ([day, messageGroups], isLastGroup, seenBy) => {
    const {
      currentUser,
      otherParticipants,
      retryMessage,
      retrySend,
      withdrawMessage,
      thread,
      downloadMessageFile,
    } = this.props;
    return (
      <div key={day}>
        <DateDivider timestamp={day} />
        {messageGroups.map(({ sender, messages, timestamp }, index) => (
          <MessageGroup
            key={index}
            user={sender}
            messages={messages}
            timestamp={timestamp}
            isSender={sender.userId === currentUser.userId}
            otherParticipants={otherParticipants}
            retryMessage={retryMessage}
            retrySend={retrySend}
            withdrawMessage={withdrawMessage}
            thread={thread}
            seenBy={seenBy}
            downloadMessageFile={downloadMessageFile}
            activeItem={this.state.activeItem}
            activeOption={this.state.activeOption}
            setActiveItem={this.setActiveItem}
            toggleOption={this.toggleOption}
            isLastGroup={isLastGroup}
            onImageLoaded={this.onImageLoaded}
          />
        ))}
      </div>
    );
  };
  loadMessages = () => {
    const { loadMessages, thread } = this.props;
    if (thread.isFetchingMessages) return;
    loadMessages(thread.id, thread.source);
  };

  render() {
    const {
      thread,
      messages,
      otherTypingParticipants,
      allActiveParticipants,
      currentUser,
      otherParticipants,
    } = this.props;

    let lastMessageId = null;
    const seenBy = [];
    let lastConsumptionTimestamp = null;
    Object.entries(messages).forEach(([day, messageGroups]) => {
      messageGroups.forEach(({ sender, messages }) => {
        const isSender = sender.userId === currentUser.userId;
        messages.forEach((message) => {
          let findSign = false;
          otherParticipants.forEach((participant) => {
            if (participant.lastSeenMessageId === message.id) {
              if (message.withdraw && lastMessageId) {
                const newParticipant = { ...participant, lastSeenMessageId: lastMessageId, lastConsumptionTimestamp };
                seenBy.push(newParticipant);
              } else {
                seenBy.push({ ...participant });
              }
              findSign = true;
            }
          });
          if (!findSign) {
            if (!message.withdraw && isSender) {
              lastMessageId = message.id;
              lastConsumptionTimestamp = message.createdAt;
            }
          }
        });
      });
    });
    return (
      <div className={css.messageWindow}>
        <div ref={this.scrollWindow} className={css.messages}>
          <div className={classNames(css.progressWrapper, { [css.hidden]: !thread.isFetchingMessages })}>
            <CircularProgress className={css.progress} size={18} />
          </div>
          <InfiniteScroll
            pageStart={0}
            loadMore={this.loadMessages}
            hasMore={this.hasMoreHistory()}
            isReverse
            useWindow={false}
          >
            {thread.isHistoryComplete || !thread.createdAt ? (
              <ThreadStartsHere
                type={thread.type}
                threadId={thread.id}
                source={thread.source}
                chatName={thread.chatName}
                users={allActiveParticipants.filter((x) => x.user.userId !== currentUser.userId).map((x) => x.user)}
                icon={thread.icon}
              />
            ) : null}
            {Object.entries(messages).map((message, index) =>
              this.renderDateDividedMessages(message, index === Object.entries(messages).length - 1, seenBy),
            )}
          </InfiniteScroll>
          <TypingIndicator otherTypingParticipants={otherTypingParticipants} />
        </div>
      </div>
    );
  }
}

MessageWindow.propTypes = {
  messages: PropTypes.object,
  loadMessages: PropTypes.func.isRequired,
  thread: PropTypes.object,
  isNew: PropTypes.bool,
  currentUser: PropTypes.object,
  otherParticipants: PropTypes.array,
  otherTypingParticipants: PropTypes.array,
  retryMessage: PropTypes.func,
  consumeThread: PropTypes.func,
  lastSeenMessageId: PropTypes.string,
  retrySend: PropTypes.func,
  withdrawMessage: PropTypes.func,
  downloadMessageFile: PropTypes.func,
  allActiveParticipants: PropTypes.array,
};
