import styled from 'styled-components';
import { PrimaryButton } from 'components/organisms/ApplicationTracker/components';

export const StyledButton = styled(PrimaryButton)`
  height: 36px;
  padding: 8px 24px;
  > span {
    color: #fff !important;
    font-size: 14px;
  }
  margin-left: auto;
`;
