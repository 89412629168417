// eslint-disable-next-line no-restricted-imports
import { cloneDeep } from 'lodash';
import { formatGraphQLRequestError } from 'utils/graphql';
import getGraphQLClient from '.';

/**
 * @param {import('@apollo/client').ApolloError} apolloError
 */
function getRawErrors(apolloError) {
  const rawError = apolloError.graphQLErrors || [];
  if (rawError.length === 0) {
    if (apolloError.networkError) {
      rawError.push(apolloError.networkError);
    }
  }
  return rawError;
}

/**
 * Legacy Error Wrapper to retain existing logic, used for the legacy createClient.
 * @deprecated
 */
export class LegacyGraphQLError extends Error {
  /**
   * @param {import('@apollo/client').ApolloError} apolloError
   */
  constructor(apolloError) {
    const rawError = getRawErrors(apolloError);
    super(formatGraphQLRequestError({ rawError }));
    this.apolloError = apolloError;
    this.rawError = rawError;
  }
}

/**
 * Wrapper around the graphql client to retrofit existing logic.
 * @deprecated Use the default getGraphQLClient instead and it's native Apollo interface.
 * @param {string} endpoint API Service Endpoint.
 * @returns Legacy GraphQL Client.
 */
export const createClient = (endpoint) => {
  const client = getGraphQLClient(endpoint, false);

  const clientWrapper = {
    /**
     * Wrapper around the graphql client to retrofit existing logic.
     * @deprecated Use the default getGraphQLClient instead and it's native Apollo interface.
     * @param {import('graphql').DocumentNode} query Query to execute.
     * @param {*} variables Variable for the Query.
     * @returns data from query
     */
    query: async (query, variables) => {
      // Handle query and mutate under the same function, as legacy.
      const operation = query.definitions.find((def) => def.kind === 'OperationDefinition');
      const isMutation = operation.operation === 'mutation';
      if (isMutation) {
        return clientWrapper.mutate(query, variables);
      }

      try {
        // We can't trust in the legacy code that we don't depend on non-cached content.
        const results = await client.query({ query, variables, fetchPolicy: 'no-cache' });
        if (results.error) {
          throw new LegacyGraphQLError(results.error);
        }

        // Clone data, as it is readonly by default.
        return cloneDeep(results.data);
      } catch (e) {
        throw new LegacyGraphQLError(e);
      }
    },
    /**
     * Wrapper around the graphql client to retrofit existing logic.
     * @deprecated Use the default getGraphQLClient instead and it's native Apollo interface.
     * @param {import('graphql').DocumentNode} query Query to execute.
     * @param {*} variables Variable for the Query.
     * @returns data from query
     */
    mutate: async (query, variables) => {
      try {
        // We can't trust in the legacy code that we don't depend on non-cached content.
        const results = await client.mutate({ mutation: query, variables, fetchPolicy: 'no-cache' });
        if (results.error) {
          throw new LegacyGraphQLError(results.error);
        }

        // Clone data, as it is readonly by default.
        return cloneDeep(results.data);
      } catch (e) {
        throw new LegacyGraphQLError(e);
      }
    },
  };
  return clientWrapper;
};
