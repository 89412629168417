import React from 'react';
import PropTypes from 'prop-types';

export default function CrimsonUnfiltered({ className }) {
  return (
    <svg className={className} height="24" viewBox="0 0 24 24" width="27" xmlns="http://www.w3.org/2000/svg">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <polygon
          id="Fill-1"
          fill="#1EF970"
          points="2.7,0.7 2.3,1.1 2.3,22.9 2.7,23.3 4.5,23.3 21.3,14.2 21.7,13.4 21.7,10.6 21.3,9.8 4.5,0.7 "
        />
      </g>
    </svg>
  );
}

CrimsonUnfiltered.propTypes = {
  className: PropTypes.string,
};
