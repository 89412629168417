/**
 * remove quotation mark from object keys in a json string.
 * @export
 * @param {any} object to convert
 * @returns converted string of the object
 */
export function getGraphqlObjectString(object) {
  return JSON.stringify(object).replace(/([{,])\s*"([^,"]+?)"\s*:/g, '$1$2:');
}

/**
 * escape new line and quate mark
 * @export
 * @param {String} value string to escape
 * @returns json safey string
 */
export function escapeString(value) {
  return value || value === 0 ? JSON.stringify(`${value}`.replace(/(\r\n|\n|\r)/gm, '\n')).slice(1, -1) : '';
}

/**
 * Process error into human readable form, removing duplicate errors.
 * @export
 * @param {import('../graphql/utils').LegacyGraphQLError} error object to process.
 * @returns {String} human readable error.xs
 */
export const formatGraphQLRequestError = (error, fallback = 'An unexpected error occurred') => {
  if (error.rawError && error.rawError[0].statusCode === 401) {
    return 'Unauthorized';
  }
  return error.rawError
    ? `${error.rawError
        .map((err) => err.message.replace(/\.+$/, ''))
        .filter((value, index, arr) => arr.indexOf(value) === index)
        .join(', ')}.`
    : fallback;
};
