import { createSelector } from 'reselect';
import { getViewableUser, getSelectedAcademicId } from 'selectors/meta';

export const academic = (state) => state.get('academic');

export const getAcademicById = (id) => {
  return createSelector(academic, (academic) => (academic.get(id) ? academic.get(id).toJS() : null));
};

export const getSelectedAcademic = createSelector(getSelectedAcademicId, academic, (id, academic) => {
  if (!id) return null;
  const selectedAcademic = academic.get(id);
  return selectedAcademic && selectedAcademic.toJS();
});

export const getAcademicsForUser = createSelector([academic, getViewableUser], (academics, userId) => {
  return academics
    ? academics
        .toList()
        .filter((academic) => academic.get('userId') === userId)
        .toJS()
    : [];
});
