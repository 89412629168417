import { BookingStatusTypes } from '@crimson-education/common-config';

import { footerType } from './constants';

const buttons = {
  BACK: 'Back',
  CONFIRM_REQUEST: 'Yes',
  CONFIRM_REQUEST_IN_THE_PAST: 'Yes',
  DELETE_REQUEST_IN_THE_PAST: 'Yes, delete this request',
  DELETE_REQUEST_IN_THE_PAST_ADMIN: 'No',
  DO_NOT_DELETE_LESSON_IN_THE_PAST: 'Close',
  DECLINE_REQUEST: 'No',
  DECLINE_SESSION: 'Decline session',
  CANCEL_BOOKING: 'Yes, cancel this booking',
  CANCEL_REQUEST: 'Yes, cancel this request',
  SUGGEST_NEW_TIME: 'Suggest new time',
  GO_TO_MESSAGES: 'Go to messages',
  CLOSE: 'Close',
};

export default function getFooterProperties(type, bookingStatus, actions) {
  switch (type) {
    case footerType.SENDER_CANCEL:
      return {
        primary: {
          text: bookingStatus === BookingStatusTypes.TENTATIVE ? buttons.CANCEL_REQUEST : buttons.CANCEL_BOOKING,
          action: actions.onCancelBooking,
          dataTestId: 'cancelRequestButton',
        },
        back: actions.goBack,
      };

    case footerType.RECEIVER_CONFIRM:
      return {
        primary: {
          text: buttons.CONFIRM_REQUEST,
          action: actions.onConfirmBooking,
          dataTestId: 'confirmBookingButton',
        },
        secondary: {
          text: buttons.DECLINE_REQUEST,
          action: actions.onConfirmDeclineBooking,
          dataTestId: 'declineRequestButton',
        },
      };

    case footerType.RECEIVER_CONFIRM_IN_THE_PAST:
      return {
        primary: {
          text: buttons.CONFIRM_REQUEST_IN_THE_PAST,
          action: actions.onConfirmBookingInThePast,
          dataTestId: 'confirmBookingButton',
        },
      };

    case footerType.RECEIVER_CONFIRM_IN_THE_PAST_ADMIN:
      return {
        primary: {
          text: buttons.CONFIRM_REQUEST_IN_THE_PAST,
          action: actions.onConfirmBookingInThePast,
          dataTestId: 'confirmBookingButton',
        },
        secondary: {
          text: buttons.DELETE_REQUEST_IN_THE_PAST_ADMIN,
          action: actions.openCancelBookingModal,
          dataTestId: 'declineRequestButton',
        },
      };

    case footerType.RECEIVER_CONFIRM_IN_THE_PAST_STUDENT:
      return {
        primary: {
          text: buttons.CONFIRM_REQUEST_IN_THE_PAST,
          action: actions.onConfirmBookingInThePast,
          dataTestId: 'confirmBookingButton',
        },
      };

    case footerType.RECEIVER_DECLINE:
      return {
        primary: {
          text: buttons.DECLINE_SESSION,
          action: actions.onConfirmDeclineBooking,
          dataTestId: 'declineRequestButton',
        },
        back: actions.goBack,
      };

    case footerType.SENDER_DELETE_IN_THE_PAST:
      return {
        primary: {
          text: buttons.DELETE_REQUEST_IN_THE_PAST,
          action: actions.openCancelBookingModal,
          dataTestId: 'openCancelBookingModalButton',
        },
      };

    case footerType.CONFIRMED:
      return {
        primary: {
          text: buttons.GO_TO_MESSAGES,
          action: actions.redirectToMessagesFromBooking,
          dataTestId: 'redirectToMessagesFromBookingButton',
          // permissions: props.bookingItemId
          //   && checkPermission(props.loginUserPermissions, PermissionTypes.VIEW_LESSON_DETAILS),
        },
      };

    default:
      return {};
  }
}
