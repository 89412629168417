import React from 'react';
import { useMissionControlUpdatesContext } from '../helper';
import Close from '@material-ui/icons/Close';
import { Container, Title, Content, StyledIconButton } from './style';
import Filters from './Filters';

const Header = () => {
  const { controller } = useMissionControlUpdatesContext();
  return (
    <Container>
      <Title>{'Mission Control Updates'}</Title>
      <StyledIconButton onClick={controller.closeUpdatesModal}>{<Close style={{ fontSize: 25 }} />}</StyledIconButton>
      <Content>{'Select the relevant mission updates and add to your Session Notes'}</Content>
      <Filters />
    </Container>
  );
};

export default Header;
