import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/molecules/Modal';
import { CircularProgress } from '@material-ui/core';
import css from './styles.scss';

export default function SyncWaitingModal(props) {
  const { isOpen, onCancel } = props;
  return (
    <Modal customHeaderStyle={css.hideHeader} isOpen={isOpen} onClose={onCancel} closeButtonClass={css.hideHeader}>
      <div className={css.front}>
        <div className={css.modalBody}>
          <div className={css.bookingWithWrapper}>
            <CircularProgress size={60} thickness={5} />
            <div className={css.waitingText}>
              <div>Data synchronizing…</div>
              <div>It will probably take a few minutes, </div>
              <div>please wait.</div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

SyncWaitingModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
};
