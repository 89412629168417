import { createSelector } from 'reselect';
import { eventStatusTypes } from '@crimson-education/common-config';
import { pageSize } from 'utils/invoiceUtils';
import { getMetaItem } from 'selectors/meta';
import componentKeys from 'constants/componentKeys';

const invoicing = (state) => state.get('invoicing');

const getItemData = (state, itemIds) => {
  return itemIds
    .map((id) => {
      const invoiceItem = state.getIn(['invoiceItem', id.toString()]);

      if (!invoiceItem) return null;

      return {
        start: invoiceItem.get('start'),
        end: invoiceItem.get('end'),
        duration: invoiceItem.get('duration'),
        status: invoiceItem.get('status'),
        amount: invoiceItem.get('amount'),
        subjectName: state.getIn(['packageItem', invoiceItem.get('subjectId'), 'name']),
        studentName: `${state.getIn(['user', invoiceItem.get('studentId'), 'firstName'])} ${state.getIn([
          'user',
          invoiceItem.get('studentId'),
          'lastName',
        ])}`,
      };
    })
    .toJS();
};

const getCompletedLessons = (items) =>
  items.filter(
    ({ status }) =>
      status === eventStatusTypes.COMPLETED ||
      status === eventStatusTypes.AWAITING_STUDENT_FEEDBACK ||
      status === eventStatusTypes.PENALTY,
  );

export const getInvoice = (id) =>
  createSelector(invoicing, (state) => {
    const invoice = state.get('invoice').get(id);

    if (!invoice) return null;
    const invoiceItems = invoice.get('items');

    if (!invoiceItems) return null;
    const items = getItemData(state, invoiceItems);
    const completedLessons = getCompletedLessons(items);
    const tutor = state.getIn(['user', invoice.get('tutor')]).toJS();

    return Object.assign({}, invoice.toJS(), { items, completedLessons, tutor });
  });

export const getInvoices = createSelector(invoicing, (state) => {
  const invoices = state.get('invoice');
  let invoiceList = [];

  if (invoices && invoices.size) {
    invoiceList = invoices.toArray().map((i) => {
      const invoice = i.toJS();
      const tutorId = invoice.tutor;
      if (tutorId) {
        invoice.tutor = state.getIn(['user', tutorId]).toJS();
      }
      return invoice;
    });
  }

  return invoiceList.sort((a, b) => {
    return a.periodStart < b.periodStart ? 1 : -1;
  });
});

export const getFilterableBillingCountries = createSelector(invoicing, (state) => {
  const countries = state.get('filterableBillingCountries');
  return countries ? countries.toJS() : [];
});

export const getFilterableTutors = createSelector(invoicing, (state) => {
  const tutors = state.get('filterableTutors');
  return tutors ? tutors.toJS() : [];
});

export const getFilterablePeriods = createSelector(invoicing, (state) => {
  const periods = state.get('filterablePeriods');
  return periods ? periods.toJS() : [];
});

export const getTotalPages = createSelector(invoicing, (state) => {
  const totalCount = state.getIn(['pageInfo', 'totalCount']);

  if (!totalCount) return 0;

  return Math.ceil(totalCount / pageSize);
});

export const getCurrentPage = createSelector(invoicing, (state) => {
  const endCursor = state.getIn(['pageInfo', 'endCursor']);

  if (!endCursor) return 0;

  return Math.ceil(endCursor / pageSize);
});

export const getSelectedInvoice = createSelector(
  invoicing,
  getMetaItem(componentKeys.INVOICE_SELECTED_ID),
  (invoices, id) => {
    const invoiceState = invoices.get('invoice');
    if (!invoiceState) return null;
    const invoice = invoices.get('invoice').get(id);

    if (!invoice) return null;
    const invoiceItems = invoice.get('items');

    if (!invoiceItems) return null;
    const items = getItemData(invoices, invoiceItems);
    const completedLessons = getCompletedLessons(items);
    const tutor = invoices.getIn(['user', invoice.get('tutor')]).toJS();

    return Object.assign({}, invoice.toJS(), { items, completedLessons, tutor });
  },
);
