import React from 'react';

import { EventTypes } from '@crimson-education/common-config';

import PropTypes from 'prop-types';
import VersionOne from './VersionOne';
import VersionTwo from './VersionTwo';
import VersionThree from './VersionThree';

const SESSION_REPORT_HEADING = 'SESSION REPORT';

export default function SessionReportModal(props) {
  const {
    isOpen,
    booking,
    version,
    onSubmit,
    eventId,
    tutorId,
    studentId,
    fetchIncompleteReports,
    fetchIncompleteSessionReports,
    role,
    isStraOrEdu,
    staffInfo,
  } = props;

  if ((isStraOrEdu || version === 3) && booking.get('type').toLowerCase() === EventTypes.SESSION.toLowerCase()) {
    return (
      <VersionThree
        booking={booking}
        isOpen={isOpen}
        title={SESSION_REPORT_HEADING}
        onSubmit={onSubmit}
        eventId={eventId}
        reporterId={tutorId}
        role={role}
        studentId={studentId}
        fetchIncompleteSessionReports={fetchIncompleteSessionReports}
        closeSessionReport={props.closeSessionReport}
        lapsedBillingPeriod={props.lapsedBillingPeriod}
        type={EventTypes.SESSION}
        staffInfo={staffInfo}
      />
    );
  }

  if (version === 2) {
    return (
      <VersionTwo
        booking={booking}
        isOpen={isOpen}
        title={SESSION_REPORT_HEADING}
        onSubmit={onSubmit}
        eventId={eventId}
        tutorId={tutorId}
        studentId={studentId}
        fetchIncompleteReports={fetchIncompleteReports}
        closeSessionReport={props.closeSessionReport}
        lapsedBillingPeriod={props.lapsedBillingPeriod}
        type={EventTypes.LESSON}
      />
    );
  }

  return (
    <VersionOne
      isOpen={isOpen}
      title={SESSION_REPORT_HEADING}
      onSubmit={onSubmit}
      eventId={eventId}
      tutorId={tutorId}
      studentId={studentId}
      closeSessionReport={props.closeSessionReport}
      type={EventTypes.LESSON}
    />
  );
}

SessionReportModal.propTypes = {
  isOpen: PropTypes.bool,
  booking: PropTypes.shape(),
  version: PropTypes.number,
  onSubmit: PropTypes.func.isRequired,
  eventId: PropTypes.number.isRequired,
  tutorId: PropTypes.string.isRequired,
  studentId: PropTypes.string.isRequired,
  closeSessionReport: PropTypes.func,
  fetchIncompleteReports: PropTypes.func,
  fetchIncompleteSessionReports: PropTypes.func,
  lapsedBillingPeriod: PropTypes.object,
  role: PropTypes.object,
  isStraOrEdu: PropTypes.bool,
  staffInfo: PropTypes.object,
};
