import React from 'react';
import PropTypes from 'prop-types';

export default function MagnifyingGlass({ className }) {
  return (
    <svg className={className} width="10px" height="10px" viewBox="353 169 10 10">
      <path
        stroke="none"
        fillRule="evenodd"
        d="M353.679231,172.850385 C353.679231,171.103462 355.093462,169.682308 356.831923,169.682308 C358.570385,169.682308 359.985,171.103462 359.985,172.850385 C359.985,174.597308 358.570385,176.018462 356.831923,176.018462 C355.093462,176.018462 353.679231,174.597308 353.679231,172.850385 L353.679231,172.850385 Z M363,178.268846 L359.898846,175.152308 C360.377308,174.509615 360.663846,173.714615 360.663846,172.850385 C360.663846,170.723846 358.948462,169 356.831923,169 C354.715769,169 353,170.723846 353,172.850385 C353,174.976923 354.715769,176.700769 356.831923,176.700769 C357.704615,176.700769 358.506154,176.404615 359.150385,175.911154 L362.224231,179 L363,178.268846 Z"
      />
    </svg>
  );
}

MagnifyingGlass.propTypes = {
  className: PropTypes.string,
};

MagnifyingGlass.displayName = 'MagnifyingGlass';
