import React from 'react';

const GirlWoohoo = (props) => (
  <svg viewBox="0 0 160.7 338.89" {...props}>
    <defs>
      <linearGradient id="linear-gradient" x1={86.88} y1={250.26} x2={66.5} y2={121.53} gradientUnits="userSpaceOnUse">
        <stop offset={0} stopColor="#d62027" />
        <stop offset={0.28} stopColor="#ba2026" />
        <stop offset={0.87} stopColor="#732024" />
        <stop offset={1} stopColor="#632023" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-2"
        x1={1568.72}
        y1={41.92}
        x2={1572.01}
        y2={41.92}
        gradientTransform="matrix(1.5 .07 -.11 1 -2290.19 -4.1)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#211c40" />
        <stop offset={0.9} stopColor="#282847" />
      </linearGradient>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <circle className="cls-1" cx={63.5} cy={39.28} r={13.54} fill="#dca65e" />
        <path
          className="cls-2"
          d="M65 323.76s-16.34 13.15-15.2 14.67 11.35-.86 18.57-5.05 2.67-9.62 2.67-9.62-1.2 2.93-6.04 0z"
          fill="#fac1c2"
        />
        <path
          d="M69.07 251.63s-4.52-23.27-4.87-26.54-4-16.37-2.76-32.2-.14-35.09-.14-35.09l28.14 4.3s5.28 15.66 6.51 26.49-9.88 54-7.51 64.16c0 0-12.86 12.11-19.37-1.12"
          fill="url(#linear-gradient)"
        />
        <path
          className="cls-4"
          fill="#cd4b53"
          d="M39.82 148.68s-5.7 17.31-7 29.29 7.41 54.21 8.11 70.93c.49 11.49 8.19 28.88 14.23 46.66C60.49 311.33 64.18 320.1 65 324c.27 1.32 4.51 3.75 6 0 0 0-3.32-35.72-2.68-43.83.82-10.47-.66-19-6.64-31 0 0 5-27.26 5.41-30.87s4.32-18.1 3-35.6.15-38.79.15-38.79z"
        />
        <path
          className="cls-4"
          fill="#cd4b53"
          d="M69.07 251.63s-4.52-23.27-4.87-26.54-4-16.37-2.76-32.2-.14-35.09-.14-35.09l28.14 4.3s5.28 15.66 6.51 26.49-9.88 54-7.51 64.16c0 0-12.86 12.11-19.37-1.12"
        />
        <path
          className="cls-5"
          fill="#fee863"
          d="M35.12 118.74c.09-.78.18-1.53.27-2.22 1.13-8.25 3.59-25-.12-23.72 0 0-3.72-.4-7 5"
        />
        <path
          className="cls-6"
          fill="#e3646f"
          d="M111.29 41.29c-4.11-10.4-10.15-22.15-10.15-22.15a15.89 15.89 0 0 0 .44-9.08c-1.33-4.51-7.92-10.74-9.32-10-3.08 1.64-1 6.16.45 8.67s1.36 7.42 1.36 7.42-1.77-3.41-3.76-4.85-1.53-.28.6 4.06a13.4 13.4 0 0 0 5.94 5.94c.22 1.71 2.19 17.31 2 19s.2 18 2.12 19.06C97.34 79 93.59 86 89.56 93.24c-3.33 6-19.52 13.43-15.63 12.92A133.1 133.1 0 0 0 90.06 117c1.13.65 9 1.7 17.34-17.34 10.67-24.45 6.75-40.93 6.75-40.93s1.25-7.05-2.86-17.44z"
        />
        <path
          className="cls-5"
          fill="#fee863"
          d="M90.06 117c-10.52 2.82-6.77-7-6.77-7-3.9.51 2.94-10.8 6.27-16.82a37.71 37.71 0 0 0 1.89-4.13s6.4 17.47-1.39 27.93"
        />
        <path
          className="cls-6"
          fill="#e3646f"
          d="M75.92 62.66s1.49-.58 2.28 0 1.94 4.46-1.41 8.23-.87-8.23-.87-8.23z"
        />
        <path
          className="cls-2"
          fill="#fac1c2"
          d="M96 188.59c-1.23-10.83-6.51-26.49-6.51-26.49-.06-.15-5.74-16.48-5.42-22.29.36-6.53 4-16.72 6.08-22.77 2.19-6.22-.5-23.8-.5-23.8L74.28 88c-11-4.32-21.39-.54-21.65.12s-19.26 4.64-19.26 4.64c3.94 22.63.07 22 2.36 32.84 1 4.69 4.73 9.64 4.34 16.61A74.74 74.74 0 0 1 38.18 154c-1.79 6.06-4.52 16.15-5.39 24-.77 6.89-4.69 23.81-2.23 40.12 0 0 8.2 5 23.39 2.57s26.75-11.89 43.49-5.59c1.96-11.3-.97-21.89-1.44-26.51z"
        />
        <path className="cls-6" fill="#e3646f" d="M49 62.66s-1.48-.58-2.28 0-1.94 4.46 1.41 8.23.87-8.23.87-8.23z" />
        <path
          className="cls-4"
          fill="#cd4b53"
          d="M53.81 76.84s2.64 7.86-1.18 11.27c-3 2.73 2.87 18.13 11.3 19C74.33 108.26 76.74 90 74.28 88c-5.44-4.41-1.77-10.71-1.68-12.35s-10.22-10.17-18.79 1.19z"
        />
        <path
          className="cls-6"
          fill="#e3646f"
          d="M48.78 54.24c.4-3.88 5.75-10.46 14.66-10.46S77.51 50 76.71 60.05c0 0-.66 7.44-1.45 12.28-.63 3.83-6.85 13-12 13-4.26 0-11.57-7.09-13.15-11.33s-1.72-15.89-1.33-19.76z"
        />
        <path
          className="cls-4"
          fill="#cd4b53"
          d="M67.91 245.21c1 22.17 16.79 13.2 33.08 7.43s51.82-18.67 48.55-27.86c-.68-1.92-21.46 9-34.6 6.61-16.79-3.08-25.7 5.13-25.7 5.13s-2.82 17.74-13.85 17.69c-6.39-.03-7.48-9-7.48-9z"
        />
        <path
          className="cls-2"
          fill="#fac1c2"
          d="M143.37 234s6.49 2.64 9.67 4.88 14 8.42 2.55-8.56c-6.79-10.08-6.68-11.35-15.17-3.34a9 9 0 0 1 2.95 7.02z"
        />
        <path
          className="cls-6"
          fill="#e3646f"
          d="M8.75 110c.5 1.69 5.69 19.3 5.83 21s1.26 12.43 6.11 16.58c0 0 8 7.21 10.68-2.93 2-4.23 2.78-17.41 3.75-25.93l-6.81-21a24 24 0 0 0-2.12 4.74c-3.44 10.34-2.72 16.22-3.38 18.17 0 .13-.11.37-.18.68-5-7.54-10-14.23-10-14.23s4-13.39 1.5-10.28-2.49 4.2-3.58 3.58-7.16-11.77-9.19-7.65S-1.91 108 8.75 110z"
        />
        <path
          className="cls-1"
          fill="#dca65e"
          d="M59.49 50.77S48.79 50.85 48.6 67c0 0-6.14-18.5 10.89-23zM61.62 51.18L59.49 44s13-2 16.8 6.51-.37 12.14-.37 12.14-11.29-4.5-14.3-11.47z"
        />
        <path
          className="cls-7"
          fill="#d62027"
          d="M39.61 146.57s22.68 3.52 44.87-1.9l.29 1.33s-17.28 5.55-45.33 1.64z"
        />
        <path className="cls-7" fill="#d62027" transform="translate(-10.31 4.83)" d="M59.69 146.85h7.11v3.24h-7.11z" />
        <path
          transform="rotate(-4.04 63.458 148.577)"
          fill="url(#linear-gradient-2)"
          d="M60.99 147.73h4.81v1.45h-4.81z"
        />
      </g>
    </g>
  </svg>
);

export default GirlWoohoo;
