import { connect } from 'react-redux';
import { getAllIncompleteReports } from 'selectors/incompleteReport';
import { getLoginUser } from 'selectors/user';
import { getMetaItem } from 'selectors/meta';
import { allBookings } from 'selectors/booking';
import { showCompleteReportModal } from 'ducks/incompleteReport';
import componentKeys from 'constants/componentKeys';
import IncompleteReport from './IncompleteReport';

export default connect(
  (state) => ({
    reports: getAllIncompleteReports(state),
    bookings: allBookings(state).toJS(),
    nextBillingPeriod: getMetaItem(componentKeys.NEXT_BILLING_PERIOD)(state),
    loginUser: getLoginUser(state),
  }),
  (dispatch) => ({
    showCompleteReportModal: (eventId) => dispatch(showCompleteReportModal(eventId)),
  }),
)(IncompleteReport);
