import { Event, getAppYearStudents, isInDateRange, DataInputs, processor, filterUserCountMap, TODAY } from './util';
import {
  schoolConfirmationNotSendBase,
  parentApprovalPendingBase,
  parentApprovalRejectedBase,
  onlyReceiveRejectionBase,
  haventReceiveOfferBase,
  incompleteResultsBase,
  schoolSelectionDataBase,
  milestoneMeetingBase,
} from './common';
import { isInvitedToInterview, isUCASSchool } from '../../util';
import moment from 'moment';
export const schoolConfirmationNotSend: Event<string[]> = {
  ...schoolConfirmationNotSendBase,
  value: 'schoolConfirmationNotSendUK',
  region: 'UK',
  filterInput: (input, event) => {
    const { applications } = input;
    // after 09-05 UK 1
    // after 12-10 UK 1 UK 2
    const uk1Only = isInDateRange('09-05', '12-10');
    // if between [09-05, 12-11)
    // show UK1 | Medicine applications
    if (uk1Only) {
      return {
        ...input,
        applications: applications.filter((o) => o.round === 'UK 1' || o.round === 'Medicine'),
      };
    }
    return input;
  },
  filterAppYearStudents: (students) => {
    return getAppYearStudents(students);
  },
  availability: () => {
    return !isInDateRange('09-01', '09-05'); // 09-05 util next year 09-01
  },
};

export const parentApprovalPending: Event = {
  ...parentApprovalPendingBase,
  value: 'parentApprovalPendingUK',
  region: 'UK',
  filterAppYearStudents: (students) => {
    return getAppYearStudents(students);
  },
  availability: () => {
    return true;
  },
};

export const parentApprovalRejected: Event = {
  ...parentApprovalRejectedBase,
  value: 'parentApprovalRejectedUK',
  region: 'UK',
  filterAppYearStudents: (students) => {
    return getAppYearStudents(students);
  },
  availability: () => {
    return !isInDateRange('06-01', '09-01');
  },
};

export const submissionPending: Event = {
  priority: 0,
  label: 'Submission pending',
  value: 'submissionPending',
  region: 'UK',
  filterAppYearStudents: (students) => getAppYearStudents(students),
  filterInput: (input) => {
    return {
      ...input,
      applications: input.applications.filter((o) => !o.isSubmitted && !isInvitedToInterview(o)), // return in progress applications only
    };
  },
  availability: () => {
    return !isInDateRange('09-01', '09-30'); //after 09-30 - next year 09-01
  },
  resolve: ({ applications, studentUserIds }) => {
    const isAfterSep30 = isInDateRange('09-30');
    const isAfterJan01 = isInDateRange('01-01');
    const countMap = processor(applications, studentUserIds, (app) => {
      const isUK1 = app.round === 'UK 1';
      const isUK2 = app.round === 'UK 2';
      const isMedecine = app.round === 'Medicine';
      const isNonUCAS = !isUCASSchool(app);
      // non UCAS 2 weeks before deadline
      if (isNonUCAS && app.deadline) return moment(app.deadline).diff(TODAY(), 'day') <= 12;
      // after 09-30 UK 1, or Medicine
      if (isAfterSep30) return isUK1 || isMedecine;
      // after 01-01 UK 2
      if (isAfterJan01) return isUK2;
      return false;
    });
    return filterUserCountMap(countMap, (v) => !!v);
  },
};

export const onlyReceiveRejection: Event = {
  ...onlyReceiveRejectionBase,
  region: 'UK',
  value: 'onlyReceiveRejectionUK',
  filterAppYearStudents: (students) => getAppYearStudents(students),
  availability: () => isInDateRange('03-01', '09-01'), // after 03-01
};

export const haventReceiveOffer: Event = {
  ...haventReceiveOfferBase,
  region: 'UK',
  value: 'haventReceiveOffeUK',
  filterAppYearStudents: (students) => getAppYearStudents(students),
  availability: () => isInDateRange('03-01', '09-01'), // after 03-01
};

export const incompleteResults: Event = {
  ...incompleteResultsBase,
  region: 'UK',
  value: 'incompleteResultsUK',
  filterAppYearStudents: (students) => getAppYearStudents(students),
  availability: () => isInDateRange('06-01', '09-01'), // after 06-01
};

export const schoolSelectionData: Event<
  Record<
    'inExploration' | 'shortListInProgress' | 'finalListInProgress' | 'parentApprovalPending' | 'finalListApproved',
    string[]
  >
> = {
  ...schoolSelectionDataBase,
  region: 'UK',
  label: 'school selection',
  value: 'schoolSelectionDataUK',
};

export const milestoneMeeting: Event<Record<'meeting1' | 'meeting2' | 'meeting3' | 'meeting4', string[]>> = {
  ...milestoneMeetingBase,
  value: 'milestoneMeetingUK',
  region: 'UK',
};
