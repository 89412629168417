import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Highlighter from 'react-highlight-words';
import Modal from 'components/molecules/Modal';
import EventSummary, { EventSummaryModes } from '../EventSummary';
import css from './styles.scss';

const ACCEPT_TEXT = 'Got it';
const NOTICE = 'Just to let you know, we have confirmed this session for you.';
const NOT_CORRECT = 'Are these session details incorrect?';
const NOT_CORRECT_ADVICE = `If this session doesn't look right to you, please
  contact your Student Success Manager. Otherwise, this session will be deducted from
  your total hours.`;
const HIGHLIGHT = 'Contact your Student Success Manager';
const SESSION_CONFIRM_HEADING = 'DID THIS SESSION TAKE PLACE?';

export default function AutoComplete(props) {
  return (
    <Modal
      isOpen
      title={SESSION_CONFIRM_HEADING}
      alert={props.alert}
      onSubmit={props.onSubmit}
      submitText={ACCEPT_TEXT}
      onSubmitDataTestId="studentConfirmAutoComplete"
    >
      <div className={css.autoComplete}>
        <div>{NOTICE}</div>
        <EventSummary
          booking={props.booking}
          personName={props.personName}
          eventSummaryMode={EventSummaryModes.HIDDEN}
        />
        <div>{NOT_CORRECT}</div>
        <div className={css.checkCorrectInstructions}>
          <Highlighter searchWords={[HIGHLIGHT]} textToHighlight={NOT_CORRECT_ADVICE} highlightTag="strong" />
        </div>
      </div>
    </Modal>
  );
}

AutoComplete.propTypes = {
  booking: ImmutablePropTypes.map.isRequired,
  personName: PropTypes.string.isRequired,
  alert: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};
