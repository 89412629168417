/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

export default function Astronaut({ className }) {
  return (
      <svg className={className} xmlns="http://www.w3.org/2000/svg" width="120" height="100" viewBox="0 0 120 100">
      <defs>
          <linearGradient id="a" x1="65.419%" x2="-3.639%" y1="40.089%" y2="84.561%">
              <stop offset="0%" stopColor="#FFF"/>
              <stop offset="31%" stopColor="#FCFCFC"/>
              <stop offset="58%" stopColor="#F1F1F2"/>
              <stop offset="83%" stopColor="#E0E0E2"/>
              <stop offset="100%" stopColor="#CFD0D3"/>
          </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
          <g transform="translate(15)">
              <ellipse cx="26.524" cy="15.537" fill="#BABCC4" rx="2.975" ry="4.746" transform="rotate(-37.41 26.524 15.537)"/>
              <ellipse cx="27.148" cy="15.024" fill="#BABCC4" rx="2.975" ry="4.746" transform="rotate(-37.41 27.148 15.024)"/>
              <path fill="#BABCC4" d="M62.01 44.2l5.09-1.604-4.48-8.903-9.955-18.834a3.762 3.762 0 0 0-1.189-1.36c-.764-.53-3.918.09-4.946.441-.314.129-8.778 2.399-9.778 2.734-3.031 1.053-8.1 2.821-8.884 3.389a5.21 5.21 0 0 0-1.592 2.473.075.075 0 0 0 0 .047 4.76 4.76 0 0 0-.1.42c-.008.13-.008.261 0 .392 0 .11-.032.22-.041.332a3.633 3.633 0 0 0 .41 1.881L45.041 47.84l-4.831 2.821 11.514-3.64 10.285-2.82z"/>
              <path fill="#BABCC4" d="M26.26 22.536a5.21 5.21 0 0 1 1.593-2.473c.783-.58 5.852-2.336 8.884-3.389.997-.335 9.448-2.605 9.777-2.734 1.028-.35 4.182-.971 4.947-.442.506.349.92.816 1.204 1.36l9.971 18.844 4.48 8.903s1.527-.47 1.527-4.567c0-2.123-1.198-4.39-1.198-4.39l-2.75-5.256-.313-.596-.432-.815-1.254-2.398-.43-.815-3.348-5.643-2.445-4.655-.332-.627-.771-1.47-.448-.853a3.762 3.762 0 0 0-.511-.743 3.821 3.821 0 0 0-1.33-1c-.2-.096-.41-.171-.626-.225a3.643 3.643 0 0 0-2.032.034c-.74.113-1.473.271-2.194.474-3.04.83-15.461 4.489-18.348 6.532l-.314.21a6.96 6.96 0 0 0-1.777 1.819 12.871 12.871 0 0 0-1.646 5.382 4.76 4.76 0 0 1 .1-.42c0-.017.006-.033.016-.047z"/>
              <path fill="#F4F6F8" d="M20.376 21.514s1.721-1.172 4.894-.627c2.382.401 3.163 1.163 5.473 1.05 5.643-.27 8.893 3.292 8.404 8.718-.495 5.486-8.175 5.49-13.517 1.53-2.003-1.486-3.74-1.088-4.856-1.649-1.862-.94-2.385-2.194-2.385-2.194s-.95-5.549 1.987-6.828z"/>
              <path fill="#DADAE1" d="M11.577 29.64s1.514-2.68-.746-4.875c0 0-4.702-1.367-5.862-.511C3.809 25.11.404 28.15.313 29.78c-.137 2.617 3.778 1.14 6.007.94 1.38-.122 1.451 0 1.451 0s.17.646-1.022 1.166c-.564.248-1.84-.147-2.135.289-.21.313.919 1.081 2.458 1.307 1.54.225 3.332-1.637 4.505-3.844z"/>
              <path fill="#F4F6F8" d="M25.677 21.113a9.658 9.658 0 0 0-7.288-.705c-4.179 1.206-9.718 3.808-9.718 3.808s2.602 1.684 2.595 6.292c0 0 6.85-.053 7.64.46.79.515 3.022 1.71 5.16.352-.006 0-1.047-8.505 1.611-10.207z"/>
              <path fill="#DADAE1" d="M41.649 94.737s-3.483 2.699.338 4.975c1.367.815 9.02-3.107 7.558-7.408-1.46-4.3-7.896 2.433-7.896 2.433z"/>
              <path fill="#F4F6F8" d="M46.417 92.502s3.034 2.003 3.828.244c2.028-4.505 6.006-13.33 5.385-16.614-.62-3.286-1.686-5.26-1.414-8.668.273-3.408 6.803-13.809 2.944-23.229-3.86-9.42-15.408-2.79-15.408-2.79s-6.075 11.756-1.376 21.759c0 0-2.473 6.896.213 11.197 2.687 4.301-3.633 3.878 2.414 12.12 2.295 3.134.727 5.015 3.414 5.98z"/>
              <path fill="#DADAE1" d="M54.69 72.245a15.962 15.962 0 0 1-15.047-.217s-2.74 3.897 1.815 6.342c0 0 9.903 3.58 13.72-1.947-.006 0 .665-3.63-.488-4.178zM80.486 85.476s-.972 4.558 3.668 3.794c1.661-.276 5.169-8.668 1.053-11.148-4.116-2.48-4.721 7.354-4.721 7.354z"/>
              <path fill="#741443" d="M34.21 17.417s1.724 3.182-.401 5.956c0 0 1.51 4.075 5.68 2.439 4.17-1.636 3.665-8.386 3.665-8.386s-3.386-2.539-4.737-5.692c-1.351-3.154-6.25 2.131-4.207 5.683z"/>
              <path fill="#F4F6F8" d="M82.818 80.417s3.818-.451 3.257-2.414c-1.432-5.016-4.241-14.871-6.99-17.113-2.75-2.241-4.953-3.1-7.06-6.04-2.107-2.94-3.922-15.803-13.48-20.784-9.558-4.981-14.344 8.254-14.344 8.254s3.112 13.649 13.733 18.526c0 0 2.705 7.26 7.815 8.906 5.11 1.646-.291 5.612 10.223 8.15 3.987.966 4.015 3.571 6.846 2.515z"/>
              <path fill="#9D2E2B" d="M38.931 11.859c-.85 2.875-3.984 6.097-4.762 6.552a.94.94 0 0 1-.796.16c-.545-.135-.708-.511-1.053-.84-.345-.33-.972-.534-1.254-.8-.144-.138-.138-.313-.336-.417-.457-.21-.94.207-1.498-.122a1.88 1.88 0 0 1-.191-1.254c.047-.451.398-1.188.116-1.505-.094-.11-.226-.188-.314-.282-.686-.658-.94-1.426-1.254-2.235-.4-1.019-.711-3.417.753-5.194 1.88-2.283 3.74-3.007 5.783-2.784 2.48.263 6.295 3.683 4.806 8.72z"/>
              <path fill="#F4F6F8" d="M42.292 16.665s2.507-.44 3.21-.834c.702-.395 5.015-.966 6.188 0 1.172.965 2.376 3.877 3.796 6.699 1.68 3.373 4.389 10.307 4.925 12.655 0 0-3.834 6.053-8.662 9.056-4.661 2.9-10.658 2.938-10.658 2.938s-2.561-.204-5.533-5.612c-.752-1.366-5.292-8.188-5.928-11.316-.636-3.129-.354-5.956.922-6.994 1.276-1.038 3.53-1.972 4.235-2.884 0 0 .27 3.172 2.68 2.354 2.41-.818 5.138-2.392 4.825-6.062z"/>
              <path fill="#DCA65E" d="M27.273 9.561s1.439-3.135 6.012-2.75c0 0 1.997.082 3.05 1.568 0 0-.166-1.517 1.15-1.366 1.318.15-.056 3.761-.056 3.761s1.223 2.025.79 2.768c-.432.743 2.286-1.567 2.486-4.075.201-2.508-2.821-8.37-7.79-8.385-4.968-.016-7.16 5.937-5.642 8.48z"/>
              <path fill="#DCA65E" d="M29.59 7.398a4.389 4.389 0 0 0 1.423-1.254c.56-.818.79.373.79.373a.906.906 0 0 0 .768-.874c0-.514.94.589.714 1.178 0 0 1.881-.156 2.822 1.06.517.664.674 2.116.674 2.116s-1.568-2.803-3.104-1.94c-3.006 1.705-3.417-1.107-5.016-.07M27.953 5.978s-1.376 2.025-2.821 2.263c-1.445.239-.217 2.822 2.14 2.123l.681-4.386z"/>
              <path fill="#DADAE1" d="M75.677 58.398a16.871 16.871 0 0 1-12.32 10.1s.446 5.016 5.797 3.89c0 0 10.445-3.864 9.761-10.937 0-.009-1.93-3.398-3.238-3.053z"/>
              <ellipse cx="52.904" cy="74.695" fill="#BABCC4" rx="3.1" ry="1.944" transform="rotate(-74.42 52.904 74.695)"/>
              <ellipse cx="53.687" cy="74.911" fill="#BABCC4" rx="3.1" ry="1.944" transform="rotate(-74.42 53.687 74.91)"/>
              <ellipse cx="75.913" cy="61.601" fill="#BABCC4" rx="2.056" ry="3.282" transform="rotate(-24.59 75.913 61.6)"/>
              <ellipse cx="76.693" cy="61.242" fill="#BABCC4" rx="2.056" ry="3.282" transform="rotate(-24.59 76.693 61.242)"/>
              <path fill="#DADAE1" d="M25.455 32.04s-2.6-5.413.909-10.836c0 0-2.593-2.138-4.558.94 0 0-3.047 6.765.627 9.75.003-.01 2.576.93 3.022.147z"/>
              <ellipse cx="24.237" cy="21.651" fill="#BABCC4" rx="1.37" ry="2.185" transform="rotate(-60.38 24.237 21.65)"/>
              <ellipse cx="24.523" cy="21.154" fill="#BABCC4" rx="1.37" ry="2.185" transform="rotate(-60.38 24.523 21.154)"/>
              <path fill="#F4F6F8" d="M21.285 20.019c.467.04.878.17 1.254.232.15-.232.343-.433.568-.593 0 0 .677-.229.968 1.107a.79.79 0 0 0 1.025.432.777.777 0 0 0 .292-.37c.194-.868-.398-1.987-1.796-2.162-1.399-.176-2.304 1.354-2.304 1.354"/>
              <circle cx="58.803" cy="32.683" r="2.301" fill="#BABCC4"/>
              <path fill="#F4F6F8" d="M62.404 24.21s1.289 4.144-3.793 6.646c0 0-1.783 1.037-.595 2.702s3.83-.85 4.818-1.85c.987-1 2.508-2.783 1.865-5.257-.828-3.222-2.295-2.241-2.295-2.241z"/>
              <circle cx="61.777" cy="38.107" r="2.301" fill="#BABCC4"/>
              <path fill="#F4F6F8" d="M65.38 29.633s1.285 4.144-3.794 6.646c0 0-1.787 1.038-.599 2.702 1.189 1.665 3.831-.85 4.819-1.85.987-1 2.507-2.783 1.88-5.256-.843-3.223-2.307-2.242-2.307-2.242zM67.354 12.26s-1.655-1.254-4.852-.896c-2.402.275-3.207.743-5.52.761-6.647.054-8.314 2.461-8.11 7.906.203 5.505 7.134 6.27 12.677 2.596 2.078-1.38 3.79-.89 4.937-1.392 1.906-.837 2.508-2.078 2.508-2.078s1.225-5.464-1.64-6.897z"/>
              <path fill="#DADAE1" d="M79.58 20.821s-1.075-3.04 1.298-5.125c0 0 4.483-.828 5.583.085 1.1.912 4.354 4.14 4.354 5.783 0 2.624-3.83.94-6.047.627-1.37-.194-1.448-.075-1.448-.075s-.204.627.959 1.216c.552.276 1.843-.05 2.116.402.194.313-.972 1.03-2.524 1.175-1.551.144-3.238-1.821-4.291-4.088z"/>
              <path fill="#F4F6F8" d="M65.947 11.567a9.665 9.665 0 0 1 7.316-.313c4.107 1.414 9.496 4.307 9.496 4.307s-2.684 1.542-2.922 6.144c0 0-6.837-.41-7.652.06-.815.47-5.044 2.194-6.743-.226.013 0 3.069-8.135.505-9.972z"/>
              <path fill="#DADAE1" d="M64.618 11.034a11.241 11.241 0 0 1-.906 10.574s2.601 2.122 4.551-.956c0 0 3.01-6.78-.683-9.743 0 0-2.517-.658-2.962.125z"/>
              <ellipse cx="66.259" cy="12.411" fill="#BABCC4" rx="1.37" ry="2.185" transform="rotate(-70.34 66.259 12.411)"/>
              <ellipse cx="66.448" cy="11.871" fill="#BABCC4" rx="1.37" ry="2.185" transform="rotate(-70.34 66.448 11.871)"/>
              <path fill="#F4F6F8" d="M63.066 11.313c.464-.037.893 0 1.282 0a2.12 2.12 0 0 1 .458-.655s.627-.345 1.14.922a.793.793 0 0 0 1.088.248.796.796 0 0 0 .21-.414c.041-.89-.736-1.881-2.144-1.822-1.407.06-2.034 1.734-2.034 1.734"/>
              <path fill="#DADAE1" d="M80.401 22.32s-1.125.363-1.454-.665c-.33-1.028.097-5.22 2.655-6.702 2.338-1.354 1.912.426 1.912.426s-3.743 2.49-3.113 6.94z"/>
              <path fill="#F4F6F8" d="M54.304 70.382c.183.515.299 1.052.345 1.596.245.103.457.273.611.49 0 0 .549.849-1.191 1.71 0 0-.875.672-.241 1.568.171.144.382.233.605.257 1.254-.031 2.595-1.232 2.36-3.216-.235-1.984-2.536-2.527-2.536-2.527M74.097 57.157c.498.292.957.646 1.367 1.053.269-.083.555-.09.828-.019 0 0 1.021.314.203 2.195 0 0-.247 1.138.872 1.426.237-.001.468-.073.664-.207 1-.887 1.254-2.768-.285-4.21-1.54-1.442-3.762-.313-3.762-.313"/>
              <path fill="#DADAE1" d="M43.649 12.89s1.627.314 1.335 3.449c-.19 2.043-3.711 5.134-7.523 6.385-3.812 1.25-4.86-.8-4.86-.8s-2.137-2.078-.94-1.861c2.195.395 3.107.313 3.107.313l5.06-2.282 3.82-5.204z"/>
              <path fill="#BABCC4" d="M40.48 7.868c.22 1.568-.57 2.963-1.765 3.135-1.195.173-2.248-.972-2.565-2.54-.583-2.87 1.455-3.087 1.768-3.134 1.195-.153 2.342.978 2.562 2.54z"/>
              <ellipse cx="39.239" cy="8.211" fill="#F4F6F8" rx="2.185" ry="2.856" transform="rotate(-24.59 39.239 8.211)"/>
              <path fill="#BABCC4" d="M36.777 9.987l.84-.37s.082.941.286.979c.204.037-1.495 3.69-3.301 5.247 0 0-.69-.235-.37-.805.401-.725 2.583-4.21 2.545-5.05z"/>
              <path fill="#F4F6F8" d="M34.313 14.944c-.52.445.314.94.314.94 2.01-1.464 4.038-5.226 3.853-5.345a3.718 3.718 0 0 1-.862-.921s-.257 2.74-3.305 5.326z"/>
              <path fill="#BABCC4" d="M31.702 19.75c1.918.252 3.868-.021 5.643-.79a9.903 9.903 0 0 0 5.517-6.69s.147-.806.53-.577c.382.229.373 1.285.128 1.84-.244.555-.799 3.395-4.504 5.517-4.6 2.62-7.02 1.376-7.499 1.28-.48-.098-.743-.684.185-.58z"/>
              <circle cx="33.197" cy="9.928" r="9.925" fill="url(#a)" opacity=".4"/>
              <ellipse cx="40.912" cy="7.648" fill="#BABCC4" rx="2.982" ry="4.757" transform="rotate(-24.775 40.912 7.648)"/>
              <ellipse cx="41.982" cy="7.283" fill="#BABCC4" rx="2.975" ry="4.746" transform="rotate(-24.59 41.982 7.283)"/>
              <path fill="#F4F6F8" d="M38.226 1.37c.72.424 1.384.935 1.978 1.523a2.17 2.17 0 0 1 1.194-.028s1.483.452.298 3.176c0 0-.36 1.646 1.254 2.062.344-.005.678-.115.96-.313 1.447-1.285 1.82-4.003-.412-6.09-2.232-2.089-5.457-.446-5.457-.446"/>
              <path fill="#DADAE1" d="M8.028 24.157s.47-1.072 1.414-.677 3.643 3.348 3.031 6.19c-.558 2.6-1.661 1.217-1.661 1.217s.787-4.417-2.784-6.73z"/>
              <path fill="#BABCC4" d="M47.125 39.464l2.333-.737-2.05-4.075-3.424-6.777a1.389 1.389 0 0 0-.178-.276 1.542 1.542 0 0 0-.367-.348c-.351-.245-1.793.04-2.263.204-.154.06-4.022 1.097-4.477 1.253-1.389.483-3.712 1.286-4.075 1.568-.347.3-.6.692-.73 1.132a.038.038 0 0 0 0 .021 2.421 2.421 0 0 0-.048.192c-.012.062 0 .125 0 .178l-.018.154c-.014.297.05.592.188.856l4.95 9.674.166-.054 5.291-1.677 4.702-1.288z"/>
              <path fill="#BABCC4" d="M31.9 31.389c.13-.44.383-.833.73-1.132.357-.266 2.68-1.069 4.075-1.567.455-.154 4.323-1.192 4.477-1.254.47-.163 1.912-.449 2.263-.204.139.097.26.217.36.354.07.085.13.177.18.276l3.423 6.777 2.05 4.076s.699-.213.699-2.091a5.044 5.044 0 0 0-.549-2.006l-1.254-2.408-.144-.273-.194-.376-.577-1.097-.204-.37-.389-.737-1.119-2.134-.153-.292-.355-.674-.203-.392a1.859 1.859 0 0 0-.834-.787 1.614 1.614 0 0 0-.292-.103 1.67 1.67 0 0 0-.94 0 8.599 8.599 0 0 0-1 .216c-1.386.401-7.072 2.082-8.392 3.022l-.138.094c-.32.227-.595.51-.815.834a5.956 5.956 0 0 0-.759 2.47c0-.062.029-.125.047-.19a.038.038 0 0 1 .007-.032z"/>
              <path fill="#F4F6F8" d="M32.1 35.812s.831 1.69 3.176.962a.787.787 0 0 1 .994.674c.119.878-1.461.875-2.07.853-.608-.022-1.617-.132-2.153-1.1-.699-1.264.053-1.39.053-1.39zM33.627 38.439s.83 1.69 3.176.965a.784.784 0 0 1 .993.674c.122.878-1.46.875-2.069.853-.608-.022-1.617-.132-2.153-1.1-.7-1.273.053-1.392.053-1.392z"/>
              <path fill="#FFF" d="M41.962 32.821l-5.19 1.775a2.122 2.122 0 0 1-2.556-1.091 1.665 1.665 0 0 1 1-2.308l5.192-1.774a2.122 2.122 0 0 1 2.554 1.091 1.668 1.668 0 0 1-1 2.307z"/>
              <path fill="#DADAE1" d="M42.37 35.229l-3.423 1.153c-.627.217-1.254.204-1.383-.03-.116-.233.314-.603.96-.82l3.423-1.153c.645-.216 1.254-.203 1.385.032.116.232-.316.602-.962.818zM43.279 37.05l-3.423 1.154c-.646.216-1.254.204-1.386-.032-.116-.232.314-.601.963-.818l3.423-1.153c.627-.217 1.254-.204 1.382.031.116.232-.316.602-.959.818zM44.2 38.871l-3.448 1.154c-.645.216-1.254.204-1.382-.031-.116-.232.313-.602.96-.818l3.422-1.154c.646-.216 1.254-.204 1.383.031.125.232-.307.602-.934.818z"/>
              <path fill="#F4F6F8" d="M47.307 30.304s.59 1.9-1.736 3.044c0 0-.819.476-.273 1.254.545.777 1.752-.389 2.194-.847.442-.457 1.144-1.275.853-2.407-.367-1.492-1.038-1.044-1.038-1.044zM48.668 32.79s.59 1.897-1.737 3.044c0 0-.815.473-.273 1.235.543.762 1.756-.389 2.195-.846.439-.458 1.144-1.276.852-2.408-.366-1.476-1.037-1.025-1.037-1.025z"/>
          </g>
          <path fill="#83858B" fillRule="nonzero" d="M2.17 29.811A2.135 2.135 0 0 1 0 27.641c0-1.226.943-2.17 2.17-2.17 1.226 0 2.17.944 2.17 2.17 0 1.227-1.038 2.17-2.17 2.17zm0-3.302c-.66 0-1.227.566-1.227 1.227 0 .66.566 1.226 1.227 1.226.66 0 1.226-.566 1.226-1.226s-.566-1.227-1.226-1.227zM17.264 54.623a2.135 2.135 0 0 1-2.17-2.17c0-1.227.944-2.17 2.17-2.17 1.227 0 2.17.943 2.17 2.17-.094 1.226-1.038 2.17-2.17 2.17zm0-3.397c-.66 0-1.226.566-1.226 1.227 0 .66.566 1.226 1.226 1.226s1.227-.566 1.227-1.226-.566-1.227-1.227-1.227zM104.17 40.34a2.135 2.135 0 0 1-2.17-2.17c0-1.227.943-2.17 2.17-2.17 1.226 0 2.17.943 2.17 2.17 0 1.226-1.038 2.17-2.17 2.17zm0-3.302c-.66 0-1.227.566-1.227 1.226s.566 1.227 1.227 1.227c.66 0 1.226-.566 1.226-1.227 0-.66-.566-1.226-1.226-1.226z"/>
          <path fill="#741443" fillRule="nonzero" d="M117.736 8.962c-.094 0-.189 0-.189-.094l-3.396-3.396c-.094-.095-.094-.283 0-.472s.283-.094.472 0l3.396 3.396c.094.095.094.283 0 .472-.094.094-.189.094-.283.094z"/>
          <path fill="#741443" fillRule="nonzero" d="M114.34 8.962c-.095 0-.19 0-.19-.094-.093-.094-.093-.283 0-.472L117.548 5c.095-.094.283-.094.472 0s.094.283 0 .472l-3.396 3.396c-.095.094-.189.094-.283.094zM119.528 56.132c-.094 0-.188 0-.188-.094l-3.397-3.396c-.094-.095-.094-.284 0-.472.095-.189.283-.095.472 0l3.396 3.396c.095.094.095.283 0 .472-.188.094-.283.094-.283.094z"/>
          <path fill="#741443" fillRule="nonzero" d="M116.038 56.132c-.095 0-.189 0-.189-.094-.094-.095-.094-.283 0-.472l3.396-3.396c.095-.095.283-.095.472 0 .189.094.094.283 0 .472l-3.396 3.396c-.095.094-.189.094-.283.094zM5.472 77.17c-.095 0-.189 0-.189-.095L1.887 73.68c-.095-.094-.095-.283 0-.471.094-.19.283-.095.471 0l3.397 3.396c.094.094.094.283 0 .471-.095.095-.189.095-.283.095z"/>
          <path fill="#741443" fillRule="nonzero" d="M2.075 77.17c-.094 0-.188 0-.188-.095-.095-.094-.095-.283 0-.471l3.396-3.396c.094-.095.283-.095.472 0 .188.094.094.283 0 .471l-3.397 3.396c-.094.095-.188.095-.283.095zM20.566 5c-.094 0-.189 0-.189-.094l-3.396-3.397c-.094-.094-.094-.283 0-.471.094-.189.283-.095.472 0l3.396 3.396c.094.094.094.283 0 .472-.094 0-.189.094-.283.094z"/>
          <path fill="#741443" fillRule="nonzero" d="M17.17 5c-.095 0-.189 0-.189-.094-.094-.095-.094-.283 0-.472l3.396-3.396c.095-.095.283-.095.472 0 .189.094.094.283 0 .471l-3.396 3.397c-.095 0-.189.094-.283.094z"/>
      </g>
  </svg>

  );
}

Astronaut.propTypes = {
  className: PropTypes.string,
};