import styled from 'styled-components';
import { Row } from '../../index.style';

export const AidCurrencyContainer = styled.div`
  display: grid;
  gap: 15px;
  grid-template-columns: 2fr 1fr;
`;

export const OptionContainer = styled(Row)`
  gap: 10px;
`;
