import React from 'react';
import Highlighter from 'react-highlight-words';
import font from 'components/atoms/typography/font.scss';
import fontFamily from 'components/atoms/typography/fontFamily.scss';
import fontColour from 'components/atoms/typography/fontColour.scss';
import classNames from 'classnames';
import { components } from 'react-select';
import css from './style.scss';

const render = (Comp) => (typeof Comp === 'function' ? <Comp /> : Comp);

export default function HighlightOption(componentProps) {
  const searchValue = componentProps.selectProps.inputValue;

  const { prefix: Prefix, label, suffix: Suffix } = componentProps.data;

  return (
    <components.Option {...componentProps}>
      {render(Prefix)}
      <Highlighter
        searchWords={[searchValue]}
        textToHighlight={label}
        unhighlightClassName={classNames(fontFamily.regular, fontColour.primary, font.body)}
        highlightClassName={classNames(fontFamily.bold, fontColour.primary, font.body, css.highlighted)}
        autoEscape
      />
      {render(Suffix)}
    </components.Option>
  );
}
