import React from 'react';
import PropTypes from 'prop-types';
import { Info as InfoTextInfo } from 'components/generic/InfoText';
// import css from './styles.scss';

export default function Info(props) {
  const { summary } = props;
  return <InfoTextInfo text={summary || 'Tutor will add this lesson summary.'} keepNewLine />;
}

Info.displayName = 'LessonSummaryInfo';

Info.propTypes = {
  summary: PropTypes.string,
};
