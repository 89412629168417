import componentKeys from 'constants/componentKeys';
import { updateMeta } from 'ducks/meta';
import { isUserOnline } from 'selectors/meta';

const connectionMiddleware = (store) => (next) => (action) => {
  const storeValue = isUserOnline(store.getState());
  if (storeValue !== window.navigator.onLine) {
    if (action && action.payload && action.payload.component !== componentKeys.IS_USER_ONLINE) {
      store.dispatch(updateMeta(componentKeys.IS_USER_ONLINE, window.navigator.onLine));
    }
  }
  return next(action);
};

export default connectionMiddleware;
