import { gql } from '@apollo/client';

export const contractType = gql`
  fragment contractType on contractType {
    id
    userId
    user {
      email
      userId
      firstName
      lastName
      profileImageUrl
    }
    itemId
    status
    notes
    createdAt
    declinedAt
    hasResponded
  }
`;

export const itemType = gql`
  fragment itemType on packageItemType {
    id
    name
    description
    remainingValue
    initialValue
    pendingValue
    isUnlimited
    start
    subjectId
    subject {
      id
      name
      status
      upsellAvailable
    }
    tutoringFrequency
    preferredTimes
    notes
  }
`;

export const packageType = gql`
  fragment packageType on packageType {
    id
    userId
    name
    description
    start
    end
    canAllocateTutors
    user {
      userId
      firstName
      lastName
      address
      email
      phone
      city
      country
      primaryLanguage
      languages
      profileImageUrl
      timezone
      studentInfo {
        schoolName
        schoolYearLevel
      }
      isBetaUser
    }
    files {
      id
      bucket
      key
      name
      description
      getUrl
      createdAt
      packageId
    }
  }
`;

export const subjectType = gql`
  fragment subjectType on subjectType {
    id
    name
    parentId
    isLeaf
    status
  }
`;

export const educationType = gql`
  fragment educationType on EducationType {
    id
    userId
    curriculumId
    name
    college
    degree
    major
    minor
    yearStarted
    yearOfGraduation
    isGraduated
    currentYearLevel
    type
    status
    createdAt
  }
`;

export const consentType = gql`
  fragment consentType on consentType {
    id
    userId
    value
    consentType
  }
`;

export const hexacoType = gql`
  fragment hexacoType on hexacoType {
    honesty
    emotionality
    extraversion
    agreeableness
    conscientiousness
    openness
  }
`;

export const keyContactInfoType = gql`
  fragment keyContactInfoType on keyContactInfoType {
    id
    firstName
    lastName
    email
    phone
    primaryLanguage
    languages
    city
    state
    country
    location
    gender
    relationship
    isPrimary
    createdAt
  }
`;

export const publicUserType = gql`
  fragment publicUserType on publicUserType {
    userId
    firstName
    lastName
    fullName
    bio
    status
    email
    userRoles
    roles {
      isPrimary
      roleId
      role {
        name
      }
    }
  }
`;

export const studentInfoType = gql`
  ${educationType}
  ${keyContactInfoType}
  fragment studentInfoType on studentInfoType {
    userId
    schoolName
    schoolYearLevel
    schoolGraduateYear
    yearOfApplication
    curriculumId
    curriculum {
      id
      name
      shortName
    }
    university
    universities {
      ...educationType
    }
    schools {
      ...educationType
    }
    subjects
    packageSubjects {
      id
      name
      subjectId
      start
    }
    interests
    keyContacts {
      ...keyContactInfoType
    }
    studentType
    contractStatus
    contractSubStatus
    pronoun
    hookStatement
    essayFolder
    university
    subjects
    interests
    intendedCareer
    academicGoals
    extracurricularGoals
    aidRequirements
    aidPayRequirements
    admissionGoals
    targetCountries
    siblings
    requirement
    preparedness
    parentIntensity
    englishLevel
    parentEnglishLevel
    degree
    major
    minor
    academicAdvisorName
  }
`;

export const tutorInfoType = gql`
  ${educationType}
  fragment tutorInfoType on tutorInfoType {
    averageRating
    totalSessions
    userId
    schoolName
    schoolGraduateYear
    expertise
    curriculumId
    curriculum {
      id
      name
      shortName
    }
    subjects {
      userId
      subject
      studentCount
      subjectId
    }
    contractSubjects {
      id
      name
      subjectId
      start
    }
    university
    universities {
      ...educationType
    }
    schools {
      ...educationType
    }
    degree
    universityStartYear
    universityGraduateYear
    capacity
    studentCount
    onboardedAt
    availableHoursPerWeek
    preferredTime
  }
`;

export const tutorInfoWithAvgHours = gql`
  ${tutorInfoType}
  fragment tutorInfoWithAvgHours on tutorInfoType {
    ...tutorInfoType
    averageHours
  }
`;

export const tutorNoteType = gql`
  fragment tutorNoteType on tutorInfoType {
    note {
      note
      noteEditedBy
      noteEditedAt
    }
  }
`;

export const detailedAppcuesUserType = gql`
  fragment detailedAppcuesUserType on userType {
    userId
    fullName
    email
    userRoles
    roles {
      isPrimary
      roleId
      role {
        name
      }
    }
    createdAt
    pronoun
    bio
    gender
    primaryLanguage
    languages
    dob
    citizenships
    activatedAt
    profileImageUrl
    city
    phone
    skype
  }
`;

export const detailedUserType = gql`
  ${consentType}
  ${tutorInfoType}
  ${studentInfoType}
  ${publicUserType}
  ${hexacoType}
  fragment detailedUserType on userType {
    userId
    firstName
    lastName
    fullName
    gender
    citizenships
    email
    contactEmail
    phone
    skype
    address
    city
    state
    location
    country
    bio
    timezone
    salesforceContactId
    autoTimezone
    subscribedEmail
    createdAt
    activatedAt
    role
    userRoles
    roles {
      isPrimary
      roleId
      role {
        name
      }
    }
    status
    ethnicity
    dob
    primaryLanguage
    isTest
    languages
    profileImageUrl
    meetingRecordingConsent
    pronoun
    preferredName
    consents {
      ...consentType
    }
    studentInfo {
      ...studentInfoType
    }
    tutorInfo {
      ...tutorInfoType
    }
    relationships {
      principalUser {
        ...publicUserType
      }
      relationUser {
        ...publicUserType
      }
      type
    }
    hexaco {
      ...hexacoType
    }
    salesforceAccountUrl
    isBetaUser
  }
`;

export const detailedTutorOfUserType = gql`
  ${tutorInfoWithAvgHours}
  ${tutorNoteType}
  ${publicUserType}
  fragment detailedTutorOfUserType on userType {
    userId
    firstName
    lastName
    fullName
    gender
    citizenships
    email
    phone
    skype
    address
    city
    state
    location
    country
    bio
    timezone
    autoTimezone
    subscribedEmail
    createdAt
    activatedAt
    role
    userRoles
    roles {
      isPrimary
      roleId
      role {
        name
      }
    }
    status
    ethnicity
    dob
    primaryLanguage
    languages
    profileImageUrl
    meetingRecordingConsent
    tutorInfo {
      ...tutorInfoWithAvgHours
      ...tutorNoteType
    }
    relationships {
      principalUser {
        ...publicUserType
      }
      relationUser {
        ...publicUserType
      }
      type
    }
    hexaco {
      honesty
      emotionality
      extraversion
      agreeableness
      conscientiousness
      openness
    }
  }
`;

export const detailedTutorUserType = gql`
  ${tutorInfoWithAvgHours}
  ${tutorNoteType}
  ${publicUserType}
  fragment detailedTutorUserType on scoredTutorType {
    userId
    firstName
    lastName
    fullName
    gender
    citizenships
    email
    phone
    skype
    address
    city
    state
    location
    country
    bio
    timezone
    autoTimezone
    subscribedEmail
    createdAt
    activatedAt
    role
    userRoles
    roles {
      isPrimary
      roleId
      role {
        name
      }
    }
    status
    ethnicity
    dob
    primaryLanguage
    languages
    profileImageUrl
    meetingRecordingConsent
    tutorInfo {
      ...tutorInfoWithAvgHours
      ...tutorNoteType
    }
    relationships {
      principalUser {
        ...publicUserType
      }
      relationUser {
        ...publicUserType
      }
      type
    }
    hexaco {
      honesty
      emotionality
      extraversion
      agreeableness
      conscientiousness
      openness
    }
    score
    ranking
  }
`;

export const tutorRecommendationsType = gql`
  ${detailedTutorUserType}
  fragment tutorRecommendationsType on scoredPackageItemTutorsType {
    id
    tutors {
      ...detailedTutorUserType
    }
    contracts {
      userId
    }
    algorithm
    version
  }
`;

export const paginationType = gql`
  fragment paginationType on paginationType {
    pageNumber
    pageSize
    totalPages
    totalCount
  }
`;

export const filterType = gql`
  fragment filterType on filterType {
    name
    email
    roles
    active
    subjectName
    location
    university
  }
`;

// this UserType contains user settings, so only user for login user
export const loginUserType = gql`
  fragment loginUserType on userType {
    userId
    firstName
    lastName
    fullName
    email
    timezone
    autoTimezone
    subscribedEmail
    activatedAt
    profileImageUrl
    isTest
    studentInfo {
      yearOfApplication
      pronoun
    }
    tutorInfo {
      subjects {
        subject
      }
    }
    pronoun
    preferredName
  }
`;

export const userContactType = gql`
  fragment userContactType on userType {
    userId
    firstName
    lastName
    fullName
    role
    roles {
      isPrimary
      roleId
      role {
        name
      }
    }
    city
    profileImageUrl
    studentInfo {
      userId
      studentType
    }
    isBetaUser
  }
`;

export const studentNoteType = gql`
  fragment studentNoteType on studentInfoType {
    note {
      note
      noteEditedBy
      noteEditedAt
    }
  }
`;

export const detailedFriendUserType = gql`
  ${consentType}
  ${educationType}
  fragment detailedFriendUserType on friendUserType {
    userId
    firstName
    lastName
    fullName
    gender
    citizenships
    email
    phone
    skype
    address
    city
    state
    location
    country
    bio
    timezone
    autoTimezone
    subscribedEmail
    createdAt
    activatedAt
    role
    userRoles
    roles {
      isPrimary
      roleId
      role {
        name
      }
    }
    status
    ethnicity
    dob
    primaryLanguage
    isTest
    languages
    profileImageUrl
    meetingRecordingConsent
    pronoun
    preferredName
    consents {
      ...consentType
    }
    userFriendId
    friendId
    friendStatus
    sessionId
    universities {
      ...educationType
    }
  }
`;

export const studentAnswerType = gql`
  fragment studentAnswerType on studentAnswerType {
    id
    questionId
    answer
    userId
  }
`;

export const optionType = gql`
  fragment optionType on optionType {
    label
    value
  }
`;

export const leafQuestionType = gql`
  ${studentAnswerType}
  ${optionType}
  fragment leafQuestionType on leafQuestionType {
    id
    content
    hasLeaf
    answer {
      ...studentAnswerType
    }
    type
    options {
      ...optionType
    }
  }
`;

export const studentQuestionType = gql`
  ${studentAnswerType}
  ${leafQuestionType}
  ${optionType}
  fragment studentQuestionType on studentQuestionType {
    id
    content
    hasLeaf
    answer {
      ...studentAnswerType
    }
    leafQuestion {
      ...leafQuestionType
    }
    type
    options {
      ...optionType
    }
    modalTitle
  }
`;

export const salesPackageType = gql`
  fragment salesPackageType on salesPackageType {
    id
    name
    year
    hours
    notes
    userId
  }
`;

export const quizResultType = gql`
  fragment quizResult on quizResultType {
    id
    status
    content
  }
`;
export const quizQuestionType = gql`
  fragment quizQuestion on quizQuestionType {
    id
    answer
    text
    subjectText
    primarySubject
    groupId
    category
    parentQuestionId
    showOptionsByParentSubject {
      id
      text
      value
    }
    showOptionsByPrimarySubject {
      id
      text
      value
    }
    options {
      id
      text
      value
    }
    children {
      id
      answer
      text
      subjectText
      primarySubject
      groupId
      parentQuestionId
      showOptionsByParentSubject {
        id
        text
        value
      }
      showOptionsByPrimarySubject {
        id
        text
        value
      }
      options {
        id
        text
        value
      }
    }
  }
`;

export const staffInfoType = gql`
  fragment staffInfoType on staffInfoType {
    id
    userId
    division
    region
    countries
    capacity
    allocationStatus
    vipAvailable
    title
    managerId
    sisterRegions
    specialtyArea
    vvipAvailable
    outOfRegion
  }
`;

export const teamMemberType = gql`
  fragment teamMemberType on TeamMemberType {
    userId
    firstName
    lastName
    fullName
    email
    subscribedEmail
    profileImageUrl
    tutorInfo {
      subjects {
        subject
      }
    }
    pronoun
    preferredName
    roles {
      isPrimary
      roleId
      role {
        name
      }
    }
    staffInfo {
      userId
      division
      countries
      title
      specialtyArea
      managerId
    }
  }
`;

export const appYearStudentType = gql`
  fragment appYearStudentType on userType {
    userId
    firstName
    lastName
    fullName
    role
    city
    profileImageUrl
    roles {
      isPrimary
      roleId
      role {
        name
      }
    }
    studentInfo {
      userId
      studentType
      yearOfApplication
    }
  }
`;

export const staffStudentsUserRelationType = gql`
  ${teamMemberType}
  fragment staffStudentsUserRelationType on StaffStudentsUserRelationType {
    staffInfos {
      ...teamMemberType
    }
    relationships
  }
`;
