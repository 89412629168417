import flatten from 'lodash/flatten';
import moment from 'moment';
import { splitIntoDays } from 'utils/calendarUtils';
import squeezeEvent from 'utils/squeezeEvent';

export const EventTypes = {
  BOOKING: 'BOOKING',
  AVAILABILITY: 'AVAILABILITY',
  UNAVAILABILITY: 'UNAVAILABILITY',
  BLOCK: 'BLOCK',
};

// This function compares two blocks for which is earlier
function blockCompare(firstBlock, secondBlock) {
  return firstBlock.start < secondBlock.start ? -1 : 1;
}

export function mapBookingsToBlocks(bookings) {
  // If we have a display start, we want to use that instead of the real time.
  // This is because we are mapping to dates that will be used to directly render on the calendar
  const mapped = bookings.map((b) => ({
    start: new Date(b.get('displayStart') ? b.get('displayStart') : b.get('start')),
    end: new Date(b.get('displayEnd') ? b.get('displayEnd') : b.get('end')),
    type: EventTypes.BOOKING,
    booking: b,
  }));

  mapped.sort(blockCompare);
  return mapped;
}

export function mapUserAvailabilitiesToBlocks(user, date, counter, viewTimeZone) {
  if (user.size) {
    const unflattened = user
      .getIn(['availability', 'available'])
      .map((av) => splitIntoDays(moment(av.get('start')).tz(viewTimeZone), moment(av.get('end')).tz(viewTimeZone)));
    const flattenedBlocks = flatten(unflattened.toArray());
    const mappedBlocks = flattenedBlocks
      .filter(({ start }) => start.isBetween(moment(date).startOf('day'), moment(date).endOf('day'), null, '[)'))
      .map(({ start, end }) => {
        return {
          start,
          end,
          user,
          counter,
          type: EventTypes.AVAILABILITY,
        };
      });
    mappedBlocks.sort(blockCompare);
    return mappedBlocks;
  }
  return [];
}

export function mapUserBlocksToBlocks(user, date, viewTimeZone) {
  if (user.size && user.getIn(['availability', 'block'])) {
    const blockCalendar = user
      .getIn(['availability', 'block'])
      .map((av) => splitIntoDays(moment(av.get('start')).tz(viewTimeZone), moment(av.get('end')).tz(viewTimeZone)));
    const flattenedBlocks = flatten(blockCalendar.toArray());
    const mappedBlocks = flattenedBlocks
      .filter(({ start }) => start.isBetween(moment(date).startOf('day'), moment(date).endOf('day'), null, '[)'))
      .map(({ start, end }) => {
        return {
          start,
          end,
          user,
          type: EventTypes.BLOCK,
        };
      });
    mappedBlocks.sort(blockCompare);
    return mappedBlocks;
  }
  return [];
}

export function mapUnavailabilitiesIntoBlocks(unavailabilities, date, viewTimeZone) {
  if (unavailabilities.size) {
    const unflattened = unavailabilities.map((unav) =>
      splitIntoDays(moment(unav.get('start')).tz(viewTimeZone), moment(unav.get('end')).tz(viewTimeZone)),
    );
    const flattenedBlocks = flatten(unflattened.toArray());
    const mappedBlocks = flattenedBlocks
      .filter(({ start }) => start.isBetween(moment(date).startOf('day'), moment(date).endOf('day'), null, '[)'))
      .map(({ start, end }) => {
        return {
          start,
          end,
          type: EventTypes.UNAVAILABILITY,
        };
      });
    mappedBlocks.sort(blockCompare);
    return mappedBlocks;
  }
  return [];
}

export function foldBlocks(existingBlocks, newBlocks) {
  let foldedBlocks = existingBlocks;
  newBlocks.forEach((newBlock) => {
    foldedBlocks = squeezeEvent(foldedBlocks, newBlock);
  });
  return foldedBlocks;
}
