import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { selectUserPermissions, selectUserRoles, selectUserId, getMetaItem } from 'selectors/meta';
import componentKeys from 'constants/componentKeys';
import { updateMeta } from 'ducks/meta';
import { getLoginUser } from 'selectors/user';
import { getAllPeopleCounts } from 'selectors/aggregate';
import { hasNewFriend } from 'selectors/globalState';
import AuthorisationCheck from 'components/wrappers/AuthorisationCheck';
import { Acl, permissionTypes, roleTypes } from '@crimson-education/common-config';
import { useLocation } from 'react-router-dom';
import MyStudents from '../MyStudents';
import Contacts from './Contacts';

function ContactsContainer(props) {
  const { pathname } = useLocation();
  const {
    loginUser,
    loginUserRoles,
    loginUserId,
    userCountQuery,
    activeTab,
    setActiveTab,
    setActivePage,
    hasNewFriend,
  } = props;
  return Acl.checkRole(loginUserRoles, roleTypes.TUTOR) ? (
    <MyStudents
      loginUserRoles={loginUserRoles}
      loggedInUser={loginUser}
      hasNewFriend={hasNewFriend}
      defaultActive={!pathname.endsWith('my-friends')}
    />
  ) : (
    <Contacts
      loginUser={loginUser}
      loginUserRoles={loginUserRoles}
      loginUserId={loginUserId}
      userCountQuery={userCountQuery}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      setActivePage={setActivePage}
      hasNewFriend={hasNewFriend}
      defaultActive={!pathname.endsWith('my-friends')}
    />
  );
}

ContactsContainer.propTypes = {
  loginUser: ImmutablePropTypes.map,
  loginUserId: PropTypes.string.isRequired,
  loginUserRoles: PropTypes.array.isRequired,
  userCountQuery: PropTypes.object.isRequired,
  activeTab: PropTypes.number,
  setActiveTab: PropTypes.func.isRequired,
  setActivePage: PropTypes.func.isRequired,
  hasNewFriend: PropTypes.number,
};

const withAuthorisation = AuthorisationCheck(permissionTypes.VIEW_CONTACTS);

export default withAuthorisation(
  connect(
    (state) => ({
      loginUser: getLoginUser(state),
      loginUserId: selectUserId(state),
      loginUserRoles: selectUserRoles(state),
      loginUserPermissions: selectUserPermissions(state),
      userCountQuery: getAllPeopleCounts(state),
      activeTab: getMetaItem(componentKeys.ACTIVE_TAB)(state),
      hasNewFriend: hasNewFriend(state),
    }),
    (dispatch) => ({
      setActiveTab: (tabId) => dispatch(updateMeta(componentKeys.ACTIVE_TAB, tabId)),
      setActivePage: (pageId) => dispatch(updateMeta(componentKeys.ACTIVE_PAGE, pageId)),
    }),
  )(ContactsContainer),
);
