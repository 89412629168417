import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TagsInput from 'react-tagsinput';

import css from './styles.scss';

export default function InputWithTags(props) {
  const { wrapperClassName, inputClassName, tagClassName, placeholder } = props;

  const inputProps = {
    className: classNames(css.input, inputClassName),
    placeholder: placeholder || 'Add a tag',
  };

  const tagProps = {
    className: classNames(css.tag, tagClassName),
    classNameRemove: css.tagRemove,
  };

  return (
    <TagsInput
      className={classNames(css.inputWrapper, wrapperClassName)}
      inputProps={inputProps}
      tagProps={tagProps}
      {...props}
    />
  );
}

InputWithTags.propTypes = {
  wrapperClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  tagClassName: PropTypes.string,
  placeholder: PropTypes.string,
};
