import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';
import { gql } from '@apollo/client';
import { createClient } from '../utils';
import { keyContactInfoType, itemType, contractType, educationType, detailedAppcuesUserType } from './common/types';
import { bookingTypeUser } from './booking';
import { sessionReportType } from './session';

const { api } = getConfig();

const client = createClient(api.graphQLEndpoint);

const gradeResult = gql`
  fragment gradeResult on GradeResult {
    id
    type
    value
  }
`;

const grade = gql`
  ${gradeResult}
  fragment grade on Grade {
    id
    userId
    subjectId
    date
    results {
      ...gradeResult
    }
  }
`;

const activity = gql`
  fragment activity on Activity {
    id
    userId
    type
    activity
    sport
    participationGradeLevels
    participationPeriod
    clubName
    position
    descriptionOfActivity
    hoursPerWeek
    weeksPerYear
    aspirations
    involvementSteps
    leadershipPlan
    reasonsForInvolvement
    collegeParticipation
    status
    order
    createdAt
  }
`;

export const fetchAppcuesInfo = gql`
  ${detailedAppcuesUserType}
  ${keyContactInfoType}
  ${itemType}
  ${contractType}
  ${educationType}
  ${grade}
  ${activity}
  ${bookingTypeUser}
  ${sessionReportType}
  query fetchAppcuesInfo($userId: String!) {
    user(userId: $userId) {
      ...detailedAppcuesUserType
    }
    getKeyContactsInfoByUserId(userId: $userId) {
      ...keyContactInfoType
    }
    myPackages {
      items {
        ...itemType
        contract {
          ...contractType
        }
      }
    }
    getSchoolsByUserId(userId: $userId) {
      ...educationType
    }
    getUniversitiesByUserId(userId: $userId) {
      ...educationType
    }
    getGradesByUserId(userId: $userId) {
      ...grade
    }
    getECActivitiesByUserId(userId: $userId) {
      ...activity
    }
    getHexacoResultExistByUserId(userId: $userId)
    getBookingsByUserId(userId: $userId) {
      ...bookingTypeUser
    }
    getContractsExistByUserId(userId: $userId)
    getActiveRoadmapExistByUserId(userId: $userId)
    getSessionByUserId(userId: $userId) {
      ...sessionReportType
    }
    getApplicationsExistByUserId(userId: $userId)
    getAssignedStudents(userId: $userId)
  }
`;

export default {
  fetchAppcuesInfo: async (userId) => {
    return client.query(fetchAppcuesInfo, { userId });
  },
};
