import React, { useReducer, createContext, useCallback, useEffect } from 'react';
import { TStaffDashboardState, Actions, reducer, initialState } from './store';
import { StaffDashboardController } from './controller';
import { useDispatch } from 'react-redux';
type TContext = {
  state: TStaffDashboardState;
  dispatch: <A extends Actions>(name: A['name'], payload?: A['payload']) => void;
  controller: StaffDashboardController;
};

export const StaffDashboardContext = createContext<TContext>(null as any);

export const withStaffDashboardContext = <P,>(Comp: React.ComponentType<P>) => {
  const controller = new StaffDashboardController();
  const EnhancedComp = (props: P): JSX.Element => {
    const [state, _dispatch] = useReducer(reducer, initialState);
    const dispatch = useCallback(
      <A extends Actions>(name: A['name'], payload?: A['payload']) => _dispatch({ name, payload } as A),
      [_dispatch],
    );
    const reduxDispatch = useDispatch();
    useEffect(() => {
      controller.setParams(state, dispatch, reduxDispatch);
    }, [state, dispatch, reduxDispatch]);
    return (
      <StaffDashboardContext.Provider
        value={{
          state,
          dispatch,
          controller,
        }}
      >
        <Comp {...props} />
      </StaffDashboardContext.Provider>
    );
  };
  return EnhancedComp;
};
