import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './Info.scss';

export default function Info({ text, keepNewLine, className }) {
  let textArray = [];
  if (text) {
    textArray = keepNewLine ? text.split('\n') : [text];
  }
  return (
    <div className={classNames(css.info, className)}>
      {textArray.map((paragraph, i) => {
        return paragraph && <p key={i}>{paragraph}</p>;
      })}
    </div>
  );
}

Info.propTypes = {
  text: PropTypes.string.isRequired,
  keepNewLine: PropTypes.bool,
  className: PropTypes.string,
};
