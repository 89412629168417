import React from 'react';
import BasicPopover, { BasicPopoverProps } from './BasicPopover';
import { Column } from '../../../index.style';
import { Title } from './index.style';

type TitlePopoverProps = {
  title: string;
  disable?: boolean;
} & BasicPopoverProps;

const TitlePopover = ({ title, style, content, children, disable, ...restProps }: TitlePopoverProps) => {
  return (
    <BasicPopover
      showArrow={false}
      overlayInnerStyle={{ width: 320, boxSizing: 'border-box', ...style }}
      content={
        !disable && (
          <Column gap={'12px'}>
            <Title>{title}</Title>
            {content}
          </Column>
        )
      }
      {...restProps}
    >
      {children}
    </BasicPopover>
  );
};

export default TitlePopover;
