import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import styled from 'styled-components';
import AttachFileIcon from '@material-ui/icons/AttachFile';

export const StyledCheck = styled(CheckCircleIcon)<{ $checked?: boolean }>`
  font-size: 20px;
  color: ${({ $checked }) => ($checked ? '#12C39A' : '#A9ACC0')};
  margin-left: auto;
`;

export const UpdatesCardContainer = styled.div`
  background: #ffffff;
  border: 1px solid #e3e7ed;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 18px 18px 16px 14px;

  transition: box-shadow, scale 0.4s;

  :hover {
    box-shadow: 4px 12px 12px rgba(115, 116, 125, 0.25);
    scale: 1.01;
  }
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Dark navy */
  color: #1d1e2b;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 13px;
`;

export const LinkText = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-decoration-line: underline;
  cursor: pointer;

  /* Indigo */
  color: #464ac9;
  word-break: break-all;
  width: fit-content;
`;

export const ClipIcon = styled(AttachFileIcon)`
  color: #464ac9;
  transform: rotate(-43.05deg);
  margin-bottom: -3px;
`;

export const ActionItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ActionName = styled.div`
  font-weight: 500;
  font-size: 14px;

  /* Stone */
  color: #73747d;
  margin-right: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ActionDetail = styled.div`
  font-weight: 500;
  font-size: 14px;

  color: #1d1e2b;
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  margin-left: 14px;
`;

export const TasksContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 25px;
`;

export const ColorBlockContainer = styled.div`
  background: #ffffff;
  /* Cool grey */
  border: 1px solid #a9acc0;
  border-radius: 2px;
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  padding: 2px;
  > :first-child {
    width: 100%;
    height: 100%;
  }
`;

export const Dot = styled.div`
  width: 3px;
  height: 3px;
  border-radius: 999px;
  background: #73747d;
  display: inline;
  vertical-align: middle;
  margin-right: 12px;
`;
