import styled from 'styled-components';

export const Container = styled.div`
  background-color: #f3f6fa;
  flex: 1;
  gap: 20px;
  overflow-y: auto;
  padding: 30px 32px;
  flex-direction: column;
  display: flex;
`;
