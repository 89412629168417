import React from 'react';
import { Link } from 'react-router-dom';
import { Column } from 'components/molecules/Table';
import Avatar from 'components/molecules/Avatar';
import { getAvatarColour } from 'utils/calendarUtils';
import { columnNames } from '../columnMap';
import css from '../styles.scss';

export default function Profile(params) {
  return (
    <Column
      key={columnNames.ProfileColumn}
      flex="4 1 0"
      renderer={({ rowData }) => (
        <div className={css.personNameCell}>
          <div className={css.avatar}>
            <Avatar
              firstName={rowData.get('firstName')}
              lastName={rowData.get('lastName')}
              colourIndex={getAvatarColour(rowData.get('userId'))}
              image={rowData.get('profileImageUrl')}
              userId={rowData.get('userId')}
              showStudentType
            />
          </div>
          <div className={css.personDetails}>
            <Link
              className={css.userName}
              to={`/users/${rowData.get('userId')}`}
              data-ga-label="Person"
              data-test-id={`linkTo_${rowData.get('firstName')}_${rowData.get('lastName')}`}
            >
              {`${rowData.get('firstName')} ${rowData.get('lastName')}`}
            </Link>
            {params && params.hideEmail ? null : <div className={css.userEmail}>{rowData.get('email')}</div>}
          </div>
        </div>
      )}
      name="Name"
      tdClassName={css.userTd}
    />
  );
}
