import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import css from './styles.scss';
import { handleEnter } from '../../../../utils/accessibility';

export default function FriendsQueryFilter(props) {
  const { onSearchParamsChange, hasNewFriend, defaultActive } = props;

  const [isActive, setActive] = React.useState(defaultActive);
  const [friendStatus, setFriendStatus] = React.useState('OK');
  const [name, setName] = React.useState('');
  const filterUser = (status) => {
    const filterParams = {
      friendStatus: status,
      name,
    };
    if (status === 'OK') {
      setActive(true);
      setFriendStatus('OK');
    } else {
      setActive(false);
      setFriendStatus('SEND');
    }
    onSearchParamsChange(filterParams);
  };

  const handleInputChange = (target) => {
    const filterParams = {
      friendStatus,
      name: target.value,
    };
    setName(target.value);
    onSearchParamsChange(filterParams);
  };

  return (
    <div className={css.filter}>
      <div className={css.left}>
        <div
          role="button"
          tabIndex={0}
          className={classNames(css.type, isActive ? css.isActive : null)}
          onClick={() => filterUser('OK')}
          onKeyDown={handleEnter(() => filterUser('OK'))}
        >
          Friends
        </div>
        <div className={css.vertical}>|</div>
        <div
          role="button"
          tabIndex={0}
          className={classNames(css.type, !isActive ? css.isActive : null)}
          onClick={() => filterUser('SEND')}
          onKeyDown={handleEnter(() => filterUser('SEND'))}
        >
          Pending requests
          {hasNewFriend > 0 && (
            <span className={css.circle}>
              <span className={css.number}>{hasNewFriend}</span>
            </span>
          )}
        </div>
      </div>
      <div className={css.right}>
        <span>Search by name</span>
        <input
          className={css.filterInput}
          type="text"
          placeholder="Enter a name to search"
          onChange={(event) => handleInputChange(event.target)}
        />
      </div>
    </div>
  );
}

FriendsQueryFilter.displayName = 'FriendsQueryFilter';

FriendsQueryFilter.propTypes = {
  onSearchParamsChange: PropTypes.func.isRequired,
  hasNewFriend: PropTypes.number,
  defaultActive: PropTypes.bool,
};
