import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import DropdownMenu from '../Dropdown/DropdownMenu';
import Dialogue from '../Dialogue';
import { ModalText } from './index.style';
import useToggle from 'hooks/useToggle';
import { Application } from '../type';
import { DropdownBaseProps } from '../Dropdown/DropdownBase';

export type DeleteApplicationButtonProps = {
  onConfirmDeleteOption: (value: string, applicationId: string) => Promise<boolean>;
  options: { label: string; value: string }[];
  menuItemStyle?: React.CSSProperties;
  text?: string;
  getConfirmDeletionText?: (value: string | null) => string;
  application: Application;
} & Pick<DropdownBaseProps, 'anchorOrigin' | 'transformOrigin' | 'preOpenDropdown'>;
const DeleteApplicationButton = ({
  menuItemStyle,
  onConfirmDeleteOption,
  options,
  text,
  getConfirmDeletionText,
  application,
  ...restProps
}: DeleteApplicationButtonProps) => {
  const [isOpen, toggleModal] = useToggle(false);
  const [isDeleting, setDeleting] = useState(false);
  const [selectedDeleteOption, setSelectedDeleteOption] = useState<string | null>(null);
  const onConfirmDelete = async () => {
    if (!selectedDeleteOption) return;
    setDeleting(true);
    const success = await onConfirmDeleteOption(selectedDeleteOption, application.id);
    setDeleting(false);
    if (success) toggleModal();
  };
  const onConfirmDeleteModalClose = () => {
    setSelectedDeleteOption(null);
    toggleModal();
  };
  const onClickMenuItem = (v: string) => {
    setSelectedDeleteOption(v);
    toggleModal();
  };
  return (
    <div>
      <DropdownMenu
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        menuItemStyle={{ width: 140, ...menuItemStyle }}
        data={options}
        onClickItem={onClickMenuItem}
        {...restProps}
      >
        <IconButton>
          <MoreHorizIcon style={{ fontSize: 20 }} />
        </IconButton>
      </DropdownMenu>
      <Dialogue
        isOpen={isOpen}
        closeModal={onConfirmDeleteModalClose}
        isLoading={isDeleting}
        title={'Are you sure'}
        onClickConfirm={onConfirmDelete}
      >
        <ModalText>{text || (getConfirmDeletionText && getConfirmDeletionText(selectedDeleteOption))}</ModalText>
      </Dialogue>
    </div>
  );
};

export default DeleteApplicationButton;
