import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Face from '@material-ui/icons/Face';
import VIPStudentIndicator from 'components/organisms/VIPStudentIndicator';

import { getFullName, getInitials } from 'utils/nameUtils';
import { getAvatarColour } from 'utils/calendarUtils';
import css from './style.scss';

// FIXME: Import from atoms/colours.scss
const colours = [
  '#ca0915',
  '#ff5f00',
  '#cda132',
  '#b5ba1b',
  '#64a324',
  '#157112',
  '#17b0a0',
  '#1271ac',
  '#1e4c77',
  '#1b2387',
  '#9b19ff',
  '#620ba6',
  '#d30f8e',
  '#860759',
];

function WrappedAvatar(props) {
  const { size, className, children } = props;

  return <div className={classNames(css.avatarWrapper, css[size], className)}>{children}</div>;
}

WrappedAvatar.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(['large', 'smallNormal', 'normal', 'small']),
  children: PropTypes.element.isRequired,
};

export default function Avatar(props) {
  const { image, firstName, lastName, colourIndex, isRound, userId, showStudentType } = props;

  let avatar;

  if (image) {
    avatar = (
      <WrappedAvatar {...props}>
        <img
          className={classNames(css.avatar, { [css.round]: isRound })}
          width="100%"
          height="100%"
          src={image}
          alt=""
        />
      </WrappedAvatar>
    );
  } else if (!firstName || !lastName) {
    avatar = (
      <WrappedAvatar {...props}>
        <Face />
      </WrappedAvatar>
    );
  } else {
    const fullName = getFullName(firstName, lastName);
    const initials = getInitials(firstName, lastName);
    const colour = colourIndex || getAvatarColour(userId);

    avatar = (
      <WrappedAvatar {...props}>
        <div className={classNames(css.avatar, { [css.round]: isRound })} style={{ backgroundColor: colours[colour] }}>
          <svg role="img" aria-label={fullName} width="100%" height="100%" viewBox="0 0 24 24">
            <title>{fullName}</title>
            <desc>Avatar</desc>
            <text
              x="12"
              y="12"
              fill="white"
              fontFamily="Montserrat"
              fontSize="10"
              textAnchor="middle"
              alignmentBaseline="central"
            >
              {initials}
            </text>
          </svg>
        </div>
      </WrappedAvatar>
    );
  }

  return (
    <div className={css.avatarContainer}>
      {avatar}
      {showStudentType && (
        <div className={css.vipTag}>
          <VIPStudentIndicator userId={userId} />
        </div>
      )}
    </div>
  );
}

Avatar.defaultProps = {
  showStudentType: false,
};

Avatar.propTypes = {
  image: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  colourIndex: PropTypes.number,
  isRound: PropTypes.bool,
  userId: PropTypes.string,
  showStudentType: PropTypes.bool,
};
