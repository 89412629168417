import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Body } from 'components/atoms/typography';
import classNames from 'classnames';
import InputWarningMessage from 'components/molecules/InputWarningMessage';
import css from './style.scss';
import { handleEnter } from '../../../utils/accessibility';

class HorizontalRadioButtons extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeButton: props.defaultActive,
    };

    this.handleButtonClick = this.handleButtonClick.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { defaultActive } = nextProps;
    if (defaultActive !== undefined) {
      this.setState({ activeButton: defaultActive });
    }
  }

  handleButtonClick(index) {
    const { onButtonChange } = this.props;

    const { activeButton } = this.state;

    this.setState({ activeButton: index });

    if (activeButton !== index) {
      onButtonChange && onButtonChange(index);
    }
  }

  render() {
    const { buttons, className, isValid, validationMessage, height, dataTestIdPrefix, dataGaLabel } = this.props;

    const { activeButton } = this.state;

    return (
      <React.Fragment>
        <div
          className={classNames({
            [css.buttonContainer]: true,
            [css.invalid]: !isValid,
            [className]: className,
          })}
          role="radiogroup"
        >
          {buttons.map((title, index) => {
            const dataGaLabelTitle = typeof title === 'object' ? title.innerHtml : title;
            return (
              <div
                key={index}
                className={classNames(css.button, { [css.activeButton]: index === activeButton })}
                onClick={() => this.handleButtonClick(index)}
                onKeyDown={handleEnter(() => this.handleButtonClick(index))}
                data-test-id={`${dataTestIdPrefix}${index}`}
                style={{ minHeight: `${height}rem` }}
                role="radio"
                tabIndex={0}
                aria-checked={index === activeButton ? 'true' : 'false'}
                data-ga-label={dataGaLabel ? `${dataGaLabel} - ${dataGaLabelTitle}` : dataGaLabelTitle}
              >
                <Body
                  className={classNames({
                    [css.buttonContent]: true,
                    [css.buttonActiveText]: index === activeButton,
                  })}
                >
                  {title}
                </Body>
              </div>
            );
          })}
        </div>
        {!isValid && <InputWarningMessage message={validationMessage} />}
      </React.Fragment>
    );
  }
}

HorizontalRadioButtons.defaultProps = {
  isValid: true,
};

HorizontalRadioButtons.propTypes = {
  buttons: PropTypes.array.isRequired,
  onButtonChange: PropTypes.func,
  defaultActive: PropTypes.number,
  className: PropTypes.string,
  validationMessage: PropTypes.string,
  isValid: PropTypes.bool,
  height: PropTypes.number,
  dataTestIdPrefix: PropTypes.string,
  dataGaLabel: PropTypes.string,
};

export default HorizontalRadioButtons;
