import { batchActions } from 'redux-batched-actions';

const batchFirebase = (store) => (next) => {
  let batch = [];
  let timeout = null;
  let flushing = false;

  const flush = () => {
    flushing = true;
    store.dispatch(batchActions(batch));
    batch = [];
    flushing = false;
    timeout = null;
  };

  return (action) => {
    if (flushing || action.type.indexOf('@@reactReduxFirebase') === -1) {
      return next(action);
    }

    batch.push(action);
    if (timeout === null) {
      timeout = window.setTimeout(flush, 10);
    }
    return null;
  };
};

export default batchFirebase;
