import React from 'react';
import { StyledButton } from './index.style';
import DropdownMenu from '../../Dropdown/DropdownMenu';
import { MoreLessIcon } from '../../index.style';
import { Application } from '../../type';
import { PopoverOrigin } from '@material-ui/core';
export type GroupButtonProps = {
  application: Application;
  getExtraActionButtonProps: (
    application: Application,
  ) => {
    getActionButtonText: () => string;
    onClickActionButtonItem: (value: string) => Promise<any>;
    actionButtonOptions: { label: string; value: string }[];
    renderActionButton?: (renderButton: (isOpen: boolean) => JSX.Element) => (isOpen: boolean) => JSX.Element;
    menuItemStyle?: React.CSSProperties;
    anchorOrigin?: PopoverOrigin;
    transformOrigin?: PopoverOrigin;
    disableDropdown?: boolean;
    preOpenDropdown?: () => boolean;
  };
};

const GroupButton = (props: GroupButtonProps) => {
  const { application, getExtraActionButtonProps } = props;
  const {
    getActionButtonText,
    actionButtonOptions,
    onClickActionButtonItem,
    renderActionButton,
    menuItemStyle,
    anchorOrigin,
    transformOrigin,
    disableDropdown,
    preOpenDropdown,
  } = getExtraActionButtonProps(application);
  const renderButton = (isOpen: boolean) => (
    <StyledButton endIcon={<MoreLessIcon open={isOpen} />}>{getActionButtonText()}</StyledButton>
  );
  return (
    <DropdownMenu
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      menuItemStyle={menuItemStyle}
      data={actionButtonOptions}
      onClickItem={onClickActionButtonItem}
      disable={disableDropdown}
      preOpenDropdown={preOpenDropdown}
    >
      {renderActionButton ? renderActionButton(renderButton) : renderButton}
    </DropdownMenu>
  );
};

export default GroupButton;
