import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Modal from 'components/molecules/Modal';
import PostForm from './postForm';
import css from './styles.scss';
import Comments from './Comments';

class LessonCommentPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabIndex: 1,
      modalIsOpen: false,
      deleteParams: {},
      lessonDetails: {
        loginUser: this.props.loginUser,
        lesson: this.props.lesson,
      },
    };

    this.onCancel = this.onCancel.bind(this);
    this.openDeleteConfirm = this.openDeleteConfirm.bind(this);
    this.deleteCommentFile = this.deleteCommentFile.bind(this);
    this.deleteComment = this.deleteComment.bind(this);
  }

  onCancel() {
    this.setState({
      modalIsOpen: false,
    });
  }

  openDeleteConfirm(fileName, fileId, commentId, replyId, comment) {
    this.setState({
      deleteParams: { fileName, fileId, commentId, replyId, comment },
      modalIsOpen: true,
    });
  }

  deleteCommentFile() {
    const { onDeleteCommentFile } = this.props;
    const {
      deleteParams: { fileId, commentId, replyId, comment },
      lessonDetails,
    } = this.state;
    this.setState({
      modalIsOpen: false,
    });
    onDeleteCommentFile(fileId, commentId, replyId, lessonDetails, comment);
  }

  deleteComment() {
    const { onDeleteComment } = this.props;
    const {
      deleteParams: { commentId, replyId, comment },
      lessonDetails,
    } = this.state;
    this.setState({
      modalIsOpen: false,
    });
    onDeleteComment(commentId, replyId, lessonDetails, comment);
  }

  render() {
    const {
      onPostComment,
      comments,
      lesson,
      loginUser,
      onReplyComment,
      onDownloadCommentFile,
      getCachedUsers,
      onEditComment,
    } = this.props;
    const {
      modalIsOpen,
      deleteParams: { fileName },
      lessonDetails,
    } = this.state;

    const modalOnConfirm = fileName ? this.deleteCommentFile : this.deleteComment;
    const modalText = fileName
      ? `Are you sure you want to delete “${fileName}” from this comment.`
      : 'Are you sure you want to delete this comment? Any files and replies will also be deleted.';
    const modalHeader = fileName ? 'DELETE FILE ?' : 'DELETE COMMENT ?';
    return (
      <div className={css.commentPanel}>
        <PostForm onPostComment={onPostComment} lessonDetails={lessonDetails} />
        <Comments
          comments={comments}
          lesson={lesson}
          loginUser={loginUser}
          onReplyComment={onReplyComment}
          lessonDetails={lessonDetails}
          onDownloadCommentFile={onDownloadCommentFile}
          openDeleteModal={this.openDeleteConfirm}
          getCachedUsers={getCachedUsers}
          onEditComment={onEditComment}
        />
        <Modal
          className={css.deleteFileModal}
          isOpen={modalIsOpen}
          onClose={this.onCancel}
          onSubmit={modalOnConfirm}
          submitLabel="Delete"
          title={modalHeader}
        >
          <div>{modalText}</div>
        </Modal>
      </div>
    );
  }
}

LessonCommentPanel.propTypes = {
  comments: ImmutablePropTypes.map.isRequired,
  onPostComment: PropTypes.func.isRequired,
  onEditComment: PropTypes.func.isRequired,
  onReplyComment: PropTypes.func.isRequired,
  onDeleteCommentFile: PropTypes.func.isRequired,
  onDownloadCommentFile: PropTypes.func.isRequired,
  onDeleteComment: PropTypes.func.isRequired,
  lesson: ImmutablePropTypes.map.isRequired,
  loginUser: ImmutablePropTypes.map.isRequired,
  getCachedUsers: ImmutablePropTypes.map,
};

LessonCommentPanel.displayName = 'LessonCommentPanel';

export default LessonCommentPanel;
