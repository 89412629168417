import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Tiny } from 'components/atoms/typography';

import css from './style.scss';

export const TAG_STYLE = {
  DEFAULT: css.default,
  SUCCESS: css.success,
  WARNING: css.warning,
  DRAFT: css.draft,
  DISABLED: css.disabled,

  STROKE_BLUE: css.strokeBlue,
  STROKE_GREEN: css.strokeGreen,
  STROKE_GREY: css.strokeGrey,
  STROKE_JELLYBEAN: css.strokeJellyBean,
  FILL_BLUE: css.fillBlue,
  FILL_GREEN: css.fillGreen,
  FILL_GREY: css.fillGrey,
  FILL_JELLYBEAN: css.fillJellyBean,
  FILL_CHEDDAR: css.fillCheddar,
};

export const TAG_SIZE = {
  SMALL: css.small,
  REGULAR: css.regular,
};

export default function Tag(props) {
  const { children, text, className, style, size, autoWidth } = props;

  return (
    <div className={classNames(className, css.tag, style, size, { [css.minWidth]: !autoWidth })}>
      <Tiny className={classNames(css.tagText)}>{children || text}</Tiny>
    </div>
  );
}

Tag.defaultProps = {
  style: TAG_STYLE.DEFAULT,
  size: TAG_SIZE.REGULAR,
  autoWidth: false,
};

Tag.propTypes = {
  children: PropTypes.node,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  style: PropTypes.string,
  size: PropTypes.string,
  autoWidth: PropTypes.bool,
};
