import {
  Application,
  ApplicationApproval,
  ApplicationRegion,
  RegionValue,
  ApplicationOverview,
  UploadedFile,
} from '../type';
export const initialState: TAppTrackerState = {
  isAddUniversityPopupOpen: false,
  tabValue: 'school_list',
  applications: [],
  listGroupBy: 'Round',
  trackerGroupBy: 'Round',
  listRegion: 'US',
  trackerRegion: 'US',
  showSelectAppCycleHint: false,
  isAdmissionDrawerOpen: false,
  admissionDrawerApplication: null,
  isParentApprovalModalOpen: false,
  approvals: {
    US: undefined,
    UK: undefined,
    EU: undefined,
    Other: undefined,
  },
  overviews: {
    US: undefined,
    UK: undefined,
    EU: undefined,
    Other: undefined,
  },
  currentSelectionHash: {
    US: undefined,
    UK: undefined,
    EU: undefined,
    Other: undefined,
  },
  isConfirmEditingModalOpen: false,
  grantEditing: {
    US: false,
    UK: false,
    EU: false,
    Other: false,
  },
  unmountingAppIds: [],
  essays: [],
};

export type TAppTrackerState = {
  isAddUniversityPopupOpen: boolean;
  tabValue: string;
  applications: Application[];
  listGroupBy: 'Round' | 'Goal';
  trackerGroupBy: 'Round' | 'Status' | 'Deadline';
  listRegion: ApplicationRegion;
  trackerRegion: ApplicationRegion;
  showSelectAppCycleHint: boolean;
  isAdmissionDrawerOpen: boolean;
  admissionDrawerApplication: Application | null;
  isParentApprovalModalOpen: boolean;
  approvals: RegionValue<ApplicationApproval | undefined>;
  overviews: RegionValue<ApplicationOverview | undefined>;
  currentSelectionHash: RegionValue<string | undefined>;
  isConfirmEditingModalOpen: boolean;
  grantEditing: RegionValue<boolean>;
  unmountingAppIds: string[];
  essays: UploadedFile[];
};
export type TAction<N, K extends keyof TAppTrackerState> = {
  name: N;
  payload?: Pick<TAppTrackerState, K>;
};

export type TAction2<N, K extends keyof TAppTrackerState, K2 extends keyof TAppTrackerState[K]> = {
  name: N;
  payload?: Pick<TAppTrackerState[K], K2>;
};

// define new action here
type ToggleAddUniversityPopup = TAction<'appTracker/toggleAddUniversityPopup', 'isAddUniversityPopupOpen'>;

type SwitchTab = TAction<'appTracker/switchTab', 'tabValue'>;

type SetApplications = TAction<'appTracker/setApplications', 'applications'>;

type SetListGroupBy = TAction<'appTracker/setListGroupBy', 'listGroupBy'>;

type SetTrackerGroupBy = TAction<'appTracker/setTrackerGroupBy', 'trackerGroupBy'>;

type SetListRegion = TAction<'appTracker/setListRegion', 'listRegion'>;

type SetTrackerRegion = TAction<'appTracker/setTrackerRegion', 'trackerRegion'>;

type SetSelectAppCycleHint = TAction<'appTracker/setSelectAppCycleHint', 'showSelectAppCycleHint'>;

type ToggleAdmissionDrawer = TAction<'appTracker/toggleAdmissionDrawer', 'isAdmissionDrawerOpen'>;

type SetAdmissionDrawerApplication = TAction<'appTracker/setAdmissionDrawerApplication', 'admissionDrawerApplication'>;

type SetParentApprovalModalOpen = TAction<'appTracker/setParentApprovalModalOpen', 'isParentApprovalModalOpen'>;

type SetApprovals = TAction2<'appTracker/setApprovals', 'approvals', ApplicationRegion>;

type SetOverviews = TAction2<'appTracker/setOverviews', 'overviews', ApplicationRegion>;

type SetCurrentSelectionHash = TAction2<
  'appTracker/setCurrentSelectionHash',
  'currentSelectionHash',
  ApplicationRegion
>;

type SetConfirmEditingModalOpen = TAction<'appTracker/setConfirmEditingModalOpen', 'isConfirmEditingModalOpen'>;

type SetGrantEditing = TAction2<'appTracker/setGrantEditing', 'grantEditing', ApplicationRegion>;

type SetUnmountingAppIds = TAction<'appTracker/setUnmountingAppIds', 'unmountingAppIds'>;

type SetUploadedEssays = TAction<'appTracker/setUploadedEssays', 'essays'>;

// dont forget to add new action here
export type Actions =
  | ToggleAddUniversityPopup
  | SwitchTab
  | SetApplications
  | SetListGroupBy
  | SetTrackerGroupBy
  | SetListRegion
  | SetTrackerRegion
  | SetSelectAppCycleHint
  | ToggleAdmissionDrawer
  | SetAdmissionDrawerApplication
  | SetParentApprovalModalOpen
  | SetApprovals
  | SetOverviews
  | SetCurrentSelectionHash
  | SetConfirmEditingModalOpen
  | SetGrantEditing
  | SetUnmountingAppIds
  | SetUploadedEssays;

// add reducer of new action here
export const reducer = <A extends Actions>(state: TAppTrackerState, action: A) => {
  switch (action.name) {
    case 'appTracker/toggleAddUniversityPopup':
      const { isAddUniversityPopupOpen } = action.payload || {};
      return {
        ...state,
        isAddUniversityPopupOpen:
          typeof isAddUniversityPopupOpen === 'undefined' ? !state.isAddUniversityPopupOpen : isAddUniversityPopupOpen,
      };
    case 'appTracker/switchTab':
      const { tabValue } = action.payload!;
      return {
        ...state,
        tabValue,
      };
    case 'appTracker/setApplications':
      const { applications } = action.payload!;
      return {
        ...state,
        applications,
      };
    case 'appTracker/setListGroupBy':
      const { listGroupBy } = action.payload!;
      return {
        ...state,
        listGroupBy,
      };
    case 'appTracker/setTrackerGroupBy':
      const { trackerGroupBy } = action.payload!;
      return {
        ...state,
        trackerGroupBy,
      };
    case 'appTracker/setListRegion':
      const { listRegion } = action.payload!;
      return {
        ...state,
        listRegion,
      };
    case 'appTracker/setTrackerRegion':
      const { trackerRegion } = action.payload!;
      return {
        ...state,
        trackerRegion,
      };
    case 'appTracker/setSelectAppCycleHint':
      const { showSelectAppCycleHint } = action.payload!;
      return {
        ...state,
        showSelectAppCycleHint,
      };
    case 'appTracker/toggleAdmissionDrawer':
      const { isAdmissionDrawerOpen } = action.payload!;
      return {
        ...state,
        isAdmissionDrawerOpen,
      };
    case 'appTracker/setAdmissionDrawerApplication':
      const { admissionDrawerApplication } = action.payload!;
      return {
        ...state,
        admissionDrawerApplication,
      };
    case 'appTracker/setParentApprovalModalOpen':
      const { isParentApprovalModalOpen } = action.payload!;
      return {
        ...state,
        isParentApprovalModalOpen,
      };
    case 'appTracker/setApprovals':
      const { ...approvals } = action.payload!;
      return {
        ...state,
        approvals: {
          ...state.approvals,
          ...approvals,
        },
      };
    case 'appTracker/setOverviews':
      const { ...overviews } = action.payload!;
      return {
        ...state,
        overviews: {
          ...state.overviews,
          ...overviews,
        },
      };
    case 'appTracker/setCurrentSelectionHash':
      const { ...currentSelectionHash } = action.payload!;
      return {
        ...state,
        currentSelectionHash: {
          ...state.currentSelectionHash,
          ...currentSelectionHash,
        },
      };
    case 'appTracker/setConfirmEditingModalOpen':
      const { isConfirmEditingModalOpen } = action.payload!;
      return {
        ...state,
        isConfirmEditingModalOpen,
      };
    case 'appTracker/setGrantEditing':
      const { ...grantEditing } = action.payload!;
      return {
        ...state,
        grantEditing: {
          ...state.grantEditing,
          ...grantEditing,
        },
      };
    case 'appTracker/setUnmountingAppIds':
      const { unmountingAppIds } = action.payload!;
      return {
        ...state,
        unmountingAppIds,
      };
    case 'appTracker/setUploadedEssays':
      const { essays } = action.payload!;
      return {
        ...state,
        essays,
      };
    default:
      return state;
  }
};
