import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import Body from 'components/atoms/typography/Body';
import { ExpandMore } from '@material-ui/icons';
import { StyledDropdown, StyledLink, StyledLeft, StyledLinkContainer, StyledMenu, StyledLinkText } from './style';
import css from './styles.scss';

const StudentCenterCategories = [
  {
    label: 'Dashboard',
    link: '/dashboard',
    hideInPaths: ['/dashboard'],
    imgUrl: '/static/studentCenter/overview.svg',
  },
  {
    label: 'Academics',
    link: '/academics',
    subCategories: [],
    imgUrl: '/static/studentCenter/academics.svg',
  },
  {
    label: 'Testing',
    link: '/testing',
    imgUrl: '/static/studentCenter/testing.svg',
  },
  {
    label: 'Activities',
    link: '/activities',
    imgUrl: '/static/studentCenter/activities.svg',
  },
  {
    label: 'Honors',
    link: '/honors',
    imgUrl: '/static/studentCenter/honors_nav.svg',
  },
  {
    label: 'Personal Development',
    link: '/personal-development',
    imgUrl: '/static/studentCenter/personal_dev.svg',
  },
  {
    label: 'Careers',
    link: '/career',
    imgUrl: '/static/studentCenter/careers.svg',
  },
  {
    label: 'Personal Information',
    link: '/personal-info',
    imgUrl: '/static/studentCenter/personal_info.svg',
    subCategories: [
      { label: 'Basic Information', link: '/personal-info#Basic Information' },
      { label: 'Applications', link: '/personal-info#Applications' },
      { label: 'Contact Details', link: '/personal-info#Contact Details' },
      { label: 'Internal Information', link: '/personal-info#Internal Information' },
    ],
  },
];

const mapTabToDropdownMenuItems = {
  'Mission Control': StudentCenterCategories,
};

const menu = (menuItems, url) => {
  return (
    <StyledMenu
      items={menuItems.map(({ label, link, hideInPaths, imgUrl }, index) => {
        if (hideInPaths?.includes(location.pathname)) {
          return null;
        }
        return {
          key: index,
          label: (
            <StyledLink to={url + link} role="tab">
              <img alt={link} style={{ width: '24px' }} src={imgUrl} />
              <StyledLinkText>{label}</StyledLinkText>
            </StyledLink>
          ),
        };
      })}
    />
  );
};
export default function TabNavLink(props) {
  const { url, dataTestId, text, className, hasDropdown } = props;
  return (
    <div className={classNames(css.link, className)}>
      <NavLink
        isActive={(match) => {
          if (!match) {
            return false;
          }
          return true;
        }}
        role="tab"
        to={url}
        activeClassName={css.active}
        data-test-id={dataTestId}
      >
        {hasDropdown ? (
          <StyledDropdown overlay={menu(mapTabToDropdownMenuItems[text], url)} trigger={['hover']}>
            <StyledLinkContainer>
              <StyledLeft>
                <Body bold>{text}</Body>
              </StyledLeft>
              <ExpandMore width={20} />
            </StyledLinkContainer>
          </StyledDropdown>
        ) : (
          <Body bold>{text}</Body>
        )}
      </NavLink>
    </div>
  );
}

TabNavLink.propTypes = {
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  dataTestId: PropTypes.string.isRequired,
  className: PropTypes.string,
  hasDropdown: PropTypes.bool,
};
