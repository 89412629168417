import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/molecules/Modal';

export default function CancelPendingRequestModal(props) {
  const { history, title, body, onClose, onSubmitDataTestId, cancelBooking } = props;

  async function onSubmit() {
    await cancelBooking();
    history.push('/calendar');
  }

  return (
    <Modal
      isOpen
      onSubmit={onSubmit}
      onSubmitDataTestId={onSubmitDataTestId}
      onClose={onClose}
      title={title}
      submitText="Confirm"
    >
      <div>{body || 'Are you sure you want to cancel this booking request?'}</div>
    </Modal>
  );
}

CancelPendingRequestModal.propTypes = {
  history: PropTypes.object,
  title: PropTypes.string,
  body: PropTypes.string,
  cancelBooking: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  onSubmitDataTestId: PropTypes.string,
};
