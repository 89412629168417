import { GradeResultType, GradeResultValueType, gradeValidations } from '@crimson-education/common-config';
import GradeInputTypes from 'constants/gradeInputTypes';
import GradeResultText from 'constants/gradeResultText';

// Append a percentage symbol if missing.
function appendPercentage(value) {
  if (value) {
    return value.endsWith('%') ? value : value.concat('%');
  }
  return '';
}

// Force the value to be uppercase.
function upperCase(value) {
  if (value) {
    return value.toUpperCase();
  }
  return '';
}

// Fields:
// displayName    The name to show for this grade result on entry.
// default        Value to use as default.
// placeholder    Descriptive text to show as placeholder/prompt.
// formatter      How to format this value for display.
// processor      Process raw user input.
// inputType      The type (from the constants directory) of this input.
// align          Alignment of this result in a table.

// Configuration for displaying a result in the grades table and modal.
export default {
  [GradeResultType.IB_PREDICTED_GRADE]: {
    displayName: 'Predicted grade',
    align: 'right',
    placeholder: '0 - 7',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.IB_ACHIEVED_GRADE]: {
    displayName: 'Grade achieved',
    align: 'right',
    default: '',
    placeholder: '0 - 7',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.IB_EE_PREDICTED_GRADE]: {
    displayName: 'Predicted grade',
    align: 'right',
    default: GradeResultValueType[GradeResultType.IB_EE_PREDICTED_GRADE].UNKNOWN,
    placeholder: GradeResultValueType[GradeResultType.IB_EE_PREDICTED_GRADE].UNKNOWN,
    inputType: GradeInputTypes.RADIO,
    formatter: (value) => GradeResultText[value],
    processor: (value) => value,
  },
  [GradeResultType.IB_EE_ACHIEVED_GRADE]: {
    displayName: 'Grade achieved',
    align: 'right',
    default: GradeResultValueType[GradeResultType.IB_EE_ACHIEVED_GRADE].UNKNOWN,
    placeholder: GradeResultValueType[GradeResultType.IB_EE_ACHIEVED_GRADE].UNKNOWN,
    inputType: GradeInputTypes.RADIO,
    formatter: (value) => GradeResultText[value],
    processor: (value) => value,
  },
  [GradeResultType.IB_CAS_PREDICTED_GRADE]: {
    displayName: 'Predicted grade',
    align: 'right',
    default: GradeResultValueType[GradeResultType.IB_CAS_ACHIEVED_GRADE].UNKNOWN,
    placeholder: GradeResultValueType[GradeResultType.IB_CAS_ACHIEVED_GRADE].UNKNOWN,
    inputType: GradeInputTypes.RADIO,
    formatter: (value) => GradeResultText[value],
    processor: (value) => value,
  },
  [GradeResultType.IB_CAS_ACHIEVED_GRADE]: {
    displayName: 'Grade achieved',
    align: 'right',
    default: GradeResultValueType[GradeResultType.IB_CAS_ACHIEVED_GRADE].UNKNOWN,
    placeholder: GradeResultValueType[GradeResultType.IB_CAS_ACHIEVED_GRADE].UNKNOWN,
    inputType: GradeInputTypes.RADIO,
    formatter: (value) => GradeResultText[value],
    processor: (value) => value,
  },
  [GradeResultType.IB_TOK_PREDICTED_GRADE]: {
    displayName: 'Predicted grade',
    align: 'right',
    default: GradeResultValueType[GradeResultType.IB_TOK_ACHIEVED_GRADE].UNKNOWN,
    placeholder: GradeResultValueType[GradeResultType.IB_TOK_ACHIEVED_GRADE].UNKNOWN,
    inputType: GradeInputTypes.RADIO,
    formatter: (value) => GradeResultText[value],
    processor: (value) => value,
  },
  [GradeResultType.IB_TOK_ACHIEVED_GRADE]: {
    displayName: 'Grade achieved',
    align: 'right',
    default: GradeResultValueType[GradeResultType.IB_TOK_ACHIEVED_GRADE].UNKNOWN,
    placeholder: GradeResultValueType[GradeResultType.IB_TOK_ACHIEVED_GRADE].UNKNOWN,
    inputType: GradeInputTypes.RADIO,
    formatter: (value) => GradeResultText[value],
    processor: (value) => value,
  },
  [GradeResultType.CIE_SCHOOL_EXAM_PERCENTAGE]: {
    displayName: 'School exam',
    align: 'right',
    placeholder: '0 - 100%',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: appendPercentage,
  },
  [GradeResultType.CIE_SCHOOL_EXAM_GRADE]: {
    displayName: 'School exam grade',
    align: 'right',
    placeholder: 'A* - G',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: upperCase,
  },
  [GradeResultType.CIE_FINAL_EXAM_PERCENTAGE]: {
    displayName: 'Final exam',
    align: 'right',
    default: '',
    placeholder: '0 - 100%',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: appendPercentage,
  },
  [GradeResultType.CIE_FINAL_EXAM_GRADE]: {
    displayName: 'Final exam grade',
    align: 'right',
    default: '',
    placeholder: 'A* - G',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: upperCase,
  },
  [GradeResultType.CIE_AWARDS]: {
    displayName: 'Awards',
    align: 'left',
    default: GradeResultValueType[GradeResultType.CIE_AWARDS].NO_AWARDS,
    placeholder: GradeResultValueType[GradeResultType.CIE_AWARDS].NO_AWARDS,
    inputType: GradeInputTypes.RADIO,
    formatter: (value) => GradeResultText[value],
    processor: (value) => value,
  },
  [GradeResultType.NCEA_NOT_ACHIEVED_CREDITS]: {
    displayName: 'Not achieved (internal)',
    align: 'right',
    default: '',
    placeholder: '0 - 100',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.NCEA_ACHIEVED_CREDITS]: {
    displayName: 'Achieved (internal)',
    align: 'right',
    default: '',
    placeholder: '0 - 100',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.NCEA_MERIT_CREDITS]: {
    displayName: 'Merit (internal)',
    align: 'right',
    default: '',
    placeholder: '0 - 100',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.NCEA_EXCELLENCE_CREDITS]: {
    displayName: 'Excellence (internal)',
    align: 'right',
    default: '',
    placeholder: '0 - 100',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.NCEA_NOT_ACHIEVED_CREDITS_EXTERNAL]: {
    displayName: 'Not achieved (external)',
    align: 'right',
    default: '',
    placeholder: '0 - 100',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.NCEA_ACHIEVED_CREDITS_EXTERNAL]: {
    displayName: 'Achieved (external)',
    align: 'right',
    default: '',
    placeholder: '0 - 100',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.NCEA_MERIT_CREDITS_EXTERNAL]: {
    displayName: 'Merit (external)',
    align: 'right',
    default: '',
    placeholder: '0 - 100',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.NCEA_EXCELLENCE_CREDITS_EXTERNAL]: {
    displayName: 'Excellence (external)',
    align: 'right',
    default: '',
    placeholder: '0 - 100',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.NCEA_SUBJECT_ENDORSEMENT]: {
    displayName: 'Endorsement',
    align: 'right',
    default: GradeResultValueType[GradeResultType.NCEA_SUBJECT_ENDORSEMENT].NO_ENDORSEMENT,
    placeholder: GradeResultValueType[GradeResultType.NCEA_SUBJECT_ENDORSEMENT].NO_ENDORSEMENT,
    inputType: GradeInputTypes.RADIO,
    formatter: (value) => GradeResultText[value],
    processor: (value) => value,
  },
  [GradeResultType.HSC_EXAMINATION_MARK]: {
    displayName: 'Examination mark',
    align: 'right',
    placeholder: '0 - 100',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.HSC_ASSESSMENT_MARK]: {
    displayName: 'Assessment mark',
    align: 'right',
    placeholder: '0 - 100',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.HSC_MARK]: {
    displayName: 'HSC mark',
    align: 'right',
    placeholder: '0 - 100',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.HSC_PERFORMANCE_BAND]: {
    displayName: 'Performance band',
    align: 'left',
    placeholder: '1 - 6',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.VCE_GA1]: {
    displayName: 'GA1',
    align: 'right',
    placeholder: 'A+ - E, UG',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: upperCase,
  },
  [GradeResultType.VCE_GA2]: {
    displayName: 'GA2',
    align: 'right',
    placeholder: 'A+ - E, UG',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: upperCase,
  },
  [GradeResultType.VCE_GA3]: {
    displayName: 'GA3',
    align: 'right',
    placeholder: 'A+ - E, UG',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: upperCase,
  },
  [GradeResultType.VCE_STUDY_SCORE]: {
    displayName: 'Study score',
    align: 'right',
    placeholder: '0 - 50',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.VCE_SCALED_SCORE]: {
    displayName: 'Scaled score',
    align: 'right',
    placeholder: '0.00 - 60.00',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.NZQA_AWARDS]: {
    displayName: 'Awards',
    align: 'left',
    default: GradeResultValueType[GradeResultType.NZQA_AWARDS].NO_SCHOLARSHIP,
    placeholder: GradeResultValueType[GradeResultType.NZQA_AWARDS].NO_SCHOLARSHIP,
    inputType: GradeInputTypes.RADIO,
    formatter: (value) => GradeResultText[value],
    processor: (value) => value,
  },
  [GradeResultType.NZQA_SCORE]: {
    displayName: 'Score',
    align: 'right',
    placeholder: '0 - 40',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.SAT_REASONING_READING_WRITING]: {
    displayName: 'Reading/Writing',
    align: 'right',
    placeholder: '0 - 800',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.SAT_REASONING_MATH]: {
    displayName: 'Math',
    align: 'right',
    placeholder: '0 - 800',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.SAT_REASONING_ESSAY]: {
    displayName: 'Essay (Optional)',
    align: 'right',
    placeholder: '0 - 24',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.SAT_REASONING_OVERALL_SCORE]: {
    displayName: 'Overall',
    align: 'right',
    placeholder: '0 - 1600',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.SAT_SUBJECT_SCORE]: {
    displayName: 'Score',
    align: 'right',
    placeholder: '0 - 800',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.ACT_MATH]: {
    displayName: 'Math',
    align: 'right',
    placeholder: '0 - 36',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.ACT_SCIENCE]: {
    displayName: 'Science',
    align: 'right',
    placeholder: '0 - 36',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.ACT_READING]: {
    displayName: 'Reading',
    align: 'right',
    placeholder: '0 - 36',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.ACT_ENGLISH]: {
    displayName: 'English',
    align: 'right',
    placeholder: '0 - 36',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.ACT_WRITING]: {
    displayName: 'Writing (Optional)',
    align: 'right',
    placeholder: '0 - 12',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.ACT_OVERALL_SCORE]: {
    displayName: 'Overall',
    align: 'right',
    placeholder: '0 - 36',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.AP_SCORE]: {
    displayName: 'Score',
    align: 'right',
    placeholder: '0 - 5',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.IB_TOK]: {
    displayName: 'Score',
    align: 'right',
    placeholder: '0 - 5',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.IB_EE]: {
    displayName: 'Score',
    align: 'right',
    placeholder: '0 - 5',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.IB_CAS]: {
    displayName: 'Score',
    align: 'right',
    placeholder: '0 - 5',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.IB_EE_TOK]: {
    displayName: 'EE/TOK',
    align: 'right',
    placeholder: '0 - 3',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.IB_TOTAL_POINTS]: {
    displayName: 'Total points',
    align: 'right',
    placeholder: '0 - 45',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.IB_RESULTS]: {
    displayName: 'Results',
    align: 'right',
    default: GradeResultValueType[GradeResultType.IB_RESULTS].UNKNOWN,
    placeholder: GradeResultValueType[GradeResultType.IB_RESULTS].UNKNOWN,
    inputType: GradeInputTypes.RADIO,
    formatter: (value) => GradeResultText[value],
    processor: (value) => value,
  },
  [GradeResultType.HSC_ATAR]: {
    displayName: 'Australian Tertiary Admission Rank (ATAR)',
    align: 'right',
    placeholder: '0 - 100',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.VCE_AGGREGATE]: {
    displayName: 'Aggregate',
    align: 'right',
    placeholder: '0 - 252',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.VCE_ATAR]: {
    displayName: 'Australian Tertiary Admission Rank (ATAR)',
    align: 'right',
    placeholder: '0 - 100',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.NZQA_OVERALL_AWARDS]: {
    displayName: 'Overall awards',
    align: 'right',
    default: GradeResultValueType[GradeResultType.NZQA_OVERALL_AWARDS].NO_AWARDS,
    placeholder: GradeResultValueType[GradeResultType.NZQA_OVERALL_AWARDS].NO_AWARDS,
    inputType: GradeInputTypes.RADIO,
    formatter: (value) => GradeResultText[value],
    processor: (value) => value,
  },
  [GradeResultType.NCEA_LEVEL_1_ENDORSEMENT]: {
    displayName: 'NCEA level 1 endorsement',
    align: 'right',
    default: GradeResultValueType[GradeResultType.NCEA_SUBJECT_ENDORSEMENT].NO_ENDORSEMENT,
    placeholder: GradeResultValueType[GradeResultType.NCEA_SUBJECT_ENDORSEMENT].NO_ENDORSEMENT,
    inputType: GradeInputTypes.RADIO,
    formatter: (value) => GradeResultText[value],
    processor: (value) => value,
  },
  [GradeResultType.NCEA_LEVEL_2_ENDORSEMENT]: {
    displayName: 'NCEA level 2 endorsement',
    align: 'right',
    default: GradeResultValueType[GradeResultType.NCEA_SUBJECT_ENDORSEMENT].NO_ENDORSEMENT,
    placeholder: GradeResultValueType[GradeResultType.NCEA_SUBJECT_ENDORSEMENT].NO_ENDORSEMENT,
    inputType: GradeInputTypes.RADIO,
    formatter: (value) => GradeResultText[value],
    processor: (value) => value,
  },
  [GradeResultType.NCEA_LEVEL_3_ENDORSEMENT]: {
    displayName: 'NCEA level 3 endorsement',
    align: 'right',
    default: GradeResultValueType[GradeResultType.NCEA_SUBJECT_ENDORSEMENT].NO_ENDORSEMENT,
    placeholder: GradeResultValueType[GradeResultType.NCEA_SUBJECT_ENDORSEMENT].NO_ENDORSEMENT,
    inputType: GradeInputTypes.RADIO,
    formatter: (value) => GradeResultText[value],
    processor: (value) => value,
  },
  [GradeResultType.NCEA_TOTAL_CREDITS]: {
    displayName: 'Total internal credits',
    align: 'right',
    default: '',
    placeholder: '0',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.NCEA_TOTAL_CREDITS_EXTERNAL]: {
    displayName: 'Total external credits',
    align: 'right',
    default: '',
    placeholder: '0',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.IELTS_TEST_TYPE]: {
    displayName: 'Test Type',
    align: 'right',
    default: GradeResultValueType[GradeResultType.IELTS_TEST_TYPE].ACADEMIC,
    placeholder: GradeResultValueType[GradeResultType.IELTS_TEST_TYPE].ACADEMIC,
    inputType: GradeInputTypes.RADIO,
    formatter: (value) => GradeResultText[value],
    processor: (value) => value,
  },
  [GradeResultType.IELTS_LISTENING]: {
    displayName: 'Listening',
    align: 'right',
    placeholder: '1 - 9',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.IELTS_READING]: {
    displayName: 'Reading',
    align: 'right',
    placeholder: '1 - 9',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.IELTS_WRITING]: {
    displayName: 'Writing',
    align: 'right',
    placeholder: '1 - 9',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.IELTS_SPEAKING]: {
    displayName: 'Speaking',
    align: 'right',
    placeholder: '1 - 9',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.IELTS_OVERALL]: {
    displayName: 'Overall',
    align: 'right',
    placeholder: '1 - 9',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.TOEFL_LISTENING]: {
    displayName: 'Listening',
    align: 'right',
    placeholder: '0 - 30',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.TOEFL_READING]: {
    displayName: 'Reading',
    align: 'right',
    placeholder: '0 - 30',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.TOEFL_WRITING]: {
    displayName: 'Writing',
    align: 'right',
    placeholder: '0 - 30',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.TOEFL_SPEAKING]: {
    displayName: 'Speaking',
    align: 'right',
    placeholder: '0 - 30',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.TOEFL_OVERALL]: {
    displayName: 'Overall',
    align: 'right',
    placeholder: '0 - 120',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.UCAT_VR_SCORE]: {
    displayName: 'VR Score',
    align: 'right',
    placeholder: '300 - 900',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.UCAT_DM_SCORE]: {
    displayName: 'DM Score',
    align: 'right',
    placeholder: '300 - 900',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.UCAT_QR_SCORE]: {
    displayName: 'QR Score',
    align: 'right',
    placeholder: '300 - 900',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.UCAT_AR_SCORE]: {
    displayName: 'AR Score',
    align: 'right',
    placeholder: '300 - 900',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.UCAT_TOTAL_COGNITIVE_SUBTEST_SCORE]: {
    displayName: 'Total Cognitive Subtest score',
    align: 'right',
    placeholder: '1200 - 3600',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.UCAT_SJT_SCORE]: {
    displayName: 'SJT Score',
    align: 'right',
    placeholder: '300 - 900',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.UCAT_PERCENTILE]: {
    displayName: 'Percentile',
    align: 'right',
    default: '',
    placeholder: '0 - 99',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.GAMSAT_SECTION_I_SCORE]: {
    displayName: 'Section I Score',
    align: 'right',
    placeholder: '0 - 100',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.GAMSAT_SECTION_II_SCORE]: {
    displayName: 'Section II Score',
    align: 'right',
    placeholder: '0 - 100',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.GAMSAT_SECTION_III_SCORE]: {
    displayName: 'Section III Score',
    align: 'right',
    placeholder: '0 - 100',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.GAMSAT_OVERALL_SCORE]: {
    displayName: 'Overall Score',
    align: 'right',
    placeholder: '0 - 100',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.GAMSAT_PERCENTILE]: {
    displayName: 'Percentile',
    align: 'right',
    placeholder: '0 - 99',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.QCE_IA_1_NUMERICAL_SCORE]: {
    displayName: 'Internal Assessment 1 Numerical Score',
    align: 'right',
    default: '',
    placeholder: '0 - 100',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.QCE_IA_1_GRADE]: {
    displayName: 'Internal Assessment 1 Grade',
    align: 'right',
    default: '',
    placeholder: 'A - E',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.QCE_IA_2_NUMERICAL_SCORE]: {
    displayName: 'Internal Assessment 2 Numerical Score',
    align: 'right',
    default: '',
    placeholder: '0 - 100',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.QCE_IA_2_GRADE]: {
    displayName: 'Internal Assessment 2 Grade',
    align: 'right',
    default: '',
    placeholder: 'A - E',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.QCE_IA_3_NUMERICAL_SCORE]: {
    displayName: 'Internal Assessment 3 Numerical Score',
    align: 'right',
    default: '',
    placeholder: '0 - 100',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.QCE_IA_3_GRADE]: {
    displayName: 'Internal Assessment 3 Grade',
    align: 'right',
    default: '',
    placeholder: 'A - E',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.QCE_EA_1_NUMERICAL_SCORE]: {
    displayName: 'External Assessment 1 Numerical Score',
    align: 'right',
    default: '',
    placeholder: '0 - 100',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.QCE_EA_1_GRADE]: {
    displayName: 'External Assessment 1 Grade',
    align: 'right',
    default: '',
    placeholder: 'A - E',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.QCE_OVERALL_NUMERICAL_SCORE]: {
    displayName: 'Overall Numerical Score',
    align: 'right',
    default: '',
    placeholder: '0 - 100',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.QCE_OVERALL_GRADE]: {
    displayName: 'Overall Grade',
    align: 'right',
    default: '',
    placeholder: 'A - E',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.WACE_SCHOOL_MARK_NUMERICAL]: {
    displayName: 'School Mark Numerical',
    align: 'right',
    default: '',
    placeholder: '0 - 100',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.WACE_SCHOOL_MARK_GRADE]: {
    displayName: 'School Mark Grade',
    align: 'right',
    default: '',
    placeholder: 'A - E',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.WACE_EXAM_MARK_NUMERICAL]: {
    displayName: 'Exam Mark Numerical',
    align: 'right',
    default: '',
    placeholder: '0 - 100',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.WACE_EXAM_GRADE]: {
    displayName: 'Exam Grade',
    align: 'right',
    default: '',
    placeholder: 'A - E',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.WACE_COMBINED_SCORE]: {
    displayName: 'Combined Score',
    align: 'right',
    default: '',
    placeholder: '0 - 100',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.SACE_IA_GRADE]: {
    displayName: 'Internal Assessment Grade',
    align: 'right',
    default: '',
    placeholder: 'A+ - E-',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.SACE_EA_GRADE]: {
    displayName: 'External Assessment Grade',
    align: 'right',
    default: '',
    placeholder: 'A+ - E-',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.SACE_FINAL_GRADE]: {
    displayName: 'Final Grade',
    align: 'right',
    default: '',
    placeholder: 'A+ - E-',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.CELS191_PROGRESS_TEST_MARK]: {
    displayName: 'Progress test mark',
    align: 'right',
    placeholder: '0 - 100%',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: appendPercentage,
  },
  [GradeResultType.CELS191_FINAL_MARK]: {
    displayName: 'Final mark',
    align: 'right',
    default: '',
    placeholder: '0 - 100%',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: appendPercentage,
  },
  [GradeResultType.CHEM191_PROGRESS_TEST_MARK]: {
    displayName: 'Progress test mark',
    align: 'right',
    placeholder: '0 - 100%',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: appendPercentage,
  },
  [GradeResultType.CHEM191_FINAL_MARK]: {
    displayName: 'Final mark',
    align: 'right',
    default: '',
    placeholder: '0 - 100%',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: appendPercentage,
  },
  [GradeResultType.PHSI191_PROGRESS_TEST_MARK]: {
    displayName: 'Progress test mark',
    align: 'right',
    placeholder: '0 - 100%',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: appendPercentage,
  },
  [GradeResultType.PHSI191_FINAL_MARK]: {
    displayName: 'Final mark',
    align: 'right',
    default: '',
    placeholder: '0 - 100%',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: appendPercentage,
  },
  [GradeResultType.BIOC192_PROGRESS_TEST_MARK]: {
    displayName: 'Progress test mark',
    align: 'right',
    placeholder: '0 - 100%',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: appendPercentage,
  },
  [GradeResultType.BIOC192_FINAL_MARK]: {
    displayName: 'Final mark',
    align: 'right',
    default: '',
    placeholder: '0 - 100%',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: appendPercentage,
  },
  [GradeResultType.HUBS191_PROGRESS_TEST_1_MARK]: {
    displayName: 'Progress test 1 mark',
    align: 'right',
    placeholder: '0 - 100%',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: appendPercentage,
  },
  [GradeResultType.HUBS191_PROGRESS_TEST_2_MARK]: {
    displayName: 'Progress test 2 mark',
    align: 'right',
    default: '',
    placeholder: '0 - 100%',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: appendPercentage,
  },
  [GradeResultType.HUBS191_FINAL_MARK]: {
    displayName: 'Final mark',
    align: 'right',
    default: '',
    placeholder: '0 - 100%',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: appendPercentage,
  },
  [GradeResultType.HUBS192_PROGRESS_TEST_1_MARK]: {
    displayName: 'Progress test 1 mark',
    align: 'right',
    placeholder: '0 - 100%',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: appendPercentage,
  },
  [GradeResultType.HUBS192_PROGRESS_TEST_2_MARK]: {
    displayName: 'Progress test 2 mark',
    align: 'right',
    default: '',
    placeholder: '0 - 100%',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: appendPercentage,
  },
  [GradeResultType.HUBS192_FINAL_MARK]: {
    displayName: 'Final mark',
    align: 'right',
    default: '',
    placeholder: '0 - 100%',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: appendPercentage,
  },
  [GradeResultType.POPH192_PROGRESS_TEST_1_MARK]: {
    displayName: 'Progress test 1 mark',
    align: 'right',
    placeholder: '0 - 100%',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: appendPercentage,
  },
  [GradeResultType.POPH192_PROGRESS_TEST_2_MARK]: {
    displayName: 'Progress test 2 mark',
    align: 'right',
    default: '',
    placeholder: '0 - 100%',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: appendPercentage,
  },
  [GradeResultType.POPH192_FINAL_MARK]: {
    displayName: 'Final mark',
    align: 'right',
    default: '',
    placeholder: '0 - 100%',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: appendPercentage,
  },
  [GradeResultType.OPTIONAL_8TH_PAPER_FINAL_MARK]: {
    displayName: 'Final mark',
    align: 'right',
    placeholder: '0 - 100%',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: appendPercentage,
  },
  [GradeResultType.ISAT_CR_SCORE]: {
    displayName: 'CR Score',
    align: 'right',
    placeholder: '0 - 100',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.ISAT_QR_SCORE]: {
    displayName: 'QR Score',
    align: 'right',
    placeholder: '0 - 100',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.ISAT_TOTAL_SCORE]: {
    displayName: 'Total Score',
    align: 'right',
    placeholder: '0 - 100',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.ISAT_PERCENTILE]: {
    displayName: 'Percentile',
    align: 'right',
    placeholder: '0 - 99',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.BMAT_SECTION_1]: {
    displayName: 'Section 1',
    align: 'right',
    placeholder: '0 - 9',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.BMAT_SECTION_2]: {
    displayName: 'Section 2',
    align: 'right',
    placeholder: '0 - 9',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.BMAT_SECTION_3]: {
    displayName: 'Section 3',
    align: 'right',
    placeholder: '1 - 5, A - E',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.BIOSCI107_TEST_GRADE]: {
    displayName: 'Test grade',
    align: 'right',
    placeholder: 'A+ - D-',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.BIOSCI107_FINAL_GRADE]: {
    displayName: 'Final grade',
    align: 'right',
    default: '',
    placeholder: 'A+ - D-',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.CHEM110_TEST_GRADE]: {
    displayName: 'Test grade',
    align: 'right',
    placeholder: 'A+ - D-',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.CHEM110_FINAL_GRADE]: {
    displayName: 'Final grade',
    align: 'right',
    default: '',
    placeholder: 'A+ - D-',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.POPLHLTH111_TEST_GRADE]: {
    displayName: 'Test grade',
    align: 'right',
    placeholder: 'A+ - D-',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.POPLHLTH111_FINAL_GRADE]: {
    displayName: 'Final grade',
    align: 'right',
    default: '',
    placeholder: 'A+ - D-',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.MEDSCI142_TEST_1_GRADE]: {
    displayName: 'Test 1 grade',
    align: 'right',
    placeholder: 'A+ - D-',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.MEDSCI142_TEST_2_GRADE]: {
    displayName: 'Test 2 grade',
    align: 'right',
    default: '',
    placeholder: 'A+ - D-',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.MEDSCI142_FINAL_GRADE]: {
    displayName: 'Final grade',
    align: 'right',
    default: '',
    placeholder: 'A+ - D-',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.ATAR]: {
    displayName: 'ATAR',
    align: 'right',
    placeholder: '0 - 99.95',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.AKL_CCP_OVERALL_GPA]: {
    displayName: 'Overall GPA',
    align: 'right',
    placeholder: '0 - 9',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.OTAGO_P_AVG_BEST_7_AVERAGE]: {
    displayName: 'Average',
    align: 'right',
    placeholder: '0 - 100%',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: appendPercentage,
  },
  [GradeResultType.CIE_AGGREGATE_SCORE]: {
    displayName: 'Aggregate score',
    align: 'right',
    placeholder: 'UUU - A*A*A*',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.SCHOOL_GPA]: {
    displayName: 'School GPA',
    align: 'right',
    placeholder: (context) => `0 - ${gradeValidations.computeMaximumGPA(context)}`,
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.GPA_REGION]: {
    displayName: 'Region',
    align: 'right',
    default: GradeResultValueType[GradeResultType.GPA_REGION].US,
    placeholder: GradeResultValueType[GradeResultType.GPA_REGION].US,
    inputType: GradeInputTypes.SELECT,
    formatter: (value) => GradeResultText[value],
    processor: (value) => value,
  },
  [GradeResultType.GPA_YEAR]: {
    displayName: 'Year',
    align: 'right',
    default: GradeResultValueType[GradeResultType.GPA_YEAR].FRESHMAN,
    placeholder: GradeResultValueType[GradeResultType.GPA_YEAR].FRESHMAN,
    inputType: GradeInputTypes.RADIO,
    formatter: (value) => GradeResultText[value],
    processor: (value) => value,
  },
  [GradeResultType.PRACTICE_SAT_READING_WRITING]: {
    displayName: 'Reading/Writing',
    align: 'right',
    placeholder: '0 - 800',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.PRACTICE_SAT_MATH]: {
    displayName: 'Math',
    align: 'right',
    placeholder: '0 - 800',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.PRACTICE_SAT_ESSAY]: {
    displayName: 'Essay (Optional)',
    align: 'right',
    placeholder: '0 - 24',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.PRACTICE_SAT_OVERALL_SCORE]: {
    displayName: 'Overall',
    align: 'right',
    placeholder: '0 - 1600',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.PRACTICE_ACT_MATH]: {
    displayName: 'Math',
    align: 'right',
    placeholder: '0 - 36',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.PRACTICE_ACT_SCIENCE]: {
    displayName: 'Science',
    align: 'right',
    placeholder: '0 - 36',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.PRACTICE_ACT_READING]: {
    displayName: 'Reading',
    align: 'right',
    placeholder: '0 - 36',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.PRACTICE_ACT_ENGLISH]: {
    displayName: 'English',
    align: 'right',
    placeholder: '0 - 36',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.PRACTICE_ACT_WRITING]: {
    displayName: 'Writing (Optional)',
    align: 'right',
    placeholder: '0 - 12',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.PRACTICE_ACT_OVERALL_SCORE]: {
    displayName: 'Overall',
    align: 'right',
    placeholder: '0 - 36',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.Duolingo]: {
    displayName: 'Official Duolingo English Test',
    align: 'right',
    placeholder: '10 - 160',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
  [GradeResultType.TOEFL_PBT_OVERALL]: {
    displayName: 'Official TOEFL PBT',
    align: 'right',
    placeholder: '310 - 677',
    inputType: GradeInputTypes.TEXT,
    formatter: (value) => value,
    processor: (value) => value,
  },
};
