import React from 'react';
import { HintContainer, HintText } from './index.style';
import Popover from '@material-ui/core/Popover';

const SelectAppCycleHint = () => {
  return (
    <HintContainer>
      <HintText>{'Please select your\napplication cycle first.'}</HintText>
    </HintContainer>
  );
};

export default SelectAppCycleHint;
