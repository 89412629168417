/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'auto-bind';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import moment from 'moment';
import IconButton from 'components/molecules/IconButton';

import { Acl, eventStatusTypes, permissionTypes, roleTypes } from '@crimson-education/common-config';
import eventType from 'constants/eventType';

import { Body } from 'components/atoms/typography';
import HeaderCenter from 'components/molecules/HeaderCenter';
import Button from 'components/molecules/Button';
import Avatar from 'components/molecules/Avatar';

import {
  RequestBookingModal,
  CancelBookingModal,
  CancelPendingRequestModal,
  SendSessionSummaryModal,
  CancelConfirmedSessionModal,
} from 'components/organisms/BookingModals';

import { isPast, formatDate, formatTime, formatStatusLabel, getAvatarColour, getTagStyle } from 'utils/calendarUtils';

import SessionStatus from '../SessionStatus';
import SessionConfirmationActions from '../SessionConfirmationActions';
import css from './styles.scss';
import { handleEnter } from '../../../../utils/accessibility';

export default class SessionHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalState: {
        booking: false,
        cancelPending: false,
        cancelConfirmed: false,
        sendSummary: false,
        cancelConfirmedSession: false,
      },
    };
    autoBind(this);
  }

  static getDerivedStateFromProps(props) {
    const { session, userId } = props;

    const isPastSession = isPast(session.end, 'minutes');
    const isSender = userId === session.creatorUserId;
    const student = session.participants.find((p) => Acl.checkRole(p.userRoles, roleTypes.STUDENT));

    return {
      isPastSession,
      isSender,
      student,
    };
  }

  toggleModal(name, isOpen = false) {
    const newModalState = this.state.modalState;
    newModalState[name] = isOpen;
    this.setState({ modalState: newModalState });
  }

  generateHeaderActions() {
    const { session, loginUser } = this.props;

    const { isSender, isPastSession, student } = this.state;
    let cancelSessionAction;
    const { TENTATIVE, CONFIRMED } = eventStatusTypes;
    const diffDays = (date, otherDate) => Math.abs(date - otherDate) / (1000 * 60 * 60);

    const openCancelPendingModal = () => this.toggleModal('cancelPending', true);
    const openCancelConfirmedModal = () => this.toggleModal('cancelConfirmed', true);
    const openCancelConfirmedSessionModal = () => this.toggleModal('cancelConfirmedSession', true);
    const openSendSummaryModal = () => this.toggleModal('sendSummary', true);

    const hasSendSummaryPermission = Acl.checkPermission(
      loginUser.permissions,
      permissionTypes['SESSION_SUMMARY:SEND_EMAIL'],
    );

    // Show this button only if the student is a participant of the session
    const isAllowToSendSummary = hasSendSummaryPermission && student;
    if (!session.otherUser) {
      return [];
    }
    const viewingUserId = session.otherUser.userRoles;
    if (
      diffDays(new Date(), session.start) < 24 &&
      session.cancellationCount >= 1 &&
      Acl.checkRole(viewingUserId[0], roleTypes.TUTOR)
    ) {
      const text =
        'You have already missed or cancelled a session within 24 hours with this tutor/mentor before. \n Proceeding with this cancellation will automatically deduct 0.5 hours from your package of this subject.';
      this.bodyText = text;
      cancelSessionAction =
        session.status === CONFIRMED && session.type === eventType.Lesson
          ? openCancelConfirmedSessionModal
          : openCancelPendingModal;
    } else if (
      diffDays(new Date(), session.start) < 24 &&
      session.cancellationCount === 0 &&
      Acl.checkRole(viewingUserId[0], roleTypes.TUTOR)
    ) {
      const text2 =
        'This cancellation is within 24 hours before the next session begins, please inform the tutor/mentor earlier next next time so that he or she can adjust his or her schedule. You will not be penalized for this action.';
      this.bodyText = text2;
      cancelSessionAction =
        session.status === CONFIRMED && session.type === eventType.Lesson
          ? openCancelConfirmedSessionModal
          : openCancelPendingModal;
    } else {
      cancelSessionAction =
        session.status === CONFIRMED && session.type === eventType.Lesson
          ? openCancelConfirmedModal
          : openCancelPendingModal;
    }
    const actions = isAllowToSendSummary
      ? [
          {
            label: 'Send session summary',
            action: openSendSummaryModal,
          },
        ]
      : [];

    if ((session.status === CONFIRMED || session.status === TENTATIVE) && isSender && !isPastSession) {
      actions.push(
        {
          label: 'Edit booking',
          action: () => this.toggleModal('booking', true),
        },
        {
          label: 'Cancel booking',
          action: cancelSessionAction,
        },
      );
    }

    if (session.status === CONFIRMED && !isSender && !isPastSession) {
      actions.push({
        label: 'Cancel booking',
        action: cancelSessionAction,
      });
    }

    return actions;
  }

  backForward(action) {
    const { fetchEventByAction, loadSessionAs, session, history } = this.props;
    let userId = null;
    if (loadSessionAs) {
      userId = loadSessionAs.userId;
    }
    const data = {
      action,
      userId,
      participantUserIds: session.participantUserIds,
      eventId: session.id,
      itemId: session.itemId,
      history,
    };
    fetchEventByAction(data);
  }
  render() {
    const {
      app: { isMobile },
      history,
      session,
      confirmBooking,
      loginUser,
      bookAs,
      userId,
      onClickBack,
      saveSessionSummary,
      changeBookingAs,
      featureFlags,
    } = this.props;

    const { modalState, isSender, isPastSession, student } = this.state;

    const { otherUser, participants, recipientUserId } = session;
    const isAdminSession = session.type === eventType.Session;
    const pendingUser = participants.find((u) => u.userId === recipientUserId) || otherUser;

    const tagStyle = getTagStyle(session);
    const headerActions = this.generateHeaderActions();
    const statusLabel = formatStatusLabel({ ...session, userId, bookAs, isSender, pendingUser });

    const isSameDay = moment(session.start).isSame(moment(session.end), 'day');
    const caption = isSameDay
      ? `${formatDate(session.start)} ${formatTime(session.start)} — ${formatTime(session.end)}`
      : `${formatDate(session.start)} ${formatTime(session.start)} — ${formatDate(session.end)} ${formatTime(
          session.end,
        )}`;
    const { FLAG_STUDENT_CENTER_TAB } = featureFlags;
    return (
      <div className={css.sessionHeader}>
        <HeaderCenter
          title={session.name}
          caption={caption}
          status={!isMobile ? statusLabel : null}
          tagStyle={tagStyle}
          actions={headerActions}
          showBorder={false}
          onClickBack={onClickBack}
        />
        <div className={css.sessionDetails}>
          <div className={css.detailBlock}>
            <ReactTooltip effect="solid" id="sessionHeaderTooltip" />
            <div data-tip="Go to previous session" data-for="sessionHeaderTooltip">
              <IconButton
                icon="ArrowBack"
                className={css.actionButton}
                iconSize={{ height: '2.1rem', width: '2rem' }}
                onClick={() => this.backForward('back')}
              />
            </div>
            <div className={css.participants}>
              {participants &&
                participants.length &&
                participants.map((participant) => {
                  const profileLink =
                    FLAG_STUDENT_CENTER_TAB && participant.isBetaUser && Acl.isStudent(participant.userRoles)
                      ? `/users/${participant.userId}/student-center/personal-info`
                      : `/users/${participant.userId}/profile`;
                  return (
                    <div
                      key={participant.userId}
                      className={css.participant}
                      data-tip={
                        loginUser.userId === participant.userId
                          ? 'You'
                          : `${participant.firstName} ${participant.lastName}`
                      }
                      data-for="sessionHeaderTooltip"
                    >
                      <Link className={css.profilePic} to={profileLink}>
                        <Avatar
                          firstName={participant.firstName}
                          lastName={participant.lastName}
                          colourIndex={getAvatarColour(participant.userId)}
                          image={participant.profileImageUrl}
                          size="normal"
                          userId={participant.userId}
                          showStudentType
                        />
                      </Link>
                    </div>
                  );
                })}
            </div>
            <div data-tip="Go to next session" data-for="sessionHeaderTooltip">
              <IconButton
                icon="ArrowForward"
                className={css.actionButton}
                iconSize={{ height: '2.1rem', width: '2rem' }}
                onClick={() => this.backForward('forward')}
              />
            </div>
          </div>
          {bookAs && (
            <div className={css.bookAs}>
              <Body>
                You are viewing this session on behalf of {bookAs.firstName}.
                {session.participantUserIds.indexOf(loginUser.userId) > -1 && (
                  <>
                    <span>&nbsp;</span>
                    <a
                      role="button"
                      tabIndex={0}
                      style={{ cursor: 'pointer' }}
                      onClick={() => changeBookingAs(loginUser.userId)}
                      onKeyDown={handleEnter(() => changeBookingAs(loginUser.userId))}
                    >
                      Click <u>here</u> to view as self
                    </a>
                  </>
                )}
              </Body>
            </div>
          )}
        </div>
        {isMobile && (
          <div className={css.sessionStatus}>
            <SessionStatus currentSession={session} sessionId={session.id} />
          </div>
        )}
        <Fragment>
          {session.status === eventStatusTypes.TENTATIVE && !isSender && !isPastSession && (
            <div className={css.actions}>
              <SessionConfirmationActions currentSession={session} sessionId={session.id} userId={userId} />
            </div>
          )}

          {session.status === eventStatusTypes.TENTATIVE && isPastSession && (
            <div className={css.actions}>
              {isSender ? (
                <Fragment>
                  <Body className={css.message}>
                    {otherUser.firstName} left this request pending.
                    {!isAdminSession && (
                      <>
                        {' '}
                        These package hours are on hold until you delete this session request or {
                          otherUser.firstName
                        }{' '}
                        confirms.
                      </>
                    )}
                  </Body>
                  <div className={css.actionGroup}>
                    <Button
                      small
                      dataTestId="DeleteRequestButton"
                      className={css.action}
                      onClick={() => this.toggleModal('cancelPending', true)}
                    >
                      Delete request
                    </Button>
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  <Body className={css.message}>
                    You left this request pending.
                    {!isAdminSession && (
                      <>
                        {' '}
                        The package hours are on hold until you confirm this session request or {
                          otherUser.firstName
                        }{' '}
                        deletes it.
                      </>
                    )}
                  </Body>
                  <div className={css.actionGroup}>
                    <Button
                      small
                      dataTestId="ConfirmSessionButton"
                      className={css.action}
                      onClick={async () => confirmBooking(true)}
                    >
                      Confirm session
                    </Button>
                  </div>
                </Fragment>
              )}
            </div>
          )}
        </Fragment>

        {modalState.booking && (
          <RequestBookingModal
            isOpen={modalState.booking}
            onCancel={() => this.toggleModal('booking', false)}
            selectedUserId={[session.otherUserId]}
            booking={session}
            isEditMode={session && true}
          />
        )}

        {modalState.cancelConfirmed && (
          <CancelBookingModal
            eventId={session.id}
            isOpen={modalState.cancelConfirmed}
            onBookingCancelled={() => history.push('/calendar')}
            onClose={() => this.toggleModal('cancelConfirmed', false)}
            isPenaltySession={
              Math.abs(new Date() - session.start) / (1000 * 60 * 60) < 24 &&
              session.cancellationCount >= 1 &&
              Acl.checkRole(session.otherUser.userRoles[0], roleTypes.TUTOR)
            }
          />
        )}

        {modalState.cancelPending && (
          <CancelPendingRequestModal
            session={session}
            userId={userId}
            title="Cancel request"
            onClose={() => this.toggleModal('cancelPending', false)}
            onSubmitDataTestId="submitCancelPendingRequest"
          />
        )}

        {modalState.sendSummary && (
          <SendSessionSummaryModal
            session={session}
            onClose={() => this.toggleModal('sendSummary', false)}
            studentId={student && student.userId}
            eventId={session.id}
            saveSessionSummary={saveSessionSummary}
            loginUser={loginUser}
          />
        )}

        {modalState.cancelConfirmedSession && (
          <CancelConfirmedSessionModal
            eventId={session.id}
            isOpen={modalState.cancelConfirmedSession}
            onClose={() => this.toggleModal('cancelConfirmedSession', false)}
            body={this.bodyText}
            onSubmit={() => {
              this.toggleModal('cancelConfirmed', true);
              this.toggleModal('cancelConfirmedSession', false);
            }}
          />
        )}
      </div>
    );
  }
}

SessionHeader.propTypes = {
  history: PropTypes.object.isRequired,
  app: PropTypes.object.isRequired,
  session: PropTypes.object.isRequired,
  userId: PropTypes.string.isRequired,
  loginUser: PropTypes.object.isRequired,
  bookAs: PropTypes.object,
  confirmBooking: PropTypes.func,
  onClickBack: PropTypes.func,
  saveSessionSummary: PropTypes.func,
  changeBookingAs: PropTypes.func,
  fetchEventByAction: PropTypes.func,
  loadSessionAs: PropTypes.object,
  featureFlags: PropTypes.object,
};
