import AllTasksCompleted from '@crimson-education/cga-report-renderer/lib/static/AllTasksCompleted.png';
import CauseForConcern from '@crimson-education/cga-report-renderer/lib/static/CauseForConcern.png';
import CGALogo_Color from '@crimson-education/cga-report-renderer/lib/static/CGALogo_Color.png';
import CGALogo_White from '@crimson-education/cga-report-renderer/lib/static/CGALogo_White.png';
import Excellent from '@crimson-education/cga-report-renderer/lib/static/Excellent.png';
import Good from '@crimson-education/cga-report-renderer/lib/static/Good.png';
import Kevin_Ferrone_signature from '@crimson-education/cga-report-renderer/lib/static/Kevin_Ferrone_signature.jpg';
import Mark_Phillips_signature from '@crimson-education/cga-report-renderer/lib/static/Mark_Phillips_signature.jpg';
import MostTasksCompleted from '@crimson-education/cga-report-renderer/lib/static/MostTasksCompleted.png';
import NeverDoneWhenChecked from '@crimson-education/cga-report-renderer/lib/static/NeverDoneWhenChecked.png';
import PageBackground from '@crimson-education/cga-report-renderer/lib/static/PageBackground.png';
import Satisfactory from '@crimson-education/cga-report-renderer/lib/static/Satisfactory.png';
import SometimesAttempted from '@crimson-education/cga-report-renderer/lib/static/SometimesAttempted.png';
import MontserratBold from '@crimson-education/cga-report-renderer/lib/static/fonts/Montserrat-Bold.ttf';
import MontserratBoldItalic from '@crimson-education/cga-report-renderer/lib/static/fonts/Montserrat-BoldItalic.ttf';
import MontserratItalic from '@crimson-education/cga-report-renderer/lib/static/fonts/Montserrat-Italic.ttf';
import MontserratMedium from '@crimson-education/cga-report-renderer/lib/static/fonts/Montserrat-Medium.ttf';
import MontserratMediumItalic from '@crimson-education/cga-report-renderer/lib/static/fonts/Montserrat-MediumItalic.ttf';
import MontserratRegular from '@crimson-education/cga-report-renderer/lib/static/fonts/Montserrat-Regular.ttf';
import MontserratSemiBold from '@crimson-education/cga-report-renderer/lib/static/fonts/Montserrat-SemiBold.ttf';
import MontserratSemiBoldItalic from '@crimson-education/cga-report-renderer/lib/static/fonts/Montserrat-SemiBoldItalic.ttf';
