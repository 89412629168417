import Immutable from 'immutable';

import createReducer from 'utils/createReducer';
import {
  FETCH_SESSION_LOG,
  FETCH_SESSION_LOG_COMPLETE,
  FETCH_SESSION_NOTES,
  FETCH_SESSION_NOTES_COMPLETE,
  SET_SESSION_NOTES,
  SET_SESSION_NOTES_COMPLETE,
} from './actions';

const initialState = Immutable.fromJS({
  entities: {
    session: {},
    note: {},
  },
});

export default createReducer(initialState, {
  [FETCH_SESSION_LOG]: (state, action) => state.mergeDeep(action.payload),
  [FETCH_SESSION_LOG_COMPLETE]: (state, action) => state.mergeDeep(action.payload),

  [FETCH_SESSION_NOTES]: (state, action) => state.mergeDeep(action.payload),
  [FETCH_SESSION_NOTES_COMPLETE]: (state, action) => state.mergeDeep(action.payload),

  [SET_SESSION_NOTES]: (state, action) => state.mergeDeep(action.payload),
  [SET_SESSION_NOTES_COMPLETE]: (state, action) => state.mergeDeep(action.payload),
});
