/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';

import fontFamily from 'components/atoms/typography/fontFamily.scss';
import font from 'components/atoms/typography/font.scss';
import css from './styles.scss';
import { handleEnter } from '../../../utils/accessibility';

export default function CollapsiblePanel(props) {
  const { title, children, message, isDisabled, isOpen, handleToggle } = props;

  const open = isOpen && !isDisabled && children;
  return (
    <div className={classNames({ [css.disabled]: isDisabled || !children })}>
      <a
        role="button"
        tabIndex={0}
        data-ga-label="Collapsible Panel"
        onClick={handleToggle}
        onKeyDown={handleEnter(handleToggle)}
        className={classNames(fontFamily.bold, css.heading)}
      >
        <ArrowDropDown className={classNames(css.arrow, { [css.openArrow]: open })} />
        <span className={css.title}>{title}</span>
        <span className={classNames(font.caption, css.message)}>{message}</span>
      </a>
      {open && children && <div className={classNames(css.content, font.body)}>{children}</div>}
    </div>
  );
}

CollapsiblePanel.defaultProps = {
  isOpen: false,
  isDisabled: false,
};

CollapsiblePanel.propTypes = {
  title: PropTypes.string.isRequired,
  handleToggle: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  message: PropTypes.string,
  isOpen: PropTypes.bool,
  isDisabled: PropTypes.bool,
};
