import Immutable from 'immutable';
import createReducer from 'utils/createReducer';
import { toasterMessages } from '@crimson-education/common-config';
import { uploadFile } from 'utils/packageUtils';
import { formatGraphQLRequestError } from 'utils/graphql';
import packageService from 'graphql/api/package';
import packageItemService from 'graphql/api/packageItem';
import isEmpty from 'lodash/isEmpty';
import { formSuccess, formFail, formLoading } from 'ducks/meta';
import { ADD_ENTITIES, addEntitiesWithNormalisation } from 'ducks/normalizr';
import { getUserWithId } from 'selectors/user';
import { getSubjectCategoryById } from 'selectors/subjectCategory';
import { packageEntity } from 'schema';

const EDIT_PACKAGE_SUCCEEDED = 'package/EDIT_PACKAGE_SUCCEEDED';
const DELETE_FILE_SUCCEEDED = 'package/DELETE_FILE_SUCCEEDED';
const CREATE_ITEM_SUCCEEDED = 'package/CREATE_ITEM_SUCCEEDED';
const DELETE_ITEM_SUCCEEDED = 'package/DELETE_ITEM_SUCCEEDED';
const EDIT_ITEM_SUCCEEDED = 'package/EDIT_ITEM_SUCCEEDED';
const SEND_INVITATION_SUCCEEDED = 'package/SEND_INVITATION_SUCCEEDED';
const ARCHIVE_CONTRACT_SUCCEEDED = 'package/ARCHIVE_CONTRACT_SUCCEEDED';

const initialState = Immutable.fromJS({});

const reducer = {
  [ADD_ENTITIES]: (state, action) => {
    return state.mergeDeep(action.payload.entities.package);
  },
  [EDIT_PACKAGE_SUCCEEDED]: (state, action) => {
    const updatedPackage = action.payload;
    if (!updatedPackage.files) {
      updatedPackage.files = [];
    }
    return state.set(updatedPackage.userId, Immutable.fromJS(updatedPackage));
  },
  [DELETE_FILE_SUCCEEDED]: (state, action) => {
    const payload = action.payload;
    let existingPackage = state.get(payload.userId);
    let files = existingPackage.get('files');
    const fileIndex = files.findIndex((f) => {
      return f.get('id') === payload.fileId;
    });
    files = files.remove(fileIndex);
    existingPackage = existingPackage.set('files', files);
    return state.set(action.userId, existingPackage);
  },
  [CREATE_ITEM_SUCCEEDED]: (state, action) => {
    const newSubject = action.payload.data;
    const userId = action.payload.userId;
    let subjectList = state.getIn([userId, 'subjects']);
    if (subjectList) {
      subjectList = subjectList.push(Immutable.fromJS(newSubject));
    } else {
      subjectList = Immutable.fromJS([newSubject]);
    }
    return state.setIn([userId, 'subjects'], subjectList);
  },
  [DELETE_ITEM_SUCCEEDED]: (state, action) => {
    const { userId, itemId } = action.payload;

    const subjectList = state.getIn([userId, 'subjects']);

    if (!subjectList) return state;

    const newSubjectList = subjectList.filter((item) => item.get('id') !== itemId);

    return state.setIn([userId, 'subjects'], newSubjectList);
  },
  [EDIT_ITEM_SUCCEEDED]: (state, action) => {
    const updatedSubject = action.payload.data;
    const userId = action.payload.userId;
    const subjectList = state.getIn([userId, 'subjects']);
    const key = subjectList.findKey((s) => s.get('id') === updatedSubject.id);

    return state.setIn([userId, 'subjects', key], Immutable.fromJS(updatedSubject));
  },
  [ARCHIVE_CONTRACT_SUCCEEDED]: (state, action) => {
    const contractId = action.payload.contractId;
    const studentId = action.payload.studentId;
    const itemId = action.payload.itemId;

    const subjectList = state.getIn([studentId, 'subjects']);

    if (!subjectList) return state;

    const key = subjectList.findKey((s) => s.get('id') === itemId);

    let tutors = state.getIn([studentId, 'subjects', key, 'tutors']);
    if (tutors) {
      tutors = tutors.filter((t) => t.get('id') !== contractId); // Remove the now archived contract from the list of contracts in the store.
    }
    return state.setIn([studentId, 'subjects', key, 'tutors'], tutors);
  },
  [SEND_INVITATION_SUCCEEDED]: (state, action) => {
    const contract = action.payload.contract;
    const userId = action.payload.userId;
    const itemId = contract.itemId;

    const subjectList = state.getIn([userId, 'subjects']);
    const key = subjectList.findKey((s) => s.get('id') === itemId);

    let tutors = state.getIn([userId, 'subjects', key, 'tutors']);
    if (tutors) {
      tutors = tutors.push(
        Immutable.fromJS({
          user: contract.user,
          status: contract.status,
          id: contract.id,
        }),
      );
    } else {
      tutors = Immutable.fromJS([{ user: contract.user, status: contract.status, id: contract.id }]);
    }
    return state.setIn([userId, 'subjects', key, 'tutors'], tutors);
  },
};

export default createReducer(initialState, reducer);

export function editPackageSucceeded(payload) {
  return { type: EDIT_PACKAGE_SUCCEEDED, payload };
}

export function deleteFileSucceeded(payload) {
  return { type: DELETE_FILE_SUCCEEDED, payload };
}

export function createItemSucceeded(payload) {
  return { type: CREATE_ITEM_SUCCEEDED, payload };
}

export function deleteItemSucceeded(payload) {
  return { type: DELETE_ITEM_SUCCEEDED, payload };
}

export function editItemSucceeded(payload) {
  return { type: EDIT_ITEM_SUCCEEDED, payload };
}

export function sendInvitationSucceeded(payload) {
  return { type: SEND_INVITATION_SUCCEEDED, payload };
}

export function archiveContractSucceeded(payload) {
  return { type: ARCHIVE_CONTRACT_SUCCEEDED, payload };
}

// create a new package and optionally upload and save a file associated with package
export function createPackage(newPackage) {
  return (dispatch, getState) => {
    dispatch(formLoading());

    return uploadFile(newPackage.name, newPackage.addedFile).then((uploadedFile) => {
      if (newPackage.addedFile && !uploadedFile) {
        dispatch(formFail('Failed to upload file'));
        return Promise.reject(new Error('File upload failed'));
      }
      let fileMutationFragment;
      if (uploadedFile && uploadedFile.name) {
        fileMutationFragment = {
          name: uploadedFile.name,
          bucket: uploadedFile.bucket,
          key: uploadedFile.key,
        };
      }
      return packageService
        .createPackage(
          newPackage.userId,
          newPackage.name,
          newPackage.description,
          newPackage.start,
          newPackage.end,
          fileMutationFragment,
        )
        .then((res) => {
          const state = getState();
          const student = getUserWithId(newPackage.userId)(state).get('fullName');
          dispatch(formSuccess(toasterMessages.packageAdded(student)));
          return dispatch(addEntitiesWithNormalisation(res.createPackage, packageEntity));
        })
        .catch((error) => dispatch(formFail([formatGraphQLRequestError(error)])));
    });
  };
}

export function updatePackage(data) {
  return (dispatch) => {
    const uploadFailed = () => {
      return dispatch(formFail(toasterMessages.userFileNotUploaded()));
    };
    dispatch(formLoading());
    return uploadFile(data.name, data.addedFile)
      .then((res) => {
        if (data.addedFile && !res) {
          return uploadFailed();
        }
        const addedFiles = isEmpty(res)
          ? []
          : [
              {
                bucket: res.bucket,
                key: res.key,
                name: res.name,
              },
            ];
        return packageService.modifyPackage({ ...data, addedFiles });
      })
      .then(({ modifyPackage }) => {
        dispatch(formSuccess(toasterMessages.packageEdited()));
        return dispatch(editPackageSucceeded(modifyPackage));
      })
      .catch((error) => dispatch(formFail(['Error', formatGraphQLRequestError(error)])));
  };
}

export function fetchPackageFileUrl(bucket, key) {
  return (dispatch) => {
    const fetchFailed = () => {
      return dispatch(formFail('Fetching File Url failed'));
    };
    dispatch(formLoading());
    return packageService
      .packageFileUrl(bucket, key)
      .then((res) => {
        if (!res || (res && !res.getPackageFileUrl)) {
          return fetchFailed();
        }
        dispatch(formSuccess('Package file loaded'));
        return res.getPackageFileUrl;
      })
      .catch((error) => dispatch(formFail(['Error', formatGraphQLRequestError(error)])));
  };
}

// fetch a user's package and the files associated
export function fetchPackageByUser(userId) {
  return (dispatch) => {
    return packageService
      .allPackages(userId)
      .then((res) => {
        dispatch(addEntitiesWithNormalisation(res.allPackages, [packageEntity]));
      })
      .catch((error) => dispatch(formFail(['Error', formatGraphQLRequestError(error)])));
  };
}

// delete a file associated with a package/user
export function deleteFile(userId, fileId) {
  return (dispatch) => {
    return packageService
      .deleteFile(fileId)
      .then(() => {
        dispatch(deleteFileSucceeded({ userId, fileId }));
      })
      .catch((error) => dispatch(formFail(['Error', formatGraphQLRequestError(error)])));
  };
}

export function createItem(userId, item) {
  return (dispatch, getState) => {
    dispatch(formLoading());
    return packageItemService
      .createItem(item)
      .then((res) => {
        const state = getState();
        const subject = getSubjectCategoryById(res.createItem.subjectId)(state);
        const student = getUserWithId(userId)(state).get('fullName');
        dispatch(createItemSucceeded({ userId, data: res.createItem }));
        dispatch(formSuccess(toasterMessages.packageSubjectCreated(subject.name, student)));
      })
      .catch((error) => dispatch(formFail(['Error', formatGraphQLRequestError(error)])));
  };
}

export function deleteItem(userId, itemId) {
  return (dispatch) => {
    dispatch(formLoading());
    return packageItemService
      .deleteItem(itemId)
      .then(() => {
        dispatch(deleteItemSucceeded({ userId, itemId }));
        dispatch(formSuccess(toasterMessages.packageSubjectDeleted()));
      })
      .catch((error) => dispatch(formFail(['Error', formatGraphQLRequestError(error)])));
  };
}

export function updateItem(request) {
  return (dispatch) => {
    dispatch(formLoading());
    return packageItemService
      .modifyItem(request)
      .then((res) => {
        dispatch(editItemSucceeded({ userId: request.userId, data: res.modifyItem }));
        dispatch(formSuccess(toasterMessages.packageSubjectUpdated()));
      })
      .catch((error) => {
        dispatch(formFail([toasterMessages.userSubjectNotUpdated(), formatGraphQLRequestError(error)]));
      });
  };
}
