import React, { Component } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import css from './styles.scss';
import { asButton } from '../../../../utils/accessibility';

export default class CheckboxField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isChecked: props.isChecked,
    };

    this.handleOnChange = this.handleOnChange.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.isChecked !== undefined && nextProps.isChecked !== prevState.isChecked) {
      return { isChecked: nextProps.isChecked };
    }
    return null;
  }

  handleOnChange(isChecked) {
    this.setState({
      isChecked,
    });

    this.props.onClick(isChecked);
  }

  render() {
    const { id, label, className, disabled, dataTestId, dataGaLabel } = this.props;
    const { isChecked } = this.state;
    const gaLabel = dataGaLabel || label;
    return (
      <div
        role="checkbox"
        tabIndex={0}
        aria-checked={isChecked}
        data-ga-label={gaLabel}
        className={classNames(className, css.formItemContainer)}
      >
        <div className={classNames(css.formControlWrapper, { [css.checkboxDisabled]: disabled })}>
          <i
            id={id}
            className={classNames('zmdi', css.checkboxInputStyle, {
              [css.checked]: isChecked,
              'zmdi-check-square': isChecked,
              'zmdi-square-o': !isChecked,
            })}
            data-test-id={dataTestId}
            {...asButton(() => this.handleOnChange(!isChecked))}
          />
          <label
            className={classNames('inline', css.checkboxLabel)}
            htmlFor={id}
            {...asButton(() => this.handleOnChange(!isChecked))}
          >
            {label}
          </label>
        </div>
      </div>
    );
  }
}

CheckboxField.displayName = 'CheckboxField';

CheckboxField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isChecked: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  dataGaLabel: PropTypes.string,
  disabled: PropTypes.bool,
};
