import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { trimAllSpaces } from 'utils/utils';

import { formatDate, timeString } from 'utils/calendarUtils';
import css from './styles.scss';
import { asButton } from '../../../utils/accessibility';

export default function EventSummary(props) {
  const { adjustedTime, booking, personName, options } = props;

  const start = booking.start;
  const end = booking.end;
  const name = booking.name;
  const subjectName = booking.subject && booking.subject.name;
  const bookingNotes = booking.description;

  const originalTime = timeString(start, end);
  const disputedTime = (adjustedTime.start || adjustedTime.end) && timeString(adjustedTime.start, adjustedTime.end);

  return (
    <div className={css.eventSummaryCard}>
      <div className={css.title}>{name}</div>
      <div className={css.row}>
        <div className={css.column}>
          <div className={css.item}>{formatDate(booking.start)}</div>
          <div
            className={classnames({
              [css.item]: true,
              [css.changedTime]: adjustedTime.start || adjustedTime.end,
            })}
          >
            {originalTime}
          </div>
          {disputedTime && <div className={css.item}>{disputedTime}</div>}
        </div>
        <div className={css.columnLast}>
          <div className={css.item}>{personName}</div>
          <div className={css.item}>{subjectName}</div>
        </div>
      </div>
      {options && options.length > 0 && (
        <div className={css.toggle}>
          {options.map((option) => {
            return (
              option.text && (
                <span
                  key={option.text}
                  className={css.toggleLink}
                  data-test-id={`click_${trimAllSpaces(option.text)}`}
                  {...asButton(option.action)}
                >
                  {option.text}
                </span>
              )
            );
          })}
        </div>
      )}
      {bookingNotes && (
        <div className={css.row}>
          <div className={css.notes}>{bookingNotes}</div>
        </div>
      )}
    </div>
  );
}

EventSummary.propTypes = {
  booking: PropTypes.object.isRequired,
  personName: PropTypes.string.isRequired,
  adjustedTime: PropTypes.object,
  options: PropTypes.array,
};
