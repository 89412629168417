import { compose } from 'redux';
import { connect } from 'react-redux';

import { unwrapImmutable } from 'utils/utils';
import {
  getThreadName,
  getThreadStatus,
  getOtherThreadParticipants,
  getOtherTypingThreadParticipants,
  getThreadGroupName,
} from 'ducks/messaging';
import ThreadListItem from './ThreadListItem';

export default compose(
  connect((state, { id }) => ({
    threadName: getThreadName(state, id),
    otherParticipants: unwrapImmutable(getOtherThreadParticipants(id)(state)),
    otherTypingParticipants: unwrapImmutable(getOtherTypingThreadParticipants(id)(state)),
    threadStatus: unwrapImmutable(getThreadStatus(id)(state)),
    chatName: getThreadGroupName(id)(state),
  })),
)(ThreadListItem);
