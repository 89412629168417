import React from 'react';
import PropTypes from 'prop-types';
import { Link as ReactRouterLink } from 'react-router-dom';
import styles from './styles.scss';

export default function Link(props) {
  const { to, children, refCallback, onClick } = props;

  return (
    <ReactRouterLink innerRef={refCallback} to={to} className={styles.linkStyle} onClick={onClick}>
      {children}
    </ReactRouterLink>
  );
}

Link.defaultProps = {
  to: '/',
  children: '',
};

Link.propTypes = {
  to: PropTypes.string,
  refCallback: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  onClick: PropTypes.func,
};
