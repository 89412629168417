import React, { Component } from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';
import classname from 'classnames';

import css from './styles.scss';

class PopoverMenu extends Component {
  handleClickOutside() {
    const { onClickOutside } = this.props;

    onClickOutside();
  }

  render() {
    const { children, show, className } = this.props;

    return show ? <div className={classname(className, css.options)}>{children}</div> : null;
  }
}

PopoverMenu.propTypes = {
  onClickOutside: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default onClickOutside(PopoverMenu);
