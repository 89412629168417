import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import autoBind from 'auto-bind';
import Lock from '@material-ui/icons/LockOutlined';
import titleCase from 'titlecase';

import { getAvatarColour } from 'utils/calendarUtils';
import CommentField from 'components/generic/Forms/CommentField';
import Modal from 'components/molecules/Modal';
import InfoPanel from 'components/molecules/InfoPanel';

import CallQuality from './CallQuality';
import SessionQuality from './SessionQuality';
import ThumbPicker from './ThumbPicker';
import css from './Feedback.scss';

const OPTIONAL_FIELDS = ['punctuality', 'preparation', 'content', 'helpfulness', 'enthusiasm', 'engagement'];

export default class Feedback extends Component {
  constructor() {
    super();

    const options = OPTIONAL_FIELDS.reduce((a, x) => {
      a[x] = 0;
      return a;
    }, {});

    this.state = {
      callRating: 0,
      rating: 0,
      comment: '',
      options,
    };

    autoBind(this);
  }

  componentDidMount() {
    const { booking, fetchSessionLog } = this.props;
    fetchSessionLog(booking.id);
  }

  onOptionChange(key, value) {
    this.setState((state) => {
      const { options } = state;
      options[key] = value;
      return { options };
    });
  }

  validateCall() {
    const { isCall } = this.props;
    const { callRating } = this.state;

    if (!isCall) {
      return true;
    }

    return callRating !== 0;
  }

  validate() {
    const { rating, comment } = this.state;

    if (rating < 5 && !comment) {
      return false;
    }

    if (!this.validateCall() || rating === 0) {
      return false;
    }

    return true;
  }

  submit() {
    const { onSubmit } = this.props;
    const { callRating, rating, comment, options } = this.state;

    const answers = [];

    answers.push({
      questionType: 'rating',
      question: 'How was the overall quality of the session?',
      rating,
    });

    if (callRating) {
      answers.push({
        questionType: 'call_quality',
        rating: callRating,
      });
    }

    if (comment) {
      answers.push({
        questionType: 'rating_comment',
        question: 'Session rating comment',
        answer: comment,
      });
    }

    Object.entries(options).forEach((x) =>
      answers.push({
        questionType: x[0],
        rating: x[1],
      }),
    );

    onSubmit(answers, comment);
  }

  render() {
    const { isCall, isOpen, onBack, booking } = this.props;
    const { callRating, rating, options } = this.state;

    const { otherUser, start, end, name, subject } = booking;

    const periodStart = moment(start);
    const periodEnd = moment(end);
    const isSameDay = periodStart.isSame(periodEnd, 'day');
    let period = null;
    if (isSameDay) {
      period = `${periodStart.format('ddd, D MMM, h:mma')} - ${periodEnd.format('h:mma')}`;
    } else {
      period = `${periodStart.format('ddd, D MMM, h:mma')} - ${periodEnd.format('ddd, D MMM, h:mma')}`;
    }
    let title = name;
    if (subject) {
      title += ` - ${subject.name}`;
    }

    return (
      <Modal
        isOpen={isOpen}
        title="Please Rate This Session"
        onBack={onBack}
        onSubmit={this.submit}
        submitText="Submit"
        isSubmitDisabled={!this.validate()}
        onSubmitDataTestId="completeAttendanceFeedbackButton"
      >
        <div className={css.feedback}>
          <InfoPanel
            firstName={otherUser.firstName}
            lastName={otherUser.lastName}
            colourIndex={getAvatarColour(otherUser.userId)}
            image={otherUser.profileImageUrl}
            textTop={title}
            textBottom={period}
            userId={otherUser.userId}
          />
          <div className={css.form}>
            {isCall && <CallQuality value={callRating} onChange={(callRating) => this.setState({ callRating })} />}
            <SessionQuality value={rating} onChange={(rating) => this.setState({ rating })} />
            <CommentField
              className={classNames(css.ratingComment, { [css.active]: rating > 0 })}
              dataTestId="emojiRatingComment"
              autoSize
              required={rating !== 5}
              placeholder={
                rating === 5
                  ? 'Let us know what you liked about your session (optional).'
                  : 'Please let us know how we could improve the quality of the session.'
              }
              onValueChange={(comment) => this.setState({ comment })}
            />
            <div className={classNames(css.options, { [css.active]: this.validateCall() && rating })}>
              <h3>Tell us what stood out to you (optional)</h3>
              <div className={css.optionalFields}>
                {OPTIONAL_FIELDS.map((x) => {
                  return (
                    <div key={x}>
                      <label>{titleCase(x)}</label>
                      <ThumbPicker value={options[x]} onChange={(val) => this.onOptionChange(x, val)} />
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={css.info}>
              <Lock />
              <p>Your feedback will be used to help us provide you with the best learning experience.</p>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

Feedback.propTypes = {
  booking: PropTypes.object.isRequired,
  isCall: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  onBack: PropTypes.func,
  fetchSessionLog: PropTypes.func.isRequired,
};
