import { connect } from 'react-redux';
import { selectUserPermissions, getMetaItem, getUsersFetched, getFormState } from 'selectors/meta';
import { getQueriedFriends, getQueriedMyFriendsPagination } from 'selectors/user';
import { updateMeta } from 'ducks/meta';
import componentKeys from 'constants/componentKeys';
import { hasNewFriend } from 'selectors/globalState';
import { queryMyFriends, setUserStatus, acceptFriendRequest, blockFriend, ignoreFriendRequest } from 'ducks/user';
import FriendsQuery from './FriendsQuery';

export default connect(
  (state) => ({
    loginUserPermissions: selectUserPermissions(state),
    usersFetched: getUsersFetched(state),
    userQueryResults: getQueriedFriends(state),
    userPagination: getQueriedMyFriendsPagination(state),
    formState: getFormState(state),
    activePage: getMetaItem(componentKeys.ACTIVE_PAGE)(state),
    hasNewFriend: hasNewFriend(state),
  }),
  (dispatch) => ({
    setActivePage: (page) => dispatch(updateMeta(componentKeys.ACTIVE_PAGE, page)),
    fetchMyFriends: ({ pageNumber, pageSize }, status, name) =>
      dispatch(queryMyFriends({ pageNumber, pageSize }, status, name)),
    setUserStatus: (handleSearchParamsChange) => (userId, status) => {
      dispatch(setUserStatus(userId, status));
      handleSearchParamsChange();
    },
    acceptFriendRequest: (requestId, { pageNumber, pageSize }, status, name) =>
      dispatch(acceptFriendRequest(requestId, { pageNumber, pageSize }, status, name)),
    blockFriend: (friendId, { pageNumber, pageSize }, status, name) =>
      dispatch(blockFriend(friendId, { pageNumber, pageSize }, status, name)),
    ignoreFriendRequest: (requestId, { pageNumber, pageSize }, status, name) =>
      dispatch(ignoreFriendRequest(requestId, { pageNumber, pageSize }, status, name)),
  }),
)(FriendsQuery);
