import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Table } from 'components/molecules/Table';
import { featureSwitches } from 'featureSwitches';
import css from './styles.scss';
import columnMap, { columnNames } from './columnMap';

export default function UserQueryTable(props) {
  const { users, columns, setUserStatus, loginUserPermissions, usersFetched, processFriend, hideEmail } = props;

  return (
    usersFetched && (
      <div className={css.tableContainer}>
        <Table data={users} className={css.table} name="Users" emptyMessage="No users to show" rowClassName={css.row}>
          {columnMap(columns, { loginUserPermissions, setUserStatus, processFriend, hideEmail })}
        </Table>
      </div>
    )
  );
}

UserQueryTable.propTypes = {
  users: ImmutablePropTypes.list.isRequired,
  columns: PropTypes.array,
  setUserStatus: PropTypes.func.isRequired,
  loginUserPermissions: PropTypes.array.isRequired,
  processFriend: PropTypes.func,
  hideEmail: PropTypes.bool,
  usersFetched: PropTypes.bool,
};

UserQueryTable.defaultProps = {
  columns: [
    columnNames.ProfileColumn,
    columnNames.UserSince,
    columnNames.UserType,
    columnNames.QuickLink,
    columnNames.EditUserButton,
  ],
  usersFetched: PropTypes.bool.isRequired,
};
