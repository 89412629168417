import MultiSelectFilter from './multiSelect.js';

export default class DegreeFilter extends MultiSelectFilter {
  constructor(...args) {
    super(...args);
    this.title = 'Degree';
    this.name = 'Degree';
  }

  apply(tutor) {
    if (!this.active()) {
      return true;
    }
    return this.state.filtered.some((value) => tutor.tutorInfo.universities.map((u) => u.degree).indexOf(value) !== -1);
  }

  data({ tutors }) {
    const universities = tutors
      .filter((t) => t.tutorInfo && t.tutorInfo.universities && t.tutorInfo.universities.length)
      .map((t) => t.tutorInfo.universities.map((u) => u.degree))
      .reduce((a, b) => a.concat(b), []); // .flat();
    this.setState({ all: universities });
  }

  value() {
    return this.state.filtered;
  }

  simpleValue() {
    return this.value().join(', ');
  }
}
