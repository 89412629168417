import SelectFilter from './select.js';

export default class CurrencyFilter extends SelectFilter {
  constructor(...args) {
    super(...args);
    this.title = 'Currency';
    this.name = 'Currency';
    this.options = {
      'Coming soon': null,
    };

    this.state.all = Object.keys(this.options);
  }

  apply(tutor) {
    if (!this.active()) {
      return true;
    }
    const number = this.options[this.state.filtered];
    if (number === null) {
      return true;
    }
    return tutor.tutorInfo.studentCount < number;
  }

  data() {
    /* Doesn't use data */
  }
}
