import React from 'react';
import { useSelector } from 'react-redux';

import { getLoginUser } from 'selectors/user';

import Button from 'components/molecules/Button';
import RequestBookingModal from 'components/organisms/BookingModals/RequestBookingModal';

import css from './bookAction.scss';

function BookAction() {
  const user = useSelector((state) => getLoginUser(state));

  const [isModalOpen, toggleModal] = React.useState(false);

  return (
    <section className={css.bookAction}>
      <RequestBookingModal isOpen={isModalOpen} onCancel={() => toggleModal(false)} selectedUserId={user.userId} />
      <Button onClick={() => toggleModal(true)} primary>
        Book a session
      </Button>
    </section>
  );
}

export default React.memo(BookAction);
