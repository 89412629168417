import { escapeString } from 'utils/graphql';
import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';
import { gql } from '@apollo/client';
import { createClient } from '../utils';
import { contractType, itemType, packageType } from './common/types';

const { api } = getConfig();

const client = createClient(api.graphQLEndpoint);

const archiveContract = gql`
  ${contractType}
  mutation archiveContract($id: Int!) {
    archiveContract(id: $id) {
      ...contractType
      item {
        package {
          userId
          user {
            firstName
            lastName
          }
        }
      }
    }
  }
`;

const deleteOrRetractContract = gql`
  ${contractType}
  mutation deleteOrRetractContract($id: Int!) {
    deleteOrRetractContract(id: $id) {
      ...contractType
      item {
        package {
          userId
          user {
            firstName
            lastName
          }
        }
      }
    }
  }
`;

const createContract = gql`
  ${contractType}
  mutation createContract(
    $userId: String!
    $itemId: String!
    $studentDetails: [String]
    $subject: String!
    $start: String!
    $hours: String!
    $notes: String
  ) {
    createContract(
      userId: $userId
      itemId: $itemId
      studentDetails: $studentDetails
      subject: $subject
      start: $start
      hours: $hours
      notes: $notes
    ) {
      ...contractType
    }
  }
`;

const createContracts = gql`
  ${contractType}
  ${itemType}
  mutation createContracts(
    $userId: [String!]!
    $algorithm: String
    $version: String
    $rankings: [Int]
    $itemId: String!
    $status: String
    $invitationTutoringFrequency: String
    $invitationPreferredTimes: String
    $invitationNotes: String
    $selectionInfo: contractSelectionInfoType
  ) {
    createContracts(
      userId: $userId
      algorithm: $algorithm
      version: $version
      rankings: $rankings
      itemId: $itemId
      invitationTutoringFrequency: $invitationTutoringFrequency
      invitationPreferredTimes: $invitationPreferredTimes
      invitationNotes: $invitationNotes
      status: $status
      selectionInfo: $selectionInfo
    ) {
      contracts {
        ...contractType
      }
      item {
        ...itemType
      }
    }
  }
`;

const myContracts = gql`
  ${contractType}
  ${itemType}
  ${packageType}
  query myContracts {
    myContracts {
      ...contractType
      item {
        ...itemType
        package {
          ...packageType
        }
      }
    }
  }
`;

const getContractsByUserId = gql`
  ${contractType}
  ${itemType}
  ${packageType}
  query getContractsByUserId($userId: String!) {
    getContractsByUserId(userId: $userId) {
      ...contractType
      item {
        ...itemType
        package {
          ...packageType
        }
      }
    }
  }
`;

const getContractsByItemId = gql`
  ${contractType}
  ${itemType}
  ${packageType}
  query getContractsByItemId($itemId: String!) {
    getContractsByItemId(itemId: $itemId) {
      ...contractType
      item {
        ...itemType
        package {
          ...packageType
        }
      }
    }
  }
`;

const getContractById = gql`
  ${contractType}
  ${itemType}
  ${packageType}
  query getContractById($id: String!) {
    getContractById(id: $id) {
      ...contractType
      item {
        ...itemType
        package {
          ...packageType
        }
      }
    }
  }
`;

const packageFilePresignedUploadUrl = gql`
  query packageFilePresignedUploadUrl($fileName: String!, $packageName: String!) {
    packageFilePresignedUploadUrl(fileName: $fileName, packageName: $packageName) {
      putUrl
      bucket
      key
    }
  }
`;

const acceptContract = gql`
  mutation acceptContract($contractId: Int!) {
    acceptContract(id: $contractId)
  }
`;

const declineContract = gql`
  mutation declineContract($contractId: Int!, $reason: String) {
    declineContract(id: $contractId, reason: $reason)
  }
`;

export default {
  archiveContract: (id) => (id ? client.query(archiveContract, { id }) : Promise.resolve(null)),
  deleteOrRetractContract: (id) => (id ? client.query(deleteOrRetractContract, { id }) : Promise.resolve(null)),
  createContract: (data) =>
    client.query(createContract, { ...data, userId: data.tutorId, notes: escapeString(data.notes) }),
  createContracts: (data) => client.query(createContracts, data),
  myContracts: () => client.query(myContracts),
  getContractsByUserId: (userId) => client.query(getContractsByUserId, { userId }),
  getContractsByItemId: (itemId) => client.query(getContractsByItemId, { itemId }),
  getContractById: (id) => client.query(getContractById, { id: id.toString() }),
  packageFilePresignedUploadUrl: (fileName, packageName) =>
    client.query(packageFilePresignedUploadUrl, { fileName, packageName }),
  acceptContract: (contractId) => client.query(acceptContract, { contractId }),
  declineContract: (contractId, reason) => client.query(declineContract, { contractId, reason }),
};
