import React, { Component } from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';
import classnames from 'classnames';
import range from 'lodash/range';
import DropdownIcon from 'components/generic/Icons/Dropdown';
import css from './styles.scss';
import { asButton } from '../../../../utils/accessibility';

const MAX_MINUTES = 60;
const MIN_MINUTES = 0;

const isFirstHour = (time) => {
  return time.hour === 0;
};

class TimeSelect extends Component {
  constructor(props) {
    super(props);
    const { time, date, minuteIncrement, allowFirstSlot } = props;

    // Use prop date as override, if specified.
    let currentDate = null;
    if (date) {
      currentDate = date.clone().startOf('day');
    } else {
      currentDate = time.time.clone().subtract(time.hour, 'hours').startOf('day');
    }

    this.state = {
      focus: false,
      maxMinutes: MAX_MINUTES,
      minMinutes: isFirstHour(time) && !allowFirstSlot ? minuteIncrement : MIN_MINUTES,
      date: currentDate,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { time, date, minuteIncrement, allowFirstSlot, onChange } = nextProps;

    const updated = {
      maxMinutes: MAX_MINUTES,
      minMinutes: isFirstHour(time) && !allowFirstSlot ? minuteIncrement : MIN_MINUTES,
    };

    if (date) {
      const newDate = date.clone().startOf('day');

      if (!newDate.isSame(this.state.date)) {
        updated.date = newDate;
        time.time = newDate.clone().set({ hour: time.hour, minute: time.minute });
        onChange(time);
      }
    }

    this.setState(updated);
  }

  toggleFocus = () => {
    const { focus } = this.state;

    this.setState({
      focus: !focus,
    });
  };

  handleClickOutside = () => {
    const { focus } = this.state;

    if (focus) {
      this.setState({ focus: false });
    }
  };

  handleMinuteChange = (time) => {
    const { onChange } = this.props;

    this.setState({ focus: false }, () => onChange(time));
  };

  handleHourChange = (time) => {
    const { minuteIncrement, allowFirstSlot, onChange } = this.props;

    this.setState(
      {
        maxMinutes: MAX_MINUTES,
        minMinutes: isFirstHour(time) && !allowFirstSlot ? minuteIncrement : MIN_MINUTES,
      },
      () => {
        return onChange(time);
      },
    );
  };

  renderHour(thisHour) {
    const { time } = this.props;
    const { date } = this.state;

    const currentDate = date.clone().add(thisHour, 'h').add(time.minute, 'm');
    const hourString = currentDate.format('HH');

    return (
      <a
        key={thisHour}
        data-test-id={`selectHourInTimePicker_${hourString}`}
        className={classnames({ [css.selected]: currentDate.hour() === time.hour })}
        {...asButton(() =>
          this.handleHourChange({
            time: currentDate.startOf('hour'),
            hour: currentDate.hour(),
            minute: 0,
          }),
        )}
      >
        {this.props.twelveHour ? currentDate.format('ha') : currentDate.format('H')}
      </a>
    );
  }

  renderMinute(thisMinute) {
    const { time } = this.props;
    const { date } = this.state;

    const currentDate = date.clone().add(time.hour, 'h').add(thisMinute, 'm');
    const minuteString = currentDate.format('mm');

    return (
      <a
        key={thisMinute}
        data-test-id={`selectMinuteInTimePicker_${minuteString}`}
        className={classnames({
          [css.selected]: thisMinute === time.minute,
        })}
        {...asButton(() =>
          this.handleMinuteChange({
            time: currentDate,
            hour: time.hour,
            minute: thisMinute,
          }),
        )}
      >
        {currentDate.format('mm')}
      </a>
    );
  }

  render() {
    const {
      label,
      twelveHour,
      minuteIncrement,
      time,
      className,
      allowLastSlot,
      dataTestId,
      showOvernight,
      showCustomize,
    } = this.props;
    const { maxMinutes, minMinutes, focus } = this.state;
    let dropdown;
    let timeDisplayStyle = css.timeDisplay;

    // Only render the dropdown if focused.
    if (focus) {
      timeDisplayStyle = classnames(css.timeDisplay, css.timeDisplayFocused);

      // Create lists of hour and minute elements.
      const maxHoursRange = allowLastSlot ? 25 : 24;
      const hours = range(0, maxHoursRange).map((hour) => this.renderHour(hour));
      const minutes = range(minMinutes, maxMinutes, minuteIncrement).map((minute) => this.renderMinute(minute));

      dropdown = (
        <div className={classnames({ [css.dropdownContent]: true, [css.showCustomize]: showCustomize === true })}>
          <div className={css.dropdownColumn}>{hours}</div>
          <div className={css.dropdownColumn}>{minutes}</div>
          {showCustomize ? (
            <div className={css.customize} {...asButton(showOvernight)}>
              Customize
            </div>
          ) : null}
        </div>
      );
    }

    return (
      <div className={classnames(css.timeSelect, className)}>
        {label && <div className={css.label}>{label}</div>}
        <div className={timeDisplayStyle} data-test-id={dataTestId} {...asButton(this.toggleFocus)}>
          <span>{twelveHour ? time.time.format('h:mma') : time.time.format('H:mm')}</span>
          <DropdownIcon className={css.dropdownIcon} />
        </div>
        <div className={css.dropdownContainer}>{dropdown}</div>
      </div>
    );
  }
}

TimeSelect.defaultProps = {
  twelveHour: true,
  minuteIncrement: 15,
  allowLastSlot: true,
  allowFirstSlot: true,
};

TimeSelect.propTypes = {
  label: PropTypes.string,
  twelveHour: PropTypes.bool,
  className: PropTypes.string,
  minuteIncrement: PropTypes.number,
  date: PropTypes.object,
  time: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  allowLastSlot: PropTypes.bool,
  allowFirstSlot: PropTypes.bool,
  dataTestId: PropTypes.string,
  showCustomize: PropTypes.bool,
  showOvernight: PropTypes.func,
};

const withOnClickOutSide = onClickOutside(TimeSelect);

export { withOnClickOutSide as default, TimeSelect as TimeSelectWithoutOnClickOutside };
