import React from 'react';
import PropTypes from 'prop-types';

export default function Rocket({ className }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <defs>
        <path
          id="a"
          d="M19.52 3.007a1.643 1.643 0 0 0-.378.009c-2.06.289-6.488.926-11.024 6.29H5.753l-2.41 2.508a1.165 1.165 0 0 0-.253 1.274c.18.439.605.721 1.08.721h2.215c-.297.338-.449.696-.602 1.093-.17.44-.31.918-.427 1.369-.234.901-.373 1.705-.373 1.705l-.224 1.265 1.265-.224s.804-.139 1.705-.373c.45-.117.93-.258 1.369-.427.397-.153.755-.305 1.092-.602v2.215c0 .475.283.9.722 1.08.148.062.303.09.455.09.299 0 .587-.11.789-.313l2.538-2.314v-2.491c5.362-4.536 6-8.964 6.29-11.024a1.619 1.619 0 0 0-.46-1.38 1.624 1.624 0 0 0-1.004-.471zm-3.474 3.596a1.352 1.352 0 1 1-.001 2.703 1.352 1.352 0 0 1 0-2.703zm-3.604 3.603a1.352 1.352 0 1 1 0 2.704 1.352 1.352 0 0 1 0-2.704zm-4.377 4.455l1.274 1.274-.313.313c.016-.016-.24.158-.577.288-.338.13-.764.257-1.174.363-.117.03-.111.022-.223.05.027-.113.019-.107.049-.224.106-.41.232-.836.362-1.174.13-.337.305-.593.289-.577l.313-.313z"
        />
      </defs>
      <g fillRule="evenodd">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <use fill="inherit" fillRule="nonzero" xlinkHref="#a" />
        <g fill="inherit" mask="url(#b)">
          <path d="M0 0h24v24H0z" />
        </g>
      </g>
    </svg>
  );
}

Rocket.propTypes = {
  className: PropTypes.string,
};
