import { Map, fromJS } from 'immutable';
import createReducer from 'utils/createReducer';
import uuid from 'uuid/v4';

import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';
import { fileEntity } from 'schema';
import { ADD_ENTITIES, addEntitiesWithNormalisation } from 'ducks/normalizr';
import { updateMeta } from 'ducks/meta';
import { getFilesUploading } from 'selectors/meta';
import componentKeys from 'constants/componentKeys';
import { getBearer } from 'utils/auth';
import uploadedFile from 'graphql/api/uploadedFile';
import { fetchUsersByIds } from 'ducks/user';
import * as Logger from '@crimson-education/browser-logger';
import conversation from 'graphql/api/conversation';
import OSS from 'ali-oss';
import crypto from 'crypto';
import moment from 'moment';

import { FILE_SIZE_ERROR, FILE_SIZE_LIMIT, FILE_UPLOAD_ERROR } from 'components/molecules/FileUploadingDisplay';

const config = getConfig();
export const DELETE_FILE_SUCCEEDED = 'file/DELETE_FILE_SUCCEEDED';

const initialState = new Map();

export default createReducer(initialState, {
  [ADD_ENTITIES]: (state, action) => {
    return state.mergeDeep(action.payload.entities.file);
  },
  [DELETE_FILE_SUCCEEDED]: (state, action) => {
    return state.delete(action.payload.fileId);
  },
});

export function deleteFile(fileId) {
  return { type: DELETE_FILE_SUCCEEDED, payload: { fileId } };
}

export function fetchFileById(id, refType) {
  return async (dispatch) => {
    const { getUploadedFileById: file } = await uploadedFile.fetchFileByIdAndRefType(id, refType);
    if (file) {
      dispatch(addEntitiesWithNormalisation(file, fileEntity));
      dispatch(fetchUsersByIds([file.uploadedBy]));
      if (refType === 'message') {
        dispatch(updateMeta(componentKeys.FILE_UPLOADED, file));
      }
    }
  };
}

export function fetchFilesForUser(userId, tag) {
  return async (dispatch) => {
    const { getUploadedFilesByUserId: results } = await uploadedFile.fetchFilesForUser(userId, tag);
    const uploaderIds = results.map((file) => file.uploadedBy);
    if (uploaderIds.length) {
      dispatch(addEntitiesWithNormalisation(results, [fileEntity]));
      dispatch(fetchUsersByIds(uploaderIds));
    }
  };
}

function updateProgressEvent(event, id) {
  return async (dispatch, getState) => {
    if (event.lengthComputable) {
      const uploadsInProgress = getFilesUploading(getState());
      const upload = uploadsInProgress.get(id).toJS();
      upload.uploadPercentage = Math.floor((event.loaded / event.total) * 100);
      dispatch(updateMeta(componentKeys.FILES_UPLOADING, uploadsInProgress.set(id, fromJS(upload))));
    }
  };
}

function uploadFailedEvent(event, id) {
  return async (dispatch, getState) => {
    const uploadsInProgress = getFilesUploading(getState());
    const upload = uploadsInProgress.get(id).toJS();
    upload.error = FILE_UPLOAD_ERROR;
    if (event.lengthComputable) upload.uploadPercentage = Math.floor((event.loaded / event.total) * 100);
    dispatch(updateMeta(componentKeys.FILES_UPLOADING, uploadsInProgress.set(id, fromJS(upload))));
  };
}

function uploadCompleteEvent(event, request, tempId, type, fileSize) {
  return async (dispatch, getState) => {
    // Delete file from uploads list and create entry in documents list
    const uploadsInProgress = getFilesUploading(getState());
    if (request.status === 201) {
      const metaObj = {
        fileSize,
      };
      Logger.trackEventSinceLastAction({ message: 'upload file success', metadata: metaObj });
      const response = JSON.parse(request.response);
      dispatch(fetchFileById(response.id, type));
      dispatch(updateMeta(componentKeys.FILES_UPLOADING, uploadsInProgress.delete(tempId)));
    } else if (request.status === 500) {
      dispatch(uploadFailedEvent(event, tempId));
    }
  };
}

function uploadCancelledEvent(id) {
  return async (dispatch, getState) => {
    const uploadsInProgress = getFilesUploading(getState());
    dispatch(updateMeta(componentKeys.FILES_UPLOADING, uploadsInProgress.delete(id)));
  };
}

export function uploadFile(upload, tag, refType, threadId) {
  return async (dispatch, getState) => {
    // Add extra metadata to the upload object
    upload.tempId = uuid();
    upload.name = upload.blob.name;
    if (refType === 'message') {
      const MESSAGE_FILE_SIZE_LIMIT = 100000000;
      upload.error = upload.blob.size > MESSAGE_FILE_SIZE_LIMIT ? FILE_SIZE_ERROR : null;
    } else {
      upload.error = upload.blob.size > FILE_SIZE_LIMIT ? FILE_SIZE_ERROR : null;
    }
    upload.uploadPercentage = 0;
    upload.cancel = () => dispatch(uploadCancelledEvent(upload.tempId));

    // Create a new upload entry in redux store
    const uploadsInProgress = getFilesUploading(getState());
    dispatch(updateMeta(componentKeys.FILES_UPLOADING, uploadsInProgress.set(upload.tempId, fromJS(upload))));

    if (!upload.error) {
      const request = new XMLHttpRequest();

      upload.cancel = () => request.abort();

      const updateProgress = (event) => dispatch(updateProgressEvent(event, upload.tempId));
      const uploadFailed = (event) => dispatch(uploadFailedEvent(event, upload.tempId));
      const uploadComplete = (event) =>
        dispatch(uploadCompleteEvent(event, request, upload.tempId, refType, upload.size));
      const uploadCancelled = () => dispatch(uploadCancelledEvent(upload.tempId));

      request.upload.addEventListener('progress', updateProgress);
      request.addEventListener('loadend', uploadComplete);
      request.upload.addEventListener('error', uploadFailed);
      request.upload.addEventListener('abort', uploadCancelled);

      const formData = new FormData();
      let metaData;
      if (refType === 'user') {
        metaData = JSON.stringify({
          refType: 'user',
          refs: [
            {
              refId: upload.userId,
              refType,
              tag,
            },
          ],
        });
      } else {
        metaData = JSON.stringify({
          refType: 'message',
          threadId,
        });
      }
      formData.append('meta', metaData);
      formData.append('data', upload.blob);
      request.open('POST', `${config.api.endpoint}/files`);
      request.setRequestHeader('Authorization', `Bearer ${getBearer()}`);
      request.send(formData);
    }
  };
}

export function deleteUploadedFile(fileId) {
  return async (dispatch) => {
    await uploadedFile.deleteUploadedFile(fileId);
    dispatch(deleteFile(fileId));
  };
}

// Upload / Download file for message
export function downloadMessageFile(fileId, source) {
  return async () => {
    if (source && source === 'OSS') {
      conversation.getPresignedDownloadUrl(fileId).then((url) => {
        const openedWin = window.open(url);
        if (!openedWin) {
          window.location.href = url;
        }
      });
    } else {
      // Get the download file from aws
      uploadedFile.getDownloadUrl(fileId).then((url) => {
        const openedWin = window.open(url);
        if (!openedWin) {
          window.location.href = url;
        }
      });
    }
  };
}

function updateFileProgressEvent(id, p, tempCheckpoint) {
  return async (dispatch, getState) => {
    const uploadsInProgress = getFilesUploading(getState());
    if (uploadsInProgress.get(id)) {
      const upload = uploadsInProgress.get(id).toJS();
      upload.uploadPercentage = Math.floor(p * 100);
      if (tempCheckpoint) {
        upload.uploadId = tempCheckpoint.uploadId;
        upload.objectName = tempCheckpoint.name;
      }
      dispatch(updateMeta(componentKeys.FILES_UPLOADING, uploadsInProgress.set(upload.tempId, fromJS(upload))));
    }
  };
}

function uploadFileSuccessEvent(id, file) {
  return async (dispatch, getState) => {
    const uploadsInProgress = getFilesUploading(getState());
    dispatch(updateMeta(componentKeys.FILE_UPLOADED, file));
    dispatch(updateMeta(componentKeys.FILES_UPLOADING, uploadsInProgress.delete(id)));
  };
}

function uploadFileFailedEvent(id) {
  return async (dispatch, getState) => {
    const uploadsInProgress = getFilesUploading(getState());
    if (uploadsInProgress.get(id)) {
      const upload = uploadsInProgress.get(id).toJS();
      upload.error = FILE_UPLOAD_ERROR;
      dispatch(updateMeta(componentKeys.FILES_UPLOADING, uploadsInProgress.set(id, fromJS(upload))));
    }
  };
}

// After upload file successfully, save the file information to database
export function saveFileToDatabase(upload, key, startTime) {
  return async (dispatch) => {
    const duration = moment().diff(moment(startTime), 'seconds');
    const metaObj = {
      fileSize: upload.blob.size,
      costTime: `${duration} seconds`,
    };
    Logger.trackEventSinceLastAction({ message: 'upload file success', metadata: metaObj });
    const sha512 = crypto.createHash('sha512').update(upload.blob).digest('hex');
    return uploadedFile
      .saveUploadedFile(key, sha512, upload.name, upload.blob.size, upload.blob.type)
      .then((result) => {
        dispatch(uploadFileSuccessEvent(upload.tempId, result.saveUploadedFile));
        return result;
      });
  };
}

// Upload file to oss: Use Alibaba Cloud security token service to authorize temporary access to OSS
export function uploadFileThroughSTS(threadId, file, startTime) {
  return async (dispatch, getState) => {
    let upload;
    try {
      const { stsInfo: creds } = await conversation.fetchOSSSecurityToken();
      const client = new OSS({
        region: creds.region,
        accessKeyId: creds.accessKeyId,
        accessKeySecret: creds.accessKeySecret,
        stsToken: creds.stsToken,
        bucket: creds.bucket,
      });

      upload = file;
      upload.tempId = uuid();
      upload.name = file.blob.name;
      const uploadsInProgress = getFilesUploading(getState());
      dispatch(updateMeta(componentKeys.FILES_UPLOADING, uploadsInProgress.set(upload.tempId, fromJS(upload))));

      const key = `${threadId}/${Date.now()}_${Math.random().toString().slice(-6)}`;
      let tempCheckpoint;
      const res = await client.multipartUpload(key, file.blob, {
        progress(p, checkpoint) {
          tempCheckpoint = checkpoint;
          dispatch(updateFileProgressEvent(upload.tempId, p, tempCheckpoint));
        },
        checkpoint: tempCheckpoint,
      });
      if (res.res.status === 200) {
        const saveFileToDbRes = await dispatch(saveFileToDatabase(upload, key, startTime));
        return {
          ...res,
          ...saveFileToDbRes,
        };
      }
      return res;
    } catch (err) {
      Logger.reportError(err);
      upload && dispatch(uploadFileFailedEvent(upload.tempId));
    }
  };
}

// Cancel upload file
export function abortMultipartUpload(id) {
  return async (dispatch, getState) => {
    Logger.trackEventSinceLastAction({ message: 'cancel upload file' });
    const uploadsInProgress = getFilesUploading(getState());
    const upload = uploadsInProgress.get(id).toJS();
    return conversation.fetchOSSSecurityToken().then((res) => {
      const creds = res.stsInfo;
      const client = new OSS({
        region: creds.region,
        accessKeyId: creds.accessKeyId,
        accessKeySecret: creds.accessKeySecret,
        stsToken: creds.stsToken,
        bucket: creds.bucket,
      });
      if (upload.uploadId) {
        return client
          .abortMultipartUpload(upload.objectName, upload.uploadId)
          .then(() => {
            dispatch(uploadCancelledEvent(id));
          })
          .catch((err) => {
            Logger.reportError(err);
          });
      }
      return true;
    });
  };
}
