import { LOCATION_CHANGE } from 'ducks/meta';
import { fetchBookingsRequiringFeedBack } from 'ducks/booking';

const feedbackMiddleware = ({ dispatch }) => (next) => (action) => {
  if (action.type === LOCATION_CHANGE) {
    if (action.payload.pathname.split('/').find((val) => val === 'test-prep')) return next(action);
    dispatch(fetchBookingsRequiringFeedBack());
  }
  return next(action);
};

export default feedbackMiddleware;
