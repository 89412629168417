import { connect } from 'react-redux';
import { compose } from 'redux';
import { withAppContext } from 'components/enhancers/AppContext';
import { getCachedContactListPageInfo } from 'selectors/meta';
import { getFilteredContacts } from 'selectors/user';
import { fetchingUserContacts } from 'ducks/user';
// import userAPI from 'graphql/api/user';
import AddMorePeopleModal from './index.js';

// const setContactsFilter = async (filterText, pageSize) => {
//   const { edges } = await userAPI
//     .getContactsForUser(filterText, pageSize, null);
//   return edges.map(e => e.node);
// };

export default compose(
  connect(
    (state) => ({
      contactPageInfo: getCachedContactListPageInfo(state).toJS(),
      contacts: getFilteredContacts(state).toList().toJS(),
    }),
    (dispatch) => ({
      setContactsFilter: (filter) => dispatch(fetchingUserContacts(filter)),
    }),
  ),
)(withAppContext(AddMorePeopleModal));
