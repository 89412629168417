import React from 'react';
import PropTypes from 'prop-types';

export default function SelectCard({ className }) {
  return (
    <svg className={className} height="121" viewBox="0 0 212 121" width="100%" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <ellipse cx="106.48" cy="117.3" fill="#f2f3f6" fillRule="nonzero" rx="43.82" ry="3.63" />
        <rect height="28.59" rx="5" stroke="#dadae0" width="89.33" x="60.63" y=".5" />
        <rect
          fill="#e5e5e5"
          fillRule="nonzero"
          height="28.59"
          rx="5"
          stroke="#3e464d"
          width="89.33"
          x="60.63"
          y="35.26"
        />
        <rect height="28.59" rx="5" stroke="#dadae0" width="89.33" x="60.63" y="70.02" />
        <g fillRule="nonzero">
          <rect fill="#e5e5e5" height="3.93" rx="1.96" width="71" x="69.3" y="17.33" />
          <rect fill="#babcc5" height="3.93" rx="1.96" width="21" x="69.3" y="9.87" />
          <rect fill="#e5e5e5" height="3.93" rx="1.96" width="63" x="69.3" y="85.61" />
          <rect fill="#babcc5" height="3.93" rx="1.96" width="21" x="69.3" y="78.14" />
          <rect fill="#fff" height="3.93" rx="1.96" width="57" x="69.3" y="51.17" />
          <rect fill="#babcc5" height="3.93" rx="1.96" width="21" x="69.3" y="43.71" />
          <g transform="translate(104 52)">
            <path
              d="m5.56 17.44c-.67.66-1.32 1.28-1.94 1.91-.4048787.6159712-1.18755223.8615958-1.87178437.5874176-.68423213-.2741781-1.08072186-.9923043-.94821563-1.7174176 0-5.1733333 0-10.34333333 0-15.51-.09979912-.70760815.28473823-1.39486639.94-1.68.66167534-.25623892 1.4130915-.04909177 1.85.51l11.11 10.74c.1549616.1397155.2926108.2975085.41.47.3265574.430336.3847894 1.0072443.1508174 1.494159s-.720785.8019183-1.2608174.815841c-1 0-2 0-3.13 0 .21.52.37.93.54 1.34.41 1 .83 1.91 1.23 2.87.2614171.476256.2937424 1.0451058.0879613 1.5479108-.2057812.5028049-.6276515.8857677-1.1479613 1.0420892-.57.25-1.14.5-1.72.73-.46002998.2410017-1.00237023.2690762-1.48482583.0768628s-.85690572-.585543-1.02517417-1.0768628c-.62-1.38-1.2-2.76-1.79-4.15zm-3.34-15.34v16.52l.15.1 3.63-3.72c.16.31.26.48.34.67.73 1.72 1.47 3.44 2.19 5.17.19.45.42.7.94.44s.92-.4 1.38-.6c.75-.32.78-.4.46-1.16-.72-1.69-1.45-3.37-2.16-5-.29-.67-.25-.72.5-.73h3.75c.22 0 .44 0 .86-.06z"
              fill="#4a90e2"
              stroke="#4a90e2"
            />
            <path
              d="m2.22 2.1 12 11.59c-.42 0-.64.06-.86.06h-3.75c-.75 0-.79.06-.5.73.71 1.68 1.44 3.36 2.16 5 .32.76.29.84-.46 1.16-.46.2-.93.37-1.38.6s-.75 0-.94-.44c-.72-1.73-1.46-3.45-2.19-5.17-.08-.19-.18-.36-.34-.67l-3.58 3.69-.15-.1z"
              fill="#fff"
            />
          </g>
          <path
            d="m33.28 80.54h-2.59v-2.54c.0000258-.1396628-.0561303-.2734652-.1558228-.3712767-.0996926-.0978116-.2345402-.1514091-.3741772-.1487233-.2871881 0-.52.2328119-.52.52v2.59h-2.59c-.2927109 0-.53.2372891-.53.53-.0000258.1396628.0561303.2734652.1558228.3712767.0996926.0978116.2345402.1514091.3741772.1487233h2.59v2.59c-.0026858.139637.0509117.2744846.1487233.3741772.0978115.0996925.2316139.1558486.3712767.1558228.2927109 0 .53-.2372891.53-.53v-2.64h2.59c.2871881 0 .52-.2328119.52-.52.0026858-.139637-.0509117-.2744846-.1487233-.3741772-.0978115-.0996925-.2316139-.1558486-.3712767-.1558228z"
            fill="#e3e7ed"
          />
          <path
            d="m6.75 49.69h-2.58v-2.59c0-.2927109-.23728908-.53-.53-.53s-.53.2372891-.53.53v2.59h-2.58c-.29271092 0-.53.2372891-.53.53-.00002583.1396628.05613031.2734652.15582284.3712767.09969254.0978116.23454019.1514091.37417716.1487233h2.58v2.59c0 .2927109.23728908.53.53.53s.53-.2372891.53-.53v-2.59h2.58c.13963698.0026858.27448463-.0509117.37417716-.1487233.09969253-.0978115.15584867-.2316139.15582284-.3712767 0-.2927109-.23728908-.53-.53-.53z"
            fill="#00a896"
          />
          <path
            d="m31.72 19.77h-2.59v-2.58c0-.2927109-.2372891-.53-.53-.53-.1396628-.0000258-.2734652.0561303-.3712767.1558228-.0978116.0996926-.1514091.2345402-.1487233.3741772v2.58h-2.59c-.2927109 0-.53.2372891-.53.53-.0000258.1396628.0561303.2734652.1558228.3712767.0996926.0978116.2345402.1514091.3741772.1487233h2.59v2.59c-.0026858.139637.0509117.2744846.1487233.3741772.0978115.0996925.2316139.1558486.3712767.1558228.1405647 0 .2753723-.0558391.3747666-.1552334s.1552334-.2342019.1552334-.3747666v-2.59h2.59c.2871881 0 .52-.2328119.52-.52.0026858-.139637-.0509117-.2744846-.1487233-.3741772-.0978115-.0996925-.2316139-.1558486-.3712767-.1558228z"
            fill="#babcc5"
          />
          <path
            d="m178.35 11.3h-2.69v-2.69c0-.29271092-.237289-.53-.53-.53s-.53.23728908-.53.53v2.69h-2.69c-.292711 0-.53.2372891-.53.53s.237289.53.53.53h2.69v2.69c0 .2927109.237289.53.53.53s.53-.2372891.53-.53v-2.69h2.69c.292711 0 .53-.2372891.53-.53s-.237289-.53-.53-.53z"
            fill="#e3e7ed"
          />
          <path
            d="m178.35 74.26h-2.69v-2.7c0-.2927109-.237289-.53-.53-.53s-.53.2372891-.53.53v2.7h-2.69c-.219554-.0771882-.463776-.0021181-.602066.1850661-.13829.1871843-.13829.4426835 0 .6298678.13829.1871842.382512.2622543.602066.1850661h2.69v2.74c0 .2927109.237289.53.53.53s.53-.2372891.53-.53v-2.7h2.69c.219554.0771882.463776.0021181.602066-.1850661.13829-.1871843.13829-.4426835 0-.6298678-.13829-.1871842-.382512-.2622543-.602066-.1850661z"
            fill="#83a0bc"
          />
          <path
            d="m196 45.15h-2.69v-2.7c.000026-.1396628-.05613-.2734652-.155823-.3712767-.099692-.0978116-.23454-.1514091-.374177-.1487233-.137913 0-.270177.0547856-.367696.1523045-.097518.0975189-.152304.2297829-.152304.3676955v2.7h-2.7c-.287188 0-.52.2328119-.52.52-.002686.139637.050912.2744846.148723.3741772.097812.0996925.231614.1558486.371277.1558228h2.7v2.7c0 .1379126.054786.2701766.152304.3676955.097519.0975189.229783.1523045.367696.1523045.139637.0026858.274485-.0509117.374177-.1487233.099693-.0978115.155849-.2316139.155823-.3712767v-2.7h2.69c.292711 0 .53-.2372891.53-.53.000026-.1396628-.05613-.2734652-.155823-.3712767-.099692-.0978116-.23454-.1514091-.374177-.1487233z"
            fill="#00a896"
          />
        </g>
      </g>
    </svg>
  );
}

SelectCard.propTypes = {
  className: PropTypes.string,
};
