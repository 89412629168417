import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  PermissionAction,
  PermissionResourceQualifier,
  PermissionResourceType,
} from '@crimson-education/common-config/lib/authorization';
import Immutable from 'immutable';
import { useHistory, useParams } from 'react-router';
import { useErrorHandler } from 'react-error-boundary';

import classNames from 'classnames';
import { RadioGroup, Radio } from 'react-radio-group';
import usePermissionCheck from 'hooks/usePermissionCheck';
import authorizationAPI from 'graphql/api/authorization';
import userAPI from 'graphql/api/user';
import { validateRoleAssociations } from 'utils/validation';

import { fetchUserById, setUserStatus, setUserRoles, setIsTestUser } from 'ducks/user';
import { getCurrentUser } from 'selectors/user';
import { selectUserProfile } from 'selectors/meta';

import Button from 'components/molecules/Button';
import MultiSelectDropdown from 'components/generic/Forms/MultiSelectDropdown';
import { Container as InfoTextContainer, Info as InfoTextInfo } from 'components/generic/InfoText';
import Modal from 'components/molecules/Modal';
import Header from 'components/molecules/Header';
import CheckboxField from 'components/generic/Forms/CheckboxField';
import Unauthorised from 'components/pages/Unauthorised';
import LoadingIndicator from 'components/molecules/LoadingIndicator';

import css from './styles.scss';

export default function EditUser() {
  const handleError = useErrorHandler();
  const history = useHistory();
  const dispatch = useDispatch();
  const loginUser = useSelector(selectUserProfile).toJS();
  const { userId = loginUser.id } = useParams();
  const user = useSelector(getCurrentUser);
  const [stateUserRoleIds, setStateUserRoleIds] = useState(null);
  const [statePrimaryRole, setStatePrimaryRole] = useState(null);
  const [stateIsTestUser, setStateIsTestUser] = useState(false);
  const [selectableRoles, setSelectableRoles] = useState(null);
  const [isConfirmOpen, setConfirmOpen] = useState(false);
  const [isDeactivateOpen, setDeactivateOpen] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Dispatch a request for the user, if the userId changes.
  useEffect(() => {
    dispatch(fetchUserById(userId));
  }, [userId, dispatch]);

  // Once the user comes back, pull out the info.
  useEffect(() => {
    if (user) {
      setStateIsTestUser(user.get('isTest'));
      const userRoles = user.get('userRoles');
      if (userRoles) {
        setStateUserRoleIds(userRoles);
        const primaryRole = userRoles && userRoles.size > 0 && userRoles.get(0);
        setStatePrimaryRole(primaryRole);
      }
    }
  }, [user]);

  const resource = {
    qualifier: PermissionResourceQualifier.User,
    identifier: userId,
  };

  const canEditUser = usePermissionCheck(
    {
      action: PermissionAction.Edit,
      resourceType: PermissionResourceType.User,
      resource,
    },
    [userId],
  );

  const hasEditPermission = !canEditUser.loading && canEditUser.isPermitted;

  useEffect(() => {
    if (hasEditPermission) {
      // There might be some roles the editing user has the requesting user doesn't have the ability to assign directly.
      Promise.all([
        authorizationAPI.actionableRoles(PermissionAction.Associate).then((data) => data.actionableRoles),
        userAPI.rolesForUser(userId).then((data) => data.rolesForUser.roles.map((r) => r.role)),
      ]).then(([actionableRoles, rolesForUser]) => {
        const additionalRoles = rolesForUser.filter((r) => actionableRoles.findIndex((ar) => ar.id === r.id) === -1);

        setSelectableRoles(Immutable.fromJS([...actionableRoles, ...additionalRoles]));
      }, handleError);
    }
  }, [handleError, hasEditPermission, userId]);

  const canDeactivateUser = usePermissionCheck(
    {
      action: PermissionAction.Delete,
      resourceType: PermissionResourceType.User,
      resource,
    },
    [userId],
  );

  const onRolesChanged = useCallback(
    (roles) => {
      const newRoles = roles.toJS ? roles.toJS() : roles;

      const newRoleIds = newRoles.map((r) => r.id);
      if (!newRoleIds.includes(statePrimaryRole)) {
        setStatePrimaryRole(newRoleIds[0]);
      }
      setStateUserRoleIds(newRoleIds);
    },
    [statePrimaryRole],
  );

  const deactivateUser = useCallback(async () => {
    setIsSubmitting(true);
    dispatch(setUserStatus(userId, false)).then((success) => {
      setIsSubmitting(null);
      if (success) {
        history.goBack();
      }
    });
  }, [dispatch, history, userId]);

  // TODO: Confirm new logic here.
  const validateRoles = useCallback(() => {
    const validationErrors = validateRoleAssociations(stateUserRoleIds);
    setValidationErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  }, [setValidationErrors, stateUserRoleIds]);

  const submit = useCallback(async () => {
    setIsSubmitting(true);

    await dispatch(setUserRoles(userId, stateUserRoleIds, statePrimaryRole));
    if (stateIsTestUser !== user.get('isTest')) {
      await dispatch(setIsTestUser(userId, stateIsTestUser));
    }

    setIsSubmitting(false);
    setConfirmOpen(false);
  }, [dispatch, stateIsTestUser, statePrimaryRole, stateUserRoleIds, user, userId]);

  if (canEditUser.loading || selectableRoles === null || stateUserRoleIds === null) {
    return <LoadingIndicator />;
  }

  if (!hasEditPermission) {
    return <Unauthorised />;
  }

  const selectedRoles = stateUserRoleIds.map((roleId) => selectableRoles.find((r) => roleId === r.get('id')));

  const isEditingSelf = loginUser.userId === userId;

  const canDeactivate = !isEditingSelf && canDeactivateUser.isPermitted;

  const info = {
    backText: 'Our People',
    backURL: '/our-people',
    text: `Editing a type of user will give them varying levels of access to
  the Crimson app. Please ensure they are the correct user type.`,
  };

  const actionButtons = canDeactivate
    ? [
        {
          buttonText: 'Deactivate this user',
          buttonAction: () => setDeactivateOpen(!isDeactivateOpen),
        },
      ]
    : [];

  return (
    <div data-ga-category="Edit User">
      <Header
        title="Edit User"
        isBackButtonVisible
        backButtonText={info.backText}
        backButtonLink={info.backURL}
        actionButtons={actionButtons}
      />

      <Modal
        isOpen={isDeactivateOpen}
        onSubmit={deactivateUser}
        onClose={() => setDeactivateOpen(!isDeactivateOpen)}
        title="Deactivate User"
        submitText="Deactivate this user"
        dataTestId="deactivateUserButton"
        secondarySubmitText="Cancel"
        onSecondarySubmit={() => setDeactivateOpen(!isDeactivateOpen)}
        loading={isSubmitting}
        loadingTick={false}
      >
        <span className={css.modalText}>Are you sure you want to deactivate this user?</span>
      </Modal>

      <Modal
        isOpen={isConfirmOpen}
        onSubmit={submit}
        onClose={() => setConfirmOpen(false)}
        title="Confirm edit user"
        submitText="Confirm"
        secondarySubmitText="Cancel"
        onSecondarySubmit={() => setConfirmOpen(false)}
        loading={isSubmitting}
      >
        <span className={css.modalText}> Are you sure you want to make changes to this user?</span>
      </Modal>

      {/* Info and form */}
      <div className={css.container}>
        <InfoTextContainer>
          <InfoTextInfo text={info.text} />
        </InfoTextContainer>

        <div className={css.form}>
          <MultiSelectDropdown
            id="userRoles"
            dataTestId="editUserRolesDropdown"
            options={selectableRoles}
            optionLabelSelector="name"
            placeholder="Select role of user"
            label="Type of user"
            onValueChange={onRolesChanged}
            multiple
            defaultValue={selectedRoles}
            error={validationErrors.roleError}
            validationMessage={validationErrors.roleMessage}
            className={css.multiSelect}
          />

          <div className={classNames({ [css.hide]: stateUserRoleIds.length < 2 })}>
            <label htmlFor="primaryRole" className={css.label}>
              Primary role
            </label>
            <RadioGroup name="primaryRole" selectedValue={statePrimaryRole} onChange={setStatePrimaryRole}>
              {stateUserRoleIds.map((roleId) => {
                const roleInfo = selectableRoles.find((r) => r.get('id') === roleId);

                return (
                  <label key={roleId} className={css.option}>
                    <Radio value={roleId} />
                    {roleInfo.get('name')}
                  </label>
                );
              })}
            </RadioGroup>
          </div>
          <CheckboxField
            id="isTestUser"
            dataTestId="isTestUser"
            label="This is a test user"
            onClick={setStateIsTestUser}
            isChecked={stateIsTestUser}
            dataGaLabel="IsTestUser"
          />

          <div className={css.footer}>
            <Button
              primary
              dataTestId="confirmEditUserRoleButton"
              onClick={() => validateRoles() && setConfirmOpen(true)}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
