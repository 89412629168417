import React from 'react';
import ReactMarkdown from 'react-markdown';
import classnames from 'classnames';
import { Body } from 'components/atoms/typography';
import css from './style.scss';

type Props = {
  markdownString: string;
  className?: string;
};
export default function Markdown({ className, markdownString, ...props }: Props) {
  return (
    <Body className={classnames(className, css.markdown)}>
      <ReactMarkdown {...props}>{markdownString}</ReactMarkdown>
    </Body>
  );
}
