import React, { useState, useEffect, useContext } from 'react';
import SendParentForApproval from './SendParentForApproval';
import { AppTrackerContext } from '../viewController';
import Dialogue from '../Dialogue';
import SelectEmailTempalteUK from './SelectEmailTemplateUK';
import DefineEmailContentUS from './DefineEmailContentUS';
import { DoubleConfirmText } from './index.style';
import { useUserId, useSafeLoading } from '../helper';
import { isContactSelected } from './helper';
import { ApplicationApprovalRecipient, ApplicationApproval } from '../type';
import { openLink } from '../util';
import { PARENT_APPROVAL_MODAL_LINKS, EMAIL_TEMPLATE_OPTIONS } from '../constants';
import { get } from 'lodash';

// 'A' for Send School Confirmation Agreement / select contacts modal
// 'B' for select template, add clause / fill in additional email content modal
type TModal = 'A' | 'B';

// 'cancel' for double confirm cancel
// 'send' for double confirm send
type TDoubleConfirmModal = 'cancel' | 'send';

const useParentApprovalManager = () => {
  const { state } = useContext(AppTrackerContext);
  const [currentModal, setCurrentModal] = useState<TModal | null>('A');
  const [currentDoubleConfirmModal, setCurrentDoubleConfirmModal] = useState<TDoubleConfirmModal | null>(null);
  const [selectedContacts, setSelectedContacts] = useState<{ id: string; type: string }[]>([]);
  const [selectedEmailTemplate, setSelectedEmailTempalte] = useState<{ label: string; value: string } | null>(null);
  const [clause, setClause] = useState('');
  useEffect(() => {
    if (!state.isParentApprovalModalOpen) {
      // reset state
      setSelectedContacts([]);
      // default modal
      setCurrentModal('A');
      setCurrentDoubleConfirmModal(null);
      setClause('');
      setSelectedEmailTempalte(null);
    }
  }, [state.isParentApprovalModalOpen]);
  // prefill content from previous approval
  useEffect(() => {
    if (!state.isParentApprovalModalOpen) return;
    if (!state.approvals || !get(state.approvals, [state.listRegion, 'emailMetadata'])) return;
    const { recipients, clauses, template } = get(state.approvals, [
      state.listRegion,
      'emailMetadata',
    ]) as ApplicationApproval['emailMetadata'];
    if (recipients.length) {
      setSelectedContacts(recipients);
    }
    if (clauses.length) {
      setClause(clauses[0].value);
    }
    if (template) {
      const templateOption = EMAIL_TEMPLATE_OPTIONS.find((o) => o.value === template);
      if (templateOption) setSelectedEmailTempalte(templateOption);
    }
  }, [state.approvals, state.listRegion, state.isParentApprovalModalOpen]);
  const onSelectContact = ({ id, type }: { id: string; type: string }) => {
    if (isContactSelected(selectedContacts, id, type)) {
      setSelectedContacts(selectedContacts.filter((o) => o.id !== id || o.type !== type));
    } else {
      setSelectedContacts([...selectedContacts, { id, type }]);
    }
  };

  return {
    selectedContacts,
    onSelectContact,
    currentModal,
    setCurrentModal,
    currentDoubleConfirmModal,
    setCurrentDoubleConfirmModal,
    selectedEmailTemplate,
    setSelectedEmailTempalte,
    clause,
    setClause,
  };
};

const ParentApprovalModal = () => {
  const { state, controller } = useContext(AppTrackerContext);
  const {
    selectedContacts,
    onSelectContact,
    currentModal,
    setCurrentModal,
    currentDoubleConfirmModal,
    setCurrentDoubleConfirmModal,
    selectedEmailTemplate,
    setSelectedEmailTempalte,
    clause,
    setClause,
  } = useParentApprovalManager();
  const userId = useUserId();
  const [isLoading, setIsLoading] = useSafeLoading(false);
  if (!state.isParentApprovalModalOpen) return null;
  const isModalAOpen = currentModal === 'A' && !currentDoubleConfirmModal;
  const isModalBOpen = currentModal === 'B' && !currentDoubleConfirmModal;
  const onClickConfirmOfModalA = () => setCurrentModal('B');
  const showConfirmCancelModal = () => {
    setCurrentDoubleConfirmModal('cancel');
  };
  const closeDoubleConfirm = () => setCurrentDoubleConfirmModal(null);
  const showConfirmSendModal = () => setCurrentDoubleConfirmModal('send');

  const onClickCheckEmailTempalte = () => {
    openLink(PARENT_APPROVAL_MODAL_LINKS.TEMPLATE);
  };
  const onClickCheckClause = () => {
    openLink(PARENT_APPROVAL_MODAL_LINKS.CLAUSE);
  };
  const onDoubleConfirmCancel = () => {
    controller.closeParentApprovalModal();
  };
  const onDoubleConfirmSend = async () => {
    if (isLoading) return;
    setIsLoading(true);
    // call api to send email
    const success = await controller.requestApplicationApproval({
      userId,
      region: state.listRegion,
      template: selectedEmailTemplate?.value,
      clause,
      recipients: selectedContacts as ApplicationApprovalRecipient[],
    });
    setIsLoading(false);
    if (success) {
      controller.closeParentApprovalModal();
      // lock editing of this region
      controller.setGrantEditingFalse(state.listRegion);
    }
  };
  const isUS = state.listRegion === 'US';
  return (
    <>
      {/* select contacts */}
      <SendParentForApproval
        selectedContacts={selectedContacts}
        onSelectContact={onSelectContact}
        isOpen={isModalAOpen}
        closeModal={showConfirmCancelModal}
        onClickConfirm={onClickConfirmOfModalA}
      />
      {!isUS && (
        // For UK, EU schools, require to select template
        <SelectEmailTempalteUK
          isOpen={isModalBOpen}
          closeModal={showConfirmCancelModal}
          onClickConfirm={showConfirmSendModal}
          clause={clause}
          onClauseChange={setClause}
          selectedTemplate={selectedEmailTemplate}
          onSelectTemplate={setSelectedEmailTempalte}
          onClickCheckTemplate={onClickCheckEmailTempalte}
          onClickClickableText={onClickCheckClause}
        />
      )}
      {isUS && (
        // For US, Other schools
        <DefineEmailContentUS
          isOpen={isModalBOpen}
          closeModal={showConfirmCancelModal}
          onClickConfirm={showConfirmSendModal}
          clause={clause}
          onClauseChange={setClause}
          onClickClickableText={onClickCheckEmailTempalte}
        />
      )}
      <Dialogue
        isOpen={currentDoubleConfirmModal === 'cancel'}
        closeModal={closeDoubleConfirm}
        title={'Are you sure?'}
        cancelButtonText={'Back to editing'}
        confirmButtonText={'Yes, Cancel'}
        onClickConfirm={onDoubleConfirmCancel}
      >
        <DoubleConfirmText>
          {
            'Are you sure you want to cancel? Any additional clauses you have written will not be saved once you cancel.'
          }
        </DoubleConfirmText>
      </Dialogue>
      <Dialogue
        isOpen={currentDoubleConfirmModal === 'send'}
        closeModal={closeDoubleConfirm}
        title={'Confirm Send'}
        cancelButtonText={'Back to editing'}
        confirmButtonText={'Yes, Send'}
        onClickConfirm={onDoubleConfirmSend}
        isLoading={isLoading}
      >
        <DoubleConfirmText>
          {
            'Please confirm you want to send the School Choice Confirmation Agreement email to the parents/primary contacts.'
          }
        </DoubleConfirmText>
      </Dialogue>
    </>
  );
};

export default ParentApprovalModal;
