import * as Styled from './styles';
import React from 'react';
import { Skeleton } from 'antd';

const SubjectPromotionLoadingView = () => (
  <Styled.PromotionRow wrap={false}>
    {Array.from(Array(4).keys()).map((_, index) => (
      <Styled.CardContainer key={index} span={24} lg={12} xl={9} xxl={7}>
        <Skeleton
          title={{ width: '100%' }}
          paragraph={{ width: '50%' }}
          round
          avatar={{ shape: 'circle', size: 'large' }}
          active
        />
      </Styled.CardContainer>
    ))}
  </Styled.PromotionRow>
);

export default SubjectPromotionLoadingView;
