import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import { withAppContext } from 'components/enhancers/AppContext';
import { confirmBooking, declineBooking, fetchEventByAction } from 'ducks/booking';
import { selectBookingAs } from 'ducks/user';
import { withFeatureFlag } from 'utils/withFeatureFlag';
import SessionHeader from './SessionHeader';

function mapDispatchToProps(dispatch, props) {
  const {
    session: { id },
    userId,
  } = props;

  return {
    confirmBooking: (isInThePast = false) => dispatch(confirmBooking(id, userId, isInThePast)),
    declineBooking: (reason) => dispatch(declineBooking(id, userId, reason)),
    changeBookingAs: (userId) => dispatch(selectBookingAs(userId, false)),
    fetchEventByAction: (data) => dispatch(fetchEventByAction(data)),
  };
}

export default compose(
  withAppContext,
  withRouter,
  connect(null, mapDispatchToProps),
)(withFeatureFlag(['FLAG_STUDENT_CENTER_TAB'], SessionHeader));
