import React from 'react';
import Link from 'components/molecules/Link';
import ProfileSection from 'components/organisms/ProfileSection';
import Body from 'components/atoms/typography/Body';

import css from './styles.scss';

export default function EditSubscriptionSection() {
  const onHrefClick = (event) => {
    event.preventDefault();
    window.open('https://crimsontutoring.myshopify.com/account');
  };

  const linkContent = (
    <Body className={css.innerContent}>
      To update your subscriptions, visit -
      <Link onClick={onHrefClick}> https://crimsontutoring.myshopify.com/account </Link>
    </Body>
  );

  return <ProfileSection icon="AutoRenew" title="Subscription" canEdit={false} displayComponent={linkContent} />;
}
