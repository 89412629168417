import { connect } from 'react-redux';
import { getGradeResultById } from 'selectors/gradeResult';
import { getSubjectsByCurriculums } from 'selectors/subjectCategory';
import GradesTable from './GradesTable';

const mapStateToProps = (state) => ({
  getSubjectsByCurriculums: (ids) => getSubjectsByCurriculums(ids)(state),
  getGradeResultById: (id) => getGradeResultById(id)(state),
});

export default connect(mapStateToProps)(GradesTable);
