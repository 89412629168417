import React, { useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import api from 'graphql/api/cga';

import Header from 'components/molecules/CGAHeader';
import 'design-system/styles/themes/default/index.scss';
import 'design-system/styles/index.scss';
import { statuses, sortByStudent } from '../../utils';
import css from '../styles.scss';
import ReportViewer from './ReportViewer';
import Toolbar from './Toolbar';
import SideBar from './Sidebar';
import { actions } from '../reducer';
import { ReportsContext } from '../context';

const ReportViewPage = (props) => {
  const {
    app: { isMobile },
  } = props;
  const { reportId } = useParams();

  const { state, dispatch, reportIntake, reportType } = useContext(ReportsContext);
  const { activeStudentReport, reportGroup, student, uiFlow } = state;

  const { id: studentId } = student || {};

  // Load once.
  useEffect(() => {
    api.getStreamIntakes().then(({ getCGAStreamIntakes: intakes }) => {
      dispatch(actions.SET_INTAKES, { intakes });
    });
  }, [dispatch]);

  // Load when report id changes.
  useEffect(() => {
    api.getCGAReport(reportId).then(({ getCGAReport: result }) => {
      const { studentReports, ...reportGroup } = result;

      const hasReports = (studentReports?.length || 0) > 0;

      dispatch(actions.SET_REPORT_GROUP, {
        reportGroup,
        studentReports: hasReports ? studentReports.sort(sortByStudent) : [],
      });
    });
  }, [dispatch, reportId]);

  // Load when report or student or status changes.
  useEffect(() => {
    if (!reportId || !studentId) return;

    api.getCGAStudentReport(reportId, studentId).then(({ getCGAStudentReport: result }) => {
      const { streams } = result;
      const studentStreamsMap = streams
        ? streams.map((stream) => ({
            ...stream,
            id: stream.subject_id,
            name: stream.subject_name,
            selected: stream.enabled,
          }))
        : [];

      dispatch(actions.SET_STUDENT_REPORT, {
        studentReport: result,
        studentStreams: studentStreamsMap,
        hiddenToolbar: !statuses.find((status) => status.value === result.generation.status),
      });
    });
  }, [dispatch, reportId, studentId]);

  return (
    <div className={css.body}>
      <Header title="Reports" />
      <div className="horiz">
        <SideBar isMobile={isMobile} />
        <div className={classnames('align-items', 'grow', css.rightPane)}>
          <div className="horiz align-items space-between">
            <div className="u-1-3">
              <h2 className="typography bread-crumb">Preview</h2>
            </div>
            {reportGroup && reportType && (
              <div className="u-1-3">
                <h2 className="typography bread-crumb">Type: {reportType.label}</h2>
              </div>
            )}
            {reportIntake && (
              <div className="u-1-3">
                <h2 className="typography bread-crumb">Intake: {reportIntake.display_name}</h2>
              </div>
            )}
          </div>
          <div className={classnames('align-items', 'center', 'horizontal', 'grow', css.previewWrapper)}>
            <div className={classnames('align-items', 'center', 'vertical', 'grow', css.preview, css.item)}>
              <ReportViewer student={student} studentReport={activeStudentReport} uxState={uiFlow} />
            </div>
          </div>
          <Toolbar isMobile={isMobile} reportId={reportId} />
        </div>
      </div>
    </div>
  );
};

ReportViewPage.propTypes = {
  app: PropTypes.object,
};

export default ReportViewPage;
