import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';
import { gql } from '@apollo/client';
import { createClient } from '../utils';

const { api } = getConfig();

const client = createClient(api.graphQLEndpoint);

const timeBlockType = gql`
  fragment timeBlockType on timeBlockType {
    userId
    start
    end
    duration
  }
`;

const availabilityType = gql`
  fragment availabilityType on timeBlockType {
    userId
    start
    end
  }
`;

const fetchAvailabilitiesByUserIds = gql`
  ${timeBlockType}
  query fetchAvailabilitiesByUserIds($userIds: [String]!, $start: String!, $end: String!) {
    available: availabilities(userIds: $userIds, start: $start, end: $end) {
      ...timeBlockType
    }
  }
`;

const fetchUnavailabilitiesByUserId = gql`
  ${availabilityType}
  query fetchUnavailabilitiesByUserId($userId: String!) {
    unavailable(userId: $userId) {
      userId
      start
      end
    }
  }
`;

const fetchCalculatedUnavailabilities = gql`
  ${timeBlockType}
  query fetchCalculatedUnavailabilities($userIds: [String]!, $start: String!, $end: String!) {
    unavailable: fetchCalculatedUnavailabilities(userIds: $userIds, start: $start, end: $end) {
      ...timeBlockType
    }
  }
`;

const fetchUnavailable = gql`
  query unavailable($userId: String!) {
    unavailable(userId: $userId) {
      id
      calendarId
      name
      description
      dayOfWeek
      start
      end
      timezone
    }
  }
`;

const setUnavailable = gql`
  mutation setUnavailable($userId: String!, $list: [unavailableInputType]!) {
    setUnavailable(userId: $userId, unavailableList: $list)
  }
`;

const fetchBlockCalendarByUserIds = gql`
  ${timeBlockType}
  query fetchBlockCalendarByUserIds($userIds: [String]!, $start: String!, $end: String!) {
    blockCalendar: blockCalendar(userIds: $userIds, start: $start, end: $end) {
      ...timeBlockType
    }
  }
`;
export default {
  fetchAvailabilitiesByUserIds: (userIds, start, end) =>
    client.query(fetchAvailabilitiesByUserIds, { userIds, start, end }).then((res) => {
      if (!res.available || res.errors) {
        return Promise.reject(new Error('Failed to fetch availabilities.'));
      }
      return res.available;
    }),
  fetchUnavailabilitiesByUserId: (userId) =>
    client.query(fetchUnavailabilitiesByUserId, { userId }).then((res) => {
      if (!res.unavailable || res.errors) {
        return Promise.reject(new Error('Failed to fetch unavailabilities.'));
      }
      return res.unavailable;
    }),
  fetchCalculatedUnavailabilities: (userIds, start, end) =>
    client.query(fetchCalculatedUnavailabilities, { userIds, start, end }).then((res) => {
      if (!res.unavailable || res.errors) {
        return Promise.reject(new Error('Failed to fetch unavailabilities.'));
      }
      return res.unavailable;
    }),
  fetchUnavailable: ({ userId }) => client.query(fetchUnavailable, { userId }),
  setUnavailable: ({ userId, list }) => {
    return client.query(setUnavailable, { userId, list });
  },
  fetchBlockCalendarByUserIds: (userIds, start, end) =>
    client.query(fetchBlockCalendarByUserIds, { userIds, start, end }).then((res) => {
      if (!res.blockCalendar || res.errors) {
        return Promise.reject(new Error('Failed to fetch availabilities.'));
      }
      return res.blockCalendar;
    }),
};
