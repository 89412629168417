
export default class ValueCheckPair {
  constructor(value, selected = false) {
    this.value = value;
    this.selected = typeof value.selected !== 'undefined' ? value.selected : selected;
  }
}

// returns an array of fresh new SelectListEntry array from whatever is passed.
export function valueCheckPairsFrom(items) {  
  return items.map(i => (i instanceof ValueCheckPair ? i : new ValueCheckPair(i)));
}