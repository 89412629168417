import React from 'react';
import { Text } from './index.style';
import { StyledTableCell } from '../../index.style';

type TextCellProps = {
  children: React.ReactNode;
  textColor?: string;
  textStyle?: React.CSSProperties;
  colSpan?: number;
};

const TextCell = ({ children, textColor, textStyle, colSpan }: TextCellProps) => {
  const style = textStyle ? textStyle : textColor ? { color: textColor } : undefined;
  return (
    <StyledTableCell colSpan={colSpan}>
      <Text style={style}>{children}</Text>
    </StyledTableCell>
  );
};

export default TextCell;
