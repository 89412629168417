import React, { useState, useMemo } from 'react';
import PanelWithTitle from '../PanelWithTitle';
import { ProgressBar, StudentsModal, Placeholder } from '../../components';
import { MeetingProgressBarContainer } from '../index.style';
import { useStaffDashboardContext, retriveUserInfoFromIds } from '../../helper';
import { difference } from 'lodash';
import { getAppYearStudents } from '../../alerts/util';
import LoadingView from './MeetingPanelLoadingView';

const MEETING_VALUE_LABEL_MAP = {
  meeting1: 'Meeting 1 not Completed',
  meeting2: 'Meeting 2 not Completed',
  meeting3: 'Meeting 3 not Completed',
  meeting4: 'Meeting 4 not Completed',
};
const MeetingsPanel = () => {
  const { state } = useStaffDashboardContext();
  const [isModalOpen, setModalOpen] = useState(false);
  const { students, milestoneMeeting, region } = state;
  const [studentIds, setStudentIds] = useState<string[]>([]);
  const [meetingValue, setMeetingValue] = useState<string | null>(null);

  const onCloseModal = () => {
    setModalOpen(false);
    setStudentIds([]);
    setMeetingValue(null);
  };
  const onClickProgressBar = (meetingV: string, _studentIds: string[]) => {
    if (!_studentIds.length) return;
    setStudentIds(_studentIds);
    setMeetingValue(meetingV);
    setModalOpen(true);
  };
  const MEETING_DATA = milestoneMeeting[region];
  const allAppYearUserIds = useMemo(() => getAppYearStudents(students).map((o) => o.userId), [students]);
  const modalStudents = useMemo(() => retriveUserInfoFromIds(students, studentIds), [students, studentIds]);
  const isCalculating = state.isCalculating['meeting'];
  const isLoading = state.isLoading;
  return (
    <PanelWithTitle title={'Milestone Meetings'}>
      {isLoading && <LoadingView />}
      {!isLoading && !!allAppYearUserIds.length && (
        <MeetingProgressBarContainer>
          {MEETING_DATA.map((meeting, idx) => {
            const allUserIds = !idx ? allAppYearUserIds : MEETING_DATA[idx - 1].userIds;
            return (
              <ProgressBar
                key={meeting.value}
                tintColor={meeting.color}
                label={meeting.label}
                current={meeting.userIds.length}
                total={allUserIds.length}
                onClick={() => onClickProgressBar('meeting' + (idx + 1), difference(allUserIds, meeting.userIds))}
              />
            );
          })}
        </MeetingProgressBarContainer>
      )}

      {!isLoading && !allAppYearUserIds.length && !isCalculating && (
        <Placeholder
          iconSize={90}
          containerStyle={{ flexDirection: 'column', gap: 20 }}
          iconName={'01'}
          placeholderText={'No valid data yet.'}
        />
      )}
      <StudentsModal
        students={modalStudents}
        title={meetingValue ? MEETING_VALUE_LABEL_MAP[meetingValue as keyof typeof MEETING_VALUE_LABEL_MAP] : undefined}
        isOpen={isModalOpen}
        onCancel={onCloseModal}
      />
    </PanelWithTitle>
  );
};

export default MeetingsPanel;
