import { normalize } from 'normalizr';

import { notesEntity, sessionEntity } from 'schema';
import sessionService from 'graphql/api/session';
import notesService from 'graphql/api/sessionNotes';
import * as Logger from '@crimson-education/browser-logger';
import { formatGraphQLRequestError } from 'utils/graphql';

export const FETCH_SESSION_LOG = 'session/FETCH_SESSION_LOG';
export const FETCH_SESSION_LOG_COMPLETE = 'session/FETCH_SESSION_LOG_COMPLETE';
export function fetchSessionLog(eventId) {
  return async (dispatch) => {
    dispatch({
      type: FETCH_SESSION_LOG,
      payload: { entities: normalize({ id: eventId, isLoading: true }, sessionEntity).entities },
    });

    const result = await sessionService.getSessionLogByEvent(eventId.toString());

    dispatch({
      type: FETCH_SESSION_LOG_COMPLETE,
      payload: {
        entities: normalize({ id: eventId, isLoading: false, sessionLog: result.sessionLog }, sessionEntity).entities,
      },
    });
  };
}

export const FETCH_SESSION_NOTES = 'session/FETCH_SESSION_NOTES';
export const FETCH_SESSION_NOTES_COMPLETE = 'session/FETCH_SESSION_NOTES_COMPLETE';
export function fetchNotes(eventId) {
  return async (dispatch) => {
    dispatch({
      type: FETCH_SESSION_NOTES,
      payload: { entities: normalize({ eventId, isLoading: true }, notesEntity).entities },
    });

    const result = await notesService.getNotesByEvent(eventId.toString());

    dispatch({
      type: FETCH_SESSION_NOTES_COMPLETE,
      payload: { entities: normalize({ ...result.sessionNote, eventId, isLoading: false }, notesEntity).entities },
    });
  };
}

export const SET_SESSION_NOTES = 'session/SET_SESSION_NOTES';
export const SET_SESSION_NOTES_COMPLETE = 'session/SET_SESSION_NOTES_COMPLETE';
export function setNotes(eventId, notes, version, overwrite) {
  return async (dispatch) => {
    try {
      if (notes.text && notes.text.trim() === '' && notes.html && notes.html.trim() !== '') {
        Logger.trackEvent({ message: 'suspicious session notes', metadata: { eventId } });
      }
      dispatch({
        type: SET_SESSION_NOTES,
        payload: { entities: normalize({ eventId, isUpdating: true }, notesEntity).entities },
      });

      const result = await notesService.setNotes(eventId.toString(), version, overwrite, notes);

      dispatch({
        type: SET_SESSION_NOTES_COMPLETE,
        payload: { entities: normalize({ ...result.setSessionNote, isUpdating: false }, notesEntity).entities },
      });
      return result.setSessionNote;
    } catch (err) {
      Logger.reportError(err);
      Logger.trackEvent({ message: 'failed to save session data', metadata: { eventId, err } });
      dispatch({
        type: SET_SESSION_NOTES,
        payload: { entities: normalize({ eventId, isUpdating: false }, notesEntity).entities },
      });
      return { error: true, message: formatGraphQLRequestError(err) };
    }
  };
}
