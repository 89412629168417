import React, { useMemo } from 'react';
import { Row, Column } from '../../../index.style';
import { Avatar, AlertItem, TitlePopover } from '../../../components';
import { StyledUserName, RegionText, BaseCell } from '../index.style';
import { useStaffDashboardContext } from '../../../helper';
import { StudentInList } from '../../../type';
export type NameCellProps = {
  student: StudentInList;
};

const NameCell = ({ student: { firstName, lastName, profileImageUrl, region, userId } }: NameCellProps) => {
  const { controller, state } = useStaffDashboardContext();
  const { userAlertsMap, alerts } = state;
  const _alerts = useMemo(() => {
    if (region === 'Other') return [];
    const alertValues = userAlertsMap[region]?.[userId];
    if (!alertValues) return [];
    return alerts[region].filter((o) => alertValues.includes(o.value));
  }, [userAlertsMap, alerts, userId, region]);
  return (
    <BaseCell style={{ paddingLeft: 15 }}>
      <Row style={{ position: 'relative' }} rowcenter justifycontent={'space-between'}>
        <TitlePopover
          title={'Alerts'}
          placement={'topLeft'}
          mouseEnterDelay={0.2}
          destroyTooltipOnHide
          getPopupContainer={(target) => target.parentElement!}
          disable={!_alerts.length}
          content={
            !!_alerts.length && (
              <Column gap={'12px'}>
                {_alerts.map((alert) => (
                  <AlertItem key={alert.value} title={alert.label} dotColor={alert.color} />
                ))}
              </Column>
            )
          }
        >
          <Row
            style={{ cursor: 'pointer' }}
            gap={'14px'}
            rowcenter
            onClick={() => controller.goToStudentProfile(userId)}
          >
            <Avatar
              showDot={!!_alerts.length}
              size={40}
              src={profileImageUrl}
              firstName={firstName}
              lastName={lastName}
            />
            <StyledUserName>{firstName + ' ' + lastName}</StyledUserName>
          </Row>
        </TitlePopover>
        <RegionText style={{ width: '25%' }}>{region}</RegionText>
      </Row>
    </BaseCell>
  );
};

export default NameCell;
