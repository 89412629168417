import MultiSelectFilter from './multiSelect.js';

export default class LanguageFilter extends MultiSelectFilter {
  constructor(...args) {
    super(...args);
    this.title = 'Language';
    this.name = 'Language';
  }

  apply(tutor) {
    if (!this.active()) {
      return true;
    }
    const { filtered, map } = this.state;
    const primary = tutor.primaryLanguage;
    return (
      (primary && filtered.some((language) => map[primary] === language)) ||
      filtered.some((language) => tutor.languages.find((secondary) => map[secondary] === language))
    );
  }

  data({ languages }) {
    const map = languages.reduce((o, v) => {
      o[v.id] = v.language;
      return o;
    }, {});
    this.setState({
      all: languages.map((l) => l.language),
      map,
    });
  }

  value() {
    return this.state.filtered;
  }

  simpleValue() {
    return this.value().join(', ');
  }
}
