import { createSelector } from 'reselect';

const gradeResult = (state) => state.get('gradeResult');

export const getAllGradeResults = createSelector(gradeResult, (gradeResult) => gradeResult.toJS());

export const getGradeResultById = (id) => {
  return createSelector(gradeResult, (gradeResult) => gradeResult.get(id).toJS());
};

export const getGradeResultByGradeId = (gradeId) => {
  return createSelector(gradeResult, (gradeResult) =>
    gradeResult.filter((grade) => grade.get('gradeId') === gradeId).toJS(),
  );
};
