import React from 'react';
import PropTypes from 'prop-types';

export default function Skype({ className }) {
  return (
    <svg
      className={className}
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path
          id="x"
          d="m19.1796875 13.4140625c.041016-.2988277.0703125-.6035153.0703125-.9140625 0-3.729492-3.020508-6.75-6.75-6.75-.3105465 0-.615234.02929725-.9140625.0703125-.6884767-.5126955-1.5380858-.8203125-2.4609375-.8203125-2.2792965 0-4.125 1.8457035-4.125 4.125 0 .9228517.307617 1.7724608.8203125 2.4609375-.04101525.2988285-.0703125.603516-.0703125.9140625 0 3.729492 3.020508 6.75 6.75 6.75.3105472 0 .6152348-.0292965.9140625-.0703125.6884767.5126955 1.5380858.8203125 2.4609375.8203125 2.2792972 0 4.125-1.8457028 4.125-4.125 0-.9228517-.307617-1.7724608-.8203125-2.4609375zm-6.6796875 3.5859375c-2.504883 0-3.943359-1.3125-3.943359-2.6015625 0-.65625.688476-.8671875.963867-.8671875.635742 0 .8466795.5185545.963867.7587892.6445312 1.3271483 1.265625 1.151367 1.8779295 1.2099608.5390625.0498045 1.8105472-.1875 1.8105472-1.1132812 0-.0908205.1376955-.6914063-.9580079-.9931643-.7001955-.1904295-1.9335938-.4248045-2.9501955-.8144528-.9550778-.3691409-1.5146483-1.125-1.5146483-2.1005857 0-.489258.24902325-2.478516 3.6328125-2.478516 2.2587892 0 3.462891 1.15722675 3.462891 2.1738285 0 .234375-.0908205.4394527-.2695313.6269527-.1787114.1845705-.6855472.4570313-1.239258.1025393-.1376954-.087891-.254883-.275391-.3925785-.515625-.1640624-.301758-.4365232-.8876955-1.6845697-.8876955-1.212891 0-1.5498052.5771483-1.5498052.8203125 0 .8085937 1.546875.978516 1.9628909 1.0869142 1.5146483.3896483 3.5771483.75 3.5771483 2.7919921 0 2.3466795-2.4404295 2.8007812-3.75 2.8007812z"
        />
        <mask id="y">
          <use fill="none" xlinkHref="#x" />
        </mask>
      </defs>
      <g fill="inherit" fillRule="evenodd">
        <use fill="inherit" fillRule="nonzero" xlinkHref="#x" />
        <g fill="inherit" mask="url(#y)">
          <path d="m0 0h24v24h-24z" />
        </g>
      </g>
    </svg>
  );
}

Skype.propTypes = {
  className: PropTypes.string,
};
