import React, { useState, useEffect, useMemo } from 'react';
import { Column, Row } from '../../../index.style';
import { CardContainer, Deadline, DueDayInfo } from './index.style';
import { AvatarGroup } from '../../components';
import Moment from 'moment';
import { APPLICATION_DEADLINE_ANIMATION_DURATION } from '../../constants';
import { AppYearStudent } from '../../../type';
import { getDeadlineTextAndColor } from '../../../util';

const ANIMATION_NAME = {
  forward: {
    out: 'fade-out-left',
    in: 'fade-in-right',
  },
  backward: {
    out: 'fade-out-right',
    in: 'fade-in-left',
  },
};
const CALENDAR_ICON = '/static/applicationTracker/staffDashboardCalendarIcon.png';
type DeadlineCardProps = {
  deadline: string;
  currentIndex: number;
  students: AppYearStudent[];
  initialIndex: number;
  actionType: 'forward' | 'backward' | null;
  isAnimating: boolean;
  onClick: () => void;
};
const DeadlineCard = ({
  deadline,
  currentIndex,
  initialIndex,
  isAnimating,
  actionType,
  students,
  onClick,
}: DeadlineCardProps) => {
  const [animationName, setAnimationName] = useState<string | undefined>(undefined);
  useEffect(() => {
    if (!isAnimating || !actionType) return;
    const isForwardClick = actionType === 'forward';
    const activeCard = currentIndex === initialIndex;
    if (activeCard) {
      setAnimationName(isForwardClick ? ANIMATION_NAME.forward.in : ANIMATION_NAME.backward.in);
    } else {
      setAnimationName(isForwardClick ? ANIMATION_NAME.forward.out : ANIMATION_NAME.backward.out);
    }
    const timer = setTimeout(() => {
      // setAnimationName(undefined);
    }, APPLICATION_DEADLINE_ANIMATION_DURATION);
    return () => {
      timer && clearTimeout(timer);
      // setAnimationName(undefined);
    };
  }, [currentIndex, isAnimating, initialIndex, actionType]);
  const _date = Moment(deadline);
  const isCurrent = currentIndex === initialIndex;
  const { text: deadlineText, color } = useMemo(
    () => getDeadlineTextAndColor({ deadline, isSubmitted: false, extraApplicationStatus: null }),
    [deadline],
  );
  return (
    <CardContainer
      style={{
        display: isAnimating || isCurrent ? undefined : 'none',
        position: isAnimating ? 'absolute' : 'relative',
        zIndex: isAnimating && !isCurrent ? 10 : 1,
      }}
      $animationName={animationName}
      onClick={onClick}
    >
      <img width={40} height={40} src={CALENDAR_ICON} alt={CALENDAR_ICON} />
      <Column style={{ flex: 1 }} gap={'20px'}>
        <Row rowcenter justifycontent={'space-between'}>
          <Deadline>{_date.format('MMM DD, YYYY')}</Deadline>
          <DueDayInfo style={{ color: color, background: color ? color + '26' : undefined }}>{deadlineText}</DueDayInfo>
        </Row>
        <AvatarGroup
          avatars={students.map(({ firstName, lastName, profileImageUrl, userId }) => ({
            firstName,
            lastName,
            userId,
            avatar: profileImageUrl,
          }))}
        />
      </Column>
    </CardContainer>
  );
};

export default DeadlineCard;
