import React from 'react';
import { useMissionControlUpdatesContext } from '../helper';
import { StyledButton } from './style';

const GetUpdatesButton = () => {
  const { controller } = useMissionControlUpdatesContext();
  return <StyledButton onClick={controller.openUpdatesModal}>{'Get updates from Mission Control'}</StyledButton>;
};

export default GetUpdatesButton;
