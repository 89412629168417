import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { updateMeta } from 'ducks/meta';
import componentKeys from 'constants/componentKeys';
import { selectUserProfile, getCalScroll } from 'selectors/meta';
import { getBookingAsUserCalendar, getBookingWithUsersCalendar } from 'selectors/calendarDay';

import CalendarGrid from './CalendarGrid';

function CalendarGridContainer(props) {
  return <CalendarGrid {...props} />;
}

CalendarGridContainer.displayName = 'CalendarGridContainer';

CalendarGridContainer.propTypes = {
  selectedScroll: PropTypes.number.isRequired,
  updateScroll: PropTypes.func.isRequired,
  onAvailabilityClicked: PropTypes.func.isRequired,
  onBookingClicked: PropTypes.func.isRequired,
  bookingAsUserCalendar: PropTypes.object.isRequired,
  bookingWithUser: ImmutablePropTypes.list.isRequired,
};

export default connect(
  (state) => ({
    selectedScroll: getCalScroll(state),
    userProfile: selectUserProfile(state),
    bookingAsUserCalendar: getBookingAsUserCalendar(state),
    bookingWithUser: getBookingWithUsersCalendar(state),
  }),
  (dispatch) => ({
    updateScroll: (newHeight) => dispatch(updateMeta(componentKeys.CALENDAR_SCROLL, newHeight)),
    updateScrollHeight: (newHeight) => dispatch(updateMeta(componentKeys.CALENDAR_SCROLL_HEIGHT, newHeight)),
  }),
)(CalendarGridContainer);
