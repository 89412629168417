import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './styles.scss';
import { handleEnter } from '../../../utils/accessibility';

export const FILTER_ICONS = {
  NONE: css.noIcon,
  SELECT: 'zmdi-chevron-down',
  SEARCH: 'zmdi-search',
};

export default function FilterTag(props) {
  const { className, label, value, displayValue, onClick, onClear, dataTestId } = props;

  if (!value) {
    return (
      <div
        role="button"
        tabIndex={0}
        data-ga-label={`Filter - ${label}`}
        data-test-id={dataTestId}
        className={classNames(css.filterTagEmpty, className)}
        onClick={onClick}
        onKeyDown={handleEnter(onClick)}
      >
        <div>{label}</div>
        <i className={classNames('zmdi', props.icon)} />
      </div>
    );
  }

  return (
    <div data-test-id={dataTestId} className={classNames(css.filterTag, className)}>
      <div
        role="button"
        tabIndex={0}
        data-ga-label={`Filter - ${label}`}
        onClick={onClick}
        onKeyDown={handleEnter(onClick)}
      >
        {displayValue || value}
      </div>
      <div
        role="button"
        tabIndex={0}
        data-ga-label={`Clear filter - ${label}`}
        onClick={onClear}
        onKeyDown={handleEnter(onClear)}
      >
        <i className="zmdi zmdi-close" />
      </div>
    </div>
  );
}

FilterTag.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  onClear: PropTypes.func,
  value: PropTypes.string,
  displayValue: PropTypes.string,
  label: PropTypes.string,
  dataTestId: PropTypes.string,
  icon: PropTypes.string,
};

FilterTag.defaultProps = {
  label: 'Label',
  icon: FILTER_ICONS.NONE,
};
