import moment from 'moment';
import { BookingStatusTypes } from '@crimson-education/common-config';
import { bookingSummaryModes } from 'utils/bookingSummary';

import TextResources from './resources';

const FooterType = {
  New: 'New',
  SenderEditDelete: 'SenderEditDelete',
  ReceiverConfirm: 'ReceiverConfirm',
  ReceiverConfirmInThePast: 'ReceiverConfirmInThePast',
  ReceiverDelete: 'ReceiverDelete',
  View: 'View',
};

const BookingFormStatus = {
  New: 'New',
  Cancelled: 'Cancelled',
  InThePast: 'InThePast',
  InThePastReceiverPendingBookFor: 'InThePastReceiverPendingBookFor', // For a user who can book for others, can confirm a booking in the past.

  SenderPendingBookFor: 'SenderPendingBookFor',
  SenderPending: 'SenderPending',

  SenderConfirmedInProgress: 'SenderConfirmedInProgress',
  SenderConfirmedBookFor: 'SenderConfirmedBookFor',
  SenderConfirmed: 'SenderConfirmed',

  ReceiverPendingBookFor: 'ReceiverPendingBookFor',
  ReceiverPending: 'ReceiverPending',

  ReceiverConfirmedBookFor: 'ReceiverConfirmedBookFor',
  ReceiverConfirmed: 'ReceiverConfirmed',
};

function getBookingFormStatus(booking, canBookFor) {
  if (booking) {
    const isCreator = booking.creatorUserId !== booking.otherUserId;

    const minutesUntilEndOfSession = moment.duration(moment(booking.end).diff(moment())).asMinutes();
    const sessionLength = moment.duration(moment(booking.end).diff(booking.start)).asMinutes();

    const isInThePast = minutesUntilEndOfSession < 0;
    const isInProgress = !isInThePast && minutesUntilEndOfSession < sessionLength;

    if (booking.status === BookingStatusTypes.CANCELLED) {
      return BookingFormStatus.Cancelled;
    }

    if (isInThePast) {
      if (!isCreator && booking.status === BookingStatusTypes.TENTATIVE) {
        return BookingFormStatus.InThePastReceiverPendingBookFor;
      }
      return BookingFormStatus.InThePast;
    }

    if (isInProgress && isCreator && booking.status === BookingStatusTypes.CONFIRMED) {
      return BookingFormStatus.SenderConfirmedInProgress;
    }

    if (isCreator) {
      if (booking.status === BookingStatusTypes.TENTATIVE) {
        if (canBookFor) {
          return BookingFormStatus.SenderPendingBookFor;
        }
        return BookingFormStatus.SenderPending;
      } else if (booking.status === BookingStatusTypes.CONFIRMED) {
        if (canBookFor) {
          return BookingFormStatus.SenderConfirmedBookFor;
        }
        return BookingFormStatus.SenderConfirmed;
      }
    }

    if (!isCreator) {
      // this if not, for seeing logic clearly
      if (booking.status === BookingStatusTypes.TENTATIVE) {
        if (canBookFor) {
          return BookingFormStatus.ReceiverPendingBookFor;
        }
        return BookingFormStatus.ReceiverPending;
      } else if (booking.status === BookingStatusTypes.CONFIRMED) {
        if (canBookFor) {
          return BookingFormStatus.ReceiverConfirmedBookFor;
        }
        return BookingFormStatus.ReceiverConfirmed;
      }
    }
  }

  return BookingFormStatus.New;
}

const getBookingFormSetting = (booking, canBookFor) => {
  const bookingFormStatus = getBookingFormStatus(booking, canBookFor);

  let header = null;
  let message = null;
  let readOnly = false;
  let footerType = null;
  let summaryMode = null;
  switch (bookingFormStatus) {
    case BookingFormStatus.New:
      header = TextResources.BookingSession;
      footerType = FooterType.New;
      break;
    case BookingFormStatus.Cancelled:
      header = TextResources.Cancelled;
      message = TextResources.CancelledBooking;
      readOnly = true;
      footerType = FooterType.View;
      break;
    case BookingFormStatus.InThePast:
      header = TextResources.ViewSession;
      footerType = FooterType.View;
      readOnly = true;
      summaryMode = bookingSummaryModes.VIEW_ONLY;
      break;
    case BookingFormStatus.SenderPendingBookFor:
    case BookingFormStatus.SenderPending:
      header = TextResources.EditSession;
      message = TextResources.PendingBookingWarning;
      footerType = FooterType.SenderEditDelete;
      summaryMode = bookingSummaryModes.SENDER_TENTATIVE_EDIT;
      break;
    case BookingFormStatus.SenderConfirmedInProgress:
      header = TextResources.ViewSession;
      footerType = FooterType.View;
      message = TextResources.BookingInProgressWarning;
      readOnly = true;
      summaryMode = bookingSummaryModes.VIEW_ONLY;
      break;
    case BookingFormStatus.SenderConfirmedBookFor:
    case BookingFormStatus.SenderConfirmed:
      header = TextResources.EditSession;
      message = TextResources.UpdateBookingWarning;
      footerType = FooterType.SenderEditDelete;
      summaryMode = bookingSummaryModes.SENDER_CONFIRMED_EDIT;
      break;
    case BookingFormStatus.InThePastReceiverPendingBookFor:
      header = TextResources.ConfirmSession;
      message = TextResources.TutorToConfirm;
      readOnly = true;
      footerType = FooterType.ReceiverConfirmInThePast;
      summaryMode = bookingSummaryModes.RECEIVER_TENTATIVE_VIEW_ONLY;
      break;
    case BookingFormStatus.ReceiverPendingBookFor:
    case BookingFormStatus.ReceiverPending:
      header = TextResources.ConfirmSession;
      message = TextResources.TutorToConfirm;
      readOnly = true;
      footerType = FooterType.ReceiverConfirm;
      summaryMode = bookingSummaryModes.RECEIVER_TENTATIVE_EDIT;
      break;
    case BookingFormStatus.ReceiverConfirmedBookFor:
    case BookingFormStatus.ReceiverConfirmed:
      header = TextResources.DeleteSession;
      message = TextResources.DeleteConfirmedSessionWarningTutor;
      readOnly = true;
      footerType = FooterType.ReceiverDelete;
      summaryMode = bookingSummaryModes.RECEIVER_CONFIRMED_EDIT;
      break;
    default:
      break;
  }

  return { header, message, readOnly, footerType, summaryMode };
};

export default {
  getBookingFormSetting,
};
