import React from 'react';
import { Row } from '../index.style';
import ListPlaceholderIcon from 'components/atoms/graphics/ApplicationTracker/listPlaceholderIcon';
import { PlaceholderText } from './index.style';

type PlaceholderProps = {
  text: string;
};
const Placeholder = ({ text }: PlaceholderProps) => {
  return (
    <Row rowcenter gap={'12px'} style={{ marginTop: 40 }}>
      <ListPlaceholderIcon />
      <PlaceholderText>{text}</PlaceholderText>
    </Row>
  );
};

export default Placeholder;
