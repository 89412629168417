import React, { useLayoutEffect, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import { getCurrentUserJS } from 'selectors/user';
import * as zoid from 'zoid/dist/zoid.frameworks.js';
import { getEnvironmentConfig } from '@crimson-education/common-config';
import { AUTH_ACCESS_TOKEN, AUTH_ID_TOKEN, AUTH_EXPIRATION_DATE } from '../../../utils/auth/auth';
import { history } from '../../../app';
import _ from 'lodash';

const { environment } = getEnvironmentConfig();

const environmentHostnames = {
  local: 'http://localhost:3002',
  test: 'https://test.community.crimsoneducation.org',
  staging: 'https://staging.community.crimsoneducation.org',
  production: 'https://community.app.crimsoneducation.org',
  prod: 'https://community.app.crimsoneducation.org',
};

const communityDomain = environmentHostnames[environment];

const { protocol, hostname, port } = window.location;
const isLocal = hostname === 'localhost';
// store all the registered event callbacks.
const eventEmitters = {};
// store the community current url
let communityUrl;
// tag the url change original source from iframe of navbar.
// if it's true, the iframe will not refresh
let innerChange = false;

const domain = `${protocol}//${hostname}${port ? `:${port}` : ''}`;

let isLogin = false;

const CommunityComponent = zoid.create({
  tag: 'community-component',
  url: ({ props }) => {
    if (props && props.history) {
      const { location } = props.history;
      const { pathname } = location;
      return communityDomain + pathname.replace('/community', '') + location.search;
    }
    return communityDomain;
  },
  dimensions: {
    width: '100%',
    height: '99vh',
  },
});

const CommunityReactComponent = CommunityComponent.driver('react', {
  React,
  ReactDOM,
});

const checkLogin = () => {
  return isLogin;
};

// registerEventEmitter
// a function to add more callbacks from iframe
const registerEventEmitter = (eventName, callback) => {
  eventEmitters[eventName] = callback;
};

// invoke the event callbacks
// conditionally, if the innerChange is true, we should do something special
const performEvent = (eventName, data) => {
  if (innerChange) {
    innerChange = false;
    return;
  }
  eventEmitters[eventName] && eventEmitters[eventName](data);
};

const onMessage = ({ event, data }) => {
  if (event === 'open') {
    window.open(data, '_blank');
    return;
  }
  // accept the route event from the url routing change of the iframe.
  if (event === 'route') {
    if (communityUrl === data) {
      return;
    }
    innerChange = true;
    communityUrl = data;
    history.push(`/community${data}`);
  }
};

function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join(''),
  );
  return JSON.parse(jsonPayload);
}
export default function Community() {
  const tokens = {
    access_token: localStorage.getItem(AUTH_ACCESS_TOKEN),
    id_token: localStorage.getItem(AUTH_ID_TOKEN),
    expires_date: localStorage.getItem(AUTH_EXPIRATION_DATE),
  };
  const { sub } = parseJwt(tokens.id_token);
  const userId = sub.replace('|', '-');
  useLayoutEffect(() => {
    fetch(`${isLocal ? 'http://localhost:3001' : communityDomain}/auth/custom/callback?userId=${userId}`, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
      redirect: 'manual',
    }).then(() => {
      isLogin = true;
    });
  }, [tokens.access_token, userId]);

  useEffect(() => {
    return () => {
      fetch(`${isLocal ? 'http://localhost:3001' : communityDomain}/auth/logout?userId=${userId}`, {
        method: 'GET',
        credentials: 'include',
        mode: 'cors',
        redirect: 'manual',
      }).then(() => {
        isLogin = false;
      });
    };
  }, [userId]);

  const user = useSelector(getCurrentUserJS);

  // fix about the navbar button doesn't work issue.
  useEffect(() => {
    // console.log('current', history.location.pathname);
    performEvent('refresh', `${history.location.pathname}${history.location.search}`);
  });

  return (
    <CommunityReactComponent
      registerEventEmitter={registerEventEmitter}
      checkLogin={checkLogin}
      tokens={tokens}
      history={history}
      onMessage={onMessage}
      domain={domain}
      userId={user.userId}
      user={_.pick(user, ['userId', 'firstName', 'lastName', 'email'])}
    />
  );
}
