import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { PermissionTypes } from '@crimson-education/common-config';
import { selectUserPermissions, selectUserRoles, selectUserId, getMetaItem } from 'selectors/meta';
import componentKeys from 'constants/componentKeys';
import { updateMeta } from 'ducks/meta';
import { getLoginUser } from 'selectors/user';
import { getAllPeopleCounts } from 'selectors/aggregate';
import { hasNewFriend } from 'selectors/globalState';
import AuthorisationCheck from 'components/wrappers/AuthorisationCheck';
import { useLocation } from 'react-router-dom';
import OurPeople from './OurPeople';

function OurPeopleContainer(props) {
  const { pathname } = useLocation();
  const {
    loginUser,
    loginUserRoles,
    loginUserPermissions,
    loginUserId,
    userCountQuery,
    activeTab,
    setActiveTab,
    setActivePage,
    hasNewFriend,
  } = props;
  return (
    <OurPeople
      loginUser={loginUser}
      loginUserRoles={loginUserRoles}
      loginUserPermissions={loginUserPermissions}
      loginUserId={loginUserId}
      userCountQuery={userCountQuery}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      setActivePage={setActivePage}
      hasNewFriend={hasNewFriend}
      showMyFriendsTab={pathname.endsWith('my-friends')}
    />
  );
}

OurPeopleContainer.propTypes = {
  loginUser: ImmutablePropTypes.map,
  loginUserId: PropTypes.string.isRequired,
  loginUserRoles: PropTypes.array.isRequired,
  loginUserPermissions: PropTypes.array.isRequired,
  userCountQuery: PropTypes.object.isRequired,
  activeTab: PropTypes.number,
  setActiveTab: PropTypes.func.isRequired,
  setActivePage: PropTypes.func.isRequired,
  hasNewFriend: PropTypes.number,
};

const withAuthorisation = AuthorisationCheck(PermissionTypes.VIEW_OUR_PEOPLE);

export default withAuthorisation(
  connect(
    (state) => ({
      loginUser: getLoginUser(state),
      loginUserId: selectUserId(state),
      loginUserRoles: selectUserRoles(state),
      loginUserPermissions: selectUserPermissions(state),
      userCountQuery: getAllPeopleCounts(state),
      activeTab: getMetaItem(componentKeys.ACTIVE_TAB)(state),
      hasNewFriend: hasNewFriend(state),
    }),
    (dispatch) => ({
      setActiveTab: (tabId) => dispatch(updateMeta(componentKeys.ACTIVE_TAB, tabId)),
      setActivePage: (pageId) => dispatch(updateMeta(componentKeys.ACTIVE_PAGE, pageId)),
    }),
  )(OurPeopleContainer),
);
