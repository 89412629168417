import Popover from '@material-ui/core/Popover';
import styled from 'styled-components';
import ToggleSwitch from 'components/molecules/ToggleSwitch';

export const StyledSwitch = styled(ToggleSwitch)`
  box-shadow: none;
`;

export const StyledPopover = styled(Popover)`
  pointer-events: none;
`;

export const PopoverText = styled.div`
  font-weight: 500;
  font-size: 12px;
  font-family: 'Montserrat';
  line-height: 15px;
  color: #ffffff;
`;

export const ModalText = styled.span`
  font-weight: 500;
  font-size: 14px;
  font-family: 'Montserrat';
  line-height: 20px;
  color: #1d1e2b;
  margin: 40px 0;
  white-space: pre-wrap;
`;
