import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { contractStatusTypes } from '@crimson-education/common-config';
import TutorCapacityDisplay from 'components/molecules/TutorCapacityDisplay';
import AverageRatingBadge from 'components/molecules/AverageRatingBadge';
import { Body, Caption } from 'components/atoms/typography';
import Tag, { TAG_STYLE } from 'components/molecules/Tag';
import Card from 'components/molecules/Card';
import IconInCircle from 'components/molecules/IconInCircle';
// import TutorMatch from './TutorMatch';
import css from './TutorCard.scss';
import { tutorCostIndicator } from '../InviteTutor';
import { handleEnter } from '../../../../utils/accessibility';

const statusDisplayStrings = {
  [contractStatusTypes.ACTIVE]: 'ALLOCATED',
  [contractStatusTypes.DECLINED]: 'DECLINED',
  [contractStatusTypes.PENDING]: 'PENDING',
};

const statusDisplayStyles = {
  [contractStatusTypes.ACTIVE]: TAG_STYLE.SUCCESS,
  [contractStatusTypes.DECLINED]: TAG_STYLE.WARNING,
  [contractStatusTypes.PENDING]: TAG_STYLE.STROKE_BLUE,
};

const getDollarScale = (subjectPayRate, payRatePercentiles) => {
  let value;
  switch (tutorCostIndicator(subjectPayRate, payRatePercentiles)) {
    case 'high':
      value = '$$$';
      break;

    case 'above average':
      value = '$$';
      break;

    case 'average':
      value = '$';
      break;

    default:
      value = null;
      break;
  }

  // Calculate Pay Scale
  return <p title={`$${subjectPayRate} USD/hour`}>{value}</p>;
};

const TutorCard = ({ tutor, onClick, selected, onSelect, highlighted, subjectPayRate, subjectPayPercentiles }) => {
  if (!tutor || !tutor.tutorInfo) {
    return null;
  }
  const canSelect = !tutor.invitation || tutor.invitation.status === contractStatusTypes.DECLINED;

  // Show NEW Status when onboarded less than 8 weeks ago.
  const cutoffNew = moment().add(-8, 'week');
  const onboarded = tutor.tutorInfo.onboardedAt && moment(tutor.tutorInfo.onboardedAt);
  const isRecentlyOnboarded = (onboarded && onboarded.diff(cutoffNew) >= 0) || false;

  // const tutorWasInvited = !!(tutor.invitation && tutor.invitation.status);
  // const tutorPreviouslyDeclined = !!(tutor.invitation && tutor.invitation.status === contractStatusTypes.DECLINED);

  return (
    <Card selected={highlighted} className={css.card}>
      <div
        role="button"
        tabIndex={0}
        data-ga-label="Tutor Card"
        onClick={onClick}
        onKeyDown={handleEnter(onClick)}
        className={css.tutor}
      >
        <Body bold className={css.name}>
          <div className={css.fullName}>{tutor.fullName}</div>
          {isRecentlyOnboarded && <div className={css.markNew}>NEW</div>}
        </Body>
        {canSelect && (
          <div className={css.add} data-test-id={`${tutor.email}-add`}>
            {selected ? (
              <IconInCircle onClick={onSelect} primary icon="Check" />
            ) : (
              <IconInCircle onClick={onSelect} secondary icon="Add" />
            )}
          </div>
        )}
        <div className={css.rating}>
          <AverageRatingBadge averageRating={tutor.tutorInfo.averageRating} />
          <Body>
            {tutor.tutorInfo.studentCount} Active Student{tutor.tutorInfo.studentCount !== 1 ? 's' : ''}
          </Body>
        </div>
        <div className={css.availability}>
          {subjectPayRate && (
            <div className={css.payRate}>{getDollarScale(subjectPayRate.rateInUSD, subjectPayPercentiles)}</div>
          )}
          <TutorCapacityDisplay iconPosition="after" type={tutor.tutorInfo.capacity} />
        </div>
        {/* <div className={css.match}><TutorMatch percent={0} /></div> */}
        {tutor.invitation && tutor.invitation.status && (
          <div className={css.footer}>
            <Tag
              text={statusDisplayStrings[tutor.invitation.status]}
              style={statusDisplayStyles[tutor.invitation.status]}
            />
            <Caption>{tutor.invitation.timestamp ? moment(tutor.invitation.timestamp).format('ll') : ''}</Caption>
          </div>
        )}
      </div>
    </Card>
  );
};

TutorCard.propTypes = {
  tutor: PropTypes.object.isRequired,
  subjectPayRate: PropTypes.object,
  subjectPayPercentiles: PropTypes.array,
  onClick: PropTypes.func,
  onSelect: PropTypes.func,
  selected: PropTypes.bool,
  highlighted: PropTypes.bool,
};

export default TutorCard;
