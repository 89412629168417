import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Spinner from 'react-spinkit';

import fontColour from 'components/atoms/typography/fontColour.scss';
import css from './style.scss';

export default function LoadingIndicator(props) {
  return (
    <Spinner
      name="ball-beat"
      overrideSpinnerClassName={fontColour[props.colour]}
      className={classNames(css.spinner, props.className)}
    />
  );
}

LoadingIndicator.defaultProps = {
  colour: 'hint',
};

LoadingIndicator.propTypes = {
  colour: PropTypes.string,
  className: PropTypes.string,
};
