import React from 'react';
import Skeleton from 'antd/lib/skeleton';
import { Column } from '../../index.style';

const LoadingView = () => {
  return (
    <Column style={{ marginTop: 50 }}>
      <Skeleton active />
    </Column>
  );
};

export default LoadingView;
