import React from 'react';
import PropTypes from 'prop-types';
import css from './layout.scss';

const Container = ({ children }) => <div className={css.container}>{children}</div>;
const Header = ({ children }) => <div className={css.header}>{children}</div>;
const Sidebar = ({ children, header, headerClass }) => (
  <div className={css.sidebarContainer}>
    {header && <div className={headerClass}>{header}</div>}
    <div className={css.sidebarScroll}>
      <div className={css.sidebar}>{children}</div>
    </div>
  </div>
);
const Main = ({ children }) => <div className={css.main}>{children}</div>;

Container.propTypes = { children: PropTypes.node.isRequired };
Header.propTypes = { children: PropTypes.node.isRequired };
Sidebar.propTypes = { children: PropTypes.node.isRequired, header: PropTypes.node, headerClass: PropTypes.string };
Main.propTypes = { children: PropTypes.node.isRequired };

export default { Container, Header, Sidebar, Main };
