import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FileRefType, AppEssayTypes } from '@crimson-education/common-config';
import { getViewableUser } from 'selectors/meta';
import uploadedFileService from 'graphql/api/uploadedFile';
import { AppTrackerContext } from '../viewController';
import { Column, Row } from '../index.style';
import { Container, TopRow, StyledRadio, RadioLabelText, StyledPrimaryButton } from './index.style';
import UploadedFileList, { UploadEssay } from './UploadedFileList';
import Dialogue from '../Dialogue';

const FILE_SIZE_LIMIT = 25000000;
const FILE_SIZE_ERROR = `This file is too big. Maximum file size is ${FILE_SIZE_LIMIT / 1000000}MB.`;
const FILE_NUM_LIMIT = 15;
const FILE_NUM_ERROR = 'You can only upload up to 15 files.';

const Documents: React.FC = () => {
  const userId = useSelector(getViewableUser);
  const { state, controller } = useContext(AppTrackerContext)!;
  const [fileList, setFileList] = useState<UploadEssay[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [selectedEssayType, setSelectedEssayType] = useState(AppEssayTypes.COMMON_APP_PDF);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    controller.getFilesByTypeAndRefId(userId, FileRefType.APPLICATION, '');
  }, []);

  useEffect(() => {
    if (state.essays.length) {
      setFileList(
        state.essays.map((essay) => ({
          uid: essay.id as string,
          name: essay.name || 'unknown',
          fileName: essay.name || 'unknown',
          size: essay.size || 0,
          type: essay.type || 'unknown',
          essayType: essay.tag || '',
        })),
      );
    }
  }, [state.essays]);

  const uploadFile = async (file: File, essayType: string) => {
    if (fileList.length >= FILE_NUM_LIMIT) {
      controller.showError(FILE_NUM_ERROR);
      return;
    }
    if (file.size > FILE_SIZE_LIMIT) {
      controller.showError(FILE_SIZE_ERROR);
      return;
    }

    const { name, type, size } = file;

    try {
      const res = await uploadedFileService.getApplicationTrackerUploadUrl(userId, essayType, name, type);
      const { putUrl, key } = res.getApplicationTrackerUploadUrl;
      await fetch(putUrl, {
        method: 'PUT',
        body: file,
      });
      await uploadedFileService.saveFileUplRef(
        key,
        ' ',
        name,
        `${size}`,
        type,
        FileRefType.APPLICATION,
        userId,
        essayType,
      );
      await controller.getFilesByTypeAndRefId(userId, FileRefType.APPLICATION);
    } catch (err: any) {
      controller.showError(`Failed to upload essay: ${err.message}`);
    }
  };

  const onChangeFiles = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = [...(e.target.files || [])];
    if (!files.length) return;
    if (fileList.length >= FILE_NUM_LIMIT) {
      controller.showError(FILE_NUM_ERROR);
      return;
    }
    setIsUploading(true);
    try {
      await Promise.all(
        files.map(async (file) => {
          await uploadFile(file, selectedEssayType);
        }),
      );
      e.target.files = null;
      e.target.value = '';
    } catch (e) {
      setIsUploading(false);
    }
    setIsModalOpen(false);
    setIsUploading(false);
  };

  const onCloseModal = () => {
    setIsModalOpen(false);
  };

  const onModalConfirm = () => {
    document.getElementById('fileUploadInput')?.click();
  };

  return (
    <Container>
      <form>
        <input id="fileUploadInput" type="file" multiple hidden onChange={onChangeFiles} />
      </form>
      <Dialogue
        title={'Select a file type'}
        isOpen={isModalOpen}
        closeModal={onCloseModal}
        isLoading={isUploading} // loading will disable buttons
        onClickConfirm={onModalConfirm}
        confirmButtonText={'Next'}
      >
        <Column style={{ margin: '30px 0 40px 15px' }} rowcenter>
          {Object.values(AppEssayTypes).map((value: string) => {
            return (
              <Row
                style={{ cursor: 'pointer', margin: '6px 0' }}
                rowcenter
                key={value}
                onClick={() => setSelectedEssayType(value)}
              >
                <StyledRadio color={'default'} checked={value === selectedEssayType} />
                <RadioLabelText>{value}</RadioLabelText>
              </Row>
            );
          })}
        </Column>
      </Dialogue>

      <TopRow>
        <div className="left-side">
          <img className="folder-icon" alt="folder-icon" src="/static/applicationTracker/essayUpload/folder.svg" />
          <div className="title-container">
            <div className="title">Your application folder</div>
            <div className="sub-title">Upload your common app pdf, essay</div>
          </div>
        </div>
        <StyledPrimaryButton onClick={() => setIsModalOpen(true)}>Upload</StyledPrimaryButton>
      </TopRow>

      <UploadedFileList
        fileList={fileList}
        deleteFile={async (id: string) => {
          controller.deleteFileByFileId(id);
        }}
        uploading={isUploading}
      />
    </Container>
  );
};

export default Documents;
