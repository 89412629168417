import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';
import { Description } from 'components/atoms/icons';
import { getBearer } from 'utils/auth';
import css from './style.scss';
import { asButton, handleEnter } from '../../../utils/accessibility';

const config = getConfig();

function FileTypeIcon({ file }) {
  const fileType = file.type;
  let fileSvgName = null;
  if (fileType.endsWith('pdf')) {
    fileSvgName = 'pdf';
  } else if (fileType.endsWith('presentation')) {
    fileSvgName = 'slides';
  } else if (fileType.endsWith('sheet') || fileType.endsWith('csv')) {
    fileSvgName = 'sheets';
  } else if (fileType.endsWith('document')) {
    fileSvgName = 'doc';
  }
  if (fileSvgName != null) {
    const imgFullPath = `/static/${fileSvgName}.svg`;
    return <img src={imgFullPath} alt={fileSvgName} className={css.fileIcon} />;
  }
  return <Description fontSize="large" style={{ color: '#518ef8' }} />;
}
FileTypeIcon.propTypes = {
  file: PropTypes.object.isRequired,
};
export default class MessageFile extends Component {
  size(file) {
    if (file.size > 1024) {
      if (file.size / 1024 > 1024) {
        return `${(file.size / 1024 / 1024).toFixed()} MB`;
      }
      return `${(file.size / 1024).toFixed()} KB`;
    }
    return `${file.size} B`;
  }

  downloadFile(file) {
    const { downloadMessageFile } = this.props;
    if (config.dulwich) {
      const downloadUrl = `${config.api.endpoint}/files/${file.id}?token=${getBearer()}`;
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.target = '_blank';
      a.download = file.filename;
      a.click();
    } else {
      downloadMessageFile(file.id, file.source);
    }
  }
  render() {
    const { file } = this.props;
    return (
      <div className={css.wrapper} {...asButton(() => this.downloadFile(file))}>
        <FileTypeIcon file={file} />
        <div className={css.info}>
          <div className={css.details}>
            <span className={css.fileName}>{file.name}</span>
          </div>
          <div className={css.details}>
            <span className={css.subInfo}>Click to Download </span>
            <div className={css.oval} />
            <span className={css.subInfo}>{this.size(file)}</span>
          </div>
        </div>
      </div>
    );
  }
}

MessageFile.propTypes = {
  file: PropTypes.object.isRequired,
  downloadMessageFile: PropTypes.func,
};
