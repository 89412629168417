export const EMAIL_TEMPLATE_OPTIONS = [
  {
    value: 'template1',
    label: 'Template 1',
    detail: 'For students applying with <strong>predicted</strong> grades.',
  },
  {
    value: 'template2',
    label: 'Template 2',
    detail: 'For students applying with <strong>achieved</strong> grades.',
  },
];

export const EMAIL_TEMPLATE_LABEL = {
  template1: 'Template 1: Predicted Grades',
  template2: 'Template 2: Achieved Grades',
};

export const PARENT_APPROVAL_MODAL_LINKS = {
  TEMPLATE: 'https://app.getguru.com/card/TzAkXoMc/Crimson-App-School-Choice-Confirmation-Agreement-Template',
  CLAUSE: 'https://app.getguru.com/card/ixdg9LLT/Crimson-App-School-Choice-Confirmation-Agreement-Additional-Clauses',
};

export const APP_STATUS_DROPDOWN_OPTIONS = [
  { label: 'Accepted', value: 'Accepted' },
  { label: 'Accepted with conditional offer', value: 'Accepted with conditional offer' },
  { label: 'Alternate admission', value: 'Alternate admission' },
  { label: 'Deferred', value: 'Deferred' },
  { label: 'Matriculation school', value: 'Matriculation school' },
  { label: 'Rejected', value: 'Rejected' },
  { label: 'Waitlisted', value: 'Waitlisted' },
  { label: 'Application withdrawn', value: 'Application withdrawn' },
  { label: 'Foundation year offer', value: 'Foundation year offer' },
  { label: 'No responses', value: 'No responses' },
  { label: 'Accepted ED elsewhere', value: 'Accepted ED elsewhere' },
  { label: 'Others', value: 'Others' },
];

export const MAP_GROUP_TEXT = {
  APPLYING: 'Final List',
  SHORTLISTED: 'Short List',
  PROSPECTIVE: 'Long List',
};

export const US_ROUND_MAP = {
  'ED I': 'ED',
  'ED II': 'ED II',
  REA: 'REA',
  EA: 'EA',
  'EA II': 'EA II',
  Priority: 'Priority',
  RD: 'RD',
  Rolling: 'Rolling',
  Transfer: 'Transfer',
  Postgraduate: 'Postgraduate',
  Other: 'Other',
};

export const UK_ROUND_MAP = {
  'UK 1': 'UK 1 (Oct Deadline)',
  'UK 2': 'UK 2 (Jan Deadline)',
  Clearing: 'Clearing',
  Extra: 'Extra',
  Postgraduate: 'Postgraduate',
  Medicine: 'Medicine',
  'Foundation (UCAS)': 'Foundation (UCAS)',
  'Foundation (Non-UCAS)': 'Foundation (Non-UCAS)',
  'Undergraduate (Non-UCAS)': 'Undergraduate (Non-UCAS)',
  Other: 'Other',
};

export const OTHER_REGION_ROUND_MAP = {
  Undergraduate: 'Undergraduate',
  Postgraduate: 'Postgraduate',
  Foundation: 'Foundation',
  Other: 'Other',
};

export const GROUP_PLACEHOLDER_TEXT = {
  APPLYING: 'You can move schools here from the long list and short list.',
  SHORTLISTED: 'You can move schools here from the long list',
  PROSPECTIVE: 'Start from here to add universities you’re interested in',
};

export const UNIVERSITY_REGIONS = [
  {
    value: 'US',
    label: 'US',
  },
  {
    value: 'UK',
    label: 'UK',
  },
  {
    value: 'EU',
    label: 'EU',
  },
  {
    value: 'Other',
    label: 'Others',
  },
];

export const US_ROUND_GROUPS = {
  Early: ['ED I', 'ED II', 'REA', 'EA', 'EA II', 'Priority'],
  Regular: ['UC Decision', 'RD', 'Rolling'],
  Other: ['Transfer', 'Postgraduate', 'Other'],
};

export const UK_ROUND_GROUPS = {
  Medicine: ['Medicine'],
  UCAS: ['UK 1', 'UK 2', 'Clearing', 'Extra', 'Foundation (UCAS)'],
  'Non-UCAS': ['Postgraduate', 'Foundation (Non-UCAS)', 'Other', 'Undergraduate (Non-UCAS)'],
};
export const TRACKER_PAGE_GROUP_BUTTON_OPTIONS_MAP = {
  IN_PROGRESS: 'In Progress',
  SUBMITTED: 'Submitted',
  INVITED_TO_INTERVIEW: 'Invited to Interview',
  GOT_RESULT: 'Decision Received',
};

export const GOAL_OPTION_MAP = {
  Hard: 'Hard Reach',
  Reach: 'Reach',
  Target: 'Target',
  Safety: 'Safety',
};

export const MILESTONE_MEETING_TYPE = {
  meeting1: 'Milestone Meeting 1',
  meeting2: 'Milestone Meeting 2',
  meeting3: 'Milestone Meeting 3',
  meeting4: 'Milestone Meeting 4',
};

export const AppTrackerEventType = {
  addUniversities: 'ADD_UNIVERSITIES',
  updateUniversity: 'UPDATE_UNIVERSITY',
};
