import { normalize } from 'normalizr';
import merge from 'lodash/merge';

export const ADD_ENTITIES = 'normalizr/ADD_ENTITIES';
export const SET_ENTITIES = 'normalizr/UPDATE_ENTITIES';

export function addEntities(payload) {
  return { type: ADD_ENTITIES, payload };
}

// The addEntities action with normalisation by default.
export function addEntitiesWithNormalisation(payload, schema) {
  if (payload === null) throw new Error('Cannot normalise a null payload.');
  return addEntities(normalize(payload, schema));
}

// Use this when entities must be completely replaced instead of deep merging.
export function setEntities(payload) {
  return { type: SET_ENTITIES, payload };
}

// Use this when entities must be completely replaced with normalization.
export function setEntitiesWithNormalisation(payload, schema) {
  if (payload === null) throw new Error('Cannot normalise a null payload.');
  return setEntities(normalize(payload, schema));
}

// Base function for addMeta, addEntity and addAggregate. Use these instead.
export const addEntitiesSection = (section) => (name, value) => {
  return addEntities({ [section]: { [name]: value } });
};

// ADD_ENTITIES with either entities, meta or aggregate data, e.g.
// const myAction1 = addMeta(componentKeys.MY_COMPONENT, 'loaded');
// const myAction2 = addEntities('user', normalisedUser);
// const myAction3 = addAggregate(aggregateKeys.USER_COUNT, 123);
// Notice that addEntity expects the entity to already be normalised.
export const addMeta = addEntitiesSection('meta');
export const addEntity = addEntitiesSection('entities');
export const addAggregate = addEntitiesSection('aggregate');

// Compose multiple ADD_ENTITIES actions together, e.g.
// const myAction = compose(
//  addEntitiesWithNormalisation(users, [userEntity]),
//  addMeta(componentKeys.SPECIAL_USERS, userIds),
//  addAggregate(aggregateKeys.SPECIAL_USER_COUNT, userIds.length)
// );
export const compose = (...actions) => {
  return merge(...actions);
};

// Merges two arrays of scalars together. mergeDeep is not safe on arrays:
// ['foo', 'bar'] mergeDeep ['baz'] => ['baz', 'bar']
export function mergeArray(array1 = [], array2 = []) {
  const combined = new Set([...array1, ...array2]);
  return Array.from(combined);
}
