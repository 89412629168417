import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { List } from 'immutable';
import classNames from 'classnames';
import { trimAllSpaces } from 'utils/utils';
import onClickOutside from 'react-onclickoutside';
import { Caption } from 'components/atoms/typography';

import css from './styles.scss';
import { asButton } from '../../../../utils/accessibility';

function getDefaultValue(props) {
  let defaultSelected;
  if (props.defaultValue) {
    defaultSelected = props.multiple ? props.defaultValue : new List([props.defaultValue]);
  } else {
    defaultSelected = new List();
  }
  return defaultSelected;
}

class MultiSelectDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      selected: getDefaultValue(props),
    };

    this.renderSelect = this.renderSelect.bind(this);
    this.renderCustomItem = this.renderCustomItem.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.renderSelectedString = this.renderSelectedString.bind(this);
  }

  componentDidMount() {
    const { options } = this.props;
    const { selected } = this.state;
    if (options && this.state.selected?.size) {
      // if has options, trigger callback
      this.triggerOnValueChange(selected, true);
    }
  }

  componentDidUpdate(previousProps, nextProps) {
    const { options, defaultValue } = previousProps;
    if (nextProps.defaultValue && !nextProps.defaultValue.equals(defaultValue)) {
      const defaultSelected = getDefaultValue(nextProps);

      if (options) {
        // if has options, trigger callback when receive new default value
        this.triggerOnValueChange(defaultSelected);
      }
    }
    if (defaultValue && !options && nextProps.options) {
      // if has default value, trigger callback when receive new options
      this.triggerOnValueChange(getDefaultValue(this.props));
    }
  }

  getOptionLabel(option) {
    const { optionLabelSelector } = this.props;
    return typeof optionLabelSelector === 'function' ? optionLabelSelector(option) : option.get(optionLabelSelector);
  }

  toggleDropdown(newState) {
    this.setState({
      open: typeof newState === 'boolean' ? newState : !this.state.open,
    });
  }

  handleClickOutside() {
    this.toggleDropdown(false);
  }

  handleOptionChanged(option) {
    const { multiple } = this.props;
    const { selected } = this.state;

    let newSelected;
    if (multiple) {
      newSelected = selected.includes(option)
        ? selected.filter((val) => !val.equals(option))
        : selected.concat([option]);
    } else {
      newSelected = new List([option]);
      this.toggleDropdown(false);
    }

    this.setState({
      selected: newSelected,
    });

    this.triggerOnValueChange(newSelected);
  }

  triggerOnValueChange(newSelected, onMount = false) {
    const { multiple, onValueChange } = this.props;
    if (onValueChange) {
      onValueChange(multiple ? newSelected : newSelected.get(0), onMount);
    }
  }

  renderCustomItem() {
    const { options, defaultValue, placeholder, itemRenderer } = this.props;
    const { selected } = this.state;
    const selectedItem = options && options.filter((option) => selected.includes(option));

    return (
      (selectedItem && selectedItem.get(0) && itemRenderer(selectedItem.get(0))) ||
      (defaultValue && itemRenderer(defaultValue)) ||
      placeholder
    );
  }

  renderSelectedString() {
    const { options } = this.props;
    const { selected } = this.state;
    return (
      options &&
      options
        .filter((option) => selected.includes(option))
        .map((option) => this.getOptionLabel(option))
        .join(', ')
    );
  }

  // TODO: Scroll to the first default value
  renderSelect() {
    const { options, multiple, error, itemRenderer } = this.props;
    const { selected } = this.state;

    return (
      options && (
        <div
          className={classNames(css.select, {
            [css.error]: error,
          })}
        >
          {options.map((option, i) => {
            const isChecked = selected.includes(option);
            return (
              <div
                className={css.option}
                key={i}
                data-test-id={`selectOptions_${trimAllSpaces(this.getOptionLabel(option))}`}
                {...asButton(() => this.handleOptionChanged(option))}
              >
                {multiple && (
                  <i
                    className={classNames('zmdi', css.checkboxInputStyle, {
                      'zmdi-check-square': isChecked,
                      'zmdi-square-o': !isChecked,
                    })}
                  />
                )}
                {itemRenderer && itemRenderer(option)}
                {!itemRenderer && <span className={css.optionLabel}>{this.getOptionLabel(option)}</span>}
              </div>
            );
          })}
        </div>
      )
    );
  }

  render() {
    const {
      label,
      id,
      className,
      placeholder,
      disabled,
      validationMessage,
      error,
      itemRenderer,
      dataTestId,
    } = this.props;
    const { open, selected } = this.state;
    return (
      <div className={classNames(css.formItemContainer, className)}>
        {label && <Caption htmlFor={id}>{label}</Caption>}
        <div className={css.dropdownWrapper}>
          <div
            id={id}
            className={classNames(css.dropdown, {
              [css.active]: open,
              [css.disabled]: disabled,
              [css.error]: error,
            })}
            data-test-id={dataTestId}
            {...asButton(this.toggleDropdown)}
          >
            {itemRenderer && this.renderCustomItem()}
            {!itemRenderer && (
              <span className={classNames(css.placeholderText, { [css.hasSelected]: selected.size > 0 })}>
                {this.renderSelectedString() || placeholder}
              </span>
            )}
            <i className={classNames('zmdi zmdi-chevron-down', css.chevron)} {...asButton(this.toggleDropdown)} />
          </div>
          {open && !disabled && this.renderSelect()}
        </div>
        {error && validationMessage && <div className={css.validationMessage}>{validationMessage}</div>}
      </div>
    );
  }
}

MultiSelectDropdown.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  defaultValue: PropTypes.oneOfType([ImmutablePropTypes.map, ImmutablePropTypes.list]),
  options: PropTypes.oneOfType([ImmutablePropTypes.map, ImmutablePropTypes.list]),
  optionLabelSelector: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  placeholder: PropTypes.string.isRequired,
  onValueChange: PropTypes.func,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  validationMessage: PropTypes.string,
  error: PropTypes.bool,
  itemRenderer: PropTypes.func,
  dataTestId: PropTypes.string,
};

MultiSelectDropdown.defaultProps = {
  optionLabelSelector: 'label',
};

MultiSelectDropdown.displayName = 'MultiSelectDropdown';

export default onClickOutside(MultiSelectDropdown);
