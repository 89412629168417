import { escapeString } from 'utils/graphql';
import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';
import { gql } from '@apollo/client';
import { createClient } from '../utils';
import { contractType, itemType, packageType } from './common/types';

const { api } = getConfig();

const client = createClient(api.graphQLEndpoint);

const createPackage = gql`
  ${packageType}
  ${itemType}
  ${contractType}
  mutation createPackage(
    $userId: String!
    $name: String!
    $description: String
    $start: String!
    $end: String!
    $files: [fileInputType]
  ) {
    createPackage(userId: $userId, name: $name, description: $description, start: $start, end: $end, files: $files) {
      ...packageType
      subjects: items {
        ...itemType
        tutors: contract {
          ...contractType
        }
      }
    }
  }
`;

const modifyPackage = gql`
  ${packageType}
  ${itemType}
  ${contractType}
  mutation modifyPackage(
    $id: Int!
    $name: String!
    $description: String!
    $start: String!
    $end: String!
    $deletedFiles: [Int]
    $addedFiles: [fileInputType]
  ) {
    modifyPackage(
      id: $id
      name: $name
      description: $description
      start: $start
      end: $end
      deletedFiles: $deletedFiles
      addedFiles: $addedFiles
    ) {
      ...packageType
      subjects: items {
        ...itemType
        tutors: contract {
          ...contractType
        }
      }
    }
  }
`;

const createFile = gql`
  mutation createPackageFile($name: String!, $packageId: Int!, $bucket: String!, $key: String!) {
    createPackageFile(name: $name, packageId: $packageId, bucket: $bucket, key: $key) {
      id
      bucket
      key
      name
      description
      getUrl
      createdAt
    }
  }
`;

const allPackages = gql`
  ${packageType}
  ${itemType}
  ${contractType}
  query allPackages($userId: String) {
    allPackages(userId: $userId) {
      ...packageType
      subjects: items {
        ...itemType
        tutors: contract {
          ...contractType
        }
      }
    }
  }
`;

const deleteFile = gql`
  mutation deletePackageFile($id: Int!) {
    deletePackageFile(id: $id)
  }
`;

const myPackages = gql`
  ${itemType}
  ${contractType}
  query myPackages {
    myPackages {
      id
      userId
      items {
        ...itemType
        contract {
          ...contractType
        }
      }
    }
  }
`;

const packageFileUrl = gql`
  query packageFileUrl($bucket: String!, $key: String!) {
    getPackageFileUrl(bucket: $bucket, key: $key) {
      getUrl
      key
    }
  }
`;

export default {
  createPackage: (userId, name, description, start, end, files) =>
    client.query(createPackage, { userId, name, description, start, end, files }),
  modifyPackage: (data) => client.query(modifyPackage, { ...data, description: escapeString(data.description) }),
  createFile: (name, packageId, bucket, key) => client.query(createFile, { name, packageId, bucket, key }),
  allPackages: (userId) => client.query(allPackages, { userId }),
  deleteFile: (id) => client.query(deleteFile, { id }),
  myPackages: () => client.query(myPackages),
  packageFileUrl: (bucket, key) => client.query(packageFileUrl, { bucket, key }),
};
