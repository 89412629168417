import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';
import { getBearer } from 'utils/auth';

const {
  pushNotifications: { endpoint },
} = getConfig();

export const updatePushNotification = (notification) => {
  return fetch(`${endpoint}/update`, {
    method: 'PATCH',
    body: JSON.stringify(notification),
    headers: new Headers({
      Authorization: `Bearer ${getBearer()}`,
      'Content-Type': 'application/json',
    }),
  });
};

export const clearPushNotifications = () => {
  return fetch(`${endpoint}/clear`, {
    method: 'PATCH',
    headers: new Headers({
      Authorization: `Bearer ${getBearer()}`,
      'Content-Type': 'application/json',
    }),
  });
};

export const fetchPushNotifications = async () => {
  const response = await fetch(`${endpoint}/list`, {
    method: 'GET',
    headers: new Headers({
      Authorization: `Bearer ${getBearer()}`,
    }),
  });
  return response.json();
};
