import React from 'react';

import Profile from './Profile';
import BookAction from './BookAction';

import css from './dashboard.scss';

export default function Dashboard() {
  return (
    <div className={css.page}>
      <section className={css.top}>
        <Profile />
        <section>Greeting & Hook</section>
        <BookAction />
      </section>
      <main className={css.main}>
        <section className={css.bigCol}>1</section>
        <section>2</section>
        <section>3</section>
        <section>4</section>
        <section>5</section>
      </main>
    </div>
  );
}
