import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from 'components/unique/ProfilePage/profileItem.scss';
import NotificationView from './NotificationView';
import NotificationForm from './NotificationForm';
import NotificationSwitchContainer from './NotificationSwitchView';

export default class NotificationSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editing: false,
    };
    this.renderView = this.renderView.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.updateEditingStatus = this.updateEditingStatus.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  onSave(details) {
    const { onSaveDetails } = this.props;
    onSaveDetails(details).then(() => {
      this.setState({
        editing: false,
      });
    });
  }

  updateEditingStatus() {
    const { editing } = this.state;
    this.setState({
      editing: !editing,
    });
  }

  renderView() {
    const { subscribedEmail } = this.props;
    return <NotificationView subscribedEmail={subscribedEmail} />;
  }

  renderForm() {
    const { subscribedEmail } = this.props;
    return (
      <NotificationForm
        subscribedEmail={subscribedEmail}
        onSaveDetails={this.onSave}
        onCancel={this.updateEditingStatus}
      />
    );
  }

  render() {
    const { userId, onSaveDetails, subscribedEmail } = this.props;
    return (
      <div className={classNames(css.profileItem, css.rightItem)}>
        <div className={css.itemTitle}>
          <i className={classNames('zmdi zmdi-notifications', css.itemIcon)} />
          <div className={css.itemName}>Notifications</div>
        </div>
        {/* TODO: delete feature switch after api is ready */}
        <NotificationSwitchContainer
          subscribedEmailDefault={subscribedEmail}
          onSaveDetails={onSaveDetails}
          userId={userId}
        />
      </div>
    );
  }
}

NotificationSection.displayName = 'NotificationSection';

NotificationSection.propTypes = {
  subscribedEmail: PropTypes.bool,
  onSaveDetails: PropTypes.func.isRequired,
  canEdit: PropTypes.bool,
  userId: PropTypes.string,
};
