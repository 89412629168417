import React, { Component } from 'react';
import Body from 'components/atoms/typography/Body';
import Header from 'components/molecules/Header';
import Modal from 'components/molecules/Modal';
import CheckboxField from 'components/generic/Forms/CheckboxField';
import moment from 'moment';
import classnames from 'classnames';
import Banner from 'components/molecules/Banner';
import PropTypes from 'prop-types';
import progressReportStatus from 'constants/progressReportStatus';
import removeStyleTagsFromHtml from 'utils/progressReportUtils';
import Send from '@material-ui/icons/Send';
import Info from '@material-ui/icons/Info';
import css from './styles.scss';

export default class ProgressReportPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      isSendingEmail: false,
      sendCopy: false,
      htmlContent: '',
      contactIds: [],
    };
  }

  componentDidMount() {
    const { report, reportId, fetchProgressReportById, getProgressReportHtml } = this.props;
    if (!report && reportId) {
      fetchProgressReportById(reportId);
    }
    getProgressReportHtml(reportId);
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.reportHtml) {
      this.setState({
        htmlContent: removeStyleTagsFromHtml(props.reportHtml),
      });
    }
  }

  toggleModal = () => {
    this.setState((prevState) => ({ isModalOpen: !prevState.isModalOpen }));
  };

  toggleSendCopy = () => {
    this.setState((prevState) => ({ sendCopy: !prevState.sendCopy }));
  };

  toggleContactsToSend = (id, checked) => {
    this.setState(({ contactIds }) => {
      if (checked) {
        return {
          contactIds: [...contactIds, id],
        };
      }
      return {
        contactIds: contactIds.filter((i) => i !== id),
      };
    });
  };

  goToStudentsProfile = () => {
    const { history, student } = this.props;
    history.push(`/users/${student.get('userId')}/about`);
  };

  sendEmail = async () => {
    const { report, sendProgressReport } = this.props;
    const { sendCopy, contactIds } = this.state;

    this.setState({
      isSendingEmail: true,
    });

    await sendProgressReport(report.id, sendCopy, contactIds);

    this.setState({
      isModalOpen: false,
      isSendingEmail: false,
    });
  };

  renderKeyContacts() {
    const { keyContactInfo } = this.props;
    const { contactIds } = this.state;

    return keyContactInfo.map((keyContact) => {
      const { id, firstName, lastName, isPrimary, relationship } = keyContact;
      const keyContactsName = `${firstName || ''} ${lastName || ''}${relationship ? `, ${relationship}` : ''} ${
        isPrimary ? '(Primary contact)' : ''
      }`;
      return (
        <CheckboxField
          key={id}
          id={`contact${id}`}
          label={keyContactsName}
          isChecked={contactIds.includes(id)}
          onClick={(checked) => this.toggleContactsToSend(id, checked)}
          className={css.checkbox}
        />
      );
    });
  }

  render() {
    const { isModalOpen, isSendingEmail, sendCopy, contactIds } = this.state;
    const { report, student, keyContactInfo, canEdit } = this.props;
    let period;

    if (report) {
      if (moment(report.startDate).isSame(report.endDate, 'year')) {
        period = `${moment(report.startDate).format('DD MMM')} - ${moment(report.endDate).format('DD MMM YYYY')}`;
      } else {
        period = `${moment(report.startDate).format('DD MMM YYYY')} - ${moment(report.endDate).format('DD MMM YYYY')}`;
      }
    }

    const newData = {
      __html: this.state.htmlContent,
    };

    const hasBeenSent = report && report.status === progressReportStatus.SENT;
    const canBeSent = report && report.score !== null && (report.successes || report.challenges);

    return (
      <div>
        <Modal
          title={hasBeenSent ? 'Resend to key contacts?' : 'Send to key contacts?'}
          submitText={keyContactInfo.length ? 'Send report' : 'Add email'}
          secondarySubmitText="Back"
          isOpen={isModalOpen}
          onClose={this.toggleModal}
          onSubmit={keyContactInfo.length ? this.sendEmail : this.goToStudentsProfile}
          onSecondarySubmit={this.toggleModal}
          loading={isSendingEmail}
          isSubmitDisabled={!contactIds.length && !sendCopy}
        >
          {keyContactInfo.length ? (
            <div>
              <Body className={css.modalText}>
                {hasBeenSent
                  ? 'This report has been sent already, you will be resending this report to the following contact(s) for the '
                  : 'You will be sending this report to the following contact(s) for the '}
                <b>{period}</b> period. You <b>cannot undo</b> this action.
              </Body>
              <div>{this.renderKeyContacts()}</div>
              <CheckboxField
                id="sendEmailToMyself"
                label="Send me a copy of this report."
                isChecked={sendCopy}
                onClick={this.toggleSendCopy}
                className={classnames(css.checkbox, css.checkboxCopy)}
              />
            </div>
          ) : null}
          {!keyContactInfo.length ? (
            <div>
              <Body className={css.modalText}>
                Doh! Looks like there are no records of a keyContact’s email on the student profile. You must add a
                keyContact’s email before you can send this report.
              </Body>
              <Body className={css.modalText}>Don’t worry, this report will be saved.</Body>
            </div>
          ) : null}
        </Modal>
        {report && (
          <div>
            <Header
              title={period}
              isBackButtonVisible
              backButtonText={hasBeenSent ? 'Reports' : 'Back'}
              backButtonLink={
                !canEdit || hasBeenSent
                  ? `/users/${student.get('userId')}/reports`
                  : `/users/${student.get('userId')}/reports/${report.id}`
              }
              actionButtons={
                canEdit
                  ? [
                      {
                        buttonText: hasBeenSent ? 'Resend report' : 'Send report',
                        buttonAction: this.toggleModal,
                        isButtonDisabled: !canBeSent,
                      },
                    ]
                  : []
              }
            />
            {!hasBeenSent && !canBeSent && (
              <Banner
                text={
                  canEdit
                    ? 'Oops, the summary is incomplete. It must be completed before you can send this report.'
                    : 'This report is incomplete.'
                }
                backgroundColor="#ff764c"
                icon={<Info />}
              />
            )}
            {hasBeenSent && report.sentDate && (
              <Banner
                text={`This report was sent on ${moment(report.sentDate).format('h:mma, dddd, D MMM, YYYY')}.`}
                icon={<Send />}
              />
            )}
            {/* eslint-disable-next-line react/no-danger */}
            <div className={css.emailPreview} dangerouslySetInnerHTML={newData} />
          </div>
        )}
      </div>
    );
  }
}

ProgressReportPreview.propTypes = {
  student: PropTypes.object,
  report: PropTypes.object,
  reportId: PropTypes.string.isRequired,
  sendProgressReport: PropTypes.func.isRequired,
  keyContactInfo: PropTypes.array,
  history: PropTypes.object.isRequired,
  fetchProgressReportById: PropTypes.func.isRequired,
  getProgressReportHtml: PropTypes.func.isRequired,
  reportHtml: PropTypes.string,
  canEdit: PropTypes.bool,
};

ProgressReportPreview.defaultProps = {
  canEdit: true,
};
