import { createSelector } from 'reselect';
import { getMeta } from 'selectors/meta';

const notification = (state) => state.get('notification');

export const getAllNotifications = createSelector(notification, (items) =>
  items.sort((a, b) => b.get('timestamp') - a.get('timestamp')),
);

export const getLatestNotificationKey = createSelector(
  getMeta,
  (items) => items.getIn(['notifications', 'lastLoadedKey']) || '',
);

export const hasMoreNotifications = createSelector(getMeta, (items) =>
  items.getIn(['notifications', 'hasMore'], false),
);

export const getNewNotificationCount = createSelector(getMeta, (items) => items.getIn(['notifications', 'new'], 0));
