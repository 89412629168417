import React, { useContext, useEffect, useState, useMemo } from 'react';
import { ApprovalPendingContainer, ResendPrompt } from './index.style';
import ClickableUnderlineText from './ClickableUnderlineText';
import { AppTrackerContext } from '../../viewController';
import { STORAGE_KEY, getCurrent } from '../../util';
import { useUserId, useSafeLoading } from '../../helper';
import { DoubleConfirmText } from '../index.style';
import Dialogue from '../../Dialogue';
import useToggle from 'hooks/useToggle';
import ApprovalStatus from './ApprovalStatus';

const getStatusPrompt = (region: string, responseType?: string | null) => {
  switch (responseType) {
    case 'approved':
      return `Final list for ${region} applications have been approved by the parents.`;
    case 'rejected':
      return `Final list for ${region} applications was rejected by the parents.`;
    default:
      return `The final school choice list for ${region} Universities is pending approval from the student's parents`;
  }
};
type ApprovalPendingProps = {
  edited: boolean;
};
const ApprovalPending = ({ edited }: ApprovalPendingProps) => {
  const { state, controller } = useContext(AppTrackerContext);
  const [showResendPrompt, setShowResendPrompt] = useState(false);
  const [isOpen, toggleModal] = useToggle(false);
  const [isLoading, setLoading] = useSafeLoading();
  const userId = useUserId();
  const currentRegion = getCurrent(state.tabValue, state.listRegion, state.trackerRegion);
  const responseType = useMemo(() => state.approvals[state.listRegion]?.responseType, [
    state.approvals,
    state.listRegion,
  ]);
  const approved = responseType === 'approved';
  const rejected = responseType === 'rejected';

  useEffect(() => {
    const getReminderLaterFromStorage = () => {
      const res = sessionStorage.getItem(STORAGE_KEY.RESEND_APPROVAL_REMIND_LATER(userId, state.listRegion));
      setShowResendPrompt(res !== 'true');
    };
    getReminderLaterFromStorage();
  }, [setShowResendPrompt, edited, userId, state.listRegion]);
  const onClickRemindMeLater = () => {
    sessionStorage.setItem(STORAGE_KEY.RESEND_APPROVAL_REMIND_LATER(userId, state.listRegion), 'true');
    setShowResendPrompt(false);
  };
  const onClickResend = () => {
    toggleModal();
  };

  const resendApprovalEmail = async () => {
    const enable = controller.checkIfHasApplicationsToSendApproval();
    if (!enable) return;
    toggleModal();
    controller.openParentApprovalModal();
  };
  return (
    <ApprovalPendingContainer>
      <ApprovalStatus
        status={!responseType ? 'Approval Pending' : responseType}
        prompt={getStatusPrompt(state.listRegion, responseType)}
      />
      {(edited || rejected) && <ClickableUnderlineText title={'Resend'} onClick={onClickResend} />}
      {edited && showResendPrompt && (
        <ResendPrompt>
          <div>{'New changes haven’t been sent out yet!'}</div>
          <div onClick={onClickRemindMeLater} onKeyDown={() => void 0} tabIndex={0} role={'button'}>
            {'Remind me again'}
          </div>
        </ResendPrompt>
      )}
      <Dialogue
        isOpen={isOpen}
        closeModal={() => toggleModal()}
        title={'Are you sure?'}
        confirmButtonText={'Okay, I understand'}
        onClickConfirm={resendApprovalEmail}
        isLoading={isLoading}
      >
        <DoubleConfirmText>
          {
            'The final school list has already been sent to parents/primary contacts for approval, are you sure you want to resend?'
          }
        </DoubleConfirmText>
      </Dialogue>
    </ApprovalPendingContainer>
  );
};

export default ApprovalPending;
