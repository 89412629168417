import thunk from 'redux-thunk';
import logger from 'middleware/logger';
import appCuesMiddleware from 'middleware/appCues';
import appLoadingMiddleware from 'middleware/appLoading';
import feedbackMiddleware from 'middleware/feedback';
import connectionMiddleware from 'middleware/connection';
import serviceWorkerMiddleware from 'middleware/serviceWorkerMiddleware';
import userCurrentlyViewing from 'middleware/userCurrentlyViewing';
import batchFirebase from 'middleware/batchFirebase';
import { routerMiddleware } from 'react-router-redux';

export default function configureMiddlewares(history) {
  const configuredRouterMiddleware = routerMiddleware(history);

  const middlewares = [
    thunk,
    batchFirebase,
    connectionMiddleware,
    logger,
    appCuesMiddleware,
    appLoadingMiddleware,
    feedbackMiddleware,
    configuredRouterMiddleware,
    userCurrentlyViewing,
  ];

  if (navigator.serviceWorker) middlewares.push(serviceWorkerMiddleware);

  return middlewares;
}
