import { plateUI } from '../common';
import { basicElementsPlugins } from '../editor-basic-elements/basicElementsPlugins';
import { basicMarksPlugins } from '../editor-basic-marks/basicMarksPlugins';
import { withStyledPlaceHolders } from '../placeholder/withStyledPlaceHolders';
import { createMyPlugins } from '../types/plateTypes';

const components = withStyledPlaceHolders(plateUI);
export const basicNodesPlugins = createMyPlugins([...basicElementsPlugins, ...basicMarksPlugins], {
  components,
});
