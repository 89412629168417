import { connect } from 'react-redux';

import { getBookingByIdToJS } from 'selectors/booking';
import { getLoginUserToJs, getBookingAsToJs } from 'selectors/user';

import SessionStatus from './SessionStatus';

const mapStateToProps = (state, props) => ({
  currentUser: getLoginUserToJs(state),
  session: getBookingByIdToJS(props.sessionId)(state),
  bookAs: getBookingAsToJs(state),
});

export default connect(mapStateToProps)(SessionStatus);
