import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getLoginUser } from 'selectors/user';
import { formLoading, formSuccess, fetchUserProfile } from 'ducks/meta';

import ProfilePopover from './ProfilePopover';

const mapStateToProps = (state) => ({
  loginUser: getLoginUser(state),
});

const mapDispatchToProps = (dispatch) => ({
  formLoading: () => dispatch(formLoading()),
  formSuccess: (message) => dispatch(formSuccess(message)),
  fetchUserProfile: () => dispatch(fetchUserProfile()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfilePopover));
