import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autobind from 'auto-bind';
import { Acl } from '@crimson-education/common-config';
import { pageSize } from 'utils/invoiceUtils';
import { Body } from 'components/atoms/typography';
import { Ghost } from 'components/atoms/graphics';

import InvoiceListView from './InvoiceListView';
import css from './styles.scss';

export default class InvoiceList extends Component {
  static canViewTutorInfo(userRoles) {
    return Acl.isSuperAdmin(userRoles) || Acl.isAccounts(userRoles);
  }

  constructor(props) {
    super(props);

    autobind(this);
  }

  UNSAFE_componentWillMount() {
    const { filter, fetchInvoices, fetched } = this.props;

    if (!fetched) {
      fetchInvoices({ filter });
    }
  }

  hasNextPage() {
    return this.props.currentPage < this.props.totalPages;
  }

  loadMore() {
    const { filter, currentPage, fetchInvoices } = this.props;
    if (this.hasNextPage()) {
      const after = currentPage * pageSize;

      fetchInvoices({ filter, after });
    }
  }

  selectOption(invoice, idx) {
    this.setState({ highlightedIndex: idx });
    this.props.setSelectedInvoiceId(invoice.id);
    this.props.fetchInvoiceById(invoice.id);
  }

  render() {
    const { invoices, loginUser, fetched, setSelectedInvoiceId, selectedInvoiceId } = this.props;

    return fetched && !invoices.length ? (
      <div className={css.emptySearchContainer}>
        <Ghost className={css.emptyGraphic} />
        <Body className={css.emptyResultMessage}>No invoice found.</Body>
      </div>
    ) : (
      <InvoiceListView
        invoices={invoices}
        loginUserTimezone={loginUser.get('timezone')}
        isLoading={!fetched}
        hasNextPage={this.hasNextPage()}
        loadMore={this.loadMore}
        onSelect={this.selectOption}
        setSelectedInvoiceId={setSelectedInvoiceId}
        selectedInvoiceId={selectedInvoiceId}
        showTutor={InvoiceList.canViewTutorInfo(loginUser.get('userRoles'))}
      />
    );
  }
}

InvoiceList.propTypes = {
  invoices: PropTypes.array,
  loginUser: PropTypes.object,
  fetchInvoices: PropTypes.func.isRequired,
  fetched: PropTypes.bool,
  filter: PropTypes.object.isRequired,
  totalPages: PropTypes.number,
  currentPage: PropTypes.number,
  setSelectedInvoiceId: PropTypes.func,
  fetchInvoiceById: PropTypes.func,
  selectedInvoiceId: PropTypes.string,
};

InvoiceList.defaultProps = {
  invoices: null,
};
