import React from 'react';
import { Column, Row } from '../../index.style';
import { PlacholderText } from './index.style';
const ICONS = {
  '01': '/static/applicationTracker/staffDashboardPlaceholder01.png',
  '02': '/static/applicationTracker/staffDashboardPlaceholder02.png',
  '03': '/static/applicationTracker/staffDashboardPlaceholder03.png',
};

type PlaceholderProps = {
  iconSize: number;
  iconName: keyof typeof ICONS;
  placeholderText: string;
  containerStyle?: React.CSSProperties;
  wrapperStyle?: React.CSSProperties;
};

const Placeholder = ({ iconName, iconSize, placeholderText, containerStyle, wrapperStyle }: PlaceholderProps) => {
  return (
    <Column style={{ flex: 1, background: 'white', borderRadius: 8, ...wrapperStyle }} columncenter rowcenter>
      <Row style={containerStyle} gap={'30px'} columncenter rowcenter>
        <img
          style={{ objectFit: 'contain' }}
          width={iconSize}
          height={iconSize}
          src={ICONS[iconName]}
          alt={'placeholder_icon'}
        />
        <PlacholderText>{placeholderText}</PlacholderText>
      </Row>
    </Column>
  );
};

export default Placeholder;
