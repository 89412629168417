import { useRef, useState } from 'react';

export const useHorizontalScroll = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
  const scroll = (offset: number) => {
    if (ref.current) {
      const container = ref.current;
      container.scrollLeft += offset;
    }
  };
  const reset = () => {
    if (ref?.current) {
      const container = ref.current;
      setCanScrollLeft(container.scrollLeft > 0);
      setCanScrollRight(container.scrollLeft + container.offsetWidth < container.scrollWidth);
    }
  };
  return { ref, canScrollLeft, canScrollRight, scroll, reset };
};
