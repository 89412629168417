import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getGlobalMessages } from 'selectors/examPrep';
import { getSessionStartMessage } from 'selectors/booking';
import GlobalMessageBanner from './GlobalMessageBanner';

const mapStateToProps = (state) => {
  const globalMessage = getGlobalMessages(state);
  return {
    messages: globalMessage.length ? globalMessage : getSessionStartMessage(state),
  };
};

export default withRouter(connect(mapStateToProps)(GlobalMessageBanner));
