import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Close from '@material-ui/icons/Close';
import Done from '@material-ui/icons/Done';
import eventApi from 'graphql/api/event';
import Button from 'components/molecules/Button';
import css from './SessionConfirmationActions.scss';

export default function SessionConfirmationActions(props) {
  const { session, confirm, decline, currentSession, setCurrentSession, setRefetchSessions } = props;
  const sessionResult = currentSession || session;
  const [actionVisible, setActionVisible] = useState(
    sessionResult && new Date(sessionResult.end) >= new Date() && sessionResult?.status === 'tentative',
  );
  const [refetch, setRefetch] = useState(false);
  useEffect(() => {
    async function refetchSession() {
      const result = await eventApi.fetchEventById(sessionResult.id);
      if (result) {
        if (setCurrentSession) {
          setCurrentSession(result);
        }
        setActionVisible(new Date(result.end) >= new Date() && result?.status === 'tentative');
      } else {
        setActionVisible(false);
      }
    }
    if (refetch) {
      refetchSession();
    }
  }, [sessionResult, refetch]);

  return (
    <>
      {actionVisible ? (
        <div className={css.actionGroup}>
          <Button
            small
            dataTestId="AcceptSessionButton"
            className={css.action}
            onClick={async () => {
              await confirm();
              setRefetch(true);
              setRefetchSessions && setRefetchSessions(true);
            }}
          >
            <Done className={css.icon} />
            Confirm
          </Button>

          <Button
            small
            dataTestId="DeclineSessionButton"
            className={css.action}
            secondary
            onClick={async () => {
              await decline();
              setRefetch(true);
              setRefetchSessions && setRefetchSessions(true);
            }}
          >
            <span className={css.secondaryText}>
              <Close className={css.icon} />
              Decline
            </span>
          </Button>
        </div>
      ) : null}
    </>
  );
}

SessionConfirmationActions.propTypes = {
  currentSession: PropTypes.object,
  session: PropTypes.object,
  confirm: PropTypes.func,
  decline: PropTypes.func,
  setCurrentSession: PropTypes.func,
  setRefetchSessions: PropTypes.func,
};
