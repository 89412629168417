import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './styles.scss';

const StaticTooltip = (props) => {
  return (
    <div className={classNames('static-tooltip-container', props.containerClassName)}>
      <div
        className={classNames(
          'static-tooltip',
          {
            'static-tooltip-bottom': props.arrowPosition === 'bottom',
          },
          props.tooltipClassName,
        )}
        style={props.tooltipStyle}
      >
        <div className="static-tooltip-content">
          <div
            className="static-tooltip-arrow"
            style={props.tooltipColor ? { borderBottomColor: props.tooltipColor } : {}}
          />
          <div
            className="static-tooltip-inner"
            style={props.tooltipColor ? { backgroundColor: props.tooltipColor } : {}}
            role="tooltip"
          >
            {props.label}
          </div>
        </div>
      </div>
    </div>
  );
};

StaticTooltip.propTypes = {
  label: PropTypes.node.isRequired,
  arrowPosition: PropTypes.string,
  tooltipClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  tooltipColor: PropTypes.string,
  tooltipStyle: PropTypes.object,
};

StaticTooltip.defaultProps = {
  arrowPosition: 'bottom',
};

export default StaticTooltip;
