import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ThumbUp from '@material-ui/icons/ThumbUp';
import ThumbDown from '@material-ui/icons/ThumbDown';

import css from './CallQuality.scss';

export default function CallQuality(props) {
  const { value, onChange } = props;
  return (
    <div className={css.callQuality}>
      <h3>How was the call quality?</h3>
      <div className={css.controls}>
        <button onClick={() => onChange(-1)}>
          <ThumbDown className={classNames(css.icon, { [css.negative]: value === -1 })} />
        </button>
        <button onClick={() => onChange(1)}>
          <ThumbUp className={classNames(css.icon, { [css.positive]: value === 1 })} />
        </button>
      </div>
    </div>
  );
}

CallQuality.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};
