import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Acl, permissionTypes } from '@crimson-education/common-config';
import timezones from 'utils/timezones';
import InfoIcon from 'components/generic/Icons/Info';
import Header from 'components/molecules/Header';
import EulaModal from 'components/organisms/EulaModal';
import {
  Container as InfoTextContainer,
  Header as InfoTextHeader,
  Info as InfoTextInfo,
} from 'components/generic/InfoText';
import TimezoneSection from './TimezoneSection';
import NotificationSection from './NotificationSection';
import EditSubscriptionSection from './EditSubscriptionSection';

import css from './styles.scss';
import { handleEnter } from '../../../../utils/accessibility';

export default class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eulaOpen: false,
    };
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { userProfile, fetchLoginUser } = this.props;
    const loginUserId = userProfile.get('userId');
    fetchLoginUser(loginUserId);
  }

  openModal() {
    this.setState({ eulaOpen: true });
  }

  closeModal() {
    this.setState({ eulaOpen: false });
  }

  render() {
    const {
      userProfile,
      loginUser,
      onSaveTimezoneDetails,
      onSaveNotificationDetails,
      loginUserPermissions,
    } = this.props;

    const canViewEditSubscription = Acl.checkPermission(loginUserPermissions, permissionTypes['UNSUBSCRIBE_LINK:VIEW']);

    return loginUser && loginUser.size ? (
      <div>
        <Header title="Settings" />
        <div className={css.container}>
          <InfoTextContainer className={css.info}>
            <InfoTextHeader text="ACCOUNT SETTINGS" />
            <InfoTextInfo text="You can manage your account settings here. Other public information can be edited in your profile." />
          </InfoTextContainer>
          <div className={css.main}>
            {canViewEditSubscription && <EditSubscriptionSection />}
            <TimezoneSection
              canEdit
              timezone={loginUser.get('timezone') || ''}
              autoTimezone={loginUser.get('autoTimezone')}
              timezones={timezones}
              onSaveDetails={(details) => onSaveTimezoneDetails(userProfile.get('userId'), details)}
            />
            <NotificationSection
              canEdit
              userId={userProfile.get('userId')}
              subscribedEmail={loginUser.get('subscribedEmail')}
              onSaveDetails={(details) => onSaveNotificationDetails(userProfile.get('userId'), details)}
            />
            {this.props.app.isMobile && (
              <div
                role="button"
                tabIndex={0}
                className={css.eulaSection}
                onClick={this.openModal}
                onKeyDown={handleEnter(this.openModal)}
              >
                <InfoIcon className={css.icon} />
                <span className={css.eulaText}>End-User License Agreement</span>
              </div>
            )}
            <EulaModal isOpen={this.state.eulaOpen} onClose={this.closeModal} />
          </div>
        </div>
      </div>
    ) : null;
  }
}

Settings.propTypes = {
  userProfile: ImmutablePropTypes.map.isRequired,
  loginUser: ImmutablePropTypes.map.isRequired,
  loginUserPermissions: PropTypes.array.isRequired,
  app: PropTypes.object.isRequired,
  fetchLoginUser: PropTypes.func.isRequired,
  onSaveTimezoneDetails: PropTypes.func.isRequired,
  onSaveNotificationDetails: PropTypes.func.isRequired,
};
