import React from 'react';
import { PanelContainer } from './index.style';
type PanelWithTitleProps = {
  title: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
};
const PanelWithTitle = ({ title, children, style }: PanelWithTitleProps) => {
  return (
    <PanelContainer style={style}>
      <div>{title}</div>
      {children}
    </PanelContainer>
  );
};

export default PanelWithTitle;
