import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Body, Caption, SubHeader } from 'components/atoms/typography';
import flex from 'components/atoms/flex.scss';
import fontColour from 'components/atoms/typography/fontColour.scss';
import css from './style.scss';
import { handleEnter } from '../../../utils/accessibility';

export default function Table(props) {
  const {
    children,
    data,
    className,
    emptyMessage,
    hideHeader,
    headerClassName,
    rowClassName,
    onRowClick,
    dataGaCategory,
    isScrollable,
  } = props;

  const empty = !data.length && !data.size;

  const [scrollWidth, setWidth] = useState('100%');

  const setWidthRef = (el) => {
    if (isScrollable && el) {
      setWidth(`${el.scrollWidth}px`);
    }
  };

  return (
    <div data-ga-category={dataGaCategory} className={className}>
      {!empty && (
        <div className={classNames(css.table, { [css.scrollable]: isScrollable })} ref={setWidthRef}>
          {!hideHeader && (
            <div className={classNames(flex.row, css.tableHeadings, headerClassName)} style={{ width: scrollWidth }}>
              {children.map((column, index) => {
                if (!column) return null;

                const { flex, name, align } = column.props;

                return (
                  <div
                    key={index}
                    className={classNames(css.column, { [css.columnMinWidth]: isScrollable })}
                    style={{ flex, textAlign: align }}
                  >
                    <Caption>{name}</Caption>
                  </div>
                );
              })}
            </div>
          )}
          <div>
            {data.map((row, rowIndex) => {
              return (
                <div
                  role="button"
                  tabIndex={0}
                  className={classNames(rowClassName, flex.row, css.tableRow, { [css.clickable]: onRowClick })}
                  key={rowIndex}
                  onClick={onRowClick ? () => onRowClick(row, rowIndex) : undefined}
                  onKeyDown={onRowClick ? handleEnter(() => onRowClick(row, rowIndex)) : undefined}
                  style={{ width: scrollWidth }}
                >
                  {children &&
                    children.map((column, index) => {
                      if (!column) return null;

                      const { flex, align, renderer, dataKey } = column.props;

                      const content = renderer
                        ? renderer({ value: row[dataKey], rowData: row, rowIndex })
                        : row[dataKey];

                      return (
                        <div
                          key={index}
                          className={classNames(css.column, { [css.columnMinWidth]: isScrollable })}
                          style={{ flex, textAlign: align }}
                        >
                          <Body className={classNames(fontColour.secondary, css.cell)}>{content || ''}</Body>
                        </div>
                      );
                    })}
                </div>
              );
            })}
          </div>
        </div>
      )}
      {empty && <SubHeader className={classNames(fontColour.secondary, css.emptyMessage)}>{emptyMessage}</SubHeader>}
    </div>
  );
}

Table.propTypes = {
  children: PropTypes.array,
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  emptyMessage: PropTypes.string.isRequired,
  className: PropTypes.string,
  hideHeader: PropTypes.bool,
  onRowClick: PropTypes.func,
  headerClassName: PropTypes.string,
  rowClassName: PropTypes.string,
  dataGaCategory: PropTypes.string,
  isScrollable: PropTypes.bool,
};
