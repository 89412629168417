import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import InfiniteScroll from 'react-infinite-scroller';

import LoadingIndicator from 'components/molecules/LoadingIndicator';

import css from './styles.scss';
import Person from '../Person';

const NONE_AVAILABLE = 'No person available.';

function isSelected(user, selectedUsers) {
  return selectedUsers.filter((selectedUser) => selectedUser.get('userId') === user.get('userId')).size !== 0;
}

export default class People extends Component {
  constructor() {
    super();

    this.loadMoreUsers = this.loadMoreUsers.bind(this);
  }

  loadMoreUsers() {
    const { bookingWithPagination, filter, loadMoreContacts } = this.props;
    if (bookingWithPagination.get('hasNextPage')) {
      const pagination = {
        pageNumber: bookingWithPagination.get('pageNumber') + 1,
        pageSize: bookingWithPagination.get('pageSize'),
      };
      loadMoreContacts(filter, pagination);
    }
  }

  render() {
    const { people, selectedBookWith, togglePersonSelected, canSelectMorePeople, bookingWithPagination } = this.props;

    return (
      <div className={css.peopleList} data-appcues-id="people">
        {people.size ? (
          <InfiniteScroll
            initialLoad={false}
            loadMore={this.loadMoreUsers}
            hasMore={bookingWithPagination.get('hasNextPage')}
            useWindow={false}
            loader={<LoadingIndicator key="loader" className={css.loadingIndicator} colour="hint" />}
          >
            {people
              .groupBy((person) => person.get('userId'))
              .map((group) => group.first())
              .toList()
              .map((person) => {
                return (
                  !isSelected(person, selectedBookWith) && (
                    <Person
                      key={person.get('userId')}
                      person={person}
                      selected={isSelected(person, selectedBookWith)}
                      togglePersonSelected={togglePersonSelected}
                      canSelectMorePeople={canSelectMorePeople}
                    />
                  )
                );
              })}
          </InfiniteScroll>
        ) : (
          <div className={css.noneAvailable}>{NONE_AVAILABLE}</div>
        )}
      </div>
    );
  }
}

People.propTypes = {
  people: ImmutablePropTypes.list.isRequired,
  canSelectMorePeople: PropTypes.bool.isRequired,
  selectedBookWith: ImmutablePropTypes.list.isRequired,
  togglePersonSelected: PropTypes.func.isRequired,
  bookingWithPagination: ImmutablePropTypes.map.isRequired,
  loadMoreContacts: PropTypes.func.isRequired,
  filter: PropTypes.object,
};
