import { connect } from 'react-redux';
import componentKeys from 'constants/componentKeys';
import { Acl, permissionTypes } from '@crimson-education/common-config';
import { getMetaItem, selectUserPermissions } from 'selectors/meta';
import { fetchTasksByUserId, createTask, createTaskRedux, updateTask, deleteTask } from 'ducks/task';
import { showPageLoader, updateMeta } from 'ducks/meta';
import { getRoadmapIdByTasksListUserId } from 'selectors/roadmap';
import { fetchRoadmapByUserId } from 'ducks/roadmap';
import {
  getTasksWithNoDate,
  getOverdueTasks,
  getTasksInSevenDays,
  getTasksInThirtyDays,
  getTasksInLater,
  getCompletedSortedTasks,
} from 'selectors/task';
import { withAppContext } from 'components/enhancers/AppContext';

import TaskList from './TaskList';

const mapStateToProps = (state, props) => {
  const userId = props.userId || props.match.params.userId;
  return {
    roadmapId: getRoadmapIdByTasksListUserId(state),
    userId,
    tasksFetched: getMetaItem(componentKeys.TASKS_FETCHED)(state),
    tasksWithNoDate: getTasksWithNoDate(state),
    tasksOverdue: getOverdueTasks(state),
    tasksInSevenDays: getTasksInSevenDays(state),
    tasksInThirtyDays: getTasksInThirtyDays(state),
    tasksInLater: getTasksInLater(state),
    tasksCompleted: getCompletedSortedTasks(state),
    loginUserPermissions: selectUserPermissions(state),
    canEdit:
      props.canEdit ||
      Acl.checkPermission(selectUserPermissions(state), permissionTypes.EDIT_ROADMAP) ||
      Acl.checkPermission(selectUserPermissions(state), permissionTypes.EDIT_ALL_TASKS),
    canDelete:
      props.canDelete ||
      Acl.checkPermission(selectUserPermissions(state), permissionTypes.DELETE_ROADMAP_ELEMENTS) ||
      Acl.checkPermission(selectUserPermissions(state), permissionTypes.DELETE_ALL_TASKS),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createTask: (task) => dispatch(createTask(task)),
    createTaskRedux: (task) => dispatch(createTaskRedux(task)),
    updateTask: (taskUpdates) => dispatch(updateTask(taskUpdates)),
    deleteTask: (taskId) => dispatch(deleteTask(taskId)),
    fetchTasksByUserId: showPageLoader((userId) => dispatch(fetchTasksByUserId(userId)), dispatch),
    fetchRoadmapByUserId: showPageLoader((userId) => dispatch(fetchRoadmapByUserId(userId)), dispatch),
    setTasksListUser: (userId) => dispatch(updateMeta(componentKeys.TASKS_LIST_USER, userId)),
  };
};

export default withAppContext(connect(mapStateToProps, mapDispatchToProps)(TaskList));
