import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as Icons from 'components/atoms/icons';
import { Body } from 'components/atoms/typography';

import fontColour from 'components/atoms/typography/fontColour.scss';
import css from './style.scss';
import { asButton } from '../../../utils/accessibility';

export default function IconTextButton(props) {
  const {
    className,
    children,
    onClick,
    icon,
    dataTestId,
    style,
    renderIcon,
    textClassName,
    isDisabled,
    isReversed,
  } = props;

  let Icon;

  if (!renderIcon) {
    Icon = Icons[icon];
  }

  return (
    <div
      {...asButton(isDisabled ? undefined : onClick)}
      className={classNames({ [css.isDisabled]: isDisabled, [css.reversed]: isReversed }, css.button, className)}
      data-test-id={dataTestId}
    >
      {renderIcon ? (
        renderIcon()
      ) : (
        <Icon
          className={classNames(children && css.icon, fontColour.primary, css.paddingLeft, {
            [css.disabledText]: isDisabled,
            [css.reversed]: isReversed,
          })}
          style={style || { height: '1.6rem', width: '1.6rem' }}
        />
      )}
      {children && (
        <Body className={classNames({ [css.disabledText]: isDisabled }, textClassName)} bold>
          {children}
        </Body>
      )}
    </div>
  );
}

IconTextButton.defaultProps = {
  textClassName: css.backButtonText,
};

IconTextButton.propTypes = {
  className: PropTypes.string,
  textClassName: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node,
  onClick: PropTypes.func,
  icon: PropTypes.string,
  isReversed: PropTypes.bool,
  dataTestId: PropTypes.string,
  renderIcon: PropTypes.func,
  isDisabled: PropTypes.bool,
};
