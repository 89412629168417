import React from 'react';

type CalendarIconProps = {
  width?: number;
  height?: number;
};
const CalendarIcon = ({ width = 20, height = 20 }: CalendarIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8333 3.33334H15V1.66667H13.3333V3.33334H6.66667V1.66667H5V3.33334H4.16667C3.24167 3.33334 2.50833 4.08334 2.50833 5.00001L2.5 16.6667C2.5 17.5833 3.24167 18.3333 4.16667 18.3333H15.8333C16.75 18.3333 17.5 17.5833 17.5 16.6667V5.00001C17.5 4.08334 16.75 3.33334 15.8333 3.33334ZM15.8333 16.6667H4.16667V8.33334H15.8333V16.6667ZM7.5 11.6667H5.83333V10H7.5V11.6667ZM10.8333 11.6667H9.16667V10H10.8333V11.6667ZM14.1667 11.6667H12.5V10H14.1667V11.6667ZM7.5 15H5.83333V13.3333H7.5V15ZM10.8333 15H9.16667V13.3333H10.8333V15ZM14.1667 15H12.5V13.3333H14.1667V15Z"
        fill="#464AC9"
      />
    </svg>
  );
};

export default CalendarIcon;
