import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';
import { gql } from '@apollo/client';
import { createClient } from '../utils';

const { api } = getConfig();

const client = createClient(api.graphQLEndpoint);

const participantsTypeCalendar = gql`
  fragment participantsTypeCalendar on eventTypeCalendar {
    participants {
      userId
      firstName
      lastName
      userRoles
      roles {
        isPrimary
        roleId
        role {
          name
        }
      }
      profileImageUrl
      timezone
      isBetaUser
    }
  }
`;

const bookingEventCalendar = gql`
  ${participantsTypeCalendar}
  fragment bookingEventCalendar on eventTypeCalendar {
    id
    name
    description
    status
    eventStatus
    start
    end
    reportVersion
    creatorUserId
    recipientUserId
    participantUserIds
    ...participantsTypeCalendar
    otherUserId
    otherUser {
      userId
      firstName
      lastName
      userRoles
      roles {
        isPrimary
        roleId
        role {
          name
        }
      }
      profileImageUrl
    }
    type
    itemId
    subject {
      id
      name
      start
    }
    zoomMeetingUrl
    attendance {
      id
      eventId
      userId
      otherUserId
      start
      end
      status
    }
    hasAgenda
    hasNotes
    tasksCount
  }
`;

const bookingReasonOptionType = gql`
  fragment bookingReasonOptionType on bookingReasonOptionType {
    id
    reason
    type
    order
    requiresComments
    status
  }
`;

export const bookingTypeUser = gql`
  fragment bookingTypeUser on bookingTypeUser {
    calendarId
    eventId
    status
    participantType
    reason
    updatedByUserId
    createdAt
    updatedAt
    id
    zoomMeetingUrl
    userId
    timezone
    name
    description
    email
    zoomBatch
  }
`;

const fetchCancellationReasonOptions = gql`
  ${bookingReasonOptionType}
  query getCancellationReasonOptions($userId: String!, $eventId: String!) {
    reasons: getCancellationReasonOptions(userId: $userId, eventId: $eventId) {
      ...bookingReasonOptionType
    }
  }
`;

const fetchReasonsForAbsence = gql`
  ${bookingReasonOptionType}
  query fetchReasonsForAbsence($eventId: String!) {
    reasons: getReasonsForSessionAbsence(eventId: $eventId) {
      ...bookingReasonOptionType
    }
  }
`;

const fetchRemainingHoursBySubjectId = gql`
  query getRemainingHoursBySubjectId($itemId: Int!) {
    remainingHours: getRemainingHoursBySubjectId(itemId: $itemId)
  }
`;

const makeBooking = gql`
  ${bookingEventCalendar}
  mutation makeBooking(
    $senderUserId: String!
    $receiverUserId: String!
    $start: String!
    $end: String!
    $name: String!
    $description: String
    $type: eventTypeType!
    $itemId: Int
  ) {
    booking: makeBooking(
      studentId: $senderUserId
      receiverUserId: $receiverUserId
      start: $start
      end: $end
      name: $name
      description: $description
      type: $type
      itemId: $itemId
    ) {
      ...bookingEventCalendar
    }
  }
`;

const makeRecurringBookings = gql`
  ${bookingEventCalendar}
  mutation makeRecurringBookings(
    $senderUserId: String!
    $receiverUserId: String!
    $name: String!
    $description: String
    $type: eventTypeType!
    $itemId: Int
    $startEndTimeList: [bookingStartEndTime]!
    $recurringFrequency: String!
  ) {
    bookings: makeRecurringBookings(
      studentId: $senderUserId
      receiverUserId: $receiverUserId
      name: $name
      description: $description
      type: $type
      itemId: $itemId
      startEndTimeList: $startEndTimeList
      recurringFrequency: $recurringFrequency
    ) {
      ...bookingEventCalendar
    }
  }
`;

const confirmBooking = gql`
  mutation confirmBooking($eventId: String!, $participantUserId: String!) {
    booking: confirmBooking(eventId: $eventId, participantUserId: $participantUserId) {
      zoomMeetingUrl
      otherUserZoomUrl
    }
  }
`;

const editBooking = gql`
  ${bookingEventCalendar}
  mutation editBooking(
    $eventId: String!
    $senderUserId: String!
    $receiverUserId: String!
    $start: String!
    $end: String!
    $name: String!
    $description: String
    $type: eventTypeType!
    $itemId: Int
  ) {
    booking: editBooking(
      eventId: $eventId
      studentId: $senderUserId
      receiverUserId: $receiverUserId
      start: $start
      end: $end
      name: $name
      description: $description
      type: $type
      itemId: $itemId
    ) {
      ...bookingEventCalendar
    }
  }
`;

const cancelBooking = gql`
  mutation cancelBooking(
    $eventId: String!
    $senderUserId: String
    $cancellationReasonId: String
    $cancellationComments: String
    $isPenaltySession: Boolean
  ) {
    booking: cancelBooking(
      eventId: $eventId
      studentId: $senderUserId
      cancellationReasonId: $cancellationReasonId
      cancellationComments: $cancellationComments
      isPenaltySession: $isPenaltySession
    )
  }
`;

const autoCompleteBooking = gql`
  mutation acceptAutoComplete($eventId: String!) {
    booking: acceptAutoComplete(eventId: $eventId) {
      calendarId
      eventId
      status
      participantType
    }
  }
`;

const declineBooking = gql`
  mutation declineBooking($eventId: String!, $participantUserId: String!, $reason: String!) {
    booking: declineBooking(eventId: $eventId, participantUserId: $participantUserId, reason: $reason)
  }
`;

const completeBooking = gql`
  mutation completeBooking($eventId: String!, $otherUserId: String!) {
    booking: completeBooking(eventId: $eventId, otherUserId: $otherUserId)
  }
`;

const getStudentsOfSessionConfirmedWithSSMs = gql`
  query getStudentsOfSessionConfirmedWithSSMs($startDay: String!, $endDay: String!) {
    getStudentsOfSessionConfirmedWithSSMs(startDay: $startDay, endDay: $endDay)
  }
`;
export default {
  fetchCancellationReasonOptions: (userId, eventId) =>
    client.query(fetchCancellationReasonOptions, { userId, eventId }),
  fetchReasonsForAbsence: (eventId) => client.query(fetchReasonsForAbsence, { eventId }),
  fetchRemainingHoursBySubjectId: (itemId) => client.query(fetchRemainingHoursBySubjectId, { itemId }),
  makeBooking: ({ senderUserId, receiverUserId, start, end, name, description, type, itemId }) =>
    client.query(makeBooking, { senderUserId, receiverUserId, start, end, name, description, type, itemId }),
  makeRecurringBookings: ({
    senderUserId,
    receiverUserId,
    name,
    description,
    type,
    itemId,
    startEndTimeList,
    recurringFrequency,
  }) =>
    client.query(makeRecurringBookings, {
      senderUserId,
      receiverUserId,
      name,
      description,
      type,
      itemId,
      startEndTimeList,
      recurringFrequency,
    }),
  confirmBooking: (id, participantUserId) =>
    client.query(confirmBooking, { eventId: id.toString(), participantUserId }),
  editBooking: ({ id, senderUserId, receiverUserId, start, end, name, description, type, itemId }) =>
    client.query(editBooking, {
      eventId: id,
      senderUserId,
      receiverUserId,
      start,
      end,
      name,
      description,
      type,
      itemId,
    }),
  cancelBooking: (id, senderUserId, reasonId, comments, isPenaltySession) =>
    client.query(cancelBooking, {
      eventId: id,
      senderUserId,
      cancellationReasonId: reasonId,
      cancellationComments: comments,
      isPenaltySession,
    }),
  declineBooking: (id, participantUserId, reason) =>
    client.query(declineBooking, { eventId: id, participantUserId, reason }),
  completeBooking: ({ eventId, otherUserId }) => client.query(completeBooking, { eventId, otherUserId }),
  autoCompleteBooking: (eventId) => client.query(autoCompleteBooking, { eventId }),
  getStudentsOfSessionConfirmedWithSSMs: (startDay, endDay) =>
    client.query(getStudentsOfSessionConfirmedWithSSMs, { startDay, endDay }),
};
