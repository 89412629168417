import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ProgressBar from 'components/generic/ProgressBar';
import { VerticalCell } from 'components/generic/Table';
import { decimalHoursToHoursAndMinutes, buildDynamicDurationString } from 'utils/lessonUtils';

import css from './styles.scss';

const SubjectProgressBar = (props) => {
  const { isUnlimited, pendingUnits, remainingUnits, totalUnits, units, isUserActive } = props;

  const availableUnits = remainingUnits - pendingUnits;

  const percentRemaining = isUnlimited ? 100 : (availableUnits / totalUnits) * 100;
  const percentPending = isUnlimited ? 0 : (remainingUnits / totalUnits) * 100;

  const isBelowDepletionThreshold = percentRemaining <= 20;
  const available = decimalHoursToHoursAndMinutes(availableUnits);
  const pending = decimalHoursToHoursAndMinutes(pendingUnits);
  const total = decimalHoursToHoursAndMinutes(totalUnits);

  const isTimeLeft = available.hours !== 0 || available.minutes !== 0;

  const availableLabel =
    !isUnlimited && (isTimeLeft ? `${buildDynamicDurationString(available, units)} available` : '0h available');

  const pendingLabel =
    !isUnlimited && pendingUnits !== 0 ? `${buildDynamicDurationString(pending, units)} pending` : '';

  const totalLabel = !isUnlimited ? `${buildDynamicDurationString(total, units)} total` : 'Unlimited';

  return (
    <VerticalCell>
      <ProgressBar
        percent={percentRemaining}
        percentSecondary={percentPending}
        warningPercent={20}
        showWarningColor
        isUserActive={isUserActive}
      />
      <div className={css.hoursDisplay}>
        <div
          className={classNames(css.hoursAvailable, {
            [css.error]: (!isUnlimited && availableUnits < 0) || isBelowDepletionThreshold,
          })}
        >
          {availableLabel}
        </div>
        <div className={css.hoursPending}>{pendingLabel}</div>
        <div className={css.hoursTotal}>{totalLabel}</div>
      </div>
    </VerticalCell>
  );
};

SubjectProgressBar.defaultProps = {
  isUnlimited: false,
  pendingUnits: 0,
  remainingUnits: 0,
  totalUnits: 0,
  units: ['h', 'm'],
  isUserActive: true,
};

SubjectProgressBar.propTypes = {
  isUnlimited: PropTypes.bool,
  pendingUnits: PropTypes.number,
  remainingUnits: PropTypes.number,
  totalUnits: PropTypes.number,
  units: PropTypes.array,
  isUserActive: PropTypes.bool,
};

export default SubjectProgressBar;
