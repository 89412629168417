import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Acl, permissionTypes, roleTypes } from '@crimson-education/common-config';
import moment from 'moment';
import titleCase from 'titlecase';
import { Table, Column } from 'components/molecules/Table';
import Header from 'components/molecules/Header';
import Button from 'components/molecules/Button';
import { Body, SubHeader } from 'components/atoms/typography';
import { Notes } from 'components/atoms/graphics';
import EmptyState from 'components/molecules/EmptyState';
import { getFullNameFromNames, displayDate } from 'utils/utils';
import { timeString } from 'utils/calendarUtils';
import { lessonState } from 'utils/lessonUtils';
import css from './styles.scss';
import { handleEnter } from '../../../utils/accessibility';

function displayStatus(status) {
  let className;
  switch (status) {
    case lessonState.UPCOMING.statusName:
      className = css.upcoming;
      break;
    case lessonState.IN_PROGRESS.statusName:
      className = css.inProgress;
      break;
    case lessonState.COMPLETED.statusName:
      className = css.completed;
      break;
    case lessonState.ABSENT.statusName:
      className = css.absent;
      break;
    case lessonState.PENALTY.statusName:
      className = css.penalty;
      break;
    default:
      className = css.completed;
  }
  return <span className={className}>{status}</span>;
}

function displayDates(rowData) {
  const start = rowData.get('start');
  const end = rowData.get('end');
  const startDay = moment(start);
  const endDay = moment(end);
  const daysText = titleCase(
    startDay.isSame(endDay, 'day') ? displayDate(start) : `${displayDate(start)} - ${displayDate(end)}`,
  );
  if (rowData.getIn(['tableStatus', 'statusName']) === lessonState.ABSENT.statusName) {
    return <span className={css.absent}>{daysText}</span>;
  }
  return daysText;
}

function displayTime(rowData) {
  const time = timeString(rowData.get('start'), rowData.get('end'));
  if (rowData.getIn(['tableStatus', 'statusName']) === lessonState.ABSENT.statusName) {
    return <span className={css.absent}>{time}</span>;
  }
  return time;
}

function canViewTutorProfile(props, tutorId) {
  const { loginUserRoles } = props;
  if (Acl.isTutor(loginUserRoles)) {
    const loggedInUserId = props.tutorId;
    return loggedInUserId === tutorId;
  }
  return true;
}

function MyLessonsTable(props) {
  const {
    allLessons,
    titles,
    loginUserRoles,
    loginUserPermissions,
    lessonsFetched,
    activePackage,
    history,
    selectBookingAs,
  } = props;
  const lessons = allLessons;
  const isStudent = Acl.isStudent(loginUserRoles);
  const isTutor = Acl.isTutor(loginUserRoles);
  let navText;
  let navUrl;

  if (isStudent) {
    navText = 'Subjects';
    navUrl = '/subjects';
  } else if (isTutor) {
    navText = 'Students';
    navUrl = '/students';
  } else if (Acl.isAdmin(loginUserRoles) || Acl.isHeadTutor(loginUserRoles)) {
    navText = 'Our People';
    navUrl = '/our-people';
  }

  const isEmpty = lessonsFetched && (!lessons || lessons.size === 0);
  const hasTutor = activePackage && activePackage.get('contract') && activePackage.get('contract').size > 0;
  const canMakeBooking =
    hasTutor && loginUserRoles.some((role) => role === roleTypes.TUTOR || role === roleTypes.STUDENT);

  const isHigherLevel =
    Acl.isAdmin(loginUserRoles) ||
    Acl.isHeadTutor(loginUserRoles) ||
    Acl.checkRole(loginUserRoles, roleTypes.STRATEGIST);
  const viewSessionReport = (rowData) => {
    if (isHigherLevel) {
      selectBookingAs(rowData.getIn(['client', 'userId']));
    }
    history.push(`/session/${rowData.get('eventId')}`);
  };

  return (
    <div data-ga-category="Lesson Table">
      <Header
        title={titles.title}
        isBackButtonVisible
        backButtonText={navText}
        backButtonLink={navUrl}
        subtitles={titles.subtitles}
        isBorderVisible={isEmpty}
      />
      <EmptyState showIf={isEmpty}>
        <Notes className={css.emptyStateIcon} />
        <div className={css.emptyStatePadding} />
        <SubHeader>No bookings have been made yet</SubHeader>
        <div className={css.emptyStatePadding} />
        {canMakeBooking ? (
          <div>
            <Body>
              All of your upcoming bookings and completed sessions will live here. You can book your first session here:
            </Body>
            <div className={css.emptyStatePadding} />
            <Link to="/calendar">
              <Button>Make a booking request</Button>
            </Link>
          </div>
        ) : (
          <div>
            <Body>All upcoming bookings and completed sessions will live here.</Body>
          </div>
        )}
      </EmptyState>
      {lessons && lessonsFetched && (
        <Table data={lessons.toList()} className={css.table} name="My Lessons" emptyMessage="">
          <Column
            dataKey="title"
            flex="2 0 0"
            renderer={({ rowData }) => `${rowData.get('title')}`}
            name="Lessons"
            tdClassName={css.lessonTd}
          />
          <Column name="Date" renderer={({ rowData }) => displayDates(rowData)} />
          <Column name="Time" renderer={({ rowData }) => displayTime(rowData)} />
          <Column
            name="Tutor"
            renderer={({ rowData }) => {
              return canViewTutorProfile(props, rowData.get('tutor').get('userId')) ? (
                <Link data-ga-label="Person" to={`/users/${rowData.get('tutor').get('userId')}`}>
                  {getFullNameFromNames([rowData.get('tutor').get('firstName'), rowData.get('tutor').get('lastName')])}
                </Link>
              ) : (
                `${getFullNameFromNames([rowData.get('tutor').get('firstName'), rowData.get('tutor').get('lastName')])}`
              );
            }}
          />
          <Column
            name="Status"
            renderer={({ rowData }) =>
              displayStatus(
                `${rowData.getIn(['tableStatus', 'statusName'])}${
                  (rowData.get('cancelledBy') !== null &&
                    rowData.getIn(['tableStatus', 'statusName']) === 'Penalty Session' &&
                    `\nCancelled by ${rowData.get('cancelledBy')}`) ||
                  ''
                }`,
              )
            }
          />
          <Column
            name=""
            flex="0.5 0 0"
            renderer={({ rowData }) => {
              const hasPermission = Acl.checkPermission(loginUserPermissions, permissionTypes.VIEW_ALL_LESSON_REPORTS);
              if (!isTutor && !hasPermission) return '';
              return (
                <div
                  role="button"
                  tabIndex={0}
                  className={css.sessionLink}
                  onClick={() => viewSessionReport(rowData)}
                  onKeyDown={handleEnter(() => viewSessionReport(rowData))}
                >
                  View report
                </div>
              );
            }}
          />
        </Table>
      )}
    </div>
  );
}

MyLessonsTable.propTypes = {
  allLessons: ImmutablePropTypes.map.isRequired,
  titles: PropTypes.object.isRequired,
  loginUserRoles: PropTypes.array.isRequired,
  loginUserPermissions: PropTypes.array.isRequired,
  lessonsFetched: PropTypes.bool.isRequired,
  activePackage: ImmutablePropTypes.map,
  history: PropTypes.object.isRequired,
  selectBookingAs: PropTypes.func,
};

export default withRouter(MyLessonsTable);
