import React from 'react';
import { SubHeader, Body } from 'components/atoms/typography';
import MessagingIcon from 'components/generic/Icons/Messaging';
import css from './styles.scss';

/** @type {React.SFC<{}>} */
const Landing = () => (
  <div className={css.landingContainer}>
    <MessagingIcon />
    <SubHeader dataTestId="welcomeMessageHeader" className={css.header}>
      Your messages will live here.
    </SubHeader>
    <Body dataTestId="welcomeMessageBody" className={css.body}>
      Search for your team members in the left sidebar to get a chat started!
    </Body>
  </div>
);

export default Landing;
