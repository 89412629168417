import Immutable from 'immutable';
import createReducer from 'utils/createReducer';
import { toasterMessages } from '@crimson-education/common-config';

import feedbackService from 'graphql/api/feedback';
import { formLoaded, formFail, formLoading, modalSuccess, updateMeta } from 'ducks/meta';
import { completeBooking } from 'ducks/booking';
import { fetchLessonsByEventId } from 'ducks/lesson';
import { removeReport } from 'ducks/incompleteReport';
import { formatGraphQLRequestError } from 'utils/graphql';
import { ADD_ENTITIES, addEntitiesWithNormalisation } from 'ducks/normalizr';
import { feedbackEntity } from 'schema';
import componentKeys from 'constants/componentKeys';

const FETCH_USER_RATING = 'feedback/FETCH_USER_RATING';
const FETCH_USER_RATING_COMPLETE = 'feedback/FETCH_USER_RATING_COMPLETE';
const FETCH_USER_RATING_ALL = 'feedback/FETCH_USER_RATING_ALL';
const FETCH_USER_RATING_ALL_COMPLETE = 'feedback/FETCH_USER_RATING_ALL_COMPLETE';
const FETCH_USER_REVIEW = 'feedback/FETCH_USER_REVIEW';
const FETCH_USER_REVIEW_COMPLETE = 'feedback/FETCH_USER_REVIEW_COMPLETE';

const initialState = Immutable.fromJS({
  ratings: {},
});

export default createReducer(initialState, {
  [ADD_ENTITIES]: (state, action) => state.mergeDeep(action.payload.entities.feedback),
  [FETCH_USER_RATING]: (state, action) => state.mergeDeep(action.payload),
  [FETCH_USER_RATING_COMPLETE]: (state, action) => state.mergeDeep(action.payload),
  [FETCH_USER_RATING_ALL]: (state, action) => state.mergeDeep(action.payload),
  [FETCH_USER_RATING_ALL_COMPLETE]: (state, action) => state.mergeDeep(action.payload),
  [FETCH_USER_REVIEW]: (state, action) => state.mergeDeep(action.payload),
  [FETCH_USER_REVIEW_COMPLETE]: (state, action) => {
    if (action.payload.resetFilter) {
      return state.merge(action.payload);
    }
    return state.mergeDeep(action.payload);
  },
});

export function submitSessionFeedback(feedback) {
  const { booking } = feedback;

  return async (dispatch) => {
    dispatch(formLoading());
    dispatch(completeBooking(booking));

    try {
      const result = await feedbackService.createSessionFeedback(feedback);
      const { createSessionFeedback } = result;

      dispatch(addEntitiesWithNormalisation(createSessionFeedback, feedbackEntity));
      dispatch(modalSuccess(toasterMessages.feedbackSent()));
      dispatch(formLoaded());
    } catch (err) {
      dispatch(formFail([toasterMessages.feedbackNotSent(), formatGraphQLRequestError(err)]));
    }
  };
}

export function submitSessionReport(request) {
  return (dispatch) => {
    dispatch(formLoading());
    return feedbackService
      .submitSessionReport(request)
      .then(() => {
        dispatch(removeReport(request.eventId));
        dispatch(updateMeta(componentKeys.ACTIVE_SESSION_REPORT, null));
        dispatch(modalSuccess(toasterMessages.feedbackSessionReported()));
        dispatch(formLoaded());
        dispatch(fetchLessonsByEventId(request.eventId));
      })
      .catch((error) =>
        dispatch(formFail([toasterMessages.feedbackSessionNotReported(), formatGraphQLRequestError(error)])),
      );
  };
}

// It's different from tutor and student, it's for strategist and eduCo
export function createSessionFeedbackReport(request) {
  return (dispatch) => {
    dispatch(formLoading());
    return feedbackService
      .createSessionFeedbackReport(request)
      .then(() => {
        dispatch(removeReport(request.eventId));
        dispatch(updateMeta(componentKeys.ACTIVE_SESSION_REPORT, null));
        dispatch(modalSuccess(toasterMessages.feedbackSent()));
        dispatch(formLoaded());
        // dispatch(fetchLessonsByEventId(request.eventId));
      })
      .catch((error) =>
        dispatch(formFail([toasterMessages.feedbackSessionNotReported(), formatGraphQLRequestError(error)])),
      );
  };
}

export function fetchUserRatingAllTypes(userId) {
  return async (dispatch) => {
    dispatch({ type: FETCH_USER_RATING_ALL, payload: { isLoading: true } });

    const result = await Promise.all([
      feedbackService.fetchUserRating({ userId, reportType: 'all' }),
      feedbackService.fetchUserRating({ userId, reportType: 'last6months' }),
    ]);

    const ratings = result.reduce((a, x) => {
      const rating = x.userFeedbackRating;

      if (!a[rating.userId]) {
        a[rating.userId] = {};
      }

      a[rating.userId][rating.reportType] = {
        loading: false,
        ...rating,
      };

      return a;
    }, {});

    dispatch({ type: FETCH_USER_RATING_ALL_COMPLETE, payload: { ratings } });
  };
}

export function fetchUserRating(userId, reportType = 'all') {
  return async (dispatch) => {
    const loadingState = {
      [reportType]: {
        loading: true,
      },
    };

    dispatch({ type: FETCH_USER_RATING, payload: { isLoading: true, ratings: { [userId]: loadingState } } });

    const ratingResult = await feedbackService.fetchUserRating({ userId, reportType });
    const rating = ratingResult && ratingResult.userFeedbackRating;

    const newState = {
      [reportType]: {
        loading: false,
        ...(rating || {}),
      },
    };

    dispatch({ type: FETCH_USER_RATING_COMPLETE, payload: { ratings: { [userId]: newState } } });
  };
}

export function fetchUserReview(userId, filter, pagination, resetFilter = false) {
  return async (dispatch) => {
    dispatch({ type: FETCH_USER_REVIEW, payload: { isLoading: true } });

    const result = await feedbackService.fetchUserReview({ userId, filter, pagination });

    const reviews = result.pagedUserReview.reviews.reduce((acc, curr) => {
      acc[curr.id] = curr;
      return acc;
    }, {});

    const newState = {};
    newState[userId] = {
      reviews,
      pagination: result.pagedUserReview.pagination,
      total: result.pagedUserReview.total,
    };

    dispatch({ type: FETCH_USER_REVIEW_COMPLETE, payload: { isLoading: false, reviews: newState, resetFilter } });
  };
}
