import { TeamMember } from '../type';
import { Acl } from '@crimson-education/common-config';

export const isValidAppYear = (appYear: string) => {
  const splited = appYear.split('/');
  return splited.length === 2 && splited.every((o) => !isNaN(+o));
};
/**
 *
 *
 * @param {string} appYear
 * @return {*}
 * [appYear, intakeYear]
 */
export const deconstructAppYear = (appYear: string) => {
  return appYear.split('/').map((o) => +o);
};

export const getUserDisplayName = <T extends { firstName: string; lastName: string }>(o: T) =>
  (o.firstName + ' ' + o.lastName).trim();

export const getTeamMemberTitle = (teamMember: TeamMember) => {
  const roleIds = teamMember.roles.map((o) => o.roleId);
  const isSSM = Acl.isCaseManager(roleIds);
  if (isSSM) return 'SSM';
  const isStrategist = Acl.isStrategist(roleIds);
  if (isStrategist) {
    if (!teamMember.staffInfo?.division?.length) return;
    return teamMember.staffInfo.division.join(',') + ' Strategist';
  }
  return undefined;
};
