import decodeJwt from 'jwt-decode';
import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';
import { getBearer, getCachedProfile, storeProfile } from './auth';
import usersAPI from '../../graphql/api/user';

export async function getProfile() {
  const { auth0 } = getConfig();

  const token = getBearer();
  if (!token) return null;

  const cachedProfile = getCachedProfile(token);
  if (cachedProfile) {
    return cachedProfile;
  }

  const profile = decodeJwt(token);
  if (!profile) return null;

  let sub = profile.sub;

  const impersonationInfo = profile['https://crimsoneducation.org/impersonate'];
  if (impersonationInfo) {
    sub = impersonationInfo.sub;
  }

  profile.userId = sub.replace('|', '-');
  profile.lmsSettings = profile[`${auth0.audience}/lmsSettings`];

  // Fetch authorization info for the user.
  const user = await usersAPI
    .fetchLoginUserAuthorization()
    .then((data) => data && data.user)
    .catch((e) => {
      // eslint-disable-next-line no-console
      console.error(e);
      return null;
    });

  if (!user || user.status !== 'active') {
    return null;
  }

  profile.status = user.status;
  profile.isAllTags = user.isAllTags;
  profile.isMultiTenant = user.isMultiTenant;
  profile.myTags = user.myTags;

  const roleIds = user.roles.sort((a, b) => b.isPrimary - a.isPrimary).map((ur) => ur.roleId);

  // New format.
  profile.roleInfo = user.roles || [];
  profile.permissionInfo = user.permissions || [];

  // Old format.
  profile.role = roleIds[0];
  profile.userRoles = roleIds;
  profile.permissions = profile.permissionInfo.map((perm) => perm.legacyId).filter((perm) => !!perm);

  // Legacy key, can remove one refactored auth0 rules.
  delete profile[`${auth0.audience}/authorization`];
  delete profile[`${auth0.audience}/lmsSettings`];

  storeProfile(token, profile);
  return profile;
}
