import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { MoreHoriz, PeopleGroup } from 'components/atoms/icons';

import css from './styles.scss';

export default function CGAReportDetailsPreview(props) {
  const { title, subtitle, studentCount, reportDate } = props;

  return (
    <div className={classNames(css.details)}>
      <div className={classNames(css.reportDate, css.detailsItem)}>
        <label className={classNames(css.reportDetailsText, css.subtitle)}>{reportDate}</label>
        <div className={classNames(css.reportUsers)}>
          <PeopleGroup fontSize="large" className={classNames(css.groupIcon, css.reportUsersItems)} />
          <label className={classNames(css.reportUsersItems, css.subtitle)}>{studentCount}</label>
        </div>
      </div>
      <div className={classNames(css.reportDetailsText, css.title)}>{title}</div>
      <MoreHoriz fontSize="large" className={classNames(css.moreOptionsIcon)} />
    </div>
  );
}

CGAReportDetailsPreview.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  studentCount: PropTypes.number,
  reportDate: PropTypes.string,
};
