import styled from 'styled-components';
import BannerRightGraph1 from 'components/atoms/graphics/ApplicationTracker/bannerRightGraph1';
import BannerRightGraph2 from 'components/atoms/graphics/ApplicationTracker/bannerRightGraph2';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  height: 195px;
  position: relative;
  z-index: 10;
`;

export const BackgroundImg = styled.img`
  width: 100%;
  height: 195px;
  display: flex;
  position: absolute;
  z-index: 1;
`;

export const PeopleImg = styled.img`
  display: flex;
  position: absolute;
  right: 15%;
  bottom: 0;
  object-fit: cover;
  height: 90%;
  width: auto;
  z-index: 4;
`;

export const Graph4 = styled.img`
  display: flex;
  position: absolute;
  right: 0px;
  bottom: 0px;
  height: 100%;
  width: 350px;
  z-index: 5;
  object-fit: cover;
  mix-blend-mode: color-burn;
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 36px;
  font-family: 'Montserrat';
  line-height: 44px;
  color: #ffffff;
  margin-left: 76px;
`;

export const Content = styled.div`
  font-weight: 500;
  font-family: 'Montserrat';
  font-size: 16px;
  line-height: 24px;
  color: #e3e7ed;
  margin-top: 20px;
  margin-left: 76px;
`;

export const TextContainer = styled.div`
  height: 195px;
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Graph1 = styled(BannerRightGraph1)`
  z-index: 3;
  display: flex;
  position: absolute;
  bottom: 0px;
  right: 100px;
`;

export const Graph2 = styled(BannerRightGraph2)`
  z-index: 3;
  display: flex;
  position: absolute;
  right: 30px;
  top: 15px;
`;

export const Graph3 = styled.img`
  z-index: 5;
  display: flex;
  position: absolute;
  bottom: 13px;
  right: 30px;
  width: 51px;
  height: 18px;
  object-fit: contain;
  mix-blend-mode: color-burn;
`;
