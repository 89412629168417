import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Popover from '@material-ui/core/Popover';
import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';
import MetabaseDashboard from 'components/atoms/graphics/MetabaseDashboard';
import css from './styles.scss';
import { handleEnter } from '../../../../../utils/accessibility';
const config = getConfig();
import { selectUserProfile } from 'selectors/meta';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import profile from 'graphql/api/profile';
import { roleTypes } from '@crimson-education/common-config';
import { featureSwitches, useFeatureFlag } from 'featureSwitches';
import { canViewAppTrackerStaffDashboard } from 'utils/profileTabMap';

const ALL_USER_REGION = {
  Dulwich: 'Dulwich',
};
export default function MetaBasePopover({ className, linkClassName, onClick }) {
  const [metabaseOptionVisible, setMetabaseOptionVisible] = useState(false);
  const [anchor, setAnchor] = useState(null);
  const history = useHistory();
  const user = useSelector(selectUserProfile);
  const userRoles = Array.from(user.get('userRoles'));
  const [userTile, setUserTitle] = useState('');
  const [userDivision, setUserDivision] = useState([]);
  const [userRegion, setUserRegion] = useState('');
  useEffect(() => {
    async function fetch() {
      const staffInfo = await profile?.fetchStaffInformation(user.get('userId'));
      if (staffInfo?.getStaffInfo) {
        setUserTitle(staffInfo?.getStaffInfo?.title || '');
        setUserRegion(staffInfo?.getStaffInfo?.region);
        setUserDivision(
          staffInfo?.getStaffInfo?.division?.length !== 0
            ? staffInfo?.getStaffInfo?.division.filter((division) => ['UK', 'EU', 'US', 'Rise'].includes(division))
            : [],
        );
      }
    }
    fetch();
  }, [user, profile, setUserDivision, setUserTitle]);
  const STAFF_DASHBOARD = useFeatureFlag('NEW_APPLICATION_TRACKER_PHASE_5');
  return (
    <div
      role="button"
      tabIndex={0}
      onClick={(e) => {
        onClick(e);
        setAnchor(e.currentTarget);
        setMetabaseOptionVisible(!metabaseOptionVisible);
      }}
      onKeyDown={handleEnter((e) => {
        onClick(e);
        setAnchor(e.currentTarget);
        setMetabaseOptionVisible(!metabaseOptionVisible);
      })}
      className={className}
      data-appcues-id="tasks"
    >
      {!config.dulwich && (
        <div className={linkClassName} data-tip="metabase" data-for="navigationToMetabase">
          <MetabaseDashboard />
          <span>Dashboards</span>
        </div>
      )}
      <Popover
        id="metabase-popover"
        open={metabaseOptionVisible}
        anchorEl={anchor}
        onClose={() => setMetabaseOptionVisible(false)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {userRoles.includes(roleTypes.CASE_MANAGER) && userTile === 'associate' && (
          <div className={css.popover}>
            <div
              role="button"
              tabIndex={0}
              className={css.link}
              onKeyDown={handleEnter(() => history.push('/metabaseDashboard', { type: 'SSA_Students' }))}
              onClick={() => history.push('/metabaseDashboard', { type: 'SSA_Students' })}
              data-test-id="navMetabaseDashboard"
              data-appcues-id="metabaseDashboard"
            >
              <span>SSA Students</span>
            </div>
            <div
              role="button"
              tabIndex={0}
              className={css.link}
              onKeyDown={handleEnter(() => history.push('/metabaseDashboard', { type: 'SSA_KPI' }))}
              onClick={() => history.push('/metabaseDashboard', { type: 'SSA_KPI' })}
              data-test-id="navMetabaseDashboard"
              data-appcues-id="metabaseDashboard"
            >
              <span>SSA KPIs</span>
            </div>
            {userRegion === ALL_USER_REGION.Dulwich && (
              <div
                role="button"
                tabIndex={0}
                className={css.link}
                onKeyDown={handleEnter(() => history.push('/metabaseDashboard', { type: 'SSM_DULWICH' }))}
                onClick={() => history.push('/metabaseDashboard', { type: 'SSM_DULWICH' })}
              >
                <span>Dulwich SSA KPIs</span>
              </div>
            )}
          </div>
        )}
        {userRoles.includes(roleTypes.CASE_MANAGER) && userTile !== 'associate' && (
          <div className={css.popover}>
            <div
              role="button"
              tabIndex={0}
              className={css.link}
              onKeyDown={handleEnter(() => history.push('/metabaseDashboard', { type: 'SSM_Students' }))}
              onClick={() => history.push('/metabaseDashboard', { type: 'SSM_Students' })}
              data-test-id="navMetabaseDashboard"
              data-appcues-id="metabaseDashboard"
            >
              <span>SSM Students</span>
            </div>
            <div
              role="button"
              tabIndex={0}
              className={css.link}
              onKeyDown={handleEnter(() => history.push('/metabaseDashboard', { type: 'SSM_KPI' }))}
              onClick={() => history.push('/metabaseDashboard', { type: 'SSM_KPI' })}
            >
              <span>SSM KPIs</span>
            </div>
            {userRegion === ALL_USER_REGION.Dulwich && (
              <div
                role="button"
                tabIndex={0}
                className={css.link}
                onKeyDown={handleEnter(() => history.push('/metabaseDashboard', { type: 'SSM_DULWICH' }))}
                onClick={() => history.push('/metabaseDashboard', { type: 'SSM_DULWICH' })}
              >
                <span>Dulwich SSM KPIs</span>
              </div>
            )}
          </div>
        )}
        {userRoles.includes(roleTypes.STRATEGIST) && (
          <div className={css.popover}>
            <div
              role="button"
              tabIndex={0}
              className={css.link}
              onKeyDown={handleEnter(() => history.push('/metabaseDashboard', { type: 'STRATEGIST_Students' }))}
              onClick={() => history.push('/metabaseDashboard', { type: 'STRATEGIST_Students' })}
              data-test-id="navMetabaseDashboard"
              data-appcues-id="metabaseDashboard"
            >
              <span>Strategist Students</span>
            </div>
            {userDivision.length !== 0 &&
              userDivision.map((division) => (
                <div
                  key={division}
                  role="button"
                  tabIndex={0}
                  className={css.link}
                  onKeyDown={handleEnter(() =>
                    history.push('/metabaseDashboard', { type: `STRATEGIST_${division}_KPI` }),
                  )}
                  onClick={() => history.push('/metabaseDashboard', { type: `STRATEGIST_${division}_KPI` })}
                >
                  <span>{division === 'Rise' ? `${division} Strategist` : division} KPIs</span>
                </div>
              ))}
          </div>
        )}
        {STAFF_DASHBOARD && canViewAppTrackerStaffDashboard(userRoles) && (
          <div className={css.popover}>
            <div
              role="button"
              tabIndex={0}
              className={css.link}
              onKeyDown={handleEnter(() => history.push('/app-tracker-staff-dashboard/' + user.get('userId')))}
              onClick={() => history.push('/app-tracker-staff-dashboard/' + user.get('userId'))}
              // data-test-id="navAppTrackerStaffDashboard"
              // data-appcues-id="appTrackerStaffDashboard"
            >
              <span>App Tracker Dashboard</span>
            </div>
          </div>
        )}
      </Popover>
    </div>
  );
}

MetaBasePopover.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  linkClassName: PropTypes.string,
};
