import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';
import { gql } from '@apollo/client';
import { createClient } from '../utils';

const { api } = getConfig();

export const client = createClient(api.graphQLEndpoint);

const agendaItem = gql`
  fragment agendaItem on agendaItemType {
    id
    eventId
    text
    createdBy
    createdAt
    updatedAt
    html
    version
  }
`;

export const GET_AGENDA_BY_EVENT = gql`
  ${agendaItem}
  query getAgendaByEvent($eventId: String!) {
    agenda(eventId: $eventId) {
      ...agendaItem
    }
  }
`;

export const SAVE_AGENDA = gql`
  ${agendaItem}
  mutation setAgendaItem($id: ID, $eventId: String!, $text: String, $html: String, $version: Int, $overwrite: Boolean) {
    setAgendaItem(id: $id, eventId: $eventId, text: $text, html: $html, version: $version, overwrite: $overwrite) {
      ...agendaItem
    }
  }
`;

const deleteAgendaItems = gql`
  mutation deleteAgendaItems($ids: [ID]) {
    deleteAgendaItems(ids: $ids) {
      id
      eventId
    }
  }
`;

export default {
  getAgendaByEvent: async (eventId) => {
    return client.query(GET_AGENDA_BY_EVENT, { eventId });
  },

  setAgendaItem: async (eventId, id, text, html, version, overwrite) => {
    return client.query(SAVE_AGENDA, { eventId, id, text, html, version, overwrite });
  },

  deleteAgendaItems: async (ids) => {
    return client.query(deleteAgendaItems, { ids });
  },
};
