import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TextAreaAutoSize from 'react-textarea-autosize';

import InputWarningMessage from 'components/molecules/InputWarningMessage';

import css from './style.scss';

export default function TextArea(props) {
  const {
    id,
    value,
    autoSize,
    isError,
    isDisabled,
    isReadOnly,
    onChange,
    validationMessage,
    placeholder,
    maxRows,
    rows,
    onBlur,
    forwardedRef,
    dataTestId,
    className,
    charLimit,
  } = props;

  const handleOnChange = (e) => onChange(e.target.value);

  return (
    <div>
      {autoSize ? (
        <TextAreaAutoSize
          id={id}
          className={classNames(css.commentField, { [css.error]: isError, [css.disabled]: isDisabled })}
          value={value}
          data-test-id={dataTestId}
          onChange={handleOnChange}
          disabled={isDisabled}
          readOnly={isReadOnly}
          placeholder={placeholder}
          maxRows={maxRows}
          minRows={rows}
          onBlur={onBlur}
          inputRef={(input) => {
            if (forwardedRef) {
              forwardedRef.current = input;
            }
          }}
          maxLength={charLimit}
        />
      ) : (
        <textarea
          id={id}
          data-test-id={dataTestId}
          className={classNames(css.commentField, { [css.error]: isError, [css.disabled]: isDisabled }, className)}
          value={value}
          onChange={handleOnChange}
          disabled={isDisabled}
          readOnly={isReadOnly}
          rows={rows}
          placeholder={placeholder}
          onBlur={onBlur}
          ref={forwardedRef}
          maxLength={charLimit}
        />
      )}
      {isError && validationMessage && <InputWarningMessage message={validationMessage} />}
    </div>
  );
}

TextArea.defaultProps = {
  value: '',
};

TextArea.propTypes = {
  id: PropTypes.any,
  autoSize: PropTypes.bool,
  isError: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  validationMessage: PropTypes.string,
  placeholder: PropTypes.string,
  maxRows: PropTypes.number,
  rows: PropTypes.number,
  onBlur: PropTypes.func,
  dataTestId: PropTypes.string,
  forwardedRef: PropTypes.object,
  className: PropTypes.string,
  charLimit: PropTypes.number,
};
