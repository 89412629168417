import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';
import { gql } from '@apollo/client';
import { createClient } from '../utils';

const { api } = getConfig();

const client = createClient(api.graphQLEndpoint);

const lessonInfo = gql`
  fragment lessonInfo on lessonType {
    id
    itemId
    title
    summary
    start
    end
    status
    tutor {
      userId
      firstName
      lastName
    }
    client {
      userId
      firstName
      lastName
    }
    report {
      lessonId
      studentId
      tutorId
      goal
      todo
      content
      progress
      assignmentCompletion
      enthusiasm
      behavior
      createdAt
      version
      isProgressing
      isRoadblocked
      roadblockDetail
      effort
    }
    eventId
    cancelledBy
  }
`;

const uploadInfo = gql`
  fragment uploadInfo on uploadType {
    putUrl
    bucket
    key
  }
`;

const fileInfo = gql`
  fragment fileInfo on fileType {
    id
    name
    createdAt
    lessonId
    userId
    bucket
    key
  }
`;

const fetchLessonsQuery = gql`
  ${lessonInfo}
  query fetchLessonQuery($itemId: Int!, $clientId: String!, $tutorId: String!) {
    allLessons: getLessonsByItemTutorStudent(itemId: $itemId, clientId: $clientId, tutorId: $tutorId) {
      ...lessonInfo
    }
  }
`;

const fetchLessonByIdQuery = gql`
  ${lessonInfo}
  query fetchLessonByIdQuery($lessonId: Int!) {
    lesson: getLessonsById(id: $lessonId) {
      ...lessonInfo
    }
  }
`;

const fetchLessonsByItemId = gql`
  ${lessonInfo}
  query fetchLessonsByItemId($itemId: Int!, $withPenalty: Boolean) {
    allLessons: getLessonsByItemId(itemId: $itemId, withPenalty: $withPenalty) {
      ...lessonInfo
    }
  }
`;

const fetchLessonsByEventIdQuery = gql`
  ${lessonInfo}
  query fetchLessonsByEventIdQuery($eventId: String!) {
    allLessons: getLessonsByEventId(eventId: $eventId) {
      ...lessonInfo
    }
  }
`;

const editLessonByIdQuery = gql`
  ${lessonInfo}
  mutation editLessonByIdQuery($lessonId: Int!, $title: String, $summary: String) {
    lesson: editLessonById(id: $lessonId, title: $title, summary: $summary) {
      ...lessonInfo
    }
  }
`;

const fetchUploadUrlQuery = gql`
  ${uploadInfo}
  query fetchUploadUrl($lessonId: Int!, $fileName: String!, $contentType: String) {
    uploadInfo: presignedUploadUrl(lessonId: $lessonId, fileName: $fileName, contentType: $contentType) {
      ...uploadInfo
    }
  }
`;

const fetchDownloadUrlQuery = gql`
  query fetchDownloadUrlQuery($lessonId: Int!, $fileId: Int!) {
    fileUrl(lessonId: $lessonId, fileId: $fileId)
  }
`;

const fetchSharedFilesQuery = gql`
  ${fileInfo}
  query fetchSharedFiles($lessonId: Int!) {
    fileInfo: files(lessonId: $lessonId) {
      ...fileInfo
    }
  }
`;

const createFileMutation = gql`
  ${fileInfo}
  mutation createFileMutation($name: String!, $lessonId: Int!, $userId: String!, $bucket: String!, $key: String!) {
    file: createFile(name: $name, lessonId: $lessonId, userId: $userId, bucket: $bucket, key: $key) {
      ...fileInfo
    }
  }
`;

const deleteFileMutaton = gql`
  mutation deleteFileMutaton($id: Int!) {
    status: deleteFile(id: $id)
  }
`;

const deleteFilesMutation = gql`
  mutation deleteFilesMutation($ids: [Int]!) {
    status: deleteFiles(ids: $ids)
  }
`;

const incompleteReports = gql`
  query incompleteReports($tutorId: String!) {
    incompleteReports(tutorId: $tutorId) {
      tutorId
      status
      title
      start
      end
      clientId
      eventId
      id
      itemId
      eventId
    }
    userBilling(userId: $tutorId) {
      billingPeriods {
        start
        end
      }
    }
  }
`;

export default {
  fetchLessons: (itemId, clientId, tutorId) => {
    return client.query(fetchLessonsQuery, { itemId, clientId, tutorId });
  },
  fetchLessonsByItemId: (itemId, penalty = {}) => {
    const { withPenalty = false } = penalty;
    return client.query(fetchLessonsByItemId, { itemId: parseInt(itemId, 10), withPenalty });
  },
  fetchLessonById: (lessonId) => {
    return client.query(fetchLessonByIdQuery, { lessonId });
  },
  fetchLessonsByEventId: (eventId) => {
    return client.query(fetchLessonsByEventIdQuery, { eventId });
  },
  fetchIncompleteReports: (tutorId) => {
    return client.query(incompleteReports, { tutorId });
  },
  editLessonById: (lessonId, title, summary) => {
    return client.query(editLessonByIdQuery, { lessonId, title, summary });
  },
  fetchUploadUrl: (lessonId, fileName, contentType) => {
    return client.query(fetchUploadUrlQuery, { lessonId, fileName, contentType });
  },
  fetchSharedFiles: (lessonId) => {
    return client.query(fetchSharedFilesQuery, { lessonId });
  },
  createFile: (lessonId, fileName, bucket, key, userId) => {
    return client.query(createFileMutation, { lessonId, name: fileName, bucket, key, userId });
  },
  deleteFile: (fileId) => {
    return client.query(deleteFileMutaton, { id: fileId }).then((res) => {
      if (res.status !== 'OK') {
        return Promise.reject(new Error('error deleting file'));
      }
      return res.status;
    });
  },
  deleteFiles: (fileIds) => {
    return client.query(deleteFilesMutation, { ids: fileIds }).then((res) => {
      if (res.status !== 'OK') {
        return Promise.reject(new Error('error deleting files'));
      }
      return res.status;
    });
  },
  getFileUrl: (lessonId, fileId) => {
    return client.query(fetchDownloadUrlQuery, { lessonId, fileId }).then((res) => {
      if (!res.fileUrl) {
        return Promise.reject(new Error('failed to fetch file url'));
      }
      return res.fileUrl;
    });
  },
};
