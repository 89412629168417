import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';
import { gql } from '@apollo/client';
import { createClient } from '../utils';

const { api } = getConfig();

const client = createClient(`${api.endpoint}/graphql`);

export const allCurriculum = gql`
  query allCurriculum {
    allCurriculum {
      id
      name
      shortName
      region
      description
    }
  }
`;

export default {
  allCurriculum: () => client.query(allCurriculum),
};
