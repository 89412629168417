import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import autoBind from 'auto-bind';
import ImmutablePropTypes from 'react-immutable-proptypes';

import Day from './Day';
import CalendarTimes from './CalendarTimes';
import DayHeader from './DayHeader';

import css from './styles.scss';

export default class CalendarGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      calendarHeight: 0,
      touch: {},
    };

    autoBind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedScroll !== this.props.selectedScroll) {
      this.calendarDiv.scrollTop = this.props.selectedScroll;
    }
  }

  setHeight(calendar) {
    if (calendar && this.props.selectedScroll === 0) {
      const hoursSince = moment().hour();
      const heightDiff = Math.floor(calendar.scrollHeight / 24) * hoursSince;
      this.props.updateScroll(heightDiff);
      this.props.updateScrollHeight(calendar.scrollHeight);
    }
  }

  rendTimeZone(timezone) {
    if (timezone) {
      const offset = moment.tz(timezone).utcOffset() / 60;
      if (offset >= 0) {
        return `GMT+${offset}`;
      }
      return `GMT${offset}`;
    }
    return null;
  }
  render() {
    const {
      onAvailabilityClicked,
      onBookingClicked,
      openModalViaCalendar,
      bookingAsUserCalendar,
      bookingWithUser,
    } = this.props;
    return (
      <div className={css.calendarList}>
        <div className={css.calendarInfo}>
          <div className={css.calendarGrid}>
            <DayHeader user={bookingAsUserCalendar.user} date={bookingAsUserCalendar.date} />
          </div>
          {bookingWithUser.map((u) => {
            return (
              <div className={classNames(this.props.className, css.calendarGrid)} key={u.user.get('userId')}>
                <DayHeader user={u.user} />
              </div>
            );
          })}
        </div>
        <div className={css.calendarInfo}>
          <div className={css.calendarGrid}>
            <div className={css.timezoneHeader}>
              <div className={css.timezone}>{this.rendTimeZone(bookingAsUserCalendar.user.get('timezone'))}</div>
              <div className={css.vertical} />
            </div>
          </div>
          {bookingWithUser.map((u) => {
            return (
              <div className={classNames(this.props.className, css.calendarGrid)} key={u.user.get('userId')}>
                <div className={css.timezoneHeader}>
                  <div className={css.timezone}>{this.rendTimeZone(u.user.get('timezone'))}</div>
                  <div className={css.vertical} />
                </div>
              </div>
            );
          })}
        </div>
        <div className={css.wholeGrid}>
          <div
            className={css.weekGrid}
            key={bookingAsUserCalendar.user.get('userId')}
            ref={(ref) => {
              this.calendarDiv = ref;
            }}
            data-appcues-id="sessions"
          >
            <CalendarTimes timezone={bookingAsUserCalendar.user.get('timezone')} />
            <Day
              key={bookingAsUserCalendar.date}
              date={bookingAsUserCalendar.date}
              dayElements={bookingAsUserCalendar.dayElements}
              setHeight={this.setHeight}
              onAvailabilityClicked={onAvailabilityClicked}
              onBookingClicked={onBookingClicked}
              openModalViaCalendar={openModalViaCalendar}
            />
          </div>
          {bookingWithUser.map((u) => {
            return (
              <div
                className={css.weekGrid}
                key={u.user.get('userId')}
                ref={(ref) => {
                  this.calendarDiv = ref;
                }}
                data-appcues-id="sessions"
              >
                <CalendarTimes timezone={u.user.get('timezone')} />
                <Day
                  key={u.date}
                  date={u.date}
                  dayElements={u.dayElements}
                  setHeight={this.setHeight}
                  onAvailabilityClicked={onAvailabilityClicked}
                  onBookingClicked={onBookingClicked}
                  openModalViaCalendar={openModalViaCalendar}
                  timezone={u.user.get('timezone')}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

CalendarGrid.displayName = 'CalendarGrid';

CalendarGrid.propTypes = {
  className: PropTypes.string,
  selectedScroll: PropTypes.number.isRequired,
  updateScroll: PropTypes.func.isRequired,
  onAvailabilityClicked: PropTypes.func.isRequired,
  onBookingClicked: PropTypes.func.isRequired,
  openModalViaCalendar: PropTypes.func,
  updateScrollHeight: PropTypes.func.isRequired,
  bookingAsUserCalendar: PropTypes.object.isRequired,
  bookingWithUser: ImmutablePropTypes.list.isRequired,
};
