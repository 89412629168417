import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';
import { gql } from '@apollo/client';
import { createClient } from '../utils';

const { api } = getConfig();

const client = createClient(api.graphQLEndpoint);

const cgaReportType = gql`
  fragment cgaReportType on cgaReportType {
    id
    intake_id
    type
    publish_date
    name
    status
    studentReports {
      id
      status
      student {
        id
        public_id
        login_id
        first_name
        last_name
        email_address
      }
    }
  }
`;

const cgaStudentReportType = gql`
  fragment cgaStudentReportType on cgaStudentReportType {
    id
    student_id
    report_id
    student {
      id
      public_id
      login_id
      first_name
      last_name
      email_address
    }
    generation {
      id
      student_report_id
      path
      status
      note
      summary_statement
      sent_date
      url
    }
    streams {
      student_report_id
      student_stream_id
      stream_id
      stream_name
      intake_id
      subject_id
      subject_name
      subject_display_name
      enabled
    }
  }
`;

const getStreamIntakes = gql`
  query getStreamIntakes {
    getCGAStreamIntakes {
      id
      display_name
    }
  }
`;

const listCGAReports = gql`
  ${cgaReportType}
  query listCGAReports {
    listCGAReports {
      ...cgaReportType
    }
  }
`;

const getCGAReport = gql`
  ${cgaReportType}
  query getCGAReport($reportId: String!) {
    getCGAReport(reportId: $reportId) {
      ...cgaReportType
    }
  }
`;

const getCGAStudentReport = gql`
  ${cgaStudentReportType}
  query getCGAStudentReport($reportId: String!, $studentId: String!) {
    getCGAStudentReport(reportId: $reportId, studentId: $studentId) {
      ...cgaStudentReportType
    }
  }
`;

const createReport = gql`
  mutation createReport($intake_id: String!, $publish_date: String!, $type: String!) {
    createCGAReport(intake_id: $intake_id, publish_date: $publish_date, type: $type)
  }
`;

const updateStudentReportStatus = gql`
  mutation updateCGAStudentReportStatus($reportId: String!, $studentId: String!, $status: String!) {
    updateCGAStudentReportStatus(reportId: $reportId, studentId: $studentId, status: $status)
  }
`;

const updateStudentReport = gql`
  mutation updateCGAStudentReport(
    $reportId: String!
    $studentId: String!
    $status: String!
    $studentReportStreams: [cgaStudentSubjectInputType]
  ) {
    updateCGAStudentReport(
      reportId: $reportId
      studentId: $studentId
      status: $status
      studentReportStreams: $studentReportStreams
    ) {
      id
      student {
        id
        public_id
        login_id
        first_name
        last_name
        email_address
      }
      generation {
        status
      }
    }
  }
`;

const updateAllReports = gql`
  ${cgaStudentReportType}
  mutation regenerateReports($reportId: String!) {
    regenerateReports(reportId: $reportId) {
      ...cgaStudentReportType
    }
  }
`;

const sendReports = gql`
  mutation sendCGAReports($reportId: String!) {
    sendCGAReports(reportId: $reportId)
  }
`;

const getCGAStudent = gql`
  query getCGAStudent($studentAuth0Id: String!) {
    getCGAStudent(studentAuth0Id: $studentAuth0Id) {
      id
      first_name
      last_name
      email_address
    }
  }
`;

const getCGAStudentAttendance = gql`
  query getCGAStudentAttendance($studentAuth0Id: String!, $studentCGAId: String!, $start: String!, $end: String!) {
    getCGAStudentAttendance(studentAuth0Id: $studentAuth0Id, studentCGAId: $studentCGAId, start: $start, end: $end) {
      lesson_id
      student_id
      status
      status_object {
        status
        code
        description
        explanation
        truancy
        calculation
      }
      note
      start
      end
      stream
      subject
      intake
      teachers {
        first_name
        last_name
        email
        public_id
      }
    }
  }
`;

const getCGAStudentGrades = gql`
  query getCGAStudentGrades($studentAuth0Id: String!, $studentCGAId: String!) {
    getCGAStudentGrades(studentAuth0Id: $studentAuth0Id, studentCGAId: $studentCGAId) {
      assignment
      category
      due
      excused
      grade
      grading_type
      late
      missing
      points_possible
      seconds_late
      state
      subject
      subject_id
      submission_type
      submitted_date
      updated_at
      workflow_state
    }
  }
`;

export default {
  createReport: (report) => client.query(createReport, report),
  getCGAReport: (reportId) => client.query(getCGAReport, { reportId }),
  getCGAStudent: (studentAuth0Id) => client.query(getCGAStudent, { studentAuth0Id }),
  getCGAStudentAttendance: (studentAuth0Id, studentCGAId, start, end) =>
    client.query(getCGAStudentAttendance, { studentAuth0Id, studentCGAId, start, end }),
  getCGAStudentGrades: (studentAuth0Id, studentCGAId) =>
    client.query(getCGAStudentGrades, { studentAuth0Id, studentCGAId }),
  getCGAStudentReport: (reportId, studentId) => client.query(getCGAStudentReport, { reportId, studentId }),
  getStreamIntakes: () => client.query(getStreamIntakes),
  listCGAReports: () => client.query(listCGAReports),
  sendReports: ({ reportId }) => client.query(sendReports, { reportId }),
  updateAllReports: (reportId) => client.query(updateAllReports, { reportId }),
  updateStudentReport: ({ reportId, studentId, status, studentReportStreams }) =>
    client.query(updateStudentReport, { reportId, studentId, status, studentReportStreams }),
  updateStudentReportStatus: ({ reportId, studentId, status }) =>
    client.query(updateStudentReportStatus, { reportId, studentId, status }),
};
