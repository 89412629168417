import Immutable from 'immutable';
import createReducer from 'utils/createReducer';
import examPrepApi from 'graphql/api/examPrep';
import {
  ADD_ENTITIES,
  addEntitiesWithNormalisation,
  SET_ENTITIES,
  setEntitiesWithNormalisation,
} from 'ducks/normalizr';
import { examPrepEntity } from 'schema';
import { formFail, modalSuccess, updateMeta } from 'ducks/meta';
import componentKeys from 'constants/componentKeys';
import examType from 'constants/examType';
import examStatus from 'constants/examStatus';
import { formatGraphQLRequestError } from 'utils/graphql';

const initialState = new Immutable.Map();

const ENABLE_EXAM = 'examPrep/ENABLE_EXAM';
const UPDATE_SESSION = 'examPrep/UPDATE_SESSION';
const DISABLE_EXAM = 'examPrep/DISABLE_EXAM';
const SUBMIT_ANSWER_SUCCEEDED = 'examPrep/SUBMIT_ANSWER_SUCCEEDED';
const FETCH_REPORT_SUCCEEDED = 'examPrep/FETCH_REPORT_SUCCEEDED';
const RESET_EXAM = 'examPrep/RESET_EXAM';

// TODO move to common config
const EXAM_AVAILABLE_SUCCESS = 'Invitation sent successfully!';
const SECTION_TIMEOUT_MESSAGE =
  'You have used up all the time allocated for this section. You have been automatically moved onto the next section.';
const EXAM_TIMEOUT_MESSAGE =
  'You have used up all the time allocated for this test. The test has been automatically ended, and a report has been generated.';

export default createReducer(initialState, {
  [SET_ENTITIES]: (state, action) => {
    if (action.payload.entities.examSession || action.payload.entities.examRelationship) {
      return state.merge(action.payload.entities);
    }
    return state;
  },
  [ADD_ENTITIES]: (state, action) => {
    if (action.payload.entities.examSession || action.payload.entities.examRelationship) {
      return state.mergeDeep(action.payload.entities);
    }
    return state;
  },
  [ENABLE_EXAM]: (state, { payload: { userId, externalExamId } }) => {
    return state.setIn(['examRelationship', `${userId}-${externalExamId}`, 'isAvailable'], true);
  },
  [DISABLE_EXAM]: (state, { payload: { userId, externalExamId } }) => {
    return state.setIn(['examRelationship', `${userId}-${externalExamId}`, 'isAvailable'], false);
  },
  [UPDATE_SESSION]: (state, { payload: { exam } }) => {
    const path = ['examSession', exam.examPrepSessionId];
    const session = state.getIn(path);
    let sessionUpdated = Immutable.fromJS(exam);
    if (session) {
      sessionUpdated = session.mergeDeep(Immutable.fromJS(exam));
    }
    return state.setIn(path, sessionUpdated);
  },
  [SUBMIT_ANSWER_SUCCEEDED]: (state, { payload: answer }) => {
    const { examPrepSectionId, externalQuestionGroupId, externalQuestionId, givenAnswer, examPrepSessionId } = answer;
    const examSections = state.getIn(['examSession', examPrepSessionId, 'sections']);
    const examSectionIndex = examSections.findIndex((section) => section.get('sectionId') === examPrepSectionId);
    const questionsAndQuestionGroups = examSections.get(examSectionIndex).get('questionsAndQuestionGroups');

    let questionGroupIndex;
    let questionIndex;
    let path;

    if (externalQuestionGroupId) {
      questionGroupIndex = questionsAndQuestionGroups.findIndex(
        (g) => g.get('externalQuestionGroupId') === externalQuestionGroupId,
      );

      questionIndex = questionsAndQuestionGroups
        .get(questionGroupIndex)
        .get('questions')
        .findIndex((q) => q.get('externalQuestionId') === externalQuestionId);

      path = [
        'examSession',
        examPrepSessionId,
        'sections',
        examSectionIndex,
        'questionsAndQuestionGroups',
        questionGroupIndex,
        'questions',
        questionIndex,
        'givenAnswer',
      ];
    } else {
      questionIndex = questionsAndQuestionGroups.findIndex((q) => q.get('externalQuestionId') === externalQuestionId);

      path = [
        'examSession',
        examPrepSessionId,
        'sections',
        examSectionIndex,
        'questionsAndQuestionGroups',
        questionIndex,
        'givenAnswer',
      ];
    }
    return state.setIn(path, givenAnswer);
  },
  [FETCH_REPORT_SUCCEEDED]: (state, { payload: { examPrepSessionId, report } }) => {
    return state.setIn(['examSession', examPrepSessionId, 'report'], Immutable.fromJS(report));
  },
  [RESET_EXAM]: (state, { payload: { examPrepSessionId, exam } }) => {
    const session = state.getIn(['examSession', examPrepSessionId]);
    let newAvailability = exam;
    if (session) {
      newAvailability = Object.assign(exam, session.toJS(), {
        status: null,
        timeCompleted: null,
        isAvailable: true,
        examPrepSessionId: null,
      });
    }
    return state
      .deleteIn(['examSession', examPrepSessionId])
      .setIn(['examRelationship', `${exam.userId}-${exam.externalExamId}`], Immutable.fromJS(newAvailability));
  },
});

export function enableExamSucceeded(userId, externalExamId) {
  return { type: ENABLE_EXAM, payload: { userId, externalExamId } };
}

export function disableExam(userId, externalExamId) {
  return { type: DISABLE_EXAM, payload: { userId, externalExamId } };
}

export function resetExamSucceeded(examPrepSessionId, exam) {
  return { type: RESET_EXAM, payload: { examPrepSessionId, exam } };
}

export function startExamSucceeded(exam) {
  return { type: UPDATE_SESSION, payload: { exam } };
}

export function answersRetrieved(exam) {
  return { type: UPDATE_SESSION, payload: { exam } };
}

export function completeSectionSucceeded(userId, exam) {
  return { type: UPDATE_SESSION, payload: { userId, exam } };
}

export function submitAnswerSucceeded(answer) {
  return { type: SUBMIT_ANSWER_SUCCEEDED, payload: answer };
}

export function fetchReportSucceeded(examPrepSessionId, report) {
  return { type: FETCH_REPORT_SUCCEEDED, payload: { examPrepSessionId, report } };
}

export function fetchExamBySessionId(examPrepSessionId, history) {
  return (dispatch) => {
    return examPrepApi
      .fetchExamBySessionId(examPrepSessionId)
      .then((res) => {
        if (res.exam) {
          const regex = /^\/test-prep\/session\//;
          if (res.exam.status === examStatus.COMPLETED && regex.test(history.location.pathname)) {
            history.push(`/test-prep/report/${res.exam.examPrepSessionId}`);
          }
          dispatch(addEntitiesWithNormalisation([res.exam], examPrepEntity));
        }
      })
      .catch((errors) => {
        dispatch(formFail(formatGraphQLRequestError(errors)));
      });
  };
}

export function fetchAllExamIdsByUserId(userId) {
  return (dispatch) => {
    return examPrepApi
      .fetchAllExamIdsByUserId(userId)
      .then((res) => {
        if (res.exams) {
          dispatch(addEntitiesWithNormalisation(res.exams, examPrepEntity));
          dispatch(updateMeta(componentKeys.EXAM_LIST_FETCHED, true));
        }
      })
      .catch((errors) => {
        dispatch(formFail(formatGraphQLRequestError(errors)));
      });
  };
}

export function fetchAllExamsByUserId(userId) {
  return (dispatch) => {
    return examPrepApi
      .fetchAllExamsByUserId(userId)
      .then((res) => {
        if (res.exams) {
          dispatch(setEntitiesWithNormalisation(res.exams, examPrepEntity));
          dispatch(updateMeta(componentKeys.EXAM_LIST_FETCHED, true));
        }
      })
      .catch((errors) => {
        dispatch(formFail(formatGraphQLRequestError(errors)));
      });
  };
}

export function fetchAvailableExamsByUserId(userId, isStudentView, history) {
  return (dispatch) => {
    return examPrepApi
      .fetchAvailableExamsByUserId(userId)
      .then((res) => {
        if (res.exams) {
          dispatch(setEntitiesWithNormalisation(res.exams, examPrepEntity));
          const examInProgress = res.exams.find((exam) => exam.status === examStatus.STARTED);
          if (isStudentView && examInProgress) {
            history.push(`/test-prep/session/${examInProgress.examPrepSessionId}`);
          }
          dispatch(updateMeta(componentKeys.EXAM_LIST_FETCHED, true));
        }
      })
      .catch((errors) => {
        dispatch(formFail(formatGraphQLRequestError(errors)));
      });
  };
}

export function fetchExamIdInProgressByUserId(userId) {
  return (dispatch) => {
    return examPrepApi.fetchAvailableExamIdsByUserId(userId).then((res) => {
      if (res.exams) {
        dispatch(addEntitiesWithNormalisation(res.exams, examPrepEntity));
      }
    });
  };
}

export function setExamAvailability(userId, externalExamId, isAvailable) {
  return (dispatch) => {
    return examPrepApi
      .setExamAvailability({ userId, externalExamId, isAvailable })
      .then((examPrep) => {
        dispatch(modalSuccess(EXAM_AVAILABLE_SUCCESS));
        dispatch(enableExamSucceeded(userId, examPrep.setExamAvailability.externalExamId));
      })
      .catch((errors) => {
        dispatch(formFail(formatGraphQLRequestError(errors)));
      });
  };
}

export function startExam(userId, externalExamId) {
  return (dispatch) => {
    return examPrepApi
      .startExam(externalExamId)
      .then((exam) => {
        dispatch(disableExam(userId, externalExamId));
        dispatch(startExamSucceeded(exam.startExam));
        return exam;
      })
      .catch((errors) => {
        dispatch(formFail(formatGraphQLRequestError(errors)));
      });
  };
}

export function completeSection(userId, examPrepSectionId, timedOut, history) {
  return (dispatch) => {
    return examPrepApi
      .completeSection(examPrepSectionId)
      .then((exam) => {
        if (
          timedOut &&
          exam.completeSection.status !== examStatus.COMPLETED &&
          exam.completeSection.examType !== examType.UMAT
        ) {
          dispatch(formFail(SECTION_TIMEOUT_MESSAGE));
        }
        const regex = /^\/test-prep\/session\//;

        if (exam.completeSection.status === examStatus.COMPLETED && regex.test(history.location.pathname)) {
          if (timedOut) {
            dispatch(formFail(EXAM_TIMEOUT_MESSAGE));
          }
          history.push(`/test-prep/report/${exam.completeSection.examPrepSessionId}`);
        }
        dispatch(completeSectionSucceeded(userId, exam.completeSection));
      })
      .catch((errors) => {
        dispatch(formFail(formatGraphQLRequestError(errors)));
      });
  };
}

export function resetExam(examPrepSessionId) {
  return (dispatch) => {
    return examPrepApi
      .resetExam(examPrepSessionId)
      .then((res) => {
        dispatch(resetExamSucceeded(examPrepSessionId, res.resetExam));
        dispatch(modalSuccess(`${res.resetExam.name} has been successfully reset`));
        return res.resetExam;
      })
      .catch((errors) => {
        dispatch(formFail(formatGraphQLRequestError(errors, 'Could not reset exam')));
      });
  };
}

export function submitAnswer({ examPrepSectionId, externalQuestionGroupId, externalQuestionId, givenAnswer }) {
  return (dispatch) => {
    return examPrepApi
      .submitAnswer(examPrepSectionId, externalQuestionGroupId, externalQuestionId, givenAnswer)
      .then((exam) => {
        dispatch(submitAnswerSucceeded(Object.assign({}, exam.submitAnswer)));
        return exam;
      })
      .catch((errors) => {
        dispatch(formFail(formatGraphQLRequestError(errors, 'Could not submit answer')));
      });
  };
}

export function fetchExamAnswerDataBySessionId(examPrepSessionId) {
  return (dispatch) => {
    return examPrepApi
      .fetchExamAnswerDataBySessionId(examPrepSessionId)
      .then((examData) => {
        dispatch(answersRetrieved(examData.exam));
      })
      .catch((errors) => {
        dispatch(formFail(formatGraphQLRequestError(errors)));
      });
  };
}
export function fetchSATExamResult(examPrepSessionId) {
  return (dispatch) => {
    return examPrepApi
      .fetchSATExamResult(examPrepSessionId)
      .then((results) => {
        if (results.report) {
          dispatch(fetchReportSucceeded(examPrepSessionId, results.report));
        }
      })
      .catch((errors) => {
        dispatch(formFail(formatGraphQLRequestError(errors)));
      });
  };
}

export function fetchExamWithReportsByUserId(userId) {
  return (dispatch) => {
    return examPrepApi
      .fetchAvailableExamsByUserId(userId)
      .then(async (res) => {
        if (res.exams) {
          dispatch(addEntitiesWithNormalisation(res.exams, examPrepEntity));
          const completedExams = res.exams.filter((exam) => exam.status === examStatus.COMPLETED);

          if (completedExams.length) {
            try {
              const reports = await Promise.all(
                completedExams.map((exam) => {
                  if (exam.examType === examType.SAT) {
                    return examPrepApi.fetchSATExamResult(exam.examPrepSessionId);
                  } else if (exam.examType === examType.ACT) {
                    return examPrepApi.fetchACTExamResult(exam.examPrepSessionId);
                  } else if (exam.examType === examType.UMAT || exam.examType === examType.GENERAL) {
                    return examPrepApi.fetchGeneralExamResult(exam.examPrepSessionId);
                  } else if (exam.examType === examType.SAT_30_MINS) {
                    return examPrepApi.fetch30MinSATExamResult(exam.examPrepSessionId);
                  }
                  return null;
                }),
              );
              reports
                .filter((report) => report)
                .map(({ report }) => dispatch(fetchReportSucceeded(report.examPrepSessionId, report)));
            } catch (errors) {
              dispatch(formFail(formatGraphQLRequestError(errors)));
            }
          }
          dispatch(updateMeta(componentKeys.EXAM_LIST_FETCHED, true));
        }
      })
      .catch((errors) => {
        dispatch(formFail(formatGraphQLRequestError(errors)));
      });
  };
}
