import Immutable from 'immutable';

import createReducer from 'utils/createReducer';
import {
  FETCH_AGENDA_ITEMS,
  FETCH_AGENDA_ITEMS_COMPLETE,
  SET_AGENDA_ITEM,
  SET_AGENDA_ITEM_COMPLETE,
  DELETE_AGENDA_ITEMS,
  DELETE_AGENDA_ITEMS_COMPLETE,
} from './actions';

const initialState = Immutable.fromJS({
  entities: {
    agenda: {},
    agendaItem: {},
  },
});

export default createReducer(initialState, {
  [FETCH_AGENDA_ITEMS]: (state, action) => state.mergeDeep(action.payload),
  [FETCH_AGENDA_ITEMS_COMPLETE]: (state, action) => state.mergeDeep(action.payload),

  [SET_AGENDA_ITEM]: (state, action) => state.mergeDeep(action.payload),
  [SET_AGENDA_ITEM_COMPLETE]: (state, action) =>
    state
      .mergeDeep({ entities: action.payload.entities })
      .updateIn(['entities', 'agenda', action.payload.eventId.toString(), 'items'], (x) =>
        x.includes(action.payload.id) ? x : x.push(action.payload.id),
      ),

  [DELETE_AGENDA_ITEMS]: (state, action) => state.mergeDeep(action.payload),
  [DELETE_AGENDA_ITEMS_COMPLETE]: (state, action) => state.mergeDeep({ entities: action.payload.entities }),
});
