import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';
import { gql } from '@apollo/client';
import { createClient } from '../utils';

const { api } = getConfig();

const client = createClient(api.graphQLEndpoint);

const sessionFeedbackType = gql`
  fragment sessionFeedbackType on sessionFeedbackType {
    id
    objectId
    senderId
    sender {
      firstName
      lastName
      userId
    }
    receiverId
    receiver {
      firstName
      lastName
    }
    answers {
      id
      question
      answer
      questionType
      rating
    }
    subject {
      id
      name
    }
    event {
      type
      description
      name
      start
      end
    }
    createTime
    comment
    editTime
    status
  }
`;

const reportType = gql`
  fragment reportType on reportType {
    tutorId
    studentId
  }
`;

const feedbackSessionReport = gql`
  fragment feedbackSessionReport on sessionReportType {
    reporterId
    studentId
  }
`;

const otherSessionInfo = gql`
  fragment otherSessionInfo on eventSessionType {
    id
    name
    status
    start
    end
    otherUser {
      userId
      firstName
      lastName
      userRoles
      roles {
        isPrimary
        roleId
        role {
          name
        }
      }
    }
    report {
      studentId
      reporterId
      eventId
      sessionType
      subjects
      concern
      elaborate
      version
    }
    userId
  }
`;

const createSessionFeedback = gql`
  ${sessionFeedbackType}
  mutation createSessionFeedback(
    $eventId: String!
    $feedbackType: String!
    $receiverId: String!
    $comment: String
    $answers: [questionAnswerInput]
    $booking: bookingType
  ) {
    createSessionFeedback(
      eventId: $eventId
      feedbackType: $feedbackType
      receiverId: $receiverId
      comment: $comment
      answers: $answers
      booking: $booking
    ) {
      ...sessionFeedbackType
    }
  }
`;

const submitSessionReport = gql`
  ${reportType}
  mutation submitLessonReport(
    $studentId: String!
    $tutorId: String!
    $eventId: String!
    $goal: String!
    $content: String!
    $progress: String!
    $todo: String!
    $version: Int
    $isProgressing: Boolean
    $isRoadblocked: Boolean
    $roadblockDetail: String
    $effort: Int
    $assignmentCompletion: Int
    $enthusiasm: Int
    $behavior: Int
  ) {
    submitLessonReport(
      studentId: $studentId
      tutorId: $tutorId
      eventId: $eventId
      goal: $goal
      content: $content
      progress: $progress
      todo: $todo
      assignmentCompletion: $assignmentCompletion
      enthusiasm: $enthusiasm
      behavior: $behavior
      version: $version
      isProgressing: $isProgressing
      isRoadblocked: $isRoadblocked
      roadblockDetail: $roadblockDetail
      effort: $effort
    ) {
      ...reportType
    }
  }
`;

const fetchUserRating = gql`
  query userFeedbackRating($userId: String!, $reportType: String) {
    userFeedbackRating(userId: $userId, reportType: $reportType) {
      userId
      averageRating
      reportType
      sessionCount
      sentiment {
        type
        totalNegative
        totalPositive
      }
    }
  }
`;

const pageInfo = gql`
  fragment pageInfo on paginationType {
    pageSize
    pageNumber
  }
`;

const fetchUserReview = gql`
  ${sessionFeedbackType}
  ${pageInfo}
  query pagedUserReview($userId: String, $pagination: paginationInputType, $filter: reviewFilterInputType) {
    pagedUserReview(userId: $userId, pagination: $pagination, filter: $filter) {
      reviews {
        ...sessionFeedbackType
      }
      pagination {
        ...pageInfo
      }
      total
    }
  }
`;

const createSessionFeedbackReport = gql`
  ${feedbackSessionReport}
  mutation submitSessionReportFeedback(
    $studentId: String!
    $reporterId: String!
    $eventId: String!
    $sessionType: String!
    $subjects: String!
    $version: Int
    $concern: Boolean
    $elaborate: String
  ) {
    submitSessionReportFeedback(
      studentId: $studentId
      reporterId: $reporterId
      eventId: $eventId
      sessionType: $sessionType
      subjects: $subjects
      version: $version
      concern: $concern
      elaborate: $elaborate
    ) {
      ...feedbackSessionReport
    }
  }
`;

const incompleteSessionReports = gql`
  query incompleteSessionReports($userId: String!) {
    incompleteSessionReports(userId: $userId) {
      eventId
      title
      eventStatus
      start
      end
      status
      userId
    }
  }
`;

const fetchSessionFeedbackReport = gql`
  query fetchSessionFeedbackReport($eventId: String!) {
    fetchSessionFeedbackReport(eventId: $eventId) {
      studentId
      reporterId
      eventId
      sessionType
      subjects
      concern
      elaborate
      version
    }
  }
`;

const getSessionsByStudentId = gql`
  ${otherSessionInfo}
  query getSessionsByStudentId($studentId: String!, $limit: Int, $date: String, $type: String!) {
    getSessionsByStudentId(studentId: $studentId, limit: $limit, date: $date, type: $type) {
      ...otherSessionInfo
    }
  }
`;

export default {
  submitSessionReport: (args) => {
    return client.query(submitSessionReport, args);
  },
  createSessionFeedback: (feedback) => client.query(createSessionFeedback, feedback),
  fetchUserRating: (args) => client.query(fetchUserRating, args),
  fetchUserReview: (args) => client.query(fetchUserReview, args),
  createSessionFeedbackReport: (args) => {
    return client.query(createSessionFeedbackReport, args);
  },
  fetchIncompleteSessionReports: (userId) => {
    return client.query(incompleteSessionReports, { userId });
  },
  fetchSessionFeedbackReport: (eventId) => client.query(fetchSessionFeedbackReport, { eventId }),
  fetchSessionsByStudent: (args) => client.query(getSessionsByStudentId, args),
};
