import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Link } from 'react-router-dom';

import Avatar from 'components/molecules/Avatar';
import css from './styles.scss';

export default function DayHeader({ user, date }) {
  const profileLink = `/users/${user.get('userId')}/profile`;
  const roles = user.get('roles');
  const primaryRole = roles && roles.size > 0 && (roles.find((r) => r.get('isPrimary')) || roles.get(0));

  return (
    <div className={css.dayHeaderRow}>
      <div className={css.data}>
        {date ? (
          <div className={css.date}>
            <span className={css.number}>{date.format('D')}</span>
            <span className={css.week}>{date.format('ddd').toUpperCase()}</span>
          </div>
        ) : null}
        <div className={css.profile}>
          <span className={css.name}>
            {user.get('firstName')} {user.get('lastName')}
          </span>
          <span className={css.role}>{primaryRole && primaryRole.get('role').get('name')}</span>
          <Link className={css.profilePic} to={profileLink}>
            <Avatar
              size="normal"
              firstName={user.get('firstName')}
              lastName={user.get('lastName')}
              userId={user.get('userId')}
              image={user.get('profileImageUrl')}
            />
          </Link>
        </div>
      </div>
    </div>
  );
}

DayHeader.propTypes = {
  user: ImmutablePropTypes.map.isRequired,
  date: PropTypes.object,
};
