import React from 'react';
import { ClickableUnderlineTextContainer } from './index.style';
import MailIcon from '@material-ui/icons/Mail';
type ClickableUnderlineTextProps = {
  onClick: () => void;
  title: string;
};
const ClickableUnderlineText = ({ title, onClick }: ClickableUnderlineTextProps) => {
  return (
    <ClickableUnderlineTextContainer rowcenter onClick={onClick}>
      <div>{title}</div>
      <MailIcon />
    </ClickableUnderlineTextContainer>
  );
};

export default ClickableUnderlineText;
