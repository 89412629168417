import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import autoBind from 'auto-bind';

import ErrorIcon from 'components/generic/Icons/Error';
import CriteriaTooltip from 'components/generic/CriteriaTooltip';
import CommentField from 'components/generic/Forms/CommentField';
import Modal from 'components/molecules/Modal';
import InfoPanel from 'components/molecules/InfoPanel';
import { Caption } from 'components/atoms/typography';
import HorizontalRadioButtons from 'components/molecules/HorizontalRadioButtons';
import * as Logger from '@crimson-education/browser-logger';
import { getAvatarColour } from 'utils/calendarUtils';
import css from './styles.scss';
import sessionReportData, { COMMENT_FIELD, BOOLEAN_FIELD } from './versionTwoData';

const SESSION_REPORT_HEADING = 'SESSION REPORT';

const getInitialdata = () => {
  const initialData = {
    loading: false,
    submitted: false,
  };
  sessionReportData.forEach((field) => {
    initialData[field.key] = '';
    if (field.criteria) {
      initialData[`${field.key}_criteria_open`] = false;
    }
  });
  return initialData;
};

export default class VersionTwo extends Component {
  constructor() {
    super();
    this.state = getInitialdata();

    autoBind(this);
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  async onSubmitForm() {
    const { onSubmit, studentId, eventId, tutorId, type } = this.props;

    this.setState({ submitted: true });

    if (this.isFormValid()) {
      this.setState({ loading: true });
      const report = {
        version: 2,
        content: 'NA',
        todo: 'NA', // Need to be removed once backend todo is no longer required
      };
      const RatingMap = { A: 3, B: 2, C: 1, D: 0 };
      const BooleanMap = { Yes: true, No: false };

      sessionReportData.forEach((field) => {
        if (field.fieldType === COMMENT_FIELD) {
          report[field.key] = this.state[field.key] || null;
        } else if (field.fieldType === BOOLEAN_FIELD) {
          report[field.key] = BooleanMap[this.state[field.key]];
        } else {
          report[field.key] = RatingMap[this.state[field.key]];
        }
      });

      await onSubmit(studentId, tutorId, eventId, report, type);
      if (this.mounted) {
        this.setState({ loading: false });
      }
    }
  }

  updateKeyValue(key, value) {
    this.setState({ [key]: value });
  }

  handleTabSelection(key, options) {
    return (selection) => {
      this.setState({
        [key]: options[selection],
      });
    };
  }

  isFormValid() {
    return sessionReportData.reduce((isValid, field) => {
      const { key, fieldType, depends } = field;

      // The current field is hidden because if depends on the value of something else
      if (depends && depends.key && this.state[depends.key] !== depends.value) {
        return isValid && true;
      }

      // Comment field has no text
      if (fieldType === COMMENT_FIELD && !this.state[key].length) {
        return false;
      }

      // Rating or boolean field is not checked
      if (!this.state[key]) {
        return false;
      }

      return isValid && true;
    }, true);
  }

  closeSessionReport() {
    const { closeSessionReport, fetchIncompleteReports, tutorId } = this.props;
    Logger.trackEvent({ message: 'doItLater click' });
    closeSessionReport();
    fetchIncompleteReports(tutorId);
  }

  render() {
    const { isOpen, booking, lapsedBillingPeriod } = this.props;

    const { submitted, loading } = this.state;

    const periodStart = moment(booking.get('start'));
    const periodEnd = moment(booking.get('end'));
    const isSameDay = periodStart.isSame(periodEnd, 'day');
    let period = null;
    if (isSameDay) {
      period = `${periodStart.format('ddd, D MMM, h:mma')} - ${periodEnd.format('h:mma')}`;
    } else {
      period = `${periodStart.format('ddd, D MMM, h:mma')} - ${periodEnd.format('ddd, D MMM, h:mma')}`;
    }

    const secondaryAction =
      periodEnd < lapsedBillingPeriod
        ? null // If it is pass the billing period, we should not allow the report to be skipped
        : this.closeSessionReport;
    return (
      <Modal
        isOpen={isOpen}
        title={SESSION_REPORT_HEADING}
        submitText="Submit"
        onSubmit={this.onSubmitForm}
        onSubmitDataTestId="submitSessionReportButton"
        isSubmitDisabled={false}
        loading={loading}
        onSecondarySubmit={secondaryAction}
        onClose={secondaryAction}
        secondarySubmitText="I'll do this later"
      >
        <InfoPanel
          firstName={booking.getIn(['otherUser', 'firstName'])}
          lastName={booking.getIn(['otherUser', 'lastName'])}
          colourIndex={getAvatarColour(booking.getIn(['otherUser', 'userId']))}
          image={booking.getIn(['otherUser', 'profileImageUrl'])}
          textTop={`${booking.get('name')} - ${booking.getIn(['subject', 'name'])}`}
          textBottom={period}
          userId={booking.getIn(['otherUser', 'userId'])}
        />
        <div className={css.feedback}>
          {sessionReportData.map(({ label, key, buttons, criteria, fieldType, depends }) => {
            if (depends && depends.key && this.state[depends.key] !== depends.value) return null;
            if (fieldType === COMMENT_FIELD) {
              return (
                <div key={key}>
                  <CommentField
                    autoSize
                    required
                    className={classNames({
                      [css.commentBox]: true,
                      [css.commentInvalid]: submitted && !this.state[key].length,
                    })}
                    id={`sessionReport_${key}`}
                    dataTestId={`sessionReport_${key}`}
                    key={key}
                    label={label}
                    onValueChange={(val) => this.updateKeyValue(key, val)}
                  />
                  <div className={css.errorMessageContainer}>
                    {submitted && !this.state[key].length && (
                      <div className={css.errorMessage}>
                        <ErrorIcon className={css.alertIcon} />
                        {'Please complete this field'}
                      </div>
                    )}
                  </div>
                </div>
              );
            }
            return (
              <div key={key}>
                <div>
                  <div className={css.selectLabel}>
                    <Caption className={css.selectCaption}>{label}</Caption>
                    {criteria && (
                      <CriteriaTooltip
                        isOpen={this.state[`${key}_criteria_open`]}
                        arrowSize={10}
                        preferPlace="right"
                        targetStyling={css.seeCriteria}
                        onMouseEnter={() => this.updateKeyValue(`${key}_criteria_open`, true)}
                        onMouseLeave={() => this.updateKeyValue(`${key}_criteria_open`, false)}
                        targetText="See criteria"
                        body={criteria || []}
                        buttons={buttons}
                      />
                    )}
                  </div>
                  <HorizontalRadioButtons
                    height={3.2}
                    buttons={buttons}
                    onButtonChange={this.handleTabSelection(key, buttons)}
                    dataTestIdPrefix=""
                    dataGaLabel={label}
                  />
                </div>
                <div className={css.errorMessageContainer}>
                  {submitted && !this.state[key] && (
                    <div className={css.errorMessage}>
                      <ErrorIcon className={css.alertIcon} />
                      {'Please select an option'}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </Modal>
    );
  }
}

VersionTwo.propTypes = {
  isOpen: PropTypes.bool,
  booking: PropTypes.shape(),
  onSubmit: PropTypes.func.isRequired,
  tutorId: PropTypes.string.isRequired,
  studentId: PropTypes.string.isRequired,
  eventId: PropTypes.number.isRequired,
  closeSessionReport: PropTypes.func,
  fetchIncompleteReports: PropTypes.func,
  lapsedBillingPeriod: PropTypes.object,
  type: PropTypes.string.isRequired,
};
