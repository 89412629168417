import Immutable from 'immutable';
import createReducer from 'utils/createReducer';
import { ADD_ENTITIES, addEntitiesWithNormalisation } from 'ducks/normalizr';
import { gradeEntity } from 'schema';
import gradeConnector from 'graphql/api/grade';

export const DELETE_GRADES_SUCCEEDED = 'grade/DELETE_GRADES_SUCCEEDED';

const initialState = new Immutable.Map();

export default createReducer(initialState, {
  [ADD_ENTITIES]: (state, action) => {
    return state.mergeDeep(action.payload.entities.grade);
  },
  [DELETE_GRADES_SUCCEEDED]: (state, action) => {
    const { gradeIds } = action.payload;
    let newState = state;
    gradeIds.forEach((gradeId) => {
      newState = newState.delete(gradeId);
      return newState;
    });
    return newState;
  },
});

export function deleteGradesSucceeded(gradeIds) {
  return { type: DELETE_GRADES_SUCCEEDED, payload: { gradeIds } };
}

export function fetchGradesByUserId(userId) {
  return async (dispatch) => {
    const result = await gradeConnector.getGradesByUserId(userId);
    const grades = result.getGradesByUserId;
    dispatch(addEntitiesWithNormalisation(grades, [gradeEntity]));
  };
}

export function createGrade(subjectId, userId, date, results) {
  return async (dispatch) => {
    const result = await gradeConnector.createGrade(subjectId, userId, date, results);
    const grade = result.createGrade;
    dispatch(addEntitiesWithNormalisation(grade, gradeEntity));
  };
}

export function updateGrade(id, date, results) {
  return async (dispatch) => {
    const result = await gradeConnector.updateGrade(id, date, results);
    const grade = result.updateGrade;
    dispatch(addEntitiesWithNormalisation(grade, gradeEntity));
  };
}

export function deleteGrades(ids) {
  return async (dispatch) => {
    const result = await gradeConnector.deleteGrades(ids);
    const gradeIds = result.deleteGrades;
    dispatch(deleteGradesSucceeded(gradeIds));
  };
}
