import styled from 'styled-components';
import { Column } from '../../index.style';
import Dialogue from '../../Dialogue';
import TextArea from 'components/molecules/TextArea';

export const StyledDialogue = styled(Dialogue)`
  width: 700px;
`;

export const Container = styled(Column)`
  gap: 15px;
  position: relative;
  margin-top: 49px;
  margin-bottom: 30px;
`;

export const SectionTitleContainer = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #1d1e2b;

  & :nth-child(2) {
    color: #a9acc0 !important;
  }
`;

export const ClickableUnderlineText = styled.div`
  font-weight: 500;
  font-size: 14px;
  text-decoration-line: underline;
  color: #464ac9;
  cursor: pointer;
`;

export const TextCount = styled.div`
  font-size: 12px;
  line-height: 20px;
  color: #a9acc0;
  position: absolute;
  right: 15px;
  bottom: 15px;
`;

export const StyledTextArea = styled(TextArea)`
  min-height: 290px;
`;
