import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ReactWebMediaPlayer from 'react-web-media-player';
import PropTypes from 'prop-types';
import {
  PermissionAction,
  PermissionResourceQualifier,
  PermissionResourceType,
} from '@crimson-education/common-config/lib/authorization';
import usePermissionCheck from 'hooks/usePermissionCheck';
import sessionService from 'graphql/api/session';
import { getLoginUser } from 'selectors/user';
import LoadingIndicator from 'components/molecules/LoadingIndicator';
import css from './styles.scss';

function SessionRecording(props) {
  const {
    match: { params },
  } = props;

  const [videoUrl, setVideoUrl] = useState(null);
  const [hasViewRecordingPermission, setHasViewRecordingPermission] = useState(null);
  const userId = useSelector(getLoginUser).toJS().userId;

  const canViewRecordingCheck = usePermissionCheck(
    {
      action: PermissionAction.View,
      resourceType: PermissionResourceType.SessionRecording,
      resource: {
        identifier: userId,
        qualifier: PermissionResourceQualifier.User,
      },
    },
    [userId],
  );
  const canViewRecording = !canViewRecordingCheck.loading && canViewRecordingCheck.isPermitted;

  useEffect(() => {
    (async () => {
      // const hasPermission = await sessionService.hasViewRecordingPermission(params.sessionId);
      setHasViewRecordingPermission(canViewRecording);
      if (canViewRecording) {
        const url = await sessionService.getSessionRecordingUrl(params.sessionId);
        setVideoUrl(url?.getSessionRecordingUrl);
      }
    })();
  }, [params, canViewRecording]);

  if (videoUrl === null) return <LoadingIndicator />;
  return (
    <div className={css.background}>
      {!hasViewRecordingPermission && hasViewRecordingPermission != null ? (
        <div className={css.denied}>
          <img src="/static/Group_1793.png" alt="No Permission" />
          <div className={css.title}>Access Denied</div>
        </div>
      ) : (
        <div className={css.access}>
          {videoUrl ? (
            <div className={css.video}>
              <ReactWebMediaPlayer width="100%" height="100%" video={videoUrl} thumbnail={videoUrl} />
            </div>
          ) : (
            <div className={css.noVideo}>
              <img src="/static/Group_1793.png" alt="No Video Recording" />
              <div className={css.title}>No Video Recording</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

SessionRecording.propTypes = {
  match: PropTypes.object,
};

export default SessionRecording;
