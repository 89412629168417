import { connect } from 'react-redux';

import { selectBookingAs, fetchPeopleForBookingFor } from 'ducks/user';
import { showPageLoader } from 'ducks/meta';
import { getBookingForPagination, selectUserPermissions } from 'selectors/meta';

import { getPeopleForBookingFor, getLoginUser, getBookingAs } from 'selectors/user';

import PeopleSearch from './PeopleSearch';

const mapStateToProps = (state) => ({
  bookFor: getPeopleForBookingFor(state),
  bookAs: getBookingAs(state),
  userId: getLoginUser(state).get('userId'),
  bookingForPagination: getBookingForPagination(state),
  loginUserPermissions: selectUserPermissions(state),
});

const mapDispatchToProps = (dispatch) => ({
  selectBookingAs: showPageLoader((userId) => dispatch(selectBookingAs(userId)), dispatch),
  loadMoreContacts: (filter, pagination) => dispatch(fetchPeopleForBookingFor(filter, pagination)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PeopleSearch);
