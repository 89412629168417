import isFunction from 'lodash/isFunction';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';
import ImmutablePropTypes from 'react-immutable-proptypes';
import React from 'react';
import PropTypes from 'prop-types';
import css from './styles.scss';
import Column from './Column';

import { asButton } from '../../../utils/accessibility';

const findAllByType = (children, type) => {
  const result = [];
  const targetType = type && (type.displayName || type.name);

  React.Children.forEach(children, (child) => {
    if (child && child.type && (child.type.displayName || child.type.name) === targetType) {
      result.push(child);
    }
  });

  return result;
};

const getDisplayName = (el) => el && el.type && (el.type.displayName || el.type.name);

const getStyle = ({ width, align }) => {
  const style = {};

  if (width) {
    style.width = width;
  }
  if (align) {
    style.textAlign = align;
  }

  return style;
};

const renderThs = (columns) =>
  columns.map((col, index) => {
    const { name, dataKey, th, thClassName } = col.props;
    const props = {
      name,
      dataKey,
      colIndex: index,
    };
    let content;
    let className;

    if (React.isValidElement(th)) {
      content = React.cloneElement(th, props);
      className = getDisplayName(th);
    } else if (isFunction(th)) {
      content = th(props);
      className = th.name;
    } else {
      content = name || '';
    }

    return (
      <th
        style={getStyle(col.props)}
        className={classnames(className, thClassName)}
        data-datakey={dataKey}
        key={`${content}TableHead`}
      >
        {content}
      </th>
    );
  });

const renderTds = (data, entry, columns, rowIndex, history) =>
  columns.map((col, index) => {
    const { dataKey, td, tdClassName } = col.props;
    const value = entry.get(dataKey);
    const props = {
      data,
      rowData: entry,
      tdValue: value,
      dataKey,
      rowIndex,
      colIndex: index,
    };

    let content;
    let className;
    let tdClassNameValue;

    if (React.isValidElement(td)) {
      content = React.cloneElement(td, props);
      className = getDisplayName(td);
    } else if (isFunction(td)) {
      content = td(props);
      className = td.name;
    } else {
      content = value;
    }

    if (isFunction(tdClassName)) {
      tdClassNameValue = tdClassName(props);
    } else {
      tdClassNameValue = tdClassName;
    }

    // TODO: add a linkTemplateKeys array prop, to allow for a link template to use keys other than `id`
    // linkTemplateKeys = ['id', 'name'];
    // linkTemplate(linkTemplateKeys.map(key => entry.get(key)))

    const { linkTemplate } = col.props;

    return (
      <td
        style={getStyle(col.props)}
        className={classnames(className, tdClassNameValue, linkTemplate && css.clickable)}
        key={`${col.props.name}${rowIndex}`}
        {...asButton(() => linkTemplate && history.push(linkTemplate({ id: entry.get('id') })))}
      >
        {content}
      </td>
    );
  });

const renderRows = (data, columns, tableName, rowClassName, history) => {
  if (!data || !data.size) {
    return null;
  }

  return data.valueSeq().map((entry, index) => (
    <tr key={`${tableName}_row${index}`} className={rowClassName}>
      {renderTds(data, entry, columns, index, history)}
    </tr>
  ));
};

function Table(props) {
  const { children, data, className, history, name: tableName } = props;

  const columns = findAllByType(children, Column);
  return (
    <div className={classnames(css.container, className)}>
      {data.size ? (
        <table className={css.table}>
          {columns.filter((col) => !!col.props.name) && (
            <thead>
              <tr>{renderThs(columns)}</tr>
            </thead>
          )}
          <tbody>{renderRows(data, columns, tableName, props.rowClassName, history)}</tbody>
        </table>
      ) : (
        <div className={css.emptyMessage}>{props.emptyMessage || 'Nothing to show'}</div>
      )}
    </div>
  );
}

Table.displayName = 'Table';

Table.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  data: PropTypes.oneOfType([ImmutablePropTypes.map, ImmutablePropTypes.list]).isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  rowClassName: PropTypes.string,
  emptyMessage: PropTypes.string,
  history: PropTypes.object.isRequired,
};

export default withRouter(Table);
