import React from 'react';
import { HeadingToolbar, ToolbarProps } from '@udecode/plate';
import styled from 'styled-components';

const StyledToolbar = styled.div`
  position: absolute;
  top: 0px;
  left: 23px;
  background: white;
  height: 48px;
  padding: 0px;
  margin: 0px;
  border-radius: 8px;
  box-shadow: 2.58px 6px 21px -1px rgba(6, 25, 56, 0.08);
  /* #464AC9 */
  & .slate-HeadingToolbar {
    border-bottom: 0px;
    padding: 0px;
    margin: 0px;
    height: 100%;
    .slate-ToolbarButton {
      height: 28px;
      & > svg {
        width: 2rem;
        height: 2rem;
      }
    }
  }
`;
export const Toolbar = (props: ToolbarProps) => (
  <StyledToolbar>
    <HeadingToolbar {...props} />
  </StyledToolbar>
);
