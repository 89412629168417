import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/molecules/Modal';
import MultiSelect from 'components/molecules/MultiSelect';
import InputField from 'components/generic/Forms/InputField';
import classNames from 'classnames';
import ContactList from 'components/unique/Conversations/ContactList';
import * as Logger from '@crimson-education/browser-logger';

import debounce from 'lodash/debounce';

import css from './styles.scss';

export default class CreateNewChatModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: [],
      inputValue: '',
      highlightedIndex: 0,
      groupName: '',
      showGroupName: false,
    };
    this.selectOption = this.selectOption.bind(this);
    this.loadMore = this.loadMore.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.inputRenderer = this.inputRenderer.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.clearValues = this.clearValues.bind(this);
  }

  onChange(newValue) {
    this.setState({ values: newValue });
    if (newValue.length <= 1) {
      this.setState({ showGroupName: false });
      this.setState({ groupName: '' });
    } else {
      const userIds = newValue.map((user) => user.userId);
      this.checkGroup(userIds);
    }
  }

  onCancel() {
    this.props.onCancel();
    this.clearValues();
  }

  onSubmit() {
    Logger.trackEventSinceLastAction({ message: 'create new chat button' });
    this.props.onConfirm(this.state.values, this.state.groupName);
    this.clearValues();
  }

  onSearch(val) {
    if (val === ' ') return '';
    const string = val.replace(/[^\w\s]/gi, '');
    this.setState({ inputValue: string });
    if (!this.onSearchFunc) {
      this.onSearchFunc = debounce((text) => {
        this.props.setContactsFilter(text);
      }, 500);
    }
    this.onSearchFunc(string);
    return string;
  }

  onInputChange(newValue, actionMeta) {
    if (actionMeta.action !== 'input-blur' && actionMeta.action !== 'menu-close') {
      this.onSearch(newValue);
    }
  }

  onKeyDown(event) {
    const { highlightedIndex } = this.state;

    switch (event.keyCode) {
      case 40: // DOWN
        if (highlightedIndex < this.mapContacts().length - 1) {
          this.setState({ highlightedIndex: highlightedIndex + 1 });
        }
        break;
      case 38: // UP
        if (highlightedIndex > 0) {
          this.setState({ highlightedIndex: highlightedIndex - 1 });
        }
        break;
      case 13: // ENTER
        this.selectOption(this.mapContacts()[highlightedIndex]);
        event.preventDefault();
        break;
      default:
        break;
    }
  }
  onFormInputChange(inputName, value) {
    this.setState({ [inputName]: value });
  }

  onFocus() {
    Logger.trackEventSinceLastAction({ message: 'onfocus input new chat' });
  }

  clearValues() {
    this.setState({
      values: [],
      inputValue: '',
      highlightedIndex: 0,
      groupName: '',
      showGroupName: false,
      readOnly: false,
    });
    this.onSearch('');
  }

  mapContacts() {
    return this.props.contacts
      .filter(
        (opt) =>
          !this.state.values.some((s) => {
            if (s) return s.userId === opt.userId;
            return false;
          }) && opt.userId !== this.props.loginUserId,
      )
      .map((contact) =>
        Object.assign({}, contact, {
          value: contact.userId,
          label: `${contact.firstName} ${contact.lastName}`,
        }),
      );
  }

  checkGroup(userIds) {
    const { threads, loginUserId } = this.props;
    const participantIds = [...userIds, loginUserId];
    const thread = threads.find((thread) => {
      const participants = thread.participants.map((x) => x.userId);
      return participants.length === participantIds.length && participants.every((p) => participantIds.includes(p));
    });
    if (thread && thread.chatName) {
      const chatName = thread.chatName;
      this.setState({ groupName: chatName, showGroupName: false });
    } else {
      this.setState({ groupName: '', showGroupName: true });
    }
  }

  selectOption(option) {
    Logger.trackEventSinceLastAction({ message: 'onselect contact list' });
    this.setState({ values: [...this.state.values, option] });
    this.onSearch('');
    this.setState({ inputValue: '' });
    this.setState({ highlightedIndex: 0 });
    this.select.focus();
    if (this.state.values.length > 0) {
      this.setState({ showGroupName: true });
      const users = [...this.state.values, option];
      const userIds = users.map((user) => user.userId);
      this.checkGroup(userIds);
    }
  }

  inputRenderer(inputProps) {
    return (
      <div className="Select-input">
        <input {...inputProps} value={this.state.inputValue} data-test-id="searchMultiContactsSelectorInput" />
      </div>
    );
  }

  loadMore() {
    this.props.setContactsFilter(this.state.inputValue);
  }

  render() {
    const { isOpen, contactPageInfo } = this.props;

    const mappedContacts = this.mapContacts();
    const { groupName } = this.state;
    return (
      <Modal
        isOpen={isOpen}
        title="START A NEW CHAT"
        submitText="Create chat"
        cancelLabel="close"
        onSubmit={this.onSubmit}
        onSubmitDataTestId="createChatButton"
        onClose={this.onCancel}
        style={css.modalStyle}
        isSubmitDisabled={!this.state.values.length}
      >
        <div className={css.newChatContainer}>
          <MultiSelect
            dataTestId="searchMultiContactsSelectorInput"
            forwardedRef={(select) => {
              this.select = select;
            }}
            onKeyDown={this.onKeyDown}
            onFocus={this.onFocus}
            onChange={this.onChange}
            placeholder="Search for a contact"
            className={classNames(css.chatFormInput, css.selectContainer)}
            value={this.state.values}
            options={mappedContacts}
            onInputChange={this.onInputChange}
            menuIsOpen={false}
            inputValue={this.state.inputValue}
          />
          {this.state.showGroupName ? (
            <InputField
              id="groupName"
              className={classNames(css.groupName)}
              onValueChange={(value) => this.onFormInputChange('groupName', value)}
              value={groupName}
              inputValue={groupName}
              label="Group name"
              dataTestId="groupNameInput"
            />
          ) : (
            <span className={css.labelGroup}>
              {groupName ? (
                <div>
                  <label htmlFor="groupName">Group Name</label>
                  <p>{groupName}</p>
                </div>
              ) : null}
            </span>
          )}
          <ContactList
            className={css.contactList}
            contacts={mappedContacts}
            onSelect={this.selectOption}
            loadMore={this.loadMore}
            contactPageInfo={contactPageInfo}
            highlightedIndex={this.state.highlightedIndex}
          />
        </div>
      </Modal>
    );
  }
}

CreateNewChatModal.propTypes = {
  isOpen: PropTypes.bool,
  contactPageInfo: PropTypes.object,
  loginUserId: PropTypes.string,
  onSearch: PropTypes.func,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  setContactsFilter: PropTypes.func,
  contacts: PropTypes.array,
  getThreadByParticipants: PropTypes.object,
  threads: PropTypes.array,
};
