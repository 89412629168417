import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import RadioGroup from 'components/molecules/RadioGroup';
import RadioButton from 'components/molecules/RadioButton';

import ErrorIcon from 'components/generic/Icons/Error';
import CommentField from 'components/generic/Forms/CommentField';
import Modal from 'components/molecules/Modal';
import css from './styles.scss';

const VALIDATION_MESSAGE = 'Please tell us what happened';
const SESSION_ABSENCE_HEADING = 'PLEASE TELL US WHY';

export default class AbsenceModal extends Component {
  constructor() {
    super();

    this.state = {
      selectedReasonId: '',
      comment: '',
      commentInvalid: false,
    };

    this.onSubmitClick = this.onSubmitClick.bind(this);
    this.handleReasonChange = this.handleReasonChange.bind(this);
    this.handleCommentChange = this.handleCommentChange.bind(this);
    this.getSelectedReasonById = this.getSelectedReasonById.bind(this);
  }

  onSubmitClick() {
    const { selectedReasonId, comment } = this.state;

    const selectedReason = this.getSelectedReasonById(selectedReasonId);

    if (selectedReason.requiresComments && !comment) {
      this.setState({
        commentInvalid: true,
      });
    } else {
      const { eventId, onSubmit } = this.props;
      onSubmit(eventId, comment, selectedReasonId);
    }
  }

  getSelectedReasonById(selectedReasonId) {
    const { reasonsForAbsence } = this.props;
    return selectedReasonId && reasonsForAbsence.find((reason) => reason.id === selectedReasonId);
  }

  handleReasonChange(newReasonId) {
    const selectedReason = this.getSelectedReasonById(newReasonId);
    const { commentInvalid } = this.state;
    this.setState({
      selectedReasonId: newReasonId,
      commentInvalid: selectedReason.requiresComments ? commentInvalid : false,
    });
  }

  handleCommentChange(newComment) {
    this.setState({
      comment: newComment,
      commentInvalid: false,
    });
  }

  render() {
    const { selectedReasonId } = this.state;
    const { onBack, reasonsForAbsence } = this.props;

    return (
      <Modal
        isOpen
        title={SESSION_ABSENCE_HEADING}
        onBack={onBack}
        onSubmit={this.onSubmitClick}
        submitText="Submit"
        onSubmitDataTestId="submitAbsenceFeedbackButton"
        isSubmitDisabled={!selectedReasonId}
      >
        <div className={css.absence}>
          <div className={css.radios}>
            <RadioGroup name="reasons" onChange={this.handleReasonChange} selectedValue={selectedReasonId}>
              {reasonsForAbsence.map((reasonOption, index) => {
                const { id, reason } = reasonOption;
                return (
                  <div key={id}>
                    <RadioButton
                      option={{ text: reason, value: id }}
                      isChecked={selectedReasonId === id}
                      dataTestId={`absenceReason${index}`}
                    />
                  </div>
                );
              })}
            </RadioGroup>
          </div>

          <CommentField
            className={classnames({
              [css.commentBox]: true,
              [css.commentInvalid]: this.state.commentInvalid,
            })}
            dataTestId="otherReasonComment"
            label="Add a comment"
            onValueChange={this.handleCommentChange}
          />

          <div className={css.errorMessageContainer}>
            {this.state.commentInvalid && (
              <div className={css.errorMessage}>
                <ErrorIcon className={css.alertIcon} />
                {VALIDATION_MESSAGE}
              </div>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

AbsenceModal.propTypes = {
  reasonsForAbsence: PropTypes.array,
  eventId: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onBack: PropTypes.func,
};
