import React from 'react';
import PropTypes from 'prop-types';
import Transition from 'react-transition-group/Transition';

const easing = 'cubic-bezier(0.42, 0, 0, 1)';

const getInitialStyles = (index, timeout, taskHeight) => ({
  position: 'absolute',
  width: '100%',
  top: `${index * taskHeight}px`,
  transition: `opacity ${timeout}ms ${easing} 100ms, top ${timeout}ms ${easing} 100ms`,
  opacity: 0,
});

const getStylesForState = (state) => {
  switch (state) {
    case 'entering':
      return {
        opacity: 1,
      };
    case 'entered':
      return {
        opacity: 1,
      };
    default:
      return {};
  }
};

export default function TaskTransition({ in: inProp, index, children, timeout, taskHeight }) {
  return (
    <Transition in={inProp} timeout={timeout} unmountOnExit>
      {(state) => (
        <div
          style={{
            ...getInitialStyles(index, timeout, taskHeight),
            ...getStylesForState(state),
          }}
        >
          {children}
        </div>
      )}
    </Transition>
  );
}

TaskTransition.defaultProps = {
  timeout: 300,
  taskHeight: 50,
};

TaskTransition.propTypes = {
  index: PropTypes.number.isRequired,
  in: PropTypes.bool,
  children: PropTypes.any,
  timeout: PropTypes.number,
  taskHeight: PropTypes.number,
};
