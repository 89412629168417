import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SwipeableDrawer } from '@material-ui/core';

import css from './Drawer.scss';

export default function Drawer(props) {
  const { showMenu, toggleMenu, children, variant } = props;

  return (
    <SwipeableDrawer
      anchor="left"
      variant={variant}
      open={showMenu}
      onOpen={() => toggleMenu(true)}
      onClose={() => toggleMenu(false)}
      className={classNames(css.drawer, { [css.disableClick]: !showMenu })}
      disableSwipeToOpen={false}
    >
      {children}
    </SwipeableDrawer>
  );
}

Drawer.propTypes = {
  children: PropTypes.element,
  showMenu: PropTypes.bool,
  toggleMenu: PropTypes.func,
  variant: PropTypes.string,
};
