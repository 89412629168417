import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import DropdownMenuButton from './DropdownMenuButton';
import Prompt from './Prompt';
import { US, UK, EU, Others } from 'components/atoms/icons';
import {
  StripContainer,
  IconContainer,
  RightContainer,
  Column,
  LargeLabel,
  MediumLabel,
  TinyLabel,
  Row,
  SmallLabel,
  HighlightLabel,
  HSplit,
} from './index.style';
import { ApplicationRegion } from '../type';
import { AppTrackerContext } from '../viewController';
import {
  useUpcomingApplication,
  useSchoolSelectionStatus,
  useSubmitted,
  useResultReceived,
  useSchoolsSelected,
} from './helper';
import { useIsAccessibleStaff, useCalculateCurrentSelectionHash } from '../helper';
import { getViewableUser } from 'selectors/meta';

type DropdownMenuItemValue = { label: string; value: string; available: string[] };
type OverviewStripProps = {
  region: ApplicationRegion;
};

const allServiceStatusOptions: DropdownMenuItemValue[] = [
  {
    label: 'In Progress',
    value: 'In Progress',
    available: ['US', 'UK', 'EU', 'Other'],
  },
  {
    label: 'All School Submitted',
    value: 'All School Submitted',
    available: ['US', 'UK', 'EU', 'Other'],
  },
  {
    label: 'Pre-Interview Meeting Completed',
    value: 'Pre-Interview Meeting Completed',
    available: ['UK', 'EU', 'Other'],
  },
  {
    label: 'Post-Offers Meeting Completed',
    value: 'Post-Offers Meeting Completed',
    available: ['US', 'EU', 'Other'],
  },
  {
    label: 'Post-All-UCAS-Offers Meeting Completed',
    value: 'Post-All-UCAS-Offers Meeting Completed',
    available: ['UK'],
  },
  {
    label: 'Post-All-Offers Meeting Completed',
    value: 'Post-All-Offers Meeting Completed',
    available: ['UK'],
  },
  {
    label: 'Service Completed',
    value: 'Service Completed',
    available: ['US', 'UK', 'EU', 'Other'],
  },
];

const tooltip =
  'The purpose of this field is to gather completion status of service milestones and to facilitate data monitoring on Metabase. Please modify your service status accordingly.';

const regionIcons = { US: <US />, UK: <UK />, EU: <EU />, Other: <Others /> };

const OverviewStrip = ({ region }: OverviewStripProps) => {
  const { state, controller } = useContext(AppTrackerContext)!;
  const { applications, approvals, overviews, currentSelectionHash } = state;
  const isAccessibleStaff = useIsAccessibleStaff();
  const userId = useSelector(getViewableUser);
  const upcoming = useUpcomingApplication(applications, region);
  const status = useSchoolSelectionStatus(applications, region, approvals, currentSelectionHash);
  const schoolsSelected = useSchoolsSelected(applications, region);
  const submitted = useSubmitted(applications, region);
  const resultReceived = useResultReceived(applications, region);
  const options = allServiceStatusOptions.filter((o) => o.available.includes(region));

  const onClickItem = (v: string) => {
    const overview = overviews[region];
    if (overview?.id) {
      controller.updateApplicationOverview(overview.id, { ...overview, serviceStatus: v });
    } else {
      controller.createApplicationOverview({
        region,
        userId,
        serviceStatus: v,
      });
    }
  };

  return (
    <StripContainer>
      <IconContainer>{regionIcons[region]}</IconContainer>
      <Column style={{ width: '155px' }}>
        <LargeLabel>{region === 'Other' ? 'Others' : region}</LargeLabel>
        {upcoming?.deadline ? (
          <Row>
            <SmallLabel>{DateTime.fromISO(upcoming.deadline) > DateTime.now() ? 'Upcoming:' : 'Overdue:'}</SmallLabel>
            <HighlightLabel
              style={{ backgroundColor: DateTime.fromISO(upcoming.deadline) > DateTime.now() ? '#464ac9' : '#ff764c' }}
            >
              {DateTime.fromISO(upcoming.deadline).toLocaleString({
                month: 'short',
                day: '2-digit',
                year: 'numeric',
              })}
            </HighlightLabel>
          </Row>
        ) : (
          <MediumLabel>-</MediumLabel>
        )}
      </Column>
      <HSplit style={{ margin: '0 0 0 25px' }} />
      <RightContainer>
        <Column style={{ width: '175px' }}>
          <SmallLabel>School Selection Status</SmallLabel>
          <MediumLabel>{status.text}</MediumLabel>
          {status.date ? (
            <Row>
              <TinyLabel style={{ color: '#73747D' }}>Sent on &nbsp;</TinyLabel>
              <TinyLabel>
                {DateTime.fromISO(status.date).toLocaleString({
                  month: 'short',
                  day: '2-digit',
                  year: 'numeric',
                })}
              </TinyLabel>
            </Row>
          ) : (
            <SmallLabel>&nbsp;</SmallLabel>
          )}
        </Column>
        <Column style={{ flex: '1', justifyContent: 'flex-start', minWidth: '150px' }}>
          <SmallLabel>Schools Selected</SmallLabel>
          {schoolsSelected.selected > 0 ? (
            <Row style={{ display: 'block', marginTop: '6px' }}>
              <MediumLabel>{schoolsSelected.text}</MediumLabel>
              &nbsp;
              <MediumLabel style={{ color: '#A9ACC0' }}>(/{schoolsSelected.selected})</MediumLabel>
            </Row>
          ) : (
            <MediumLabel>-</MediumLabel>
          )}
        </Column>
        <Column>
          <SmallLabel>Submitted</SmallLabel>
          {submitted.selected > 0 ? (
            <Row>
              <MediumLabel>{submitted.submitted}</MediumLabel>
              <MediumLabel style={{ color: '#A9ACC0' }}>(/{submitted.selected})</MediumLabel>
            </Row>
          ) : (
            <MediumLabel>-</MediumLabel>
          )}
          <SmallLabel>&nbsp;</SmallLabel>
        </Column>
        <Column style={{ minWidth: '115px' }}>
          <SmallLabel>Decision Received</SmallLabel>
          {resultReceived.submitted > 0 ? (
            <Row>
              <MediumLabel>{resultReceived.resultReceived}</MediumLabel>
              <MediumLabel style={{ color: '#A9ACC0' }}>(/{resultReceived.submitted})</MediumLabel>
            </Row>
          ) : (
            <MediumLabel>-</MediumLabel>
          )}
          <SmallLabel>&nbsp;</SmallLabel>
        </Column>
        {isAccessibleStaff && (
          <Column style={{ width: '180px' }}>
            <Row>
              <SmallLabel style={{ paddingLeft: '8px' }}>Service Status</SmallLabel>
              &nbsp;
              <Prompt prompt={tooltip} />
            </Row>
            <DropdownMenuButton
              data={options}
              onClickItem={onClickItem}
              text={overviews[region]?.serviceStatus || 'In Progress'}
              menuItemStyle={{ width: 'auto' }}
            />
            <SmallLabel>&nbsp;</SmallLabel>
          </Column>
        )}
      </RightContainer>
    </StripContainer>
  );
};

export default OverviewStrip;
