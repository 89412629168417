import React from 'react';
import PropTypes from 'prop-types';

import css from './styles.scss';

export default function TooltipItem(props) {
  const { children } = props;

  return <div className={css.toolTipItem}>{children}</div>;
}

TooltipItem.propTypes = {
  children: PropTypes.node.isRequired,
};
