import React from 'react';
import PropTypes from 'prop-types';
import OptionSelectForm from 'components/generic/OptionSelectForm';

const SESSION_ABSENCE_HEADING = 'PLEASE TELL US WHY';

export default function Absence(props) {
  const { onBack, onSubmit } = props;

  const options = [
    {
      id: 'feedback_incorrect_absent',
      value: 'ABSENT',
      label: "I couldn't make it and didn't have time to cancel",
    },
    {
      id: 'feedback_incorrect_agree_to_cancel',
      value: 'AGREE_TO_CANCEL',
      label: 'My strategist and I agreed to cancel this session',
    },
    {
      id: 'feedback_incorrect_forgot',
      value: 'FORGOT',
      label: 'I forgot about it',
    },
    {
      id: 'feedback_incorrect_no_strategist',
      value: 'NO_STRATEGIST',
      label: "My strategist didn't turn up",
    },
  ];

  return (
    <OptionSelectForm
      radioGroupName="session_absence"
      hasOtherOption
      options={options}
      title={SESSION_ABSENCE_HEADING}
      onBack={onBack}
      onSubmit={onSubmit}
    />
  );
}

Absence.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onBack: PropTypes.func,
};
