import styled from 'styled-components';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

export const Container = styled.div`
  box-sizing: border-box;
  width: 500px;
  background: #ffffff;
  border-radius: 12px;
  margin-top: -50px;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 40px 70px 55px;
  margin: 50px auto;
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 24px;
  font-family: 'Montserrat';
  line-height: 29px;
  color: #1d1e2b;
  align-self: center;
  text-align: center;
`;

export const Content = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;

  color: #1d1e2b;
  margin: 32px 0;
`;
export const StyledModal = styled(Modal)`
  overflow-y: auto;
  display: block;
`;

export const StyledClose = styled(CloseIcon)`
  font-size: 25px;
`;
export const StyledIconButton = styled(IconButton)`
  display: flex;
  position: absolute;
  top: 10px;
  right: 10px;
`;
