import { Button, Modal as MuiModal } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { Input, InputNumber } from 'antd';
import styled from 'styled-components';

export const Modal = styled(MuiModal)`
  margin: auto auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  max-width: 526px;
  overflow-y: auto;
  display: block;
`;

export const Container = styled.div`
  width: 100%;
  position: relative;
  font-family: Montserrat;
  background: white;
  border-radius: 8px;
  padding: 20px 40px 40px;
  margin: 50px auto;
  color: #73747d;
`;

export const Title = styled.h1`
  color: black;
  text-align: center;
  margin-bottom: 24px;
`;

export const LineItemContainer = styled.div`
  border-radius: 4px;
  padding: 12px 48px 12px 20px;
  border: 1px solid #e3e7ed;
  background: rgba(243, 246, 250, 0.5);
  box-shadow: 0 1px 2px rgba(184, 200, 224, 0.2);
  margin-bottom: 20px;
`;

export const LineItemLabel = styled.span`
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  font-style: normal;
  color: '#A9ACC0';
`;

export const LineItem = styled.p`
  font-weight: 600;
  font-size: 14px;
  color: #1d1e2b;
`;

export const LineItemRate = styled.p`
  font-weight: 700;
  font-size: 16px;
  color: #ff764c;
  margin: 0;

  span {
    font-weight: 400;
    color: #a9acc0;
    font-size: 14px;
  }
`;

export const SummaryRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;

  & :nth-child(1) {
    flex-grow: 1;
  }
`;

export const QuantityPicker = styled(InputNumber)`
  background: #f3f6fa;
  border-radius: 4px;
  border: none;
  padding: 4px 12px 4px 12px;
  font-size: 8px 12px 8px 16px;
  font-weight: 500;
  line-height: 20px;
  color: #1d1e2b;
  width: 96px;

  &:hover {
    background: #f3f6fa;
  }

  .ant-input-number-handler-wrap {
    opacity: 1;
    background: transparent;
    right: 10px;
    padding: 7px 0;

    &:hover .ant-input-number-handler-up,
    .ant-input-number-handler-down {
      height: 50%;
    }
    .ant-input-number-handler {
      border: none;
    }
    .ant-input-number-handler-up:hover {
      height: 50% !important;
    }
    .ant-input-number-handler-down:hover {
      height: 50% !important;
    }
  }
`;

export const QuantityUpIcon = styled(KeyboardArrowUp)`
  color: #454f5b;
  width: 18px;
  height: auto;
  :hover {
    color: #000;
    width: 20px;
  }
`;

export const QuantityDownIcon = styled(KeyboardArrowDown)`
  color: #454f5b;
  width: 18px;
  height: auto;
  :hover {
    color: #000;
    width: 20px;
  }
`;

export const TotalLabel = styled.p`
  color: #1d1e2b;
  font-weight: 700;
`;

interface TotalStyleProps {
  isInvalid: boolean;
}
export const Total = styled.p<TotalStyleProps>`
  font-weight: 700;
  font-size: 14px;
  color: ${(props) => (props.isInvalid ? '#ED4B53' : '#ff764c')};
  flex-grow: 0;
  min-width: 76px;
  text-decoration: ${(props) => (props.isInvalid ? 'line-through' : 'none')};
`;

export const ActionRow = styled.div`
  display: flex;
`;
export const CheckoutButton = styled(Button)`
  padding: 10px 10%;
  border-radius: 30px;
  background: ${(props) => (props.disabled ? '#e3e7ed' : '#464ac9')};
  color: white;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  text-transform: capitalize;
  margin: 32px auto 12px;
  :hover {
    background: #464ac9;
    text-decoration: underline;
  }
  span {
    margin-left: 10px;
  }
`;

export const NoticeContainer = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  color: #73747d;
  align-items: flex-start;
  gap: 10px;
  margin-top: 16px;
`;

export const LoadingContainer = styled.div`
  padding: 48px 0;
  display: flex;
  justify-content: center;
`;

export const EmailBlock = styled.div`
  padding: 22px 0 0;
  p {
    font-weight: 700;
    font-size: 14px;
    color: #1d1e2b;
  }
  input {
    border: 1px solid #e3e7ed;
    border-radius: 4px;
    width: 100%;
    line-height: 20px;
    font-size: 14px;
    font-weight: 500;
    padding: 12px 16px;
    ::placeholder {
      opacity: 0.5;
    }
  }
`;

export const EmailInput = styled(Input)`
  border: 1px solid #e3e7ed;
  border-radius: 4px;
  width: 100%;
  line-height: 20px;
  font-size: 14px;
  font-weight: 500;
  padding: 12px 16px;
  ::placeholder {
    opacity: 0.5;
  }
`;
