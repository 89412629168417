import { connect } from 'react-redux';
import { fetchInvoices, fetchInvoiceById } from 'ducks/invoicing';
import { getInvoices, getCurrentPage, getTotalPages } from 'selectors/invoicing';
import { getLoginUser } from 'selectors/user';
import componentKeys from 'constants/componentKeys';
import { getMetaItem, getInvoiceFilter } from 'selectors/meta';
import { showPageLoader, updateMeta } from 'ducks/meta';
import InvoiceList from './InvoiceList';

const mapStateToProps = (state) => ({
  loginUser: getLoginUser(state),
  invoices: getInvoices(state),
  fetched: getMetaItem(componentKeys.INVOICES_FETCHED)(state),
  filter: getInvoiceFilter(state),
  totalPages: getTotalPages(state),
  currentPage: getCurrentPage(state),
  selectedInvoiceId: getMetaItem(componentKeys.INVOICE_SELECTED_ID)(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchInvoices: showPageLoader(
    ({ filter, first, after }) => dispatch(fetchInvoices({ filter, first, after })),
    dispatch,
  ),
  setSelectedInvoiceId: (id) => dispatch(updateMeta(componentKeys.INVOICE_SELECTED_ID, id)),
  fetchInvoiceById: (id) => dispatch(fetchInvoiceById(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceList);
