import React from 'react';
import Popover, { PopoverProps } from 'antd/lib/popover';

export type OtherPopoverProps = PopoverProps;

export type BasicPopoverProps = {
  children: React.ReactNode;
} & OtherPopoverProps;

const BasicPopover = ({ children, content, overlayInnerStyle, ...restProps }: BasicPopoverProps) => {
  return (
    <Popover
      content={content}
      overlayInnerStyle={{
        borderRadius: 8,
        border: '1px solid #e3e7ed',
        boxShadow: '0px 5px 15px rgba(61, 64, 144, 0.2)',
        padding: '15px 20px',
        ...overlayInnerStyle,
      }}
      {...restProps}
    >
      {children}
    </Popover>
  );
};

export default BasicPopover;
