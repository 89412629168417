import React, { useContext, useState } from 'react';
import { Upload, UploadFileItemContainer } from './index.style';
import type { UploadFile } from 'antd/es/upload/interface';
import CloseIcon from '@material-ui/icons/Close';
import uploadedFileService from 'graphql/api/uploadedFile';
import { AppTrackerContext } from '../../viewController';

export type UploadEssay = UploadFile & {
  essayType?: string;
};

type Props = {
  fileList: UploadEssay[];
  deleteFile: (id: string) => void;
  uploading: boolean;
};

interface UploadListItemProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  originNode: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actions: any;
  file: UploadEssay;
  fileList: UploadEssay[];
}

const matchFileTypeToIconName = (type: string) => {
  if (/png|jpg|jpeg|gif|svg|webp/.test(type)) {
    return 'image';
  }
  if (type.includes('pdf')) {
    return 'pdf';
  }
  if (/word|doc/.test(type)) {
    return 'word';
  }
  if (/excel|xls/.test(type)) {
    return 'excel';
  }
  if (/powerpoint|ppt/.test(type)) {
    return 'ppt';
  }
  return 'unknown';
};

const download = (name: string, url: string) => {
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const blobUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
};

const UploadedFileList: React.FC<Props> = ({ fileList, deleteFile, uploading }) => {
  const { state, controller } = useContext(AppTrackerContext)!;

  const UploadedFileItem: React.FC<UploadListItemProps> = ({ file, originNode, actions }) => {
    const [showDelete, setShowDelete] = useState(false);
    return (
      <UploadFileItemContainer
        showHover={showDelete}
        onMouseEnter={() => {
          if (!uploading) {
            setShowDelete(true);
          }
        }}
        onMouseLeave={() => {
          setShowDelete(false);
        }}
      >
        <div
          className="hover-effect-container"
          onClickCapture={(e) => {
            e.stopPropagation();
            if (uploading) return;
            uploadedFileService
              .getApplicationTrackerDownloadUrl(file.uid)
              .then((res) => {
                const { url } = res.getApplicationTrackerDownloadUrl;
                if (file.type?.includes('pdf') || file.name?.endsWith('pdf')) {
                  window.open(url, '_blank');
                } else {
                  download(file.name, url);
                }
              })
              .catch((err) => {
                controller.showError(err.message);
              });
          }}
        >
          <img
            className="document-type-icon"
            alt="document-type-icon"
            src={`/static/applicationTracker/essayUpload/${matchFileTypeToIconName(file.type || '')}.svg`}
          />

          <div title={file.name} className="upload-list-item-node-container">
            {originNode}
            <div className="essay-type-text">{file.essayType}</div>
          </div>
        </div>

        <div
          className="upload-list-item-remove"
          style={{ display: showDelete ? 'block' : 'none' }}
          onMouseEnter={() => {
            if (!uploading) {
              setShowDelete(true);
            }
          }}
        >
          <CloseIcon
            fontSize="large"
            onClick={() => {
              actions.remove(file);
            }}
          />
        </div>
      </UploadFileItemContainer>
    );
  };

  return (
    <Upload
      length={fileList.length}
      fileList={fileList}
      showUploadList={{ showRemoveIcon: false }}
      onRemove={async (file) => {
        deleteFile(file.uid);
      }}
      itemRender={(originNode, file, _list, actions) => (
        <UploadedFileItem originNode={originNode} file={file as UploadEssay} fileList={fileList} actions={actions} />
      )}
    />
  );
};

export default UploadedFileList;
