import { createSelector } from 'reselect';
import moment from 'moment-timezone';
import Immutable from 'immutable';
import range from 'lodash/range';
import { BookingStatusTypes } from '@crimson-education/common-config';
import { getSelectedDay, getCalendarDays } from 'selectors/meta';
import { selectedBookWith, getBookingsForBookingAs, getUnavailableForBookingAs, getLoginUser } from 'selectors/user';
import { mapBookingsToBlocks, mapUnavailabilitiesIntoBlocks, foldBlocks } from 'utils/blockMapper';

const bookingTypesToShow = [
  BookingStatusTypes.TENTATIVE,
  BookingStatusTypes.CONFIRMED,
  BookingStatusTypes.COMPLETED,
  BookingStatusTypes.AUTO_COMPLETED,
];

function makeBookingsArray(allBookings, date) {
  const splitBookings = [];
  const activeBookings = allBookings.filter((b) => bookingTypesToShow.some((t) => t === b.get('status')));
  const mappedBookings = activeBookings
    .map((b) =>
      Object.assign(b.toJS(), {
        person: `${b.getIn(['otherUser', 'firstName'])} ${b.getIn(['otherUser', 'lastName'])}`,
      }),
    )
    .toArray();

  mappedBookings.forEach((b) => {
    const start = moment(b.start);
    const end = moment(b.end);
    if (start.date() !== end.date()) {
      const splitBookingsObj = Object.assign({}, b, {
        displayStart: moment([end.year(), end.month(), end.date()]),
      });
      if (!!end.hour() && !!end.minute()) {
        splitBookings.push(
          Object.assign(splitBookingsObj, {
            displayEnd: moment([start.year(), start.month(), start.date(), 23, 59]),
          }),
        );
      } else {
        splitBookings.push(splitBookingsObj);
      }
    }
  });
  mappedBookings.push(...splitBookings);

  return mappedBookings.filter((b) =>
    (b.displayStart ? moment(b.displayStart) : moment(b.start)).isBetween(
      moment(date).startOf('day'),
      moment(date).endOf('day'),
      null,
      '[)',
    ),
  );
}

function makeAvailabilitiesArray(selectedUsers, date, timezone, unavailable) {
  const unavailableBlocks = mapUnavailabilitiesIntoBlocks(unavailable, date, timezone);
  // const availabilitiesUnFlattened = selectedUsers.toArray().map((user, counter) => mapUserAvailabilitiesToBlocks(user, date, counter, timezone));
  // const availabilitiesFlattened = flatten(availabilitiesUnFlattened);

  return unavailableBlocks;
}

function makeDayObject(date, selectedUsers, allBookings, timezone, unavailable) {
  const bookings = allBookings ? makeBookingsArray(allBookings, date) : [];
  const foldedUnavailabilities = allBookings ? makeAvailabilitiesArray(selectedUsers, date, timezone, unavailable) : [];
  const dayElements = foldBlocks(foldedUnavailabilities, mapBookingsToBlocks(Immutable.fromJS(bookings)));
  return {
    date,
    dayElements,
  };
}

function extractWeekData(selectedUsers, allBookings, startDate, loginUser, unavailable, calendarDays) {
  const daysToDisplay = calendarDays || 7;

  moment.locale(navigator.language || navigator.languages[0]);
  const timezone = loginUser.get('timezone');
  const startOfDay = moment(startDate).local().startOf('day');
  const weekDayDates = range(daysToDisplay).map((increment) => moment(startOfDay).add(increment, 'day').toDate());
  const week = weekDayDates.map((date) => makeDayObject(date, selectedUsers, allBookings, timezone, unavailable));
  return Immutable.fromJS(week);
}

export default createSelector(
  [
    selectedBookWith,
    getBookingsForBookingAs,
    getSelectedDay,
    getLoginUser,
    getUnavailableForBookingAs,
    getCalendarDays,
  ],
  extractWeekData,
);
