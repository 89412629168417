import React from 'react';
import PropTypes from 'prop-types';
import { DecileRanks as rankList } from '@crimson-education/common-config';
import Accordion from 'components/molecules/Accordion';
import AccordionSummary from 'components/molecules/AccordionSummary';
import { Table, Column } from 'components/molecules/Table';
import IconTextButton from 'components/molecules/IconTextButton';
import AddNew from 'components/molecules/AddNew';
import styles from './styles.scss';

export default function DecileRankTable(props) {
  const { canEdit, decileRanks, onNewDecile, onEditDecile, deleteDeciles } = props;

  // Empty state.
  const emptyMessage = 'No grades have been entered for this student.';

  // Columns to show in the table.
  const columns = [];

  // Construct grade column.
  columns.push(<Column key="grade" name="Grade Level(current Year/Grade Level field) " dataKey="grade" />);

  // Construct rank column.
  columns.push(<Column key="rank" name="Academic Ranking （estimate if unknown）" dataKey="rank" />);

  // Construct data, our rows.
  const rows = decileRanks.map((decile) => {
    // Default cells, subject and date are always shown.
    const row = {
      id: decile.id,
      grade: decile.grade,
      rank: rankList.find((level) => level.value === decile.rank).label,
    };

    return row;
  });

  // Handle curriculum deletion.
  const onDeleteDeciles = (e) => {
    e.stopPropagation();
    const ids = decileRanks.map((grade) => grade.id);
    deleteDeciles(ids, 'decile');
  };

  // Construct accordion summary for this grade table.
  // We only count the leaf node grades for the count.
  const summary = (
    <AccordionSummary
      title="Decile rank"
      results={`${decileRanks.length}`}
      resultsText={decileRanks.length > 1 ? 'results' : 'result'}
      actions={
        canEdit && (
          <IconTextButton icon="Delete" onClick={onDeleteDeciles}>
            Delete table
          </IconTextButton>
        )
      }
    />
  );

  // Construct accordion content for this grade table.
  const content = (
    <div>
      <Table
        isScrollable
        data={rows}
        emptyMessage={emptyMessage}
        className={styles.table}
        onRowClick={
          canEdit
            ? (decile) => {
                onEditDecile(decile.id);
              }
            : undefined
        }
      >
        {columns}
      </Table>
      {canEdit && <AddNew className={styles.newButton} text="Add Decile Rank" onClick={onNewDecile} />}
    </div>
  );

  return <Accordion summary={summary} content={content} className={styles.gradesTable} />;
}

DecileRankTable.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  onNewDecile: PropTypes.func.isRequired,
  onEditDecile: PropTypes.func.isRequired,
  deleteDeciles: PropTypes.func.isRequired,
  decileRanks: PropTypes.array.isRequired,
};
