import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import css from './styles.scss';

const defaultDateFormat = 'ddd[,] Do MMM';

export default function DateDivider({ timestamp }) {
  return (
    <div className={css.wrapper}>
      <div className={css.line} />
      <div className={css.text}>
        {moment(timestamp).calendar(null, {
          sameDay: '[Today]',
          nextDay: defaultDateFormat,
          nextWeek: defaultDateFormat,
          lastDay: defaultDateFormat,
          lastWeek: defaultDateFormat,
          sameElse: defaultDateFormat,
        })}
      </div>
      <div className={css.line} />
    </div>
  );
}

DateDivider.propTypes = {
  timestamp: PropTypes.string,
};
