// Return a full name from a series of names, filtering undefined.
export function getFullName(...names) {
  return names.filter((name) => !!name).join(' ');
}

// Return initials from a list of names.
export function getInitials(...names) {
  const initials = names.map((name) => {
    return name.charAt(0).toUpperCase();
  });
  return initials.join('');
}
