import Immutable from 'immutable';
import { createSelector } from 'reselect';
import titleCase from 'titlecase';
import { contractStatusTypes } from '@crimson-education/common-config';
import { getInitialsFromNames } from 'utils/utils';

const MAX_COLORS = 9;

const contracts = (state) => state.get('contract');

function mapStudentFromContract(contract) {
  const item = contract.get('item');
  const user = contract.getIn(['item', 'package', 'user']);
  return contract.getIn(['item', 'package', 'user']).mergeDeep({
    initials: getInitialsFromNames([user.get('firstName'), user.get('lastName')]),
    fullName: titleCase(`${user.get('firstName')} ${user.get('lastName')}`),
    invitationDetails: {
      tutoringFrequency: item.get('tutoringFrequency'),
      preferredTimes: item.get('preferredTimes'),
      additionalNotes: item.get('notes'),
    },
    subjects: [
      {
        id: item.get('id'),
        subjectId: item.get('subjectId'),
        name: item.get('name'),
        totalHours: item.get('initialValue'),
        remainingHours: item.get('remainingValue'),
        pendingHours: item.get('pendingValue'),
        isUnlimited: item.get('isUnlimited'),
      },
    ],
    status: contract.get('status'),
    contractId: contract.get('id'),
    hasResponded: contract.get('hasResponded'),
  });
}

function mapActiveStudentFromContract(contract) {
  const student = mapStudentFromContract(contract);
  return student.set(
    'subjects',
    student.get('subjects').map((item) => item.set('subject', item.get('name'))),
  );
}

function mapPendingStudentFromContract(contract) {
  const student = mapStudentFromContract(contract);
  return student.set('start', contract.getIn(['item', 'start'])).set('contractId', contract.get('id'));
}

function mapPastStudentFromContract(contract) {
  const student = mapStudentFromContract(contract);
  return student.set(
    'subjects',
    student.get('subjects').map((subject) => subject.set('pendingHours', 0).set('remainingHours', 0)),
  );
}

function mapUnavailableStudentFromContract(contract) {
  const studentByContractMap = mapStudentFromContract(contract);
  const studentSubjects = {};
  for (const [key, value] of studentByContractMap.entries()) {
    studentSubjects[key] = value;
  }
  return Immutable.fromJS({
    contractId: contract.get('id'),
    subjects: [{ name: contract.getIn(['item', 'name']), studentSubjects }],
    status: contract.get('status'),
  });
}

function getMapper(status) {
  switch (status) {
    case contractStatusTypes.ACTIVE:
      return mapActiveStudentFromContract;
    case contractStatusTypes.PENDING:
      return mapPendingStudentFromContract;
    case contractStatusTypes.RETRACTED:
    case contractStatusTypes.DECLINED:
      return mapUnavailableStudentFromContract;
    case contractStatusTypes.ARCHIVED:
    case contractStatusTypes.DELETED:
      return mapPastStudentFromContract;
    default:
      throw new Error(`Unknown contract status (${status})!`);
  }
}

function isContractStatusOngoing(status) {
  switch (status) {
    case contractStatusTypes.ACTIVE:
    case contractStatusTypes.PENDING:
      return true;
    default:
      return false;
  }
}

function wasContractStatusActive(status) {
  switch (status) {
    case contractStatusTypes.DELETED:
    case contractStatusTypes.ARCHIVED:
    case contractStatusTypes.RETRACTED:
      return true;
    default:
      return false;
  }
}

/**
 * Generates a list of students from contracts based on the
 * desired contract status. Ignores contracts with statuses
 * not explicitly defined in {@link isContractStatusOngoing}
 * or  {@link wasContractStatusActive}.
 *
 * @param {Immutable.Map} contracts
 * @param {string} status filters and maps accordingly
 * @return {Immutable.List}
 */
function buildStudentsFromContracts(contracts, status) {
  return contracts
    .toList()
    .filter((contract) => {
      // ignore contracts without a package user
      if (!contract.getIn(['item', 'package', 'user'])) {
        return false;
      }

      const statusMatches = status === contract.get('status');
      const isUnlimited = contract.getIn(['item', 'isUnlimited']);
      const hasRemainingHours = contract.getIn(['item', 'remainingValue']) > 0;
      const hasPendingHours = contract.getIn(['item', 'pendingValue']) > 0;
      const noHoursLeft = !hasRemainingHours && !hasPendingHours;

      // A special case when the contract status is ongoing (e.g. active),
      // has hours assigned but no hours left on the package.
      if (isContractStatusOngoing(contract.get('status')) && !isUnlimited) {
        return wasContractStatusActive(status) ? noHoursLeft : !noHoursLeft && statusMatches;
      }
      return statusMatches;
    })
    .map((contract) => getMapper(status)(contract))
    .sort((a, b) => (a.get('firstName') ? a.get('firstName').localeCompare(b.get('firstName')) : 0))
    .map((student, idx) => student.set('colorIndex', idx % MAX_COLORS));
}

export const getContractById = (contractId) => createSelector(contracts, (contracts) => contracts.get(contractId));

export const getContractsByStudentId = (studentId) =>
  createSelector(contracts, (contracts) =>
    contracts.filter((contract) => contract.getIn(['item', 'package', 'user', 'userId']) === studentId),
  );

export const getAllContractsByTutorId = (tutorId) =>
  createSelector(contracts, (contracts) => contracts.filter((contract) => contract.get('userId') === tutorId));

// FIXME: itemId passed in could be a string or an int
export const getContractsByItemId = (itemId) =>
  createSelector(contracts, (contracts) =>
    contracts
      .filter((contract) => `${contract.getIn(['item', 'id'])}` === itemId)
      .filter((contract) => {
        const status = contract.get('status');
        return (
          status === contractStatusTypes.ACTIVE ||
          status === contractStatusTypes.PENDING ||
          status === contractStatusTypes.DECLINED
        );
      }),
  );

export const getInvitationsByTutorId = (tutorId) =>
  createSelector(contracts, (contracts) =>
    contracts
      .filter((contract) => contract.get('userId') === tutorId)
      .filter((contract) => {
        const status = contract.get('status');
        return (
          status === contractStatusTypes.ACTIVE ||
          status === contractStatusTypes.PENDING ||
          status === contractStatusTypes.ARCHIVED ||
          status === contractStatusTypes.DELETED
        );
      }),
  );

export const getActiveStudentsByTutorId = (tutorId) =>
  createSelector(getAllContractsByTutorId(tutorId), (contracts) =>
    buildStudentsFromContracts(contracts, contractStatusTypes.ACTIVE),
  );

export const getPendingStudentsByTutorId = (tutorId) =>
  createSelector(getAllContractsByTutorId(tutorId), (contracts) => {
    return buildStudentsFromContracts(contracts, contractStatusTypes.PENDING);
  });

export const getPastStudentsByTutorId = (tutorId) =>
  createSelector(getAllContractsByTutorId(tutorId), (contracts) => {
    const archived = buildStudentsFromContracts(contracts, contractStatusTypes.ARCHIVED);
    const deleted = buildStudentsFromContracts(contracts, contractStatusTypes.DELETED);
    return archived.concat(deleted);
  });

export const getUnavailableStudentsByTutorId = (tutorId) =>
  createSelector(getAllContractsByTutorId(tutorId), (contracts) => {
    const retracted = buildStudentsFromContracts(contracts, contractStatusTypes.RETRACTED);
    const declined = buildStudentsFromContracts(contracts, contractStatusTypes.DECLINED);
    return retracted.concat(declined);
  });

export const getStudentsByTutorId = (tutorId) =>
  createSelector(
    [getActiveStudentsByTutorId(tutorId), getPendingStudentsByTutorId(tutorId), getPastStudentsByTutorId(tutorId)],
    (current, pending, past) => new Immutable.List().concat(current).concat(pending).concat(past),
  );

export const getGroupedStudentsByTutorId = (tutorId) =>
  createSelector(
    [
      getActiveStudentsByTutorId(tutorId),
      getPendingStudentsByTutorId(tutorId),
      getPastStudentsByTutorId(tutorId),
      getUnavailableStudentsByTutorId(tutorId),
    ],
    (current, pending, past, unavailable) => Immutable.fromJS({ current, pending, past, unavailable }),
  );
