import React from 'react';
import Body from 'components/atoms/typography/Body';
import SubjectProgressBar from 'components/generic/SubjectProgressBar';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import css from './styles.scss';

export default function SubjectSection({ subject, parentSubject, isPast }) {
  const {
    id,
    name,
    subject: subjectName,
    isUnlimited,
    remainingHours,
    remainingValue,
    totalHours,
    initialValue,
    pendingHours,
    pendingValue,
  } = subject;
  return (
    <Link
      data-ga-label="Subject"
      key={`subject${id}${name}`}
      to={`/subjects/${id}/lessons`}
      className={isPast ? css.pastSubjectLink : css.subjectLink}
    >
      <div className={css.subjectContainer}>
        <Body className={css.subjectText}>{name || subjectName}</Body>
        <Body className={css.parentSubjectText}>{parentSubject || ''}</Body>
        <SubjectProgressBar
          isUnlimited={isUnlimited}
          remainingUnits={remainingHours || remainingValue}
          totalUnits={totalHours || initialValue}
          pendingUnits={pendingHours || pendingValue}
        />
      </div>
    </Link>
  );
}

SubjectSection.propTypes = {
  parentSubject: PropTypes.string,
  subject: PropTypes.object,
  isPast: PropTypes.bool,
};
