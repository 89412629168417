import React, { Component } from 'react';
import PropTypes from 'prop-types';

import css from './styles.scss';

export default class UnavailabilityBlock extends Component {
  shouldComponentUpdate(nextProps) {
    return nextProps.style.height !== this.props.style.height || nextProps.style.top !== this.props.style.top;
  }

  render() {
    const { style } = this.props;

    return (
      <div className={css.unavailable} style={style}>
        <span className={css.busy}>Busy</span>
      </div>
    );
  }
}

UnavailabilityBlock.propTypes = {
  style: PropTypes.object.isRequired,
};
