import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Container as InfoTextContainer, Header as InfoTextHeader } from 'components/generic/InfoText';
import ComponentSwitcher from 'components/generic/Forms/ComponentSwitcher';

import Info from './Info';
import Form from './Form';
import css from './styles.scss';

export default class Summary extends Component {
  constructor() {
    super();

    this.state = {
      editing: false,
    };

    this.renderView = this.renderView.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.updateEditingStatus = this.updateEditingStatus.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  onSave(...args) {
    const { onSaveInfo } = this.props;
    onSaveInfo(...args).then(() => {
      this.setState({
        editing: false,
      });
    });
  }

  updateEditingStatus() {
    const { editing } = this.state;
    this.setState({
      editing: !editing,
    });
  }

  renderView() {
    const { summary } = this.props;
    return <Info summary={summary} />;
  }

  renderForm() {
    const { summary } = this.props;
    return <Form summary={summary} onCancel={this.updateEditingStatus} onSaveInfo={this.onSave} />;
  }

  render() {
    return (
      <InfoTextContainer>
        <InfoTextHeader text="Lesson Summary" />
        <ComponentSwitcher
          className={css.summary}
          toggleButtonClassName={css.toggleButton}
          primary={this.renderView()}
          alternate={this.renderForm()}
          canEdit={this.props.canEdit}
          signalOnEdit={this.updateEditingStatus}
          isAlternateVisible={this.state.editing}
          hideToggleButtonInEditMode
        />
      </InfoTextContainer>
    );
  }
}

Summary.displayName = 'LessonSummary';

Summary.propTypes = {
  summary: PropTypes.string,
  onSaveInfo: PropTypes.func.isRequired,
  canEdit: PropTypes.bool,
};
