import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Avatar from 'components/generic/Avatar';
import Dot from '@material-ui/icons/FiberManualRecord';
import { threadStatus as threadStatusEnum } from 'constants/messaging';
import ThreadStatusText from './ThreadStatusText';
import css from './styles.scss';

export default function ThreadListItem(props) {
  const {
    threadName,
    otherParticipants,
    otherTypingParticipants,
    selected,
    unreadMessagesCount,
    threadStatus: { message, status, type },
    dataTestId,
    chatName,
    source,
    icon,
    threadType,
  } = props;
  let online = false;
  const users = otherParticipants
    .filter((x) => x.user)
    .filter((x) => x.active)
    .map((x) => {
      if (x.user.online) {
        online = true;
      }
      return x.user;
    });
  if (source && threadType === 'group' && source === 'COMMUNITY') {
    return (
      <div data-test-id={dataTestId} className={classNames(selected && css.selectedItem, css.threadListItem)}>
        <Avatar avatar={icon} dataTestId="thread_community" large showStudentType />
        <div className={css.contentContainer}>
          <div className={css.threadNameContainer}>
            <div className={css.nameContainer}>{chatName || threadName}</div>
            {online === true ? (
              <i className={css.dot}>
                <Dot />
              </i>
            ) : null}
          </div>
          <div className={css.threadStatus}>
            {status !== threadStatusEnum.EMPTY && (
              <ThreadStatusText
                message={message}
                type={type}
                status={status}
                otherTypingParticipants={otherTypingParticipants}
              />
            )}
          </div>
        </div>
        <div className={css.notificationContainer}>
          {unreadMessagesCount > 0 && (
            <div className={css.notification}>{unreadMessagesCount > 50 ? '50+' : unreadMessagesCount}</div>
          )}
        </div>
      </div>
    );
  }
  if (users.length > 0) {
    const avatar = users[0].profileImageUrl;
    return (
      <div data-test-id={dataTestId} className={classNames(selected && css.selectedItem, css.threadListItem)}>
        <Avatar
          firstName={users[0].firstName}
          lastName={users[0].lastName}
          userId={users[0].userId}
          avatar={avatar}
          dataTestId={`thread_${users.length}_${users[0].firstName}`}
          secondUser={users[1]}
          otherPeople={users.length > 1 ? users.length - 1 : 0}
          large
          showStudentType
        />
        <div className={css.contentContainer}>
          <div className={css.threadNameContainer}>
            <div className={css.nameContainer}>{chatName || threadName}</div>
            {online === true ? (
              <i className={css.dot}>
                <Dot />
              </i>
            ) : null}
          </div>
          <div className={css.threadStatus}>
            {status !== threadStatusEnum.EMPTY && (
              <ThreadStatusText
                message={message}
                type={type}
                status={status}
                otherTypingParticipants={otherTypingParticipants}
              />
            )}
          </div>
        </div>
        <div className={css.notificationContainer}>
          {unreadMessagesCount > 0 && (
            <div className={css.notification}>{unreadMessagesCount > 20 ? '20+' : unreadMessagesCount}</div>
          )}
        </div>
      </div>
    );
  }
  return null;
}

ThreadListItem.propTypes = {
  threadName: PropTypes.string,
  otherParticipants: PropTypes.array,
  otherTypingParticipants: PropTypes.array,
  selected: PropTypes.bool,
  unreadMessagesCount: PropTypes.number,
  threadStatus: PropTypes.shape({
    message: PropTypes.string,
    status: PropTypes.string,
    type: PropTypes.string,
  }),
  dataTestId: PropTypes.string,
  chatName: PropTypes.string,
  source: PropTypes.string,
  icon: PropTypes.string,
  threadType: PropTypes.string,
};
