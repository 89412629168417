import { connect } from 'react-redux';
import { getLanguage, getLanguages } from 'selectors/language';
import { getAncestorsOfSubjectTree } from 'ducks/subjectCategory';
import { selectSubjectCategories } from 'selectors/subjectCategory';

import StudentCard from './StudentCard';

export default connect(
  (state, props) => ({
    primaryLanguage: getLanguage(props.user.primaryLanguage)(state),
    languages: getLanguages(props.user.languages)(state),
    subjectCategories: selectSubjectCategories(state),
  }),
  (dispatch) => ({
    getAncestorsOfSubjectTree: (ids) => dispatch(getAncestorsOfSubjectTree(ids)),
  }),
)(StudentCard);
