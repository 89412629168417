import Immutable from 'immutable';
import createReducer from 'utils/createReducer';
import { roleTypes, toasterMessages } from '@crimson-education/common-config';
import { formLoading, formSuccess, formFail } from 'ducks/meta';
import { fetchUsersByRole } from 'ducks/user';
import tutorSubjectAPI from 'graphql/api/tutorSubject';
import { tutorSubjectEntity } from 'schema';
import { ADD_ENTITIES, addEntitiesWithNormalisation, mergeArray } from 'ducks/normalizr';
import { normalize } from 'normalizr';
import { getSubjectsOfTutors } from 'selectors/tutorSubject';
import { getUserWithId } from 'selectors/user';

const SET_ENTITIES = 'tutorSubjects/SET_ENTITIES';

const initialState = new Immutable.Map();

export default createReducer(initialState, {
  [ADD_ENTITIES]: (state, action) => {
    if (action.payload.entities.tutorSubject) {
      const data = action.payload.entities.tutorSubject;

      let updatedState = state.mergeDeep(data);

      // Correct the merged tutor arrays. mergeDeep is not safe on arrays.
      Object.keys(data).forEach((subject) => {
        const newArray = data[subject].tutors;
        const originalArray = state.getIn([subject, 'tutors'], new Immutable.List());
        const mergedArray = Immutable.fromJS(mergeArray(newArray, originalArray.toJS()));
        updatedState = updatedState.setIn([subject, 'tutors'], mergedArray);
      });

      return updatedState;
    }

    return state;
  },
  [SET_ENTITIES]: (state, action) => {
    // Shallow merge, allows us to remove tutors from the list.
    return state.merge(action.payload.entities.tutorSubject);
  },
});

export function setEntities(payload) {
  return { type: SET_ENTITIES, payload };
}

export function fetchAllSubjectsOfTutor(tutorId) {
  return (dispatch) => {
    return tutorSubjectAPI.getSubjectsOfTutor(tutorId).then(({ getSubjectsOfTutor }) => {
      const dataForNormalisation = getSubjectsOfTutor.map((subject) => ({
        ...subject,
        tutors: [{ userId: tutorId }],
      }));
      dispatch(addEntitiesWithNormalisation(dataForNormalisation, [tutorSubjectEntity]));
    });
  };
}

export function createOrUpdateTutorSubject(tutorId, subjects) {
  return (dispatch, getState) => {
    dispatch(formLoading());
    return tutorSubjectAPI
      .editTutorSubject(tutorId, subjects)
      .then((res) => {
        if (res.errors || !res.editTutorSubject) {
          dispatch(formFail(res.errors[0].message));
        } else {
          const state = getState();
          const tutor = getUserWithId(tutorId)(state).get('fullName');
          dispatch(formSuccess(toasterMessages.userSubjectAdded(tutor)));
          dispatch(fetchUsersByRole(roleTypes.TUTOR));

          // Need to loop through the users's old subject list and amend it
          const oldSubjectList = getSubjectsOfTutors(state);
          let subjectsWithTutorRemoved;
          if (oldSubjectList && oldSubjectList.get(tutorId)) {
            // Ideally the selector would return a non immutable list, but it's not yet.
            const plainOldSubjectList = oldSubjectList.get(tutorId).toJS();
            // Subjects that have been removed
            subjectsWithTutorRemoved = plainOldSubjectList.filter(
              (subject) =>
                // If we can't find the old ID in the new list then it's been removed.
                subjects.find((s2) => s2.subjectId === subject.subjectId) === undefined,
            );
          } else {
            subjectsWithTutorRemoved = [];
          }

          const dataForNormalisation = [
            ...subjectsWithTutorRemoved.map((subject) => ({
              ...subject,
              tutors: subject.tutors.filter((tID) => tID !== tutorId),
            })),

            ...subjects.map((subject) => ({
              ...subject,
              tutors: [{ userId: tutorId }],
            })),
          ];

          const normalisedResponse = normalize(dataForNormalisation, [tutorSubjectEntity]);
          dispatch(setEntities(normalisedResponse));
        }
      })
      .catch(() => {
        dispatch(formFail(toasterMessages.userSubjectNotAssigned()));
        dispatch(fetchAllSubjectsOfTutor(tutorId));
      });
  };
}

export function fetchAllTutorsOfSubject(subjectId) {
  return (dispatch) =>
    tutorSubjectAPI.getTutorsOfSubject(subjectId).then(({ getTutorsOfSubject }) => {
      const dataForNormalisation = {
        subjectId,
        tutors: getTutorsOfSubject,
      };
      dispatch(addEntitiesWithNormalisation(dataForNormalisation, tutorSubjectEntity));
    });
}
