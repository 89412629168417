import React from 'react';
import PropTypes from 'prop-types';
import Accordion from 'components/molecules/Accordion';
import AccordionSummary from 'components/molecules/AccordionSummary';
import { Table, Column } from 'components/molecules/Table';
import IconTextButton from 'components/molecules/IconTextButton';
import AddNew from 'components/molecules/AddNew';
import styles from './styles.scss';

export default function GradesTable(props) {
  const {
    canEdit,
    curriculum,
    grades,
    getGradeResultById,
    onNewGrade,
    onEditGrade,
    onNewOverallGrade,
    template,
    resultTypesTemplates,
    getSubjectsByCurriculums,
    deleteGrades,
  } = props;

  // Missing template, we aren't configured to show this curriculum.
  if (!template) return null;

  // Get all subjects for this template.
  const subjects = getSubjectsByCurriculums([curriculum.id]);

  // Empty state.
  const emptyMessage = 'No grades have been entered for this student.';

  // Columns to show in the table.
  const columns = [];

  // Construct subject column.
  columns.push(<Column key="subject" name="Subject" dataKey="subject" />);

  // Construct one column per result type.
  template.baseResultTypes.forEach((resultType, index) => {
    const resultTypeTemplate = resultTypesTemplates[resultType];
    columns.push(
      <Column
        key={`${index}`}
        name={resultTypeTemplate.displayName}
        dataKey={`${index}`}
        align={resultTypeTemplate.align}
      />,
    );
  });

  // Construct date column.
  columns.push(<Column key="date" name="Date" dataKey="date" />);

  // filter grades to only subjects in this template
  const filteredGrades = grades.filter((grade) => subjects[grade.subjectId]);

  // Sort grades by date.
  const sortedGrades = filteredGrades.sort((a, b) => new Date(a.date) - new Date(b.date));
  // Construct data, our rows.
  const rows = sortedGrades.map((grade) => {
    // Default cells, subject and date are always shown.

    const row = {
      id: grade.id,
      subject: subjects[grade.subjectId] && subjects[grade.subjectId].name,
      date: template.dateFormatter(grade.date),
    };

    // Add a cell for each result associated with this grade.
    grade.results.forEach((resultId) => {
      const result = getGradeResultById(resultId);
      const resultTemplate = resultTypesTemplates[result.type];
      const templateIndex = template.resultTypes.findIndex((columnArray) => columnArray.includes(result.type));
      row[`${templateIndex}`] = resultTemplate.formatter(result.value);
    });

    return row;
  });

  // Grab overall grades to display separately
  const overallColumns = [
    <Column key="subject" name="Subject" dataKey="subject" />,
    <Column key="result" name="Result" dataKey="result" align="right" />,
  ];

  const overallGrades = grades.filter((grade) => grade.subjectId === curriculum.id);
  // If we ever decide to support multiple years then this will need to be changed. For now just grab the first grade
  // because there should only be one.
  const chosenOverallGrade = overallGrades[0];
  const chosenOverallGradeResults = chosenOverallGrade ? chosenOverallGrade.results.map(getGradeResultById) : [];

  // We show a row for each grade in the template even if we don't have a result for it yet.
  // This allows us to use the 'edit' function to add a new result.
  const overallRows = template.overallResultTypes.map((resultType) => {
    const result = chosenOverallGradeResults.find(({ type }) => type === resultType);
    const resultTemplate = resultTypesTemplates[resultType];
    return {
      subject: resultTemplate.displayName,
      result: result ? resultTemplate.formatter(result.value) : '-',
      id: result ? result.id : null,
    };
  });

  // Handle curriculum deletion.
  const onDeleteGrades = (e) => {
    e.stopPropagation();
    const ids = grades.map((grade) => grade.id);
    deleteGrades(ids, 'grade');
  };

  // Construct accordion summary for this grade table.
  // We only count the leaf node grades for the count.
  const summary = (
    <AccordionSummary
      title={curriculum.name}
      results={`${filteredGrades.length}`}
      resultsText={filteredGrades.length > 1 ? 'results' : 'result'}
      actions={
        canEdit && (
          <IconTextButton icon="Delete" onClick={onDeleteGrades}>
            Delete table
          </IconTextButton>
        )
      }
    />
  );

  // Construct accordion content for this grade table.
  const content = (
    <div>
      <Table
        isScrollable
        data={rows}
        emptyMessage={emptyMessage}
        className={styles.table}
        onRowClick={
          canEdit
            ? (grade) => {
                onEditGrade(grade.id);
              }
            : undefined
        }
      >
        {columns}
      </Table>

      {overallRows.length > 0 && (
        <Table
          hideHeader
          data={overallRows}
          emptyMessage={emptyMessage}
          className={styles.overallTable}
          onRowClick={
            canEdit
              ? () => {
                  if (chosenOverallGrade) {
                    onEditGrade(chosenOverallGrade.id);
                  } else {
                    onNewOverallGrade(curriculum.id);
                  }
                }
              : undefined
          }
        >
          {overallColumns}
        </Table>
      )}

      {canEdit && (
        <AddNew className={styles.newButton} text={`Add new ${curriculum.name} grade`} onClick={onNewGrade} />
      )}
    </div>
  );

  return <Accordion summary={summary} content={content} className={styles.gradesTable} />;
}

GradesTable.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  template: PropTypes.object,
  onNewGrade: PropTypes.func.isRequired,
  onEditGrade: PropTypes.func.isRequired,
  onNewOverallGrade: PropTypes.func.isRequired,
  deleteGrades: PropTypes.func.isRequired,
  curriculum: PropTypes.object.isRequired,
  grades: PropTypes.array.isRequired,
  getGradeResultById: PropTypes.func.isRequired,
  resultTypesTemplates: PropTypes.object.isRequired,
  getSubjectsByCurriculums: PropTypes.func.isRequired,
};
