import React, { useMemo, useState, useEffect } from 'react';
import DropdownMenu from '../../Dropdown/DropdownMenu';
import ButtonWithDropdownIndicator from '../../components/ButtonWithDropdownIndicator';
import { useAppTrackerContext, useRequesterUserId } from '../../helper';
import { Application } from '../../type';
type SelectCollegeProps = {
  colleges: string[];
  college: string | null;
  application: Application;
};

const SelectCollege = ({ colleges, college, application }: SelectCollegeProps) => {
  const { controller } = useAppTrackerContext();
  const requesterUserId = useRequesterUserId();
  const options = useMemo(
    () =>
      colleges.map((o) => ({
        label: o,
        value: o,
      })),
    [colleges],
  );
  const [selectedCollege, setSelectedCollege] = useState<string | null>(null);
  useEffect(() => {
    if (college) setSelectedCollege(college);
  }, [college]);
  const onClickItem = async (value: string) => {
    // update application
    const success = await controller.updateApplication(
      application.id,
      { college: value },
      { updatedBy: requesterUserId },
    );
  };

  if (!colleges.length) return null;
  return (
    <DropdownMenu data={options} onClickItem={onClickItem}>
      {(isOpen) => (
        <ButtonWithDropdownIndicator
          style={{ fontSize: 12, color: !!selectedCollege ? '#1d1e2b' : '#A9ACC0' }}
          isOpen={isOpen}
        >
          {selectedCollege || 'Select a college'}
        </ButtonWithDropdownIndicator>
      )}
    </DropdownMenu>
  );
};

export default SelectCollege;
