import { connect } from 'react-redux';
import { withAppContext } from 'components/enhancers/AppContext';
import { compose } from 'redux';
import componentKeys from 'constants/componentKeys';
import { SomeAuthorisationCheck } from 'components/wrappers/AuthorisationCheck/index';
import { PermissionTypes } from '@crimson-education/common-config';

import { fetchEventForSessionSummary } from 'ducks/booking';
import { fetchLessonsByEventId } from 'ducks/lesson';
import {
  fetchReasonsForCancellation,
  updateMeta,
  showPageLoader,
  updateDayCalendarViewHidden,
  formFail,
  formSuccess,
} from 'ducks/meta';

import { getSelectedBooking } from 'selectors/booking';
import { getIsHeaderHidden } from 'selectors/meta';
import { getLoginUserToJs, getBookingAsToJs } from 'selectors/user';

import { setAgendaItem } from 'ducks/agenda';
import { setNotes } from 'ducks/session';

import SessionComponent from './Session.js';

export { default as SessionConfirmationActions } from './SessionConfirmationActions';
export { default as SessionStatus } from './SessionStatus';

const mapStateToProps = (state) => ({
  loginUser: getLoginUserToJs(state),
  session: getSelectedBooking(state),
  bookAsUser: getBookingAsToJs(state),
  isHeaderHidden: getIsHeaderHidden(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchReasonsForCancellation: (userId, eventId) => dispatch(fetchReasonsForCancellation(userId, eventId)),
  setSelectedSessionId: (id) => dispatch(updateMeta(componentKeys.SESSION_SELECTED_ID, id)),
  fetchLessonsByEventId: showPageLoader((id) => dispatch(fetchLessonsByEventId(id)), dispatch),
  fetchEventForSessionSummary: showPageLoader((id) => dispatch(fetchEventForSessionSummary(id)), dispatch),
  updateDayCalendarViewHidden: (isHidden) => dispatch(updateDayCalendarViewHidden(isHidden)),
  setAgendaItem: (eventId, id, text, html, version) => dispatch(setAgendaItem(eventId, id, text, html, version)),
  setNotes: (eventId, notes) => dispatch(setNotes(eventId, notes)),
  setFailForm: (msg) => dispatch(formFail(msg)),
  setSuccessForm: (msg) => dispatch(formSuccess(msg)),
});

export default compose(
  SomeAuthorisationCheck([PermissionTypes.VIEW_CALENDAR, PermissionTypes.VIEW_SESSION_REPORT]),
  withAppContext,
  connect(mapStateToProps, mapDispatchToProps),
)(SessionComponent);
