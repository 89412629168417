import React from 'react';
import PropTypes from 'prop-types';

import IconTextButton from 'components/molecules/IconTextButton';

import { NoDocument } from 'components/atoms/graphics';
import { Body, SubHeader } from 'components/atoms/typography';
import LoadingCircle from 'components/molecules/LoadingCircle';

import Layout from './Layout';
import FolderActions from './FolderActions';
import FileList from './FileList';

import css from './styles.scss';

const { Acl, roleTypes } = require('@crimson-education/common-config');

const Workspace = (props) => {
  const {
    match: {
      params: { sessionId },
    },
    resources,
    fetchResourcesForSession,
    isHeaderHidden,
    updateHeaderHidden,
    app: { isMobile },
    session,
  } = props;
  const files = Object.values(resources);

  const [selectedFile, selectFile] = React.useState(null);
  const [filesLoad, setFilesLoad] = React.useState(false);
  const foundSelectedFile = files.find((f) => f.id === selectedFile);
  const studentInfo = session.participants.find((x) => Acl.checkRole(x.userRoles, roleTypes.STUDENT));

  if (!foundSelectedFile && files[0] && !isMobile) {
    selectFile(files[0].id);
  }

  const onSelectFile = (fileId, webLink) => {
    if (isMobile) {
      window.open(webLink, '_blank');
    }
    selectFile(fileId);
  };

  React.useEffect(() => {
    async function fetchResourcesAsync() {
      setFilesLoad(true);
      await fetchResourcesForSession(sessionId, studentInfo.userId);
      setFilesLoad(false);
    }

    fetchResourcesAsync();
  }, [fetchResourcesForSession, sessionId, studentInfo.userId]);

  React.useEffect(() => () => updateHeaderHidden(false), [updateHeaderHidden]);

  if (filesLoad) {
    return (
      <div className={css.loading}>
        <LoadingCircle style={{ height: '3rem', width: '3rem' }} color="#ed4b53" />
      </div>
    );
  }
  return (
    <div data-ga-category="GoogleDrive">
      <Layout.Container>
        <Layout.Sidebar>
          <FolderActions sessionId={sessionId} studentInfo={studentInfo} />
          <FileList
            sessionId={sessionId}
            files={files}
            selected={selectedFile}
            onSelect={onSelectFile}
            studentInfo={studentInfo}
          />
        </Layout.Sidebar>
        {!isMobile && (
          <Layout.Main>
            {foundSelectedFile ? (
              <div>
                <div className={css.documentBanner}>
                  <div className={css.start}>
                    <img alt="document icon" src={foundSelectedFile.iconLink} />
                    <div>{foundSelectedFile.name}</div>
                  </div>
                  <div className={css.end}>
                    <IconTextButton icon="Tab" onClick={() => window.open(foundSelectedFile.webViewLink, '_blank')}>
                      Open in a new tab
                    </IconTextButton>
                    {isHeaderHidden ? (
                      <IconTextButton icon="FullscreenExit" onClick={() => updateHeaderHidden(false)}>
                        Collapse
                      </IconTextButton>
                    ) : (
                      <IconTextButton icon="Fullscreen" onClick={() => updateHeaderHidden(true)}>
                        Expand
                      </IconTextButton>
                    )}
                  </div>
                </div>
                <iframe
                  title="embed"
                  /* https://stackoverflow.com/questions/23446449/google-sheet-embed-url-documentation# */
                  src={`${foundSelectedFile.webViewLink}&rm=embedded`}
                  style={{ width: '100%', height: '100%', border: 'none' }}
                />
              </div>
            ) : (
              <div className={css.emptyState}>
                <NoDocument className={css.emptyIllustration} />
                <SubHeader>No shared resources for this subject yet</SubHeader>
                <Body>
                  Your shared Google files for this subject will appear here and in all of your future sessions.
                </Body>
              </div>
            )}
          </Layout.Main>
        )}
      </Layout.Container>
    </div>
  );
};

Workspace.propTypes = {
  match: PropTypes.object,
  resources: PropTypes.object,
  fetchResourcesForSession: PropTypes.func,
  isHeaderHidden: PropTypes.bool,
  updateHeaderHidden: PropTypes.func,
  app: PropTypes.object,
  session: PropTypes.object,
};

export default React.memo(Workspace);
