import React from 'react';

export default function Contacts() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <defs>
        <path
          id="contacts"
          d="M16.364 1.636h-6.04L9.958.905A1.626 1.626 0 0 0 8.494 0H1.636C.734 0 0 .734 0 1.636v11.455c0 .902.734 1.636 1.636 1.636h1.637v-1.636h1.636v1.636h8.182v-1.636h1.636v1.636h1.637c.902 0 1.636-.734 1.636-1.636V3.273c0-.903-.734-1.637-1.636-1.637zM5.727 4.091c.927 0 1.637.71 1.637 1.636 0 .927-.71 1.637-1.637 1.637s-1.636-.71-1.636-1.637.71-1.636 1.636-1.636zM9 10.636H2.455v-.64c0-1.07 1.485-1.814 3.272-1.814C7.515 8.182 9 8.926 9 9.996v.64zm6.545 0h-4.909V9h4.91v1.636zm0-3.272h-4.909V5.727h4.91v1.637z"
        />
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(3 4)">
        <mask id="contactsB" fill="#fff">
          <use xlinkHref="#contacts" />
        </mask>
        <use fill="#232950" fillRule="nonzero" xlinkHref="#contacts" />
        <g mask="url(#contactsB)">
          <path fill="#232950" d="M-4.5-5.216h27v26h-27z" />
        </g>
      </g>
    </svg>
  );
}
