import React from 'react';
import PropTypes from 'prop-types';

import PeopleSearchContainer from './PeopleSearch';
import CalendarHeader from './CalendarHeader';
import CalendarGridContainer from './CalendarGrid';
import DayCalendarHeader from './DayCalendarHeader';
import DayCalendarGridContainer from './DayCalendarGrid';

import css from './styles.scss';

export default function CalendarView({
  availabilityBookingHandler,
  eventBookingHandler,
  openModalViaCalendar,
  showDayCalendar,
  hasSynced,
}) {
  return (
    <React.Fragment>
      <div className={css.leftNav}>
        <div className={css.peopleSearchWrapper}>
          <PeopleSearchContainer />
        </div>
      </div>
      {showDayCalendar ? (
        <div className={css.contents}>
          <DayCalendarHeader />
          <DayCalendarGridContainer
            onAvailabilityClicked={availabilityBookingHandler}
            onBookingClicked={eventBookingHandler}
            openModalViaCalendar={openModalViaCalendar}
          />
        </div>
      ) : (
        <div className={css.contents}>
          <CalendarHeader hasSynced={hasSynced} />
          <CalendarGridContainer
            onAvailabilityClicked={availabilityBookingHandler}
            onBookingClicked={eventBookingHandler}
            openModalViaCalendar={openModalViaCalendar}
          />
        </div>
      )}
    </React.Fragment>
  );
}

CalendarView.propTypes = {
  availabilityBookingHandler: PropTypes.func,
  eventBookingHandler: PropTypes.func,
  openModalViaCalendar: PropTypes.func,
  showDayCalendar: PropTypes.bool,
  hasSynced: PropTypes.bool,
};
