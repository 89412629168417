/**
 * Types of global banner messages
 * @readOnly
 * @enum {string}
 */
module.exports = {
  EXAM_IN_PROGRESS: 'examInProgress',
  FEEDBACK_REQUIRED: 'feedbackRequired',
  USER_NOT_CONNECTED: 'userNotConnected',
  SESSION_STARTING_SOON: 'sessionStartingSoon',
  SESSION_IN_PROGRESS: 'sessionInProgress',
};
