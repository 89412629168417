import React from 'react';
import PropTypes from 'prop-types';

import css from './style.scss';

function transformBoolean(val) {
  return val ? 'Yes' : 'No';
}

function transformSubjects(val) {
  const subjects = JSON.parse(val);
  return subjects.map((x) => `${x.label}; `);
}
export default function VersionThree(props) {
  const { report } = props;
  const reportCategoryList = [
    { key: 'sessionType', label: 'What was the session type?' },
    {
      key: 'subjects',
      label: 'What were the topics discussed during the Session?',
      transform: (val) => transformSubjects(val),
    },
    {
      key: 'concern',
      label: 'Do you want to raise any concern?',
      transform: (val) => transformBoolean(val),
      combine: 'elaborate',
    },
  ];
  return (
    <div className={css.report}>
      {reportCategoryList.map((category, index) => {
        if (report[category.key] !== undefined && report[category.key] !== null) {
          return (
            <div className={css.contentWrapper} key={index}>
              <h4>{category.label}</h4>
              <p className={css.preText}>
                {category.transform ? category.transform(report[category.key]) : report[category.key]}

                {category.combine && report[category.combine] && ` — ${report[category.combine]}`}
              </p>
            </div>
          );
        }
        return null;
      })}
    </div>
  );
}

VersionThree.propTypes = {
  report: PropTypes.object.isRequired,
};
