const connect = ({ url, onmessage }) => {
  const websocket = new WebSocket(url);
  websocket.onmessage = onmessage;
  websocket.onclose = (e) => e.code === 1001 && connect({ url, onmessage }); // 1001 (Going away) - Connection closed by server
  return websocket;
};

export default {
  connect,
};
