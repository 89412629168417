export const BOOLEAN_FIELD = 'BOOLEAN_FIELD';
export const COMMENT_FIELD = 'COMMENT_FIELD';
export const RATING_FIELD = 'RATING_FIELD';
export const DROPDOWN_FIELD = 'DROPDOWN_FIELD';
export const MULTI_SELECT_FIELD = 'MULTI_DROPDOWN_FIELD';

const sessionReport = [
  {
    key: 'sessionType',
    label: 'What was the session type?',
    placeholder: 'Please select the session type',
    fieldType: DROPDOWN_FIELD,
  },
  {
    key: 'subjects',
    label: 'What were the subjects discussed during the Session?',
    placeholder: 'Add subjects discussed',
    fieldType: MULTI_SELECT_FIELD,
  },
  {
    key: 'concern',
    label: 'Do you want to raise any concern?',
    buttons: ['Yes', 'No'],
    fieldType: BOOLEAN_FIELD,
  },
  {
    key: 'elaborate',
    label: 'Could you please elaborate?',
    fieldType: COMMENT_FIELD,
    depends: {
      key: 'concern',
      value: 'Yes',
    },
  },
];

export default sessionReport;
