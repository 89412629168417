import {
  createBoldPlugin,
  createItalicPlugin,
  createStrikethroughPlugin,
  createSubscriptPlugin,
  createSuperscriptPlugin,
  createUnderlinePlugin,
} from '@udecode/plate';
import { plateUI } from '../common';
import { createMyPlugins } from '../types/plateTypes';

export const basicMarksPlugins = createMyPlugins(
  [
    createBoldPlugin(),
    createItalicPlugin(),
    createStrikethroughPlugin(),
    createSubscriptPlugin(),
    createSuperscriptPlugin(),
    createUnderlinePlugin(),
  ],
  {
    components: plateUI,
  },
);
