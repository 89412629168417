import React from 'react';

function RegisterSuccess(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={254} height={224} viewBox="0 0 254 224" {...props}>
      <defs>
        <linearGradient id="prefix__a" y1={0.5} x2={1} y2={0.5} gradientUnits="objectBoundingBox">
          <stop offset={0} stopColor="#fff2e6" />
          <stop offset={1} stopColor="#ffd9c3" />
        </linearGradient>
        <linearGradient id="prefix__b" y1={1} x2={1} y2={1} gradientUnits="objectBoundingBox">
          <stop offset={0} stopColor="#1eaefb" />
          <stop offset={1} stopColor="#4169fc" />
        </linearGradient>
        <linearGradient id="prefix__c" y1={0.388} x2={0.636} y2={0.5} gradientUnits="objectBoundingBox">
          <stop offset={0} stopColor="#b2dfff" />
          <stop offset={1} stopColor="#406afb" />
        </linearGradient>
        <style>{'.prefix__a{fill:#e3e6eb}'}</style>
      </defs>
      <g>
        <rect className="prefix__a" width={191} height={19} rx={9.5} transform="translate(36)" />
        <rect className="prefix__a" width={226} height={18} rx={9} transform="translate(15 38)" />
        <rect className="prefix__a" width={225} height={17} rx={8.5} transform="translate(16 118)" />
        <rect className="prefix__a" width={254} height={17} rx={8.5} transform="translate(0 77)" />
        <rect className="prefix__a" width={182} height={19} rx={9.5} transform="translate(36 155)" />
        <ellipse cx={85.5} cy={3} rx={85.5} ry={3} transform="translate(42 218)" fill="#3d3d3d" opacity={0.4} />
        <path d="M166.355 116.643l79.591 10.648-86.884 45.181v-19.751l-115.751 5.451 17.022-52.987 98.729 22.106z" />
        <path
          d="M179.044 147.458l79.591 10.648-86.884 45.181v-19.751L56 188.987 73.022 136l98.729 22.106z"
          transform="translate(-12.689 -30.815)"
          fill="url(#prefix__a)"
        />
        <g transform="translate(35 37)">
          <rect width={94} height={71} rx={8} />
          <rect width={94} height={71} rx={8} fill="#afb5f8" />
        </g>
        <rect width={114} height={86} rx={8} transform="translate(53 53)" fill="url(#prefix__b)" />
        <circle cx={21.5} cy={21.5} r={21.5} transform="translate(129 108)" fill="url(#prefix__c)" />
        <path
          d="M106.731 112.919a3.094 3.094 0 013.094 3.094v.773a3.094 3.094 0 01-3.094 3.094H75.795a3.094 3.094 0 01-3.094-3.094v-.773a3.094 3.094 0 013.094-3.094zm16.242-20.882a3.094 3.094 0 013.094 3.094v.773a3.094 3.094 0 01-3.094 3.094H75.795a3.094 3.094 0 01-3.094-3.097v-.773a3.094 3.094 0 013.094-3.094zm11.601-19.336a3.094 3.094 0 013.094 3.094v.773a3.094 3.094 0 01-3.094 3.094H75.795a3.094 3.094 0 01-3.094-3.094v-.773a3.094 3.094 0 013.094-3.094z"
          fillRule="evenodd"
          fill="#afb5f8"
        />
        <path
          d="M139.973 128.781a79.487 79.487 0 017.343 4.466c1.8-1.725 9.642-9.628 14.443-12.581.646.741.24.1.835 1.066-2.568 2.619-6.409 6.882-13.866 19.09-8.773-8.719-2.37-2.554-10.323-10.3a10.813 10.813 0 011.568-1.741z"
          fill="#fff"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
}

export default RegisterSuccess;
