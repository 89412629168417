import { Modal, ModalFuncProps } from 'antd';
import { Close } from '@material-ui/icons';
import styled, { keyframes, css } from 'styled-components';
import React from 'react';

export const SvgCommonStyle = css`
  width: 1em;
  height: 1em;
`;

const rotate180 = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(180deg);
  }
`;

const CloseIcon = styled(Close)`
  :hover {
    fill: var(--color-indigo);
    animation: ${rotate180} 0.2s infinite linear;
  }
`;

declare type ConfigUpdate = ModalFuncProps | ((prevConfig: ModalFuncProps) => ModalFuncProps);

export type ModalReturnType = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  destroy: (...args: any[]) => void;
  update: (configUpdate: ConfigUpdate) => void;
};
export const popupModalInfo = ({
  maskClosable = true,
  icon = '',
  closable = true,
  content,
  centered = true,
  className,
  okText = '',
  closeIcon = <CloseIcon />,
  ...rest
}: ModalFuncProps): ModalReturnType => {
  return Modal.confirm({
    maskClosable,
    icon,
    closable,
    content,
    closeIcon,
    centered,
    className,
    okText,
    ...rest,
  });
};
