import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const DialogTitle = ((props) => {
  const { id, title: Title, onClose, ...other } = props;

  const title = useMemo(() => {
    if (!Title) {
      return null;
    }

    return typeof Title === 'string' ?
      <h1 style={{ textAlign: 'center' }}>{Title}</h1> :
      <Title />;
  }, [Title]);

  return (
    <MuiDialogTitle id={id} disableTypography className="ds-dialog-title horiz " {...other}>
      <div className="grow">
        { title }
      </div>
      {onClose ? (
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

DialogTitle.propTypes = {
  id: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  onClose: PropTypes.func
};

export default DialogTitle;