import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';
import { gql } from '@apollo/client';
import { createClient } from '../utils';

const { api } = getConfig();

const client = createClient(api.graphQLEndpoint);

const academicType = gql`
  fragment academicType on Academic {
    id
    userId
    grade
    rank
  }
`;

const getAcademicsByUserId = gql`
  ${academicType}
  query getAcademicsByUserId($userId: String!) {
    getAcademicsByUserId(userId: $userId) {
      ...academicType
    }
  }
`;

const createAcademic = gql`
  ${academicType}
  mutation createAcademic($academic: NewAcademic!) {
    createAcademic(academic: $academic) {
      ...academicType
    }
  }
`;

const updateAcademic = gql`
  ${academicType}
  mutation updateAcademic($academic: EditAcademic!) {
    updateAcademic(academic: $academic) {
      ...academicType
    }
  }
`;

const deleteAcademics = gql`
  mutation deleteAcademics($ids: [String]!) {
    deleteAcademics(ids: $ids)
  }
`;

export default {
  getAcademicsByUserId: async (userId) => client.query(getAcademicsByUserId, { userId }),
  createAcademic: async (academic) => client.query(createAcademic, { academic }),
  updateAcademic: async (academic) => client.query(updateAcademic, { academic }),
  deleteAcademics: async (ids) => client.query(deleteAcademics, { ids }),
};
