export function handleEnter(
  action: ((event: KeyboardEvent) => void) | ((event: KeyboardEvent) => Promise<void>) | undefined,
): (event: KeyboardEvent) => void {
  return (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      action?.(event);
    }
  };
}

export function asButton(
  action: ((event: Event) => void) | ((event: Event) => Promise<void>) | undefined,
): {
  role: 'button';
  tabIndex: 0;
  onClick: ((event: MouseEvent) => void) | undefined;
  onKeyDown: ((event: KeyboardEvent) => void) | undefined;
} {
  return {
    role: 'button',
    tabIndex: 0,
    onClick: action,
    onKeyDown: action ? handleEnter(action) : undefined,
  };
}
