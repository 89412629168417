export function ifElementsOverlap(id1: string, id2: string) {
  const el1 = document.getElementById(id1);
  const el2 = document.getElementById(id2);
  if (!el1 || !el2) return false;
  const domRect1 = el1.getBoundingClientRect();
  const domRect2 = el2.getBoundingClientRect();

  return !(
    domRect1.top > domRect2.bottom ||
    domRect1.right < domRect2.left ||
    domRect1.bottom < domRect2.top ||
    domRect1.left > domRect2.right
  );
}
export function typeWidth(containerWidth: number) {
  return 0.25 * containerWidth + 5;
}
export function hasEnoughWidthForType(containerWidth: number, groupNameWidth: number) {
  // if left width greater than 0.25*container width + 5px, show <Type/>
  return containerWidth - groupNameWidth > typeWidth(containerWidth);
}
export function hasEnoughWidthForCount(containerWidth: number, groupNameWidth: number) {
  // Count Comp width, 2 digits take around 38px, plus margin left 8px, plus some right margin = 50px
  return containerWidth - groupNameWidth - typeWidth(containerWidth) > 50;
}
export function checkIfHasEnoughWidth(
  containerId: string,
  id2: string,
  hasEnoughWidth: (containerWidth: number, elmWidth: number) => boolean,
) {
  const el1 = document.getElementById(containerId);
  const el2 = document.getElementById(id2);
  if (!el1 || !el2) return false;
  const domRect1 = el1.getBoundingClientRect();
  const domRect2 = el2.getBoundingClientRect();
  return hasEnoughWidth(domRect1.width, domRect2.width);
}
