import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './Switch.scss';
import { handleEnter } from '../../../utils/accessibility';

export default function Switch(props) {
  const { left, right, onChange, value } = props;

  return (
    <div
      role="button"
      tabIndex={0}
      className={classNames(css.track, 'switch_component')}
      onClick={() => onChange(!value)}
      onKeyDown={handleEnter(() => onChange(!value))}
    >
      <div className={classNames(css.toggle, { [css.on]: value })} />
      <div className={classNames(css.option, { [css.active]: !value })}>{left}</div>
      <div className={classNames(css.option, { [css.active]: value })}>{right}</div>
    </div>
  );
}

Switch.propTypes = {
  value: PropTypes.bool,
  left: PropTypes.node,
  right: PropTypes.node,
  onChange: PropTypes.func,
};
