import moment from 'moment';
import { createSelector } from 'reselect';
import { getTasksListUser } from 'selectors/meta';
import taskStatus from 'constants/taskStatus';
import memoize from 'lodash.memoize';

export const tasks = (state) => state.get('task');

const sortTasksByDateThenName = (tasks) => {
  return tasks.sort((a, b) => {
    const aDate = new Date(a.date);
    const bDate = new Date(b.date);
    if (aDate - bDate === 0) {
      return a.name.localeCompare(b.name);
    }
    return aDate - bDate;
  });
};

const sortTasksByDateThenCreatedAt = (tasks) => {
  return tasks.sort((a, b) => {
    if (!a.date && b.date) return 1;
    if (a.date && !b.date) return -1;
    return new Date(a.date) - new Date(b.date) || new Date(a.createdAt) - new Date(b.createdAt);
  });
};

const sortTasksByCreated = (tasks) => {
  return tasks.sort((a, b) => {
    const aDate = new Date(a.createdAt);
    const bDate = new Date(b.createdAt);
    if (aDate - bDate === 0) {
      return a.name.localeCompare(b.name);
    }
    return aDate - bDate;
  });
};

export const getTaskById = createSelector(tasks, (tasks) => {
  return memoize((taskId) => {
    const result = tasks.find((task) => task.get('id') === taskId);
    return result ? result.toJS() : {};
  });
});

export const getTasksForViewableUser = createSelector([tasks, getTasksListUser], (tasks, userId) => {
  return tasks
    .toList()
    .filter((task) => task.get('userId') === userId && task.get('status') !== taskStatus.DELETED)
    .toJS();
});

export const getIncompleteTasks = createSelector(getTasksForViewableUser, (tasks) => {
  return tasks.filter((t) => !t.isComplete);
});

export const getCompletedSortedTasks = createSelector(getTasksForViewableUser, (tasks) => {
  return sortTasksByDateThenName(tasks.filter((t) => t.isComplete));
});

export const getTasksWithNoDate = createSelector(getTasksForViewableUser, (tasks) => {
  return sortTasksByCreated(tasks.filter((t) => !t.date && !t.isComplete));
});

export const getTasksForMission = createSelector(tasks, (tasks) => {
  return memoize((missionId) => {
    return !missionId
      ? []
      : sortTasksByCreated(
          tasks
            .toList()
            .filter((t) => t.get('roadmapMissionId') === missionId && t.get('status') !== taskStatus.DELETED),
        ).toJS();
  });
});

export const getAllTasksWithoutAMission = createSelector(
  [getTasksForViewableUser, getTasksListUser],
  (tasks, userId) => {
    return sortTasksByDateThenCreatedAt(tasks.filter((t) => !t.roadmapMissionId && t.userId === userId));
  },
);

export const numberOfCompleteTasksWithoutMission = createSelector(
  getAllTasksWithoutAMission,
  (tasksWithoutMissions) => {
    return tasksWithoutMissions.filter((t) => t.isComplete).length;
  },
);

export const numberOfTasksWithoutAMission = createSelector(getAllTasksWithoutAMission, (tasksWithoutMissions) => {
  return tasksWithoutMissions.length;
});

export const getOverdueTasks = createSelector(getIncompleteTasks, (tasks) => {
  return sortTasksByDateThenName(tasks.filter((t) => moment(t.date).isBefore(moment(), 'day')));
});

export const getTasksInSevenDays = createSelector(getIncompleteTasks, (tasks) => {
  return sortTasksByDateThenName(
    tasks.filter(
      (t) =>
        moment(t.date).isBefore(moment().add(8, 'days'), 'day') &&
        moment(t.date).isAfter(moment().subtract(1, 'day'), 'day'),
    ),
  );
});

export const getTasksInThirtyDays = createSelector(getIncompleteTasks, (tasks) => {
  return sortTasksByDateThenName(
    tasks.filter(
      (t) =>
        moment(t.date).isBefore(moment().add(31, 'days'), 'day') &&
        moment(t.date).isAfter(moment().add(7, 'days'), 'day'),
    ),
  );
});

export const getTasksInLater = createSelector(getIncompleteTasks, (tasks) => {
  return sortTasksByDateThenName(tasks.filter((t) => moment(t.date).isAfter(moment().add(30, 'days'), 'day')));
});
