/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Radio } from 'react-radio-group';

import { Body } from 'components/atoms/typography';
import LoadingCircle from 'components/molecules/LoadingCircle';
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonChecked from '@material-ui/icons/RadioButtonChecked';
import colours from 'components/atoms/colours.scss';

import css from './style.scss';

export default function RadioButton(props) {
  const { isChecked, isSending, option, dataTestId, dataGaLabel } = props;
  let radioButton;

  if (isSending) {
    radioButton = (
      <div className={css.loading}>
        <LoadingCircle color={colours.crimsonRedDark} />
      </div>
    );
  } else if (isChecked) {
    radioButton = <RadioButtonChecked className={classNames([css.radio, css.checked])} />;
  } else {
    radioButton = <RadioButtonUnchecked className={classNames([css.radio, css.unchecked])} />;
  }

  return (
    <label
      role="radio"
      data-ga-label={dataGaLabel}
      aria-checked={isChecked}
      className={css.radioInputStyle}
      data-test-id={dataTestId}
    >
      {radioButton}
      <Radio className={css.reactRadio} value={option.value || option.optionLabel} />
      {option.imageUrl ? (
        <div className={css.imageOptionContainer}>
          {option.optionLabel && <span className={css.imageOptionLabel}>({option.optionLabel})</span>}
          <img className={css.optionImage} src={option.imageUrl} alt="Option" />
        </div>
      ) : (
        <Body>
          {option.optionLabel && <span>({option.optionLabel})</span>} {option.text}
        </Body>
      )}
    </label>
  );
}

RadioButton.defaultProps = {
  isChecked: false,
  isSending: false,
};

RadioButton.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  isSending: PropTypes.bool,
  option: PropTypes.object.isRequired,
  dataTestId: PropTypes.string,
  dataGaLabel: PropTypes.string,
};
