export const APPLICATION_DEADLINE_ANIMATION_DURATION = 400;

export const STUDENT_LIST_TYPE_OPTIONS = [
  {
    label: 'All Types',
    value: '*',
  },
  {
    label: 'US',
    value: 'US',
  },
  {
    label: 'UK',
    value: 'UK',
  },
  {
    label: 'EU',
    value: 'EU',
  },
];
export const STUDENT_LIST_TYPE_LABEL_MAP = {
  '*': 'All Types',
  US: 'US',
  UK: 'UK',
  EU: 'EU',
};

export const STUDENT_LIST_GROUP_OPTIONS_MAP = {
  schoolSelectionData: 'School Selection Status',
  serviceStatus: 'Service Completion',
};

export const STUDENT_LIST_GROUP_OPTIONS = Object.entries(STUDENT_LIST_GROUP_OPTIONS_MAP).map(([value, label]) => ({
  value,
  label,
}));

export const STUDENT_LIST_SERVICE_OPTIONS = [
  {
    label: 'In Progress',
    value: 'In Progress',
    available: ['US', 'UK', 'EU', 'Other'],
  },
  {
    label: 'All School Submitted',
    value: 'All School Submitted',
    available: ['US', 'UK', 'EU', 'Other'],
  },
  {
    label: 'Pre-Interview Meeting Completed',
    value: 'Pre-Interview Meeting Completed',
    available: ['UK', 'EU', 'Other'],
  },
  {
    label: 'Post-Offers Meeting Completed',
    value: 'Post-Offers Meeting Completed',
    available: ['US', 'EU', 'Other'],
  },
  {
    label: 'Post-All-UCAS-Offers Meeting Completed',
    value: 'Post-All-UCAS-Offers Meeting Completed',
    available: ['UK'],
  },
  {
    label: 'Post-All-Offers Meeting Completed',
    value: 'Post-All-Offers Meeting Completed',
    available: ['UK'],
  },
  {
    label: 'Service Completed',
    value: 'Service Completed',
    available: ['US', 'UK', 'EU', 'Other'],
  },
];

export const SERVICE_COLOR_MAP = {
  'In Progress': '#A9ACC0', // background: color+26 15%
  'All School Submitted': '#464AC9',
  'Pre-Interview Meeting Completed': '#3B86FE',
  Completed: '#12C39A',
  'Post-Offers Meeting Completed': '#FF764C',
  'Service Completed': '#FDAA02',
};
