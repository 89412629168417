/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/molecules/Modal';
import InputField from 'components/generic/Forms/InputField';
import classNames from 'classnames';
import css from './styles.scss';
import { handleEnter } from '../../../../utils/accessibility';

export default function AppleCalendarSyncModal(props) {
  const { handleSubmitAppleCalInfo, onCancel, openAppleInstructionModal, isOpen } = props;
  const [appleId, setAppleId] = useState('');
  const [appSpecificPassword, setAppSpecificPassword] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const submitAppleCalInfo = () => {
    const validationErrors = {};
    if (appleId.trim() === '') {
      validationErrors.appleId = true;
    }
    if (appSpecificPassword.trim() === '') {
      validationErrors.appSpecificPassword = true;
    }
    if (Object.keys(validationErrors).length) {
      setValidationErrors(validationErrors);
      return;
    }
    const appleCalInfo = {
      appleId,
      appSpecificPassword,
    };
    onCancel();
    handleSubmitAppleCalInfo(appleCalInfo);
  };
  const handleAppleId = (val) => {
    setAppleId(val);
  };
  const handleSpecificPassword = (val) => {
    setAppSpecificPassword(val);
  };
  const gotoAppleInstruction = () => {
    openAppleInstructionModal();
  };
  // console.log(`validationErrors: ${JSON.stringify(validationErrors)}`);
  return (
    <Modal
      isOpen={isOpen}
      title="Sync with iCalendar"
      onClose={onCancel}
      submitText="SYNC NOW"
      onSubmit={submitAppleCalInfo}
    >
      <div className={css.front}>
        <div className={css.modalBody}>
          <div className={css.bookingWithWrapper}>
            <div className={css.appCalInstruction}>
              Apple requires an app-specific password for your iCloud account in order to use Crimson calendar.
            </div>
            <InputField
              id="appleId"
              dataTestId="appleCalId"
              value={appleId}
              placeholder="Your Apple ID"
              className={classNames('formItemLessMargin', css.formGroup)}
              onValueChange={handleAppleId}
              maxLength="255"
              error={validationErrors.appleId}
              validationMessage="Please enter your Apple ID"
            />
            {/* {validationErrors.appleId && <p className={css.errorMessage}>Please enter your Apple ID.</p>} */}
            <InputField
              id="appSpecificPassword"
              dataTestId="appSpecificPassword"
              type="password"
              value={appSpecificPassword}
              placeholder="App-specific password"
              className={classNames('formItemLessMargin', css.formGroup)}
              onValueChange={handleSpecificPassword}
              maxLength="255"
              error={validationErrors.appSpecificPassword}
              validationMessage="Please enter app-specific password"
            />
            {/* {validationErrors.appSpecificPassword && <p className={css.errorMessage}>Please enter specific password.</p>} */}
            <div>Do not enter your Apple ID password here.</div>
            <a
              role="button"
              tabIndex={0}
              className={css.applePwdinstruction}
              onClick={gotoAppleInstruction}
              onKeyDown={handleEnter(gotoAppleInstruction)}
            >
              How to get app-specific password?
            </a>
          </div>
        </div>
      </div>
    </Modal>
  );
}

AppleCalendarSyncModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  handleSubmitAppleCalInfo: PropTypes.func.isRequired,
  openAppleInstructionModal: PropTypes.func.isRequired,
};
