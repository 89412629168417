import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import fontFamily from './fontFamily.scss';
import font from './font.scss';
import fontColour from './fontColour.scss';

export default function ModalHeader(props) {
  return (
    <div
      className={classNames(fontFamily.bold, font.modalHeading, fontColour.primary, props.className)}
      style={props.style}
    >
      {props.children}
    </div>
  );
}

ModalHeader.defaultProps = {
  className: '',
  style: {},
};

ModalHeader.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
};
