import moment from 'moment';
import { GradeResultType } from '@crimson-education/common-config';
import GradeDateTypes from 'constants/gradeDateTypes';

// Fields:
// resultTypes          Equivalent types for display in a table.
// baseResultTypes      Result types used for new grades.
// overallResultTypes   Results for the curriculum as a whole.
// sessionMonths        Months to display for sessions (1-12).
// dateType             DATE, SESSION or YEAR. Sessions are like "November 2018".
// dateFormatter        How to render the date in the UI.

// Maps the subject ids for curriculums available on the Grades page to the
// results their grades will display, plus their overall results.

export default {
  // IB
  '0fb06771-d31f-4e43-88e4-034328fbeefa': {
    resultTypes: [
      [
        GradeResultType.IB_PREDICTED_GRADE,
        GradeResultType.IB_TOK_PREDICTED_GRADE,
        GradeResultType.IB_CAS_PREDICTED_GRADE,
        GradeResultType.IB_EE_PREDICTED_GRADE,
      ],
      [
        GradeResultType.IB_ACHIEVED_GRADE,
        GradeResultType.IB_TOK_ACHIEVED_GRADE,
        GradeResultType.IB_CAS_ACHIEVED_GRADE,
        GradeResultType.IB_EE_ACHIEVED_GRADE,
      ],
    ],
    baseResultTypes: [GradeResultType.IB_PREDICTED_GRADE, GradeResultType.IB_ACHIEVED_GRADE],
    overallResultTypes: [GradeResultType.IB_EE_TOK, GradeResultType.IB_TOTAL_POINTS, GradeResultType.IB_RESULTS],
    sessionMonths: [5, 11],
    dateType: GradeDateTypes.SESSION,
    dateFormatter: (date) => moment(date).format('MMM, YYYY'),
  },

  // IB TOK
  'd7f3aa84-4c40-4947-bf27-4434283812a4': {
    resultTypes: [[GradeResultType.IB_TOK_PREDICTED_GRADE], [GradeResultType.IB_TOK_ACHIEVED_GRADE]],
    baseResultTypes: [GradeResultType.IB_TOK_PREDICTED_GRADE, GradeResultType.IB_TOK_ACHIEVED_GRADE],
    overallResultTypes: [],
    sessionMonths: [5, 11],
    dateType: GradeDateTypes.SESSION,
    dateFormatter: (date) => moment(date).format('MMM, YYYY'),
  },

  // IB CAS
  '6bc9f54c-2654-4000-a607-74b59e0a3054': {
    resultTypes: [[GradeResultType.IB_CAS_PREDICTED_GRADE], [GradeResultType.IB_CAS_ACHIEVED_GRADE]],
    baseResultTypes: [GradeResultType.IB_CAS_PREDICTED_GRADE, GradeResultType.IB_CAS_ACHIEVED_GRADE],
    overallResultTypes: [],
    sessionMonths: [5, 11],
    dateType: GradeDateTypes.SESSION,
    dateFormatter: (date) => moment(date).format('MMM, YYYY'),
  },

  // IB EE
  '3b63c0ea-2910-4c5b-a147-b9851165e1d8': {
    resultTypes: [[GradeResultType.IB_EE_PREDICTED_GRADE], [GradeResultType.IB_EE_ACHIEVED_GRADE]],
    baseResultTypes: [GradeResultType.IB_EE_PREDICTED_GRADE, GradeResultType.IB_EE_ACHIEVED_GRADE],
    overallResultTypes: [],
    sessionMonths: [5, 11],
    dateType: GradeDateTypes.SESSION,
    dateFormatter: (date) => moment(date).format('MMM, YYYY'),
  },

  // CIE
  '616bdaac-0ffa-4b6e-b11a-f0ffc72d5a11': {
    resultTypes: [
      [GradeResultType.CIE_SCHOOL_EXAM_PERCENTAGE],
      [GradeResultType.CIE_SCHOOL_EXAM_GRADE],
      [GradeResultType.CIE_FINAL_EXAM_PERCENTAGE],
      [GradeResultType.CIE_FINAL_EXAM_GRADE],
      [GradeResultType.CIE_AWARDS],
    ],
    baseResultTypes: [
      GradeResultType.CIE_SCHOOL_EXAM_PERCENTAGE,
      GradeResultType.CIE_SCHOOL_EXAM_GRADE,
      GradeResultType.CIE_FINAL_EXAM_PERCENTAGE,
      GradeResultType.CIE_FINAL_EXAM_GRADE,
      GradeResultType.CIE_AWARDS,
    ],
    overallResultTypes: [],
    dateType: GradeDateTypes.SESSION,
    sessionMonths: [6, 11],
    dateFormatter: (date) => moment(date).format('MMM, YYYY'),
  },

  // NCEA
  '73436cfe-da19-4202-9d00-e40a4d691fb9': {
    resultTypes: [
      [GradeResultType.NCEA_NOT_ACHIEVED_CREDITS],
      [GradeResultType.NCEA_ACHIEVED_CREDITS],
      [GradeResultType.NCEA_MERIT_CREDITS],
      [GradeResultType.NCEA_EXCELLENCE_CREDITS],
      [GradeResultType.NCEA_NOT_ACHIEVED_CREDITS_EXTERNAL],
      [GradeResultType.NCEA_ACHIEVED_CREDITS_EXTERNAL],
      [GradeResultType.NCEA_MERIT_CREDITS_EXTERNAL],
      [GradeResultType.NCEA_EXCELLENCE_CREDITS_EXTERNAL],
      [GradeResultType.NCEA_SUBJECT_ENDORSEMENT],
    ],
    baseResultTypes: [
      GradeResultType.NCEA_NOT_ACHIEVED_CREDITS,
      GradeResultType.NCEA_ACHIEVED_CREDITS,
      GradeResultType.NCEA_MERIT_CREDITS,
      GradeResultType.NCEA_EXCELLENCE_CREDITS,
      GradeResultType.NCEA_NOT_ACHIEVED_CREDITS_EXTERNAL,
      GradeResultType.NCEA_ACHIEVED_CREDITS_EXTERNAL,
      GradeResultType.NCEA_MERIT_CREDITS_EXTERNAL,
      GradeResultType.NCEA_EXCELLENCE_CREDITS_EXTERNAL,
      GradeResultType.NCEA_SUBJECT_ENDORSEMENT,
    ],
    overallResultTypes: [
      GradeResultType.NCEA_LEVEL_1_ENDORSEMENT,
      GradeResultType.NCEA_LEVEL_2_ENDORSEMENT,
      GradeResultType.NCEA_LEVEL_3_ENDORSEMENT,
      GradeResultType.NCEA_TOTAL_CREDITS,
      GradeResultType.NCEA_TOTAL_CREDITS_EXTERNAL,
    ],
    dateType: GradeDateTypes.YEAR,
    dateFormatter: (date) => moment(date).format('YYYY'),
  },

  // HSC
  '426c0962-ffb4-4a26-814c-e56015da0b62': {
    resultTypes: [
      [GradeResultType.HSC_EXAMINATION_MARK],
      [GradeResultType.HSC_ASSESSMENT_MARK],
      [GradeResultType.HSC_MARK],
      [GradeResultType.HSC_PERFORMANCE_BAND],
    ],
    baseResultTypes: [
      GradeResultType.HSC_EXAMINATION_MARK,
      GradeResultType.HSC_ASSESSMENT_MARK,
      GradeResultType.HSC_MARK,
      GradeResultType.HSC_PERFORMANCE_BAND,
    ],
    overallResultTypes: [GradeResultType.HSC_ATAR],
    dateType: GradeDateTypes.YEAR,
    dateFormatter: (date) => moment(date).format('YYYY'),
  },

  // VCE
  '223f4dd6-94da-4d01-97a9-ef1dfe90fb48': {
    resultTypes: [
      [GradeResultType.VCE_GA1],
      [GradeResultType.VCE_GA2],
      [GradeResultType.VCE_GA3],
      [GradeResultType.VCE_STUDY_SCORE],
      [GradeResultType.VCE_SCALED_SCORE],
    ],
    baseResultTypes: [
      GradeResultType.VCE_GA1,
      GradeResultType.VCE_GA2,
      GradeResultType.VCE_GA3,
      GradeResultType.VCE_STUDY_SCORE,
      GradeResultType.VCE_SCALED_SCORE,
    ],
    overallResultTypes: [GradeResultType.VCE_AGGREGATE, GradeResultType.VCE_ATAR],
    dateType: GradeDateTypes.YEAR,
    dateFormatter: (date) => moment(date).format('YYYY'),
  },

  // NZQA Scholarship
  '0b54ad61-65be-48bd-8c24-b4cdd5bc4128': {
    resultTypes: [[GradeResultType.NZQA_AWARDS], [GradeResultType.NZQA_SCORE]],
    baseResultTypes: [GradeResultType.NZQA_AWARDS, GradeResultType.NZQA_SCORE],
    overallResultTypes: [GradeResultType.NZQA_OVERALL_AWARDS],
    dateType: GradeDateTypes.YEAR,
    dateFormatter: (date) => moment(date).format('YYYY'),
  },

  // SAT Reasoning
  '8bdca88d-70bc-4e14-bb45-b23e756a8229': {
    resultTypes: [
      [GradeResultType.SAT_REASONING_READING_WRITING],
      [GradeResultType.SAT_REASONING_MATH],
      [GradeResultType.SAT_REASONING_ESSAY],
      [GradeResultType.SAT_REASONING_OVERALL_SCORE],
    ],
    baseResultTypes: [
      GradeResultType.SAT_REASONING_READING_WRITING,
      GradeResultType.SAT_REASONING_MATH,
      GradeResultType.SAT_REASONING_ESSAY,
      GradeResultType.SAT_REASONING_OVERALL_SCORE,
    ],
    overallResultTypes: [],
    dateType: GradeDateTypes.DATE,
    dateFormatter: (date) => moment(date).format('D MMM, YYYY'),
  },

  // SAT Subjects
  '47231aab-a82e-42e0-9dae-9de26c238c84': {
    resultTypes: [[GradeResultType.SAT_SUBJECT_SCORE]],
    baseResultTypes: [GradeResultType.SAT_SUBJECT_SCORE],
    overallResultTypes: [],
    dateType: GradeDateTypes.DATE,
    dateFormatter: (date) => moment(date).format('D MMM, YYYY'),
  },

  // ACT
  '9b5be1f8-afd3-4260-af6c-a181dc875236': {
    resultTypes: [
      [GradeResultType.ACT_MATH],
      [GradeResultType.ACT_SCIENCE],
      [GradeResultType.ACT_READING],
      [GradeResultType.ACT_ENGLISH],
      [GradeResultType.ACT_WRITING],
      [GradeResultType.ACT_OVERALL_SCORE],
    ],
    baseResultTypes: [
      GradeResultType.ACT_MATH,
      GradeResultType.ACT_SCIENCE,
      GradeResultType.ACT_READING,
      GradeResultType.ACT_ENGLISH,
      GradeResultType.ACT_WRITING,
      GradeResultType.ACT_OVERALL_SCORE,
    ],
    overallResultTypes: [],
    dateType: GradeDateTypes.DATE,
    dateFormatter: (date) => moment(date).format('D MMM, YYYY'),
  },

  // AP
  'f7308d83-d1b6-4d3e-8caf-06a8225556cc': {
    resultTypes: [[GradeResultType.AP_SCORE]],
    baseResultTypes: [GradeResultType.AP_SCORE],
    overallResultTypes: [],
    dateType: GradeDateTypes.DATE,
    dateFormatter: (date) => moment(date).format('D MMM, YYYY'),
  },

  // IELTS
  '4f71c288-ec6d-4ed5-8b88-24d90b147e33': {
    resultTypes: [
      [GradeResultType.IELTS_TEST_TYPE],
      [GradeResultType.IELTS_LISTENING],
      [GradeResultType.IELTS_READING],
      [GradeResultType.IELTS_WRITING],
      [GradeResultType.IELTS_SPEAKING],
      [GradeResultType.IELTS_OVERALL],
    ],
    baseResultTypes: [
      GradeResultType.IELTS_TEST_TYPE,
      GradeResultType.IELTS_LISTENING,
      GradeResultType.IELTS_READING,
      GradeResultType.IELTS_WRITING,
      GradeResultType.IELTS_SPEAKING,
      GradeResultType.IELTS_OVERALL,
    ],
    overallResultTypes: [],
    dateType: GradeDateTypes.DATE,
    dateFormatter: (date) => moment(date).format('D MMM, YYYY'),
  },

  // TOEFL
  'faffbd62-e5f5-455f-b59b-3d82bef04546': {
    resultTypes: [
      [GradeResultType.TOEFL_LISTENING],
      [GradeResultType.TOEFL_READING],
      [GradeResultType.TOEFL_WRITING],
      [GradeResultType.TOEFL_SPEAKING],
      [GradeResultType.TOEFL_OVERALL],
    ],
    baseResultTypes: [
      GradeResultType.TOEFL_LISTENING,
      GradeResultType.TOEFL_READING,
      GradeResultType.TOEFL_WRITING,
      GradeResultType.TOEFL_SPEAKING,
      GradeResultType.TOEFL_OVERALL,
    ],
    overallResultTypes: [],
    dateType: GradeDateTypes.DATE,
    dateFormatter: (date) => moment(date).format('D MMM, YYYY'),
  },

  // UCAT
  '9176a3dd-22c6-4fd0-9f29-d0fd954a2aff': {
    resultTypes: [
      [GradeResultType.UCAT_VR_SCORE],
      [GradeResultType.UCAT_DM_SCORE],
      [GradeResultType.UCAT_QR_SCORE],
      [GradeResultType.UCAT_AR_SCORE],
      [GradeResultType.UCAT_TOTAL_COGNITIVE_SUBTEST_SCORE],
      [GradeResultType.UCAT_SJT_SCORE],
      [GradeResultType.UCAT_PERCENTILE],
    ],
    baseResultTypes: [
      GradeResultType.UCAT_VR_SCORE,
      GradeResultType.UCAT_DM_SCORE,
      GradeResultType.UCAT_QR_SCORE,
      GradeResultType.UCAT_AR_SCORE,
      GradeResultType.UCAT_TOTAL_COGNITIVE_SUBTEST_SCORE,
      GradeResultType.UCAT_SJT_SCORE,
      GradeResultType.UCAT_PERCENTILE,
    ],
    overallResultTypes: [],
    dateType: GradeDateTypes.YEAR,
    dateFormatter: (date) => moment(date).format('YYYY'),
  },

  // GAMSAT
  'd74379ae-1640-4f3a-ab62-2c535f768378': {
    resultTypes: [
      [GradeResultType.GAMSAT_SECTION_I_SCORE],
      [GradeResultType.GAMSAT_SECTION_II_SCORE],
      [GradeResultType.GAMSAT_SECTION_III_SCORE],
      [GradeResultType.GAMSAT_OVERALL_SCORE],
      [GradeResultType.GAMSAT_PERCENTILE],
    ],
    baseResultTypes: [
      GradeResultType.GAMSAT_SECTION_I_SCORE,
      GradeResultType.GAMSAT_SECTION_II_SCORE,
      GradeResultType.GAMSAT_SECTION_III_SCORE,
      GradeResultType.GAMSAT_OVERALL_SCORE,
      GradeResultType.GAMSAT_PERCENTILE,
    ],
    overallResultTypes: [],
    dateType: GradeDateTypes.YEAR,
    dateFormatter: (date) => moment(date).format('YYYY'),
  },

  // QCE
  '34ce5377-87b4-4ddf-9550-ca2b12ab89fb': {
    resultTypes: [
      [GradeResultType.QCE_IA_1_NUMERICAL_SCORE],
      [GradeResultType.QCE_IA_1_GRADE],
      [GradeResultType.QCE_IA_2_NUMERICAL_SCORE],
      [GradeResultType.QCE_IA_2_GRADE],
      [GradeResultType.QCE_IA_3_NUMERICAL_SCORE],
      [GradeResultType.QCE_IA_3_GRADE],
      [GradeResultType.QCE_EA_1_NUMERICAL_SCORE],
      [GradeResultType.QCE_EA_1_GRADE],
      [GradeResultType.QCE_OVERALL_NUMERICAL_SCORE],
      [GradeResultType.QCE_OVERALL_GRADE],
    ],
    baseResultTypes: [
      GradeResultType.QCE_IA_1_NUMERICAL_SCORE,
      GradeResultType.QCE_IA_1_GRADE,
      GradeResultType.QCE_IA_2_NUMERICAL_SCORE,
      GradeResultType.QCE_IA_2_GRADE,
      GradeResultType.QCE_IA_3_NUMERICAL_SCORE,
      GradeResultType.QCE_IA_3_GRADE,
      GradeResultType.QCE_EA_1_NUMERICAL_SCORE,
      GradeResultType.QCE_EA_1_GRADE,
      GradeResultType.QCE_OVERALL_NUMERICAL_SCORE,
      GradeResultType.QCE_OVERALL_GRADE,
    ],
    overallResultTypes: [],
    dateType: GradeDateTypes.YEAR,
    dateFormatter: (date) => moment(date).format('YYYY'),
  },

  // WACE
  '2d7d1c56-4dc4-4b05-80ab-ab11004ba31b': {
    resultTypes: [
      [GradeResultType.WACE_SCHOOL_MARK_NUMERICAL],
      [GradeResultType.WACE_SCHOOL_MARK_GRADE],
      [GradeResultType.WACE_EXAM_MARK_NUMERICAL],
      [GradeResultType.WACE_EXAM_GRADE],
      [GradeResultType.WACE_COMBINED_SCORE],
    ],
    baseResultTypes: [
      GradeResultType.WACE_SCHOOL_MARK_NUMERICAL,
      GradeResultType.WACE_SCHOOL_MARK_GRADE,
      GradeResultType.WACE_EXAM_MARK_NUMERICAL,
      GradeResultType.WACE_EXAM_GRADE,
      GradeResultType.WACE_COMBINED_SCORE,
    ],
    overallResultTypes: [],
    dateType: GradeDateTypes.YEAR,
    dateFormatter: (date) => moment(date).format('YYYY'),
  },

  // SACE
  'f81ea48d-7602-4695-92f8-e0f4d7dc5dab': {
    resultTypes: [[GradeResultType.SACE_IA_GRADE], [GradeResultType.SACE_EA_GRADE], [GradeResultType.SACE_FINAL_GRADE]],
    baseResultTypes: [GradeResultType.SACE_IA_GRADE, GradeResultType.SACE_EA_GRADE, GradeResultType.SACE_FINAL_GRADE],
    overallResultTypes: [],
    dateType: GradeDateTypes.YEAR,
    dateFormatter: (date) => moment(date).format('YYYY'),
  },

  // CELS191
  '03189d1b-7d04-4102-9f4b-577870fb001c': {
    resultTypes: [[GradeResultType.CELS191_PROGRESS_TEST_MARK], [GradeResultType.CELS191_FINAL_MARK]],
    baseResultTypes: [GradeResultType.CELS191_PROGRESS_TEST_MARK, GradeResultType.CELS191_FINAL_MARK],
    overallResultTypes: [],
    dateType: GradeDateTypes.YEAR,
    dateFormatter: (date) => moment(date).format('YYYY'),
  },

  // CHEM191
  '9f57ed0e-adf5-45b6-96a9-a05c0a4ddd23': {
    resultTypes: [[GradeResultType.CHEM191_PROGRESS_TEST_MARK], [GradeResultType.CHEM191_FINAL_MARK]],
    baseResultTypes: [GradeResultType.CHEM191_PROGRESS_TEST_MARK, GradeResultType.CHEM191_FINAL_MARK],
    overallResultTypes: [],
    dateType: GradeDateTypes.YEAR,
    dateFormatter: (date) => moment(date).format('YYYY'),
  },

  // PHSI191
  '31b0068b-818f-405f-b1d6-06542995f4a6': {
    resultTypes: [[GradeResultType.PHSI191_PROGRESS_TEST_MARK], [GradeResultType.PHSI191_FINAL_MARK]],
    baseResultTypes: [GradeResultType.PHSI191_PROGRESS_TEST_MARK, GradeResultType.PHSI191_FINAL_MARK],
    overallResultTypes: [],
    dateType: GradeDateTypes.YEAR,
    dateFormatter: (date) => moment(date).format('YYYY'),
  },

  // BIOC192
  '4f2023c8-0aec-4a53-a38e-8e99d2731837': {
    resultTypes: [[GradeResultType.BIOC192_PROGRESS_TEST_MARK], [GradeResultType.BIOC192_FINAL_MARK]],
    baseResultTypes: [GradeResultType.BIOC192_PROGRESS_TEST_MARK, GradeResultType.BIOC192_FINAL_MARK],
    overallResultTypes: [],
    dateType: GradeDateTypes.YEAR,
    dateFormatter: (date) => moment(date).format('YYYY'),
  },

  // HUBS191
  'cfa041b3-3bd4-48bf-966f-1bc7376bf2f2': {
    resultTypes: [
      [GradeResultType.HUBS191_PROGRESS_TEST_1_MARK],
      [GradeResultType.HUBS191_PROGRESS_TEST_2_MARK],
      [GradeResultType.HUBS191_FINAL_MARK],
    ],
    baseResultTypes: [
      GradeResultType.HUBS191_PROGRESS_TEST_1_MARK,
      GradeResultType.HUBS191_PROGRESS_TEST_2_MARK,
      GradeResultType.HUBS191_FINAL_MARK,
    ],
    overallResultTypes: [],
    dateType: GradeDateTypes.YEAR,
    dateFormatter: (date) => moment(date).format('YYYY'),
  },

  // HUBS192
  '544c0396-a9c5-4050-839c-e25a324b442e': {
    resultTypes: [
      [GradeResultType.HUBS192_PROGRESS_TEST_1_MARK],
      [GradeResultType.HUBS192_PROGRESS_TEST_2_MARK],
      [GradeResultType.HUBS192_FINAL_MARK],
    ],
    baseResultTypes: [
      GradeResultType.HUBS192_PROGRESS_TEST_1_MARK,
      GradeResultType.HUBS192_PROGRESS_TEST_2_MARK,
      GradeResultType.HUBS192_FINAL_MARK,
    ],
    overallResultTypes: [],
    dateType: GradeDateTypes.YEAR,
    dateFormatter: (date) => moment(date).format('YYYY'),
  },

  // POPH192
  'a195140a-db4f-4a18-975c-bb62da47c0c2': {
    resultTypes: [
      [GradeResultType.POPH192_PROGRESS_TEST_1_MARK],
      [GradeResultType.POPH192_PROGRESS_TEST_2_MARK],
      [GradeResultType.POPH192_FINAL_MARK],
    ],
    baseResultTypes: [
      GradeResultType.POPH192_PROGRESS_TEST_1_MARK,
      GradeResultType.POPH192_PROGRESS_TEST_2_MARK,
      GradeResultType.POPH192_FINAL_MARK,
    ],
    overallResultTypes: [],
    dateType: GradeDateTypes.YEAR,
    dateFormatter: (date) => moment(date).format('YYYY'),
  },

  // Optional 8th Paper
  '3c5a1bf2-fc1b-47e5-8555-a272ecdf1052': {
    resultTypes: [[GradeResultType.OPTIONAL_8TH_PAPER_FINAL_MARK]],
    baseResultTypes: [GradeResultType.OPTIONAL_8TH_PAPER_FINAL_MARK],
    overallResultTypes: [],
    dateType: GradeDateTypes.YEAR,
    dateFormatter: (date) => moment(date).format('YYYY'),
  },

  // ISAT
  'a4f7db96-a15b-46c7-a80f-e0fdd7ad5343': {
    resultTypes: [
      [GradeResultType.ISAT_CR_SCORE],
      [GradeResultType.ISAT_QR_SCORE],
      [GradeResultType.ISAT_TOTAL_SCORE],
      [GradeResultType.ISAT_PERCENTILE],
    ],
    baseResultTypes: [
      GradeResultType.ISAT_CR_SCORE,
      GradeResultType.ISAT_QR_SCORE,
      GradeResultType.ISAT_TOTAL_SCORE,
      GradeResultType.ISAT_PERCENTILE,
    ],
    overallResultTypes: [],
    dateType: GradeDateTypes.YEAR,
    dateFormatter: (date) => moment(date).format('YYYY'),
  },

  // BMAT
  'e73291b9-5ce7-4665-a595-07baa3d4099a': {
    resultTypes: [[GradeResultType.BMAT_SECTION_1], [GradeResultType.BMAT_SECTION_2], [GradeResultType.BMAT_SECTION_3]],
    baseResultTypes: [GradeResultType.BMAT_SECTION_1, GradeResultType.BMAT_SECTION_2, GradeResultType.BMAT_SECTION_3],
    overallResultTypes: [],
    dateType: GradeDateTypes.YEAR,
    dateFormatter: (date) => moment(date).format('YYYY'),
  },

  // BIOSCI107
  'f12695eb-4b5b-419a-a518-e75e6e4e8507': {
    resultTypes: [[GradeResultType.BIOSCI107_TEST_GRADE], [GradeResultType.BIOSCI107_FINAL_GRADE]],
    baseResultTypes: [GradeResultType.BIOSCI107_TEST_GRADE, GradeResultType.BIOSCI107_FINAL_GRADE],
    overallResultTypes: [],
    dateType: GradeDateTypes.YEAR,
    dateFormatter: (date) => moment(date).format('YYYY'),
  },

  // CHEM110
  'ad11ad29-b0eb-4cd0-8043-dc48a1f0b010': {
    resultTypes: [[GradeResultType.CHEM110_TEST_GRADE], [GradeResultType.CHEM110_FINAL_GRADE]],
    baseResultTypes: [GradeResultType.CHEM110_TEST_GRADE, GradeResultType.CHEM110_FINAL_GRADE],
    overallResultTypes: [],
    dateType: GradeDateTypes.YEAR,
    dateFormatter: (date) => moment(date).format('YYYY'),
  },

  // POPLHLTH111
  'c74e2c3b-165c-4110-8d8b-05ee01d8953b': {
    resultTypes: [[GradeResultType.POPLHLTH111_TEST_GRADE], [GradeResultType.POPLHLTH111_FINAL_GRADE]],
    baseResultTypes: [GradeResultType.POPLHLTH111_TEST_GRADE, GradeResultType.POPLHLTH111_FINAL_GRADE],
    overallResultTypes: [],
    dateType: GradeDateTypes.YEAR,
    dateFormatter: (date) => moment(date).format('YYYY'),
  },

  // MEDSCI142
  '684f4296-9ce7-4aea-ba11-8ee1c9653286': {
    resultTypes: [
      [GradeResultType.MEDSCI142_TEST_1_GRADE],
      [GradeResultType.MEDSCI142_TEST_2_GRADE],
      [GradeResultType.MEDSCI142_FINAL_GRADE],
    ],
    baseResultTypes: [
      GradeResultType.MEDSCI142_TEST_1_GRADE,
      GradeResultType.MEDSCI142_TEST_2_GRADE,
      GradeResultType.MEDSCI142_FINAL_GRADE,
    ],
    overallResultTypes: [],
    dateType: GradeDateTypes.YEAR,
    dateFormatter: (date) => moment(date).format('YYYY'),
  },

  // ATAR
  'd720ba44-734d-4ed4-a17d-b3b08a5e62b2': {
    resultTypes: [[GradeResultType.ATAR]],
    baseResultTypes: [GradeResultType.ATAR],
    overallResultTypes: [],
    dateType: GradeDateTypes.YEAR,
    dateFormatter: (date) => moment(date).format('YYYY'),
  },

  // Auckland Core CPA Paper Overall GPA
  'fbf58faa-07ed-48fe-a843-8eb0e80e2951': {
    resultTypes: [[GradeResultType.AKL_CCP_OVERALL_GPA]],
    baseResultTypes: [GradeResultType.AKL_CCP_OVERALL_GPA],
    overallResultTypes: [],
    dateType: GradeDateTypes.YEAR,
    dateFormatter: (date) => moment(date).format('YYYY'),
  },

  // Otago Paper Average (Best 7)
  'eb613f2f-9450-4bc4-a68f-f344256ee1e4': {
    resultTypes: [[GradeResultType.OTAGO_P_AVG_BEST_7_AVERAGE]],
    baseResultTypes: [GradeResultType.OTAGO_P_AVG_BEST_7_AVERAGE],
    overallResultTypes: [],
    dateType: GradeDateTypes.YEAR,
    dateFormatter: (date) => moment(date).format('YYYY'),
  },

  // CIE/CAIE A-Level
  'ca00d47c-a1ec-4eea-9812-9f52d1ead758': {
    resultTypes: [[GradeResultType.CIE_AGGREGATE_SCORE]],
    baseResultTypes: [GradeResultType.CIE_AGGREGATE_SCORE],
    overallResultTypes: [],
    dateType: GradeDateTypes.YEAR,
    dateFormatter: (date) => moment(date).format('YYYY'),
  },

  // School GPA
  '29ed35ed-1952-4b76-963b-e8b5cd0e7ab7': {
    resultTypes: [[GradeResultType.GPA_REGION], [GradeResultType.GPA_YEAR], [GradeResultType.SCHOOL_GPA]],
    baseResultTypes: [GradeResultType.GPA_REGION, GradeResultType.GPA_YEAR, GradeResultType.SCHOOL_GPA],
    overallResultTypes: [],
    dateFormatter: (date) => moment(date).format('D MMM, YYYY'),
  },

  // Practice SAT
  'a80e6b12-0416-4447-9326-93d076d5869f': {
    resultTypes: [
      [GradeResultType.PRACTICE_SAT_READING_WRITING],
      [GradeResultType.PRACTICE_SAT_MATH],
      [GradeResultType.PRACTICE_SAT_ESSAY],
      [GradeResultType.PRACTICE_SAT_OVERALL_SCORE],
    ],
    baseResultTypes: [
      GradeResultType.PRACTICE_SAT_READING_WRITING,
      GradeResultType.PRACTICE_SAT_MATH,
      GradeResultType.PRACTICE_SAT_ESSAY,
      GradeResultType.PRACTICE_SAT_OVERALL_SCORE,
    ],
    overallResultTypes: [],
    dateType: GradeDateTypes.DATE,
    dateFormatter: (date) => moment(date).format('D MMM, YYYY'),
  },

  // Practice ACT
  'ba1a2c82-66cf-4834-9f11-f4ae6e41d96c': {
    resultTypes: [
      [GradeResultType.PRACTICE_ACT_MATH],
      [GradeResultType.PRACTICE_ACT_SCIENCE],
      [GradeResultType.PRACTICE_ACT_READING],
      [GradeResultType.PRACTICE_ACT_ENGLISH],
      [GradeResultType.PRACTICE_ACT_WRITING],
      [GradeResultType.PRACTICE_ACT_OVERALL_SCORE],
    ],
    baseResultTypes: [
      GradeResultType.PRACTICE_ACT_MATH,
      GradeResultType.PRACTICE_ACT_SCIENCE,
      GradeResultType.PRACTICE_ACT_READING,
      GradeResultType.PRACTICE_ACT_ENGLISH,
      GradeResultType.PRACTICE_ACT_WRITING,
      GradeResultType.PRACTICE_ACT_OVERALL_SCORE,
    ],
    overallResultTypes: [],
    dateType: GradeDateTypes.DATE,
    dateFormatter: (date) => moment(date).format('D MMM, YYYY'),
  },

  // Duolingo
  '3b51de04-9ddb-482f-9682-47ee95673a5b': {
    resultTypes: [[GradeResultType.Duolingo]],
    baseResultTypes: [GradeResultType.Duolingo],
    overallResultTypes: [],
    dateType: GradeDateTypes.YEAR,
    dateFormatter: (date) => moment(date).format('YYYY'),
  },

  // TOEFL PBT
  '1e4c0173-b529-4ac0-8c81-7f8e13b98064': {
    resultTypes: [[GradeResultType.TOEFL_PBT_OVERALL]],
    baseResultTypes: [GradeResultType.TOEFL_PBT_OVERALL],
    overallResultTypes: [],
    dateType: GradeDateTypes.YEAR,
    dateFormatter: (date) => moment(date).format('YYYY'),
  },
};
