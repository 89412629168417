import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { SubHeader, PageHeader } from 'components/atoms/typography';

import css from './style.scss';

export default function Header(props) {
  const { className, title, isBorderVisible, isSubHeader } = props;

  return (
    <React.Fragment>
      <header className={classNames(css.header, { [css.border]: isBorderVisible }, className)}>
        <div className={classNames(css.main)}>
          {isSubHeader ? (
            <SubHeader className={css.title}>{title}</SubHeader>
          ) : (
            <PageHeader className={css.title}>{title}</PageHeader>
          )}
        </div>
      </header>
    </React.Fragment>
  );
}

Header.defaultProps = {
  isBackButtonVisible: false,
  actionButtons: [],
  subtitles: [],
  isBorderVisible: true,
  isSubHeader: false,
  className: '',
};

Header.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isSubHeader: PropTypes.bool,
  isBorderVisible: PropTypes.bool,
  className: PropTypes.string,
};
