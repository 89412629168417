import { isInvitedToInterview } from '../../util';
import { difference, uniq } from 'lodash';
import moment from 'moment';
import bookingService from 'graphql/api/booking';
import { roundGroupUS } from '../../util';
import {
  DataInputs,
  processor,
  filterUserCountMap,
  getAppYearStudents,
  Event,
  isInDateRange,
  stringToMoment,
  isInMonths,
  TODAY,
  getStudentsWithStartYear,
  currentMonth,
  currentYear,
} from './util';
import {
  schoolSelectionDataBase,
  schoolConfirmationNotSendBase,
  parentApprovalPendingBase,
  parentApprovalRejectedBase,
  onlyReceiveRejectionBase,
  haventReceiveOfferBase,
  incompleteResultsBase,
  submissionOverdueBase,
  milestoneMeetingBase,
} from './common';
/**
 *
 *
 * @param {ApplicationApproval[]} approvals
 * @param {string[]} studentUserIds
 * @return {*}
 * School confirmation not sent => Users without any approval Record
 * return user ids
 */
export const schoolConfirmationNotSend: Event<string[]> = {
  ...schoolConfirmationNotSendBase,
  value: 'schoolConfirmationNotSendUS',
  region: 'US',
  filterAppYearStudents: (students) => {
    return getAppYearStudents(students);
  },
  availability: () => {
    // 09-07 - next year 03-01
    return !isInDateRange('03-01', '09-07');
  },
};

/**
 *
 *
 * @param {Application[]} applications
 * @param {string[]} studentUserIds
 * return userIds that dont have an application that is either submiited or invited to interview
 */
export const earlySubmissionNotStarted: Event = {
  region: 'US',
  priority: 0,
  value: 'earlySubmissionNotStarted',
  label: 'Early submission not started',
  filterInput: (input, event) => {
    const { applications } = input;
    const earlyRoundApplications = applications.filter((app) => app.round && roundGroupUS(app.round) === 'Early');
    return {
      ...input,
      applications: earlyRoundApplications,
    };
  },
  filterAppYearStudents: (students) => getAppYearStudents(students),
  availability: () => {
    // 10-15 - next year 03-01
    return !isInDateRange('03-01', '10-15'); // after 10-15
  },
  resolve: ({ applications, studentUserIds }: DataInputs) => {
    const userCountMap = processor(
      applications,
      studentUserIds,
      (item) => item.group === 'APPLYING' && !(item.isSubmitted || isInvitedToInterview(item)),
      'userId',
    );
    return filterUserCountMap(userCountMap, (v) => !!v);
  },
};

export const regularSubmissionNotStarted: Event = {
  region: 'US',
  priority: 0,
  value: 'regularSubmissionNotStarted',
  label: 'Regular submission not started',
  filterInput: (input, event) => {
    const { applications } = input;
    const regularRoundApplications = applications.filter((app) => app.round && roundGroupUS(app.round) === 'Regular');
    return {
      ...input,
      applications: regularRoundApplications,
    };
  },
  filterAppYearStudents: (students) => getAppYearStudents(students),
  availability: () => {
    // remove in intake year 03-01
    return !isInDateRange('03-01', '09-01');
  },
  resolve: ({ applications, studentUserIds }: DataInputs) => {
    const userCountMap = processor(
      applications,
      studentUserIds,
      (item) =>
        item.group === 'APPLYING' &&
        !(item.isSubmitted || isInvitedToInterview(item)) &&
        !!(item.deadline && moment(item.deadline).diff(TODAY()) < 8), // deadline in 7 days @TODO take holiday into consideration
      'userId',
    );
    return filterUserCountMap(userCountMap, (v) => !!v);
  },
};

/**
 *
 *
 * @param {Application[]} applications
 * @param {string[]} studentUserIds
 * @return {*}
 * return userIds that exist an application's deadline is before today
 */
export const submissionOverdue: Event = {
  ...submissionOverdueBase,
  region: 'US',
  value: 'submissionOverdueUS',
  filterAppYearStudents: getAppYearStudents,
  availability: () => {
    // until 05-01 intake year
    return !isInDateRange('05-01', '09-01');
  },
};

/**
 *
 *
 * @param {Application[]} applications
 * @param {string[]} studentUserIds
 * last item of applicationStatus contains one of [Accepted, Accepted with conditional offer]
 */
export const haventReceiveOffer: Event = {
  ...haventReceiveOfferBase,
  region: 'US',
  value: 'haventReceiveOffeUS',
  filterAppYearStudents: (students) => getAppYearStudents(students),
  availability: () => isInDateRange('02-01', '05-01'), // intake year 02-01 - 05-01
};

export const onlyReceiveRejection: Event = {
  ...onlyReceiveRejectionBase,
  region: 'US',
  value: 'onlyReceiveRejectionUS',
  filterAppYearStudents: (students) => getAppYearStudents(students),
  availability: () => isInDateRange('03-01', '05-01'), // intake year 03-01 - 05-01
};

export const finalListNotStarted: Event = {
  priority: 0,
  region: 'US',
  label: 'Final list not started',
  value: 'finalListNotStarted',
  availability: () => {
    return isInDateRange('07-01', '10-30');
  },
  filterAppYearStudents: (students) => {
    return getStudentsWithStartYear(students, currentYear());
  },
  resolve: (inputs: DataInputs) => {
    const { shortListInProgress, inExploration } = schoolSelectionDataBase.resolve(inputs);
    return uniq([...shortListInProgress, ...inExploration]);
  },
};

/**
 *
 * users that exist approval without response
 */
export const parentApprovalPending: Event = {
  ...parentApprovalPendingBase,
  value: 'parentApprovalPendingUS',
  region: 'US',
  filterAppYearStudents: (students) => {
    return getAppYearStudents(students);
  },
  availability: () => {
    // 09-10 - next year 03-01
    return !isInDateRange('03-01', '09-10'); // after 09-10
  },
};

export const parentApprovalRejected: Event = {
  ...parentApprovalRejectedBase,
  value: 'parentApprovalRejectedUS',
  region: 'US',
  filterAppYearStudents: (students) => {
    return getAppYearStudents(students);
  },
  availability: () => !isInDateRange('03-01', '09-01'),
};

const _firstMeetingWithSSMConfirmed = async ({ applications }: DataInputs, startDay: string, endDay: string) => {
  const studentUserIds = uniq(applications.map((o) => o.userId));
  const { getStudentsOfSessionConfirmedWithSSMs } = await bookingService.getStudentsOfSessionConfirmedWithSSMs(
    startDay,
    endDay,
  );
  return difference(studentUserIds, getStudentsOfSessionConfirmedWithSSMs);
};

export const firstMeetingWithSSMConfirmedJan: Event<Promise<string[]>> = {
  region: 'US',
  priority: 1,
  value: 'firstMeetingWithSSMConfirmedJan',
  label: 'First meeting with SSM (Jan) not scheduled/confirmed',
  availability: () => {
    // Dec 01, Jan - Oct 30
    return !isInDateRange('10-30', '12-01');
  },
  filterAppYearStudents: (students) => {
    const isInDec = currentMonth() === 12;
    // if in Dec, 2022: check app year 2023/2024
    // if in Jan, 2023 - Oct 01, 2023, check app year 2023/2024
    return getStudentsWithStartYear(students, currentYear() + +isInDec);
  },
  resolve: async (inputs: DataInputs) => {
    const startDay = moment().set('month', 0).set('date', 1); // XXXX-01-01
    const endDay = moment().set('month', 1).set('date', 1); // XXXX-02-01
    const isInDec = currentMonth() === 12;
    if (isInDec) {
      startDay.add(1, 'year');
      endDay.add(1, 'year');
    }
    return _firstMeetingWithSSMConfirmed(inputs, startDay.format('YYYY-MM-DD'), endDay.format('YYYY-MM-DD'));
  },
};

export const firstMeetingWithSSMConfirmedMay: Event<Promise<string[]>> = {
  region: 'US',
  priority: 1,
  value: 'firstMeetingWithSSMConfirmedJan',
  label: 'First meeting with SSM (May) not scheduled/confirmed',
  availability: () => {
    // from April - October 30
    return isInDateRange('04-01', '10-30');
  },
  filterAppYearStudents: (students) => {
    return getStudentsWithStartYear(students, currentYear());
  },
  resolve: async (inputs: DataInputs) => {
    return _firstMeetingWithSSMConfirmed(
      inputs,
      stringToMoment('05-01').format('YYYY-MM-DD'), // 05-01
      stringToMoment('06-01').format('YYYY-MM-DD'), // 06-01
    );
  },
};

export const firstMeetingWithSSMConfirmedJuly: Event<Promise<string[]>> = {
  region: 'US',
  priority: 1,
  value: 'firstMeetingWithSSMConfirmedJan',
  label: 'First meeting with SSM (July) not scheduled/confirmed',
  availability: () => {
    // 06-01 - 10-30
    return isInDateRange('06-01', '10-30');
  },
  filterAppYearStudents: (students) => {
    return getStudentsWithStartYear(students, currentYear());
  },
  resolve: async (inputs: DataInputs) => {
    return _firstMeetingWithSSMConfirmed(
      inputs,
      stringToMoment('07-01').format('YYYY-MM-DD'), // 07-01
      stringToMoment('08-01').format('YYYY-MM-DD'), // 08-01
    );
  },
};

export const firstMeetingWithSSMConfirmedSep: Event<Promise<string[]>> = {
  region: 'US',
  priority: 1,
  value: 'firstMeetingWithSSMConfirmedJan',
  label: 'First meeting with SSM (September) not scheduled/confirmed',
  availability: () => {
    return isInDateRange('08-10', '10-30');
  },
  filterAppYearStudents: (students) => {
    return getStudentsWithStartYear(students, currentYear());
  },
  resolve: async (inputs: DataInputs) => {
    return _firstMeetingWithSSMConfirmed(
      inputs,
      stringToMoment('09-01').format('YYYY-MM-DD'), // 09-01
      stringToMoment('10-01').format('YYYY-MM-DD'), // 10-01
    );
  },
};

export const incompleteResults: Event = {
  ...incompleteResultsBase,
  region: 'US',
  value: 'incompleteResultsUS',
  filterAppYearStudents: (students) => getAppYearStudents(students),
  availability: () => isInDateRange('03-01', '05-01'), // intake year 03-01 - 05-01
};

export const schoolSelectionData: Event<
  Record<
    'inExploration' | 'shortListInProgress' | 'finalListInProgress' | 'parentApprovalPending' | 'finalListApproved',
    string[]
  >
> = {
  ...schoolSelectionDataBase,
  region: 'US',
  label: 'school selection',
  value: 'schoolSelectionDataUS',
};

export const milestoneMeeting: Event<Record<'meeting1' | 'meeting2' | 'meeting3' | 'meeting4', string[]>> = {
  ...milestoneMeetingBase,
  value: 'milestoneMeetingUS',
  region: 'US',
};
