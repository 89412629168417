import { connect } from 'react-redux';

import { togglePersonSelected, fetchPeopleForBookingWith } from 'ducks/user';

import { getPeopleForBookingWith, canSelectMorePeople, selectedBookWith, getBookingAs } from 'selectors/user';

import { getBookingWithFetched, getBookingWithPagination } from 'selectors/meta';
import { updateDayCalendarViewHidden } from 'ducks/meta';
import BookingWith from './BookingWith';

const mapStateToProps = (state) => ({
  bookWith: getPeopleForBookingWith(state),
  bookAs: getBookingAs(state),
  canSelectMorePeople: canSelectMorePeople(state),
  selectedBookWith: selectedBookWith(state),
  bookingWithFetched: getBookingWithFetched(state),
  bookingWithPagination: getBookingWithPagination(state),
});

const mapDispatchToProps = (dispatch) => ({
  togglePersonSelected: (userId) => dispatch(togglePersonSelected(userId)),
  loadMoreContacts: (filter, pagination) => dispatch(fetchPeopleForBookingWith(filter, pagination)),
  updateDayCalendarViewHidden: (isHidden) => dispatch(updateDayCalendarViewHidden(isHidden)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BookingWith);
