import React, { useState, useEffect, useCallback } from 'react';
import { TextHeader, GroupTag, TitleRegionType } from './index.style';
import { Row } from '../../index.style';
import { ifElementsOverlap, checkIfHasEnoughWidth, hasEnoughWidthForCount, hasEnoughWidthForType } from './helper';
type HeaderProps = {
  groupName: string;
  count: number;
  tintColor: string;
};
const Header = ({ groupName, tintColor, count }: HeaderProps) => {
  const [isReadyToShow, setReadyToShow] = useState(false);
  const [showCount, setShowCount] = useState(true);
  const [showType, setShowType] = useState(true);

  const groupNameId = groupName + 'tag';
  const typeId = groupName + 'type';
  const containerId = groupName + 'container';
  // check if <groupName/> <count/> <type/> is overlapping
  const checkIsOverlapping = useCallback(() => {
    if (ifElementsOverlap(groupNameId, typeId)) {
      // if is overlapping, hide count
      setShowCount(false);
      setTimeout(() => {
        if (ifElementsOverlap(groupNameId, typeId)) {
          // check again if is overlapping, if true, hide type
          setShowType(false);
        }
      }, 0);
    } else {
      // if not overlapping, check if has enough space for <type/>
      if (checkIfHasEnoughWidth(containerId, groupNameId, hasEnoughWidthForType)) {
        setShowType(true);
        setTimeout(() => {
          if (checkIfHasEnoughWidth(containerId, groupNameId, hasEnoughWidthForCount)) {
            // check if has enough space for <count/>
            setShowCount(true);
          }
        }, 0);
      }
    }
    setTimeout(() => setReadyToShow(true), 1);
  }, [groupNameId, typeId, containerId]);
  useEffect(() => {
    checkIsOverlapping();
    const listenToWindowSizeChange = () => {
      const _listener = (event: UIEvent) => {
        checkIsOverlapping();
      };
      window.addEventListener('resize', _listener);
      return () => window.removeEventListener('resize', _listener);
    };
    return listenToWindowSizeChange();
  }, [checkIsOverlapping]);
  return (
    <tr>
      <th id={containerId} style={{ width: '25%', paddingRight: 10 }}>
        <Row justifycontent={'space-between'} style={{ position: 'relative', opacity: +isReadyToShow }} rowcenter>
          <Row gap={'8px'} id={groupNameId} rowcenter>
            <GroupTag $tintColor={tintColor}>{groupName}</GroupTag>
            {showCount && <TitleRegionType style={{ fontWeight: 800, fontSize: 18 }}>{`(${count})`}</TitleRegionType>}
          </Row>
          {showType && (
            <TitleRegionType id={typeId} style={{ width: '25%', position: 'absolute', right: 0 }}>
              {'Type'}
            </TitleRegionType>
          )}
        </Row>
      </th>
      <TextHeader style={{ width: '13%' }}>{'School Selected'}</TextHeader>
      <TextHeader style={{ width: '10%' }}>{'Submitted'}</TextHeader>
      <TextHeader style={{ width: '13%' }}>{'Result Received'}</TextHeader>
      <TextHeader style={{ width: '14%' }}>{'Upcoming Deadline'}</TextHeader>
      <TextHeader style={{ width: '25%' }}>{'Service Completed'}</TextHeader>
    </tr>
  );
};

export default Header;
