const keys = {
  OUR_PEOPLE_SEARCH: 'ourPeopleSearch',
  APP_VIEWED_USER: 'appViewedUser',
  APP_LOGIN_USER: 'appLoginUser',
  ACTIVE_PAGE: 'activePage',
  ACTIVE_TAB: 'activeTab',
  NOTIFICATIONS: 'notifications',
  FORM_STATE: 'formState',
  MODAL_STATE: 'modalState',
  MODAL_ERROR: 'errorMessages',
  CALENDAR_BOOKING_AS: 'calendarBookingAs',
  CALENDAR_SELECTED: 'calendarSelected',
  CALENDAR_SCROLL: 'calendarScrollPos',
  CALENDAR_SCROLL_HEIGHT: 'calendarScrollHeight',
  USER_PROFILE: 'authentication',
  SELECTED_DAY: 'selectedDay',
  SELECTED_SPECIFIC_DAY: 'selectedSpecificDay',
  STUDENT_INTERESTS: 'studentInterests',
  TUTOR_EXPERTISE: 'tutorExpertise',
  UNAVAILABILITIES: 'unavailable',
  LANGUAGES_FETCHED: 'languagesFetched',
  INVOICE_FETCHED: 'invoiceFetched',
  INVOICES_FETCHED: 'invoicesFetched',
  INVOICE_SUBMITTING: 'invoiceSubmitting',
  INVOICE_PAYING: 'invoicePaying',
  INVOICE_DELETING: 'invoiceDeleting',
  INVOICE_FILTER: 'invoiceFilter',
  ROADMAP_FETCHED: 'roadmapFetched',
  LOADED_ROADMAP_TEMPLATE: 'loadedRoadmapTemplate',
  ZENDESK_LOGIN_URL: 'zendeskLoginUrl',
  ACTIVE_MESSAGE_THREAD: 'activeMessageThread',
  MESSAGE_CONTACT_FILTER: 'messageContactFilter',
  LESSON_DETAILS_CACHED_USERNAMES: 'lessonDetailsCachedUsernames',
  BOOKING_WITH_FETCHED: 'bookingWithFetched',
  USERS_FETCHED: 'usersFetched',
  IS_PAGE_LOADING: 'isPageLoading',
  THUNKS_REMAINING: 'thunksRemaining',
  CLIENTS_FETCHED: 'clientsFetched',
  LESSONS_FETCHED: 'lessonsFetched',
  PACKAGE_ITEMS_FETCHED: 'packageItemsFetched',
  CONTACT_LIST_CACHE: 'contactListCache',
  CURRENT_ROADMAP_TAB: 'currentRoadmapTab',
  FILES_UPLOADING: 'filesUploading',
  FILE_UPLOADED: 'fileUploaded',
  ROUTE_PATHNAME: 'routePathname',
  GLOBAL_MESSAGES: 'globalMessages',
  IS_USER_ONLINE: 'isUserOnline',
  EXAM_LIST_FETCHED: 'examListFetched',
  BOOKING_WITH_CACHE: 'bookingWithCache',
  BOOKING_FOR_CACHE: 'bookingForCache',
  ASSIGNABLE_USERS_CACHE: 'assignableUsersCache',
  TASKS_FETCHED: 'tasksFetched',
  ACTIVE_SESSION_REPORT: 'activeSessionReport',
  SHOW_INCOMPLETE_REPORT_REMINDER: 'showIncompleteReportReminder',
  NEXT_BILLING_PERIOD: 'nextBillingPeriod',
  LAPSED_BILLING_PERIOD: 'lapsedBillingPeriod',
  CALENDAR_DAYS: 'calendarDays',
  REASONS_FOR_CANCELLATION: 'reasonsForCancellation',
  REASONS_FOR_ABSENCE: 'reasonsForAbsence',
  ECL_ACTIVITIES_FETCHED: 'eclActivitiesFetched',
  ECL_SELECTED_ACTIVITY_ID: 'eclSelectedActivityId',
  APPLICATIONS_FETCHED: 'applicationsFetched',
  APPLICATION_SELECTED_ID: 'applicationSelectedId',
  INVOICE_SELECTED_ID: 'invoiceSelectedId',
  INVOICE_FETCHING_LOAD: 'invoiceFetchingLoad',
  SESSION_SELECTED_ID: 'sessionSelectedId',
  SESSION_REDIRECT_ID: 'sessionRedirectId',
  IS_ROADMAP_BULK_DELETE_MODE: 'isRoadmapBulkDeleteMode',
  SESSION_PROMPT_MODAL_OPEN: 'sessionPromptModalOpen',
  TASKS_LIST_USER: 'tasksListUser',
  IS_HEADER_HIDDEN: 'isHeaderHidden',
  SESSION_SCHEDULE_HAS_MORE: 'sessionScheduleHasMore',
  SESSION_IS_CALENDAR_VIEW: 'sessionIsCalendarView',
  SESSION_SCHEDULE_TAB_IS_PAST: 'sessionScheduleTabIsPas',
  IS_MULTI_TENANT: 'isMultiTenant',
  MY_TAGS: 'myTags',
  SESSION_FEEDBACK_REPORT: 'sessionFeedbackReport',
  OTHER_SESSION_HAS_MORE: 'otherSessionHasMore',
  OUR_PEOPLE_FILTER: 'ourPeopleFilter',
  USERS_FETCHED_COUNT: 'usersFetchedCount',
  BOOKING_WITH_FILTER: 'bookingWithFilter',
  BOOKING_FOR_FILTER: 'bookingForFilter',
  DAY_CALENDAR_VIEW: 'dayCalendarView',
  FILTERED_QUERY: 'filteredQuery',
  CACHED_EVENT_IDS: 'cachedEventIds',
  MY_FRIENDS_SEARCH: 'myFriendsSearch',
  VIEWABLE_ROLES: 'viewableRoles',
  ECL_AWARDS_FETCHED: 'eclAwardsFetched',
  ECL_SELECTED_AWARD_ID: 'eclSelectedAwardId',
  GRADE_ACADEMIC_FETCHED: 'gradeAcademicFetched',
  GRADE_SELECTED_ACADEMIC_ID: 'gradeSelectedAcademicId',
  CURRENT_THREAD_ID: 'currentThreadId',
};

const keysProxy = new Proxy(keys, {
  get: function getProperty(target, property) {
    const isKeyPresent = Reflect.has(target, property);
    if (!isKeyPresent) throw new Error(`An unknown key ${property} was provided.`);
    return Reflect.get(target, property);
  },
});

export default keysProxy;
