import React from 'react';
import { Container, Title, StyledModal, StyledClose, StyledIconButton, Content } from './index.style';
import ConfirmCancelButtons from '../ConfirmCancelButtons';
type DialogueProps = {
  children?: React.ReactNode;
  isOpen: boolean;
  closeModal: () => void;
  onClickConfirm?: () => void;
  onClickCancel?: () => void;
  title?: string;
  cancelButtonText?: string;
  confirmButtonText?: string;
  isLoading?: boolean;
  className?: string;
};

const Dialogue = ({
  children,
  isOpen,
  closeModal,
  onClickCancel,
  onClickConfirm,
  title,
  cancelButtonText = 'Cancel',
  confirmButtonText = 'Confirm',
  isLoading,
  className,
}: DialogueProps) => {
  const isStringChildren = typeof children === 'string';
  const ChildWrapper = isStringChildren ? Content : React.Fragment;
  return (
    <StyledModal open={isOpen}>
      <Container className={className}>
        <StyledIconButton onClick={closeModal}>
          <StyledClose />
        </StyledIconButton>
        <Title>{title}</Title>
        <ChildWrapper>{children}</ChildWrapper>
        <ConfirmCancelButtons
          onClickCancel={onClickCancel || closeModal}
          cancelButtonText={cancelButtonText}
          onClickConfirm={onClickConfirm}
          disabled={isLoading}
          confirmButtonText={confirmButtonText}
        />
      </Container>
    </StyledModal>
  );
};

export default Dialogue;
