import React from 'react';
import DropdownMenu from '../Dropdown/DropdownMenu';
import { DropdownButton } from './index.style';
import { MoreLessIcon } from '../index.style';

type DropdownMenuButtonProps = {
  data: { label: string; value: string }[];
  text: string;
  onClickItem: <P extends string>(value: P) => any;
  menuItemStyle?: React.CSSProperties;
};

const DropdownMenuButton = ({ data, text, onClickItem, menuItemStyle }: DropdownMenuButtonProps) => {
  return (
    <DropdownMenu
      data={data}
      onClickItem={onClickItem}
      menuStyle={{ maxHeight: '320px' }}
      menuItemStyle={menuItemStyle}
    >
      {(isOpen) => <DropdownButton endIcon={<MoreLessIcon open={isOpen} />}>{text}</DropdownButton>}
    </DropdownMenu>
  );
};

export default DropdownMenuButton;
