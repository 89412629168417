import { Event, getAppYearStudents, isInDateRange, processor, filterUserCountMap, TODAY } from './util';
import {
  schoolConfirmationNotSendBase,
  parentApprovalPendingBase,
  haventReceiveOfferBase,
  incompleteResultsBase,
  submissionOverdueBase,
  schoolSelectionDataBase,
  milestoneMeetingBase,
} from './common';
import { isInvitedToInterview } from '../../util';
import moment from 'moment';

export const schoolConfirmationNotSend: Event<string[]> = {
  ...schoolConfirmationNotSendBase,
  value: 'schoolConfirmationNotSendEU',
  region: 'EU',
  filterAppYearStudents: (students) => {
    return getAppYearStudents(students);
  },
  availability: () => {
    return !isInDateRange('09-01', '11-01');
  },
};

export const parentApprovalPending: Event = {
  ...parentApprovalPendingBase,
  value: 'parentApprovalPendingEU',
  region: 'EU',
  filterAppYearStudents: (students) => {
    return getAppYearStudents(students);
  },
  availability: () => {
    return true;
  },
};

export const submissionNotStarted: Event = {
  region: 'EU',
  priority: 0,
  value: 'submissionNotStarted',
  label: 'Submission not started',
  availability: () => true,
  filterAppYearStudents: (students) => getAppYearStudents(students),
  resolve: ({ applications, studentUserIds }) => {
    const userCountMap = processor(applications, studentUserIds, (app) => {
      if (!app.deadline) return false;
      const isDeadlineBeforeToday = moment(app.deadline).isSameOrBefore(TODAY(), 'day');
      const isIn14Days = moment(app.deadline).diff(TODAY(), 'day') <= 14;
      const notSubmitted = !app.isSubmitted || !isInvitedToInterview(app);
      return isDeadlineBeforeToday || (isIn14Days && notSubmitted);
    });
    return filterUserCountMap(userCountMap, (v) => !!v);
  },
};
export const submissionOverdue: Event = {
  ...submissionOverdueBase,
  region: 'EU',
  value: 'submissionOverdueEU',
  availability: () => true,
};

export const haventReceiveOffer: Event = {
  ...haventReceiveOfferBase,
  region: 'EU',
  value: 'haventReceiveOffeEU',
  filterAppYearStudents: (students) => getAppYearStudents(students),
  availability: () => isInDateRange('05-01', '09-01'),
};

export const incompleteResults: Event = {
  ...incompleteResultsBase,
  region: 'EU',
  value: 'incompleteResultsEU',
  filterAppYearStudents: (students) => getAppYearStudents(students),
  availability: () => isInDateRange('07-01', '09-01'),
};

export const schoolSelectionData: Event<
  Record<
    'inExploration' | 'shortListInProgress' | 'finalListInProgress' | 'parentApprovalPending' | 'finalListApproved',
    string[]
  >
> = {
  ...schoolSelectionDataBase,
  region: 'EU',
  label: 'school selection',
  value: 'schoolSelectionDataEU',
};

export const milestoneMeeting: Event<Record<'meeting1' | 'meeting2' | 'meeting3' | 'meeting4', string[]>> = {
  ...milestoneMeetingBase,
  value: 'milestoneMeetingEU',
  region: 'EU',
};
