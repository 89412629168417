import Immutable from 'immutable';
import createReducer from 'utils/createReducer';
import userRoles from 'constants/userRoles';
import { ADD_ENTITIES, SET_ENTITIES } from 'ducks/normalizr';
import profileAPI from 'graphql/api/profile';
import userAPI from 'graphql/api/user';
import { getCurrentUserId } from 'selectors/user';
import { toasterMessages, roleTypes } from '@crimson-education/common-config';
import moment from 'moment';

import { formLoading, formSuccess, formFail } from 'ducks/meta';

/**
 * Profile Duck
 * The purpose of this duck is to store role-specific information for users of
 * the Crimson app as their "profile" for that role. Each user may have
 * multiple roles, and therefore multiple profiles. This provides a means of
 * separating information that the user needs while acting in a particular role
 * from their base information which is stored in the User duck.
 */

const SET_PROFILE = 'profile/SET_PROFILE';
const REMOVE_PROFILE = 'profile/REMOVE_PROFILE';
const CREATE_UNIVERSITY = 'profile/CREATE_UNIVERSITY';
const UPDATE_UNIVERSITY = 'profile/UPDATE_UNIVERSITY';
const DELETE_UNIVERSITY = 'profile/DELETE_UNIVERSITY';
const CREATE_SCHOOL = 'profile/CREATE_SCHOOL';
const UPDATE_SCHOOL = 'profile/UPDATE_SCHOOL';
const DELETE_SCHOOL = 'profile/DELETE_SCHOOL';
const UPDATE_STUDENT_INTERESTS = 'profile/UPDATE_STUDENT_INTERESTS';
const UPDATE_TUTOR_EXPERTISE = 'profile/UPDATE_TUTOR_EXPERTISE';
const UPDATE_YEAR_OF_APPLICATION = 'profile/UPDATE_YEAR_OF_APPLICATION';
const UPDATE_STUDENT_TYPE = 'profile/UPDATE_STUDENT_TYPE';
const CREATE_KEY_CONTACT = 'profile/CREATE_KEY_CONTACT';
const UPDATE_KEY_CONTACT = 'profile/UPDATE_KEY_CONTACT';
const DELETE_KEY_CONTACT = 'profile/DELETE_KEY_CONTACT';
const UPDATE_STUDENT_CONTRACT_STATUS = 'profile/UPDATE_STUDENT_CONTRACT_STATUS';
const UPDATE_STUDENT_CONTRACT_SUB_STATUS = 'profile/UPDATE_STUDENT_CONTRACT_SUB_STATUS';
const EDIT_STUDENT_ESSAY_FOLDER = 'profile/EDIT_STUDENT_ESSAY_FOLDER';
const EDIT_STUDENT_HOOK_STATEMENT = 'profile/EDIT_STUDENT_HOOK_STATEMENT';
const initialState = Immutable.fromJS({});

export default createReducer(initialState, {
  [ADD_ENTITIES]: (state, action) => {
    return state.mergeDeep(action.payload.entities.profile);
  },
  [SET_ENTITIES]: (state, action) => {
    return state.merge(action.payload.entities.profile);
  },
  [SET_PROFILE]: (state, action) => {
    const { userId, role, profile } = action.payload;
    if (!(userId && role)) return state;
    if (!Object.values(userRoles).includes(role)) return state;

    return state.set(`${userId}-${role}`, Immutable.fromJS(profile));
  },
  [REMOVE_PROFILE]: (state, action) => {
    const { userId, role } = action.payload;
    if (!(userId && role)) return state;

    return state.delete(`${userId}-${role}`);
  },
  [UPDATE_STUDENT_INTERESTS]: (state, action) => {
    const {
      userId,
      studentInfo: { interests },
    } = action.payload;
    return state.setIn([`${userId}-STUDENT`, 'interests'], interests);
  },
  [UPDATE_YEAR_OF_APPLICATION]: (state, action) => {
    const { userId, yearOfApplication } = action.payload;
    return state.setIn([`${userId}-STUDENT`, 'yearOfApplication'], yearOfApplication);
  },
  [UPDATE_TUTOR_EXPERTISE]: (state, action) => {
    const {
      userId,
      tutorInfo: { expertise },
    } = action.payload;
    return state.setIn([`${userId}-TUTOR`, 'expertise'], expertise);
  },
  [CREATE_KEY_CONTACT]: (state, action) => {
    const { userId, keyContact } = action.payload;
    return state.updateIn([`${userId}-STUDENT`, 'keyContacts'], (keyContacts) => {
      // Need to update all the other keyContacts as not the primary if we selected this one as primary
      if (keyContact.isPrimary) {
        return keyContacts
          .map((item) => {
            return item.setIn(['isPrimary'], false);
          })
          .push(Immutable.fromJS(keyContact));
      }
      return keyContacts.push(Immutable.fromJS(keyContact));
    });
  },
  [UPDATE_KEY_CONTACT]: (state, action) => {
    const { userId, keyContact } = action.payload;
    return state.updateIn([`${userId}-STUDENT`, 'keyContacts'], (keyContacts) => {
      // Need to update all the other keyContacts as not the primary if we selected this one as primary
      if (keyContact.isPrimary) {
        return keyContacts.map((item) => {
          if (item.get('id') === keyContact.id) {
            return Immutable.fromJS(keyContact);
          }
          return item.setIn(['isPrimary'], false);
        });
      }
      // Otherwise just look for the keyContact and update the details
      const index = keyContacts.findIndex((item) => {
        return item.get('id') === keyContact.id;
      });
      return keyContacts.setIn([index], Immutable.fromJS(keyContact));
    });
  },
  [DELETE_KEY_CONTACT]: (state, { payload: { keyContactId, userId } }) => {
    return state.updateIn([`${userId}-STUDENT`, 'keyContacts'], (keyContacts) => {
      const sortedKeyContacts = keyContacts.sortBy(
        (keyContact) => keyContact,
        (a, b) => {
          const aCreated = a.get('createdAt');
          const bCreated = b.get('createdAt');
          return moment(bCreated).isBefore(moment(aCreated));
        },
      );
      const index = sortedKeyContacts.findIndex((item) => {
        return item.get('id') === keyContactId;
      });
      const deletedKeyContact = sortedKeyContacts.get(index);
      const keyContactsWithoutDeleted = sortedKeyContacts.deleteIn([index]);
      return keyContactsWithoutDeleted.size > 0 && deletedKeyContact.get('isPrimary')
        ? keyContactsWithoutDeleted.setIn([0, 'isPrimary'], true)
        : keyContactsWithoutDeleted;
    });
  },
  [CREATE_UNIVERSITY]: (state, { payload: { userId, role, university } }) => {
    return state.updateIn([`${userId}-${role}`, 'universities'], (universities) => {
      return universities.push(Immutable.fromJS(university));
    });
  },
  [UPDATE_UNIVERSITY]: (state, { payload: { userId, role, university } }) => {
    return state.updateIn([`${userId}-${role}`, 'universities'], (universities) => {
      const index = universities.findIndex((item) => {
        return item.get('id') === university.id;
      });
      return universities.setIn([index], Immutable.fromJS(university));
    });
  },
  [DELETE_UNIVERSITY]: (state, { payload: { universityId, role, userId } }) => {
    return state.updateIn([`${userId}-${role}`, 'universities'], (universities) => {
      const index = universities.findIndex((item) => {
        return item.get('id') === universityId;
      });
      return universities.deleteIn([index]);
    });
  },
  [CREATE_SCHOOL]: (state, { payload: { userId, role, school } }) => {
    return state.updateIn([`${userId}-${role}`, 'schools'], (schools) => {
      return schools.push(Immutable.fromJS(school));
    });
  },
  [UPDATE_SCHOOL]: (state, { payload: { userId, role, school } }) => {
    return state.updateIn([`${userId}-${role}`, 'schools'], (schools) => {
      const index = schools.findIndex((item) => {
        return item.get('id') === school.id;
      });
      return schools.setIn([index], Immutable.fromJS(school));
    });
  },
  [DELETE_SCHOOL]: (state, { payload: { schoolId, role, userId } }) => {
    return state.updateIn([`${userId}-${role}`, 'schools'], (schools) => {
      const index = schools.findIndex((item) => {
        return item.get('id') === schoolId;
      });
      return schools.deleteIn([index]);
    });
  },
  [UPDATE_STUDENT_TYPE]: (state, { payload: { userId, studentType } }) => {
    return state.updateIn([`${userId}-${roleTypes.STUDENT}`, 'studentType'], () => {
      return studentType;
    });
  },
  [UPDATE_STUDENT_CONTRACT_STATUS]: (state, { payload: { userId, studentContractStatus } }) => {
    return state.updateIn([`${userId}-${roleTypes.STUDENT}`, 'contractStatus'], () => {
      return studentContractStatus;
    });
  },
  [UPDATE_STUDENT_CONTRACT_SUB_STATUS]: (state, { payload: { userId, studentContractSubStatus } }) => {
    return state.updateIn([`${userId}-${roleTypes.STUDENT}`, 'contractSubStatus'], () => {
      return studentContractSubStatus;
    });
  },
  [EDIT_STUDENT_ESSAY_FOLDER]: (state, { payload: { userId, essayFolder } }) => {
    return state.updateIn([`${userId}-${roleTypes.STUDENT}`, 'essayFolder'], () => {
      return essayFolder;
    });
  },
  [EDIT_STUDENT_HOOK_STATEMENT]: (state, { payload: { userId, hookStatement } }) => {
    return state.updateIn([`${userId}-${roleTypes.STUDENT}`, 'hookStatement'], () => {
      return hookStatement;
    });
  },
});

export function setProfile(payload) {
  return { type: SET_PROFILE, payload };
}

export function removeProfile(payload) {
  return { type: REMOVE_PROFILE, payload };
}

export function createUniversitySucceeded(userId, role, university) {
  return { type: CREATE_UNIVERSITY, payload: { userId, role, university } };
}

export function updateUniversitySucceeded(userId, role, university) {
  return { type: UPDATE_UNIVERSITY, payload: { userId, role, university } };
}

export function deleteUniversitySucceeded(universityId, role, userId) {
  return { type: DELETE_UNIVERSITY, payload: { universityId, role, userId } };
}

export function createSchoolSucceeded(userId, role, school) {
  return { type: CREATE_SCHOOL, payload: { userId, role, school } };
}

export function updateSchoolSucceeded(userId, role, school) {
  return { type: UPDATE_SCHOOL, payload: { userId, role, school } };
}

export function deleteSchoolSucceeded(schoolId, role, userId) {
  return { type: DELETE_SCHOOL, payload: { schoolId, role, userId } };
}

export function updateTutorExpertise(payload) {
  return { type: UPDATE_TUTOR_EXPERTISE, payload };
}

export function updateStudentInterests(payload) {
  return { type: UPDATE_STUDENT_INTERESTS, payload };
}

export function updateYearOfApplication(payload) {
  return { type: UPDATE_YEAR_OF_APPLICATION, payload };
}

export function createKeyContactSucceeded(userId, keyContact) {
  return { type: CREATE_KEY_CONTACT, payload: { userId, keyContact } };
}

export function updateKeyContactSucceeded(userId, keyContact) {
  return { type: UPDATE_KEY_CONTACT, payload: { userId, keyContact } };
}

export function deleteKeyContactSucceeded(keyContactId, userId) {
  return { type: DELETE_KEY_CONTACT, payload: { keyContactId, userId } };
}

export function updateStudentType(userId, studentType) {
  return { type: UPDATE_STUDENT_TYPE, payload: { userId, studentType } };
}

export function updateStudentContractStatusSucceeded(userId, studentContractStatus) {
  return { type: UPDATE_STUDENT_CONTRACT_STATUS, payload: { userId, studentContractStatus } };
}

export function updateStudentContractSubStatusSucceeded(userId, studentContractSubStatus) {
  return { type: UPDATE_STUDENT_CONTRACT_SUB_STATUS, payload: { userId, studentContractSubStatus } };
}

export function editStudentEssayFolderSucceeded(userId, essayFolder) {
  return { type: EDIT_STUDENT_ESSAY_FOLDER, payload: { userId, essayFolder } };
}

export function editStudentHookStatementSucceeded(userId, hookStatement) {
  return { type: EDIT_STUDENT_HOOK_STATEMENT, payload: { userId, hookStatement } };
}

export function createKeyContact(keyContactInfo) {
  return async (dispatch) => {
    try {
      dispatch(formLoading());
      const result = await profileAPI.createKeyContact(keyContactInfo);
      dispatch(formSuccess('A contact has been added successfully.'));
      dispatch(createKeyContactSucceeded(keyContactInfo.userId, result.createKeyContact));
    } catch (error) {
      dispatch(formFail('Failed to save new contact'));
    }
  };
}

export function createUniversity(university, role) {
  return async (dispatch, getState) => {
    dispatch(formLoading());
    try {
      const result = await profileAPI.createUniversity(university);
      const userId = getCurrentUserId(getState());
      dispatch(formSuccess('Added university'));
      dispatch(createUniversitySucceeded(userId, role, result.createUniversity));
    } catch (err) {
      dispatch(formFail('Failed to create university'));
    }
  };
}

export function updateUniversity(universityData, role) {
  return async (dispatch, getState) => {
    dispatch(formLoading());
    try {
      const result = await profileAPI.updateUniversity(universityData);
      const userId = getCurrentUserId(getState());
      dispatch(formSuccess('Updated university'));
      dispatch(updateUniversitySucceeded(userId, role, result.updateUniversity));
    } catch (err) {
      dispatch(formFail('Failed to update university'));
    }
  };
}

export function deleteUniversity(id, role) {
  return async (dispatch, getState) => {
    try {
      dispatch(formLoading());
      const userId = getCurrentUserId(getState());
      await profileAPI.deleteUniversity(id);
      dispatch(formSuccess('University removed'));
      dispatch(deleteUniversitySucceeded(id, role, userId));
    } catch (err) {
      dispatch(formFail('Failed to remove university'));
    }
  };
}

export function createSchool(school, role) {
  return async (dispatch, getState) => {
    dispatch(formLoading());
    try {
      const result = await profileAPI.createSchool(school);
      const userId = getCurrentUserId(getState());
      dispatch(formSuccess('Added school'));
      dispatch(createSchoolSucceeded(userId, role, result.createSchool));
    } catch (err) {
      dispatch(formFail('Failed to create school'));
    }
  };
}

export function updateSchool(schoolData, role) {
  return async (dispatch, getState) => {
    dispatch(formLoading());
    try {
      const result = await profileAPI.updateSchool(schoolData);
      const userId = getCurrentUserId(getState());
      dispatch(formSuccess('Updated school'));
      dispatch(updateSchoolSucceeded(userId, role, result.updateSchool));
    } catch (err) {
      dispatch(formFail('Failed to update school'));
    }
  };
}

export function deleteSchool(id, role) {
  return async (dispatch, getState) => {
    try {
      dispatch(formLoading());
      const userId = getCurrentUserId(getState());
      await profileAPI.deleteSchool(id);
      dispatch(formSuccess('school removed'));
      dispatch(deleteSchoolSucceeded(id, role, userId));
    } catch (err) {
      dispatch(formFail('Failed to remove school'));
    }
  };
}

export function saveKeyContactInfo(keyContactInfo) {
  return async (dispatch, getState) => {
    dispatch(formLoading());
    try {
      const result = await profileAPI.saveKeyContactInfo(keyContactInfo);
      const userId = getCurrentUserId(getState());
      dispatch(formSuccess('A contact has been updated successfully.'));
      dispatch(updateKeyContactSucceeded(userId, result.saveKeyContactInfo));
    } catch (err) {
      dispatch(formFail('Failed to update contact'));
    }
  };
}

export function deleteKeyContact(id) {
  return async (dispatch, getState) => {
    try {
      dispatch(formLoading());
      const userId = getCurrentUserId(getState());
      await profileAPI.deleteKeyContact(id);
      dispatch(formSuccess('A contact has been removed successfully.'));
      dispatch(deleteKeyContactSucceeded(id, userId));
    } catch (err) {
      dispatch(formFail('Failed to remove contact'));
    }
  };
}

export function editTutorExpertiseByUserId(userId, expertise) {
  return (dispatch) => {
    return userAPI
      .editTutorExpertise(userId, expertise)
      .then((res) => {
        const {
          editUser: { tutorInfo },
        } = res;
        dispatch(formSuccess(toasterMessages.userExpertiseUpdated()));
        dispatch(updateTutorExpertise({ tutorInfo, userId }));
      })
      .catch(() => {
        dispatch(formFail(toasterMessages.userExpertiseNotUpdated()));
      });
  };
}

export function editStudentInterestsByUserId(userId, interests) {
  return (dispatch) => {
    dispatch(formLoading());
    return userAPI
      .editStudentInterests(userId, interests)
      .then((res) => {
        const {
          editUser: { studentInfo },
        } = res;
        dispatch(formSuccess(toasterMessages.userInterestUpdated()));
        dispatch(updateStudentInterests({ studentInfo, userId }));
      })
      .catch(() => {
        dispatch(formFail(toasterMessages.userInterestNotUpdated()));
      });
  };
}

export function editYearOfApplicationByUserId(userId, yearOfApplication, displayToaster) {
  return async (dispatch) => {
    try {
      await profileAPI.updateYearOfApplication(userId, yearOfApplication);
      if (displayToaster) {
        dispatch(formSuccess(`Application cycle has been ${displayToaster}`));
      }
      dispatch(updateYearOfApplication({ userId, yearOfApplication }));
      return true;
    } catch (err) {
      dispatch(formFail('Application cycle failed to update'));
      return false;
    }
  };
}

export function editStudentType(userId, studentType) {
  return (dispatch) => {
    return userAPI
      .editUser(userId, {}, { studentType })
      .then(({ editUser: user }) => {
        if (user) {
          dispatch(formSuccess(toasterMessages.userProfileUpdated()));
          dispatch(updateStudentType(userId, studentType));
        } else {
          dispatch(formFail(toasterMessages.userProfileNotUpdated()));
        }
      })
      .catch(() => {
        dispatch(formFail(toasterMessages.userProfileNotUpdated()));
      });
  };
}

export function updateStudentContractStatus(userId, studentContractStatus) {
  return async (dispatch) => {
    try {
      const result = await profileAPI.updateStudentContractStatus(userId, studentContractStatus);
      dispatch(updateStudentContractStatusSucceeded(userId, result.editStudentContractStatus.contractStatus));
      dispatch(formSuccess('Student contract status has been updated successfully.'));
    } catch (err) {
      dispatch(formFail('Failed to update student contract status'));
    }
  };
}

export function updateStudentContractSubStatus(userId, studentContractSubStatus) {
  return async (dispatch) => {
    try {
      const result = await profileAPI.updateStudentContractSubStatus(userId, studentContractSubStatus);
      dispatch(updateStudentContractSubStatusSucceeded(userId, result.editStudentContractSubStatus.contractSubStatus));
      dispatch(formSuccess('Student contract sub status has been updated successfully.'));
    } catch (err) {
      dispatch(formFail('Failed to update student contract sub status'));
    }
  };
}

export function editStudentEssayFolder(userId, essayFolder) {
  return async (dispatch) => {
    try {
      const result = await profileAPI.editStudentEssayFolder(userId, essayFolder);
      dispatch(editStudentEssayFolderSucceeded(userId, result.editStudentEssayFolder.essayFolder));
      dispatch(formSuccess('Essay folder has been updated successfully.'));
    } catch (err) {
      dispatch(formFail('Failed to update student essay folder'));
    }
  };
}

export function editStudentHookStatement(userId, hookStatement) {
  return async (dispatch) => {
    try {
      const result = await profileAPI.editStudentHookStatement(userId, hookStatement);
      dispatch(editStudentHookStatementSucceeded(userId, result.editStudentHookStatement.hookStatement));
      dispatch(formSuccess('Hook statement has been updated successfully.'));
    } catch (err) {
      dispatch(formFail('Failed to update student hook statement'));
    }
  };
}
