import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RadioGroup from 'components/molecules/RadioGroup';
import RadioButton from 'components/molecules/RadioButton';
import Modal from 'components/molecules/Modal';
import LoadingCircle from 'components/molecules/LoadingCircle';
import TextArea from 'components/molecules/TextArea';
import { Caption } from 'components/atoms/typography';
import colours from 'components/atoms/colours.scss';

import css from './styles.scss';

const DTID_CANCEL_SESSION_MODAL_SUBMIT = 'cancelSessionModalSubmitButton';

export default class CancelBookingModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedReasonId: null,
      comment: '',
      commentValid: true,
    };

    this.submitCancelBooking = this.submitCancelBooking.bind(this);
    this.onChangeSelectedReason = this.onChangeSelectedReason.bind(this);
    this.renderModalContent = this.renderModalContent.bind(this);
    this.onCommentChange = this.onCommentChange.bind(this);
    this.getSelectedReasonById = this.getSelectedReasonById.bind(this);

    this.commentFieldRef = React.createRef();
  }

  onChangeSelectedReason(selectedReasonId) {
    const selectedReason = this.getSelectedReasonById(selectedReasonId);

    this.setState({
      selectedReasonId,
      commentValid: true,
    });
    selectedReason.requiresComments && this.commentFieldRef.current && this.commentFieldRef.current.focus();
  }

  onCommentChange(value) {
    this.setState({ comment: value, commentValid: true });
  }

  getSelectedReasonById(selectedReasonId) {
    const { reasonsForCancellation } = this.props;
    return selectedReasonId && reasonsForCancellation.find((reason) => reason.id === selectedReasonId);
  }

  async submitCancelBooking() {
    const { cancelBooking, onBookingCancelled, eventId, userId, isPenaltySession } = this.props;
    const { selectedReasonId, comment } = this.state;

    const selectedReason = this.getSelectedReasonById(selectedReasonId);

    if (selectedReason.requiresComments && !comment) {
      this.setState({ commentValid: false });
    } else {
      await cancelBooking(eventId, userId, selectedReasonId, comment, isPenaltySession);
      onBookingCancelled && onBookingCancelled();
    }
  }

  renderModalContent() {
    const { reasonsForCancellation } = this.props;
    const { selectedReasonId, comment, commentValid } = this.state;

    const selectedReason = this.getSelectedReasonById(selectedReasonId);

    if (reasonsForCancellation.length === 0) {
      return (
        <div className={css.loader}>
          <LoadingCircle color={colours.crimsonRedDark} />
        </div>
      );
    }

    return (
      <div>
        <RadioGroup
          name="reasons_for_cancellation"
          onChange={this.onChangeSelectedReason}
          selectedValue={selectedReasonId}
        >
          {reasonsForCancellation.map((reasonOption, index) => {
            const { id, reason } = reasonOption;
            return (
              <div key={id}>
                <RadioButton
                  option={{ text: reason, value: id }}
                  isChecked={selectedReasonId === id}
                  dataTestId={`reasonForCancellationOption${index}`}
                />
              </div>
            );
          })}
        </RadioGroup>

        <div className={css.commentBox}>
          <Caption>{`Additional comments ${
            selectedReason && selectedReason.requiresComments ? '' : '(Optional)'
          }`}</Caption>
          <TextArea
            autoSize
            dataTestId="reasonForCancellationComments"
            isError={!commentValid}
            validationMessage="Please submit a reason"
            placeholder={selectedReason && selectedReason.requiresComments ? 'Please tell us what happened' : ''}
            onChange={this.onCommentChange}
            value={comment}
            forwardedRef={this.commentFieldRef}
          />
        </div>
      </div>
    );
  }

  render() {
    const { isOpen, onClose, onBack } = this.props;
    const { selectedReasonId } = this.state;

    return (
      <Modal
        isOpen={isOpen}
        onSubmit={this.submitCancelBooking}
        onSubmitDataTestId={DTID_CANCEL_SESSION_MODAL_SUBMIT}
        isSubmitDisabled={!selectedReasonId}
        onClose={onClose}
        onBack={onBack}
        title="Why are you cancelling?"
        submitText="Cancel this session"
        secondarySubmitText={'Back' || 'Close'}
      >
        {this.renderModalContent()}
      </Modal>
    );
  }
}

CancelBookingModal.propTypes = {
  eventId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  cancelBooking: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onBack: PropTypes.func,
  onBookingCancelled: PropTypes.func,
  reasonsForCancellation: PropTypes.array,
  isPenaltySession: PropTypes.bool,
};
