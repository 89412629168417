import { connect } from 'react-redux';
import {
  getFormState,
  selectUserPermissions,
  getNextBillingPeriod,
  getShowIncompleteReportReminder,
} from 'selectors/meta';
import { getLoginUser } from 'selectors/user';
import { getAllCurriculum } from 'selectors/curriculum';
import { getAllIncompleteReports } from 'selectors/incompleteReport';
import { fetchRoadmapIdByUserId } from 'ducks/roadmap';
import { fetchExamIdInProgressByUserId, fetchAvailableExamsByUserId } from 'ducks/examPrep';
import { selectBookingAs, fetchStaffInfo } from 'ducks/user';
import { fetchIncompleteReports, fetchIncompleteSessionReports } from 'ducks/incompleteReport';
import { fetchAllCurriculum } from 'ducks/curriculum';
import { doIncompleteReportLater } from 'ducks/meta';
import AppContainer from './AppContainer';

export default connect(
  (state) => ({
    allCurriculum: getAllCurriculum(state),
    formState: getFormState(state),
    loginUser: getLoginUser(state),
    loginUserPermissions: selectUserPermissions(state),
    incompleteReports: getAllIncompleteReports(state),
    nextBillingPeriod: getNextBillingPeriod(state),
    isReportReminderVisible: getShowIncompleteReportReminder(state),
  }),
  (dispatch, ownProps) => ({
    fetchCurriculum: () => dispatch(fetchAllCurriculum()),
    fetchRoadmapIdByUserId: (userId) => dispatch(fetchRoadmapIdByUserId(userId)),
    fetchExamIdInProgressByUserId: (userId) => dispatch(fetchExamIdInProgressByUserId(userId)),
    selectBookingAs: (userId, fetchBookingWith) => dispatch(selectBookingAs(userId, fetchBookingWith)),
    fetchIncompleteReports: (userId) => dispatch(fetchIncompleteReports(userId)),
    fetchAvailableExamsByUserId: (userId, isStudentView) =>
      dispatch(fetchAvailableExamsByUserId(userId, isStudentView, ownProps.history)),
    doIncompleteReportLater: () => dispatch(doIncompleteReportLater()),
    fetchIncompleteSessionReports: (userId) => dispatch(fetchIncompleteSessionReports(userId)),
    fetchStaffInfo: (userId) => dispatch(fetchStaffInfo(userId)),
  }),
)(AppContainer);
