import Immutable from 'immutable';
import createReducer from 'utils/createReducer';
import { formatGraphQLRequestError } from 'utils/graphql';
import salesPackageService from 'graphql/api/salesPackage';
import { formFail } from 'ducks/meta';
import { ADD_ENTITIES, addEntitiesWithNormalisation } from 'ducks/normalizr';
import { salesPackageEntity } from 'schema';

const initialState = Immutable.fromJS({});

const reducer = {
  [ADD_ENTITIES]: (state, action) => {
    return state.mergeDeep(action.payload.entities.salesPackage);
  },
};

export default createReducer(initialState, reducer);

export function processSalesPackage(packages) {
  return packages.reduce((prev, item) => {
    prev[item.id] = item;
    return prev;
  }, {});
}
// fetch a user's sales package
export function fetchSalesPackageByUser(userId) {
  return (dispatch) => {
    return salesPackageService
      .getAllSalesPackages(userId)
      .then((res) => {
        dispatch(addEntitiesWithNormalisation(processSalesPackage(res.getSalesPackages), [salesPackageEntity]));
      })
      .catch((error) => dispatch(formFail(['Error', formatGraphQLRequestError(error)])));
  };
}
