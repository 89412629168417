import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBox from '@material-ui/icons/CheckBox';
import IndeterminateCheckBox from '@material-ui/icons/IndeterminateCheckBox';

import css from './style.scss';
import { handleEnter } from '../../../utils/accessibility';

export const checkedStates = {
  CHECKED: 'CHECKED',
  UNCHECKED: 'UNCHECKED',
  INDETERMINATE: 'INDETERMINATE',
};

export default function Checkbox(props) {
  const { dataTestId, checkedClass, uncheckedClass, onClick, isChecked, isIndeterminate, canEdit } = props;

  let dataGaLabel;
  if (isIndeterminate) {
    dataGaLabel = 'Indeterminate';
  } else if (!isIndeterminate && isChecked) {
    dataGaLabel = 'Checked';
  } else {
    dataGaLabel = 'Unchecked';
  }

  return (
    <div
      data-ga-label={dataGaLabel}
      role="checkbox"
      aria-checked={isChecked}
      tabIndex={0}
      className={classNames(css.checkboxInputStyle, { [css.disabled]: !canEdit })}
      data-test-id={dataTestId}
      onClick={canEdit ? onClick : undefined}
      onKeyDown={canEdit ? handleEnter(onClick) : undefined}
    >
      {isIndeterminate && (
        <IndeterminateCheckBox
          style={{ width: '2.4rem', height: '2.4rem' }}
          className={classNames(css.checkbox, css.checked, checkedClass)}
        />
      )}
      {!isIndeterminate && isChecked && (
        <CheckBox
          style={{ width: '2.4rem', height: '2.4rem' }}
          className={classNames(css.checkbox, css.checked, checkedClass)}
        />
      )}
      {!isIndeterminate && !isChecked && (
        <CheckBoxOutlineBlank className={classNames(css.checkbox, css.unchecked, uncheckedClass)} />
      )}
    </div>
  );
}

Checkbox.defaultProps = {
  isChecked: false,
};

Checkbox.propTypes = {
  isChecked: PropTypes.bool,
  isIndeterminate: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  dataTestId: PropTypes.string,
  checkedClass: PropTypes.string,
  uncheckedClass: PropTypes.string,
  canEdit: PropTypes.bool,
};

Checkbox.defaultProps = {
  canEdit: true,
};
