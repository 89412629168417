import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';
import { gql } from '@apollo/client';
import { createClient } from '../utils';

const { api } = getConfig();

const client = createClient(api.graphQLEndpoint);

const uploadedFileType = gql`
  fragment uploadedFileType on uploadedFileType {
    id
    name
    description
    size
    type
    uploadedBy
    createdAt
    refs {
      refType
      refId
      tag
    }
  }
`;

const fetchFileByIdAndRefType = gql`
  ${uploadedFileType}
  query fetchFileByIdAndRefType($id: String!, $refType: String!) {
    getUploadedFileById(id: $id, refType: $refType) {
      ...uploadedFileType
    }
  }
`;

const fetchFilesForUser = gql`
  ${uploadedFileType}
  query fetchFilesForUser($userId: String!, $tag: String) {
    getUploadedFilesByUserId(userId: $userId, tag: $tag) {
      ...uploadedFileType
    }
  }
`;

const deleteFile = gql`
  mutation deleteFile($id: String!) {
    deleteUploadedFile(id: $id) {
      id
    }
  }
`;

const getDownloadUrl = gql`
  query getDownloadUrl($fileId: String!) {
    getDownloadUrl(fileId: $fileId)
  }
`;

const saveUploadedFile = gql`
  ${uploadedFileType}
  mutation saveUploadedFile($location: String!, $sha512: String!, $name: String!, $size: String!, $type: String!) {
    saveUploadedFile(location: $location, sha512: $sha512, name: $name, size: $size, type: $type) {
      ...uploadedFileType
    }
  }
`;

// for student center & application tracker
const getFilesByTypeAndRefId = gql`
  query getFilesByTypeAndRefId($refType: String!, $refId: String!, $tag: String) {
    getFilesByTypeAndRefId(refType: $refType, refId: $refId, tag: $tag) {
      id
      name
      description
      size
      type
      location
      refType
      refId
      tag
    }
  }
`;

// for student center & application tracker
const saveFileUplRef = gql`
  mutation saveFileUplRef(
    $location: String!
    $sha512: String!
    $name: String!
    $size: String!
    $type: String!
    $refType: String!
    $refId: String!
    $tag: String
  ) {
    saveFileUplRef(
      location: $location
      sha512: $sha512
      name: $name
      size: $size
      type: $type
      refType: $refType
      refId: $refId
      tag: $tag
    ) {
      name
    }
  }
`;

// for application tracker
const getApplicationTrackerUploadUrl = gql`
  query getApplicationTrackerUploadUrl($studentId: String!, $kind: String!, $fileName: String!, $contentType: String!) {
    getApplicationTrackerUploadUrl(studentId: $studentId, kind: $kind, fileName: $fileName, contentType: $contentType) {
      putUrl
      bucket
      key
    }
  }
`;

// for application tracker
const getApplicationTrackerDownloadUrl = gql`
  query getApplicationTrackerDownloadUrl($fileId: String!) {
    url: getApplicationTrackerDownloadUrl(fileId: $fileId)
  }
`;

// for student center & application tracker
const deleteFileByFileId = gql`
  mutation deleteFileByFileId($fileId: String!) {
    deleteFileByFileId(fileId: $fileId) {
      id
    }
  }
`;

export default {
  fetchFileByIdAndRefType: (id, refType) => client.query(fetchFileByIdAndRefType, { id, refType }),
  fetchFilesForUser: (userId, tag) => client.query(fetchFilesForUser, { userId, tag }),
  deleteUploadedFile: (id) => client.query(deleteFile, { id }),
  getDownloadUrl: (fileId) => {
    return client.query(getDownloadUrl, { fileId }).then((res) => {
      if (!res.getDownloadUrl) {
        return Promise.reject(new Error('failed to fetch file url'));
      }
      return res.getDownloadUrl;
    });
  },
  saveUploadedFile: (location, sha512, name, size, type) =>
    client.query(saveUploadedFile, { location, sha512, name, size: size.toString(), type }),

  // For application tracker & student center
  getFilesByTypeAndRefId: async (refType, refId, tag) => client.query(getFilesByTypeAndRefId, { refType, refId, tag }),
  saveFileUplRef: async (location, sha512, name, size, type, refType, refId, tag) =>
    client.query(saveFileUplRef, { location, sha512, name, size, type, refType, refId, tag }),
  getApplicationTrackerUploadUrl: async (studentId, kind, fileName, contentType) =>
    client.query(getApplicationTrackerUploadUrl, { studentId, kind, fileName, contentType }),
  getApplicationTrackerDownloadUrl: async (fileId) => client.query(getApplicationTrackerDownloadUrl, { fileId }),
  deleteFileByFileId: async (fileId) => client.query(deleteFileByFileId, { fileId }),
};
