import React from 'react';
import moment from 'moment';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Acl, roleTypes } from '@crimson-education/common-config';
import { selectUserProfile, getLapsedBillingPeriod, getShowIncompleteReportReminder } from 'selectors/meta';
import {
  getNextSessionRequiringFeedback,
  getNextLessonRequiringFeedback,
  getNextBookingRequiringReport,
  getReasonsForAbsenceByRole,
  getStrategistOrEduCoSessionFeedback,
} from 'selectors/booking';
import { autoCompleteBooking, completeAttendanceBooking, submitAbsence } from 'ducks/booking';
import { submitSessionReport, submitSessionFeedback, createSessionFeedbackReport } from 'ducks/feedback';
import { updateMeta, showIncompleteReportBanner, showPageLoader, fetchReasonsForAbsence } from 'ducks/meta';
import { getAllIncompleteReports } from 'selectors/incompleteReport';
import componentKeys from 'constants/componentKeys';

import FeedbackModals from 'components/generic/FeedbackModals';

function FeedbackContainer(props) {
  const {
    booking,
    loginUser,
    incompleteReports,
    lapsedBillingPeriod,
    showIncompleteReportBanner,
    setActiveSessionReport,
    isReportReminderVisible,
  } = props;

  const isTutorOrStudent =
    Acl.checkRole(loginUser.get('userRoles'), roleTypes.STUDENT) ||
    Acl.checkRole(loginUser.get('userRoles'), roleTypes.TUTOR) ||
    Acl.checkRole(loginUser.get('userRoles'), roleTypes.STRATEGIST) ||
    Acl.checkRole(loginUser.get('userRoles'), roleTypes.CASE_MANAGER);
  const isRequiredToFill = booking && loginUser && isTutorOrStudent;

  if (isRequiredToFill) {
    const key = `feedback-modal-${booking && booking.get('id')}-${booking && booking.get('recipientUserId')}-${
      booking && booking.get('otherUserId')
    }`;
    return <FeedbackModals key={key} {...props} />;
  }

  const dueReport = incompleteReports.find((x) => moment(x.end) <= lapsedBillingPeriod);
  if (dueReport) {
    setActiveSessionReport(dueReport.eventId.toString());
  } else if (!isReportReminderVisible) {
    showIncompleteReportBanner();
  }

  // Render nothing if no bookings or login user.
  return null;
}

FeedbackContainer.propTypes = {
  loginUser: ImmutablePropTypes.map,
  booking: ImmutablePropTypes.map,
  incompleteReports: PropTypes.array,
  lapsedBillingPeriod: PropTypes.instanceOf(moment),
  showIncompleteReportBanner: PropTypes.func,
  setActiveSessionReport: PropTypes.func,
  isReportReminderVisible: PropTypes.bool,
};

export default connect(
  (state) => ({
    loginUser: selectUserProfile(state),
    incompleteReports: getAllIncompleteReports(state),
    lapsedBillingPeriod: getLapsedBillingPeriod(state),
    booking:
      getNextBookingRequiringReport(state) ||
      getNextSessionRequiringFeedback(state) ||
      getNextLessonRequiringFeedback(state) ||
      getStrategistOrEduCoSessionFeedback(state),
    reasonsForAbsence: getReasonsForAbsenceByRole(state),
    isReportReminderVisible: getShowIncompleteReportReminder(state),
  }),
  (dispatch) => ({
    submitAbsence: showPageLoader((request) => dispatch(submitAbsence(request)), dispatch),
    submitAutoComplete: (eventId) => dispatch(autoCompleteBooking(eventId)),
    submitSessionReport: (request) => dispatch(submitSessionReport(request)),
    submitSessionFeedback: showPageLoader((request) => dispatch(submitSessionFeedback(request)), dispatch),
    completeAttendanceBooking: (data) => dispatch(completeAttendanceBooking(data)),
    setActiveSessionReport: (eventId) => dispatch(updateMeta(componentKeys.ACTIVE_SESSION_REPORT, eventId)),
    showIncompleteReportBanner: () => dispatch(showIncompleteReportBanner()),
    fetchReasonsForAbsence: (eventId) => dispatch(fetchReasonsForAbsence(eventId)),
    createSessionFeedbackReport: (request) => dispatch(createSessionFeedbackReport(request)),
  }),
)(FeedbackContainer);
