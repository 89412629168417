import React, { useContext, useMemo } from 'react';
import TextCell from '../TextCell';
import DropdownMenu from '../../Dropdown/DropdownMenu';
import { AppTrackerContext } from '../../viewController';
import { ApplicationRound, Application } from '../../type';
import {
  getRoundsByRegion,
  getCurrentRegion,
  mapRoundValue,
  mapApplicationRegion,
  getApplicationYearsFromString,
  isXRegion,
} from '../../util';
import { useSelector } from 'react-redux';
import { getYearOfApplication } from 'selectors/profile';
import { roleTypes } from '@crimson-education/common-config';
import { getViewableUser } from 'selectors/meta';
import { DateTime } from 'luxon';
import { useIsAccessibleStaff, useIsStudent, useRequesterUserId } from '../../helper';
import { unionBy } from 'lodash';
type RoundProps = {
  round: string | null;
  application: Application;
};

const Round = ({ round, application }: RoundProps) => {
  const { controller, state } = useContext(AppTrackerContext);
  const universityDeadlines = application.universityData?.deadlines;
  const hasDeadlineData = universityDeadlines && !!universityDeadlines.length;
  const userId = useSelector(getViewableUser);
  const isStudent = useIsStudent();
  const isAccessibleStaff = useIsAccessibleStaff();
  const yearOfApplication = useSelector(getYearOfApplication(userId, roleTypes.STUDENT));
  const isUKApplication = isXRegion(application, 'UK');
  const requesterUserId = useRequesterUserId();
  const onClickItem = async (value: string) => {
    const payload = { round: value as ApplicationRound };
    if (hasDeadlineData) {
      const deadline = universityDeadlines.find((d) => d.round === value);
      const years = getApplicationYearsFromString(yearOfApplication);
      if (deadline && years?.length === 2) {
        // Generate Deadline Here based on Student Application Year
        const parsedDeadline = DateTime.fromISO(deadline.deadline);
        let adjustedDeadline: string;
        // If deadline is in the second half of the year, it should be in the initial application year
        // otherwise it should be in the ending application year
        if (parsedDeadline.month > 6) {
          adjustedDeadline = parsedDeadline.set({ year: years[0] }).toISO();
        } else {
          adjustedDeadline = parsedDeadline.set({ year: years[1] }).toISO();
        }
        Object.assign(payload, { deadline: adjustedDeadline, deadlineType: 'university_deadline' });
      }
    }
    await controller.updateApplication(application.id, payload, { updatedBy: requesterUserId });
  };
  const currentRegion = useMemo(() => getCurrentRegion(state.tabValue, state.listRegion, state.trackerRegion), [
    state.tabValue,
    state.listRegion,
    state.trackerRegion,
  ]);
  const roundDataFromUniversityDeadlines = useMemo(
    () =>
      hasDeadlineData
        ? universityDeadlines.map((o) => ({
            label: mapRoundValue(o.round, currentRegion),
            value: o.round,
          }))
        : [],
    [universityDeadlines, currentRegion, hasDeadlineData],
  );
  const defaultRoundData = useMemo(() => getRoundsByRegion(currentRegion), [currentRegion]);
  const roundData = useMemo(() => {
    if (isUKApplication && hasDeadlineData) {
      return unionBy(roundDataFromUniversityDeadlines, defaultRoundData, 'value');
    }
    if (hasDeadlineData) return roundDataFromUniversityDeadlines;
    return defaultRoundData;
  }, [defaultRoundData, hasDeadlineData, roundDataFromUniversityDeadlines, isUKApplication]);
  const isApplying = application.group === 'APPLYING';
  const getMenuItemStyleByIndex = (idx: number) => {
    return idx === roundDataFromUniversityDeadlines.length - 1
      ? ({ borderBottom: '1px solid rgb(224,224,224)' } as React.CSSProperties)
      : {};
  };
  return (
    <TextCell textColor={!!round ? undefined : '#A9ACC0'}>
      <DropdownMenu
        preOpenDropdown={
          isApplying ? () => controller.checkIfEditable(isStudent, isAccessibleStaff, application) : undefined
        }
        data={roundData}
        onClickItem={onClickItem}
        getMenuItemStyleByIndex={isUKApplication ? getMenuItemStyleByIndex : undefined}
      >
        {round ? mapRoundValue(round, mapApplicationRegion(application)) : 'Select'}
      </DropdownMenu>
    </TextCell>
  );
};
export default Round;
