import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Acl, permissionTypes } from '@crimson-education/common-config';
import { submitInvoiceById, markInvoiceAsPaidById, deleteInvoiceById } from 'ducks/invoicing';
import { updateMeta } from 'ducks/meta';
import { getSelectedInvoice } from 'selectors/invoicing';
import { getLoginUser } from 'selectors/user';
import componentKeys from 'constants/componentKeys';
import { getMetaItem, getInvoiceFilter, selectUserPermissions } from 'selectors/meta';
import { withAppContext } from 'components/enhancers/AppContext';
import InvoiceDetails from './InvoiceDetails';

function InvoiceDetailsContainer(props) {
  const {
    invoice,
    loginUser,
    fetched,
    submitInvoiceById,
    markInvoiceAsPaidById,
    deleteInvoiceById,
    submitting,
    paying,
    deleting,
    loginUserPermissions,
  } = props;

  return (
    <InvoiceDetails
      {...props}
      invoice={invoice}
      loginUserTimezone={loginUser.get('timezone')}
      isLoading={!fetched || submitting || paying || deleting}
      submitInvoice={submitInvoiceById}
      markInvoiceAsPaid={markInvoiceAsPaidById}
      deleteInvoice={deleteInvoiceById}
      showMarkPaid={Acl.checkPermission(loginUserPermissions, permissionTypes.MARK_INVOICE_AS_PAID)}
      showSubmit={Acl.checkPermission(loginUserPermissions, permissionTypes.SUBMIT_INVOICE)}
      showDelete={Acl.checkPermission(loginUserPermissions, permissionTypes.DELETE_INVOICE)}
    />
  );
}

InvoiceDetailsContainer.propTypes = {
  invoice: PropTypes.object,
  submitInvoiceById: PropTypes.func.isRequired,
  markInvoiceAsPaidById: PropTypes.func.isRequired,
  deleteInvoiceById: PropTypes.func.isRequired,
  loginUser: PropTypes.object,
  fetched: PropTypes.bool,
  submitting: PropTypes.bool,
  paying: PropTypes.bool,
  deleting: PropTypes.bool,
  loginUserPermissions: PropTypes.array,
};

InvoiceDetailsContainer.defaultProps = {
  invoice: null,
};

const mapStateToProps = (state) => ({
  loginUser: getLoginUser(state),
  fetched: getMetaItem(componentKeys.INVOICE_FETCHED)(state),
  submitting: getMetaItem(componentKeys.INVOICE_SUBMITTING)(state),
  paying: getMetaItem(componentKeys.INVOICE_PAYING)(state),
  deleting: getMetaItem(componentKeys.INVOICE_SUBMITTING)(state),
  loginUserPermissions: selectUserPermissions(state),
  invoice: getSelectedInvoice(state),
  isFetching: getMetaItem(componentKeys.INVOICE_FETCHING_LOAD)(state),
  filter: getInvoiceFilter(state),
});

const mapDispatchToProps = (dispatch) => ({
  submitInvoiceById: (id) => dispatch(submitInvoiceById(id)),
  markInvoiceAsPaidById: (id, filter) => dispatch(markInvoiceAsPaidById(id, filter)),
  deleteInvoiceById: (id) => dispatch(deleteInvoiceById(id)),
  clearSelectedInvoiceId: () => dispatch(updateMeta(componentKeys.INVOICE_SELECTED_ID, null)),
});

export default compose(withAppContext, connect(mapStateToProps, mapDispatchToProps))(InvoiceDetailsContainer);
