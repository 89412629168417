import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/molecules/Modal';
import autoBind from 'auto-bind';

import css from './styles.scss';

export default class RemovePeopleConfirmModal extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  onSubmit() {
    this.props.onRemovePeople();
  }
  render() {
    const { isOpen, onClose } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        title="Remove from Group"
        cancelLabel="close"
        onSubmit={this.onSubmit}
        submitText="REMOVE"
        onSubmitDataTestId="addPeopleButton"
        onSecondarySubmit={onClose}
        secondarySubmitText="CANCEL"
        onClose={onClose}
        className={css.modalStyle}
        buttonStyle={css.buttonCss}
        secondButtonStyle={css.secondButtonCss}
        customButtonGroupStyle={css.customButtonGroup}
        customHeaderStyle={css.headerStyle}
        secondButtonTextStyle={css.secondButtonText}
      >
        <div className={css.mainContent}>
          <span>
            Are you sure you want to remove this person from the conversation? They will no longer be able to send or
            receive new messages.
          </span>
        </div>
      </Modal>
    );
  }
}

RemovePeopleConfirmModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onRemovePeople: PropTypes.func,
};
