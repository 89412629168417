import React from 'react';
import { components } from 'react-select';

import Close from '@material-ui/icons/Close';
import css from './style.scss';

export default function MultiValueRemove(props) {
  return (
    <components.MultiValueRemove {...props} className={css.closeIcon}>
      <Close className={css.closeIcon} style={{ height: '1.6rem', width: '1.6rem' }} />
    </components.MultiValueRemove>
  );
}
