import Immutable from 'immutable';
import createReducer from 'utils/createReducer';
import { ADD_ENTITIES, addEntitiesWithNormalisation } from 'ducks/normalizr';
import { resourceEntity } from 'schema';
import googleConnector from 'graphql/api/google';
import { fetchResourcesForSession } from './sessionFiles.js';

const initialState = new Immutable.Map();

export default createReducer(initialState, {
  [ADD_ENTITIES]: (state, action) => {
    return state.mergeDeep(action.payload.entities.resource);
  },
});

export function fetchResourcesBySubjectId(subjectId) {
  return async (dispatch) => {
    const result = await googleConnector.getResourcesBySubjectId(subjectId);
    const resources = result.getResourcesBySubjectId;
    dispatch(addEntitiesWithNormalisation(resources, [resourceEntity]));
  };
}

export function addResourceToSession(resourceId, sessionId) {
  return async (dispatch) => {
    await googleConnector.addResourceToSession(resourceId, sessionId);
    dispatch(fetchResourcesForSession(sessionId));
  };
}
