import { createSelector } from 'reselect';
import Immutable from 'immutable';
import componentKeys from 'constants/componentKeys';

const meta = (state) => state.get('meta');

export const getRoutingData = () => window.location.pathname;

export const getMeta = createSelector(meta, (meta) => meta);

export const getMetaItem = (item) => {
  return createSelector(meta, (meta) => meta.toJS()[item]);
};

export const getAppLoginUser = createSelector(meta, (meta) => meta.get(componentKeys.APP_LOGIN_USER));

export const getModalState = createSelector(meta, (meta) => meta.get(componentKeys.MODAL_STATE));

export const getBookingErrors = createSelector(meta, (meta) => meta.get(componentKeys.MODAL_ERROR));

export const getFormState = createSelector(meta, (meta) => meta.get(componentKeys.FORM_STATE));

export const selectUserProfile = createSelector(meta, (meta) => meta.get(componentKeys.USER_PROFILE));

export const selectUserId = createSelector(selectUserProfile, (profile) => profile?.get('userId'));

export const selectUserPermissionInfo = createSelector(selectUserProfile, (profile) =>
  profile ? profile.get('permissionInfo').toArray() : undefined,
);

export const selectUserRoleInfo = createSelector(selectUserProfile, (profile) =>
  profile ? profile.get('roleInfo').toArray() : undefined,
);

/**
 * @deprecated use selectUserPermissionInfo instead.
 */
export const selectUserPermissions = createSelector(selectUserProfile, (profile) =>
  profile ? profile.get('permissions').toArray() : undefined,
);

/**
 * @deprecated use selectUserRoleInfo[*].roleId instead.
 */
export const selectUserRoles = createSelector(selectUserProfile, (profile) =>
  profile ? profile.get('userRoles').toArray() : undefined,
);

export const getCalScroll = createSelector(meta, (meta) => meta.get(componentKeys.CALENDAR_SCROLL));

export const getSelectedDay = createSelector(meta, (meta) => meta.get(componentKeys.SELECTED_DAY));

export const getAppViewedUser = createSelector(meta, (meta) => meta.get(componentKeys.APP_VIEWED_USER));

export const getTasksListUser = createSelector(meta, (meta) => meta.get(componentKeys.TASKS_LIST_USER));

export const getSessionSelectedId = createSelector(meta, (meta) => meta.get(componentKeys.SESSION_SELECTED_ID));

export const getAllStudentInterests = createSelector(meta, (meta) => meta.get(componentKeys.STUDENT_INTERESTS).toJS());

export const getAllTutorExpertise = createSelector(meta, (meta) => meta.get(componentKeys.TUTOR_EXPERTISE).toJS());

export const getUnavailable = createSelector(meta, (meta) => meta.get(componentKeys.UNAVAILABILITIES));

export const getByComponentKey = (componentKey) => createSelector(meta, (meta) => meta.get(componentKey));

export const getOurPeopleFilter = createSelector(meta, (meta) => meta.get(componentKeys.OUR_PEOPLE_FILTER).toJS());

export const getFilteredQuery = createSelector(meta, (meta) => meta.get(componentKeys.FILTERED_QUERY));

export const getBookingWithFilter = createSelector(meta, (meta) => meta.get(componentKeys.BOOKING_WITH_FILTER).toJS());

export const getBookingForFilter = createSelector(meta, (meta) => meta.get(componentKeys.BOOKING_FOR_FILTER));

export const getInvoiceFilter = createSelector(meta, (meta) => meta.get(componentKeys.INVOICE_FILTER).toJS());

export const getZendeskLoginUrl = createSelector(meta, (meta) => meta.get(componentKeys.ZENDESK_LOGIN_URL));

export const getCachedContactListForFilter = createSelector(
  [getByComponentKey(componentKeys.MESSAGE_CONTACT_FILTER), getByComponentKey(componentKeys.CONTACT_LIST_CACHE)],
  (filter, cache) => cache.get(filter, new Immutable.Map()),
);

export const getCachedContactListPageInfo = createSelector(getCachedContactListForFilter, (cache) =>
  cache.get('pageInfo', new Immutable.Map({ endCursor: undefined, hasNextPage: true })),
);

export const getContactsForFilter = createSelector(getCachedContactListForFilter, (cache) =>
  cache.get('contacts', new Immutable.OrderedSet()),
);

export const getBookingWithFetched = createSelector(meta, (meta) => meta.get(componentKeys.BOOKING_WITH_FETCHED));

export const getUsersFetched = createSelector(meta, (meta) => meta.get(componentKeys.USERS_FETCHED));

export const isPageLoading = createSelector(meta, (meta) => meta.get(componentKeys.IS_PAGE_LOADING));

export const getThunksRemaining = createSelector(meta, (meta) => meta.get(componentKeys.THUNKS_REMAINING));

export const getClientsFetched = createSelector(meta, (meta) => meta.get(componentKeys.CLIENTS_FETCHED));

export const getLessonsFetched = createSelector(meta, (meta) => meta.get(componentKeys.LESSONS_FETCHED));

export const getPackageItemsFetched = createSelector(meta, (meta) => meta.get(componentKeys.PACKAGE_ITEMS_FETCHED));

export const getOurPeopleSearch = () => {
  return createSelector(meta, (meta) => meta.get(componentKeys.OUR_PEOPLE_SEARCH));
};

export const getFilesUploading = createSelector(meta, (meta) => meta.get(componentKeys.FILES_UPLOADING));

export const getExamListFetched = createSelector(meta, (meta) => meta.get(componentKeys.EXAM_LIST_FETCHED));

export const getRoutePathname = createSelector(meta, (meta) => meta.get(componentKeys.ROUTE_PATHNAME));

export const getCalendarDays = createSelector(meta, (meta) => meta.get(componentKeys.CALENDAR_DAYS));

export const isUserOnline = createSelector(meta, (meta) => meta.get(componentKeys.IS_USER_ONLINE));

// BOOKING WITH
export const getBookingWithPagination = createSelector(getByComponentKey(componentKeys.BOOKING_WITH_CACHE), (cache) =>
  cache.get('pagination'),
);

export const getBookingForPagination = createSelector(getByComponentKey(componentKeys.BOOKING_FOR_CACHE), (cache) =>
  cache.get('pagination'),
);

export const getAssignableUsersPagination = createSelector(
  getByComponentKey(componentKeys.ASSIGNABLE_USERS_CACHE),
  (cache) => cache.get('pagination'),
);

export const getBookingWithUsers = createSelector(getByComponentKey(componentKeys.BOOKING_WITH_CACHE), (cache) =>
  cache.get('users'),
);

export const getBookingForUsers = createSelector(getByComponentKey(componentKeys.BOOKING_FOR_CACHE), (cache) =>
  cache.get('users'),
);

export const getAssignableUsers = createSelector(getByComponentKey(componentKeys.ASSIGNABLE_USERS_CACHE), (cache) =>
  cache.get('users'),
);

export const getViewableUser = createSelector([selectUserId, getAppViewedUser], (loggedInUser, userViewing) => {
  return userViewing || loggedInUser;
});

export const getSelectedEclActivityId = createSelector(meta, (meta) =>
  meta.get(componentKeys.ECL_SELECTED_ACTIVITY_ID),
);

export const getSelectedApplicationId = createSelector(meta, (meta) => meta.get(componentKeys.APPLICATION_SELECTED_ID));

export const getApplicationsFetched = createSelector(meta, (meta) => meta.get(componentKeys.APPLICATIONS_FETCHED));

export const getLapsedBillingPeriod = createSelector(meta, (meta) => meta.get(componentKeys.LAPSED_BILLING_PERIOD));

export const getNextBillingPeriod = createSelector(meta, (meta) => meta.get(componentKeys.NEXT_BILLING_PERIOD));

export const getActiveSessionReport = createSelector(meta, (meta) => meta.get(componentKeys.ACTIVE_SESSION_REPORT));

export const getShowIncompleteReportReminder = createSelector(meta, (meta) =>
  meta.get(componentKeys.SHOW_INCOMPLETE_REPORT_REMINDER),
);

export const getReasonsForAbsence = createSelector(meta, (meta) => meta.get(componentKeys.REASONS_FOR_ABSENCE));

export const getLoadedRoadmapTemplate = createSelector(meta, (meta) => meta.get(componentKeys.LOADED_ROADMAP_TEMPLATE));

export const getIsRoadmapBulkDeleteMode = createSelector(meta, (meta) =>
  meta.get(componentKeys.IS_ROADMAP_BULK_DELETE_MODE),
);

export const getIsRoadmapFetched = createSelector(meta, (meta) => meta.get(componentKeys.ROADMAP_FETCHED));

export const getIsHeaderHidden = createSelector(meta, (meta) => meta.get(componentKeys.IS_HEADER_HIDDEN));

export const getSessionReport = createSelector(meta, (meta) => meta.get(componentKeys.SESSION_FEEDBACK_REPORT));

export const getFilesUploaded = createSelector(meta, (meta) => meta.get(componentKeys.FILE_UPLOADED));

export const getUsersFetchedCount = createSelector(meta, (meta) => meta.get(componentKeys.USERS_FETCHED_COUNT));

export const getSelectedSpecificDay = createSelector(meta, (meta) => meta.get(componentKeys.SELECTED_SPECIFIC_DAY));

export const getShowDayCalendar = createSelector(meta, (meta) => meta.get(componentKeys.DAY_CALENDAR_VIEW));

export const getCachedEventIds = createSelector(meta, (meta) => meta.get(componentKeys.CACHED_EVENT_IDS).toJS());

export const getMyFriendsSearch = () => {
  return createSelector(meta, (meta) => meta.get(componentKeys.MY_FRIENDS_SEARCH));
};

export const getViewableRoles = () => {
  return createSelector(meta, (meta) => meta.get(componentKeys.VIEWABLE_ROLES));
};

export const getSelectedEclAwardId = createSelector(meta, (meta) => meta.get(componentKeys.ECL_SELECTED_AWARD_ID));

export const getSelectedAcademicId = createSelector(meta, (meta) => meta.get(componentKeys.GRADE_SELECTED_ACADEMIC_ID));
