import styled from 'styled-components';
import Button from '@material-ui/core/Button';

export const StyledButton = styled(Button)`
  background: #ffffff;
  border: 1px solid #464ac9;
  border-radius: 28px;
  min-width: 112px;
  height: 29px;
  gap: 2px;
  color: #464ac9;
  text-transform: capitalize;
  font-weight: 700;
  font-size: 11px;
  font-family: 'Montserrat';
  line-height: 26px;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
`;
