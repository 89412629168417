import Immutable from 'immutable';
import createReducer from 'utils/createReducer';
import attendanceApi from 'graphql/api/attendance';
import bookingApi from 'graphql/api/booking';
import eventApi from 'graphql/api/event';
import session from 'graphql/api/session';
import onlineClassroomApi from 'graphql/api/onlineClassroom';
import attendanceStatus from 'constants/attendanceStatus';
import { BookingStatusTypes, toasterMessages } from '@crimson-education/common-config';
import * as Logger from '@crimson-education/browser-logger';
import { getBookingAs, getLoginUser } from 'selectors/user';
import { getUserSessionStartingSoon, getEventUserIdForEvent } from 'selectors/booking';
import { processBookings } from 'utils/bookings';
import { findSessionStartingSoon } from 'utils/sessionVideo';
import { ADD_ENTITIES, addEntitiesWithNormalisation } from 'ducks/normalizr';
import { bookingEntity } from 'schema';
import { formatGraphQLRequestError } from 'utils/graphql';
import {
  modalSuccess,
  modalFail,
  modalLoading,
  resetModal,
  formLoading,
  formLoaded,
  updateMeta,
  formSuccess,
  formFail,
  formInfo,
} from 'ducks/meta';
import { updateBookingForUserAction, removeBookingForUserAction, changeBookingStatusForUserAction } from 'ducks/user';

import componentKeys from 'constants/componentKeys';
import { MessageEventType } from 'utils/MessageEventType';

const FETCH_BOOKINGS_SUCCEEDED = 'booking/FETCH_BOOKINGS_SUCCEEDED';
const FETCH_BOOKING_SUCCEEDED = 'booking/FETCH_BOOKING_SUCCEEDED';
const CREATE_BOOKING_SUCCEEDED = 'booking/CREATE_BOOKING_SUCCEEDED';
const CONFIRM_BOOKING_SUCCEEDED = 'booking/CONFIRM_BOOKING_SUCCEEDED';
const DECLINE_BOOKING_SUCCEEDED = 'booking/DECLINE_BOOKING_SUCCEEDED';
const COMPLETE_BOOKING_SUCCEEDED = 'booking/COMPLETE_BOOKING_SUCCEEDED';
const AUTOCOMPLETE_BOOKING_SUCCEEDED = 'booking/AUTOCOMPLETE_BOOKING_SUCCEEDED';
const CANCEL_BOOKING_SUCCEEDED = 'booking/CANCEL_BOOKING_SUCCEEDED';
const EDIT_BOOKING_SUCCEEDED = 'booking/EDIT_BOOKING_SUCCEEDED';
const UPDATE_SESSION_EMAIL_HISTORY = 'booking/UPDATE_SESSION_EMAIL_HISTORY';
const UPDATE_ZOOM_MEETING_URL = 'booking/UPDATE_ZOOM_MEETING_URL';
const UPDATE_OTHER_USER_ZOOM_URL = 'booking/UPDATE_OTHER_USER_ZOOM_URL';

const initialState = Immutable.fromJS({});

export const reducer = {
  [ADD_ENTITIES]: (state, action) => {
    const bookings = action.payload.entities.booking;
    return state.mergeDeep(processBookings(bookings));
  },
  [EDIT_BOOKING_SUCCEEDED]: (state, action) => {
    const { oldBooking, updatedBooking } = action.payload;
    const updatedValue = Immutable.fromJS(updatedBooking);
    let newState = state;
    newState = newState.delete(oldBooking.id.toString());
    newState = newState.set(updatedBooking.id.toString(), updatedValue);
    return newState;
  },
  [CANCEL_BOOKING_SUCCEEDED]: (state, action) => {
    const id = action.payload.toString();
    return state.delete(id);
  },
  [CONFIRM_BOOKING_SUCCEEDED]: (state, action) => {
    const id = action.payload.toString();
    const booking = state.get(id);
    if (!booking) return state;
    return state.setIn([id, 'status'], BookingStatusTypes.CONFIRMED);
  },
  [UPDATE_ZOOM_MEETING_URL]: (state, { payload: { id, zoomMeetingUrl } }) => {
    const bookingId = id.toString();
    const booking = state.get(bookingId.toString());
    if (!booking) return state;
    return state.setIn([bookingId, 'zoomMeetingUrl'], zoomMeetingUrl);
  },
  [UPDATE_OTHER_USER_ZOOM_URL]: (state, { payload: { id, otherUserZoomUrl } }) => {
    const bookingId = id.toString();
    const booking = state.get(bookingId.toString());
    if (!booking) return state;
    return state.setIn([bookingId, 'otherUserZoomUrl'], otherUserZoomUrl);
  },
  [DECLINE_BOOKING_SUCCEEDED]: (state, action) => {
    const id = action.payload.toString();
    const booking = state.get(id);
    if (!booking) return state;
    return state.setIn([id, 'status'], BookingStatusTypes.DECLINED);
  },
  [COMPLETE_BOOKING_SUCCEEDED]: (state, action) => {
    const id = action.payload.toString();
    const booking = state.get(id);
    if (!booking) return state;
    return state.setIn([id, 'status'], BookingStatusTypes.COMPLETED);
  },
  [AUTOCOMPLETE_BOOKING_SUCCEEDED]: (state, action) => {
    const id = action.payload.toString();
    const booking = state.get(id);
    if (!booking) return state;
    return state.setIn([id, 'status'], BookingStatusTypes.AUTO_COMPLETED);
  },
  [UPDATE_SESSION_EMAIL_HISTORY]: (state, action) => {
    const { id, sessionEmailHistory } = action.payload;
    return state.setIn([id, 'sessionEmailHistory'], sessionEmailHistory);
  },
};

export default createReducer(initialState, reducer);

export function fetchBookingsSucceeded(payload) {
  return { type: FETCH_BOOKINGS_SUCCEEDED, payload };
}

export function fetchBookingSucceeded(payload) {
  return { type: FETCH_BOOKING_SUCCEEDED, payload };
}

export function createBookingSucceeded(payload) {
  return { type: CREATE_BOOKING_SUCCEEDED, payload };
}

export function editBookingSucceeded(payload) {
  return { type: EDIT_BOOKING_SUCCEEDED, payload };
}

export function cancelBookingSucceeded(payload) {
  return { type: CANCEL_BOOKING_SUCCEEDED, payload };
}

export function confirmBookingSucceeded(payload) {
  return { type: CONFIRM_BOOKING_SUCCEEDED, payload };
}

export function updateZoomMeetingUrlForBooking(payload) {
  return { type: UPDATE_ZOOM_MEETING_URL, payload };
}

export function updateOtherUserZoomUrlForBooking(payload) {
  return { type: UPDATE_OTHER_USER_ZOOM_URL, payload };
}

export function declineBookingSucceeded(payload) {
  return { type: DECLINE_BOOKING_SUCCEEDED, payload };
}

export function completeBookingSucceeded(payload) {
  return { type: COMPLETE_BOOKING_SUCCEEDED, payload };
}

export function autocompleteBookingSucceeded(payload) {
  return { type: AUTOCOMPLETE_BOOKING_SUCCEEDED, payload };
}

export function updateSessionEmailHistory(payload) {
  return { type: UPDATE_SESSION_EMAIL_HISTORY, payload };
}

export function recordAttendance(request) {
  let attendance = request;
  if (attendance.absence) {
    attendance = Object.assign({}, request, {
      start: null,
      end: null,
      status: attendanceStatus.ABSENT,
    });
  }
  return attendanceApi.recordAttendance(attendance);
}

export function fetchBookingsRequiringFeedBack() {
  let isBookingsRequiringFeedbackFetching = false;
  return (dispatch) => {
    if (!isBookingsRequiringFeedbackFetching) {
      isBookingsRequiringFeedbackFetching = true;
      return eventApi.allEventsRequiringFeedback().then((events) => {
        isBookingsRequiringFeedbackFetching = false;
        dispatch(addEntitiesWithNormalisation(events, [bookingEntity]));
      });
    }
    return null;
  };
}

export function getBookings(from, to, userId) {
  let isBookingsFetching = false;
  return (dispatch) => {
    if (!isBookingsFetching) {
      isBookingsFetching = true;
      return eventApi
        .fetchEventsBetweenForUser(from, to, userId)
        .then((events) => {
          isBookingsFetching = false;
          dispatch(addEntitiesWithNormalisation(events, [bookingEntity]));
        })
        .catch((err) => {
          isBookingsFetching = false;
          return Promise.reject(err);
        });
    }
    return null;
  };
}

export function getBooking(eventId, userId) {
  return (dispatch) =>
    eventApi
      .fetchEventById(eventId, userId)
      .then((booking) => {
        dispatch(addEntitiesWithNormalisation(booking, bookingEntity));
      })
      .catch(() => {
        dispatch(modalFail(['Error fetching session']));
      });
}

export function getBookingForUser(eventId) {
  return async (dispatch, getState) => {
    const booking = await eventApi.fetchEventById(eventId);
    if (!booking) {
      return;
    }

    dispatch(addEntitiesWithNormalisation(booking, bookingEntity));
    dispatch(updateBookingForUserAction(getBookingAs(getState()), booking));
  };
}

export function fetchEventForSessionSummary(eventId) {
  return async (dispatch, getState) => {
    try {
      const basicBooking = await eventApi.fetchSessionParticipants(eventId);
      dispatch(addEntitiesWithNormalisation(basicBooking.event, bookingEntity));
    } finally {
      const userId = getEventUserIdForEvent(getState());
      dispatch(getBooking(eventId, userId));
    }
  };
}

export function makeBooking(booking) {
  return (dispatch, getState) => {
    dispatch(modalLoading());
    return bookingApi
      .makeBooking(booking)
      .then((res) => {
        if (res.errors || !res.booking) {
          dispatch(modalFail(res.errors || []));
        } else {
          dispatch(addEntitiesWithNormalisation(res.booking, bookingEntity));
          dispatch(updateBookingForUserAction(getBookingAs(getState()), res.booking));
          dispatch(updateMeta(componentKeys.SESSION_REDIRECT_ID, res.booking.id));
          dispatch(modalSuccess(toasterMessages.bookingCreated()));
        }
      })
      .catch((err) => {
        dispatch(modalFail([formatGraphQLRequestError(err)]));
      });
  };
}

export function makeRecurringBookings(booking) {
  return (dispatch, getState) => {
    dispatch(modalLoading());
    return bookingApi
      .makeRecurringBookings(booking)
      .then((res) => {
        if (res.errors || !res.bookings) {
          dispatch(modalFail(res.errors || []));
        } else {
          Logger.trackEvent({
            message: MessageEventType.CreateRecurringSessionSuccess,
          });
          for (const booking of res.bookings) {
            dispatch(addEntitiesWithNormalisation(booking, bookingEntity));
            dispatch(updateBookingForUserAction(getBookingAs(getState()), booking));
          }
          dispatch(updateMeta(componentKeys.SESSION_REDIRECT_ID, res.bookings[0].id));
          dispatch(modalSuccess(toasterMessages.bookingCreated()));
        }
      })
      .catch((err) => {
        dispatch(modalFail([formatGraphQLRequestError(err)]));
      });
  };
}

export function editBooking(booking) {
  return (dispatch, getState) => {
    dispatch(modalLoading());
    return bookingApi
      .editBooking(booking)
      .then((res) => {
        if (res.errors || !res.booking) {
          dispatch(modalFail(res.errors || []));
        } else {
          dispatch(editBookingSucceeded({ oldBooking: booking, updatedBooking: res.booking }));
          dispatch(removeBookingForUserAction(getBookingAs(getState()), booking.id));
          dispatch(updateBookingForUserAction(getBookingAs(getState()), res.booking));
          dispatch(updateMeta(componentKeys.SESSION_SELECTED_ID, res.booking.id));
          dispatch(modalSuccess(toasterMessages.bookingUpdated()));
        }
      })
      .catch((err) => {
        dispatch(modalFail([formatGraphQLRequestError(err)]));
      });
  };
}

export function cancelBooking(id, senderUserId, reasonId, comments, isPenaltySession) {
  return (dispatch, getState) => {
    dispatch(modalLoading());
    return bookingApi
      .cancelBooking(id, senderUserId, reasonId, comments, isPenaltySession)
      .then((res) => {
        if (res.errors || !res.booking) {
          dispatch(modalFail(res.errors || []));
        } else {
          dispatch(cancelBookingSucceeded(id));
          dispatch(removeBookingForUserAction(getBookingAs(getState()), id));
          dispatch(updateMeta(componentKeys.SESSION_SELECTED_ID, null));
          dispatch(modalSuccess(toasterMessages.bookingCancelled()));
        }
      })
      .catch((e) => {
        dispatch(modalFail(formatGraphQLRequestError(e)));
      });
  };
}

export function confirmBooking(id, participantUserId, isInThePast) {
  return async (dispatch, getState) => {
    dispatch(modalLoading());
    return bookingApi.confirmBooking(id, participantUserId).then((res) => {
      if (res.errors || !res.booking) {
        dispatch(modalFail(res.errors || []));
      } else {
        if (isInThePast) dispatch(fetchBookingsRequiringFeedBack());
        dispatch(changeBookingStatusForUserAction(getBookingAs(getState()), id, BookingStatusTypes.CONFIRMED));
        if (res.booking && res.booking.zoomMeetingUrl) {
          dispatch(updateZoomMeetingUrlForBooking({ id, zoomMeetingUrl: res.booking.zoomMeetingUrl }));
          dispatch(updateOtherUserZoomUrlForBooking({ id, otherUserZoomUrl: res.booking.otherUserZoomUrl }));
        }
        dispatch(confirmBookingSucceeded(id));
        dispatch(modalSuccess(toasterMessages.bookingConfirmed()));
      }
    });
  };
}

export function declineBooking(id, participantUserId, reason) {
  return (dispatch, getState) => {
    dispatch(modalLoading());
    return bookingApi.declineBooking(id, participantUserId, reason).then((res) => {
      if (res.errors || !res.booking) {
        dispatch(modalFail(res.errors || []));
      } else {
        dispatch(declineBookingSucceeded(id));
        dispatch(removeBookingForUserAction(getBookingAs(getState()), id));
        dispatch(modalSuccess(toasterMessages.bookingDeclined()));
      }
    });
  };
}

/**
 * Updates the state to reflect feedback provided
 * @param  {object} request
 * @return {object} dispatch action
 */
export function completeBooking(request) {
  return (dispatch, getState) => {
    dispatch(completeBookingSucceeded(request.eventId));
    dispatch(changeBookingStatusForUserAction(getBookingAs(getState()), request.eventId, BookingStatusTypes.COMPLETED));
  };
}

export function autoCompleteBooking(id) {
  return (dispatch) => {
    dispatch(formLoading());
    dispatch(modalLoading());
    return bookingApi.autoCompleteBooking(id).then(() => {
      dispatch(autocompleteBookingSucceeded(id));
      dispatch(resetModal());
      dispatch(formLoaded());
    });
  };
}

export function completeAttendanceBooking(attendanceData) {
  return (dispatch, getState) => {
    const loginUser = getLoginUser(getState());

    const request = {
      eventId: attendanceData.eventId,
      userId: loginUser.get('userId'),
      otherUserId: attendanceData.otherUserId,
      newStart: attendanceData.start,
      newEnd: attendanceData.end,
      status: attendanceData.status,
      reason: attendanceData.reason || '',
      absence: attendanceData.absence || false,
    };

    return attendanceApi.completeAttendanceBooking(request).then((data) => {
      dispatch(completeBooking(request));
      dispatch(updateMeta(componentKeys.ACTIVE_SESSION_REPORT, request.eventId.toString()));
      if (data.attendanceCompleteBooking === 'Duplicated Confirm') {
        dispatch(updateMeta(componentKeys.ACTIVE_SESSION_REPORT, null));
        dispatch(formFail('You have already confirmed whether this session has taken place'));
      }
    });
  };
}

export function submitAbsence(request) {
  const booking = Object.assign({}, request.booking, { absence: true });
  const absence = {
    eventId: request.eventId,
    absenceReasonId: request.reason,
    absenceReasonComment: request.comment,
    receiverId: request.receiverId,
  };
  return (dispatch) => {
    dispatch(completeBooking(booking));
    return attendanceApi
      .createSessionAbsence(absence)
      .then(() => {
        dispatch(modalSuccess(toasterMessages.feedbackNoSession()));
      })
      .catch((error) => dispatch(formFail(error.message)));
  };
}

export function sendSessionSummaryEmail({ eventId, studentId, emails }) {
  return (dispatch) => {
    return session
      .sendSessionSummaryEmail({ eventId, studentId, emails })
      .then(() => {
        Logger.trackEvent({ message: MessageEventType.SendSessionSummarySuccess, metadata: { eventId, emails } });
        dispatch(formSuccess(`Session summary has been emailed to ${emails.length} people.`));
      })
      .catch((err) => {
        Logger.reportError(err);
        Logger.trackEvent({ message: MessageEventType.SendSessionSummaryFail, metadata: { eventId, emails, err } });
        dispatch(formFail('An error has occured. Session summary has not been sent.'));
      });
  };
}

export function fetchSessionSummaryEmailHistory(eventId) {
  return (dispatch) => {
    return session
      .getSessionEmailHistory(eventId)
      .then((results) => {
        dispatch(
          updateSessionEmailHistory({ id: eventId.toString(), sessionEmailHistory: results.sessionEmailHistory }),
        );
      })
      .catch(() => dispatch(formFail('An error has occured. Fail to fetch email history.')));
  };
}

export function checkForSessionStartingSoon(bookings) {
  return (dispatch, getState) => {
    const sessionStartingSoon = findSessionStartingSoon(bookings);
    if (sessionStartingSoon) {
      const bookingStartingSoon = getUserSessionStartingSoon(getState());
      if (!bookingStartingSoon) {
        dispatch(addEntitiesWithNormalisation(sessionStartingSoon, bookingEntity));
      }
      const sessionPromptId = localStorage.getItem('sessionPromptId');
      if (sessionStartingSoon.id.toString() !== sessionPromptId) {
        dispatch(updateMeta(componentKeys.SESSION_PROMPT_MODAL_OPEN, true));
        localStorage.setItem('sessionPromptId', sessionStartingSoon.id);
      }
    }
  };
}

export function fetchRefreshedZoomMeeting(eventId, userId) {
  return async (dispatch) => {
    dispatch(modalLoading());
    return eventApi.fetchEventById(eventId, userId).then((booking) => {
      if (booking.errors || !booking.zoomMeetingUrl) {
        dispatch(modalFail(booking.errors || []));
      } else {
        if (booking && booking.zoomMeetingUrl) {
          dispatch(
            updateZoomMeetingUrlForBooking({
              id: eventId,
              zoomMeetingUrl: booking.zoomMeetingUrl,
            }),
          );
          dispatch(
            updateOtherUserZoomUrlForBooking({
              id: eventId,
              otherUserZoomUrl: booking.otherUserZoomUrl,
            }),
          );
        }
        dispatch(modalSuccess('Meeting link refreshed'));
      }
    });
  };
}

export function recreateZoomMeeting(eventId) {
  return async (dispatch) => {
    dispatch(modalLoading());
    return onlineClassroomApi.recreateZoomMeeting(eventId).then((res) => {
      if (res.errors || !res.recreateZoomMeeting) {
        dispatch(modalFail(res.errors || []));
      } else {
        if (res.recreateZoomMeeting && res.recreateZoomMeeting.startUrl) {
          dispatch(
            updateZoomMeetingUrlForBooking({
              id: eventId,
              zoomMeetingUrl: res.recreateZoomMeeting.startUrl,
            }),
          );
          dispatch(
            updateOtherUserZoomUrlForBooking({
              id: eventId,
              otherUserZoomUrl: res.recreateZoomMeeting.joinUrl,
            }),
          );
        }
        dispatch(modalSuccess('New meeting link generated'));
      }
    });
  };
}

export function fetchEventByAction(data) {
  const { action, userId, participantUserIds, eventId, itemId, history } = data;
  return async (dispatch) => {
    dispatch(modalLoading());
    return eventApi.fetchEventByAction(action, userId, participantUserIds, eventId, itemId).then((res) => {
      if (res.getEventByAction === 0) {
        dispatch(formInfo('There are no more previous/next sessions to go to'));
        return;
      }
      history.push(`/session/${res.getEventByAction}/agenda`);
    });
  };
}
