import React from 'react';
import { Route, Router } from 'react-router-dom';
import PropTypes from 'prop-types';
import AppRoute from './routes';
import { listenToHistory } from '../utils/routeTracker';

// eslint-disable-next-line react/display-name
function AppRouter({ history }) {
  // Track History for Analytics.
  listenToHistory(history);

  return (
    <Router history={history}>
      <Route component={AppRoute} />
    </Router>
  );
}

AppRouter.propTypes = {
  history: PropTypes.object,
};

export default AppRouter;
