import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/molecules/Modal';
import EventSummary from 'components/generic/FeedbackModals/EventSummary';
import Info from '@material-ui/icons/Info';
import css from './styles.scss';

export default function Confirm(props) {
  const SESSION_CONFIRM_HEADING = 'DID THIS SESSION TAKE PLACE?';
  const CORRECT_TEXT = 'Yes';
  const INCORRECT_TEXT = 'No';
  const INFO_TEXT = 'Please note this is a strategy session and no hours will be deducted from your package.';

  const { booking, personName, onConfirmCorrect, onConfirmIncorrect, isOpen } = props;

  return (
    <div>
      <Modal
        className={css.confirmModal}
        title={SESSION_CONFIRM_HEADING}
        isOpen={isOpen}
        onSubmit={onConfirmCorrect}
        submitText={CORRECT_TEXT}
        onSecondarySubmit={onConfirmIncorrect}
        secondarySubmitText={INCORRECT_TEXT}
      >
        <div>
          <EventSummary booking={booking} personName={personName} eventSummaryMode="HIDDEN" />
          <div className={css.info}>
            <Info className={css.infoIcon} />
            <span className={css.infoText}>{INFO_TEXT}</span>
          </div>
        </div>
      </Modal>
    </div>
  );
}

Confirm.propTypes = {
  isOpen: PropTypes.bool,
  booking: PropTypes.object.isRequired,
  personName: PropTypes.string.isRequired,
  onConfirmCorrect: PropTypes.func.isRequired,
  onConfirmIncorrect: PropTypes.func.isRequired,
};
