import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Acl, permissionTypes, roleTypes } from '@crimson-education/common-config';
import { getProfile } from 'utils/auth/profile';
import { toastr } from 'react-redux-toastr';
import isEqual from 'lodash/isEqual';
import FeedbackContainer from 'components/unique/Feedback';
import SessionPromptModal from 'components/organisms/SessionPromptModal';
import { withAppContext } from 'components/enhancers/AppContext';
import { VIEW_MESSAGES_PERIMSSION } from 'components/unique/Conversations/MessageBar';
import * as Logger from '@crimson-education/browser-logger';
import { handleEnter } from '../../../utils/accessibility';

const showToaster = (reports, days, doItLater, history, isTutor) => {
  let options = {};
  if (isTutor) {
    options = {
      timeOut: 0,
      removeOnHover: false,
      onHideComplete: () => {
        doItLater();
        toastr.removeByType('warning');
      },
      component: () => (
        <div
          role="button"
          tabIndex={0}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            doItLater();
            history.replace('/reports');
            toastr.removeByType('warning');
          }}
          onKeyDown={handleEnter(() => {
            doItLater();
            history.replace('/reports');
            toastr.removeByType('warning');
          })}
        >
          <span>
            You have{' '}
            <strong>
              {reports} incomplete session {reports > 1 ? 'reports' : 'report'}
            </strong>
            .
          </span>
          {days ? (
            <span>
              {' '}
              You have <strong>{days} days left</strong> to complete them before your next billing cycle.
            </span>
          ) : (
            <span> You need to complete them before your next billing cycle.</span>
          )}
        </div>
      ),
    };
  } else {
    options = {
      timeOut: 0,
      removeOnHover: false,
      onHideComplete: () => {
        doItLater();
      },
      component: () => (
        <div
          role="button"
          tabIndex={0}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            doItLater();
            history.replace('/reports');
            toastr.removeByType('warning');
          }}
          onKeyDown={() => {
            doItLater();
            history.replace('/reports');
            toastr.removeByType('warning');
          }}
        >
          <span>
            You have{' '}
            <strong>
              {reports} incomplete session {reports > 1 ? 'reports' : 'report'}
            </strong>
            .
          </span>
        </div>
      ),
    };
  }

  toastr.warning('', '', options);
};

class AppContainer extends Component {
  constructor(props) {
    super(props);
    this.rootRedirect = this.rootRedirect.bind(this);
  }

  componentDidMount() {
    const {
      fetchRoadmapIdByUserId,
      fetchExamIdInProgressByUserId,
      fetchCurriculum,
      loginUserPermissions,
      allCurriculum,
      loginUser,
      location,
      selectBookingAs,
      fetchAvailableExamsByUserId,
      fetchIncompleteReports,
      fetchIncompleteSessionReports,
      fetchStaffInfo,
    } = this.props;

    const userId = loginUser && loginUser.get('userId');
    if (!userId) {
      return;
    }

    selectBookingAs(userId, location.pathname.includes('calendar'));

    fetchIncompleteReports(userId);
    if (
      Acl.checkRole(loginUser.get('userRoles'), roleTypes.STRATEGIST) ||
      Acl.checkRole(loginUser.get('userRoles'), roleTypes.CASE_MANAGER)
    ) {
      fetchIncompleteSessionReports(userId);
      fetchStaffInfo(userId);
    }

    if (Acl.checkPermission(loginUserPermissions, permissionTypes.VIEW_EXAM_PREP)) {
      fetchExamIdInProgressByUserId(userId);
      fetchAvailableExamsByUserId(userId, true);
    }

    if (Acl.checkPermission(loginUserPermissions, permissionTypes.VIEW_ROADMAP)) {
      fetchRoadmapIdByUserId(userId);
    }

    if (!Object.keys(allCurriculum).length) {
      fetchCurriculum();
    }

    if (location.pathname === '/') {
      this.rootRedirect();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname && this.props.location.pathname === '/') {
      this.rootRedirect();
    }

    // Stop the banner from showing when user navigate to reports
    if (prevProps.location.pathname !== this.props.location.pathname && this.props.location.pathname === '/reports') {
      this.props.doIncompleteReportLater();
      toastr.removeByType('warning');
    }

    // Delay session banner logic
    if (
      'isReportReminderVisible' in this.props &&
      !this.props.isReportReminderVisible &&
      this.props.incompleteReports &&
      this.props.incompleteReports.length &&
      !isEqual(prevProps.incompleteReports, this.props.incompleteReports)
    ) {
      if (this.props.location.pathname === '/reports') {
        this.props.doIncompleteReportLater();
        toastr.removeByType('warning');
      } else {
        const loginUser = this.props.loginUser;
        let isTutor = true;
        if (
          Acl.checkRole(loginUser.get('userRoles'), roleTypes.STRATEGIST) ||
          Acl.checkRole(loginUser.get('userRoles'), roleTypes.CASE_MANAGER)
        ) {
          isTutor = false;
        }
        const nextBillingPeriod = this.props.nextBillingPeriod;
        if (!nextBillingPeriod) {
          showToaster(
            this.props.incompleteReports.length,
            null,
            this.props.doIncompleteReportLater,
            this.props.history,
            isTutor,
          );
        } else {
          const durationInDays = nextBillingPeriod.diff(moment(), 'days');
          showToaster(
            this.props.incompleteReports.length,
            durationInDays,
            this.props.doIncompleteReportLater,
            this.props.history,
            isTutor,
          );
        }
      }
    }

    if (this.props.location !== prevProps.location) {
      if (this.props.location.pathname === '/messages') {
        Logger.trackEventSinceLastAction({ message: 'click message route' });
      }
    }
  }

  async rootRedirect() {
    const { app, history, loginUserPermissions } = this.props;

    const profile = await getProfile();
    const roles = (profile && profile.roles) || [];

    // All users accessing '/' will be redirected to a relevant part of the app.
    if (app.isMobile && Acl.checkPermission(loginUserPermissions, VIEW_MESSAGES_PERIMSSION)) {
      history.replace('/messages');
    } else if (Acl.isExternalStudent(roles)) {
      history.replace('/my-profile');
    } else if (
      Acl.isSuperAdmin(roles) ||
      Acl.isCaseManager(roles) ||
      Acl.isOperationalSupport(roles) ||
      Acl.isReviewer(roles)
    ) {
      history.replace('/our-people');
    } else if (Acl.checkPermission(loginUserPermissions, permissionTypes.VIEW_CALENDAR)) {
      history.replace('/calendar');
    } else {
      history.replace('/my-profile');
    }
  }

  render() {
    const { children, location } = this.props;

    // Don't display route while being redirected.
    if (location.pathname === '/') {
      return null;
    }

    // Don't render if there is nothing to render.
    if (!children) {
      return null;
    }

    return (
      <div>
        {children}
        <FeedbackContainer />
        <SessionPromptModal history={this.props.history} location={location} />
      </div>
    );
  }
}

AppContainer.propTypes = {
  children: PropTypes.node,
  loginUser: PropTypes.object,
  loginUserPermissions: PropTypes.array,
  allCurriculum: PropTypes.object,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  fetchCurriculum: PropTypes.func.isRequired,
  fetchIncompleteReports: PropTypes.func.isRequired,
  fetchRoadmapIdByUserId: PropTypes.func.isRequired,
  fetchExamIdInProgressByUserId: PropTypes.func.isRequired,
  selectBookingAs: PropTypes.func.isRequired,
  fetchAvailableExamsByUserId: PropTypes.func.isRequired,
  incompleteReports: PropTypes.array,
  doIncompleteReportLater: PropTypes.func,
  isReportReminderVisible: PropTypes.bool,
  nextBillingPeriod: PropTypes.object,
  app: PropTypes.object,
  fetchIncompleteSessionReports: PropTypes.func.isRequired,
  fetchStaffInfo: PropTypes.func,
};

export default withAppContext(AppContainer);
