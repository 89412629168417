import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { selectUserProfile } from 'selectors/meta';

import { loadNotifications, clearNotifications, setNotificationRead } from 'ducks/notification';

import {
  getAllNotifications,
  getNewNotificationCount,
  getLatestNotificationKey,
  hasMoreNotifications,
} from 'selectors/notification';

import NotificationPage from './NotificationPage';

function NotificationPageContainer(props) {
  const { newNotificationCount, clearNotifications, setNotificationRead, lastLoadedKey, loadNotifications } = props;

  const loadKey = !newNotificationCount ? lastLoadedKey : '';

  return (
    <NotificationPage
      {...props}
      unseen={newNotificationCount}
      loadNotifications={() => loadNotifications(loadKey, loadKey ? 6 : 50)}
      clearNotifications={() => clearNotifications()}
      setNotificationRead={(notificationId) => setNotificationRead(notificationId)}
    />
  );
}

NotificationPageContainer.displayName = 'NotificationPageContainer';

NotificationPageContainer.propTypes = {
  newNotificationCount: PropTypes.number.isRequired,
  lastLoadedKey: PropTypes.string.isRequired,
  hasMore: PropTypes.bool.isRequired,
  notifications: ImmutablePropTypes.map.isRequired,
  loginUser: ImmutablePropTypes.map.isRequired,
  loadNotifications: PropTypes.func.isRequired,
  clearNotifications: PropTypes.func.isRequired,
  setNotificationRead: PropTypes.func.isRequired,
  showBackButton: PropTypes.bool,
};

export default connect(
  (state) => ({
    newNotificationCount: getNewNotificationCount(state),
    notifications: getAllNotifications(state),
    loginUser: selectUserProfile(state),
    lastLoadedKey: getLatestNotificationKey(state),
    hasMore: hasMoreNotifications(state),
  }),
  (dispatch) => ({
    loadNotifications: (...args) => dispatch(loadNotifications(...args)),
    clearNotifications: () => dispatch(clearNotifications()),
    setNotificationRead: (notificationId) => dispatch(setNotificationRead(notificationId)),
  }),
)(NotificationPageContainer);
