import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { uploadFile, uploadFileThroughSTS, abortMultipartUpload } from 'ducks/file';
import { getFilesUploading, getFilesUploaded } from 'selectors/meta';
import UploadFileModal from './UploadFileModal';

export default withRouter(
  connect(
    (state) => ({
      uploads: getFilesUploading(state).toArray(),
      uploaded: getFilesUploaded(state).toArray(),
    }),

    (dispatch) => ({
      uploadFile: (file, tag, refType, threadId) => dispatch(uploadFile(file, tag, refType, threadId)),
      uploadFileThroughSTS: (threadId, file, startTime) => dispatch(uploadFileThroughSTS(threadId, file, startTime)),
      abortMultipartUpload: (id) => dispatch(abortMultipartUpload(id)),
    }),
  )(UploadFileModal),
);
