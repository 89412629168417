import React from 'react';
import Avatar from '../Avatar';
import { Row } from '../../../index.style';
import { RemainingCount } from './index.style';

type AvatarGroupProps = {
  avatars: { firstName: string; lastName: string; avatar?: string; userId: string }[];
  maxCount?: number;
  size?: number;
};

const AvatarGroup = ({ avatars, maxCount = 5, size = 40 }: AvatarGroupProps) => {
  const moreThanMax = avatars.length > maxCount;
  const moreThanOne = avatars.length > 1;
  const borderStyle: React.CSSProperties = {
    border: '1px solid #fff',
  };
  return (
    <Row rowcenter>
      {avatars.slice(0, maxCount).map(({ firstName, lastName, avatar }, idx) => (
        <Avatar
          style={{
            width: size,
            height: size,
            translate: `-${0.5 * idx * 100}% 0`,
            ...(moreThanOne ? borderStyle : undefined),
          }}
          firstName={firstName}
          lastName={lastName}
          src={avatar}
          key={firstName + lastName + idx}
          size={size}
        />
      ))}
      {moreThanMax && (
        <RemainingCount
          style={{
            width: size,
            height: size,
            lineHeight: `${size}px`,
            translate: `-${0.5 * maxCount * 100}% 0`,
          }}
        >
          {`+${avatars.length - maxCount}`}
        </RemainingCount>
      )}
    </Row>
  );
};

export default AvatarGroup;
