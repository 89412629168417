import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import css from './styles.scss';

export default function InitialsAvatar({ className, colorIndex, initials, userId, dataTestId, style }) {
  let cIndex = colorIndex;
  // this line is for compatibility
  if (!userId) {
    return (
      <div data-test-id={dataTestId} style={style} className={classnames(className, css.initialsAvatar)}>
        {initials}
      </div>
    );
  }
  if (userId && !colorIndex) cIndex = (userId.charCodeAt(userId.length - 1) + 9) % 9;
  return (
    <div
      data-test-id={dataTestId}
      style={style}
      className={classnames(className, css.initialsAvatar, css[`avatarColor${cIndex}`])}
    >
      {initials}
    </div>
  );
}

InitialsAvatar.propTypes = {
  className: PropTypes.string,
  colorIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  userId: PropTypes.string,
  dataTestId: PropTypes.string,
  initials: PropTypes.string.isRequired,
  style: PropTypes.object,
};

InitialsAvatar.defaultProps = {
  colorIndex: 9,
};
