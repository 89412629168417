import React, { useMemo } from 'react';
import { StyledSector, StyledCircle } from './index.style';
import { getPathOfOneSector } from './helper';

const rectifyData = (data: DonutChartDataItem[]) => {
  let residual = 1;
  const res: ExtendedDonutChartDataItem[] = [];
  data.forEach((item, idx) => {
    const lastItem = res[idx - 1];
    const startPercent = lastItem?.endPercentage || 0;
    const diff = residual - item.percentage;
    if (diff < 0) {
      res.push({
        ...item,
        percentage: residual,
        startPercentage: startPercent,
        endPercentage: startPercent + residual,
      });
      residual = 0;
      return;
    }
    residual -= item.percentage;
    res.push({
      ...item,
      startPercentage: startPercent,
      endPercentage: startPercent + item.percentage,
    });
  });
  return res;
};

const renderCircle = (
  key: string,
  color: string,
  innerRadius: number,
  outerRadius: number,
  centerX: number,
  centerY: number,
  onClick?: () => void,
) => {
  return (
    <StyledCircle
      onClick={onClick}
      key={key}
      cx={centerX}
      cy={centerY}
      r={(innerRadius + outerRadius) / 2}
      fill={'none'}
      stroke={color}
      strokeWidth={outerRadius - innerRadius}
    />
  );
};

type DonutChartDataItem = {
  color: string;
  percentage: number;
  value: string;
};

type ExtendedDonutChartDataItem = DonutChartDataItem & {
  startPercentage: number;
  endPercentage: number;
};

type DonutChartProps<T extends DonutChartDataItem> = {
  data: T[];
  innerRadius: number;
  outerRadius: number;
  width: number;
  height: number;
  onClickSector?: (sectorValue: string) => void;
};

const DonutChart = <T extends DonutChartDataItem>({
  data,
  innerRadius,
  outerRadius,
  width,
  height,
  onClickSector,
}: DonutChartProps<T>) => {
  const centerX = width / 2;
  const centerY = height / 2;
  const rectifiedData: ExtendedDonutChartDataItem[] = useMemo(() => rectifyData(data), [data]);
  const _onClickSector = (value: string) => onClickSector && onClickSector(value);
  const renderSectors = () => {
    if (data.length === 0) {
      return renderCircle('donut-circle-empty', '#F9FAFB', innerRadius, outerRadius, centerX, centerY);
    }
    return rectifiedData.map(({ color, percentage, startPercentage, endPercentage, value }, idx) => {
      if (percentage === 1)
        return renderCircle('donut-circle-full', color, innerRadius, outerRadius, centerX, centerY, () =>
          _onClickSector(value),
        );
      return (
        <StyledSector
          onClick={() => _onClickSector(value)}
          key={'donut-graph-sector' + color + idx}
          d={getPathOfOneSector(startPercentage, endPercentage, innerRadius, outerRadius, centerX, centerY)}
          fill={color}
        />
      );
    });
  };
  return (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg">
      {renderCircle('donut-circle-placeholder', '#F9FAFB', innerRadius, outerRadius, centerX, centerY)}
      {renderSectors()}
    </svg>
  );
};

export default DonutChart;
