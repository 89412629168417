import { connect } from 'react-redux';
import { getAncestorsOfSubjectTree } from 'ducks/subjectCategory';
import { selectSubjectCategories } from 'selectors/subjectCategory';

import SubjectCard from './SubjectCard';

export default connect(
  (state) => ({
    subjectCategories: selectSubjectCategories(state),
  }),
  (dispatch) => ({
    getAncestorsOfSubjectTree: (ids) => dispatch(getAncestorsOfSubjectTree(ids)),
  }),
)(SubjectCard);
