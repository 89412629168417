import { createSelector } from 'reselect';
import moment from 'moment';
import { getUserWithId } from 'selectors/user';

export const progressReport = (state) => state.get('progressReport');
const state = (state) => state;

export const getProgressReportList = (studentId) =>
  createSelector(state, progressReport, (state, progressReport) => {
    if (progressReport.size) {
      const reports = progressReport.toList().filter((report) => {
        return studentId === report.get('student');
      });
      return reports
        .map((report) => {
          const userObject = getUserWithId(report.get('updatedBy'))(state);
          return {
            id: report.get('id'),
            startDate: moment(report.get('startDate')),
            endDate: moment(report.get('endDate')),
            status: report.get('status'),
            updatedAt: moment(report.get('updatedAt')),
            updatedBy: userObject.get('fullName') || report.get('updatedBy'),
          };
        })
        .sort((a, b) => {
          if (a.startDate.isBefore(b.startDate)) {
            return 1;
          }
          if (a.startDate.isAfter(b.startDate)) {
            return -1;
          }
          return 0;
        })
        .toJS();
    }

    return [];
  });

export const getProgressReportById = (reportId) =>
  createSelector(progressReport, (progressReport) => {
    if (progressReport.size) {
      return progressReport.get(reportId) ? progressReport.get(reportId).toJS() : null;
    }
    return null;
  });

export const getProgressReportHtmlById = (reportId) =>
  createSelector(progressReport, (progressReport) => {
    if (progressReport.size) {
      return progressReport.get(reportId) && progressReport.get(reportId).get('html')
        ? progressReport.get(reportId).get('html')
        : '';
    }
    return '';
  });
