/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import { Column } from 'components/molecules/Table';
import IconButton from 'components/molecules/IconButton';
import ReactTooltip from 'react-tooltip';
import ConfirmationModal from 'components/generic/ConfirmationModal';
import classNames from 'classnames';
import { columnNames } from '../columnMap';

import css from '../styles.scss';

function renderMessageButton(params, rowData) {
  return (
    <div className={css.friendAction}>
      <Link to={`/messages/${rowData.get('sessionId')}`}>
        <button className={classNames(css.commonButton, css.messageButton)}> Message</button>
      </Link>
      <a data-tip data-for={rowData.get('friendId')}>
        <IconButton className={css.editIcon} icon="MoreHoriz" />
      </a>
      <ReactTooltip id={rowData.get('friendId')} place="bottom" effect="solid" delayHide={1000} clickable>
        <ConfirmationModal
          onConfirm={() => params.processFriend(rowData.get('friendId'), 'BLOCK')}
          buttonClassName={css.noneStyleButton}
          buttonText="Unfriend"
          dataTestId="unFriendButton"
          actionText="Are you sure you want to unfriend this person? You can not message each other once done."
          header="Are you sure?"
          secondarySubmitText="Cancel"
        />
      </ReactTooltip>
    </div>
  );
}

function renderAcceptButton(params, rowData) {
  return (
    <div className={css.friendAction}>
      <button
        className={classNames(css.commonButton, css.acceptButton)}
        onClick={() => params.processFriend(rowData.get('userFriendId'), 'ACCEPT')}
      >
        {' '}
        Accept friend request
      </button>
      <a data-tip data-for={rowData.get('userFriendId')}>
        <IconButton className={css.editIcon} icon="MoreHoriz" />
      </a>
      <ReactTooltip id={rowData.get('userFriendId')} place="bottom" effect="solid" delayHide={1000} clickable>
        <ConfirmationModal
          onConfirm={() => params.processFriend(rowData.get('userFriendId'), 'IGNORE')}
          buttonClassName={css.noneStyleButton}
          buttonText="DELETE"
          dataTestId="deleteFriendButton"
          actionText="Are you sure you want to delete this friend request?"
          header="Are you sure?"
          secondarySubmitText="Cancel"
        />
      </ReactTooltip>
    </div>
  );
}

export default function FriendActionButton(params) {
  return (
    <Column
      key={columnNames.FriendActionButton}
      flex="2 1 0"
      name=""
      renderer={({ rowData }) => {
        const isFriend = rowData.get('friendStatus') === 'OK';
        if (isFriend) return renderMessageButton(params, rowData);
        return renderAcceptButton(params, rowData);
      }}
    />
  );
}
