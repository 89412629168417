import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';
import classnames from 'classnames';
import autoBind from 'auto-bind';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { MINUTES_BEFORE } from 'utils/sessionVideo';
import * as auth from '@crimson-education/common-config';
import copy from 'copy-to-clipboard';
import { Body, Caption } from 'components/atoms/typography';
import CountdownSimple from 'components/molecules/CountdownSimple';
import css from './styles.scss';
import { handleEnter } from '../../../utils/accessibility';

const {
  Acl: { checkRole },
  RoleTypes,
} = auth;
const config = getConfig();
const zoomDownload = !config.china ? 'https://zoom.us/download' : 'https://zoom.com.cn/download';

const zoomTest = !config.china ? 'https://zoom.us/test' : 'https://zoom.com.cn/test';

function renderMessage(isInProgress, hasEnded, sessionStart, onCountdownFinish) {
  if (hasEnded) {
    return <Body className={css.message}>This session has ended.</Body>;
  }
  if (isInProgress) {
    return <Body className={css.message}>This session is in progress...</Body>;
  }
  return (
    <Body className={css.message}>
      This session will be starting in <CountdownSimple end={moment(sessionStart)} bold onFinish={onCountdownFinish} />.
    </Body>
  );
}

function isLinkEnabled(start, end) {
  if (
    moment().isBetween(moment(start), moment(end)) ||
    moment(start).isBetween(moment(), moment().add(MINUTES_BEFORE, 'minutes'))
  ) {
    return true;
  }
  return false;
}

function getHostUser(participants, eventCreatorUserId) {
  const canHost = participants.filter(({ userRoles }) => !checkRole(userRoles, RoleTypes.STUDENT));

  let hostUser;

  if (canHost.length === 1) {
    [hostUser] = canHost;
  } else {
    // If more than one participant can host, use the creator of the event
    hostUser = participants.find((p) => p.userId === eventCreatorUserId);
  }
  return hostUser;
}

function getHostLink(session) {
  const hostStudent = session.participants
    .filter(({ userRoles }) => checkRole(userRoles, RoleTypes.STUDENT))
    .filter(({ userId }) => userId === session.creatorUserId);
  let hostLink;
  if (hostStudent.length > 0 && session.participantType === 'participant') {
    hostLink = session.zoomMeetingUrl;
  } else if (!hostStudent.length > 0 && session.participantType === 'creator') {
    hostLink = session.zoomMeetingUrl;
  } else {
    hostLink = session.otherUserZoomUrl;
  }
  return hostLink;
}

function getGuestLink(session) {
  const hostStudent = session.participants
    .filter(({ userRoles }) => checkRole(userRoles, RoleTypes.STUDENT))
    .filter(({ userId }) => userId === session.creatorUserId);
  let guestLink;
  if (hostStudent.length > 0 && session.participantType === 'participant') {
    guestLink = session.otherUserZoomUrl;
  } else if (!hostStudent.length > 0 && session.participantType === 'creator') {
    guestLink = session.otherUserZoomUrl;
  } else {
    guestLink = session.zoomMeetingUrl;
  }
  return guestLink;
}

export default class SessionVideoLink extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sessionHasStarted: false,
    };

    autoBind(this);
  }

  setSessionStart() {
    this.setState({ sessionHasStarted: true });
  }

  enableJoinCall() {
    this.props.fetchBookingAsEvents();
  }

  recreateZoomMeeting() {
    const { session, recreateZoomMeeting } = this.props;
    recreateZoomMeeting(session.id);
  }

  fetchRefreshedZoomMeeting() {
    const { session, fetchRefreshedZoomMeeting, loginUser } = this.props;
    fetchRefreshedZoomMeeting(session.id, loginUser.get('userId'));
  }

  render() {
    const { session, loginUser } = this.props;
    const hostUser = getHostUser(session.participants, session.creatorUserId);
    const isHost = hostUser.userId === loginUser.get('userId');
    const isStudent = checkRole(loginUser.get('userRoles'), RoleTypes.STUDENT);
    const hostLink = getHostLink(session);
    const guestLink = getGuestLink(session);
    const showLink = isLinkEnabled(session.start, session.end);
    const isInProgress = moment().isBetween(moment(session.start), moment(session.end)) || this.state.sessionHasStarted;
    const hasEnded = moment().isAfter(moment(session.end));

    return (
      <div
        className={classnames(css.session, {
          [css.inProgress]: isInProgress,
          [css.sessionVideoLink]: session.zoomMeetingUrl,
        })}
      >
        {!showLink && (
          <React.Fragment>
            <ReactTooltip
              id="sessionVideoTooltip"
              effect="solid"
              offset={{ top: -3 }}
              className={css.sessionVideoTooltip}
            />
            <ReactTooltip
              id="refreshZoomMeetingLinkTooltip"
              effect="solid"
              offset={{ top: -3 }}
              className={css.sessionVideoTooltip}
            />
          </React.Fragment>
        )}
        <div>
          <div className={css.messageContainer}>
            <i className={classnames('zmdi zmdi-timer', css.timerIcon)} />
            {renderMessage(isInProgress, hasEnded, session.start, this.setSessionStart)}
          </div>
          {!isStudent && session.zoomMeetingUrl && (
            <div>
              <i
                role="button"
                tabIndex={0}
                className={classnames('zmdi zmdi-share', css.shareLinkIcon)}
                onClick={() => copy(guestLink)}
                disabled={!showLink}
                onKeyDown={() => copy(guestLink)}
                style={{ fontSize: '1.2rem' }}
              >
                {' '}
                Copy Guest Link
              </i>
              <i
                role="button"
                tabIndex={0}
                className={classnames('zmdi zmdi-share', css.shareLinkIcon)}
                onClick={() => copy(hostLink)}
                disabled={!showLink}
                style={{ marginLeft: '10px', fontSize: '1.2rem' }}
                onKeyDown={() => copy(hostLink)}
              >
                {' '}
                Copy Host Link
              </i>
            </div>
          )}
        </div>
        {session.zoomMeetingUrl && (
          <React.Fragment>
            <div
              data-tip={`You can join this call ${MINUTES_BEFORE} minutes before the start time.`}
              data-for="sessionVideoTooltip"
              className={css.joinBtnCtn}
            >
              <CountdownSimple
                className={css.invisible}
                end={moment(session.start).subtract(MINUTES_BEFORE, 'minutes')}
                onFinish={this.enableJoinCall}
              />
              <button
                className={classnames(css.joinBtn, {
                  [css.inProgress]: isInProgress,
                })}
                disabled={!showLink || !session.zoomMeetingUrl}
                onClick={() => window.open(session.zoomMeetingUrl, '_blank')}
              >
                <i className={classnames('zmdi zmdi-videocam', css.videoIcon)} />
                <span>Join call</span>
              </button>
            </div>
            <div
              data-tip="Try refreshing when the zoom link is not working, and rejoin the call"
              data-for="refreshZoomMeetingLinkTooltip"
              className={css.refreshMeetingLink}
            >
              <i
                role="button"
                tabIndex={0}
                className={classnames('zmdi zmdi-refresh', css.refreshIcon)}
                onClick={isHost ? this.recreateZoomMeeting : this.fetchRefreshedZoomMeeting}
                onKeyDown={handleEnter(isHost ? this.recreateZoomMeeting : this.fetchRefreshedZoomMeeting)}
                disabled={!showLink}
              />
              <span>
                Refresh&nbsp;
                <sup>
                  <i
                    className={classnames('zmdi zmdi-info-outline', css.refreshSuperscript)}
                    title="Try refreshing when the zoom link is not working, and rejoin the call"
                  />
                </sup>
                <div className={css.addMobileText}>when the zoom link is not working</div>
              </span>
            </div>
            <Caption className={css.testLink}>
              Be ready for your session by downloading&nbsp;
              <a href={zoomDownload} target="_blank" rel="noopener noreferrer">
                Zoom
              </a>
              &nbsp;now. Try out a&nbsp;
              <a href={zoomTest} target="_blank" rel="noopener noreferrer">
                Test call
              </a>
              &nbsp; to make sure you&rsquo;re all set to go.
            </Caption>
          </React.Fragment>
        )}
      </div>
    );
  }
}

SessionVideoLink.propTypes = {
  session: PropTypes.object,
  fetchBookingAsEvents: PropTypes.func,
  loginUser: PropTypes.object,
  recreateZoomMeeting: PropTypes.func,
  fetchRefreshedZoomMeeting: PropTypes.func,
};
