import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import CheckCircle from '@material-ui/icons/CheckCircle';
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import InputText from 'components/molecules/InputText';
import Datepicker from 'components/molecules/Datepicker';
import Button from 'components/molecules/Button';
import InputWarningMessage from 'components/molecules/InputWarningMessage';
import IconButton from 'components/molecules/IconButton';
import RoadmapCategoryLabel from 'components/molecules/RoadmapCategoryLabel';
import moment from 'moment';
import transitions from 'components/atoms/behaviours/transitions.scss';
import { Body, Caption } from 'components/atoms/typography';

import { getPrettyDaysRemaining, isDateInWarningState } from '../utils';
import css from './styles.scss';
import { handleEnter } from '../../../../utils/accessibility';

export default class Task extends Component {
  constructor(props) {
    super(props);

    this.defaultRef = React.createRef();

    const { date, name } = props;

    this.state = {
      daysRemaining: date ? getPrettyDaysRemaining(moment(date)) : null,
      dateInWarningState: date ? isDateInWarningState(moment(date)) : false,
      name,
      isEditMode: false,
      isDatePickerOpened: false,
      isAllowedToSave: true,
      isTaskNameValid: true,
    };
  }

  componentDidMount() {
    const { inputTextRef, autoFocus } = this.props;
    inputTextRef && autoFocus && inputTextRef.current.select();
    if (autoFocus) {
      inputTextRef ? inputTextRef.current.select() : this.defaultRef.current.select();
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { name } = this.state;
    const { date } = newProps;

    const newState = {};

    if (newProps.name !== name && !this.state.isEditMode) {
      newState.name = newProps.name;
    }

    if (date !== this.props.date) {
      newState.daysRemaining = date ? getPrettyDaysRemaining(moment(date)) : null;
      newState.dateInWarningState = date ? isDateInWarningState(moment(date)) : false;
    }

    this.setState(newState);
  }

  onClickDetails = () => {
    const { onClickDetails } = this.props;
    onClickDetails();
  };

  onBlurName = () => {
    this.setState({ isEditMode: false });
    if (this.validateTaskName()) {
      this.onSaveNameChange();
    }
  };

  onFocusName = () => {
    this.setState({ isEditMode: true });
  };

  onDatePickerClose = () => {
    const { date } = this.props;
    if (!date) {
      this.setState({ isDatePickerOpened: false });
    }
  };

  onSaveNameChange = () => {
    const { name, isAllowedToSave } = this.state;
    const { onNameChange } = this.props;
    if (name !== this.props.name) {
      isAllowedToSave && onNameChange(name);
    }
  };

  onKeyDown = (event) => {
    const { onEnterDown, onDeleteTaskFromKeyBoard, onArrowUp, onArrowDown, inputTextRef } = this.props;

    const { name } = this.state;

    if (event.key === 'Enter') {
      event.preventDefault();
      if (this.validateTaskName()) {
        inputTextRef ? inputTextRef.current.blur() : this.defaultRef.current.blur();
        onEnterDown && onEnterDown();
      }
    } else if (event.key === 'Backspace' && name === '') {
      event.preventDefault();
      this.setState({ isAllowedToSave: false }, onDeleteTaskFromKeyBoard);
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      this.onSaveNameChange();
      onArrowUp && onArrowUp();
    } else if (event.key === 'ArrowDown') {
      event.preventDefault();
      this.onSaveNameChange();
      onArrowDown && onArrowDown();
    }
  };

  updateDate = (date) => {
    const { onDateChange } = this.props;

    this.setState({
      daysRemaining: date ? getPrettyDaysRemaining(moment(date)) : null,
      dateInWarningState: date ? isDateInWarningState(moment(date)) : false,
      isDatePickerOpened: false,
    });
    onDateChange(date);
  };

  updateName = (name) => {
    this.setState(({ isTaskNameValid }) => ({
      name,
      isTaskNameValid: isTaskNameValid || (!isTaskNameValid && name),
    }));
  };

  validateTaskName = () => {
    const { name } = this.state;
    if (name === '') {
      this.setState({ isTaskNameValid: false });
      return false;
    }
    return true;
  };

  render() {
    const {
      taskId,
      date,
      isComplete,
      mission,
      index,
      className,
      taskNameInputClassName,

      showDaysRemaining,
      onDeleteTask,
      onToggleComplete,
      isMissionColumnVisible,
      onClickTagMission,
      onClearMission,
      inputTextRef,
      showDateOnMobile,
      isCompactView,
      showDateOnCompact,
      canEdit,
      canDelete,
    } = this.props;

    const { name, daysRemaining, dateInWarningState, isEditMode, isTaskNameValid, isDatePickerOpened } = this.state;

    const daysRemainingText =
      isCompactView && showDateOnCompact
        ? `due ${date && date.format("DD MMM 'YY")} (${daysRemaining})`
        : daysRemaining || '';

    return (
      <div>
        <div
          className={classnames(css.taskContainer, className, {
            [css.withMission]: isMissionColumnVisible,
            [css.taskContainerShowDate]: showDateOnMobile && isCompactView,
            [css.taskContainerFull]: !isCompactView,
            [css.taskContainerCompact]: isCompactView,
          })}
        >
          {/* CheckBox */}
          <div
            data-test-id={`taskToggleComplete-${index}`}
            role="checkbox"
            aria-checked={isComplete}
            tabIndex={0}
            data-ga-label="Toggle Complete"
            className={classnames(css.checkBoxColumn, css.circleContainer, { [css.noselect]: !canEdit })}
            onClick={canEdit ? onToggleComplete : undefined}
            onKeyDown={canEdit ? handleEnter(onToggleComplete) : undefined}
          >
            {isComplete ? <CheckCircle className={css.ticked} /> : <RadioButtonUnchecked className={css.unticked} />}
          </div>

          {/* Name */}
          <div className={classnames(css.taskNameColumn, css.nameContainer)}>
            <InputText
              forwardedRef={inputTextRef || this.defaultRef}
              dataTestId={`taskNameInput-${index}`}
              className={classnames(css.taskNameInput, {
                [css.taskNameInputCompact]: isCompactView,
                [css.nameComplete]: isComplete,
                [css.withDate]: !!date && !isCompactView && !isComplete,
                [css.withDateCompact]: !!date && isCompactView,
                [css.notEditMode]: isCompactView && !isEditMode,
                [css.taskNameInvalid]: !isTaskNameValid,
                [taskNameInputClassName]: taskNameInputClassName,
              })}
              charLimit={254}
              value={name}
              onChange={(event) => this.updateName(event.target.value)}
              onKeyDown={this.onKeyDown}
              onBlur={this.onBlurName}
              onFocus={this.onFocusName}
              canEdit={canEdit}
            />
            {date && !isEditMode && (
              <Caption
                className={classnames(transitions.fadeContentUp, css.daysRemaining, {
                  [css.warningDaysLeft]: dateInWarningState,
                  [css.daysRemainingFull]: !isCompactView,
                  [css.daysRemainingComplete]: isComplete,
                })}
              >
                {daysRemainingText}
              </Caption>
            )}
            {canEdit && !showDateOnMobile && (
              <IconButton
                dataTestId={`taskDetailsButton-${index}`}
                className={isCompactView ? css.detailsButtonCompact : css.detailsButtonDesktop}
                onClick={this.onClickDetails}
                icon="ChevronRight"
              />
            )}
          </div>

          {/* Date */}
          <div className={!showDateOnMobile && isCompactView ? css.taskDateColumnCompact : css.taskDateColumn}>
            {canEdit && (
              <>
                {date || isDatePickerOpened ? (
                  <div className={css.dateGroup}>
                    {showDaysRemaining && daysRemaining && !isComplete && (
                      <div className={css.daysRemainingContainer}>
                        <Body
                          className={classnames(css.daysRemainingText, { [css.warningDaysLeft]: dateInWarningState })}
                        >
                          {daysRemaining}
                        </Body>
                      </div>
                    )}
                    <Datepicker
                      dataTestId={`taskDatepicker-${index}`}
                      onChange={this.updateDate}
                      date={date}
                      simpleInputStyle
                      isDefaultEmpty={!date}
                      allowEmpty
                      onClose={this.onDatePickerClose}
                    />
                    <IconButton
                      dataGaLabel="Clear Date"
                      dataTestId={`taskDateClear-${index}`}
                      className={classnames(css.iconButton, css.clearValueButton)}
                      onClick={() => this.updateDate(null)}
                      icon="Close"
                    />
                  </div>
                ) : (
                  <Button
                    dataTestId={`taskAddDueDate-${index}`}
                    secondary
                    onClick={() => this.setState({ isDatePickerOpened: true })}
                  >
                    Add due date
                  </Button>
                )}
              </>
            )}
            {!canEdit && <>{date ? moment(date).format('DD/MM/YYYY') : <>No due date</>}</>}
          </div>

          {/* Mission */}
          {isMissionColumnVisible && onClickTagMission && (
            <div
              className={classnames(css.taskMissionColumn, css.missionContainer, {
                [css.missionContainerCompact]: isCompactView,
              })}
            >
              {mission && (
                <div className={css.missionGroup}>
                  <button
                    data-ga-label="Mission Category Block"
                    data-test-id={`taskMissionCategoryButton-${index}`}
                    onClick={() => onClickTagMission(taskId)}
                    className={css.roadmapCategorylabelButton}
                    disabled={!canEdit}
                  >
                    <RoadmapCategoryLabel
                      categoryColor={mission.category && mission.category.color}
                      category={mission.category && mission.category.name}
                      mission={mission.name}
                      canEdit={canEdit}
                    />
                  </button>
                  {canEdit && onClearMission && (
                    <IconButton
                      dataGaLabel="Clear Mission"
                      dataTestId={`taskMissionClear-${index}`}
                      className={classnames(css.iconButton, css.clearValueButton)}
                      onClick={onClearMission}
                      icon="Close"
                    />
                  )}
                </div>
              )}
              {!mission &&
                (canEdit ? (
                  <Button dataTestId={`taskTagMission-${index}`} onClick={() => onClickTagMission(taskId)} secondary>
                    Tag a mission
                  </Button>
                ) : (
                  'No Mission'
                ))}
            </div>
          )}

          {/* Delete */}
          {canDelete && (
            <IconButton
              dataTestId={`taskDelete-${index}`}
              className={showDateOnMobile && isCompactView ? css.deleteTaskColumnCompact : css.deleteTaskColumn}
              onClick={onDeleteTask}
              icon="Delete"
            />
          )}
        </div>
        {!isTaskNameValid && (
          <InputWarningMessage
            className={classnames(css.taskValidationMessage, { [css.taskValidationMessageCompact]: isCompactView })}
            message="Please add a name to save this task"
          />
        )}
      </div>
    );
  }
}

Task.defaultProps = {
  index: -1,
  isMissionColumnVisible: true,
  showDaysRemaining: true,
  autoFocus: false,
  showDateOnMobile: false,
  canEdit: true,
  canDelete: true,
};

Task.propTypes = {
  className: PropTypes.string,
  taskNameInputClassName: PropTypes.string,
  index: PropTypes.number,
  taskId: PropTypes.string,
  name: PropTypes.string,
  isComplete: PropTypes.bool,
  date: PropTypes.object,
  mission: PropTypes.object,
  autoFocus: PropTypes.bool,
  showDateOnMobile: PropTypes.bool,
  showDateOnCompact: PropTypes.bool,

  showDaysRemaining: PropTypes.bool,
  isMissionColumnVisible: PropTypes.bool,
  onDeleteTask: PropTypes.func,
  onDeleteTaskFromKeyBoard: PropTypes.func,
  onDateChange: PropTypes.func,
  onNameChange: PropTypes.func,
  onToggleComplete: PropTypes.func,
  onClickTagMission: PropTypes.func,
  onClearMission: PropTypes.func,
  onClickDetails: PropTypes.func,
  onEnterDown: PropTypes.func,
  onArrowUp: PropTypes.func,
  onArrowDown: PropTypes.func,
  inputTextRef: PropTypes.object,
  isCompactView: PropTypes.bool,
  canEdit: PropTypes.bool,
  canDelete: PropTypes.bool,
};
