import React, { useState, useContext, useRef, useEffect } from 'react';
import TextCell from '../TextCell';
import { AppTrackerContext } from '../../viewController';
import { StyledInput } from './index.style';
import { useRequesterUserId } from '../../helper';
type MajorProps = {
  major: string | null;
  applicationId: string;
};
const Major = ({ major, applicationId }: MajorProps) => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(major || '');
  const [focused, setFocused] = useState(false);
  const { controller } = useContext(AppTrackerContext);
  const requesterUserId = useRequesterUserId();
  const prevMajor = useRef<string>('');
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    prevMajor.current = major || '';
    setValue(major || '');
  }, [major]);
  const onFocus = () => {
    setFocused(true);
  };
  const onConfirm = async () => {
    setFocused(false);
    if (loading) return;
    // return if value is same as previous value
    if (value.trim() === prevMajor.current) return;
    setLoading(true);
    const success = await controller.updateApplication(
      applicationId,
      {
        major: !!value.trim().length ? value.trim() : null,
      },
      {
        updatedBy: requesterUserId,
      },
    );
    if (!success) {
      setValue(prevMajor.current);
    }
    setLoading(false);
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 100 || loading) return;
    setValue(e.target.value);
  };
  const onKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      inputRef.current?.blur();
    }
  };
  return (
    <TextCell textColor={!!major ? undefined : '#A9ACC0'}>
      <StyledInput
        inputRef={inputRef}
        placeholder={major || 'Enter'}
        value={value}
        onFocus={onFocus}
        onBlur={onConfirm}
        onChange={onChange}
        onKeyDown={onKeyDown}
        variant={'outlined'}
        $grey={!major || focused}
        multiline
        maxRows={3}
      />
    </TextCell>
  );
};

export default Major;
