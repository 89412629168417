import React from 'react';

import { useSelector } from 'react-redux';
import { selectUserProfile } from 'selectors/meta';
import { roleTypes } from '@crimson-education/common-config';

import { withAppContext } from 'components/enhancers/AppContext';
import Unauthorised from '../Unauthorised';

import DaVinciReportsViewPage from './components/Page';

import { useFeatureFlag } from 'featureSwitches';
import NotFound from '../NotFound';

const DaVinciReports = () => {
  const loginUser = useSelector(selectUserProfile);

  const flag = useFeatureFlag('DAVINCI_REPORTS_TEMPLATE');

  const isAuthorised = loginUser
    .get('roleInfo')
    .find((r) => [roleTypes.SUPER_ADMIN, roleTypes.HEAD_TUTOR, roleTypes.TUTOR].includes(r.get('roleId')));

  if (!isAuthorised) {
    return <Unauthorised />;
  }

  if (!flag) {
    return <NotFound />;
  }

  return <DaVinciReportsViewPage />;
};

export default withAppContext(DaVinciReports);
