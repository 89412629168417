import React from 'react';
import PropTypes from 'prop-types';

import { Caption } from 'components/atoms/typography';
import TextArea from 'components/molecules/TextArea';
import Modal from 'components/molecules/Modal';
import FilledModalSection from 'components/molecules/FilledModalSection';

import css from './style.scss';

const DECLINE_CONTRACT_TITLE = 'Decline request';
const DECLINE_CONTRACT_MESSAGE = "Please let us know why you're declining this request";
const VALIDATION_MESSAGE = 'Please complete this field';

export default function DeclineContractModal(props) {
  const {
    isOpen,
    onBack,
    onClose,
    onDecline,
    contractDetails,
    contractId,
    subject: sub,
    declineReason,
    onReasonChange,
    isError,
  } = props;

  const contract = contractId || (contractDetails && contractDetails.toJS());

  if (!contract) return null;

  const subject = sub || contract.subjects[0];
  const hours = subject.isUnlimited ? 'Unlimited' : subject.totalHours;

  return (
    <Modal
      title={DECLINE_CONTRACT_TITLE}
      isOpen={isOpen}
      onBack={onBack}
      onClose={onClose}
      onSubmit={onDecline}
      onSubmitDataTestId="declineRequestConfirmButton"
      onSecondarySubmit={onBack}
      submitText="Decline request"
      secondarySubmitText="Back"
    >
      <FilledModalSection heading={subject.name} subHeading={`${hours} hours`} />
      <div className={css.declineModalContent}>
        <Caption>{DECLINE_CONTRACT_MESSAGE}</Caption>
        <TextArea
          autosize
          isError={isError}
          validationMessage={VALIDATION_MESSAGE}
          onChange={(value) => onReasonChange(value)}
          dataTestId="declineContractReasonTextArea"
          value={declineReason}
        />
      </div>
    </Modal>
  );
}

DeclineContractModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onBack: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  onDecline: PropTypes.func.isRequired,
  contractDetails: PropTypes.object,
  declineReason: PropTypes.string,
  onReasonChange: PropTypes.func.isRequired,
  isError: PropTypes.bool,
  contractId: PropTypes.number,
  subject: PropTypes.object,
};
