import React from 'react';
import { ActionItemContainer, ActionDetail, ActionName, LinkText, ClipIcon, TasksContainer, Dot } from './style';

type ActionNormalProps = {
  actionName: string;
  actionDetail?: string;
  detailLink?: string;
  tasks?: { name: string; link: string }[];
};
const ActionNormal = ({ actionDetail, actionName, detailLink, tasks }: ActionNormalProps) => {
  const DetailComp = !!detailLink ? LinkText : ActionDetail;
  const hasTask = !!tasks;
  const onClickDetail = () => {
    if (!detailLink) return;
    window.open(detailLink);
  };

  const onClickTask = (taskLink: string) => {
    window.open(taskLink);
  };
  return (
    <ActionItemContainer style={hasTask ? { flexDirection: 'column', alignItems: 'flex-start' } : undefined}>
      <ActionName>
        <Dot />
        {`${actionName}:`}
      </ActionName>
      {!!actionDetail && (
        <DetailComp onClick={onClickDetail}>
          {!!detailLink && <ClipIcon />}
          {actionDetail}
        </DetailComp>
      )}
      {hasTask && (
        <TasksContainer>
          {tasks?.map(({ name, link }) => (
            <LinkText onClick={() => onClickTask(link)} key={name}>
              <span style={{ display: 'inline-block', marginRight: 3 }}>{'-'}</span>
              {`${name}`}
            </LinkText>
          ))}
        </TasksContainer>
      )}
    </ActionItemContainer>
  );
};

export default ActionNormal;
