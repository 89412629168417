import React from 'react';
import PropTypes from 'prop-types';

export default function Logo({ className }) {
  return (
    <svg viewBox="0 0 335 335" className={className} version="1.1">
      <path
        id="XMLID_20_"
        d="M94.8,75.3L83.6,56c-1.7-3-6.1-3-7.8,0L51.4,97.6c-1.6,2.8-1.6,6.2,0,9l12.3,21C70.3,108.1,81,90.3,94.8,75.3z"
      />
      <g id="XMLID_94_">
        <path
          id="XMLID_27_"
          d="M45.2,15.9L60,41.4c1.6,2.8,1.6,6.2,0,9L45.2,75.8c-1.8,3-6.1,3.1-7.9,0L22.4,50.4c-1.6-2.8-1.6-6.3,0-9.1l14.9-25.5C39.1,12.8,43.5,12.8,45.2,15.9z"
        />
      </g>
      <g id="XMLID_87_">
        <path
          id="XMLID_25_"
          d="M23.2,80.9l9.4,16.3c1.6,2.8,1.6,6.2,0,9l-9.4,16.3c-1.8,3-6.1,3.1-7.9,0l-9.5-16.3c-1.6-2.8-1.6-6.3,0-9.1l9.5-16.3C17,77.9,21.4,77.9,23.2,80.9z"
        />
      </g>
      <g id="XMLID_1_">
        <path
          id="XMLID_22_"
          d="M329.7,100.3c-30-51.1-89.3-81.2-151.5-70.6c-33.2,5.6-62.1,22.3-83.5,45.6l17.9,4.5c2.3,0.6,3.4,3.2,2.2,5.3l-26.5,45.8c-1,1.7-3.1,2.6-5,2.1l-19.8-5.3c0.8-2.4,1.7-4.7,2.6-7c-8.8,21.9-12.4,46.2-9.5,71c9.8,83.3,86.6,141.1,168.7,128.3c13-2,25.3-5.7,36.7-10.8c4.2-1.8,5.9-6.8,3.8-10.9l-17.5-34.2c-1.8-3.6-6-5.2-9.8-3.8c-4.4,1.6-9,2.9-13.7,3.9c-56.5,12.1-29-11.6-43.6-24.5c-8.2-7.2-27.9-13.5-43.1-14.1c-34.1-1.4-33.7-13.8-27.2-27.6c4.5-9.6,2.4-14.9,3.3-19c0.9-4.2,1-5.7,3-7.9c3.1-3.2,0.6-7.5-2-11.2c-6.1-8.4-4.5-13.8,4-15.6c8.4-1.7,25.7-10.7,26.9-17.8c1.3-7,12.3-36,35.8-41.7c38.7-9.4,78.5,2.7,100.7,40.5c2.1,3.7,6.7,5.1,10.5,3.1l33.1-16.9C330.6,109.3,332,104.2,329.7,100.3z"
        />
      </g>
    </svg>
  );
}

Logo.propTypes = {
  className: PropTypes.string,
};
