import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './styles.scss';

export default function HorizontalCell(props) {
  return <div className={classNames(css.horizontalCell, css[props.align])}>{props.children}</div>;
}

HorizontalCell.displayName = 'HorizontalCell';

HorizontalCell.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  align: PropTypes.string,
};

HorizontalCell.defaultProps = {
  align: 'start',
};
