import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import QuillEditor from 'components/molecules/QuillEditor';
import agendaService from 'graphql/api/agenda';
import { fetchAgendaItems } from 'ducks/agenda';
import { clearNotesLocalStorage } from 'utils/utils';
import {
  getSessionItem,
  setSessionItem,
  getFloatingSessionContext,
  IdEuqal,
} from 'components/pages/FloatingSession/index.util';
export default function Agenda(props) {
  const {
    eventId,
    canEdit,
    setDraft,
    refreshAgenda,
    recoverAgenda,
    setEditedAgenda,
    setAgendaVersion,
    agendaVersion,
    toggleFloating,
  } = props;
  const [currentHtml, setCurrentHtml] = useState('');
  const [currentVersion, setCurrentVersion] = useState(0);
  const { sessionId } = getFloatingSessionContext();
  const [editorVisible, setEditorVisible] = useState(IdEuqal(sessionId, eventId));
  useEffect(() => {
    const { sessionId } = getFloatingSessionContext();
    setEditorVisible(!IdEuqal(sessionId, eventId));
  }, [eventId, toggleFloating]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAgendaItems(eventId));
  }, [dispatch, eventId]);

  const thisOnChange = useCallback(
    (agenda) => {
      const { version, id } = JSON.parse(localStorage.getItem(`event-agenda-${eventId}`) || '{}');
      setDraft();
      if (!id) {
        fetchAgendaId(eventId);
      }
      setCurrentHtml(agenda.html);
      setEditedAgenda(agenda);
      setAgendaVersion(currentVersion);
      localStorage.setItem(
        `event-agenda-${eventId}`,
        JSON.stringify({ agenda, id, version: version || 0, isDraft: true, updatedAt: new Date() }),
      );
    },
    [setDraft, eventId, currentVersion, setEditedAgenda, setAgendaVersion],
  );

  async function fetchAgendaId(eventId) {
    const localAgenda = getSessionItem({
      sessionId: eventId,
      type: 'agenda',
    });
    if (!localAgenda?.agenda?.reFetch && localAgenda?.agenda?.reFetch !== undefined) {
      setCurrentHtml(localAgenda?.agenda?.html);
      setSessionItem({
        sessionId: eventId,
        type: 'agenda',
        item: {
          ...localAgenda,
        },
      });
    } else {
      const remoteAgenda = await agendaService.getAgendaByEvent(eventId.toString());
      const sessionAgenda = remoteAgenda && remoteAgenda.agenda;
      if (sessionAgenda && sessionAgenda.length > 0) {
        const { version, id: agendaId } = sessionAgenda[0];
        try {
          localStorage.setItem(
            `event-agenda-${eventId}`,
            JSON.stringify({ id: agendaId, version: version || 0, isDraft: false, updatedAt: new Date() }),
          );
          setCurrentVersion(version || 0);
        } catch (err) {
          // If the localStorage is full
          if (err.code === '22' || err.code === '1024') {
            clearNotesLocalStorage();
          }
        }
      }
    }
  }

  async function fetchAgenda(eventId) {
    const localAgenda = getSessionItem({
      sessionId: eventId,
      type: 'agenda',
    });
    if (!localAgenda?.agenda?.reFetch && localAgenda?.agenda?.reFetch !== undefined) {
      setCurrentHtml(localAgenda?.agenda?.html);
      setSessionItem({
        sessionId: eventId,
        type: 'agenda',
        item: {
          ...localAgenda,
        },
      });
    } else {
      const remoteAgenda = await agendaService.getAgendaByEvent(eventId.toString());
      const sessionAgenda = remoteAgenda?.agenda[0];
      if (sessionAgenda) {
        const { html, text, version, id: agendaId } = sessionAgenda;
        const agenda = { html, text };
        setCurrentHtml(html);
        localStorage.setItem(
          `event-agenda-${eventId}`,
          JSON.stringify({ agenda, id: agendaId, version: version || 0, isDraft: false, updatedAt: new Date() }),
        );
        setCurrentVersion(version || 0);
      } else {
        setCurrentHtml('');
        localStorage.setItem(
          `event-agenda-${eventId}`,
          JSON.stringify({ agenda: {}, version: 0, isDraft: false, updatedAt: new Date() }),
        );
        setCurrentVersion(0);
      }
    }
  }
  useEffect(() => {
    fetchAgenda(eventId);
  }, [refreshAgenda, eventId]);
  useEffect(() => {
    if (refreshAgenda) {
      recoverAgenda();
    }
  }, [refreshAgenda, recoverAgenda]);

  useEffect(() => {
    setCurrentVersion(agendaVersion);
  }, [agendaVersion]);

  return editorVisible ? (
    <QuillEditor disabled={!canEdit} html={canEdit ? currentHtml : ''} onChange={thisOnChange} id="agenda" />
  ) : (
    ''
  );
}
Agenda.propTypes = {
  eventId: PropTypes.string.isRequired,
  canEdit: PropTypes.bool,
  setDraft: PropTypes.func.isRequired,
  refreshAgenda: PropTypes.bool,
  toggleFloating: PropTypes.bool,
  recoverAgenda: PropTypes.func,
  setEditedAgenda: PropTypes.func,
  setAgendaVersion: PropTypes.func,
  agendaVersion: PropTypes.number,
};

Agenda.defaultProps = {
  canEdit: true,
};
