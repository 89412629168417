import styled from 'styled-components';

export const RemainingCount = styled.div`
  border-radius: 999px;
  background: #a9acbe;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  text-align: center;
`;
