import React, { Component } from 'react';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import PropTypes from 'prop-types';
import { components as ReactSelectComponents } from 'react-select';
import InputWarningMessage from 'components/molecules/InputWarningMessage';

import HighlightOption from './HightlightOption';
import DropdownIndicator from './DropdownIndicator';
import ClearIndicator from './ClearIndicator';
import { defaultSelectStyles, menuOpenStyles, menuOpenStylesMobile } from './style';

function selectStyle(isMobile, isMenuOpen) {
  if (isMobile && isMenuOpen) {
    return menuOpenStylesMobile;
  }
  if (isMenuOpen) {
    return menuOpenStyles;
  }
  return defaultSelectStyles;
}

export default class Select extends Component {
  constructor(props) {
    super(props);
    this.state = { input: '' };

    this.renderInput = this.renderInput.bind(this);
  }

  renderInput(props) {
    const { dataTestId } = this.props;
    return <ReactSelectComponents.Input {...props} data-test-id={dataTestId || 'selectComponent'} />;
  }

  render() {
    const {
      onInputChange,
      components,
      options,
      getOptionLabel,
      loadOptions,
      filterOption,
      forwardedRef,
      styles,
      isCreatable,
      isValid,
      validationMessage,
      menuIsOpen,
      isMobile,
      defaultOptions,
      DropdownIndicatorProps,
    } = this.props;

    const defaultLoadOptions = (_, callback) => {
      return callback(options);
    };

    const defaultFilterOption = (option, inputValue) => {
      const label = (getOptionLabel && getOptionLabel(option)) || option.label;
      return label && label.toString().toLowerCase().includes(inputValue.toString().toLowerCase());
    };

    const componentOverrides = Object.assign(
      {},
      {
        Option: HighlightOption,
        Input: this.renderInput,
        DropdownIndicator: (props) => <DropdownIndicator {...props} {...DropdownIndicatorProps} />,
        ClearIndicator,
      },
      components,
    );

    const styleToUse = selectStyle(isMobile, menuIsOpen);

    const styleOverrides = Object.assign({}, styleToUse, styles);

    const allProps = {
      ...this.props,
      ref: forwardedRef,
      styles: styleOverrides,
      onInputChange: (input, actionMeta) => {
        this.setState({ input });
        onInputChange && onInputChange(input, actionMeta);
      },
      defaultOptions: defaultOptions || options,
      noOptionsMessage: () => 'No results found',
      captureMenuScroll: false,
      components: componentOverrides,
      loadOptions: loadOptions || defaultLoadOptions,
      filterOption: filterOption || defaultFilterOption,
      getOptionLabel,
    };

    if (isCreatable) {
      return (
        <React.Fragment>
          <AsyncCreatableSelect {...allProps} />
          {!isValid && <InputWarningMessage message={validationMessage} />}
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <AsyncSelect {...allProps} />
        {!isValid && <InputWarningMessage message={validationMessage} />}
      </React.Fragment>
    );
  }
}

Select.defaultProps = {
  isValid: true,
};

Select.propTypes = {
  dataTestId: PropTypes.string,
  onInputChange: PropTypes.func,
  components: PropTypes.object,
  loadOptions: PropTypes.func,
  filterOption: PropTypes.func,
  isCreatable: PropTypes.bool,
  options: PropTypes.array,
  getOptionLabel: PropTypes.func,
  forwardedRef: PropTypes.func,
  styles: PropTypes.object,
  isValid: PropTypes.bool,
  validationMessage: PropTypes.string,
  menuIsOpen: PropTypes.bool,
  isMobile: PropTypes.bool,
  defaultOptions: PropTypes.bool,
  DropdownIndicatorProps: PropTypes.object,
};
