import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { eventStatusTypes } from '@crimson-education/common-config';

import { formatTime, formatDateForDataTestId, formatTimeForDataTestId, formatDateWithYear } from 'utils/calendarUtils';
import { withAppContext } from 'components/enhancers/AppContext';
import { Body, Tiny } from 'components/atoms/typography';
import Card from 'components/molecules/Card';
import Avatar from 'components/molecules/Avatar';

import { SessionConfirmationActions, SessionStatus } from 'components/pages/Session';
import css from './SessionCard.scss';

const isDraft = (eventId) => {
  const { isDraft: isAgendaDraft } = JSON.parse(localStorage.getItem(`event-agenda-${eventId}`) || '{}');
  const { isDraft: isNotesDraft } = JSON.parse(localStorage.getItem(`event-notes-${eventId}`) || '{}');
  return isAgendaDraft || isNotesDraft;
};

function SessionCard(props) {
  const {
    session,
    app: { isMobile },
    currentUser,
    showDate,
    setRefetchSessions,
  } = props;

  const [currentSession, setCurrentSession] = useState(session);
  const participant = session.participants.find((x) => x.userId !== currentUser.userId);

  const isPending = session.status === eventStatusTypes.TENTATIVE && session.creatorUserId !== currentUser.userId;
  return (
    <Card className={css.card}>
      <Link
        to={`/session/${currentSession.id}`}
        className={css.info}
        data-test-id={`${currentSession.status}Booking_${formatDateForDataTestId(
          currentSession.start,
        )}_${formatTimeForDataTestId(currentSession.start)}_${formatTimeForDataTestId(currentSession.end)}`}
      >
        <div className={classNames(css.sessionTime, css.section)}>
          <Body bold>
            {showDate && formatDateWithYear(currentSession.start) + ','} {formatTime(currentSession.start)} &mdash;{' '}
            {formatTime(currentSession.end)}
            {isDraft(currentSession.id) ? <span className={css.draft}> (Draft)</span> : ''}
          </Body>
        </div>

        <div className={classNames(css.sessionName, css.section)}>
          <Body bold>
            {currentSession.name}
            {!isMobile && (
              <React.Fragment>
                &nbsp;&nbsp;&nbsp;&middot;&nbsp;&nbsp;&nbsp;
                {participant.firstName}&nbsp;{participant.lastName}
              </React.Fragment>
            )}
          </Body>
        </div>
        <div className={classNames(css.summary, css.section)}>
          <ul>
            <li>
              <Tiny
                bold
                className={classNames(css.text, {
                  [css.active]: currentSession.hasAgenda,
                })}
              >
                Agenda
              </Tiny>
            </li>
            <li>
              <Tiny
                bold
                className={classNames(css.text, {
                  [css.active]: currentSession.hasNotes,
                })}
              >
                Notes
              </Tiny>
            </li>
            <li>
              <Tiny
                bold
                className={classNames(css.text, {
                  [css.active]: currentSession.tasksCount,
                })}
              >
                Tasks
              </Tiny>
            </li>
          </ul>
        </div>
        <div className={classNames(css.avatar, css.section)}>
          <Avatar
            firstName={participant.firstName}
            lastName={participant.lastName}
            image={participant.profileImageUrl}
            size="large"
            userId={participant.userId}
            showStudentType
          />
        </div>

        <div className={classNames(css.name, css.section)}>
          <Body bold>
            {participant.firstName}&nbsp;{participant.lastName}
          </Body>
        </div>

        <div className={classNames(css.status, css.section)}>
          <SessionStatus sessionId={currentSession.id} currentSession={currentSession} />
        </div>
      </Link>

      {isPending && (
        <div className={css.actions}>
          <SessionConfirmationActions
            currentSession={session}
            sessionId={currentSession.id}
            userId={currentUser.userId}
            setCurrentSession={setCurrentSession}
            setRefetchSessions={setRefetchSessions}
          />
        </div>
      )}
    </Card>
  );
}

SessionCard.propTypes = {
  session: PropTypes.object,
  app: PropTypes.object.isRequired,
  currentUser: PropTypes.object,
  showDate: PropTypes.bool,
  setRefetchSessions: PropTypes.func,
};

export default withAppContext(SessionCard);
