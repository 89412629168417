import componentKeys from 'constants/componentKeys';
import { updateMeta, LOCATION_CHANGE } from 'ducks/meta';
const userURLRegex = /^\/users\/(auth0-[a-zA-Z0-9]*)\/?/;

const userCurrentlyViewing = (store) => (next) => (action) => {
  if (action.type === LOCATION_CHANGE && action.payload) {
    try {
      const pathname = action.payload.pathname;

      const regexResult = userURLRegex.exec(pathname);

      if (regexResult) {
        const userId = regexResult[1];
        store.dispatch(updateMeta(componentKeys.APP_VIEWED_USER, userId));
      } else {
        store.dispatch(updateMeta(componentKeys.APP_VIEWED_USER, undefined));
      }
    } catch (err) {
      // ignore error
    }
  }
  next(action);
};

export default userCurrentlyViewing;
