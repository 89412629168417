import React from 'react';
import PropTypes from 'prop-types';
import { Acl, permissionTypes } from '@crimson-education/common-config';

import Popover from '@material-ui/core/Popover';
import { CrimsonUnfiltered, CrimsonHub, AskCrimson, CrimsonConnectLogo } from 'components/atoms/graphics';
import Pageview from '@material-ui/icons/Pageview';
import AppsIcon from '@material-ui/icons/Apps';
import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';

import css from './styles.scss';
import { handleEnter } from '../../../../../utils/accessibility';

const config = getConfig();

export default function SwitchAppPopover({ className, linkClassName, loginUserPermissions, onClick }) {
  const [switchAppPopper, toggleSwitchAppPopper] = React.useState(false);
  const [switchAppAnchor, setSwitchAppAnchor] = React.useState(null);

  const isAskLinkVisible = Acl.checkPermission(loginUserPermissions, permissionTypes.VIEW_ASK_LINK);
  const isHubLinkVisible = Acl.checkPermission(loginUserPermissions, permissionTypes.VIEW_HUB_LINK) && !config.china;
  const isConnectLinkVisible = Acl.checkPermission(loginUserPermissions, permissionTypes['CRIMSON_CONNECT_LINK:VIEW']);
  const isECLSearchLinkVisible = Acl.checkPermission(loginUserPermissions, permissionTypes['ECL_SEARCH_LINK:VIEW']);

  const isNothingVisible = !isAskLinkVisible && !isHubLinkVisible && !isConnectLinkVisible && !isECLSearchLinkVisible;

  return isNothingVisible ? null : (
    <div
      role="button"
      tabIndex={0}
      onClick={(e) => {
        onClick(e);
        setSwitchAppAnchor(e.currentTarget);
        toggleSwitchAppPopper(!switchAppPopper);
      }}
      onKeyDown={handleEnter((e) => {
        onClick(e);
        setSwitchAppAnchor(e.currentTarget);
        toggleSwitchAppPopper(!switchAppPopper);
      })}
      className={className}
      data-appcues-id="tasks"
    >
      {!config.dulwich && (
        <div className={linkClassName} data-tip="Switch apps" data-for="navigationToolTip">
          <AppsIcon />
          <span>Switch app</span>
        </div>
      )}
      <Popover
        id="switch-app-popover"
        open={switchAppPopper}
        anchorEl={switchAppAnchor}
        onClose={() => toggleSwitchAppPopper(false)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className={css.switchAppPopover}>
          <div
            role="button"
            tabIndex={0}
            className={css.appLink}
            onClick={() => window.open('https://auth.unfiltered.tv/auth0/signin', '_blank')}
            onKeyDown={handleEnter(() => window.open('https://auth.unfiltered.tv/auth0/signin', '_blank'))}
          >
            <CrimsonUnfiltered />
            <span>Unfiltered</span>
          </div>
          {isECLSearchLinkVisible && (
            <div
              role="button"
              tabIndex={0}
              className={css.appLink}
              onClick={() => window.open('https://crimsonecl.com/', '_blank')}
              onKeyDown={handleEnter(() => window.open('https://crimsonecl.com/', '_blank'))}
            >
              <Pageview />
              <span>ECL Search</span>
            </div>
          )}
          {isConnectLinkVisible && (
            <div
              role="button"
              tabIndex={0}
              className={css.appLink}
              onClick={() => window.open('https://connect.crimsoneducation.org/', '_blank')}
              onKeyDown={handleEnter(() => window.open('https://connect.crimsoneducation.org/', '_blank'))}
            >
              <CrimsonConnectLogo />
              <span>Connect</span>
            </div>
          )}
          {isAskLinkVisible && (
            <div
              role="button"
              tabIndex={0}
              className={css.appLink}
              onClick={() => window.open('https://ask.crimsoneducation.org/', '_blank')}
              onKeyDown={handleEnter(() => window.open('https://ask.crimsoneducation.org/', '_blank'))}
            >
              <AskCrimson />
              <span>Ask</span>
            </div>
          )}
          {isHubLinkVisible && (
            <div
              role="button"
              tabIndex={0}
              className={css.appLink}
              onClick={() => window.open('https://hub.crimsoneducation.org/', '_blank')}
              onKeyDown={handleEnter(() => window.open('https://hub.crimsoneducation.org/', '_blank'))}
            >
              <CrimsonHub />
              <span>Hub</span>
            </div>
          )}
        </div>
      </Popover>
    </div>
  );
}

SwitchAppPopover.propTypes = {
  className: PropTypes.string,
  linkClassName: PropTypes.string,
  onClick: PropTypes.func,
  loginUserPermissions: PropTypes.array.isRequired,
};
