import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import onClickOutside from 'react-onclickoutside';
import classnames from 'classnames';
import Highlighter from 'react-highlight-words';
import InfiniteScroll from 'react-infinite-scroller';
import LoadingIndicator from 'components/molecules/LoadingIndicator';

import Filter from 'components/molecules/TextFilter';
import css from './styles.scss';
import { handleEnter } from '../../../../../../../utils/accessibility';

const PLACEHOLDER = 'Search a name';

class Dropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filterText: '',
      focus: false,
    };

    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onFocusHandler = this.onFocusHandler.bind(this);
    this.onClickHandler = this.onClickHandler.bind(this);
    this.makePeopleList = this.makePeopleList.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.loadMoreUsers = debounce(this.loadMoreUsers.bind(this), 200);
  }

  // Focused; render dropdown.
  onFocusHandler() {
    this.setState({
      focus: true,
    });
  }

  // Set the BookingAs user.
  onClickHandler(userId) {
    this.setState({
      filterText: '',
      focus: false,
    });

    this.props.selectBookingAs(userId);
  }

  // Update the filter text.
  onChangeHandler(newText) {
    this.props.loadMoreContacts(newText);
    this.setState({
      filterText: newText,
    });
  }

  // Prepare a list of people for display.
  makePeopleList() {
    const somePeople = this.props.people;

    return somePeople.map((person) => {
      return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          role="button"
          tabIndex={0}
          key={person.get('userId')}
          onClick={() => this.onClickHandler(person.get('userId'))}
          onKeyDown={handleEnter(() => this.onClickHandler(person.get('userId')))}
          data-test-id={`bookAs_${person.get('role')}_${person.get('firstName')}_DropdownOption`}
        >
          <Highlighter
            searchWords={[this.state.filterText]}
            textToHighlight={person.get('fullName')}
            highlightTag="strong"
            autoEscape
          />
        </a>
      );
    });
  }

  // Not focused; stop rendering the dropdown if it was previously focused.
  handleClickOutside() {
    if (this.state.focus === true) {
      this.setState({
        focus: false,
      });
    }
  }

  loadMoreUsers() {
    const { pagination, loadMoreContacts } = this.props;
    if (pagination.get('hasNextPage')) {
      const paginationObj = { pageNumber: pagination.get('pageNumber') + 1, pageSize: pagination.get('pageSize') };
      loadMoreContacts(this.state.filterText, paginationObj);
    }
  }

  render() {
    return (
      <div className={this.state.focus ? classnames(css.dropdown, css.dropdownFocused) : css.dropdown}>
        <Filter
          value={this.state.filterText}
          placeholder={PLACEHOLDER}
          dataTestId={this.props.dataTestId}
          onChangeHandler={(event) => this.onChangeHandler(event.target.value)}
          onFocusHandler={() => this.onFocusHandler()}
        />
        {this.state.focus && (
          <div className={css.wrapper}>
            <InfiniteScroll
              className={css.dropdownContent}
              initialLoad={false}
              loadMore={this.loadMoreUsers}
              hasMore={this.props.pagination.get('hasNextPage')}
              useWindow={false}
              threshold={100}
              loader={<LoadingIndicator key="loader" className={css.loadingIndicator} colour="hint" />}
            >
              {this.makePeopleList()}
            </InfiniteScroll>
          </div>
        )}
      </div>
    );
  }
}

Dropdown.propTypes = {
  people: ImmutablePropTypes.list.isRequired,
  selectBookingAs: PropTypes.func.isRequired,
  pagination: PropTypes.object,
  loadMoreContacts: PropTypes.func,
  dataTestId: PropTypes.string,
};

export default onClickOutside(Dropdown);
