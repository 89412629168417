import { getEnvironmentConfig } from '@crimson-education/common-config/lib/environment';
import { gql } from '@apollo/client';
import getGraphQLClient from '../';

const { api } = getEnvironmentConfig();

export const client = getGraphQLClient(api.graphQLEndpoint);

export const getNotificationSettings = gql`
  query notificationSettings($userId: String) {
    notificationSettings(userId: $userId) {
      userId
      settings {
        email {
          id
          action
          options
        }
      }
    }
  }
`;

export const updateNotificationSettings = gql`
  mutation setNotificationSettings($userId: String!, $settings: userNotificationSettingSectionInputType) {
    setNotificationSettings(userId: $userId, settings: $settings)
  }
`;
