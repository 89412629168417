import React from 'react';
import PropTypes from 'prop-types';
import SessionReport from './SessionReport';

export default function VersionOne(props) {
  return <SessionReport {...props} />;
}

VersionOne.propTypes = {
  isOpen: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  eventId: PropTypes.number.isRequired,
  tutorId: PropTypes.string.isRequired,
  studentId: PropTypes.string.isRequired,
};
