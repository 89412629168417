import React from 'react';
import PropTypes from 'prop-types';

export default function PendingStudents(props) {
  return (
    <svg viewBox="0 0 223.18 160.05" {...props}>
      <path
        d="M84.2 18.48a3.48 3.48 0 1 1-3.29-3.66 3.48 3.48 0 0 1 3.29 3.66zm-5.42-.29a1.95 1.95 0 1 0 2-1.84 1.95 1.95 0 0 0-2 1.84zM223.18 85a3.48 3.48 0 1 1-3.29-3.66 3.49 3.49 0 0 1 3.29 3.66zm-5.42-.28a1.95 1.95 0 1 0 2-1.85 2 2 0 0 0-2 1.82z"
        fill="#e7d756"
      />
      <path
        d="M194.31 67.79a2.51 2.51 0 1 1-2.38-2.64 2.51 2.51 0 0 1 2.38 2.64zm-3.72-.2a1.21 1.21 0 1 0 1.28-1.14 1.21 1.21 0 0 0-1.28 1.14zM142.75 2.93A2.78 2.78 0 1 1 140.12 0a2.78 2.78 0 0 1 2.63 2.93zm-4.11-.22a1.34 1.34 0 1 0 1.41-1.27 1.34 1.34 0 0 0-1.41 1.27zM23.5 10a2.78 2.78 0 1 1-2.63-3 2.78 2.78 0 0 1 2.63 3zm-4.11-.21a1.34 1.34 0 1 0 1.4-1.27 1.33 1.33 0 0 0-1.4 1.22z"
        fill="#dff2f4"
      />
      <path
        d="M208.69 125.72c-1.72 28.78-43.48 38.1-104.49 33s-98-26.44-98-46.15c0-18 24.77-22.79 86-22.79S210.12 102 208.69 125.72z"
        fill="#cfecf3"
      />
      <path
        d="M4.33 47.44A16 16 0 0 1 7 37.41a15 15 0 0 1 8.6-5.93 15.63 15.63 0 0 1 10.29 1A15.48 15.48 0 0 1 32.6 39c-3.28-.21-5.64-.84-8.25-1a23.72 23.72 0 0 0-7 .53 20.05 20.05 0 0 0-6.29 2.85c-2.16 1.46-4.25 3.95-6.73 6.06z"
        fill="#237b8e"
      />
      <path
        d="M57.34 43.08c-2.4-1.21-4.44-2.32-6.42-3.24a25.9 25.9 0 0 0-5.65-2 17.69 17.69 0 0 0-5.71-.21c-2.07.21-4.3.8-7 1.35A11.84 11.84 0 0 1 38 33.15a12.55 12.55 0 0 1 8.48-1.22A14 14 0 0 1 53.77 36a12.92 12.92 0 0 1 3.57 7.08z"
        fill="#237b8e"
      />
      <path
        d="M29.17 96.87C43 74.52 30.9 39.18 30.9 39.18l3.4-1s15.45 46.91 8.18 60c-3.22 1.94-11.84 3.82-13.31-1.31z"
        fill="#dda75e"
      />
      <path
        d="M32.61 39c.17 3.15.23 5.58.13 8a49.86 49.86 0 0 1-.8 7.13 40.64 40.64 0 0 1-2 7.07 28.09 28.09 0 0 1-4.05 7.27 18.27 18.27 0 0 1-3.08-8 24.22 24.22 0 0 1 .19-8 22.38 22.38 0 0 1 3-7.69A13.71 13.71 0 0 1 32.61 39z"
        fill="#73c8d6"
      />
      <path
        d="M32.61 39A38.72 38.72 0 0 0 25 44.28 23.11 23.11 0 0 0 20.49 50a51.56 51.56 0 0 0-3.28 7.33c-1.07 2.77-2.06 5.8-3.69 9.15a19.39 19.39 0 0 1-3.42-10.06 19.75 19.75 0 0 1 2.47-10.92 16.37 16.37 0 0 1 9.08-7.5 13.12 13.12 0 0 1 10.96 1z"
        fill="#62b9ce"
      />
      <path
        d="M53 64.78c-2-2.93-3.43-5.49-4.85-7.91s-2.73-4.59-4.1-6.56a39.88 39.88 0 0 0-4.61-5.56A71 71 0 0 0 32.6 39a13.47 13.47 0 0 1 10.4-.5 17.29 17.29 0 0 1 8.57 6.6A20.17 20.17 0 0 1 55 54.87a16.91 16.91 0 0 1-2 9.91z"
        fill="#62b9ce"
      />
      <path
        d="M30.41 79.55c-1.34-.68-2.48-1.3-3.58-1.81a14.92 14.92 0 0 0-3.16-1.1 10.21 10.21 0 0 0-3.19-.12c-1.16.12-2.41.45-3.89.76a6.63 6.63 0 0 1 3-3.28 7 7 0 0 1 4.74-.68 7.85 7.85 0 0 1 4.07 2.29 7.19 7.19 0 0 1 2.01 3.94z"
        fill="#237b8e"
      />
      <path
        d="M14.67 109.61c7.72-12.49 1-32.24 1-32.24l1.9-.55s8.63 26.21 4.57 33.54c-1.83 1.07-6.65 2.09-7.47-.75z"
        fill="#dda75e"
      />
      <path
        d="M17.32 76.16a44.48 44.48 0 0 1-.32 4.48 28.54 28.54 0 0 1-.82 3.93 22.87 22.87 0 0 1-1.48 3.83 15.58 15.58 0 0 1-2.63 3.84 10.29 10.29 0 0 1-1.3-4.61 13.27 13.27 0 0 1 .52-4.48A12.37 12.37 0 0 1 13.33 79a7.63 7.63 0 0 1 3.99-2.84z"
        fill="#73c8d6"
      />
      <path
        d="M4.64 70.33a11.29 11.29 0 0 1 5.79-.33 9.58 9.58 0 0 1 5.28 3 7.49 7.49 0 0 1 2 5.43 38 38 0 0 0-4.44-2.25A21.8 21.8 0 0 0 9.41 75c-1.32-.27-2.74-.48-4.28-.66s-3.19-.42-5.13-.79a9.46 9.46 0 0 1 4.64-3.22z"
        fill="#62b9ce"
      />
      <path
        d="M163.56 128.44c0 11.28-21.58 17.16-45.36 16-31.77-1.44-58.61-19.54-54.79-31.81 3.49-11.22 31-1.77 54.79-1.77s45.36 5.97 45.36 17.58z"
        fill="#afdee6"
      />
      <path
        d="M96 51.41c4.55-12 17.7-17.42 28.61-15.61C140 38.36 143.1 46.86 147 62.14s-8.57 8.86-2.93 21.46c4.59 10.26 16.21 7.39 16.59 24.06.29 13-18.86 28-46.18 25.69S62.78 120 63.43 85.55c0 0 .65-7.49 8.46-3.1s19.83 5.38 28.29 3.75S88.17 72 96 51.41z"
        fill="#e7d756"
      />
      <path
        d="M79.35 96.69a1.24 1.24 0 0 1 2-1c7.12 4.82 15.82 2.92 23.84 2.62 0 0 7.77-.06 8.15 6.3.48 7.91-2.69 11.28-8.6 11.77-9 .75-19.44-6-23.22-12.44a13.11 13.11 0 0 1-2.17-7.25z"
        fill="#dbbf32"
      />
      <circle cx={126.1} cy={63.04} fill="#3e464d" r={2.13} />
      <ellipse cx={141.93} cy={53.86} fill="#3e464d" rx={1.62} ry={2.13} transform="rotate(-18.14 141.884 53.838)" />
      <path
        d="M101.45 63.76a1.62 1.62 0 0 1-1.6-1.44c-.21-1.84.88-9.64 4.76-13.46a1.62 1.62 0 0 1 2.27 2.31c-3 2.95-3.94 9.72-3.82 10.79a1.63 1.63 0 0 1-1.43 1.79z"
        fill="#f6eaac"
      />
      <path
        d="M139.05 62.06c-4 3.34-1.84 7-7.94 6.91-.3 0 5.75 2.68 8.29 2.76 5.64.19 8.86.25 10.72-.4 5.41-1.9 7.73-9.75 3.87-10.14s-5.72.18-8.72-1c-2.27-.86-4.71.62-6.22 1.87z"
        fill="#d89a50"
      />
      <path
        d="M131.11 69c2.33 1.28 5.52 3 9 3.57a21.69 21.69 0 0 0 10.71-.69c5.07-1.66 6.07-8.09 4.73-10 .16 3.26-1.81 7.36-5.77 8.55-4.3 1.21-13.72 1.27-18.67-1.43z"
        fill="#c66546"
      />
      <path
        d="M109.81 46.92a1.62 1.62 0 0 1-1.61-1.62 2.17 2.17 0 0 1 1.33-1.88 2 2 0 0 0 .19-.12 1.63 1.63 0 0 1 2.26.34 1.61 1.61 0 0 1-.32 2.25 4.59 4.59 0 0 1-.52.33 1.62 1.62 0 0 1-1.33.7z"
        fill="#f6eaac"
      />
      <g fill="#dda75e">
        <ellipse cx={126.38} cy={70.59} rx={4.51} ry={3.01} transform="rotate(-21.1 126.355 70.589)" />
        <path d="M143.85 58.49a2.87 2.87 0 0 0 2.61 1.59c-.45-1.72-.9-3.35-1.37-4.88-1.09.98-1.65 2.23-1.24 3.29zM186.15 17.35a.75.75 0 0 1-.73-.77l.06-3.5a.74.74 0 0 1 .75-.73.76.76 0 0 1 .74.76l-.06 3.5a.76.76 0 0 1-.76.74zM177.74 20.66a.78.78 0 0 1-.54-.23l-2.43-2.52a.75.75 0 0 1 0-1.06.75.75 0 0 1 1.06 0l2.43 2.52a.75.75 0 0 1 0 1.06.73.73 0 0 1-.52.23zM174.14 28.94l-3.5-.06a.75.75 0 1 1 0-1.5l3.5.06a.75.75 0 0 1 0 1.5zM174.94 39.78a.75.75 0 0 1-.52-1.29l2.51-2.43a.75.75 0 0 1 1.06 0 .75.75 0 0 1 0 1.06l-2.52 2.43a.77.77 0 0 1-.53.23zM185.68 44.45a.75.75 0 0 1-.73-.77l.06-3.5a.75.75 0 0 1 .75-.73.75.75 0 0 1 .74.76l-.06 3.5a.75.75 0 0 1-.76.74zM196.57 40.15a.73.73 0 0 1-.54-.23l-2.43-2.51a.75.75 0 0 1 1.08-1l2.43 2.52a.75.75 0 0 1 0 1.06.73.73 0 0 1-.54.16zM201.24 29.41l-3.5-.06a.76.76 0 0 1-.74-.76.75.75 0 0 1 .77-.74l3.49.06a.75.75 0 0 1 0 1.5zM194.43 21a.76.76 0 0 1-.54-.23.75.75 0 0 1 0-1.06l2.52-2.43a.75.75 0 0 1 1.06 0 .77.77 0 0 1 0 1.07L195 20.74a.73.73 0 0 1-.57.26z" />
        <circle cx={185.94} cy={28.4} r={8.24} />
      </g>
    </svg>
  );
}

PendingStudents.propTypes = {
  className: PropTypes.string,
};
