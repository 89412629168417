import Immutable from 'immutable';
import { createSelector } from 'reselect';
import { MAXIMUM_PEOPLE_SELECTED } from 'ducks/meta';
import componentKeys from 'constants/componentKeys';
import { prepareCalendarUsers } from 'utils/calendarUtils';
import { userIdsOfRelationshipForUser } from 'utils/user';
import {
  getMetaItem,
  getContactsForFilter,
  getOurPeopleSearch,
  getBookingWithUsers,
  getBookingForUsers,
  getAssignableUsers,
  getViewableUser,
  selectUserPermissions,
  getMyFriendsSearch,
  selectUserProfile,
} from 'selectors/meta';
import { allUserRelationships } from 'selectors/userRelationship';
import { allProfiles } from 'selectors/profile';
import moment from 'moment';
import { get } from 'lodash';

const state = (state) => state;
const users = (state) => state.get('user');

export const allUsers = createSelector(users, allUserRelationships, allProfiles, (users, relationships, profiles) => {
  let userList = Object.values(users.toJS());

  Object.values(userList).forEach((user) => {
    if (user.relationships) user.relationships = user.relationships.map((id) => relationships[id]);
    if (user.studentInfo) user.studentInfo = profiles[user.studentInfo];
    if (user.tutorInfo) user.tutorInfo = profiles[user.tutorInfo];
  });

  userList = prepareCalendarUsers(userList);

  return Immutable.fromJS(userList).sortBy((user) => user.get('fullName'));
});

/**
 * @deprecated use selectors/meta/selectUserProfile instead.
 */
export const getLoginUser = createSelector(
  state,
  allUsers,
  getMetaItem(componentKeys.APP_LOGIN_USER),
  selectUserProfile,
  (state, users, loginUserId, profile) => {
    const user = users.get(loginUserId) || new Immutable.Map();
    const userRoles = (profile && profile.get('userRoles')) || new Immutable.List();
    return user.set('permissions', selectUserPermissions(state)).set('userRoles', userRoles);
  },
);

/**
 * @deprecated use selectors/meta/selectUserProfile instead.
 */
export const getLoginUserToJs = createSelector(
  state,
  allUsers,
  getMetaItem(componentKeys.APP_LOGIN_USER),
  selectUserProfile,
  (state, users, loginUserId, profile) => {
    const user = users.get(loginUserId) || new Immutable.Map();
    const userRoles = (profile && profile.get('userRoles')) || new Immutable.List();
    return user.set('permissions', selectUserPermissions(state)).set('userRoles', userRoles).toJS();
  },
);

/**
 * @deprecated use selectors/meta/selectUserPermissionInfo instead
 */
export const getLoginUserPermissions = createSelector(getLoginUser, (loginUser) => {
  return loginUser ? loginUser.get('permissions') : [];
});

export const getBookingAs = createSelector(
  allUsers,
  getMetaItem(componentKeys.CALENDAR_BOOKING_AS),
  (users, bookingAs) => {
    return users.get(bookingAs);
  },
);

export const getBookingAsToJs = createSelector(getBookingAs, (user) => (user ? user.toJS() : null));

export const getBookingsForBookingAs = createSelector(getBookingAs, (user) =>
  user ? user.getIn(['availability', 'bookings']) : new Immutable.List(),
);

export const getAllBookings = createSelector(users, (users) =>
  users
    ? users
        .valueSeq()
        .filter((v) => v)
        .flatMap((user) => user.getIn(['availability', 'bookings']))
        .filter((v) => v)
    : new Immutable.List(),
);

export const getUnavailableForBookingAs = createSelector(getBookingAs, (user) =>
  user ? user.getIn(['availability', 'unavailable']) : new Immutable.List(),
);

export const getPeopleForBookingWith = createSelector(allUsers, getBookingWithUsers, (users, bookingWithUsersCache) => {
  return bookingWithUsersCache.map((userId) => users.get(userId));
});

export const getPeopleForBookingFor = createSelector(allUsers, getBookingForUsers, (users, getBookingForUsersCache) => {
  return getBookingForUsersCache.map((userId) => users.get(userId));
});

export const getUsersWithId = (ids) =>
  createSelector(allUsers, (users) => users.filter((user) => ids.includes(user.get('userId'))));

export const getUserWithId = (id) =>
  createSelector(allUsers, (allUsers) => allUsers.find((user) => user.get('userId') === id) || new Immutable.Map());

export const getFilteredContacts = createSelector([getContactsForFilter, users], (contacts, users) => {
  return contacts.filter((contact) => users.has(contact)).map((contact) => users.get(contact));
});

export const selectedBookWith = createSelector(
  allUsers,
  getMetaItem(componentKeys.CALENDAR_SELECTED),
  (people, selectedIds) => people.toList().filter((person) => selectedIds.includes(person.get('userId'))),
);

export const canSelectMorePeople = createSelector(selectedBookWith, (people) => people.size < MAXIMUM_PEOPLE_SELECTED);

export const getQueriedUsers = createSelector(allUsers, getOurPeopleSearch(), (users, query) => {
  if (!query) return new Immutable.Map();

  const queriedUsers = users.filter((user) => query.get('results').includes(user.get('userId')));

  // Denormalise user objects in query object.
  const updatedQuery = query.set('results', queriedUsers.toList());

  return updatedQuery;
});

export const getQueriedUsersPagination = createSelector(getQueriedUsers, (query) => ({
  totalPages: query.getIn(['pagination', 'totalPages']),
  currentPage: query.getIn(['pagination', 'pageNumber']),
}));

export const getCurrentUser = createSelector(allUsers, getViewableUser, (users, userId) => {
  return users.get(userId) || new Immutable.Map();
});

export const getCurrentUserJS = createSelector(getCurrentUser, (user) => {
  return user && user.toJS();
});

export const getCurrentUserId = createSelector([getCurrentUser], (user) => user.get('userId'));

export const getCachedUserNames = createSelector(
  allUsers,
  getMetaItem(componentKeys.LESSON_DETAILS_CACHED_USERNAMES),
  (users, cachedUserIds) => {
    if (!cachedUserIds) return new Immutable.Map();
    return users.filter((user) => cachedUserIds.includes(user.get('userId')));
  },
);

export const getLoggedInUserId = createSelector(allUsers, (users, cachedUserIds) => {
  if (!cachedUserIds) return new Immutable.Map();
  return users.filter((user) => cachedUserIds.includes(user.get('userId')));
});

export const getUsersByRoleId = (roleId) =>
  createSelector(allUsers, (users) => {
    const usersByRole = users
      .filter((user) => {
        if (!user.get('userRoles')) {
          return false;
        }
        return user.get('userRoles').includes(roleId);
      })
      .toList();
    return usersByRole.size ? usersByRole : undefined;
  });

export const getAssignableUsersByRole = createSelector(allUsers, getAssignableUsers, (users, assignableUserCache) => {
  return assignableUserCache ? assignableUserCache.map((userId) => users.get(userId)) : new Immutable.List();
});

export const getCurrentUserInterests = createSelector(getCurrentUser, (currentUser) => {
  const interests = currentUser.getIn(['studentInfo', 'interests']);
  return interests && interests.toJS();
});

export const getHookStatement = createSelector(getCurrentUser, (currentUser) => {
  const hookStatement = currentUser.getIn(['studentInfo', 'hookStatement']);
  return hookStatement;
});

export const getEssayFolder = createSelector(getCurrentUser, (currentUser) => {
  const essayFolder = currentUser.getIn(['studentInfo', 'essayFolder']);
  return essayFolder;
});

export const getCurrentUserKeyContactInfo = createSelector(getCurrentUser, (currentUser) => {
  const keyContactInfo = currentUser.getIn(['studentInfo', 'keyContactInfo']);
  return keyContactInfo && keyContactInfo.toJS();
});

export const getCurrentUserKeyContacts = createSelector(getCurrentUser, (currentUser) => {
  const keyContacts = currentUser.getIn(['studentInfo', 'keyContacts']);
  const sorted =
    keyContacts &&
    keyContacts.sortBy(
      (keyContact) => keyContact,
      (a, b) => {
        if (a.get('isPrimary')) return -1;
        if (b.get('isPrimary')) return 1;
        const aCreated = a.get('createdAt');
        const bCreated = b.get('createdAt');
        return moment(bCreated).isBefore(moment(aCreated));
      },
    );
  return sorted && sorted.toJS();
});

export const getCurrentUserTutorInfo = createSelector(getCurrentUser, (currentUser) => {
  const tutorInfo = currentUser.get('tutorInfo');
  return tutorInfo && tutorInfo.toJS();
});

export const getCurrentUserTutorNote = createSelector(getCurrentUserTutorInfo, (tutorInfo) => {
  const note = tutorInfo ? tutorInfo.note : null;
  return note || null;
});

export const getCurrentUserStudentInfo = createSelector(getCurrentUser, (currentUser) => {
  const studentInfo = currentUser.get('studentInfo');
  return studentInfo && studentInfo.toJS();
});

export const getCurrentUserStudentNote = createSelector(getCurrentUserStudentInfo, (studentInfo) => {
  const note = studentInfo ? studentInfo.note : null;
  return note || null;
});

export const getCurrentUserExpertise = createSelector(getCurrentUser, (getCurrentUser) => {
  if (!getCurrentUser) return false;
  const expertise = getCurrentUser.getIn(['tutorInfo', 'expertise']);
  return expertise && expertise.toJS();
});

export const getCurrentUserSubjects = createSelector(getCurrentUser, (getCurrentUser) => {
  if (!getCurrentUser) return false;
  const subjects = getCurrentUser.getIn(['tutorInfo', 'subjects']);
  return subjects && subjects.toJS();
});

export const getCurrentUserHexaco = createSelector(getCurrentUser, (getCurrentUser) => {
  if (!getCurrentUser) return null;
  return getCurrentUser.get('hexaco');
});

export const isCurrentUserActive = createSelector(getCurrentUser, (currentUser) => {
  const status = currentUser.get('status');
  return status && status !== 'inactive';
});

export const getStudentIdsForCM = createSelector(getCurrentUser, (currentUser) => {
  const relationships = currentUser.get('relationships');
  if (!relationships) return new Immutable.List();
  const caseManagerStudents = relationships.filter(
    (relationship) => relationship.get('type').indexOf('CaseManagerStudent') !== -1,
  );
  return caseManagerStudents && caseManagerStudents.map((students) => students.getIn(['relationUser', 'userId']));
});

export const getStudentsForCM = createSelector(allUsers, getStudentIdsForCM, (users, ids) => {
  if (!ids) return new Immutable.List();
  return users.filter((user) => ids.includes(user.get('userId')));
});

export const getCurrentUserRoles = createSelector(getCurrentUser, (currentUser) => {
  return currentUser.get('userRoles');
});

export const getCurrentUserObject = createSelector(getCurrentUser, (currentUser) => {
  const user = {
    userId: currentUser.get('userId'),
    status: currentUser.get('status'),
    fullName: currentUser.get('fullName'),
    firstName: currentUser.get('firstName'),
    invitationStatus: currentUser.get('invitationStatus'),
    preferredName: currentUser.get('preferredName'),
    salesforceAccountUrl: currentUser.get('salesforceAccountUrl'),
  };
  if (currentUser.get('studentInfo')) {
    return Object.assign({}, user, { studentType: currentUser.getIn(['studentInfo', 'studentType']) });
  }
  return user;
});

export const getLoginUserTimezone = createSelector(getLoginUser, (user) => user.get('timezone'));

export const getCurrentUserUniversities = createSelector(getCurrentUser, (currentUser) => {
  const studentUniversities = currentUser.getIn(['studentInfo', 'universities']);
  const tutorUniversities = currentUser.getIn(['tutorInfo', 'universities']);

  const universities = studentUniversities || tutorUniversities;

  const sorted =
    universities &&
    universities.sortBy(
      (university) => university,
      (a, b) => {
        const aCreated = a.get('createdAt');
        const bCreated = b.get('createdAt');
        return moment(aCreated).isAfter(moment(bCreated));
      },
    );
  return sorted && sorted.toJS();
});

export const getCurrentUserSchools = createSelector(getCurrentUser, (currentUser) => {
  const studentSchools = currentUser.getIn(['studentInfo', 'schools']);
  const tutorSchools = currentUser.getIn(['tutorInfo', 'schools']);

  const schools = studentSchools || tutorSchools;

  const sorted =
    schools &&
    schools.sortBy(
      (school) => school,
      (a, b) => {
        const aCreated = a.get('createdAt');
        const bCreated = b.get('createdAt');
        return moment(aCreated).isAfter(moment(bCreated));
      },
    );
  return sorted && sorted.toJS();
});

export const getCurrentUserBookingBlocks = createSelector(getCurrentUser, (currentUser) => {
  return currentUser.getIn(['availability', 'bookings']);
});

export const getCurrentUseRecordingConsent = createSelector(getCurrentUser, (currentUser) => {
  return currentUser.get('meetingRecordingConsent') ? 'Yes' : 'No';
});

export const getCurrentConsent = createSelector(
  (_, type) => type,
  getCurrentUser,
  (type, currentUser) => {
    if (currentUser && currentUser.get('consents')) {
      const consent = currentUser.get('consents').find((c) => c.get('consentType') === type);
      return consent && consent.get('value') ? 'Yes' : 'No';
    }
    return 'No';
  },
);

export const getCurrentStudentContractStatus = createSelector(getCurrentUser, (currentUser) => {
  if (currentUser) {
    return currentUser.getIn(['studentInfo', 'contractStatus']);
  }
  return null;
});

export const getCurrentStudentContractSubStatus = createSelector(getCurrentUser, (currentUser) => {
  if (currentUser) {
    return currentUser.getIn(['studentInfo', 'contractSubStatus']);
  }
  return null;
});

export const getCurrentUserEduco = createSelector(getCurrentUser, (currentUser) => {
  const resultArr = userIdsOfRelationshipForUser(currentUser, 'CaseManagerStudent');
  if (resultArr && resultArr.length) {
    return resultArr[0].principalUser;
  }
  return null;
});

export const getCurrentUserHeadTutor = createSelector(getCurrentUser, (currentUser) => {
  const resultArr = userIdsOfRelationshipForUser(currentUser, 'HeadTutorTutor');
  if (resultArr && resultArr.length) {
    return resultArr[0].principalUser;
  }
  return null;
});

export const getCurrentUserStrategists = createSelector(getCurrentUser, (currentUser) => {
  const resultArr = userIdsOfRelationshipForUser(currentUser, 'StrategistStudent');
  if (resultArr && resultArr.length) {
    return resultArr.map((r) => r.principalUser);
  }
  return null;
});

export const getCurrentUserReviewers = createSelector(getCurrentUser, (currentUser) => {
  const resultArr = userIdsOfRelationshipForUser(currentUser, 'ReviewerStudent');
  if (resultArr && resultArr.length) {
    return resultArr.map((r) => r.principalUser);
  }
  return null;
});

export const getQueriedFriends = createSelector(getMyFriendsSearch(), (query) => {
  if (!query) return new Immutable.List();
  return query.get('results').toList();
});

export const getQueriedMyFriendsPagination = createSelector(getMyFriendsSearch(), (query) => {
  if (!query) {
    return {
      totalPages: 0,
      currentPage: 0,
    };
  }
  return {
    totalPages: query.getIn(['pagination', 'totalPages']),
    currentPage: query.getIn(['pagination', 'pageNumber']),
  };
});

export const getRegularQuestions = createSelector(getCurrentUser, (currentUser) => {
  const regularQuestions = currentUser.getIn(['studentInfo', 'regularQuestions']);
  return regularQuestions && regularQuestions.toJS();
});

export const getStudentSubjects = createSelector(getCurrentUser, (currentUser) => {
  const subjects = currentUser.getIn(['studentInfo', 'studentSubjects']);
  return subjects && subjects.toJS();
});

export const getApplicationYear = createSelector(getCurrentUser, (currentUser) => {
  const applicationYear = currentUser.getIn(['studentInfo', 'applicationYear']);
  return applicationYear;
});

export const getIntendedMajors = createSelector(getCurrentUser, (currentUser) => {
  const degree = currentUser.getIn(['studentInfo', 'degree']);
  const major = currentUser.getIn(['studentInfo', 'major']);
  const minor = currentUser.getIn(['studentInfo', 'minor']);
  return { degree, major, minor };
});

export const getIntendedCareer = createSelector(getCurrentUser, (currentUser) => {
  const intendedCareer = currentUser.getIn(['studentInfo', 'intendedCareer']);
  return intendedCareer;
});
export const getAcademicGoals = createSelector(getCurrentUser, (currentUser) => {
  const academicGoals = currentUser.getIn(['studentInfo', 'academicGoals']);
  return academicGoals;
});
export const getExtracurricularGoals = createSelector(getCurrentUser, (currentUser) => {
  const extracurricularGoals = currentUser.getIn(['studentInfo', 'extracurricularGoals']);
  return extracurricularGoals;
});
export const getAidRequirements = createSelector(getCurrentUser, (currentUser) => {
  const aidRequirements = currentUser.getIn(['studentInfo', 'aidRequirements']);
  return aidRequirements;
});

export const getAidPayRequirements = createSelector(getCurrentUser, (currentUser) => {
  const aidPayRequirements = currentUser.getIn(['studentInfo', 'aidPayRequirements']);
  return aidPayRequirements;
});

export const getAdmissionGoals = createSelector(getCurrentUser, (currentUser) => {
  const admissionGoals = currentUser.getIn(['studentInfo', 'admissionGoals']);
  return admissionGoals;
});
export const getTargetCountries = createSelector(getCurrentUser, (currentUser) => {
  const targetCountries = currentUser.getIn(['studentInfo', 'targetCountries']);
  return targetCountries && targetCountries.toJS();
});

export const getPreparedness = createSelector(getCurrentUser, (currentUser) => {
  const preparedness = currentUser.getIn(['studentInfo', 'preparedness']);
  return preparedness;
});
export const getParentIntensity = createSelector(getCurrentUser, (currentUser) => {
  const parentIntensity = currentUser.getIn(['studentInfo', 'parentIntensity']);
  return parentIntensity;
});
export const getStudentEnglishLevel = createSelector(getCurrentUser, (currentUser) => {
  const englishLevel = currentUser.getIn(['studentInfo', 'englishLevel']);
  return englishLevel;
});
export const getParentEnglishLevel = createSelector(getCurrentUser, (currentUser) => {
  const parentEnglishLevel = currentUser.getIn(['studentInfo', 'parentEnglishLevel']);
  return parentEnglishLevel;
});
export const getStudentSiblings = createSelector(getCurrentUser, (currentUser) => {
  const siblings = currentUser.getIn(['studentInfo', 'siblings']);
  return siblings;
});

export const getStudentNotesOrRequirement = createSelector(getCurrentUser, (currentUser) => {
  const requirement = currentUser.getIn(['studentInfo', 'requirement']);
  return requirement;
});

export const getAcademicAdvisor = createSelector(getCurrentUser, (currentUser) => {
  const academicAdvisorName = currentUser.getIn(['studentInfo', 'academicAdvisorName']);
  return academicAdvisorName;
});

// onboarding quiz start
export const getQuestions = createSelector(getCurrentUser, (currentUser) => {
  const res = currentUser.get('onboardingQuestions');
  return (res && res.toJS()) || [];
});

export const getQuizResult = createSelector(getCurrentUser, (currentUser) => {
  const res = currentUser.get('onboardingResult');
  return res ? res.toJS() : undefined;
});
export const getQuizResultStatus = createSelector(getCurrentUser, (currentUser) => {
  return currentUser.getIn(['onboardingResult', 'status']);
});
const _convertHexacoScoresToNumber = (hexacoScores) => {
  return Object.entries(hexacoScores).reduce((prev, curr) => ({ ...prev, ...{ [curr[0]]: +curr[1] } }), {});
};
const _convertHexacoSummaryScoresToNumber = (hexacoSummaryScores) => {
  return Object.entries(hexacoSummaryScores).reduce(
    (prev, curr) => ({
      ...prev,
      [curr[0]]: {
        median: +curr[1].median,
        '10_percentile': +curr[1]['10_percentile'],
        '90_percentile': +curr[1]['90_percentile'],
      },
    }),
    {},
  );
};
const _convertResultContent = (resultContent) => {
  const res = {};
  Object.entries(resultContent).forEach(([key, item]) => {
    if (key === 'hexacoScores') {
      res[key] = _convertHexacoScoresToNumber(item);
      return;
    }
    if (key === 'hexacoSummaryScores') {
      res[key] = _convertHexacoSummaryScoresToNumber(item);
      return;
    }
    res[key] = item;
  });
  return res;
};
export const getQuizResultContent = createSelector(getCurrentUser, (currentUser) => {
  const res = currentUser.getIn(['onboardingResult', 'content']);
  return res ? _convertResultContent(res.toJS()) : null;
});
export const getPercentageOfFinishedQuestions = createSelector(getCurrentUser, (currentUser) => {
  const res = currentUser.get('onboardingQuestions');
  const questions = res ? res.toJS() : [];
  // if (quizStatus === 'pending') {
  const percentage =
    questions.reduce((prev, curr) => {
      if (!!get(curr, 'answer')) return prev + 1;
      return prev;
    }, 0) / questions.length;
  return Math.round(percentage * 100);
  // }
  // return 0;
});
export const getPsychometricQuizResult = createSelector(getCurrentUser, (getCurrentUser) => {
  if (!getCurrentUser) return null;
  const hexaco = getCurrentUser.get('hexaco');
  return hexaco ? hexaco.toJS() : null;
});
// onboarding quiz end

export const getStaffInformation = createSelector(getCurrentUser, (getCurrentUser) => {
  if (!getCurrentUser) return null;
  const staffInfo = getCurrentUser.get('staffInfo');
  return staffInfo ? staffInfo.toJS() : null;
});
