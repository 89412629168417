import MultiSelectFilter from './multiSelect.js';

export default class ExpertiseFilter extends MultiSelectFilter {
  constructor(...args) {
    super(...args);
    this.title = 'Expertise';
    this.name = 'Expertise';
  }

  apply(tutor) {
    if (!this.active()) {
      return true;
    }
    const { filtered, map } = this.state;
    return (
      tutor.tutorInfo &&
      filtered.some((expertise) => tutor.tutorInfo.expertise.find((secondary) => map[secondary] === expertise))
    );
  }

  data({ expertise }) {
    const map = expertise.reduce((o, v) => {
      o[v.id] = v.name;
      return o;
    }, {});
    this.setState({
      all: expertise.map((l) => l.name),
      map,
    });
  }

  value() {
    return this.state.filtered;
  }

  simpleValue() {
    return this.value();
  }
}
