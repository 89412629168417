import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import onClickOutside from 'react-onclickoutside';

import css from './styles.scss';
import { handleEnter } from '../../../../../../../utils/accessibility';

class TimeBlock extends Component {
  constructor(props) {
    super(props);

    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClickOutside() {
    this.props.onOutsideClick();
  }

  handleClick(e) {
    e.stopPropagation();
    this.props.onOutsideClick();
    this.props.onClickHandler();
  }

  render() {
    const { key, posY, displayTime, isHalf } = this.props;

    let blockHeight = posY > -1 ? 100 / 24 : 0;
    if (isHalf) {
      blockHeight /= 2;
    }

    return (
      <div
        role="button"
        data-ga-label="Timeblock"
        tabIndex={0}
        className={classNames(css.timeBlock)}
        style={{ top: posY, height: `calc(${blockHeight}% - 3px)`, opacity: `${posY > -1 ? 1 : 0}` }}
        key={key}
        onClick={this.handleClick}
        onKeyDown={handleEnter(this.handleClick)}
      >
        <div className={css.time}>{displayTime}</div>
        <div className={css.plus}>+</div>
      </div>
    );
  }
}

TimeBlock.propTypes = {
  key: PropTypes.string,
  onClickHandler: PropTypes.func,
  posY: PropTypes.number,
  onOutsideClick: PropTypes.func,
  displayTime: PropTypes.string,
  isHalf: PropTypes.bool,
};

export default onClickOutside(TimeBlock);
