import React from 'react';
import PropTypes from 'prop-types';
import { Body } from 'components/atoms/typography';
import fontColour from 'components/atoms/typography/fontColour.scss';
import globalMessageTypes from 'constants/globalMessageTypes';
import ArrowForward from '@material-ui/icons/ArrowForward';
import classNames from 'classnames';
import CountdownSimple from 'components/molecules/CountdownSimple';

import css from './style.scss';
import { handleEnter } from '../../../utils/accessibility';

export default function GlobalMessageBanner(props) {
  const { messages, history, isMobile } = props;

  const typeToClassMapping = {
    [globalMessageTypes.EXAM_IN_PROGRESS]: css.warning,
    [globalMessageTypes.USER_NOT_CONNECTED]: [css.nonClickBanner, css.warning],
    [globalMessageTypes.FEEDBACK_REQUIRED]: css.attention,
    [globalMessageTypes.SESSION_STARTING_SOON]: css.sessionStarting,
    [globalMessageTypes.SESSION_IN_PROGRESS]: css.sessionInProgress,
  };

  const filteredMessages = isMobile
    ? messages.filter((message) => message.type !== globalMessageTypes.EXAM_IN_PROGRESS)
    : messages;

  return (
    <div
      data-ga-category="Global Banner"
      className={css.bannerContainerDiv}
      style={{ height: `${filteredMessages.length * 5}rem` }}
    >
      {filteredMessages.map((message, index) => {
        return (
          <div
            role="button"
            tabIndex={0}
            key={message.type}
            className={classNames(css.bannerContainer, {
              [css.nonClickContainer]: message.type === globalMessageTypes.USER_NOT_CONNECTED,
            })}
            onClick={() => history.push(message.link)}
            onKeyDown={handleEnter(() => history.push(message.link))}
            style={{ top: `${index * 5}rem` }}
          >
            <div className={classNames(css.banner, typeToClassMapping[message.type])}>
              <Body className={classNames(fontColour.contrast, css.messageText)}>
                {message.text}
                {message.countdown && <CountdownSimple end={message.countdown.end} bold />}
              </Body>
              {message.type !== globalMessageTypes.USER_NOT_CONNECTED && <ArrowForward className={css.icon} />}
            </div>
          </div>
        );
      })}
    </div>
  );
}

GlobalMessageBanner.propTypes = {
  messages: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  isMobile: PropTypes.bool,
};
