import { trackEvent } from '@crimson-education/browser-logger';
import { GET_AGENDA_BY_EVENT, client } from 'graphql/api/agenda';
import { GET_NOTES_BY_EVENT } from 'graphql/api/sessionNotes';

const FLOATING_SESSION_CONTEXT_KEY = 'FLOATING_SESSION_CONTEXT';

export type ItemType = {
  agenda?: { html: string; text: string; reFetch: boolean };
  notes?: { html: string; text: string; reFetch: boolean };
  id?: string;
  version: string;
  isDraft: boolean;
};

export type ItemTypeParams = {
  agenda?: { html?: string; text?: string; reFetch?: boolean };
  notes?: { html?: string; text?: string; reFetch?: boolean };
  id?: string;
  version?: string;
  isDraft?: boolean;
};

export const removeSessionItem = ({ sessionId, type }: { sessionId: string; type: 'agenda' | 'notes' }) => {
  localStorage.removeItem(`event-${type}-${sessionId}`);
};

export const setSessionItem = ({
  sessionId,
  type,
  item,
}: {
  sessionId: string;
  type: 'agenda' | 'notes';
  item: ItemTypeParams;
}) => {
  const previousSession = getSessionItem({ sessionId, type });
  localStorage.setItem(
    `event-${type}-${sessionId}`,
    JSON.stringify({
      ...previousSession,
      ...item,
      agenda: type === 'agenda' ? { ...previousSession?.agenda, ...item?.agenda } : previousSession?.agenda,
      notes: type === 'notes' ? { ...previousSession?.notes, ...item?.notes } : previousSession?.notes,
    }),
  );
};

export const getSessionItem = ({ sessionId, type }: { sessionId: string; type: 'agenda' | 'notes' }): ItemType => {
  return JSON.parse(localStorage.getItem(`event-${type}-${sessionId}`) || '{}');
};

export const getFloatingSessionContext = (): {
  sessionId: string;
  visible: boolean;
  bookAsFirstName: string;
  bookAsUserId: string;
} => {
  return JSON.parse(localStorage.getItem(FLOATING_SESSION_CONTEXT_KEY) || '{}');
};

export const setFloatingSessionContext = ({
  sessionId = '',
  visible = false,
  bookAsFirstName = '',
  bookAsUserId = '',
}: {
  sessionId?: string;
  visible?: boolean;
  bookAsFirstName?: string;
  bookAsUserId?: string;
}) => {
  const {
    visible: p_visible,
    sessionId: p_sessionId,
    bookAsFirstName: p_bookAsFirstName,
    bookAsUserId: p_bookAsUserId,
  } = getFloatingSessionContext();
  localStorage.setItem(
    FLOATING_SESSION_CONTEXT_KEY,
    JSON.stringify({
      visible: visible === undefined ? p_visible : visible,
      sessionId: sessionId === undefined ? p_sessionId : sessionId,
      bookAsFirstName: bookAsFirstName === undefined ? p_bookAsFirstName : bookAsFirstName,
      bookAsUserId: bookAsUserId === undefined ? p_bookAsUserId : bookAsUserId,
    }),
  );
};

export const getAgendaByEvent = async (eventId: string): Promise<{ html: string; id: string; version: string }> => {
  const { agenda } = await client.query(GET_AGENDA_BY_EVENT, { eventId });
  return agenda && agenda.length > 0 ? agenda[0] : { html: '', id: '', version: '' };
};

export const getNotesByEvent = async (eventId: string): Promise<{ html: string; id?: string; version?: string }> => {
  const data = await client.query(GET_NOTES_BY_EVENT, { eventId });
  return data?.sessionNote ? data?.sessionNote : { html: '' };
};

export const FLOATING_SESSION_TRACKING_ID = 'FLOATING_SESSION';

export const eventType = {
  Open: 'Open',
  FullScreen: 'Full_screen',
  SaveNotes: 'Save_notes',
  SaveAgenda: 'Save_agenda',
  Minimize: 'Minimize',
  Maximise: 'Maximise',
  Close: 'Close',
};

type trackEventMetadataType = { userId: string; url: string; sessionId: string };

export const getEventParams = ({
  userId,
  url,
  sessionId,
}: {
  userId: string;
  url: string;
  sessionId?: string;
}): trackEventMetadataType => {
  const { sessionId: localSessionId } = getFloatingSessionContext();
  return {
    userId,
    // use local sessionid as default value
    sessionId: sessionId || localSessionId,
    url,
  };
};

export const trackFloatingSessionEvent = ({
  key,
  metadata,
}: {
  key: keyof typeof eventType;
  metadata: trackEventMetadataType;
}) => {
  trackEvent({
    message: `${FLOATING_SESSION_TRACKING_ID}_${eventType[key]}`,
    metadata,
  });
};

export const IdEuqal = (strNumA: string & number, strNumB: string & number): boolean => {
  return Number(strNumA) === Number(strNumB);
};
