import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Acl } from '@crimson-education/common-config';
import Avatar from 'components/molecules/Avatar';
import profileImageUtil from 'utils/profileImage';
import { getAvatarColour } from 'utils/calendarUtils';
import css from './styles.scss';

export default function PeopleListItem(props) {
  const { userId, person } = props;

  let fullName = 'Nobody Selected';
  let firstName = 'Nobody';
  let lastName = 'Selected';
  let image = '';
  let subtitle = 'None';
  let avatarUserId;

  if (person) {
    // Check whether to display the fullname of "Myself".
    if (userId && person.userId === userId) {
      fullName = 'Myself';
      avatarUserId = userId;
    } else {
      fullName = person.fullName;
      avatarUserId = person.userId;
    }

    // Define additional fields.
    const primaryRole = person.roles.find((r) => r.isPrimary) || person.roles[0];
    subtitle = primaryRole?.role?.name;
    firstName = person.firstName;
    lastName = person.lastName;
    image = person.profileImageUrl;

    // Resize image if exists.
    if (image) {
      image = profileImageUtil.getProfileUrlOfSize(image, profileImageUtil.sizes.p100);
    }
  }

  return (
    <div className={css.details}>
      <div className={css.initials}>
        <Avatar
          colourIndex={getAvatarColour(userId || person.userId)}
          firstName={firstName}
          lastName={lastName}
          image={image}
          userId={avatarUserId}
          showStudentType
        />
      </div>
      <div className={css.column}>
        <div className={css.name}>{fullName}</div>
      </div>
      <div className={css.column}>
        <div className={classNames(css.name, css.userDetails)}>{subtitle}</div>
      </div>
      <div className={css.column}>
        <div className={classNames(css.name, css.userDetails)} style={{ textAlign: 'right' }}>
          {person.city}
        </div>
      </div>
    </div>
  );
}

PeopleListItem.propTypes = {
  userId: PropTypes.string,
  person: PropTypes.object,
};
