import React from 'react';
import PropTypes from 'prop-types';

import Cross from 'components/generic/Icons/Cross';

import css from './styles.scss';
import { handleEnter } from '../../../../../../../utils/accessibility';

export default function CancelButton(props) {
  const onClickHandler = () => {
    props.selectBookingAs(props.userId);
  };

  return (
    <span className={css.cancelButton}>
      <div
        role="button"
        data-ga-label="x"
        tabIndex={0}
        data-test-id={props.dataTestId}
        className={css.iconContainer}
        onClick={onClickHandler}
        onKeyDown={handleEnter(onClickHandler)}
      >
        <Cross className={css.cross} />
      </div>
    </span>
  );
}

CancelButton.propTypes = {
  selectBookingAs: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  dataTestId: PropTypes.string,
};
