import { connect } from 'react-redux';

import { getBookingByIdToJS } from 'selectors/booking';
import { confirmBooking, declineBooking } from 'ducks/booking';

import SessionConfirmationActions from './SessionConfirmationActions';

const mapStateToProps = (state, props) => ({
  session: getBookingByIdToJS(props.sessionId)(state),
});

const mapDispatchToProps = (dispatch, props) => {
  const { sessionId, currentSession, userId } = props;
  const id = sessionId || currentSession?.id;
  return {
    confirm: () => dispatch(confirmBooking(id, userId)),
    decline: () => dispatch(declineBooking(id, userId, '')),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SessionConfirmationActions);
