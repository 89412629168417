import React from 'react';
import PropTypes from 'prop-types';
import VersionOne from './VersionOne';
import VersionTwo from './VersionTwo';
import VersionThree from './VersionThree';

export default function SessionReportView(props) {
  const { report } = props;
  if (report.version === 1) {
    return <VersionOne {...props} />;
  } else if (report.version === 2) {
    return <VersionTwo {...props} />;
  } else if (report.version === 3) {
    return <VersionThree {...props} />;
  }
}

SessionReportView.propTypes = {
  report: PropTypes.object.isRequired,
  tutor: PropTypes.object,
};
