import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classnames from 'classnames';
import moment from 'moment';

import attendanceStatus from 'constants/attendanceStatus';
import { formatDate, timeString, createTimeObject } from 'utils/calendarUtils';
import ErrorIcon from 'components/generic/Icons/Error';
import IconButton from 'components/molecules/IconButton';
import packageItemService from 'graphql/api/packageItem';

import AdjustTime from '../AdjustTime';
import css from './styles.scss';
import { asButton, handleEnter } from '../../../../utils/accessibility';

const MINUTE_INCREMENT = 5;

export const EventSummaryModes = {
  VISIBLE: 'VISIBLE',
  TOGGLED_VISIBLE: 'TOGGLED_VISIBLE',
  TOGGLED_HIDDEN: 'TOGGLED_HIDDEN',
  HIDDEN: 'HIDDEN',
};

export default function EventSummary(props) {
  const {
    booking,
    eventSummaryText,
    adjustedStart,
    adjustedEnd,
    adjustedReason,
    onAdjustedReasonChange,
    onAdjustedTimeChange,
    onExtraOptionClick,
    adjustedTimesInvalid,
    adjustedReasonInvalid,
    extraOptionText,
    eventSummaryMode,
    personName,
    toggleEventSummaryMode,
  } = props;
  const [packageItem, setPackageItem] = useState();
  useEffect(() => {
    (async () => {
      if (booking.get('itemId')) {
        const { item } = await packageItemService.getItemById(booking.get('itemId'));
        setPackageItem(item);
      }
    })();
  }, [booking, setPackageItem]);

  const start = booking.get('start');
  const end = booking.get('end');
  let overnight = false;
  if (!createTimeObject(start).time.isSame(createTimeObject(end).time, 'day')) {
    overnight = true;
  }
  const title = booking.get('name');
  const subjectName = booking.getIn(['subject', 'name']);
  const originalTime = timeString(start, end);
  const defaultEventSummaryText = {
    VALIDATION_MESSAGE: 'Please tell us what happened',
    ORIGINALLY_SCHEDULED: `This lesson was originally scheduled for ${originalTime}`,
    RUN_TO_SCHEDULE: `Why didn't the session run to schedule? (${originalTime})`,
    TOGGLE_OPEN: 'This time is not correct',
    TOGGLE_CLOSED: 'Cancel edit mode',
    COMMENTS: '',
  };
  const EventSummaryText = eventSummaryText || defaultEventSummaryText;

  // Disputed Time: Event time reported by other user.
  let disputedTime;
  let disputedStart;
  let disputedEnd;
  if (booking.getIn(['attendance', 'status']) === attendanceStatus.DISAGREE) {
    disputedStart = booking.getIn(['attendance', 'start']);
    disputedEnd = booking.getIn(['attendance', 'end']);
    disputedTime = timeString(disputedStart, disputedEnd);
  }

  const adjustedTime = adjustedStart || adjustedEnd ? timeString(adjustedStart || start, adjustedEnd || end) : null;

  // Define display flags and variables.
  const show = {
    time: true,
    toggle: false,
    adjustTime: false,
    toggleOpenable: true,
    reasonBox: adjustedTime,
    originallyScheduled: false,
    subject: title !== subjectName,
    reasonBoxInvalid: adjustedReasonInvalid,
  };

  // Calculate display flags based on mode.
  if (eventSummaryMode === EventSummaryModes.VISIBLE) {
    show.adjustTime = true;
    show.time = adjustedTime;
  } else if (eventSummaryMode === EventSummaryModes.TOGGLED_VISIBLE) {
    show.toggle = true;
    show.reasonBox = true;
    show.adjustTime = true;
    show.toggleOpenable = false;
  } else if (eventSummaryMode === EventSummaryModes.TOGGLED_HIDDEN) {
    show.toggle = true;
  } else if (eventSummaryMode === EventSummaryModes.HIDDEN) {
    show.originallyScheduled = disputedTime !== undefined;
  }

  const newEnd = adjustedEnd ? adjustedEnd.time : end;
  const isSameday = moment(booking.get('start')).isSame(moment(newEnd), 'day');

  let startTime = start;
  if (adjustedStart) {
    startTime = new Date(adjustedStart.time);
  } else if (disputedStart) {
    startTime = disputedStart;
  }

  let endTime = end;
  if (adjustedEnd) {
    endTime = new Date(adjustedEnd.time);
  } else if (disputedEnd) {
    endTime = disputedEnd;
  }

  const hours = moment(endTime).diff(moment(startTime), 'hours');
  const minutes = moment(endTime).diff(moment(startTime), 'minutes') % 60;
  const hoursFormat = hours > 0 ? `${hours} ${hours > 1 ? 'hours' : 'hour'}` : null;
  const minutesFormat = minutes > 0 ? `${minutes} mins` : null;

  // If the event type is lesson, check if can deduct the hour from the package
  const packageRemainingValue = packageItem && packageItem.remainingValue;
  const packagePendingValue = packageItem && packageItem.pendingValue;

  const actualHours = moment(newEnd).diff(moment(startTime), 'minutes') / 60;

  const isUnlimited = packageItem && packageItem.isUnlimited;

  return (
    <div className={css.eventSummary}>
      <div className={css.eventSummaryCard}>
        <div className={css.title}>{title}</div>

        <div className={css.row}>
          <div className={css.column}>
            {isSameday ? (
              <div className={css.item}>{formatDate(booking.get('start'))}</div>
            ) : (
              <div className={css.item}>
                {moment(booking.get('start')).format('ddd, D MMM')} — {moment(newEnd).format('ddd, D MMM')}
              </div>
            )}
            {show.time && (
              <div
                data-test-id="changedBookingTime"
                className={classnames({
                  [css.subsequentItem]: true,
                  [css.changedTime]: adjustedTime,
                })}
              >
                {disputedTime || originalTime}
              </div>
            )}
            {adjustedTime && <div className={css.subsequentItem}>{adjustedTime}</div>}
          </div>
          <div className={css.column}>
            <div className={css.item}>{personName}</div>
            {show.subject && <div className={css.subsequentItem}>{subjectName}</div>}
          </div>
        </div>

        {show.originallyScheduled && (
          <div className={css.originallyScheduled}>{EventSummaryText.ORIGINALLY_SCHEDULED}</div>
        )}

        {show.toggle && (
          <div className={css.toggle}>
            {show.toggleOpenable && EventSummaryText.TOGGLE_OPEN && (
              <span
                className={css.toggleLink}
                role="button"
                tabIndex={0}
                data-test-id="changeAttendanceTimeInFeedback"
                onClick={toggleEventSummaryMode}
                onKeyDown={handleEnter(toggleEventSummaryMode)}
              >
                {EventSummaryText.TOGGLE_OPEN}
              </span>
            )}
            {!show.toggleOpenable && EventSummaryText.TOGGLE_CLOSED && (
              <span
                className={css.toggleLink}
                role="button"
                tabIndex={0}
                data-test-id="cancelEditAttendanceTimeInFeedback"
                onClick={toggleEventSummaryMode}
                onKeyDown={handleEnter(toggleEventSummaryMode)}
              >
                {EventSummaryText.TOGGLE_CLOSED}
              </span>
            )}
            {onExtraOptionClick && extraOptionText && (
              <span className={css.extraLink} data-test-id="extraOptionText" {...asButton(onExtraOptionClick)}>
                {extraOptionText}
              </span>
            )}
          </div>
        )}
        <div data-test-id="eventCOMMENTS" className={css.comments}>
          {EventSummaryText.COMMENTS}
        </div>
      </div>
      {show.adjustTime && overnight && (
        <div className={css.timeSelectors}>
          <div>{formatDate(start)}</div>
          <div>{formatDate(newEnd)}</div>
        </div>
      )}
      {show.adjustTime && (
        <div className={css.adjustTime}>
          <AdjustTime
            start={createTimeObject(adjustedStart) || createTimeObject(disputedStart) || createTimeObject(start)}
            end={createTimeObject(adjustedEnd) || createTimeObject(disputedEnd) || createTimeObject(end)}
            timesInvalid={adjustedTimesInvalid}
            onAdjustedTimeChange={onAdjustedTimeChange}
            minuteIncrement={MINUTE_INCREMENT}
          />
        </div>
      )}

      {show.reasonBox && (
        <div
          className={classnames({
            [css.reasonBox]: true,
            [css.invalidReasonBox]: show.reasonBoxInvalid,
          })}
        >
          {EventSummaryText.RUN_TO_SCHEDULE}
          <textarea
            onChange={onAdjustedReasonChange}
            className={css.reasonBoxText}
            data-test-id="adjustAttendanceTimeReasonBoxText"
            value={adjustedReason || ''}
          />
          {show.reasonBoxInvalid && (
            <div className={css.errorMessage}>
              <ErrorIcon className={css.alertIcon} />
              {EventSummaryText.VALIDATION_MESSAGE}
            </div>
          )}
        </div>
      )}
      {booking.get('type') === 'Lesson' ? (
        <div className={css.sessionAlert}>
          <IconButton icon="AccessTime" className={css.accessTimeIcon} iconSize={{ height: '2rem', width: '2rem' }} />
          <span className={css.desc}>Package hour deduction</span>
          <span className={css.deductHour}>
            {hoursFormat} {minutesFormat}
          </span>
        </div>
      ) : null}
      {!isUnlimited && packageRemainingValue + packagePendingValue < actualHours ? (
        <div className={css.remindMessage}>There&apos;s not enough hours left in the package for this session.</div>
      ) : null}
    </div>
  );
}

EventSummary.propTypes = {
  booking: ImmutablePropTypes.map.isRequired,
  personName: PropTypes.string.isRequired,
  eventSummaryMode: PropTypes.string.isRequired,
  toggleEventSummaryMode: PropTypes.func,
  adjustedStart: PropTypes.object,
  adjustedEnd: PropTypes.object,
  adjustedReason: PropTypes.string,
  adjustedReasonInvalid: PropTypes.bool,
  adjustedTimesInvalid: PropTypes.bool,
  onAdjustedTimeChange: PropTypes.func,
  onAdjustedReasonChange: PropTypes.func,
  eventSummaryText: PropTypes.object,
  extraOptionText: PropTypes.string,
  onExtraOptionClick: PropTypes.func,
};
