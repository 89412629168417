import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import autoBind from 'auto-bind';
import TIM from 'tim-js-sdk';
import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';

import * as Logger from '@crimson-education/browser-logger';

import MessageField from 'components/generic/Forms/MessageField';
import LoadingBar from 'components/molecules/LoadingBar';
import RequestBookingModal from 'components/organisms/BookingModals/RequestBookingModal';
import { Acl, roleTypes } from '@crimson-education/common-config';
import { messageTypes, messageStatus } from 'constants/messaging';
import throttle from 'lodash/throttle';

import Landing from './Landing';
import MessageWindow from '../MessageWindow';
import MessageHeader from '../MessageHeader';
import css from './styles';
import tim, { sendMessageC2C, createGroupIM } from '../../../../tim/tim';
import GroupPeople from '../GroupPeople';
import { MessageEventType } from '../../../../utils/MessageEventType';

const config = getConfig();

export default class MessageBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bookingFormModalIsOpen: false,
      showGroup: false,
      addMorePeopleModalIsOpen: false,
      removePeopleModalIsOpen: false,
      removePeopleUserId: '',
    };

    autoBind(this);
  }

  componentDidUpdate(prevProps) {
    const { fetchBookingWith, otherParticipants } = this.props;

    if (!otherParticipants) {
      return;
    }

    const unfetchedBookingUsers = otherParticipants.reduce((a, x) => {
      if (!prevProps.otherParticipants || !prevProps.otherParticipants.find((p) => p.userId === x.userId)) {
        a.push(x.userId);
      }

      return a;
    }, []);

    if (unfetchedBookingUsers.length) {
      fetchBookingWith({ users: unfetchedBookingUsers });
    }
  }
  onShowGroup() {
    this.setState(({ showGroup }) => ({ showGroup: !showGroup }));
  }

  setTyping(threadId) {
    if (!this.isSetTypingFunc) {
      const throttleTime = 500; // 0.5 seconds
      this.isSetTypingFunc = throttle((threadId) => {
        const { thread, currentUser } = this.props;
        const recipients = thread.participants.map((p) => p.userId).filter((userId) => userId !== currentUser.userId);
        recipients.forEach((recipient) => {
          const content = {
            message: 'isTyping',
            type: 'action',
            threadId,
            source: thread.source,
          };
          sendMessageC2C(recipient, content, currentUser.userId);
        });
      }, throttleTime);
    }
    this.isSetTypingFunc(threadId);
  }

  openQuickBooking() {
    Logger.trackEvent({ message: 'quick book button' });
    this.setState({ bookingFormModalIsOpen: true });
  }

  closeQuickBooking(callback) {
    this.setState({ bookingFormModalIsOpen: false });
    if (typeof callback === 'function') {
      callback();
    }
  }

  toggleAddEditModal() {
    this.setState(({ addMorePeopleModalIsOpen }) => ({ addMorePeopleModalIsOpen: !addMorePeopleModalIsOpen }));
  }
  toggleRemoveEditModal() {
    this.setState({ removePeopleUserId: '' });
    this.setState(({ removePeopleModalIsOpen }) => ({ removePeopleModalIsOpen: !removePeopleModalIsOpen }));
  }

  isArtificialThread() {
    const { skeleton, isNew } = this.props;

    return skeleton || isNew;
  }

  async sendFile(type, fileName) {
    const { thread, currentUser, sendMessage } = this.props;

    if (thread.participants) {
      const hasBot = !!thread.participants.map((x) => x.user).find((x) => Acl.checkRole(x.userRoles, roleTypes.BOT));

      if (hasBot) {
        this.props.onBotMessage();
      }
    }
    const conversationId = Math.random();
    await sendMessage(thread.id, currentUser.userId, fileName, type, null, conversationId, 1, true);
    if (this.checkLatestMessage()) {
      const recipients = thread.participants.map((p) => p.userId).filter((userId) => userId !== currentUser.userId);
      recipients.forEach((recipient) => {
        const content = {
          message: type,
          type: 'message',
          threadId: thread.id,
          source: thread.source,
        };
        const message = tim.createCustomMessage({
          to: `${config.timConfig.source}-${recipient}`,
          conversationType: TIM.TYPES.CONV_C2C,
          payload: {
            data: JSON.stringify(content),
          },
        });
        tim
          .sendMessage(message)
          .then(() => {
            const eventInfo = {
              fromUserId: currentUser.userId,
              toUserId: recipient,
              threadId: thread.id,
            };
            Logger.trackEvent({ message: MessageEventType.TIMSentMessage, metadata: eventInfo });
          })
          .catch((imError) => {
            Logger.reportError(imError);
            Logger.trackEvent({ message: MessageEventType.SendMessageError, metadata: { imError } });
            if (imError.message === 'Network Error') {
              // sendMessageError(conversationId, null, imError.message);
            }
          });
      });
      // this.sendMessageToTim(thread, messageTxt, conversationId, null, currentUser.userId);
    }
  }
  sendImage() {
    const { thread, currentUser, sendMessage, sendFileError } = this.props;

    const recipients = thread.participants.map((p) => p.userId).filter((userId) => userId !== currentUser.userId);
    let toAccount = null;
    let currentConversationType = TIM.TYPES.CONV_C2C;
    if (recipients.length > 2) {
      toAccount = thread.id;
      currentConversationType = TIM.TYPES.GRP_PRIVATE;
    } else {
      toAccount = `${config.timConfig.source}-${recipients[0]}`;
    }
    const file = document.getElementById('imagePicker');
    if (typeof file === 'undefined') {
      // warning the file is undefined
      return;
    }
    const message = tim.createImageMessage({
      to: toAccount,
      conversationType: currentConversationType,
      payload: {
        file, // 或者用event.target
      },
      onProgress: (event) => {
        sendMessage(
          thread.id,
          currentUser.userId,
          '',
          messageTypes.IMAGE,
          JSON.stringify(message.payload),
          message.ID,
          event,
          false,
        );
      },
    });
    tim
      .sendMessage(message)
      .then(() => {
        sendMessage(
          thread.id,
          currentUser.userId,
          '',
          messageTypes.IMAGE,
          JSON.stringify(message.payload),
          message.ID,
          1,
          true,
        );
        recipients.forEach((recipient) => {
          const content = {
            message: 'file',
            type: 'message',
            threadId: thread.id,
            source: thread.source,
          };
          sendMessageC2C(recipient, content, currentUser.userId);
        });
      })
      .catch(() => {
        sendFileError(message.ID);
        createGroupIM(thread.id, currentUser.userId);
      });
  }

  sendMessageToTim(thread, messageTxt, conversationId, messageId, currentUserId) {
    // const { sendMessageError } = this.props;
    const recipients = thread.participants.map((p) => p.userId).filter((userId) => userId !== currentUserId);
    recipients.forEach((recipient) => {
      const content = {
        messageTxt,
        type: 'message',
        threadId: thread.id,
        source: thread.source,
      };
      const message = tim.createCustomMessage({
        to: `${config.timConfig.source}-${recipient}`,
        conversationType: TIM.TYPES.CONV_C2C,
        payload: {
          data: JSON.stringify(content),
        },
      });
      tim
        .sendMessage(message)
        .then(() => {
          const eventInfo = {
            from: currentUserId,
            to: recipient,
            threadId: thread.id,
          };
          Logger.trackEvent({ message: MessageEventType.TIMSentMessage, metadata: eventInfo });
        })
        .catch((imError) => {
          Logger.reportError(imError);
          Logger.trackEvent({ message: MessageEventType.SendMessageError, metadata: { imError } });
          if (imError.message === 'Network Error') {
            // sendMessageError(conversationId, messageId, imError.message);
          }
        });
    });
  }

  async checkLatestMessage() {
    const { getLatestMessageStatus } = this.props;
    if (getLatestMessageStatus === messageStatus.SENT) {
      return true;
    }
    return false;
  }

  async sendMessage(messageTxt) {
    const { thread, currentUser, sendMessage } = this.props;

    if (thread.participants) {
      const hasBot = !!thread.participants.map((x) => x.user).find((x) => Acl.checkRole(x.userRoles, roleTypes.BOT));

      if (hasBot) {
        this.props.onBotMessage();
      }
    }
    const conversationId = Math.random();
    await sendMessage(thread.id, currentUser.userId, messageTxt, messageTypes.TEXT, null, conversationId, 1, true);
  }

  async retrySendMessage(messageId, message, retry) {
    const { thread, currentUser, retrySendMessage } = this.props;

    // const conversationId = Math.random();
    if (retry) {
      await retrySendMessage(messageId);
    }
    if (this.checkLatestMessage()) {
      this.sendMessageToTim(thread, message, null, messageId, currentUser.userId);
    }
  }
  async withdrawMessage(messageId, threadId) {
    const { withdrawMessage } = this.props;
    await withdrawMessage(messageId, true);
    const { thread, currentUser } = this.props;
    const recipients = thread.participants.map((p) => p.userId).filter((userId) => userId !== currentUser.userId);
    recipients.forEach((recepient) => {
      const content = {
        messageId,
        type: 'withdraw',
        threadId,
        source: thread.source,
      };
      sendMessageC2C(recepient, content);
    });
  }
  closeGroup() {
    this.setState({ showGroup: false });
  }

  renderThreadDescription(users) {
    const userRoles = users.map((u) => {
      if (u.roles) {
        const primaryRole = u.roles.find((r) => r.isPrimary) || u.roles[0];
        return primaryRole.role.name;
      }
      return undefined;
    });
    if (userRoles.length > 2) {
      return `${userRoles.slice(0, 2).join(', ')} +${users.length - 2} others`;
    }
    return userRoles.join(', ');
  }

  renderThread() {
    const {
      thread,
      otherParticipants,
      threadName,
      chatName,
      editGroupName,
      currentUser,
      uploadFile,
      uploadedFile,
      downloadMessageFile,
      addMorePeople,
      removePeopleFromGroup,
      allActiveParticipants,
      featureFlags,
    } = this.props;

    if (!thread) {
      return <LoadingBar />;
    }
    const otherUsers = (otherParticipants || []).map((x) => x.user);
    const allActiveUsers = (allActiveParticipants || []).map((x) => x.user);
    const canEdit = thread.createdBy ? currentUser.userId === thread.createdBy : true;
    return (
      <React.Fragment>
        {this.state.bookingFormModalIsOpen && (
          <RequestBookingModal
            userName={otherUsers.map((x) => `${x.firstName} ${x.lastName}`)}
            isOpen={this.state.bookingFormModalIsOpen}
            onCancel={this.closeQuickBooking}
            selectedUserId={otherUsers.map((x) => x.userId)}
          />
        )}
        <div className={css.messageBar}>
          <MessageHeader
            title={threadName}
            subject={this.renderThreadDescription(otherUsers)}
            threadUsers={allActiveUsers}
            chatName={chatName}
            editGroupName={editGroupName}
            thread={thread}
            threadId={thread.id}
            chatType={thread.type}
            canEdit={canEdit}
            isMobile={this.props.app.isMobile}
            openQuickBooking={this.openQuickBooking}
            onChangeState={this.onShowGroup}
          />
          <MessageWindow
            {...this.props}
            retryMessage={(id, message) => this.retrySendMessage(id, message, true)}
            retrySend={(id, message) => this.retrySendMessage(id, message, false)}
            withdrawMessage={(id, threadId) => this.withdrawMessage(id, threadId)}
            downloadMessageFile={downloadMessageFile}
          />
          <MessageField
            id={thread.id}
            onSubmit={this.sendMessage}
            fieldClass={css.messageField}
            onChange={() => this.setTyping(thread.id)}
            showQuickBook={thread.type === 'private'}
            openQuickBooking={this.openQuickBooking}
            sendFile={this.sendFile}
            sendImage={this.sendImage}
            uploadFile={uploadFile}
            uploadedFile={uploadedFile}
            isMobile={this.props.app.isMobile}
          />
        </div>
        <div className={css.vertical} />
        {this.state.showGroup ? (
          <GroupPeople
            thread={thread}
            currentUser={currentUser}
            allActiveUsers={allActiveUsers}
            addMorePeople={addMorePeople}
            removePeopleFromGroup={removePeopleFromGroup}
            closeGroup={this.closeGroup}
            isMobile={this.props.app.isMobile}
            featureFlags={featureFlags}
          />
        ) : null}
      </React.Fragment>
    );
  }

  render() {
    const { skeleton } = this.props;
    return skeleton ? (
      <div className={css.messageBar}>
        <div className={classnames(css.messages, css.landingPage)}>
          <Landing />
        </div>
      </div>
    ) : (
      this.renderThread()
    );
  }
}

MessageBar.propTypes = {
  socket: PropTypes.object,
  app: PropTypes.object,
  skeleton: PropTypes.bool.isRequired,
  isNew: PropTypes.bool.isRequired,
  thread: PropTypes.object,
  currentUser: PropTypes.object,
  messages: PropTypes.object,
  sendMessage: PropTypes.func,
  fetchBookingWith: PropTypes.func,
  onBotMessage: PropTypes.func,
  threadName: PropTypes.string,
  history: PropTypes.object.isRequired,
  otherParticipants: PropTypes.array,
  updateTypingStatus: PropTypes.func,
  lastSeenMessageId: PropTypes.string,
  sendFileError: PropTypes.func,
  sendMessageError: PropTypes.func,
  retrySendMessage: PropTypes.func,
  getLatestMessageStatus: PropTypes.string,
  chatName: PropTypes.string,
  editGroupName: PropTypes.func.isRequired,
  withdrawMessage: PropTypes.func,
  uploadFile: PropTypes.func,
  uploadedFile: PropTypes.object,
  downloadMessageFile: PropTypes.func,
  addMorePeople: PropTypes.func,
  removePeopleFromGroup: PropTypes.func,
  allActiveParticipants: PropTypes.array,
  featureFlags: PropTypes.object,
};
