import 'date-fns';
import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';

import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { selectUserProfile } from 'selectors/meta';
import { roleTypes } from '@crimson-education/common-config';

import Button from '@material-ui/core/Button';
import { withAppContext } from 'components/enhancers/AppContext';
import CGAReportCard from 'components/organisms/CGAReportCard';
import Header from 'components/molecules/CGAHeader';
import ReportStatusToggle from 'components/molecules/ReportStatusToggle';
import { Dialog, Select } from 'design-system';
import DatePicker from 'design-system/components/atoms/DatePicker';
import LoadingCircle from 'components/molecules/LoadingCircle';
import colours from 'components/atoms/colours.scss';
import api from 'graphql/api/cga';

import Unauthorised from '../Unauthorised';
import { reportTypes } from './utils';
import css from './styles.scss';

// container for the card view
const CGAReports = (props) => {
  const {
    app: { isMobile },
  } = props;
  const loginUser = useSelector(selectUserProfile);
  const [selectedIntake, setSelectedIntake] = useState(0);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedType, setSelectedType] = useState('term1_signpost');
  const [fetchedReports, setFetchedReports] = useState([]);
  const [fetchedIntakes, setFetchedIntakes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [generating, setGenerating] = useState(false);
  const [selectedReportStatus, setSelectedReportStatus] = useState({ ready: true, sent: true, generating: true });
  const dialogRef = useRef();

  const isAuthorised = loginUser
    .get('roleInfo')
    .find((r) => [roleTypes.SUPER_ADMIN, roleTypes.CGA_STAFF].includes(r.get('roleId')));

  const fetchReports = () => {
    setLoading(true);
    setFetchedReports([]);
    api.listCGAReports().then(({ listCGAReports: reports }) => {
      setLoading(false);
      return reports && setFetchedReports(reports);
    });
  };

  useEffect(() => {
    if (!isAuthorised) return;

    fetchReports();
    api.getStreamIntakes().then(({ getCGAStreamIntakes: intakes }) => intakes && setFetchedIntakes(intakes));
  }, [isAuthorised]);

  const openGenerateReportDialog = () => {
    dialogRef.current && dialogRef.current.show();
  };

  /** Event handlers */
  const generateReport = async () => {
    const day = selectedDate.getDate();
    const month = selectedDate.getMonth();
    const report = {
      intake_id: fetchedIntakes[selectedIntake].id,
      type: selectedType,
      publish_date: `${selectedDate.getFullYear()}-${month < 9 ? '0' : ''}${month + 1}-${day < 0 ? '0' : ''}${day}`,
    };
    setGenerating(true);
    await api.createReport(report);
    setGenerating(false);
    fetchReports();
    dialogRef.current && dialogRef.current.hide();
  };

  const handleSelected = (selected) => {
    setSelectedReportStatus(selected);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleSelectedIntake = (item) => {
    const intakeIds = fetchedIntakes.map((intake) => intake.id);
    setSelectedIntake(intakeIds.indexOf(item.id));
  };

  const handleSelectedType = (item) => {
    setSelectedType(item.value);
  };
  /** End event handlers */

  if (!isAuthorised) {
    return <Unauthorised />;
  }

  return (
    <div className={classNames(css.cgaReports)} data-ga-category="CGA Reports">
      <Header title="Reports" />
      <div className={classNames('horiz', css.actions)}>
        <div className={classNames('grow', css.chips)}>
          <ReportStatusToggle toggleSelected={handleSelected} selectedStatus={selectedReportStatus} />
        </div>
        <div className={classNames(isMobile ? 'grow' : 'u-1-4', 'align-self', 'justify-end end')}>
          <div className={classNames('horiz', 'align-self', 'end')}>
            <Button
              className={classNames('ds-button', 'grow', css.generateReportsBtn, isMobile ? css.mobileButton : '')}
              color="default"
              onClick={() => openGenerateReportDialog()}
            >
              generate new report batch
            </Button>
          </div>
        </div>
      </div>

      {loading && (
        <div style={{ width: '100%', margin: '8rem auto', textAlign: 'center' }}>
          <LoadingCircle color={colours.crimsonRed} />
        </div>
      )}

      {/* list of reports  */}
      <div className={css.reports}>
        {fetchedReports.map(
          (report) => selectedReportStatus[report.status] && <CGAReportCard key={report.id} {...report} />,
        )}
      </div>
      <Dialog title="Report Batch settings" ref={dialogRef} fullWidth={false} maxWidth="md">
        <div className={classNames('vert', 'align-items', 'center', 'justify-end', css.generateReportModal)}>
          <h3 className={classNames(css.modalTitleText)}>These are applied to all reports generated.</h3>
          <div className={classNames('horizontal', 'align-self', 'start', css.actions)}>
            <div className={classNames('u-2-4')}>
              <div className={classNames('grow', css.selector)}>
                <Select
                  style={{ fontFamily: 'inherit !important' }}
                  label="Intake"
                  inputText={(item) =>
                    function displayName() {
                      return <div className={classNames('bread-crumb')}>{item.display_name}</div>;
                    }
                  }
                  items={fetchedIntakes}
                  map={(item) => item.display_name}
                  onChange={handleSelectedIntake}
                  color="default"
                  className={classNames(css.chips, css.select)}
                />
              </div>
            </div>
            <div className={classNames('u-2-4')}>
              <DatePicker label="Date" value={selectedDate} onChange={handleDateChange} />
            </div>
          </div>
          <div className={classNames('horizontal', 'align-self', 'start', css.actions)}>
            <div className={classNames('u-2-4', 'grow')}>
              <div className={classNames(css.selector)}>
                <Select
                  label="Type"
                  inputText={(item) =>
                    function label() {
                      return <div className={classNames('bread-crumb')}>{item.label}</div>;
                    }
                  }
                  items={reportTypes}
                  map={(item) => item.label}
                  onChange={handleSelectedType}
                  color="default"
                  className={classNames(css.chips, css.select)}
                />
              </div>
            </div>
          </div>
          {generating && (
            <div style={{ width: '100%', textAlign: 'center' }}>
              <LoadingCircle color={colours.crimsonRed} />
            </div>
          )}
          <div className={classNames(css.actions, css.generateBtn)}>
            <Button className={classNames('ds-button')} color="primary" onClick={() => generateReport()}>
              Generate
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

CGAReports.propTypes = {
  app: PropTypes.object,
};

export default withAppContext(CGAReports);
