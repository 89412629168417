import React from 'react';
import PropTypes from 'prop-types';

const colors = ['black', 'red', 'green', 'blue', 'yellow', 'cyan', 'pink', 'grey', 'white'];

function Toolbar({ fontSizes, id, className }) {
  return (
    <div id={id} className={className}>
      <span className="ql-formats">
        <select style={{ width: '6rem' }} className="ql-size">
          <option value="">Size</option>
          {fontSizes.map((size) => {
            return (
              <option key={size} value={`${size}px`}>
                {size}
              </option>
            );
          })}
        </select>
      </span>
      <span className="ql-formats">
        <select title="Color" className="ql-color">
          {colors.map((color) => (
            <option key={color} value={color} />
          ))}
        </select>
      </span>
      <span className="ql-formats">
        <button className="ql-bold" />
        <button className="ql-italic" />
        <button className="ql-underline" />
        <button className="ql-strike" />
      </span>

      <span className="ql-formats">
        <button className="ql-list" value="ordered" />
        <button className="ql-list" value="bullet" />
      </span>

      <span className="ql-formats">
        <button className="ql-indent" value="-1" />
        <button className="ql-indent" value="+1" />
      </span>

      <span className="ql-formats">
        <button className="ql-align" />
        <button className="ql-align" value="center" />
        <button className="ql-align" value="right" />
        <button className="ql-align" value="justify" />
      </span>
    </div>
  );
}

export default Toolbar;

Toolbar.propTypes = {
  id: PropTypes.string.isRequired,
  fontSizes: PropTypes.array.isRequired,
  className: PropTypes.string,
};
