import React, { useContext } from 'react';
import { Row } from '../../index.style';
import GroupButton, { GroupButtonProps } from '../GroupButton';
import { StyledSwitch } from './index.style';
import { useState } from 'react';
import { StyledTableCell } from '../../index.style';
import { ApplicationGroup, Application } from '../../type';
import { AppTrackerContext } from '../../viewController';
import DeleteApplicationButton from '../../components/DeleteApplicationButton';
import style from './style.scss';
import { useIsAccessibleStaff, useRequesterUserId } from '../../helper';
export type ActionCellProps = {
  hadCrimsonSupport: boolean;
  group: ApplicationGroup;
  application: Application;
  getExtraDeleteAppButtonProps: (
    application: Application,
  ) => {
    getConfirmDeletionText: (value: string | null) => string;
    options: { label: string; value: string }[];
    onConfirmDeleteOption: (value: string, applicationId: string) => Promise<boolean>;
    menuItemStyle?: React.CSSProperties;
  };
} & Pick<GroupButtonProps, 'getExtraActionButtonProps'>;

const ActionCell = ({
  hadCrimsonSupport,
  application,
  getExtraActionButtonProps,
  getExtraDeleteAppButtonProps,
}: ActionCellProps) => {
  const { options, onConfirmDeleteOption, ...restDeleteBtnProps } = getExtraDeleteAppButtonProps(application);
  const [loading, setLoading] = useState(false);
  const { controller } = useContext(AppTrackerContext);
  const isAccessibleStaff = useIsAccessibleStaff();
  const requesterUserId = useRequesterUserId();
  const onChangeSwitch = async (event: React.ChangeEvent<HTMLButtonElement>) => {
    if (loading || !isAccessibleStaff) return;
    if (application.group === 'APPLYING') {
      const canEdit = controller.checkIfEditable(false, isAccessibleStaff, application, true);
      if (!canEdit) return;
    }
    setLoading(true);
    await controller.updateApplication(
      application.id,
      { hadCrimsonSupport: !hadCrimsonSupport },
      { updatedBy: requesterUserId },
    );
    setLoading(false);
  };
  return (
    <StyledTableCell>
      <Row justifycontent={'space-between'} rowcenter>
        <StyledSwitch toggleOnClassname={style.switchOn} on={hadCrimsonSupport} handleToggle={onChangeSwitch} />
        <Row rowcenter>
          <GroupButton application={application} getExtraActionButtonProps={getExtraActionButtonProps} />
          <DeleteApplicationButton
            onConfirmDeleteOption={onConfirmDeleteOption}
            options={options}
            application={application}
            {...restDeleteBtnProps}
          />
        </Row>
      </Row>
    </StyledTableCell>
  );
};

export default ActionCell;
