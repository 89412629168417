import { Component } from 'react';
import PropTypes from 'prop-types';

const REDIRECT_URL = '/my-profile/about';

export default class AppCuesRedirect extends Component {
  componentDidMount() {
    const { history } = this.props;
    history.push(REDIRECT_URL);
  }

  render() {
    return null;
  }
}

AppCuesRedirect.propTypes = {
  history: PropTypes.object.isRequired,
};
