import SelectFilter from './select.js';

export default class StarRatingFilter extends SelectFilter {
  constructor(...args) {
    super(...args);
    this.title = 'Star Rating';
    this.name = 'Star Rating';
    this.options = {
      'Show all ( 1 star and more )': null,
      '2 stars and more': 2,
      '3 stars and more': 3,
      '4 stars and more': 4,
      '5 stars': 5,
    };

    this.state.all = Object.keys(this.options);
  }

  apply(tutor) {
    if (!this.active()) {
      return true;
    }
    const number = this.options[this.state.filtered];
    if (number === null) {
      return true;
    }
    return tutor.tutorInfo.averageRating >= number;
  }

  data() {
    /* Doesn't use data */
  }

  value() {
    if (!this.options[this.state.filtered]) {
      return [];
    }
    return this.options[this.state.filtered];
  }

  simpleValue() {
    return this.value();
  }
}
