import styled from 'styled-components';
import Radio from '@material-ui/core/Radio';
import { PrimaryButton } from '../index.style';

export const Container = styled.div`
  background: #f4f5ff;
  border: 1px dashed rgba(70, 74, 201, 0.3);
  border-radius: 8px;
  margin-bottom: 40px;
`;

export const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 24px 16px 24px;
  .left-side {
    display: flex;
    justify-content: center;
    img.folder-icon {
      margin-right: 16px;
    }
    .title-container {
      display: flex;
      flex-direction: column;
      row-gap: 4px;
      .title {
        color: #1d1e2b;
        font-size: 16px;
        font-weight: 700;
      }
      .sub-title {
        color: #73747d;
        font-size: 14px;
      }
    }
  }
`;

export const StyledRadio = styled(Radio)`
  &.MuiRadio-root {
    color: #73747d;
    padding: 4px;
    &.Mui-checked {
      color: #464ac9;
    }
  }
`;

export const RadioLabelText = styled.div`
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 14px;
  color: #73747d;
`;

export const StyledPrimaryButton = styled(PrimaryButton)`
  padding: 0 48px;
`;
