import Immutable from 'immutable';
import createReducer from 'utils/createReducer';
import { Acl, toasterMessages } from '@crimson-education/common-config';
import lesson from 'graphql/api/lesson';
import firebaseClient from 'firebase/firebaseClient';
import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';
import { formLoading, formLoaded, formSuccess, formFail } from 'ducks/meta';
import { ADD_ENTITIES, addEntities, addEntitiesWithNormalisation } from 'ducks/normalizr';
import { commentEntity } from 'schema';
import {
  LESSON_DETAIL_SHARED_FILE,
  LESSON_DETAIL_COMMENTED,
  LESSON_DETAIL_REPLY,
  LESSON_DETAIL_ADMIN_DELETE_COMMENT,
  LESSON_DETAIL_ADMIN_DELETE_FILE,
} from 'constants/lessonDetailsNotificationTypes';
import { sendLessonNotification, uploadFile, addToParticipantList, getCommentEntities } from 'utils/commentUtils';

const { firebase: config } = getConfig();

const DELETE_FILE_SUCCEEDED = 'comment/DELETE_FILE_SUCCEEDED';
const EDIT_COMMENT_SUCCEEDED = 'comment/EDIT_COMMENT_SUCCEEDED';
const DELETE_COMMENT_SUCCEEDED = 'comment/DELETE_COMMENT_SUCCEEDED';

const initialState = Immutable.fromJS({});

export default createReducer(initialState, {
  [ADD_ENTITIES]: (state, action) => {
    return state.mergeDeep(action.payload.entities.comment);
  },
  [DELETE_FILE_SUCCEEDED]: (state, action) => {
    const { lessonId, parentId, replyId, fileId } = action.payload;
    const normalisedFilesList = state.getIn([lessonId, 'files']).filter((item) => item.get('id') !== fileId);
    // ^ Filter file matching deleteId from list
    if (replyId) {
      return state
        .deleteIn([lessonId, 'conversations', parentId, 'replies', replyId, 'file'])
        .setIn([lessonId, 'files'], normalisedFilesList);
    }
    return state
      .deleteIn([lessonId, 'conversations', parentId, 'file'])
      .setIn([lessonId, 'files'], normalisedFilesList);
  },
  [DELETE_COMMENT_SUCCEEDED]: (state, action) => {
    const { lessonId, parentId, replyId, fileDeletions } = action.payload;
    let normalisedFilesList = state.getIn([lessonId, 'files']);
    if (fileDeletions.length && normalisedFilesList) {
      normalisedFilesList = normalisedFilesList.filterNot((f) => fileDeletions.includes(f.get('id')));
    }

    if (replyId) {
      return state
        .deleteIn([lessonId, 'conversations', parentId, 'replies', replyId])
        .setIn([lessonId, 'files'], normalisedFilesList);
    }

    return state.deleteIn([lessonId, 'conversations', parentId]).setIn([lessonId, 'files']);
  },
  [EDIT_COMMENT_SUCCEEDED]: (state, action) => {
    const { lessonId, parentId, replyId, message, editedTimestamp } = action.payload;
    const normalisedUpdatePath = [lessonId, 'conversations', parentId];

    if (replyId) {
      normalisedUpdatePath.push('replies');
      normalisedUpdatePath.push(replyId);
    }

    return state.updateIn(normalisedUpdatePath, (comment) => {
      return comment.set('message', message).set('editedTimestamp', editedTimestamp);
    });
  },
});

export function commentDeleted(payload) {
  return { type: DELETE_COMMENT_SUCCEEDED, payload };
}

export function commentFileDeleted(payload) {
  return { type: DELETE_FILE_SUCCEEDED, payload };
}

export function commentUpdated(payload) {
  return { type: EDIT_COMMENT_SUCCEEDED, payload };
}

// TODO: handle rejection from firebase client, review error handling
export function fetchComments(lessonId) {
  return (dispatch) => {
    return firebaseClient.getFromFirebase(`/${config.lessonPath}/${lessonId}`).then((response) => {
      const conversations = response ? response.conversations : {};
      const participants = response ? response.participants : {};
      dispatch(addEntitiesWithNormalisation({ lessonId, conversations, participants }, commentEntity));
    });
  };
}

export function fetchSharedFiles(lessonId) {
  return (dispatch) => {
    return lesson.fetchSharedFiles(lessonId).then((sharedFileInfo) => {
      if (!sharedFileInfo.fileInfo) {
        dispatch(formFail(toasterMessages.commentFileNotRetrieved()));
        return Promise.reject(new Error('Failed to retrieve shared files'));
      }

      dispatch(addEntitiesWithNormalisation({ lessonId, files: sharedFileInfo.fileInfo }, commentEntity));
      return Promise.resolve();
    });
  };
}

/**
 * POST COMMENT
 * Notify all participants except intitiating user.
 *
 * TODO: handle rejection from firebaseClient
 *
 * @export
 * @param {string} userId
 * @param {string} lessonId
 * @param {string} comment
 * @param {Object} file
 * @param {Object} lessonDetails
 * @returns
 */
export function postComment(userId, lessonId, comment, file, lessonDetails) {
  return (dispatch) => {
    dispatch(formLoading());

    // TODO: consistently handle errors - reject within "then" and "catch" once on top level
    return uploadFile(lessonId, file, userId).then((uploadedFile) => {
      if (file && !uploadedFile) {
        dispatch(formFail(toasterMessages.commentFileNotUploaded()));
        return Promise.reject(new Error('File upload failed'));
      }

      const message = {
        message: comment,
        user_id: userId,
        timestamp: firebaseClient.TimestampPlaceholder,
      };

      if (file && uploadedFile) {
        message.file = {
          name: uploadedFile.name,
          size: uploadedFile.size,
          type: uploadedFile.type,
          id: uploadedFile.id,
        };
      }

      return firebaseClient
        .pushToFirebase(`/${config.lessonPath}/${lessonId}/conversations/`, message)
        .then((key) => {
          if (!key) {
            dispatch(formFail(toasterMessages.commentNotSent()));
            return null;
          }

          // add user to particiant list if not already (admin post for first time)
          addToParticipantList(lessonId, userId);

          const timestamp = Date.now();
          const entities = getCommentEntities(message, lessonId, key, timestamp);

          dispatch(formLoaded());
          dispatch(addEntities(entities));

          const notificationType = file ? LESSON_DETAIL_SHARED_FILE : LESSON_DETAIL_COMMENTED;
          return sendLessonNotification(notificationType, lessonDetails, comment, file);
        })
        .catch(() => {
          dispatch(formFail(toasterMessages.commentNotSent()));
        });
    });
  };
}

/**
 * POST REPLY
 * Notify all participants in lesson except intiating user.
 *
 * TODO: handle rejection from firebaseClient
 *
 * @export
 * @param {string} userId
 * @param {string} lessonId
 * @param {string} parentId
 * @param {string} comment
 * @param {Object} file
 * @param {Object} lessonDetails
 * @returns
 */
export function postReply(userId, lessonId, parentId, comment, file, lessonDetails) {
  return (dispatch) => {
    dispatch(formLoading());

    return uploadFile(lessonId, file, userId).then((uploadedFile) => {
      if (file && !uploadedFile) {
        dispatch(formFail(toasterMessages.commentFileNotUploaded()));
        return Promise.reject(new Error('File upload failed'));
      }

      const message = {
        message: comment,
        user_id: userId,
        timestamp: firebaseClient.TimestampPlaceholder,
      };

      if (file && uploadedFile) {
        message.file = {
          name: uploadedFile.name,
          size: uploadedFile.size,
          type: uploadedFile.type,
          id: uploadedFile.id,
        };
      }

      return firebaseClient
        .pushToFirebase(`/${config.lessonPath}/${lessonId}/conversations/${parentId}/replies/`, message)
        .then((key) => {
          if (!key) {
            dispatch(formFail(toasterMessages.commentReplyNotSent()));
            return null;
          }
          // add user to particiant list if not already (admin post for first time)
          addToParticipantList(lessonId, userId);

          const timestamp = Date.now();
          const entities = getCommentEntities(message, lessonId, key, timestamp, null, parentId);

          dispatch(formLoaded());
          dispatch(addEntities(entities));

          const notificationType = file ? LESSON_DETAIL_SHARED_FILE : LESSON_DETAIL_REPLY;
          return sendLessonNotification(notificationType, lessonDetails, comment, file);
        })
        .catch(() => {
          dispatch(formFail(toasterMessages.commentReplyNotSent()));
        });
    });
  };
}

/**
 * DELETE COMMENT
 * Notify author user if deleter is an admin.
 *
 * TODO: handle rejection from firebaseClient
 *
 * @export
 * @param {string} lessonId
 * @param {string} parentId
 * @param {string} replyId
 * @param {Object} lessonDetails
 * @param {Map} comment
 * @param {Map} loginUser
 * @returns
 */
export function deleteComment(lessonId, parentId, replyId, lessonDetails, comment, loginUser) {
  const notificationType = LESSON_DETAIL_ADMIN_DELETE_COMMENT;
  const loggedInUserRole = loginUser.get('userRoles');

  return (dispatch) => {
    dispatch(formLoading());
    let path = `/${config.lessonPath}/${lessonId}/conversations/${parentId}`;
    const fileDeletions = [];
    if (replyId) path += `/replies/${replyId}`;

    return firebaseClient
      .deleteFromFirebase(path)
      .then(() => {
        if (comment.get('file')) fileDeletions.push(comment.getIn(['file', 'id']));
        if (comment.get('replies')) {
          comment.get('replies').forEach((r) => {
            if (r.get('file')) {
              fileDeletions.push(r.getIn(['file', 'id']));
            }
          });
        }
        return fileDeletions.length ? lesson.deleteFiles(fileDeletions) : Promise.resolve(true);
      })
      .then(() => {
        dispatch(commentDeleted({ lessonId, parentId, replyId, fileDeletions }));
        dispatch(formLoaded());
        if (Acl.isAdmin(loggedInUserRole)) sendLessonNotification(notificationType, lessonDetails, comment);
      })
      .catch(() => dispatch(formFail(toasterMessages.commentNotDeleted())));
  };
}

/**
 * DELETE FILE
 * Notify author user if deleter is an admin.
 *
 * @export
 * @param {string} lessonId
 * @param {string} parentId
 * @param {string} replyId
 * @param {string} fileId
 * @param {Object} lessonDetails
 * @param {Object} comment
 * @param {Map} loginUser
 * @returns
 */
export function deleteFile(lessonId, parentId, replyId, fileId, lessonDetails, comment, loginUser) {
  const notificationType = LESSON_DETAIL_ADMIN_DELETE_FILE;
  const loggedInUserRole = loginUser.get('userRoles');

  return (dispatch) => {
    dispatch(formLoading());
    let path = `/${config.lessonPath}/${lessonId}/conversations/${parentId}`;
    if (replyId) path += `/replies/${replyId}`;

    return firebaseClient
      .deleteFromFirebase(`${path}/file`)
      .then(() => lesson.deleteFile(fileId))
      .then(() => {
        dispatch(commentFileDeleted({ parentId, replyId, fileId }));
        dispatch(formSuccess(toasterMessages.commentFileDeleted()));
        if (Acl.isAdmin(loggedInUserRole)) sendLessonNotification(notificationType, lessonDetails, comment);
      })
      .catch(() => dispatch(formFail(toasterMessages.commentFileNotDeleted())));
  };
}

/**
 * EDIT COMMENT
 *
 * @param {string} lessonId
 * @param {string} parentId
 * @param {string} replyId
 * @param {Object} message
 * @returns {function(*)}
 */
export function editComment(lessonId, parentId, replyId, message) {
  return (dispatch) => {
    dispatch(formLoading());
    let path = `/${config.lessonPath}/${lessonId}/conversations/${parentId}`;
    if (replyId) {
      path += `/replies/${replyId}`;
    }
    const editedMessage = {
      message,
      editedTimestamp: firebaseClient.TimestampPlaceholder,
    };
    return firebaseClient
      .updateFirebase(path, editedMessage)
      .then(() => {
        const editedTimestamp = Date.now();

        dispatch(commentUpdated({ lessonId, parentId, replyId, message, editedTimestamp }));
        dispatch(formLoaded());
      })
      .catch(() => {
        dispatch(formFail(toasterMessages.commentNotEdited()));
      });
  };
}
