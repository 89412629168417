import styled from 'styled-components';
export const Container = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  padding-top: 15vh;
  flex-direction: column;
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  font-family: 'Montserrat';
  margin-top: 28px;
`;

export const Content = styled.div`
  font-weight: 500;
  font-size: 14px;
  font-family: 'Montserrat';
  line-height: 20px;
  color: #73747d;
  margin-top: 18px;
  white-space: pre-wrap;
  text-align: center;
`;
