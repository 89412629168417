import React from 'react';
import { Link } from 'react-router-dom';
import IconButton from 'components/molecules/IconButton';
import { Acl, permissionTypes } from '@crimson-education/common-config';
import { Column } from 'components/molecules/Table';
import ConfirmationModal from 'components/generic/ConfirmationModal';
import { columnNames } from '../columnMap';

import css from '../styles.scss';

function renderEditPencil(rowData) {
  return (
    <Link
      to={`users/${rowData.get('userId')}/edit-role`}
      data-test-id={`editRoleOf_${rowData.get('firstName')}_${rowData.get('lastName')}`}
    >
      <IconButton className={css.editIcon} icon="Edit" />
    </Link>
  );
}

function renderActivateButton(params, rowData) {
  return (
    <ConfirmationModal
      onConfirm={() => params.setUserStatus(rowData.get('userId'), true)}
      buttonClassName={css.activateButton}
      buttonText="Activate"
      dataTestId="reactivateUserButton"
      actionText="Are you sure you want to activate this user?"
      header="ACTIVATE USER"
    />
  );
}

function renderDeactivateButton(params, rowData) {
  return (
    <ConfirmationModal
      onConfirm={() => params.setUserStatus(rowData.get('userId'), false)}
      buttonClassName={css.activateButton}
      buttonText="Deactivate"
      dataTestId="deactivateUserButton"
      actionText="Are you sure you want to deactivate this user?"
      header="DEACTIVATE USER"
    />
  );
}

export default function EditUserButton(params) {
  return (
    <Column
      key={columnNames.EditUserButton}
      flex="0.5 1 0"
      name=""
      renderer={({ rowData }) => {
        const isInactive = rowData.get('status') === 'inactive';
        const hasEditPermission = Acl.checkPermission(params.loginUserPermissions, permissionTypes.EDIT_USER_ACCOUNT);
        const hasDeactivatePermission = Acl.checkPermission(
          params.loginUserPermissions,
          permissionTypes.DEACTIVATE_USER,
        );

        if (isInactive) return renderActivateButton(params, rowData);
        else if (hasEditPermission) return renderEditPencil(rowData);
        else if (hasDeactivatePermission) return renderDeactivateButton(params, rowData);
        return null;
      }}
    />
  );
}
