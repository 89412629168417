import React from 'react';
import Select from '../../components/Select';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
type MultiSelectSelectorProps = {
  value: { label: string; value: string }[];
  onChange: (value: { label: string; value: string }[]) => void;
  options: { label: string; value: string }[];
};

const MultiSelectSelector = ({ value, onChange, options }: MultiSelectSelectorProps) => {
  return (
    <Select
      isClearable
      isMulti
      value={value}
      onChange={onChange}
      options={options}
      hideSelectedOptions
      closeMenuOnSelect={false}
      DropdownIndicatorProps={{
        searchIcon: ExpandMoreIcon,
        searchIconStyle: {
          color: '#A9ACC0',
        },
      }}
    />
  );
};

export default MultiSelectSelector;
