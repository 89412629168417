import React from 'react';
import PropTypes from 'prop-types';

import FilledModalSection from 'components/molecules/FilledModalSection';
import fontColour from 'components/atoms/typography/fontColour.scss';
import css from './style.scss';

export default function WarningModalSection({ message }) {
  return (
    <FilledModalSection
      heading={message}
      icon="Warning"
      bannerClassName={css.warningBanner}
      headingClassName={fontColour.contrast}
      isHeadingBold={false}
    />
  );
}

WarningModalSection.propTypes = {
  message: PropTypes.string.isRequired,
};
