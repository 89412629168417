import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Tab, Tabs, TabList, TabPanel } from 'components/molecules/ReactTabs';

import css from './styles.scss';

import LessonCommentPanel from '../LessonCommentPanel';
import LessonSharedFilesPanel from '../LessonSharedFilesPanel';

class LessonDetailsContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabIndex: 0,
    };
    this.onTabSelected = this.onTabSelected.bind(this);
  }

  onTabSelected(index) {
    this.setState({ tabIndex: index });
  }

  render() {
    const {
      loginUser,
      lesson,
      comments,
      onPostComment,
      onEditComment,
      onReplyComment,
      onDeleteCommentFile,
      onDownloadCommentFile,
      onDeleteComment,
      getCachedUsers,
    } = this.props;
    const { tabIndex } = this.state;

    return (
      <div className={css.content}>
        <Tabs selectedIndex={tabIndex} onSelect={this.onTabSelected}>
          <TabList compactMode>
            <Tab title="Conversation" />
            <Tab title="Shared Files" />
          </TabList>

          <TabPanel compactMode>
            <LessonCommentPanel
              loginUser={loginUser}
              lesson={lesson}
              comments={comments}
              onPostComment={onPostComment}
              onEditComment={onEditComment}
              onReplyComment={onReplyComment}
              onDeleteCommentFile={onDeleteCommentFile}
              onDeleteComment={onDeleteComment}
              onDownloadCommentFile={onDownloadCommentFile}
              getCachedUsers={getCachedUsers}
            />
          </TabPanel>
          <TabPanel compactMode>
            <LessonSharedFilesPanel
              loginUser={loginUser}
              comments={comments}
              lesson={lesson}
              onDeleteCommentFile={onDeleteCommentFile}
              onDownloadCommentFile={onDownloadCommentFile}
              getCachedUsers={getCachedUsers}
            />
          </TabPanel>
        </Tabs>
      </div>
    );
  }
}

LessonDetailsContent.propTypes = {
  lesson: ImmutablePropTypes.map.isRequired,
  onPostComment: PropTypes.func.isRequired,
  onEditComment: PropTypes.func.isRequired,
  onReplyComment: PropTypes.func.isRequired,
  onDeleteCommentFile: PropTypes.func.isRequired,
  onDownloadCommentFile: PropTypes.func.isRequired,
  onDeleteComment: PropTypes.func.isRequired,
  comments: ImmutablePropTypes.map.isRequired,
  loginUser: ImmutablePropTypes.map.isRequired,
  getCachedUsers: ImmutablePropTypes.map,
};

LessonDetailsContent.displayName = 'LessonDetailsContent';

export default LessonDetailsContent;
