import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ThumbUp from '@material-ui/icons/ThumbUp';
import ThumbDown from '@material-ui/icons/ThumbDown';

import css from './ThumbPicker.scss';

export default function ThumbPicker(props) {
  const { onChange, value } = props;

  return (
    <div className={css.thumbPicker}>
      <button onClick={() => onChange(value === -1 ? 0 : -1)}>
        <ThumbDown className={classNames(css.icon, { [css.negative]: value === -1 })} />
      </button>
      <button onClick={() => onChange(value === 1 ? 0 : 1)}>
        <ThumbUp className={classNames(css.icon, { [css.positive]: value === 1 })} />
      </button>
    </div>
  );
}

ThumbPicker.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};
