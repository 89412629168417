import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Popover from 'react-popover';
import css from './styles.scss';

export default function CriteriaTooltip(props) {
  const {
    isOpen,
    arrowSize,
    preferPlace,
    onMouseEnter,
    onMouseLeave,
    targetStyling,
    popoverStyling,
    targetText,
    body,
    buttons,
  } = props;

  return (
    <Popover
      isOpen={isOpen}
      className={css.popover}
      tipSize={arrowSize}
      body={
        buttons ? (
          <div className={classnames(css.tooltip, popoverStyling)}>
            {body.map((line, index) => (
              <div className={css.line} key={`$line_${index}`}>
                <b className={index !== 3 && buttons[3] === 'N/A' ? css.bodyLine : css.bodyLineNA}>{buttons[index]}</b>
                <div>{line}</div>
              </div>
            ))}
          </div>
        ) : (
          <div className={classnames(css.tooltip, popoverStyling)}>
            {body.map((line) => (
              <div className={css.line} key={`$line_${line}`}>
                <div>{line}</div>
              </div>
            ))}
          </div>
        )
      }
      preferPlace={preferPlace}
    >
      <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} className={targetStyling}>
        {targetText}
      </div>
    </Popover>
  );
}

CriteriaTooltip.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  arrowSize: PropTypes.number,
  preferPlace: PropTypes.string,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
  targetStyling: PropTypes.string,
  popoverStyling: PropTypes.string,
  targetText: PropTypes.string.isRequired,
  body: PropTypes.array.isRequired,
  buttons: PropTypes.array,
};
