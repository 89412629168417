import React, { Component } from 'react';
import PropTypes from 'prop-types';
import IconButton from 'components/molecules/IconButton';
import Button from 'components/molecules/Button';
import LoadingCircle from 'components/molecules/LoadingCircle';
import SelectCard from 'components/atoms/graphics/SelectCard';
import { Body, SubHeader, ModalHeader, Caption } from 'components/atoms/typography';
import { Logo } from 'components/atoms/graphics';
import classNames from 'classnames';
import { formatPeriodDate, formatCurrencyValue, formatDate, formatDuration } from 'utils/invoiceUtils';
import printPage from 'utils/print';
import textBehaviour from 'components/atoms/behaviours/text.scss';
import fontColour from 'components/atoms/typography/fontColour.scss';

import InvoiceSummary from './InvoiceSummary';
import InvoiceItem from './InvoiceItem';
import InvoiceSingleLine from './InvoiceSingleLine';
import InvoiceActionModal from './InvoiceActionModal';
import InvoiceStatusTag from '../InvoiceStatusTag';

import css from './styles.scss';

function renderLessonStatus(completed, total) {
  return (
    <div className={css.lessonStatus}>
      {completed === total ? (
        <i className={`zmdi zmdi-check ${css.iconDone}`} />
      ) : (
        <i className={`zmdi zmdi-alert-triangle ${css.iconIncomplete}`} />
      )}
      <div className={css.lessons}>
        {completed} of {total} completed
      </div>
    </div>
  );
}

export default class InvoiceDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
      modalData: {},
    };
  }

  closeModal = () => {
    this.setState({
      isModalOpen: false,
      modalData: {},
    });
  };

  openSubmitInvoiceModal = () => {
    const {
      invoice: { id, netPay, billingCountry },
      submitInvoice,
    } = this.props;

    const { currency } = billingCountry;
    const payValue = formatCurrencyValue({ currency, amount: netPay });

    this.setState({
      isModalOpen: true,
      modalData: {
        title: 'Submit Invoice',
        bodyText: [
          "By submitting this invoice you're confirming that all details are correct and you're happy to be paid the total amount of ",
          <strong key={1}>{payValue}</strong>,
        ],
        onSubmit: () => {
          this.closeModal();
          return submitInvoice(id);
        },
        onClose: this.closeModal,
      },
    });
  };

  openDeleteInvoiceModal = () => {
    const {
      invoice: { id },
      deleteInvoice,
    } = this.props;

    this.setState({
      isModalOpen: true,
      modalData: {
        title: 'Delete Invoice',
        bodyText: 'Are you sure you want to delete this invoice?',
        onSubmit: () => {
          this.closeModal();
          return deleteInvoice(id);
        },
        onClose: this.closeModal,
      },
    });
  };

  openMarkInvoiceAsPaidModal = () => {
    const {
      invoice: { id },
      markInvoiceAsPaid,
      filter,
    } = this.props;

    this.setState({
      isModalOpen: true,
      modalData: {
        title: 'Mark Invoice as Paid',
        bodyText: 'Are you sure you want to mark this invoice as paid?',
        onSubmit: () => {
          this.closeModal();
          return markInvoiceAsPaid(id, filter);
        },
        onClose: this.closeModal,
      },
    });
  };

  renderExtraActions(props) {
    const {
      invoice: { periodEnd, isDeletable, tutor },
      loginUserTimezone,
      showDelete,
      app,
    } = props;

    const isMobile = app && app.isMobile;

    return (
      <div className={css.extraActions}>
        {isDeletable && showDelete && (
          <IconButton
            dataTestId="deleteInvoiceButton"
            className={css.headerActionButton}
            icon="Delete"
            onClick={this.openDeleteInvoiceModal}
          />
        )}
        {!isMobile && (
          <IconButton
            dataTestId="printInvoiceButton"
            className={css.headerActionButton}
            icon="Print"
            onClick={() =>
              printPage(
                `${tutor.firstName} ${tutor.lastName} - ${formatDate(periodEnd, loginUserTimezone, 'D MMM YYYY')}`,
              )
            }
          />
        )}
      </div>
    );
  }

  render() {
    const {
      app,
      invoice,
      loginUserTimezone,
      showMarkPaid,
      showSubmit,
      clearSelectedInvoiceId,
      isFetching,
    } = this.props;

    if (isFetching) {
      return (
        <div className={css.loader}>
          <LoadingCircle color="#cd4b53" />
        </div>
      );
    }

    if (!invoice) {
      return (
        <div className={css.emptyContainer}>
          <SelectCard />
          <SubHeader className={classNames(css.emptyTitle, fontColour.secondary)}>Select an invoice</SubHeader>
          <Body className={classNames(fontColour.secondary, css.emptyText)}>
            Please select an invoice from the left panel to view.
          </Body>
        </div>
      );
    }

    const {
      periodStart,
      periodEnd,
      billingCountry,
      completedLessons,
      grossPay,
      isPayable,
      isSubmittable,
      items,
      netPay,
      status,
      statusDate,
      withholdingTax,
      totalHours,
    } = invoice;

    const currency = billingCountry.currency;

    const { isModalOpen, modalData } = this.state;

    const isMobile = app && app.isMobile;

    return (
      <div className={css.invoiceWrapper}>
        <InvoiceActionModal isOpen={isModalOpen} {...modalData} />
        <div className={css.scrollableContentArea}>
          <div className={classNames(css.headerBar)}>
            <div className={css.headerBox}>
              <div className={css.invoiceHeaderDetails}>
                {isMobile && (
                  <IconButton
                    dataTestId="BackButton"
                    className={css.backButtonLink}
                    icon="ArrowBack"
                    onClick={() => clearSelectedInvoiceId()}
                  />
                )}
                <div>
                  <ModalHeader className={textBehaviour.truncateText}>
                    {formatPeriodDate(periodStart, periodEnd)}
                  </ModalHeader>
                  {renderLessonStatus(completedLessons.length, items.length)}
                </div>
              </div>
              {isMobile && this.renderExtraActions(this.props)}
            </div>
            <div className={css.headerActions}>
              <div className={css.invoiceStatus}>
                <InvoiceStatusTag status={status} />
                <Caption>{formatDate(statusDate, loginUserTimezone, 'D MMM YYYY HH:mma')}</Caption>
              </div>
              <div className={css.actions}>
                <div className={css.mainActions}>
                  {isSubmittable && showSubmit && (
                    <Button dataTestId="invoiceSubmitButton" onClick={this.openSubmitInvoiceModal}>
                      Submit invoice
                    </Button>
                  )}
                  {isPayable && showMarkPaid && (
                    <Button dataTestId="markInvoiceAsPaidButton" onClick={this.openMarkInvoiceAsPaidModal}>
                      Mark as Paid
                    </Button>
                  )}
                </div>
                {!isMobile && this.renderExtraActions(this.props)}
              </div>
            </div>
          </div>

          <div className={classNames(css.content)}>
            <Logo className={css.crimsonLogo} />
            <InvoiceSummary invoice={invoice} />

            {items.map((i, idx) => (
              <InvoiceItem key={idx} item={i} currency={currency} />
            ))}

            <InvoiceSingleLine
              label="Gross pay"
              value={formatCurrencyValue({ amount: grossPay, currency })}
              caption={`(Total Duration: ${formatDuration(totalHours)})`}
            />
            <InvoiceSingleLine
              label="Withholding tax"
              value={`-${formatCurrencyValue({ amount: withholdingTax, currency })}`}
            />
            <InvoiceSingleLine
              bold
              label="TOTAL PAY"
              value={formatCurrencyValue({ amount: netPay, currency })}
              className={css.totalPay}
            />
          </div>
        </div>
      </div>
    );
  }
}

InvoiceDetails.propTypes = {
  isMobile: PropTypes.bool,
  onClickBack: PropTypes.func,
  invoice: PropTypes.object,
  loginUserTimezone: PropTypes.string,
  submitInvoice: PropTypes.func.isRequired,
  markInvoiceAsPaid: PropTypes.func.isRequired,
  deleteInvoice: PropTypes.func.isRequired,
  showSubmit: PropTypes.bool,
  showMarkPaid: PropTypes.bool,
  showDelete: PropTypes.bool,
  clearSelectedInvoiceId: PropTypes.func,
  app: PropTypes.object,
  isFetching: PropTypes.bool,
  filter: PropTypes.object.isRequired,
};

InvoiceDetails.defaultProps = {
  isMobile: false,
};
