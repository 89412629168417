import { connect } from 'react-redux';

import componentKeys from 'constants/componentKeys';
import { updateMeta } from 'ducks/meta';
import { checkSessionAboutToStart } from 'ducks/user';
import { getUserSessionStartingSoon } from 'selectors/booking';
import { getByComponentKey } from 'selectors/meta';

import SessionPromptModal from './SessionPromptModal';

export default connect(
  (state) => ({
    session: getUserSessionStartingSoon(state),
    isPromptModalOpen: getByComponentKey(componentKeys.SESSION_PROMPT_MODAL_OPEN)(state),
  }),
  (dispatch) => ({
    setPromptModalOpen: (isOpen) => dispatch(updateMeta(componentKeys.SESSION_PROMPT_MODAL_OPEN, isOpen)),
    checkSessionAboutToStart: (params) => dispatch(checkSessionAboutToStart(params)),
  }),
)(SessionPromptModal);
