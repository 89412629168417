import AuthorisationCheck from 'components/wrappers/AuthorisationCheck';
import { Acl, PermissionTypes } from '@crimson-education/common-config';
import { selectUserPermissions } from 'selectors/meta';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getProgressReportById } from 'selectors/progressReport';
import { getUserWithId } from 'selectors/user';
import { fetchProgressReportById, sendProgressReport, updateProgressReport } from 'ducks/progressReport';
import { showPageLoader } from 'ducks/meta';
import ProgressReportSummary from 'components/organisms/ProgressReportSummary/ProgressReportSummary';

const mapStateToProps = (state, ownProps) => ({
  student: getUserWithId(ownProps.match.params.userId)(state),
  reportId: ownProps.match.params.reportId,
  report: getProgressReportById(ownProps.match.params.reportId)(state),
  canEdit: Acl.checkPermission(selectUserPermissions(state), PermissionTypes.EDIT_PROGRESS_REPORT),
});

const mapDispatchToProps = (dispatch) => ({
  fetchProgressReportById: showPageLoader((id) => dispatch(fetchProgressReportById(id)), dispatch),
  sendProgressReport: showPageLoader((id) => dispatch(sendProgressReport(id)), dispatch),
  updateProgressReport: (report) => dispatch(updateProgressReport(report)),
});

const withAuthorisation = AuthorisationCheck(PermissionTypes.VIEW_PROGRESS_REPORT_TAB);

export default withAuthorisation(withRouter(connect(mapStateToProps, mapDispatchToProps)(ProgressReportSummary)));
