import React from 'react';
import { RootContainer, Board1Container } from './index.style';
import { withStaffDashboardContext } from './viewController';
import 'styles/fonts.scss';
import AlertsPanel from './panels/AlertsPanel';
import SchoolSelectionPanel from './panels/SchoolSelectionPanel';
import MeetingsPanel from './panels/MeetingsPanel';
import UpcomingApplicationDeadlines from './panels/UpcomingApplicationDeadlines';
import StudentList from './StudentList';
import DashboardHeader from './DashboardHeader';
import { EmptyHookComp } from './components';
import AlertDetailModal from './AlertDetailModal';
import ContactsModal from './ContactsModal';
import DevToolChangeToday from './components/devtool/changeToday';
import { useFeatureFlag } from 'featureSwitches';
import withAuthorization from './components/withAuthorization';

const StaffDashboard = () => {
  const showDevTool = useFeatureFlag('STAFF_DASHBOARD_DEV_TOOL');
  return (
    <RootContainer>
      <DashboardHeader />
      <Board1Container>
        <AlertsPanel />
        <SchoolSelectionPanel />
        <MeetingsPanel />
        <UpcomingApplicationDeadlines />
      </Board1Container>
      <StudentList />
      <AlertDetailModal />
      <ContactsModal />
      <EmptyHookComp />
      {showDevTool && <DevToolChangeToday />}
    </RootContainer>
  );
};

export default withAuthorization(withStaffDashboardContext(StaffDashboard));
