import { GradeResultType, GradeResultValueType } from '@crimson-education/common-config';

// Some grade result types have values that are enums. Get each enum.
const ibResults = GradeResultValueType[GradeResultType.IB_RESULTS];
const ibTokPredicted = GradeResultValueType[GradeResultType.IB_TOK_PREDICTED_GRADE];
const ibCasPredicted = GradeResultValueType[GradeResultType.IB_CAS_PREDICTED_GRADE];
const ibEePredicted = GradeResultValueType[GradeResultType.IB_EE_PREDICTED_GRADE];
const ibTokAchieved = GradeResultValueType[GradeResultType.IB_TOK_ACHIEVED_GRADE];
const ibCasAchieved = GradeResultValueType[GradeResultType.IB_CAS_ACHIEVED_GRADE];
const ibEeAchieved = GradeResultValueType[GradeResultType.IB_EE_ACHIEVED_GRADE];
const cieAwards = GradeResultValueType[GradeResultType.CIE_AWARDS];
const nzqaAwards = GradeResultValueType[GradeResultType.NZQA_AWARDS];
const nzqaOverallAwards = GradeResultValueType[GradeResultType.NZQA_OVERALL_AWARDS];
const nceaSubjectEndorsement = GradeResultValueType[GradeResultType.NCEA_SUBJECT_ENDORSEMENT];
const ieltsTestType = GradeResultValueType[GradeResultType.IELTS_TEST_TYPE];
const gpaRegions = GradeResultValueType[GradeResultType.GPA_REGION];
const gpaYears = GradeResultValueType[GradeResultType.GPA_YEAR];

// Define the UI text to display when showing these grade result value enums.
export default {
  [ibResults.UNKNOWN]: 'Unknown',
  [ibResults.DIPLOMA_AWARDED]: 'Diploma awarded',
  [ibResults.NOT_AWARDED]: 'Not awarded',
  [ibTokPredicted.UNKNOWN]: 'Unknown',
  [ibTokPredicted.A]: 'A',
  [ibTokPredicted.B]: 'B',
  [ibTokPredicted.C]: 'C',
  [ibTokPredicted.D]: 'D',
  [ibTokPredicted.E]: 'E',
  [ibTokAchieved.UNKNOWN]: 'Unknown',
  [ibTokAchieved.A]: 'A',
  [ibTokAchieved.B]: 'B',
  [ibTokAchieved.C]: 'C',
  [ibTokAchieved.D]: 'D',
  [ibTokAchieved.E]: 'E',
  [ibEePredicted.UNKNOWN]: 'Unknown',
  [ibEePredicted.A]: 'A',
  [ibEePredicted.B]: 'B',
  [ibEePredicted.C]: 'C',
  [ibEePredicted.D]: 'D',
  [ibEePredicted.E]: 'E',
  [ibEeAchieved.UNKNOWN]: 'Unknown',
  [ibEeAchieved.A]: 'A',
  [ibEeAchieved.B]: 'B',
  [ibEeAchieved.C]: 'C',
  [ibEeAchieved.D]: 'D',
  [ibEeAchieved.E]: 'E',
  [ibCasPredicted.UNKNOWN]: 'Unknown',
  [ibCasPredicted.PASS]: 'Pass',
  [ibCasPredicted.FAIL]: 'Fail',
  [ibCasAchieved.UNKNOWN]: 'Unknown',
  [ibCasAchieved.PASS]: 'Pass',
  [ibCasAchieved.FAIL]: 'Fail',
  [cieAwards.NO_AWARDS]: 'No awards',
  [cieAwards.TOP_IN_COUNTRY]: 'Top in Country',
  [cieAwards.TOP_IN_WORLD]: 'Top in World',
  [nzqaAwards.NO_SCHOLARSHIP]: 'No scholarship',
  [nzqaAwards.SCHOLARSHIP]: 'Scholarship',
  [nzqaAwards.OUTSTANDING]: 'Outstanding',
  [nzqaAwards.TOP_SCHOLAR_OUTSTANDING]: 'Top Scholar + Outstanding',
  [nzqaOverallAwards.NO_AWARDS]: 'No awards',
  [nzqaOverallAwards.SCHOLARSHIP_AWARD]: 'Scholarship Award',
  [nzqaOverallAwards.OUTSTANDING_SCHOLAR_AWARD]: 'Outstanding Scholar Award',
  [nzqaOverallAwards.PREMIER_SCHOLAR_AWARD]: 'Premier Scholar Award',
  [nceaSubjectEndorsement.NO_ENDORSEMENT]: 'No endorsement',
  [nceaSubjectEndorsement.MERIT]: 'Merit',
  [nceaSubjectEndorsement.EXCELLENCE]: 'Excellence',
  [ieltsTestType.ACADEMIC]: 'Academic (A)',
  [ieltsTestType.GENERAL]: 'General (G)',
  [gpaRegions.US]: 'US (out of 4.0)',
  [gpaRegions.JAPAN]: 'Japan (out of 5.0)',
  [gpaRegions.RUSSIA]: 'Russia (out of 5.0)',
  [gpaRegions.BRAZIL]: 'Brazil (out of 10.0)',
  [gpaYears.FRESHMAN]: 'Freshman',
  [gpaYears.SOPHOMORE]: 'Sophomore',
  [gpaYears.JUNIOR]: 'Junior',
  [gpaYears.SENIOR]: 'Senior',
};
