import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import AddCircle from '@material-ui/icons/AddCircle';
import { ButtonText } from 'components/atoms/typography';
import styles from './styles.scss';

/*  AddNew
 *  Looks a bit like: `+ Add A New Thing!`
 *  Clicking the component will trigger the attached handler. Providing text is
 *  optional. If text is absent, then all the user will see is the `+` icon.
 */
export default class AddNew extends Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
    this.buttonRef = React.createRef();
  }

  onClick(evt) {
    const { onClick } = this.props;
    if (onClick) {
      onClick(evt);
    }
    if (this.buttonRef && this.buttonRef.current) {
      this.buttonRef.current.blur();
    }
  }

  render() {
    const { text, className, dataTestId, icon } = this.props;

    return (
      <button
        ref={this.buttonRef}
        data-test-id={dataTestId}
        className={classnames(styles.addNew, className)}
        onClick={this.onClick}
      >
        {icon || <AddCircle style={{ height: '2rem', width: '2rem' }} className={styles.addCircle} />}
        {text && (
          <ButtonText bold className={styles.addNewText}>
            {text}
          </ButtonText>
        )}
      </button>
    );
  }
}

AddNew.propTypes = {
  dataTestId: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.object,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};
