import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Button from '@material-ui/core/Button';
import { Select } from 'design-system';
import 'design-system/styles/themes/default/index.scss';
import 'design-system/styles/index.scss';
import ValueCheckPair from 'design-system/utils/ValueCheckedPair';
import { statuses } from '../../utils';
import { ReportsContext } from '../context';
import { actions } from '../reducer';

import css from '../styles.scss';

export const ToolbarButton = (props) => {
  const { disabled, isMobile, text, onClick } = props;
  return (
    <Button
      disabled={disabled}
      variant="outlined"
      color="primary"
      className={classnames(isMobile ? css.mobileButton : '', 'ds-button')}
      onClick={onClick}
    >
      <h5 className={classnames('typography', 'bread-crumb', css.btnText)}>{text}</h5>
    </Button>
  );
};

export const ToolbarSection = (props) => {
  const { input, button, isMobile } = props;
  return (
    <div className={classnames(isMobile ? 'grow' : 'u-3-6', 'horizontal', 'align-items', css.spaceEvenly)}>
      <div className={classnames(isMobile ? 'grow' : 'u-2-6', 'vertical', 'align-items', 'justify-end')}>{input}</div>
      <div className={classnames(isMobile ? 'grow' : 'u-3-6', 'vertical', 'align-items', 'justify-end')}>{button}</div>
    </div>
  );
};
export default function Toolbar(props) {
  const { isMobile, reportId } = props;

  const { state, dispatch, updateReportStatus, updateReport } = useContext(ReportsContext);
  const {
    studentStreams,
    dirtyStudentStreams,
    statusId,
    uiFlow: { disabled, hiddenToolbar },
  } = state;

  const multiSelectLabel = () => {
    const streamList = studentStreams.map((ss) => {
      const dirtyItem = dirtyStudentStreams.find(
        (di) => di.student_report_id === ss.student_report_id && di.student_stream_id === ss.student_stream_id,
      );
      return dirtyItem || ss;
    });
    return `${streamList.filter((c) => c.enabled).length} items selected`;
  };

  const onStatusChange = (item) => {
    if (statusId !== item.value) {
      dispatch(actions.SET_STATUS, { statusId: item.value });
    }
  };

  const onStreamsChange = (streams) => {
    const dirtyItems = [...studentStreams];
    const dirtyStudentStreams = dirtyItems.map((dirtyItem) => {
      const index = streams.findIndex((stream) => stream.student_stream_id === dirtyItem.student_stream_id);
      return { ...dirtyItem, enabled: index > -1 };
    });
    dispatch(actions.SET_STREAM_DIRTY, { dirtyStudentStreams });
  };

  const SelectInputStatus = (
    <Select
      disabled={disabled}
      label="Select Status"
      items={statuses.map((s) => (s.value === statusId ? new ValueCheckPair(s, true) : s))}
      map={(item) => item.label}
      inputText={(item) => item.label}
      onChange={(item) => onStatusChange(item)}
      color="primary"
    />
  );

  const UpdateStatusButton = (
    <ToolbarButton
      disabled={disabled}
      onClick={() => updateReportStatus(reportId)}
      isMobile={isMobile}
      text="UPDATE STATUS"
    />
  );

  const SelectInputStreams = (
    <Select
      multi
      disabled={disabled}
      label="Select Streams"
      items={studentStreams}
      map={(item) => <p>{item.name}</p>}
      inputText={multiSelectLabel()}
      onChange={onStreamsChange}
      color="primary"
    />
  );

  const UpdateReportButton = (
    <ToolbarButton
      disabled={disabled}
      onClick={() => updateReport(reportId)}
      isMobile={isMobile}
      text="UPDATE REPORT"
    />
  );

  return (
    <div className={classnames('horizontal', 'grow', css['padding-04'], { [css.hidden]: hiddenToolbar })}>
      <ToolbarSection input={SelectInputStatus} button={UpdateStatusButton} isMobile={isMobile} />
      <ToolbarSection input={SelectInputStreams} button={UpdateReportButton} isMobile={isMobile} />
    </div>
  );
}

Toolbar.propTypes = {
  isMobile: PropTypes.bool,
  reportId: PropTypes.string,
};

ToolbarButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

ToolbarSection.propTypes = {
  input: PropTypes.node.isRequired,
  button: PropTypes.node.isRequired,
  isMobile: PropTypes.bool.isRequired,
};
