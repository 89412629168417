import React from 'react';
import { Column } from 'components/molecules/Table';
import { Link } from 'react-router-dom';
import { Acl, roleTypes } from '@crimson-education/common-config';
import { columnNames } from '../columnMap';
import { useFeatureFlag } from 'featureSwitches';

export default function QuickLink() {
  const STUDENT_CENTER_BETA = useFeatureFlag('STUDENT_CENTER_BETA');
  return (
    <Column
      key={columnNames.QuickLink}
      name="Quick link"
      renderer={({ rowData }) => {
        return (
          Acl.checkRole(rowData.getIn(['userRoles']), roleTypes.STUDENT) &&
          !(rowData.getIn(['isBetaUser']) && STUDENT_CENTER_BETA) && (
            <Link to={`/pathfinder/?userId=${rowData.getIn(['userId'])}`}>
              <div style={{ color: '#454fc2' }}>Pathfinder</div>
            </Link>
          )
        );
      }}
    />
  );
}
