/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Card from 'components/molecules/Card';
import University from 'components/atoms/graphics/University';
import IconButton from 'components/molecules/IconButton';
import Avatar from 'components/molecules/Avatar';
import { Link } from 'react-router-dom';
import { getAvatarColour } from 'utils/calendarUtils';
import ReactTooltip from 'react-tooltip';
import Modal from 'components/molecules/Modal';
import css from './styles.scss';

export default function FriendCard(props) {
  const { user, processFriend } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [friendData, setFriendData] = useState();

  const notEmptyList = (list) => !!(list && list.size > 0);

  const prettyPrimaryRole = (user) => {
    const userRoles = user.get('roles');
    if (userRoles.size > 1) {
      return userRoles.filter((r) => r.get('isPrimary')).map((r) => r.get('role').get('name'));
    }
    return userRoles.map((r) => r.get('role').get('name'));
  };

  const submitModal = (friendId, type) => {
    processFriend(friendId, type);
    setSubmitDisabled(true);
    setIsModalOpen(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSubmitDisabled(false);
  };

  const openModal = () => {
    if (!friendData) return null;
    if (friendData.get('friendStatus') === 'OK') {
      return (
        <Modal
          isOpen={isModalOpen}
          onSubmit={() => submitModal(friendData.get('friendId'), 'BLOCK')}
          onClose={() => closeModal()}
          title="Are you sure"
          onSubmitDataTestId={`mobileDeleteFriendButton${friendData.get('userId')}`}
          submitText="submit"
          secondarySubmitText="Cancel"
          onSecondarySubmit={() => closeModal()}
          overlayClassName={css.modalStyle}
          isSubmitDisabled={submitDisabled}
        >
          <div>Are you sure you want to unfriend this person? You can not message each other once done.</div>
        </Modal>
      );
    }
    return (
      <Modal
        isOpen={isModalOpen}
        onSubmit={() => submitModal(friendData.get('userFriendId'), 'IGNORE')}
        onClose={() => closeModal()}
        title="Are you sure"
        onSubmitDataTestId={`mobileUnFriendButton${friendData.get('userId')}`}
        submitText="submit"
        secondarySubmitText="Cancel"
        onSecondarySubmit={() => closeModal()}
        overlayClassName={css.modalStyle}
        isSubmitDisabled={submitDisabled}
      >
        <div>Are you sure you want to delete this friend request?</div>
      </Modal>
    );
  };

  const actionButton = (data) => {
    setIsModalOpen(true);
    setSubmitDisabled(false);
    setFriendData(data);
  };

  const renderMoreAction = (data) => {
    return (
      <div className={css.friendAction}>
        <a data-tip data-for={data.get('friendId')}>
          <IconButton className={css.editIcon} icon="MoreHoriz" />
        </a>
        <ReactTooltip id={data.get('friendId')} place="bottom" effect="solid" clickable>
          {data.get('friendStatus') === 'OK' ? (
            <button className={css.tipButton} onClick={() => actionButton(data)}>
              Unfriend
            </button>
          ) : (
            <button className={css.tipButton} onClick={() => actionButton(data)}>
              DELETE
            </button>
          )}
        </ReactTooltip>
        {openModal()}
      </div>
    );
  };

  const renderMessageButton = (data) => {
    return (
      <div className={css.friendAction}>
        <Link to={`/messages/${data.get('sessionId')}`} data-test-id="linkToMessage">
          <IconButton icon="Sms" className={css.messageIcon} />
        </Link>
      </div>
    );
  };

  const renderAcceptButton = (processFriend, data) => {
    return (
      <div className={css.friendAction}>
        <button
          data-test-id="acceptFriendRequest"
          className={classNames(css.commonButton, css.acceptButton)}
          onClick={() => processFriend(data.get('userFriendId'), 'ACCEPT')}
        >
          {' '}
          Accept{' '}
        </button>
      </div>
    );
  };

  const renderLocation = (data) => {
    if (data.get('city') && data.get('country')) {
      return `${data.get('city')}, ${data.get('country')}`;
    }
    if (data.get('city')) {
      return data.get('city');
    }
    if (data.get('country')) {
      return data.get('country');
    }
    return 'To be updated';
  };

  return (
    <Card className={css.card}>
      <div className={css.profileContainer}>
        <div className={css.profileInfo}>
          <div className={css.avatar}>
            <Avatar
              firstName={user.get('firstName')}
              lastName={user.get('lastName')}
              colourIndex={getAvatarColour(user.get('userId'))}
              image={user.get('profileImageUrl')}
              userId={user.get('userId')}
              showStudentType
            />
          </div>
          <div className={css.info}>
            <div className={css.name}>
              {user.get('firstName')} {user.get('lastName')}
            </div>
            <div className={css.role}>{prettyPrimaryRole(user)}</div>
          </div>
        </div>
        {renderMoreAction(user)}
      </div>
      <div className={css.school}>
        <University />
        <span>
          {notEmptyList(user.get('universities')) ? `${user.get('universities').get(0).get('name')}` : 'No University'}
        </span>
      </div>
      <div className={css.lastContainer}>
        <div className={css.location}>
          <IconButton icon="LocationOn" className={css.locationIcon} /> {renderLocation(user)}
        </div>
        <div>
          {' '}
          {user.get('friendStatus') === 'OK' ? renderMessageButton(user) : renderAcceptButton(processFriend, user)}
        </div>
      </div>
    </Card>
  );
}

FriendCard.displayName = 'FriendCard';

FriendCard.propTypes = {
  user: PropTypes.object,
  processFriend: PropTypes.func,
};
