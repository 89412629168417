import React from 'react';
import PropTypes from 'prop-types';

export default function Dropdown({ className }) {
  return (
    <svg viewBox="0 0 48 48" className={className}>
      <path d="M14.83 16.42L24 25.59l9.17-9.17L36 19.25l-12 12-12-12z" />
    </svg>
  );
}

Dropdown.displayName = 'Dropdown';

Dropdown.propTypes = {
  className: PropTypes.string,
};
