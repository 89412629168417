import { connect } from 'react-redux';
import { roleTypes } from '@crimson-education/common-config';

import { selectUserPermissions } from 'selectors/meta';

import { getLoginUser, getUserWithId } from 'selectors/user';

import { formFail } from 'ducks/meta';

import { getKeyContactsInfo } from 'selectors/profile';
import { getSessionEmailHistory } from 'selectors/booking';

import { fetchUserById } from 'ducks/user';

import { sendSessionSummaryEmail, fetchSessionSummaryEmailHistory } from 'ducks/booking';

import SendSessionSummaryModal from './SendSessionSummaryModal';

export default connect(
  (state, ownProps) => ({
    loginUser: getLoginUser(state),
    loginUserPermissions: selectUserPermissions(state),
    userOnBehalf: ownProps.studentId ? getUserWithId(ownProps.studentId)(state).toJS() : null,
    keyContactInfo: ownProps.studentId ? getKeyContactsInfo(ownProps.studentId, roleTypes.STUDENT)(state) : null,
    sessionEmailHistory: ownProps.eventId ? getSessionEmailHistory(ownProps.eventId.toString())(state) : null,
  }),
  (dispatch) => ({
    fetchUserById: (userId) => dispatch(fetchUserById(userId)),
    sendSessionSummaryEmail: ({ eventId, studentId, emails }) =>
      dispatch(sendSessionSummaryEmail({ eventId, studentId, emails })),
    fetchSessionSummaryEmailHistory: (eventId) => dispatch(fetchSessionSummaryEmailHistory(eventId)),
    setFailForm: (msg) => dispatch(formFail(msg)),
  }),
)(SendSessionSummaryModal);
