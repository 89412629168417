import React from 'react';
import DropdownMenu, { DropdownMenuProps } from '../../Dropdown/DropdownMenu';
import ButtonWithDropdownIndicator from '../../components/ButtonWithDropdownIndicator';

type SimpleMenuDropdownProps = {
  children?: React.ReactNode;
  innerButtonStyle?: React.CSSProperties;
  placeholder?: string;
  className?: string;
} & Pick<DropdownMenuProps, 'data' | 'onClickItem' | 'menuStyle' | 'menuItemStyle'>;
const SimpleMenuDropdown = ({
  children,
  data,
  innerButtonStyle,
  onClickItem,
  placeholder,
  className,
  ...restProps
}: SimpleMenuDropdownProps) => {
  return (
    <DropdownMenu wrapperStyle={{ borderWidth: 0 }} data={data} onClickItem={onClickItem} {...restProps}>
      {(isOpen) => (
        <ButtonWithDropdownIndicator
          className={className}
          placeholder={placeholder}
          style={innerButtonStyle}
          isOpen={isOpen}
          iconStyle={{ fontSize: 20, color: '#1D1E2B' }}
        >
          {children}
        </ButtonWithDropdownIndicator>
      )}
    </DropdownMenu>
  );
};

export default SimpleMenuDropdown;
