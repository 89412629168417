import Immutable from 'immutable';
import { createSelector } from 'reselect';

import componentKeys from 'constants/componentKeys';
import { threadStatus } from 'constants/messaging';
import { getByComponentKey } from 'selectors/meta';
import { getLoginUser } from 'selectors/user';
import { getFailedMessages, firebaseState } from './messages';

const state = (state) => state;
const messaging = (state) => state.get('messaging');
const threads = (state) => state.getIn(['messaging', 'entities', 'thread']);

/* Firebase */
// grab all the public info from users we are subscribed to in firebase.
const getFBUsers = createSelector(firebaseState, (firebase) => Immutable.Map(firebase).map((x) => x.public));

/* Users */
export const getUsers = createSelector(messaging, (messaging) => messaging.getIn(['entities', 'user']).toList());

export const getUsersMap = createSelector(messaging, (messaging) => {
  const users = messaging.getIn(['entities', 'user']);
  return users.map((user) => {
    return user;
  });
});

export const getUserById = (id) =>
  createSelector(messaging, getFBUsers, (messaging, firebase) => {
    const liveUser = firebase.get(id) || {};
    const savedUser = messaging.getIn(['entities', 'user', id]);
    const mergedUser = savedUser.merge(liveUser);
    return mergedUser;
  });

/* Threads */
export const getIsFetchingThreads = createSelector(messaging, (messaging) => messaging.get('isFetchingThreads'));

export const getIsFetchingCommunityThreads = createSelector(messaging, (messaging) =>
  messaging.get('isFetchingCommunityThreads'),
);

export const getThreadParticipantById = (id) =>
  createSelector(messaging, (messaging) => messaging.getIn(['entities', 'threadParticipant', id]));

const getAllThreads = createSelector(threads, (threads) => threads);

export const getThreadById = (id) => createSelector(getAllThreads, (threads) => threads.get(id));

export const getThreadParticipantsMap = createSelector(
  messaging,
  getAllThreads,
  getUsersMap,
  (messaging, threads, users) => {
    return threads.map((thread) => {
      const threadId = thread.get('id');
      const participants = messaging
        .getIn(['entities', 'threadParticipant'])
        .toList()
        .filter((x) => x.get('threadId') === threadId)
        .map((x) => x.set('user', users.get(x.get('userId'))));

      return participants;
    });
  },
);

export const getOtherThreadParticipantsMap = createSelector(
  getThreadParticipantsMap,
  getLoginUser,
  (threadParticipantsMap, currentUser) =>
    threadParticipantsMap.map((threadParticipants) =>
      threadParticipants.filter((x) => x.getIn(['user', 'userId']) !== currentUser.get('userId')),
    ),
);

export const getOtherThreadParticipants = (threadId) =>
  createSelector(getThreadParticipantsMap, getLoginUser, (threadParticipantsMap, currentUser) =>
    threadParticipantsMap
      .get(threadId, Immutable.List())
      .filter((x) => x.getIn(['user', 'userId']) !== currentUser.get('userId')),
  );

export const getOtherTypingThreadParticipants = (threadId) =>
  createSelector(getOtherThreadParticipantsMap, (otherParticipantsMap) =>
    otherParticipantsMap
      .get(threadId, Immutable.List())
      .filter((x) => x.getIn(['user', 'threads', threadId, 'isTyping'])),
  );

export const getAllThreadActiveParticipants = (threadId) =>
  createSelector(getThreadParticipantsMap, (threadParticipantsMap) =>
    threadParticipantsMap
      .get(threadId, Immutable.List())
      .filter((x) => x.get('active') || x.get('active') === undefined),
  );
export const getThreadName = createSelector(
  (state, id) => id,
  getOtherThreadParticipantsMap,
  (threadId, otherParticipantsMap) => {
    const users = otherParticipantsMap
      .get(threadId, Immutable.List())
      .filter((x) => x.get('active') || x.get('active') === undefined)
      .map((x) => x.get('user'));

    if (users.size > 2) {
      return `${users.getIn([0, 'firstName'])}, ${users.getIn([1, 'firstName'])} and ${users.size - 2} others`;
    }

    if (users.size > 1) {
      return `${users.getIn([0, 'firstName'])} and ${users.getIn([1, 'firstName'])}`;
    }

    if (users.size > 0) {
      return `${users.getIn([0, 'firstName'])} ${users.getIn([0, 'lastName'])}`;
    }

    return null;
  },
);

export const getUnreadMessageCountByThread = (threadId) =>
  createSelector(getThreadById(threadId), (thread) => {
    try {
      if (!thread.get('lastMessageIsRead') && thread.get('messages') && thread.getIn(['messages', 'totalCount']) > 0) {
        const messages = thread.getIn(['messages', 'edges']);
        const lastSeenMessageId = thread.get('lastSeenMessageId');
        const key = messages.findKey((x) => x.get('node') === lastSeenMessageId);
        return key >= 0 ? key : thread.getIn(['messages', 'totalCount']);
      }
      return 0;
    } catch (err) {
      // console.log(`${JSON.stringify(err)}`);
      return 0;
    }
  });

export const getThreads = createSelector(
  state,
  getAllThreads,
  getThreadParticipantsMap,
  (state, threads, threadParticipantsMap) => {
    const threadsList = threads
      .toList()
      .filter((thread) => !thread.get('isNew'))
      .map((thread) => {
        const threadId = thread.get('id');
        const name = getThreadName(state, threadId);
        const participants = threadParticipantsMap.get(threadId, Immutable.List());

        return thread
          .set('participants', participants)
          .set('name', name)
          .set('unreadMessagesCount', getUnreadMessageCountByThread(threadId)(state));
      })
      .sort((a, b) => b.get('lastMessageSentAt') - a.get('lastMessageSentAt'));

    return threadsList;
  },
);

export const getLastSeenMessageId = createSelector(
  (state, id) => id,
  getLoginUser,
  getThreadParticipantsMap,
  (threadId, currentUser, threadParticipantsMap) => {
    const participants = threadParticipantsMap.get(threadId, Immutable.List());
    const currentParticipant = participants.find((x) => x.get('userId') === currentUser.get('userId'));
    if (currentParticipant) {
      const lastSeenMessageId = currentParticipant.get('lastSeenMessageId');
      return lastSeenMessageId;
    }
    return null;
  },
);

export const getThreadsHasNextPage = createSelector(messaging, (messaging) =>
  messaging.getIn(['threadPageInfo', 'hasNextPage']),
);

export const getThreadsEndCursor = createSelector(messaging, (messaging) =>
  messaging.getIn(['threadPageInfo', 'endCursor']),
);

export const getCommunityThreadsHasNextPage = createSelector(messaging, (messaging) =>
  messaging.getIn(['communityThreadPageInfo', 'hasNextPage']),
);

export const getCommunityThreadsEndCursor = createSelector(messaging, (messaging) =>
  messaging.getIn(['communityThreadPageInfo', 'endCursor']),
);

export const getNewThreads = createSelector(
  state,
  getAllThreads,
  getThreadParticipantsMap,
  (state, threads, threadParticipantsMap) => {
    const threadsList = threads
      .toList()
      .filter((thread) => thread.get('isNew'))
      .map((thread) => {
        const threadId = thread.get('id');
        const name = getThreadName(state, threadId);
        const participants = threadParticipantsMap.get(threadId, Immutable.List());

        return thread
          .set('participants', participants)
          .set('name', name)
          .set('unreadMessagesCount', getUnreadMessageCountByThread(threadId)(state));
      })
      .sort((a, b) => b.get('lastMessageSentAt') - a.get('lastMessageSentAt'));

    return threadsList;
  },
);

export const getActiveThreadId = createSelector(
  getByComponentKey(componentKeys.CURRENT_THREAD_ID),
  getByComponentKey(componentKeys.ROUTE_PATHNAME),
  (id, path) => {
    if (id) return id;
    return (path.match(/^\/messages\/(.{36})/) || [])[1];
  },
);

export const getActiveThread = createSelector(
  getThreads,
  getActiveThreadId,
  getNewThreads,
  (threads, activeThreadId, newThreads) => {
    const thread = threads.find((x) => x.get('id') === activeThreadId);
    if (!thread) {
      const newt = newThreads.find((x) => x.get('id') === activeThreadId);
      return newt;
    }
    return thread;
  },
);

export const getThreadStatus = (threadId) =>
  createSelector(getThreadById(threadId), getFailedMessages(threadId), (thread, failedMessages) => {
    let status = {
      message: '',
      status: threadStatus.LOADING,
      type: 'TEXT',
    };

    if (thread) {
      const failedMessageCount = failedMessages.count();
      const lastMessage = thread.get('lastMessage');

      if (thread.get('isError')) {
        status = {
          message: 'Failed to create conversation.',
          status: threadStatus.FAILED,
        };
      } else if (failedMessageCount > 0) {
        status = {
          message: `${failedMessageCount} message${failedMessageCount > 1 ? 's' : ''} failed to send.`,
          status: threadStatus.FAILED,
        };
      } else if (lastMessage === undefined) {
        status = {
          message: '',
          status: threadStatus.EMPTY,
        };
      } else if (thread.get('lastMessageWithdraw') === true) {
        status = {
          message: 'The message was withdrawn',
          status: threadStatus.SUCCESS,
          type: 'TEXT',
        };
      } else {
        status = {
          message: lastMessage,
          status: threadStatus.SUCCESS,
          type: thread.get('lastMessageType'),
        };
      }
    }

    return Immutable.fromJS(status);
  });

export const getNewMessageCount = createSelector(getThreads, (threads) =>
  threads.reduce((a, x) => a + x.get('unreadMessagesCount') || 0, 0),
);

export const getMessagesHasNextPage = (threadId) =>
  createSelector(getThreadById(threadId), (thread) => thread.has('createdAt') && !thread.get('isHistoryComplete'));

export const getThreadMessageCursor = (threadId) =>
  createSelector(getThreadById(threadId), (thread) => thread.get('messageCursor'));

export const getIsConsumingThread = (threadId) =>
  createSelector(messaging, (messaging) => messaging.getIn(['consumingThreads', threadId]));

export const getThreadByParticipants = (participantIds) =>
  createSelector(getThreads, (threads) =>
    threads.find((thread) => {
      const participants = thread.get('participants').map((x) => x.get('userId'));
      return participants.size === participantIds.length && participants.every((p) => participantIds.includes(p));
    }),
  );

export const getAllParticipantsId = createSelector(messaging, (messages) => {
  const users = messages.get('entities').get('user');

  const userIds = new Set();
  users.map((x) => {
    userIds.add(x.get('userId'));
    return x.get('userId');
  });
  return Array.from(userIds);
});

export const getOnlineUsers = createSelector(messaging, (messages) => {
  const users = messages.get('onlineUsers');
  if (users.size === 0) {
    return [];
  }
  return users;
});

export const getThreadGroupName = (threadId) =>
  createSelector(getThreadById(threadId), (thread) => {
    if (thread) {
      return thread.get('chatName');
    }
    return null;
  });

export const getCommunityThreads = createSelector(state, getAllThreads, (state, threads) => {
  const threadsList = threads
    .toList()
    .filter((thread) => !thread.get('isNew') && thread.get('source') === 'COMMUNITY')
    .map((thread) => {
      const threadId = thread.get('id');
      return thread.set('unreadMessagesCount', thread.get('lastMessageIsRead') ? 0 : 1);
    })
    .sort((a, b) => b.get('lastMessageSentAt') - a.get('lastMessageSentAt'));
  return threadsList;
});
