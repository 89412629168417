import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classNames from 'classnames';
import Checkbox from 'components/molecules/Checkbox';
import Modal from 'components/molecules/Modal';
import EventSummary, { EventSummaryModes } from '../EventSummary';
import { validateTimeAndReason } from '../validations';
import css from './styles.scss';
import { handleEnter } from '../../../../utils/accessibility';

const SESSION_CONFIRM_HEADING = 'DID THIS SESSION TAKE PLACE?';
const CONFIRM_TEXT = 'Yes';
const ABSENT_TEXT = 'No';

export default class Confirm extends Component {
  constructor(props) {
    super(props);

    const { isTutor, adjustedStart, adjustedEnd, isStrategistOrEduCo } = this.props;
    const { reasonValidation, timeValidation } = validateTimeAndReason(this.props);

    // Show a different default mode depending on whether the user is a
    // tutor or a student.
    let defaultMode = isTutor || isStrategistOrEduCo ? EventSummaryModes.VISIBLE : EventSummaryModes.TOGGLED_HIDDEN;

    // Override the default mode if the user has already provided an
    // adjusted time (and is navigating back from the next modal).
    if (defaultMode === EventSummaryModes.TOGGLED_HIDDEN && (adjustedStart || adjustedEnd)) {
      defaultMode = EventSummaryModes.TOGGLED_VISIBLE;
    }

    this.state = {
      eventSummaryMode: defaultMode,
      reasonInvalid: !reasonValidation,
      timesInvalid: !timeValidation,
      isUnderstood: false,
      canClick: true,
    };

    this.toggleEventSummaryMode = this.toggleEventSummaryMode.bind(this);
    this.toggleUnderstood = this.toggleUnderstood.bind(this);
    this.confirmSession = this.confirmSession.bind(this);
  }

  static getDerivedStateFromProps(nextProps) {
    const { reasonValidation, timeValidation } = validateTimeAndReason(nextProps);
    return {
      reasonInvalid: !reasonValidation,
      timesInvalid: !timeValidation,
    };
  }

  toggleEventSummaryMode() {
    const { onAdjustedTimeChange } = this.props;
    const { eventSummaryMode } = this.state;

    const nextMode =
      eventSummaryMode === EventSummaryModes.TOGGLED_VISIBLE
        ? EventSummaryModes.TOGGLED_HIDDEN
        : EventSummaryModes.TOGGLED_VISIBLE;

    // Toggle mode, and clear adjusted times+reason.
    this.setState(
      {
        eventSummaryMode: nextMode,
      },
      onAdjustedTimeChange(undefined, undefined),
    );
  }

  toggleUnderstood() {
    this.setState({ isUnderstood: !this.state.isUnderstood });
  }

  confirmSession() {
    const { reasonInvalid, timesInvalid, canClick } = this.state;
    const { onCorrect } = this.props;

    if (!(reasonInvalid || timesInvalid) && canClick) {
      onCorrect();
      this.setState({ canClick: false }, () => {
        setTimeout(() => {
          this.setState({ canClick: true });
        }, 6000);
      });
    }
  }

  render() {
    const {
      booking,
      personName,
      adjustedStart,
      adjustedEnd,
      adjustedReason,
      onAdjustedTimeChange,
      onAdjustedReasonChange,
      isTutor,
      onIncorrect,
      alert,
      isOpen,
    } = this.props;
    const { eventSummaryMode, timesInvalid, reasonInvalid, isUnderstood, canClick } = this.state;

    return (
      <Modal
        isOpen={isOpen}
        title={SESSION_CONFIRM_HEADING}
        alert={alert}
        onSecondarySubmit={onIncorrect}
        onSubmit={this.confirmSession}
        submitText={CONFIRM_TEXT}
        secondarySubmitText={ABSENT_TEXT}
        isSubmitDisabled={isTutor && !isUnderstood && canClick}
        onSubmitDataTestId="gotoAttendanceFeedbackButton"
        onSecondarySubmitDataTestId="gotoAbsenceFeedbackButton"
      >
        <div className={css.confirm}>
          <EventSummary
            booking={booking}
            personName={personName}
            adjustedStart={adjustedStart}
            adjustedEnd={adjustedEnd}
            adjustedReason={adjustedReason}
            eventSummaryMode={eventSummaryMode}
            toggleEventSummaryMode={this.toggleEventSummaryMode}
            adjustedTimesInvalid={timesInvalid}
            adjustedReasonInvalid={reasonInvalid}
            onAdjustedTimeChange={onAdjustedTimeChange}
            onAdjustedReasonChange={onAdjustedReasonChange}
          />

          {isTutor && (
            <div
              role="checkbox"
              data-ga-label="is Understood Deduction"
              tabIndex={0}
              aria-checked={isUnderstood}
              className={css.deductionConfirm}
              onClick={this.toggleUnderstood}
              onKeyDown={handleEnter(this.toggleUnderstood)}
            >
              <Checkbox isChecked={isUnderstood} dataTestId="isUnderstoodDeductionConfirm" />
              <span className={classNames(css.confirmationMessage, { [css.isUnderstood]: isUnderstood })}>
                I understand that my student&apos;s subject hours will be deducted once I confirm this session.
              </span>
            </div>
          )}
        </div>
      </Modal>
    );
  }
}

Confirm.propTypes = {
  isOpen: PropTypes.bool,
  isTutor: PropTypes.bool.isRequired,
  personName: PropTypes.string.isRequired,
  booking: ImmutablePropTypes.map.isRequired,
  onAdjustedTimeChange: PropTypes.func.isRequired,
  onAdjustedReasonChange: PropTypes.func.isRequired,
  adjustedStart: PropTypes.object,
  adjustedEnd: PropTypes.object,
  adjustedReason: PropTypes.string,
  onCorrect: PropTypes.func,
  onIncorrect: PropTypes.func,
  alert: PropTypes.object,
  isStrategistOrEduCo: PropTypes.bool,
};
