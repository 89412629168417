import styled from 'styled-components';

export const Content = styled.div`
  text-align: center;
`;

export const IconContainer = styled.div`
  width: 100px;
  height: 100px;
  margin: 0 auto;
  svg {
    width: 100%;
    height: auto;
  }
`;

export const Title = styled.h1`
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
`;

export const Body = styled.p`
  color: #73747d;
  font-weight: 500;
  line-height: 20px;
  font-size: 14px;
`;

export const Button = styled.button`
  margin: 36px auto;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 48px;
  gap: 8px;

  background-color: #464ac9;
  border: none;
  border-radius: 30px;

  color: #ffffff;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;

  transition: background 0.3s;

  :hover {
    background-color: rgba(70, 74, 201, 0.9);
  }
`;
