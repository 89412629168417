import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import classNames from 'classnames';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'left',
  },
  chips: {
    fontFamily: 'inherit !important',
    fontSize: 'inherit !important',
    marginRight: '1em',
  },
}));

export default function ReportStatusToggle(props) {
  const css = useStyles();
  const { toggleSelected, selectedStatus } = props;

  const setActiveToggle = (selected) => {
    toggleSelected({ ...selectedStatus, [selected]: !selectedStatus[selected] });
  };

  return (
    <div>
      <Chip
        color={selectedStatus.ready ? 'primary' : 'default'}
        onClick={() => setActiveToggle('ready')}
        label="Ready"
        clickable
        className={classNames('ds-chip', css.chips)}
      />
      <Chip
        color={selectedStatus.sent ? 'primary' : 'default'}
        onClick={() => setActiveToggle('sent')}
        label="Sent"
        clickable
        className={classNames('ds-chip', css.chips)}
      />
      <Chip
        color={selectedStatus.generating ? 'primary' : 'default'}
        onClick={() => setActiveToggle('generating')}
        label="Draft"
        clickable
        className={classNames('ds-chip', css.chips)}
      />
    </div>
  );
}

ReportStatusToggle.propTypes = {
  toggleSelected: PropTypes.func,
  selectedStatus: PropTypes.object,
};
