import React from 'react';
import { PropTypes } from 'prop-types';
import Button from 'components/molecules/Button';
import { SubHeader, Body } from 'components/atoms/typography';
import PersonReading from 'components/atoms/graphics/PersonReading';
import styles from './styles.scss';

export default function EmptyState(props) {
  const { onNewGrade, canEdit, onNewDecile } = props;

  return (
    <div className={styles.emptyContainer}>
      <PersonReading className={styles.emptyGraphic} />

      <SubHeader className={styles.emptyTitle}>No grades have been recorded yet.</SubHeader>

      <Body className={styles.emptyText}>Keep track of all school subjects and standardised testing results here.</Body>

      {canEdit && (
        <div>
          <Button className={styles.emptyButton} onClick={onNewGrade}>
            Add grade
          </Button>
          <Button className={styles.emptyButton} onClick={onNewDecile}>
            Add Decile Rank
          </Button>
        </div>
      )}
    </div>
  );
}

EmptyState.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  onNewGrade: PropTypes.func.isRequired,
  onNewDecile: PropTypes.func.isRequired,
};
