import React from 'react';
import ReactDOM from 'react-dom';
import ErrorView, { Headers } from 'components/generic/ErrorView';

export function Render(elementTarget, props, callback) {
  ReactDOM.render(<ErrorView {...props} />, elementTarget, callback);
}

export function RenderPreset(elementTarget, preset, additionalProps = {}, callback) {
  Render(
    elementTarget,
    {
      preset,
      ...additionalProps,
    },
    callback,
  );
}

export function RenderMessage(elementTarget, message, header = Headers.SystemicError, additionalProps = {}, callback) {
  Render(
    elementTarget,
    {
      header,
      message,
      ...additionalProps,
    },
    callback,
  );
}
