export const INITIAL_STORE: TStore = {
  updatesModalOpen: false,
};

export type TStore = {
  updatesModalOpen: boolean;
};

export type TActionWithCustomPayload<N, P> = {
  name: N;
  payload: P;
};

export type TAction<N, K extends keyof TStore> = TActionWithCustomPayload<N, Pick<TStore, K>>;

export type TActionWithoutPayload<N> = TActionWithCustomPayload<N, undefined>;

export type TActionWithPartialPayload<N, K extends keyof TStore> = TActionWithCustomPayload<
  N,
  Partial<Pick<TStore, K>>
>;

type SetUpdatesModalOpen = TAction<'MSU/setUpdatesModalOpen', 'updatesModalOpen'>;

export type Actions = SetUpdatesModalOpen;

export const reducer = <A extends Actions>(state: TStore, action: A): TStore => {
  switch (action.name) {
    case 'MSU/setUpdatesModalOpen':
      const { updatesModalOpen } = action.payload;
      return {
        ...state,
        updatesModalOpen,
      };
    default:
      return state;
  }
};
