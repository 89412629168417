import Immutable from 'immutable';
import { createSelector } from 'reselect';

const packageItems = (state) => state.get('packageItem');

export const allPackageItems = createSelector(packageItems, (packageItems) => packageItems.toJS());

export const getPackageItem = (id) => {
  return createSelector(packageItems, (packageItems) => packageItems.get(id));
};

export const getAllSubjects = createSelector(packageItems, (packageItems) => {
  const subjects = packageItems
    .valueSeq()
    .toJS()
    .reduce((prev, curr) => {
      prev[curr.id] = {
        id: curr.id,
        subject: curr.name,
        initialValue: curr.initialValue,
        remainingValue: curr.remainingValue,
        pendingValue: curr.pendingValue,
        isUnlimited: curr.isUnlimited,
        tutors: curr.contract,
        subjectId: curr.subjectId,
        upsellAvailable: curr.subject ? curr.subject.upsellAvailable : null,
        lastPurchase: curr.lastPurchase,
      };

      return prev;
    }, {});

  return Immutable.fromJS(subjects)
    .toOrderedMap()
    .sortBy((item) => item.get('subject'));
});

export const getSingleSubjects = createSelector(packageItems, (packageItems) => packageItems);

// Retrieve a list of tutorIds ordered by their recommendation rank.
export const getRecommendedTutorIds = createSelector(
  packageItems,
  (_, packageItemId) => packageItemId,
  (items, packageItemId) => {
    const tutors = items.getIn([parseInt(packageItemId, 10), 'tutorRecommendations']);
    return tutors ? tutors.map((i) => i.get('userId')).toJS() : [];
  },
);

// Retrieve a list of tutor recommendation by package item
export const getTutorRecommendations = createSelector(
  packageItems,
  (_, packageItemId) => packageItemId,
  (items, packageItemId) => {
    const tutors = items.getIn([parseInt(packageItemId, 10), 'tutorRecommendations']);
    return tutors ? tutors.toJS() : [];
  },
);
