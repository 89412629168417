import contracts from '../graphql/api/contract';

export function uploadFile(packageName, file) {
  if (!file || !file.name) return Promise.resolve([]);
  return contracts.packageFilePresignedUploadUrl(file.name, packageName).then(async (result) => {
    // pretty sure this is result.packageFilePresignedUploadUrl, not result.data.packageFilePresignedUploadUrl.
    const packageFilePresignedUploadUrl =
      result && (result.packageFilePresignedUploadUrl || (result.data && result.data.packageFilePresignedUploadUrl));
    if (packageFilePresignedUploadUrl) {
      const urlData = packageFilePresignedUploadUrl;
      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': '',
        },
        body: file,
        mode: 'cors',
      };
      return fetch(urlData.putUrl, options)
        .then((res) => {
          if (!res.ok) {
            return null;
          }
          return Object.assign(urlData, { name: file.name });
        })
        .catch((e) => {
          throw new Error(e);
        });
    }
    throw new Error('File upload url not found');
  });
}

export function constructUserRelationMap(subjectMap, rootUserId, childUserId, user, subjectName, item) {
  if (!subjectMap[rootUserId]) {
    subjectMap[rootUserId] = {};
  }
  if (!subjectMap[rootUserId][childUserId]) {
    subjectMap[rootUserId][childUserId] = {
      firstName: user.firstName,
      lastname: user.lastName,
      subjects: [],
    };
  }
  if (subjectMap[rootUserId][childUserId] && !subjectMap[rootUserId][childUserId].subjects.includes(subjectName)) {
    subjectMap[rootUserId][childUserId].subjects.push({ subjectName, subjectId: item.id });
  }
}
