import React from 'react';
import { tutorCapacityTypes } from '@crimson-education/common-config';

import FilterTag, { FILTER_ICONS } from 'components/molecules/FilterTag';
import TutorCapacityDisplay from 'components/molecules/TutorCapacityDisplay';
import Checkbox from 'components/molecules/Checkbox';

import Filter from './filter.js';
import css from './capacity.css';

const { AVAILABLE, SEEKING_HOURS, FULL } = tutorCapacityTypes;

export default class CapacityFilter extends Filter {
  constructor(...args) {
    super(...args);
    this.state = {
      [AVAILABLE]: true,
      [SEEKING_HOURS]: true,
      [FULL]: false,
      active: true,
    };
    this.title = 'Capacity';
  }

  apply(tutor) {
    return !this.state.active || this.state[tutor.tutorInfo.capacity];
  }

  data() {
    // Doesn't use any data.
  }

  simpleValue() {
    return this.value().join(',');
  }

  value() {
    return [AVAILABLE, SEEKING_HOURS, FULL].filter((key) => this.state[key] === true);
  }

  reset() {
    this.setState({
      [AVAILABLE]: false,
      [SEEKING_HOURS]: false,
      [FULL]: false,
      active: false,
    });
  }

  handleSelect(value) {
    const newState = {
      ...this.state,
      [value]: !this.state[value],
    };

    const allSame = newState[AVAILABLE] || newState[SEEKING_HOURS] || newState[FULL];

    newState.active = allSame;

    this.setState(newState);
  }

  active() {
    return this.state.active;
  }

  edit() {
    return (
      <div>
        <div className={css.checkboxField}>
          <Checkbox isChecked={this.state[SEEKING_HOURS]} onClick={() => this.handleSelect(SEEKING_HOURS)} />
          <TutorCapacityDisplay type={SEEKING_HOURS} iconPosition="after" alternativeLabel="Seeking hours" />
        </div>
        <div className={css.checkboxField}>
          <Checkbox isChecked={this.state[AVAILABLE]} onClick={() => this.handleSelect(AVAILABLE)} />
          <TutorCapacityDisplay type={AVAILABLE} iconPosition="after" alternativeLabel="Available" />
        </div>
        <div className={css.checkboxField}>
          <Checkbox isChecked={this.state[FULL]} onClick={() => this.handleSelect(FULL)} />
          <TutorCapacityDisplay type={FULL} iconPosition="after" alternativeLabel="Full" />
        </div>
      </div>
    );
  }

  tag() {
    const displayValues = [
      this.state[AVAILABLE] && 'Available',
      this.state[SEEKING_HOURS] && 'Seeking Hours',
      this.state[FULL] && 'Full',
    ].filter((i) => i);

    const displayValue = displayValues.length === 1 ? displayValues[0] : `Capacity: ${displayValues.length}`;

    return (
      <FilterTag
        dataTestId="capacityFilterTag"
        onClear={() => this.reset()}
        onClick={() => this.openModal()}
        value={this.active() ? 'active' : ''}
        displayValue={displayValue}
        label="Capacity"
        icon={FILTER_ICONS.SELECT}
      />
    );
  }
}
