import React, { useState } from 'react';
import Popover, { PopoverOrigin } from '@material-ui/core/Popover';
import { StyledDiv } from './index.style';
export type DropdownBaseProps = {
  children: React.ReactNode | ((isOpen: boolean) => React.ReactNode);
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  onOpenDropdown?: () => void;
  preOpenDropdown?: () => boolean;
  disable?: boolean;
  wrapperStyle?: React.CSSProperties;
};
const createDropdown = <P,>(DropdownComp: React.ComponentType<P>) => {
  const DropDown = ({
    children,
    anchorOrigin = { vertical: 'bottom', horizontal: 'left' },
    transformOrigin = { vertical: 'top', horizontal: 'left' },
    onOpenDropdown,
    disable,
    preOpenDropdown,
    wrapperStyle,
    ...restProps
  }: DropdownBaseProps & Omit<P, 'closeDropdown' | 'isOpen'>) => {
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
    const onClick = (event: React.MouseEvent<HTMLDivElement>) => {
      if (disable) return;
      if (!(preOpenDropdown ? preOpenDropdown() : true)) return;
      event.stopPropagation();
      onOpenDropdown && onOpenDropdown();
      setAnchorEl(event.currentTarget);
    };
    const close = () => setAnchorEl(null);
    const isFunctionChildren = typeof children === 'function';
    return (
      <>
        <StyledDiv style={wrapperStyle} role={'button'} tabIndex={0} onKeyDown={undefined} onClick={onClick}>
          {isFunctionChildren ? (children as (isOpen: boolean) => React.ReactNode)(!!anchorEl) : children}
        </StyledDiv>
        <Popover
          onClick={(event) => {
            event.stopPropagation();
          }}
          PaperProps={{
            style: {
              boxShadow: '0px 5px 15px rgba(61, 64, 144, 0.2)',
              borderRadius: 8,
              border: '1px solid #E3E7ED',
            },
          }}
          anchorOrigin={anchorOrigin}
          anchorEl={anchorEl}
          transformOrigin={transformOrigin}
          open={!!anchorEl}
          onClose={close}
        >
          <DropdownComp isOpen={!!anchorEl} closeDropdown={close} {...((restProps as unknown) as P)} />
        </Popover>
      </>
    );
  };
  return DropDown;
};

export default createDropdown;
