import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';
import { gql } from '@apollo/client';
import { createClient } from '../utils';

const { api } = getConfig();

const client = createClient(api.graphQLEndpoint);

const participantsType = gql`
  fragment participantsType on eventTypeCalendar {
    participants {
      userId
      firstName
      lastName
      userRoles
      roles {
        isPrimary
        roleId
        role {
          name
        }
      }
      profileImageUrl
      timezone
      isBetaUser
    }
  }
`;

const participantsTypeCalendar = gql`
  fragment participantsTypeCalendar on eventTypeCalendar {
    participants {
      userId
      firstName
      lastName
      userRoles
      roles {
        isPrimary
        roleId
        role {
          name
        }
      }
      profileImageUrl
      timezone
    }
  }
`;

const eventTypeCalendar = gql`
  ${participantsTypeCalendar}
  fragment eventTypeCalendar on eventTypeCalendar {
    id
    name
    description
    status
    eventStatus
    start
    end
    reportVersion
    cancellationCount
    creatorUserId
    recipientUserId
    participantUserIds
    ...participantsTypeCalendar
    otherUserId
    otherUser {
      userId
      firstName
      lastName
      userRoles
      roles {
        isPrimary
        roleId
        role {
          name
        }
      }
      profileImageUrl
    }
    source
    type
    itemId
    subject {
      id
      name
      start
    }
    zoomMeetingUrl
    attendance {
      id
      eventId
      userId
      otherUserId
      start
      end
      status
    }
    hasAgenda
    hasNotes
    tasksCount
    otherUserZoomUrl
    participantType
  }
`;

const fetchEventById = gql`
  ${eventTypeCalendar}
  query fetchEventById($id: String!, $userId: String) {
    event: eventById(id: $id, userId: $userId) {
      ...eventTypeCalendar
    }
  }
`;

const bindCalendarAccountOauth = gql`
  mutation bindCalendarAccountOauth($source: String!, $authCode: String!, $redirectUrl: String!) {
    bindCalendarAccountOauth(source: $source, authCode: $authCode, redirectUrl: $redirectUrl)
  }
`;

const bindCalendarAccountBasic = gql`
  mutation bindCalendarAccountBasic($account: String!, $source: String!, $password: String!) {
    bindCalendarAccountBasic(account: $account, source: $source, password: $password)
  }
`;

const unbindCalendarAccount = gql`
  mutation unbindCalendarAccount($source: String!, $forceUnbind: Boolean) {
    bindCalResult: unbindCalendarAccount(source: $source, forceUnbind: $forceUnbind)
  }
`;

const getCalendarSyncState = gql`
  query getCalendarSyncState {
    getCalendarSyncState {
      google {
        account
        synced
      }
      apple {
        account
        synced
      }
    }
  }
`;
const fetchSessionParticipants = gql`
  ${participantsType}
  query fetchSessionParticipants($id: String!) {
    event: event(id: $id) {
      id
      name
      start
      end
      participantUserIds
      ...participantsType
    }
  }
`;

const fetchEventsBetweenForUser = gql`
  ${eventTypeCalendar}
  query fetchEventsBetweenForUser($from: String!, $to: String, $userId: String, $includeExternal: Boolean) {
    events: allEvents(from: $from, to: $to, userId: $userId, inclusive: true, includeExternal: $includeExternal) {
      ...eventTypeCalendar
    }
  }
`;

const fetchEventIdsBetweenForUser = gql`
  query fetchEventIdsBetweenForUser($from: String!, $to: String, $userId: String) {
    events: allEvents(from: $from, to: $to, userId: $userId, inclusive: true) {
      id
      eventStatus
    }
  }
`;

const allEventsRequiringFeedback = gql`
  ${eventTypeCalendar}
  query allEventsRequiringFeedback {
    events: allEventsRequiringFeedback {
      ...eventTypeCalendar
    }
  }
`;

const setEventAdjustment = gql`
  mutation setEventAdjustment(
    $eventId: String!
    $proposedStart: String
    $proposedEnd: String
    $reason: String
    $otherUserId: String
    $creatorUserId: String
  ) {
    eventAdjustment: setEventAdjustment(
      eventId: $eventId
      proposedStart: $proposedStart
      proposedEnd: $proposedEnd
      reason: $reason
      otherUserId: $otherUserId
      creatorUserId: $creatorUserId
    ) {
      id
      eventId
      proposedStart
      proposedEnd
      reason
      proposalUserId
    }
  }
`;

const fetchEventSchedule = gql`
  ${eventTypeCalendar}
  query fetchEventSchedule($userId: ID!, $date: String, $isReverse: Boolean, $pagination: paginationParams) {
    events: allEventsSchedule(userId: $userId, date: $date, isReverse: $isReverse, pagination: $pagination) {
      ...eventTypeCalendar
    }
  }
`;

const fetchEventByAction = gql`
  query getEventByAction($action: String, $userId: ID, $participantUserIds: [ID], $eventId: String, $itemId: Int) {
    getEventByAction(
      action: $action
      userId: $userId
      participantUserIds: $participantUserIds
      eventId: $eventId
      itemId: $itemId
    )
  }
`;

export default {
  fetchCalSyncStatus: async () => {
    return client.query(getCalendarSyncState).then((res) => {
      if (res.errors) {
        return Promise.reject(new Error('failed to bindCalendarAccountBasic'));
      }
      return res.getCalendarSyncState;
    });
  },
  bindCalendarAccountBasic: async ({ account, source, password }) => {
    return client.query(bindCalendarAccountBasic, { account, source, password }).then((res) => {
      if (res.errors) {
        return Promise.reject(new Error('failed to bindCalendarAccountBasic'));
      }
      return res.bindCalendarAccountBasic;
    });
  },
  bindCalendarAccountOauth: async ({ source, authCode, redirectUrl }) => {
    return client.query(bindCalendarAccountOauth, { source, authCode, redirectUrl }).then((res) => {
      if (res.errors) {
        return Promise.reject(new Error('failed to bindCalendarAccountOauth'));
      }
      return res.bindCalendarAccountOauth;
    });
  },
  unbindCalendarAccount: async (calSource, forceUnbind) => {
    return client.query(unbindCalendarAccount, { source: calSource, forceUnbind }).then((res) => {
      if (res.errors) {
        return Promise.reject(new Error('failed to unbindCalendarAccount'));
      }
      return res.bindCalResult;
    });
  },
  fetchEventById: (id, userId) =>
    client.query(fetchEventById, { id: id.toString(), userId }).then((res) => {
      if (res.errors) {
        return Promise.reject(new Error('failed to fetch event'));
      }
      return res.event;
    }),
  fetchSessionParticipants: (id) => client.query(fetchSessionParticipants, { id }),
  fetchEventsBetweenForUser: (from, to, userId, includeExternal) => {
    return client.query(fetchEventsBetweenForUser, { from, to, userId, includeExternal }).then((res) => {
      if (!res.events || res.errors) {
        return Promise.reject(new Error('failed to fetch events'));
      }
      const newEvent = res.events;
      return newEvent;
    });
  },
  fetchEventIdsBetweenForUser: (from, to, userId) =>
    client.query(fetchEventIdsBetweenForUser, { from, to, userId }).then((res) => {
      if (!res.events || res.errors) {
        return Promise.reject(new Error('failed to fetch events'));
      }
      return res.events;
    }),
  allEventsRequiringFeedback: () =>
    client.query(allEventsRequiringFeedback).then((res) => {
      if (!res.events || res.errors) {
        return Promise.reject(new Error('failed to fetch events'));
      }
      return res.events;
    }),
  setEventAdjustment: (eventId, proposedStart, proposedEnd, reason, otherUserId, creatorUserId) =>
    client.query(setEventAdjustment, { eventId, proposedStart, proposedEnd, reason, otherUserId, creatorUserId }),
  fetchEventSchedule: (args) =>
    client.query(fetchEventSchedule, args).then((res) => {
      if (!res.events || res.errors) {
        return Promise.reject(new Error('failed to fetch events schedule'));
      }
      return res.events;
    }),
  fetchEventByAction: (action, userId, participantUserIds, eventId, itemId) =>
    client.query(fetchEventByAction, { action, userId, participantUserIds, eventId, itemId }),
};
