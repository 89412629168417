import React, { useContext } from 'react';
import { AppTrackerContext } from '../viewController';
import { Container } from './index.style';
import { Title, Content, AddUniversityButton } from './index.style';
import AddIcon from '@material-ui/icons/Add';
import { useSelector } from 'react-redux';
import { getYearOfApplication } from 'selectors/profile';
import { getViewableUser } from 'selectors/meta';
import { roleTypes } from '@crimson-education/common-config';

const PLACEHOLDER_IMG_SRC = '/static/applicationTracker/setupApplication.png';
const SetupApplication = () => {
  const { controller } = useContext(AppTrackerContext);
  const userId = useSelector(getViewableUser);
  const yearOfApplication = useSelector(getYearOfApplication(userId, roleTypes.STUDENT));
  const onClickAddUniversity = () => {
    if (!yearOfApplication) {
      return controller.openSelectAppCycleHint();
    }
    controller.openAddUniversityModal();
  };
  return (
    <Container>
      <img width={176} height={156} src={PLACEHOLDER_IMG_SRC} alt={'app_tracker_placeholder_img'} />
      <Title>{'Build your school list'}</Title>
      <Content>{'Get started by adding your application cycle and a \nuniversity of your choice!'}</Content>
      <AddUniversityButton
        onClick={onClickAddUniversity}
        variant={'contained'}
        color={'primary'}
        startIcon={<AddIcon />}
      >
        {'Add a University'}
      </AddUniversityButton>
    </Container>
  );
};

export default SetupApplication;
