import moment from 'moment';

const format = 'DD/MM/YYYY';
const fallbackFormat = 'YYYY-MM-DD';

/**
 * Do not use moment.toDate() as this new date will not be using the app timzone but the browser's timezone
 * @param momentObj
 * @returns {Date}
 */
export const convertMomentToDate = (momentObj) => {
  return momentObj && momentObj.isValid() ? momentObj.toDate() : new Date();
};

export const setToday = (selectedDates, dateStr, instance) => {
  instance.now = new Date();
};

export const parseDateFunction = (previousValue) => {
  return (value) => {
    const momentDate = moment(value, format);
    const fallBackMomentDate = momentDate.isValid() ? momentDate : moment(value, fallbackFormat);
    const newDate = fallBackMomentDate.isValid() ? fallBackMomentDate : moment(previousValue, format);
    return convertMomentToDate(newDate);
  };
};

/**
 * In the Mission form, this function updates a value depending on the last two values updated e.g. If days and endDate are updated then startDate will update
 * If nothing has been updated yet, it uses initialSecondLastUpdated to set an initial state
 * We really hope the user will drop this dumb requirement eventually
 * @param prevState - Component state before all these changes
 * @param {String} value - Updated field value
 * @param updatedField - Options: 'startDate', 'days', 'endDate'
 * @returns a new state with the updated values
 */
export const calculateDatesAndDays = (prevState, value, updatedField) => {
  const allFields = ['startDate', 'days', 'endDate'];
  const lastUpdated = updatedField;
  let secondLastUpdated;

  const initialSecondLastUpdated = (field) =>
    ({
      startDate: 'endDate',
      days: 'startDate',
      endDate: 'startDate',
    }[field]);

  if (!prevState.secondLastUpdated) {
    secondLastUpdated = initialSecondLastUpdated(lastUpdated);
  } else {
    secondLastUpdated = prevState.lastUpdated === updatedField ? prevState.secondLastUpdated : prevState.lastUpdated;
  }

  const newState = Object.assign({}, prevState, {
    [updatedField]: value,
  });

  const fieldUpdate = (field) =>
    ({
      startDate: moment(newState.endDate, format).subtract(parseInt(newState.days, 10), 'days').format(format),
      days: moment(newState.endDate, format)
        .startOf('day')
        .diff(moment(newState.startDate, format).startOf('day'), 'days')
        .toString(),
      endDate: moment(newState.startDate, format).add(parseInt(newState.days, 10), 'days').format(format),
    }[field]);

  const fieldToUpdate = allFields.filter((field) => field !== lastUpdated && field !== secondLastUpdated);

  return {
    ...newState,
    [fieldToUpdate]: fieldUpdate(fieldToUpdate),
    lastUpdated,
    secondLastUpdated,
  };
};
