import React from 'react';
import PropTypes from 'prop-types';
import Search from '@material-ui/icons/Search';

import css from './styles.scss';
import { handleEnter } from '../../../utils/accessibility';

export default function TextFilter(props) {
  // Map event handlers to optional handler functions.
  const handlers = {
    onChange: props.onChangeHandler,
    onFocus: props.onFocusHandler,
  };

  return (
    <div className={css.filter}>
      <input
        type="text"
        data-test-id={props.dataTestId}
        placeholder={props.placeholder}
        value={props.value}
        className={css.inputText}
        {...handlers}
      />
      <div
        role="button"
        tabIndex={0}
        className={css.iconContainer}
        onClick={() => window.focus()}
        onKeyDown={handleEnter(() => window.focus())}
      >
        <Search className={css.magnifyingGlass} />
      </div>
    </div>
  );
}

TextFilter.propTypes = {
  onFocusHandler: PropTypes.func,
  onChangeHandler: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  dataTestId: PropTypes.string,
};
