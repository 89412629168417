import profile from './columns/Profile';
import userSince from './columns/UserSince';
import userType from './columns/UserType';
import studentPackageSubjects from './columns/StudentPackageSubjects';
import tutorSubjects from './columns/TutorSubjects';
import caseManagerStudentCount from './columns/CaseManagerStudentCount';
import location from './columns/Location';
import university from './columns/University';
import editUserButton from './columns/EditUserButton';
import applicationYear from './columns/ApplicationYear';
import quickLink from './columns/QuickLink';
import friendActionButton from './columns/FriendActionButton';
import teamActionButton from './columns/TeamActionButton';

export const columnNames = {
  ProfileColumn: 'ProfileColumn',
  UserSince: 'UserSince',
  UserType: 'UserType',
  StudentPackageSubjects: 'StudentPackageSubjects',
  TutorSubjects: 'TutorSubjects',
  CaseManagerStudentCount: 'CaseManagerStudentCount',
  Location: 'Location',
  University: 'University',
  EditUserButton: 'EditUserButton',
  ApplicationYear: 'ApplicationYear',
  QuickLink: 'QuickLink',
  FriendActionButton: 'FriendActionButton',
  TeamActionButton: 'TeamActionButton',
};

// TODO: Add parameter checks and safety to these columns!
const columnMap = {
  [columnNames.ProfileColumn]: (params) => profile(params),
  [columnNames.UserSince]: () => userSince(),
  [columnNames.UserType]: () => userType(),
  [columnNames.StudentPackageSubjects]: () => studentPackageSubjects(),
  [columnNames.TutorSubjects]: () => tutorSubjects(),
  [columnNames.CaseManagerStudentCount]: () => caseManagerStudentCount(),
  [columnNames.Location]: () => location(),
  [columnNames.University]: () => university(),
  [columnNames.EditUserButton]: (params) => editUserButton(params),
  [columnNames.ApplicationYear]: () => applicationYear(),
  [columnNames.FriendActionButton]: (params) => friendActionButton(params),
  [columnNames.TeamActionButton]: (params) => teamActionButton(params),
  [columnNames.QuickLink]: () => quickLink(),
};

export default (columns, params) => {
  return columns.map((colName) => columnMap[colName](params));
};
