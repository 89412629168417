import React, { useContext, useState, useMemo, useEffect } from 'react';
import Drawer from '@material-ui/core/Drawer';
import { AppTrackerContext } from '../viewController';
import { AddResultText, UniversityText, Container, StatusSelector } from './index.style';
import { useSelector } from 'react-redux';
import { getViewableUser } from 'selectors/meta';
import WithTitle from './WithTitle';
import BooleanSelector from './BooleanSelector';
import FileUploader from './FileUploader';
import HeaderBar from './Header';
import ConfirmCancelButtons from '../ConfirmCancelButtons';
import { FileUploadRes, Application } from '../type';
import { hasAdmissionResult } from '../util';
import { useRequesterUserId } from '../helper';
import { APP_STATUS_DROPDOWN_OPTIONS } from '../constants';
import Input from 'components/molecules/InputText';
import { toasterMessages } from '@crimson-education/common-config';
import { formSuccess, formFail } from 'ducks/meta';
import { useDispatch } from 'react-redux';
import Aid from './Aid';
import { omit, pick } from 'lodash';

const isSameArray = (arr1: string[], arr2: string[]) => arr1.sort().join('') === arr2.sort().join('');

const isSameObj = <T1 extends object, T2 extends object>(obj1: T1, obj2: T2) => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  if (!isSameArray(keys1, keys2)) return false;
  const formatedObj1: any = {};
  const formatedObj2: any = {};
  keys1.forEach((key) => {
    formatedObj1[key] = obj1[key as keyof T1];
  });
  keys2.forEach((key) => {
    formatedObj2[key] = obj2[key as keyof T2];
  });
  return JSON.stringify(formatedObj1) === JSON.stringify(formatedObj2);
};
type EditableValues = {
  degree: string;
  hadInterview: boolean;
  applicationStatus: string[];
  receivedAid: boolean;
  totalAid: number | null;
  currency?: string | null;
  aidDetails: string;
  aidType: string;
};
const composePayload = (editableValues: EditableValues) => {
  if (editableValues.receivedAid) {
    return {
      ...editableValues,
    };
  }
  return omit(editableValues, ['totalAid', 'currency', 'aidDetails', 'aidType']);
};

const isValueEdited = (application: Application | null, attrs: EditableValues) => {
  if (!application) return true;
  const editedValues = composePayload(attrs);
  const originValues = pick(application, Object.keys(editedValues));
  return !isSameObj(editedValues, originValues);
};

const isValidTotalAid = (totalAid: string | null) => {
  if (!totalAid) return false;
  const num = parseFloat(totalAid);
  if (isNaN(num)) return false;
  return num > 0 && totalAid.length <= 12;
};
const canSubmit = ({
  isEditingMode,
  isChanged,
  statusLength,
  hasAid,
  currency,
  totalAid,
}: {
  isEditingMode: boolean;
  isChanged: boolean;
  statusLength: number;
  hasAid: boolean;
  currency: string | undefined;
  totalAid: string;
}) => {
  const isAidValid = hasAid ? !!currency && !!totalAid : true;
  return isEditingMode ? isChanged && statusLength > 0 && isAidValid : statusLength > 0 && isAidValid;
};
const useAid = (application: Application | null, isDrawerOpen: boolean) => {
  const [hasAid, setHasAid] = useState(false);
  const [aidType, setAidType] = useState<'scholarship' | 'financial_aid'>('scholarship');
  const [totalAid, setTotalAid] = useState<string>('');
  const [currency, setCurrency] = useState<{ label: string; value: string } | null>(null);
  const [aidDetails, setAidDetails] = useState('');
  useEffect(() => {
    if (!application || !hasAdmissionResult(application)) return;
    setHasAid(application.receivedAid);
    application.aidType && setAidType(application.aidType);
    typeof application.totalAid === 'number' && setTotalAid(application.totalAid.toString());
    application.currency && setCurrency({ label: application.currency, value: application.currency });
    application.aidDetails && setAidDetails(application.aidDetails);
  }, [application]);
  useEffect(() => {
    if (!isDrawerOpen) {
      setHasAid(false);
      setAidType('scholarship');
      setTotalAid('');
      setCurrency(null);
      setAidDetails('');
    }
  }, [isDrawerOpen]);
  const _setTotalAid = (v: string) => {
    setTotalAid(v.trim());
  };
  return {
    hasAid,
    setHasAid,
    aidType,
    setAidType,
    totalAid,
    setTotalAid: _setTotalAid,
    currency,
    setCurrency,
    aidDetails,
    setAidDetails,
  };
};

const AdmissionDrawer = () => {
  const { state, controller } = useContext(AppTrackerContext);
  const userId = useSelector(getViewableUser);
  const { admissionDrawerApplication } = state;
  const [degree, setDegree] = useState<string>('');
  const [isInterview, setIsInterview] = useState<boolean>(true);
  const [statuses, setStatuses] = useState<{ label: string; value: string }[]>([]);
  const aidValues = useAid(admissionDrawerApplication, state.isAdmissionDrawerOpen);
  const { hasAid, aidType, totalAid, currency, aidDetails } = aidValues;
  const requesterUserId = useRequesterUserId();
  useEffect(() => {
    if (!state.isAdmissionDrawerOpen) {
      setDegree('');
      setIsInterview(true);
      setStatuses([]);
    }
  }, [state.isAdmissionDrawerOpen]);
  useEffect(() => {
    if (!admissionDrawerApplication || !hasAdmissionResult(admissionDrawerApplication)) return;
    admissionDrawerApplication.degree && setDegree(admissionDrawerApplication.degree);
    setIsInterview(admissionDrawerApplication.hadInterview);
    setStatuses(admissionDrawerApplication.applicationStatus.map((status) => ({ label: status, value: status })));
  }, [admissionDrawerApplication]);
  const isEditingMode = useMemo(() => !!hasAdmissionResult(admissionDrawerApplication), [admissionDrawerApplication]);
  const isChanged = useMemo(
    () =>
      isValueEdited(admissionDrawerApplication, {
        degree,
        hadInterview: isInterview,
        receivedAid: hasAid,
        applicationStatus: statuses.map((o) => o.value),
        totalAid: !!totalAid ? parseFloat(totalAid) : null,
        currency: currency ? currency.value : null,
        aidDetails,
        aidType,
      }),
    [degree, isInterview, hasAid, statuses, totalAid, currency, aidDetails, aidType, admissionDrawerApplication],
  );
  const _canSubmit = useMemo(
    () =>
      canSubmit({
        isEditingMode,
        isChanged,
        statusLength: statuses.length,
        hasAid,
        currency: currency?.value,
        totalAid,
      }),
    [isEditingMode, isChanged, statuses, hasAid, currency, totalAid],
  );
  const dispatch = useDispatch();
  const uploadAdmissionOfferFile = async (file: FileUploadRes) => {
    if (!admissionDrawerApplication) return false;
    const success = await controller.uploadAdmissionOffer(
      admissionDrawerApplication.id,
      file.saveUploadedFile.id,
      userId,
    );
    return success;
  };
  const deleteAdmissionOfferFile = async (fileId: string) => {
    if (!admissionDrawerApplication) return;
    const success = await controller.deleteAdmissionOffer(admissionDrawerApplication.id, fileId, userId);
  };
  const onClickConfirm = async () => {
    if (!admissionDrawerApplication || !_canSubmit) return;
    let success: boolean;
    const payload = composePayload({
      degree,
      applicationStatus: statuses.map((o) => o.value),
      hadInterview: isInterview,
      receivedAid: hasAid,
      totalAid: !!totalAid ? parseFloat(totalAid) : null,
      currency: currency?.value,
      aidDetails,
      aidType,
    });
    if (isEditingMode) {
      // update admission
      if (hasAid && !isValidTotalAid(totalAid)) return dispatch(formFail('Please enter a valid number'));
      success = await controller.updateApplication(
        admissionDrawerApplication.id,
        {
          ...payload,
        },
        {
          updatedBy: requesterUserId,
        },
      );
      if (success) {
        dispatch(formSuccess(toasterMessages.admissionResultUpdated()));
      }
    } else {
      // submit admission
      success = await controller.submitAdmission({
        applicationId: admissionDrawerApplication.id,
        ...payload,
      });
    }
    if (success) controller.closeAdmissionDrawer();
  };
  const onDegreeInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDegree(e.target.value);
  };
  return (
    <Drawer anchor={'right'} open={state.isAdmissionDrawerOpen} onClose={controller.closeAdmissionDrawer}>
      <Container>
        <HeaderBar
          status={'Submitted'}
          closeModal={controller.closeAdmissionDrawer}
          application={admissionDrawerApplication}
        />
        <AddResultText>{'Add a Result'}</AddResultText>
        <UniversityText>{admissionDrawerApplication?.university}</UniversityText>
        <WithTitle title={'Degree'}>
          <Input placeholder={'Input your major'} onChange={onDegreeInputChange} value={degree} />
        </WithTitle>

        <WithTitle title={'Was there an interview?'}>
          <BooleanSelector value={isInterview} onChange={setIsInterview} />
        </WithTitle>
        <WithTitle title={'Status'}>
          <div style={{ width: 350 }}>
            <StatusSelector value={statuses} onChange={setStatuses} options={APP_STATUS_DROPDOWN_OPTIONS} />
          </div>
        </WithTitle>

        <Aid {...aidValues} />

        <WithTitle title={'Your Acceptance Letter'}>
          <FileUploader
            offerFiles={admissionDrawerApplication?.offerFiles}
            deleteOfferFile={deleteAdmissionOfferFile}
            onFileUploaded={uploadAdmissionOfferFile}
          />
        </WithTitle>
        <ConfirmCancelButtons
          onClickCancel={controller.closeAdmissionDrawer}
          style={{ marginTop: 40 }}
          confirmButtonText={isEditingMode ? 'Update' : 'Submit'}
          onClickConfirm={onClickConfirm}
          disabled={!_canSubmit}
        />
      </Container>
    </Drawer>
  );
};

export default AdmissionDrawer;
