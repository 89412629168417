import { createSelector } from 'reselect';

const google = (state) => state.get('google');
const resource = createSelector(google, (google) => google.get('resource'));
const sessionFile = createSelector(google, (google) => google.get('sessionFile'));

export const getAllResources = createSelector(resource, (resource) => resource.toJS());

export const getResourcesBySubjectId = createSelector(resource, (resource) => resource.toJS());

export const getFilesForSession = createSelector(
  sessionFile,
  (_, sessionId) => sessionId,
  (sessionFile, sessionId) => sessionFile.filter((s) => s.get('sessionId') === sessionId).toJS(),
);
