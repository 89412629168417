import React from 'react';
import PropTypes from 'prop-types';

export default function TasksComplete({ className }) {
  return (
    <svg viewBox="0 0 121 96" xmlns="http://www.w3.org/2000/svg" className={className}>
      <g fill="none" fillRule="evenodd" transform="translate(.75)">
        <ellipse cx="59.122" cy="92.195" fill="#f2f3f6" rx="35.941" ry="2.985" />
        <path
          d="m30.0292683 11.7073171h58.2439024c2.0487805 0 3.6878049 1.6390244 3.6878049 3.6878049v71.4731707c0 2.0487805-1.6390244 3.6878049-3.6878049 3.6878049h-58.2439024c-2.0487805 0-3.6878049-1.6390244-3.6878049-3.6878049v-71.4731707c0-2.0487805 1.6390244-3.6878049 3.6878049-3.6878049z"
          fill="#1e2248"
        />
        <path
          d="m77.2682927 83.8829268h-42.3804878c-1.697561 0-3.0439025-1.3463414-3.0439025-3.0439024v-59.4731707c0-1.697561 1.3463415-3.0439025 3.0439025-3.0439025h48.4682927c1.6975609 0 3.0439024 1.3463415 3.0439024 3.0439025v52.7414634"
          fill="#fffffa"
        />
        <path
          d="m49.5219512 33.3658537h31.6682927c.8195122 0 1.4634146.6439024 1.4634146 1.4634146 0 .8195122-.6439024 1.4634146-1.4634146 1.4634146h-31.6682927c-.8195122 0-1.4634146-.6439024-1.4634146-1.4634146.0585366-.8195122.702439-1.4634146 1.4634146-1.4634146z"
          fill="#e5e5e5"
        />
        <path
          d="m49.5219512 27.9219512h12.5268293c.8195122 0 1.4634146.6439025 1.4634146 1.4634147 0 .8195121-.6439024 1.4634146-1.4634146 1.4634146h-12.5268293c-.8195122 0-1.4634146-.6439025-1.4634146-1.4634146.0585366-.8195122.702439-1.4634147 1.4634146-1.4634147z"
          fill="#babcc5"
        />
        <path
          d="m49.5219512 50.9268293h17.5609756c.8195122 0 1.4634147.6439024 1.4634147 1.4634146 0 .8195122-.6439025 1.4634146-1.4634147 1.4634146h-17.5609756c-.8195122 0-1.4634146-.6439024-1.4634146-1.4634146.0585366-.8195122.702439-1.4634146 1.4634146-1.4634146z"
          fill="#e5e5e5"
        />
        <path
          d="m49.5219512 45.4829268h22.3609756c.8195122 0 1.4634147.6439025 1.4634147 1.4634147 0 .8195122-.6439025 1.4634146-1.4634147 1.4634146h-22.3609756c-.8195122 0-1.4634146-.6439024-1.4634146-1.4634146.0585366-.8195122.702439-1.4634147 1.4634146-1.4634147z"
          fill="#babcc5"
        />
        <path
          d="m49.5219512 69.2487805h23.8243903c.8195122 0 1.4634146.6439024 1.4634146 1.4634146 0 .8195122-.6439024 1.4634147-1.4634146 1.4634147h-23.8243903c-.8195122 0-1.4634146-.6439025-1.4634146-1.4634147.0585366-.8195122.702439-1.4634146 1.4634146-1.4634146z"
          fill="#e5e5e5"
        />
        <g fill="#babcc5">
          <path d="m49.5219512 63.7463415h9.7756098c.8195122 0 1.4634146.6439024 1.4634146 1.4634146 0 .8195122-.6439024 1.4634146-1.4634146 1.4634146h-9.7756098c-.8195122 0-1.4634146-.6439024-1.4634146-1.4634146.0585366-.8195122.702439-1.4634146 1.4634146-1.4634146z" />
          <path d="m44.604878 7.72682927h30.6731708c1.9317073 0 3.5121951 1.5804878 3.5121951 3.51219513v.2341463c0 1.9317073-1.5804878 3.5121952-3.5121951 3.5121952h-30.6731708c-1.9317073 0-3.5121951-1.5804879-3.5121951-3.5121952v-.2341463c0-1.99024391 1.5804878-3.51219513 3.5121951-3.51219513z" />
          <path
            d="m60 13.8146341c-3.804878 0-6.9073171-3.102439-6.9073171-6.90731703-.0585366-3.80487805 3.0439025-6.90731707 6.9073171-6.90731707s6.9073171 3.10243902 6.9073171 6.90731707c-.0585366 3.80487803-3.1024391 6.90731703-6.9073171 6.90731703zm0-9.71707312c-1.5219512 0-2.8097561 1.28780487-2.8097561 2.80975609s1.2878049 2.8097561 2.8097561 2.8097561 2.8097561-1.28780488 2.8097561-2.8097561c-.0585366-1.52195122-1.2878049-2.80975609-2.8097561-2.80975609z"
            fillRule="nonzero"
          />
          <path d="m77.2682927 83.8829268v-6.7317073c0-1.697561 1.3463414-3.0439024 3.0439024-3.0439024h6.0878049" />
        </g>
        <circle cx="40.8" cy="31.61" fill="#237b8e" r="4.039" />
        <path
          d="m40.1560976 33.5414634c-.1756098 0-.3512196-.1170732-.4682927-.2341463l-.995122-1.3463415c-.1756097-.2926829-.1170731-.6439024.1170732-.8780488.2926829-.1756097.6439024-.1170731.8780488.1170732l.5268292.702439 1.8146342-2.2243902c.2341463-.2341464.5853658-.2926829.8780488-.1170732.2341463.2341464.2926829.5853659.1170731.8780488l-2.3414634 2.8682927c-.1756097.1170731-.3512195.2341463-.5268292.2341463z"
          fill="#fff"
        />
        <circle cx="40.8" cy="49.463" fill="#237b8e" r="4.039" />
        <path
          d="m40.1560976 51.395122c-.2341464 0-.4097561-.1170732-.4682927-.2341464l-.995122-1.404878c-.1756097-.292683-.1170731-.6439025.1170732-.8780488.2926829-.1756098.6439024-.1170732.8780488.1170732l.5268292.702439 1.8146342-2.2243903c.2341463-.2341463.5853658-.2926829.8780488-.1170731.2341463.2341463.2926829.5853658.1170731.8780487l-2.3414634 2.8682927c-.1756097.1756098-.3512195.292683-.5268292.292683z"
          fill="#fff"
        />
        <circle cx="40.8" cy="65.62" fill="#237b8e" r="4.039" />
        <path
          d="m40.1560976 67.4926829c-.1756098 0-.3512196-.1170731-.4682927-.2341463l-.995122-1.3463415c-.1756097-.2926829-.1170731-.6439024.1170732-.8780488.2926829-.1756097.6439024-.1170731.8780488.1170732l.5268292.702439 1.8146342-2.2243902c.2341463-.2341463.5853658-.2926829.8780488-.1170732.2341463.2341464.2926829.5853659.1170731.8780488l-2.3414634 2.8682927c-.1756097.1756097-.3512195.2341463-.5268292.2341463z"
          fill="#fff"
        />
        <path
          d="m19.4926829 67.3756098h-1.5219512v-1.5219513c0-.1756097-.1170732-.2926829-.2926829-.2926829-.1756098 0-.2926829.1170732-.2926829.2926829v1.5219513h-1.5804879c-.1756097 0-.2926829.1170731-.2926829.2926829 0 .1756097.1170732.2926829.2926829.2926829h1.5219513v1.5219512c0 .1756098.1170731.292683.2926829.292683.1756098 0 .2926829-.1170732.2926829-.292683v-1.5219512h1.5219512c.1756098 0 .292683-.1170732.292683-.2926829.0585366-.1756098-.0585366-.2926829-.2341464-.2926829z"
          fill="#e3e7ed"
        />
        <path
          d="m3.9804878 49.2878049h-1.52195121v-1.5219512c0-.1756098-.11707318-.292683-.29268293-.292683-.17560976 0-.29268293.1170732-.29268293.292683v1.5219512h-1.5804878c-.17560976 0-.29268293.1170731-.29268293.2926829s.11707317.2926829.29268293.2926829h1.52195122v1.5219513c0 .1756097.11707317.2926829.29268292.2926829.17560976 0 .29268293-.1170732.29268293-.2926829v-1.5219513h1.52195122c.17560976 0 .29268293-.1170731.29268293-.2926829.05853658-.1756098-.11707317-.2926829-.23414635-.2926829z"
          fill="#83a0bc"
        />
        <path
          d="m18.5560976 31.7853659h-1.5219513v-1.5219513c0-.1756097-.1170731-.2926829-.2926829-.2926829-.1756097 0-.2926829.1170732-.2926829.2926829v1.5219513h-1.5219512c-.1756098 0-.292683.1170731-.292683.2926829 0 .1756097.1170732.2926829.292683.2926829h1.5219512v1.5219512c0 .1756098.1170732.292683.2926829.292683.1756098 0 .2926829-.1170732.2926829-.292683v-1.5219512h1.5219513c.1756097 0 .2926829-.1170732.2926829-.2926829 0-.1756098-.1170732-.2926829-.2926829-.2926829z"
          fill="#babcc5"
        />
        <path
          d="m104.429268 26.8097561h-1.580488v-1.5804878c0-.1756098-.117073-.2926829-.292682-.2926829-.17561 0-.292683.1170731-.292683.2926829v1.5804878h-1.580488c-.17561 0-.292683.1170732-.292683.2926829 0 .1756098.117073.292683.292683.292683h1.580488v1.5804878c0 .1756097.117073.2926829.292683.2926829.175609 0 .292682-.1170732.292682-.2926829v-1.5804878h1.580488c.17561 0 .292683-.1170732.292683-.292683 0-.1170731-.17561-.2926829-.292683-.2926829z"
          fill="#e3e7ed"
        />
        <path
          d="m108.760976 70.3609756h-1.580488v-1.5804878c0-.1756098-.117073-.2926829-.292683-.2926829s-.292683.1170731-.292683.2926829v1.5804878h-1.580488c-.17561 0-.292683.1170732-.292683.2926829 0 .1756098.117073.292683.292683.292683h1.580488v1.5804878c0 .1756097.117073.2926829.292683.2926829.17561 0 .292683-.1170732.292683-.2926829v-1.5804878h1.580488c.175609 0 .292683-.1170732.292683-.292683.058536-.1170731-.058537-.2926829-.292683-.2926829.058536 0 .058536 0 0 0z"
          fill="#83a0bc"
        />
        <path
          d="m119.121951 45.1317073h-1.580488v-1.5804878c0-.1756097-.117073-.2926829-.292683-.2926829-.175609 0-.292682.1170732-.292682.2926829v1.5804878h-1.580488c-.17561 0-.292683.1170732-.292683.2926829 0 .1756098.117073.292683.292683.292683h1.580488v1.5804878c0 .1756097.117073.2926829.292682.2926829.17561 0 .292683-.1170732.292683-.2926829v-1.5804878h1.580488c.17561 0 .292683-.1170732.292683-.292683.058537-.1170731-.117073-.2926829-.292683-.2926829z"
          fill="#babcc5"
        />
        <circle cx="86.283" cy="52.39" fill="#f2f3f6" r="11.239" />
        <g transform="translate(76.683 40.39)">
          <circle cx="11.766" cy="12" fill="#cd4b53" r="11.239" />
          <path
            d="m11.7658537 23.5317073c-6.38048785 0-11.59024394-5.2097561-11.59024394-11.5902439 0-6.38048779 5.20975609-11.59024389 11.59024394-11.59024389 6.3804878 0 11.5902439 5.2097561 11.5902439 11.59024389-.0585366 6.4390244-5.2097561 11.5902439-11.5902439 11.5902439zm0-22.53658535c-6.02926833 0-11.00487809 4.91707317-11.00487809 11.00487805 0 6.0878049 4.91707317 11.004878 11.00487809 11.004878 6.0878048 0 11.004878-4.9170731 11.004878-11.004878 0-6.08780488-4.9756097-11.00487805-11.004878-11.00487805z"
            fill="#a91f24"
            fillRule="nonzero"
          />
        </g>
        <path
          d="m86.6341463 57.7170732c-.5853658 0-1.0536585-.292683-1.404878-.7024391l-2.7512195-3.804878c-.5853659-.7609756-.4097561-1.8146341.3512195-2.4s1.8146341-.4097561 2.4.3512195l1.4634146 1.9902439 5.092683-6.2634146c.5853658-.7609756 1.6975609-.8195122 2.4-.2341464.7609756.5853659.8195121 1.697561.2341463 2.4l-6.497561 8.0195122c-.2926829.4097561-.7609756.6439025-1.2878049.6439025z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}

TasksComplete.propTypes = {
  className: PropTypes.string,
};
