import React from 'react';
import { Column } from 'components/molecules/Table';
import { columnNames } from '../columnMap';

export default function ApplicationYear() {
  return (
    <Column
      key={columnNames.University}
      name="Application Year"
      renderer={({ rowData }) =>
        rowData.getIn(['studentInfo', 'yearOfApplication'])
          ? `${rowData.getIn(['studentInfo', 'yearOfApplication'])}`
          : 'No year of application'
      }
    />
  );
}
