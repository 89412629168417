import { CircularProgress, Divider, IconButton, ModalProps } from '@material-ui/core';
import { Close, InfoOutlined, ReportProblemRounded } from '@material-ui/icons';
import oms from 'graphql/oms';
import React, { ChangeEventHandler, useEffect, useMemo, useState } from 'react';
import getSymbolFromCurrency from 'currency-symbol-map';
import * as Styled from './index.style';
import currency from 'currency.js';
import { valueType } from 'antd/lib/statistic/utils';
import { useUserId } from 'selectors/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { roleTypes } from '@crimson-education/common-config';
import { getKeyContactsInfo } from 'selectors/profile';
import { z } from 'zod';
import { Input } from 'antd';
import { formFail, formSuccess } from 'ducks/meta';
import { trackEvent } from '@crimson-education/browser-logger';
import { PurchaseModalEvents } from './util';
import { PurchaseItem } from 'types';
import _ from 'lodash';
import { FeatureSwitchContext } from 'featureSwitches';

interface PurchaseModalProps extends Omit<ModalProps, 'children'> {
  item: PurchaseItem | null;
  closeModal: (successful?: boolean) => void;
}

const PayerEmail = z.string().email();

const PurchaseItemUnit: Record<string, string> = {
  CRIMSON_APP_SUBJECTS: 'Hour',
  PROMOTIONAL_SUBJECTS: 'Program',
};

const PurchaseButtonText: Record<string, string> = {
  CRIMSON_APP_SUBJECTS: 'Request Additional Hours',
  PROMOTIONAL_SUBJECTS: 'Request New Program',
};

const PurchaseHoursModal = ({ item, closeModal, ...props }: PurchaseModalProps) => {
  const userId = useUserId();
  const dispatch = useDispatch();

  const [quantity, setQuantity] = useState(1);
  const [loading, setLoading] = useState(false);
  const [rate, setRate] = useState<PurchaseItem['rate']>();
  const [processing, setProcessing] = useState(false);
  const [contact] = useSelector(getKeyContactsInfo(userId, roleTypes.STUDENT));
  const [emailTouched, setEmailTouched] = useState(false);
  const [payerEmail, setPayerEmail] = useState(contact?.email || '');
  const [invalidEmail, setInvalidEmail] = useState(!payerEmail);

  useEffect(() => {
    if (!payerEmail && contact?.email && !emailTouched) {
      setPayerEmail(contact.email);
      setInvalidEmail(false);
    }
  }, [contact]);

  const canPurchaseMultiple = useMemo(() => {
    let result = true; // default is true
    const config = _.find(item?.metadata, { name: 'fixedQuantity' });
    try {
      if (config?.value && Number.parseInt(config.value) === 1) result = false;
    } catch (error) {}
    return result;
  }, [item]);

  const total = useMemo(
    () =>
      currency(rate?.price || 0, { symbol: rate?.symbol })
        .multiply(quantity)
        .format(),
    [rate?.price, rate?.symbol, quantity],
  );

  const invalidQuantity = useMemo(() => {
    return quantity < 1 || quantity.toString().split('.').length > 1;
  }, [quantity]);

  const close = (successful = false) => {
    setProcessing(false);
    setLoading(false);
    setQuantity(1);
    setEmailTouched(false);
    closeModal(successful);
  };

  const handleQuantityChange = (value: valueType | null) => {
    try {
      if (value === null) return;
      const incomingQuantity = +value;
      setQuantity(incomingQuantity);
    } catch (error) {}
  };

  const handleEmailChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.target.value;
    setInvalidEmail(!value || !PayerEmail.safeParse(value).success);
    setPayerEmail(event.target.value);
  };

  const handleCheckout = async () => {
    setProcessing(true);
    if (!item) {
      dispatch(formFail('No item specified'));
      return;
    }
    const { data, errors } = await oms
      .purchaseAdditionalHours(item.itemId.toString(), quantity, payerEmail, item.source)
      .catch((error) => {
        return { data: null, errors: [error] };
      });
    setProcessing(false);
    if ((errors && errors?.length > 0) || data?.order?.status !== 'PENDING') {
      dispatch(formFail('Could not create order at this time, please refresh & try again later'));
      return;
    }
    trackEvent({
      message: PurchaseModalEvents.SubmitPurchaseRequest,
      metadata: {
        quantity,
        name: item.name,
        itemId: item.itemId,
        price: rate?.price,
        currency: rate?.currency,
      },
    });
    close(true); // Successful Purchase (Force Pending UI State)
    dispatch(formSuccess("An enrollment request email has been sent to your parent/guardian's email"));
  };

  // Rate Hook
  useEffect(() => {
    const fetchRates = async () => {
      setLoading(true);
      const { data } = await oms.getRegionalRateCard().catch(() => ({ data: null }));
      setLoading(false);
      if (data?.rateCard) {
        setRate({ ...data.rateCard, symbol: getSymbolFromCurrency(data.rateCard.currency) });
      }
    };
    if (item?.rate) {
      setRate({ ...item.rate, symbol: getSymbolFromCurrency(item.rate.currency) });
    } else {
      fetchRates();
    }
  }, [item]);

  return (
    <FeatureSwitchContext.Consumer>
      {(value) => (
        <Styled.Modal {...props}>
          <Styled.Container>
            <div style={{ display: 'flex', marginRight: '-24px', justifyContent: 'flex-end' }}>
              <IconButton onClick={() => close()}>
                <Close fontSize="large" />
              </IconButton>
            </div>
            {loading ? (
              <Styled.LoadingContainer>
                <CircularProgress size={24} />
              </Styled.LoadingContainer>
            ) : null}
            {!loading && !rate ? (
              <div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <ReportProblemRounded style={{ fontSize: '4rem' }} />
                </div>
                <p style={{ textAlign: 'center', paddingTop: '10px', fontSize: '14px' }}>
                  Failed to Load Rates, Refresh & Try Again
                </p>
              </div>
            ) : null}
            {rate ? (
              <div>
                <Styled.Title>Request Enrollment</Styled.Title>
                <Styled.LineItemContainer>
                  <Styled.LineItemLabel>Program Name</Styled.LineItemLabel>
                  <Styled.LineItem>{item?.name}</Styled.LineItem>
                  {!value.featureSwitches.HIDE_PURCHASE_PRICES ? (
                    <Styled.LineItemRate>
                      {rate.symbol + rate.price}
                      <span>/{item?.source ? PurchaseItemUnit[item.source] : 'Hour'}</span>
                    </Styled.LineItemRate>
                  ) : null}
                </Styled.LineItemContainer>

                {canPurchaseMultiple ? (
                  <Styled.SummaryRow>
                    <p>Hours to add</p>
                    <Styled.QuantityPicker
                      disabled={processing}
                      onChange={handleQuantityChange}
                      min={1}
                      step={1}
                      bordered={false}
                      keyboard={true}
                      precision={0}
                      value={quantity}
                      controls={{
                        upIcon: <Styled.QuantityUpIcon />,
                        downIcon: <Styled.QuantityDownIcon />,
                      }}
                    />
                  </Styled.SummaryRow>
                ) : null}
                {!value.featureSwitches.HIDE_PURCHASE_PRICES ? (
                  <Styled.SummaryRow>
                    <Styled.TotalLabel>Total Price</Styled.TotalLabel>
                    <Styled.Total isInvalid={invalidQuantity}>{`${total} ${rate.currency}`}</Styled.Total>
                  </Styled.SummaryRow>
                ) : null}
                <Divider />
                <Styled.EmailBlock>
                  <p>Parent/Guardian Email</p>
                  <Input
                    status={invalidEmail ? 'error' : ''}
                    placeholder="Enter a Parent/Guardian's Email Address"
                    onChange={handleEmailChange}
                    value={payerEmail}
                    type="email"
                    onFocus={() => setEmailTouched(true)}
                  />
                </Styled.EmailBlock>
                <Styled.ActionRow>
                  {processing ? (
                    <Styled.CheckoutButton disabled={true}>
                      <CircularProgress size={16} style={{ color: 'white' }} />
                      <span style={{ color: 'rgba(255, 255, 255, 0.5)' }}>Sending...</span>
                    </Styled.CheckoutButton>
                  ) : (
                    <Styled.CheckoutButton disabled={invalidQuantity || invalidEmail} onClick={handleCheckout}>
                      <span>{item?.source ? PurchaseButtonText[item.source] : 'Request Additional Hours'}</span>
                    </Styled.CheckoutButton>
                  )}
                </Styled.ActionRow>
                <Styled.NoticeContainer>
                  <InfoOutlined fontSize="large" />
                  <span>
                    A payment link will be sent to your parent/guardian&apos;s email and must be completed within 7
                    days.
                    <br />
                    The additional hours will be added to your program once the payment is completed.
                  </span>
                </Styled.NoticeContainer>
              </div>
            ) : null}
          </Styled.Container>
        </Styled.Modal>
      )}
    </FeatureSwitchContext.Consumer>
  );
};

export default PurchaseHoursModal;
