import React, { useMemo } from 'react';
import { useStaffDashboardContext, retriveUserInfoFromIds } from '../helper';
import { StudentsModal } from '../components';

const AlertDetailModal = () => {
  const { state, controller } = useStaffDashboardContext();
  const { alertModalOpen, activeAlertValue, students, alerts, region, schoolSelectionData } = state;
  const alert = useMemo(() => {
    const _alert = alerts[region].find((o) => o.value === activeAlertValue);
    if (_alert) return _alert;
    const _alert2 = schoolSelectionData[region].find((o) => o.value === activeAlertValue);
    return _alert2;
  }, [alerts, activeAlertValue, region, schoolSelectionData]);
  const _students = useMemo(() => {
    if (!alert) return [];
    return retriveUserInfoFromIds(students, alert.userIds);
  }, [students, alert]);

  return (
    <StudentsModal
      students={_students}
      title={alert?.label}
      isOpen={alertModalOpen}
      onCancel={controller.closeAlertDetailModal}
    />
  );
};
export default AlertDetailModal;
