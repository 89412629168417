import { connect } from 'react-redux';
import {
  selectUserPermissions,
  getMetaItem,
  getUsersFetched,
  getFormState,
  getOurPeopleFilter,
  getFilteredQuery,
} from 'selectors/meta';
import { fetchViewableRoles, updateMeta } from 'ducks/meta';
import componentKeys from 'constants/componentKeys';
import { queryUsers, setUserStatus } from 'ducks/user';
import { getQueriedUsers, getQueriedUsersPagination } from 'selectors/user';
import UserQuery from './UserQuery';

export default connect(
  (state) => ({
    loginUserPermissions: selectUserPermissions(state),
    userQueryResults: getQueriedUsers(state).get('results'),
    userPagination: getQueriedUsersPagination(state),
    activePage: getMetaItem(componentKeys.ACTIVE_PAGE)(state),
    usersFetched: getUsersFetched(state),
    formState: getFormState(state),
    prevFilters: getOurPeopleFilter(state),
    prevFilteredQuery: getFilteredQuery(state),
    viewableRoles: getMetaItem(componentKeys.VIEWABLE_ROLES)(state),
  }),
  (dispatch) => ({
    setActivePage: (page) => dispatch(updateMeta(componentKeys.ACTIVE_PAGE, page)),
    fetchUsers: ({ pageNumber, pageSize }, filters, userId, { tabType, hasFilters }) =>
      dispatch(queryUsers({ pageNumber, pageSize }, filters, userId, { tabType, hasFilters })),
    setUserStatus: (handleSearchParamsChange) => (userId, status) => {
      dispatch(setUserStatus(userId, status));
      handleSearchParamsChange();
    },
    fetchViewableRoles: () => dispatch(fetchViewableRoles()),
  }),
)(UserQuery);
