import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Body, Caption } from 'components/atoms/typography';
import { formatStatusLabel } from 'utils/invoiceUtils';

import css from './styles.scss';

export default function InvoiceSummary({ invoice }) {
  const { billingCountry, submitDate, tutor, periodEnd, status } = invoice;

  return (
    <div className={css.invoiceSummary}>
      <div className={css.from}>
        <Body bold className={css.uppercase}>
          From
        </Body>
        <Body>
          {tutor.firstName} {tutor.lastName}
        </Body>
      </div>
      <div className={css.summaryContainer}>
        <div className={css.billTo}>
          <Body bold className={css.uppercase}>
            Bill to
          </Body>
          {billingCountry.entityName && <Body bold>{billingCountry.entityName}</Body>}
          {billingCountry.aptSuite && <Body>{billingCountry.aptSuite}</Body>}
          {billingCountry.street && <Body>{billingCountry.street}</Body>}
          {billingCountry.city && <Body>{billingCountry.city}</Body>}
          {billingCountry.postCode && <Body>{billingCountry.postCode}</Body>}
          {billingCountry.country && <Body>{billingCountry.country}</Body>}
        </div>

        <div className={css.invoiceDetails}>
          <Body bold className={css.uppercase}>
            Invoice Details
          </Body>
          <div>
            <Caption>Invoice date</Caption>
            <Body>{submitDate ? moment(submitDate).format('D MMM YYYY') : 'Not submitted yet'}</Body>
          </div>
          <div>
            <Caption>Invoice reference</Caption>
            <Body>
              {tutor.firstName} {tutor.lastName} - {moment(periodEnd).format('D MMM YYYY')}
            </Body>
          </div>
          <div className={css.invoiceStatus}>
            <Caption>Invoice status</Caption>
            <Body>{formatStatusLabel(status)}</Body>
          </div>
        </div>
      </div>
    </div>
  );
}

InvoiceSummary.propTypes = {
  invoice: PropTypes.object,
};
