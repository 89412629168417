import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import * as zoid from 'zoid/dist/zoid.frameworks.js';
import { getEnvironmentConfig } from '@crimson-education/common-config';
import { history } from '../../../app';
import { getBearer, getRefreshToken, authorize } from '../../../utils/auth/auth';
import { getCurrentUserJS } from 'selectors/user';
import _ from 'lodash';

const { environment } = getEnvironmentConfig();
const eventEmitters = {};
let subPath;
let innerChange = false;

const environmentHostnames = {
  local: 'http://localhost:8000',
  staging: 'https://staging.ops.crimsoneducation.org',
  production: 'https://ops.crimsoneducation.org',
};

const usMentorOpsDomain = environmentHostnames[environment];

const UsMentorOpsComponent = zoid.create({
  tag: 'ops-mentor-component',
  url: ({ props }) => {
    if (props && props.history) {
      const { location } = props.history;
      const { pathname } = location;
      const subPath = pathname.replace('/us-mentor-ops', '');
      if (subPath === '') {
        return usMentorOpsDomain + '/mentor/authorize';
      }
      return usMentorOpsDomain + subPath + location.search;
    }
    return usMentorOpsDomain + '/mentor/authorize';
  },
  dimensions: {
    width: '100%',
    height: '99vh',
  },
  props: {
    user: {
      type: 'object',
      isRequired: true,
    },
    token: {
      type: 'string',
      isRequired: true,
    },
    refreshToken: {
      type: 'string',
      isRequired: true,
    },
    authorize: {
      type: 'function',
      isRequired: true,
    },
  },
});

const UsMentorOpsReactComponent = UsMentorOpsComponent.driver('react', {
  React,
  ReactDOM,
});

// registerEventEmitter
// a function to add more callbacks from iframe
const registerEventEmitter = (eventName, callback) => {
  eventEmitters[eventName] = callback;
};

const unregisterEventEmitter = (eventName) => {
  delete eventEmitters[eventName];
};

// invoke the event callbacks
// conditionally, if the innerChange is true, we should do something special
const performEvent = (eventName, data) => {
  if (innerChange) {
    innerChange = false;
    return;
  }
  eventEmitters[eventName] && eventEmitters[eventName](data);
};

const onMessage = ({ event, data }) => {
  // accept the route event from the url routing change of the iframe.
  if (event === 'route') {
    if (subPath === data) {
      return;
    }
    innerChange = true;
    subPath = data;
    history.push(`/us-mentor-ops${data}`);
  }
};

function UsMentorOps() {
  const user = useSelector(getCurrentUserJS);

  useEffect(() => {
    const { pathname } = location;
    const subPath = pathname.replace('/us-mentor-ops', '');
    performEvent('refresh', `${subPath}${history.location.search}`);
  });

  return (
    <UsMentorOpsReactComponent
      registerEventEmitter={registerEventEmitter}
      unregisterEventEmitter={unregisterEventEmitter}
      history={history}
      onMessage={onMessage}
      token={getBearer() || ''}
      refreshToken={getRefreshToken() || ''}
      authorize={authorize}
      user={_.pick(user, ['userId', 'firstName', 'lastName', 'email', 'roles'])}
    />
  );
}

export default UsMentorOps;
