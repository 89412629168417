import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';
import { gql } from '@apollo/client';
import { createClient } from '../utils';

const { api } = getConfig();

const client = createClient(api.graphQLEndpoint);

const fetchInvoiceById = gql`
  query fetchInvoiceById($id: String!) {
    invoice(id: $id) {
      id
      periodStart
      periodEnd
      submitDate
      status
      statusDate
      withholdingTax
      grossPay
      netPay
      isPayable
      isSubmittable
      isDeletable
      tutor {
        userId
        firstName
        lastName
      }
      items {
        id
        start
        end
        duration
        subjectId
        studentId
        status
        amount
      }
      subjects {
        id
        name
      }
      users {
        userId
        firstName
        lastName
      }
      billingCountry {
        id
        name
        currency
        businessNumberName
        entityName
        aptSuite
        street
        city
        postCode
        country
      }
      businessNumber
    }
  }
`;

const fetchInvoices = gql`
  query fetchInvoices($filter: InvoiceFilter, $first: Int, $after: Int) {
    invoices(filter: $filter, first: $first, after: $after) {
      invoices {
        edges {
          node {
            id
            periodStart
            periodEnd
            submitDate
            status
            statusDate
            isLocked
            completedLessons
            totalLessons
            totalHours
            grossPay
            netPay
            isPayable
            isSubmittable
            isDeletable
            tutor {
              userId
              firstName
              lastName
            }
            billingCountry {
              id
              name
              currency
            }
          }
          cursor
        }
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        totalCount
      }
      filterablePeriods {
        periodStart
        periodEnd
      }
      filterableTutors {
        userId
        firstName
        lastName
      }
      filterableBillingCountries {
        id
        name
      }
    }
  }
`;

const markInvoiceAsPaidById = gql`
  mutation markInvoiceAsPaid($invoiceId: String!) {
    markInvoiceAsPaid(invoiceId: $invoiceId) {
      id
      status
      statusDate
      isPayable
      isSubmittable
      isDeletable
    }
  }
`;

const submitInvoiceById = gql`
  mutation submitInvoice($invoiceId: String!) {
    submitInvoice(invoiceId: $invoiceId) {
      id
      isSubmittable
      isPayable
      isDeletable
      status
      statusDate
    }
  }
`;

const deleteInvoiceById = gql`
  mutation deleteInvoice($invoiceId: String!) {
    deleteInvoice(invoiceId: $invoiceId)
  }
`;

export default {
  fetchInvoiceById: async (id) => {
    const { invoice } = await client.query(fetchInvoiceById, { id });
    return invoice;
  },

  fetchInvoicesByUserId: async ({ id, filter, first, after }) => {
    const { invoices } = await client.query(fetchInvoices, { filter: { ...filter, tutorId: id }, first, after });
    return invoices;
  },

  fetchInvoicesForAdmin: async ({ filter, first, after }) => {
    const { invoices } = await client.query(fetchInvoices, { filter, first, after });
    return invoices;
  },

  markInvoiceAsPaidById: async (invoiceId) => {
    const { markInvoiceAsPaid: invoice } = await client.query(markInvoiceAsPaidById, { invoiceId });
    return invoice;
  },

  submitInvoiceById: async (invoiceId) => {
    const { submitInvoice: invoice } = await client.query(submitInvoiceById, { invoiceId });
    return invoice;
  },

  deleteInvoiceById: (invoiceId) => {
    return client.query(deleteInvoiceById, { invoiceId });
  },
};
