import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { trimSpecialCharacters } from 'utils/utils';
import { PageHeader, Body } from 'components/atoms/typography';
import BackButton from 'components/molecules/BackButton';
import Tag from 'components/molecules/Tag';
import PopoverMenu from 'components/molecules/PopoverMenu';

import css from './styles.scss';

export default function HeaderCenter(props) {
  const { title, caption, status, backAction, actions, tagStyle, showBorder, onClickBack } = props;

  return (
    <div className={classNames(css.headerCenter, { [css.showBorder]: showBorder })}>
      <div className={css.headerActions}>
        {backAction ? (
          <BackButton
            backButtonText={backAction.label}
            backButtonLink={backAction.link}
            className={css.backButton}
            dataTestId={`headerNavBackButton_${trimSpecialCharacters(backAction.label || 'Back')}`}
          />
        ) : (
          <BackButton className={css.backButton} onClickBack={onClickBack} />
        )}
        <div className={css.actions}>
          {status && <Tag className={css.tag} text={status} style={tagStyle} />}
          {actions && actions.length ? (
            <PopoverMenu dataTestId="sessionPopoverMenu" className={css.menu} items={actions} />
          ) : null}
        </div>
      </div>
      <div className={css.headerInfo}>
        <PageHeader className={css.title}>{title}</PageHeader>
        {caption && <Body className={css.title}>{caption}</Body>}
      </div>
    </div>
  );
}

const actionProp = PropTypes.shape({
  label: PropTypes.string,
  action: PropTypes.func,
  icon: PropTypes.string,
  disabled: PropTypes.bool,
});

HeaderCenter.propTypes = {
  title: PropTypes.string,
  caption: PropTypes.string,
  tagStyle: PropTypes.string,
  showBorder: PropTypes.bool,
  backAction: PropTypes.shape({
    label: PropTypes.string,
    link: PropTypes.string,
  }),
  actions: PropTypes.arrayOf(actionProp),
  status: PropTypes.string,
  onClickBack: PropTypes.func,
};

HeaderCenter.defaultProps = {
  showBorder: true,
};
