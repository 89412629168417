import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
import Select from 'react-select';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { Astronaut, Planet } from 'components/atoms/graphics';
import { SubHeader } from 'components/atoms/typography';
import SessionCard from './SessionCard';
import css from './styles.scss';

const customSelectStyles = {
  container: (provided) => ({
    ...provided,
    width: 166,
    fontSize: '1.2rem',
  }),
  control: (provided) => ({
    ...provided,
    border: '1px solid #E3E7ED',
    borderRadius: 33,
    height: 31,
    minHeight: 31,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    marginLeft: '1.2rem',
    color: '#73747D',
    '&::before': {
      content: '"Sort by: "',
      fontWeight: 500,
      color: '#A9ACC0',
      marginRight: '1.4rem',
    },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '0 18px 0 0',
  }),
  menu: (provided) => ({
    ...provided,
    width: '80%',
    marginLeft: '1.4rem',
    marginTop: 4,
  }),
};

export default function SessionList(props) {
  const { bookings, loadMore, hasMore, isPast, currentUser, setRefetchSessions } = props;
  let { sortby } = useParams();
  const [groupedBookings, setGroupedBookings] = useState([]);
  const options = [
    { value: 'time', label: 'Time' },
    { value: 'status', label: 'Status' },
  ];
  const [groupBy, setGroupBy] = useState(sortby === 'status' ? options[1] : options[0]);

  useEffect(() => {
    const dateFormat = 'ddd, DD MMM YYYY';
    const formatBookingItem = {
      time: (booking) => moment(booking.start).format(dateFormat),
      status: (booking) => booking.status.charAt(0).toUpperCase() + booking.status.slice(1),
    };

    function splitBookingsByGroup(bookings) {
      let bookingsGrouped = [];
      bookings.forEach((booking) => {
        const curItemFormat = formatBookingItem[groupBy.value](booking);
        const curItemInPre = bookingsGrouped.find((item) => item.hasOwnProperty(curItemFormat));
        curItemInPre ? curItemInPre[curItemFormat].push(booking) : bookingsGrouped.push({ [curItemFormat]: [booking] });
      });
      // do some sorting
      if (groupBy.value === 'time') {
        bookingsGrouped.sort((a, b) => {
          return isPast
            ? moment(Object.keys(b)[0], dateFormat) - moment(Object.keys(a)[0], dateFormat)
            : moment(Object.keys(a)[0], dateFormat) - moment(Object.keys(b)[0], dateFormat);
        });
        return bookingsGrouped;
      } else if (groupBy.value === 'status') {
        for (let i = 0; i < bookingsGrouped.length; i++) {
          const item = bookingsGrouped[i];
          const [status, bookings] = Object.entries(item)[0];
          bookingsGrouped[i][status] = bookings.sort((a, b) => {
            return isPast ? moment(b.start) - moment(a.start) : moment(a.start) - moment(b.start);
          });
        }
        return bookingsGrouped.sort((a, b) => Object.keys(b)[0].localeCompare(Object.keys(a)[0]));
      }
    }

    const newBookingsGrouped = splitBookingsByGroup(bookings);
    if (newBookingsGrouped) {
      setGroupedBookings(newBookingsGrouped);
    }
  }, [bookings, setGroupedBookings, isPast, groupBy]);

  function createSubheader(isFirst, key) {
    const mapStatusToHeading = {
      Tentative: 'Unconfirmed sessions',
      Confirmed: 'Confirmed sessions',
    };
    const cardHeader = mapStatusToHeading[key] || key;
    if (isFirst) {
      return (
        <div>
          <SubHeader className={css.dateHeader}>
            <div>{cardHeader}</div>
            <div>
              <Select
                options={options}
                value={groupBy}
                styles={customSelectStyles}
                isSearchable={false}
                onChange={(item) => setGroupBy(item)}
              />
            </div>
          </SubHeader>
        </div>
      );
    }
    return <SubHeader className={css.dateHeader}>{cardHeader}</SubHeader>;
  }

  return (
    <div className={css.sessionList}>
      {groupedBookings && groupedBookings?.length ? (
        <InfiniteScroll initialLoad={false} loadMore={loadMore} hasMore={hasMore} useWindow>
          {groupedBookings.map((item, index) => (
            <div key={index} className={css.sessionGroup}>
              <div>{createSubheader(index === 0, Object.keys(item)[0])}</div>
              <div>
                {Object.values(item)[0].map((x) => (
                  <SessionCard
                    key={x.id}
                    session={x}
                    currentUser={currentUser}
                    showDate={groupBy.value === 'status'}
                    setRefetchSessions={setRefetchSessions}
                  />
                ))}
              </div>
            </div>
          ))}
        </InfiniteScroll>
      ) : (
        <div className={css.empty}>
          {isPast ? <Astronaut /> : <Planet />}
          <div>{isPast ? 'You have no past bookings' : 'You have no upcoming bookings'}</div>
        </div>
      )}
    </div>
  );
}

SessionList.propTypes = {
  currentUser: PropTypes.object,
  bookings: PropTypes.array,
  loadMore: PropTypes.func,
  hasMore: PropTypes.bool,
  isPast: PropTypes.bool,
  setRefetchSessions: PropTypes.func,
};
