import React from 'react';
import { components } from 'react-select';

import Close from '@material-ui/icons/Close';
import fontColour from 'components/atoms/typography/fontColour.scss';
import css from './style.scss';

export default function ClearIndicator(props) {
  return (
    <components.ClearIndicator {...props} className={css.clearIndicator}>
      <Close
        className={fontColour.primary}
        style={{
          height: '2rem',
          width: '2rem',
        }}
      />
    </components.ClearIndicator>
  );
}
