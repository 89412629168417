import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { tutorCapacityTypes } from '@crimson-education/common-config';

import { Body } from 'components/atoms/typography';
import css from './style.scss';

const defaultLabels = {
  [tutorCapacityTypes.AVAILABLE]: 'Available',
  [tutorCapacityTypes.SEEKING_HOURS]: 'Seeking hours',
  [tutorCapacityTypes.FULL]: 'Full',
  [tutorCapacityTypes.RESIGNED]: 'Resigned',
  [tutorCapacityTypes.ONBOARDING]: 'Onboarding',
};

const iconClasses = {
  [tutorCapacityTypes.AVAILABLE]: 'available',
  [tutorCapacityTypes.SEEKING_HOURS]: 'seeking',
  [tutorCapacityTypes.FULL]: 'full',
  [tutorCapacityTypes.RESIGNED]: 'resigned',
  [tutorCapacityTypes.ONBOARDING]: 'onboarding',
};

export default function TutorCapacityDisplay(props) {
  const { iconPosition, type, className, alternativeLabel } = props;
  const iconStyle = iconClasses[type];
  const icon = <span className={classNames(css.statusIcon, css[iconStyle], css[iconPosition])} />;
  return (
    <div className={css.displayWrapper}>
      <Body className={classNames(css.statusLabel, className)}>
        <span>
          {iconPosition === 'before' && icon}
          <span className={classNames({ [css.after]: iconPosition === 'after' })}>
            {alternativeLabel || defaultLabels[type]}
          </span>
          {iconPosition === 'after' && icon}
        </span>
      </Body>
    </div>
  );
}

TutorCapacityDisplay.defaultProps = {
  iconPosition: 'before',
  className: '',
  alternativeLabel: '',
};

TutorCapacityDisplay.propTypes = {
  iconPosition: PropTypes.string,
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
  alternativeLabel: PropTypes.string,
};
