import styled from 'styled-components';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Row, SecondaryButton } from '../index.style';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const StyledAppBar = styled(AppBar)`
  margin: 27px 60px 0px;
  background: #ffffff;
  border: 1px solid #e3e7ed;
  border-radius: 8px;
  width: auto;
  box-shadow: none;
`;

export const StyledTabs = styled(Tabs)`
  &.MuiTabs-root {
    overflow: visible !important;
  }
  &.MuiTabs-root > .MuiTabs-scroller {
    overflow: visible !important;
  }
`;

export const StyledTab = styled(Tab)<{ $active: boolean }>`
  font-weight: 600;
  font-size: 18px;
  font-family: 'Montserrat';
  line-height: 22px;
  color: #1d1e2b;
  text-transform: none;
  padding-left: 0;
  padding-right: 0;
  min-width: 0;
  margin-left: 44px;
  color: ${(props) => (props.$active ? '#464AC9' : '#1D1E2B;')};
`;

export const IconContainer = styled.div`
  width: 32px;
  height: 32px;
  background: rgba(70, 74, 201, 0.11);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 999px;
`;

export const SelectAppCycleContainer = styled(Row)`
  margin: auto 15px auto auto;
  display: flex;
  flex-direction: row;
  position: relative;
  overflow: visible;
`;

export const ApplicationCycleText = styled.span`
  font-weight: 500;
  font-size: 12px;
  font-family: 'Montserrat';
  line-height: 15px;

  color: #a9acc0;
`;

export const AppCycleButton = styled(SecondaryButton)`
  text-align: left;
  border: none;
  width: 90px;
  height: 25px;
  font-weight: 500;
  font-size: 12px;
  color: #73747d;
  font-family: 'Montserrat';
  padding: 0 5px;

  &:hover {
    background: none;
  }
  &.MuiButtonBase-root {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const HintContainer = styled.div`
  width: 170px;
  height: 63px;
  padding: 16px;
  background: rgba(29, 30, 43, 0.8);
  border-radius: 4px;
  display: flex;
  position: absolute;
  right: -5px;
  top: 40px;
  box-sizing: border-box;
  &:before {
    width: 0;
    height: 0;
    border-bottom: 10px solid rgba(29, 30, 43, 0.8);
    border-right: 10px solid transparent;
    border-top: 10px solid transparent;
    border-left: 10px solid transparent;
    content: '';
    position: absolute;
    top: -20px;
    left: 76px;
  }
`;

export const HintText = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
  white-space: pre-wrap;
`;
