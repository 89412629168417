import React, { useMemo } from 'react';
import { Document, Page } from 'react-pdf';
import PropTypes from 'prop-types';
import LoadingIndicator from 'components/molecules/LoadingIndicator';
import pdfCss from './documents.scss';

const SinglePageViewer = (props) => {
  const { pdfData, onDocumentLoadSuccess, pageNumber, pageState } = props;
  const file = useMemo(() => ({ data: pdfData }), [pdfData]);
  if (!pdfData || pdfData.byteLength === undefined || pdfData.byteLength === 0) {
    return (
      <div style={{ height: '19px', margin: 'auto', paddingTop: 'calc(50vh - 19px)' }}>
        <LoadingIndicator />
      </div>
    );
  }
  return (
    <div className={pdfCss.Container}>
      <Document
        file={file}
        onLoadSuccess={onDocumentLoadSuccess}
        externalLinkTarget="_blank"
        className={pdfCss.Document}
      >
        <Page
          pageNumber={pageNumber}
          scale={pageState.get('scale')}
          width={pageState.get('width')}
          height={pageState.get('height')}
        />
      </Document>
    </div>
  );
};

SinglePageViewer.propTypes = {
  pdfData: PropTypes.object,
  onDocumentLoadSuccess: PropTypes.func,
  pageNumber: PropTypes.number,
  pageState: PropTypes.object,
};

export default SinglePageViewer;
