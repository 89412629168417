import { Col, Row } from 'antd';
import styled from 'styled-components';

const Arrow = styled.button`
  position: absolute;
  top: 40%;
  width: 32px;
  height: 32px;
  border: 1px solid #e3e7ed;
  border-radius: 50%;
  background: #ffffff;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  transition: ease-in-out;
  transition-duration: 150ms;
  :hover {
    ${({ disabled }) => !disabled && 'box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1)'};
  }
`;

export const LeftArrow = styled(Arrow)`
  left: -20px;
`;

export const RightArrow = styled(Arrow)`
  right: -20px;
`;

// Full Row
export const PromotionRow = styled(Row)`
  overflow-x: scroll;
  scroll-behavior: smooth;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;
// Promotion Card
export const CardContainer = styled(Col)`
  background-color: #f4f5ff;
  border: 1px solid #e3e7ed;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  gap: 16px;
  margin-right: 8px;
  margin-left: 8px;
  font-family: 'Montserrat';
`;

export const IconContainer = styled.div`
  width: 64px;
  height: 64px;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.025);
  background-image: url(/static/iap-subject-icon-bg.svg);
`;

export const CardContent = styled.div`
  height: 76px;
  margin-bottom: 4px;
  h3 {
    color: '#1D1E2B';
    font-weight: 700;
    font-size: '16px';
    line-height: '24px';
    margin-bottom: 0;
  }
  p {
    margin-top: 4px;
    padding: 0;
    font-size: 12px;
    font-weight: 500;
    color: #73747d;
  }
`;

export const ActionButton = styled.button`
  border-radius: 8px;
  background: #464ac9;
  padding: 6px 19px;
  color: white;
  border: none;
  margin-right: 16px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
`;

export const ActionLink = styled.a`
  color: #464ac9;
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  gap: 2px;
`;

export const Pill = styled.span`
  background-color: #fff2ef;
  color: #ff764c;
  border-radius: 30px;
  font-size: 10px;
  font-weight: 500;
  padding: 6px 16px;
`;

export const PendingLabel = styled.span`
  font-size: 12px;
  color: #ff764c;
  margin-right: 15px;
`;
