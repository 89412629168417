import React, { useState, useContext, useEffect } from 'react';
import { AppTrackerContext } from '../viewController';
import { useSelector } from 'react-redux';
import { getViewableUser } from 'selectors/meta';
import { Column, LoadingView, StyledIndicator } from '../index.style';
import UniversityGroup from '../UniversityGroup';
import ToolBar from '../ToolBar';
import SearchUniversityPopup from '../SearchUniversityPopup';
import SetupApplication from '../SetupApplication';
import { Application, ApplicationGroup } from '../type';
import { MAP_GROUP_TEXT } from '../constants';
import ParentApprovalModal from '../ParentApprovalModal';
import { useIsAccessibleStaff, useIsStudent, useRequesterUserId } from '../helper';
const GROUP_BUTTON_OPTIONS = [
  { label: 'Final List', value: 'APPLYING' },
  { label: 'Short List', value: 'SHORTLISTED' },
  { label: 'Long List', value: 'PROSPECTIVE' },
];
const ListPage = () => {
  const userId = useSelector(getViewableUser);
  const { state, controller } = useContext(AppTrackerContext);
  const [isLoading, setLoading] = useState(true);
  const isStudent = useIsStudent();
  const isAccessibleStaff = useIsAccessibleStaff();
  const { applications } = state;
  const requesterUserId = useRequesterUserId();
  useEffect(() => {
    controller.queryAllApplications(userId).finally(() => {
      setLoading(false);
    });
  }, [controller, userId]);
  const onClickActionButtonItem = async (application: Application, value: string) => {
    if (value === 'APPLYING') {
      // if user try to move application to final list
      const canEdit = controller.checkIfEditable(isStudent, isAccessibleStaff, application);
      if (!canEdit) return false;
    }
    return controller.updateApplication(
      application.id,
      { group: value as ApplicationGroup },
      { updatedBy: requesterUserId },
    );
  };
  const getExtraActionButtonProps = (application: Application) => {
    const isApplying = application.group === 'APPLYING';
    return {
      onClickActionButtonItem: (value: string) => onClickActionButtonItem(application, value),
      actionButtonOptions: GROUP_BUTTON_OPTIONS,
      getActionButtonText: () => MAP_GROUP_TEXT[application.group] || application.group,
      menuItemStyle: {
        width: 140,
      },
      preOpenDropdown: isApplying
        ? () => controller.checkIfEditable(isStudent, isAccessibleStaff, application)
        : undefined,
    };
  };
  const getExtraDeleteAppButtonProps = (application: Application) => {
    const isApplying = application.group === 'APPLYING';
    return {
      preOpenDropdown: isApplying
        ? () => controller.checkIfEditable(isStudent, isAccessibleStaff, application)
        : undefined,
      options: [{ label: 'Remove school from the list', value: 'remove_application' }],
      onConfirmDeleteOption: controller.onConfirmDeleteItem,
      getConfirmDeletionText: () =>
        'Are you sure you want to remove the school from your list? \n\nYou can add it back if you change your mind later.',
      menuItemStyle: {
        width: 170,
      },
    };
  };
  return (
    <>
      {isLoading && !applications.length && (
        <LoadingView>
          <StyledIndicator colour={'primary'} />
        </LoadingView>
      )}
      {!isLoading && !applications.length && <SetupApplication />}
      {!!applications.length && (
        <Column style={{ margin: '25px 60px 300px' }} gap={'30px'}>
          <ToolBar />
          <Column gap={'60px'}>
            <UniversityGroup
              getExtraActionButtonProps={getExtraActionButtonProps}
              group={'APPLYING'}
              showParentApprovalButton
              getExtraDeleteAppButtonProps={getExtraDeleteAppButtonProps}
              prompt={'Schools that you will definitely\nbe applying to'}
            />
            <UniversityGroup
              getExtraActionButtonProps={getExtraActionButtonProps}
              group={'SHORTLISTED'}
              getExtraDeleteAppButtonProps={getExtraDeleteAppButtonProps}
              prompt={'Schools that you will do in-depth\nresearch on and are seriously\nconsidering applying to'}
            />
            <UniversityGroup
              getExtraActionButtonProps={getExtraActionButtonProps}
              group={'PROSPECTIVE'}
              getExtraDeleteAppButtonProps={getExtraDeleteAppButtonProps}
              prompt={'Schools that you are interested in\nlearning more about and might\napply to'}
            />
          </Column>
        </Column>
      )}
      <SearchUniversityPopup />
      <ParentApprovalModal />
    </>
  );
};

export default ListPage;
