import React from 'react';
import { Column } from 'components/molecules/Table';
import { columnNames } from '../columnMap';

export const hasUniversities = (universities) => universities && universities.size > 0;

export const extractUniversitiesFromData = (data) => {
  const university = data.get('university') || data.getIn(['tutorInfo', 'university']);
  const [unis, tutorUnis] = [data.get('universities'), data.getIn(['tutorInfo', 'universities'])];
  const universities = (hasUniversities(unis) && unis.toJS()) || (hasUniversities(tutorUnis) && tutorUnis.toJS());

  return universities || (university && [{ name: university }]) || [];
};

export const getUniversityNames = (universities) => {
  const hasUniversities = universities && universities.length > 0;

  return hasUniversities ? universities.map((university) => university.name) : ['No University'];
};

export default function University() {
  const universities = (data) => getUniversityNames(extractUniversitiesFromData(data));

  return (
    <Column
      key={columnNames.University}
      name="University"
      renderer={({ rowData }) => `${universities(rowData).join(', ')}`}
    />
  );
}
