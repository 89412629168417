import moment from 'moment';
import Immutable from 'immutable';
import { PermissionResourceType } from '@crimson-education/common-config/lib/authorization';
import createReducer from 'utils/createReducer';
import lessonService from 'graphql/api/lesson';
import feedbackService from 'graphql/api/feedback';
import { ADD_ENTITIES, addEntitiesWithNormalisation } from 'ducks/normalizr';
import { getBooking } from 'ducks/booking';
import { updateMeta } from 'ducks/meta';
import { selectUserPermissionInfo } from 'selectors/meta';
import { incompleteReportEntity } from 'schema';
import componentKeys from 'constants/componentKeys';

const REMOVE_REPORT = 'incompleteReport/REMOVE_REPORT';

const initialState = new Immutable.Map();
let lapsedBillingPeriod = null; // This is localized to avoid race conditions with the value stored in meta

export default createReducer(initialState, {
  [ADD_ENTITIES]: (state, action) => {
    const { incompleteReport } = action.payload.entities;
    if (!incompleteReport) {
      return state;
    }

    return state.mergeDeep(incompleteReport);
  },
  [REMOVE_REPORT]: (state, action) => {
    return state.delete(action.payload.eventId);
  },
});

export function removeReport(eventId) {
  return { type: REMOVE_REPORT, payload: { eventId: eventId.toString() } };
}

export function fetchIncompleteReports(tutorId) {
  return async (dispatch, getState) => {
    const state = getState();
    const loginUserPermissionsInfo = selectUserPermissionInfo(state);
    if (
      !loginUserPermissionsInfo.filter((p) => p.get('resourceType') === PermissionResourceType.LessonList).length > 0
    ) {
      return;
    }
    (tutorId ? lessonService.fetchIncompleteReports(tutorId) : Promise.reject()).then((res) => {
      const { incompleteReports, userBilling } = res;

      incompleteReports.forEach((x) => dispatch(getBooking(x.eventId))); // We need booking information for all incomplete reports
      dispatch(addEntitiesWithNormalisation(incompleteReports, [incompleteReportEntity]));

      if (!userBilling) {
        return;
      }

      const { billingPeriods } = userBilling;
      const nextBillingPeriod = moment(billingPeriods.pop().end);
      dispatch(updateMeta(componentKeys.NEXT_BILLING_PERIOD, nextBillingPeriod));

      lapsedBillingPeriod = nextBillingPeriod < moment() ? nextBillingPeriod.clone() : moment(billingPeriods.pop().end);

      dispatch(updateMeta(componentKeys.LAPSED_BILLING_PERIOD, lapsedBillingPeriod));
    });
  };
}

export function fetchIncompleteSessionReports(reporterId) {
  return async (dispatch) => {
    return feedbackService.fetchIncompleteSessionReports(reporterId).then((res) => {
      const { incompleteSessionReports } = res;
      incompleteSessionReports.forEach((x) => dispatch(getBooking(x.eventId))); // We need booking information for all incomplete reports
      dispatch(addEntitiesWithNormalisation(incompleteSessionReports, [incompleteReportEntity]));
    });
  };
}

export function showCompleteReportModal(eventId) {
  return (dispatch) => {
    dispatch(getBooking(eventId));
    dispatch(updateMeta(componentKeys.ACTIVE_SESSION_REPORT, eventId.toString()));
  };
}
