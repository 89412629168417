import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { DecileRanks as rankList } from '@crimson-education/common-config';
import { getCurrentUser } from 'selectors/user';
import Modal from 'components/molecules/Modal';
import InputText from 'components/molecules/InputText';
import Select from 'components/molecules/Select';
import { Caption } from 'components/atoms/typography';
import { createAcademic, updateAcademic } from 'ducks/academic';
import { getSelectedAcademic } from 'selectors/academic';
import css from './styles.scss';

const MODAL_TITLE = 'Edit decile rank';

const MISSING_GRADE_VALIDATION = 'Please enter a grade';
const MISSING_RANK_VALIDATION = 'Please enter a rank';

export default function GradeDecileRankModal(props) {
  const dispatch = useDispatch();
  const user = useSelector(getCurrentUser);
  const { isOpen, onClose } = props;
  const data = useSelector(getSelectedAcademic);
  const [grade, setGrade] = useState(data ? data.grade : '');
  const [rank, setRank] = useState(data ? data.rank : '');
  const [errors, setErrors] = useState({});

  const isValid = () => {
    const validations = {
      currentGrade: grade ? undefined : MISSING_GRADE_VALIDATION,
      currentRank: rank ? undefined : MISSING_RANK_VALIDATION,
    };

    const errors = {};

    Object.keys(validations).forEach((key) => {
      if (validations[key] !== undefined) {
        errors[key] = validations[key];
      }
    });
    setErrors(errors);
    return !Object.keys(errors).length;
  };

  const save = async () => {
    if (!isValid()) return;
    if (data) {
      const academic = {
        id: data.id,
        grade,
        rank,
      };
      await dispatch(updateAcademic(academic));
    } else {
      const academic = {
        userId: user.get('userId'),
        grade,
        rank,
      };
      await dispatch(createAcademic(academic));
    }
    onClose();
  };

  const handleTextValueUpdated = (e) => {
    setGrade(e.target.value);
  };

  const onSelectChange = (e) => {
    setRank(e.value);
  };

  return (
    <Modal
      title={MODAL_TITLE}
      submitText="Save"
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={save}
      onSecondarySubmit={onClose}
    >
      <InputText
        label="Grade Level"
        placeholder="Grade 11"
        value={grade}
        onChange={handleTextValueUpdated}
        isValid={!errors.currentGrade}
        validationMessage={errors.currentGrade}
      />
      <Caption className={css.caption}>Academic Ranking （estimate if unknown）</Caption>
      <Select
        placeholder="Academic Ranking"
        value={rankList.find((option) => option.value === rank)}
        onChange={onSelectChange}
        isClearable
        isCreatable
        isSearchable={false}
        options={rankList.map((option) => ({ label: option.label, value: option.value }))}
        isValid={!errors.currentRank}
        validationMessage={errors.currentRank}
      />
    </Modal>
  );
}

GradeDecileRankModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
