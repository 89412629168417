import React, { useState, useEffect } from 'react';
import { StyledDatePicker, FilterContainer, StyledDropdownButton } from './style';
import { MoreLessIcon } from 'components/organisms/ApplicationTracker/components';
import moment, { Moment } from 'moment';
const Filters = () => {
  const [dates, setDates] = useState<[Moment, Moment] | undefined>(undefined);

  useEffect(() => {
    // [14 days ago, today]
    setDates([moment().add(-14, 'days'), moment()]);
  }, []);
  const dateFormatter = (value: Moment) => {
    if (value.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) return 'Now';
    return value.format('MMM DD');
  };
  return (
    <FilterContainer>
      <StyledDatePicker
        value={dates as any}
        allowClear={false}
        placeholder={['Start', 'End']}
        onChange={(value) => setDates((value as unknown) as [Moment, Moment])}
        suffixIcon={<MoreLessIcon style={{ fontSize: 20, color: '#1D1E2B' }} />}
        format={dateFormatter as any}
      />
      <StyledDropdownButton data={[{ label: 'All', value: '*' }]} onClickItem={() => void 0}>
        <span>{'Updated By: All'}</span>
      </StyledDropdownButton>
    </FilterContainer>
  );
};

export default Filters;
