import { useSelector } from 'react-redux';
import { getViewableUser, selectUserRoleInfo, selectUserId } from 'selectors/meta';
import { Application, ApplicationGroup, ApplicationRegion, UserRole } from './type';
import { mapApplicationRegion, getCurrentRegion, calculateSelectionHash } from './util';
import { useMemo, useState, useEffect, useRef, useContext } from 'react';
import { AppTrackerController } from './viewController/controller';
import { TAppTrackerState } from './viewController/state';
import { Acl } from '@crimson-education/common-config';
import { useFeatureFlag } from 'featureSwitches';
import { AppTrackerContext } from './viewController';
export const useUserId = (): string => {
  const userId = useSelector(getViewableUser);
  return userId;
};

export const useRequesterUserId = (): string => {
  return useSelector(selectUserId);
};

export const useApplicationsWithRegionAndGroup = (
  applications: Application[],
  region: ApplicationRegion,
  group: ApplicationGroup,
): Application[] =>
  useMemo(() => applications.filter((o) => o.group === group && mapApplicationRegion(o) === region), [
    applications,
    region,
    group,
  ]);

export const useSafeLoading = (initialValue = false): [boolean, (v: boolean) => void] => {
  const [isLoading, setLoading] = useState(initialValue);
  const alive = useRef(true);
  useEffect(() => {
    return () => {
      alive.current = false;
    };
  }, [alive]);
  const _setLoading = (loading: boolean) => {
    if (alive) setLoading(loading);
  };
  return [isLoading, _setLoading];
};

export const useCurrentUserRole = (): UserRole[] | undefined => {
  const res = useSelector(selectUserRoleInfo);
  return res ? res.map((o: any) => o.toJS()) : undefined;
};

export const useIsStudent = () => {
  const roles = useCurrentUserRole();
  if (!roles) return true;
  const roleIds = roles.map((o) => o.roleId);
  return Acl.isStudent(roleIds) || Acl.isExternalStudent(roleIds);
};

export const useIsAccessibleStaff = () => {
  const roles = useCurrentUserRole();
  if (!roles) return false;
  const roleIds = roles.map((o) => o.roleId);
  return Acl.isStrategist(roleIds);
};

export const useHasAccesssToStaffDashboard = () => {
  const roles = useCurrentUserRole();
  if (!roles) return false;
  const roleIds = roles.map((o) => o.roleId);
  return Acl.isStrategist(roleIds) || Acl.isCaseManager(roleIds);
};

export const useCalculateCurrentSelectionHash = (state: TAppTrackerState, controller: AppTrackerController) => {
  const currentRegion = getCurrentRegion(state.tabValue, state.listRegion, state.trackerRegion);
  const currentRegionApps = useApplicationsWithRegionAndGroup(state.applications, currentRegion, 'APPLYING').filter(
    (o) => o.hadCrimsonSupport,
  );
  const userId = useUserId();
  useEffect(() => {
    const currentHash = calculateSelectionHash(currentRegionApps, currentRegion, userId);
    const hasDiff = currentHash !== state.currentSelectionHash[currentRegion];
    if (!hasDiff) return;
    controller.setCurrentSelectionHash({
      [currentRegion]: currentHash,
    } as Record<ApplicationRegion, string>);
  }, [currentRegionApps, currentRegion, state.currentSelectionHash, userId, controller]);
};

export const useAppTrackerContext = () => useContext(AppTrackerContext);
