import React, { Component } from 'react';
import PropTypes from 'prop-types';

const MOBILE_WIDTH = 812;

const context = React.createContext();

export default class AppContext extends Component {
  constructor(props) {
    super(props);

    this.onResize = this.onResize.bind(this);
    this.setSocket = this.setSocket.bind(this);

    this.state = {
      isMobile: window.innerWidth <= MOBILE_WIDTH,
      socket: null,
      setSocket: this.setSocket,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  onResize() {
    const { isMobile } = this.state;
    const newIsMobile = window.innerWidth <= MOBILE_WIDTH;
    if (isMobile !== newIsMobile) {
      this.setState({ isMobile: newIsMobile });
    }
  }

  setSocket(socket) {
    this.setState({ socket });
  }

  render() {
    const { Provider } = context;
    const { children } = this.props;

    return <Provider value={this.state}>{children}</Provider>;
  }
}

AppContext.propTypes = {
  children: PropTypes.element,
};

export function withAppContext(Component) {
  const { Consumer } = context;

  return function WrappedComponent(props) {
    return <Consumer>{(ctx) => <Component {...props} app={ctx} />}</Consumer>;
  };
}
