import Immutable from 'immutable';
import createReducer from 'utils/createReducer';
import { formatGraphQLRequestError } from 'utils/graphql';
import feedbackService from 'graphql/api/feedback';
import { updateMeta, formFail } from 'ducks/meta';

import { ADD_ENTITIES, addEntitiesWithNormalisation } from 'ducks/normalizr';
import { otherSessionEntity } from 'schema';
import componentKeys from 'constants/componentKeys';
import { getLastPastBookingDate } from 'selectors/feedback';

const FETCH_USER_SESSIONS = 'feedback/FETCH_USER_SESSIONS';

const initialState = Immutable.fromJS({});

export const reducer = {
  [ADD_ENTITIES]: (state, action) => {
    return state.mergeDeep(action.payload.entities.otherSession);
  },
  [FETCH_USER_SESSIONS]: (state, action) => state.mergeDeep(action.payload),
};

export default createReducer(initialState, reducer);

const SCHEDULE_PAGE_LIMIT = 10;
export function fetchSessionsByStudent(studentId) {
  return async (dispatch, getState) => {
    // const date = moment();
    const date = getLastPastBookingDate()(getState());
    const appendedFilters = Object.assign({}, { limit: SCHEDULE_PAGE_LIMIT, date, type: 'session' });

    await feedbackService
      .fetchSessionsByStudent({ studentId, ...appendedFilters })
      .then((res) => {
        const { getSessionsByStudentId } = res;
        dispatch(addEntitiesWithNormalisation(getSessionsByStudentId, [otherSessionEntity]));

        if (getSessionsByStudentId.length === SCHEDULE_PAGE_LIMIT) {
          dispatch(updateMeta(componentKeys.OTHER_SESSION_HAS_MORE, true));
        } else {
          dispatch(updateMeta(componentKeys.OTHER_SESSION_HAS_MORE, false));
        }
      })
      .catch((error) => dispatch(formFail(['Error', formatGraphQLRequestError(error)])));
  };
}
