import 'react-quill/dist/quill.snow.css';
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ReactQuill, { Quill } from 'react-quill';
import Toolbar from './Toolbar';
import MagicUrl from 'quill-magic-url';
import ExtendSnowTheme from './ExtendedSnowTheme';
import './styles.scss';

const formats = [
  'header',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'color',
  'link',
  'image',
  'align',
];

const Size = Quill.import('attributors/style/size');
const sizes = [9, 10, 12, 14, 18, 24, 32, 42];
Size.whitelist = sizes.map((size) => `${size}px`);
Quill.register(Size, true);
Quill.register('modules/magicUrl', MagicUrl);
Quill.register('themes/snow', ExtendSnowTheme);

export default function QuillEditor({ html, disabled, onChange, id, toolbarClassName, className }) {
  const editorRef = useRef();
  const [currentHtml, setCurrentHtml] = useState('');
  useEffect(() => {
    setCurrentHtml(html);

    if (disabled !== undefined) {
      if (editorRef.current) {
        editorRef.current.editor.enable(!disabled);
      }
    }
  }, [html, disabled]);

  const handleChange = (content, delta, source, editor) => {
    setCurrentHtml(content);
    if (source === 'user') {
      const plainText = editor.getText();
      onChange({ html: content, text: plainText });
    }
  };

  const toolbarId = `quillToolbar-${id}`;

  return (
    <React.Fragment>
      {!disabled ? (
        <Toolbar id={toolbarId} fontSizes={sizes} className="custom-ql-toolbar" />
      ) : (
        <div id={toolbarId} className={`custom-ql-toolbar notoolbar ${toolbarClassName}`} />
      )}
      <ReactQuill
        ref={editorRef}
        onChange={handleChange}
        defaultValue={html}
        value={currentHtml}
        disabled={disabled}
        theme="snow"
        modules={{
          toolbar: `#${toolbarId}`,
          magicUrl: true,
        }}
        formats={formats}
        className={className}
      />
    </React.Fragment>
  );
}

QuillEditor.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  toolbarClassName: PropTypes.string,
  html: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
