import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { withStyles, makeStyles } from '@material-ui/core/styles';
export const EditorContainer = styled.div`
  display: flex;
  max-width: 546px;
  margin: 0 auto;
  flex-direction: column;
  justify-content: center;
  background: white;
  margin-bottom: 9px;
  border: 1px solid #e3e7ed;
  border-radius: 8px;
`;
export const EditorHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 20px;
`;

export const Title = styled.div`
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #1d1e2b;
  margin-right: 20px;
`;

export const Drafting = styled.div`
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #217a8d;
`;

export const ButtonGroup = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

export const StyledButton = withStyles({
  root: {
    textTransform: 'none',
  },
})(Button);

export const buttonStyle = {
  display: 'flex',
  alignItems: 'center',
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '20px',
  color: '#FFFFFF',
  background: '#464AC9',
  width: '82px',
  height: '28px',
};

export const outlineButtonStyle = {
  marginRight: '8px',
  backgroundColor: 'white',
  border: '1px solid #464AC9',
  color: '#464AC9',
};

export const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize: 12,
  },
}));
