import React from 'react';
import { Row } from '../../../index.style';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { StyledIconButton } from './index.style';
type ForwardBackwardButtonsProps = {
  onClickBack: () => void;
  onClickForward: () => void;
  isLast: boolean;
  isFirst: boolean;
  style?: React.CSSProperties;
};

const ForwardBackwardButtons = ({
  style,
  onClickBack,
  onClickForward,
  isFirst,
  isLast,
}: ForwardBackwardButtonsProps) => {
  return (
    <Row rowcenter gap={'15px'} style={style}>
      <StyledIconButton $disable={isFirst} onClick={onClickBack}>
        <ArrowBackIcon />
      </StyledIconButton>

      <StyledIconButton $disable={isLast} onClick={onClickForward}>
        <ArrowForwardIcon />
      </StyledIconButton>
    </Row>
  );
};

export default ForwardBackwardButtons;
