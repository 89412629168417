import { createSelector } from 'reselect';
import Immutable from 'immutable';
import { getSessionSelectedId } from 'selectors/meta';

const lesson = (state) => state.get('lesson');

// TODO: This selector is maintained for backwards compatability. A new selector
// that filters directly by clientId, tutorId and subjectId would be more
// efficient.
export const getAllLessons = createSelector(lesson, (allLessons) => {
  const lessonsList = allLessons.toList().sortBy((lesson) => -lesson.get('start'));

  return lessonsList.reduce((acc, curr) => {
    const id = curr.get('id');
    const clientId = curr.getIn(['client', 'userId']);
    const tutorId = curr.getIn(['tutor', 'userId']);
    const subjectId = curr.get('itemId');
    const slug = `${clientId}-${tutorId}-${subjectId}`;

    // Ensure that each slug maps to an OrderedMap to preserve insertion
    // order. Insertion order is by sorted start date.
    let sortedAcc = acc;
    if (!acc.has(slug)) {
      sortedAcc = acc.set(slug, new Immutable.OrderedMap());
    }

    return sortedAcc.setIn([slug, id], curr);
  }, new Immutable.Map());
});

export const allLessons = createSelector(lesson, (lessons) => lessons.toJS());

export const getLessonsByItemId = (itemId) => {
  return createSelector(lesson, (allLessons) => {
    const sortedLessons = allLessons.sortBy((lesson) => -lesson.get('start'));
    return sortedLessons.filter((lesson) => lesson.get('itemId').toString() === itemId);
  });
};

export const getLesson = (id) => {
  return createSelector(lesson, (lessons) => lessons.get(id));
};

export const getLessonByEventId = (eventId) => {
  return createSelector(lesson, (allLessons) => {
    return allLessons && allLessons.size
      ? allLessons.find((lesson) => eventId && lesson.get('eventId').toString() === eventId.toString())
      : null;
  });
};

export const getLessonSelected = createSelector(getSessionSelectedId, lesson, (id, allLessons) => {
  const lesson =
    allLessons && allLessons.size
      ? allLessons.find((lesson) => id && lesson.get('eventId').toString() === id.toString())
      : null;
  return lesson ? lesson.toJS() : null;
});

export default {
  getAllLessons,
  allLessons,
  getLesson,
};
