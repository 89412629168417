import React from 'react';
import FilterTag, { FILTER_ICONS } from 'components/molecules/FilterTag';
import Select from 'components/molecules/Select';
import { components } from 'react-select';
import font from 'components/atoms/typography/font.scss';
import fontFamily from 'components/atoms/typography/fontFamily.scss';
import fontColour from 'components/atoms/typography/fontColour.scss';
import classNames from 'classnames';
import Highlighter from 'react-highlight-words';

import Filter from './filter.js';

const filterNameEmailField = (option, inputValue) => {
  const { label, detail } = option.data;
  return (
    (label && label.toLowerCase().includes(inputValue.toLowerCase())) ||
    (detail && detail.toLowerCase().includes(inputValue.toLowerCase()))
  );
};

// Customised option component to render the users in the dropdown
const Option = (componentProps) => {
  const inputValue = componentProps.selectProps.inputValue;
  const option = componentProps.data;
  return (
    <components.Option {...componentProps}>
      <div data-test-id={componentProps.data.dataTestId}>
        <Highlighter
          searchWords={[inputValue]}
          textToHighlight={option.label}
          unhighlightClassName={classNames(fontFamily.regular, fontColour.primary, font.body)}
          highlightClassName={classNames(fontFamily.bold, fontColour.primary, font.body)}
          autoEscape
        />
        <br />
        <Highlighter
          searchWords={[inputValue]}
          textToHighlight={option.detail}
          unhighlightClassName={classNames(fontFamily.regular, fontColour.secondary, font.caption)}
          highlightClassName={classNames(fontFamily.bold, fontColour.secondary, font.caption)}
          autoEscape
        />
      </div>
    </components.Option>
  );
};

export default class NameFilter extends Filter {
  constructor(...args) {
    super(...args);
    this.state = {
      name: '',
      tutors: [],
    };
    this.title = 'Name';
  }

  apply(tutor) {
    const searchStr = this.state.name.toLowerCase();
    if (!tutor.fullName || !tutor.fullName.toLowerCase().includes(searchStr)) {
      return false;
    }
    return true;
  }

  data({ tutors }) {
    this.setState({ tutors });
  }

  value() {
    return this.state.name;
  }

  simpleValue() {
    return this.value();
  }

  reset() {
    this.setState({
      name: '',
    });
  }

  handleSelect(value) {
    this.setState({
      name: value,
    });
  }

  active() {
    return this.state.name.length > 0;
  }

  edit() {
    const nameEmailOptions = this.state.tutors.map((tutor) => ({
      value: tutor.fullName,
      label: tutor.fullName,
      detail: tutor.email,
      dataTestId: `${tutor.email}-filteroption`,
    }));

    return (
      <Select
        id="nameEmailSelector"
        dataTestId="nameEmailSelector"
        name="Name"
        value={nameEmailOptions.find((option) => option.value === this.state.name)}
        onChange={(option) => this.handleSelect(option ? option.value : '')}
        options={nameEmailOptions}
        components={{ Option }}
        filterOption={filterNameEmailField}
        isClearable
      />
    );
  }

  tag() {
    return (
      <FilterTag
        dataTestId="nameEmailFilterTag"
        onClear={() => this.reset()}
        onClick={() => this.openModal()}
        value={this.state.name}
        displayValue={this.state.name}
        label="Name"
        icon={FILTER_ICONS.SEARCH}
      />
    );
  }
}
