import { createSelector } from 'reselect';
import Immutable from 'immutable';

const agenda = (state) => state.get('agenda');

export const agendas = createSelector(agenda, (x) => x.getIn(['entities', 'agenda']));

export const agendaItems = createSelector(agenda, (x) => x.getIn(['entities', 'agendaItem']));

export const getAgenda = (eventId) =>
  createSelector(agendas, agendaItems, (agendas, items) => {
    const list = agendas.getIn([eventId, 'items']);
    if (!list) {
      return new Immutable.List();
    }

    return list.map((x) => items.get(x));
  });

export const getAgendaIsLoading = (eventId) =>
  createSelector(agendas, (agendas) => agendas.getIn([eventId, 'isLoading']));
