import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import isEqual from 'lodash/isEqual';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Paginator from 'components/generic/Paginator';
import LoadingCircle from 'components/molecules/LoadingCircle';
import colours from 'components/atoms/colours.scss';
import formStates from 'constants/formStates';
import UserQueryTable from './UserQueryTable';
import UserQueryFilter from './UserQueryFilter';
import css from './styles.scss';

const DEFAULT_PAGE = 1;
const USER_LIST_PAGE_SIZE = 10;

export default class UserQuery extends Component {
  constructor(props) {
    super(props);
    const { fixedFilters, prevFilters } = props;
    // assign prevFilters to state if navigating back or loading the all users tab
    this.state = {
      filterParams:
        (fixedFilters &&
          (!fixedFilters.roles || (fixedFilters.roles && isEqual(prevFilters.roles, fixedFilters.roles))) &&
          prevFilters) ||
        null,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.activePage > 0 && prevProps.activePage !== this.props.activePage) {
      this.handleSearchParamsChange({ pageNumber: this.props.activePage });
    }
  }

  handleSearchParamsChange = (params, filters = {}) => {
    const {
      fetchUsers,
      activePage,
      activeUsers,
      fixedFilters,
      changeableFilters,
      userId,
      tabType,
      prevFilters,
      filtersToShow,
    } = this.props;
    const pageNumber = (params && params.pageNumber) || activePage || DEFAULT_PAGE;

    let filterObj;
    if (
      (!Object.keys(filters).length && !this.state.filterParams && fixedFilters && !fixedFilters.roles) ||
      (fixedFilters && fixedFilters.roles && isEqual(prevFilters.roles, fixedFilters.roles))
    ) {
      const { roles, ...enteredFilters } = filters;
      filterObj = {
        ...prevFilters,
        ...enteredFilters,
        ...fixedFilters,
      };
    } else {
      filterObj = Object.assign(
        {},
        {
          ...this.state.filterParams,
        },
        (Object.keys(filters).length && filters) || {},
        {
          ...fixedFilters,
          ...(filters && !filters.tutorCapacity && changeableFilters && { ...changeableFilters }),
        },
        { active: activeUsers },
      );
    }

    // create an array of valid filters that we'll use to verify filterObjs against
    const validFilters = [
      ...((filtersToShow && filtersToShow) || []),
      ...((fixedFilters && Object.keys(fixedFilters)) || []),
      ...((changeableFilters && Object.keys(changeableFilters)) || []),
      ...Object.keys(filters),
      'roles',
      'active',
      'relatedTutors',
    ];
    const filterProps = Object.keys(filterObj);
    for (let i = 0; i < filterProps.length; i += 1) {
      if (!validFilters.map((p) => p.toLowerCase()).includes(filterProps[i].toLowerCase())) {
        delete filterObj[filterProps[i]];
      }
    }

    this.setState({ filterParams: filterObj });

    fetchUsers(
      {
        pageNumber,
        pageSize: (params && params.pageSize) || USER_LIST_PAGE_SIZE,
      },
      filterObj,
      userId,
      {
        tabType,
        hasFilters: filtersToShow && filtersToShow.length && true,
      },
    );
    this.props.setActivePage(pageNumber);
  };

  render() {
    const {
      loginUserPermissions,
      userQueryResults,
      columnsToShow,
      setUserStatus,
      filtersToShow,
      userPagination,
      usersFetched,
      formState,
      activePage,
      prevFilters,
      prevFilteredQuery,
      fixedFilters,
      viewableRoles,
      fetchViewableRoles,
    } = this.props;

    const defaults = {
      totalPages: 0,
      currentPage: 0,
      userResults: new List(),
      filterParams: null,
    };

    const totalPages =
      userPagination && userPagination.totalPages && formState !== formStates.Loading
        ? userPagination.totalPages
        : defaults.totalPages;
    const currentPage =
      userPagination && userPagination.currentPage && formState !== formStates.Loading
        ? userPagination.currentPage
        : defaults.currentPage;
    const userResults = userQueryResults || defaults.userResults;

    return (
      <div>
        <UserQueryFilter
          loginUserPermissions={loginUserPermissions}
          onSearchParamsChange={(filterParams) => {
            // Reset pageNumber to 1 after changing search params
            return this.handleSearchParamsChange({ pageNumber: 1 }, filterParams);
          }}
          activePage={activePage}
          filtersToShow={filtersToShow}
          prevFilters={prevFilters}
          prevFilteredQuery={prevFilteredQuery}
          fixedFilters={fixedFilters}
          viewableRoles={viewableRoles}
          fetchViewableRoles={fetchViewableRoles}
        />
        {formState === formStates.Loading && (
          <div style={{ margin: '8rem auto', textAlign: 'center' }}>
            <LoadingCircle color={colours.crimsonRed} />
          </div>
        )}
        <UserQueryTable
          usersFetched={usersFetched}
          users={userResults}
          columns={columnsToShow}
          setUserStatus={setUserStatus(() => this.handleSearchParamsChange(null, this.state.filterParams))}
          loginUserPermissions={loginUserPermissions}
        />
        <Paginator
          className={css.pager}
          totalPages={totalPages}
          currentPage={currentPage}
          onPageClick={(pageNumber) => {
            this.props.setActivePage(pageNumber);
          }}
        />
      </div>
    );
  }
}

UserQuery.propTypes = {
  loginUserPermissions: PropTypes.array.isRequired,
  userQueryResults: ImmutablePropTypes.list,
  fetchUsers: PropTypes.func.isRequired,
  activeUsers: PropTypes.bool,
  fixedFilters: PropTypes.object,
  changeableFilters: PropTypes.object,
  setUserStatus: PropTypes.func.isRequired,
  columnsToShow: PropTypes.array,
  filtersToShow: PropTypes.array,
  setActivePage: PropTypes.func.isRequired,
  activePage: PropTypes.number,
  userPagination: PropTypes.object.isRequired,
  usersFetched: PropTypes.bool.isRequired,
  userId: PropTypes.string,
  formState: PropTypes.string,
  tabType: PropTypes.string,
  prevFilters: PropTypes.object,
  prevFilteredQuery: PropTypes.bool,
  viewableRoles: PropTypes.array,
  fetchViewableRoles: PropTypes.func.isRequired,
};

UserQuery.defaultProps = {
  activeUsers: true,
};
