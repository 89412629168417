import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { withAppTrackerContext, AppTrackerContext } from './viewController';
import { ApplicationTab, TabPanel } from './ApplicationTab';
import { Applications } from '../../pages/Applications';
import { Container } from './index.style';
import Banner from './Banner';
import ListPage from './ListPage';
import TrackerPage from './TrackerPage';
import { featureSwitches } from 'featureSwitches';
import AdmissionDrawer from './AdmissionDrawer';
import Dialogue from './Dialogue';
import { useCalculateCurrentSelectionHash } from './helper';
import StreamlitSSA from 'components/pages/StreamlitSSA/loader';
import { getCurrentUserRoles } from 'selectors/user';
import { selectUserPermissions } from 'selectors/meta';
import { canViewSSA } from 'utils/profileTabMap';
import { getCurrent } from './util';
import StudentApplicationOverview from './StudentApplicationOverview';

type ApplicationTrackerProps = {
  showBanner?: boolean;
  openInProfile: boolean;
};

const ApplicationTracker = ({ showBanner, openInProfile }: ApplicationTrackerProps) => {
  const { state, controller } = useContext(AppTrackerContext);
  const { applications } = state;
  const currentRegion = getCurrent(state.tabValue, state.listRegion, state.trackerRegion);
  const onConfirmEdit = () => {
    controller.setGrantEditingTrue(currentRegion);
    controller.closeConfirmEditingModal();
  };
  const currentUserRoles = useSelector(getCurrentUserRoles);
  const loginUserPermissions = useSelector(selectUserPermissions);

  const tabs: { value: string; label: React.ReactNode }[] = [
    {
      value: 'school_list',
      label: 'School List',
    },
    {
      value: 'tracker_list',
      label: 'Application Tracker',
    },
  ];

  if (canViewSSA(loginUserPermissions, currentUserRoles)) {
    tabs.push({
      value: 'streamlit_ssa',
      label: (
        <span style={{ display: 'inline-flex' }}>
          <span>College Algorithm</span>
          <span style={{ marginTop: '-5px', fontSize: '10px' }}>&nbsp;Beta</span>
        </span>
      ),
    });
  }

  useCalculateCurrentSelectionHash(state, controller);

  return (
    <Container style={{ overflowY: openInProfile ? 'visible' : 'auto' }}>
      {showBanner && <Banner />}

      {(featureSwitches as any).STUDENT_APPLICATION_OVERVIEW() && applications.length > 0 && (
        <StudentApplicationOverview />
      )}

      <ApplicationTab tabs={tabs}>
        <TabPanel value={'school_list'}>
          <ListPage />
        </TabPanel>
        <TabPanel value={'tracker_list'}>
          <TrackerPage />
        </TabPanel>
        {(featureSwitches as any).STREAMLIT_SSA() && canViewSSA(loginUserPermissions, currentUserRoles) && (
          <TabPanel value={'streamlit_ssa'}>
            <StreamlitSSA />
          </TabPanel>
        )}
      </ApplicationTab>
      <Dialogue
        title={'Do you want to make changes?'}
        isOpen={state.isConfirmEditingModalOpen}
        closeModal={controller.closeConfirmEditingModal}
        onClickConfirm={onConfirmEdit}
        cancelButtonText={'No, Cancel'}
        confirmButtonText={'Yes, I understand'}
      >
        {
          'Making changes to the final school list will require a new School Choice Confirmation Agreement email to be sent to the parents. '
        }
      </Dialogue>
      <AdmissionDrawer />
    </Container>
  );
};

export default withAppTrackerContext(ApplicationTracker);
