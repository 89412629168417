import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './styles.scss';

export default function VerticalCell(props) {
  return <div className={classNames(css.verticalCell, css[props.align])}>{props.children}</div>;
}

VerticalCell.displayName = 'VerticalCell';

VerticalCell.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  align: PropTypes.string,
};

VerticalCell.defaultProps = {
  align: 'start',
};
