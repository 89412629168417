/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import classNames from 'classnames';
import IconButton from 'components/molecules/IconButton';
import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';
import { getBearer } from 'utils/auth';
import LoadingCircle from 'components/molecules/LoadingCircle';
import * as Logger from '@crimson-education/browser-logger';

import css from './style.scss';
import { handleEnter } from '../../../utils/accessibility';
import { MessageEventType } from '../../../utils/MessageEventType';

const config = getConfig();

export default class MessageImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      loaded: false,
    };
    this.onClose = this.onClose.bind(this);
    // this.handleLoadImage = this.handleLoadImage.bind(this);
  }

  onClose() {
    this.setState({
      modalIsOpen: false,
    });
  }
  trackRenderImageEvent() {
    const fileSize = sessionStorage.getItem('uploadFileSize');
    if (fileSize && fileSize !== '0') {
      Logger.trackEventSinceLastAction({
        message: 'upload image render success',
        metadata: {
          fileSize: parseInt(fileSize, 10),
        },
      });
      sessionStorage.setItem('uploadFileSize', 0);
    }
  }
  handlePreview() {
    this.setState({
      modalIsOpen: true,
    });
  }
  handleLoadImage = () => {
    if (Logger.getLastTrackedEvent()?.message === MessageEventType.MessageSent) {
      this.trackRenderImageEvent();
    }
    this.setState({ loaded: true });
    this.props.onImageLoaded();
  };

  downloadImage(image) {
    const { downloadMessageFile } = this.props;
    if (config.dulwich) {
      const downloadUrl = `${config.api.endpoint}/files/${image.id}?token=${getBearer()}`;
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.target = '_blank';
      a.download = image.filename;
      a.click();
    } else {
      downloadMessageFile(image.id, image.source);
    }
  }

  render() {
    const { image } = this.props;
    let ossurl = '';
    if (config.dulwich && image.ossurl) {
      ossurl = image.ossurl;
    }
    if (!config.dulwich) {
      ossurl = image.awsurl;
    }
    const isOpen = this.state.modalIsOpen;
    return (
      <div className={css.wrapper}>
        {this.state.loaded ? null : (
          <LoadingCircle color="#00a896" style={{ width: '2rem', height: '2rem' }} data-test-id="sending-indicator" />
        )}
        <div className={css.info} style={this.state.loaded ? {} : { display: 'none' }}>
          <img
            role="button"
            tabIndex={0}
            className={css.imageEement}
            src={ossurl}
            alt=""
            onClick={() => this.handlePreview()}
            onKeyDown={handleEnter(() => this.handlePreview())}
            onLoad={this.handleLoadImage}
          />
          <IconButton
            icon="GetApp"
            className={css.downloadImage}
            style={{ color: '#ffffff' }}
            iconSize={{ width: '1.5rem', height: '1.5rem' }}
            onClick={() => this.downloadImage(image)}
          />
          <span className={css.imageName}>{image.name}</span>
        </div>
        {isOpen && (
          <ReactModal
            isOpen={this.state.modalIsOpen}
            className={classNames(css.content)}
            overlayClassName={classNames(css.overlay)}
          >
            <IconButton
              icon="Close"
              onClick={this.onClose}
              dataTestId="closeModalIcon"
              className={classNames(css.closeAction, {
                [css.hideClose]: !this.onClose,
              })}
              iconSize={{ height: '3rem', width: '3rem', background: 'rgba(255, 255, 255, 0.8)', borderRadius: '50%' }}
            />
            <div className={css.imageWrapper}>
              <img className={css.image} src={ossurl} alt="" />
            </div>
          </ReactModal>
        )}
      </div>
    );
  }
}

MessageImage.propTypes = {
  image: PropTypes.object,
  downloadMessageFile: PropTypes.func,
  onImageLoaded: PropTypes.func,
};
