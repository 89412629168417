import { createSelector } from 'reselect';
import { getCurrentUser } from 'selectors/user';
import Immutable from 'immutable';
import moment from 'moment';

const feedback = (state) => state.get('feedback');
const otherSessions = (state) => state.get('otherSession');

export const feedbackRating = (reportType, userId, toJS = false) =>
  createSelector(getCurrentUser, feedback, (user, feedback) => {
    const ratingUserId = userId || user.get('userId');
    const result = feedback.getIn(['ratings', ratingUserId, reportType]);
    if (toJS) {
      return result && result.toJS();
    }
    return result || null;
  });

export const averageRating = createSelector(feedbackRating('all'), (rating) => rating && rating.get('averageRating'));

export const getUserReview = (userId) =>
  createSelector(feedback, getCurrentUser, (feedback, user) => {
    const id = userId || user.get('userId');
    const reviewObj = feedback.getIn(['reviews', id, 'reviews']);
    return reviewObj ? reviewObj.toList() : null;
  });

export const getUserReviewPagination = createSelector(feedback, getCurrentUser, (feedback, user) =>
  feedback.getIn(['reviews', user.get('userId'), 'pagination']),
);

export const getUserReviewTotal = createSelector(feedback, getCurrentUser, (feedback, user) =>
  feedback.getIn(['reviews', user.get('userId'), 'total']),
);

export const getOtherSessions = createSelector(otherSessions, getCurrentUser, (otherSessions, user) => {
  if (otherSessions) {
    return otherSessions
      .filter((o) => o.get('userId') === user.get('userId'))
      .sortBy((session) => -moment(session.get('start')));
  }
  return new Immutable.OrderedMap({});
});

export const getPastOtherSessions = createSelector(getOtherSessions, getCurrentUser, (otherSessions, user) =>
  otherSessions
    .filter((o) => o.get('userId') === user.get('userId'))
    .filter((m) => moment(m.get('end')).isBefore(moment()))
    .sort((a, b) => b.get('start') - a.get('start')),
);

export const getLastPastBookingDate = () => {
  return createSelector(getPastOtherSessions, (otherSessions) => {
    return otherSessions && otherSessions.size ? moment(otherSessions.last().get('start')).format() : null;
  });
};
