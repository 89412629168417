import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';
import { gql } from '@apollo/client';
import { createClient } from '../utils';
import { detailedUserType } from './common/types';

const { api } = getConfig();

const client = createClient(`${api.endpoint}/graphql`);

export const editTutorSubject = gql`
  mutation editTutorSubject($tutorId: String!, $subjects: [subjectInputType]!) {
    editTutorSubject(tutorId: $tutorId, subjects: $subjects)
  }
`;

export const getTutorsOfSubject = gql`
  ${detailedUserType}
  query getTutorsOfSubject($subjectId: String!) {
    getTutorsOfSubject(subjectId: $subjectId) {
      ...detailedUserType
    }
  }
`;

export const getSubjectsOfTutor = gql`
  query getSubjectsOfTutor($tutorId: String!) {
    getSubjectsOfTutor(tutorId: $tutorId) {
      name
      subjectId
    }
  }
`;

export default {
  editTutorSubject: (tutorId, subjects) => client.query(editTutorSubject, { tutorId, subjects }),
  getTutorsOfSubject: (subjectId) => client.query(getTutorsOfSubject, { subjectId }),
  getSubjectsOfTutor: (tutorId) => client.query(getSubjectsOfTutor, { tutorId }),
};
