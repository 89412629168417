import React, { useState } from 'react';
import PropTypes from 'prop-types';
import css from './styles.scss';

function InputField(props) {
  const [value, setValue] = useState('');
  const { error, validationMessage, maxLength, placeholder, onValueChange, className, onBlur, disabled } = props;
  const handleOnChange = (e) => {
    const currentValue = e.target.value;
    setValue(currentValue);
    onValueChange(currentValue);
  };
  return (
    <div className={className}>
      <input
        className={css.formInput}
        maxLength={maxLength}
        placeholder={placeholder}
        onChange={handleOnChange}
        value={value}
        onBlur={onBlur}
        disabled={disabled}
      />
      {error && validationMessage && <div className={css.validationMessage}>{validationMessage}</div>}
    </div>
  );
}
InputField.propTypes = {
  error: PropTypes.bool,
  onValueChange: PropTypes.func,
  placeholder: PropTypes.string,
  validationMessage: PropTypes.string,
  maxLength: PropTypes.string,
  className: PropTypes.string,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
};

export default InputField;
