/* eslint react/no-unescaped-entities: 0 */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import StudentCard from 'components/organisms/StudentCard';
import { Tab, Tabs, TabList, TabPanel } from 'components/molecules/ReactTabs';
import DeclineContractModal from 'components/organisms/DeclineContractModal';
import PastStudents from 'components/atoms/graphics/PastStudents';
import PendingStudents from 'components/atoms/graphics/PendingStudents';
import CurrentStudents from 'components/atoms/graphics/CurrentStudents';
import Body from 'components/atoms/typography/Body';
import Button from 'components/molecules/Button';
import LoadingIndicator from 'components/molecules/LoadingIndicator';

import css from './styles.scss';

const renderedTabs = ['current', 'pending', 'past'];

function PastEmptyScreen() {
  return (
    <div className={css.emptyContainer}>
      <PastStudents className={css.emptyGraphic} />
      <Body className={css.emptyHeader}>You have no past students</Body>
      <Body className={css.emptyContent}>
        Once a student completes all of their hours with you, they will appear here.
      </Body>
    </div>
  );
}

function CurrentEmptyScreen() {
  return (
    <div className={css.emptyContainer}>
      <CurrentStudents className={css.emptyGraphic} />
      <Body className={css.emptyHeader}>Smile, it’s free therapy</Body>
      <Body className={css.emptyContent}>
        Your students will appear here once you have been invited to teach. New student requests will appear in the
        pending tab above.
      </Body>
      {/* <div className={css.buttonDiv}>
        <Link to="pending">
          <Button className={css.button}>See pending students</Button>
        </Link>
      </div> */}
    </div>
  );
}

function PendingEmptyScreen({ showButton }) {
  return (
    <div className={css.emptyContainer}>
      <PendingStudents className={css.emptyGraphic} />
      <Body className={css.emptyHeader}>Sit back and relax</Body>
      <Body className={css.emptyContent}>
        You have no pending students. We'll let you know when you have a new allocation request.
      </Body>
      {showButton ? (
        <div className={css.buttonDiv}>
          <Link to="/calendar">
            <Button className={css.button}>Make a booking request</Button>
          </Link>
        </div>
      ) : null}
      <Body className={css.footerText}>
        If your student requests are not here anymore, unfortunately they have been either accepted by another tutor or
        the request has been cancelled.
      </Body>
    </div>
  );
}

class Students extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clientsLoaded: !!props.allStudents.get('loaded'),
      declineModal: false,
      validationError: false,
      contractId: null,
      subject: null,
      declineReason: '',
    };

    if (!props.allStudents.get('loaded')) {
      props.fetchClients().then(() => this.setState({ clientsLoaded: true }));
    }

    this.handleSelect = this.handleSelect.bind(this);
    this.getStudentsMap = this.getStudentsMap.bind(this);
    this.openDeclineModal = this.openDeclineModal.bind(this);
    this.closeDeclineModal = this.closeDeclineModal.bind(this);
    this.updateDeclineReason = this.updateDeclineReason.bind(this);
    this.acceptContract = this.acceptContract.bind(this);
    this.declineContract = this.declineContract.bind(this);
  }

  componentDidMount() {
    this.props.fetchLanguages();
  }

  getStudentsMap(students) {
    const studentMap = {};
    this.props.allStudents
      .get(students)
      .toJS()
      .forEach((student) => {
        if (!studentMap[student.userId]) {
          studentMap[student.userId] = student;
        } else {
          studentMap[student.userId].subjects = [...studentMap[student.userId].subjects, student.subjects[0]];
        }
      });
    return Object.values(studentMap);
  }

  handleSelect(studentsTab) {
    const { history, setClientsFetched } = this.props;

    history.push(`/students/${studentsTab}`);

    if (setClientsFetched) {
      setClientsFetched(false);
    }
  }

  acceptContract(contractId) {
    this.props.acceptContract(contractId);
    this.closeDeclineModal();
  }

  declineContract() {
    const { contractId, declineReason } = this.state;
    if (declineReason) {
      this.props.declineContract(contractId, declineReason);
      this.closeDeclineModal();
    } else {
      this.setState({ validationError: true });
    }
  }

  openDeclineModal(contractId, subject) {
    this.setState({ declineModal: true, contractId, subject });
  }

  closeDeclineModal() {
    this.setState({ declineModal: true, contractId: null, subject: null, declineReason: '' });
  }

  updateDeclineReason(val) {
    this.setState({ declineReason: val });
  }

  render() {
    const { allStudents, match } = this.props;
    const { declineModal, contractId, subject, declineReason, validationError, clientsLoaded } = this.state;

    const currentStudents = this.getStudentsMap('current');
    let unavailableStudents = this.getStudentsMap('unavailable');
    const pendingStudents = allStudents.get('pending').toJS();
    const pastStudents = this.getStudentsMap('past');
    const studentsTab = match.params.studentsTab || 'current';

    for (const value of unavailableStudents.values()) {
      unavailableStudents = value;
    }
    // https://crimsonconsulting.atlassian.net/browse/PCC-1398
    // Tutor allocation - not removed from pending list with accepting allocation
    // if (unavailableStudents.subjects) {
    //   unavailableStudents.subjects.forEach((subject) => {
    //     if (subject.studentSubjects.status === contractStatusTypes.RETRACTED && subject.studentSubjects.hasResponded === false) {
    //       pendingStudents.push(subject.studentSubjects);
    //     }
    //   });
    // }
    return (
      <div data-ga-category="Students" className={css.contents}>
        <Tabs
          selectedIndex={match.params.studentsTab && renderedTabs.indexOf(studentsTab)}
          onSelect={(index) => match.params.studentsTab && this.handleSelect(renderedTabs[index])}
        >
          <TabList className={css.tabList}>
            <Tab
              className={css.tabName}
              title={`Current (${currentStudents.length})`}
              dataTestId="currentStudentsTab"
            />
            <div className={css.vertical}>|</div>
            <Tab
              className={css.tabName}
              title={`Pending (${pendingStudents.length})`}
              dataTestId="pendingStudentsTab"
            />
            <div className={css.vertical}>|</div>
            <Tab className={css.tabName} title="Past" dataTestId="pastStudentsTab" />
          </TabList>
          {clientsLoaded ? (
            <Fragment>
              <TabPanel>
                <div className={css.cards}>
                  {currentStudents.map((student) => (
                    <StudentCard user={student} key={student.userId} />
                  ))}
                  {!currentStudents.length ? <CurrentEmptyScreen /> : null}
                </div>
              </TabPanel>
              <TabPanel>
                <div className={css.cards}>
                  {pendingStudents.map((student, index) => (
                    <StudentCard
                      user={student}
                      key={`${student.userId}-${student.subjects[0].subjectId}-${index}`}
                      parentSubject={student.subjects}
                      isPending
                      onAccept={() => this.acceptContract(student.contractId)}
                      onDecline={() => this.openDeclineModal(student.contractId, student.subjects[0])}
                    />
                  ))}
                  {!pendingStudents.length ? <PendingEmptyScreen showButton={!!currentStudents.length} /> : null}
                </div>
              </TabPanel>
              <TabPanel>
                <div className={css.cards}>
                  {pastStudents.map((student) => (
                    <StudentCard isPast user={student} key={student.userId} />
                  ))}
                  {!pastStudents.length ? <PastEmptyScreen /> : null}
                </div>
              </TabPanel>
            </Fragment>
          ) : (
            <Fragment>
              <LoadingIndicator className={css.loading} />
              <TabPanel />
              <TabPanel />
              <TabPanel />
            </Fragment>
          )}
        </Tabs>
        <DeclineContractModal
          isOpen={declineModal}
          contractId={contractId}
          onClose={this.closeDeclineModal}
          onDecline={this.declineContract}
          onReasonChange={this.updateDeclineReason}
          isError={validationError}
          declineReason={declineReason}
          subject={subject}
        />
      </div>
    );
  }
}

Students.propTypes = {
  allStudents: PropTypes.object,
  fetchClients: PropTypes.func,
  fetchLanguages: PropTypes.func,
  history: PropTypes.object,
  setClientsFetched: PropTypes.func,
  acceptContract: PropTypes.func,
  declineContract: PropTypes.func,
  match: PropTypes.object,
};

PendingEmptyScreen.propTypes = {
  showButton: PropTypes.bool,
};

export default Students;
