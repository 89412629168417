const processResponse = (response) => {
  const status = response.status;
  const contentType = response.headers.get('content-type');
  if (contentType && contentType.indexOf('application/json') !== -1) {
    if (status >= 400 && response.status < 600) {
      return response.json().then((json) => {
        throw json;
      });
    }
    return response.json();
  }

  return response.text();
};

export function get(url) {
  return fetch(url, {
    mode: 'cors',
  }).then((res) => processResponse(res));
}

export function put(url, data, headers) {
  return fetch(url, {
    method: 'put',
    headers,
    mode: 'cors',
    body: data,
  }).then((res) => processResponse(res));
}

export function post(url, jsonData, headers) {
  return fetch(url, {
    headers: Object.assign(
      {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      headers,
    ),
    method: 'post',
    mode: 'cors',
    body: JSON.stringify(jsonData),
  }).then((res) => processResponse(res));
}
