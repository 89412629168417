import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Acl, roleTypes, eventStatusTypes } from '@crimson-education/common-config';
import componentKeys from 'constants/componentKeys';

import SessionSummaryReport from 'components/organisms/SessionSummaryReport';
import TaskList from 'components/pages/Tasks/TaskList';
import SessionVideoLink from 'components/organisms/SessionVideoLink';
import Button from 'components/molecules/Button';
import Card from 'components/molecules/Card';
import IconButton from 'components/molecules/IconButton';

import { getBooking, fetchEventForSessionSummary } from 'ducks/booking';
import { fetchLessonsByEventId } from 'ducks/lesson';
import { fetchReasonsForCancellation, fetchSessionReportByEventId } from 'ducks/meta';

import { setAgendaItem } from 'ducks/agenda';
import { setNotes } from 'ducks/session';

import { getSessionStudentId, getSelectedBooking, getEventUserIdForEvent } from 'selectors/booking';
import { getLessonSelected } from 'selectors/lesson';
import { getMetaItem, getSessionReport } from 'selectors/meta';
import { getLoginUser } from 'selectors/user';
import Modal from 'components/molecules/Modal';
import Agenda from './Agenda/Agenda';
import Notes from './SessionNotes/Notes';
import SwitchButton from './SwitchButton';
import css from './styles.scss';
import { featureSwitches, useFeatureFlag } from 'featureSwitches';
import {
  getFloatingSessionContext,
  setFloatingSessionContext,
  setSessionItem,
  getEventParams,
  trackFloatingSessionEvent,
} from 'components/pages/FloatingSession/index.util';
import FloatingSession from 'components/pages/FloatingSession';
import { popupModalInfo } from 'components/pages/Modal/commonModal';
import { useLocation } from 'react-router-dom';
import MissionControlUpdates from 'components/organisms/MissionControlUpdates';
import GetUpdatesButton from 'components/organisms/MissionControlUpdates/GetUpdatesButton';
import { withMissionControlUpdatesContext } from 'components/organisms/MissionControlUpdates/viewController';
function SessionAgendaNew(props) {
  const FLOATING_SESSION = featureSwitches.FLOATING_SESSION();
  const MISSION_CONTROL_UPDATES = useFeatureFlag('MISSION_CONTROL_UPDATES');
  const {
    match: { params },
    onChangeSessionData,
    history,
    isModalOpen,
    setModalOpen,
    leaveAnyway,
    bookAsFirstName,
    bookAsId,
  } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const session = useSelector(getSelectedBooking);
  const loginUser = useSelector(getLoginUser).toJS();
  const activeSessionId = useSelector(getMetaItem(componentKeys.SESSION_SELECTED_ID));
  const { sessionId } = getFloatingSessionContext();
  const [floatingVisible, setFloatingVisible] = useState(!!sessionId);

  const [toggleFloating, setToggleFloating] = useState();
  const F_TOGGLE = () => {
    setToggleFloating((toggle) => !toggle);
  };

  useEffect(() => {
    if (location?.state?.floatingVisible !== undefined) {
      const { sessionId } = getFloatingSessionContext();
      if (sessionId) {
        setFloatingSessionContext({ sessionId: '' });
        F_TOGGLE();
        setFloatingVisible(false);
      } else {
        setFloatingVisible(location?.state?.floatingVisible);
      }
      setRefreshAgenda(true);
      setRefreshNotes(true);
    } else {
      setSessionItem({
        sessionId: activeSessionId,
        type: 'notes',
        item: {
          notes: {
            reFetch: true,
          },
        },
      });
      setSessionItem({
        sessionId: activeSessionId,
        type: 'agenda',
        item: {
          agenda: {
            reFetch: true,
          },
        },
      });
      const { sessionId } = getFloatingSessionContext();
      if (sessionId) {
        onOpenFloatingWindow(sessionId);
      }
    }
  }, [location.state, activeSessionId]);
  const lesson = useSelector(getLessonSelected);
  const studentId = useSelector(getSessionStudentId);
  const sessionReport = useSelector(getSessionReport);
  const eventUserId = useSelector(getEventUserIdForEvent);

  const [isAgendaDraft, setIsAgendaDraft] = useState(false);
  const [refreshAgenda, setRefreshAgenda] = useState(false);
  const [isEditConflictModalOpen, setIsEditConflictModalOpen] = useState(false);
  const [isAgendaSaved, setIsAgendaSaved] = useState(false);
  const [isNotesDraft, setIsNotesDraft] = useState(false);
  const [refreshNotes, setRefreshNotes] = useState(false);
  const [isNotesSaved, setIsNotesSaved] = useState(false);
  const [isSavingAgenda, setIsSavingAgenda] = useState(false);
  const [isSavingNotes, setIsSavingNotes] = useState(false);
  const [editedAgenda, setEditedAgenda] = useState({});
  const [agendaVersion, setAgendaVersion] = useState(0);
  const [editedNote, setEditedNote] = useState({});
  const [noteVersion, setNoteVersion] = useState(0);
  const isStudent = Acl.checkRole(loginUser.userRoles, roleTypes.STUDENT);
  const eventIdToString = activeSessionId ? activeSessionId.toString() : null;
  // Permissions
  const canViewAgenda = Acl.checkPermission(loginUser.permissions, 'AGENDA:VIEW');
  const canEditAgenda = Acl.checkPermission(loginUser.permissions, 'AGENDA:EDIT');
  const canViewNotes = Acl.checkPermission(loginUser.permissions, 'SESSION_NOTES:VIEW');
  const canEditNotes = Acl.checkPermission(loginUser.permissions, 'SESSION_NOTES:EDIT');
  const canViewTasks = Acl.checkPermission(loginUser.permissions, isStudent ? 'VIEW_OWN_TASKS' : 'VIEW_ALL_TASKS');
  const canEditTasks = Acl.checkPermission(loginUser.permissions, isStudent ? 'EDIT_OWN_TASKS' : 'EDIT_ALL_TASKS');
  const canDeleteTasks = Acl.checkPermission(
    loginUser.permissions,
    isStudent ? 'DELETE_OWN_TASKS' : 'DELETE_ALL_TASKS',
  );

  const setDraft = () => {
    setIsAgendaDraft(true);
    setIsAgendaSaved(false);
    onChangeSessionData(true);
  };

  const setNotesDraft = () => {
    setIsNotesDraft(true);
    setIsNotesSaved(false);
    onChangeSessionData(true);
  };

  const recoverAgenda = () => {
    setIsAgendaDraft(false);
    setRefreshAgenda(false);
    onChangeSessionData(isNotesDraft);
  };

  const recoverNotes = () => {
    setIsNotesDraft(false);
    setRefreshNotes(false);
    onChangeSessionData(isAgendaDraft);
  };

  async function fetchData(loginUser, params) {
    fetchEventForSessionSummary(params.sessionId);
    fetchReasonsForCancellation(loginUser.userId, params.sessionId);
    if (!Acl.checkRole(loginUser.userRoles, roleTypes.STUDENT)) {
      fetchLessonsByEventId(params.sessionId);
    }
    if (
      !Acl.checkRole(loginUser.userRoles, roleTypes.STUDENT) &&
      !Acl.checkRole(loginUser.userRoles, roleTypes.TUTOR)
    ) {
      fetchSessionReportByEventId(params.sessionId);
    }
  }

  useEffect(() => {
    fetchData(loginUser, params);
  }, [params, loginUser]);

  async function fetchBookingData(activeSessionId, loginUser, eventUserId) {
    getBooking(activeSessionId, eventUserId);
    fetchReasonsForCancellation(loginUser.userId, activeSessionId);
  }

  useEffect(() => {
    if (
      !activeSessionId ||
      (session && [eventStatusTypes.DELETED, eventStatusTypes.CANCELLED].includes(session.status))
    ) {
      history.push('/calendar');
      return;
    }
    fetchBookingData(activeSessionId, loginUser, eventUserId);
  }, [activeSessionId, loginUser, eventUserId, session, history]);

  const saveAllNotes = async (overwrite, isAgenda, isNotes) => {
    let hasError = false;
    if (isAgenda) {
      const { id: agendaId } = JSON.parse(localStorage.getItem(`event-agenda-${activeSessionId}`) || '{}');
      setIsSavingAgenda(true);
      const agendaRes = await dispatch(
        setAgendaItem(activeSessionId, agendaId, editedAgenda.text, editedAgenda.html, agendaVersion || 0, overwrite),
      );
      if (agendaRes.error && agendaRes.message === 'The note has been updated by others.') {
        hasError = true;
      } else if (!agendaRes.error) {
        const { html, text, version } = agendaRes;
        localStorage.setItem(
          `event-agenda-${activeSessionId}`,
          JSON.stringify({ agenda: { html, text }, id: agendaRes.id, version, isDraft: false, updatedAt: new Date() }),
        );
        setAgendaVersion(version);
        setIsAgendaDraft(false);
        setIsAgendaSaved(true);
        setIsSavingAgenda(false);
        onChangeSessionData(isNotesDraft);
      }
    }
    if (isNotes) {
      setIsSavingNotes(true);
      const notesRes = await dispatch(setNotes(activeSessionId, editedNote, noteVersion || 0, overwrite));
      if (notesRes.error && notesRes.message === 'The note has been updated by others.') {
        hasError = true;
      } else if (!notesRes.error) {
        const { html, version, text } = notesRes;
        localStorage.setItem(
          `event-notes-${activeSessionId}`,
          JSON.stringify({ notes: { html, text }, version, isDraft: false, updatedAt: new Date() }),
        );
        setNoteVersion(version);
        setIsNotesDraft(false);
        setIsNotesSaved(true);
        setIsSavingNotes(false);
        onChangeSessionData(isAgendaDraft);
      }
    }
    if (hasError) {
      setIsEditConflictModalOpen(true);
    } else {
      setIsEditConflictModalOpen(false);
      if (isAgenda && isNotes) {
        onChangeSessionData(false);
      }
    }
  };

  const saveAnyway = () => {
    saveAllNotes(true, isSavingAgenda, isSavingNotes);
  };

  const saveAllNote = () => {
    saveAllNotes(false, isSavingAgenda, isSavingNotes);
    setModalOpen(false);
  };

  const leaveWithoutSave = () => {
    leaveAnyway();
  };

  const floatingWindowRef = useRef(null);

  const onOpenFloatingWindow = async (activeSessionId) => {
    const { sessionId } = getFloatingSessionContext();
    if (document.getElementsByClassName('crimson-app-floating-modal').length === 0) {
      if (!sessionId) {
        setFloatingSessionContext({ sessionId: `${activeSessionId}`, bookAsFirstName, bookAsUserId: bookAsId });
        setFloatingVisible(true);
      }
      F_TOGGLE();
      trackFloatingSessionEvent({
        key: 'Open',
        metadata: getEventParams({
          userId: loginUser?.userId,
          url: history.location.pathname,
        }),
      });
      floatingWindowRef.current = popupModalInfo({
        width: '0px',
        wrapClassName: 'crimson-app-floating-modal component-crimson-app-modal',
        zIndex: 1600,
        maskClosable: false,
        mask: false,
        keyboard: false,
        getContainer: () => document.getElementById('floating-session-root'),
        content: (
          <FloatingSession
            getParams={() => {
              return {
                userId: loginUser?.userId,
                url: history.location.pathname,
              };
            }}
            onUnFloating={(changeHistory) => {
              floatingWindowRef.current?.destroy();
              if (!changeHistory) {
                setFloatingVisible(false);
                setFloatingSessionContext({ sessionId: '' });
                F_TOGGLE();
                if (history.location.pathname.includes('/session/') && history.location.pathname.includes('/agenda')) {
                  history.push(history.location.pathname, {
                    floatingVisible: false,
                  });
                }
              } else {
                const { sessionId } = getFloatingSessionContext();
                setFloatingSessionContext({ sessionId: '' });
                if (sessionId) {
                  history.push(`/session/${sessionId}/agenda`, {
                    floatingVisible: false,
                  });
                }
              }
            }}
          />
        ),
      });
    }
  };
  return (
    <div>
      <div className={css.session} data-ga-category="Session">
        <div className={css.sessionContent}>
          <div className={css.info}>
            <IconButton className={css.infoIcon} icon="Info" />
            <div>Please save each section regularly to avoid a conflict with changes made by other users.</div>
          </div>
          {FLOATING_SESSION && canViewAgenda && canViewNotes && (
            <div
              style={{
                position: 'fixed',
                right: '8%',
              }}
            >
              <SwitchButton
                eventId={eventIdToString}
                onSwitch={() => onOpenFloatingWindow(eventIdToString)}
                toggleFloating={toggleFloating}
              />
            </div>
          )}
          {session.status === eventStatusTypes.CONFIRMED && <SessionVideoLink session={session} />}
          {canViewAgenda && (
            <Card className={css.card}>
              <div className={css.sessionHeader}>
                <h1>Session agenda</h1>
                {!floatingVisible && isAgendaDraft ? <span>Drafting...</span> : null}
                {!floatingVisible && isAgendaDraft ? (
                  <div className={css.action}>
                    <Button
                      simple
                      className={css.cancelButton}
                      buttonTextStyle={css.cancelButtonText}
                      dataTestId="sessionAgendaCancelButton"
                      onClick={() => setRefreshAgenda(true)}
                    >
                      Cancel
                    </Button>
                    <Button
                      simple
                      primary
                      className={css.saveButton}
                      dataTestId="sessionAgendaSaveButton"
                      onClick={() => saveAllNotes(false, true, false)}
                    >
                      Save
                    </Button>
                  </div>
                ) : null}
                {!floatingVisible && isAgendaSaved ? (
                  <div className={css.action}>
                    <Button
                      simple
                      className={css.savedButton}
                      buttonTextStyle={css.savedButtonText}
                      dataTestId="sessionAgendaSavedButton"
                    >
                      Saved
                    </Button>
                  </div>
                ) : null}
              </div>
              <Agenda
                eventId={eventIdToString}
                canEdit={canEditAgenda}
                setDraft={setDraft}
                toggleFloating={toggleFloating}
                refreshAgenda={refreshAgenda}
                recoverAgenda={recoverAgenda}
                setEditedAgenda={setEditedAgenda}
                setAgendaVersion={(version) => {
                  setAgendaVersion(version);
                  setIsSavingAgenda(true);
                }}
                agendaVersion={agendaVersion}
              />
            </Card>
          )}
          {canViewNotes && (
            <Card className={css.card}>
              <div className={css.sessionHeader}>
                <h1>Notes</h1>
                {!floatingVisible && isNotesDraft ? <span>Drafting...</span> : null}
                {!floatingVisible && isNotesDraft ? (
                  <div className={css.action}>
                    <Button
                      simple
                      className={css.cancelButton}
                      buttonTextStyle={css.cancelButtonText}
                      dataTestId="sessionNotesCancelButton"
                      onClick={() => setRefreshNotes(true)}
                    >
                      Cancel
                    </Button>
                    <Button
                      simple
                      primary
                      className={css.saveButton}
                      dataTestId="sessionNotesSaveButton"
                      onClick={() => saveAllNotes(false, false, true)}
                    >
                      Save
                    </Button>
                  </div>
                ) : null}
                {!floatingVisible && isNotesSaved ? (
                  <div className={css.action}>
                    <Button
                      simple
                      className={css.savedButton}
                      buttonTextStyle={css.savedButtonText}
                      dataTestId="sessionNotesSavedButton"
                    >
                      Saved
                    </Button>
                  </div>
                ) : null}
                {MISSION_CONTROL_UPDATES && <GetUpdatesButton />}
              </div>
              <Notes
                eventId={activeSessionId}
                userId={loginUser.userId}
                canEdit={canEditNotes}
                setDraft={setNotesDraft}
                toggleFloating={toggleFloating}
                refreshNotes={refreshNotes}
                recoverNotes={recoverNotes}
                setEditedNote={setEditedNote}
                setNoteVersion={(version) => {
                  setNoteVersion(version);
                  setIsSavingNotes(true);
                }}
                noteVersion={noteVersion}
              />
            </Card>
          )}

          {!floatingVisible && canViewTasks && studentId && (
            <Card className={css.card}>
              <h1>Student&rsquo;s tasks</h1>
              <TaskList
                userId={studentId}
                eventId={activeSessionId}
                simple
                canAddNew={canEditTasks}
                canEdit={canEditTasks}
                canDelete={canDeleteTasks}
              />
            </Card>
          )}
          {!floatingVisible && lesson && !isStudent && (
            <SessionSummaryReport report={lesson.report} tutor={lesson.client} />
          )}
          {!floatingVisible && sessionReport && !isStudent && <SessionSummaryReport report={sessionReport.toJS()} />}
        </div>
      </div>
      {MISSION_CONTROL_UPDATES && <MissionControlUpdates />}
      <Modal
        isOpen={isEditConflictModalOpen}
        onSubmit={saveAnyway}
        onClose={() => setIsEditConflictModalOpen(false)}
        title="Editing conflict"
        submitText="Save Anyway"
        secondarySubmitText="Go Back"
        onSecondarySubmit={() => setIsEditConflictModalOpen(false)}
        customButtonGroupStyle={css.modalFooter}
        secondButtonStyle={css.secondButtonStyle}
        onSubmitDataTestId="saveAnyway"
        onSecondarySubmitDataTestId="goBack"
      >
        <div className={css.editConflict}>
          <p>This section has been updated by another user. Saving will overwrite these changes.</p>
          <p>
            You may want to go back and copy the content to your computer and check for updates before making changes.
          </p>
        </div>
      </Modal>
      <Modal
        isOpen={isModalOpen}
        onSubmit={saveAllNote}
        onClose={() => setModalOpen(false)}
        title="Leave Without Saving?"
        submitText="Save"
        onSecondarySubmit={leaveWithoutSave}
        secondarySubmitText="Leave Anyway"
        mainContentClass={css.modalContent}
        customButtonGroupStyle={css.modalFooter}
        secondButtonStyle={css.secondButtonStyle}
        onSubmitDataTestId="saveAllNotes"
        onSecondarySubmitDataTestId="leaveAnyway"
      >
        <div>
          Are you sure you want to leave the page
          <br />
          without saving?
        </div>
      </Modal>
    </div>
  );
}

export default withMissionControlUpdatesContext(SessionAgendaNew);
SessionAgendaNew.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  onChangeSessionData: PropTypes.func,
  isModalOpen: PropTypes.bool,
  setModalOpen: PropTypes.func,
  leaveAnyway: PropTypes.func,
  bookAsFirstName: PropTypes.string,
  bookAsId: PropTypes.string,
};
