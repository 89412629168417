import { connect } from 'react-redux';
import { selectUserRoles, selectUserPermissions, getLessonsFetched } from 'selectors/meta';
import { getPackageItem } from 'selectors/packageItem';
import { getLessonsByItemId } from 'selectors/lesson';
import { getCurrentUser } from 'selectors/user';
import { fetchPackageItemById } from 'ducks/packageItem';
import { fetchLessonsBySubjectId } from 'ducks/lesson';
import { showPageLoader } from 'ducks/meta';
import { selectBookingAs } from 'ducks/user';
import MyLessons from './MyLessons';

const mapStateToProps = (state, ownProps) => ({
  allLessons: getLessonsByItemId(ownProps.match.params.itemId)(state),
  loginUserRoles: selectUserRoles(state),
  loginUserPermissions: selectUserPermissions(state),
  packageItem: getPackageItem(ownProps.match.params.itemId)(state),
  currentUser: getCurrentUser(state),
  lessonsFetched: getLessonsFetched(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchPackageItem: showPageLoader(() => dispatch(fetchPackageItemById(ownProps.match.params.itemId)), dispatch),
  fetchLessons: showPageLoader(
    () => dispatch(fetchLessonsBySubjectId(ownProps.match.params.itemId, { withPenalty: true })),
    dispatch,
  ),
  selectBookingAs: (userId) => dispatch(selectBookingAs(userId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyLessons);
