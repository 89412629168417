import React, { useMemo } from 'react';
import { Row } from '../../../../index.style';
import { IconWithTooltip } from '../../../../components';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import SmsOutlinedIcon from '@material-ui/icons/SmsOutlined';
import DropdownMenu from 'components/organisms/ApplicationTracker/Dropdown/DropdownMenu';
import { ServiceStatus, BaseCell } from '../../index.style';
import { useStaffDashboardContext } from '../../../../helper';
import { STUDENT_LIST_SERVICE_OPTIONS } from '../../../../constants';
import { StudentInList } from '../../../../type';
import { useRequesterUserId } from '../../../../../helper';
import { getUserDisplayName } from 'components/organisms/ApplicationTracker/StaffDashboard/util';

type ActionCellProps = {
  student: StudentInList;
};
const ActionCell = ({ student }: ActionCellProps) => {
  const { overviewId, region, userId } = student;
  const { state, controller } = useStaffDashboardContext();
  const { overviews } = state;
  const OPTIONS = useMemo(() => STUDENT_LIST_SERVICE_OPTIONS.filter((o) => o.available.includes(region)), [region]);
  const overview = useMemo(() => overviews.find((o) => o.id === overviewId), [overviews, overviewId]);
  const currentUserId = useRequesterUserId();
  return (
    <BaseCell style={{ paddingRight: 15 }}>
      <Row rowcenter>
        <DropdownMenu
          data={OPTIONS}
          onClickItem={(serviceStatus) =>
            controller.createOrUpdateServiceStatus(userId, region, serviceStatus, overviewId)
          }
          wrapperStyle={{ width: '100%' }}
          menuItemStyle={{ width: 230 }}
        >
          <ServiceStatus>{overview?.serviceStatus || 'In Progress'}</ServiceStatus>
        </DropdownMenu>
        <Row style={{ justifySelf: 'end', alignSelf: 'end', position: 'relative' }} gap={'20px'}>
          <IconWithTooltip
            hint={'Crimson Team'}
            icon={<PeopleAltOutlinedIcon />}
            onClick={controller.onClickTeamMember(userId)}
          />
          <IconWithTooltip
            hint={'Session Notes'}
            icon={<DescriptionOutlinedIcon />}
            onClick={() => controller.onClickSessionNotes(userId)}
          />
          <IconWithTooltip
            hint={'Message'}
            icon={<SmsOutlinedIcon />}
            onClick={() => controller.onClickMessage(currentUserId, userId, getUserDisplayName(student))}
          />
        </Row>
      </Row>
    </BaseCell>
  );
};

export default ActionCell;
