import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/molecules/Modal';

export default class ConfirmationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: props.isOpen,
    };

    this.handleConfirm = this.handleConfirm.bind(this);
  }

  handleConfirm() {
    this.props.onConfirm();
    this.setState({
      modalIsOpen: false,
    });
  }

  render() {
    const {
      buttonClassName,
      buttonText,
      buttonIcon,
      actionText,
      header,
      beforeOpen,
      dataTestId,
      buttonDisabled,
      confirmLabel,
      secondarySubmitText,
    } = this.props;

    return (
      <div>
        <Modal
          isOpen={this.state.modalIsOpen}
          onSubmit={() => this.handleConfirm()}
          onClose={() => this.setState({ modalIsOpen: false })}
          title={header}
          onSubmitDataTestId={dataTestId}
          submitText={confirmLabel}
          secondarySubmitText={secondarySubmitText}
          onSecondarySubmit={() => this.setState({ modalIsOpen: false })}
        >
          <div>{actionText}</div>
        </Modal>
        <button
          className={buttonClassName}
          data-test-id={dataTestId}
          onClick={() => {
            // If the validation method doesn't return true, prevent the modal from opening;
            if (beforeOpen && !beforeOpen()) return;
            // Otherwise open the modal.
            this.setState({ modalIsOpen: true });
          }}
          disabled={buttonDisabled}
        >
          {buttonIcon}
          {buttonText}
        </button>
      </div>
    );
  }
}

ConfirmationModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
  buttonIcon: PropTypes.object,
  buttonDisabled: PropTypes.bool,
  header: PropTypes.string,
  actionText: PropTypes.string,
  isOpen: PropTypes.bool,
  buttonClassName: PropTypes.string,
  confirmLabel: PropTypes.string,
  beforeOpen: PropTypes.func,
  dataTestId: PropTypes.string,
  secondarySubmitText: PropTypes.string,
};

ConfirmationModal.defaultProps = {
  isOpen: false,
  buttonDisabled: false,
};
