const userRoles = {
  STUDENT: 'student',
  TUTOR: 'tutor',
  EDUCATION_COORDINATOR: 'educationCoordinator',
  STRATEGIST: 'strategist',
  HEAD_TUTOR: 'headTutor',
  OPERATIONAL_SUPPORT: 'operationalSupport',
  SUPER_ADMIN: 'superAdmin',
};

export default userRoles;
