import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';

import NotificationToggler from './NotificationToggler';
import NotificationWrapper from './NotificationWrapper';
import css from './styles.scss';

class Notification extends Component {
  constructor() {
    super();

    this.state = {
      wrapperIsOpen: false,
    };

    this.toggleNotificationWrapper = this.toggleNotificationWrapper.bind(this);
    this.onClickMobile = this.onClickMobile.bind(this);
  }

  onClickMobile() {
    this.props.history.push('/notifications');
  }

  toggleNotificationWrapper(wrapperState) {
    const { unseen, clearNotifications } = this.props;
    const { wrapperIsOpen } = this.state;

    const newState = typeof wrapperState === 'undefined' ? !wrapperIsOpen : !!wrapperState;

    if (newState && unseen) {
      clearNotifications();
    }
    this.setState({ wrapperIsOpen: newState });
  }

  render() {
    const { notifications, hasMore, unseen, className, loadNotifications, setNotificationRead, mobile } = this.props;
    const { wrapperIsOpen } = this.state;

    return (
      <div className={classNames(className, css.container, 'ignore-react-onclickoutside')}>
        <NotificationToggler
          unseen={unseen}
          onClick={() => {
            mobile ? this.onClickMobile() : this.toggleNotificationWrapper();
          }}
          className={css.toggler}
          wrapperIsOpen={wrapperIsOpen}
          mobile={mobile}
        />
        <NotificationWrapper
          isOpen={wrapperIsOpen}
          unseen={unseen}
          notifications={notifications}
          hasMore={hasMore}
          className={css.wrapper}
          onClickOutside={() => {
            this.toggleNotificationWrapper(false);
          }}
          toggleNotificationWrapper={this.toggleNotificationWrapper}
          onScrollToBottom={loadNotifications}
          setNotificationRead={setNotificationRead}
        />
      </div>
    );
  }
}

Notification.displayName = 'Notification';

Notification.propTypes = {
  notifications: ImmutablePropTypes.map.isRequired,
  hasMore: PropTypes.bool.isRequired,
  unseen: PropTypes.number.isRequired,
  className: PropTypes.string,
  mobile: PropTypes.bool,
  history: PropTypes.object,
  loadNotifications: PropTypes.func.isRequired,
  clearNotifications: PropTypes.func.isRequired,
  setNotificationRead: PropTypes.func.isRequired,
};

export default withRouter(Notification);
