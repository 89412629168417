import Immutable from 'immutable';
import { createSelector } from 'reselect';
import { allUsers } from './user';

function getTotalStudentsForTutor(tutor) {
  const subjects = tutor.getIn(['tutorInfo', 'subjects']);

  let studentCount = 0;
  if (subjects) {
    studentCount = subjects.reduce((total, subject) => {
      return total + parseInt(subject.get('studentCount'), 10);
    }, 0);
  }

  // Add a total student count to the user's tutorInfo.
  return tutor.mergeDeep({ tutorInfo: { studentCount } });
}

const tutorSubject = (state) => state.get('tutorSubject');

export const getAllTutorsOfSubject = (subjectId) =>
  createSelector(tutorSubject, allUsers, (tutorSubject, users) => {
    const tutorIds = tutorSubject.getIn([subjectId, 'tutors']);
    if (!tutorIds) return new Immutable.List();
    return tutorIds.map((tutorId) => users.get(tutorId)).map((tutor) => getTotalStudentsForTutor(tutor));
  });

export const getSubjectsOfTutors = createSelector(tutorSubject, (tS) => {
  const orderedSubjects = tS.sort((a, b) => (a.get('name') ? a.get('name').localeCompare(b.get('name')) : 0));

  return Immutable.fromJS(
    orderedSubjects.reduce((tutors, subject) => {
      subject.get('tutors').forEach((tutorId) => {
        tutors[tutorId] = tutors[tutorId] || [];
        tutors[tutorId].push(subject);
      });
      return tutors;
    }, {}),
  );
});
