import { InAppMessageTypes } from '@crimson-education/common-config';

const ACADEMIC = 'zmdi-graduation-cap';
const ACCOUNT = 'zmdi-account';
const CALENDAR = 'zmdi-calendar-alt';
const COMMENT_ALERT = 'zmdi-comment-alert';
const NOTIFICATION = 'zmdi-notifications-active';
const LESSON = 'zmdi-comment-text';
const FEEDBACK = 'zmdi-comment-alert';
const REPORT = 'zmdi-file-text';
const ROADMAP = 'zmdi-map';

export function getRedirectUrl(notifType) {
  // todo figure out the redirects to other things
  switch (notifType) {
    case InAppMessageTypes.USER:
      return '/calendar';
    case InAppMessageTypes.CALENDAR:
    case InAppMessageTypes.BOOKING:
      return '/calendar';
    default:
      return '/calendar';
  }
}

/**
 * The returned string from this function is to be
 * an instance name of the ZMDI icon font. This will
 * allow the string to be used directly in the className
 * after the zmdi- tag to apply a specific icon.
 */
export function returnNotificationIcon(notifType) {
  switch (notifType) {
    case InAppMessageTypes.USER:
      return ACCOUNT;
    case InAppMessageTypes.CALENDAR:
    case InAppMessageTypes.BOOKING:
      return CALENDAR;
    case InAppMessageTypes.WARNING:
      return COMMENT_ALERT;
    case InAppMessageTypes.LESSON:
      return LESSON;
    case InAppMessageTypes.FEEDBACK:
      return FEEDBACK;
    case InAppMessageTypes.ACADEMIC:
      return ACADEMIC;
    case InAppMessageTypes.REPORT:
      return REPORT;
    case InAppMessageTypes.ROADMAP:
      return ROADMAP;
    default:
      return NOTIFICATION;
  }
}
