import styled from 'styled-components';

export const MenuItem = styled.div`
  cursor: pointer;
  margin: 0 1px;
  min-height: 31px;
  width: 165px;
  padding: 0 16px;
  display: flex;
  white-space: pre-wrap;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  &:hover {
    background: #f3f6fa;
  }
  & div {
    font-weight: 500;
    font-size: 12px;
    font-family: 'Montserrat';
    color: #73747d;
  }
`;

export const Container = styled.div`
  padding: 7px 0;
  max-height: 175px;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: #a9acc0;
    width: 5px;
    border-radius: 4px;
  }
`;
