import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';
import { gql } from '@apollo/client';
import { createClient } from '../utils';
import { subjectType } from './common/types';

const { api } = getConfig();

const client = createClient(api.graphQLEndpoint);

const getSubjectCategoriesByParentId = gql`
  query getSubjectCategoriesByParentId($parentIds: [String]) {
    getSubjectCategories(parentIds: $parentIds) {
      id
      name
      parentId
      isLeaf
    }
  }
`;

const getDescendantsOfSubjectCategories = gql`
  ${subjectType}
  query getDescendantsOfSubjectCategories($subjectCategoryIds: [String]) {
    getDescendantsOfSubjectCategories(subjectCategoryIds: $subjectCategoryIds) {
      ...subjectType
    }
  }
`;

const getAncestorsOfSubjectCategories = gql`
  ${subjectType}
  query getAncestorsOfSubjectCategories($subjectCategoryIds: [String]) {
    getAncestorsOfSubjectCategories(subjectCategoryIds: $subjectCategoryIds) {
      ...subjectType
    }
  }
`;

export default {
  getSubjectCategoriesByParentId: (parentIds) => client.query(getSubjectCategoriesByParentId, { parentIds }),
  getDescendantsOfSubjectCategories: (subjectCategoryIds) =>
    client.query(getDescendantsOfSubjectCategories, { subjectCategoryIds }),
  getAncestorsOfSubjectCategories: (subjectCategoryIds) =>
    client.query(getAncestorsOfSubjectCategories, { subjectCategoryIds }),
};
