import React from 'react';
import { Row, StyledCheckbox } from '../../index.style';
import { OptionTextContainer } from './index.style';

type ContactOptionProps = {
  checked: boolean;
  onClick: () => void;
  title: string;
  subTitle: string;
};

const ContactOption = ({ checked, onClick, title, subTitle }: ContactOptionProps) => {
  return (
    <Row style={{ marginTop: 16, cursor: 'pointer' }} onClick={onClick}>
      <StyledCheckbox disableRipple disableFocusRipple disableTouchRipple checked={checked} />
      <OptionTextContainer>
        <div>{title}</div>
        <div>{subTitle || ''}</div>
      </OptionTextContainer>
    </Row>
  );
};

export default ContactOption;
