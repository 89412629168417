import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import InfiniteScroll from 'react-infinite-scroller';
import IconButton from 'components/molecules/IconButton';
import ReactAutocomplete from '@material-ui/lab/Autocomplete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import css from './style.scss';
import { asButton } from '../../../../utils/accessibility';
import { StyledTextField } from './style';
import TextField from '@material-ui/core/TextField';
import { useEffect } from 'react';

/**
 * This is a wrapper component for the original react-autocomplete component
 * For more info on how to use the props, go to
 * https://github.com/reactjs/react-autocomplete
 */

type AutoCompleteProps<T extends { label: string; id: string }> = {
  error?: boolean;
  disabled?: boolean;
  items: T[];
  loadMore: (page: number) => void;
  hasMore?: boolean;
  placeholder: string;
  customIcon?: JSX.Element;
  keySelector: keyof T;
  itemOutputFormat: (item: T) => any;
  renderItem?: (item: T) => JSX.Element;
  emptyText?: string;
  inputDataTestId?: string;
  multiItemDataTestId: (item: T) => string;
  onClear: () => void;
  dropIcon?: boolean;
  getItemValue: (item: T) => string;
  onChange?: (event: any) => void;
  onSelect?: (value: string, item: T | null) => void;
  value?: string;
};
const AutoComplete = <T extends { label: string; id: string }>({
  itemOutputFormat = (item) => item.label,
  loadMore,
  hasMore,
  disabled,
  emptyText,
  placeholder,
  customIcon,
  renderItem,
  keySelector = 'id',
  inputDataTestId,
  onClear,
  error,
  dropIcon,
  items,
  multiItemDataTestId,
  getItemValue,
  value: valueFromProps,
  onChange,
  onSelect,
  ...restProps
}: AutoCompleteProps<T>) => {
  const [isOpen, setOpen] = useState(false);
  const [value, setValue] = useState<T | null>(null);
  const [searchText, setSearchText] = useState(valueFromProps || '');
  const _onClear = () => {
    setSearchText('');
    onClear && onClear();
  };

  const clearButton = <IconButton onClick={_onClear} className={css.clearIcon} icon="Close" />;

  const defaultRenderer = (item: T) => {
    return (
      <div
        key={(item[keySelector] as unknown) as string}
        data-test-id={`DropdownOption_${multiItemDataTestId(item)}`}
        className={classNames(css.menuItem, { [css.highlighted]: false })}
      >
        {itemOutputFormat(item)}
      </div>
    );
  };
  return (
    <ReactAutocomplete
      // {...restProps}
      value={value}
      onChange={(event: any, newValue: T | null) => {
        onSelect && onSelect(searchText, newValue);
        setValue(newValue);
      }}
      inputValue={searchText}
      onInputChange={(event, newInputValue) => {
        onChange && onChange(event);
        setSearchText(newInputValue);
      }}
      options={items}
      getOptionLabel={getItemValue}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      openOnFocus
      open={isOpen}
      renderOption={renderItem || defaultRenderer}
      PaperComponent={({ children }) => {
        return (
          <div className={css.autocompleteMenu}>
            {!!children ? (
              <InfiniteScroll initialLoad={false} loadMore={loadMore} hasMore={hasMore} useWindow={false}>
                {children}
              </InfiniteScroll>
            ) : (
              <div className={css.noItems}>{emptyText || 'No result found.'}</div>
            )}
          </div>
        );
      }}
      renderInput={(inputProps) => {
        return (
          <TextField
            {...inputProps}
            className={classNames(
              css.autocompleteInputWrapper,
              css.autoCompleteInput,
              { [css.isOpen]: isOpen },
              { [css.disabled]: disabled },
              { [css.inputError]: error },
            )}
            data-test-id={inputDataTestId}
            disabled={disabled}
            placeholder={placeholder}
            variant={'outlined'}
            InputProps={{
              renderSuffix: ({ focused }) => {
                return !!searchText.length && !disabled
                  ? clearButton
                  : customIcon ||
                      (dropIcon ? (
                        <ExpandMoreIcon data-test-id="searchButton" className={css.dropdownIcon} />
                      ) : (
                        <SearchIcon data-test-id="searchButton" className={css.searchIcon} />
                      ));
              },
              ...inputProps.InputProps,
              endAdornment: undefined,
              style: { paddingRight: 0 },
            }}
          />
        );
      }}
    />
  );
};

export default AutoComplete;
