import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import { BookingStatusTypes } from '@crimson-education/common-config';
import css from './styles.scss';
import { handleEnter } from '../../../../../../../utils/accessibility';

export default class CalendarBooking extends Component {
  shouldComponentUpdate(nextProps) {
    return (
      nextProps.status !== this.props.status ||
      !nextProps.displayStartTime.isSame(this.props.displayStartTime) ||
      !nextProps.displayEndTime.isSame(this.props.displayEndTime) ||
      nextProps.description !== this.props.description
    );
  }
  handleClickHandler(props) {
    const { source, clickHandler } = props;
    if (source && source !== 'crimson-app') {
      return;
    }
    clickHandler();
  }

  render() {
    const {
      person,
      status,
      description,
      displayStartTime,
      displayEndTime,
      realStartTime,
      realEndTime,
      blockStyle,
      source,
    } = this.props;
    const displayTimeDiff = (displayEndTime - displayStartTime) / (1000 * 60 * 15);
    const realTimeDiff = (realEndTime - realStartTime) / (1000 * 60 * 15);
    return (
      <div
        role="button"
        tabIndex={0}
        data-ga-label="SessionBlock"
        className={classNames(
          css.block,
          { [css.external]: source && source !== 'crimson-app' },
          { [css.confirmed]: status === BookingStatusTypes.CONFIRMED },
          { [css.completed]: status === BookingStatusTypes.COMPLETED || status === BookingStatusTypes.AUTO_COMPLETED },
          {
            [css.pending]:
              status !== BookingStatusTypes.CONFIRMED &&
              status !== BookingStatusTypes.COMPLETED &&
              status !== BookingStatusTypes.AUTO_COMPLETED,
          },
          { [css.continued]: displayStartTime - realStartTime !== 0 },
          { [css.continuing]: displayEndTime - realEndTime !== 0 },
          { [css.past]: displayEndTime.isBefore(moment()) },
          {
            [css.inProgress]:
              status === BookingStatusTypes.CONFIRMED && moment().isBetween(displayStartTime, displayEndTime),
          },
        )}
        style={blockStyle}
        onClick={() => this.handleClickHandler(this.props)}
        onKeyDown={handleEnter(() => this.handleClickHandler(this.props))}
        data-test-id={`${status}Booking_${moment(displayStartTime).format('YYYYMMDD')}_${moment(
          displayStartTime,
        ).format('HH-mm')}_${moment(displayEndTime).format('HH-mm')}`}
      >
        <div className={classNames(css.time, css.textContent, { [css.invisible]: realTimeDiff < 4 })}>
          {moment(displayStartTime).format('LT')}
        </div>
        <div
          className={classNames(css.description, css.textContent, {
            [css.marginTop]: displayTimeDiff < 4 && displayTimeDiff > 1,
          })}
        >
          {description}
        </div>
        <div
          className={classNames(
            css.person,
            css.textContent,
            { [css.marginTop]: displayTimeDiff < 3 && displayTimeDiff > 1 },
            { [css.smallText]: displayTimeDiff === 1 },
            { [css.colorChange]: displayTimeDiff < 3 },
          )}
        >
          {source && source !== 'crimson-app' ? null : person}
        </div>
      </div>
    );
  }
}

CalendarBooking.displayName = 'CalendarBooking';

CalendarBooking.propTypes = {
  person: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  displayStartTime: PropTypes.object.isRequired,
  displayEndTime: PropTypes.object.isRequired,
  realStartTime: PropTypes.object.isRequired,
  realEndTime: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
  clickHandler: PropTypes.func.isRequired,
  blockStyle: PropTypes.object.isRequired,
  source: PropTypes.string,
};
