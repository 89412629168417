import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import autoBind from 'auto-bind';
import ImmutablePropTypes from 'react-immutable-proptypes';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import Day from './Day';
import CalendarTimes from './CalendarTimes';
import WeekHeader from './WeekHeader';

import css from './styles.scss';

const SWIPE_LENGTH = 100;

export default class CalendarGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      calendarHeight: 0,
      touch: {},
    };

    autoBind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedScroll !== this.props.selectedScroll) {
      this.calendarDiv.scrollTop = this.props.selectedScroll;
    }
  }

  setHeight(calendar) {
    if (calendar && this.props.selectedScroll === 0) {
      const hoursSince = moment().hour();
      const heightDiff = Math.floor(calendar.scrollHeight / 24) * hoursSince;
      this.props.updateScroll(heightDiff);
      this.props.updateScrollHeight(calendar.scrollHeight);
    }
  }

  handleTouchStart(e) {
    this.setState({
      touch: e.changedTouches[0],
    });
  }

  handleTouchEnd(e) {
    const changedTouch = e.changedTouches[0];

    if (changedTouch.pageX > this.state.touch.pageX && changedTouch.pageX - this.state.touch.pageX > SWIPE_LENGTH) {
      this.triggerAnimation('swipeLeft');
      this.props.prevWeek();
    }
    if (changedTouch.pageX < this.state.touch.pageX && this.state.touch.pageX - changedTouch.pageX > SWIPE_LENGTH) {
      this.triggerAnimation('swipeRight');
      this.props.nextWeek();
    }
  }

  triggerAnimation(direction) {
    this.setState({ [direction]: true });
    window.setTimeout(() => {
      this.setState({ [direction]: false });
    }, 400);
  }

  render() {
    const { onAvailabilityClicked, onBookingClicked, openModalViaCalendar } = this.props;

    return (
      <div
        className={classNames(this.props.className, css.calendarGrid)}
        onTouchStart={this.handleTouchStart}
        onTouchEnd={this.handleTouchEnd}
      >
        <div className={classNames(css.swipeIndicator, css.swipeLeft, { [css.showArrow]: this.state.swipeLeft })}>
          <KeyboardArrowLeft />
        </div>
        <div className={classNames(css.swipeIndicator, css.swipeRight, { [css.showArrow]: this.state.swipeRight })}>
          <KeyboardArrowRight />
        </div>
        <WeekHeader week={this.props.week} />
        <div
          className={css.weekGrid}
          ref={(ref) => {
            this.calendarDiv = ref;
          }}
          data-appcues-id="sessions"
        >
          <CalendarTimes />
          {this.props.week.map((day) => (
            <Day
              key={day.get('date')}
              date={day.get('date')}
              dayElements={day.get('dayElements')}
              setHeight={this.setHeight}
              onAvailabilityClicked={onAvailabilityClicked}
              onBookingClicked={onBookingClicked}
              openModalViaCalendar={openModalViaCalendar}
            />
          ))}
        </div>
      </div>
    );
  }
}

CalendarGrid.displayName = 'CalendarGrid';

CalendarGrid.propTypes = {
  className: PropTypes.string,
  week: ImmutablePropTypes.list.isRequired,
  selectedScroll: PropTypes.number.isRequired,
  updateScroll: PropTypes.func.isRequired,
  onAvailabilityClicked: PropTypes.func.isRequired,
  onBookingClicked: PropTypes.func.isRequired,
  nextWeek: PropTypes.func.isRequired,
  prevWeek: PropTypes.func.isRequired,
  openModalViaCalendar: PropTypes.func,
  updateScrollHeight: PropTypes.func.isRequired,
};
