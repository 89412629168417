import React from 'react';
import PropTypes from 'prop-types';

export default function Happy({ className }) {
  return (
    <svg viewBox="0 0 64 64" enableBackground="new 0 0 64 64" className={className}>
      <circle fill="#ffdd67" cx="32" cy="32" r="30" />
      <g fill="#664e27">
        <path d="m17.316 40.949c2.475 1.455 4.865 2.338 7.314 2.924 2.439.586 4.9.836 7.369.84 2.471-.01 4.93-.26 7.367-.846 2.449-.586 4.838-1.467 7.316-2.918-1.061 2.717-3.416 4.854-6.04 6.221-2.654 1.379-5.662 2.043-8.641 2.041-2.979-.004-5.984-.668-8.637-2.047-2.625-1.367-4.98-3.502-6.05-6.215" />
        <circle cx="20.502" cy="26.592" r="4.5" />
        <circle cx="43.502" cy="26.592" r="4.5" />
      </g>
    </svg>
  );
}

Happy.propTypes = {
  className: PropTypes.string,
};
