import Immutable from 'immutable';
import createReducer from 'utils/createReducer';
import userAPI from 'graphql/api/curriculum';
import { ADD_ENTITIES, addEntitiesWithNormalisation } from 'ducks/normalizr';
import { curriculumEntity } from 'schema';

const initialState = new Immutable.Map();

export default createReducer(initialState, {
  [ADD_ENTITIES]: (state, action) => {
    return state.mergeDeep(action.payload.entities.curriculum);
  },
});

export function fetchAllCurriculum() {
  return (dispatch) => {
    return userAPI.allCurriculum().then(({ allCurriculum }) => {
      dispatch(addEntitiesWithNormalisation(allCurriculum, [curriculumEntity]));
    });
  };
}
