import { BookingStatusTypes } from '@crimson-education/common-config';

export const bookingSummaryModes = {
  SENDER_TENTATIVE_EDIT: 'SENDER_TENTATIVE_EDIT',
  RECEIVER_TENTATIVE_EDIT: 'RECEIVER_TENTATIVE_EDIT',
  SENDER_TENTATIVE_VIEW_ONLY: 'SENDER_TENTATIVE_VIEW_ONLY',
  RECEIVER_TENTATIVE_VIEW_ONLY: 'RECEIVER_TENTATIVE_VIEW_ONLY',
  SENDER_CONFIRMED_EDIT: 'SENDER_CONFIRMED_EDIT',
  RECEIVER_CONFIRMED_EDIT: 'RECEIVER_CONFIRMED_EDIT',
  SENDER_CONFIRMED_VIEW_ONLY: 'SENDER_CONFIRMED_VIEW_ONLY',
  RECEIVER_CONFIRMED_VIEW_ONLY: 'RECEIVER_CONFIRMED_VIEW_ONLY',
  VIEW_ONLY: 'VIEW_ONLY',
};

export const ModalTextTypes = {
  CONFIRMED: 'greenBodyText',
};

/**
 * Returns the settings of the modal based on the mode
 * @function getModalSettings
 * @param props
 * @param mode
 * @returns {{modalHeader: string, bodyText: string, eventSummaryMode: string, modalClassName: string, toggleOpenText: string}}
 */
export function getModalSettings(props, mode) {
  const booking = props.booking;
  const settings = {
    modalHeader: '',
    bodyText: '',
    eventSummaryMode: '',
    modalClassName: '',
    toggleOpenText: '',
    mode,
  };
  switch (mode) {
    case bookingSummaryModes.SENDER_TENTATIVE_EDIT:
      settings.modalHeader = booking.get('name');
      settings.bodyText = 'Pending lesson';
      settings.modalClassName = '';
      settings.toggleOpenText = 'Edit';
      settings.extraOptionText = 'Cancel lesson';
      break;
    case bookingSummaryModes.RECEIVER_TENTATIVE_EDIT:
      settings.modalHeader = 'Can you attend this lesson?';
      settings.bodyText = 'Please confirm this lesson or suggest a new time.';
      settings.modalClassName = '';
      settings.toggleOpenText = 'Suggest a new time';
      settings.extraOptionText = '';
      break;
    case bookingSummaryModes.SENDER_TENTATIVE_VIEW_ONLY:
    case bookingSummaryModes.RECEIVER_TENTATIVE_VIEW_ONLY:
      settings.modalHeader = booking.get('name');
      settings.bodyText = 'Pending lesson';
      settings.modalClassName = '';
      settings.toggleOpenText = '';
      settings.extraOptionText = '';
      break;
    case bookingSummaryModes.SENDER_CONFIRMED_EDIT:
      settings.modalHeader = booking.get('name');
      settings.bodyText = 'Confirmed lesson';
      settings.modalClassName = ModalTextTypes.CONFIRMED;
      settings.toggleOpenText = 'Edit';
      settings.extraOptionText = 'Cancel lesson';
      break;
    case bookingSummaryModes.RECEIVER_CONFIRMED_EDIT:
      settings.modalHeader = booking.get('name');
      settings.bodyText = 'Confirmed lesson';
      settings.modalClassName = ModalTextTypes.CONFIRMED;
      settings.toggleOpenText = '';
      settings.extraOptionText = 'Cancel lesson';
      break;
    case bookingSummaryModes.SENDER_CONFIRMED_VIEW_ONLY:
    case bookingSummaryModes.RECEIVER_CONFIRMED_VIEW_ONLY:
      settings.modalHeader = booking.get('name');
      settings.bodyText = 'Confirmed lesson';
      settings.modalClassName = ModalTextTypes.CONFIRMED;
      settings.toggleOpenText = '';
      settings.extraOptionText = '';
      break;
    case bookingSummaryModes.VIEW_ONLY:
      settings.modalHeader = booking.get('name');
      settings.bodyText =
        booking.get('status') === BookingStatusTypes.TENTATIVE ? 'Pending lesson' : 'Confirmed lesson';
      settings.modalClassName = booking.get('status') === BookingStatusTypes.CONFIRMED ? ModalTextTypes.CONFIRMED : '';
      settings.toggleOpenText = '';
      settings.extraOptionText = '';
      break;
    default:
      return settings;
  }
  return settings;
}
