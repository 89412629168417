import React, { useEffect, useState } from 'react';
import QuillEditor from 'components/molecules/QuillEditor';
import { StyledButton } from '../style';
import { EditorContainer, EditorHeader, Title, Drafting, ButtonGroup, buttonStyle, outlineButtonStyle } from '../style';
import {
  getFloatingSessionContext,
  getNotesByEvent,
  getSessionItem,
  setSessionItem,
  trackFloatingSessionEvent,
  getEventParams,
} from '../index.util';
import { getParamsType } from '..';

export default function Notes({
  setFloatingNotesText,
  onSave,
  setSessionEdited,
  getParams,
}: {
  onSave: () => void;
  setSessionEdited: React.Dispatch<React.SetStateAction<boolean>>;
  setFloatingNotesText: React.Dispatch<React.SetStateAction<string>>;
  getParams: getParamsType;
}) {
  const { sessionId } = getFloatingSessionContext();
  const localNotes = getSessionItem({
    sessionId,
    type: 'notes',
  });
  const [html, setHtml] = useState(localNotes?.notes?.html || '');
  useEffect(() => {
    setFloatingNotesText(html);
  }, [html]);
  const [edited, setEdited] = useState(false);
  useEffect(() => {
    async function fetchItemById() {
      const { html: notesData } = await getNotesByEvent(sessionId);
      if (html !== notesData) {
        setEdited(true);
      }
    }
    if (sessionId) {
      fetchItemById();
    }
  }, [sessionId]);
  const onCancel = async () => {
    const { html } = await getNotesByEvent(sessionId);
    setSessionItem({
      sessionId,
      type: 'notes',
      item: {
        notes: {
          html,
          reFetch: false,
        },
      },
    });
    setEdited(false);
    setHtml(html || '');
  };
  useEffect(() => {
    setSessionEdited(edited);
  }, [edited]);
  return (
    <EditorContainer>
      <EditorHeader>
        <Title>Notes</Title>
        {edited && <Drafting>Drafting...</Drafting>}
        <ButtonGroup>
          {edited && (
            <StyledButton
              variant="outlined"
              style={{
                ...buttonStyle,
                ...outlineButtonStyle,
              }}
              onClick={onCancel}
            >
              Cancel
            </StyledButton>
          )}
          {edited ? (
            <StyledButton
              variant="contained"
              style={buttonStyle}
              onClick={() => {
                setEdited(false);
                trackFloatingSessionEvent({
                  key: 'SaveNotes',
                  metadata: getEventParams({ ...getParams() }),
                });
                onSave();
              }}
            >
              Save
            </StyledButton>
          ) : (
            <StyledButton
              disabled
              variant="contained"
              style={{
                ...buttonStyle,
                background: '#cccccc',
              }}
            >
              Saved
            </StyledButton>
          )}
        </ButtonGroup>
      </EditorHeader>
      <QuillEditor
        disabled={false}
        html={html}
        onChange={(notes) => {
          const pureText = new DOMParser().parseFromString(notes.html, 'text/html').documentElement.textContent || '';
          if (!edited) {
            setEdited(true);
          } else {
            const localNotes = getSessionItem({
              sessionId,
              type: 'notes',
            });
            setEdited(pureText.length > 0 ? localNotes?.notes?.html !== notes.html : localNotes?.notes?.html !== '');
          }
          setHtml(pureText.length > 0 ? notes.html : '');
        }}
        id="floating-notes"
        toolbarClassName="no-padding"
        className="floating-notes"
      />
    </EditorContainer>
  );
}
