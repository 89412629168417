import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Tooltip from 'components/generic/Tooltip';
import TooltipItem from 'components/generic/Tooltip/TooltipItem';
import css from './styles.scss';
import { handleEnter } from '../../../../utils/accessibility';

export default class NotificationToggler extends Component {
  constructor() {
    super();

    this.state = {
      isToolTipOpen: false,
    };

    this.toggleToolTip = this.toggleToolTip.bind(this);
  }

  toggleToolTip(toState = null) {
    this.setState({ isToolTipOpen: toState === null ? !this.state.isOpen : toState });
  }

  render() {
    const { unseen, onClick, className, wrapperIsOpen, mobile } = this.props;
    return (
      <Tooltip
        isOpen={this.state.isToolTipOpen}
        enterExitTransitionDurationMs={0}
        tipSize={0.01}
        target={
          <div
            role="button"
            tabIndex={0}
            data-ga-label="Notification Bell"
            onClick={onClick}
            onKeyDown={handleEnter(onClick)}
            data-test-id="seeNotificationsButton"
            className={className}
            onMouseOver={!mobile ? () => this.toggleToolTip(true) : null}
            onMouseOut={!mobile ? () => this.toggleToolTip(false) : null}
            onFocus={!mobile ? () => this.toggleToolTip(true) : null}
            onBlur={!mobile ? () => this.toggleToolTip(false) : null}
          >
            <div className={classNames(css.notificationsToggler, { [css.highlighted]: wrapperIsOpen })}>
              <i className={`zmdi zmdi-notifications ${css.bellIcon}`} />
              {!!unseen && <div className={css.unseen}>{unseen > 99 ? '99+' : unseen}</div>}
            </div>
          </div>
        }
        body={
          !wrapperIsOpen && !mobile ? (
            <TooltipItem>
              <span className={css.notifText}>Notifications</span>
            </TooltipItem>
          ) : (
            <div />
          )
        }
        preferPlace="below"
      />
    );
  }
}

NotificationToggler.displayName = 'NotificationToggler';

NotificationToggler.propTypes = {
  unseen: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  wrapperIsOpen: PropTypes.bool,
  mobile: PropTypes.bool,
};
