import createReducer from 'utils/createReducer';
import Immutable from 'immutable';
import { normalize } from 'normalizr';
import { invoiceEntity } from 'schema';
import mapValues from 'lodash/mapValues';
import { Acl, toasterMessages } from '@crimson-education/common-config';

import invoiceApi from 'graphql/api/invoice';
import { updateMeta, formFail, formSuccess } from 'ducks/meta';
import componentKeys from 'constants/componentKeys';
import { getLoginUser } from 'selectors/user';
import { pageSize } from 'utils/invoiceUtils';

export const FETCH_INVOICE_SUCCESS = 'invoicing/FETCH_INVOICE_SUCCESS';
export const FETCH_INVOICES_FOR_USER_SUCCESS = 'invoicing/FETCH_INVOICES_FOR_USER_SUCCESS';
export const FETCH_INVOICES_FOR_ADMIN_SUCCESS = 'invoicing/FETCH_INVOICES_FOR_ADMIN_SUCCESS';
export const MARK_INVOICE_AS_PAID_SUCCESS = 'invoicing/MARK_INVOICE_AS_PAID_SUCCESS';
export const SUBMIT_INVOICE_SUCCESS = 'invoicing/SUBMIT_INVOICE_SUCCESS';
export const DELETE_INVOICE_SUCCESS = 'invoicing/DELETE_INVOICE_SUCCESS';
export const SET_SELECTED_INVOICE_ID = 'invoicing/SET_SELECTED_INVOICE_ID';

const initialState = Immutable.fromJS({
  invoice: {},
  allIds: [],
  invoiceItem: {},
  packageItem: {},
  user: {},
});

const fetchInvoicesSuccessReducer = (state, { payload }) => {
  const {
    entities,
    result: {
      invoices: { edges, pageInfo, totalCount },
      filterablePeriods,
      filterableTutors,
      filterableBillingCountries,
    },
  } = payload;

  const invoiceIds = edges.map(({ node }) => node);
  const curIds = state.get('allIds');
  const allIds = curIds.concat(invoiceIds);

  const newState = {
    ...entities,
    filterablePeriods,
    filterableTutors,
    filterableBillingCountries,
    allIds,
    pageInfo: {
      ...pageInfo,
      totalCount,
    },
  };

  if (Immutable.is(state, newState)) {
    return state;
  }

  if (pageInfo.hasPreviousPage) {
    return state.mergeDeep(newState);
  }

  return Immutable.fromJS(newState);
};

export const reducer = {
  [FETCH_INVOICE_SUCCESS]: (state, { payload }) => {
    const { entities } = payload;

    return state.mergeDeep(entities);
  },
  [FETCH_INVOICES_FOR_USER_SUCCESS]: fetchInvoicesSuccessReducer,
  [FETCH_INVOICES_FOR_ADMIN_SUCCESS]: fetchInvoicesSuccessReducer,
  [MARK_INVOICE_AS_PAID_SUCCESS]: (state, { payload }) => {
    const { entities } = payload;

    return state.mergeDeep(entities);
  },
  [SUBMIT_INVOICE_SUCCESS]: (state, { payload }) => {
    const { entities } = payload;

    return state.mergeDeep(entities);
  },
  [DELETE_INVOICE_SUCCESS]: (state, { payload }) => {
    const { id } = payload;
    return state.withMutations((state) => {
      state.updateIn(['allIds'], (allIds) => allIds.filter((currId) => currId !== id));
      state.updateIn(['invoice'], (invoices) => invoices.delete(id));
    });
  },
  [SET_SELECTED_INVOICE_ID]: (state, { payload }) => {
    const { id } = payload;
    return state.updateIn(['selectedInvoiceId'], id);
  },
};

export default createReducer(initialState, reducer);

export function fetchInvoiceByIdSuccess(payload) {
  return { type: FETCH_INVOICE_SUCCESS, payload };
}

export function markInvoiceAsPaidByIdSuccess(payload) {
  return { type: MARK_INVOICE_AS_PAID_SUCCESS, payload };
}

export function submitInvoiceByIdSuccess(payload) {
  return { type: SUBMIT_INVOICE_SUCCESS, payload };
}
export function deleteInvoiceByIdSuccess(payload) {
  return { type: DELETE_INVOICE_SUCCESS, payload };
}

export function fetchInvoicesByUserIdSuccess(payload) {
  return { type: FETCH_INVOICES_FOR_USER_SUCCESS, payload };
}

export function fetchInvoicesForAdminSuccess(payload) {
  return { type: FETCH_INVOICES_FOR_ADMIN_SUCCESS, payload };
}

export function fetchInvoiceById(id) {
  return async (dispatch) => {
    dispatch(updateMeta(componentKeys.INVOICE_FETCHED, false));
    dispatch(updateMeta(componentKeys.INVOICE_FETCHING_LOAD, true));
    try {
      const invoice = await invoiceApi.fetchInvoiceById(id);
      dispatch(updateMeta(componentKeys.INVOICE_FETCHED, true));
      dispatch(fetchInvoiceByIdSuccess(normalize(invoice, invoiceEntity)));
      dispatch(updateMeta(componentKeys.INVOICE_FETCHING_LOAD, false));
    } catch (e) {
      dispatch(formFail(toasterMessages.invoiceNotFetchedById()));
    }
  };
}
export function submitInvoiceById(id) {
  return async (dispatch) => {
    dispatch(updateMeta(componentKeys.INVOICE_SUBMITTING, true));
    try {
      const invoice = await invoiceApi.submitInvoiceById(id);
      dispatch(updateMeta(componentKeys.INVOICE_SUBMITTING, false));
      dispatch(formSuccess(toasterMessages.invoiceSubmitted()));
      dispatch(submitInvoiceByIdSuccess(normalize(invoice, invoiceEntity)));
    } catch (e) {
      dispatch(updateMeta(componentKeys.INVOICE_SUBMITTING, false));
      dispatch(formFail(toasterMessages.invoiceNotSubmitted()));
    }
  };
}
export function deleteInvoiceById(id) {
  return async (dispatch) => {
    dispatch(updateMeta(componentKeys.INVOICE_DELETING, true));
    try {
      await invoiceApi.deleteInvoiceById(id);
      dispatch(updateMeta(componentKeys.INVOICE_DELETING, false));
      dispatch(formSuccess(toasterMessages.invoiceDeleted()));
      dispatch(deleteInvoiceByIdSuccess({ id }));
    } catch (e) {
      dispatch(updateMeta(componentKeys.INVOICE_DELETING, false));
      dispatch(formFail(toasterMessages.invoiceNotDeleted()));
    }
  };
}

export function fetchInvoicesByUserId({ id, filter, first, after }) {
  const filterValue = mapValues(filter, (f) => f.value);

  return async (dispatch) => {
    dispatch(updateMeta(componentKeys.INVOICES_FETCHED, false));
    try {
      const invoices = await invoiceApi.fetchInvoicesByUserId({ id, filter: filterValue, first, after });
      dispatch(fetchInvoicesByUserIdSuccess(normalize(invoices, { invoices: { edges: [{ node: invoiceEntity }] } })));
      dispatch(updateMeta(componentKeys.INVOICES_FETCHED, true));
    } catch (e) {
      dispatch(formFail(toasterMessages.invoiceNotFetched()));
    }
  };
}

export function fetchInvoicesForAdmin({ filter, first, after }) {
  const filterValue = mapValues(filter, (f) => f.value);

  return async (dispatch) => {
    dispatch(updateMeta(componentKeys.INVOICES_FETCHED, false));
    try {
      const invoices = await invoiceApi.fetchInvoicesForAdmin({ filter: filterValue, first, after });
      dispatch(fetchInvoicesForAdminSuccess(normalize(invoices, { invoices: { edges: [{ node: invoiceEntity }] } })));
      dispatch(updateMeta(componentKeys.INVOICES_FETCHED, true));
    } catch (e) {
      dispatch(formFail(toasterMessages.invoiceNotFetched()));
    }
  };
}

export function fetchInvoices({ filter, first = pageSize, after }) {
  return (dispatch, getState) => {
    const loginUser = getLoginUser(getState());
    const userRoles = loginUser.get('userRoles');

    if (Acl.isAccounts(userRoles) || Acl.isSuperAdmin(userRoles)) {
      return dispatch(fetchInvoicesForAdmin({ filter, first, after }));
    }
    const userId = loginUser.get('userId');
    return dispatch(fetchInvoicesByUserId({ id: userId, filter, first, after }));
  };
}

export function updateInvoiceFilter(filter) {
  return (dispatch) => {
    dispatch(updateMeta(componentKeys.INVOICE_FILTER, filter));
    dispatch(updateMeta(componentKeys.INVOICE_SELECTED_ID, null));
    dispatch(fetchInvoices({ filter }));
  };
}

export function markInvoiceAsPaidById(id, filter) {
  return async (dispatch) => {
    dispatch(updateMeta(componentKeys.INVOICE_PAYING, true));
    try {
      const invoice = await invoiceApi.markInvoiceAsPaidById(id);
      dispatch(updateMeta(componentKeys.INVOICE_PAYING, false));
      dispatch(formSuccess(toasterMessages.invoicePaid()));
      dispatch(markInvoiceAsPaidByIdSuccess(normalize(invoice, invoiceEntity)));
      dispatch(fetchInvoices({ filter }));
    } catch (e) {
      dispatch(updateMeta(componentKeys.INVOICE_PAYING, false));
      dispatch(formFail(toasterMessages.invoiceNotPaid()));
    }
  };
}
