import React, { useContext, useMemo, useEffect } from 'react';
import { AppTrackerContext } from '../../viewController';
import ClickableUnderlineText from './ClickableUnderlineText';
import ApprovalPending from './ApprovalPending';
import { useUserId } from '../../helper';
import { get } from 'lodash';
import { isPreviewApproval } from '../../util';

type ParentApprovalButtonProps = {
  show?: boolean;
};
const ParentApprovalButton = ({ show }: ParentApprovalButtonProps) => {
  const { controller, state } = useContext(AppTrackerContext);
  const userId = useUserId();
  useEffect(() => {
    controller.getStudentAllApprovalApplications(userId);
  }, [controller, userId]);
  const approval = useMemo(() => get(state.approvals, [state.listRegion]), [state.listRegion, state.approvals]);
  const hasSentEmail = !!approval;
  const isPreview = isPreviewApproval(approval);
  const currentHash = useMemo(() => state.currentSelectionHash[state.listRegion], [
    state.currentSelectionHash,
    state.listRegion,
  ]);
  const edited = currentHash !== get(state.approvals, [state.listRegion, 'selectionHash']);
  const onClickSendForApproval = () => {
    const enable = controller.checkIfHasApplicationsToSendApproval();
    if (!enable) return;
    controller.openParentApprovalModal();
  };
  if (!show) return null;
  return (
    <>
      {hasSentEmail && !isPreview ? (
        <ApprovalPending edited={edited} />
      ) : (
        <ClickableUnderlineText
          title={'Send to parents/primary contacts for approval'}
          onClick={onClickSendForApproval}
        />
      )}
    </>
  );
};

export default ParentApprovalButton;
