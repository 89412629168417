import { connect } from 'react-redux';
import { updateMeta } from 'ducks/meta';
import { fetchIncompleteReports, fetchIncompleteSessionReports } from 'ducks/incompleteReport';
import componentKeys from 'constants/componentKeys';
import { getByComponentKey } from 'selectors/meta';
import { getStaffInformation } from 'selectors/user';

import SessionReportModal from './SessionReportModal';

export default connect(
  (state) => ({
    lapsedBillingPeriod: getByComponentKey(componentKeys.LAPSED_BILLING_PERIOD)(state),
    staffInfo: getStaffInformation(state),
  }),
  (dispatch) => ({
    closeSessionReport: () => dispatch(updateMeta(componentKeys.ACTIVE_SESSION_REPORT, null)),
    fetchIncompleteReports: (userId) => dispatch(fetchIncompleteReports(userId)),
    fetchIncompleteSessionReports: (userId) => dispatch(fetchIncompleteSessionReports(userId)),
  }),
)(SessionReportModal);
