import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Paginator from 'components/generic/Paginator';
import LoadingCircle from 'components/molecules/LoadingCircle';
import formStates from 'constants/formStates';
import colours from 'components/atoms/colours.scss';
import FriendCard from '../FriendCard/FriendCard';
import UserQueryTable from '../../UserQuery/UserQueryTable';
import FriendsQueryFilter from '../FriendsQueryFilter/FriendsQueryFilter';
import css from './styles.scss';

const DEFAULT_PAGE = 1;
const USER_LIST_PAGE_SIZE = 10;

export default class FriendsQuery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterParams: {
        friendStatus: props.defaultActive ? 'OK' : 'SEND',
        name: '',
      },
    };
  }

  UNSAFE_componentWillMount() {
    this.handleSearchParamsChange({ pageNumber: 1 }, this.state.filterParams);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.activePage > 0 && prevProps.activePage !== this.props.activePage) {
      this.handleSearchParamsChange({ pageNumber: this.props.activePage }, this.state.filterParams);
    }
  }
  handleSearchParamsChange = (params, filters = {}) => {
    const { fetchMyFriends, activePage, setActivePage } = this.props;
    const pageNumber = (params && params.pageNumber) || activePage || DEFAULT_PAGE;

    this.setState({
      filterParams: {
        friendStatus: filters.friendStatus,
        name: filters.name,
      },
    });
    fetchMyFriends(
      {
        pageNumber,
        pageSize: (params && params.pageSize) || USER_LIST_PAGE_SIZE,
      },
      [filters.friendStatus],
      filters.name,
    );
    setActivePage(pageNumber);
  };

  processFriend = (targetId, type) => {
    const { acceptFriendRequest, blockFriend, ignoreFriendRequest } = this.props;
    if (type === 'ACCEPT') {
      acceptFriendRequest(
        targetId,
        {
          pageNumber: 1,
          pageSize: USER_LIST_PAGE_SIZE,
        },
        [this.state.filterParams.friendStatus],
        this.state.filterParams.name,
      );
    }
    if (type === 'BLOCK') {
      blockFriend(
        targetId,
        {
          pageNumber: 1,
          pageSize: USER_LIST_PAGE_SIZE,
        },
        [this.state.filterParams.friendStatus],
        this.state.filterParams.name,
      );
    }
    if (type === 'IGNORE') {
      ignoreFriendRequest(
        targetId,
        {
          pageNumber: 1,
          pageSize: USER_LIST_PAGE_SIZE,
        },
        [this.state.filterParams.friendStatus],
        this.state.filterParams.name,
      );
    }
  };

  render() {
    const {
      loginUserPermissions,
      columnsToShow,
      setUserStatus,
      usersFetched,
      userQueryResults,
      formState,
      userPagination,
      hasNewFriend,
      defaultActive,
    } = this.props;
    const defaults = {
      totalPages: 0,
      currentPage: 0,
      userResults: new List(),
      filterParams: null,
    };
    const totalPages =
      userPagination && userPagination.totalPages && formState !== formStates.Loading
        ? userPagination.totalPages
        : defaults.totalPages;
    const currentPage =
      userPagination && userPagination.currentPage && formState !== formStates.Loading
        ? userPagination.currentPage
        : defaults.currentPage;
    const userResults = userQueryResults || defaults.userResults;

    return (
      <div>
        <FriendsQueryFilter
          onSearchParamsChange={(filterParams) => {
            return this.handleSearchParamsChange({ pageNumber: 1 }, filterParams);
          }}
          hasNewFriend={hasNewFriend}
          defaultActive={defaultActive}
        />
        <UserQueryTable
          usersFetched={usersFetched}
          users={userResults}
          columns={columnsToShow}
          setUserStatus={setUserStatus(() => this.handleSearchParamsChange(null, this.state.filterParams))}
          processFriend={(targetId, type) => this.processFriend(targetId, type)}
          hideEmail
          loginUserPermissions={loginUserPermissions}
        />
        <div className={css.mobileStyle}>
          {userResults
            ? userResults.map((user) => (
                <FriendCard
                  key={user.get('userId')}
                  user={user}
                  processFriend={(targetId, type) => this.processFriend(targetId, type)}
                />
              ))
            : null}
        </div>
        {formState === formStates.Loading && (
          <div style={{ margin: '8rem auto', textAlign: 'center' }}>
            <LoadingCircle color={colours.crimsonRed} />
          </div>
        )}
        <Paginator
          className={css.pager}
          totalPages={totalPages}
          currentPage={currentPage}
          onPageClick={(pageNumber) => {
            this.props.setActivePage(pageNumber);
          }}
        />
      </div>
    );
  }
}

FriendsQuery.propTypes = {
  loginUserPermissions: PropTypes.array.isRequired,
  setUserStatus: PropTypes.func.isRequired,
  columnsToShow: PropTypes.array,
  usersFetched: PropTypes.bool.isRequired,
  userQueryResults: ImmutablePropTypes.list,
  fetchMyFriends: PropTypes.func.isRequired,
  acceptFriendRequest: PropTypes.func,
  blockFriend: PropTypes.func,
  ignoreFriendRequest: PropTypes.func,
  formState: PropTypes.string,
  userPagination: PropTypes.object.isRequired,
  setActivePage: PropTypes.func.isRequired,
  activePage: PropTypes.number,
  hasNewFriend: PropTypes.number,
  defaultActive: PropTypes.bool,
};

FriendsQuery.defaultProps = {
  activeUsers: true,
};
