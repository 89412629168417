import React, { useContext } from 'react';
import { IconContainer, SelectAppCycleContainer, ApplicationCycleText, AppCycleButton } from './index.style';
import CalendarIcon from 'components/atoms/graphics/ApplicationTracker/calendar';
import DropdownMenu from '../Dropdown/DropdownMenu';
import { getSelectableYearCycleList } from 'utils/utils';
import { useSelector } from 'react-redux';
import { getYearOfApplication } from 'selectors/profile';
import { getViewableUser } from 'selectors/meta';
import { roleTypes } from '@crimson-education/common-config';
import { AppTrackerContext } from '../viewController';
import { MoreLessIcon } from '../index.style';
import SelectAppCycleHint from './SelectAppCycleHint';
const YEARS = getSelectableYearCycleList(10, 3).reverse();
const SelectAppCycle = () => {
  const userId = useSelector(getViewableUser);
  const yearOfApplication = useSelector(getYearOfApplication(userId, roleTypes.STUDENT));
  const { controller, state } = useContext(AppTrackerContext);
  return (
    <SelectAppCycleContainer alignitems={'center'} gap={'9px'}>
      <IconContainer>
        <CalendarIcon />
      </IconContainer>
      <ApplicationCycleText>{'Application cycle:'}</ApplicationCycleText>
      <DropdownMenu
        onOpenDropdown={controller.closeSelectAppCycleHint}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        menuItemStyle={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          width: 145,
        }}
        data={YEARS}
        onClickItem={(value) =>
          controller.editYearOfApplicationByUserId(userId, value, !!yearOfApplication ? 'updated' : 'added')
        }
      >
        {(isOpen) => (
          <AppCycleButton endIcon={<MoreLessIcon open={isOpen} />}>{yearOfApplication || 'Select'}</AppCycleButton>
        )}
      </DropdownMenu>
      {state.showSelectAppCycleHint && <SelectAppCycleHint />}
    </SelectAppCycleContainer>
  );
};

export default SelectAppCycle;
