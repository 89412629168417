import AccessTime from '@material-ui/icons/AccessTime';
import AccountBalance from '@material-ui/icons/AccountBalance';
import Add from '@material-ui/icons/Add';
import AddCircle from '@material-ui/icons/AddCircle';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Block from '@material-ui/icons/Block';
import BorderColor from '@material-ui/icons/BorderColor';
import Cancel from '@material-ui/icons/Cancel';
import Check from '@material-ui/icons/Check';
import CheckBox from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckCircle from '@material-ui/icons/CheckCircle';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import Close from '@material-ui/icons/Close';
import Create from '@material-ui/icons/Create';
import Delete from '@material-ui/icons/Delete';
import Description from '@material-ui/icons/Description';
import Done from '@material-ui/icons/Done';
import DoneAll from '@material-ui/icons/DoneAll';
import Edit from '@material-ui/icons/Edit';
import Equalizer from '@material-ui/icons/Equalizer';
import Error from '@material-ui/icons/Error';
import Event from '@material-ui/icons/Event';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import FormatListBulleted from '@material-ui/icons/FormatListBulleted';
import Fullscreen from '@material-ui/icons/Fullscreen';
import FullscreenExit from '@material-ui/icons/FullscreenExit';
import GetApp from '@material-ui/icons/GetApp';
import Help from '@material-ui/icons/Help';
import ImportContacts from '@material-ui/icons/ImportContacts';
import Info from '@material-ui/icons/Info';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import InsertEmoticon from '@material-ui/icons/InsertEmoticon';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LibraryAdd from '@material-ui/icons/LibraryAdd';
import LocationOn from '@material-ui/icons/LocationOn';
import Lock from '@material-ui/icons/Lock';
import Mail from '@material-ui/icons/Mail';
import Map from '@material-ui/icons/Map';
import Message from '@material-ui/icons/Message';
import MoreVert from '@material-ui/icons/MoreVert';
import Pageview from '@material-ui/icons/Pageview';
import People from '@material-ui/icons/People';
import Person from '@material-ui/icons/Person';
import Phone from '@material-ui/icons/Phone';
import PlayArrow from '@material-ui/icons/PlayArrow';
import Print from '@material-ui/icons/Print';
import Publish from '@material-ui/icons/Publish';
import QueryBuilder from '@material-ui/icons/QueryBuilder';
import Remove from '@material-ui/icons/Remove';
import School from '@material-ui/icons/School';
import Send from '@material-ui/icons/Send';
import Star from '@material-ui/icons/Star';
import Grade from '@material-ui/icons/Grade';
import AttachMoney from '@material-ui/icons/AttachMoney';
import Subject from '@material-ui/icons/Subject';
import SupervisorAccount from '@material-ui/icons/SupervisorAccount';
import Timeline from '@material-ui/icons/Timeline';
import Tab from '@material-ui/icons/Tab';
import Warning from '@material-ui/icons/Warning';
import WatchLater from '@material-ui/icons/WatchLater';
import WbIncandescent from '@material-ui/icons/WbIncandescent';
import CreateNewFolder from '@material-ui/icons/CreateNewFolder';
import AutoRenew from '@material-ui/icons/Autorenew';
import Mood from '@material-ui/icons/Mood';
import Face from '@material-ui/icons/Face';
import ThumbUp from '@material-ui/icons/ThumbUp';
import ThumbDown from '@material-ui/icons/ThumbDown';
import ThumbsUpDown from '@material-ui/icons/ThumbsUpDown';
import ZoomIn from '@material-ui/icons/ZoomIn';
import ZoomOut from '@material-ui/icons/ZoomOut';
import RestorePage from '@material-ui/icons/RestorePage';
import SwapHorizontalCircle from '@material-ui/icons/SwapHorizontalCircle';
import SwapVerticalCircle from '@material-ui/icons/SwapVerticalCircle';
import AttachFile from '@material-ui/icons/AttachFile';
import DescriptionOutlined from '@material-ui/icons/DescriptionOutlined';
import InsertPhotoOutlined from '@material-ui/icons/InsertPhotoOutlined';
import FormatBold from '@material-ui/icons/FormatBold';
import FormatItalic from '@material-ui/icons/FormatItalic';
import FormatUnderlined from '@material-ui/icons/FormatUnderlined';
import CloudUpload from '@material-ui/icons/CloudUpload';
import Image from '@material-ui/icons/Image';
import AddBox from '@material-ui/icons/AddBox';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import ArrowForward from '@material-ui/icons/ArrowForward';
import HourglassEmptySharpIcon from '@material-ui/icons/HourglassEmptySharp';
import PeopleGroup from '@material-ui/icons/Group';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import Settings from '@material-ui/icons/Settings';
import ArrowLeft from '@material-ui/icons/ArrowLeft';
import LockOutlined from '@material-ui/icons/LockOutlined';
import Sms from '@material-ui/icons/Sms';
import PlayCircle from '@material-ui/icons/PlayCircleFilled';
import FileUpload from './FileUpload.js';
import { US, UK, EU, Canada, Others } from './Countries';
import { MulticolorSuccess } from './MulticolorSuccess';
import { MulticolorFailed } from './MulticolorFailed';

export {
  AccessTime,
  AccountBalance,
  Add,
  AddCircle,
  ArrowBack,
  Block,
  BorderColor,
  Cancel,
  Check,
  CheckBox,
  CheckBoxOutlineBlank,
  CheckCircle,
  ChevronRight,
  Clear,
  Close,
  Create,
  CreateNewFolder,
  Delete,
  Description,
  Done,
  DoneAll,
  Edit,
  Equalizer,
  Error,
  Event,
  ExpandLess,
  ExpandMore,
  FormatListBulleted,
  Fullscreen,
  FullscreenExit,
  Help,
  GetApp,
  ImportContacts,
  Info,
  InsertEmoticon,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LibraryAdd,
  LocationOn,
  Lock,
  Mail,
  Map,
  Message,
  MulticolorFailed,
  MulticolorSuccess,
  MoreVert,
  Pageview,
  People,
  Person,
  Phone,
  PlayArrow,
  Print,
  Publish,
  QueryBuilder,
  Remove,
  School,
  Send,
  Star,
  Grade,
  AttachMoney,
  Subject,
  SupervisorAccount,
  Timeline,
  Tab,
  Warning,
  WatchLater,
  WbIncandescent,
  AutoRenew,
  Mood,
  Face,
  ThumbUp,
  ThumbDown,
  ThumbsUpDown,
  FileUpload,
  ZoomIn,
  ZoomOut,
  RestorePage,
  SwapHorizontalCircle,
  SwapVerticalCircle,
  AttachFile,
  DescriptionOutlined,
  InsertPhotoOutlined,
  FormatBold,
  FormatItalic,
  FormatUnderlined,
  CloudUpload,
  Image,
  AddBox,
  MoreHoriz,
  HourglassEmptyIcon,
  ArrowForward,
  HourglassEmptySharpIcon,
  PeopleGroup,
  ArrowDropDown,
  Settings,
  ArrowDropUp,
  ArrowLeft,
  LockOutlined,
  Sms,
  PlayCircle,
  InfoOutlined,
  US,
  UK,
  EU,
  Canada,
  Others,
};
