import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Caption } from 'components/atoms/typography';
import InputWarningMessage from 'components/molecules/InputWarningMessage';
import classNames from 'classnames';
import styles from './styles.scss';

/* InputText
 * Text input field, Crimson style. Optional field label.
 */

export default class InputText extends Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  componentDidMount() {
    const { autoFocus, forwardedRef } = this.props;
    if (autoFocus) {
      forwardedRef ? forwardedRef.current.focus() : this.ref.current.focus();
    }
  }

  render() {
    const {
      className,
      value,
      type,
      onChange,
      onBlur,
      onFocus,
      label,
      placeholder,
      isValid,
      validationMessage,
      onKeyDown,
      forwardedRef,
      dataTestId,
      disabled,
      charLimit,
      canEdit,
      ...restProps
    } = this.props;

    return (
      <div className={className}>
        {label && (
          <Caption className={classNames(styles.fieldCaption, { [styles.disabled]: disabled })}>{label}</Caption>
        )}
        <input
          disabled={disabled || !canEdit}
          type={type || 'text'}
          data-test-id={dataTestId}
          className={classNames(styles.inputText, { [styles.disabled]: disabled, [styles.invalid]: !isValid })}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          onBlur={onBlur}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          maxLength={charLimit}
          ref={forwardedRef || this.ref}
          {...restProps}
        />
        {!isValid && <InputWarningMessage message={validationMessage} />}
      </div>
    );
  }
}

InputText.defaultProps = {
  isValid: true,
  canEdit: true,
};

InputText.propTypes = {
  isValid: PropTypes.bool,
  validationMessage: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  forwardedRef: PropTypes.object,
  dataTestId: PropTypes.string,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  charLimit: PropTypes.number,
  canEdit: PropTypes.bool,
};
