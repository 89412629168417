import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import css from './styles.scss';

export default class InputField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.defaultValue || '',
    };

    this.handleOnChange = this.handleOnChange.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.value !== undefined && nextProps.value !== prevState.value) {
      return { value: nextProps.value };
    }
    return null;
  }

  handleOnChange(event) {
    const valueChange = this.props.onValueChange;
    this.setState({
      value: event.target.value,
    });
    if (valueChange) {
      valueChange(event.target.value);
    }
  }

  render() {
    const {
      className,
      label,
      validationMessage,
      error,
      id,
      type,
      placeholder,
      max,
      min,
      maxLength,
      pattern,
      size,
      step,
      required,
      readOnly,
      disabled,
      onKeyDown,
      dataTestId,
      onBlur,
      onFocus,
    } = this.props;

    return (
      <div className={classnames(css.formItemContainer, className)}>
        {label && (
          <label className={css.formLabel} htmlFor={id}>
            {label}
          </label>
        )}
        <input
          className={classnames(css.formInput, { [css.disabled]: disabled }, { [css.error]: error })}
          id={id}
          type={type}
          value={this.state.value}
          placeholder={placeholder}
          max={max}
          min={min}
          maxLength={maxLength}
          pattern={pattern}
          size={size}
          step={step}
          required={required}
          readOnly={readOnly}
          disabled={disabled}
          onChange={this.handleOnChange}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
          onFocus={onFocus}
          data-test-id={dataTestId}
        />
        {error && validationMessage && <div className={css.validationMessage}>{validationMessage}</div>}
      </div>
    );
  }
}

InputField.displayName = 'InputField';

InputField.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  defaultValue: PropTypes.string,

  type: PropTypes.string,
  placeholder: PropTypes.string,
  max: PropTypes.string,
  min: PropTypes.string,
  maxLength: PropTypes.string,
  pattern: PropTypes.string,
  size: PropTypes.string,
  step: PropTypes.string,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,

  validationMessage: PropTypes.string,
  error: PropTypes.bool,
  onValueChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,

  dataTestId: PropTypes.string,
  value: PropTypes.string,
};

InputField.defaultProps = {
  type: 'text',
  maxLength: '255',
  onKeyDown: () => null,
};
