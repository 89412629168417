import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createCollaborativeFileInSession } from 'ducks/google/sessionFiles.js';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ReactTooltip from 'react-tooltip';

import Button from 'components/molecules/Button';
import Body from 'components/atoms/typography/Body';
import Modal from 'components/molecules/Modal';
import InputText from 'components/molecules/InputText';

import { btn } from './reset.css';
import css from './styles.scss';
import { handleEnter } from '../../../../utils/accessibility';

const FolderActions = (props) => {
  const { sessionId, studentInfo, createCollaborativeFileInSession } = props;
  const studentId = studentInfo && studentInfo.userId;
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [fileTypeToCreate, setFileTypeToCreate] = React.useState(null);
  const [fileName, setFileName] = React.useState('');
  const [docNameValid, setDocNameValid] = React.useState(true);

  const openModal = React.useCallback(
    (type) => () => {
      setDropdownOpen(false);
      setFileName('');
      setModalOpen(true);
      setFileTypeToCreate(type);
    },
    [],
  );

  const createDoc = React.useCallback(async () => {
    if (!fileName || fileName.length === 0) {
      setDocNameValid(false);
      await Promise.reject(new Error('Fail validation'));
    } else {
      setDocNameValid(true);
      await createCollaborativeFileInSession(sessionId, fileName, fileTypeToCreate, studentId);
      setTimeout(() => setModalOpen(false), 300); // little delay to show the dynamic tick
    }
  }, [fileName, createCollaborativeFileInSession, sessionId, fileTypeToCreate, studentId]);

  return (
    <ClickAwayListener onClickAway={() => setDropdownOpen(false)}>
      <div className={css.folderActions}>
        <ReactTooltip effect="solid" id="sessionActions" />
        <Button className={btn} onClick={() => setDropdownOpen(!dropdownOpen)}>
          Create new
        </Button>
        {dropdownOpen && (
          <div className={css.folderActionsDropDown}>
            <div
              role="button"
              tabIndex={0}
              data-ga-label="new-document"
              className={css.folderAction}
              onClick={openModal('DOCUMENT')}
              onKeyDown={handleEnter(openModal('DOCUMENT'))}
            >
              <img
                alt="DOCUMENT icon"
                src="https://drive-thirdparty.googleusercontent.com/16/type/application/vnd.google-apps.document"
              />
              <Body>Create Google Doc</Body>
            </div>
            <div
              role="button"
              tabIndex={0}
              data-ga-label="new-spreadsheet"
              className={css.folderAction}
              onClick={openModal('SPREADHEET')}
              onKeyDown={handleEnter(openModal('SPREADHEET'))}
            >
              <img
                alt="SPREADHEET icon"
                src="https://drive-thirdparty.googleusercontent.com/16/type/application/vnd.google-apps.spreadsheet"
              />
              <Body>Create Google Sheet</Body>
            </div>
            <div
              role="button"
              tabIndex={0}
              data-ga-label="new-drawing"
              className={css.folderAction}
              onClick={openModal('DRAWING')}
              onKeyDown={handleEnter(openModal('DRAWING'))}
            >
              <img
                alt="DRAWING icon"
                src="https://drive-thirdparty.googleusercontent.com/16/type/application/vnd.google-apps.drawing"
              />
              <Body>Create Google Drawing</Body>
            </div>
            <div
              role="button"
              tabIndex={0}
              data-ga-label="new-presentation"
              className={css.folderAction}
              onClick={openModal('PRESENTATION')}
              onKeyDown={handleEnter(openModal('PRESENTATION'))}
            >
              <img
                alt="PRESENTATION icon"
                src="https://drive-thirdparty.googleusercontent.com/16/type/application/vnd.google-apps.presentation"
              />
              <Body>Create Google Slides</Body>
            </div>
            {/* doesn't seem to allow embeds <div className={css.folderAction} onClick={openModal('JAMBOARD')}>
          <img alt="document icon" src="https://drive-thirdparty.googleusercontent.com/16/type/application/vnd.google-apps.jam" />
          <Body>Create Google Jamboard</Body>
        </div> */}
          </div>
        )}
        <Modal
          title="Enter a name for your new file"
          isOpen={modalOpen}
          onSubmit={() => createDoc()}
          onClose={() => setModalOpen(false)}
          submitText="Create"
        >
          <InputText
            value={fileName}
            onChange={(e) => setFileName(e.target.value)}
            placeholder="Document name"
            isValid={docNameValid}
            validationMessage="Name cannot be blank"
          />
        </Modal>
      </div>
    </ClickAwayListener>
  );
};

FolderActions.propTypes = {
  sessionId: PropTypes.string,
  createCollaborativeFileInSession: PropTypes.func,
  studentInfo: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => ({
  createCollaborativeFileInSession: (sessionId, fileName, mimeType, studentId) =>
    dispatch(createCollaborativeFileInSession(sessionId, fileName, mimeType, studentId)),
});

export default connect(() => ({}), mapDispatchToProps)(FolderActions);
