import { connect } from 'react-redux';
import { compose } from 'redux';
import { withAppContext } from 'components/enhancers/AppContext';
import { createFlagReport } from 'ducks/messaging';
import Message from './Message';

export default compose(
  connect(
    (state) => ({}),
    (dispatch, ownProps) => ({
      createFlagReport: (flaggedPersonUserId, flagType, flagReason, messageId) =>
        dispatch(createFlagReport(flaggedPersonUserId, flagType, flagReason, messageId)),
    }),
  ),
)(withAppContext(Message));
