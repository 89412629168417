import React from 'react';
import { StyledModal } from './index.style';
import { ModalProps } from 'antd/lib/modal';

export type OtherModalProps = Omit<ModalProps, 'open' | 'onCancel' | 'title'>;

type BasicModalProps = {
  title?: string;
  isOpen: boolean;
  onCancel: () => void;
  children?: React.ReactNode;
} & OtherModalProps;

const BasicModal = ({ title, isOpen, onCancel, children, ...restProps }: BasicModalProps) => {
  return (
    <StyledModal
      footer={null}
      keyboard={false}
      closable
      destroyOnClose
      open={isOpen}
      title={title}
      onCancel={onCancel}
      {...restProps}
    >
      {children}
    </StyledModal>
  );
};

export default BasicModal;
