import Immutable from 'immutable';
import createReducer from 'utils/createReducer';
import packageService from 'graphql/api/package';
import packageItemService from 'graphql/api/packageItem';
import { addEntitiesWithNormalisation, ADD_ENTITIES } from 'ducks/normalizr';
import { packageEntity, packageItemEntity, userEntity } from 'schema';
import { updateMeta } from 'ducks/meta';
import componentKeys from 'constants/componentKeys';
import oms from 'graphql/oms';
import _ from 'lodash';

const initialState = new Immutable.Map();

const UPDATE_TUTOR_RECOMMENDATIONS = 'packageItem/UPDATE_TUTOR_RECOMMENDATIONS';

export default createReducer(initialState, {
  [ADD_ENTITIES]: (state, action) => {
    return state.mergeDeep(action.payload.entities.packageItem);
  },
  [UPDATE_TUTOR_RECOMMENDATIONS]: (state, action) => {
    const { id, algorithm, version, tutors } = action.payload;
    return state.setIn(
      [id, 'tutorRecommendations'],
      Immutable.fromJS(tutors.map((t) => ({ ...t, algorithm, version }))),
    );
  },
});

export function updateTutorRecommendationsData(payload) {
  return { type: UPDATE_TUTOR_RECOMMENDATIONS, payload };
}

export function fetchPackageItemById(id) {
  return (dispatch) => {
    return packageItemService.getItemById(id).then(({ item }) => {
      dispatch(addEntitiesWithNormalisation(item, packageItemEntity));
    });
  };
}

// Fetch tutor recommendations for the given package item.
export function fetchTutorRecommendations(packageItemId) {
  return async (dispatch) => {
    const response = await packageItemService.fetchTutorRecommendations(packageItemId);

    dispatch(updateTutorRecommendationsData(response.recommendations));
    return dispatch(addEntitiesWithNormalisation(response.recommendations.tutors, [userEntity]));
  };
}

export function fetchMyPackageItems() {
  return (dispatch) => {
    dispatch(updateMeta(componentKeys.PACKAGE_ITEMS_FETCHED, false));
    return oms
      .fetchUserOrders(['PENDING', 'CANCELLED'], ['CRIMSON_APP_SUBJECTS'])
      .then(({ data }) => {
        const purchasedItems = _.flatMap(data.orders, ({ lineItems, ...order }) =>
          _.map(lineItems, (item) => ({ ...item, ...order })),
        );
        const groupedPurchasedItems = _.groupBy(_.filter(purchasedItems, { source: 'CRIMSON_APP_SUBJECTS' }), 'itemId');
        return packageService.myPackages().then(({ myPackages }) => {
          const packageItems = myPackages
            .reduce((prev, curr) => (curr.items ? prev.concat(curr.items) : prev), [])
            .map((item) => {
              if (item.id) {
                const purchaseItem = _.head(_.orderBy(groupedPurchasedItems[item.id], 'createdAt', 'desc'));
                item.lastPurchase = purchaseItem;
              }
              return item;
            });
          const userPackages = myPackages.map(({ items, ...p }) => p);
          dispatch(addEntitiesWithNormalisation(userPackages, [packageEntity]));
          dispatch(addEntitiesWithNormalisation(packageItems, [packageItemEntity]));
          dispatch(updateMeta(componentKeys.PACKAGE_ITEMS_FETCHED, true));
        });
      })
      .catch(() => {
        return packageService.myPackages().then(({ myPackages }) => {
          const packageItems = myPackages.reduce((prev, curr) => (curr.items ? prev.concat(curr.items) : prev), []);
          dispatch(addEntitiesWithNormalisation(packageItems, [packageItemEntity]));
          dispatch(updateMeta(componentKeys.PACKAGE_ITEMS_FETCHED, true));
        });
      });
  };
}
