import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import UserSection from 'components/molecules/CardSection/User';
import SubjectSection from 'components/molecules/CardSection/Subject';

import Card from 'components/molecules/Card';
import Modal from 'components/molecules/Modal';
import Button from 'components/molecules/Button';

import { ExpandLess, ExpandMore } from 'components/atoms/icons';
import { contractStatusTypes } from '@crimson-education/common-config';

import css from './styles.scss';
import { handleEnter } from '../../../utils/accessibility';

class StudentCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      modal: false,
      modalAllocated: false,
    };

    this.onAccept = this.onAccept.bind(this);
    this.alreadyAllocatedAccept = this.alreadyAllocatedAccept.bind(this);
    this.getParentSubjectName = this.getParentSubjectName.bind(this);
  }

  componentDidMount() {
    const subjectIds = this.props.user.subjects.map((sub) => sub.subjectId);
    this.props.getAncestorsOfSubjectTree(subjectIds);
  }

  onAccept(user) {
    if (user.hasResponded === false && user.status === contractStatusTypes.RETRACTED) {
      this.setState({ modal: false, modalAllocated: true });
    } else {
      this.props.onAccept();
      this.setState({ modal: false });
    }
  }

  getParentSubjectName(subjectId) {
    if (!subjectId) return '';
    const category = this.props.subjectCategories[subjectId];
    if (!category) return '';
    const parentId = category.parentId;
    if (!parentId) return '';

    return this.props.subjectCategories[parentId].name;
  }

  alreadyAllocatedAccept() {
    this.props.onAccept();
    this.setState({ modalAllocated: false });
  }

  renderSubject(subject, isPast) {
    return (
      <SubjectSection
        key={subject.id}
        isPast={isPast}
        subject={subject}
        parentSubject={this.getParentSubjectName(subject.subjectId)}
      />
    );
  }

  render() {
    const { user, isPending, isPast, primaryLanguage, languages, onDecline } = this.props;
    const subjects = user.subjects || [];
    let studentTimezoneOffset = '';
    if (user && user.timezone) {
      const timezone = moment.tz(user.timezone);
      const offset = timezone.format('ZZ');
      const abbreviation = timezone.format('zz');
      studentTimezoneOffset = `${abbreviation} (GMT ${offset})`;
    }
    const primaryLanguageText = primaryLanguage && `${primaryLanguage} (Primary)`;
    const languagesText = [primaryLanguageText, ...languages].filter((i) => i);

    return (
      <Card className={css.container}>
        <UserSection user={user} roleText="Student" />
        <div className={isPending ? css.topPadding : ''}>
          {this.state.expanded ? subjects.map((x) => this.renderSubject(x)) : this.renderSubject(subjects[0], isPast)}
        </div>
        {isPending ? (
          <div>
            <div className={css.pendingContainer}>
              <div className={css.subjectText}>Timezone</div>
              <div className={css.roleText}>{studentTimezoneOffset || 'Timezone not set yet'}</div>
            </div>
            <div className={css.pendingContainer}>
              <div className={css.subjectText}>Language</div>
              <div className={css.roleText}>
                {languagesText.length ? languagesText.join(', ') : 'Languages not added yet'}
              </div>
            </div>
            <div className={css.pendingContainer}>
              <div className={css.subjectText}>Frequency of tutoring</div>
              <div className={css.roleText}>
                {user.invitationDetails.tutoringFrequency || 'Frequency not added yet'}
              </div>
            </div>
            <div className={css.pendingContainer}>
              <div className={css.subjectText}>Preferred times</div>
              <div className={css.roleText}>
                {user.invitationDetails.preferredTimes || 'Preferred times not added yet'}
              </div>
            </div>
            <div className={css.buttonContainer}>
              <Button
                dataTestId={`declineContractButton-${user.email}`}
                className={css.button}
                secondary
                onClick={onDecline}
              >
                Decline
              </Button>
              <Button
                dataTestId={`acceptContractButton-${user.email}`}
                className={css.button}
                onClick={() => {
                  this.setState({ modal: true });
                }}
              >
                Accept
              </Button>
            </div>
          </div>
        ) : null}
        {subjects.length > 1 ? (
          <div
            role="button"
            tabIndex={0}
            data-ga-label="More subjects"
            className={css.footer}
            onClick={() => this.setState({ expanded: !this.state.expanded })}
            onKeyDown={handleEnter(() => this.setState({ expanded: !this.state.expanded }))}
          >
            {this.state.expanded ? (
              <React.Fragment>
                Collapse <ExpandLess />
              </React.Fragment>
            ) : (
              <React.Fragment>
                +{subjects.length - 1} more subjects <ExpandMore />
              </React.Fragment>
            )}
          </div>
        ) : null}
        <Modal
          isOpen={this.state.modal}
          title="Are you sure?"
          submitText="Accept"
          onSubmit={() => this.onAccept(user)}
          onSubmitDataTestId="acceptRequestConfirmButton"
          onClose={() => this.setState({ modal: false })}
        >
          Awesome! You are about to be assigned a new student. Please double check that you can commit to these hours
          before accepting.
        </Modal>
        <Modal
          isOpen={this.state.modalAllocated}
          title="Already allocated"
          submitText="Ok"
          onSubmit={() => this.alreadyAllocatedAccept()}
          onSubmitDataTestId="acceptAlreadyAllocatedRequest"
          onClose={() => this.setState({ modalAllocated: false })}
        >
          Sorry this request may have already been accepted by another tutor.
        </Modal>
      </Card>
    );
  }
}

StudentCard.propTypes = {
  user: PropTypes.object,
  getAncestorsOfSubjectTree: PropTypes.func,
  subjectCategories: PropTypes.object,
  onAccept: PropTypes.func,
  onDecline: PropTypes.func,
  isPending: PropTypes.bool,
  isPast: PropTypes.bool,
  primaryLanguage: PropTypes.string,
  languages: PropTypes.array,
};

export default StudentCard;
