import React from 'react';
import { Count, Tag } from './index.style';
import { StyledTableCell, Row } from '../../index.style';
import { getTagColor } from '../../util';

export type UniversityNameHeaderProps = {
  count: number;
  groupText: string;
  index: number;
};

const TAG_STYLE_MAP = {
  'EA/ED': {
    color: '#6C63FF',
    backgroundColor: '#6C63FF30',
  },
  RD: {
    color: '#3B86FE',
    backgroundColor: '#3B86FE30',
  },
  Other: {
    color: '#57AEC2',
    backgroundColor: '#57AEC230',
  },
  Reach: {
    color: '#6C63FF',
    backgroundColor: '#6C63FF30',
  },
  Target: {
    color: '#3B86FE',
    backgroundColor: '#3B86FE30',
  },
  Safety: {
    color: '#57AEC2',
    backgroundColor: '#57AEC230',
  },
};

const UniversityNameHeader = ({ count, groupText, index }: UniversityNameHeaderProps) => {
  return (
    <StyledTableCell style={{ width: '35%' }} align={'left'}>
      <Row style={{ marginTop: 'auto' }} rowcenter>
        <Tag style={{ ...(TAG_STYLE_MAP[groupText as keyof typeof TAG_STYLE_MAP] || getTagColor(index)) }}>
          {groupText}
        </Tag>
        <Count>{`(${count})`}</Count>
      </Row>
    </StyledTableCell>
  );
};

export default UniversityNameHeader;
