import { createSelector } from 'reselect';

const languages = (state) => state.get('languages');

export const allLanguages = createSelector(languages, (languages) =>
  languages
    .valueSeq()
    .sortBy((language) => language.get('language'))
    .toJS(),
);

export const getLanguage = (languageId) =>
  createSelector(languages, (languages) => languageId && languages.getIn([`${languageId}`, 'language']));

export const getLanguages = (languageIdArray) =>
  createSelector(languages, (languages) => languageIdArray.map((id) => languages.getIn([`${id}`, 'language'])));
