import styled from 'styled-components';
import { Column } from '../../../index.style';

export const Count = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */
  display: flex;
  align-items: center;
  text-align: right;
  color: #1d1e2b;

  > span:first-child {
    color: #a9acc0;
  }
`;

export const Label = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #111827;
`;

export const ProgressBarTrack = styled.div`
  border-radius: 2px;
  background: #f3f6fa;
  width: 100%;
  height: 8px;

  > div:first-child {
    border-radius: 2px;
    height: 100%;
    transition: opacity 0.4s;
    &:hover {
      opacity: 0.7;
    }
  }
`;

export const StyledColumn = styled(Column)`
  transition: opacity 0.4s;
  &:hover {
    opacity: 0.7;
  }
`;
