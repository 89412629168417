import { connect } from 'react-redux';
import { compose } from 'redux';
import componentKeys from 'constants/componentKeys';
import { getMetaItem } from 'selectors/meta';
import { getInvoices } from 'selectors/invoicing';
import { withAppContext } from 'components/enhancers/AppContext';
import AuthorisationCheck from 'components/wrappers/AuthorisationCheck/index';
import { PermissionTypes } from '@crimson-education/common-config';

import InvoicingComponent from './Invoicing';

const mapStateToProps = (state) => ({
  selectedInvoiceId: getMetaItem(componentKeys.INVOICE_SELECTED_ID)(state),
  invoices: getInvoices(state),
  fetched: getMetaItem(componentKeys.INVOICES_FETCHED)(state),
});

const withAuthorisation = AuthorisationCheck(PermissionTypes.VIEW_INVOICES);

export const Invoicing = compose(withAppContext, connect(mapStateToProps, null))(InvoicingComponent);

const UserProfileInvoicing = withAuthorisation(Invoicing);

export default UserProfileInvoicing;
