import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import moment from 'moment';
import { Acl } from '@crimson-education/common-config';

import { Table, Column } from 'components/molecules/Table';
import Modal from 'components/molecules/Modal';

import css from './styles.scss';
import { handleEnter } from '../../../../utils/accessibility';

class LessonSharedFilesPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deleteFileName: '',
      isModalOpen: false,
    };

    this.onCancel = this.onCancel.bind(this);
    this.getFileTreePath = this.getFileTreePath.bind(this);
    this.openDeleteConfirm = this.openDeleteConfirm.bind(this);
    this.deleteSharedFile = this.deleteSharedFile.bind(this);
  }

  onCancel() {
    this.setState({
      isModalOpen: false,
    });
  }

  // on file delete from shared files tab we traverse the conversations json tree and
  // delete the right file from within the tree so the view in conversations matches view in shared files.
  getFileTreePath(fileId) {
    const { comments } = this.props;
    const conversations = comments.get('conversations').toJS();

    if (fileId) {
      const conversationKeys = Object.keys(conversations);
      for (let i = 0; i < conversationKeys.length; i += 1) {
        const commentId = conversationKeys[i];
        const rootComment = conversations[commentId];
        if (rootComment.file && rootComment.file.id === fileId) {
          return { matchedCommentId: commentId, matchedReplyId: null };
        }
        if (rootComment.replies) {
          const commentReplyIds = Object.keys(rootComment.replies);
          for (let j = 0; j < commentReplyIds.length; j += 1) {
            const replyId = commentReplyIds[j];
            const reply = rootComment.replies[replyId];
            if (reply.file && reply.file.id === fileId) {
              return { matchedCommentId: commentId, matchedReplyId: replyId };
            }
          }
        }
      }
    }
    return false;
  }

  openDeleteConfirm(name = false, id) {
    if (name) this.setState({ deleteFileName: name, deleteFileId: id });
    this.setState({
      isModalOpen: true,
    });
  }

  deleteSharedFile() {
    const { onDeleteCommentFile } = this.props;
    const { deleteFileId } = this.state;
    const commentTreePath = this.getFileTreePath(deleteFileId);
    this.setState({
      isModalOpen: false,
    });
    onDeleteCommentFile(deleteFileId, commentTreePath.matchedCommentId, commentTreePath.matchedReplyId);
  }

  render() {
    const { comments, lesson, loginUser, onDownloadCommentFile, getCachedUsers } = this.props;
    const { isModalOpen, deleteFileName } = this.state;
    return (
      <div>
        <Table data={comments.get('files')} emptyMessage="No results">
          <Column dataKey="name" name="File name" renderer={({ rowData }) => rowData.get('name')} />
          <Column
            dataKey="createdAt"
            name="Date uploaded"
            renderer={({ rowData }) => moment(rowData.get('createdAt')).toString()}
          />
          <Column
            dataKey="userId"
            name="Uploaded by"
            renderer={({ rowData }) => {
              const submittedUser = rowData.get('userId');
              const clientUserId = lesson.get('client').get('userId');
              const tutorUserId = lesson.get('tutor').get('userId');
              const clientName = `${lesson.get('client').get('firstName')} ${lesson.get('client').get('lastName')}`;
              const tutorName = `${lesson.get('tutor').get('firstName')} ${lesson.get('tutor').get('lastName')}`;

              if (submittedUser === clientUserId) return clientName;
              if (submittedUser === tutorUserId) return tutorName;
              if (getCachedUsers.get(submittedUser)) return getCachedUsers.get(submittedUser).get('fullName');
              return '';
            }}
          />
          <Column
            dataKey="download"
            flex="0.2 0 0"
            align="right"
            name=""
            renderer={({ rowData }) => {
              const submittedUserId = rowData.get('userId');
              const loggedInUserId = loginUser.get('userId');
              if (submittedUserId === loggedInUserId || Acl.isAdmin(loginUser.get('userRoles'))) {
                return (
                  <span className={css.block}>
                    <div
                      role="button"
                      tabIndex={0}
                      className={css.download}
                      onClick={() => {
                        onDownloadCommentFile(rowData.get('id'));
                      }}
                      onKeyDown={handleEnter(() => {
                        onDownloadCommentFile(rowData.get('id'));
                      })}
                    >
                      <i className="zmdi zmdi-download" />
                    </div>
                    <div
                      role="button"
                      tabIndex={0}
                      className={css.delete}
                      onClick={() => {
                        this.openDeleteConfirm(rowData.get('name'), rowData.get('id'));
                      }}
                      onKeyDown={handleEnter(() => {
                        this.openDeleteConfirm(rowData.get('name'), rowData.get('id'));
                      })}
                    >
                      <i className="zmdi zmdi-delete" />
                    </div>
                  </span>
                );
              }
              return (
                <span className={css.block}>
                  <div
                    role="button"
                    tabIndex={0}
                    className={css.download}
                    onClick={() => {
                      onDownloadCommentFile(rowData.get('id'));
                    }}
                    onKeyDown={handleEnter(() => {
                      onDownloadCommentFile(rowData.get('id'));
                    })}
                  >
                    <i className="zmdi zmdi-download" />
                  </div>
                </span>
              );
            }}
          />
        </Table>
        <Modal
          className={css.deleteFileModal}
          isOpen={isModalOpen}
          onClose={this.onCancel}
          onSubmit={this.deleteSharedFile}
          submitLabel="Delete"
          title="DELETE FILE ?"
        >
          <div>{`Are you sure you want to delete “${deleteFileName}” from this comment.`}</div>
        </Modal>
      </div>
    );
  }
}

LessonSharedFilesPanel.propTypes = {
  comments: ImmutablePropTypes.map.isRequired,
  lesson: ImmutablePropTypes.map.isRequired,
  loginUser: ImmutablePropTypes.map.isRequired,
  getCachedUsers: ImmutablePropTypes.map,
  onDeleteCommentFile: PropTypes.func.isRequired,
  onDownloadCommentFile: PropTypes.func.isRequired,
};

LessonSharedFilesPanel.displayName = 'LessonSharedFilesPanel';

export default LessonSharedFilesPanel;
