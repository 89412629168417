import React, { useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { Container, Overview } from './index.style';
import OverviewStrip from './OverviewStrip';
import { AppTrackerContext } from '../viewController';
import { useApplicationsWithRegion } from './helper';
import { getViewableUser } from 'selectors/meta';

const StudentApplicationOverview = () => {
  const { state, controller } = useContext(AppTrackerContext)!;
  const { applications } = state;
  const userId = useSelector(getViewableUser);
  const applications1 = useApplicationsWithRegion(applications, 'US');
  const applications2 = useApplicationsWithRegion(applications, 'UK');
  const applications3 = useApplicationsWithRegion(applications, 'EU');
  const applications4 = useApplicationsWithRegion(applications, 'Other');

  useEffect(() => {
    controller.getApplicationOverviewsByUserId(userId);
  }, [controller, userId]);

  return (
    <Container>
      <Overview>Overview</Overview>
      {applications1.length > 0 && <OverviewStrip region={'US'} />}
      {applications2.length > 0 && <OverviewStrip region={'UK'} />}
      {applications3.length > 0 && <OverviewStrip region={'EU'} />}
      {applications4.length > 0 && <OverviewStrip region={'Other'} />}
    </Container>
  );
};
export default StudentApplicationOverview;
