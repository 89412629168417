import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import BackButton from 'components/molecules/BackButton';
import IconButton from 'components/molecules/IconButton';
import MobileModal from 'components/molecules/MobileModal';
import InputField from 'components/generic/Forms/InputField';
import Avatar from 'components/generic/Avatar';
import ChatName from './ChatName';
import css from './styles.scss';

export default class MessageHeader extends Component {
  constructor(props) {
    super(props);
    const { chatName, canEdit } = props;
    this.state = {
      isOpenModal: false,
      chatName,
      canEdit,
      inputValue: chatName,
      groupOpen: true,
    };
    this.saveEditGroupName = this.saveEditGroupName.bind(this);
    this.handleChatNameChange = this.handleChatNameChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  saveEditGroupName() {
    const { threadId, editGroupName } = this.props;
    const chatName = this.state.inputValue;
    if (chatName) {
      editGroupName(threadId, chatName);
      this.setState({ chatName });
    }
    this.setState(() => ({ isOpenModal: false }));
  }

  closeModal() {
    const chatName = this.state.chatName;
    this.setState({ inputValue: chatName });
    this.setState(() => ({ isOpenModal: false }));
  }

  handleChatNameChange(value) {
    this.setState({ inputValue: value });
  }

  render() {
    const {
      title,
      subject,
      editGroupName,
      threadId,
      chatType,
      canEdit,
      isMobile,
      openQuickBooking,
      onChangeState,
      threadUsers,
      thread,
    } = this.props;
    const { chatName, inputValue } = this.state;
    return (
      <div className={css.messageHeader}>
        <MobileModal
          className={css.editGroupModal}
          isOpen={this.state.isOpenModal}
          dataTestId="mobileChatNameModal"
          title="EDIT GROUP NAME"
          onClose={this.closeModal}
        >
          <span>New group name</span>
          <InputField
            className={css.groupNameInput}
            defaultValue={inputValue}
            value={inputValue}
            dataTestId="mobileChatNameInputField"
            onValueChange={this.handleChatNameChange}
          />
          <button className={css.saveButton} onClick={this.saveEditGroupName}>
            Save
          </button>
        </MobileModal>
        <BackButton className={css.messageBackButton} backButtonLink="/messages" />
        {thread.source && thread.type === 'group' && thread.source === 'COMMUNITY' ? (
          <Link to={`/community/${threadId}?message=true`}>
            <div className={css.communityHeader}>
              <Avatar avatar={thread.icon} large />
              <div className={css.info}>
                <h3>{thread.chatName}</h3>
                <span>
                  {threadUsers.length} {threadUsers.length === 1 ? 'member' : 'members'}
                </span>
              </div>
            </div>
          </Link>
        ) : (
          <div>
            {isMobile ? (
              <div className={css.headerDetails}>
                <h3>{title}</h3>
                {chatType === 'group' ? (
                  <div className={css.mobileEdit}>
                    {chatName || 'Set a group name'}{' '}
                    <IconButton
                      icon="Edit"
                      iconSize={{ height: '1rem', width: '1rem' }}
                      onClick={() => {
                        this.setState({ isOpenModal: true });
                      }}
                    />
                  </div>
                ) : (
                  subject && <p>{subject}</p>
                )}
              </div>
            ) : (
              <div className={css.headerDetails}>
                <h3>{title}</h3>
                {chatType === 'group' ? (
                  <ChatName chatName={chatName} threadId={threadId} editGroupName={editGroupName} canEdit={canEdit} />
                ) : (
                  subject && <p>{subject}</p>
                )}
              </div>
            )}
          </div>
        )}

        {chatType === 'private' ? (
          <div>
            <IconButton
              icon="Event"
              dataTestId="openQuickBooking"
              style={{ height: '2rem', width: '2rem' }}
              onClick={openQuickBooking}
            />
          </div>
        ) : (
          <div className={css.people}>
            <IconButton icon="People" onClick={onChangeState} className={css.peopleIcon} />
            <span>{threadUsers.length}</span>
          </div>
        )}
      </div>
    );
  }
}

MessageHeader.propTypes = {
  title: PropTypes.string,
  subject: PropTypes.string,
  threadUsers: PropTypes.array,
  chatName: PropTypes.string,
  threadId: PropTypes.string,
  editGroupName: PropTypes.func.isRequired,
  chatType: PropTypes.string,
  canEdit: PropTypes.bool,
  isMobile: PropTypes.bool,
  openQuickBooking: PropTypes.func,
  onChangeState: PropTypes.func,
  thread: PropTypes.object,
};
