export enum MessageEventType {
  PreSendMessage = 'pre send message',
  MessageSent = 'message sent',
  SendMessageError = 'send message error',
  LoadMessage = 'load message',
  TIMRecieveMessage = 'tim receive message',
  TIMSentMessage = 'tim send message success',
  TIMSentConsumeMessage = 'tim send consume message success',
  TIMSendConsumeMessageError = 'tim send consume message fail',
  TIMReceiveConsumeMessage = 'tim receive consume message',
  SendSessionSummary = 'Send session summary',
  SendSessionSummarySuccess = 'Session summary has been sent',
  SendSessionSummaryFail = 'Session summary has not been sent',
  AuthLogOut = 'auth logout',
  AuthExpired = 'auth: token expired',
  AuthSuccess = 'auth: auth success',
  AuthFail = 'auth: authorize fail',
  CreateRecurringSessionSuccess = 'booked recurring sessions success',
  InitRecurringSession = 'initialized a recurring session booking',
}
