export const BOOLEAN_FIELD = 'BOOLEAN_FIELD';
export const COMMENT_FIELD = 'COMMENT_FIELD';
export const RATING_FIELD = 'RATING_FIELD';

const sessionReport = [
  {
    key: 'goal',
    label: 'What was the aim of this session?',
    fieldType: COMMENT_FIELD,
  },
  {
    key: 'isProgressing',
    label: 'Is your student making progress?',
    buttons: ['Yes', 'No'],
    fieldType: BOOLEAN_FIELD,
  },
  {
    key: 'progress',
    label: "That's awesome! Care to elaborate?",
    fieldType: COMMENT_FIELD,
    depends: {
      key: 'isProgressing',
      value: 'Yes',
    },
  },
  {
    key: 'progress',
    label: 'Could you provide a bit more detail?',
    fieldType: COMMENT_FIELD,
    depends: {
      key: 'isProgressing',
      value: 'No',
    },
  },
  {
    key: 'effort',
    label: 'Effort grade for the session.',
    buttons: ['A', 'B', 'C', 'D'],
    criteria: [
      'The student is proactive and focused during \n lesson. Student is motivated to learn.',
      'The student shows interest in the learning \n process but is sometimes disengaged.',
      'The student is often disengaged.',
      'The student is passive and does not take initiative \n in the learning process.',
    ],
    fieldType: RATING_FIELD,
  },
  {
    key: 'isRoadblocked',
    label: 'Are you facing any road blocks?',
    buttons: ['Yes', 'No'],
    fieldType: BOOLEAN_FIELD,
  },
  {
    key: 'roadblockDetail',
    label: 'Please explain the current road blocks. This will be sent to their Student Success Manager',
    fieldType: COMMENT_FIELD,
    depends: {
      key: 'isRoadblocked',
      value: 'Yes',
    },
  },
];

export default sessionReport;
