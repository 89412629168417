import React, { useContext } from 'react';
import { Row } from '../../index.style';
import { Dot, Text, StyledClose } from './index.style';
import IconButton from '@material-ui/core/IconButton';
import DeleteApplicationButton from '../../components/DeleteApplicationButton';
import { AppTrackerContext } from '../../viewController';
import { hasAdmissionResult } from '../../util';
import { Application } from '../../type';
type HeaderBarProps = {
  closeModal: () => void;
  status: string;
  application: Application | null;
};
const HeaderBar = ({ closeModal, status, application }: HeaderBarProps) => {
  const { controller } = useContext(AppTrackerContext);
  const onConfirmDeleteOption = async (value: string, applicationId: string) => {
    const success = await controller.onConfirmDeleteItem(value, applicationId);
    if (success) controller.closeAdmissionDrawer();
    return success;
  };
  return (
    <Row rowcenter justifycontent={'space-between'}>
      <Row rowcenter>
        <Dot />
        <Text>{status}</Text>
      </Row>
      <Row style={{ marginRight: -30 }} rowcenter>
        {application && hasAdmissionResult(application) && (
          <DeleteApplicationButton
            options={[{ label: 'Remove the\nacceptance result', value: 'remove_admission_result' }]}
            onConfirmDeleteOption={onConfirmDeleteOption}
            application={application}
            text={'Are you sure you want to remove the admission result?'}
            menuItemStyle={{
              width: 110,
            }}
            anchorOrigin={{
              horizontal: 'right',
              vertical: 'bottom',
            }}
            transformOrigin={{
              horizontal: 'right',
              vertical: 'top',
            }}
          />
        )}
        <IconButton onClick={closeModal}>
          <StyledClose />
        </IconButton>
      </Row>
    </Row>
  );
};

export default HeaderBar;
