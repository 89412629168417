import React from 'react';
import { Container } from './index.style';
import { Title, Content } from './index.style';

const PLACEHOLDER_IMG_SRC = '/static/applicationTracker/setupApplication.png';
const Placeholder = () => {
  return (
    <Container>
      <img width={176} height={156} src={PLACEHOLDER_IMG_SRC} alt={'app_tracker_placeholder_img'} />
      <Title>{'Build your school list'}</Title>
      <Content>{'The universites that are added to your Final List will be\npre-populated here.'}</Content>
    </Container>
  );
};

export default Placeholder;
