import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { Body } from 'components/atoms/typography';
import IconButton from 'components/molecules/IconButton';
import Avatar from 'components/molecules/Avatar';
import { getAvatarColour } from 'utils/calendarUtils';

import fontColour from 'components/atoms/typography/fontColour.scss';
import flex from 'components/atoms/flex.scss';
import css from './style.scss';

export default function TutorCard(props) {
  const { tutor, onClose } = props;

  return (
    <div key={`${tutor.userId}-modalCard`} className={classNames(flex.row, css.wrapper)}>
      <div className={classNames(flex.col1, css.avatarContainer)}>
        <Avatar
          image={tutor.profileImageUrl}
          firstName={tutor.firstName}
          lastName={tutor.lastName}
          colourIndex={getAvatarColour(tutor.userId)}
        />
      </div>
      <div className={flex.col1}>
        <div className={classNames(flex.row1, css.userSummary)}>
          <div className={classNames(flex.col1, css.userInfo)}>
            <Link to={`/users/${tutor.userId}`} className={classNames(fontColour.primary, css.userName)}>
              <Body>{tutor.fullName}</Body>
            </Link>
            <Body className={classNames(fontColour.secondary, css.userSubtext)}>{tutor.email}</Body>
          </div>
          <IconButton dataGaLabel="Remove tutor" icon="Close" onClick={onClose} />
        </div>
      </div>
    </div>
  );
}

TutorCard.propTypes = {
  tutor: PropTypes.object,
  onClose: PropTypes.func,
};
