import React from 'react';
import PropTypes from 'prop-types';

import File from './File.js';
import css from './styles.scss';

const FileList = ({ sessionId, files = [], selected, onSelect, studentInfo }) => (
  <div className={css.fileList}>
    {files
      .sort((a, b) => Math.sign(new Date(b.createdTime) - new Date(a.createdTime)))
      .map((f) => (
        <File
          key={f.id}
          src={f}
          sessionId={sessionId}
          selected={selected === f.id}
          onSelect={onSelect}
          studentInfo={studentInfo}
        />
      ))}
  </div>
);

FileList.propTypes = {
  sessionId: PropTypes.string,
  files: PropTypes.array,
  selected: PropTypes.string,
  onSelect: PropTypes.func,
  studentInfo: PropTypes.object,
};

export default FileList;
