import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Avatar from 'components/generic/Avatar';

import css from './styles.scss';
import { handleEnter } from '../../../../../utils/accessibility';

export default function ContactItem({ user, className, onClick, selected, largeAvatar }) {
  const { userId, firstName, lastName, roles, profileImageUrl, city } = user;

  const cssClass = onClick ? css.peopleItemContainer : css.peopleItemContainerSimple;
  const primaryRole = roles.find((r) => r.isPrimary) || roles[0];
  const prettyUserRole = primaryRole.role.name;
  const rawUserRole = primaryRole.roleId;

  return (
    <div
      role="button"
      tabIndex={0}
      onClick={onClick}
      onKeyDown={handleEnter(onClick)}
      className={classNames(cssClass, selected && css.selected)}
    >
      <div className={classNames(css.avatarContainer, className)}>
        <Avatar
          userId={userId}
          firstName={firstName}
          lastName={lastName}
          dataTestId={`contactItem_${rawUserRole}_${firstName}`}
          avatar={profileImageUrl}
          large={largeAvatar}
          showStudentType
        />
      </div>
      <div className={css.peopleItem}>
        <div className={css.line1}>
          {firstName} {lastName}
        </div>
        <div className={classNames(css.line2, { [css.altColor]: !largeAvatar })}>{prettyUserRole}</div>
        <div className={classNames(css.line3, { [css.altColor]: !largeAvatar })}>{city}</div>
      </div>
    </div>
  );
}

ContactItem.propTypes = {
  user: PropTypes.object,
  className: PropTypes.string,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  largeAvatar: PropTypes.bool,
};
