import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Link from 'components/molecules/Link';
import Avatar from 'components/molecules/Avatar';
import profileImageUtil from 'utils/profileImage';
import { getAvatarColour } from 'utils/calendarUtils';
import css from './styles.scss';

export default function Details(props) {
  const { userId, person } = props;

  let fullName = 'Nobody Selected';
  let firstName = 'Nobody';
  let lastName = 'Selected';
  let image = '';
  let subtitle = 'None';
  let link;
  let avatarUserId;

  if (person) {
    // Check whether to display the fullname of "Myself".
    if (userId && person.get('userId') === userId) {
      fullName = 'Myself';
      avatarUserId = userId;
    } else {
      fullName = person.get('fullName');
      avatarUserId = person.get('userId');
    }

    // Define additional fields.
    subtitle = person.get('relationshipDescription');
    firstName = person.get('firstName');
    lastName = person.get('lastName');
    image = person.get('profileImageUrl');
    link = `/users/${person.get('userId')}/about`;

    // Resize image if exists.
    if (image) {
      image = profileImageUtil.getProfileUrlOfSize(image, profileImageUtil.sizes.p100);
    }
  }

  return (
    <div className={css.details}>
      <div className={css.initials}>
        <Avatar
          colourIndex={getAvatarColour(userId || person.get('userId'))}
          firstName={firstName}
          lastName={lastName}
          image={image}
          userId={avatarUserId}
          showStudentType
        />
      </div>
      <div className={css.column}>
        <Link
          refCallback={(node) => {
            if (node) node.setAttribute('data-ga-label', 'Person');
          }}
          to={link}
        >
          <div className={css.name}>{fullName}</div>
        </Link>
        <div className={css.subtitle}>{subtitle}</div>
      </div>
    </div>
  );
}

Details.propTypes = {
  userId: PropTypes.string,
  person: ImmutablePropTypes.map,
};
