import React from 'react';
import { Container } from './style';
import Header from './Header';
import UpdatesList from './UpdatesList';
import { useMissionControlUpdatesContext } from './helper';

const MissionControlUpdates = () => {
  const { state } = useMissionControlUpdatesContext();
  return (
    <Container $open={state.updatesModalOpen}>
      <Header />
      <UpdatesList />
    </Container>
  );
};

export default MissionControlUpdates;
