import { connect } from 'react-redux';
import { compose } from 'redux';

import { withAppContext } from 'components/enhancers/AppContext';
import { getLoginUser } from 'selectors/user';
import {
  isPageLoading,
  selectUserRoles,
  selectUserProfile,
  getZendeskLoginUrl,
  selectUserPermissions,
  selectUserPermissionInfo,
} from 'selectors/meta';
import { locationChange, fetchUserProfile, refreshZendeskLoginUrl } from 'ducks/meta';
import { fetchLoginUser, guessAndUpdateLoginUserTimezone, saveAppVersion } from 'ducks/user';
import {
  fetchThreads,
  markUserOnlineStatus,
  updateTypingStatus,
  messageConsumed,
  withdrawMessage,
  ackMessage,
} from 'ducks/messaging';
import { getAllIncompleteReports } from 'selectors/incompleteReport';
import { hasUnReadMessage, hasNewFriend } from 'selectors/globalState';
import { fetchBookingsRequiringFeedBack } from 'ducks/booking';
import AppWrapper from './AppWrapper';

export default compose(
  connect(
    (state) => ({
      loginUser: getLoginUser(state),
      reports: getAllIncompleteReports(state),
      hasUnReadMessage: hasUnReadMessage(state),
      isPageLoading: isPageLoading(state),
      loginUserRoles: selectUserRoles(state),
      myUserProfile: selectUserProfile(state),
      zendeskLoginUrl: getZendeskLoginUrl(state),
      loginUserPermissions: selectUserPermissions(state),
      loginUserPermissionsInfo: selectUserPermissionInfo(state),
      hasNewFriend: hasNewFriend(state),
    }),
    (dispatch) => ({
      fetchThreads: (userId, source, threadIds) => dispatch(fetchThreads(userId, source, threadIds)),
      fetchBookingsRequiringFeedBack: () => dispatch(fetchBookingsRequiringFeedBack()),
      fetchUserProfile: () => dispatch(fetchUserProfile()),
      fetchLoginUser: (userId) => dispatch(fetchLoginUser(userId)),
      refreshZendeskLoginUrl: () => dispatch(refreshZendeskLoginUrl()),
      locationChange: (pathname) => dispatch(locationChange(pathname)),
      guessAndUpdateLoginUserTimezone: (id) => dispatch(guessAndUpdateLoginUserTimezone(id)),
      saveAppVersion: (name, version, deviceId) => dispatch(saveAppVersion(name, version, deviceId)),
      markUserOnlineStatus: (userId, status) => dispatch(markUserOnlineStatus(userId, status)),
      updateTypingStatus: (userId, threadId) => dispatch(updateTypingStatus(userId, threadId)),
      messageConsumed: (data) => dispatch(messageConsumed(data)),
      withdrawMessage: (messageId, call) => dispatch(withdrawMessage(messageId, call)),
      ackMessage: (id, from, to, span) => dispatch(ackMessage(id, from, to, span)),
    }),
  ),
  withAppContext,
)(AppWrapper);
