import React from 'react';
import PropTypes from 'prop-types';
import { eventStatusTypes } from '@crimson-education/common-config';

import { isPast, isCurrent, isSessionSender } from 'utils/calendarUtils';
import Tag, { TAG_STYLE } from 'components/molecules/Tag';

export default function SessionStatus(props) {
  const { currentUser, session, bookAs, currentSession } = props;
  const { status, start, end, creatorUserId, otherUser } = currentSession ? currentSession : session;

  let statusText = status;
  const isSender = isSessionSender(currentUser.userId, creatorUserId);
  if (status === eventStatusTypes.CONFIRMED && isCurrent(start, end)) {
    statusText = 'In Progress';
  } else if (status === eventStatusTypes.TENTATIVE) {
    if (isSender) {
      statusText = `Pending (${otherUser.firstName})`;
    } else if (bookAs && bookAs.userId !== currentUser.userId) {
      statusText = `Pending (${bookAs.firstName})`;
    } else {
      statusText = 'Pending (You)';
    }
  }

  let style = TAG_STYLE.STROKE_GREY;
  if ((status === eventStatusTypes.CONFIRMED || status === eventStatusTypes.COMPLETED) && isPast(end, 'minutes')) {
    style = TAG_STYLE.FILL_GREY;
  } else if (status === eventStatusTypes.CONFIRMED && isCurrent(start, end)) {
    style = TAG_STYLE.FILL_BLUE;
  } else if (status === eventStatusTypes.CONFIRMED) {
    style = TAG_STYLE.FILL_JELLYBEAN;
  } else if (status === eventStatusTypes.TENTATIVE) {
    style = TAG_STYLE.STROKE_JELLYBEAN;
  }

  return <Tag style={style}>{statusText}</Tag>;
}

SessionStatus.propTypes = {
  currentSession: PropTypes.object,
  session: PropTypes.object,
  currentUser: PropTypes.object.isRequired,
  bookAs: PropTypes.object,
};
