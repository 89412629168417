import React from 'react';
import { useQuery } from '@apollo/client';
import { client } from 'graphql/api/metabase';
import { getFeatureFlagsByUserId } from 'graphql/api/user';
import { useUserId } from 'components/organisms/ApplicationTracker/helper';
import { useFeatureFlag } from 'featureSwitches';

export const withFeatureFlag = <P,>(flags: Array<string>, Comp: React.ComponentType<P>) => {
  const Enhanced = (props: P) => {
    const userId = useUserId();
    const { data, loading } = useQuery(getFeatureFlagsByUserId, {
      client,
      variables: {
        userId,
        flags,
      },
    });
    const FLAG_STUDENT_CENTER_TAB = useFeatureFlag('STUDENT_CENTER_BETA');

    return (
      <>
        {!loading && data?.res && (
          <Comp
            {...props}
            featureFlags={Object.assign({}, data?.res, { FLAG_STUDENT_CENTER_TAB: FLAG_STUDENT_CENTER_TAB })}
          />
        )}
      </>
    );
  };
  return Enhanced;
};
