import React, { useMemo } from 'react';
import { Row } from '../../index.style';
import { Input } from '../../components';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import { GroupByText, StyledMenuDropdown } from './index.style';
import {
  STUDENT_LIST_TYPE_OPTIONS,
  STUDENT_LIST_SERVICE_OPTIONS,
  STUDENT_LIST_TYPE_LABEL_MAP,
  STUDENT_LIST_GROUP_OPTIONS,
  STUDENT_LIST_GROUP_OPTIONS_MAP,
} from '../../constants';
import { getLabelFromOptionMap } from '../../../util';
import { orderBy } from 'lodash';
import { useStaffDashboardContext, useStaffRole } from '../../helper';
import { getUserDisplayName } from '../../util';

type ToolBarProps = {
  searchText: string;
  setSearchText: (value: string) => void;
  selectedType: null | string;
  setSelectedType: (value: string) => void;
  selectedStaff: null | string;
  setSelectedStaff: (value: string) => void;
  selectedGroupBy: string | null;
  setSelectedGroupBy: (value: string) => void;
};
const ALL_SSM_OPTION = {
  label: 'All SSMs',
  value: '*',
};
const ALL_STRATEGIST_OPTION = {
  label: 'All Strategists',
  value: '*',
};
const OPTION_MAP = {
  Strategist: ALL_SSM_OPTION,
  SSM: ALL_STRATEGIST_OPTION,
};
const OPTION_PLACEHOLDER = {
  Strategist: 'SSM',
  SSM: 'Strategist',
  Other: 'Strategist',
};
const ToolBar = ({
  searchText,
  setSearchText,
  selectedType,
  setSelectedType,
  selectedStaff,
  setSelectedStaff,
  selectedGroupBy,
  setSelectedGroupBy,
}: ToolBarProps) => {
  const { state } = useStaffDashboardContext();
  const userRole = useStaffRole();
  const STAFF_OPTIONS = useMemo(() => {
    const list = orderBy(state.staffInfos, ['firstName', 'lastName']).map((o) => ({
      label: getUserDisplayName(o),
      value: o.userId,
    }));
    if (userRole !== 'Other') return [OPTION_MAP[userRole], ...list];
    return list;
  }, [state.staffInfos, userRole]);
  const _selectedStaff = useMemo(() => {
    if (selectedStaff === '*') return selectedStaff;
    const found = state.staffInfos.find((o) => o.userId === selectedStaff);
    if (found) return getUserDisplayName(found);
    return null;
  }, [state.staffInfos, selectedStaff]);
  return (
    <Row justifycontent={'space-between'} gap={'12px'}>
      <Row gap={'12px'}>
        <Input
          placeholder={'Search by student’s name'}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          rightIcon={(focused) =>
            focused || !!searchText.length ? (
              <IconButton style={{ padding: 3 }} onClick={() => setSearchText('')}>
                <ClearIcon />
              </IconButton>
            ) : (
              <SearchRoundedIcon style={{ margin: 3 }} />
            )
          }
        />
        <StyledMenuDropdown data={STUDENT_LIST_TYPE_OPTIONS} onClickItem={setSelectedType} placeholder={'By Type'}>
          {getLabelFromOptionMap(STUDENT_LIST_TYPE_LABEL_MAP, selectedType)}
        </StyledMenuDropdown>
        <StyledMenuDropdown
          data={STAFF_OPTIONS}
          onClickItem={setSelectedStaff}
          placeholder={`By ${OPTION_PLACEHOLDER[userRole]}`}
        >
          {_selectedStaff === '*'
            ? OPTION_MAP[userRole as keyof typeof OPTION_MAP]?.label || _selectedStaff
            : _selectedStaff}
        </StyledMenuDropdown>
        {/* <StyledMenuDropdown
          data={FAKE_DATA}
          onClickItem={() => void 0}
          placeholder={'By App Mentor'}
        ></StyledMenuDropdown> */}
      </Row>
      <StyledMenuDropdown
        innerButtonStyle={{ borderRadius: 999 }}
        data={STUDENT_LIST_GROUP_OPTIONS}
        onClickItem={setSelectedGroupBy}
        menuItemStyle={{
          width: 250,
        }}
      >
        <GroupByText style={{ color: '#A9ACC0', marginLeft: 8, marginRight: 5 }}>
          {'Group by: '}
          <GroupByText>{getLabelFromOptionMap(STUDENT_LIST_GROUP_OPTIONS_MAP, selectedGroupBy)}</GroupByText>
        </GroupByText>
      </StyledMenuDropdown>
    </Row>
  );
};

export default ToolBar;
