import Immutable from 'immutable';
import { createSelector } from 'reselect';

const session = (state) => state.get('session');

export const notes = createSelector(session, (x) => x.getIn(['entities', 'note']));

export const sessionLogs = createSelector(session, (x) => x.getIn(['entities', 'sessionLog']));

export const getNotes = (eventId) => createSelector(notes, (x) => x.get(`${eventId}`) || new Immutable.Map());

export const hasNotes = (eventId) =>
  createSelector(notes, (x) => {
    const note = x.get(`${eventId}`);
    if (!note) {
      return false;
    }

    return !!note.get('text');
  });

export const isCall = (eventId) =>
  createSelector(sessionLogs, (sessionLog) => {
    if (!sessionLog) {
      return false;
    }

    const log = sessionLog.toList().filter((x) => eventId && +x.get('eventId').toString() === eventId.toString());

    return !!(
      log.find((x) => x.get('log') === 'participant.joined') && log.find((x) => x.get('log') === 'host.joined')
    );
  });
