import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
export const StyledInput = styled(TextField)<{ $grey: boolean }>`
  & .MuiInputBase-root {
    border: none;
    height: 36px;
    font-size: 14px;
    font-family: Montserrat;
    font-weight: 500;
    color: #1d1e2b;
  }
  & .MuiOutlinedInput-multiline {
    padding-left: 0;
  }
  & fieldset {
    border: none;
  }
  & .MuiOutlinedInput-inputMultiline::placeholder {
    color: ${(props) => (props.$grey ? '#A9ACC0' : '#1D1E2B')};
    opacity: 1;
  }
`;
