import MultiSelectFilter from './multiSelect.js';

export default class CityFilter extends MultiSelectFilter {
  constructor(...args) {
    super(...args);
    this.title = 'City';
    this.name = 'City';
  }

  apply(tutor) {
    if (!this.active()) {
      return true;
    }
    return this.state.filtered.some((city) => tutor.city === city);
  }

  data({ tutors }) {
    this.setState({ all: tutors.map((t) => t.city) });
  }

  value() {
    return this.state.filtered;
  }

  simpleValue() {
    return this.value().join(', ');
  }
}
