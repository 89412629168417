import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Avatar from '@material-ui/core/Avatar';
import { ListItemAvatar, ListItemText } from '@material-ui/core';

import { getStatusIcon, reportStatus } from '../../utils';
import css from '../styles.scss';

export default function StudentReportItem(props) {
  const { student, status } = props;
  return (
    <>
      <ListItemAvatar>
        <Avatar style={{ backgroundColor: 'var(--input_primary_color_bkg)' }}>
          {`${student.first_name[0]}${student.last_name[0]}`}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <h3
            className={classnames('typography', 'bread-crumb', css.selectItemHeader)}
          >{`${student.first_name} ${student.last_name}`}</h3>
        }
        secondary={
          <React.Fragment>
            {getStatusIcon(status)}
            <span className={classnames('typography', 'grow', css.selectItemSubHeader)}>{reportStatus[status]}</span>
          </React.Fragment>
        }
      />
    </>
  );
}

StudentReportItem.propTypes = {
  status: PropTypes.string,
  student: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
  }),
};
