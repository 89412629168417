/* eslint-disable jsx-a11y/anchor-is-valid */
import 'flatpickr/dist/flatpickr.css';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';
import classNames from 'classnames';
import CalendarIcon from 'components/molecules/CalendarIcon';
import InputWarningMessage from 'components/molecules/InputWarningMessage';
import { parseDateFunction, setToday } from 'utils/datepicker';
import fontColour from 'components/atoms/typography/fontColour.scss';
import font from 'components/atoms/typography/font.scss';
import css from './styles.scss';

const dateFormat = 'd/m/Y';
const altFormat = 'd M Y';
const momentFormat = 'DD/MM/YYYY';
const momentAltFormat = 'DD MMM YYYY';

export default class Datepicker extends Component {
  constructor(props) {
    super(props);
    const { date, allowEmpty, timezone } = props;

    let defaultDate = null;
    let defaultMoment;

    // Set Date if provided.
    if (date) {
      defaultMoment = moment(date);
    } else if (!allowEmpty) {
      defaultMoment = moment();
    }

    // Setup Default Date.
    if (defaultMoment) {
      if (timezone) {
        defaultMoment = defaultMoment.tz(timezone);
      }

      defaultDate = defaultMoment.format(momentFormat);
    }

    this.state = {
      date: defaultDate,
    };

    this.onDateChange = this.onDateChange.bind(this);
    this.updateDatepickerInput = this.updateDatepickerInput.bind(this);
    this.setDefaultToEmpty = this.setDefaultToEmpty.bind(this);
    this.closeDatePicker = this.closeDatePicker.bind(this);
  }

  onDateChange(selectedDates, dateStr, instance) {
    const { onChange, allowEmpty, timezone } = this.props;

    let date = dateStr ? moment(dateStr, momentFormat) : null;

    if (!date && !allowEmpty) {
      date = moment(this.state.date, momentFormat);
      instance.setDate(this.state.date, false);
    }

    if (date && timezone) {
      date = date.tz(timezone);
    }

    this.setState({ date: date ? date.format(momentFormat) : null }, () => {
      onChange(date);
      instance.close();
      this.updateDatepickerInput(selectedDates, dateStr, instance);
    });
  }

  setDefaultToEmpty(selectedDates, dateStr, instance) {
    instance.open();
  }

  closeDatePicker(selectedDates, dateStr, instance) {
    const { onClose } = this.props;
    if (!instance.isOpen && !selectedDates.length) {
      instance.input.blur();
      setTimeout(() => {
        onClose && onClose();
      }, 0);
    }
  }

  updateDatepickerInput(selectedDates, dateStr, instance) {
    const { date } = this.state;

    if (date) {
      const isOpen = instance.isOpen;
      const newDate = isOpen
        ? moment(date, momentFormat).format(momentFormat)
        : moment(date, momentFormat).format(momentAltFormat);

      const newAltFormat = isOpen ? dateFormat : altFormat;

      instance.set('altFormat', newAltFormat);
      instance.altInput.value = newDate;
    }
  }

  render() {
    const { label, simpleInputStyle, isViewOnly, dataTestId, isValid, validationMessage, isDefaultEmpty } = this.props;
    const { date } = this.state;
    const options = Object.assign(
      {},
      {
        mode: 'single',
        dateFormat,
        altFormat,
        allowInput: true,
        altInput: true,
        altInputClass: simpleInputStyle ? css.simpleInputStyle : css.flatpickrInput,
        wrap: true,
        static: true,
        parseDate: parseDateFunction(date),
      },
    );

    return (
      <div
        className={classNames(
          { [css.datepickerContainer]: true },
          { [css.isViewOnly]: isViewOnly },
          { [css.error]: !isValid },
        )}
      >
        {label && (
          <label htmlFor="datepicker" className={classNames(font.caption, fontColour.secondary, css.label)}>
            {label}
          </label>
        )}
        <Flatpickr
          value={date}
          options={options}
          onOpen={this.updateDatepickerInput}
          onClose={this.closeDatePicker}
          onChange={this.onDateChange}
          onReady={isDefaultEmpty ? this.setDefaultToEmpty : setToday}
        >
          <input
            type="text"
            id={dataTestId}
            data-test-id={dataTestId}
            placeholder={momentFormat}
            data-input
            className={isViewOnly ? css.inputIsViewOnly : ''}
          />
          {!simpleInputStyle && (
            <a data-test-id="popupDateCalendarIcon" data-ga-label="DatePicker" className={css.calendarIcon} data-toggle>
              <CalendarIcon />
            </a>
          )}
        </Flatpickr>
        <span>{!isValid && <InputWarningMessage message={validationMessage} />}</span>
      </div>
    );
  }
}

Datepicker.defaultProps = {
  isValid: true,
};

Datepicker.propTypes = {
  isValid: PropTypes.bool,
  validationMessage: PropTypes.string,
  label: PropTypes.string,
  timezone: PropTypes.string,
  date: PropTypes.object,
  simpleInputStyle: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  isViewOnly: PropTypes.bool,
  allowEmpty: PropTypes.bool,
  dataTestId: PropTypes.string,
  isDefaultEmpty: PropTypes.bool,
  onClose: PropTypes.func,
};
