import { createSelector } from 'reselect';
import moment from 'moment';
import EventType from 'constants/eventType';
import { findSessionStartingSoon } from 'utils/sessionVideo';
import globalMessageTypes from 'constants/globalMessageTypes';
import {
  BookingStatusTypes,
  Acl,
  PermissionTypes,
  roleTypes,
  bookingReasonOptionTypes,
} from '@crimson-education/common-config';
import {
  getMetaItem,
  selectUserPermissions,
  getActiveSessionReport,
  getMeta,
  getReasonsForAbsence,
  getRoutePathname,
  getSessionSelectedId,
  selectUserRoles,
  selectUserId,
} from 'selectors/meta';
import { getBookingAs, getLoginUser, getBookingAsToJs, getLoginUserToJs } from 'selectors/user';
import componentKeys from 'constants/componentKeys';

const sessionTypesToShow = [
  BookingStatusTypes.TENTATIVE,
  BookingStatusTypes.CONFIRMED,
  BookingStatusTypes.COMPLETED,
  BookingStatusTypes.AUTO_COMPLETED,
];

const bookings = (state) => state.get('booking');

const bookingSelectors = {};
export const getBookingByIdToJS = (id) => {
  const idStr = id ? id.toString() : id;

  if (!bookingSelectors[idStr]) {
    bookingSelectors[idStr] = createSelector(bookings, (bookings) => {
      const b = bookings.get(idStr);
      return b && b.toJS ? b.toJS() : b;
    });
  }

  return bookingSelectors[idStr];
};

export const getBookingById = (id) => {
  return createSelector(bookings, (bookings) => bookings.get(id));
};

export const allBookings = createSelector(bookings, (bookings) => bookings);

export const allSessionsToShow = createSelector(bookings, (bookings) =>
  bookings.filter((b) => sessionTypesToShow.some((t) => t === b.get('status'))),
);

export const getAllBookingsRequiringFeedback = createSelector(bookings, (bookings) => {
  return bookings
    .filter((booking) => booking.get('status') === BookingStatusTypes.CONFIRMED && moment().isAfter(booking.get('end')))
    .sortBy((booking) => moment(booking.get('end')).valueOf())
    .toList();
});

export const getNextSessionRequiringFeedback = createSelector(
  getAllBookingsRequiringFeedback,
  selectUserPermissions,
  (bookings, loginUserPermissions) => {
    const canProvideFeedback = Acl.checkPermission(loginUserPermissions, PermissionTypes.FEEDBACK_TO_STRATEGIST);
    if (bookings.size && canProvideFeedback) {
      const otherUserRoles = bookings.first().get('otherUser').get('userRoles');
      return bookings.first().get('type') === EventType.Session &&
        (otherUserRoles.includes(roleTypes.STRATEGIST) || otherUserRoles.includes(roleTypes.CASE_MANAGER))
        ? bookings.first()
        : null;
    }
    return null;
  },
);

export const getStrategistOrEduCoSessionFeedback = createSelector(
  getAllBookingsRequiringFeedback,
  selectUserRoles,
  selectUserId,
  (bookings, userRoles, userId) => {
    const canProvideFeedback =
      Acl.checkRole(userRoles, roleTypes.STRATEGIST) || Acl.checkRole(userRoles, roleTypes.CASE_MANAGER);
    if (bookings.size && canProvideFeedback) {
      const filterBookings = bookings.filter(
        (booking) =>
          booking.get('start') >= moment('2020-04-13') &&
          booking.get('otherUser').get('userRoles').includes(roleTypes.STUDENT),
      );
      if (filterBookings.size) {
        const otherUserRoles = filterBookings.first().get('otherUser').get('userRoles');
        return filterBookings.first().get('type') === EventType.Session &&
          filterBookings.first().get('participantUserIds').includes(userId) &&
          filterBookings.first().get('eventStatus') !== 'completed' &&
          otherUserRoles.includes(roleTypes.STUDENT)
          ? filterBookings.first()
          : null;
      }
    }
    return null;
  },
);

export const getNextLessonRequiringFeedback = createSelector(
  getAllBookingsRequiringFeedback,
  selectUserRoles,
  (bookings, userRoles) => {
    const canProvideFeedback = Acl.checkRole(userRoles, roleTypes.TUTOR) || Acl.checkRole(userRoles, roleTypes.STUDENT);
    const lessonBookings = bookings.filter((b) => b.get('type') === EventType.Lesson);
    if (lessonBookings.size && canProvideFeedback) {
      return lessonBookings.first().get('type') === EventType.Lesson ? lessonBookings.first() : null;
    }
    return null;
  },
);

export const getNextBookingRequiringReport = createSelector(bookings, getActiveSessionReport, (bookings, id) => {
  return bookings.get(id);
});

export const getReasonsForCancellation = createSelector(getMeta, getBookingAs, (meta, bookingAs) => {
  if (!meta || !bookingAs) return [];
  const allReasons = meta.get(componentKeys.REASONS_FOR_CANCELLATION).toJS();
  let bookingType;

  switch (bookingAs.get('role')) {
    case roleTypes.STUDENT:
      bookingType = bookingReasonOptionTypes.STUDENT_CANCEL_LESSON;
      break;
    case roleTypes.TUTOR:
      bookingType = bookingReasonOptionTypes.TUTOR_CANCEL_LESSON;
      break;
    default:
      return [];
  }

  const reasons = allReasons[bookingType];
  return reasons ? reasons.sort((a, b) => a.order - b.order) : [];
});

export const getReasonsForAbsenceByRole = createSelector(
  getLoginUser,
  getReasonsForAbsence,
  (loginUser, reasonsForAbsence) => {
    if (!loginUser || !reasonsForAbsence) return [];
    const allReasons = reasonsForAbsence.toJS();
    const userRoles = loginUser.get('userRoles');
    let bookingType = [];

    if (userRoles.includes(roleTypes.STUDENT)) {
      bookingType = bookingReasonOptionTypes.ABSENCE_STUDENT;
    } else if (
      userRoles.includes(roleTypes.TUTOR) ||
      userRoles.includes(roleTypes.STRATEGIST) ||
      userRoles.includes(roleTypes.CASE_MANAGER)
    ) {
      bookingType = bookingReasonOptionTypes.ABSENCE_TUTOR;
    }

    const reasons = allReasons[bookingType];
    return reasons ? reasons.sort((a, b) => a.order - b.order) : [];
  },
);

export const getSessionEmailHistory = (id) => {
  return createSelector(getBookingById(id), (booking) => {
    return booking ? booking.sessionEmailHistory : null;
  });
};

export const getUserSessionStartingSoon = createSelector(allBookings, (bookings) => {
  if (!bookings) {
    return null;
  }
  const bookingToReturn = findSessionStartingSoon(bookings.toList().toJS());
  return bookingToReturn;
});

export const getSessionStartMessage = createSelector(
  getUserSessionStartingSoon,
  getMetaItem(componentKeys.SESSION_PROMPT_MODAL_OPEN),
  getRoutePathname,
  (session, isModalOpen, pathName) => {
    const messages = [];
    // Need to include test-prep session as the pathName for a test is test-prep/session
    if (session && !isModalOpen && (!pathName.includes('session/') || pathName.includes('test-prep/session'))) {
      if (moment().isBefore(moment(session.start))) {
        messages.push({
          type: globalMessageTypes.SESSION_STARTING_SOON,
          text: 'You have a session starting in ',
          link: `/session/${session.id}`,
          countdown: { end: session.start },
        });
      }
      if (
        session &&
        session.source === 'crimson-app' &&
        moment().isBetween(moment(session.start), moment(session.end))
      ) {
        messages.push({
          type: globalMessageTypes.SESSION_IN_PROGRESS,
          text: 'You have a session in progress.',
          link: `/session/${session.id}`,
        });
      }
    }
    return messages.filter((message) => message);
  },
);

export const getSelectedBooking = createSelector(getSessionSelectedId, allBookings, (id, allBookings) => {
  const sessionId = id ? id.toString() : null;
  const session = allBookings.get(sessionId);
  return session ? session.toJS() : null;
});

export const getSessionStudentId = createSelector(getSelectedBooking, (session) => {
  const student = session && session.participants.find((p) => p.userRoles.includes('STUDENT'));
  return student ? student.userId : null;
});

export const getEventUserIdForEvent = createSelector(
  getSelectedBooking,
  getBookingAsToJs,
  getLoginUserToJs,
  (session, bookAs, loginUser) => {
    if (bookAs && bookAs.userId !== loginUser.userId) {
      return bookAs.userId;
    }

    // Requirement is to default view to anyone but the student
    if (session && !session.participantUserIds.includes(loginUser.userId)) {
      const notStudent = session.participants.find((p) => !p.userRoles.includes('STUDENT'));
      return notStudent.userId;
    }
    return loginUser.userId;
  },
);

const getUpcomingBookings = createSelector(bookings, (bookings) =>
  bookings.filter((m) => moment(m.get('end')).isAfter(moment())).sort((a, b) => a.get('start') - b.get('start')),
);

const getPastBookings = createSelector(bookings, (bookings) =>
  bookings.filter((m) => moment(m.get('end')).isBefore(moment())).sort((a, b) => b.get('start') - a.get('start')),
);

export const getBookingSchedule = (userId) => {
  return createSelector(
    getUpcomingBookings,
    getPastBookings,
    getLoginUserToJs,
    getMetaItem(componentKeys.SESSION_SCHEDULE_TAB_IS_PAST),
    (upcoming, past, loginUser, isPast) => {
      const uId = userId || loginUser.userId;
      const bookings = isPast ? past : upcoming;
      const filterById = bookings.filter((b) => b.get('participantUserIds').includes(uId));
      const filterByStatus = filterById.filter((b) => sessionTypesToShow.some((t) => t === b.get('status')));
      const grouped = filterByStatus.groupBy((f) => moment(f.get('start')).startOf('day'));
      return grouped && grouped.size ? grouped.toJS() : null;
    },
  );
};

export const getLastUpcomingBookingDate = (userId) => {
  return createSelector(getUpcomingBookings, getLoginUserToJs, (bookings, loginUser) => {
    const uId = userId || loginUser.userId;
    const booking = bookings.filter((b) => b.get('participantUserIds').includes(uId)).last();
    return booking && booking.size ? moment(booking.get('start')).format() : null;
  });
};

export const getLastPastBookingDate = (userId) => {
  return createSelector(getPastBookings, getLoginUserToJs, (bookings, loginUser) => {
    const uId = userId || loginUser.userId;
    const booking = bookings.filter((b) => b.get('participantUserIds').includes(uId)).last();
    return booking && booking.size ? moment(booking.get('start')).format() : null;
  });
};
