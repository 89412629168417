import TableCell from '@material-ui/core/TableCell';
import styled from 'styled-components';

export const StyledTableCell = styled(TableCell)`
  font-weight: 500;
  font-size: 14px;
  line-height: 32px;
  color: #a9acc0;
  padding: 0;
  font-family: 'Montserrat';
`;

export const Count = styled.div`
  font-weight: 600;
  font-size: 18px;
  color: #a9acc0;
  margin-left: 7px;
  font-family: 'Montserrat';
`;

export const Tag = styled.div`
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 18px;
  color: #6c63ff;
  background: rgba(108, 99, 255, 0.15);
  border-radius: 4px;
  height: 32px;
  padding: 0 12px;
  line-height: 32px;
  transition: 0.5s;
`;
