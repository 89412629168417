import { connect } from 'react-redux';

import { fetchSessionLog, isCall } from 'ducks/session';
import Feedback from './Feedback';

export default connect(
  (state, props) => ({
    isCall: isCall(props.booking.id)(state),
  }),
  (dispatch) => ({
    fetchSessionLog: (eventId) => dispatch(fetchSessionLog(eventId)),
  }),
)(Feedback);
