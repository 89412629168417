import React from 'react';
import PropTypes from 'prop-types';

export default function VerySad({ className }) {
  return (
    <svg viewBox="0 0 64 64" enableBackground="new 0 0 64 64" className={className}>
      <circle fill="#ffdd67" cx="32" cy="32" r="30" />
      <g fill="#917524">
        <path d="m38.422 10.238c3.236 2.732 7.523 3.881 11.693 3.133.578-.113 1.088 2.02.385 2.156-4.809.863-9.756-.461-13.492-3.615-.541-.469.971-2.06 1.414-1.674" />
        <path d="m13.887 13.221c4.168.748 8.455-.4 11.691-3.133.443-.389 1.955 1.205 1.412 1.674-3.732 3.152-8.68 4.479-13.492 3.615-.703-.135-.191-2.27.389-2.156" />
      </g>
      <path
        fill="#fff"
        d="m16 49.5c0-6.461 5.314-14 16-14 10.689 0 16 7.539 16 14 0 0-5.311 0-16 0-10.686 0-16 0-16 0"
      />
      <g fill="#664e27">
        <path d="m15.916 49.699l2-8h28.16l2 8z" />
        <path d="m32 34.498c11.04 0 17 7.729 17 15v1h-34v-1c0-7.271 5.957-15 17-15m14.957 14c-.523-5.99-5.729-12-14.957-12s-14.434 6.01-14.957 12h29.914" />
        <circle cx="20.5" cy="26" r="4.5" />
        <circle cx="43.5" cy="26" r="4.5" />
      </g>
    </svg>
  );
}

VerySad.propTypes = {
  className: PropTypes.string,
};
