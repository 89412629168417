import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { gradeValidations } from '@crimson-education/common-config';
import Modal from 'components/molecules/Modal';
import GradesForm from 'components/organisms/GradesForm';
import SubjectSelector from 'components/molecules/SubjectSelector';
import styles from './styles.scss';

const NEW_TITLE = 'Add New Grade';
const EDIT_TITLE = 'Edit Grade';
const NEW_SUBMIT = 'Add grade';
const EDIT_SUBMIT = 'Save';
const NEW_SECONDARY = 'Close';
const EDIT_SECONDARY = 'Delete';
const SUBJECT = 'Subject';

function createEmptyGrade() {
  return {
    subjectId: undefined,
    date: undefined,
    results: [],
  };
}

function createDefaultGrade(subjectId, resultTypes, resultTypesTemplates) {
  const gradeData = {
    subjectId,
    date: undefined,
    results: [],
  };

  // Create each result type with its default value.
  resultTypes.forEach((resultType) => {
    gradeData.results.push({
      type: resultType,
      value: resultTypesTemplates[resultType].default,
    });
  });

  return gradeData;
}

// Process all results in gradeData that is about to be saved or submitted.
// E.g. change all instances of a+ to A+, or 95 to 95%.
function processAll(gradeData, resultTypesTemplates) {
  // Convert raw user input to its canonical form.
  const processedResults = gradeData.results.map((result) => {
    const { processor } = resultTypesTemplates[result.type];
    return Object.assign({}, result, { value: processor(result.value) });
  });
  return Object.assign({}, gradeData, { results: processedResults });
}

export default class GradesModal extends Component {
  constructor(props) {
    super(props);

    const { curriculumTemplates, getSubjectsByCurriculums } = props;

    // Get all subjects in the curriculums we have templates for.
    const subjects = getSubjectsByCurriculums(Object.keys(curriculumTemplates));

    this.state = {
      eagerValidation: false,
      gradeData: createEmptyGrade(),
      subjects,
    };

    this.validateResults = this.validateResults.bind(this);
    this.onSubjectChange = this.onSubjectChange.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      gradeId,
      getGradeById,
      getGradeResultById,
      subjectId,
      getSubjectCategoryById,
      curriculumTemplates,
      resultTypesTemplates,
    } = this.props;

    const { subjects } = this.state;

    if (!subjectId && nextProps.subjectId) {
      // It is possible to pass in a subjectId that the user can't search for normally.
      // (We do this for overall grades.)  In this case we need to go grab the subject
      // separately.
      subjects[nextProps.subjectId] = getSubjectCategoryById(nextProps.subjectId);

      const curriculumTemplate = curriculumTemplates[nextProps.subjectId];
      const gradeData = createDefaultGrade(
        nextProps.subjectId,
        curriculumTemplate.overallResultTypes,
        resultTypesTemplates,
      );

      this.setState({
        subjects,
        gradeData,
      });
    }

    // Check for being supplied a grade to edit.
    if (!gradeId && nextProps.gradeId) {
      const grade = getGradeById(nextProps.gradeId);

      // As above, this might be an overall grade.
      subjects[grade.subjectId] = getSubjectCategoryById(grade.subjectId);

      grade.results = grade.results.map((id) => getGradeResultById(id));
      // Check for any new Grade Result Types from the Curiculum Template
      const defaultResultTypes = curriculumTemplates[grade.subjectId]?.resultTypes.map((type) => ({
        type: type[0],
        value: resultTypesTemplates[type[0]].default,
      }));
      // If there are new result types, add them to the grade result type with their defaults
      if (defaultResultTypes) {
        grade.results = grade.results.concat(
          defaultResultTypes.filter((result) => !grade.results.find((r) => r.type === result.type)),
        );
      }
      this.setState({
        gradeData: grade,
      });
    }
  }

  onSubjectChange(subject) {
    const { getGradeTableConfigForSubject, curriculumTemplates, resultTypesTemplates } = this.props;

    let gradeData;

    if (!subject || (subject && subject.length === 0)) {
      gradeData = createEmptyGrade();
    } else {
      const subjectId = subject.value;
      const curriculum = getGradeTableConfigForSubject(subjectId, curriculumTemplates);
      const curriculumTemplate = curriculumTemplates[curriculum.id];
      gradeData = createDefaultGrade(
        subjectId,
        curriculumTemplate.resultTypes.map((group) => group[0]),
        resultTypesTemplates,
      );
    }

    this.setState({ gradeData });
  }

  onValueChange(field, value) {
    const gradeData = Object.assign({}, this.state.gradeData);

    // Set either a grade field or a result.
    if (field === 'date' || field === 'subjectId' || field === 'userId') {
      gradeData[field] = value;
    } else {
      const results = gradeData.results;
      const changedField = results.find((result) => result.type === field);
      changedField.value = value;
    }

    this.setState({ gradeData });
  }

  onClose() {
    const { onClose } = this.props;

    this.setState({
      eagerValidation: false,
      gradeData: createEmptyGrade(),
    });

    onClose();
  }

  onDelete() {
    const { onDelete } = this.props;
    const { gradeData } = this.state;

    onDelete([gradeData.id], 'grade');

    this.setState({
      eagerValidation: false,
      gradeData: createEmptyGrade(),
    });
  }

  onSubmit() {
    const { onSubmit, resultTypesTemplates } = this.props;
    const { gradeData } = this.state;

    if (!this.validateResults()) {
      this.setState({
        eagerValidation: true,
      });
      return;
    }

    onSubmit(processAll(gradeData, resultTypesTemplates));

    this.setState({
      eagerValidation: false,
      gradeData: createEmptyGrade(),
    });
  }

  onSave() {
    const { onSave, resultTypesTemplates } = this.props;
    const { gradeData } = this.state;

    if (!this.validateResults()) {
      this.setState({
        eagerValidation: true,
      });
      return;
    }

    onSave(processAll(gradeData, resultTypesTemplates));

    this.setState({
      eagerValidation: false,
      gradeData: createEmptyGrade(),
    });
  }

  validateResults() {
    const { resultTypesTemplates } = this.props;
    const { gradeData } = this.state;
    const context = { data: gradeData.results };

    // Check each recorded result against its validator.
    const isValidResult = (result) => {
      const { validator } = gradeValidations.gradeResultValidations[result.type];
      const { processor } = resultTypesTemplates[result.type];
      return validator(processor(result.value), context);
    };
    if (gradeData.subjectId === '29ed35ed-1952-4b76-963b-e8b5cd0e7ab7') {
      gradeData.date = moment();
    }
    const hasDate = gradeData.date !== undefined;

    // All results must be valid for validator to pass.
    return hasDate && gradeData.results.every(isValidResult);
  }

  render() {
    const { gradeId, subjectId, isOpen, curriculumTemplates, resultTypesTemplates } = this.props;

    const { gradeData, subjects, eagerValidation } = this.state;

    // Determine mode.
    const isEditing = !!gradeId;

    return (
      <Modal
        title={isEditing ? EDIT_TITLE : NEW_TITLE}
        submitText={isEditing ? EDIT_SUBMIT : NEW_SUBMIT}
        secondarySubmitText={isEditing ? EDIT_SECONDARY : NEW_SECONDARY}
        isOpen={isOpen}
        onClose={this.onClose}
        onSubmit={isEditing ? this.onSave : this.onSubmit}
        onSecondarySubmit={isEditing ? this.onDelete : this.onClose}
      >
        <SubjectSelector
          label={SUBJECT}
          className={styles.subjectSelect}
          subjects={subjects}
          selectedSubject={gradeData.subjectId || subjectId}
          onChange={this.onSubjectChange}
          isDisabled={!!gradeId || !!subjectId}
        />
        <GradesForm
          gradeData={gradeData}
          onChange={this.onValueChange}
          eagerValidation={eagerValidation}
          curriculumTemplates={curriculumTemplates}
          resultTypesTemplates={resultTypesTemplates}
        />
      </Modal>
    );
  }
}

GradesModal.propTypes = {
  gradeId: PropTypes.string,
  subjectId: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  getGradeById: PropTypes.func.isRequired,
  getGradeResultById: PropTypes.func.isRequired,
  curriculumTemplates: PropTypes.object.isRequired,
  resultTypesTemplates: PropTypes.object.isRequired,
  getSubjectsByCurriculums: PropTypes.func.isRequired,
  getSubjectCategoryById: PropTypes.func.isRequired,
  getGradeTableConfigForSubject: PropTypes.func.isRequired,
};
