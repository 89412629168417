import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Caption } from 'components/atoms/typography';
import transitions from 'components/atoms/behaviours/transitions.scss';
import Modal from 'components/molecules/Modal';
import Datepicker from 'components/molecules/Datepicker';
import TextArea from 'components/molecules/TextArea';
import ToggleSwitch from 'components/molecules/ToggleSwitch';
import { SearchMission } from 'components/molecules/Autocomplete';
import moment from 'moment';

import css from './styles.scss';

const ADD_TASK_TITLE = 'Add a new task';
const EDIT_TASK_TITLE = 'Task details';

const DTID_TASK_MODAL_NAME_INPUT = 'taskModalNameInput';
const DTID_TASK_MODAL_TOGGLE_DATE = 'taskModalToggleDate';
const DTID_TASK_MODAL_DATEPICKER = 'taskModalDatepicker';
const DTID_TASK_MODAL_TOGGLE_MISSION = 'taskModalToggleMission';
const DTID_TASK_MODAL_MISSION_SELECT = 'taskModalMissionSelect';
const DTID_TASK_MODAL_SUBMIT = 'taskModalSubmit';

const getInitialState = (taskToEdit, mission) => {
  if (taskToEdit) {
    const { id, name, date } = taskToEdit;
    return {
      id,
      name,
      date: date ? moment(date) : null,
      missionSelected: mission,
      isDateShowing: !!date,
      isMissionShowing: !!mission,
      modalTitle: EDIT_TASK_TITLE,
      isMissionValid: true,
      isNameValid: true,
    };
  }

  return {
    name: '',
    date: null,
    missionSelected: null,
    isNameValid: true,
    isMissionValid: true,
    isDateShowing: false,
    isMissionShowing: false,
    modalTitle: ADD_TASK_TITLE,
  };
};

export default class TaskModal extends Component {
  constructor(props) {
    super(props);
    const { mission } = props;
    this.state = getInitialState(props.taskToEdit, mission);

    this.onMissionSelected = this.onMissionSelected.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.submitSaveTask = this.submitSaveTask.bind(this);
    this.onToggleDate = this.onToggleDate.bind(this);
    this.onToggleMission = this.onToggleMission.bind(this);
  }

  onMissionSelected(mission) {
    this.setState({ missionSelected: mission, isMissionValid: true });
  }

  onDateChange(value) {
    this.setState({
      date: value,
    });
  }

  onNameChange(value) {
    this.setState({
      name: value,
      isNameValid: true,
    });
  }

  onToggleDate() {
    this.setState(({ isDateShowing }) => ({
      isDateShowing: !isDateShowing,
      date: isDateShowing ? null : moment(),
    }));
  }

  onToggleMission() {
    this.setState(({ isMissionShowing }) => ({
      isMissionShowing: !isMissionShowing,
      missionSelected: null,
    }));
  }

  submitSaveTask() {
    const { onSubmit, userId, missionIsRequired } = this.props;
    const { id, missionSelected, name, date } = this.state;

    let validationsPass = true;

    if (!name) {
      this.setState({ isNameValid: false });
      validationsPass = false;
    }
    if (missionIsRequired && !missionSelected) {
      this.setState({ isMissionValid: false });
      validationsPass = false;
    }
    if (validationsPass) {
      onSubmit({
        id: id ? id.toString() : id,
        userId,
        name,
        date,
        roadmapMissionId: missionSelected && missionSelected.id,
        mission: missionSelected,
      });
    }
  }

  render() {
    const { isOpen, onClose, onDelete, userId, roadmapId, missionIsRequired } = this.props;

    const {
      id,
      name,
      date,
      isDateShowing,
      isMissionShowing,
      isNameValid,
      missionSelected,
      modalTitle,
      isMissionValid,
    } = this.state;

    return (
      <Modal
        isOpen={isOpen}
        onSubmit={this.submitSaveTask}
        onSubmitDataTestId={DTID_TASK_MODAL_SUBMIT}
        isSubmitDisabled={false}
        onClose={onClose}
        className={css.modalContent}
        overlayClassName={css.modalOverlay}
        title={modalTitle}
        submitText="Save"
        onTertiarySubmit={id && onDelete}
        tertiarySubmitText="Delete task"
      >
        <Caption className={css.inputLabel}>Task name</Caption>
        <TextArea
          rows={2}
          dataTestId={DTID_TASK_MODAL_NAME_INPUT}
          value={name || ''}
          isError={!isNameValid}
          placeholder="Type a task name..."
          validationMessage="Task must have a name"
          onChange={this.onNameChange}
          autoSize
          charLimit={255}
        />
        <div className={css.toggleContentContainer}>
          <Caption>Due date</Caption>
          <ToggleSwitch
            dataTestId={DTID_TASK_MODAL_TOGGLE_DATE}
            dataGaLabel="Toggle - Date"
            on={isDateShowing}
            handleToggle={this.onToggleDate}
          />
        </div>
        {isDateShowing && (
          <div className={transitions.fadeContentDown}>
            <Datepicker
              dataTestId={DTID_TASK_MODAL_DATEPICKER}
              date={date}
              onChange={this.onDateChange}
              isViewOnly={!isDateShowing}
            />
          </div>
        )}

        {roadmapId && (
          <div className={css.toggleContentContainer}>
            <Caption>Tag task to a mission</Caption>
            {!missionIsRequired && (
              <ToggleSwitch
                dataGaLabel="Toggle - Mission"
                dataTestId={DTID_TASK_MODAL_TOGGLE_MISSION}
                on={isMissionShowing}
                handleToggle={this.onToggleMission}
              />
            )}
          </div>
        )}
        {roadmapId && (missionIsRequired || isMissionShowing) && (
          <div className={transitions.fadeContentDown}>
            <SearchMission
              dataTestId={DTID_TASK_MODAL_MISSION_SELECT}
              roadmapId={roadmapId}
              userId={userId}
              onMissionSelected={this.onMissionSelected}
              missionSelected={missionSelected}
              isValid={isMissionValid}
              validationMessage="Please select a mission"
            />
          </div>
        )}
      </Modal>
    );
  }
}

TaskModal.propTypes = {
  userId: PropTypes.string,
  roadmapId: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  taskToEdit: PropTypes.object,
  mission: PropTypes.object,
  missionIsRequired: PropTypes.bool,
};
