import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Body } from 'components/atoms/typography';
import DoneIcon from '@material-ui/icons/Done';
import IconButton from 'components/molecules/IconButton';
import InputWarningMessage from 'components/molecules/InputWarningMessage';

import fontColour from 'components/atoms/typography/fontColour.scss';
import css from './style.scss';

export const FILE_SIZE_LIMIT = 25000000;
export const FILE_UPLOAD_ERROR = 'Failed to upload file.';
export const FILE_SIZE_ERROR = `This file is too big. Maximum file size is ${FILE_SIZE_LIMIT / 1000000}MB.`;

export default function FileUploadingDisplay({
  fileName,
  isError,
  errorMessage,
  uploadPercentage,
  cancelUpload,
  retryUpload,
}) {
  const isUploadError = errorMessage === FILE_UPLOAD_ERROR;

  return (
    <div>
      <div className={css.wrapper}>
        <div className={css.innerWrapper}>
          <div className={css.details}>
            <Body className={fontColour.secondary}>{fileName}</Body>
          </div>
          <div className={css.actions}>
            {uploadPercentage < 100 || isError ? (
              <IconButton icon="Clear" onClick={cancelUpload} />
            ) : (
              <DoneIcon className={css.done} style={{ height: '2.4rem', width: '2.4rem' }} />
            )}
          </div>
        </div>
        <div className={css.loadingBarOverlay}>
          <div
            className={classNames(css.loadingBar, { [css.loadingError]: isError })}
            style={{ width: `${uploadPercentage}%` }}
          />
        </div>
      </div>
      {isError && errorMessage && (
        <InputWarningMessage
          className={css.errorMessage}
          message={errorMessage}
          messageAction={retryUpload}
          messageActionText={isUploadError ? 'Retry' : undefined}
        />
      )}
    </div>
  );
}

FileUploadingDisplay.propTypes = {
  fileName: PropTypes.string.isRequired,
  isError: PropTypes.bool,
  errorMessage: PropTypes.string,
  uploadPercentage: PropTypes.number,
  cancelUpload: PropTypes.func.isRequired,
  retryUpload: PropTypes.func.isRequired,
};
