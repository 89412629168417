import React from 'react';

export default function InverseLogo(props) {
  return (
    <svg height="64" viewBox="0 0 144 64" {...props}>
      <g fill="#fff">
        <path d="m18.7034483 12.8621359-2.2965517-3.99741098c-.3517242-.62135922-1.262069-.62135922-1.6137932 0l-5.06896547 8.63689318c-.33103448.5799353-.33103448 1.2841424 0 1.8640777l2.54482757 4.3495146c1.3862069-4.038835 3.5793104-7.7255664 6.4344828-10.8530745z" />
        <path d="m8.46206897.5592233 3.04137933 5.2815534c.3310345.57993527.3310345 1.28414239 0 1.86407767l-3.04137933 5.28155343c-.3724138.6213592-1.26206897.6420712-1.63448276 0l-3.10344828-5.28155343c-.33103448-.57993528-.33103448-1.30485437 0-1.88478965l3.08275862-5.26084142c.37241379-.6420712 1.28275862-.62135922 1.65517242 0z" />
        <path d="m3.88965517 14.0427184 1.94482759 3.3760518c.33103448.5799353.33103448 1.2841424 0 1.8640777l-1.94482759 3.3760518c-.37241379.6213592-1.26206896.6420712-1.63448276 0l-1.98620689-3.3760518c-.33103449-.5799353-.33103449-1.3048544 0-1.8847896l1.96551724-3.3760518c.37241379-.6213593 1.28275862-.6213593 1.65517241.0207119z" />
        <path d="m67.3241379 18.0401294c-6.2068965-10.58381872-18.4965517-16.79741095-31.3448276-14.62265367-6.8689655 1.15987055-12.8482758 4.61877022-17.275862 9.44466017l3.7034483.9320389c.475862.1242718.7034482.6627831.4551724 1.0977346l-5.4827587 9.4860841c-.2068965.3521036-.6413793.5385114-1.0344827.4349515l-4.0965517-1.0977346c.1655172-.4970874.3517241-.9734628.537931-1.4498382-1.8206897 4.5359223-2.5655172 9.568932-1.9655172 14.7055016 2.0275862 17.2530744 17.9172413 29.2245955 34.9034482 26.5734628 2.6896552-.4142395 5.2344828-1.1805826 7.5931035-2.2368932.8689655-.3728156 1.2206896-1.4084143.7862069-2.2576052l-3.5793104-7.0627832c-.3724138-.7456311-1.2413793-1.0770226-2.0275862-.787055-.9103448.3313916-1.8620689.6006472-2.8344827.807767-11.6896552 2.5061489-6-2.402589-9.0206897-5.0744337-1.6965517-1.4912621-5.7724138-2.7961165-8.9172414-2.9203883-7.0551724-.2899677-6.9724138-2.8582524-5.6275862-5.7165049.9310345-1.9883495.4965517-3.0860841.6827586-3.9352751.1862069-.8699029.2068966-1.1805825.6206897-1.6362459.6413793-.6627832.1241379-1.5533981-.4137931-2.3197411-1.262069-1.7398058-.9310345-2.8582524.8275862-3.231068 1.737931-.3521035 5.3172414-2.2161812 5.5655172-3.6867314.2689656-1.4498381 2.5448276-7.4563106 7.4068966-8.6368932 8.0068965-1.9469255 16.2413793.5592233 20.8344828 8.3883496.4344827.766343 1.3862069 1.0563106 2.1724137.6420711l6.8482759-3.5003236c.8482759-.4556634 1.1586207-1.5119741.6827586-2.3404531z" />
      </g>
    </svg>
  );
}
