import { LOCATION_CHANGE } from 'ducks/meta';

const appCuesMiddleware = () => (next) => (action) => {
  if (action.type === LOCATION_CHANGE) {
    window.Appcues && window.Appcues.start();
  }
  next(action);
};

export default appCuesMiddleware;
