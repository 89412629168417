import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getFloatingSessionContext } from 'components/pages/FloatingSession/index.util';
import { CloseFull } from 'components/pages/FloatingSession/Icon';
export default function SwitchButton(props) {
  const { onSwitch, toggleFloating, eventId } = props;
  const { sessionId } = getFloatingSessionContext();
  const [switchVisible, setSwitchVisible] = useState(!sessionId);
  useEffect(() => {
    const { sessionId } = getFloatingSessionContext();
    setSwitchVisible(!sessionId);
  }, [eventId, toggleFloating]);

  return switchVisible ? (
    <div>
      {' '}
      <CloseFull
        onClick={() => {
          setSwitchVisible(false);
          onSwitch();
        }}
      />
    </div>
  ) : (
    ''
  );
}
SwitchButton.propTypes = {
  onSwitch: PropTypes.func.isRequired,
  toggleFloating: PropTypes.bool,
  eventId: PropTypes.string.isRequired,
};
