import React from 'react';
import PropTypes from 'prop-types';

export default function List({ className }) {
  return (
    <svg
      className={className}
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path
          id="a"
          d="m0 0v1.6h1.6v-1.6zm4 0v1.6h12v-1.6zm-4 4.8v1.6h1.6v-1.6zm4 0v1.6h12v-1.6zm-4 4.8v1.6h1.6v-1.6zm4 0v1.6h12v-1.6zm-4 4.8v1.6h1.6v-1.6zm4 0v1.6h12v-1.6z"
        />
        <mask id="b" fill="#fff">
          <use fill="none" xlinkHref="#a" />
        </mask>
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(4 4)">
        <use fill="#3d464d" fillRule="nonzero" xlinkHref="#a" />
        <g fill="#232950" mask="url(#b)">
          <path d="m0 0h24v24h-24z" transform="translate(-4 -4)" />
        </g>
      </g>
    </svg>
  );
}

List.propTypes = {
  className: PropTypes.string,
};
