import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';
import { gql } from '@apollo/client';
import { createClient } from '../utils';

const { api } = getConfig();

const client = createClient(`${api.endpoint}/graphql`);

export const inviteNewUser = gql`
  mutation inviteNewUser($userId: String!) {
    inviteNewUser(userId: $userId)
  }
`;

export const createNewUserInvitation = gql`
  mutation createNewUserInvitation(
    $firstName: String!
    $lastName: String!
    $email: String!
    $notes: String
    $roleIds: [String]!
    $isTest: Boolean
    $userTags: [String]
    $isAllTags: Boolean
  ) {
    createNewUserInvitation(
      firstName: $firstName
      lastName: $lastName
      email: $email
      notes: $notes
      roleIds: $roleIds
      isTest: $isTest
      userTags: $userTags
      isAllTags: $isAllTags
    )
  }
`;

export const confirmUserInvitation = gql`
  mutation confirmUserInvitation {
    confirmUserInvitation
  }
`;

export const addUserToLms = gql`
  mutation addUserToLms(
    $userId: String!
    $firstName: String!
    $lastName: String!
    $email: String!
    $phoneNumber: String
    $country: String
    $notes: String
    $roleIds: [String]!
    $userTags: [String]
    $isAllTags: Boolean
  ) {
    addUserToLms(
      userId: $userId
      firstName: $firstName
      lastName: $lastName
      email: $email
      phoneNumber: $phoneNumber
      country: $country
      notes: $notes
      roleIds: $roleIds
      userTags: $userTags
      isAllTags: $isAllTags
    )
  }
`;

export default {
  inviteNewUser: (userId) => client.query(inviteNewUser, { userId }),
  createNewUserInvitation: (firstName, lastName, email, notes, roleIds, isTest, userTags, isAllTags) =>
    client.query(createNewUserInvitation, { firstName, lastName, email, notes, roleIds, isTest, userTags, isAllTags }),
  confirmUserInvitation: () => client.query(confirmUserInvitation),
  addUserToLms: (userId, firstName, lastName, email, phoneNumber, country, notes, roleIds, userTags, isAllTags) =>
    client.query(addUserToLms, {
      userId,
      firstName,
      lastName,
      email,
      phoneNumber,
      country,
      notes,
      roleIds,
      userTags,
      isAllTags,
    }),
};
