import Immutable from 'immutable';
import createReducer from 'utils/createReducer';
import { ADD_ENTITIES, addEntitiesWithNormalisation } from 'ducks/normalizr';
import { awardEntity } from 'schema';
import awardConnector from 'graphql/api/award';
import { updateMeta, formFail, formSuccess } from 'ducks/meta';
import componentKeys from 'constants/componentKeys';
import { formatGraphQLRequestError } from 'utils/graphql';

export const UPDATE_ECL_AWARD_POSITION_OPTIMISTIC = 'award/UPDATE_ECL_AWARD_POSITION_OPTIMISTIC';
export const UPDATE_ECL_AWARD_SUCCEEDED = 'award/UPDATE_ECL_AWARD_SUCCEEDED';
export const DELETE_ECL_AWARD_SUCCEEDED = 'award/DELETE_ECL_AWARD_SUCCEEDED';

const initialState = new Immutable.Map();

export default createReducer(initialState, {
  [ADD_ENTITIES]: (state, action) => {
    return state.mergeDeep(action.payload.entities.award);
  },
  [UPDATE_ECL_AWARD_POSITION_OPTIMISTIC]: (state, action) => {
    const { id, position } = action.payload;

    const allAwards = state
      .toList()
      .filter((a) => a.get('id') !== id)
      .sort((a, b) => a.get('order') - b.get('order'))
      .splice(position, 0, state.get(id));

    let newState = state;
    allAwards.forEach((award, index) => {
      newState = newState.setIn([award.get('id'), 'order'], index);
    });
    return newState;
  },
  [UPDATE_ECL_AWARD_SUCCEEDED]: (state, action) => {
    const { award } = action.payload;
    return state.setIn([award.id], Immutable.fromJS(award));
  },
  [DELETE_ECL_AWARD_SUCCEEDED]: (state, action) => {
    const { awardId } = action.payload;
    const newState = state;
    return newState.delete(awardId);
  },
});

export function updateAwardPositionOptimistic(id, position) {
  return { type: UPDATE_ECL_AWARD_POSITION_OPTIMISTIC, payload: { id, position } };
}

export function updateAwardSucceeded(award) {
  return { type: UPDATE_ECL_AWARD_SUCCEEDED, payload: { award } };
}

export function deleteAwardSucceeded(awardId) {
  return { type: DELETE_ECL_AWARD_SUCCEEDED, payload: { awardId } };
}

export function fetchAwardsByUserId(userId) {
  return async (dispatch) => {
    dispatch(updateMeta(componentKeys.ECL_AWARDS_FETCHED, false));
    const result = await awardConnector.getECLAwardByUserId(userId);
    const awards = result.getECLAwardByUserId;
    if (awards) {
      dispatch(addEntitiesWithNormalisation(awards, [awardEntity]));
    }
    dispatch(updateMeta(componentKeys.ECL_AWARDS_FETCHED, true));
  };
}

export function createAward(award) {
  return async (dispatch) => {
    try {
      const result = await awardConnector.createECLAward(award);
      dispatch(addEntitiesWithNormalisation(result.createECLAward, awardEntity));
      dispatch(formSuccess('Award has been added successfully'));
      return result.createECLAward;
    } catch (err) {
      dispatch(formFail(formatGraphQLRequestError(err)));
      return false;
    }
  };
}

export function updateAward(updates) {
  return async (dispatch) => {
    try {
      const result = await awardConnector.updateECLAward(updates);
      dispatch(updateAwardSucceeded(result.updateECLAward));
      dispatch(formSuccess('Award has been updated successfully'));
    } catch (err) {
      dispatch(formFail(formatGraphQLRequestError(err)));
    }
  };
}

export function updateAwardSortOrder(awardId, position) {
  return async (dispatch) => {
    try {
      dispatch(updateAwardPositionOptimistic(awardId, position));
      await awardConnector.updateECLAward({
        id: awardId,
        order: position,
      });
    } catch (err) {
      dispatch(formFail(formatGraphQLRequestError(err)));
    }
  };
}

export function deleteAward(id) {
  return async (dispatch) => {
    try {
      await awardConnector.deleteECLAward(id);
      dispatch(deleteAwardSucceeded(id));
      dispatch(formSuccess('Award has been removed successfully'));
      return true;
    } catch (err) {
      dispatch(formFail(formatGraphQLRequestError(err)));
      return false;
    }
  };
}
