import moment from 'moment';

// Going back a given number of years, provide the date.
function getPreviousDate(currentDate, yearsAgo) {
  return moment(currentDate).subtract(yearsAgo, 'years');
}

// Get the date of the start of a certain month in a given year.
function getStartOfMonth(date, month = 1) {
  const monthOffset = month - 1;
  return moment(date).startOf('year').add(monthOffset, 'months');
}

// Get an option for a given year.
function getYearOption(date) {
  const yearOptions = getStartOfMonth(date);
  return {
    label: yearOptions.format('YYYY'),
    value: yearOptions.toDate(),
  };
}

// Get an option for a given year and month.
function getSessionOption(date, monthNumber) {
  const thisDate = getStartOfMonth(date, monthNumber);
  return {
    label: thisDate.format('MMMM YYYY'),
    value: thisDate.toDate(),
  };
}

// Construct an option for each date.
export function getDateOptions(now, years, months) {
  let options = [];

  for (let yearsAgo = 0; yearsAgo < years; yearsAgo += 1) {
    const dateCursor = getPreviousDate(now, yearsAgo);
    const hasMonths = months && months.length;
    options = hasMonths
      ? options.concat(months.map((monthNumber) => getSessionOption(dateCursor, monthNumber)))
      : options.concat(getYearOption(dateCursor));
  }

  return options;
}
