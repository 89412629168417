import { connect } from 'react-redux';
import { selectUserPermissions } from 'selectors/meta';
import { isStudentVIP } from 'selectors/profile';

import VIPStudentIndicator from './VIPStudentIndicator';

const mapStateToProps = (state, ownProps) => ({
  isVIP: isStudentVIP(ownProps.userId)(state),
  loginUserPermissions: selectUserPermissions(state),
});

export default connect(mapStateToProps)(VIPStudentIndicator);
