import React, { useContext } from 'react';
import { Container } from './index.style';
import TabBar, { TabBarProps } from './TabBar';
import { AppTrackerContext } from '../viewController';

type ApplicationTabProps = {
  children: React.ReactNode;
} & TabBarProps;
export const ApplicationTab = ({ children, tabs }: ApplicationTabProps) => {
  return (
    <Container>
      <TabBar tabs={tabs} />
      {children}
    </Container>
  );
};

type TabPanelProps = {
  value: string;
  children: React.ReactNode;
};
export const TabPanel = ({ value, children }: TabPanelProps) => {
  const {
    state: { tabValue },
  } = useContext(AppTrackerContext);
  if (tabValue !== value) return null;
  return <>{children}</>;
};
