import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import sessionRatings from 'constants/sessionRatings';

import css from './SessionQuality.scss';

export default function SessionQuality({ value, onChange }) {
  const [selectedRate, setSelectedRate] = useState(-1);
  const ratings = useMemo(
    () =>
      Object.entries(sessionRatings).map(([rate, { text, default_path, active_path }]) => (
        <button
          data-test-id={`emojiRating_${rate}`}
          className={classNames({ [css.selected]: value === rate })}
          onClick={() => {
            setSelectedRate(parseInt(rate, 10));
            onChange(parseInt(rate, 10));
          }}
          key={rate}
          data-ga-label={rate}
        >
          <div className={css.icon}>
            <img src={selectedRate >= parseInt(rate, 10) ? active_path : default_path} alt={text} />
          </div>
          <span className={css.ratingText}>{text}</span>
        </button>
      )),
    [onChange, value],
  );

  return (
    <div className={css.sessionQuality}>
      <h3>How was the overall quality of the session?</h3>
      <div className={css.controls}>{ratings}</div>
    </div>
  );
}

SessionQuality.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};
