import React from 'react';

/**
 * Helper hook for use when all you want to do is toggle something. E.g. a modal
 */
const useToggle = (initial = false): [boolean, () => void] => {
  const [isOn, setOn] = React.useState(initial);

  const toggleValue = React.useCallback(() => {
    setOn((on) => !on);
  }, [setOn]);
  return [isOn, toggleValue];
};

export default useToggle;
