import MultiSelectFilter from './multiSelect.js';

export default class UniversityFilter extends MultiSelectFilter {
  constructor(...args) {
    super(...args);
    this.title = 'University';
    this.name = 'University';
  }

  apply(tutor) {
    if (!this.active()) {
      return true;
    }
    return this.state.filtered.some(
      (value) => tutor.tutorInfo.universities.map((u) => u.name.toUpperCase()).indexOf(value.toUpperCase()) !== -1,
    );
  }

  data({ tutors }) {
    /**
     * Now displays the first instance of a capitalisation style
     * of a given university to prevent tutors with the same
     * university but different capitalisations displaying
     * as a separate university in the filter.
     */
    const uniqueCapitalizedUni = [];
    const uniTitleCase = [];
    const universities = tutors
      .filter((t) => t.tutorInfo && t.tutorInfo.universities && t.tutorInfo.universities.length)
      .map((t) => t.tutorInfo.universities.map((u) => u.name))
      .reduce((a, b) => a.concat(b), []); // .flat();
    universities.forEach((uni, index) => {
      const uniDisplayName = uni.toUpperCase();
      if (!uniqueCapitalizedUni.includes(uniDisplayName)) {
        uniqueCapitalizedUni.push(uniDisplayName);
        uniTitleCase.push(universities[index]);
      }
    });
    this.setState({ all: uniTitleCase });
  }

  value() {
    return this.state.filtered;
  }

  simpleValue() {
    return this.value().join(', ');
  }
}
