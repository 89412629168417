import { connect } from 'react-redux';
import { fetchBookingAsEvents } from 'ducks/user';
import { recreateZoomMeeting, fetchRefreshedZoomMeeting } from 'ducks/booking';
import { getLoginUser } from 'selectors/user';

import SessionVideoLink from './SessionVideoLink';

export default connect(
  (state) => ({
    loginUser: getLoginUser(state),
  }),
  (dispatch) => ({
    recreateZoomMeeting: (eventId) => dispatch(recreateZoomMeeting(eventId)),
    fetchRefreshedZoomMeeting: (eventId, userId) => dispatch(fetchRefreshedZoomMeeting(eventId, userId)),
    fetchBookingAsEvents: () => dispatch(fetchBookingAsEvents()),
  }),
)(SessionVideoLink);
