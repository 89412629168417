import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { Application, ApplicationApproval, ApplicationRegion, RegionValue } from '../type';
import { groupRoundsByRegion, mapApplicationRegion, hasAdmissionResult, isUCSchool, isPreviewApproval } from '../util';

const sortByDeadline = (a: Application, b: Application) => {
  const d1 = DateTime.fromISO(a.deadline || '1990-01-01');
  const d2 = DateTime.fromISO(b.deadline || '1990-01-01');
  if (d1 < d2) return -1;
  if (d1 > d2) return 1;
  return 0;
};

const getSelectedSchools = (applications: Application[]) => {
  const selected = applications.filter((o) => o.group === 'APPLYING' && o.hadCrimsonSupport && !isUCSchool(o));
  const oneUCSchool = applications.find((o) => o.group === 'APPLYING' && o.hadCrimsonSupport && isUCSchool(o));
  if (oneUCSchool) {
    selected.push(oneUCSchool);
  }
  return selected;
};

const getSubmittedSchools = (applications: Application[]) => {
  const submitted = applications.filter(
    (o) =>
      o.group === 'APPLYING' &&
      (o.isSubmitted || o.extraApplicationStatus === 'INVITED_TO_INTERVIEW') &&
      o.hadCrimsonSupport,
  );
  return submitted;
};

export const useApplicationsWithRegion = (applications: Application[], region: ApplicationRegion): Application[] =>
  useMemo(() => applications.filter((o) => mapApplicationRegion(o) === region), [applications, region]);

export const useUpcomingApplication = (
  applications: Application[],
  region: ApplicationRegion,
): Application | undefined =>
  useMemo(() => {
    const list = applications
      .filter((o) => mapApplicationRegion(o) === region && o.group === 'APPLYING' && o.deadline && !o.isSubmitted)
      .sort(sortByDeadline);
    return list[0];
  }, [applications, region]);

export const useSchoolSelectionStatus = (
  applications: Application[],
  region: ApplicationRegion,
  approvals: Record<ApplicationRegion, ApplicationApproval | undefined>,
  currentSelectionHash: RegionValue<string | undefined>,
): { text: string; date?: string } =>
  useMemo(() => {
    const list = applications.filter((o) => mapApplicationRegion(o) === region);

    const finallist = list.filter((o) => o.group === 'APPLYING');
    const shortlist = list.filter((o) => o.group === 'SHORTLISTED');
    const longlist = list.filter((o) => o.group === 'PROSPECTIVE');

    if (longlist.length > 0 && shortlist.length <= 0 && finallist.length <= 0) {
      return { text: 'Exploration' };
    }

    if (shortlist.length > 0 && finallist.length <= 0) {
      return { text: 'Short List in Progress' };
    }

    if (approvals[region]?.responseType === 'approved') {
      return { text: 'Final List Approval' };
    }

    const edited = currentSelectionHash?.[region] !== approvals[region]?.selectionHash;
    if (
      !approvals[region]?.responseType &&
      approvals[region]?.createdAt &&
      !edited &&
      !isPreviewApproval(approvals[region])
    ) {
      return { text: 'Parent Approval Pending', date: approvals[region]?.createdAt };
    }

    if (finallist.length > 0) {
      return { text: 'Final List in Progress' };
    }

    return { text: 'Not Started' };
  }, [applications, region, approvals, currentSelectionHash]);

export const useSchoolsSelected = (
  applications: Application[],
  region: ApplicationRegion,
): { text: string; selected: number } =>
  useMemo(() => {
    const list = applications.filter((o) => mapApplicationRegion(o) === region);
    const selected = getSelectedSchools(list);
    const text = groupRoundsByRegion(selected, region)
      .map((o) => `${o.applications.length} ${o.label}`)
      .join(', ');
    return { text: text, selected: selected.length };
  }, [applications, region]);

export const useSubmitted = (
  applications: Application[],
  region: ApplicationRegion,
): { submitted: number; selected: number } =>
  useMemo(() => {
    const list = applications.filter((o) => mapApplicationRegion(o) === region);
    const selected = getSelectedSchools(list);
    const submitted = getSubmittedSchools(selected);
    return { submitted: submitted.length, selected: selected.length };
  }, [applications, region]);

export const useResultReceived = (
  applications: Application[],
  region: ApplicationRegion,
): { resultReceived: number; submitted: number } =>
  useMemo(() => {
    const list = applications.filter((o) => mapApplicationRegion(o) === region);
    const selected = getSelectedSchools(list);
    const submitted = getSubmittedSchools(selected);
    const resultReceived = submitted.filter(
      (o) => o.group === 'APPLYING' && hasAdmissionResult(o) && o.hadCrimsonSupport,
    );
    return { resultReceived: resultReceived.length, submitted: submitted.length };
  }, [applications, region]);
