import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadingIndicator from 'components/molecules/LoadingIndicator';
import SubjectCard from 'components/organisms/SubjectCard';

import css from './styles.scss';
import { trackEvent } from '@crimson-education/browser-logger';
import { InAppPurchaseEvents } from './util';

class Subjects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      isPurchaseHoursModalOpen: false,
    };
  }

  componentDidMount() {
    const { fetchPackageByUser, studentUser } = this.props;
    fetchPackageByUser(studentUser.userId).then(() => {
      this.setState({
        isLoaded: true,
        pendingOrders: new Set(),
      });
    });
  }

  openPurchaseHoursModal(subject) {
    trackEvent({
      message: InAppPurchaseEvents.OpenedPurchaseModal,
      metadata: {
        subjectId: subject.subjectId,
        subjectName: subject.subject,
        type: 'Subjects',
      },
    });
    this.setState({ subjectToPurchase: subject, isPurchaseHoursModalOpen: true });
  }

  render() {
    const { subjects, onClickPurchase, pendingSubjects } = this.props;
    const { isLoaded } = this.state;

    const addMoreHours = (subject) => {
      const item = {
        name: subject.subject,
        itemId: subject.id,
        source: 'CRIMSON_APP_SUBJECTS',
      };
      onClickPurchase(item);
    };

    return (
      <div className={css.container}>
        {isLoaded ? (
          <div className={css.cards}>
            {subjects.map((subject) => {
              return (
                <SubjectCard
                  key={subject.id}
                  subject={subject}
                  onClickAddMoreHours={() => addMoreHours(subject)}
                  hasPendingOrder={pendingSubjects.has(subject.id)}
                />
              );
            })}
          </div>
        ) : (
          <LoadingIndicator className={css.loading} />
        )}
      </div>
    );
  }
}

Subjects.propTypes = {
  fetchPackageByUser: PropTypes.func.isRequired,
  subjects: PropTypes.array,
  studentUser: PropTypes.object,
  onClickPurchase: PropTypes.func.isRequired,
  pendingSubjects: PropTypes.array.isRequired,
};

export default Subjects;
