import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import CheckCircle from '@material-ui/icons/CheckCircle';

import Avatar from 'components/molecules/Avatar';
import LoadingCircle from 'components/molecules/LoadingCircle';
import { messageStatus } from 'constants/messaging';

import css from './styles.scss';
import { handleEnter } from '../../../../../utils/accessibility';

const MAX_SEEN_BY = 3;

export default function Indicator(props) {
  const { seenBy, status, retry, retryTim, isAfterReadMessage } = props;

  if (seenBy && seenBy.length > 0) {
    return (
      <div className={css.indicators}>
        {seenBy.slice(0, MAX_SEEN_BY).map((x) => (
          <Avatar
            key={x.user.userId}
            image={x.user.profileImageUrl}
            {...x.user}
            size="smallNormal"
            isRound
            data-test-id="seen-indicator"
          />
        ))}
        {seenBy.length > MAX_SEEN_BY && <span className={css.seenByPlus}>+{seenBy.length - MAX_SEEN_BY}</span>}
      </div>
    );
  }

  if (status === messageStatus.SENDING) {
    return (
      <div className={css.indicators}>
        <LoadingCircle color="#00a896" style={{ width: '1.5rem', height: '1.5rem' }} data-test-id="sending-indicator" />
      </div>
    );
  }

  if (status === messageStatus.ERROR) {
    return (
      <div className={classNames(css.indicators, css.error)}>
        <div className={css.errorText} data-test-id="error-indicator">
          Error sending.
          <span
            role="button"
            tabIndex={0}
            className={css.tryAgain}
            onClick={() => retry()}
            onKeyDown={handleEnter(() => retry())}
          >
            Try again
          </span>
        </div>
        <ErrorOutline className={css.errorIcon} style={{ width: '1.5rem', height: '1.5rem' }} />
      </div>
    );
  }

  if (status === messageStatus.TIM_ERROR) {
    return (
      <div className={classNames(css.indicators, css.error)}>
        <div className={css.errorText} data-test-id="error-indicator">
          Error sending.
          <span
            role="button"
            tabIndex={0}
            className={css.tryAgain}
            onClick={() => retryTim()}
            onKeyDown={handleEnter(() => retryTim())}
          >
            Try again
          </span>
        </div>
        <ErrorOutline className={css.errorIcon} style={{ width: '1.5rem', height: '1.5rem' }} />
      </div>
    );
  }

  if (isAfterReadMessage) {
    return (
      <div className={css.indicators}>
        <CheckCircle
          className={css.sentIndicator}
          style={{ width: '1.5rem', height: '1.5rem' }}
          data-test-id="sent-indicator"
        />
      </div>
    );
  }
  return null;
}

Indicator.propTypes = {
  seenBy: PropTypes.array,
  status: PropTypes.string,
  retry: PropTypes.func.isRequired,
  retryTim: PropTypes.func,
  isAfterReadMessage: PropTypes.bool,
};
