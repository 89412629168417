import React from 'react';
import { Description } from 'components/atoms/icons';
type FileTypeIconProps = {
  fileType: string;
};

function FileTypeIcon({ fileType }: FileTypeIconProps) {
  let fileSvgName = null;
  if (fileType.endsWith('pdf')) {
    fileSvgName = 'pdf';
  } else if (fileType.endsWith('presentation')) {
    fileSvgName = 'slides';
  } else if (fileType.endsWith('sheet') || fileType.endsWith('csv')) {
    fileSvgName = 'sheets';
  } else if (fileType.endsWith('document')) {
    fileSvgName = 'doc';
  }
  if (fileSvgName != null) {
    const imgFullPath = `/static/${fileSvgName}.svg`;
    return <img src={imgFullPath} alt={fileSvgName} style={{ width: 30, height: 30, margin: '0px 0.5em 0.5em' }} />;
  }
  return <Description fontSize="large" style={{ color: '#518ef8', fontSize: 35 }} />;
}

export default FileTypeIcon;
