import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';
import { gql } from '@apollo/client';
import { createClient } from './utils';

const { api } = getConfig();

const client = createClient(api.graphQLEndpoint);

const fetchAllBillingCountries = gql`
  query fetchAllBillingCountries {
    billingCountries {
      id
      name
      businessNumberName
      currency
      entityName
      aptSuite
      street
      city
      postCode
      country
    }
  }
`;

const fetchUserBilling = gql`
  query fetchUserBilling($userId: String!) {
    userBilling(userId: $userId) {
      id
      userId
      billingCountryId
      taxRate
      businessNumber
    }
    userSubjectPayRate(userId: $userId) {
      id
      userId
      subjectId
      currency
      rate
      rateInUSD
    }
  }
`;

const editUserBilling = gql`
  mutation editUserBilling($userId: String!, $billingCountryId: String!, $taxRate: Float, $businessNumber: String) {
    editUserBilling(
      userId: $userId
      billingCountryId: $billingCountryId
      taxRate: $taxRate
      businessNumber: $businessNumber
    ) {
      id
      userId
      billingCountryId
      taxRate
      businessNumber
    }
  }
`;

const editUserSubjectPayRates = gql`
  mutation editUserSubjectPayRates($userSubjectPayRates: [UserSubjectPayRateInputType]) {
    editUserSubjectPayRates(userSubjectPayRates: $userSubjectPayRates) {
      id
      userId
      subjectId
      rate
    }
  }
`;

const fetchTutorPayRatesForSubject = gql`
  query fetchTutorPayRatesForSubject($subjectId: String!) {
    subjectAllPayRates(subjectId: $subjectId) {
      id
      userId
      subjectId
      currency
      rate
      rateInUSD
    }
  }
`;

export default {
  fetchAllBillingCountries: () => client.query(fetchAllBillingCountries),
  fetchUserBilling: (userId) => client.query(fetchUserBilling, { userId }),
  editUserBilling: ({ userId, billingCountryId, taxRate, businessNumber }) =>
    client.query(editUserBilling, { userId, billingCountryId, taxRate, businessNumber }),
  editUserSubjectPayRates: (userSubjectPayRates) => client.query(editUserSubjectPayRates, { userSubjectPayRates }),
  fetchTutorPayRatesForSubject: (subjectId) => client.query(fetchTutorPayRatesForSubject, { subjectId }),
};
