import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Column } from '../../index.style';
import { HeadTitle } from './index.style';
import ToolBar from './ToolBar';
import GroupTable from './GroupTable';
import { useStaffDashboardContext, extendStudentData, groupStudentList } from '../helper';
import { applyFilters, filterByRegion, filterBySearchText, filterByStaff } from './util';
import { debounce } from 'lodash';
import LoadingView from './LoadingView';
import { Placeholder } from '../components';

const StudentList = () => {
  const { state } = useStaffDashboardContext();
  const [searchText, setSearchText] = useState('');
  const [filterSearchText, setFilterSearchText] = useState('');
  const debouncedSetSearchText = useCallback(() => debounce(setFilterSearchText, 500), [setFilterSearchText]);
  useEffect(() => {
    debouncedSetSearchText()(searchText);
  }, [searchText, debouncedSetSearchText]);
  const [selectedType, setSelectedType] = useState<string | null>(null);
  const [selectedStaff, setSelectedStaff] = useState<string | null>('*');
  const [selectedGroupBy, setSelectedGroupBy] = useState<string>('schoolSelectionData');
  const { students, applications, overviews, relationships, schoolSelectionData } = state;
  const data = useMemo(() => extendStudentData(students, applications, overviews), [students, applications, overviews]);
  const _filterBySearchText = useCallback(() => filterBySearchText(filterSearchText), [filterSearchText]);
  const _filterByRegion = useCallback(() => filterByRegion(selectedType), [selectedType]);
  const _filterByStaff = useCallback(() => filterByStaff(selectedStaff, relationships), [selectedStaff, relationships]);
  const filteredStudents = useMemo(
    () => applyFilters([_filterBySearchText(), _filterByRegion(), _filterByStaff()], data),
    [_filterBySearchText, _filterByRegion, _filterByStaff, data],
  );
  const grouped = useMemo(
    () => groupStudentList(filteredStudents, selectedGroupBy as any, { schoolSelectionData, overviews }),
    [filteredStudents, selectedGroupBy, schoolSelectionData, overviews],
  );
  return (
    <Column style={{ marginTop: 100 }} gap={'40px'}>
      <HeadTitle>{'App Year Student List'}</HeadTitle>
      <ToolBar
        selectedStaff={selectedStaff}
        setSelectedStaff={setSelectedStaff}
        selectedType={selectedType}
        setSelectedType={setSelectedType}
        searchText={searchText}
        setSearchText={setSearchText}
        selectedGroupBy={selectedGroupBy}
        setSelectedGroupBy={setSelectedGroupBy}
      />
      {state.isLoading && <LoadingView />}
      {!state.isLoading && !!grouped.length && (
        <Column gap={'40px'}>
          {grouped.map((o) => (
            <GroupTable
              key={'studentListGroup' + o.label}
              groupName={o.label}
              students={o.students}
              tint={{
                color: o.color,
                background: o.background,
              }}
            />
          ))}
        </Column>
      )}
      {!state.isLoading && !grouped.length && (
        <Placeholder
          wrapperStyle={{ minHeight: 500 }}
          iconName={'03'}
          iconSize={120}
          placeholderText={'No valid students data yet.'}
          containerStyle={{ gap: 54 }}
        />
      )}
    </Column>
  );
};

export default StudentList;
