import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import css from './style.scss';

const gradeMapping = {
  [-1]: 'N/A',
  0: 'D',
  1: 'C',
  2: 'B',
  3: 'A',
};

function transformGrade(val) {
  return gradeMapping[val] || 'Invalid grade';
}

export default function VersionOne(props) {
  const { report } = props;
  const reportCategoryList = [
    { key: 'goal', label: 'Session Objectives' },
    { key: 'content', label: 'Lesson Summary' },
    { key: 'progress', label: 'Progress' },
    { key: 'todo', label: 'Plan' },
    {
      key: 'assignmentCompletion',
      label: 'Assignment Completion',
      transform: (val) => transformGrade(val),
    },
    {
      key: 'enthusiasm',
      label: 'Enthusiasm for Learning',
      transform: (val) => transformGrade(val),
    },
    {
      key: 'behavior',
      label: 'Conduct and Behaviour',
      transform: (val) => transformGrade(val),
    },
  ];
  const createdDate = moment(report.createdAt).format('ddd, D MMM, YYYY [at] h:mma');
  return (
    <div className={css.report}>
      <div className={css.topFieldsContainer}>
        <div className={css.topField}>
          <h4>Created</h4>
          <p className={css.preText}>{createdDate}</p>
        </div>
      </div>
      {reportCategoryList.map((category, index) => {
        if (report[category.key] !== undefined) {
          return (
            <div className={css.contentWrapper} key={index}>
              <h4>{category.label}</h4>
              <p className={css.preText}>
                {category.transform ? category.transform(report[category.key]) : report[category.key]}
              </p>
            </div>
          );
        }
        return null;
      })}
    </div>
  );
}

VersionOne.propTypes = {
  report: PropTypes.object.isRequired,
};
