import Immutable from 'immutable';
import createReducer from 'utils/createReducer';
import taskConnector from 'graphql/api/task';
import { ADD_ENTITIES, addEntitiesWithNormalisation } from 'ducks/normalizr';
import { normalize } from 'normalizr';
import { getTaskById, getTasksForMission } from 'selectors/task';
import { getMissionsByRoadmapId } from 'selectors/roadmap';
import { taskEntity } from 'schema';
import { updateMeta, formFail } from 'ducks/meta';
import componentKeys from 'constants/componentKeys';
import moment from 'moment';
import pick from 'lodash/pick';
import { v4 as createGUID } from 'uuid';
import { formatGraphQLRequestError } from 'utils/graphql';

export const ADD_TASK_SUCCEEDED = 'task/ADD_TASK_SUCCEEDED';
export const DELETE_TASK_SUCCEEDED = 'task/DELETE_TASK_SUCCEEDED';
export const BULK_DELETE_TASKS_SUCCEEDED = 'task/DELETE_SEVERAL_TASKS_SUCCEEDED';

const initialState = new Immutable.Map();

const constructOptimisticTask = (task) => {
  const optimisticTaskId = createGUID();
  const optimisticTask = Object.assign({}, task, {
    id: optimisticTaskId,
    isComplete: false,
    createdAt: moment().format('YYYY-MM-DDTHH:mm:ss.SSSSSSS'),
    isEditable: false,
    isOptimistic: true,
    isSaving: false,
  });
  return optimisticTask;
};

export default createReducer(initialState, {
  [ADD_ENTITIES]: (state, action) => {
    return state.mergeDeep(action.payload.entities.task);
  },
  [ADD_TASK_SUCCEEDED]: (state, action) => {
    const { optimisticTaskId, newTask } = action.payload;

    // delete optimistic task from state
    let newState = state;
    newState = newState.delete(optimisticTaskId);

    // add merged task to state
    const normalizedTask = normalize(newTask, taskEntity);
    return newState.mergeDeep(normalizedTask.entities.task);
  },
  [DELETE_TASK_SUCCEEDED]: (state, action) => {
    const { taskId } = action.payload;
    let newState = state;
    newState = newState.delete(taskId);
    return newState;
  },
  [BULK_DELETE_TASKS_SUCCEEDED]: (state, action) => {
    const { taskIds } = action.payload;
    let newState = state;
    taskIds.forEach((id) => {
      newState = newState.delete(id);
    });
    return newState;
  },
});

export function addTaskSucceeded(optimisticTaskId, newTask) {
  return { type: ADD_TASK_SUCCEEDED, payload: { optimisticTaskId, newTask } };
}

export function deleteTaskSucceeded(taskId) {
  return { type: DELETE_TASK_SUCCEEDED, payload: { taskId } };
}

export function bulkDeleteTasksSucceeded(taskIds) {
  return { type: BULK_DELETE_TASKS_SUCCEEDED, payload: { taskIds } };
}

export function bulkInsertTasksSucceded(tasks) {
  return addEntitiesWithNormalisation(tasks, [taskEntity]);
}

export function createTaskRedux(task) {
  const optimisticTask = constructOptimisticTask(task);
  return addEntitiesWithNormalisation(optimisticTask, taskEntity);
}

export function fetchTasksByUserId(userId) {
  return async (dispatch) => {
    dispatch(updateMeta(componentKeys.TASKS_LIST_USER, userId));
    dispatch(updateMeta(componentKeys.TASKS_FETCHED, false));
    const result = await taskConnector.getTasksByUserId(userId);
    const tasks = result.getTasksByUserId;

    if (tasks) {
      dispatch(addEntitiesWithNormalisation(tasks, [taskEntity]));
    }
    dispatch(updateMeta(componentKeys.TASKS_FETCHED, true));
  };
}

function persistTask(optimisticTask) {
  return async (dispatch) => {
    // Create task in backend. Only send fields that backend requires)
    const graphQLTask = pick(optimisticTask, ['userId', 'name', 'date', 'roadmapMissionId', 'isComplete', 'eventId']);
    const result = await taskConnector.createTask(graphQLTask);
    const taskCreated = result.createTask;

    // Update store with task returned from backend
    const newTask = Object.assign({}, optimisticTask, {
      id: taskCreated.id,
      isEditable: true,
      isOptimistic: false,
      isSaving: false,
      autoFocus: false,
      oldOptimisticId: optimisticTask.id,
    });
    dispatch(addTaskSucceeded(optimisticTask.id, newTask));
  };
}

export function createTask(task) {
  return async (dispatch) => {
    // Create optimistic task in redux store
    const optimisticTask = constructOptimisticTask(task);
    dispatch(addEntitiesWithNormalisation(optimisticTask, taskEntity));

    try {
      // Save task to db
      dispatch(persistTask(optimisticTask));
    } catch (err) {
      // Rollback
      dispatch(deleteTaskSucceeded(optimisticTask.id));

      dispatch(formFail(formatGraphQLRequestError(err)));
    }
  };
}

export function updateTask(taskUpdates) {
  return async (dispatch, getState) => {
    const previousValue = getTaskById(getState())(taskUpdates.id);
    try {
      // Update task in redux store optimistically
      dispatch(addEntitiesWithNormalisation(taskUpdates, taskEntity));

      if (!previousValue.isOptimistic) {
        const graphQLTask = pick(taskUpdates, ['id', 'name', 'date', 'isComplete', 'roadmapMissionId']);
        await taskConnector.updateTask(graphQLTask);
        return;
      }

      if (taskUpdates.name) {
        const optimisticTask = Object.assign({}, previousValue, taskUpdates);
        dispatch(persistTask(optimisticTask));
      } else {
        dispatch(addEntitiesWithNormalisation(Object.assign({}, previousValue, taskUpdates), taskEntity));
      }
    } catch (err) {
      // Rollback
      dispatch(addEntitiesWithNormalisation(previousValue, taskEntity));

      dispatch(formFail(formatGraphQLRequestError(err)));
    }
  };
}

export function deleteTask(taskId) {
  return async (dispatch, getState) => {
    const previousValue = getTaskById(getState())(taskId);
    dispatch(deleteTaskSucceeded(taskId));
    try {
      if (!previousValue.isOptimistic) {
        await taskConnector.deleteTask(taskId);
      }
    } catch (err) {
      // Rollback
      dispatch(addEntitiesWithNormalisation(previousValue, taskEntity));

      dispatch(formFail(formatGraphQLRequestError(err)));
    }
  };
}

export function deleteTasksForMission(missionId) {
  return (dispatch, getState) => {
    const tasks = getTasksForMission(getState())(missionId);
    tasks.forEach((t) => dispatch(deleteTaskSucceeded(t.id)));
  };
}

export function deleteTasksForRoadmap(roadmapId) {
  return (dispatch, getState) => {
    const missions = getMissionsByRoadmapId(getState())(roadmapId);
    let tasks = [];
    missions.forEach((m) => {
      tasks = tasks.concat(getTasksForMission(getState())(m.id));
    });
    dispatch(bulkDeleteTasksSucceeded(tasks.map((t) => t.id)));
  };
}
