import React, { useMemo } from 'react';
import { Title, StyledImg } from './index.style';
import { Row, StyledTableCell } from '../../index.style';
import { Application } from '../../type';
import SelectCollege from '../SelectCollege';
import { getUniversityColleges } from '../../util';
import { featureSwitches } from 'featureSwitches';
export type UniversityNameProps = {
  application: Application;
  universityIconUrl?: string;
  getExtraUniversityNameProps?: (
    application: Application,
  ) => {
    onClick?: () => void;
  };
};
const DEFAULT_SCHOOL_LOGO = '/static/applicationTracker/defaultSchoolLogo.png';
const UniversityName = ({ application, universityIconUrl, getExtraUniversityNameProps }: UniversityNameProps) => {
  const extraProps = getExtraUniversityNameProps ? getExtraUniversityNameProps(application) : {};
  const colleges = useMemo(() => getUniversityColleges(application), [application]);
  return (
    <StyledTableCell {...extraProps}>
      <Row style={{}} rowcenter>
        <StyledImg src={universityIconUrl || DEFAULT_SCHOOL_LOGO} alt={universityIconUrl} />
        <Title>{application.university}</Title>
      </Row>
      {featureSwitches.NEW_APPLICATION_TRACKER_PHASE_3() && !!colleges.length && (
        <div style={{ marginLeft: 36 + 36, height: 35 }}>
          <SelectCollege application={application} college={application.college} colleges={colleges} />
        </div>
      )}
    </StyledTableCell>
  );
};

export default UniversityName;
