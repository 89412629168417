import styled from 'styled-components';
import Flatpickr from 'react-flatpickr';

export const StyledFlatpickr = styled(Flatpickr)<{ textcolor?: string; placeholdercolor?: string }>`
  width: 130px;
  border: none !important;
  text-align: left;
  font-weight: 500;
  font-size: inherit;
  color: ${(props) => (props.textcolor ? props.textcolor : 'inherit')};
  font-family: 'Montserrat';
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  &:focus,
  &.active {
    border: none !important;
  }
  &::placeholder {
    white-space: normal;
    text-overflow: ellipsis;
    color: ${(props) => (props.placeholdercolor ? props.placeholdercolor : 'inherit')};
  }
`;
