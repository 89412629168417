import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

import Immutable from 'immutable';
import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';
import { getBearer, firebaseDelegation } from 'utils/auth';

const { firebase: config } = getConfig();

const fetchFirebasePath = (path, lastKey, pageSize) => {
  let ref = firebase.database().ref(path);
  if (pageSize) {
    let realPageSize = pageSize;
    ref = ref.orderByKey();
    if (lastKey) {
      ref = ref.endAt(lastKey);
      realPageSize = pageSize + 1;
    }
    ref = ref.limitToLast(realPageSize);
  }
  return ref.once('value').then((snapshot) => {
    const value = snapshot.val();
    if (value && pageSize) {
      let newValue = Immutable.fromJS(value);
      if (lastKey) {
        newValue = newValue.skipLast(1);
      }
      newValue = newValue.reverse();
      newValue = newValue.toJS();
      return newValue;
    }
    return value;
  });
};

const createWatcher = (path, callback) => {
  const firebaseRef = firebase.database().ref(path);
  firebaseRef.on('value', (newSnapshot) => {
    const snapshotValue = newSnapshot.val();
    callback(snapshotValue);
  });
};

const pushFirebasePath = (path, value) => {
  const ref = firebase.database().ref(path).push();
  return ref.set(value).then(() => {
    return ref.key;
  });
};

const setFirebasePath = (path, value) => {
  return firebase.database().ref(path).set(value);
};

const updateFirebasePath = (path, value) => {
  return firebase.database().ref(path).update(value);
};

const updateFirebasePathIfNotExist = (path, childKey, value) => {
  const ref = firebase.database().ref(path);
  return ref.once('value').then((snapshot) => {
    if (!snapshot.hasChild(childKey)) {
      return ref.update(value);
    }
    return 'OK';
  });
};

let fbTokenPromise = null;

const doSignInWithDelegation = async () => {
  if (!firebase.auth().currentUser && getBearer()) {
    const token = await firebaseDelegation();
    return firebase.auth().signInWithCustomToken(token);
  }
  return null;
};

const signInWithDelegation = async () => {
  if (fbTokenPromise) {
    return fbTokenPromise;
  }

  fbTokenPromise = doSignInWithDelegation();
  return fbTokenPromise;
};

export default {
  initialize: () => {
    if (!firebase.apps.length) {
      firebase.initializeApp(config);
    }
  },
  // signToFirebase: () => {
  //   if (!firebase.auth().currentUser) {
  //     signInWithDelegation();
  //   }
  // },
  getFromFirebase: (path, lastKey, pageSize) => {
    // if no current user, firebase has not been authenticated yet, need to auth
    if (!firebase.auth().currentUser) {
      return signInWithDelegation().then(() => {
        return fetchFirebasePath(path, lastKey, pageSize);
      });
    }
    return fetchFirebasePath(path, lastKey, pageSize);
  },
  createFirebaseWatcher: (path, callback) => {
    if (!firebase.auth().currentUser) {
      return signInWithDelegation().then(() => {
        return createWatcher(path, callback);
      });
    }
    return createWatcher(path, callback);
  },
  writeToFirebase: (path, value) => {
    if (!firebase.auth().currentUser) {
      return signInWithDelegation().then(() => {
        return setFirebasePath(path, value);
      });
    }
    return setFirebasePath(path, value);
  },
  pushToFirebase: (path, value) => {
    if (!firebase.auth().currentUser) {
      return signInWithDelegation().then(() => {
        return pushFirebasePath(path, value);
      });
    }
    return pushFirebasePath(path, value);
  },
  deleteFromFirebase: (path) => {
    if (!firebase.auth().currentUser) {
      return signInWithDelegation().then(() => {
        return setFirebasePath(path, null);
      });
    }
    return setFirebasePath(path, null);
  },
  updateFirebase: (path, value) => {
    if (!firebase.auth().currentUser) {
      return signInWithDelegation().then(() => {
        return updateFirebasePath(path, value);
      });
    }
    return updateFirebasePath(path, value);
  },
  addIfNotExist: (path, property, value) => {
    if (!firebase.auth().currentUser) {
      return signInWithDelegation().then(() => {
        return updateFirebasePathIfNotExist(path, property, value);
      });
    }
    return updateFirebasePathIfNotExist(path, property, value);
  },
  TimestampPlaceholder: firebase.database.ServerValue.TIMESTAMP,
  getFirebaseProviderProps: () => {
    const rrfConfig = {
      /* no config needed atm */
    };
    const rrfProps = {
      firebase,
      config: rrfConfig,
    };
    return rrfProps;
  },
};
