import React from 'react';
import FilterTag, { FILTER_ICONS } from 'components/molecules/FilterTag';
import Select from 'components/molecules/Select';
import Filter from './filter.js';

export default class SelectFilter extends Filter {
  constructor(...args) {
    super(...args);
    this.state = {
      filtered: null,
      all: [],
    };

    // override title and name in child component
    this.title = 'Single Select';
    this.name = 'Single Select';
  }

  // override
  apply(item) {
    if (!this.active()) {
      return true;
    }
    return this.state.filtered === item.value;
  }

  // override
  data({ tutors }) {
    this.setState({ all: tutors.map((t) => t.city) });
  }

  reset() {
    this.setState({
      filtered: null,
    });
  }

  handleSelect(value) {
    this.setState({
      filtered: value,
    });
  }

  active() {
    return this.state.filtered !== null;
  }

  id() {
    return this.name.toLowerCase().replace(' ', '-');
  }

  edit() {
    const options = this.state.all
      .filter((i) => !!i)
      .reduce((unique, value) => {
        if (!unique.includes(value)) {
          unique.push(value);
        }
        return unique;
      }, [])
      .map((value) => {
        return { value, label: value };
      });
    return (
      <Select
        id={`${this.id()}Selector`}
        dataTestId={`${this.id()}Selector`}
        name={this.title}
        value={options.find((option) => option.value === this.state.filtered)}
        onChange={({ value }) => this.handleSelect(value)}
        options={options}
      />
    );
  }

  tag() {
    return (
      <FilterTag
        dataTestId={`${this.id()}FilterTag`}
        onClear={() => this.reset()}
        onClick={() => this.openModal()}
        value={this.state.filtered}
        displayValue={this.state.filtered}
        label={this.name}
        icon={FILTER_ICONS.SELECT}
      />
    );
  }
}
