import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/molecules/Modal';
import css from './styles.scss';

export default function AppleCalInstructionModal(props) {
  const { isOpen, onCancel } = props;
  const finishReadAppCalInstruction = () => {
    onCancel();
  };
  return (
    <Modal
      isOpen={isOpen}
      title="How to generate an app-specific password?"
      onClose={onCancel}
      submitText="GOT IT"
      onSubmit={finishReadAppCalInstruction}
    >
      <div className={css.front}>
        <div className={css.modalBody}>
          <div className={css.bookingWithWrapper}>
            <ul className={css.appleInstrUl}>
              <li>
                <div>
                  <div className={css.stepIcon}>1</div>
                  Log in to{' '}
                  <a
                    className={css.link}
                    href="https://appleid.apple.com"
                    alt="appleWebsite"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    appleid.apple.com
                  </a>
                </div>
                <img alt="appleid-signin-hero-cut" src="/static/iCalSyncGuidePic/appleid-signin-hero-cut.jpg" />
              </li>
              <li>
                <div>
                  <div className={css.stepIcon}>2</div>
                  Click Generate Password in the Security section
                </div>
                <img
                  alt="generate-app-specifc-passowrd"
                  src="/static/iCalSyncGuidePic/generate-app-specifc-passowrd.png"
                />
              </li>
              <li>
                <div>
                  <div className={css.stepIcon}>3</div>
                  Give the app-specific password a name of your choosing (e.g. Crimson app)
                </div>
                <img alt="create-for-crimson-app" src="/static/iCalSyncGuidePic/create-for-crimson-app.png" />
              </li>
              <li>
                <div>
                  <div className={css.stepIcon}>4</div>
                  Copy the app-specific password you generated, and paste into the prompt
                </div>
                <img alt="generated-password" src="/static/iCalSyncGuidePic/generated-password.png" />
              </li>
            </ul>
            <div>
              Learn more about using{' '}
              <a
                className={css.link}
                href="https://support.apple.com/en-us/HT204397"
                target="_blank"
                rel="noopener noreferrer"
              >
                app-specific passwords
              </a>{' '}
              from Apple support website.
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

AppleCalInstructionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
};
