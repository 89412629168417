import React from 'react';
import { Column } from 'components/generic/Table';
import { columnNames } from '../columnMap';

function renderSubjects(rowData) {
  if (rowData.getIn(['tutorInfo', 'subjects'])) {
    return rowData
      .getIn(['tutorInfo', 'subjects'])
      .map((subjects, i) => <div key={i}>{subjects.get('subject')}</div>)
      .toArray();
  }
  if (rowData.getIn(['tutorInfo', 'contractSubjects'])) {
    return rowData
      .getIn(['tutorInfo', 'contractSubjects'])
      .map((subjects, i) => <div key={i}>{subjects.get('name')}</div>)
      .toArray();
  }
  return 'No Subjects Available';
}
export default function TutorSubjects() {
  return (
    <Column
      key={columnNames.TutorSubjects}
      name="Subjects"
      flex="3 1 0"
      renderer={({ rowData }) => <div>{renderSubjects(rowData)}</div>}
    />
  );
}
