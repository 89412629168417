import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Acl } from '@crimson-education/common-config';
import Header from 'components/molecules/Header';

import Summary from './Summary';
import LessonDetailsContent from './LessonDetailsContent';
import css from './styles.scss';

function canEditDetails(lesson, loginUser) {
  const lessonTutorId = lesson.get('tutor').get('userId');
  const loginUserId = loginUser.get('userId');
  if (lessonTutorId === loginUserId) {
    return true;
  } else if (Acl.isAdmin(loginUser.get('userRoles'))) {
    return true;
  }
  return false;
}

export default function LessonDetails(props) {
  const {
    loginUser,
    lessonId,
    lesson,
    onSaveLessonInfo,
    comments,
    postComment,
    postReply,
    deleteFile,
    downloadFile,
    deleteComment,
    getCachedUsers,
    editComment,
  } = props;

  const canEdit = canEditDetails(lesson, loginUser);
  const userId = loginUser.get('userId');

  const onPostComment = (comment, file, lessonDetails) => postComment(userId, lessonId, comment, file, lessonDetails);
  const onReplyComment = (parentId, reply, file, lessonDetails) =>
    postReply(userId, lessonId, parentId, reply, file, lessonDetails);
  const onDeleteCommentFile = (fileId, commentId, replyId, lessonDetails, comment) =>
    deleteFile(lessonId, commentId, replyId, fileId, lessonDetails, comment, loginUser);
  const onDeleteComment = (commentId, replyId, lessonDetails, comment) =>
    deleteComment(lessonId, commentId, replyId, lessonDetails, comment, loginUser);
  const onEditComment = (commentId, replyId, message) => editComment(lessonId, commentId, replyId, message);

  return (
    <div>
      <Header
        title={lesson.get('title')}
        isBackButtonVisible
        backButtonText="Lessons Overview"
        backButtonUrl={`/subjects/${lesson.get('itemId')}/lessons`}
      />
      <div className={css.horizontalLine} />
      <div className={css.container}>
        <Summary summary={lesson.get('summary')} onSaveInfo={onSaveLessonInfo} canEdit={canEdit} />
        <LessonDetailsContent
          loginUser={loginUser}
          lesson={lesson}
          comments={comments}
          onPostComment={onPostComment}
          onEditComment={onEditComment}
          onReplyComment={onReplyComment}
          onDeleteCommentFile={onDeleteCommentFile}
          onDeleteComment={onDeleteComment}
          onDownloadCommentFile={downloadFile}
          getCachedUsers={getCachedUsers}
        />
      </div>
    </div>
  );
}

LessonDetails.propTypes = {
  loginUser: ImmutablePropTypes.map.isRequired,
  lessonId: PropTypes.string.isRequired,
  lesson: ImmutablePropTypes.map.isRequired,
  onSaveLessonInfo: PropTypes.func.isRequired,
  comments: ImmutablePropTypes.map,
  postComment: PropTypes.func.isRequired,
  editComment: PropTypes.func.isRequired,
  postReply: PropTypes.func.isRequired,
  deleteFile: PropTypes.func.isRequired,
  downloadFile: PropTypes.func.isRequired,
  deleteComment: PropTypes.func.isRequired,
  getCachedUsers: ImmutablePropTypes.map,
};

LessonDetails.displayName = 'LessonDetails';
