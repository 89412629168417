import { getEnvironmentConfig } from '@crimson-education/common-config/lib/environment';
import { gql } from '@apollo/client';
import getGraphQLClient from '../';

const { api } = getEnvironmentConfig();

export const client = getGraphQLClient(`${api.endpoint}/register`);

export const registerNewUser = gql`
  mutation registerNewUser($firstName: String!, $lastName: String!, $email: String!, $tagId: ID!) {
    registerNewUser(firstName: $firstName, lastName: $lastName, email: $email, tagId: $tagId)
  }
`;
export const getSchoolList = gql`
  query getSchoolList {
    getSchoolList {
      id
      tagId
      emailDomain
      tagName
    }
  }
`;
