import moment from 'moment';

/**
 * This function will 'squeeze' a new event into a sorted list of existing events, returning a new list of the new events.
 * Existing events that conflict with the new event will have their start and/or end times overwritten so they no longer clash
 * Existing events that are completely encompassed by the new event will be removed entirely.
 * @param {Array} existingEvents The existing events
 * @param {start: Date, end: Date} newEvent The event to insert into the existing list
 */
export default function (existingEvents, newEvent) {
  if (!(newEvent && newEvent.start && newEvent.end)) return existingEvents;
  if (!(existingEvents && existingEvents.length)) return [newEvent];

  const splitEvents = []; // If existing events need to be split, they will be put in here.
  // Remove encompassed events
  // const filteredEvents = existingEvents.filter(e => !(e.start >= newEvent.start && e.end <= newEvent.end));
  const filteredEvents = existingEvents;
  filteredEvents.forEach((event, index) => {
    const durationInMinutes = moment.duration(moment(event.end).diff(moment(event.start))).asMinutes();
    const newDurationInMinutes = moment.duration(moment(newEvent.end).diff(moment(newEvent.start))).asMinutes();
    const lengthOfDayInMinutes = 24 * 60;

    const overlapIndex = event.overlapIndex;
    const overlapArray = event.overlapArray;
    let isOverlapped = false;
    if (durationInMinutes / lengthOfDayInMinutes > 0.99 || newDurationInMinutes / lengthOfDayInMinutes > 0.99) {
      isOverlapped = false;
    } else if (event.start < newEvent.start && event.end > newEvent.end) {
      // Split encompassing event into two
      isOverlapped = true;
    } else if (event.start < newEvent.start && event.end > newEvent.start) {
      isOverlapped = true;
      // event.end = newEvent.start; // Cut the conflicting event short
    } else if (event.start < newEvent.end && event.end > newEvent.end) {
      isOverlapped = true;
      // event.start = newEvent.end; // Push forward the conflicting event
    } else if (event.start >= newEvent.start && event.end <= newEvent.end) {
      isOverlapped = true;
    }
    if (isOverlapped) {
      if (!overlapIndex) {
        event.overlapIndex = 0;
        event.overlapArray = [index, index + 1];
        newEvent.overlapIndex = 1;
        newEvent.overlapArray = [index, index + 1];
      } else {
        event.overlapArray.push(overlapArray.length);
        newEvent.overlapArray = event.overlapArray;
        newEvent.overlapIndex = overlapIndex + 1;
      }
    }
  });
  const eventsToInsert = splitEvents.concat([newEvent]);
  // Insert all new events into this pre-sorted array while maintaining sort order.
  eventsToInsert.forEach((eventToPush) => {
    const insertionIndex = filteredEvents.findIndex((e) => e.start > eventToPush.start);
    insertionIndex < 0
      ? filteredEvents.push(eventToPush) // The new event is the last to occur
      : filteredEvents.splice(insertionIndex, 0, eventToPush);
  });
  return filteredEvents;
}
