import moment from 'moment';
import { createSelector } from 'reselect';
import Immutable from 'immutable';

import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';
import { getAppLoginUser } from 'selectors/meta';
import { getUsersMap } from './threads';

const cfg = getConfig();

const MESSAGE_RECENCY_THRESHOLD = 60;
const state = (state) => state;
const messaging = (state) => state.get('messaging');

const firebase = (state) => state.get('firebase');
const { rootPath } = cfg.firebase;
export const firebaseState = createSelector(
  firebase,
  (fb) => (fb && fb.data && fb.data[rootPath] && fb.data[rootPath].userState) || {},
);

export const loggedInFirebaseState = createSelector(firebaseState, getAppLoginUser, (fb, userId) => fb[userId] || {});

// The messages for the logged in user
export const firebaseMessagesMap = createSelector(loggedInFirebaseState, (fb) => fb.unreadMessages || {});

// firebaseMessagesMap is changing with typing indicator changes we don't want that
// fixing it would avoid a bunch of rerenders
export const mergedMessages = createSelector(messaging, (messaging) => messaging.getIn(['entities', 'message']));

// deprecated
export const getMessages = (threadId) =>
  createSelector(mergedMessages, (messages) =>
    messages
      .filter((x) => x.get('threadId') === threadId)
      .sort((a, b) => new Date(a.get('createdAt')) - new Date(b.get('createdAt')))
      .toList(),
  );

export const getMessageById = (messageId) => createSelector(mergedMessages, (messages) => messages.get(messageId));

export const getFailedMessages = (threadId) =>
  createSelector(getMessages(threadId), (messages) => messages.filter((m) => m.get('isError')));

export const getMessagesGroupedByDay = (threadId) =>
  createSelector(state, getUsersMap, getMessages(threadId), (state, users, messages) => {
    const messagesByDay = messages.groupBy((message) => moment(message.get('createdAt')).startOf('day'));
    const messagesByDayAndUser = messagesByDay.map((x) =>
      x.reduce((a, msg, i, arr) => {
        const previousSenderId = arr.getIn([i - 1, 'senderId']);
        const previousTimestamp = moment(arr.getIn([i - 1, 'createdAt']));
        const senderId = msg.get('senderId');
        const timestamp = moment(msg.get('createdAt'));

        const timeDifferenceInSeconds = timestamp.diff(previousTimestamp, 'seconds');
        const previousType = arr.getIn([i - 1, 'type']);
        const type = msg.get('type');
        if (
          i === 0 ||
          previousSenderId !== senderId ||
          timeDifferenceInSeconds > MESSAGE_RECENCY_THRESHOLD ||
          type === 'BOT_INVITE' ||
          type === 'BOT_REMOVE' ||
          previousType === 'BOT_INVITE' ||
          previousType === 'BOT_REMOVE'
        ) {
          return a.push(
            new Immutable.Map({
              senderId,
              sender: users.get(senderId),
              messages: new Immutable.List([msg]),
              timestamp,
            }),
          );
        }

        return a.updateIn([a.size - 1, 'messages'], (m) => m.push(msg));
      }, new Immutable.List()),
    );

    return messagesByDayAndUser;
  });

export const getLatestMessage = (threadId) =>
  createSelector(state, messaging, (state, messaging) => {
    const messageNodes = messaging.getIn(['entities', 'thread', threadId, 'messages', 'edges']);
    return messageNodes && messageNodes.size > 0 ? getMessageById(messageNodes.first().get('node'))(state) : null;
  });

export const getLatestMessageStatus = (threadId) =>
  createSelector(getLatestMessage(threadId), (latestMessage) => {
    return latestMessage ? latestMessage.get('status') : '';
  });
