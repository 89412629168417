import { Acl, roleTypes, permissionTypes } from '@crimson-education/common-config';
import { PermissionResourceType } from '@crimson-education/common-config/lib/authorization';

const profileTabs = {
  RATING: 'RATING',
  BILLING: 'BILLING',
  PACKAGE: 'PACKAGE',
  SUBJECTS: 'SUBJECTS',
  OTHER_SESSIONS: 'OTHER_SESSIONS',
  STUDENTS: 'STUDENTS',
  TUTORS: 'TUTORS',
  AVAILABILITY: 'AVAILABILITY',
  ROADMAP: 'ROADMAP',
  ROADMAP_TEMPLATES: 'ROADMAP_TEMPLATES',
  GRADES: 'GRADES',
  ADMISSION_RESULTS: 'ADMISSION_RESULTS',
  TEST_PREP: 'TEST_PREP',
  TASKS: 'TASKS',
  PROGRESS_REPORT: 'PROGRESS_REPORT',
  ECL: 'ECL',
  APPLICATIONS: 'APPLICATIONS',
  CGA_STUDENT_PROGRESS_REPORTS: 'CGA_STUDENT_PROGRESS_REPORTS',
  SSA: 'SSA',
};

const {
  RATING,
  BILLING,
  PACKAGE,
  STUDENTS,
  TUTORS,
  SUBJECTS,
  OTHER_SESSIONS,
  AVAILABILITY,
  ROADMAP,
  ROADMAP_TEMPLATES,
  GRADES,
  ADMISSION_RESULTS,
  TEST_PREP,
  TASKS,
  PROGRESS_REPORT,
  ECL,
  APPLICATIONS,
  CGA_STUDENT_PROGRESS_REPORTS,
  SSA,
} = profileTabs;

export const profileTabMapping = {
  [roleTypes.SUPER_ADMIN]: [ROADMAP_TEMPLATES],
  [roleTypes.OPERATIONAL_SUPPORT]: [],
  [roleTypes.CASE_MANAGER]: [STUDENTS, AVAILABILITY, ROADMAP_TEMPLATES],
  [roleTypes.STRATEGIST]: [STUDENTS, AVAILABILITY, ROADMAP_TEMPLATES, SSA],
  [roleTypes.HEAD_TUTOR]: [
    // TODO: Currently disabled as there is no component for this
    // tab. Review ability to show this tab when component is built.
    // STUDENTS,
    TUTORS,
    AVAILABILITY,
  ],
  [roleTypes.TUTOR]: [RATING, BILLING, STUDENTS, AVAILABILITY],
  [roleTypes.STUDENT]: [
    PACKAGE,
    SUBJECTS,
    OTHER_SESSIONS,
    ECL,
    ROADMAP,
    GRADES,
    ADMISSION_RESULTS,
    TEST_PREP,
    TASKS,
    APPLICATIONS,
    PROGRESS_REPORT,
    SSA,
  ],
  [roleTypes.EXTERNAL_STUDENT]: [TEST_PREP],
  [roleTypes.CGA_STUDENT]: [CGA_STUDENT_PROGRESS_REPORTS],
  [roleTypes.REVIEWER]: [STUDENTS],
};

const getAllAvailableTabsForRoles = (roles) => {
  if (!roles) return [];
  // get available tabs from each role
  const tabs = roles.map((role) => profileTabMapping[role] || []).reduce((p, c) => p.concat(c), []);

  // remove duplicate tabs
  return [...new Set(tabs)];
};

const checkRolesHaveTab = (roles, tab) => {
  return roles ? getAllAvailableTabsForRoles(roles).includes(tab) : false;
};

const isRatingTabAvailable = (currentUserRoles) => checkRolesHaveTab(currentUserRoles, profileTabs.RATING);
export const isBillingTabAvailable = (currentUserRoles) => checkRolesHaveTab(currentUserRoles, profileTabs.BILLING);
export const isPackageTabAvailable = (currentUserRoles) => checkRolesHaveTab(currentUserRoles, profileTabs.PACKAGE);
export const isSubjectsTabAvailable = (currentUserRoles) => checkRolesHaveTab(currentUserRoles, profileTabs.SUBJECTS);
export const isOtherSessionsTabAvailable = (currentUserRoles) =>
  checkRolesHaveTab(currentUserRoles, profileTabs.OTHER_SESSIONS);
export const isStudentsTabAvailable = (currentUserRoles) => checkRolesHaveTab(currentUserRoles, profileTabs.STUDENTS);
export const isAvailabilityTabAvailable = (currentUserRoles) =>
  checkRolesHaveTab(currentUserRoles, profileTabs.AVAILABILITY);
export const isRoadmapTabAvailable = (currentUserRoles) => checkRolesHaveTab(currentUserRoles, profileTabs.ROADMAP);
export const isTasksTabAvailable = (currentUserRoles) => checkRolesHaveTab(currentUserRoles, profileTabs.TASKS);
export const isRoadmapTemplatesTabAvailable = (currentUserRoles) =>
  checkRolesHaveTab(currentUserRoles, profileTabs.ROADMAP_TEMPLATES);
export const isGradesTabAvailable = (currentUserRoles) => checkRolesHaveTab(currentUserRoles, profileTabs.GRADES);
export const isAdmissionResultsTabAvaiable = (currentUserRoles) =>
  checkRolesHaveTab(currentUserRoles, profileTabs.ADMISSION_RESULTS);
export const isTestPrepTabAvailable = (currentUserRoles) => checkRolesHaveTab(currentUserRoles, profileTabs.TEST_PREP);
export const isProgressReportsAvailable = (currentUserRoles) =>
  checkRolesHaveTab(currentUserRoles, profileTabs.PROGRESS_REPORT);
export const isEclTabAvailable = (currentUserRoles) => checkRolesHaveTab(currentUserRoles, profileTabs.ECL);
export const isApplicationsTabAvailable = (currentUserRoles) =>
  checkRolesHaveTab(currentUserRoles, profileTabs.APPLICATIONS);
export const isTutorsTabAvailable = (currentUserRoles) => checkRolesHaveTab(currentUserRoles, profileTabs.TUTORS);
export const isCGAStudentRProgressReportsTabAvailable = (currentUserRoles) =>
  checkRolesHaveTab(currentUserRoles, profileTabs.CGA_STUDENT_PROGRESS_REPORTS);
export const isSSATabAvailable = (currentUserRoles) => checkRolesHaveTab(currentUserRoles, profileTabs.SSA);

export const canViewProfile = () => true;

// Determine if the availability tab is shown.
export const canViewAvailabilities = (loginUserPermissions, selectedUser, selectedUserRoles, loginUserId) => {
  // Show the tab if the selected user owns the profile being viewed, the user
  // role has the ability to set unavailabilities, and the user has permission
  // to view their availability tab.
  return (
    selectedUser.userId === loginUserId &&
    isAvailabilityTabAvailable(selectedUserRoles) &&
    Acl.checkPermission(loginUserPermissions, permissionTypes.VIEW_AVAILABILITY_TAB) &&
    Acl.checkPermission(loginUserPermissions, permissionTypes.VIEW_AVAILABILITY)
  );
};

export const canEditAvailabilities = (selectedUser, loginUserId) => selectedUser.userId === loginUserId;

export const canViewRating = (loginUserPermissions, selectedUserRoles) =>
  Acl.checkPermission(loginUserPermissions, permissionTypes.VIEW_RATING_TAB) && isRatingTabAvailable(selectedUserRoles);

export const canViewBilling = (loginUserPermissions, selectedUserRoles) => {
  return (
    Acl.checkPermission(loginUserPermissions, permissionTypes.VIEW_BILLING_TAB) &&
    isBillingTabAvailable(selectedUserRoles)
  );
};

export const canViewPackages = (loginUserPermissions, selectedUserRoles) => {
  return (
    Acl.checkPermission(loginUserPermissions, permissionTypes.VIEW_PACKAGE) &&
    Acl.checkPermission(loginUserPermissions, permissionTypes.VIEW_PACKAGE_TAB) &&
    isPackageTabAvailable(selectedUserRoles)
  );
};

export const canViewSubjects = (loginUserPermissions, selectedUserRoles) => {
  return (
    Acl.checkPermission(loginUserPermissions, permissionTypes.VIEW_PACKAGE) &&
    Acl.checkPermission(loginUserPermissions, permissionTypes.VIEW_SUBJECTS_TAB) &&
    isSubjectsTabAvailable(selectedUserRoles)
  );
};

export const canViewOtherSessions = (loginUserPermissions, selectedUserRoles) => {
  return (
    Acl.checkPermission(loginUserPermissions, permissionTypes.VIEW_OTHER_SESSIONS_TAB) &&
    isOtherSessionsTabAvailable(selectedUserRoles)
  );
};

export const canViewStudents = (loginUserPermissions, selectedUserRoles, selectedUser, loginUserId) => {
  return (
    Acl.checkPermission(loginUserPermissions, permissionTypes.VIEW_STUDENTS_TAB) &&
    isStudentsTabAvailable(selectedUserRoles) &&
    selectedUser.userId !== loginUserId
  );
};

export const canCreateRoadmap = (loginUserPermissions, selectedUserRoles) => {
  return (
    Acl.checkPermission(loginUserPermissions, permissionTypes.CREATE_ROADMAP) &&
    isRoadmapTabAvailable(selectedUserRoles)
  );
};

export const canCreateTemplate = (loginUserPermissions, selectedUserRoles, selectedUser, loginUserId) => {
  return (
    Acl.checkPermission(loginUserPermissions, permissionTypes.CREATE_ROADMAP_TEMPLATE) &&
    isRoadmapTemplatesTabAvailable(selectedUserRoles) &&
    selectedUser.userId === loginUserId
  );
};

export const canViewRoadmap = (loginUserPermissions, selectedUserRoles, roadmap, selectedUser, loginUserId) => {
  return (
    Acl.checkPermission(loginUserPermissions, permissionTypes.VIEW_ROADMAP) &&
    isRoadmapTabAvailable(selectedUserRoles) &&
    selectedUser.userId !== loginUserId &&
    (roadmap || canCreateRoadmap(loginUserPermissions, selectedUserRoles))
  );
};

export const canViewGrades = (loginUserPermissions, selectedUserRoles) => {
  return (
    Acl.checkPermission(loginUserPermissions, permissionTypes.GRADE_VIEW_ALL) && isGradesTabAvailable(selectedUserRoles)
  );
};

export const canViewAdmissions = (loginUserPermissions, selectedUserRoles) => {
  return (
    Acl.checkPermission(loginUserPermissions, permissionTypes.VIEW_ADMISSION_RESULTS) &&
    isAdmissionResultsTabAvaiable(selectedUserRoles)
  );
};

export const canViewInviteUserButton = (loginUserPermissions) => {
  return Acl.checkPermission(loginUserPermissions, permissionTypes.CREATE_USER);
};

export const canViewTestPrep = (loginUserPermissions, selectedUserRoles) => {
  return (
    Acl.checkPermission(loginUserPermissions, permissionTypes.VIEW_EXAM_PREP_TAB) &&
    isTestPrepTabAvailable(selectedUserRoles)
  );
};

export const canViewProgressReport = (loginUserPermissions, selectedUserRoles) => {
  return (
    Acl.checkPermission(loginUserPermissions, permissionTypes.VIEW_PROGRESS_REPORT_TAB) &&
    isProgressReportsAvailable(selectedUserRoles)
  );
};

export const canViewTasks = (loginUserPermissions, selectedUserRoles) => {
  return (
    Acl.checkPermission(loginUserPermissions, permissionTypes.VIEW_ALL_TASKS) && isTasksTabAvailable(selectedUserRoles)
  );
};

export const canEditTasks = (loginUserPermissions) => {
  return Acl.checkPermission(loginUserPermissions, permissionTypes.EDIT_ROADMAP);
};

export const canDeleteTasks = (loginUserPermissions) => {
  return Acl.checkPermission(loginUserPermissions, permissionTypes.DELETE_ROADMAP_ELEMENTS);
};

export const canViewECL = (loginUserPermissions, selectedUserRoles) => {
  return (
    Acl.checkPermission(loginUserPermissions, permissionTypes.VIEW_ALL_ECL) && isEclTabAvailable(selectedUserRoles)
  );
};

export const canViewApplications = (loginUserPermissions, selectedUserRoles) => {
  return (
    Acl.checkPermission(loginUserPermissions, permissionTypes.VIEW_ALL_APPLICATIONS) &&
    isApplicationsTabAvailable(selectedUserRoles)
  );
};
export const canViewTutors = (loginUserPermissions, selectedUserRoles, selectedUser, loginUserId) => {
  return (
    Acl.checkPermission(loginUserPermissions, permissionTypes.VIEW_TUTOR) &&
    isTutorsTabAvailable(selectedUserRoles) &&
    selectedUser.userId !== loginUserId
  );
};
export const canViewCGAStudentProgressReports = (loginUserPermissionsInfo, selectedUserRoles) => {
  return (
    loginUserPermissionsInfo.filter(
      (p) => p.get('resourceType') === PermissionResourceType.CGAStudentReports && p.get('resource') !== 'own',
    ).length > 0 && Acl.checkRole(selectedUserRoles, roleTypes.CGA_STUDENT)
  );
};

export const canViewStudentPathfinder = (selectedUserRoles, currentUserRoles) => {
  return (
    Acl.checkRole(currentUserRoles, roleTypes.STUDENT) &&
    (Acl.checkRole(selectedUserRoles, roleTypes.SUPER_ADMIN) ||
      Acl.checkRole(selectedUserRoles, roleTypes.CASE_MANAGER) ||
      Acl.checkRole(selectedUserRoles, roleTypes.OPERATIONAL_SUPPORT) ||
      Acl.checkRole(selectedUserRoles, roleTypes.STRATEGIST) ||
      Acl.checkRole(selectedUserRoles, roleTypes.HEAD_TUTOR) ||
      Acl.checkRole(selectedUserRoles, roleTypes.TUTOR))
  );
};

export const canViewSSA = (_, selectedUserRoles) => {
  return isSSATabAvailable(selectedUserRoles);
};

export const canViewAppTrackerStaffDashboard = (userRoles) => {
  return Acl.checkRole(userRoles, roleTypes.STRATEGIST) || Acl.checkRole(userRoles, roleTypes.CASE_MANAGER);
};
