import styled from 'styled-components';
import React from 'react';
import { Button } from 'antd';

export const StyledContainer = styled.div`
  display: inline-block;
  position: relative;
`;
export const StyledPopover = styled.div`
  cursor: pointer;
  padding: 8px 10px;
  position: absolute;
  background: #f3f6fa; // theme color: mist
  border: 1px solid #e3e7ed; // theme color: pale grey
  border-radius: 4px;
  color: #73747d; // theme color: stone
  z-index: 1;
  width: max-content;
  top: 30px;
  left: -95px;
`;

export const StyledModalTitle = styled.div`
  height: 29px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #1d1e2b;
`;

export const StyledModalDesc = styled.div`
  height: 20px;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #1d1e2b;
  margin-top: 20px;
  margin-bottom: 40px;
`;

export const StyledButtonGroup = styled.div`
  margin-top: 60px;
  display: flex;
  margin-bottom: 30px;
  flex-direction: row;
  flex-wrap: no-wrap;
  justify-content: space-evenly;
  align-items: center;
`;

export const StyledModalContainer = styled.div`
  text-align: center;
`;

export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
`;

const FloatingSessionConfirm = ({
  modalText = 'Are you sure you want to leave the page without saving?',
  onOk,
  onCancel,
}: {
  modalText?: string;
  onOk: () => void;
  onCancel: () => void;
}): JSX.Element => {
  return (
    <StyledModalContainer>
      <StyledModalTitle>Leaving without saving？</StyledModalTitle>
      <StyledModalDesc>
        <div>{modalText}</div>
      </StyledModalDesc>
      <ButtonGroup>
        <Button
          style={{
            background: '#ffffff',
            color: '#464AC9',
            border: '1px solid #464AC9',
            borderRadius: '28px',
            width: '130px',
            height: '40px',
            fontWeight: 700,
          }}
          onClick={() => onCancel()}
        >
          Leave anyway
        </Button>
        <Button
          style={{
            background: '#464AC9',
            color: '#ffffff',
            border: '1px solid #464AC9',
            borderRadius: '28px',
            width: '130px',
            height: '40px',
            fontWeight: 700,
            marginLeft: '8px',
          }}
          onClick={() => onOk()}
        >
          Save
        </Button>
      </ButtonGroup>
    </StyledModalContainer>
  );
};

export default FloatingSessionConfirm;
