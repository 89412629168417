import React from 'react';
import { Column } from 'components/molecules/Table';
import { columnNames } from '../columnMap';

function prettyPrintAllRoles(user) {
  const userRoles = user.get('roles');
  return userRoles.map((r) => r.get('role').get('name')).join(', ');
}

export default function UserQueryTable() {
  return (
    <Column
      key={columnNames.UserType}
      name="Type of User"
      renderer={({ rowData }) => `${prettyPrintAllRoles(rowData)}`}
    />
  );
}
