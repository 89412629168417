import React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { isToday, isPast } from 'utils/calendarUtils';
import { BookingStatusTypes } from '@crimson-education/common-config';

import css from './styles.scss';

export default function WeekHeader({ week }) {
  return (
    <div className={css.weekHeaderContainer}>
      <div className={css.allDay}>all-day</div>
      <div className={css.weekHeaderRow}>
        {week.map((day) => {
          const date = day.get('date');
          const bookings = day.get('dayElements').filter((d) => d.get('type') === 'BOOKING');
          const allday = day.get('dayElements').filter((element) => {
            const booking = element.get('booking');
            if (booking) {
              let startTimeToUse = booking.get('start');
              let endTimeToUse = booking.get('end');
              const isSameDay = moment(startTimeToUse).isSame(endTimeToUse, 'day');
              if (!isSameDay) {
                if (moment(startTimeToUse).isSame(date, 'day')) {
                  endTimeToUse = moment(date).endOf('day');
                } else if (moment(endTimeToUse).isSame(date, 'day')) {
                  startTimeToUse = moment(date).startOf('day');
                } else {
                  startTimeToUse = moment(date).startOf('day');
                  endTimeToUse = moment(date).endOf('day');
                }
              }
              const durationInMinutes = moment.duration(moment(endTimeToUse).diff(moment(startTimeToUse))).asMinutes();
              const lengthOfDayInMinutes = 24 * 60;
              const percent = durationInMinutes / lengthOfDayInMinutes;
              if (percent > 0.99) {
                return element;
              }
            }
            return undefined;
          });
          return (
            <div
              key={`${day.get('date')}header`}
              className={classNames(
                css.weekHeader,
                css.columnWidth,
                { [css.today]: isToday(day.get('date')) },
                { [css.pastDate]: isPast(day.get('date')) },
              )}
            >
              <div className={css.date}>
                <div>{moment(day.get('date')).format('ddd')}</div>
                <div>{moment(day.get('date')).format('D')}</div>
              </div>
              {bookings && bookings.size ? (
                <div className={css.indicators}>
                  {bookings.map((b) => (
                    <div
                      key={b.getIn(['booking', 'id'])}
                      className={classNames(
                        css.indicator,
                        {
                          [css.external]:
                            b.getIn(['booking', 'source']) && b.getIn(['booking', 'source']) !== 'crimson-app',
                        },
                        { [css.past]: isPast(b.getIn(['booking', 'end']), 'minutes') },
                      )}
                    >
                      {b.getIn(['booking', 'status']) === 'tentative' ? (
                        <i className="zmdi zmdi-circle-o" />
                      ) : (
                        <i className="zmdi zmdi-circle" />
                      )}
                    </div>
                  ))}
                </div>
              ) : null}
              {allday.map((e) => {
                const booking = e.get('booking');
                const source = booking.get('source');
                const status = booking.get('status');
                let startTimeToUse = booking.get('start');
                let endTimeToUse = booking.get('end');
                const isSameDay = moment(startTimeToUse).isSame(endTimeToUse, 'day');
                if (!isSameDay) {
                  if (moment(startTimeToUse).isSame(date, 'day')) {
                    endTimeToUse = moment(date).endOf('day');
                  } else if (moment(endTimeToUse).isSame(date, 'day')) {
                    startTimeToUse = moment(date).startOf('day');
                  } else {
                    startTimeToUse = moment(date).startOf('day');
                    endTimeToUse = moment(date).endOf('day');
                  }
                }
                const displayStartTime = startTimeToUse;
                const displayEndTime = endTimeToUse;
                const realStartTime = moment(booking.get('start'));
                const realEndTime = moment(booking.get('end'));

                return (
                  <div
                    key={e.getIn(['booking', 'id'])}
                    className={classNames(
                      css.block,
                      css.alldayBlock,
                      { [css.external]: source && source !== 'crimson-app' },
                      { [css.confirmed]: status === BookingStatusTypes.CONFIRMED },
                      {
                        [css.completed]:
                          status === BookingStatusTypes.COMPLETED || status === BookingStatusTypes.AUTO_COMPLETED,
                      },
                      {
                        [css.pending]:
                          status !== BookingStatusTypes.CONFIRMED &&
                          status !== BookingStatusTypes.COMPLETED &&
                          status !== BookingStatusTypes.AUTO_COMPLETED,
                      },
                      { [css.continued]: displayStartTime - realStartTime !== 0 },
                      { [css.continuing]: displayEndTime - realEndTime !== 0 },
                      { [css.past]: displayEndTime.isBefore(moment()) },
                      {
                        [css.inProgress]:
                          status === BookingStatusTypes.CONFIRMED &&
                          moment().isBetween(displayStartTime, displayEndTime),
                      },
                    )}
                  >
                    <div className={classNames(css.description, css.textContent)}>{e.get('booking').get('name')}</div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
}

WeekHeader.propTypes = {
  week: ImmutablePropTypes.list.isRequired,
};
