import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';
import { gql } from '@apollo/client';
import { createClient } from '../utils';

const { api } = getConfig();

const client = createClient(api.graphQLEndpoint);

const recreateZoomMeeting = gql`
  mutation recreateZoomMeeting($eventId: String!) {
    recreateZoomMeeting(eventId: $eventId) {
      startUrl
      joinUrl
    }
  }
`;

export default {
  /**
   * Recreate a zoom meeting in case current url doesn't work.
   *
   * @param {Int!} eventId
   */
  recreateZoomMeeting: (eventId) => {
    return client.query(recreateZoomMeeting, { eventId });
  },
};
