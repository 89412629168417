import { connect } from 'react-redux';
import { getMissionsByRoadmapIdWithDateText } from 'selectors/roadmap';
import { showPageLoader } from 'ducks/meta';
import { fetchRoadmapByUserId } from 'ducks/roadmap';
import SearchMission from './SearchMission';

const mapStateToProps = (state, ownProps) => ({
  missions: getMissionsByRoadmapIdWithDateText(state)(ownProps.roadmapId),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchRoadmapByUserId: showPageLoader(() => dispatch(fetchRoadmapByUserId(ownProps.userId)), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchMission);
