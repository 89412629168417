import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Drawer from '@material-ui/core/Drawer';

import css from './MiniDrawer.scss';

class MiniDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
    };
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
    this.handleDrawerClose = this.handleDrawerClose.bind(this);
  }

  handleDrawerOpen() {
    this.setState({ open: true });
  }

  handleDrawerClose() {
    this.setState({ open: false });
  }

  render() {
    const { children, toggleMenu } = this.props;

    const { open } = this.state;

    return (
      <div className={css.root}>
        <Drawer
          variant="permanent"
          elevation={32}
          classes={{
            paper: classNames(css.drawerPaper, !this.props.showMenu && css.drawerPaperClose),
            modal: css.shadow,
          }}
          open={open}
          onClick={() => toggleMenu()}
        >
          {children}
        </Drawer>
      </div>
    );
  }
}

MiniDrawer.propTypes = {
  children: PropTypes.any,
  toggleMenu: PropTypes.func,
  showMenu: PropTypes.bool,
};

export default MiniDrawer;
