import { escapeString } from 'utils/graphql';
import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';
import { gql } from '@apollo/client';
import { createClient } from '../utils';
import { contractType, itemType, packageType, tutorRecommendationsType } from './common/types';

const { api } = getConfig();

const client = createClient(api.graphQLEndpoint);

const createItem = gql`
  ${itemType}
  ${contractType}
  mutation createItem(
    $name: String!
    $description: String
    $start: String!
    $packageId: Int!
    $initialValue: Float!
    $remainingValue: Float!
    $isUnlimited: Boolean!
    $subjectId: String!
  ) {
    createItem(
      name: $name
      description: $description
      start: $start
      packageId: $packageId
      initialValue: $initialValue
      remainingValue: $remainingValue
      isUnlimited: $isUnlimited
      subjectId: $subjectId
    ) {
      ...itemType
      tutors: contract {
        ...contractType
      }
    }
  }
`;

const modifyItem = gql`
  ${itemType}
  ${contractType}
  mutation modifyItem(
    $id: Int!
    $userId: String
    $name: String
    $description: String
    $start: String
    $updatedInitialValue: Float
    $updatedRemainingValue: Float
    $isUnlimited: Boolean
    $subjectId: String
    $comments: String
    $tutoringFrequency: String
    $preferredTimes: String
    $notes: String
  ) {
    modifyItem(
      id: $id
      userId: $userId
      name: $name
      description: $description
      start: $start
      updatedInitialValue: $updatedInitialValue
      updatedRemainingValue: $updatedRemainingValue
      isUnlimited: $isUnlimited
      subjectId: $subjectId
      comments: $comments
      tutoringFrequency: $tutoringFrequency
      preferredTimes: $preferredTimes
      notes: $notes
    ) {
      ...itemType
      tutors: contract {
        ...contractType
      }
    }
  }
`;

const deleteItem = gql`
  mutation deleteItem($id: Int!) {
    deleteItem(id: $id)
  }
`;

const getItemById = gql`
  ${packageType}
  ${itemType}
  ${contractType}
  query getItemById($id: Int!) {
    item: getItemById(id: $id) {
      ...itemType
      package {
        ...packageType
      }
      contract {
        ...contractType
      }
    }
  }
`;

const fetchTutorRecommendations = gql`
  ${tutorRecommendationsType}
  query fetchTutorRecommendations($packageItemId: Int!) {
    recommendations: getTutorRecommendations(packageItemId: $packageItemId) {
      ...tutorRecommendationsType
    }
  }
`;

export default {
  createItem: (item) => client.query(createItem, item),
  modifyItem: (item) => client.query(modifyItem, { ...item, description: escapeString(item.description) }),
  deleteItem: (id) => client.query(deleteItem, { id }),
  fetchTutorRecommendations: (packageItemId) =>
    client.query(fetchTutorRecommendations, { packageItemId: parseInt(packageItemId, 10) }),
  getItemById: (id) => client.query(getItemById, { id: parseInt(id, 10) }),
};
