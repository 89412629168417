import React from 'react';
import FilterTag, { FILTER_ICONS } from 'components/molecules/FilterTag';
import MultiSelect from 'components/molecules/MultiSelect';
import Filter from './filter.js';

export default class MultiSelectFilter extends Filter {
  constructor(...args) {
    super(...args);
    this.state = {
      filtered: [],
      all: [],
    };
    // override title and name in child component
    this.title = 'Multipass';
    this.name = 'Multipass';
  }

  // override
  apply(item) {
    if (!this.active()) {
      return true;
    }
    return this.state.filtered.some((value) => item.city === value);
  }

  // override
  data({ tutors }) {
    this.setState({ all: tutors.map((t) => t.city) });
  }

  reset() {
    this.setState({
      filtered: [],
    });
  }

  handleSelect(value) {
    this.setState({
      filtered: value,
    });
  }

  active() {
    return this.state.filtered.length > 0;
  }

  id() {
    return this.name.toLowerCase().replace(' ', '-');
  }

  edit() {
    const options = this.state.all
      .filter((i) => !!i)
      .reduce((unique, value) => {
        if (!unique.includes(value)) {
          unique.push(value);
        }
        return unique;
      }, [])
      .map((value) => {
        return { value, label: value };
      });
    return (
      <MultiSelect
        id={`${this.id()}Selector`}
        dataTestId={`${this.id()}Selector`}
        name={this.title}
        value={this.state.filtered.map((value) => options.find((option) => option.value === value))}
        onChange={(values) => this.handleSelect(values.map(({ value }) => value))}
        options={options}
      />
    );
  }

  tag() {
    const display =
      this.state.filtered.length === 1 ? this.state.filtered[0] : `${this.name}: ${this.state.filtered.length}`;
    return (
      <FilterTag
        dataTestId={`${this.id()}FilterTag`}
        onClear={() => this.reset()}
        onClick={() => this.openModal()}
        value={this.state.filtered.join()}
        displayValue={display}
        label={this.name}
        icon={FILTER_ICONS.SEARCH}
      />
    );
  }
}
