/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'components/generic/Tooltip';
import TooltipItem from 'components/generic/Tooltip/TooltipItem';
import css from './styles.scss';
import { handleEnter } from '../../../utils/accessibility';

export default class NavIconLink extends Component {
  constructor() {
    super();

    this.state = {
      isToolTipOpen: false,
    };

    this.toggleToolTip = this.toggleToolTip.bind(this);
  }

  toggleToolTip(toState = null) {
    this.setState({ isToolTipOpen: toState === null ? !this.state.isOpen : toState });
  }

  render() {
    const { iconClass, text, onClickHandler, dataTestId, mobile } = this.props;
    const icon = iconClass ? <i className={`zmdi ${iconClass} ${css.navIconLinkIcon}`} /> : null;

    return (
      <Tooltip
        isOpen={this.state.isToolTipOpen}
        mobile={mobile}
        enterExitTransitionDurationMs={0}
        tipSize={0.01}
        target={
          <a
            role="button"
            tabIndex={0}
            className={css.navIconLinkWrapper}
            target="_blank"
            rel="noopener noreferrer"
            onClick={onClickHandler}
            onKeyDown={handleEnter(onClickHandler)}
            onMouseOver={() => this.toggleToolTip(true)}
            onMouseOut={() => this.toggleToolTip(false)}
            onFocus={() => this.toggleToolTip(true)}
            onBlur={() => this.toggleToolTip(false)}
            data-test-id={dataTestId}
          >
            {icon}
          </a>
        }
        body={
          <TooltipItem>
            <span className={css.navIconLinkText}>{text}</span>
          </TooltipItem>
        }
        preferPlace="below"
      />
    );
  }
}

NavIconLink.propTypes = {
  iconClass: PropTypes.string,
  text: PropTypes.string.isRequired,
  dataTestId: PropTypes.string,
  onClickHandler: PropTypes.func,
  mobile: PropTypes.bool,
};
