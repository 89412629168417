export default {
  //
  // Modal titles
  //

  // Make new booking
  BookingSession: 'New Booking Request',
  // Cancelled booking, from notification only (view)
  Cancelled: 'Cancelled Booking',

  // Pending, sender, >24 hours & <24 hours; Confirmed, sender, >24 hours (edit/delete)
  EditSession: 'Edit session',
  // Pending, receive, >24 hours & <24 hours (confirm/decline)
  ConfirmSession: 'Confirm session',
  // Confirmed, receiver, >24 hours (delete)
  DeleteSession: 'Delete session',
  // Confirmed, sender & receiver, <24 hours (view)
  // In the past
  ViewSession: 'View session',

  //
  // Modal warning messages
  //
  BookingInProgressWarning:
    'This session has already started. You are unable to reschedule or cancel this session. If you are unable to attend at this time please contact your Student Success Manager.',
  // Cancelled
  // Open from notification only (view)
  CancelledBooking: 'This booking has been cancelled.',

  // Pending
  // -- Sender
  // ---- (canBookFor or)>24 hours (edit/delete)
  // ---- <24 hours (edit/delete)
  PendingBookingWarning:
    'Awaiting confirmation from the other user. Any changes made will also appear on the other user’s session invite.',
  // -- Receiver
  // ---- (canBookFor or)>24 hours (confirm/decline)
  TutorToConfirm: 'Awaiting confirmation.',
  // ---- <24 hours (confirm/decline)
  ConfirmPendingBookingWithin24Hours:
    'The start time for this session request is within 24 hours. Once confirmed, you can no longer decline the session.',

  // Confirmed
  // -- Sender
  // ---- (canBookFor or)>24 hours (edit/delete)
  UpdateBookingWarning:
    'This booking is confirmed. If you change the date or time, the other user will have to re-confirm the booking again.',
  // ---- <24 hours (view)
  Within24HoursWarning:
    'Your session is due to start within 24 hours. You are now unable to reschedule or cancel this session. If you are unable to attend at this time please contact your Student Success Manager.',
  // -- Receiver
  // ---- (canBookFor or)>24 hours (delete)
  DeleteConfirmedSessionWarningTutor:
    'You are about to permanently delete this session from the calendar. The other user will be notified. Are you sure you want to do this?',
  // ---- <24 hours (view)
  Within24HoursWarningTutor:
    'Your session is due to start within 24 hours. You are now unable to reschedule or cancel this session. If you are unable to attend at this time please contact your Student Success Manager.',

  // Not being used
  DeleteSessionWarning:
    'You are about to permanently delete this session from the calendar. Are you sure you want to do this?',
};
