import React from 'react';
import PropTypes from 'prop-types';

export default function CurrentStudents(props) {
  return (
    <svg viewBox="0 0 163.83 98.58" {...props}>
      <defs>
        <mask id="a" x={111.41} y={0} width={22.38} height={22.21} maskUnits="userSpaceOnUse">
          <path fill="#fff" d="M111.41 0h22.38v22.21h-22.38V0z" />
        </mask>
      </defs>
      <g data-name="Layer 2">
        <g data-name="Layer 1">
          <g data-name="*August-18---Empty-State">
            <g data-name="No-current-students-(Tutor)---Generic">
              <path
                d="M95.45 93.31c0 2.91-14.36 5.27-32.09 5.27s-32.09-2.36-32.09-5.27S45.63 88 63.36 88s32.09 2.36 32.09 5.27M137.68 93.12c0 2.06-7.18 3.74-16.05 3.74s-16-1.68-16-3.74 7.18-3.75 16-3.75 16.05 1.68 16.05 3.75"
                fill="#f1f2f4"
              />
              <path d="M91.07 11.71a3.13 3.13 0 1 1-3.13-3.13 3.13 3.13 0 0 1 3.13 3.13" fill="#9d2e2b" />
              <path d="M97.12 11.71A3.13 3.13 0 1 1 94 8.58a3.12 3.12 0 0 1 3.12 3.13" fill="#9d2e2b" />
              <path fill="#9d2e2b" d="M90.87 18.99l-5.46-5.46 5.46-1.82 5.51 2.01-5.51 5.27z" />
              <path
                d="M76.32 90.08l-22.13 4.63A13.88 13.88 0 0 1 37.81 84l-4.53-21.68A13.88 13.88 0 0 1 44 45.93l22.13-4.62A13.88 13.88 0 0 1 82.51 52L87 73.7a13.88 13.88 0 0 1-10.68 16.38"
                fill="#e6e6e6"
              />
              <path
                d="M69.63 61.41L47.5 66a13.88 13.88 0 0 1-16.38-10.68l-4.54-21.68A13.89 13.89 0 0 1 37.3 17.26l22.13-4.63a13.88 13.88 0 0 1 16.38 10.72L80.34 45a13.89 13.89 0 0 1-10.71 16.41"
                fill="#e6e6e6"
              />
              <path
                d="M85.05 80.75L40.79 90a4.63 4.63 0 0 1-5.46-3.57l-10.1-48.32a4.63 4.63 0 0 1 3.57-5.46l44.26-9.25a4.63 4.63 0 0 1 5.46 3.6l10.1 48.32a4.62 4.62 0 0 1-3.57 5.46"
                fill="#60b9cd"
              />
              <path
                d="M52.16 52.12a1.56 1.56 0 1 1-1.56-1.56 1.56 1.56 0 0 1 1.56 1.56M74.65 46.44a1.56 1.56 0 1 1-3.11 0 1.56 1.56 0 0 1 3.11 0"
                fill="#212751"
              />
              <path
                d="M59.48 56s6.69 3.39 9.69-2.28"
                fill="none"
                stroke="#212751"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M36.22 60.14a2 2 0 0 1-2.38-1.53l-3.27-15.15a2 2 0 0 1 3.91-.85l3.27 15.15a2 2 0 0 1-1.53 2.38M38.83 64.26a1.68 1.68 0 1 1-2-1.29 1.69 1.69 0 0 1 2 1.29"
                fill="#71c9d7"
              />
              <g mask="url(#a)">
                <path
                  d="M126.18 22.17l-9.55-1.08a5.9 5.9 0 0 1-5.18-6.51l1.06-9.36A5.9 5.9 0 0 1 119 0l9.56 1.08a5.92 5.92 0 0 1 5.18 6.52l-1.05 9.4a5.89 5.89 0 0 1-6.51 5.18"
                  fill="#731343"
                />
              </g>
              <path
                d="M125 81.35l-11.18 13.9a1.25 1.25 0 0 1-1.76-.2L104.26 79a1.25 1.25 0 0 1 .2-1.76L115 68.83a1.24 1.24 0 0 1 1.75.2l8.4 10.56a1.25 1.25 0 0 1-.2 1.76"
                fill="#e6e6e6"
              />
              <path d="M116.53 91.84l-2.74 3.41a1.25 1.25 0 0 1-1.76-.2l-2-4" fill="#212751" />
              <path
                d="M123.73 81.52l-18-2.06a2.76 2.76 0 0 1-2.43-3.06L111 8.55a2.76 2.76 0 0 1 3-2.43l18 2.05a2.77 2.77 0 0 1 2.44 3.06l-7.72 67.85a2.78 2.78 0 0 1-3.06 2.44"
                fill="#ce4a50"
              />
              <path
                d="M115.28 35.45a1.56 1.56 0 1 1-1.55-1.55 1.55 1.55 0 0 1 1.55 1.55M125.59 36.6a1.56 1.56 0 1 1-1.59-1.55 1.56 1.56 0 0 1 1.56 1.55"
                fill="#212751"
              />
              <path
                d="M114.87 39.86s2.2 4.14 6.42 1.09"
                fill="none"
                stroke="#212751"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M114.19 25.24a1.68 1.68 0 0 1-1.49-1.83l1.3-12.85a1.67 1.67 0 0 1 3.32.34L116 23.75a1.67 1.67 0 0 1-1.82 1.49M115.2 29.18a1.4 1.4 0 0 1-1.54 1.25 1.42 1.42 0 0 1-1.26-1.54 1.41 1.41 0 0 1 2.8.29"
                fill="#e1626f"
              />
              <path
                d="M10.35 38.6v5.09M12.89 41.14H7.8M143.41 63.3v5.09M146 65.84h-5.09"
                fill="none"
                stroke="#60b9cd"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={0.679}
              />
              <path
                d="M152.4 35.84v5.09M155 38.39h-5.09M18.89 70.21v5.09M21.43 72.75h-5.09"
                fill="none"
                stroke="#b9bbc3"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={0.679}
              />
              <path
                d="M161 53.44v5.09M163.49 56h-5.09M2.88 57.31v5.09M5.43 59.85H.34"
                fill="none"
                stroke="#daa55d"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={0.679}
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

CurrentStudents.propTypes = {
  className: PropTypes.string,
};
