import React, { useState } from 'react';
import {
  Container,
  Text,
  FilesContainer,
  FileContainer,
  StyledCancelIcon,
  DialogueText,
  OtherFileContainer,
  OtherFileText,
} from './index.style';
import UploadFileIcon from 'components/atoms/graphics/ApplicationTracker/uploadFile';
import UploadFileModal from 'components/organisms/UploadFileModal';
import { FileUploadRes, OfferFile } from '../../type';
import Dialogue from '../../Dialogue';
import useToggle from 'hooks/useToggle';
import FileTypeIcon from './FileTypeIcon';
import { sleep } from 'components/organisms/ProfilePage/OnboardingQuizCard/Quiz/util';
import { downloadMessageFile } from 'ducks/file';
import { updateMeta } from 'ducks/meta';
import { useDispatch } from 'react-redux';
import componentKeys from 'constants/componentKeys';
import Immutable from 'immutable';
type FileUploaderProps = {
  onFileUploaded: (file: FileUploadRes) => Promise<boolean>;
  offerFiles?: OfferFile[];
  deleteOfferFile: (fileId: string) => Promise<void>;
};
const FileUploader = ({ onFileUploaded, offerFiles, deleteOfferFile }: FileUploaderProps) => {
  const [isUploadFileModalOpen, setModalOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isDialogueOpen, toggleDialogue] = useToggle(false);
  const [selectedFile, setSelectedFile] = useState<string | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const toggleModal = () => setModalOpen((v) => !v);
  const setUploadingFalse = () => setIsUploading(false);
  const setUploadingTrue = () => setIsUploading(true);
  const dispatch = useDispatch();
  const _onFileUploaded = async (file: FileUploadRes) => {
    const success = await onFileUploaded(file);
    if (success) setShowSuccess(true);
    dispatch(updateMeta(componentKeys.FILE_UPLOADED, Immutable.Map()));
    await sleep(1000);
    setUploadingFalse();
    toggleModal();
  };
  const onClickDeleteFile = (fileId: string) => {
    setSelectedFile(fileId);
    toggleDialogue();
  };
  const closeDialogueModal = () => {
    setSelectedFile(null);
    toggleDialogue();
  };
  const onConfirmDeleteOfferFile = async () => {
    if (!selectedFile) return;
    setIsDeleting(true);
    await deleteOfferFile(selectedFile);
    setIsDeleting(false);
    closeDialogueModal();
  };
  const openUploadFileModal = () => {
    setShowSuccess(false);
    toggleModal();
  };

  const onClickFile = async (file: OfferFile) => {
    try {
      await downloadMessageFile(file.id, 'OSS')();
    } catch (err) {
      //
    }
  };
  return (
    <>
      <FilesContainer>
        {offerFiles?.map((offerFile) => {
          const isImage = offerFile.type.includes('image');
          return (
            <FileContainer key={offerFile.id} onClick={() => onClickFile(offerFile)}>
              {isImage && <img src={offerFile.url} alt={offerFile.name} />}
              {!isImage && (
                <OtherFileContainer columncenter>
                  <FileTypeIcon fileType={offerFile.type} />
                  <OtherFileText>{offerFile.name}</OtherFileText>
                </OtherFileContainer>
              )}
              <StyledCancelIcon
                onClick={(e) => {
                  e.stopPropagation();
                  onClickDeleteFile(offerFile.id);
                }}
              />
            </FileContainer>
          );
        })}
        {(!offerFiles || offerFiles?.length <= 100) && (
          <Container onClick={openUploadFileModal}>
            <UploadFileIcon />
            <Text>{'Upload\nyour offer\nhere'}</Text>
          </Container>
        )}
      </FilesContainer>
      <UploadFileModal
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        /* @ts-ignore */
        isUploadFileModalOpen={isUploadFileModalOpen}
        handleCloseModal={toggleModal}
        onUploadingDone={_onFileUploaded}
        threadId={'test-application-id-skqwenf'}
        isError={false}
        showUploadFile={!isUploading}
        handleStatus={setUploadingTrue}
        handleUploadCancel={setUploadingFalse}
        showSuccess={showSuccess}
      />
      <Dialogue
        title={'Are you sure'}
        isLoading={isDeleting}
        isOpen={isDialogueOpen}
        closeModal={closeDialogueModal}
        onClickConfirm={onConfirmDeleteOfferFile}
      >
        <DialogueText>{'Are you sure you want to delete this file?'}</DialogueText>
      </Dialogue>
    </>
  );
};

export default FileUploader;
