import { Column } from '../../index.style';
import styled from 'styled-components';
import Flatpickr from 'react-flatpickr';

export const ToolContainer = styled(Column)`
  position: fixed;
  right: 15vw;
  top: 30px;
  padding: 25px;
  border-radius: 50px;
  width: 350px;
  background: linear-gradient(145deg, #838383, #9b9b9b);
  box-shadow: 20px 20px 60px #7b7b7b, -20px -20px 60px #a7a7a7;
  transition: width, height 0.4s;
  cursor: move;
  * {
    color: white;
  }
  z-index: 999;
`;

export const StyledDatePicker = styled(Flatpickr)`
  background: transparent;
  border: none;
`;
