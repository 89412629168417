import { useState } from 'react';
import { useSelector } from 'react-redux';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { selectUserId, selectUserPermissionInfo } from 'selectors/meta';

import { validateUserPermissions } from '@crimson-education/common-config/lib/authorization/resolve';
import authorizationAPI from 'graphql/api/authorization';

/**
 * Validates the logged in user has the specified permissions to access a resource, with target users if requesting other user's resources.
 * @param {import('@crimson-education/common-config/lib/authorization/types').PermissionValidation[] | import('@crimson-education/common-config/lib/authorization/types').PermissionValidation} permissions The Permissions to validate the user has, is a list, optionally with conditions.
 * @param {string[]?} targetUserIds Target users to test permissions against, if applicable, otherwise pass an empty array.
 * @returns {{ loading: boolean, isPermitted: boolean, failedPermissions: import('@crimson-education/common-config/lib/authorization/types').PermissionRequest[] }} The status of the permission check.
 * @example
 * // Checking if you have permission to access a user.
 * function myComponent(props) {
 *   const { loading, isPermitted } = usePermissionCheck([{
 *     action: PermissionAction.View,
 *     resourceType: PermissionResourceType.User,
 *     resource: {
 *       qualifier: PermissionResourceQualifier.User,
 *       identifier: otherUserId,
 *     }
 *   }], [otherUserId]);
 *   if(!loading) {
 *     if(isPermitted) {
 *       return <div>Authorized!</div>;
 *     }
 *     return <div>Unauthorized!</div>;
 *   }
 *   return null;
 * }
 */
export default function usePermissionCheck(permissions, targetUserIds = []) {
  const userId = useSelector(selectUserId);
  const userPermissionInfoState = useSelector(selectUserPermissionInfo);
  const [permissionInfo, setPermissionInfo] = useState({
    loading: true,
    isPermitted: false,
    failedPermissions: [],
  });

  useDeepCompareEffect(() => {
    const userPermissionInfo =
      permissions instanceof Array
        ? userPermissionInfoState
            .map((perm) => perm.toJS())
            .filter((pe) => permissions.find((v) => v.action === pe.action && v.resourceType === pe.resourceType))
        : userPermissionInfoState
            .map((perm) => perm.toJS())
            .filter((pe) => permissions.action === pe.action && permissions.resourceType === pe.resourceType);

    if (userPermissionInfo.length !== 0) {
      validateUserPermissions(
        {
          getPermissions: () => Promise.resolve(userPermissionInfo),
          isAccessibleByRole: () => authorizationAPI.isAccessibleByRole,
        },
        userId,
        permissions,
        targetUserIds || [],
      ).then((failedPermissions) => {
        setPermissionInfo({
          loading: false,
          isPermitted: failedPermissions.length === 0,
          failedPermissions,
        });
      });
    }
  }, [userId, userPermissionInfoState, permissions, targetUserIds]);

  return permissionInfo;
}
