import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import filesize from 'filesize';
import css from './styles.scss';
import { handleEnter } from '../../../../utils/accessibility';

export default function CommentFile(props) {
  const { className, fileName, fileSize, onDownloadClick, onDeleteClick } = props;
  return (
    <div className={classnames(css.commentFile, className)}>
      <div>
        <i className={classnames('zmdi zmdi-file-text', css.fileIcon)} />
        <span className={css.fileName}>{fileName}</span>
        <span className={css.fileSize}>File size: {filesize(fileSize, { base: 10 })}</span>
      </div>
      <div>
        {onDownloadClick && (
          <div
            role="button"
            tabIndex={0}
            className={css.downloadButton}
            onClick={onDownloadClick}
            onKeyDown={handleEnter(onDownloadClick)}
          >
            <i className="zmdi zmdi-download" />
          </div>
        )}
        {onDeleteClick && (
          <div
            role="button"
            tabIndex={0}
            className={css.deleteButton}
            onClick={onDeleteClick}
            onKeyDown={handleEnter(onDeleteClick)}
          >
            <i className="zmdi zmdi-delete" />
          </div>
        )}
      </div>
    </div>
  );
}

CommentFile.displayName = 'CommentFile';

CommentFile.propTypes = {
  className: PropTypes.string,
  fileName: PropTypes.string,
  fileSize: PropTypes.number,
  onDeleteClick: PropTypes.func,
  onDownloadClick: PropTypes.func,
};
