/**
 * Types of modal states.
 * @readOnly
 * @enum {string}
 */
const modalStatus = {
  Success: 'successful',
  Fail: 'failed',
  Default: 'default',
  Loading: 'loading',
};

export default modalStatus;
