import React from 'react';
import PropTypes from 'prop-types';

export default function VeryHappy({ className }) {
  return (
    <svg viewBox="0 0 64 64" enableBackground="new 0 0 64 64" className={className}>
      <circle fill="#ffdd67" cx="32" cy="32" r="30" />
      <path
        fill="#664e27"
        d="m48 37.5c0 6.461-5.314 14-16 14-10.689 0-16-7.539-16-14 0 0 5.311 0 16 0 10.686 0 16 0 16 0"
      />
      <path fill="#fff" d="m16.918 37.5h30.17v5.6h-30.17z" />
      <g fill="#664e27">
        <path d="m32 52.5c-11.04 0-17-7.729-17-15v-1h34v1c0 7.271-5.957 15-17 15m-14.955-14c.523 5.99 5.727 12 14.955 12s14.434-6.01 14.957-12h-29.912" />
        <path d="m27.59 25.647c-3.264-6.197-12.653-6.197-15.917 0-.195.378.32.921.95 1.353 4.135-3.271 9.88-3.271 14.01-.001.632-.431 1.147-.974.952-1.352" />
        <path d="m52.33 25.647c-3.264-6.197-12.652-6.197-15.916-.001-.195.379.319.922.949 1.353 4.136-3.271 9.881-3.271 14.02-.001.631-.431 1.145-.973.951-1.351" />
      </g>
    </svg>
  );
}

VeryHappy.propTypes = {
  className: PropTypes.string,
};
