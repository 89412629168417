import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Avatar from 'components/generic/Avatar';
import Dot from '@material-ui/icons/FiberManualRecord';

import css from './styles.scss';
import { handleEnter } from '../../../../utils/accessibility';

export default function Contact(props) {
  const { contact, onContactSelect, onlineUsers, activeThread } = props;

  let online = false;
  onlineUsers.forEach((x) => {
    if (x === contact.userId) {
      online = true;
    }
  });
  let isSelected = false;
  activeThread &&
    activeThread.participants.forEach((p) => {
      if (p.userId === contact.userId) {
        isSelected = true;
      }
    });
  const contactPrimaryRole = contact.roles.find((r) => r.isPrimary) || contact.roles[0];

  return (
    <div
      role="button"
      tabIndex={0}
      onClick={() => onContactSelect(contact.userId)}
      onKeyDown={handleEnter(() => onContactSelect(contact.userId))}
      className={classNames(isSelected && css.selectedItem, css.peopleItemContainer)}
    >
      <div className={css.avatarContainer}>
        <Avatar
          userId={contact.userId}
          firstName={contact.firstName}
          lastName={contact.lastName}
          dataTestId={`contact_${contact.role}_${contact.firstName}`}
          avatar={contact.profileImageUrl}
          large
          showStudentType
        />
      </div>
      <div className={css.peopleItem}>
        <div className={css.line1}>
          {contact.firstName} {contact.lastName}
          {online === true ? (
            <i className={css.dot}>
              <Dot />
            </i>
          ) : null}
        </div>
        <div className={css.line2}>{contactPrimaryRole ? contactPrimaryRole.role.name : ''}</div>
      </div>
    </div>
  );
}

Contact.propTypes = {
  onContactSelect: PropTypes.func.isRequired,
  contact: PropTypes.object,
  onlineUsers: PropTypes.array,
  activeThread: PropTypes.object,
};
