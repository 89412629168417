import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'auto-bind';
import { AddBox } from 'components/atoms/icons';
import IconButton from 'components/molecules/IconButton';
import Avatar from 'components/generic/Avatar';
import InfiniteScroll from 'react-infinite-scroller';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import { Acl } from '@crimson-education/common-config';
import MobileModal from 'components/molecules/MobileModal';
import AddMorePeopleModal from '../AddMorePeopleModal/main';
import RemovePeopleConfirmModal from '../RemovePeopleConfirmModal';

import css from './styles';
import { handleEnter } from '../../../../utils/accessibility';

export default class GroupPeople extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addMorePeopleModalIsOpen: false,
      removePeopleModalIsOpen: false,
      removePeopleMobileModalIsOpen: false,
      removePeopleUserId: '',
    };
    autoBind(this);
  }

  toggleAddEditModal() {
    this.setState(({ addMorePeopleModalIsOpen }) => ({ addMorePeopleModalIsOpen: !addMorePeopleModalIsOpen }));
  }
  toggleRemoveEditModal() {
    this.setState({ removePeopleUserId: '', removePeopleModalIsOpen: false, removePeopleMobileModalIsOpen: false });
  }

  addPeople(userIds) {
    const { thread, addMorePeople } = this.props;
    addMorePeople(userIds, thread.id);
    this.setState({ addMorePeopleModalIsOpen: false });
  }

  removePeople() {
    const { thread, currentUser, removePeopleFromGroup } = this.props;
    this.setState({ removePeopleModalIsOpen: false, removePeopleMobileModalIsOpen: false });
    removePeopleFromGroup(this.state.removePeopleUserId, thread.id, currentUser.userId);
  }

  loadMore() {
    // do nothing
  }

  renderPeople(user) {
    const { currentUser, isMobile, thread, featureFlags } = this.props;
    const { FLAG_STUDENT_CENTER_TAB } = featureFlags;
    const profileLink =
      FLAG_STUDENT_CENTER_TAB && user.isBetaUser && Acl.isStudent(user.role)
        ? `/users/${user.userId}/student-center/personal-info`
        : `/users/${user.userId}/profile`;
    return (
      <div className={css.people} key={user.userId}>
        <Link className={css.profilePic} to={profileLink} key={`link-avatar-${user.userId}`}>
          <Avatar
            className={css.avatarUnderlay}
            firstName={user.firstName}
            lastName={user.lastName}
            userId={user.userId}
            avatar={user.profileImageUrl}
          />
        </Link>
        <span>
          {user.firstName} {user.lastName}
        </span>
        {(Acl.isStudent(currentUser.role) && thread.createdBy !== currentUser.userId) ||
        (thread.source && thread.source === 'COMMUNITY') ? null : (
          <div
            className={css.moreHorizIcon}
            data-place="bottom"
            data-tip="Remove this person"
            data-for="removePeopleTooltip"
          >
            <IconButton
              icon="MoreHoriz"
              dataTestId="moreHorizIcon"
              onClick={() => {
                isMobile
                  ? this.setState({ removePeopleMobileModalIsOpen: true, removePeopleUserId: user.userId })
                  : this.setState({ removePeopleModalIsOpen: true, removePeopleUserId: user.userId });
              }}
            />
          </div>
        )}
      </div>
    );
  }

  render() {
    const { currentUser, allActiveUsers, closeGroup, thread } = this.props;
    return (
      <div className={css.group}>
        <AddMorePeopleModal
          isOpen={this.state.addMorePeopleModalIsOpen}
          onClose={this.toggleAddEditModal}
          existedPeople={allActiveUsers}
          onAddMorePeople={this.addPeople}
        />
        <RemovePeopleConfirmModal
          isOpen={this.state.removePeopleModalIsOpen}
          onClose={this.toggleRemoveEditModal}
          onRemovePeople={this.removePeople}
        />
        <MobileModal
          className={css.mobileRemovePeople}
          isOpen={this.state.removePeopleMobileModalIsOpen}
          dataTestId="mobileRemovePeopleModal"
          title="Remove from Group"
          onClose={this.toggleRemoveEditModal}
        >
          <div>
            <p>
              Are you sure you want to remove this person from the conversation? They will no longer be able to send or
              receive new messages.
            </p>
          </div>
          <div className={css.buttonGroup}>
            <button className={css.cancelButton} onClick={this.toggleRemoveEditModal}>
              CANCEL
            </button>
            <button className={css.removeButton} onClick={this.removePeople}>
              REMOVE
            </button>
          </div>
        </MobileModal>
        <div className={css.groupHeader}>
          <h3>People {allActiveUsers.length}</h3>
          <IconButton icon="Close" dataTestId="closeAddGroupIcon" onClick={closeGroup} />
        </div>
        {(Acl.isStudent(currentUser.userRoles) && thread.createdBy !== currentUser.userId) ||
        (thread.source && thread.source === 'COMMUNITY') ? null : (
          <div
            role="button"
            tabIndex={0}
            className={css.addPeople}
            onClick={() => {
              this.setState({ addMorePeopleModalIsOpen: true });
            }}
            onKeyDown={handleEnter(() => {
              this.setState({ addMorePeopleModalIsOpen: true });
            })}
          >
            <AddBox className={css.addBox} />
            <span>Add People</span>
          </div>
        )}
        {!(Acl.isStudent(currentUser.userRoles) && thread.createdBy !== currentUser.userId) &&
        thread.source &&
        thread.source === 'COMMUNITY' ? (
          <Link className={css.manageLink} to="/community/admin">
            {' '}
            <div className={css.manageMember}>
              <IconButton icon="Settings" className={css.manageGroupButton} />
              <span>Manage group members</span>
            </div>
          </Link>
        ) : null}
        <div className={css.peopleList}>
          <InfiniteScroll ref={this.ref} isReverse loadMore={this.loadMore} useWindow={false}>
            <ReactTooltip effect="solid" id="removePeopleTooltip" />
            {/* {this.renderPeople(currentUser)} */}
            {allActiveUsers.map((u) => this.renderPeople(u))}
          </InfiniteScroll>
        </div>
      </div>
    );
  }
}

GroupPeople.propTypes = {
  thread: PropTypes.object,
  currentUser: PropTypes.object,
  allActiveUsers: PropTypes.array,
  addMorePeople: PropTypes.func,
  removePeopleFromGroup: PropTypes.func,
  closeGroup: PropTypes.func,
  isMobile: PropTypes.bool,
  featureFlags: PropTypes.object,
};
