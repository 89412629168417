import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classname from 'classnames';
// import TextareaAutosize from 'react-autosize-textarea';
import { escapeDoubleQuotes } from 'utils/utils';
import css from './styles.scss';

export default class CommentField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.defaultValue || '',
    };

    this.handleOnChange = this.handleOnChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.setState({
      value: this.props.defaultValue || '',
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.value !== prevState.value) {
      return { value: nextProps.value };
    }
    return null;
  }

  handleOnChange(event) {
    this.setState({
      value: event.target.value,
    });
    this.props.onValueChange(escapeDoubleQuotes(event.target.value));
  }

  render() {
    const {
      id,
      className,
      label,
      placeholder,
      disabled,
      formInputClassName,
      maxLength,
      required,
      readOnly,
      autoFocus,
      autoSize,
      onKeyPress,
      onKeyDown,
      onKeyUp,
      siblingComponent,
      error,
      validationMessage,
      dataTestId,
    } = this.props;

    return (
      <div className={classname(css.formItemContainer, className)}>
        {label && (
          <label className={css.formLabel} htmlFor={id}>
            {label}
          </label>
        )}
        <textarea
          className={classname(css.commentField, formInputClassName, {
            [css.error]: error,
            [css.disabled]: disabled,
          })}
          id={id}
          data-test-id={dataTestId}
          value={this.state.value}
          placeholder={placeholder}
          maxLength={maxLength}
          disabled={disabled}
          required={required}
          readOnly={readOnly}
          onChange={this.handleOnChange}
          onKeyPress={onKeyPress}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
        />
        {siblingComponent}
        {error && validationMessage && <div className={css.validationMessage}>{validationMessage}</div>}
      </div>
    );
  }
}

CommentField.displayName = 'CommentField';

CommentField.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  formInputClassName: PropTypes.string,
  label: PropTypes.string,
  defaultValue: PropTypes.string,

  placeholder: PropTypes.string,
  maxLength: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  autoFocus: PropTypes.bool,

  autoSize: PropTypes.bool,

  onValueChange: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func,
  onKeyDown: PropTypes.func,
  onKeyUp: PropTypes.func,
  error: PropTypes.bool,
  validationMessage: PropTypes.string,
  siblingComponent: PropTypes.object,

  dataTestId: PropTypes.string,
  value: PropTypes.string,
};
