import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { AttendanceStatusTypes, BookingStatusTypes } from '@crimson-education/common-config';
import classnames from 'classnames';

import { incrementTimeObject, createTimeObject, timeString } from 'utils/calendarUtils';
import Modal from 'components/molecules/Modal';
import { Body } from 'components/atoms/typography';
import AdjustTime from 'components/generic/FeedbackModals/AdjustTime';
import ErrorIcon from 'components/generic/Icons/Error';
import EventSummary from 'components/molecules/EventSummary';
import { getMode, getBookingModalSettings } from './utils';
import getFooterProperties from './footer';
import css from './styles.scss';

const MINUTE_INCREMENT = 5;

function getDefaultState(props) {
  const booking = props.booking;
  const loginUser = props.loginUser.toJS();
  const isCreator = loginUser.userId === booking.creatorUserId;
  const bookingWithUserFullName = booking.otherUser && `${booking.otherUser.firstName} ${booking.otherUser.lastName}`;
  const userRoles = props.loginUser.get('userRoles');
  const bookingAs = props.bookAs ? props.bookAs.toJS() : undefined;
  const mode = getMode(props.mode, booking, userRoles, loginUser, bookingAs);
  const settings = getBookingModalSettings(props, mode);

  let disputedStart;
  let disputedEnd;

  if (booking.attendance && booking.attendance.status === AttendanceStatusTypes.DISAGREE) {
    disputedStart = booking.attendance.start;
    disputedEnd = booking.attendance.end;
  }

  const newDefaultState = {
    settings,
    adjustedTime: { start: undefined, end: undefined, valid: true },
    adjustedReason: { text: undefined, valid: true },
    disputedTime: { start: disputedStart, end: disputedEnd },
    isCreator,
    bookingWithUserFullName,
    timesUnchanged: false,
  };

  return Object.assign({}, newDefaultState);
}

export default class BookingSummaryModal extends Component {
  constructor(props) {
    super(props);

    this.state = getDefaultState(props);

    this.onAdjustedTimeChange = this.onAdjustedTimeChange.bind(this);
    this.onAdjustedReasonChange = this.onAdjustedReasonChange.bind(this);
    this.redirectToMessagesFromBooking = this.redirectToMessagesFromBooking.bind(this);
    this.openEditBookingModal = this.openEditBookingModal.bind(this);
    this.openCancelBookingModal = this.openCancelBookingModal.bind(this);
    this.openDeclineBookingModal = this.openDeclineBookingModal.bind(this);
    this.confirmDeclineBooking = this.confirmDeclineBooking.bind(this);
    this.confirmLesson = this.confirmLesson.bind(this);
    this.confirmLessonInThePast = this.confirmLessonInThePast.bind(this);
    this.cancelBooking = this.cancelBooking.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState(getDefaultState(nextProps));
  }

  onAdjustedTimeChange(givenStart, givenEnd) {
    this.setState({ timesUnchanged: false });
    const { adjustedTime } = this.state;
    const isStartTimeChanged =
      givenStart &&
      givenEnd &&
      (!adjustedTime.start ||
        adjustedTime.start.hour !== givenStart.hour ||
        adjustedTime.start.minute !== givenStart.minute);
    this.setState({
      adjustedTime: {
        start: givenStart,
        end: isStartTimeChanged ? incrementTimeObject(givenStart) : givenEnd,
        valid: true,
      },
    });
  }

  onAdjustedReasonChange(changeEvent) {
    let newText = changeEvent.target.value;
    if (newText === '') newText = undefined;
    this.setState({ adjustedReason: { text: newText, valid: true } });
  }

  redirectToMessagesFromBooking() {
    this.props.redirectToMessagesFromBooking(this.props.booking);
  }

  openEditBookingModal() {
    this.props.openModalViaSummary();
  }

  openCancelBookingModal() {
    this.props.openCancelBookingModal();
  }

  openDeclineBookingModal() {
    this.props.openDeclineBookingModal();
  }

  confirmDeclineBooking() {
    const { booking, declineBooking, bookAs } = this.props;
    const reason = '';
    declineBooking(booking.id, bookAs.get('userId'), reason);
    this.props.closeSummaryModal();
  }

  confirmLesson() {
    const { booking, confirmBooking, bookAs } = this.props;
    confirmBooking(booking.id, bookAs.get('userId'));
    this.props.closeSummaryModal();
  }

  confirmLessonInThePast() {
    const { booking, confirmBooking, bookAs } = this.props;
    const isInThePast = true;
    confirmBooking(booking.id, bookAs.get('userId'), isInThePast);
    this.props.closeSummaryModal();
  }

  cancelBooking() {
    const { booking, cancelBooking, bookAs } = this.props;
    cancelBooking(booking.id, bookAs.get('userId'));
    this.props.closeSummaryModal();
  }

  render() {
    const { booking } = this.props;
    const settings = this.state.settings;

    const actions = {
      onConfirmBooking: this.confirmLesson,
      onConfirmBookingInThePast: this.confirmLessonInThePast,
      onConfirmDeclineBooking: this.confirmDeclineBooking,
      onCancelBooking: this.cancelBooking,
      openCancelBookingModal: this.openCancelBookingModal,
      redirectToMessagesFromBooking: this.redirectToMessagesFromBooking,
      closeSummaryModal: this.props.closeSummaryModal,
      goBack: this.props.openDefaultBookingModal,
    };

    const bookingStatus = booking.status;
    const bookingStart = booking.start;
    const bookingEnd = booking.end;

    const bookingStatusReadable = bookingStatus === BookingStatusTypes.TENTATIVE ? 'request' : 'booking';

    const Option = {
      EDIT: { text: 'Edit', action: this.openEditBookingModal },
      CANCEL: { text: `Cancel ${bookingStatusReadable}`, action: this.openCancelBookingModal },
    };

    const eventSummaryOptions = settings.options.map((option) => Option[option]);

    const originalTime = timeString(booking.start, booking.end);

    const EventSummaryText = {
      VALIDATION_MESSAGE: 'Please tell us what happened',
      ORIGINALLY_SCHEDULED: `This lesson was originally scheduled for ${originalTime}`,
      RUN_TO_SCHEDULE: `Why didn't the session run to schedule? (${originalTime})`,
      TOGGLE_OPEN: 'This time is not correct',
      TOGGLE_CLOSED: 'Cancel edit mode',
      COMMENTS: '',
      ADD_A_COMMENT: 'Add a comment',
    };

    const footerProperties = getFooterProperties(settings.footerType, bookingStatus, actions);

    return (
      <Modal
        className={css.bookingModal}
        isOpen={this.props.isOpen}
        title={settings.modalHeader}
        onClose={actions.closeSummaryModal}
        onSubmit={footerProperties.primary && footerProperties.primary.action}
        submitText={footerProperties.primary && footerProperties.primary.text}
        onBack={footerProperties.back}
        onSecondarySubmit={footerProperties.secondary && footerProperties.secondary.action}
        secondarySubmitText={footerProperties.secondary && footerProperties.secondary.text}
        alert={settings.alertMessage ? { message: settings.alertMessage } : null}
        onSubmitDataTestId={footerProperties.dataTestId}
      >
        <div>
          {settings.eventSummary && (
            <EventSummary
              booking={booking}
              personName={this.state.bookingWithUserFullName}
              adjustedTime={this.state.adjustedTime}
              options={eventSummaryOptions}
            />
          )}

          {settings.extraFields.adjustTime && (
            <div className={css.adjustTime}>
              <AdjustTime
                start={
                  createTimeObject(this.state.adjustedTime.start) ||
                  createTimeObject(this.state.disputedTime.start) ||
                  createTimeObject(bookingStart)
                }
                end={
                  createTimeObject(this.state.adjustedTime.end) ||
                  createTimeObject(this.state.disputedTime.end) ||
                  createTimeObject(bookingEnd)
                }
                onAdjustedTimeChange={this.onAdjustedTimeChange}
                minuteIncrement={MINUTE_INCREMENT}
                timesInvalid={!this.state.adjustedTime.valid}
                timesUnchanged={this.state.timesUnchanged}
              />
            </div>
          )}

          {settings.extraFields.reasonBox && (
            <div
              className={classnames({
                [css.reasonBox]: true,
                [css.invalidReasonBox]: !this.state.adjustedReason.valid,
              })}
            >
              {EventSummaryText.ADD_A_COMMENT}
              <textarea
                onChange={this.onAdjustedReasonChange}
                data-test-id="adjustedReasonChangeComment"
                className={css.reasonBoxText}
                value={this.state.adjustedReason.text || ''}
              />
              {!this.state.adjustedReason.valid && (
                <div className={css.errorMessage}>
                  <ErrorIcon className={css.alertIcon} />
                  {EventSummaryText.VALIDATION_MESSAGE}
                </div>
              )}
            </div>
          )}

          {settings.bodyText && (
            <div>
              <Body>{settings.bodyText}</Body>
            </div>
          )}
        </div>
      </Modal>
    );
  }
}

BookingSummaryModal.propTypes = {
  isOpen: PropTypes.bool,
  booking: PropTypes.object,
  bookAs: ImmutablePropTypes.map,
  closeSummaryModal: PropTypes.func.isRequired,
  confirmBooking: PropTypes.func,
  cancelBooking: PropTypes.func,
  declineBooking: PropTypes.func,
  redirectToMessagesFromBooking: PropTypes.func,
  openModalViaSummary: PropTypes.func,
  openCancelBookingModal: PropTypes.func,
  openDeclineBookingModal: PropTypes.func,
  openDefaultBookingModal: PropTypes.func,
};
