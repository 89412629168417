import React from 'react';
import Flag from '@material-ui/icons/Flag';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import AccessTime from '@material-ui/icons/AccessTime';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import Check from '@material-ui/icons/Check';
import NotInterested from '@material-ui/icons/NotInterested';
import TelegramIcon from '@material-ui/icons/Telegram';
import MailIcon from '@material-ui/icons/Mail';
import css from './styles.scss';
import * as Logger from '@crimson-education/browser-logger';

export const getStatusIcon = (status) => {
  switch (status) {
    case 'action_required':
      return <Flag fontSize="large" className={css.flag} />;
    case 'missing_data':
      return <ErrorOutline fontSize="large" className={css.error} />;
    case 'for_review':
      return <FiberManualRecordIcon fontSize="large" className={css.dot} />;
    case 'generating':
      return <AccessTime fontSize="large" className={css.clock} />;
    case 'ready':
      return <Check fontSize="large" className={css.check} />;
    case 'do_not_send':
      return <NotInterested fontSize="large" className={css.notInterested} />;
    case 'sending':
      return <MailIcon fontSize="large" className={css.check} />;
    case 'sent':
      return <TelegramIcon fontSize="large" className={css.check} />;
    default:
      Logger.reportError(`Unsupported status: ${status}`);
      return '';
  }
};

export const reportStatus = {
  action_required: 'Action required',
  do_not_send: 'Do not send',
  for_review: 'For review',
  missing_data: 'Missing data',
  ready: 'Ready',
  generating: 'Generating',
  sending: 'Sending',
  sent: 'Sent',
};

export const statuses = [
  { value: 'action_required', label: 'Action required' },
  { value: 'do_not_send', label: 'Do not send' },
  { value: 'for_review', label: 'For review' },
  { value: 'ready', label: 'Ready' },
  { value: 'missing_data', label: 'Missing data' },
];

export const reportTypes = [
  { value: 'term1_signpost', label: 'Term 1 Signpost' },
  { value: 'term1_progress', label: 'Term 1 Progress' },
  { value: 'term1_full', label: 'Term 1 Full' },
  { value: 'term1_full_2', label: 'Term 1 Second Report' },
  { value: 'term2_signpost', label: 'Term 2 Signpost' },
  { value: 'term2_progress', label: 'Term 2 Progress' },
  { value: 'term2_full', label: 'Term 2  Full' },
  { value: 'term3_signpost', label: 'Term 3 Signpost' },
  { value: 'term3_progress', label: 'Term 3 Progress' },
  { value: 'term3_full', label: 'Term 3 Full' },
  { value: 'term4_signpost', label: 'Term 4 Signpost' },
  { value: 'term4_progress', label: 'Term 4 Progress' },
  { value: 'term4_full', label: 'Term 4 Full' },
  { value: 'term1_full_us', label: 'Term 1 Full US' },
  { value: 'final_us', label: 'Final US' },
  { value: 'progress', label: 'Progress' },
  { value: 'mid_year', label: 'Mid Year' },
  { value: 'final', label: 'Final' },
  { value: 'third', label: 'Third' },
  { value: 'end_of_year', label: 'End Of Year' },
];
