import React from 'react';
import PropTypes from 'prop-types';

export default function CrimsonConnectLogo({ className }) {
  return (
    <svg className={className} height="24" width="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 71.26 71.98">
      <g id="f586de7e-3e67-4a0f-956f-c2491bafe614" data-name="Layer 2">
        <g id="bbcf99f1-4c54-475d-8105-189e9b3a944b" data-name="Artwork">
          <path
            d="M54.2,41.78h-.33c-.21.78-.46,1.56-.74,2.34a20.8,20.8,0,0,1-4.75,7.68,19,19,0,0,1-9,5.11,19.48,19.48,0,0,1-10.22-.2,20.73,20.73,0,0,1-9.08-5.44,20.55,20.55,0,0,1-5.41-9,19.28,19.28,0,0,1,5-19.07,19.74,19.74,0,0,1,7.94-4.76c.51-.16,1-.31,1.56-.44q0-.59,0-1.17A24.73,24.73,0,0,1,32.7,4.08,35.8,35.8,0,0,0,22.44,6.59,35.19,35.19,0,0,0,10.62,14.3,35.67,35.67,0,0,0,1.35,29.7,32.15,32.15,0,0,0,9.71,62a33.38,33.38,0,0,0,15.17,8.92A32,32,0,0,0,42,70.77a34.34,34.34,0,0,0,15.26-9.08,33.64,33.64,0,0,0,7.65-12.08A38.94,38.94,0,0,0,67.38,38,24.88,24.88,0,0,1,54.2,41.78Z"
            fill="#00a896"
          />
          <path d="M44.61,3l.45-.26Z" fill="#232950" />
          <path d="M47.91,1.35l.16-.07Z" fill="#232950" />
          <path d="M46.12,2.18l.48-.25Z" fill="#232950" />
          <path
            d="M47.43,31.83a17,17,0,0,0,13.9.18h0a16.86,16.86,0,0,0,5-3.5,14.86,14.86,0,0,0,3-4C76.52,9.88,62.71-4.42,48.07,1.28a16.74,16.74,0,0,0-5.37,3.6,16.3,16.3,0,0,0-3.34,4.7s0,0,0,0a16.65,16.65,0,0,0,0,14.14,4.86,4.86,0,0,1-.92,5.53l-.05.05a5,5,0,0,1-4.21,1.34,8.46,8.46,0,0,0-5.46,1.39,5.23,5.23,0,0,0-1.53,1.61A7.43,7.43,0,0,0,37.51,44a5.12,5.12,0,0,0,1.61-1.53A8.23,8.23,0,0,0,40.51,37a4.92,4.92,0,0,1,1.35-4.21l0-.06A4.85,4.85,0,0,1,47.43,31.83Z"
            fill="#232950"
          />
        </g>
      </g>
    </svg>
  );
}

CrimsonConnectLogo.propTypes = {
  className: PropTypes.string,
};
