/**
 *
 *
 * @param {number} angle
 * @param {number} radius
 * percent = [0, 1]
 */
export const getCoordinates = (percent: number, radius: number, centerX: number, centerY: number) => {
  const x = Math.sin(percent * 2 * Math.PI) * radius;
  const y = Math.cos(percent * 2 * Math.PI) * radius;
  return [centerX + x, centerY - y];
};

export const getPathOfOneSector = (
  startPercent: number,
  endPercent: number,
  innerRadius: number,
  outerRadius: number,
  centerX: number,
  centerY: number,
) => {
  const [oStartX, oStartY] = getCoordinates(startPercent, outerRadius, centerX, centerY);
  const [oEndX, oEndY] = getCoordinates(endPercent, outerRadius, centerX, centerY);
  const [iStartX, iStartY] = getCoordinates(startPercent, innerRadius, centerX, centerY);
  const [iEndX, iEndY] = getCoordinates(endPercent, innerRadius, centerX, centerY);
  const bigArc = endPercent - startPercent > 0.5 ? 1 : 0;
  const paths = [
    `M ${oStartX} ${oStartY}`,
    `A ${outerRadius} ${outerRadius} 0 ${bigArc} 1 ${oEndX} ${oEndY}`,
    `L ${iEndX} ${iEndY}`,
    `A ${innerRadius} ${innerRadius} 0 ${bigArc} 0 ${iStartX} ${iStartY}`,
    `L ${oStartX} ${oStartY}`,
  ];
  return paths.join(' ');
};
