import { getEnvironmentConfig } from '@crimson-education/common-config/lib/environment';
import { gql } from '@apollo/client';
import getGraphQLClient from '..';

const { api } = getEnvironmentConfig();

export const client = getGraphQLClient(`${api.endpoint}/graphql`);

export const queryMetabaseUrl = gql`
  query getMetabaseDashboardUrl($stratName: String, $dashboardName: String) {
    getMetabaseDashboardUrl(stratName: $stratName, dashboardName: $dashboardName) {
      metabaseUrl
    }
  }
`;
