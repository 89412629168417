import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import moment from 'moment';
import { Route, Switch, Redirect } from 'react-router-dom';
import autoBind from 'auto-bind';
import { BookingStatusTypes } from '@crimson-education/common-config';
import ViewHeadline from '@material-ui/icons/ViewHeadline';
import Event from '@material-ui/icons/Event';
import eventType from 'constants/eventType';
import Header from 'components/molecules/Header';
import CancelBookingModal from 'components/organisms/BookingModals/CancelBookingModal';
import RequestBookingModal from 'components/organisms/BookingModals/RequestBookingModal';
import BookingSummaryModal from 'components/organisms/BookingModals/BookingSummaryModal';
import CalendarSyncModal from 'components/organisms/BookingModals/CalendarSyncModal';
import UnbindPopupModal from 'components/organisms/BookingModals/UnbindPopupModal';
import AppleCalendarSyncModal from 'components/organisms/BookingModals/AppleCalendarSyncModal';
import AppleCalInstructionModal from 'components/organisms/BookingModals/AppleCalInstructionModal';
import SyncErrorModal from 'components/organisms/BookingModals/SyncErrorModal';
import SyncWaitingModal from 'components/organisms/BookingModals/SyncWaitingModal';

import { bookingModalModes } from 'components/organisms/BookingModals/BookingSummaryModal/constants';
import { Switch as SwitchToggle } from 'components/molecules/Switch';
import { Caption } from 'components/atoms/typography';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import eventApi from 'graphql/api/event';

import { toastr } from 'react-redux-toastr';
import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';
import SessionScheduleView from './SessionScheduleView';
import CalendarView from './CalendarView';
import css from './styles.scss';
import { handleEnter } from '../../../utils/accessibility';

const {
  googleCalendarOauth: { clientId },
} = getConfig();

const showToaster = (text, toasterStatus) => {
  const options = {
    timeOut: 5000,
    removeOnHover: false,
    onHideComplete: () => {
      toastr.removeByType(toasterStatus);
    },
    component: () => (
      <div
        role="button"
        tabIndex={0}
        style={{ cursor: 'pointer' }}
        onClick={() => {
          toastr.removeByType(toasterStatus);
        }}
        onKeyDown={handleEnter(() => {
          toastr.removeByType(toasterStatus);
        })}
      >
        <span>{text}</span>
      </div>
    ),
  };

  toastr[toasterStatus]('', '', options);
};

export default class Bookings extends Component {
  /**
   * @function constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);

    this.state = {
      isRequestBookingModalOpen: false,
      startTime: moment().set('second', 0),
      endTime: moment().set('second', 0),
      booking: null,
      isSummaryModalOpen: false,
      summaryMode: '',
      summaryFooterMode: '',
      summaryModalMessage: '',
      bookingModalMode: bookingModalModes.SUMMARY,
      isCancelBookingModalOpen: false,
      isSuggestTimeModalOpen: false,
      isCalendarSyncModalOpen: false,
      unbindPopupModalOpen: false,
      isAppleCalendarModalOpen: false,
      isAppleCalInstructionOpen: false,
      calendarSyncStatus: [],
      hasSynced: false,
      calSource: null,
      isSyncErrorModalOpen: false,
      syncWaitingModalOpen: false,
    };

    autoBind(this);

    if (window.location.pathname.includes('full')) {
      this.props.toggleCalendarView(true);
    }

    this.props.fetchBookingWith();
  }

  /**
   * @function componentWillMount
   */
  componentDidMount() {
    const { match, fetchBookFor, bookingBeingViewed, fetchBookingBeingViewed } = this.props;
    const { eventId } = match.params;

    const url = new URL(window.location.href);
    const authCode = url.searchParams.get('code');
    const error = url.searchParams.get('error');
    if (error && error === 'access_denied') {
      showToaster('Google calendar synchronization failed. Please try again.', 'error');
    } else if (authCode) {
      const redirectUrl = `${window.location.protocol}//${window.location.host}/calendar/full`;
      const calInfo = {
        source: 'google',
        authCode,
        redirectUrl,
      };
      this.dealWithGoogle(calInfo);
    }

    // User is viewing a booking but the data isn't present.
    if (eventId && !bookingBeingViewed) {
      fetchBookingBeingViewed(eventId);
    }

    if (eventId && bookingBeingViewed && !this.state.isSummaryModalOpen) {
      this.eventBookingHandler(bookingBeingViewed);
    }

    // Fetch the users that the current user can book on behalf of.
    // E.g. an Student Success Manager (Case Manager) can book on behalf of their students.

    fetchBookFor();
    this.fetchCalSyncStatus();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { match, bookingBeingViewed, fetchBookingBeingViewed } = nextProps;
    const { eventId } = match.params;

    // User is viewing a booking but the data isn't present.
    if (eventId && !bookingBeingViewed) {
      fetchBookingBeingViewed(eventId);
    }

    if (eventId && bookingBeingViewed && !this.state.isSummaryModalOpen && !this.state.isCancelBookingModalOpen) {
      this.eventBookingHandler(bookingBeingViewed);
    }
  }

  onCloseCalendarSyncModal() {
    this.setState({
      isCalendarSyncModalOpen: false,
    });
  }

  onCloseUnbindPopupModal() {
    this.setState({
      unbindPopupModalOpen: false,
    });
  }

  async onUnbindCalAccount(calSource) {
    const { fetchBookingAsEvents } = this.props;
    const result = await eventApi.unbindCalendarAccount(calSource);
    if (result) {
      if (calSource === 'google') {
        showToaster('Google calendar has been removed successfully.', 'success');
      } else if (calSource === 'apple') {
        showToaster('iCalendar has been removed successfully.', 'success');
      }
    } else if (calSource === 'google') {
      showToaster('Google calendar unbinding failed. Please try again.', 'error');
    } else if (calSource === 'apple') {
      showToaster('iCalendar unbinding failed. Please try again.', 'error');
    }
    this.onCloseUnbindPopupModal();
    fetchBookingAsEvents();
    this.fetchCalSyncStatus();
  }

  onCloseAppleCalendarModal() {
    this.setState({
      isAppleCalendarModalOpen: false,
    });
  }

  onCloseAppleCalInstructionModal() {
    this.setState({
      isAppleCalInstructionOpen: false,
    });
  }

  onCloseSyncErrorModal() {
    this.setState({
      isSyncErrorModalOpen: false,
    });
  }

  onCloseSyncWaitingModal() {
    this.setState({ syncWaitingModalOpen: false });
  }

  setCalendarView(isCalendar) {
    this.props.toggleCalendarView(isCalendar);
    isCalendar ? this.props.history.replace('full') : this.props.history.replace('schedule');
  }

  async dealWithGoogle(calInfo) {
    const { fetchBookingAsEvents, history } = this.props;
    this.setState({ syncWaitingModalOpen: true });
    const result = await eventApi.bindCalendarAccountOauth(calInfo);
    this.setState({ syncWaitingModalOpen: false });
    if (result) {
      showToaster('Google calendar has synchronized with the Crimson app successfully.', 'success');
      fetchBookingAsEvents();
      history.push('/calendar/full');
    } else {
      showToaster('Google calendar synchronization failed. Please try again.', 'error');
    }
  }
  async fetchCalSyncStatus() {
    const result = await eventApi.fetchCalSyncStatus();
    const calendarSyncStatus = [];
    let hasSynced = false;
    ['google', 'apple'].map((key) => {
      const calObj = {
        source: key,
        sourceName: key === 'google' ? 'Google Calendar' : 'iCalendar',
      };
      if (result[key].account) {
        calObj.calendarAccount = result[key].account;
        calObj.syncStatus = result[key].synced;
        hasSynced = true;
      }
      calendarSyncStatus.push(calObj);
      return true;
    });
    this.setState({
      calendarSyncStatus,
      hasSynced,
    });
  }
  showCalendarSync() {
    this.fetchCalSyncStatus();
    this.setState({
      isCalendarSyncModalOpen: true,
    });
  }

  showUnbindPopup(calSource) {
    this.setState({
      unbindPopupModalOpen: true,
      calSource,
    });
  }

  changAccountModal(/* calSourceName */) {
    // const result = TODO:
    // console.log(`calSourceName: ${calSourceName}`);
    // TODO:
  }

  showSyncAppleCalendar() {
    this.setState({
      isAppleCalendarModalOpen: true,
    });
  }

  async handleSubmitAppleCalInfo({ appleId, appSpecificPassword }) {
    const { fetchBookingAsEvents } = this.props;
    const calInfo = {
      account: appleId,
      source: 'apple',
      password: appSpecificPassword,
    };
    this.setState({ syncWaitingModalOpen: true });
    const result = await eventApi.bindCalendarAccountBasic(calInfo);
    this.setState({ syncWaitingModalOpen: false });
    if (!result) {
      showToaster('iCalendar synchronization failed. Please try again.', 'error');
      return;
    }
    showToaster('iCalendar has synchronized with the Crimson app successfully.', 'success');
    fetchBookingAsEvents();
    this.fetchCalSyncStatus();
  }

  openAppleInstructionModal() {
    this.setState({
      isAppleCalInstructionOpen: true,
    });
  }

  openSyncErroModal(calSource) {
    this.setState({
      calSource,
      isSyncErrorModalOpen: true,
    });
  }

  async resyncCal(calSource) {
    this.onCloseSyncErrorModal();
    const result = await eventApi.unbindCalendarAccount(calSource, true);
    if (result) {
      this.syncCal(calSource);
    } else {
      showToaster('Unbinding failed. Please try again.', 'error');
    }
  }

  syncCal(calSource) {
    this.onCloseCalendarSyncModal();
    if (calSource === 'apple') {
      this.showSyncAppleCalendar();
    } else if (calSource === 'google') {
      const redirectUrl = `${window.location.protocol}//${window.location.host}/calendar/full`;
      const googleAuthUrl = `https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?client_id=${clientId}&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar&access_type=offline&redirect_uri=${redirectUrl}&response_type=code&prompt=consent`;
      window.location.href = googleAuthUrl;
    }
  }

  /**
   * Updates the state to create a new booking
   * @function availabilityBookingHandler
   * @param {object} user - Selected user for booking
   * @param {string} startTime - Start time selected in calendar
   */
  availabilityBookingHandler(user, startTime) {
    this.setState({
      selectedUser: user,
      startTime,
      endTime: moment(startTime).add(1, 'hour'),
      isRequestBookingModalOpen: true,
      booking: null,
    });
  }

  /**
   * Redirects to lesson page
   * @param booking
   */
  redirectToMessagesFromBooking() {
    this.props.history.push('/messages');
  }

  /**
   * Handles the click event on the calendar/booking
   * @function eventBookingHandler
   * @param {object} booking - Selected booking from calendar
   */
  eventBookingHandler(booking) {
    const { history, fetchReasonsForCancellation, bookingAs, reasonsForCancellation } = this.props;

    this.setState({
      isRequestBookingModalOpen: false,
      isSummaryModalOpen: true,
      booking,
    });

    if (!reasonsForCancellation || !reasonsForCancellation.length) {
      fetchReasonsForCancellation(bookingAs.get('userId'), booking.id);
    }
    history.push(`/session/${booking.id}`);
  }

  goBackToSummaryModal() {
    this.setState({
      isCancelBookingModalOpen: false,
      isSuggestTimeModalOpen: false,
      isRequestBookingModalOpen: false,
      isSummaryModalOpen: true,
    });
  }

  /**
   * Opens booking form modal from summary modal
   * @function openModalViaSummary
   */
  openModalViaSummary() {
    this.setState({
      isRequestBookingModalOpen: true,
      booking: this.state.booking,
    });
    this.closeSummaryModal();
  }

  /**
   * Opens booking form modal
   * @function openModalViaButton
   */
  openModalViaButton() {
    this.setState({
      isRequestBookingModalOpen: true,
      startTime: moment().set('second', 0),
      endTime: moment().set('second', 0).add(1, 'hour'),
      selectedUser: null,
      booking: null,
    });
  }

  openModalViaCalendar(startTime) {
    this.setState({
      isRequestBookingModalOpen: true,
      startTime: moment(startTime).set('second', 0),
      endTime: moment(startTime).set('second', 0).add(1, 'hour'),
      selectedUser: null,
      booking: null,
    });
  }

  /**
   * Closes booking form modal
   * @function closeModal
   * @param {function=} callback - Callback to execute after closing modal
   */
  closeModal(callback) {
    this.setState({
      isRequestBookingModalOpen: false,
    });
    if (typeof callback === 'function') {
      callback();
    }
    this.props.fetchBookingWith();
  }

  /**
   * Closes booking summary modal
   * @function closeSummaryModal
   */
  closeSummaryModal() {
    const { history } = this.props;

    this.setState({
      isSummaryModalOpen: false,
      bookingModalMode: bookingModalModes.SUMMARY,
    });

    history.replace('/calendar');
  }

  openDefaultBookingModal() {
    this.setState({ bookingModalMode: bookingModalModes.SUMMARY });
  }

  openCancelBookingModal() {
    const { booking } = this.state;

    if (booking.status === BookingStatusTypes.TENTATIVE) {
      this.setState({ bookingModalMode: bookingModalModes.CANCEL_REQUEST });
    } else if (booking.type === eventType.Session) {
      this.setState({ bookingModalMode: bookingModalModes.CANCEL_BOOKING });
    } else {
      this.setState({
        isSummaryModalOpen: false,
        isCancelBookingModalOpen: true,
      });
    }
  }

  openDeclineBookingModal() {
    this.setState({ bookingModalMode: bookingModalModes.DECLINE_BOOKING });
  }

  /**
   * Renders the calendar and the booking modals
   * @function render
   * @returns {Calendar} markup
   */
  render() {
    const {
      bookingAs,
      match: { path, url },
      isCalendarView,
    } = this.props;

    const {
      isRequestBookingModalOpen,
      isCancelBookingModalOpen,
      selectedUser,
      startTime,
      endTime,
      booking,
      isSummaryModalOpen,
      bookingModalMode,
      isCalendarSyncModalOpen,
      unbindPopupModalOpen,
      isAppleCalendarModalOpen,
      isAppleCalInstructionOpen,
      calendarSyncStatus,
      calSource,
      isSyncErrorModalOpen,
      syncWaitingModalOpen,
      hasSynced,
    } = this.state;

    const actionButtons =
      bookingAs && bookingAs.size
        ? [
            {
              buttonText: 'Book a session',
              buttonAction: this.openModalViaButton,
              dataTestId: 'bookNewSessionButton',
              icon: 'Add',
            },
          ]
        : [];

    return (
      <div className={css.calendar} data-ga-category="Calendar">
        <Header title="Bookings" actionButtons={actionButtons}>
          <>
            <div
              role="button"
              tabIndex={0}
              className={css.calendarIcon}
              onClick={this.showCalendarSync}
              onKeyDown={handleEnter(this.showCalendarSync)}
            >
              <SwapHorizIcon />
              <span className={css.calendarSyncText}>Calendar Sync</span>
            </div>
            <SwitchToggle
              left={<ViewHeadline />}
              right={<Event />}
              onChange={this.setCalendarView}
              value={isCalendarView}
            />
          </>
        </Header>
        <div className={css.viewSwitcher}>
          <Caption bold>{isCalendarView ? 'Calendar view' : 'Schedule view'}</Caption>
          <div className={css.flexItem}>
            <div
              role="button"
              tabIndex={0}
              className={css.calBlue}
              onClick={this.showCalendarSync}
              onKeyDown={handleEnter(this.showCalendarSync)}
            >
              <SwapHorizIcon />
            </div>
            <SwitchToggle
              left={<ViewHeadline />}
              right={<Event />}
              onChange={this.setCalendarView}
              value={isCalendarView}
            />
          </div>
        </div>
        {isCalendarSyncModalOpen && (
          <CalendarSyncModal
            syncCal={this.syncCal}
            isOpen={isCalendarSyncModalOpen}
            calendarList={calendarSyncStatus}
            onCancel={this.onCloseCalendarSyncModal}
            showUnbindPopup={this.showUnbindPopup}
            changAccountModal={this.changAccountModal}
            showSyncAppleCalendar={this.showSyncAppleCalendar}
            openSyncErroModal={this.openSyncErroModal}
          />
        )}
        {unbindPopupModalOpen && (
          <UnbindPopupModal
            calSource={calSource}
            isOpen={unbindPopupModalOpen}
            onCancel={this.onCloseUnbindPopupModal}
            unbindCalAccount={this.onUnbindCalAccount}
          />
        )}
        {isAppleCalendarModalOpen && (
          <AppleCalendarSyncModal
            isOpen={isAppleCalendarModalOpen}
            onCancel={this.onCloseAppleCalendarModal}
            handleSubmitAppleCalInfo={this.handleSubmitAppleCalInfo}
            openAppleInstructionModal={this.openAppleInstructionModal}
          />
        )}
        {isAppleCalInstructionOpen && (
          <AppleCalInstructionModal
            isOpen={isAppleCalInstructionOpen}
            onCancel={this.onCloseAppleCalInstructionModal}
          />
        )}
        {isSyncErrorModalOpen && (
          <SyncErrorModal
            calSource={calSource}
            isOpen={isSyncErrorModalOpen}
            onCancel={this.onCloseSyncErrorModal}
            resync={this.resyncCal}
          />
        )}
        {syncWaitingModalOpen && (
          <SyncWaitingModal isOpen={syncWaitingModalOpen} onCancel={this.onCloseSyncWaitingModal} />
        )}
        {booking && (
          <BookingSummaryModal
            isOpen={isSummaryModalOpen}
            booking={booking}
            closeSummaryModal={this.closeSummaryModal}
            openModalViaSummary={this.openModalViaSummary}
            redirectToMessagesFromBooking={this.redirectToMessagesFromBooking}
            openCancelBookingModal={this.openCancelBookingModal}
            openDeclineBookingModal={this.openDeclineBookingModal}
            openDefaultBookingModal={this.openDefaultBookingModal}
            mode={bookingModalMode}
          />
        )}

        {isRequestBookingModalOpen && (
          <RequestBookingModal
            onBack={this.goBackToSummaryModal}
            isOpen={isRequestBookingModalOpen}
            booking={booking}
            onCancel={this.closeModal}
            selectedUser={selectedUser}
            startTime={startTime}
            endTime={endTime}
            isEditMode={booking && true}
          />
        )}

        {isCancelBookingModalOpen && (
          <CancelBookingModal
            eventId={booking.id}
            isOpen={isCancelBookingModalOpen}
            onBookingCancelled={() => this.setState({ isSummaryModalOpen: false, isCancelBookingModalOpen: false })}
            onBack={() => this.setState({ isSummaryModalOpen: true, isCancelBookingModalOpen: false })}
            onClose={() => this.setState({ isSummaryModalOpen: false, isCancelBookingModalOpen: false })}
          />
        )}

        <div className={css.body}>
          <Switch>
            <Route
              path={`${path}/full`}
              render={(props) => (
                <CalendarView
                  {...props}
                  availabilityBookingHandler={this.availabilityBookingHandler}
                  eventBookingHandler={this.eventBookingHandler}
                  openModalViaCalendar={this.openModalViaCalendar}
                  showDayCalendar={this.props.showDayCalendar}
                  hasSynced={hasSynced}
                />
              )}
            />
            <Route path={`${path}/schedule/:sortby`} component={SessionScheduleView} />
            <Route path={`${path}/schedule`} component={SessionScheduleView} />
            <Redirect to={`${url}/schedule`} />
          </Switch>
        </div>
      </div>
    );
  }
}

Bookings.defaultProps = {
  booking: null,
};

Bookings.propTypes = {
  fetchReasonsForCancellation: PropTypes.func.isRequired,
  reasonsForCancellation: PropTypes.array,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  bookingBeingViewed: PropTypes.object,
  fetchBookingBeingViewed: PropTypes.func.isRequired,
  fetchBookFor: PropTypes.func.isRequired,
  bookingAs: ImmutablePropTypes.map,
  fetchBookingWith: PropTypes.func.isRequired,
  isCalendarView: PropTypes.bool,
  toggleCalendarView: PropTypes.func,
  showDayCalendar: PropTypes.bool,
  fetchBookingAsEvents: PropTypes.func,
};
