import React from 'react';
import { Container } from './style';
import UpdatesCard from './UpdatesCard';

const UpdatesList = () => {
  return (
    <Container>
      {new Array(20).fill(1).map((_, idx) => (
        <UpdatesCard key={'updatescard' + idx} />
      ))}
    </Container>
  );
};

export default UpdatesList;
