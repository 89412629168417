import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/molecules/Modal';
import MultiSelect from 'components/molecules/MultiSelect';
import classNames from 'classnames';
import ContactList from 'components/unique/Conversations/ContactList';
import * as Logger from '@crimson-education/browser-logger';
import autoBind from 'auto-bind';
import debounce from 'lodash/debounce';

import css from './styles.scss';

export default class AddMorePeopleModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: [],
      inputValue: '',
      highlightedIndex: 0,
    };
    autoBind(this);
  }

  onChange(newValue) {
    this.setState({ values: newValue });
  }

  onCancel() {
    this.props.onCancel();
    this.clearValues();
  }

  onSubmit() {
    const addPeople = this.state.values.map((p) => p.value);
    if (addPeople.length > 0) {
      this.props.onAddMorePeople(addPeople);
    }
    this.clearValues();
  }

  onSearch(val) {
    if (val === ' ') return '';
    const string = val.replace(/[^\w\s]/gi, '');
    this.setState({ inputValue: string });
    if (!this.onSearchFunc) {
      this.onSearchFunc = debounce((text) => {
        this.props.setContactsFilter(text);
      }, 500);
    }
    this.onSearchFunc(string);
    return string;
  }

  onInputChange(newValue, actionMeta) {
    if (actionMeta.action !== 'input-blur' && actionMeta.action !== 'menu-close') {
      this.onSearch(newValue);
    }
  }

  onKeyDown(event) {
    const { highlightedIndex } = this.state;

    switch (event.keyCode) {
      case 40: // DOWN
        if (highlightedIndex < this.mapContacts().length - 1) {
          this.setState({ highlightedIndex: highlightedIndex + 1 });
        }
        break;
      case 38: // UP
        if (highlightedIndex > 0) {
          this.setState({ highlightedIndex: highlightedIndex - 1 });
        }
        break;
      case 13: // ENTER
        this.selectOption(this.mapContacts()[highlightedIndex]);
        event.preventDefault();
        break;
      default:
        break;
    }
  }
  onFormInputChange(inputName, value) {
    this.setState({ [inputName]: value });
  }

  onFocus() {
    Logger.trackEventSinceLastAction({ message: 'onfocus input new chat' });
  }

  clearValues() {
    this.setState({
      values: [],
      inputValue: '',
      highlightedIndex: 0,
      groupName: '',
      showGroupName: false,
      readOnly: false,
    });
    this.onSearch('');
  }

  mapContacts() {
    const { existedPeople } = this.props;
    return this.props.contacts
      .filter(
        (opt) =>
          !this.state.values.some((s) => {
            if (s) return s.userId === opt.userId;
            return false;
          }) &&
          opt.userId !== this.props.loginUserId &&
          !existedPeople.some((s) => {
            if (s) return s.userId === opt.userId;
            return false;
          }),
      )
      .map((contact) =>
        Object.assign({}, contact, {
          value: contact.userId,
          label: `${contact.firstName} ${contact.lastName}`,
        }),
      );
  }

  closeModal() {
    const { onClose } = this.props;
    this.clearValues();
    onClose();
  }
  selectOption(option) {
    this.setState({ values: [...this.state.values, option] });
    this.onSearch('');
    this.setState({ inputValue: '' });
    this.setState({ highlightedIndex: 0 });
    this.select.focus();
  }

  inputRenderer(inputProps) {
    return (
      <div className="Select-input">
        <input {...inputProps} value={this.state.inputValue} data-test-id="searchMultiContactsSelectorInput" />
      </div>
    );
  }

  loadMore() {
    this.props.setContactsFilter(this.state.inputValue);
  }

  render() {
    const { isOpen, contactPageInfo } = this.props;

    const mappedContacts = this.mapContacts();
    return (
      <Modal
        isOpen={isOpen}
        title="Add More People"
        cancelLabel="close"
        onSubmit={this.onSubmit}
        submitText="ADD"
        onSubmitDataTestId="addPeopleButton"
        onClose={this.closeModal}
        style={css.modalStyle}
        buttonStyle={this.state.values.length > 0 ? css.buttonBlueCss : css.buttonGrayCss}
        isSubmitDisabled={this.state.values.length < 1}
        customButtonGroupStyle={css.customButtonGroup}
        customHeaderStyle={css.headerStyle}
        buttonTextStyle={this.state.values.length > 0 ? css.buttonTextWhite : css.buttonTextGray}
      >
        <div className={css.newChatContainer}>
          <MultiSelect
            dataTestId="searchMultiContactsSelectorInput"
            forwardedRef={(select) => {
              this.select = select;
            }}
            onKeyDown={this.onKeyDown}
            onFocus={this.onFocus}
            onChange={this.onChange}
            placeholder="Search for a contact"
            className={classNames(css.chatFormInput, css.selectContainer)}
            value={this.state.values}
            options={mappedContacts}
            onInputChange={this.onInputChange}
            menuIsOpen={false}
            inputValue={this.state.inputValue}
          />
          <ContactList
            className={css.contactList}
            contacts={mappedContacts}
            onSelect={this.selectOption}
            loadMore={this.loadMore}
            contactPageInfo={contactPageInfo}
            highlightedIndex={this.state.highlightedIndex}
          />
        </div>
      </Modal>
    );
  }
}

AddMorePeopleModal.propTypes = {
  isOpen: PropTypes.bool,
  contactPageInfo: PropTypes.object,
  loginUserId: PropTypes.string,
  onSearch: PropTypes.func,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  setContactsFilter: PropTypes.func,
  contacts: PropTypes.array,
  onAddMorePeople: PropTypes.func,
  existedPeople: PropTypes.array,
  onClose: PropTypes.func.isRequired,
};
