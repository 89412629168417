import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as Icons from 'components/atoms/icons';
import css from './style.scss';

const iconSizes = {
  xsmall: { height: '1.2rem', width: '1.2rem' },
  small: { height: '1.6rem', width: '1.6rem' },
  medium: { height: '2rem', width: '2rem' },
  large: { height: '2.4rem', width: '2.4rem' },
};

export default function TextWithIcon(props) {
  const { className, icon, text, iconSize, dataTestId, renderText, renderIcon } = props;
  const Icon = icon ? Icons[icon] : null;

  return (
    <div data-test-id={dataTestId} className={classNames(css.textWithIconWrapper, className)}>
      {renderIcon ? (
        renderIcon()
      ) : (
        <Icon className={css.icon} style={{ height: iconSizes[iconSize].height, width: iconSizes[iconSize].width }} />
      )}
      {renderText ? renderText(text) : <div>{text}</div>}
    </div>
  );
}

TextWithIcon.propTypes = {
  icon: PropTypes.string,
  iconSize: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large']),
  className: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  dataTestId: PropTypes.string,
  renderText: PropTypes.func,
  renderIcon: PropTypes.func,
};

TextWithIcon.defaultProps = {
  iconSize: 'medium',
};
