import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectUserId } from 'selectors/meta';
import { useHasAccesssToStaffDashboard } from '../../helper';

const UNAUTHORISED_ROUTE = '/unauthorized';

const withAuthorization = <P,>(Comp: React.ComponentType<P>) => {
  const Enhanced = (props: P): JSX.Element | null => {
    const history = useHistory();
    const requesterUserId = useSelector(selectUserId);
    const userIdInUrl = window.location.pathname.split('/').reverse()[0];
    const isMatchedUser = requesterUserId === userIdInUrl;
    const canAccess = useHasAccesssToStaffDashboard();
    if (!isMatchedUser || !canAccess) {
      history.replace(UNAUTHORISED_ROUTE);
      return null;
    }
    return <Comp {...props} />;
  };
  return Enhanced;
};

export default withAuthorization;
