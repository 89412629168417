import React from 'react';
import ListModal from '../Modals/ListModal';
import { AppYearStudent } from '../../../type';
import ContactCard from '../ContactCard';
import { useStaffDashboardContext } from '../../helper';
import { useRequesterUserId } from '../../../helper';
import { getUserDisplayName } from '../../util';
type StudentsModalProps = {
  title?: string;
  students: AppYearStudent[];
  isOpen: boolean;
  onCancel: () => void;
};
const StudentsModal = ({ students, title, isOpen, onCancel }: StudentsModalProps) => {
  const { controller } = useStaffDashboardContext();
  const currentUserId = useRequesterUserId();
  return (
    <ListModal
      data={students}
      title={title}
      isOpen={isOpen}
      onCancel={onCancel}
      renderItem={(student) => (
        <ContactCard
          key={student.userId}
          avatar={student.profileImageUrl}
          firstName={student.firstName}
          lastName={student.lastName}
          onClickMessage={() => controller.onClickMessage(currentUserId, student.userId, getUserDisplayName(student))}
          onClickContacts={controller.onClickTeamMember(student.userId)}
          onClickName={() => controller.goToStudentProfile(student.userId)}
        />
      )}
    />
  );
};
export default StudentsModal;
