import React, { useContext, useMemo } from 'react';
import { useFeatureFlag } from 'featureSwitches';
import ToolBar from './ToolBar';
import { Column } from '../index.style';
import { AppStatusText } from './index.style';
import { Separator } from '../components/index.style';
import UniversityList from '../UniversityList';
import ApplicationEssays from '../ApplicationEssays';
import { AppTrackerContext } from '../viewController';
import {
  groupApplications,
  mapApplicationRegion,
  getApplicationStatusTextStyle,
  hasAdmissionResult,
  optionMapToOptions,
  getLastApplicationStatus,
} from '../util';
import { Application } from '../type';
import { PopoverOrigin } from '@material-ui/core';
import Placeholder from './Placeholder';
import { useIsAccessibleStaff, useIsStudent, useRequesterUserId } from '../helper';
import { TRACKER_PAGE_GROUP_BUTTON_OPTIONS_MAP } from '../constants';

const getGroupButtonText = (application: Application) => {
  if (application.isSubmitted && hasAdmissionResult(application)) {
    return getLastApplicationStatus(application);
  }
  if (application.isSubmitted) {
    return 'Submitted';
  }
  if (application.extraApplicationStatus === 'INVITED_TO_INTERVIEW') {
    return 'Invited to Interview';
  }
  return 'In Progress';
};
const TrackerPage = () => {
  const { state, controller } = useContext(AppTrackerContext);
  const isStudent = useIsStudent();
  const isAccessibleStaff = useIsAccessibleStaff();
  const requesterUserid = useRequesterUserId();
  const showAppEssayUploadFeature = useFeatureFlag('APP_ESSAY_UPLOAD');
  const { trackerRegion, trackerGroupBy } = state;
  const applyingApplications = useMemo(() => state.applications.filter((o) => o.group === 'APPLYING'), [
    state.applications,
  ]);
  const applications = useMemo(() => applyingApplications.filter((o) => mapApplicationRegion(o) === trackerRegion), [
    applyingApplications,
    trackerRegion,
  ]);
  const groupedApplications = useMemo(() => groupApplications(applications, trackerGroupBy, trackerRegion), [
    applications,
    trackerGroupBy,
    trackerRegion,
  ]);
  const renderActionButton = (application: Application) => (renderButton: (isOpen: boolean) => JSX.Element) => {
    const actionButton = (isOpen: boolean) => {
      const _hasAdmissionResult = hasAdmissionResult(application);
      if (application.isSubmitted && _hasAdmissionResult) {
        const applicatioStatus = application.applicationStatus[application.applicationStatus.length - 1];
        return (
          <AppStatusText
            onClick={(e) => {
              e.stopPropagation();
              controller.openAdmissionDrawer(application);
            }}
            style={{ ...getApplicationStatusTextStyle(applicatioStatus) }}
          >
            {applicatioStatus}
          </AppStatusText>
        );
      }
      return renderButton(isOpen);
    };
    return actionButton;
  };
  const getExtraActionButtonProps = (application: Application) => ({
    actionButtonOptions: optionMapToOptions(TRACKER_PAGE_GROUP_BUTTON_OPTIONS_MAP),
    onClickActionButtonItem: (value: string) =>
      controller.onClickTrackerPageActionButtonItem(application.id, value, { updatedBy: requesterUserid }),
    getActionButtonText: () => getGroupButtonText(application),
    renderActionButton: renderActionButton(application),
    menuItemStyle: {
      justifyContent: 'flex-end',
      width: 165,
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'right',
    } as PopoverOrigin,
    transformOrigin: { vertical: 'top', horizontal: 'right' } as PopoverOrigin,
    disableDropdown: hasAdmissionResult(application),
  });
  const getExtraUniversityNameProps = (application: Application) => {
    return {
      onClick: () => {
        if (application.isSubmitted) controller.openAdmissionDrawer(application);
      },
      style: application.isSubmitted ? { cursor: 'pointer' } : undefined,
    };
  };
  const getExtraDeleteAppButtonProps = (application: Application) => {
    const _hasAdmissionResult = hasAdmissionResult(application);
    const options = [{ label: 'Remove school from the list', value: 'remove_application' }];
    if (_hasAdmissionResult) options.push({ label: 'Remove the application result', value: 'remove_admission_result' });
    const isApplying = application.group === 'APPLYING';
    return {
      preOpenDropdown: isApplying
        ? () => controller.checkIfEditable(isStudent, isAccessibleStaff, application)
        : undefined,
      options,
      onConfirmDeleteOption: controller.onConfirmDeleteItem,
      getConfirmDeletionText: (value: string | null) => {
        if (value === 'remove_admission_result') {
          return 'Are you sure you want to remove the admission result?';
        }
        if (value === 'remove_application') {
          return 'Are you sure you want to remove the school from your list? \n\nYou can add it back if you change your mind later.';
        }
        return 'Are you sure you want to remove it?';
      },
      menuItemStyle: {
        margin: _hasAdmissionResult ? '6px 0' : undefined,
        width: 170,
      },
    };
  };
  const hasApplications = applyingApplications.length > 0;
  const hasAppsUnderCurrentRegion = applications.length > 0;
  return (
    <Column style={{ margin: '25px 60px 300px' }}>
      {showAppEssayUploadFeature && <ApplicationEssays />}
      {hasApplications && <ToolBar />}
      {hasApplications && <Separator $margin={'20px 0 0'} />}
      {!hasAppsUnderCurrentRegion && <Placeholder />}
      {hasAppsUnderCurrentRegion && (
        <>
          {groupedApplications.map(({ label, applications: _applications }, index) => {
            return (
              <UniversityList
                index={index}
                getExtraActionButtonProps={getExtraActionButtonProps}
                getExtraUniversityNameProps={getExtraUniversityNameProps}
                key={label}
                applications={_applications}
                groupText={label}
                count={_applications.length}
                getExtraDeleteAppButtonProps={getExtraDeleteAppButtonProps}
              />
            );
          })}
        </>
      )}
    </Column>
  );
};

export default TrackerPage;
