import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Body, Caption } from 'components/atoms/typography';
import textBehaviour from 'components/atoms/behaviours/text.scss';
import colours from 'components/atoms/colours.scss';
import css from './style.scss';

export default function RoadmapCategoryLabel(props) {
  const { category, categoryColor, mission, dateText, className, canEdit } = props;
  return (
    <div className={classnames(css.labelContainer, className, { [css.disabled]: !canEdit })}>
      <div className={css.categoryColor} style={{ backgroundColor: categoryColor }} />
      <div className={css.textContainer}>
        <span className={css.category}>
          <Caption className={textBehaviour.truncateText}>{category}</Caption>
        </span>
        {mission && (
          <div className={css.missionWrapper}>
            <Body bold className={classnames(css.mission, textBehaviour.truncateText)}>
              {mission}
            </Body>
            {dateText && <Caption className={textBehaviour.truncateText}>{dateText}</Caption>}
          </div>
        )}
      </div>
    </div>
  );
}

RoadmapCategoryLabel.defaultProps = {
  categoryColor: colours.primary,
  category: '',
  canEdit: true,
};

RoadmapCategoryLabel.propTypes = {
  categoryColor: PropTypes.string,
  category: PropTypes.string,
  mission: PropTypes.string,
  dateText: PropTypes.string,
  className: PropTypes.string,
  canEdit: PropTypes.bool,
};
