import { TStaffDashboardState, Actions } from './store';
import { Dispatch } from 'redux';
import applicationService from 'graphql/api/application';
import sessionService from 'graphql/api/session';
import userService from 'graphql/api/user';
import { SCHOOL_SELECTION_DATA, REGION_ALERTS, resolveEvent, DataInputs, MILESTONE_MEETING_DATA } from '../alerts';
import { formFail } from 'ducks/meta';
import { fetchingUserContacts } from 'ducks/user';
import { StaffDashboardRegion, AlertInState } from '../type';
import { pick } from 'lodash';
import {
  constructSchoolSelectionStoreAlerts,
  constructMilestoneMeetingData,
  getSessionNoteUrl,
  getUserProfileUrl,
} from '../helper';
import { ApplicationOverview, ApplicationRegion } from '../../type';
import { openLink, sleep } from '../../util';
import React from 'react';
import { prepareThread, fetchThreads } from 'ducks/messaging';
import { history } from 'app';

export class StaffDashboardController {
  private state?: TStaffDashboardState;
  private dispatch?: <A extends Actions>(name: A['name'], payload?: A['payload']) => void;
  private reduxDispatch?: Dispatch<any>;

  setParams = (
    state: TStaffDashboardState,
    dispatch: <A extends Actions>(name: A['name'], payload?: A['payload']) => void,
    reduxDispatch: Dispatch<any>,
  ) => {
    this.state = state;
    this.dispatch = dispatch;
    this.reduxDispatch = reduxDispatch;
  };

  openAlertDetailModal = (alertValue: string) => {
    this.dispatch?.('staffDashboard/setActiveAlert', { activeAlertValue: alertValue });
    this.dispatch?.('staffDashboard/setAlertModalOpen', { alertModalOpen: true });
  };
  closeAlertDetailModal = () => {
    this.dispatch?.('staffDashboard/setActiveAlert', { activeAlertValue: null });
    this.dispatch?.('staffDashboard/setAlertModalOpen', { alertModalOpen: false });
  };

  setRegion = (region: StaffDashboardRegion) => {
    this.dispatch?.('staffDashboard/setRegion', { region });
  };

  setCalculating = (task: keyof TStaffDashboardState['isCalculating'], isCalculating: boolean) => {
    this.dispatch?.('staffDashboard/setCalculating', {
      [task]: isCalculating,
    });
  };

  setUserAlertsMap = (userAlertsMap: TStaffDashboardState['userAlertsMap']) => {
    this.dispatch?.('staffDashboard/setUserAlertsMap', {
      userAlertsMap,
    });
  };

  closeContactModal = () => this.dispatch?.('staffDashboard/setContactModalClose');
  queryData = async () => {
    try {
      const {
        getAppYearStudentsByStaffUserId: students,
        getMilestoneSessionReportsByUserId: sessionReports,
        getStaffStudentsApplications: applications,
        getStaffStudentsApprovals: approvals,
        getStudentSupervisorsByRequester: { relationships, staffInfos },
        getAllStudentOverviewsByStaffId: overviews,
      } = await applicationService.staffDashboardQueries();
      this.dispatch?.('staffDashboard/setBatchData', {
        applications,
        approvals,
        sessionReports,
        students,
        relationships,
        staffInfos,
        overviews,
      });
    } catch (err) {
      this.reduxDispatch?.(formFail('Staff Dashboard Network error' + (!!err.message ? `: ${err.message}` : '')));
    } finally {
      this.dispatch?.('staffDashboard/setLoading', { isLoading: false });
    }
  };

  getStudentsApplications = async () => {
    const { getStaffStudentsApplications } = await applicationService.getStaffStudentsApplications();
    return getStaffStudentsApplications;
  };

  getStudentsApprovals = async () => {
    const { getStaffStudentsApprovals } = await applicationService.getStaffStudentsApprovals();
    return getStaffStudentsApprovals;
  };

  getMilestoneMeetings = async () => {
    const { getMilestoneSessionReportsByUserId } = await sessionService.getMilestoneSessionReportsByUserId();
    return getMilestoneSessionReportsByUserId;
  };

  _getAlertDataInput = () => {
    const { applications, approvals, sessionReports, students } = this.state!;
    const dataInput: Omit<DataInputs, 'studentUserIds' | 'region'> = {
      appYearStudents: students,
      applications,
      approvals,
      sessionReports,
    };
    return dataInput;
  };

  calculateAlerts = async () => {
    this.setCalculating('alert', true);
    try {
      for (const region of ['US', 'UK', 'EU']) {
        const dataInput: Omit<DataInputs, 'studentUserIds'> = {
          ...this._getAlertDataInput(),
          region: region as StaffDashboardRegion,
        };
        const alerts: AlertInState[] = await Promise.all(
          REGION_ALERTS()[region as StaffDashboardRegion].map(async (alert) => ({
            userIds: (await resolveEvent(dataInput)(alert)) as string[],
            color: 'red',
            ...pick(alert, ['label', 'value', 'priority']),
          })),
        );
        const filtered = alerts.filter((o) => !!o.userIds.length);
        this.dispatch?.('staffDashboard/setAlerts', { [region]: filtered });
      }
    } catch (err) {
      this.reduxDispatch?.(formFail('Network error: Failed to query alerts. Error: ' + err.message));
    } finally {
      this.setCalculating('alert', false);
    }
  };
  calculateSchoolSelectionData = async () => {
    this.setCalculating('schoolSelction', true);
    for (const region of ['US', 'UK', 'EU']) {
      const dataInput: Omit<DataInputs, 'studentUserIds'> = {
        ...this._getAlertDataInput(),
        region: region as StaffDashboardRegion,
      };
      const alert = SCHOOL_SELECTION_DATA[region as StaffDashboardRegion];
      const res = await resolveEvent(dataInput)(alert);
      const alerts: AlertInState[] = constructSchoolSelectionStoreAlerts(res, region as StaffDashboardRegion);
      this.dispatch?.('staffDashboard/setSchoolSelectionData', {
        [region]: alerts,
      });
    }
    this.setCalculating('schoolSelction', false);
  };
  calculateMilestoneMeetingData = async () => {
    this.setCalculating('meeting', true);
    for (const region of ['US', 'UK', 'EU']) {
      const dataInput: Omit<DataInputs, 'studentUserIds'> = {
        ...this._getAlertDataInput(),
        region: region as StaffDashboardRegion,
      };
      const alert = MILESTONE_MEETING_DATA[region as StaffDashboardRegion];
      const res = await resolveEvent(dataInput)(alert);
      const alerts: AlertInState[] = constructMilestoneMeetingData(res, region as StaffDashboardRegion);
      this.dispatch?.('staffDashboard/setMilestoneMeetingData', {
        [region]: alerts,
      });
    }
    this.setCalculating('meeting', false);
  };
  getTeamMembersByStudentUserId = async (studentUserId: string) => {
    try {
      const { getTeamMembersByStudentUserId } = await userService.getTeamMembersByStudentUserId(studentUserId);
      this.dispatch?.('staffDashboard/setStudentTeamMembers', {
        [studentUserId]: getTeamMembersByStudentUserId,
      });
    } catch (err) {
      this.reduxDispatch?.(formFail('Failed to fetch team members: ' + err.message));
    }
  };

  getAppYearStudents = async () => {
    const {
      data: { getAppYearStudentsByStaffUserId },
    } = await userService.getAppYearStudentsByStaffUserId();
    return getAppYearStudentsByStaffUserId;
  };

  getStudentSupervisorsByRequester = async () => {
    const {
      data: { getStudentSupervisorsByRequester },
    } = await userService.getStudentSupervisorsByRequester();
    return getStudentSupervisorsByRequester;
  };
  updateServiceStatusOfSingleOverview = (overviewId: string, payload: Pick<ApplicationOverview, 'serviceStatus'>) => {
    const { overviews } = this.state!;
    this.dispatch?.('staffDashboard/setOverviews', {
      overviews: overviews.map((o) => {
        if (o.id !== overviewId) return o;
        return {
          ...o,
          ...payload,
        };
      }),
    });
  };
  addNewOverview = (overview: ApplicationOverview) => {
    const { overviews } = this.state!;
    this.dispatch?.('staffDashboard/setOverviews', {
      overviews: overviews.concat(overview),
    });
  };

  createOrUpdateServiceStatus = async (
    userId: string,
    region: ApplicationRegion,
    serviceStatus: string,
    overviewId?: string | null,
  ) => {
    const isUpdate = !!overviewId;
    try {
      if (isUpdate) {
        const originOverview = this.state?.overviews.find((o) => o.id === overviewId);
        if (!originOverview) return;
        try {
          this.updateServiceStatusOfSingleOverview(overviewId!, { serviceStatus });
          const { updateApplicationOverview } = await applicationService.updateApplicationOverview(overviewId, {
            serviceStatus,
            userId,
            region,
          });
        } catch (err) {
          this.updateServiceStatusOfSingleOverview(overviewId!, originOverview);
          throw err;
        }
      } else {
        const { createApplicationOverview } = await applicationService.createApplicationOverview({
          region,
          userId,
          serviceStatus,
        });
        this.addNewOverview(createApplicationOverview);
      }
    } catch (err) {
      this.reduxDispatch?.(formFail('Failed to update service status: ' + err.message));
    }
  };

  onClickMessage = async (currentUserId: string, targetUserId: string, targetUserName: string) => {
    // query threads
    await this.reduxDispatch?.(fetchThreads(currentUserId, 'DIRECT'));
    // query target user info. If target user info doesnt exist in local store, page will be blank
    await this.reduxDispatch?.(fetchingUserContacts(targetUserName));
    await sleep(100);
    await this.reduxDispatch?.(prepareThread(history, [targetUserId]));
  };

  onClickTeamMember = (studentUserId: string) => async (e: React.MouseEvent<HTMLDivElement>) => {
    const domRect = e.currentTarget.getBoundingClientRect();
    const windowHeight = document.body.clientHeight;
    const contactModalHeight = 350;
    const hasSpace = domRect.bottom + contactModalHeight < windowHeight;
    try {
      const { studentTeamMembers } = this.state!;
      if (!studentTeamMembers[studentUserId]) {
        await this.getTeamMembersByStudentUserId(studentUserId);
      }
      this.dispatch?.('staffDashboard/setContactModalOpen', {
        contactModalPosition: {
          top: domRect.bottom + (hasSpace ? 20 : -20),
          left: domRect.left,
          translate: '-40% ' + (hasSpace ? '0' : '-100%'),
        },
        contactModalStudentId: studentUserId,
      });
    } catch (err) {
      this.reduxDispatch?.(formFail('Failed to fetch team members: ' + err.message));
    }
  };

  onClickSessionNotes = (studentUserId: string) => {
    openLink(getSessionNoteUrl(studentUserId));
  };

  goToStudentProfile = (studentUserId: string) => {
    openLink(getUserProfileUrl(studentUserId));
  };
}
