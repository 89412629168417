import {
  OnboardingQuizQuestion,
  OnboardingQuizQuestionOption,
  ChildQuestionSlider,
  QuestionCategoryCount,
  QuestionByCategory,
  OnboardingQuizSelectedOption,
} from 'graphql/types/profile';
import { uniqBy, isMatch, differenceBy, flatten, get } from 'lodash';
import { useRef, useState, useEffect } from 'react';
export const DESCRIPTION_PAGE_NAME = 'onboardingDescriptionPage';
export const PRIMARY_SUBJECT_QS_PAGE_NAME = 'primarySubjectQsPage';
export const PRIMARY_SUBJECT_CHILDREN_QS_PAGE_NAME = 'primarySubjectChildrenQsPage';
export const REST_QUESTIONS_PAGE_NAME = 'restQuestionsPage';
export const QUIZ_COMPLETION_PAGE_NAME = 'quizCompletionPage';

const PAGES_HIDE_NEXT_BUTTON = [
  PRIMARY_SUBJECT_QS_PAGE_NAME,
  PRIMARY_SUBJECT_CHILDREN_QS_PAGE_NAME,
  REST_QUESTIONS_PAGE_NAME,
];

export type ParentQuestionType = 'primarySubject' | 'other' | 'childOfChild';

export const shouldActivePageHideNextButton = (activePage: string) => {
  return PAGES_HIDE_NEXT_BUTTON.some((pageName) => activePage.includes(pageName));
};

export const composeSliderPageName = (pagePrefix: string, uniqId = '') => pagePrefix + '/' + uniqId;
export const decomposeSliderPageName = (pageName: string): [string, string] => {
  const splitted = pageName.split('/');
  return (splitted.length === 2 ? splitted : [...splitted, '']) as [string, string];
};
/**
 *
 *
 * @param {string} groupId
 * @param {OnboardingQuizQuestion[]} questions
 * @param {OnboardingQuizQuestion[]} [list=[]]
 * @return {*}
 * get questions with provided groupId
 */
export const getGroupQuestionsById = (
  groupId: string,
  questions: OnboardingQuizQuestion[],
  list: OnboardingQuizQuestion[] = [],
) => {
  questions.forEach((q) => {
    if (q.groupId === groupId) {
      list.push(q);
    }
    if (q.children && q.children.length > 0) {
      getGroupQuestionsById(groupId, q.children, list);
    }
  });

  return uniqBy(list, 'id');
};

/**
 *
 *
 * @param {OnboardingQuizQuestion[]} questions
 * @return {*}
 */
export const collectQuestionsByGroupId = (questions: OnboardingQuizQuestion[]) => {
  const idAndQuestions: { [key: string]: OnboardingQuizQuestion[] } = {};
  questions.forEach((q) => {
    if (idAndQuestions[q.groupId]) {
      idAndQuestions[q.groupId].push(q);
    } else {
      idAndQuestions[q.groupId] = [q];
    }
  });
  return flatten(Object.values(idAndQuestions));
};

/**
 *
 *
 * @param {OnboardingQuizQuestion[]} questions
 * @return {*}  {[OnboardingQuizQuestion[], OnboardingQuizQuestion[]]}
 *
 * return [PrimarySubjectQuestions, questionsByCategory, categoryCount]
 */
export const splitQuestions = (
  questions: OnboardingQuizQuestion[],
): [OnboardingQuizQuestion[], QuestionByCategory, QuestionCategoryCount] => {
  const primarySubjectQuestions = getPrimarySubjectQuestions(questions);
  const filtered = differenceBy(questions, primarySubjectQuestions, 'id');
  const collected = collectQuestionsByGroupId(filtered);
  const [questionByCategory, questionCategoryCount] = splitQuestionsByCategory(primarySubjectQuestions, collected);
  const [enhancedPrimarySubjectQuestions, enchandedSubjects] = extendChildrenOfPrimaryQuestions(
    primarySubjectQuestions,
    questionByCategory.Subjects,
  );
  return [
    enhancedPrimarySubjectQuestions,
    { ...questionByCategory, Subjects: enchandedSubjects },
    questionCategoryCount,
  ];
};

/**
 *
 *
 * @param {OnboardingQuizQuestion[]} primary
 * @param {OnboardingQuizQuestion[]} nonePrimary
 * @return {*}
 * add none primary subjects questions to the children of primary subject questions
 */
const extendChildrenOfPrimaryQuestions = (
  primary: OnboardingQuizQuestion[],
  nonePrimary: OnboardingQuizQuestion[],
): [OnboardingQuizQuestion[], OnboardingQuizQuestion[]] => {
  const _nonePrimary = [...nonePrimary];
  const _primary = primary.map((question) => {
    const childQuestions = _nonePrimary.filter((o) => o.groupId === question.groupId);
    childQuestions.forEach((q) => {
      q.grandparentQuestionId = question.id;
    });
    return {
      ...question,
      children: [...question.children, ...childQuestions],
    };
  });
  return [_primary, _nonePrimary];
};
/**
 *
 *
 * @param {OnboardingQuizQuestion[]} primarySubjectQuestions
 * @param {OnboardingQuizQuestion[]} restQuestions
 * @return {*}
 * return [
 *  restQuestionsByCategory,
 *  countOfCategory
 * ]
 */
export const splitQuestionsByCategory = (
  primarySubjectQuestions: OnboardingQuizQuestion[],
  restQuestions: OnboardingQuizQuestion[],
): [QuestionByCategory, QuestionCategoryCount] => {
  const categoryCount: QuestionCategoryCount = {
    Subjects: primarySubjectQuestions.length,
    Career: 0,
    Personality: 0,
  };
  const questionsByCategory: QuestionByCategory = {
    Subjects: [],
    Career: [],
    Personality: [],
  };
  restQuestions.forEach((q, idx) => {
    categoryCount[q.category]++;
    questionsByCategory[q.category]!.push(q);
  });
  if (categoryCount.Personality === 0) {
    delete categoryCount.Personality;
    delete questionsByCategory.Personality;
  }
  return [questionsByCategory, categoryCount];
};
/**
 *
 *
 * @param {OnboardingQuizQuestion[]} questions
 * get primary subject questions
 */
export const getPrimarySubjectQuestions = (questions: OnboardingQuizQuestion[]) =>
  questions.filter((o) => !!o.primarySubject);

/**
 *
 *
 * @param {OnboardingQuizQuestionOption} option
 * @param {OnboardingQuizQuestion} question
 * @return {*}  {(OnboardingQuizQuestion[] | null)}
 *
 * get children questions with selected option, return null if no children question matched
 */
export const getChildQuestions = (
  option: OnboardingQuizQuestionOption,
  question: OnboardingQuizQuestion,
): OnboardingQuizQuestion[] | null => {
  if (!question.children || !question.children.length) return null;
  const matched = question.children.filter((q) => {
    const op = q.showOptionsByParentSubject.length > 0 ? q.showOptionsByParentSubject : q.showOptionsByPrimarySubject;
    return op.some((o) => isMatch(o, { id: option.id }));
  });
  return !!matched.length ? matched : null;
};

export /**
 *
 *
 * @template T
 * @param {T} initialValue
 * @return {*}  {[T, (v: T) => void]}
 */
const useValue = <T>(initialValue: T): [T, (v: T) => void] => {
  const value = useRef<T>(initialValue);
  const setValue = (nextValue: T) => {
    value.current = nextValue;
  };
  return [value.current, setValue];
};

export const composeLocalOptionId = (questionId: string, optionId: string) => questionId + '/' + optionId;
export const decomposeLocalOptionId = (id: string) => id.split('/') as [string, string];

/**
 *
 *
 * @param {number} idx
 * @param {string} id
 * @param {ParentQuestionType} parentQuestionType
 * @return {*}
 * @deprecated
 */
const _getAfterPageName = (idx: number, id: string, parentQuestionType: ParentQuestionType) => {
  switch (parentQuestionType) {
    case 'primarySubject':
      return composeSliderPageName(
        idx === 0 ? PRIMARY_SUBJECT_QS_PAGE_NAME : PRIMARY_SUBJECT_CHILDREN_QS_PAGE_NAME,
        idx === 0 ? '' : id,
      );

    case 'other':
      return composeSliderPageName(REST_QUESTIONS_PAGE_NAME, id);
    case 'childOfChild':
      return composeSliderPageName(
        idx === 0 ? PRIMARY_SUBJECT_QS_PAGE_NAME : PRIMARY_SUBJECT_CHILDREN_QS_PAGE_NAME,
        idx === 0 ? '' : id,
      );
  }
};

/**
 *
 *
 * @param {ChildQuestionSlider[]} existingCQS
 * @param {OnboardingQuizQuestion[]} nextCQS
 * @param {OnboardingQuizQuestion} parentQuestion
 * @param {('primarySubject' | 'other')} parentQuestionType
 * @return {*}  {ChildQuestionSlider[]}
 */
export const setAfterPagePropOnAdd = (
  existingCQS: ChildQuestionSlider[],
  nextCQS: OnboardingQuizQuestion[],
  parentQuestion: OnboardingQuizQuestion,
  parentQuestionType: ParentQuestionType,
): ChildQuestionSlider[] => {
  // add child question after parent question, update `afterpage` of the page after parent question
  if (parentQuestionType === 'childOfChild') {
    const copied = [...existingCQS];
    nextCQS.forEach((question) => {
      const parentIdx = copied.findIndex((o) => o.question.id === parentQuestion.id);
      const obj: ChildQuestionSlider = {
        question,
        parentQuestionId: parentQuestion.id,
        afterPage: '',
        grandparentQuestionId: parentQuestion.grandparentQuestionId,
      };
      // parent doesnt exist, this situation should not happen
      if (parentIdx === -1) {
        return;
      }
      // insert child question after parent question
      copied.splice(parentIdx + 1, 0, obj);
    });
    return _ensureAfterPage(copied, parentQuestionType, get(existingCQS[0], 'afterPage'));
  }
  // if (parentQuestionType === 'other') {
  // deprecated
  // console.warn('[275 other]');
  // return [
  //   ...existingCQS,
  //   ...nextCQS.map((question, idx) => {
  //     const id: string = idx === 0 ? parentQuestion.id : nextCQS[idx - 1].id;
  //     return {
  //       question,
  //       afterPage: _getAfterPageName(idx, id, parentQuestionType),
  //       parentQuestionId: parentQuestion.id,
  //     } as ChildQuestionSlider;
  //   }),
  // ];
  // }
  if (parentQuestionType === 'primarySubject') {
    const prepared: ChildQuestionSlider[] = nextCQS.map((cq) => ({
      afterPage: '',
      question: cq,
      parentQuestionId: parentQuestion.id,
    }));
    const allCQS = [...existingCQS, ...prepared];
    return _ensureAfterPage(allCQS, parentQuestionType, get(existingCQS[0], 'afterPage'));
  }
  return existingCQS;
};

/**
 *
 *
 * @param {ChildQuestionSlider[]} cqs
 * @return {*}  {ChildQuestionSlider[]}
 * make sure `afterPage` of first item is equal to previous head `afterPage`
 * make sure question id of rest items is equal to the if of question ahead of them.
 *
 */
const _ensureAfterPage = (
  cqs: ChildQuestionSlider[],
  parentQuestionType: ParentQuestionType,
  headAfterPage: string,
): ChildQuestionSlider[] => {
  if (['childOfChild', 'primarySubject'].includes(parentQuestionType)) {
    if (!headAfterPage) headAfterPage = composeSliderPageName(PRIMARY_SUBJECT_QS_PAGE_NAME);
    return cqs.map((item, index) => {
      let afterPage: string;
      if (index === 0) {
        afterPage = headAfterPage;
      } else {
        const prevItem = cqs[index - 1];
        afterPage = composeSliderPageName(PRIMARY_SUBJECT_CHILDREN_QS_PAGE_NAME, prevItem.question.id);
      }
      return {
        ...item,
        afterPage,
      };
    });
  }
  return cqs;
};

export const setAfterPagePropOnRemove = (
  existingCQS: ChildQuestionSlider[],
  idxesToRemove: number[],
  parentQuestionType: ParentQuestionType,
): ChildQuestionSlider[] => {
  if (['primarySubject', 'childOfChild'].includes(parentQuestionType)) {
    // for child questions of primary subject, need to set afterPage to the value of removed question
    const res = existingCQS.filter((cq, idx) => !idxesToRemove.includes(idx));
    return _ensureAfterPage(res, parentQuestionType, get(existingCQS[0], 'afterPage'));
  }
  // deprecated
  // if (parentQuestionType === 'other') {
  //   // remove questions when idx matched
  //   return existingCQS.filter((o, idx) => !idxesToRemove.includes(idx));
  // }
  return existingCQS;
};

export function useMountAnimation() {
  const [isAnimating, setIsAnimatingIn] = useState(false);

  useEffect(() => {
    setIsAnimatingIn(true);
  }, []);

  return isAnimating;
}

export const ANIMATION_SPEED = 300;

export /**
 *
 *
 * @param {OnboardingQuizQuestion[]} qs
 * @param {{ [key: string]: OnboardingQuizSelectedOption }} selectedOptions
 * @return {*}
 * return number of questions with answer
 */
const getCountOfFinishedQuestion = (
  qs: OnboardingQuizQuestion[],
  selectedOptions: { [key: string]: OnboardingQuizSelectedOption },
) => {
  const questionIdsWithAnswer = Object.keys(selectedOptions);
  return uniqBy(qs, 'id').filter((q) => questionIdsWithAnswer.includes(q.id)).length;
};

export const shouldShowNextButton = (
  primarySubjectQuestions: OnboardingQuizQuestion[],
  activePage: string,
  selectedOptions: { [key: string]: OnboardingQuizSelectedOption },
) => {
  // check if current page should show hide next button
  const shouldPageHide = shouldActivePageHideNextButton(activePage);
  // quiz completion page, hide next button
  if (activePage === composeSliderPageName(QUIZ_COMPLETION_PAGE_NAME, 'result')) {
    return false;
  }
  if (!shouldPageHide) {
    return true;
  }
  const [pageName, questionId] = decomposeSliderPageName(activePage);
  const questionIdsWithSelectedOption = Object.keys(selectedOptions);
  if (pageName === PRIMARY_SUBJECT_QS_PAGE_NAME) {
    // if on primary subject page
    const allPrimarySubjectsQuestionIds = primarySubjectQuestions.map((o) => o.id);
    const res = allPrimarySubjectsQuestionIds.every((id) => questionIdsWithSelectedOption.includes(id));
    return res;
  }
  const res = questionIdsWithSelectedOption.includes(questionId);
  return res;
};

export const sleep = (time: number) => new Promise((resolve) => setTimeout(resolve, time));

/**
 *
 *
 * @param {OnboardingQuizQuestion[]} qs
 * @return {*}
 * return questions with answer
 */
const _getQuestionsWithAnswer = (qs: OnboardingQuizQuestion[]) => {
  return qs
    .filter((q) => !!q.answer)
    .map((q) => {
      const option = q.options.find((o) => o.id === q.answer);
      return { question: q, option: option! };
    })
    .filter((o) => !!o.option);
};

/**
 *
 *
 * @param {('primarySubject' | 'other')} source
 * when user resume quiz, get all child questions
 */
const _recoverChildQuestions = (source: ParentQuestionType) => (questions: OnboardingQuizQuestion[]) => {
  let childQuestions: ChildQuestionSlider[] = [];
  const recover = (qs: OnboardingQuizQuestion[], _source: ParentQuestionType = source) => {
    const questionsWithAnswer = _getQuestionsWithAnswer(qs);
    questionsWithAnswer.forEach(({ question, option }) => {
      const nextCQ = getChildQuestions(option, question);
      if (nextCQ) {
        const nextCQS = setAfterPagePropOnAdd(childQuestions, nextCQ, question, _source);
        childQuestions = nextCQS;
        recover(nextCQ, 'childOfChild');
      }
    });
    return childQuestions;
  };
  return recover(questions);
};

export const recoverPrimaryChildQuestions = _recoverChildQuestions('primarySubject');

export const recoverOtherChildQuestions = _recoverChildQuestions('other');

export const recoverQuestionOptions = (
  allQuestions: OnboardingQuizQuestion[],
): { [key: string]: OnboardingQuizSelectedOption } => {
  return _getQuestionsWithAnswer(allQuestions).reduce((prev, curr) => {
    return {
      ...prev,
      [curr.question.id]: {
        selectedOptionId: curr.option.id,
        mode: 'single',
      },
    };
  }, {});
};

const _findPageWithUnansweredQuestions = (qs: OnboardingQuizQuestion[]) => {
  let questionId: string | null = null;
  for (let i = 0; i < qs.length; i++) {
    const q = qs[i];
    if (!q.answer) {
      questionId = q.id;
      break;
    }
  }
  return questionId;
};

export const recoverSliderActivePage = (
  personalityQs: OnboardingQuizQuestion[],
  primarySubjectQs: OnboardingQuizQuestion[],
  cqOfPrimarySubject: OnboardingQuizQuestion[],
  otherQuestions: OnboardingQuizQuestion[],
) => {
  const personality = _findPageWithUnansweredQuestions(personalityQs);
  if (!!personality) return composeSliderPageName(REST_QUESTIONS_PAGE_NAME, personality);
  const primary = _findPageWithUnansweredQuestions(primarySubjectQs);
  if (!!primary) return composeSliderPageName(PRIMARY_SUBJECT_QS_PAGE_NAME, '');
  const cqOfPrimary = _findPageWithUnansweredQuestions(cqOfPrimarySubject);
  if (!!cqOfPrimary) return composeSliderPageName(PRIMARY_SUBJECT_CHILDREN_QS_PAGE_NAME, cqOfPrimary);
  const other = _findPageWithUnansweredQuestions(otherQuestions);
  if (!!other) return composeSliderPageName(REST_QUESTIONS_PAGE_NAME, other);
};

export const flattenQuestions = (qs: OnboardingQuizQuestion[]) => {
  return qs
    .map((q) => {
      return [q, ...q.children];
    })
    .flat();
};
