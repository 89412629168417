import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './Container.scss';

export default function Container({ children, className }) {
  return <div className={classNames(css.container, className)}>{children}</div>;
}

Container.displayName = 'Container';

Container.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
};
