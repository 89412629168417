import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';
import { gql } from '@apollo/client';
import { createClient } from '../utils';

const { api } = getConfig();

const client = createClient(api.graphQLEndpoint);

const gradeResult = gql`
  fragment gradeResult on GradeResult {
    id
    type
    value
  }
`;

const grade = gql`
  ${gradeResult}
  fragment grade on Grade {
    id
    userId
    subjectId
    date
    results {
      ...gradeResult
    }
  }
`;

const getGradesByUserId = gql`
  ${grade}
  query getGradesByUserId($userId: String!) {
    getGradesByUserId(userId: $userId) {
      ...grade
    }
  }
`;

const createGrade = gql`
  ${grade}
  mutation createGrade($subjectId: String!, $userId: String!, $date: String!, $results: [NewGradeResult]) {
    createGrade(subjectId: $subjectId, userId: $userId, date: $date, results: $results) {
      ...grade
    }
  }
`;

const updateGrade = gql`
  ${grade}
  mutation updateGrade($id: String!, $date: String!, $results: [EditGradeResult]) {
    updateGrade(id: $id, date: $date, results: $results) {
      ...grade
    }
  }
`;

const deleteGrades = gql`
  mutation deleteGrades($ids: [String]!) {
    deleteGrades(ids: $ids)
  }
`;

export default {
  getGradesByUserId: async (userId) => {
    return client.query(getGradesByUserId, { userId });
  },

  createGrade: async (subjectId, userId, date, results) => {
    return client.query(createGrade, { subjectId, userId, date, results });
  },

  updateGrade: async (id, date, results) => {
    return client.query(updateGrade, { id, date, results });
  },

  deleteGrades: async (ids) => {
    return client.query(deleteGrades, { ids });
  },
};
