import {
  createBlockquotePlugin,
  createCodeBlockPlugin,
  createHeadingPlugin,
  createParagraphPlugin,
} from '@udecode/plate';
import { plateUI } from '../common';
import { createMyPlugins } from '../types/plateTypes';

export const basicElementsPlugins = createMyPlugins(
  [createBlockquotePlugin(), createCodeBlockPlugin(), createHeadingPlugin(), createParagraphPlugin()],
  {
    components: plateUI,
  },
);
