import moment from 'moment';
import { eventStatusTypes } from '@crimson-education/common-config';
import { getFullName } from './nameUtils';

export const lessonState = {
  UPCOMING: {
    statusName: 'Upcoming',
    icon: 'zmdi zmdi-calendar-alt',
    style: 'iconNeutral',
  },
  IN_PROGRESS: {
    statusName: 'In Progress',
    icon: 'zmdi zmdi-refresh-alt',
    style: 'iconNeutral',
  },
  CONFIRMED: {
    statusName: 'Confirmed',
    icon: 'zmdi zmdi-calendar-alt',
    style: 'iconNeutral',
  },
  COMPLETED: {
    statusName: 'Completed',
    icon: 'zmdi zmdi-check',
    style: 'iconDone',
  },
  ABSENT: {
    statusName: "Lesson didn't happen",
    icon: 'zmdi zmdi-accounts-outline',
    style: 'iconNeutral',
  },
  ABSENT_AWAITING_STUDENT_FEEDBACK: {
    statusName: 'Absent (awaiting student feedback)',
    icon: 'zmdi zmdi-accounts-outline',
    style: 'iconNeutral',
  },
  AWAITING_SESSION_REPORT: {
    statusName: 'Awaiting session report',
    icon: 'zmdi zmdi-alert-triangle',
    style: 'iconIncomplete',
  },
  AWAITING_STUDENT_FEEDBACK: {
    statusName: 'Completed (awaiting student feedback)',
    icon: 'zmdi zmdi-check',
    style: 'iconDone',
  },
  CANCELLED: {
    statusName: 'Cancelled',
    icon: 'zmdi zmdi-accounts-outline',
    style: 'iconNeutral',
  },
  PENALTY: {
    statusName: 'Penalty Session',
    icon: 'zmdi zmdi-check',
    style: 'iconDone',
  },
  UNKNOWN: {
    statusName: '-',
    icon: 'zmdi zmdi-alert-triangle',
    style: 'iconIncomplete',
  },
};

// Match a lesson state to the event status
export function getLessonState({ start, end, status }) {
  if (status === eventStatusTypes.ABSENT) return lessonState.ABSENT;

  if (status === eventStatusTypes.AWAITING_SESSION_REPORT) return lessonState.AWAITING_SESSION_REPORT;

  if (status === eventStatusTypes.AWAITING_STUDENT_FEEDBACK) return lessonState.AWAITING_STUDENT_FEEDBACK;

  if (status === eventStatusTypes.ABSENT_AWAITING_STUDENT_FEEDBACK) return lessonState.ABSENT_AWAITING_STUDENT_FEEDBACK;

  if (status === eventStatusTypes.COMPLETED) return lessonState.COMPLETED;

  if (status === eventStatusTypes.CONFIRMED && moment().isBefore(start)) return lessonState.UPCOMING;

  if (status === eventStatusTypes.CONFIRMED && moment().isAfter(start) && moment().isBefore(end))
    return lessonState.IN_PROGRESS;

  if (status === eventStatusTypes.CONFIRMED && moment().isAfter(end)) return lessonState.CONFIRMED;

  if (
    (status === eventStatusTypes.CANCELLED || status === eventStatusTypes.PENALTY) &&
    new Date(start) > new Date('2021-02-02T20:00:19.931Z')
  )
    return lessonState.PENALTY;

  if (status === eventStatusTypes.CANCELLED) return lessonState.CANCELLED;

  return lessonState.UNKNOWN;
}

// Process a lesson received from the backend.
export function processLesson(lesson) {
  const start = moment(lesson.start).toDate();
  const end = moment(lesson.end).toDate();
  return Object.assign({}, lesson, {
    start,
    end,
    tableStatus: getLessonState({ start, end, status: lesson.status }),
  });
}

// Process a list of lessons received from the backend.
export function processLessons(lessons) {
  return lessons.reduce((prev, lesson) => {
    prev[lesson.id] = processLesson(lesson);
    return prev;
  }, {});
}

// Retrieves a subject from a subjects list by id.
export function getSubject(subjects, subjectId) {
  return subjects.get(subjectId);
}

// Retrieves a client from a clients list by id.
export function getClient(clients, clientId) {
  return clients.find((client) => client.get('userId') === clientId);
}

// Get all lessons between a client and tutor for a specific subject.
export function getLessons(lessons, clientId, tutorId, subjectId) {
  const found = lessons.get(`${clientId}-${tutorId}-${subjectId}`);
  return found ? found.toList() : undefined;
}

// Get the number of completed lessons in a list of lessons.
export function getLessonsCompleted(lessons) {
  if (!lessons) return null;
  const completed = lessons.filter((lesson) => {
    const start = lesson.get('start');
    const end = lesson.get('end');
    const status = lesson.get('status');
    return getLessonState({ start, end, status }) === lessonState.COMPLETED;
  });
  return completed.size;
}

// Get a message representing the number of completed lessons.
export function getLessonsSummary(lessons) {
  const completed = getLessonsCompleted(lessons);

  // Don't show a message if no lessons have been completed.
  if (!completed) return '';

  const lessonsPlural = completed === 1 ? 'session' : 'sessions';
  return `${completed} ${lessonsPlural} completed`;
}

// Get a message representing the tutors of a subject.
export function getTutorSummary(subject) {
  const contracts = subject.toJS().contract;

  if (contracts && contracts.length) {
    const heading = contracts.length === 1 ? 'Current tutor: ' : 'Current tutors: ';

    const names = contracts.map((contract) => getFullName(contract.user.firstName, contract.user.lastName));

    return heading + names.join(', ');
  }

  return 'No tutors available';
}

// Get a message representing a person's role in a list of their lessons.
export function getRoleSummary(person, roleName) {
  let name;

  if (person && person.size) {
    name = getFullName(person.get('firstName'), person.get('lastName'));
  } else {
    name = `No ${roleName.toLowerCase()} assigned`;
  }

  return `${roleName}: ${name}`;
}

export function decimalHoursToHoursAndMinutes(decimalHours) {
  const truncatedHours = parseInt(decimalHours, 10);
  if ((!truncatedHours && truncatedHours !== 0) || decimalHours === 0) return { hours: 0, minutes: 0 };
  const hours = Math.trunc(decimalHours);
  const minutes = Math.trunc((decimalHours - hours) * 60);
  return { hours, minutes };
}

export function buildDynamicDurationString(number, units) {
  const hours = number.hours !== 0 ? `${Math.abs(number.hours)}${units[0]}` : '';
  const minutes = number.minutes !== 0 ? `${Math.abs(number.minutes)}${units[1]}` : '';
  const sign = number.hours < 0 || number.minutes < 0 ? '-' : '';
  const space = hours && minutes ? ' ' : '';
  return `${sign}${hours}${space}${minutes}`;
}
