import moment from 'moment';
import attendanceStatus from 'constants/attendanceStatus';

export function validateReason(changedTime, adjustedReason) {
  return !changedTime || adjustedReason;
}

// Start time must always be before end time.
export function validateTime(start, end) {
  return start.isBefore(end);
}

// Extract variables from provided props and then perform validation.
export function validateTimeAndReason(props) {
  const { adjustedStart, adjustedEnd, adjustedReason, booking } = props;
  const originalStart = booking.get('start');
  const originalEnd = booking.get('end');

  let disputedStart;
  let disputedEnd;
  if (booking.getIn(['attendance', 'status']) === attendanceStatus.DISAGREE) {
    disputedStart = booking.getIn(['attendance', 'start']);
    disputedEnd = booking.getIn(['attendance', 'end']);
  }

  const newStart = moment(disputedStart || originalStart);
  let newEnd = moment(disputedEnd || originalEnd);

  if (adjustedStart) {
    newStart.set('hour', adjustedStart.hour).set('minute', adjustedStart.minute);
  }
  if (adjustedEnd) {
    newEnd = adjustedEnd.time;
  }

  const originalTime = newStart.isSame(originalStart) && newEnd.isSame(originalEnd);
  const changedTime = (adjustedStart || adjustedEnd) && !originalTime;

  // Ensure that if we have adjusted the time, we provide a reason.
  const reasonValidation = validateReason(changedTime, adjustedReason);

  // Ensure that if we have adjusted the time, it is a valid interval.
  const timeValidation = validateTime(newStart, newEnd);

  return {
    reasonValidation,
    timeValidation,
  };
}
