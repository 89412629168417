import React from 'react';
import { components } from 'react-select';

import Search from '@material-ui/icons/Search';
import ExpandMore from '@material-ui/icons/ExpandMore';
import fontColour from 'components/atoms/typography/fontColour.scss';
import css from './style.scss';

export default function DropdownIndicator(componentProps) {
  const { selectProps, searchIcon, searchIconStyle } = componentProps;
  const value = selectProps.value;

  const iconProps = {
    className: selectProps.isDisabled ? fontColour.disabled : fontColour.primary,
    style: {
      height: selectProps.isSearchable ? '2rem' : '2.4rem',
      width: selectProps.isSearchable ? '2rem' : '2.4rem',
      transform: selectProps.menuIsOpen && !selectProps.isSearchable ? 'rotate(180deg)' : 'none',
      transition: 'transform ease-in-out 100ms',
      ...searchIconStyle,
    },
  };

  if (!selectProps.isClearable || !value || (value && Array.isArray(value) && !value.length)) {
    const SearchIcon = searchIcon || Search;
    return (
      <components.DropdownIndicator {...componentProps} className={css.dropdownIndicator}>
        {selectProps.isSearchable ? <SearchIcon {...iconProps} /> : <ExpandMore {...iconProps} />}
      </components.DropdownIndicator>
    );
  }

  return null;
}
