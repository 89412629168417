import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/molecules/Button';
import Header from 'components/molecules/Header';
import LoadingBar from 'components/molecules/LoadingBar';
import GradesTable from 'components/organisms/GradesTable';
import DecileRankTable from 'components/organisms/DecileRankTable';
import GradesModal from 'components/organisms/GradesModal';
import GradeDecileRankModal from 'components/organisms/GradeDecileRankModal';
import ConfirmationModal from 'components/molecules/ConfirmationModal';
import EmptyState from './EmptyState';
import { curriculumTemplates, resultTypesTemplates, gradeTables } from './configuration';
import styles from './styles.scss';

export default class Grades extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      isModalOpen: false,
      gradeId: undefined,
      subjectId: undefined,
      deleteConfirmGrades: [],
      isDecileModalOpen: false,
      decileId: undefined,
      deleteType: '',
    };

    this.onClose = this.onClose.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.onNewOverallGrade = this.onNewOverallGrade.bind(this);
    this.onNew = this.onNew.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.onEditDecile = this.onEditDecile.bind(this);
    this.onCloseDecileModal = this.onCloseDecileModal.bind(this);
    this.onNewDecile = this.onNewDecile.bind(this);
  }

  componentDidMount() {
    const { canView } = this.props;

    if (canView) this.fetchData();
  }

  onClose() {
    this.setState({
      isModalOpen: false,
      gradeId: undefined,
      subjectId: undefined,
      deleteConfirmGrades: [],
    });
  }

  onEdit(gradeId) {
    this.setState({
      isModalOpen: true,
      gradeId,
    });
  }

  onEditDecile(decileId) {
    this.props.updateSelectedAcademic(decileId);
    this.setState({
      isDecileModalOpen: true,
    });
  }

  onCloseDecileModal() {
    this.props.updateSelectedAcademic(null);
    this.setState({
      isDecileModalOpen: false,
      decileId: undefined,
    });
  }

  onNewOverallGrade(subjectId) {
    this.setState({
      isModalOpen: true,
      gradeId: null,
      subjectId,
    });
  }

  onNew() {
    this.setState({
      isModalOpen: true,
    });
  }

  onNewDecile() {
    this.setState({
      isDecileModalOpen: true,
    });
  }

  onDelete(ids, type) {
    this.onClose();

    this.setState({
      deleteConfirmGrades: ids,
      deleteType: type,
    });
  }

  onSave(grade) {
    const { updateGrade } = this.props;
    const { id, date, results } = grade;

    updateGrade(id, date, results);
    this.onClose();
  }

  onSubmit(grade) {
    const { createGrade, studentId } = this.props;
    const { subjectId, date, results } = grade;

    createGrade(subjectId, studentId, date, results);
    this.onClose();
  }

  confirmDelete() {
    const { deleteGrades, deleteAcademics } = this.props;
    const { deleteConfirmGrades, deleteType } = this.state;
    if (deleteType === 'decile') {
      deleteAcademics(deleteConfirmGrades);
    } else {
      deleteGrades(deleteConfirmGrades);
    }

    this.onClose();
  }

  async fetchData() {
    const { studentId, fetchGradesByStudentId, fetchSubjectTree, fetchAcademicsByUserId } = this.props;

    await Promise.all([fetchSubjectTree(), fetchGradesByStudentId(studentId), fetchAcademicsByUserId(studentId)]);

    this.setState({
      isLoaded: true,
    });
  }

  render() {
    const {
      canView,
      canEdit,
      showGrades,
      getGradesByCurriculum,
      getSubjectCategoryById,
      studentView,
      decileRanks,
    } = this.props;
    const { isModalOpen, gradeId, subjectId, deleteConfirmGrades, isDecileModalOpen } = this.state;

    // Only show grades for students.
    if (!showGrades) return null;

    // Check for missing permission.
    if (!canView) return null;

    // Check for intermediate states.
    if (!this.state.isLoaded) return <LoadingBar />;

    // Create empty state.
    const emptyState = <EmptyState canEdit={canEdit} onNewGrade={this.onNew} onNewDecile={this.onNewDecile} />;

    const groupedGrades = getGradesByCurriculum(gradeTables);
    const gradesTables = Object.keys(groupedGrades).map((templateId) => {
      const curriculum = getSubjectCategoryById(templateId);
      return (
        <GradesTable
          canEdit={canEdit}
          key={templateId}
          curriculum={curriculum}
          grades={groupedGrades[templateId]}
          onNewGrade={this.onNew}
          onEditGrade={this.onEdit}
          onNewOverallGrade={this.onNewOverallGrade}
          template={curriculumTemplates[templateId]}
          resultTypesTemplates={resultTypesTemplates}
          deleteGrades={this.onDelete}
        />
      );
    });

    const decileRankTable = (
      <DecileRankTable
        canEdit={canEdit}
        decileRanks={decileRanks}
        onNewDecile={this.onNewDecile}
        onEditDecile={this.onEditDecile}
        onNewOverallGrade={this.onNewOverallGrade}
        deleteDeciles={this.onDelete}
      />
    );

    const addNewButtons = (
      <div>
        <Button
          className={styles.outlineButton}
          buttonTextStyle={styles.buttonText}
          color="secondary"
          onClick={() => this.setState({ isDecileModalOpen: true })}
        >
          Add Decile Rank
        </Button>
        <Button className={styles.newButton} onClick={() => this.setState({ isModalOpen: true })}>
          Add new grade
        </Button>
      </div>
    );
    const gradesTablesComponent = (
      <div className={styles.tables}>
        {!studentView && canEdit && addNewButtons}
        {gradesTables}
        {decileRanks.length > 0 ? decileRankTable : null}
      </div>
    );
    const gradesModal = (
      <GradesModal
        gradeId={gradeId}
        subjectId={subjectId}
        isOpen={isModalOpen}
        onClose={this.onClose}
        onSubmit={this.onSubmit}
        onSave={this.onSave}
        onDelete={this.onDelete}
        curriculumTemplates={curriculumTemplates}
        resultTypesTemplates={resultTypesTemplates}
      />
    );

    const deleteConfirmGradesModal = (
      <ConfirmationModal
        onConfirm={this.confirmDelete}
        onCancel={this.onClose}
        isOpen={deleteConfirmGrades.length > 0}
        actionText="Delete"
        modalBodyText={`Are you sure you want to delete ${
          deleteConfirmGrades.length === 1 ? 'this' : deleteConfirmGrades.length
        } grade${deleteConfirmGrades.length > 1 ? 's' : ''}?`}
      />
    );

    const decileModal = (
      <GradeDecileRankModal isOpen={isDecileModalOpen} onClose={this.onCloseDecileModal} onDelete={this.onDelete} />
    );

    return (
      <div data-ga-category="Grades" className={styles.container}>
        <div className={!studentView ? styles.extraPadding : ''}>
          {studentView && (
            <Header
              title="Grades"
              isBackButtonVisible={false}
              actionButtons={[
                {
                  buttonText: 'Add Decile Rank',
                  buttonAction: () => this.setState({ isDecileModalOpen: true }),
                },
                {
                  buttonText: 'Add new grade',
                  buttonAction: () => this.setState({ isModalOpen: true }),
                },
              ]}
              isBorderVisible
            />
          )}
          <div className={studentView ? styles.extraPadding : undefined}>
            {gradesTables.length || decileRanks.length ? gradesTablesComponent : emptyState}
            {gradesModal}
            {isDecileModalOpen ? decileModal : null}
            {deleteConfirmGradesModal}
          </div>
        </div>
      </div>
    );
  }
}

Grades.propTypes = {
  canView: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool.isRequired,
  showGrades: PropTypes.bool.isRequired,
  createGrade: PropTypes.func.isRequired,
  updateGrade: PropTypes.func.isRequired,
  deleteGrades: PropTypes.func.isRequired,
  studentId: PropTypes.string.isRequired,
  fetchGradesByStudentId: PropTypes.func.isRequired,
  fetchSubjectTree: PropTypes.func.isRequired,
  getSubjectCategoryById: PropTypes.func.isRequired,
  getGradesByCurriculum: PropTypes.func.isRequired,
  studentView: PropTypes.bool.isRequired,
  fetchAcademicsByUserId: PropTypes.func.isRequired,
  decileRanks: PropTypes.array,
  updateSelectedAcademic: PropTypes.func,
  deleteAcademics: PropTypes.func,
};

Grades.defaultProps = {
  studentView: false,
};
