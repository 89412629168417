import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import fontFamily from './fontFamily.scss';
import font from './font.scss';
import fontColour from './fontColour.scss';

export default function Caption(props) {
  return (
    <div
      className={classNames(
        fontFamily.regular,
        { [fontFamily.bold]: props.bold },
        font.caption,
        fontColour.secondary,
        props.className,
      )}
      style={props.style}
    >
      {props.children}
    </div>
  );
}

Caption.defaultProps = {
  className: '',
  style: {},
};

Caption.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  bold: PropTypes.bool,
};
