import React, { Component } from 'react';
import { Picker } from 'emoji-mart';
import onClickOutside from 'react-onclickoutside';
import PropTypes from 'prop-types';

import 'emoji-mart/css/emoji-mart.css';

class EmojiPicker extends Component {
  constructor(props) {
    super(props);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  handleClickOutside(evt) {
    evt.preventDefault();
    setTimeout(() => this.props.closePicker(), 150);
  }

  render() {
    return (
      <Picker
        title="Pick your emoji…"
        emoji="hand"
        style={{ position: 'absolute', top: '-42.3rem' }}
        onSelect={(emoji) => {
          this.props.onSelectEmoji(emoji);
          this.props.textArea.focus();
        }}
        color="#aa1e23"
      />
    );
  }
}

export default onClickOutside(EmojiPicker);

EmojiPicker.propTypes = {
  closePicker: PropTypes.func.isRequired,
  onSelectEmoji: PropTypes.func.isRequired,
  textArea: PropTypes.any,
};
