import { TabPanel as ReactTabPanel } from 'react-tabs';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash/omit';

import css from './style.scss';

export default function TabPanel(props) {
  const passThroughProps = omit(props, Object.keys(TabPanel.propTypes));
  return (
    <ReactTabPanel
      {...passThroughProps}
      className={classNames(css.tabPanel, props.className, {
        [css.compact]: props.compactMode,
      })}
      selectedClassName={css.tabPanelSelected}
    />
  );
}

TabPanel.propTypes = {
  compactMode: PropTypes.bool,
  className: PropTypes.string,
};

TabPanel.tabsRole = 'TabPanel';
