import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import font from '../../atoms/typography/font.scss';
import css from './style.scss';

export default function Banner(props) {
  return (
    <div className={css.banner} style={{ backgroundColor: props.backgroundColor, color: props.color }}>
      <div className={css.bannerContent}>
        {props.icon}
        <span className={classnames(font.body, css.bannerText)}>{props.text}</span>
      </div>
    </div>
  );
}

Banner.defaultProps = {
  backgroundColor: '#00a896',
  color: '#ffffff',
};

Banner.propTypes = {
  icon: PropTypes.object,
  text: PropTypes.string,
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
};
