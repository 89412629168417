import { createSelector } from 'reselect';
import { getCurrentUserId } from 'selectors/user';

const getState = (state) => state.get('billingInfo');

export const getBillingCountries = createSelector(getState, (state) => state.get('countries').toJS());

export const getUserBilling = createSelector([getState, getCurrentUserId], (state, currentUserId) => {
  const user = state.getIn(['users', currentUserId]);
  return user && user.toJS();
});

export const getUserBillingByUserId = (userId) =>
  createSelector([getState], (state) => {
    const user = state.getIn(['users', userId]);
    return user && user.toJS();
  });

export const getCurrency = createSelector([getState, getCurrentUserId], (state, currentUserId) => {
  const billingCountries = state.get('countries');
  const userBilling = state.getIn(['users', currentUserId]);
  if (userBilling) {
    const billingCountryId = userBilling.get('billingCountryId');
    const selectedCountry = billingCountries.find((country) => country.get('id') === billingCountryId);
    return selectedCountry ? selectedCountry.get('currency') : 'NZD';
  }
  return 'NZD';
});

export const getCurrencyByUserId = (userId) =>
  createSelector([getState], (state) => {
    const billingCountries = state.get('countries');
    const userBilling = state.getIn(['users', userId]);
    if (userBilling) {
      const billingCountryId = userBilling.get('billingCountryId');
      const selectedCountry = billingCountries.find((country) => country.get('id') === billingCountryId);
      return selectedCountry ? selectedCountry.get('currency') : 'NZD';
    }
    return 'NZD';
  });

export const getPayRatesBySubjectId = (subjectId) =>
  createSelector([getState], (state) => {
    return state
      .get('users')
      .map((user) => {
        const payRate = user.getIn(['userSubjectPayRate', subjectId]);
        return payRate && payRate.toJS();
      })
      .filter((rate) => !!rate)
      .map((rate) => rate.rateInUSD)
      .toArray();
  });

export const getTutorPayRateBySubjectId = (userId, subjectId) =>
  createSelector([getState], (state) => {
    const rate = state.getIn(['users', userId, 'userSubjectPayRate', subjectId]);
    if (rate) {
      return rate.toJS();
    }
    return null;
  });
