import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { SubHeader, PageHeader, Body } from 'components/atoms/typography';
import Button from 'components/molecules/Button';
import BackButton from 'components/molecules/BackButton';
import IconButton from 'components/molecules/IconButton';
import { trimSpecialCharacters } from 'utils/utils';
import textBehaviour from 'components/atoms/behaviours/text.scss';
import * as Icons from 'components/atoms/icons';

import css from './style.scss';

export default function Header(props) {
  const {
    className,
    title,
    subtitles,
    isBackButtonVisible,
    isBackButtonVisibleMobile,
    isBorderVisible,
    backButtonText,
    backButtonLink,
    actionButtons,
    isSubHeader,
    children,
  } = props;

  return (
    <React.Fragment>
      <header className={classNames(css.header, { [css.border]: isBorderVisible }, className)}>
        <div className={classNames(css.navigation, css.headerRow)}>
          {(isBackButtonVisible || isBackButtonVisibleMobile) && (
            <BackButton
              backButtonText={backButtonText}
              backButtonLink={backButtonLink}
              className={classNames(css.backButton, isBackButtonVisibleMobile && css.backButtonMobile)}
              dataTestId={`headerNavBackButton_${trimSpecialCharacters(backButtonText || 'Back')}`}
            />
          )}
        </div>

        <div className={classNames(css.main, css.headerRow)}>
          {isSubHeader ? (
            <SubHeader className={css.title}>{title}</SubHeader>
          ) : (
            <PageHeader className={css.title}>{title}</PageHeader>
          )}
        </div>

        <div className={classNames(css.actions, css.headerRow)}>
          {children}

          {actionButtons.length > 0 &&
            actionButtons.map((button, index) => {
              const renderButton = (
                <div key={index}>
                  <Button
                    dataTestId={`headerButton_${trimSpecialCharacters(button.buttonText)}`}
                    disabled={button.isButtonDisabled}
                    onClick={button.buttonLink ? undefined : button.buttonAction}
                    secondary={button.isButtonSecondary}
                    small={button.isButtonSmall}
                    className={classNames(css.actionButtons, { [css.hideOnMobile]: button.icon }, button.className)}
                  >
                    {button.buttonText}
                  </Button>
                </div>
              );
              if (!button.buttonLink) {
                return renderButton;
              }
              return (
                <Link to={button.buttonLink} key={index}>
                  {renderButton}
                </Link>
              );
            })}
        </div>

        {subtitles.length > 0 && (
          <div className={css.subtitles}>
            {subtitles.map((subtitle, index) => {
              if (typeof subtitle === 'string') {
                return (
                  <Body
                    key={index}
                    bold
                    className={classNames(css.subtitle, textBehaviour.truncateText, {
                      [subtitle.className]: subtitle.className,
                    })}
                  >
                    {subtitle}
                  </Body>
                );
              }
              const Icon = subtitle.icon && Icons[subtitle.icon];
              return (
                <Body
                  key={index}
                  bold
                  className={classNames(css.subtitle, textBehaviour.truncateText, css.subtitleWithIcon, {
                    [subtitle.className]: subtitle.className,
                  })}
                >
                  {subtitle.icon && (
                    <Icon
                      className={classNames(css.iconButton, {
                        [subtitle.iconClass]: subtitle.iconClass,
                      })}
                      style={{ height: '1.5rem', width: '1.5rem' }}
                    />
                  )}
                  {subtitle.text}
                </Body>
              );
            })}
          </div>
        )}
      </header>
      {actionButtons
        .filter((x) => x.icon)
        .map((x, i) => (
          <IconButton
            key={i}
            className={classNames(css.mobileButton, x.className)}
            icon={x.icon}
            onClick={x.buttonAction}
            iconSize={x.iconSize || { height: '1.8rem', width: '1.8rem' }}
          />
        ))}
    </React.Fragment>
  );
}

Header.defaultProps = {
  isBackButtonVisible: false,
  actionButtons: [],
  subtitles: [],
  isBorderVisible: true,
  isSubHeader: false,
  className: '',
};

Header.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isSubHeader: PropTypes.bool,
  subtitles: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        text: PropTypes.string,
        icon: PropTypes.string,
        iconClass: PropTypes.string,
        className: PropTypes.string,
      }),
    ]),
  ),
  isBackButtonVisible: PropTypes.bool,
  isBackButtonVisibleMobile: PropTypes.bool,
  backButtonText: PropTypes.string,
  backButtonLink: PropTypes.string,
  actionButtons: PropTypes.arrayOf(
    PropTypes.shape({
      buttonText: PropTypes.string,
      buttonAction: PropTypes.func,
      buttonLink: PropTypes.string,
      isButtonSmall: PropTypes.bool,
      isButtonDisabled: PropTypes.bool,
      isButtonSecondary: PropTypes.bool,
      dataTestId: PropTypes.string,
      icon: PropTypes.string,
      iconSize: PropTypes.object,
      className: PropTypes.string,
    }),
  ),
  isBorderVisible: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.element,
};
