import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import { getCurrentUserJS } from 'selectors/user';
import * as zoid from 'zoid/dist/zoid.frameworks.js';
import { getEnvironmentConfig } from '@crimson-education/common-config';
import { history } from '../../../app';
import { getBearer, authorize } from '../../../utils/auth/auth';
import { featureSwitches, getFeatureSwitchesAsString } from '../../../featureSwitches';
import _ from 'lodash';

const { environment } = getEnvironmentConfig();

const environmentHostnames = {
  local: 'http://localhost:3012',
  staging: 'https://staging.resources.crimsoneducation.org',
  production: 'https://resources.crimsoneducation.org',
};

const resourceDomain = environmentHostnames[featureSwitches.RESOURCE_CONNECT_LOCAL() ? 'local' : environment];
// store all the registered event callbacks.
const eventEmitters = {};
// store the resource current url
let resourceUrl;
// tag the url change original source from iframe of navbar.
// if it's true, the iframe will not refresh
let innerChange = false;

const ResourceComponent = zoid.create({
  tag: 'resource-component',
  url: ({ props }) => {
    if (props && props.history) {
      const { location } = props.history;
      const { pathname } = location;
      return resourceDomain + pathname.replace('/resource', '') + location.search;
    }
    return resourceDomain;
  },
  dimensions: {
    width: '100%',
    height: '99vh',
  },
  props: {
    token: {
      type: 'string',
      isRequired: false,
    },
    featureSwitches: {
      type: 'object',
      isRequired: false,
    },
    userId: {
      type: 'string',
      isRequired: true,
    },
    authorize: {
      type: 'function',
      isRequired: true,
    },
  },
});

const ResourceReactComponent = ResourceComponent.driver('react', {
  React,
  ReactDOM,
});

// registerEventEmitter
// a function to add more callbacks from iframe
const registerEventEmitter = (eventName, callback) => {
  eventEmitters[eventName] = callback;
};

const unregisterEventEmitter = (eventName) => {
  delete eventEmitters[eventName];
};

// invoke the event callbacks
// conditionally, if the innerChange is true, we should do something special
const performEvent = (eventName, data) => {
  if (innerChange) {
    innerChange = false;
    return;
  }
  eventEmitters[eventName] && eventEmitters[eventName](data);
};

const onMessage = ({ event, data }) => {
  // accept the route event from the url routing change of the iframe.
  if (event === 'route') {
    if (resourceUrl === data) {
      return;
    }
    innerChange = true;
    resourceUrl = data;
    history.push(`/resource${data}`);
  }
};

function Resource() {
  const user = useSelector(getCurrentUserJS);

  // fix about the navbar button doesn't work issue.
  useEffect(() => {
    // console.log('current', history.location.pathname);
    performEvent('refresh', `${history.location.pathname}${history.location.search}`);
  });

  return (
    <ResourceReactComponent
      registerEventEmitter={registerEventEmitter}
      unregisterEventEmitter={unregisterEventEmitter}
      history={history}
      onMessage={onMessage}
      token={getBearer() || ''}
      authorize={authorize}
      featureSwitches={JSON.parse(getFeatureSwitchesAsString())}
      userId={user.userId}
      user={_.pick(user, ['userId', 'firstName', 'lastName', 'email'])}
    />
  );
}

export default Resource;
