/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/molecules/Modal';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import classNames from 'classnames';

import css from './styles.scss';
import { handleEnter } from '../../../../utils/accessibility';

export default function CalendarSyncModal(props) {
  const { isOpen, calendarList, onCancel, showUnbindPopup, changAccountModal, openSyncErroModal, syncCal } = props;
  // console.log(`calendarList ${JSON.stringify(calendarList)}`);
  const unbindAccount = (calSource) => {
    onCancel();
    showUnbindPopup(calSource);
  };

  const changeAccount = (calSource) => {
    onCancel();
    changAccountModal(calSource);
  };

  const showSyncErroModal = (calSource) => {
    onCancel();
    openSyncErroModal(calSource);
  };
  const unSyncedView = (cal) => {
    const calSourceName = cal.sourceName;
    const calSource = cal.source;
    const imgSrc =
      calSource === 'google' ? '/static/iCalSyncGuidePic/google.svg' : '/static/iCalSyncGuidePic/apple.svg';
    return (
      <div
        role="button"
        tabIndex={0}
        className={classNames(css.calendarDetail, css.cursor)}
        onClick={() => syncCal(calSource)}
        onKeyDown={handleEnter(() => syncCal(calSource))}
      >
        <div>
          <img className={css.calIcon} src={imgSrc} alt="cal-icon" />
          {calSourceName}
        </div>
        <div>
          <ChevronRightIcon fontSize="small" />
        </div>
      </div>
    );
  };
  const syncedView = (cal) => {
    const calSourceName = cal.sourceName;
    const calSource = cal.source;
    const syncStatus = cal.syncStatus;

    const calAccount = cal.calendarAccount;
    const imgSrc =
      calSource === 'google' ? '/static/iCalSyncGuidePic/google.svg' : '/static/iCalSyncGuidePic/apple.svg';
    return (
      <div>
        <div className={css.calendarDetail}>
          <div>
            <img className={css.calIcon} src={imgSrc} alt="cal-icon" />
            {calSourceName}
          </div>
          {syncStatus ? (
            <div className={css.syncedIcon}>
              <CheckCircleOutlineRoundedIcon className={css.syncedIcon} fontSize="small" />
              Synced
            </div>
          ) : (
            <div
              role="button"
              tabIndex={0}
              className={css.syncedErrorIcon}
              onClick={() => showSyncErroModal(calSource)}
              onKeyDown={handleEnter(() => showSyncErroModal(calSource))}
            >
              <ErrorOutlineIcon className={css.syncedErrorIcon} fontSize="small" />
              Sync error
            </div>
          )}
        </div>
        <div className={css.calendarSyncDetail}>
          <div>
            <img className={css.calIcon} src="/static/iCalSyncGuidePic/user.svg" alt="user-icon" />
            {calAccount}
          </div>
          <div className={css.syncedCalOperateArea}>
            <a
              role="button"
              tabIndex={0}
              className={css.operateCalButton}
              onClick={() => unbindAccount(calSource)}
              onKeyDown={handleEnter(() => unbindAccount(calSource))}
            >
              Unbind
            </a>
          </div>
        </div>
      </div>
    );
  };
  const calendarListView = calendarList.map((cal, index) => {
    return (
      <li className={css.calendarItem} key={index}>
        {cal.calendarAccount ? syncedView(cal) : unSyncedView(cal)}
      </li>
    );
  });
  return (
    <Modal isOpen={isOpen} title="Calendar Sync" onClose={onCancel}>
      <div className={css.front}>
        <div className={css.modalBody}>
          <div className={css.bookingWithWrapper}>
            <div className={css.subtitle}>Sync with your calendar</div>
            <div className={css.subtext}>This will allow you to check different calendar events in one screen.</div>
            <ul className={css.calendarUl}>{calendarListView}</ul>
          </div>
        </div>
      </div>
    </Modal>
  );
}

CalendarSyncModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  showUnbindPopup: PropTypes.func.isRequired,
  changAccountModal: PropTypes.func.isRequired,
  calendarList: PropTypes.array.isRequired,
  openSyncErroModal: PropTypes.func.isRequired,
  syncCal: PropTypes.func.isRequired,
};
