import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import {
  updateSelectedSpecificDay,
  toggleCalendarView,
  nextDay,
  prevDay,
  goToday,
  DESKTOP_DAYS,
  MOBILE_DAYS,
  updateDayCalendarViewHidden,
} from 'ducks/meta';
import { getMetaItem, getCalendarDays } from 'selectors/meta';
import componentKeys from 'constants/componentKeys';
import { withAppContext } from 'components/enhancers/AppContext';

import CalendarHeader from './CalendarHeader';

function Container(props) {
  const { calendarDays, toggleCalendarView, app } = props;
  if (!calendarDays) {
    const days = app.isMobile ? DESKTOP_DAYS : MOBILE_DAYS; // Inverted because it is a toggle, not set
    toggleCalendarView(days);
    return null;
  }
  return <CalendarHeader {...props} />;
}

Container.propTypes = {
  app: PropTypes.object,
  calendarDays: PropTypes.number,
  toggleCalendarView: PropTypes.func.isRequired,
};

export default compose(
  withAppContext,
  connect(
    (state) => ({
      selectedDay: getMetaItem(componentKeys.SELECTED_SPECIFIC_DAY)(state),
      calendarDays: getCalendarDays(state),
    }),
    (dispatch) => ({
      updateSelectedSpecificDay: (day) => dispatch(updateSelectedSpecificDay(day)),
      toggleCalendarView: (currentView) => dispatch(toggleCalendarView(currentView)),
      nextDay: () => dispatch(nextDay()),
      prevDay: () => dispatch(prevDay()),
      goToday: () => dispatch(goToday()),
      updateDayCalendarViewHidden: (isHidden) => dispatch(updateDayCalendarViewHidden(isHidden)),
    }),
  ),
)(Container);
