import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import css from './profileItem.scss';

export default function SubItem(props) {
  const { name, item, link, className, dataGaLabel } = props;
  return (
    item && (
      <div className={classnames(css.subItem, className)}>
        {name && <div className={css.subName}>{name}</div>}
        <div className={css.subText}>
          {link ? (
            <Link data-ga-label={dataGaLabel} to={link}>
              {item}
            </Link>
          ) : (
            item
          )}
        </div>
      </div>
    )
  );
}

SubItem.displayName = 'SubItem';

SubItem.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  link: PropTypes.string,
  dataGaLabel: PropTypes.string,
  item: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
