import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import css from './styles.scss';
import { handleEnter } from '../../../utils/accessibility';

function renderPageNumbers(totalPages, currentPage, onPageClick) {
  const pages = [];
  pages.push(
    <div
      role="button"
      tabIndex={0}
      data-ga-label="Page Number"
      key={1}
      className={classnames(css.pageNumber, { [css.currentPage]: currentPage === 1 })}
      onClick={() => onPageClick(1)}
      onKeyDown={handleEnter(() => onPageClick(1))}
    >
      1
    </div>,
  ); // We always have the first page

  const previousPage = currentPage - 1;

  if (previousPage > 2) {
    pages.push(
      <div key="ellipsis-1" className={css.ellipsis}>
        ...
      </div>,
    );
  }

  if (previousPage > 1) {
    pages.push(
      <div
        role="button"
        tabIndex={0}
        data-ga-label="Page Number"
        key={previousPage}
        className={classnames(css.pageNumber)}
        onClick={() => onPageClick(previousPage)}
        onKeyDown={handleEnter(() => onPageClick(previousPage))}
      >
        {previousPage}
      </div>,
    );
  }

  if (currentPage > 1 && currentPage < totalPages) {
    pages.push(
      <div key={currentPage} className={classnames(css.pageNumber, css.currentPage)}>
        {currentPage}
      </div>,
    );
  }

  const nextPage = currentPage + 1;

  if (nextPage < totalPages) {
    pages.push(
      <div
        role="button"
        tabIndex={0}
        data-ga-label="Page Number"
        key={nextPage}
        className={classnames(css.pageNumber)}
        onClick={() => onPageClick(nextPage)}
        onKeyDown={handleEnter(() => onPageClick(nextPage))}
      >
        {nextPage}
      </div>,
    );
  }

  if (nextPage < totalPages - 1 && nextPage + 1 > 2) {
    pages.push(
      <div key="ellipsis-2" className={css.ellipsis}>
        ...
      </div>,
    );
  }

  if (totalPages > 1) {
    pages.push(
      <div
        role="button"
        tabIndex={0}
        data-ga-label="Page Number"
        key={totalPages}
        className={classnames(css.pageNumber, { [css.currentPage]: currentPage === totalPages })}
        onClick={() => onPageClick(totalPages)}
        onKeyDown={handleEnter(() => onPageClick(totalPages))}
      >
        {totalPages}
      </div>,
    ); // We always have the last page
  }
  return pages;
}

export default function Paginator({ totalPages, currentPage, onPageClick, className }) {
  if (totalPages === 0) {
    return null;
  }
  if (currentPage < 0 || currentPage > totalPages) {
    return null;
  }

  return (
    <div className={classnames(css.paginator, className)}>
      {totalPages > 1 && (
        <div
          role="button"
          tabIndex={0}
          className={classnames(css.previous, { [css.disabled]: currentPage === 1 })}
          onClick={() => {
            if (currentPage !== 1) {
              onPageClick(currentPage - 1);
            }
          }}
          onKeyDown={handleEnter(() => {
            if (currentPage !== 1) {
              onPageClick(currentPage - 1);
            }
          })}
        >
          &lt;
        </div>
      )}
      {renderPageNumbers(totalPages, currentPage, onPageClick)}
      {totalPages > 1 && (
        <div
          role="button"
          tabIndex={0}
          className={classnames(css.next, { [css.disabled]: currentPage === totalPages })}
          onClick={() => {
            if (currentPage !== totalPages) {
              onPageClick(currentPage + 1);
            }
          }}
          onKeyDown={handleEnter(() => {
            if (currentPage !== totalPages) {
              onPageClick(currentPage + 1);
            }
          })}
        >
          &gt;
        </div>
      )}
    </div>
  );
}

Paginator.displayName = 'Paginator';

Paginator.propTypes = {
  totalPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};
