import styled from 'styled-components';

export const TextHeader = styled.th`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #a9acc0;
  text-align: left;
  padding-right: 10px;
`;

export const BaseCell = styled.td`
  min-height: 60px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
`;

export const TextCell = styled(BaseCell)`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  color: #1d1e2b;
`;

export const GroupTag = styled.div<{ $tintColor: string }>`
  color: ${({ $tintColor }) => $tintColor};
  background: ${({ $tintColor }) => $tintColor + '26'};
  padding: 4px 16px;
  height: 32px;
`;

export const StyledTableRow = styled.tr`
  min-height: 60px;
  border: 0px solid rgb(224, 224, 224);
  border-bottom-width: 1px;
  background: #ffffff;
  padding: 10px 15px;
  box-sizing: border-box;
`;

export const StyledUserName = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  cursor: pointer;
  color: #000000;
  transition: color 0.4s;
  &:hover {
    color: #464ac9;
  }
`;

export const RegionText = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #1d1e2b;
`;

export const StyledIcon = styled.div`
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  svg {
    font-size: 23px;
    color: #73747d;
  }
`;

export const ServiceStatus = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
`;

export const TitleRegionType = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #a9acc0;
  text-align: left;
`;
