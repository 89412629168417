import React from 'react';
import PropTypes from 'prop-types';
import RadioGroup from 'components/molecules/RadioGroup';
import RadioButton from 'components/molecules/RadioButton';

import css from './styles.scss';

export default function RadioButtonArray(props) {
  const { selectedRating, selectRating, stateKey, buttons, name, children, dataTestId } = props;

  return (
    <div>
      {name && <label>{name}</label>}
      <RadioGroup
        name={name}
        selectedValue={selectedRating}
        onChange={(val) => selectRating(stateKey, val)}
        className={css.rGroup}
      >
        {buttons.map((button, i) => {
          return (
            <div className={css.rButtonContainer} key={`buttons_${i}`}>
              <RadioButton
                dataTestId={`${dataTestId}_${button}`}
                key={`buttons_${i}`}
                option={{ text: button, value: button }}
                isChecked={button === selectedRating}
              />
            </div>
          );
        })}
        {children}
      </RadioGroup>
    </div>
  );
}

RadioButtonArray.propTypes = {
  buttons: PropTypes.array.isRequired,
  selectedRating: PropTypes.string.isRequired,
  selectRating: PropTypes.func.isRequired,
  stateKey: PropTypes.string,
  name: PropTypes.string,
  children: PropTypes.object,
  dataTestId: PropTypes.string,
};
