import titleCase from 'titlecase';
import moment from 'moment';
import { Acl, roleTypes, eventStatusTypes } from '@crimson-education/common-config';
import { TAG_STYLE as tagStyles } from 'components/molecules/Tag';
import { getInitialsFromNames } from './utils';

const AVATAR_COLOURS_COUNT = 14;
export const CALENDAR_FUTURE_LIMIT = 112; // how many weeks the calendar can go forward.

// Display date as: Friday, 12 May
export function formatDate(date) {
  return moment(date).format('dddd, D MMM');
}

export function formatDateWithYear(date) {
  return moment(date).format('dddd, D MMM YYYY');
}

// Display date as: 4:49pm
export function formatTime(date) {
  return moment(date).format('h:mma');
}

// Display date as: 20190520
export function formatDateForDataTestId(date) {
  return moment(date).format('YYYYMMDD');
}

// Display date as: 10-15
export function formatTimeForDataTestId(date) {
  return moment(date).format('HH-mm');
}

// Build a new time object from a traditional moment time.
// Used in the new TimeSelect to rectify its output to
// parent components.
export function createTimeObject(time) {
  let newTime = time;

  if (typeof time === 'string') newTime = moment(time, moment.ISO_8601);
  else if (time instanceof Date) newTime = moment(time);

  if (moment.isMoment(newTime)) {
    if (!newTime.isValid()) throw new Error('Invalid date string');

    return {
      time: newTime,
      hour: newTime.hour(),
      minute: newTime.minute(),
    };
  }

  return time;
}

// Increase time object time by an hour
export function incrementTimeObject(timeObj) {
  const hourIncrement = {
    time: timeObj.time.clone().add(1, 'hour'),
    hour: timeObj.hour + 1,
    minute: timeObj.minute,
  };

  if (hourIncrement.hour >= 24) {
    hourIncrement.time.startOf('day');
    hourIncrement.hour = 0;
    hourIncrement.minute = 0;
  }

  return hourIncrement;
}

// Create a string showing a time interval.
export function timeString(start, end) {
  const startString = formatTime(start);
  const endString = formatTime(end);
  return `${startString} — ${endString}`;
}

// Find people with searchable fields that match a string.
export function filterPeople(people, fields, filterText) {
  const personFilter = (person) => {
    const searchFields = fields.map((field) => person.get(field));
    return searchFields.some((field) => {
      return field && field.toUpperCase().includes(filterText.toUpperCase());
    });
  };

  return people.filter(personFilter);
}

// Sort people in the order Student,Tutor,Case Manager,Head Tutor, Strategist
// Operational Support, Super Admin and then alphabetically.
const roleOrder = [
  roleTypes.STUDENT,
  roleTypes.TUTOR,
  roleTypes.CASE_MANAGER,
  roleTypes.HEAD_TUTOR,
  roleTypes.STRATEGIST,
  roleTypes.OPERATIONAL_SUPPORT,
  roleTypes.SUPER_ADMIN,
].reverse();

export function sortPeople(people) {
  const roleSort = (a, b) => {
    const roleA = a.get('role');
    const roleB = b.get('role');

    if (roleA === roleB) {
      return a.get('fullName').localeCompare(b.get('fullName'));
    }

    return roleOrder.indexOf(roleA) > roleOrder.indexOf(roleB) ? -1 : 1;
  };

  return people.sort(roleSort);
}

// Give users a colour based on their userId.
export function getAvatarColour(userId = '') {
  // Create a "hash" by summing the unicode value of each character in the userId.
  const hash = userId.split('').reduce((acc, curr) => {
    return acc + curr.charCodeAt(0);
  }, 0);

  // Retrieve the modulus of the hash divided by the max colour index.
  // e.g. AVATAR_COLOURS_COUNT = 9, values will be one of 0,1,2,3,4,5,6,7,8.
  return hash % AVATAR_COLOURS_COUNT;
}

// Stores both tutorInfo and studentInfo entries under the user's subjects.
export function unifySubjects(user) {
  if (user.tutorInfo) {
    user.subjects = user.tutorInfo.contractSubjects;
  }

  if (user.studentInfo) {
    user.subjects = user.studentInfo.packageSubjects;
  }
}

// Gives each user a "relationship description" to display relative to the
// principal user.
export function getRelationshipDescription(user) {
  // If a student or tutor, show the relevant subjects as the relationship.
  if (user.subjects) {
    const subjectNames = user.subjects.map((s) => {
      return s.name;
    });
    return subjectNames.join(', ');
  }

  // If there are no relevant subjects shared between users, show role name.
  if (user?.roles && user?.roles?.length) {
    const primaryRole = user.roles.find((r) => r.isPrimary) || user.roles[0];
    return primaryRole.role.name;
  }
  return undefined;
}

// Give a user calendar UI-specific fields.
export function decorateCalendarUser(user) {
  const initialValues = {
    initials: getInitialsFromNames([user.firstName, user.lastName]),
    fullName: titleCase(`${user.firstName} ${user.lastName}`),
    availability: {
      available: [],
      unavailable: [],
      bookings: [],
      block: [],
    },
    relationshipDescription: getRelationshipDescription(user),
    colorIndex: getAvatarColour(user.userId),
  };

  return Object.assign(initialValues, user);
}

// Convert a list of users into a map of userId: user pairs.
export function createUserIdMap(userList) {
  return userList.reduce((mapAccumulate, user) => {
    mapAccumulate[user.userId] = user;
    return mapAccumulate;
  }, {});
}

// Convert a list of users into an decorated map of calendar users.
export function prepareCalendarUsers(userList) {
  userList.forEach(unifySubjects);
  const calendarUserList = userList.map(decorateCalendarUser);
  return createUserIdMap(calendarUserList);
}

export function splitIntoDays(start, end) {
  const startMoment = moment(start);
  const endMoment = moment(end);

  const splitDays = [];
  let isEndMomentReached = false;
  let currentStart = startMoment;
  do {
    let currentEnd = moment(currentStart).add(1, 'day').startOf('day');
    if (currentEnd.isSameOrAfter(endMoment)) {
      currentEnd = endMoment;
      isEndMomentReached = true;
    }
    splitDays.push({
      start: currentStart,
      end: currentEnd,
    });
    currentStart = moment(currentStart).add(1, 'day').startOf('day');
  } while (!isEndMomentReached);

  return splitDays;
}

export function isToday(date) {
  return moment(date).format('D ddd') === moment().format('D ddd');
}

export function isPast(date, unit = 'days') {
  return moment().diff(date, unit) > 0;
}

export function isCurrent(start, end) {
  return moment().isBetween(moment(start), moment(end));
}

export function isSessionSender(userId, compareId) {
  return userId === compareId;
}

export function formatStatusLabel({ status, creatorUserId, userId, start, end, bookAs, pendingUser }) {
  const isSender = isSessionSender(userId, creatorUserId);

  if (status === eventStatusTypes.COMPLETED) {
    return 'COMPLETED';
  }
  if (status === eventStatusTypes.CONFIRMED && isCurrent(start, end)) {
    return 'IN PROGRESS';
  }
  if (status === eventStatusTypes.CONFIRMED) {
    return 'CONFIRMED';
  }
  if (status === eventStatusTypes.TENTATIVE && isSender) {
    return `PENDING (${pendingUser.firstName})`;
  }
  if (bookAs && status === eventStatusTypes.TENTATIVE && !isSender) {
    return `PENDING (${pendingUser.firstName})`;
  }
  if (status === eventStatusTypes.TENTATIVE && !isSender) {
    return 'PENDING (You)';
  }
  return status;
}

export function getTagStyle(session) {
  const { status, start, end } = session;
  const { TENTATIVE, CONFIRMED, COMPLETED } = eventStatusTypes;
  const { FILL_JELLYBEAN, FILL_GREY, STROKE_JELLYBEAN, STROKE_GREY, FILL_BLUE } = tagStyles;

  if ((status === CONFIRMED || status === COMPLETED) && isPast(end, 'minutes')) {
    return FILL_GREY;
  }
  if (status === TENTATIVE && isPast(end, 'minutes')) {
    return STROKE_GREY;
  }
  if (status === CONFIRMED && isCurrent(start, end)) {
    return FILL_BLUE;
  }
  if (status === CONFIRMED) {
    return FILL_JELLYBEAN;
  }
  if (status === TENTATIVE) {
    return STROKE_JELLYBEAN;
  }
  return STROKE_GREY;
}
