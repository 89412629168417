import Immutable from 'immutable';
import createReducer from 'utils/createReducer';
import subjectsService from 'graphql/api/subjects';
import { ADD_ENTITIES, addEntitiesWithNormalisation } from 'ducks/normalizr';
import { subjectCategoryEntity } from 'schema';

const initialState = new Immutable.Map();

export default createReducer(initialState, {
  [ADD_ENTITIES]: (state, action) => {
    return state.mergeDeep(action.payload.entities.subjectCategory);
  },
});

export function getSubjectCategories(parentId) {
  return async (dispatch) => {
    const queryArray = parentId ? [parentId] : [];

    const response = await subjectsService.getSubjectCategoriesByParentId(queryArray);
    const subjectCategories = response.getSubjectCategories;
    dispatch(addEntitiesWithNormalisation(subjectCategories, [subjectCategoryEntity]));

    return subjectCategories;
  };
}

/**
 * Get selected subject categories and all their decendants.
 * @param {String[]} parentIds List of parent ids used as branch roots.
 * @return {function(*)}
 */
export function getDecendantsOfSubjectTree(parentIds) {
  return async (dispatch) => {
    const response = await subjectsService.getDescendantsOfSubjectCategories(parentIds || []);
    const subjects = response.getDescendantsOfSubjectCategories;
    return dispatch(addEntitiesWithNormalisation(subjects, [subjectCategoryEntity]));
  };
}

export function getAncestorsOfSubjectTree(parentIds) {
  return async (dispatch) => {
    const response = await subjectsService.getAncestorsOfSubjectCategories(parentIds || []);
    const subjects = response.getAncestorsOfSubjectCategories;
    return dispatch(addEntitiesWithNormalisation(subjects, [subjectCategoryEntity]));
  };
}
