import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import autoBind from 'auto-bind';
import { Acl } from '@crimson-education/common-config';

import ErrorIcon from 'components/generic/Icons/Error';
import CriteriaTooltip from 'components/generic/CriteriaTooltip';
import CommentField from 'components/generic/Forms/CommentField';
import MultiSelect from 'components/molecules/MultiSelect';
import Modal from 'components/molecules/Modal';
import InfoPanel from 'components/molecules/InfoPanel';
import { Caption } from 'components/atoms/typography';
import HorizontalRadioButtons from 'components/molecules/HorizontalRadioButtons';
import Autocomplete from 'components/molecules/Autocomplete/Default';

import * as Logger from '@crimson-education/browser-logger';
import { getAvatarColour } from 'utils/calendarUtils';
import css from './styles.scss';
import sessionReportData, {
  COMMENT_FIELD,
  BOOLEAN_FIELD,
  DROPDOWN_FIELD,
  MULTI_SELECT_FIELD,
} from './VersionThreeData';

const SESSION_REPORT_HEADING = 'SESSION REPORT';

const getInitialdata = () => {
  const initialData = {
    loading: false,
    submitted: false,
  };
  sessionReportData.forEach((field) => {
    initialData[field.key] = '';
    if (field.criteria) {
      initialData[`${field.key}_criteria_open`] = false;
    }
  });
  return initialData;
};

const sessionTypeForUKStrategist = [
  { id: 'UK-Initial Strategy Meeting (ISM)', value: 'UK-Initial Strategy Meeting (ISM)' },
  { id: 'UK-Pathfinder Meeting', value: 'UK-Pathfinder Meeting' },
  { id: 'UK-App Year: App Year Meeting', value: 'UK-App Year: App Year Meeting' },
  { id: 'UK-NAY:Exploration Meeting', value: 'UK-NAY:Exploration Meeting' },
  { id: 'UK-NAY:Solidification Meeting', value: 'UK-NAY:Solidification Meeting' },
  { id: 'UK-NAY:Intensification Meeting', value: 'UK-NAY:Intensification Meeting' },
  { id: 'UK-AY: Timeline Alignment', value: 'UK-AY: Timeline Alignment' },
  { id: 'UK-AY: Access Meeting', value: 'UK-AY: Access Meeting' },
  { id: 'UK-AY: Final University Selection Meeting', value: 'UK-AY: Final University Selection Meeting' },
  { id: 'UK-AY: UCAS Review&Submission', value: 'UK-AY: UCAS Review&Submission' },
  { id: 'UK-AY: Non-UCAS App Review&Submission', value: 'UK-AY: Non-UCAS App Review&Submission' },
  { id: 'UK-AY: Pre-Interview Meeting', value: 'UK-AY: Pre-Interview Meeting' },
  { id: 'UK-AY: Post-All-UCAS Offers Meeting', value: 'UK-AY: Post-All-UCAS Offers Meeting' },
  { id: 'UK-AY: Post-All-Offers Meeting', value: 'UK-AY: Post-All-Offers Meeting' },
  { id: 'UK-AY: Final Meeting Before Contract End', value: 'UK-AY: Final Meeting Before Contract End' },
];

const sessionTypeForUSStrategist = [
  { id: 'US-Initial Strategy Meeting (ISM)', value: 'US-Initial Strategy Meeting (ISM)' },
  { id: 'US-Pathfinder Meeting', value: 'US-Pathfinder Meeting' },
  { id: 'US-NAY: Meeting', value: 'US-NAY: Meeting' },
  { id: 'US-AY: EC Alignment', value: 'US-AY: EC Alignment' },
  { id: 'US-AY: Timeline Alignment', value: 'US-AY: Timeline Alignment' },
  { id: 'US-AY: Writing Alignment', value: 'US-AY: Writing Alignment' },
  { id: 'US-AY: Application Portals', value: 'US-AY: Application Portals' },
  { id: 'US-AY: School List Confirmation', value: 'US-AY: School List Confirmation' },
  { id: 'US-AY: EA/ED Submission', value: 'US-AY: EA/ED Submission' },
  { id: 'US-AY: UC Submission', value: 'US-AY: UC Submission' },
  { id: 'US-AY: RD Submission', value: 'US-AY: RD Submission' },
  { id: 'US-AY: Offer Review', value: 'US-AY: Offer Review' },
  { id: 'US-AY: Other Meeting', value: 'US-AY: Other Meeting' },
];

const sessionTypeForEUStrategist = [
  { id: 'EU-Initial Strategy Meeting (ISM)', value: 'EU-Initial Strategy Meeting (ISM)' },
  { id: 'EU-Pathfinder Meeting', value: 'EU-Pathfinder Meeting' },
  { id: 'EU-AY: Strategy Meeting', value: 'EU-AY: Strategy Meeting' },
  { id: 'EU-AY: App Mentoring Meeting', value: 'EU-AY: App Mentoring Meeting' },
  { id: 'EU-NAY: Meeting', value: 'EU-NAY: Meeting' },
  { id: 'EU-Confirmed Countries for Application Meeting', value: 'EU-Confirmed Countries for Application Meeting' },
  { id: 'EU-Confirmed University Selection Meeting', value: 'EU-Confirmed University Selection Meeting' },
  { id: 'EU-AY: Timeline Alignment Meeting', value: 'EU-AY: Timeline Alignment Meeting' },
  { id: 'EU-AY: Pre-Interview Meeting', value: 'EU-AY: Pre-Interview Meeting' },
  { id: 'EU-AY: Submission Meeting', value: 'EU-AY: Submission Meeting' },
  { id: 'EU-AY: Post-All-Offers Meeting', value: 'EU-AY: Post-All-Offers Meeting' },
  { id: 'EU-AY: Final Meeting Before Contract End', value: 'EU-AY: Final Meeting Before Contract End' },
];

const sessionTypeForFoundationsStrategist = [
  { id: 'Foundations-Initial Strategy Meeting (ISM)', value: 'Foundations-Initial Strategy Meeting (ISM)' },
  { id: 'Foundations-Pathfinder Meeting', value: 'Foundations-Pathfinder Meeting' },
  { id: 'Foundations-NAY: Meeting', value: 'Foundations-NAY: Meeting' },
  { id: 'Foundations-App Year Meeting', value: 'Foundations-App Year Meeting' },
  { id: 'Foundations-AY: Submission Meeting', value: 'Foundations-AY: Submission Meeting' },
  { id: 'Foundations-AY: Post-All-Offers Meeting', value: 'Foundations-AY: Post-All-Offers Meeting' },
  {
    id: 'Foundations-AY: Final Meeting Before Contract End',
    value: 'Foundations-AY: Final Meeting Before Contract End',
  },
];

const sessionTypeForCanadaStrategist = [
  { id: 'Canada-Initial Strategy Meeting (ISM)', value: 'Canada-Initial Strategy Meeting (ISM)' },
  { id: 'Canada-Pathfinder Meeting', value: 'Canada-Pathfinder Meeting' },
  { id: 'Canada-NAY: Meeting', value: 'Canada-NAY: Meeting' },
  { id: 'Canada-AY: EC Alignment', value: 'Canada-AY: EC Alignment' },
  { id: 'Canada-AY: Timeline Alignment', value: 'Canada-AY: Timeline Alignment' },
  { id: 'Canada-AY: Writing Alignment', value: 'Canada-AY: Writing Alignment' },
  { id: 'Canada-AY: Application Portals', value: 'Canada-AY: Application Portals' },
  { id: 'Canada-AY: School List Confirmation', value: 'Canada-AY: School List Confirmation' },
  { id: 'Canada-AY: Interview Prep', value: 'Canada-AY: Interview Prep' },
  { id: 'Canada-AY: Submission Meeting', value: 'Canada-AY: Submission Meeting' },
  { id: 'Canada-AY: Offer Review', value: 'Canada-AY: Offer Review' },
  { id: 'Canada-AY: Other Meeting', value: 'Canada-AY: Other Meeting' },
];

const sessionTypeForAthleticsStrategist = [
  { id: 'Athletics-Initial Strategy Meeting (ISM)', value: 'Athletics-Initial Strategy Meeting (ISM)' },
  { id: 'Athletics-Roadmap Meeting', value: 'Athletics-Roadmap Meeting' },
  { id: 'Athletics-Pathfinder Meeting', value: 'Athletics-Pathfinder Meeting' },
  { id: 'Athletics-NAY: Meeting', value: 'Athletics-NAY: Meeting' },
  { id: 'Athletics-AY: Campaigning', value: 'Athletics-AY: Campaigning' },
  { id: 'Athletics-AY: Timeline Alignment', value: 'Athletics-AY: Timeline Alignment' },
  { id: 'Athletics-AY: Targeting Alignment', value: 'Athletics-AY: Targeting Alignment' },
  { id: 'Athletics-AY: Coach Communication', value: 'Athletics-AY: Coach Communication' },
  { id: 'Athletics-AY: School List Confirmation', value: 'Athletics-AY: School List Confirmation' },
  { id: 'Athletics-AY: Recruitment Video Review', value: 'Athletics-AY: Recruitment Video Review' },
  { id: 'Athletics-AY: Offer Review', value: 'Athletics-AY: Offer Review' },
  { id: 'Athletics-AY: Other Meeting', value: 'Athletics-AY: Other Meeting' },
];

const sessionTypeForRiseStrategist = [
  { id: 'Rise-Initial Strategy Meeting (ISM)', value: 'Rise-Initial Strategy Meeting (ISM)' },
  { id: 'Rise-Roadmap Meeting', value: 'Rise-Roadmap Meeting' },
  { id: 'Rise-Parent Alignment Meeting (PAM)', value: 'Rise-Parent Alignment Meeting (PAM)' },
  { id: 'Rise-Meeting: Learning Skills', value: 'Rise-Meeting: Learning Skills' },
  { id: 'Rise-Meeting: Personal Skills', value: 'Rise-Meeting: Personal Skills' },
  { id: 'Rise-Meeting: Academic or Career Planning', value: 'Rise-Meeting: Academic or Career Planning' },
  { id: 'Rise-Meeting: Extracurriculars', value: 'Rise-Meeting: Extracurriculars' },
  { id: 'Rise-Meeting: Other', value: 'Rise-Meeting: Other' },
  { id: 'Rise-Final Meeting', value: 'Rise-Final Meeting' },
];

const sessionTypeForBDSAStrategist = [
  { id: 'BDSA-Initial Strategy Meeting (ISM)', value: 'BDSA-Initial Strategy Meeting (ISM)' },
  { id: 'BDSA-Roadmap Meeting', value: 'BDSA-Roadmap Meeting' },
  { id: 'BDSA-Parent Alignment Meeting (PAM)', value: 'BDSA-Parent Alignment Meeting (PAM)' },
  { id: 'BDSA-NAY: Meeting', value: 'BDSA-NAY: Meeting' },
  { id: 'BDSA-AY: Tour & Event Planning', value: 'BDSA-AY: Tour & Event Planning' },
  { id: 'BDSA-AY: Interview Preparation', value: 'BDSA-AY: Interview Preparation' },
  { id: 'BDSA-AY: Timeline Alignment', value: 'BDSA-AY: Timeline Alignment' },
  { id: 'BDSA-AY: Application Portals', value: 'BDSA-AY: Application Portals' },
  { id: 'BDSA-AY: Writing Alignment', value: 'BDSA-AY: Writing Alignment' },
  { id: 'BDSA-AY: Submission Meeting', value: 'BDSA-AY: Submission Meeting' },
  { id: 'BDSA-AY: Offers Review & Waitlists', value: 'BDSA-AY: Offers Review & Waitlists' },
  { id: 'BDSA-AY: Other Meeting', value: 'BDSA-AY: Other Meeting' },
];

const sessionTypeForGSAStrategist = [
  { id: 'GSA-Initial Strategy Meeting (ISM)', value: 'GSA-Initial Strategy Meeting (ISM)' },
  { id: 'GSA-Roadmap Meeting', value: 'GSA-Roadmap Meeting' },
  { id: 'GSA-NAY: Meeting', value: 'GSA-NAY: Meeting' },
  { id: 'GSA-AY: Progress Check-in', value: 'GSA-AY: Progress Check-in' },
  { id: 'GSA-AY: Timeline & Requirements', value: 'GSA-AY: Timeline & Requirements' },
  { id: 'GSA-AY: Program Research & Selection', value: 'GSA-AY: Program Research & Selection' },
  { id: 'GSA-AY: CV Support', value: 'GSA-AY: CV Support' },
  { id: 'GSA-AY: Writing Support', value: 'GSA-AY: Writing Support' },
  { id: 'GSA-AY: Interview Prep', value: 'GSA-AY: Interview Prep' },
  { id: 'GSA-AY: Offers Review', value: 'GSA-AY: Offers Review' },
  { id: 'GSA-AY: Other Meeting', value: 'GSA-AY: Other Meeting' },
];
const sessionTypesForSSM = [
  { id: 'Kickstart Meeting (KSM)', value: 'Kickstart Meeting (KSM)' },
  { id: 'Milestone Meeting 1', value: 'Milestone Meeting 1' },
  { id: 'Milestone Meeting 2', value: 'Milestone Meeting 2' },
  { id: 'Milestone Meeting 3', value: 'Milestone Meeting 3' },
  { id: 'Milestone Meeting 4', value: 'Milestone Meeting 4' },
  { id: 'Parent Alignment', value: 'Parent Alignment' },
  { id: 'Student Ad Hoc Support', value: 'Student Ad Hoc Support' },
  { id: 'RISE: Parent Meeting', value: 'RISE: Parent Meeting' },
  { id: 'RISE: Wrap-up', value: 'RISE: Wrap-up' },
  { id: 'Strategist Introduction', value: 'Strategist Introduction' },
];

const subjects = [
  { value: 'Academics', label: 'Academics' },
  { value: 'Subject Choice', label: 'Subject Choice' },
  { value: 'Extracurricular Activities', label: 'Extracurricular Activities' },
  { value: 'Capstone Project', label: 'Capstone Project' },
  { value: 'Testing', label: 'Testing' },
  { value: 'School Break Planning', label: 'School Break Planning' },
  { value: 'Summer Planning', label: 'Summer Planning' },
  { value: 'School List', label: 'School List' },
  { value: 'Major Exploration', label: 'Major Exploration' },
  { value: 'Application Essays', label: 'Application Essays' },
  { value: 'Recommendation Letters', label: 'Recommendation Letters' },
];

export default class VersionThree extends Component {
  constructor() {
    super();
    this.state = Object.assign({}, getInitialdata(), {
      sessionType: '',
      subjects: null,
    });
    autoBind(this);
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  async onSubmitForm() {
    const { onSubmit, studentId, eventId, reporterId, type } = this.props;

    this.setState({ submitted: true });
    if (this.isFormValid()) {
      this.setState({ loading: true });
      const report = {
        version: 3,
      };
      const BooleanMap = { Yes: true, No: false };

      sessionReportData.forEach((field) => {
        if (field.fieldType === COMMENT_FIELD || field.fieldType === DROPDOWN_FIELD) {
          report[field.key] = this.state[field.key] || null;
        } else if (field.fieldType === BOOLEAN_FIELD) {
          report[field.key] = BooleanMap[this.state[field.key]];
        } else if (field.fieldType === MULTI_SELECT_FIELD) {
          report[field.key] = JSON.stringify(this.state[field.key]);
        }
      });

      await onSubmit(studentId, reporterId, eventId, report, type);
      if (this.mounted) {
        this.setState({ loading: false });
      }
    }
  }

  onSelectSessionType(value) {
    this.setState({ sessionType: value });
  }
  onClearAutocomplete() {
    this.setState({ sessionType: '' });
  }
  updateKeyValue(key, value) {
    this.setState({ [key]: value });
  }

  handleTabSelection(key, options) {
    return (selection) => {
      this.setState({
        [key]: options[selection],
      });
    };
  }
  handleSubjectSelect(selections) {
    this.setState({
      subjects: selections,
    });
  }
  isFormValid() {
    return sessionReportData.reduce((isValid, field) => {
      const { key, fieldType, depends } = field;
      // The current field is hidden because if depends on the value of something else
      if (depends && depends.key && this.state[depends.key] !== depends.value) {
        return isValid && true;
      }

      // Comment field has no text
      if (fieldType === COMMENT_FIELD && !this.state[key].length) {
        return false;
      }
      if (fieldType === MULTI_SELECT_FIELD && this.state[key] !== null && !this.state[key].length) {
        return false;
      }
      // Rating or boolean field is not checked
      if (!this.state[key]) {
        return false;
      }
      return isValid && true;
    }, true);
  }

  closeSessionReport() {
    const { closeSessionReport, fetchIncompleteSessionReports, reporterId } = this.props;
    Logger.trackEvent({ message: 'doItLater click' });
    closeSessionReport();
    fetchIncompleteSessionReports(reporterId);
  }

  render() {
    const { isOpen, booking, lapsedBillingPeriod, role, staffInfo } = this.props;

    const { submitted, loading } = this.state;

    const periodStart = moment(booking.get('start'));
    const periodEnd = moment(booking.get('end'));
    const isSameDay = periodStart.isSame(periodEnd, 'day');
    let period = null;
    if (isSameDay) {
      period = `${periodStart.format('ddd, D MMM, h:mma')} - ${periodEnd.format('h:mma')}`;
    } else {
      period = `${periodStart.format('ddd, D MMM, h:mma')} - ${periodEnd.format('ddd, D MMM, h:mma')}`;
    }

    let sessionTypesForStrategists = [];
    if (staffInfo) {
      const division = staffInfo.division;
      if (division.includes('EU')) {
        sessionTypesForStrategists = sessionTypesForStrategists.concat(sessionTypeForEUStrategist);
      }
      if (division.includes('UK')) {
        sessionTypesForStrategists = sessionTypesForStrategists.concat(sessionTypeForUKStrategist);
      }
      if (division.includes('US')) {
        sessionTypesForStrategists = sessionTypesForStrategists.concat(sessionTypeForUSStrategist);
      }
      if (division.includes('Rise')) {
        sessionTypesForStrategists = sessionTypesForStrategists.concat(sessionTypeForRiseStrategist);
      }
      if (division.includes('Canada')) {
        sessionTypesForStrategists = sessionTypesForStrategists.concat(sessionTypeForCanadaStrategist);
      }
      if (division.includes('Foundations')) {
        sessionTypesForStrategists = sessionTypesForStrategists.concat(sessionTypeForFoundationsStrategist);
      }
      if (division.includes('Athletics')) {
        sessionTypesForStrategists = sessionTypesForStrategists.concat(sessionTypeForAthleticsStrategist);
      }
      if (division.includes('BDSA')) {
        sessionTypesForStrategists = sessionTypesForStrategists.concat(sessionTypeForBDSAStrategist);
      }
      if (division.includes('GSA')) {
        sessionTypesForStrategists = sessionTypesForStrategists.concat(sessionTypeForGSAStrategist);
      }
    } else {
      sessionTypesForStrategists = sessionTypesForStrategists.concat(
        sessionTypeForEUStrategist,
        sessionTypeForUKStrategist,
        sessionTypeForUSStrategist,
        sessionTypeForRiseStrategist,
        sessionTypeForCanadaStrategist,
        sessionTypeForFoundationsStrategist,
        sessionTypeForAthleticsStrategist,
        sessionTypeForBDSAStrategist,
        sessionTypeForGSAStrategist,
      );
    }

    const secondaryAction =
      periodEnd < lapsedBillingPeriod
        ? null // If it is pass the billing period, we should not allow the report to be skipped
        : this.closeSessionReport;
    return (
      <Modal
        isOpen={isOpen}
        title={SESSION_REPORT_HEADING}
        submitText="Submit"
        onSubmit={this.onSubmitForm}
        onSubmitDataTestId="submitSessionReportButton"
        isSubmitDisabled={false}
        loading={loading}
        onSecondarySubmit={secondaryAction}
        onClose={secondaryAction}
        secondarySubmitText="I'll do this later"
      >
        <InfoPanel
          firstName={booking.getIn(['otherUser', 'firstName'])}
          lastName={booking.getIn(['otherUser', 'lastName'])}
          colourIndex={getAvatarColour(booking.getIn(['otherUser', 'userId']))}
          image={booking.getIn(['otherUser', 'profileImageUrl'])}
          textTop={`${booking.get('name')}`}
          textBottom={period}
          userId={booking.getIn(['otherUser', 'userId'])}
        />
        <div className={css.feedback}>
          {sessionReportData.map(({ label, key, buttons, criteria, fieldType, depends, placeholder }) => {
            if (depends && depends.key && this.state[depends.key] !== depends.value) return null;
            if (fieldType === COMMENT_FIELD) {
              return (
                <div key={key}>
                  <CommentField
                    autoSize
                    required
                    className={classNames({
                      [css.commentBox]: true,
                      [css.commentInvalid]: submitted && !this.state[key].length,
                    })}
                    id={`sessionReport_${key}`}
                    dataTestId={`sessionReport_${key}`}
                    key={key}
                    label={label}
                    onValueChange={(val) => this.updateKeyValue(key, val)}
                  />
                  <div className={css.errorMessageContainer}>
                    {submitted && !this.state[key].length && (
                      <div className={css.errorMessage}>
                        <ErrorIcon className={css.alertIcon} />
                        {'Please complete this field'}
                      </div>
                    )}
                  </div>
                </div>
              );
            }
            if (fieldType === DROPDOWN_FIELD) {
              return (
                <div className={css.sessionWithWrapper} key={key}>
                  <Caption className={css.sessionWithLabel}>{label}</Caption>
                  <Autocomplete
                    placeholder={placeholder}
                    getItemValue={(item) => item.value}
                    itemOutputFormat={(item) => item.value}
                    items={Acl.isStrategist(role) ? sessionTypesForStrategists : sessionTypesForSSM}
                    value={this.state.sessionType}
                    disabled={false}
                    keySelector="id"
                    multiItemDataTestId={(item) => `${item.value}`}
                    onSelect={this.onSelectSessionType}
                    dropIcon
                    onClear={this.onClearAutocomplete}
                  />
                  <div className={css.errorMessageContainer}>
                    {submitted && !this.state[key] && (
                      <div className={css.errorMessage}>
                        <ErrorIcon className={css.alertIcon} />
                        {'Please choose a type'}
                      </div>
                    )}
                  </div>
                </div>
              );
            }
            if (fieldType === MULTI_SELECT_FIELD) {
              return (
                <div className={css.sessionWithWrapper} key={key}>
                  <Caption className={css.sessionWithLabel}>{label}</Caption>
                  <MultiSelect
                    label={label}
                    multiple
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                    options={subjects}
                    placeholder={placeholder}
                    onChange={this.handleSubjectSelect}
                  />
                  <div className={css.errorMessageContainer}>
                    {submitted && (!this.state[key] || (this.state[key] !== null && !this.state[key].length)) && (
                      <div className={css.errorMessage}>
                        <ErrorIcon className={css.alertIcon} />
                        {'Please choose at least a subject'}
                      </div>
                    )}
                  </div>
                </div>
              );
            }
            return (
              <div key={key}>
                <div>
                  <div className={css.selectLabel}>
                    <Caption className={css.selectCaption}>{label}</Caption>
                    {criteria && (
                      <CriteriaTooltip
                        isOpen={this.state[`${key}_criteria_open`]}
                        arrowSize={10}
                        preferPlace="right"
                        targetStyling={css.seeCriteria}
                        onMouseEnter={() => this.updateKeyValue(`${key}_criteria_open`, true)}
                        onMouseLeave={() => this.updateKeyValue(`${key}_criteria_open`, false)}
                        targetText="See criteria"
                        body={criteria || []}
                        buttons={buttons}
                      />
                    )}
                  </div>
                  <HorizontalRadioButtons
                    height={3.2}
                    buttons={buttons}
                    onButtonChange={this.handleTabSelection(key, buttons)}
                    dataTestIdPrefix=""
                    dataGaLabel={label}
                  />
                </div>
                <div className={css.errorMessageContainer}>
                  {submitted && !this.state[key] && (
                    <div className={css.errorMessage}>
                      <ErrorIcon className={css.alertIcon} />
                      {'Please select an option'}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </Modal>
    );
  }
}

VersionThree.propTypes = {
  isOpen: PropTypes.bool,
  booking: PropTypes.shape(),
  onSubmit: PropTypes.func.isRequired,
  reporterId: PropTypes.string.isRequired,
  studentId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  eventId: PropTypes.number.isRequired,
  closeSessionReport: PropTypes.func,
  fetchIncompleteSessionReports: PropTypes.func,
  lapsedBillingPeriod: PropTypes.object,
  role: PropTypes.object,
  staffInfo: PropTypes.object,
};
