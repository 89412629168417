import React from 'react';
import PropTypes from 'prop-types';

export default function TrendUp({ className }) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="129" height="124" viewBox="0 0 129 124">
      <g fill="none" fillRule="evenodd" transform="translate(0 4)">
        <ellipse cx="62.771" cy="115.982" fill="#F2F3F6" fillRule="nonzero" rx="46.123" ry="3.822" />
        <rect width="84.135" height="101.084" x="20.703" y="12.766" fill="#1E2248" fillRule="nonzero" rx="6.27" />
        <path
          fill="#F4F6F8"
          fillRule="nonzero"
          d="M86.771 105.372H31.682a3.935 3.935 0 0 1-3.897-3.92V25.164a3.935 3.935 0 0 1 3.92-3.92h62.162a3.935 3.935 0 0 1 3.92 3.92v67.682"
        />
        <rect width="48.368" height="9.297" x="39.642" y="7.63" fill="#BABCC5" fillRule="nonzero" rx="4.648" />
        <circle cx="63.83" cy="6.608" r="6.233" stroke="#BABCC5" strokeWidth="7" />
        <path fill="#BABCC5" fillRule="nonzero" d="M86.771 105.372v-8.614a3.927 3.927 0 0 1 3.92-3.912h7.84" />
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M27.762 65.67V52.393l31.72-31.149h12.473zM27.762 67.877v1.735l49.314-48.368H75.32z"
        />
        <path
          fill="#00A896"
          fillRule="nonzero"
          d="M79.905 46.005c.122-.007.243-.006.364 0a3.293 3.293 0 0 1 2.61 1.508 3.388 3.388 0 0 1 .393.815l2.533 7.182c.622 1.762-.293 3.697-2.042 4.323a3.354 3.354 0 0 1-4.291-2.057l-.562-1.593-15.407 19.01a3.344 3.344 0 0 1-4.337.76l-5.184-3.143L43.928 84.8a3.344 3.344 0 0 1-4.736.4 3.403 3.403 0 0 1-.398-4.77l11.901-14.193a3.344 3.344 0 0 1 4.3-.716l5.143 3.118 12.687-15.654-1.084.031c-1.856.054-3.403-1.417-3.457-3.286-.053-1.87 1.407-3.428 3.263-3.482l8.358-.243z"
        />
        <path
          stroke="#BABCC5"
          strokeDasharray="1,9"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M19.517 95.829s-9.711 1.81-14.239-1.98c-7.417-6.216-8.534-20.995 18.487-29.801 26.56-8.652 69.777 4.788 91.188-9.66 14.273-9.632 16.043-17.507 5.311-23.626"
        />
        <g fillRule="nonzero" transform="translate(111 22)">
          <rect
            width="4.198"
            height="2.426"
            x="1.007"
            y="5.324"
            fill="#E3E7ED"
            rx="1.213"
            transform="rotate(-45.52 3.106 6.536)"
          />
          <rect
            width="4.198"
            height="2.426"
            x="2.926"
            y="6.604"
            fill="#E3E7ED"
            rx="1.213"
            transform="rotate(-69.68 5.025 7.817)"
          />
          <rect
            width="4.198"
            height="2.426"
            x="5.401"
            y="2.953"
            fill="#E3E7ED"
            rx="1.213"
            transform="rotate(134.48 7.5 4.166)"
          />
          <rect
            width="4.198"
            height="2.426"
            x="3.478"
            y="1.673"
            fill="#E3E7ED"
            rx="1.213"
            transform="rotate(110.32 5.577 2.886)"
          />
          <rect
            width="2.358"
            height="5.001"
            x="4.1"
            y="2.967"
            fill="#4F6987"
            rx="1.179"
            transform="rotate(-56.41 5.28 5.467)"
          />
        </g>
      </g>
    </svg>
  );
}

TrendUp.propTypes = {
  className: PropTypes.string,
};
