import styled from 'styled-components';
import { Row, Column } from '../../index.style';

export const ClickableUnderlineTextContainer = styled(Row)`
  cursor: pointer;
  gap: 10px;
  * {
    color: #464ac9;
  }
  div:nth-child(1) {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-decoration-line: underline;
  }
  svg:nth-child(2) {
    font-size: 22px;
  }
`;

export const ApprovalStatusText = styled.div`
  display: flex;
  position: relative;
  width: 152px;
  height: 32px;

  background: #e3e7ed;
  border-radius: 20px;
  font-size: 14px;
  line-height: 32px;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: #73747d;
  text-transform: capitalize;
`;

export const ApprovalPendingContainer = styled(Row)`
  position: relative;
  align-items: center;
  gap: 20px;
`;

export const ResendPrompt = styled(Column)<{
  $backgroundColor?: string;
  $bottom?: string;
  $right?: string;
}>`
  position: absolute;
  width: 268px;
  gap: 6px;
  background: ${({ $backgroundColor }) => $backgroundColor || '#ff764c'};
  border-radius: 8px;
  bottom: ${({ $bottom }) => $bottom || '40px'};
  right: ${({ $right }) => $right || '-16px'};
  padding: 16px;
  z-index: 9999;
  color: #ffffff;
  * {
    font-weight: 500;
    line-height: 20px;
    color: #ffffff;
  }

  div:nth-child(1) {
    font-size: 14px;
  }

  div:nth-child(2) {
    font-size: 12px;
    text-decoration-line: underline;
    margin-left: auto;
    cursor: pointer;
  }
  ::after {
    content: '';
    position: absolute;
    width: 0px;
    height: 0px;
    right: 20%;
    bottom: -20px;
    border-top: 10px solid ${({ $backgroundColor }) => $backgroundColor || '#ff764c'};
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid transparent;
  }
`;
