import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';
import { gql } from '@apollo/client';
import { createClient } from '../utils';
import { sessionReportType } from './session';
import { appYearStudentType, staffStudentsUserRelationType } from './common/types';
const { api } = getConfig();

const client = createClient(api.graphQLEndpoint);

const admissionOfferFile = gql`
  fragment admissionOfferFile on AdmissionOfferFile {
    name
    location
    size
  }
`;

const applicationApprovalFrag = gql`
  fragment applicationApproval on ApplicationApproval {
    id
    userId
    region
    respondedAt
    responseType
    responseMessage
    selectionHash
    createdAt
    type
    emailMetadata {
      recipients {
        type
        id
      }
      clauses {
        key
        value
      }
      template
    }
  }
`;

const applicationOverviewFrag = gql`
  fragment applicationOverview on ApplicationOverview {
    id
    userId
    region
    serviceStatus
    createdAt
  }
`;

const universityData = gql`
  fragment universityData on UniversityData {
    id
    name
    logo
    tags
    city
    image
    acceptanceRate
    enrollment
    country {
      id
      name
      shortName
      flagImage
      region
    }
    deadlines {
      key
      round
      deadline
    }
    colleges {
      name
    }
    applicationGroups
  }
`;

const application = gql`
  fragment application on Application {
    id
    userId
    university
    college
    major
    degree
    round
    applicationStatus
    hadInterview
    receivedAid
    totalAid
    aidDetails
    currency
    goal
    hadCrimsonSupport
    order
    status
    createdAt
    group
    universityId
    isTracking
    isSubmitted
    deadline
    deadlineType
    offerFileIds
    aidType
    offerFiles {
      name
      location
      size
      url
      id
      type
    }
    extraApplicationStatus
  }
`;

const getApplicationsByUserId = gql`
  ${application}
  ${universityData}
  query getApplicationsByUserId($userId: String!, $groups: [ApplicationGroupEnumType]) {
    getApplicationsByUserId(userId: $userId, groups: $groups) {
      ...application
      universityData {
        ...universityData
      }
    }
  }
`;

const createApplication = gql`
  ${application}
  ${universityData}
  mutation createApplication($application: NewApplication!) {
    createApplication(application: $application) {
      ...application
      universityData {
        ...universityData
      }
    }
  }
`;

const updateApplication = gql`
  ${application}
  mutation updateApplication($application: EditApplication!) {
    updateApplication(application: $application) {
      ...application
    }
  }
`;

const updateMultiApplicationsWithSameData = gql`
  ${application}
  mutation updateMultiApplicationsWithSameData($application: EditApplication!, $ids: [String]!) {
    updateMultiApplicationsWithSameData(application: $application, ids: $ids) {
      ...application
    }
  }
`;

const deleteApplication = gql`
  ${application}
  mutation deleteApplication($id: String!) {
    deleteApplication(id: $id) {
      ...application
    }
  }
`;

const createAdmissionResult = gql`
  ${application}
  mutation createAdmissionResult($admissionResult: NewAdmissionResult) {
    createAdmissionResult(admissionResult: $admissionResult) {
      ...application
    }
  }
`;

const deleteAdmissionResult = gql`
  ${application}
  mutation deleteAdmissionResult($id: String!) {
    deleteAdmissionResult(id: $id) {
      ...application
    }
  }
`;

const createMultiApplications = gql`
  ${application}
  ${universityData}
  mutation createMultiApplications($applications: [NewApplication!]) {
    createMultiApplications(applications: $applications) {
      ...application
      universityData {
        ...universityData
      }
    }
  }
`;

const uploadAdmissionOffer = gql`
  ${application}
  ${universityData}
  mutation uploadAdmissionOffer($applicationId: String!, $fileId: String!, $userId: String!) {
    uploadAdmissionOffer(applicationId: $applicationId, userId: $userId, fileId: $fileId) {
      ...application
      universityData {
        ...universityData
      }
    }
  }
`;

const deleteAdmissionOffer = gql`
  mutation deleteAdmissionOffer($applicationId: String!, $fileId: String!, $userId: String!) {
    deleteAdmissionOffer(applicationId: $applicationId, userId: $userId, fileId: $fileId)
  }
`;

const getStudentApprovalApplications = gql`
  ${applicationApprovalFrag}
  query getStudentApprovalApplications($userId: String!, $region: ApplicationRegionEnum) {
    getStudentApprovalApplications(user_id: $userId, region: $region) {
      ...applicationApproval
    }
  }
`;

const getStudentAllApprovalApplications = gql`
  ${applicationApprovalFrag}
  query getStudentAllApprovalApplications($userId: String!) {
    US: getStudentApprovalApplications(user_id: $userId, region: US) {
      ...applicationApproval
    }

    UK: getStudentApprovalApplications(user_id: $userId, region: UK) {
      ...applicationApproval
    }

    EU: getStudentApprovalApplications(user_id: $userId, region: EU) {
      ...applicationApproval
    }

    Other: getStudentApprovalApplications(user_id: $userId, region: OTHER) {
      ...applicationApproval
    }
  }
`;

const requestApplicationApproval = gql`
  ${applicationApprovalFrag}
  mutation requestApplicationApproval(
    $userId: String!
    $region: ApplicationRegionEnum!
    $recipients: [ApplicationApprovalRecipient]
    $clauses: [ApplicationApprovalClause]
    $template: String
  ) {
    requestApplicationApproval(
      user_id: $userId
      region: $region
      recipients: $recipients
      clauses: $clauses
      template: $template
    ) {
      ...applicationApproval
    }
  }
`;

const updateApplicationOverview = gql`
  ${applicationOverviewFrag}
  mutation updateApplicationOverview($id: String!, $input: NewApplicationOverview!) {
    updateApplicationOverview(id: $id, input: $input) {
      ...applicationOverview
    }
  }
`;

const createApplicationOverview = gql`
  ${applicationOverviewFrag}
  mutation createApplicationOverview($input: NewApplicationOverview!) {
    createApplicationOverview(input: $input) {
      ...applicationOverview
    }
  }
`;

const getApplicationOverviewsByUserId = gql`
  ${applicationOverviewFrag}
  query getApplicationOverviewsByUserId($userId: String!) {
    getApplicationOverviewsByUserId(user_id: $userId) {
      ...applicationOverview
    }
  }
`;

const getStaffStudentsApplications = gql`
  ${application}
  ${universityData}
  query {
    getStaffStudentsApplications {
      ...application
      universityData {
        ...universityData
      }
    }
  }
`;

const getStaffStudentsApprovals = gql`
  ${applicationApprovalFrag}
  query getStaffStudentsApprovals($region: ApplicationRegionEnum) {
    getStaffStudentsApprovals(region: $region) {
      ...applicationApproval
    }
  }
`;

const staffDashboardQueries = gql`
  ${application}
  ${universityData}
  ${applicationApprovalFrag}
  ${sessionReportType}
  ${appYearStudentType}
  ${staffStudentsUserRelationType}
  ${applicationOverviewFrag}
  query staffDashboardQueries($region: ApplicationRegionEnum) {
    getStaffStudentsApplications {
      ...application
      universityData {
        ...universityData
      }
    }
    getStaffStudentsApprovals(region: $region) {
      ...applicationApproval
    }
    getMilestoneSessionReportsByUserId {
      ...sessionReportType
    }
    getAppYearStudentsByStaffUserId {
      ...appYearStudentType
    }
    getStudentSupervisorsByRequester {
      ...staffStudentsUserRelationType
    }
    getAllStudentOverviewsByStaffId {
      ...applicationOverview
    }
  }
`;

export default {
  getApplicationsByUserId: async (userId, groups) => client.query(getApplicationsByUserId, { userId, groups }),
  createApplication: async (application) => client.query(createApplication, { application }),
  createMultiApplications: async (applications) => client.query(createMultiApplications, { applications }),
  updateApplication: async (application) => client.query(updateApplication, { application }),
  createAdmissionResult: async (admissionResult) => client.query(createAdmissionResult, { admissionResult }),
  deleteApplication: async (id) => client.query(deleteApplication, { id }),
  deleteAdmissionResult: async (id) => client.query(deleteAdmissionResult, { id }),
  uploadAdmissionOffer: async (applicationId, fileId, userId) =>
    client.query(uploadAdmissionOffer, { applicationId, fileId, userId }),
  deleteAdmissionOffer: async (applicationId, fileId, userId) =>
    client.query(deleteAdmissionOffer, { applicationId, fileId, userId }),
  getStudentApprovalApplications: async (userId, region) =>
    client.query(getStudentApprovalApplications, { userId, region }),
  requestApplicationApproval: async ({ userId, region, template, recipients, clauses }) =>
    client.mutate(requestApplicationApproval, { userId, region, recipients, clauses, template }),
  getStudentAllApprovalApplications: async (userId) => client.query(getStudentAllApprovalApplications, { userId }),
  updateApplicationOverview: async (id, input) => client.query(updateApplicationOverview, { id, input }),
  createApplicationOverview: async (input) => client.query(createApplicationOverview, { input }),
  getApplicationOverviewsByUserId: async (userId) => client.query(getApplicationOverviewsByUserId, { userId }),
  updateMultiApplicationsWithSameData: async (application, applicationIds) =>
    client.query(updateMultiApplicationsWithSameData, { application, ids: applicationIds }),
  getStaffStudentsApplications: async () => client.query(getStaffStudentsApplications),
  getStaffStudentsApprovals: async (region) => client.query(getStaffStudentsApprovals, { region }),
  staffDashboardQueries: async (region) => client.query(staffDashboardQueries, { region }),
};
