import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { RadioGroup, Radio } from 'react-radio-group';
import classnames from 'classnames';
import css from './styles.scss';

export default class ColorPicker extends Component {
  constructor(props) {
    super(props);
    const { defaultValue } = this.props;

    this.state = {
      selected: defaultValue,
    };

    this.onColorChange = this.onColorChange.bind(this);
  }

  onColorChange(value) {
    const { onChange } = this.props;
    this.setState({
      selected: value,
    });
    onChange(value);
  }

  render() {
    const { name, colors } = this.props;
    const { selected } = this.state;
    return (
      <div className={classnames(css.formItemContainer, css.colorPickerWrapper)}>
        <label className={css.formLabel} htmlFor={name}>
          Colour theme
        </label>
        <RadioGroup
          name={name}
          selectedValue={selected}
          onChange={(value) => this.onColorChange(value)}
          className={classnames(css.rGoup, css.colorPicker)}
        >
          {colors.map((color) => {
            return (
              <div
                key={color}
                className={classnames({
                  [css.rButtonContainer]: true,
                  [css.selected]: color === selected,
                })}
              >
                <Radio id={color} value={color} className={css.rButton} />
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className={css.labelText} htmlFor={color} style={{ background: color }} />
              </div>
            );
          })}
        </RadioGroup>
      </div>
    );
  }
}

ColorPicker.displayName = 'ColorPicker';

ColorPicker.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  colors: PropTypes.array.isRequired,
};
