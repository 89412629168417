import React from 'react';
import PropTypes from 'prop-types';

export default function Language({ className }) {
  return (
    <svg className={className} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <g id="ip1" fillRule="evenodd" transform="translate(4 4)">
        <path
          id="languageIconPath"
          d="m1.6 0c-.884 0-1.6.716-1.6 1.6v9.6c0 .884.716 1.6 1.6 1.6h11.2l3.2 3.2v-14.4c0-.884-.716-1.6-1.6-1.6zm2.5496 3.2h1.3l2.5504 6.4h-1.5l-.4752-1.3248h-2.4496l-.4752 1.3248h-1.5zm6.9 0h1.1v1.0752h2.2504v1.0496c-.1624 1.3872-.784 2.4216-1.8 3.1248.2752.044.5624.0752.8752.0752v1.0752c-.8376 0-1.6184-.1624-2.2752-.4248-.756.284-1.7184.4248-2.4.4248v-1.0752c.3064 0 .8376-.0312 1.1752-.1-.6064-.5432-1.1752-1.6936-1.1752-2.4744h1.1248c0 .5904.6032 1.6376 1.3248 2.0496 1.0592-.5064 1.744-1.4032 1.9504-2.6752h-4.4v-1.0496h2.2496zm-6.2496 1.6248-.8504 2.4h1.7z"
        />
        <mask id="languageIconMask" fill="#fff">
          <use fill="none" xlinkHref="#languageIconPath" />
        </mask>
      </g>
    </svg>
  );
}

Language.propTypes = {
  className: PropTypes.string,
};
