import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';
import { gql } from '@apollo/client';
import { createClient } from '../utils';

const { api } = getConfig();

const client = createClient(api.graphQLEndpoint);

const taskInfo = gql`
  fragment taskInfo on taskType {
    id
    roadmapMissionId
    name
    isComplete
    date
    status
    createdAt
  }
`;

const deletedElementsFragment = gql`
  fragment deletedElementsFragment on elementsDeletedType {
    deletedCategoryIds
    deletedMissionIds
    deletedTaskIds
  }
`;

export const roadmapInfo = gql`
  ${taskInfo}
  fragment roadmapInfo on roadmapType {
    id
    userId
    missions {
      id
      name
      description
      startDate
      endDate
      type
      category {
        id
        name
        description
        color
      }
    }
    categories {
      id
      name
      description
      color
      default
    }
    templateName
    isTemplate
    status
    tasks {
      ...taskInfo
    }
  }
`;

export const roadmapList = gql`
  fragment roadmapList on roadmapType {
    id
    templateName
    isTemplate
    status
  }
`;

const createMission = gql`
  ${taskInfo}
  mutation createMission(
    $roadmapId: String!
    $name: String!
    $description: String!
    $startDate: String!
    $endDate: String!
    $categoryId: String!
    $type: String!
    $tasks: [taskInputType]
  ) {
    createMission(
      roadmapId: $roadmapId
      name: $name
      description: $description
      startDate: $startDate
      endDate: $endDate
      categoryId: $categoryId
      type: $type
      tasks: $tasks
    ) {
      id
      name
      description
      startDate
      endDate
      category {
        id
        name
        color
      }
      tasks {
        id
        roadmapMissionId
        name
        isComplete
        date
        status
      }
      type
      tasks {
        ...taskInfo
      }
    }
  }
`;

const updateMission = gql`
  ${taskInfo}
  mutation updateMission(
    $missionId: String!
    $name: String
    $description: String
    $startDate: String
    $endDate: String
    $categoryId: String
    $type: String
    $tasks: [taskInputType]
  ) {
    updateMission(
      missionId: $missionId
      name: $name
      description: $description
      startDate: $startDate
      endDate: $endDate
      categoryId: $categoryId
      type: $type
      tasks: $tasks
    ) {
      id
      name
      description
      startDate
      endDate
      category {
        id
        name
        color
      }
      type
      tasks {
        ...taskInfo
      }
    }
  }
`;

const publishRoadmap = gql`
  mutation publishRoadmap($roadmapId: String!) {
    publishRoadmap(roadmapId: $roadmapId) {
      id
    }
  }
`;

const deleteMission = gql`
  mutation deleteMission($missionId: String!) {
    deleteMission(missionId: $missionId) {
      id
      type
    }
  }
`;

const fetchRoadmapByUserIdQuery = gql`
  ${roadmapInfo}
  query fetchRoadmapByUserIdQuery($userId: String!) {
    roadmap: getRoadmapByUserId(userId: $userId) {
      ...roadmapInfo
    }
  }
`;

const fetchRoadmapIdByUserIdQuery = gql`
  query fetchRoadmapIdByUserIdQuery($userId: String!) {
    roadmap: getRoadmapByUserId(userId: $userId) {
      id
      userId
    }
  }
`;

const fetchRoadmapTemplates = gql`
  ${roadmapList}
  query getRoadmapTemplates {
    roadmaps: getRoadmapTemplates {
      ...roadmapList
    }
  }
`;

const fetchRoadmapById = gql`
  ${roadmapInfo}
  query getRoadmapById($roadmapId: String!) {
    roadmap: getRoadmapById(roadmapId: $roadmapId) {
      ...roadmapInfo
    }
  }
`;

const createRoadmapQuery = gql`
  ${roadmapInfo}
  mutation createRoadmapQuery($userId: String!) {
    roadmap: createRoadmap(userId: $userId) {
      ...roadmapInfo
    }
  }
`;

const createCategory = gql`
  mutation createCategory($roadmapId: String!, $name: String!, $description: String, $color: String!) {
    createCategory(roadmapId: $roadmapId, name: $name, description: $description, color: $color) {
      id
      name
      description
      color
      default
    }
  }
`;

const updateCategory = gql`
  mutation updateCategory(
    $roadmapId: String!
    $categoryId: String!
    $name: String!
    $description: String
    $color: String!
  ) {
    updateCategory(
      roadmapId: $roadmapId
      categoryId: $categoryId
      name: $name
      description: $description
      color: $color
    ) {
      id
      name
      description
      color
      default
    }
  }
`;

const deleteCategory = gql`
  mutation deleteCategoryFromRoadmap($roadmapId: String!, $categoryId: String!) {
    deleteCategoryFromRoadmap(roadmapId: $roadmapId, categoryId: $categoryId) {
      id
    }
  }
`;

const deleteRoadmap = gql`
  mutation deleteRoadmap($roadmapId: String!) {
    deleteRoadmap(roadmapId: $roadmapId) {
      id
      userId
      isTemplate
    }
  }
`;

const createRoadmapTemplateQuery = gql`
  ${roadmapInfo}
  mutation createTemplateQuery($templateName: String!) {
    roadmap: createTemplate(templateName: $templateName) {
      ...roadmapInfo
    }
  }
`;

const duplicateRoadmapTemplate = gql`
  ${roadmapInfo}
  mutation duplicateRoadmapTemplate($templateId: String!, $newTemplateName: String!) {
    roadmap: duplicateRoadmapTemplate(templateId: $templateId, newTemplateName: $newTemplateName) {
      ...roadmapInfo
    }
  }
`;

const renameRoadmapTemplate = gql`
  ${roadmapInfo}
  mutation renameRoadmapTemplate($templateId: String!, $newTemplateName: String!) {
    roadmap: renameRoadmapTemplate(templateId: $templateId, newTemplateName: $newTemplateName) {
      ...roadmapInfo
    }
  }
`;

const createRoadmapFromTemplateQuery = gql`
  ${roadmapInfo}
  mutation createRoadmapFromTemplate($userId: String!, $templateId: String!, $endYear: Int!) {
    createRoadmapFromTemplate(userId: $userId, templateId: $templateId, endYear: $endYear) {
      ...roadmapInfo
    }
  }
`;

const deleteRoadmapElements = gql`
  ${deletedElementsFragment}
  mutation deleteRoadmapElements(
    $roadmapId: String!
    $taskIds: [String]
    $missionIds: [String]
    $categoryIds: [String]
  ) {
    deleteRoadmapElements(
      roadmapId: $roadmapId
      taskIds: $taskIds
      missionIds: $missionIds
      categoryIds: $categoryIds
    ) {
      ...deletedElementsFragment
    }
  }
`;

export default {
  fetchRoadmapByUserId: (userId) => client.query(fetchRoadmapByUserIdQuery, { userId }),
  fetchRoadmapIdByUserId: (userId) => client.query(fetchRoadmapIdByUserIdQuery, { userId }),
  createMission: ({ roadmapId, name, description, startDate, endDate, categoryId, type, tasks }) =>
    client.query(createMission, { roadmapId, name, description, startDate, endDate, categoryId, type, tasks }),
  updateMission: ({ missionId, name, description, startDate, endDate, categoryId, type, tasks }) =>
    client.query(updateMission, { missionId, name, description, startDate, endDate, categoryId, type, tasks }),
  updateMissionTask: ({ missionId, tasks }) => client.query(updateMission, { missionId, tasks }),
  createRoadmap: (userId) => client.query(createRoadmapQuery, { userId }),
  deleteMission: (missionId) => client.query(deleteMission, { missionId }),
  createCategory: ({ roadmapId, name, description, color }) =>
    client.query(createCategory, { roadmapId, name, description, color }),
  updateCategory: ({ roadmapId, categoryId, name, description, color }) =>
    client.query(updateCategory, { roadmapId, categoryId, name, description, color }),
  deleteCategory: (roadmapId, categoryId) => client.query(deleteCategory, { roadmapId, categoryId }),
  deleteRoadmap: (roadmapId) => client.query(deleteRoadmap, { roadmapId }),
  fetchRoadmapTemplates: () => client.query(fetchRoadmapTemplates),
  fetchRoadmapById: (roadmapId) => client.query(fetchRoadmapById, { roadmapId }),
  createRoadmapTemplate: (templateName) => client.query(createRoadmapTemplateQuery, { templateName }),
  createRoadmapFromTemplate: (userId, templateId, endYear) =>
    client.query(createRoadmapFromTemplateQuery, { userId, templateId, endYear }),
  duplicateRoadmapTemplate: (templateId, newTemplateName) =>
    client.query(duplicateRoadmapTemplate, { templateId, newTemplateName }),
  renameRoadmapTemplate: (templateId, newTemplateName) =>
    client.query(renameRoadmapTemplate, { templateId, newTemplateName }),
  publishRoadmap: (roadmapId) => client.query(publishRoadmap, { roadmapId }),
  deleteRoadmapElements: ({ roadmapId, taskIds, missionIds, categoryIds }) =>
    client.query(deleteRoadmapElements, { roadmapId, taskIds, missionIds, categoryIds }),
};
