import styled from 'styled-components';
import { SimpleMenuDropdown } from '../../components';

export const GroupByText = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #73747d;
`;

export const StyledMenuDropdown = styled(SimpleMenuDropdown)`
  height: 40px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 4px;
  border: 1px solid #e3e7ed;
  & :hover {
  }
`;
