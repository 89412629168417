import React from 'react';
import Cactus from 'components/atoms/graphics/Cactus';
import { Body, PageHeader, SubHeader } from 'components/atoms/typography';
import css from './styles.scss';

const HEADING = 'Nothing to see here.';
const SUBHEADING = 'You have encountered a 404 error.';
const MESSAGE = `
  You can either...
  Check the URL you have entered. Maybe it has a typo.
  If the URL is correct then we are currently working on the problem.
  Please come back a bit later.
`;

export default function NotFound() {
  return (
    <div className={css.notFound}>
      <Cactus />
      <PageHeader className={css.heading}>{HEADING}</PageHeader>
      <SubHeader className={css.subheading}>{SUBHEADING}</SubHeader>
      <Body className={css.message}>{MESSAGE}</Body>
    </div>
  );
}
