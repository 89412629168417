import React, { useState, useEffect } from 'react';
import AntAvatar, { AvatarProps as AntAvatarProps } from 'antd/lib/avatar';
import Badge from 'antd/lib/badge';
const ColorList = ['#f56a00', '#7265e6', '#ffbf00', '#00a2ae'];

type AvatarProps = {
  firstName: string;
  lastName: string;
  showDot?: boolean;
} & AntAvatarProps;

const Avatar = ({ firstName, showDot, lastName, src, size, style, ...restProps }: AvatarProps) => {
  const [color, setColor] = useState(ColorList[0]);
  useEffect(() => {
    const charCode = (firstName || 'a').charCodeAt(0) + (lastName || 'a').charCodeAt(0);
    const randomIndex = (charCode * 10) % ColorList.length;
    setColor(ColorList[randomIndex]);
  }, [firstName, lastName]);
  const hasImg = !!src;
  const Wrapper = showDot ? Badge : React.Fragment;
  const _size = typeof size === 'number' ? size : 0;
  const dotSize = 6;
  // put red dot on the top right border of the avatar
  const offset = (Math.SQRT2 * _size - _size) / 2 - dotSize / 2;
  const wrapperProps = {
    dot: true,
    offset: [-offset, offset],
    size: 'default',
  } as any;
  return (
    <Wrapper {...(showDot ? wrapperProps : undefined)}>
      <AntAvatar
        style={{ backgroundColor: color, flexShrink: 0, ...style }}
        gap={4}
        src={src}
        size={size}
        {...restProps}
      >
        {hasImg ? undefined : (firstName || '').charAt(0).toUpperCase() + (lastName || '').charAt(0).toUpperCase()}
      </AntAvatar>
    </Wrapper>
  );
};
export default Avatar;
