import React from 'react';
import PropTypes from 'prop-types';

export default function Error({ className, colour = '#000000', size = '1.6rem' }) {
  return (
    <svg
      fill={colour}
      className={className}
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z" />
    </svg>
  );
}

Error.propTypes = {
  className: PropTypes.string,
  colour: PropTypes.string,
  size: PropTypes.number,
};
