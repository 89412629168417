import React from 'react';
import { Row } from '../../index.style';
import { HintText } from './index.style';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const Hint = () => {
  return (
    <Row gap={'10px'} style={{ marginTop: 24, marginBottom: 40 }}>
      <ErrorOutlineIcon
        style={{
          fontSize: 23,
          color: '#73747D',
        }}
      />
      <HintText>
        {
          'Only Crimson supported schools in the Final List will be sent to the parent for approval. Once the email is sent, any changes to the Final List will require another School Confirmation Agreement email to be sent.'
        }
      </HintText>
    </Row>
  );
};

export default Hint;
