import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';
import { gql } from '@apollo/client';
import { createClient } from '../utils';

const { api } = getConfig();

const client = createClient(api.graphQLEndpoint);

const task = gql`
  fragment task on Task {
    id
    userId
    roadmapMissionId
    name
    isComplete
    status
    date
    mission {
      id
      name
      startDate
      endDate
      category {
        name
        color
      }
    }
    eventId
    createdAt
  }
`;

const getTasksByUserId = gql`
  ${task}
  query getTasksByUserId($userId: String!) {
    getTasksByUserId(userId: $userId) {
      ...task
    }
  }
`;

const createTask = gql`
  ${task}
  mutation createTask($task: NewTaskInput!) {
    createTask(task: $task) {
      ...task
    }
  }
`;

const updateTask = gql`
  ${task}
  mutation updateTask($task: EditTaskInput!) {
    updateTask(task: $task) {
      ...task
    }
  }
`;

const deleteTask = gql`
  ${task}
  mutation deleteTask($taskId: String!) {
    deleteTask(taskId: $taskId) {
      ...task
    }
  }
`;

export default {
  getTasksByUserId: async (userId) => client.query(getTasksByUserId, { userId }),
  createTask: async (task) => client.query(createTask, { task }),
  updateTask: async (taskUpdates) => client.query(updateTask, { task: taskUpdates }),
  deleteTask: async (taskId) => client.query(deleteTask, { taskId }),
};
