import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { withRouter, Switch, Route } from 'react-router-dom';
import { pdfjs } from 'react-pdf';
// import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import documentsConnector from 'graphql/api/documents';
import NotFound from 'components/pages/NotFound';
import { withAppContext } from 'components/enhancers/AppContext';
import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';

import pdfCss from './documents.scss';
import OperationBar from './operationBar';
import SinglePageViewer from './singlePageViewer';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const config = getConfig();

const fetchAsBlob = (url) => fetch(url).then((response) => response.blob());

const getBlobBinaryData = (blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsArrayBuffer(blob);
  });

const DocumentViewer = ({ objectName, pdfData, app }) => {
  const [numPages, setNumPages] = React.useState(0);
  const [pageState, setPageState] = React.useState(
    Immutable.Map({
      scale: 1,
      width: 300,
      height: undefined,
    }),
  );
  const [pageNumber, setPageNumber] = React.useState(1);
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  // TODO: need a better way to get these sizes; unfortunately we can't
  // just set the page width via CSS.
  const getViewportDimensions = useCallback(() => {
    if (app.isMobile) {
      return {
        width: window.innerWidth,
        height: window.innerHeight - 90,
      };
    }

    return {
      width: window.innerWidth - 180,
      height: window.innerHeight - 69,
    };
  }, [app.isMobile]);

  const fitPageWidth = useCallback(() => {
    const { width: viewportWidth } = getViewportDimensions();
    let pageWidth = 0;
    if (app.isMobile) {
      pageWidth = viewportWidth - 20;
    } else {
      pageWidth = viewportWidth - 40;
    }
    setPageState(Immutable.Map({ scale: 1, width: pageWidth, height: undefined }));
  }, [app.isMobile, getViewportDimensions]);

  const fitPageHeight = useCallback(() => {
    const { height: viewportHeight } = getViewportDimensions();
    let pageHeight = 0;
    if (app.isMobile) {
      pageHeight = viewportHeight - 20;
    } else {
      pageHeight = viewportHeight - 40;
    }

    setPageState(Immutable.Map({ scale: 1, width: undefined, height: pageHeight }));
  }, [app.isMobile, getViewportDimensions]);

  React.useEffect(() => {
    const handleResize = () => {
      if (pageState.get('width')) {
        fitPageWidth();
      } else {
        fitPageHeight();
      }
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [fitPageHeight, fitPageWidth, pageState]);
  React.useEffect(() => {
    if (app.isMobile) {
      fitPageWidth();
    } else {
      fitPageHeight();
    }
  }, [app.isMobile, fitPageHeight, fitPageWidth]);
  return (
    <div className={pdfCss.documentViewer}>
      <div className={pdfCss.navbar}>
        <div className={`${pdfCss.headerGroup}`}>{objectName}.pdf</div>
        <OperationBar
          pageNumber={pageNumber}
          changePageScale={(newPageScale) => {
            const newPageState = pageState.set('scale', newPageScale);
            setPageState(newPageState);
          }}
          changePageNumber={(pageNumber) => setPageNumber(pageNumber)}
          scale={pageState.get('scale')}
          numPages={numPages}
        />
      </div>
      <SinglePageViewer
        pdfData={pdfData}
        onDocumentLoadSuccess={onDocumentLoadSuccess}
        pageNumber={pageNumber}
        pageState={pageState}
      />
    </div>
  );
};

DocumentViewer.propTypes = {
  objectName: PropTypes.string,
  pdfData: PropTypes.object,
  app: PropTypes.object,
};

const DocumentNotFound = () => {
  return (
    <div className={pdfCss.notFoundContainer}>
      <NotFound />
    </div>
  );
};

const FileWrapper = ({
  match: {
    params: { objectName },
  },
}) => {
  const [documentData, setDocumentData] = useState(new ArrayBuffer(0));
  const [error, setError] = useState(false);
  useEffect(() => {
    documentsConnector
      .getDocumentUrl(objectName, config.china ? 'china' : 'core')
      .then((doc) => fetchAsBlob(doc.getDocumentUrl.signedUrl))
      .then((blob) => getBlobBinaryData(blob))
      .then((binary) => setDocumentData(binary))
      .catch(() => setError(true));
  }, [objectName]);

  const Component = withAppContext(DocumentViewer);

  if (error) {
    return DocumentNotFound();
  }
  return (
    <div className={pdfCss.fileWrapperContainer}>
      <Component objectName={objectName} pdfData={documentData} />
    </div>
  );
};

FileWrapper.propTypes = {
  match: PropTypes.object,
};

const DocumentLibrary = ({ match: { path } }) => {
  return (
    <div>
      <Switch>
        <Route path={`${path}/:objectName`} render={(props) => <FileWrapper {...props} />} />
        <Route component={DocumentNotFound} />
      </Switch>
    </div>
  );
};

DocumentLibrary.propTypes = {
  match: PropTypes.object,
};

export default withRouter(DocumentLibrary);
