import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';
import { gql } from '@apollo/client';
import { createClient } from '../utils';

const { api } = getConfig();

const client = createClient(api.graphQLEndpoint);

const serviceWorkerType = gql`
  fragment serviceWorkerType on ServiceWorkerType {
    id
    userId
    device
    subscriptionDetails
    createdAt
    updatedAt
  }
`;

const subscribeServiceWorker = gql`
  ${serviceWorkerType}
  mutation subscribeServiceWorker($userId: ID!, $subscriptionDetails: String!, $device: String!) {
    subscribeServiceWorker(userId: $userId, subscriptionDetails: $subscriptionDetails, device: $device) {
      ...serviceWorkerType
    }
  }
`;

export default {
  subscribeServiceWorker: async ({ userId, subscriptionDetails, device }) => {
    const data = await client.query(subscribeServiceWorker, {
      userId,
      subscriptionDetails,
      device,
    });
    return data;
  },
};
