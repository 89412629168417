import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Body } from 'components/atoms/typography';
import InitialsAvatar from 'components/generic/InitialsAvatar';
import { getAvatarColour } from 'utils/calendarUtils';
import VIPStudentIndicator from 'components/organisms/VIPStudentIndicator';

import css from './styles.scss';

export default function Avatar(props) {
  const {
    firstName,
    lastName,
    dataTestId,
    userId = '',
    avatar,
    secondUser = {},
    large,
    otherPeople,
    className,
    showStudentType,
    style,
    size,
  } = props;
  const sizeStyles = typeof size === 'number' ? { width: size, height: size } : undefined;
  return (
    <div style={{ ...sizeStyles, ...style }} className={classNames(css.wrapper, className, large && css.large)}>
      <InitialsAvatar
        userId={userId}
        dataTestId={dataTestId}
        className={classNames(css.initialsAvatar, large ? css.large : css.small)}
        initials={firstName ? `${firstName[0]}${lastName[0]}` : ''}
        colorIndex={getAvatarColour(userId)}
        style={sizeStyles}
      />
      {avatar && (
        <div style={sizeStyles} className={classNames(css.imageAvatar, large ? css.large : css.small)}>
          <img src={avatar} alt="" />
        </div>
      )}
      {showStudentType && (
        <div className={css.vipTag}>
          <VIPStudentIndicator userId={userId} />
        </div>
      )}
      {otherPeople && secondUser.firstName ? (
        <div className={css.otherPeople}>
          <InitialsAvatar
            userId={userId}
            dataTestId={dataTestId}
            className={classNames(css.initialsAvatar, css.secondaryAvatar)}
            initials={secondUser ? `${secondUser.firstName[0]}${secondUser.lastName[0]}` : ''}
            colorIndex={getAvatarColour(userId)}
          />
          <img className={css.secondaryAvatar} src={secondUser.profileImageUrl} alt="" />
          <div className={css.overlayDiv} />
          <div className={css.otherPeopleTextContainer}>
            <Body className={css.otherPeopleText}>{`+${otherPeople}`}</Body>
          </div>
        </div>
      ) : null}
    </div>
  );
}

Avatar.defaultProps = {
  showStudentType: false,
};

Avatar.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  userId: PropTypes.string,
  dataTestId: PropTypes.string,
  avatar: PropTypes.string,
  secondUser: PropTypes.object,
  large: PropTypes.bool,
  otherPeople: PropTypes.number,
  className: PropTypes.string,
  showStudentType: PropTypes.bool,
  style: PropTypes.object,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
