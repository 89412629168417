import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { selectUserProfile } from 'selectors/meta';
import { fetchLoginUser, editLoginUser } from 'ducks/user';
import { getLoginUser, getLoginUserPermissions } from 'selectors/user';
import { compose } from 'redux';
import { withAppContext } from 'components/enhancers/AppContext';
import Settings from './Settings';

function SettingsContainer(props) {
  return <Settings {...props} />;
}

SettingsContainer.displayName = 'SettingsContainer';

SettingsContainer.propTypes = {
  userProfile: ImmutablePropTypes.map.isRequired,
  loginUser: ImmutablePropTypes.map.isRequired,
  loginUserPermissions: PropTypes.array.isRequired,
  fetchLoginUser: PropTypes.func.isRequired,
  onSaveTimezoneDetails: PropTypes.func.isRequired,
  onSaveNotificationDetails: PropTypes.func.isRequired,
};

export default compose(
  withAppContext,
  connect(
    (state) => ({
      userProfile: selectUserProfile(state),
      loginUser: getLoginUser(state),
      loginUserPermissions: getLoginUserPermissions(state),
    }),
    (dispatch) => ({
      fetchLoginUser: (userId) => dispatch(fetchLoginUser(userId)),
      onSaveTimezoneDetails: (loginUserId, details) => dispatch(editLoginUser(loginUserId, details)),
      onSaveNotificationDetails: (loginUserId, details) => dispatch(editLoginUser(loginUserId, details)),
    }),
  ),
)(SettingsContainer);
