import { firebaseReducer } from 'react-redux-firebase';
import isEqual from 'lodash/isEqual';
import { enableBatching } from 'redux-batched-actions';

/* We have a bunch of components that dispatch during render. This is not good,
 * but it works because all our reducers use immutablejs: eventually the actions
 * stop changing the store and everything quiets down.
 * Unfortunately react-redux-firebase doesn't use immutablejs, and if we use it
 * raw the dispatch->render->dispatch cycle thrashes the store state and react
 * gives up and renders nothing.
 * To fix this properly we would need to do an audit of all our components and
 * make sure they don't dispatch in silly places. In the mean time we will just
 * use lodash's deep isEqual method to make firebase return the same object when
 * nothing has changed.
 */
let oldState = {};
function cachedFirebaseReducer(state, action) {
  const newState = firebaseReducer(state, action);
  if (isEqual(oldState, newState)) {
    return oldState;
  }
  oldState = newState;
  return newState;
}

export default enableBatching(cachedFirebaseReducer);
