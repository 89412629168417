import React from 'react';
import PropTypes from 'prop-types';

export default function Pane(props) {
  return (
    <div className={props.className} style={props.style}>
      {props.children}
    </div>
  );
}

Pane.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  style: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
};
