import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/molecules/Modal';

const DTID_CANCEL_SESSION_MODAL_SUBMIT = 'cancelSessionModalSubmitButton';

export default function CancelConfirmedSessonModal(props) {
  const { isOpen, onClose, onBack, body, onSubmit } = props;

  return (
    <Modal
      isOpen={isOpen}
      onSubmit={onSubmit}
      onSubmitDataTestId={DTID_CANCEL_SESSION_MODAL_SUBMIT}
      onClose={onClose}
      onBack={onBack}
      title="Are you sure you want to cancel?"
      submitText="Cancel Anyway"
      secondarySubmitText="Undo"
      onSecondarySubmit={onClose}
    >
      <div>{body}</div>
    </Modal>
  );
}

CancelConfirmedSessonModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onBack: PropTypes.func,
  body: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
