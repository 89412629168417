import { normalize } from 'normalizr';
import Immutable from 'immutable';
import uuid from 'uuid';
import debounce from 'lodash/debounce';
import moment from 'moment';

import * as Logger from '@crimson-education/browser-logger';
import { messageStatus } from 'constants/messaging';
import conversationService from 'graphql/api/conversation';
import { threadEntity, threadParticipantEntity, messageEntity, connectionSchema } from 'schema';
import { selectUserId, getFilesUploaded } from 'selectors/meta';
import { getUsersWithId } from 'selectors/user';
import { updateMeta, updateCurrentThreadId } from 'ducks/meta';

import { updateUnReadMessageStatus } from 'ducks/globalState';
import componentKeys from 'constants/componentKeys';
import { formatGraphQLRequestError } from 'utils/graphql';
import {
  getIsFetchingThreads,
  getIsFetchingCommunityThreads,
  getIsConsumingThread,
  getThreadsEndCursor,
  getCommunityThreadsEndCursor,
  getThreadById,
  getThreadParticipantsMap,
  getMessageById,
  getMessagesHasNextPage,
  getThreadMessageCursor,
  getThreadByParticipants,
  getThreadGroupName,
} from './selectors';
import { MessageEventType } from '../../utils/MessageEventType';

/*
 * To support filtering of threads based on names in a group conversation,
 * we do this on the frontend. As Twilio only has a maximum of 1000 threads per user
 * we disable pagination for now by setting the page size to Twilio's upper limit.
 */
const THREADS_PAGE_SIZE = 20;
const MESSAGES_PAGE_SIZE = 20;

export const SET_USER_STATUS = 'messages/SET_USER_STATUS';
export const SET_USER_IS_TYPING = 'messages/SET_USER_IS_TYPING';
export function markUserOnlineStatus(userId, status) {
  return async (dispatch, getState) => {
    const state = getState();
    const onlineUsers = state.getIn(['messaging', 'onlineUsers']);
    if (!onlineUsers.includes(userId)) {
      const combined = new Set([...onlineUsers, userId]);
      const users = Array.from(combined);
      const payload = { users };
      dispatch({ type: SET_USER_STATUS, payload });
    } else if (!status) {
      const users = onlineUsers.splice(
        onlineUsers.findIndex((item) => item === userId),
        1,
      );
      const payload = { users };
      dispatch({ type: SET_USER_STATUS, payload });
    }
    const messaging = state.get('messaging');
    const users = messaging.getIn(['entities', 'user']);
    const typing = {
      online: status,
    };
    const payload = {
      userId,
      typing,
    };

    users
      .filter((x) => x.get('userId') === userId)
      .map((x) => {
        payload.user = x.merge(typing);
        return x;
      });

    dispatch({ type: SET_USER_IS_TYPING, payload });
  };
}

export const FETCH_THREADS = 'threads/FETCH_THREADS';
export const FETCH_THREADS_ERROR = 'threads/FETCH_THREADS_ERROR';
export const FETCH_THREADS_COMPLETE = 'threads/FETCH_THREADS_COMPLETE';
export const FETCH_COMMUNITY_THREADS = 'threads/FETCH_COMMUNITY_THREADS';
export const FETCH_COMMUNITY_THREADS_COMPLETE = 'threads/FETCH_COMMUNITY_THREADS_COMPLETE';
export function fetchThreads(userId, source, threadIds) {
  return async (dispatch, getState) => {
    const state = getState();
    if (source === 'DIRECT' && getIsFetchingThreads(state)) {
      return;
    }
    if (source === 'COMMUNITY' && getIsFetchingCommunityThreads(state)) {
      return;
    }

    // When retrieving specific threads, we should not include a start cursor
    let endCursor = null;
    if (source === 'COMMUNITY') {
      dispatch({ type: FETCH_COMMUNITY_THREADS });
      endCursor = threadIds && threadIds.length > 0 ? null : getCommunityThreadsEndCursor(state);
    } else {
      dispatch({ type: FETCH_THREADS });
      endCursor = threadIds && threadIds.length > 0 ? null : getThreadsEndCursor(state);
    }

    try {
      const result = await conversationService.getThreads(
        userId,
        THREADS_PAGE_SIZE,
        endCursor,
        MESSAGES_PAGE_SIZE,
        threadIds,
        source,
      );

      const payload = normalize(result, connectionSchema(threadEntity));
      if (source === 'COMMUNITY') {
        payload.communityThreadPageInfo = payload.result.pageInfo;
      } else {
        payload.threadPageInfo = payload.result.pageInfo;
      }
      delete payload.result;
      if (source === 'COMMUNITY') {
        dispatch({
          type: FETCH_COMMUNITY_THREADS_COMPLETE,
          payload,
        });
      } else {
        dispatch({
          type: FETCH_THREADS_COMPLETE,
          payload,
        });
      }

      if (threadIds && threadIds.length === 1) {
        Logger.trackEvent({
          message: MessageEventType.LoadMessage,
          metadata: {
            threadId: threadIds,
          },
        });
      }

      const usersIds = result.onlineUsers;
      if (usersIds && usersIds.length > 0) {
        const onlineUsers = state.getIn(['messaging', 'onlineUsers']);
        const combined = new Set([...onlineUsers, usersIds]);
        const users = Array.from(combined);
        const payload = { users };
        dispatch({ type: SET_USER_STATUS, payload });
        const messaging = state.get('messaging');
        const mUsers = messaging.getIn(['entities', 'user']);
        usersIds.forEach((userId) => {
          const typing = {
            online: true,
          };
          const payload = {
            userId,
            typing,
          };
          mUsers
            .filter((x) => x.get('userId') === userId)
            .map((x) => {
              payload.user = x.merge(typing);
              return x;
            });
          dispatch({ type: SET_USER_IS_TYPING, payload });
        });
      }

      // get and update the status of the unread mark on the nav bar of index page
      const threads = result.edges;
      if (threads && threads.length > 0) {
        const hasUnreadMessage = threads.some((thread) => thread.node.lastMessageIsRead === false);
        if (hasUnreadMessage) {
          dispatch(updateUnReadMessageStatus(true));
        }
      }
    } catch (error) {
      dispatch({ type: FETCH_THREADS_ERROR });
    }
  };
}

export const PREPARE_THREAD = 'threads/PREPARE_THREAD';
export function prepareThread(history, userIds, chatName) {
  return async (dispatch, getState) => {
    const state = getState();

    const currentUserId = selectUserId(state);
    const participantIds = [...userIds, currentUserId];
    // If a thread exists with the exact same list of participants, use that
    const thread = getThreadByParticipants(participantIds)(state);
    if (thread) {
      history.push(`/messages/${thread.get('id')}`);
      return;
    }

    const id = uuid.v4();
    const users = getUsersWithId(participantIds)(state).toList().toJS();

    const payload = normalize(
      {
        id,
        type: users.length > 2 ? 'group' : 'private',
        participants: {
          edges: users.map((x) => ({
            node: {
              userId: x.userId,
              threadId: id,
              user: x,
              resource: 'APP',
              active: true,
            },
          })),
        },
        isNew: true,
        lastMessageSentAt: null,
        chatName,
      },
      threadEntity,
    );

    delete payload.result;
    dispatch({ type: PREPARE_THREAD, payload });
    dispatch(updateCurrentThreadId(id));
    history.push(`/messages/${id}/new`);
  };
}

export const FETCH_MESSAGES = 'messages/FETCH_MESSAGES';
export const FETCH_MESSAGES_COMPLETE = 'messages/FETCH_MESSAGES_COMPLETE';
export function fetchMessages(threadId, source) {
  return async (dispatch, getState) => {
    const state = getState();

    const hasNext = getMessagesHasNextPage(threadId)(state);
    if (!hasNext) {
      return;
    }
    dispatch({ type: FETCH_MESSAGES, payload: { threadId } });
    if (source && source === 'COMMUNITY') {
      const thread = await conversationService.getThread(threadId);
      const newUsers = {};
      const threadParticipants = {};
      thread.participants.edges.forEach((p) => {
        const userId = p.node.userId;
        newUsers[`${userId}`] = p.node.user;
        threadParticipants[`${threadId}-${userId}`] = {
          userId: `${userId}`,
          threadId: `${threadId}`,
          lastConsumptionTimestamp: null,
          lastSeenMessageId: null,
          user: `${userId}`,
        };
      });
      dispatch({
        type: UPDATE_THREAD_USERS,
        payload: { newUsers },
      });
      dispatch({ type: UPDATE_THREAD_PARTICIPANT, payload: { threadParticipants } });
    }

    const cursor = getThreadMessageCursor(threadId)(state);
    const result = await conversationService.getMessages(threadId, MESSAGES_PAGE_SIZE, cursor);
    result.isFetchingMessages = false;
    const payload = normalize(result, threadEntity);
    delete payload.result;
    dispatch({ type: FETCH_MESSAGES_COMPLETE, payload });
  };
}

export const SEND_MESSAGE = 'messages/SEND_MESSAGE';
export const UPDATE_CONVERSATION = 'messages/UPDATE_CONVERSATION';
export const SEND_MESSAGE_COMPLETE = 'messages/SEND_MESSAGE_COMPLETE';
export const UPDATE_THREAD = 'messages/UPDATE_THREAD';
export function sendMessage(
  routerHistory,
  threadId,
  senderId,
  message,
  messageType,
  messageMetadata,
  conversationId,
  percent,
  transfer,
) {
  return async (dispatch, getState) => {
    const state = getState();
    const thread = getThreadById(threadId)(state);

    let fileInfo;
    if (messageType === 'FILE' || messageType === 'IMAGE') {
      const uploaded = getFilesUploaded(state);
      if (uploaded.size === 0) return;
      fileInfo = JSON.stringify(uploaded.toJS());
      dispatch(updateMeta(componentKeys.FILE_UPLOADED, new Immutable.Map()));
    }
    // For file and image transfer, we will show the percentage of upload. So we need to store the data in store
    const conversations = state.getIn(['messaging', 'conversations']);
    let messageId = null;
    // The conversationId is the ID from Tencent IM
    if (conversationId !== null) {
      const conversation = conversations.get(conversationId);
      if (conversation !== undefined) {
        messageId = conversation.get('messageId');
      }
      if (messageId === null) {
        messageId = uuid.v4();
        const payload = {
          [`${conversationId}`]: {
            messageId,
          },
        };
        dispatch({ type: UPDATE_CONVERSATION, payload });
      }
    } else if (messageId === null) {
      messageId = uuid.v4();
    }
    const msg = {
      messageId,
      threadId,
      message,
      senderId,
      createdAt: moment().format(),
      messageType,
      messageMetadata: fileInfo,
    };
    const payload = normalize({ ...msg, id: messageId, status: messageStatus.SENDING, percent }, messageEntity);
    payload.entities.threadParticipant = {
      [`${msg.threadId}-${msg.senderId}`]: {
        lastConsumptionTimestamp: msg.createdAt,
        lastSeenMessageId: msg.messageId,
      },
    };
    const chatName = getThreadGroupName(threadId)(state);
    delete payload.result;
    dispatch({ type: SEND_MESSAGE, payload });
    // When the file and image uploads 100%, then save the data into database
    if (percent === 1 && transfer) {
      let result = {};
      const resultPayload = {};
      try {
        if (thread.get('createdAt')) {
          // If the thread exists on the server
          result.message = await conversationService.createMessage(msg);
          dispatch(updateCurrentThreadId(null));
        } else {
          // We need to create the thread while we send the message
          const participantsByThread = getThreadParticipantsMap(state);
          const userIds = participantsByThread
            .get(threadId)
            .map((x) => x.get('userId'))
            .toJS();
          const threadAndMessage = {
            ...msg,
            userIds,
            createdBy: senderId,
            chatName,
          };

          result = await conversationService.createThread(threadAndMessage);
          dispatch(updateCurrentThreadId(null));
          routerHistory.replace(`/messages/${result.thread.id}`);
          if (threadId !== result.thread.id) {
            resultPayload.removeThread = threadId;
          }
        }

        Logger.trackEventSinceLastAction({
          message: MessageEventType.MessageSent,
          metadata: {
            messageLength: message.length,
          },
        });
      } catch (err) {
        const errorMessage = formatGraphQLRequestError(err);
        result.message = { id: messageId, status: messageStatus.ERROR, errorMessage };
        Logger.trackEvent({
          message: MessageEventType.SendMessageError,
          metadata: {
            error: err,
          },
        });
        Logger.reportError(err);
      }

      if (result.thread) {
        result.thread.isNew = false;
        result.thread.lastMessageIsRead = true;
        const threadResult = normalize(result.thread, threadEntity);

        delete threadResult.result;
        resultPayload.thread = threadResult;
      }
      if (result.message) {
        const messageResult = normalize(
          { status: messageStatus.SENT, ...result.message, withdraw: false },
          messageEntity,
        );
        delete messageResult.result;
        resultPayload.message = messageResult;
      }
      // const withdraw = false;
      const messagePayload = result.message;
      messagePayload.withdraw = false;
      dispatch({ type: SEND_MESSAGE_COMPLETE, payload: resultPayload });
      dispatch({ type: UPDATE_THREAD, payload: { messagePayload } });
    }
  };
}

export const RETRY_MESSAGE = 'messages/RETRY_MESSAGE';
export function retryMessage(routerHistory, messageId) {
  return async (dispatch, getState) => {
    const state = getState();
    const message = getMessageById(messageId)(state);

    dispatch({ type: RETRY_MESSAGE, payload: { messageId } });
    sendMessage(
      routerHistory,
      message.get('threadId'),
      message.get('senderId'),
      message.get('message'),
      message.get('messageType'),
      message.get('messageMetadata'),
      null,
      1,
      true,
    )(dispatch, getState);
  };
}

export const CONSUME_THREAD = 'thread/CONSUME_THREAD';
export const CONSUME_THREAD_COMPLETE = 'thread/CONSUME_THREAD_COMPLETE';
export function consumeThread(threadId) {
  return async (dispatch, getState) => {
    const state = getState();

    if (getIsConsumingThread(threadId)(state)) {
      return;
    }
    dispatch({ type: CONSUME_THREAD, payload: { threadId } });

    const result = await conversationService.consumeMessages(threadId);
    const payload = {
      threadId,
      lastSeenMessageId: result.lastSeenMessageId,
      data: normalize(result, threadParticipantEntity),
    };
    delete payload.data.result;
    dispatch({ type: CONSUME_THREAD_COMPLETE, payload });
  };
}

const doneTypingFunc = {};
export const SET_USER_IS_TYPING_END = 'messages/SET_USER_IS_TYPING_END';
export function updateTypingStatus(userId, threadId) {
  return async (dispatch, getState) => {
    const state = getState();

    const messaging = state.get('messaging');
    const users = messaging.getIn(['entities', 'user']);
    const typing = {
      threads: {
        [`${threadId}`]: {
          isTyping: true,
        },
      },
    };
    const payload = {
      userId,
      threadId,
      typing,
    };

    users
      .filter((x) => x.get('userId') === userId)
      .map((x) => {
        payload.user = x.merge(typing);
        return x;
      });
    dispatch({ type: SET_USER_IS_TYPING, payload });

    const typingThread = threadId + userId;
    if (!doneTypingFunc[typingThread]) {
      doneTypingFunc[typingThread] = debounce(() => {
        dispatch({ type: SET_USER_IS_TYPING_END, payload });
      }, 1000);
    }
    doneTypingFunc[typingThread]();
  };
}

export const CONSUME_MESSAGE = 'messages/CONSUME_MESSAGE';
export function messageConsumed(data) {
  return async (dispatch) => {
    const payload = {
      thread: `${data.threadId}-${data.consumer}`,
      threadId: `${data.threadId}`,
      lastSeenMessageId: data.lastSeenMessageId,
    };
    dispatch({ type: CONSUME_MESSAGE, payload });
  };
}

export const UPLOAD_FAIL = 'messages/UPLOAD_FAIL';
export function sendFileError(conversationId) {
  return async (dispatch, getState) => {
    const state = getState();
    const conversations = state.getIn(['messaging', 'conversations']);
    const conversation = conversations.get(`${conversationId}`);

    const messageId = conversation.get('messageId');

    dispatch({ type: UPLOAD_FAIL, payload: { messageId } });
  };
}

export function sendMessageError(conversationId, existMessageId, err) {
  return async (dispatch, getState) => {
    const state = getState();
    let messageId = existMessageId;
    if (conversationId !== null) {
      const conversations = state.getIn(['messaging', 'conversations']);
      const conversation = conversations.get(`${conversationId}`);
      messageId = conversation.get('messageId');
    }

    const result = {};
    const resultPayload = {};
    result.message = { id: messageId, status: messageStatus.TIM_ERROR };
    Logger.reportError(err);

    if (result.message) {
      const messageResult = normalize({ status: messageStatus.SENT, ...result.message }, messageEntity);
      delete messageResult.result;
      resultPayload.message = messageResult;
    }
    dispatch({ type: SEND_MESSAGE_COMPLETE, payload: resultPayload });
  };
}

export const UPDATE_CHAT_NAME = 'messages/UPDATE_CHAT_NAME';
export function editGroupName(threadId, chatName) {
  return async (dispatch) => {
    try {
      await conversationService.editGroupName(threadId, chatName);
      dispatch({ type: UPDATE_CHAT_NAME, payload: { threadId, chatName } });
    } catch (err) {
      Logger.reportError(err);
    }
  };
}

export const WITHDRAW_MESSAGE = 'messages/WITHDRAW_MESSAGE';
export function withdrawMessage(messageId, call) {
  return async (dispatch) => {
    const withdraw = true;
    if (call) {
      const messagePayload = await conversationService.withdrawMessage(messageId);
      dispatch({ type: UPDATE_THREAD, payload: { messagePayload } });
    }
    dispatch({ type: WITHDRAW_MESSAGE, payload: { messageId, withdraw } });
  };
}

export function createFlagReport(flaggedPersonUserId, flagType, flagReason, messageId) {
  return async () => {
    await conversationService.createFlagReport(flaggedPersonUserId, flagType, flagReason, messageId);
  };
}

export const UPDATE_THREAD_PEOPLE = 'messages/UPDATE_THREAD_PEOPLE';
export const UPDATE_THREAD_PARTICIPANT = 'messages/UPDATE_THREAD_PARTICIPANT';
export const UPDATE_THREAD_USERS = 'messages/UPDATE_THREAD_USERS';
export function addMorePeople(userIds, threadId) {
  return async (dispatch, getState) => {
    const state = getState();
    const thread = getThreadById(threadId)(state);

    const threadParticipants = {};
    const users = getUsersWithId(userIds)(state).toList().toJS();
    const newUsers = {};
    users.forEach((u) => {
      newUsers[`${u.userId}`] = {
        userId: `${u.userId}`,
        firstName: `${u.firstName}`,
        lastName: `${u.lastName}`,
        profileImageUrl: `${u.profileImageUrl}`,
        email: `${u.email}`,
        role: `${u.role}`,
      };
    });
    dispatch({ type: UPDATE_THREAD_USERS, payload: { newUsers } });
    if (thread.get('createdAt')) {
      const people = await conversationService.addMorePeople(userIds, threadId);
      people.forEach((p) => {
        threadParticipants[`${p.threadId}-${p.userId}`] = {
          userId: `${p.userId}`,
          threadId: `${p.threadId}`,
          lastConsumptionTimestamp: p.lastConsumptionTimestamp,
          lastSeenMessageId: p.lastSeenMessageId,
          user: `${p.userId}`,
        };
        return p;
      });
      const resultPayload = {};
      if (people) {
        const messageResult = normalize(
          { status: messageStatus.SENT, ...people[0].message, withdraw: false },
          messageEntity,
        );
        delete messageResult.result;
        resultPayload.message = messageResult;
      }
      // const withdraw = false;
      const messagePayload = people[0].message;
      messagePayload.withdraw = false;
      dispatch({ type: SEND_MESSAGE_COMPLETE, payload: resultPayload });
      dispatch({ type: UPDATE_THREAD, payload: { messagePayload } });
      // dispatch({ type: UPDATE_THREAD_PEOPLE, payload: { threadId, participants: people.participants } });
    } else {
      userIds.forEach((u) => {
        threadParticipants[`${threadId}-${u}`] = {
          userId: `${u}`,
          threadId: `${threadId}`,
          lastConsumptionTimestamp: null,
          lastSeenMessageId: null,
          user: `${u}`,
        };
        return u;
      });
    }
    dispatch({ type: UPDATE_THREAD_PARTICIPANT, payload: { threadParticipants } });
  };
}

export const INACTIVE_THREAD_PARTICIPANT = 'messages/INACTIVE_THREAD_PARTICIPANT';
export const REMOVE_THREAD_PARTICIPANT = 'messages/REMOVE_THREAD_PARTICIPANT';
export const REMOVE_THREAD = 'messages/REMOVE_THREAD';
export function removePeopleFromGroup(history, userId, threadId, currentUserId) {
  return async (dispatch, getState) => {
    const state = getState();
    const thread = getThreadById(threadId)(state);
    if (thread.get('createdAt')) {
      const remove = await conversationService.removePeopleFromGroup(userId, threadId);
      const resultPayload = {};
      if (remove.message) {
        const messageResult = normalize(
          { status: messageStatus.SENT, ...remove.message, withdraw: false },
          messageEntity,
        );
        delete messageResult.result;
        resultPayload.message = messageResult;
      }
      // const withdraw = false;
      const messagePayload = remove.message;
      messagePayload.withdraw = false;
      dispatch({ type: SEND_MESSAGE_COMPLETE, payload: resultPayload });
      dispatch({ type: UPDATE_THREAD, payload: { messagePayload } });
      dispatch({ type: INACTIVE_THREAD_PARTICIPANT, payload: { threadParticipantId: `${threadId}-${userId}` } });
    } else {
      dispatch({ type: REMOVE_THREAD_PARTICIPANT, payload: { participant: `${threadId}-${userId}` } });
    }
    if (userId === currentUserId) {
      dispatch({ type: REMOVE_THREAD, payload: { removeThread: threadId } });
      history.push('/messages');
    }
  };
}

export const ACK_MESSAGE = 'messages/ACK_MESSAGE';
export function ackMessage(id, from, to, span) {
  return async () => {
    await conversationService.ackMessage(id, from, to, span);
  };
}

export function fetchCommunityThreads(userId, name, endCursor) {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const result = await conversationService.getCommunityThreads(userId, name, THREADS_PAGE_SIZE, endCursor);

      const payload = normalize(result, connectionSchema(threadEntity));
      payload.communityThreadPageInfo = payload.result.pageInfo;

      delete payload.result;
      // get and update the status of the unread mark on the nav bar of index page
      dispatch({
        type: FETCH_COMMUNITY_THREADS_COMPLETE,
        payload,
      });
      const threads = result.edges;
      if (threads && threads.length > 0) {
        const hasUnreadMessage = threads.some((thread) => thread.node.lastMessageIsRead === false);
        if (hasUnreadMessage) {
          dispatch(updateUnReadMessageStatus(true));
        }
      }
    } catch (error) {
      dispatch({ type: FETCH_THREADS_ERROR });
    }
  };
}
