/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

export default function Time({ className }) {
  return (
    <svg className={className} viewBox="0 0 563 241" preserveAspectRatio="xMidYMin slice">
    <defs>
    <polygon id="path-1" points="14.5444 9.86608 10.03576 9.86608 10.03576 14.27776 8.50864 14.27776 8.50864 9.86608 4 9.86608 4 8.43592 8.50864 8.43592 8.50864 4 10.03576 4 10.03576 8.43592 14.5444 8.43592"></polygon>
    </defs>
    <g id="modal-header-/-time-copy" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Asset-1@1x" transform="translate(-1.000000, -4.000000)">
            <g transform="translate(-0.000000, 0.000000)">
                <rect id="Rectangle" fill="#00A896" x="0" y="-2.84217094e-14" width="566.002186" height="244.892459"></rect>
                <path d="M231.66169,244.820765 L499.915247,244.820765 C542.02299,218.665355 527.463348,193.107101 515.731837,184.030338 C499.810501,171.728935 506.409476,179.133663 492.687799,167.429416 C464.301733,143.304335 510.075573,90.3963598 472.786129,74.5120243 C448.485143,64.2409501 422.089244,96.7262078 392.96996,72.8399889 C361.755762,47.1623036 332.741223,43.2210775 308.649728,76.3034907 C290.005006,101.981176 262.456905,87.8883069 244.85964,85.3802539 C220.453908,81.9167521 199.190545,113.924285 222.863058,147.006699 C237.108463,166.712829 148.703152,196.331741 231.66169,244.820765 Z" id="Path" fill="#17C9AB"></path>
                <path d="M120.983607,70.9081967 C120.804372,69.2054645 120.266667,67.9508197 119.101639,67.3234973 C115.51694,65.4415301 106.644809,68.5781421 102.970492,73.3278689 C97.8622951,80.0491803 102.522404,90.3551913 107.540984,90.9825137 C112.559563,91.6098361 121.969399,79.7803279 120.983607,70.9081967 Z" id="Path" fill="#11BFA6"></path>
                <path d="M462.42623,126.829508 C461.257046,126.836532 460.131424,126.386283 459.289617,125.574863 C458.631331,124.895911 457.726011,124.512694 456.780328,124.512694 C455.834645,124.512694 454.929324,124.895911 454.271038,125.574863 C452.510035,127.236434 449.758817,127.236434 447.997814,125.574863 C447.339528,124.895911 446.434208,124.512694 445.488525,124.512694 C444.542841,124.512694 443.637521,124.895911 442.979235,125.574863 C442.127421,126.370666 441.008283,126.818321 439.842623,126.829508 L439.842623,125.933333 C440.746292,125.929903 441.613509,125.576593 442.262295,124.947541 C443.099006,124.063073 444.271337,123.574602 445.488525,123.603279 C446.676887,123.588792 447.816067,124.077012 448.625137,124.947541 C449.306445,125.582213 450.203304,125.93455 451.134426,125.933333 C452.062267,125.917586 452.953264,125.567551 453.643716,124.947541 C455.295864,123.257687 457.989096,123.178475 459.737705,124.768306 L459.91694,124.947541 C460.607392,125.567551 461.498389,125.917586 462.42623,125.933333 C463.357352,125.93455 464.25421,125.582213 464.935519,124.947541 L465.025137,124.947541 C466.791797,123.264333 469.580503,123.304172 471.298361,125.037158 C471.947147,125.66621 472.814364,126.019521 473.718033,126.022951 L473.718033,126.919126 C472.552373,126.907938 471.433235,126.460283 470.581421,125.664481 C469.923135,124.985529 469.017814,124.602311 468.072131,124.602311 C467.126448,124.602311 466.221128,124.985529 465.562842,125.664481 C464.702149,126.435332 463.581441,126.851595 462.42623,126.829508 Z" id="Path" fill="#FFFFFF"></path>
                <circle id="Oval" stroke="#DDA65F" strokeWidth="1.05" cx="46.6010929" cy="71.0874317" r="1.79234973"></circle>
                <circle id="Oval" stroke="#FFFFFF" cx="75.726776" cy="126.471038" r="1.79234973"></circle>
                <circle id="Oval" stroke="#FEE863" strokeWidth="1.05" cx="471.029508" cy="56.7486339" r="1.79234973"></circle>
                <circle id="Oval" stroke="#257A8C" strokeWidth="0.79" cx="496.032787" cy="115.896175" r="1.79234973"></circle>
                <g id="Group" opacity="0.7" transform="translate(288.568306, 79.601093)" stroke="#FFFFFF" strokeWidth="0.69">
                    <path d="M0.358469945,84.2404372 L1.07540984,2.95737705 C0.896174863,2.06120219 1.79234973,0.627322404 2.5989071,0.627322404 L107.989071,2.33005464 C108.287796,2.33005464 108.467031,2.50928962 108.526776,2.86775956 L109.602186,84.1508197 C109.781421,85.0469945 108.885246,86.4808743 108.078689,86.4808743 L0.896174863,84.7781421 C0.597449909,84.7781421 0.418214936,84.5989071 0.358469945,84.2404372 Z" id="Path"></path>
                    <path d="M108.795628,20.1639344 L108.526776,2.86775956 C108.407286,2.50928962 108.228051,2.33005464 107.989071,2.33005464 L2.5989071,0.627322404 C1.79234973,0.627322404 0.896174863,2.06120219 1.07540984,2.95737705 L1.07540984,20.3431694 L108.795628,20.1639344 Z" id="Path"></path>
                    <path d="M16.4896175,0.448087432 L15.5934426,85.315847" id="Path"></path>
                    <path d="M31.9934426,0.806557377 L31.0076503,85.5846995" id="Path"></path>
                    <path d="M47.4972678,1.07540984 L46.5114754,85.8535519" id="Path"></path>
                    <path d="M62.9114754,1.3442623 L62.0153005,86.2120219" id="Path"></path>
                    <path d="M78.4153005,1.70273224 L77.5191257,86.4808743" id="Path"></path>
                    <path d="M93.9191257,1.9715847 L93.0229508,86.7497268" id="Path"></path>
                    <path d="M0.716939891,35.936612 L108.616393,38.0874317" id="Path"></path>
                    <path d="M0.896174863,53.0535519 L108.795628,54.8459016" id="Path"></path>
                    <path d="M0.896174863,68.3781421 L108.795628,70.4393443" id="Path"></path>
                </g>
                <polygon id="Path" stroke="#E3636F" strokeWidth="1.12" fill="#FFFFFF" points="320.252459 81.4830601 335.308197 81.4830601 334.860109 117.061202 319.804372 117.240437"></polygon>
                <g id="Group-2" transform="translate(291.000000, 76.000000)">
                    <g id="Group" transform="translate(5.819672, 0.912568)" stroke="#E3636F" strokeWidth="1.12">
                        <path d="M29.304918,0.179234973 L0.627322404,34.2338798" id="Path"></path>
                        <path d="M31.2765027,1.9715847 L2.68852459,36.115847" id="Path"></path>
                        <path d="M33.3377049,3.85355191 L4.66010929,37.9081967" id="Path"></path>
                        <path d="M35.3989071,5.64590164 L6.72131148,39.7901639" id="Path"></path>
                        <path d="M37.3704918,7.52786885 L8.78251366,41.5825137" id="Path"></path>
                        <path d="M39.431694,9.40983607 L10.7540984,43.4644809" id="Path"></path>
                        <path d="M41.4032787,11.2021858 L12.8153005,45.2568306" id="Path"></path>
                        <path d="M43.4644809,13.084153 L14.7868852,47.1387978" id="Path"></path>
                        <path d="M45.5256831,14.8765027 L16.8480874,48.9311475" id="Path"></path>
                        <path d="M47.4972678,16.7584699 L18.9092896,50.8131148" id="Path"></path>
                        <path d="M49.5584699,18.5508197 L20.8808743,52.695082" id="Path"></path>
                    </g>
                    <circle id="Oval" fill="#FFFFFF" cx="10.4797814" cy="22.779235" r="10.2163934"></circle>
                    <polyline id="Path" stroke="#1A7A3A" strokeWidth="1.99" strokeLinecap="round" strokeLinejoin="round" points="6.26775956 24.3027322 8.86666667 26.6327869 14.9606557 19.9114754"></polyline>
                </g>
                <g id="Group" opacity="0.7" transform="translate(293.049180, 61.677596)" stroke="#FFFFFF" strokeWidth="0.69">
                    <ellipse id="Oval" transform="translate(4.998819, 7.417968) rotate(-62.500000) translate(-4.998819, -7.417968) " cx="4.99881901" cy="7.41796831" rx="3.49508197" ry="3.67431694"></ellipse>
                    <ellipse id="Oval" transform="translate(20.098243, 7.081739) rotate(-62.500000) translate(-20.098243, -7.081739) " cx="20.0982428" cy="7.08173916" rx="3.49508197" ry="3.67431694"></ellipse>
                    <ellipse id="Oval" transform="translate(35.156286, 6.825002) rotate(-62.500000) translate(-35.156286, -6.825002) " cx="35.1562858" cy="6.8250017" rx="3.49508197" ry="3.67431694"></ellipse>
                    <ellipse id="Oval" transform="translate(50.325075, 6.481917) rotate(-62.500000) translate(-50.325075, -6.481917) " cx="50.3250754" cy="6.48191656" rx="3.49508197" ry="3.67431694"></ellipse>
                    <ellipse id="Oval" transform="translate(80.572160, 5.799332) rotate(-62.500000) translate(-80.572160, -5.799332) " cx="80.5721596" cy="5.79933246" rx="3.49508197" ry="3.67431694"></ellipse>
                    <ellipse id="Oval" transform="translate(95.671583, 5.463103) rotate(-62.500000) translate(-95.671583, -5.463103) " cx="95.6715834" cy="5.46310331" rx="3.49508197" ry="3.67431694"></ellipse>
                    <ellipse id="Oval" transform="translate(65.434625, 6.104307) rotate(-62.500000) translate(-65.434625, -6.104307) " cx="65.4346249" cy="6.10430666" rx="3.49508197" ry="3.67431694"></ellipse>
                </g>
                <g id="Group" opacity="0.7" transform="translate(158.622951, 78.704918)" stroke="#FFFFFF" strokeWidth="0.69">
                    <path d="M107.989071,84.2404372 L107.809836,2.95737705 C107.989071,1.9715847 107.092896,0.627322404 106.286339,0.627322404 L1.70273224,2.50928962 C1.43751111,2.50558406 1.20920927,2.69583559 1.16502732,2.95737705 L0.0896174863,84.2404372 C-0.0896174863,85.2262295 0.806557377,86.5704918 1.61311475,86.5704918 L107.451366,84.6885246 C107.716587,84.6922301 107.944889,84.5019786 107.989071,84.2404372 Z" id="Path"></path>
                    <path d="M0.896174863,20.2535519 L1.16502732,2.95737705 C1.20920927,2.69583559 1.43751111,2.50558406 1.70273224,2.50928962 L106.286339,0.627322404 C107.092896,0.627322404 107.989071,1.9715847 107.809836,2.95737705 L107.989071,20.2535519 L0.896174863,20.2535519 Z" id="Path"></path>
                    <path d="M92.4852459,0.448087432 L93.4710383,85.315847" id="Path"></path>
                    <path d="M77.1606557,0.806557377 L78.0568306,85.5846995" id="Path"></path>
                    <path d="M61.7464481,1.16502732 L62.7322404,85.9431694" id="Path"></path>
                    <path d="M46.4218579,1.43387978 L47.3180328,86.2120219" id="Path"></path>
                    <path d="M31.0076503,1.79234973 L31.9038251,86.5704918" id="Path"></path>
                    <path d="M16.579235,86.8393443 L15.6830601,2.06120219" id="Path"></path>
                    <path d="M108.168306,35.936612 L1.07540984,38.1770492" id="Path"></path>
                    <path d="M108.437158,52.9639344 L0.896174863,54.9355191" id="Path"></path>
                    <path d="M108.526776,68.3781421 L0.896174863,70.6185792" id="Path"></path>
                </g>
                <g id="Group" transform="translate(204.327869, 93.043716)" stroke="#FFFFFF" strokeWidth="1.11">
                    <path d="M21.6874317,0.358469945 L51.7989071,31.9038251" id="Path"></path>
                    <path d="M19.536612,2.06120219 L49.6480874,33.6065574" id="Path"></path>
                    <path d="M17.3857923,3.76393443 L47.4972678,35.3092896" id="Path"></path>
                    <path d="M15.2349727,5.46666667 L45.4360656,37.0120219" id="Path"></path>
                    <path d="M13.084153,7.16939891 L43.2852459,38.7147541" id="Path"></path>
                    <path d="M11.0229508,8.87213115 L41.1344262,40.4174863" id="Path"></path>
                    <path d="M8.87213115,10.5748634 L38.9836066,42.1202186" id="Path"></path>
                    <path d="M6.72131148,12.2775956 L36.8327869,43.9125683" id="Path"></path>
                    <path d="M4.5704918,14.0699454 L34.7715847,45.6153005" id="Path"></path>
                    <path d="M2.41967213,15.7726776 L32.620765,47.3180328" id="Path"></path>
                    <path d="M0.358469945,17.4754098 L30.4699454,49.020765" id="Path"></path>
                </g>
                <polygon id="Path" stroke="#FFFFFF" points="236.231694 98.9584699 220.369399 98.9584699 220.817486 131.937705 236.679781 132.11694"></polygon>
                <g id="Group" opacity="0.7" transform="translate(164.000000, 61.677596)" stroke="#FFFFFF" strokeWidth="1.11">
                    <ellipse id="Oval" transform="translate(95.774613, 7.214410) rotate(-26.600000) translate(-95.774613, -7.214410) " cx="95.7746133" cy="7.21440959" rx="3.67431694" ry="3.49508197"></ellipse>
                    <ellipse id="Oval" transform="translate(80.718074, 6.913914) rotate(-26.600000) translate(-80.718074, -6.913914) " cx="80.7180737" cy="6.91391407" rx="3.67431694" ry="3.49508197"></ellipse>
                    <ellipse id="Oval" transform="translate(65.501270, 6.514438) rotate(-26.600000) translate(-65.501270, -6.514438) " cx="65.5012704" cy="6.51443767" rx="3.67431694" ry="3.49508197"></ellipse>
                    <ellipse id="Oval" transform="translate(50.444731, 6.213942) rotate(-26.600000) translate(-50.444731, -6.213942) " cx="50.4447308" cy="6.21394216" rx="3.67431694" ry="3.49508197"></ellipse>
                    <ellipse id="Oval" transform="translate(20.171388, 5.513970) rotate(-26.600000) translate(-20.171388, -5.513970) " cx="20.1713878" cy="5.51397025" rx="3.67431694" ry="3.49508197"></ellipse>
                    <ellipse id="Oval" transform="translate(5.074721, 5.043725) rotate(-26.600000) translate(-5.074721, -5.043725) " cx="5.07472118" cy="5.04372539" rx="3.67431694" ry="3.49508197"></ellipse>
                    <ellipse id="Oval" transform="translate(35.308059, 5.863956) rotate(-26.600000) translate(-35.308059, -5.863956) " cx="35.3080593" cy="5.8639562" rx="3.67431694" ry="3.49508197"></ellipse>
                </g>
                <g id="Group-3" transform="translate(343.000000, 95.000000)">
                    <path d="M29.9945355,23.9431694 C29.904918,27.7967213 25.9617486,25.6459016 24.3486339,23.7639344 C22.7355191,21.8819672 22.9147541,18.6557377 20.136612,15.8775956 C13.9530055,9.69398907 15.8349727,10.3213115 18.1650273,9.42513661 C20.495082,8.52896175 23.273224,13.9956284 24.9759563,15.0710383 C28.228193,16.8045383 30.1843098,20.2626733 29.9945355,23.9431694 Z" id="Path" fill="#FAC1C2"></path>
                    <path d="M8.57595628,4.58579235 C8.57595628,4.58579235 13.0568306,6.28852459 9.83060109,3.06229508 C8.93442623,2.16612022 7.32131148,1.0010929 6.69398907,1.89726776 C6.06666667,2.79344262 5.34972678,0.732240437 5.26010929,0.821857923 C5.89724815,1.72661292 6.5854313,2.59432211 7.32131148,3.42076503 C7.32131148,3.42076503 6.15628415,2.79344262 5.97704918,2.9726776 C5.79781421,3.15191257 6.69398907,3.95846995 8.57595628,4.58579235 Z" id="Path" fill="#9C2F2C"></path>
                    <path d="M19.2404372,11.7551913 C17.8961749,13.4579235 16.1038251,12.0240437 14.6699454,10.7693989 C13.2360656,9.5147541 12.3398907,8.08087432 10.4579235,6.01967213 C8.57595628,3.95846995 7.41092896,3.51038251 8.93442623,3.24153005 C10.4579235,2.9726776 12.4295082,5.39234973 13.7737705,6.1989071 C15.1180328,7.00546448 21.0327869,9.24590164 19.2404372,11.7551913 Z" id="Path" fill="#9C2F2C"></path>
                    <path d="M10.4579235,6.01967213 C12.3398907,8.08087432 12.9672131,9.33551913 14.6699454,10.7693989 C16.3726776,12.2032787 17.8961749,13.4579235 19.2404372,11.7551913 C20.5846995,10.052459 15.5661202,7.09508197 13.7737705,6.1989071 L12.5191257,5.30273224" id="Path" fill="#FAC1C2"></path>
                    <path d="M10.8163934,6.91584699 C10.8163934,6.91584699 10.2786885,7.27431694 10.0994536,6.01967213 C9.92021858,4.76502732 10.4579235,4.76502732 11.4437158,4.31693989 C12.4295082,3.86885246 13.3256831,5.30273224 12.9672131,5.5715847 C12.6087432,5.84043716 12.1606557,7.09508197 10.8163934,6.91584699 Z" id="Path" fill="#E2626F"></path>
                    <path d="M20.6743169,33.9803279 C21.5704918,33.084153 24.9759563,39.536612 24.9759563,39.536612 C24.9759563,39.536612 28.3814208,34.3387978 31.0699454,41.3289617 C33.1311475,46.6163934 27.4852459,51.6349727 29.3672131,54.3234973 C32.8622951,59.073224 31.4284153,64.8983607 31.6972678,72.6054645 C31.8765027,76.010929 30.1737705,75.0251366 29.9945355,74.4874317 C28.6502732,66.3322404 23.1836066,56.9224044 22.7355191,55.3092896 C20.5846995,47.3333333 16.9103825,39.9846995 17.8065574,37.1169399 L20.6743169,33.9803279 Z" id="Path" fill="#222A4E"></path>
                    <path d="M31.2491803,73.0535519 C29.9286803,72.9641203 28.6021516,73.0545654 27.3060109,73.3224044 C26.1409836,73.7704918 25.6032787,74.8459016 25.9617486,75.3836066 C26.3202186,75.9213115 34.2961749,78.4306011 32.7726776,74.3978142 C32.7726776,74.3978142 31.4284153,74.8459016 31.2491803,73.0535519 Z" id="Path" fill="#FFFFFF"></path>
                    <path d="M29.1879781,73.7704918 C29.1879781,73.7704918 27.8437158,68.7519126 28.2021858,67.9453552 C28.5606557,67.1387978 30.8907104,69.4688525 31.6972678,66.9595628 C31.6972678,66.9595628 34.4754098,75.1147541 32.7726776,76.1901639 C31.0699454,77.2655738 31.7868852,76.4590164 31.2491803,76.3693989 C30.7114754,76.2797814 29.1879781,73.7704918 29.1879781,73.7704918 Z" id="Path" fill="#FFFFFF"></path>
                    <path d="M19.957377,37.1169399 C21.0327869,36.4896175 23.273224,36.8480874 23.273224,36.8480874 C23.273224,36.8480874 29.3672131,38.1027322 28.2021858,44.8240437 C27.0371585,51.5453552 20.7639344,56.0262295 21.6601093,59.1628415 C23.452459,64.8087432 20.2262295,69.8273224 18.0754098,77.2655738 C17.0896175,80.4918033 15.7453552,79.0579235 15.8349727,78.5202186 C17.0896175,70.3650273 15.0284153,59.7005464 15.1180328,57.9978142 C15.2076503,56.295082 18.2546448,32.2775956 16.6415301,34.6972678 C16.6415301,34.6972678 18.6131148,37.8338798 19.957377,37.1169399 Z" id="Path" fill="#222A4E"></path>
                    <path d="M17.4480874,77.4448087 C16.2270342,77.0193714 14.9659126,76.7191044 13.684153,76.5486339 C12.4295082,76.5486339 11.5333333,77.4448087 11.7125683,78.0721311 C11.8918033,78.6994536 18.6131148,83.6284153 18.5234973,79.326776 C18.5234973,79.326776 17.0896175,79.2371585 17.4480874,77.4448087 Z" id="Path" fill="#FFFFFF"></path>
                    <path d="M15.2972678,77.5344262 C15.2972678,77.5344262 15.5661202,72.336612 16.2830601,71.7092896 C17,71.0819672 18.2546448,73.9497268 19.8677596,71.8885246 C19.8677596,71.8885246 19.8677596,80.4918033 17.8961749,80.9398907 C15.9245902,81.3879781 16.9103825,80.9398907 16.4622951,80.6710383 C16.0142077,80.4021858 15.2972678,77.5344262 15.2972678,77.5344262 Z" id="Path" fill="#FFFFFF"></path>
                    <path d="M16.5519126,43.031694 C16.5519126,43.031694 24.5278689,47.8710383 31.2491803,41.3289617 C31.2491803,41.3289617 29.6360656,38.8196721 29.9945355,33.7114754 C30.442623,26.7213115 29.9945355,24.0327869 29.1879781,21.9715847 C28.3814208,19.9103825 17.3584699,19.3726776 16.1934426,24.7497268 C15.5661202,27.6174863 16.1934426,32.8153005 16.1934426,32.8153005 C16.4918234,36.2121338 16.6114784,39.6223012 16.5519126,43.031694 Z" id="Path" fill="#FAC1C2"></path>
                    <path d="M19.957377,24.3912568 C19.4196721,20.6273224 15.8349727,23.2262295 14.4907104,25.3770492 C13.1464481,27.5278689 12.7879781,30.126776 11.1748634,33.7114754 C10.4579235,35.4142077 10.189071,37.2961749 12.5191257,37.9234973 C14.8491803,38.5508197 14.5803279,35.1453552 16.1934426,33.8907104 C19.1711786,31.7120625 20.6347951,28.0181733 19.957377,24.3912568 Z" id="Path" fill="#FAC1C2"></path>
                    <path d="M3.64699454,42.1355191 C3.64699454,42.1355191 8.12786885,40.4327869 4.99125683,43.6590164 C3.91584699,44.7344262 1.04808743,46.7060109 0.331147541,45.8994536 C-0.38579235,45.0928962 2.39234973,43.3005464 2.39234973,43.3005464 C2.39234973,43.3005464 1.2273224,43.9278689 1.13770492,43.7486339 C1.04808743,43.5693989 1.76502732,42.7628415 3.64699454,42.1355191 Z" id="Path" fill="#9C2F2C"></path>
                    <path d="M6.24590164,42.673224 C6.93288464,42.0569536 7.68399395,41.5161549 8.4863388,41.0601093 C10.189071,40.0743169 15.7453552,37.9234973 13.863388,35.5038251 C11.9814208,33.084153 10.8163934,35.1453552 9.29289617,36.4 C7.76939891,37.6546448 7.05245902,39.1781421 5.08087432,41.2393443" id="Path" fill="#FAC1C2"></path>
                    <path d="M4.27431694,41.6874317 C4.27431694,41.6874317 4.81202186,40.3431694 5.61857923,40.7016393 C6.42513661,41.0601093 6.15628415,41.5978142 6.87322404,42.1355191 C7.59016393,42.673224 6.33551913,43.210929 6.06666667,43.3901639 C5.79781421,43.5693989 5.61857923,43.031694 5.08087432,42.3147541 C4.5431694,41.5978142 4.27431694,42.3147541 4.27431694,41.6874317 Z" id="Path" fill="#E2626F"></path>
                    <path d="M19.3300546,17.8491803 L19.7781421,20.8961749 C20.8313874,20.629377 21.9214941,20.5385348 23.0043716,20.6273224 C24.7071038,20.8961749 24.5278689,20.0896175 24.5278689,20.0896175 C24.5278689,20.0896175 23.452459,16.684153 23.5420765,15.6983607 C23.631694,14.7125683 19.3300546,17.8491803 19.3300546,17.8491803 Z" id="Path" fill="#9C2F2C"></path>
                    <path d="M24.2590164,19.3726776 L25.6032787,20 C25.6032787,20 25.4240437,21.5234973 24.2590164,21.6131148 C23.0939891,21.7027322 22.1081967,21.2546448 20.495082,21.3442623 L18.7027322,21.3442623 L19.5989071,19.6415301 C20.7782494,19.8791719 21.9864414,19.9395815 23.1836066,19.820765 C24.4382514,19.6415301 24.2590164,19.3726776 24.2590164,19.3726776 Z" id="Path" fill="#E2626F"></path>
                    <path d="M22.3770492,16.863388 C20.8535519,18.5661202 17.5377049,17.6699454 17,17.4907104 C16.4622951,17.3114754 16.6415301,17.3114754 16.6415301,17.1322404 L16.6415301,16.0568306 C16.5963017,15.669805 16.5963017,15.2788288 16.6415301,14.8918033 C16.7060863,14.7515217 16.7367835,14.5980359 16.7311475,14.4437158 C16.7311475,14.2644809 16.1934426,13.9956284 16.1934426,13.4579235 C16.3256062,13.1612394 16.5442503,12.9113604 16.820765,12.7409836 C17.0896175,12.4721311 17.6273224,12.3825137 17.7169399,12.0240437 L17.7169399,11.6655738 C17.7205476,10.9722078 17.9752445,10.3036287 18.4338798,9.78360656 C19.0439533,9.15147458 19.8854271,8.79546643 20.7639344,8.79781421 C22.1472463,8.71170267 23.4742,9.3581673 24.2590164,10.5005464 C24.4382514,10.6797814 23.273224,13.189071 23.452459,13.189071" id="Path" fill="#9C2F2C"></path>
                    <path d="M21.3016393,18.7453552 L23.9005464,17.5803279 C23.9278746,16.7941561 24.1429741,16.0259438 24.5278689,15.3398907 C25.1551913,13.9060109 26.4098361,11.1278689 26.3202186,10.5901639 C26.2306011,10.052459 18.7923497,7.99125683 18.7923497,7.99125683 L17.8961749,10.6797814 C17.8961749,10.6797814 18.9715847,12.1136612 18.8819672,12.8306011 C18.8666024,13.4910774 18.8067216,14.1497662 18.7027322,14.8021858 C18.7027322,14.8021858 19.3300546,13.368306 20.0469945,13.6371585 C20.7639344,13.9060109 21.0327869,15.0710383 19.4196721,15.7879781 L20.4054645,16.1464481 C20.9431694,16.1464481 21.3016393,18.7453552 21.3016393,18.7453552 Z" id="Path" fill="#40464E"></path>
                    <path d="M26.1409836,10.8590164 C26.1409836,10.8590164 25.6032787,11.3071038 23.9901639,10.8590164 C22.3770492,10.410929 18.6131148,8.79781421 18.7923497,7.99125683 C18.9715847,7.18469945 22.1081967,7.90163934 23.631694,8.52896175 C25.1551913,9.15628415 26.947541,10.5005464 26.1409836,10.8590164 Z" id="Path" fill="#333456"></path>
                </g>
                <path d="M102.074317,80.4972678 C100.905133,80.5042914 99.7795114,80.0540427 98.9377049,79.242623 C98.2794187,78.563671 97.3740984,78.1804536 96.4284153,78.1804536 C95.4827322,78.1804536 94.5774119,78.563671 93.9191257,79.242623 C93.0351903,80.0443114 91.8862144,80.4911353 90.6928962,80.4972678 C89.5272363,80.4860804 88.408098,80.0384251 87.5562842,79.242623 C86.8979979,78.563671 85.9926777,78.1804536 85.0469945,78.1804536 C84.1013114,78.1804536 83.1959912,78.563671 82.5377049,79.242623 C81.7288345,80.0486965 80.6326465,80.500068 79.4907104,80.4972678 L79.4907104,79.6010929 C80.3943795,79.5976628 81.2615963,79.2443522 81.9103825,78.6153005 C82.7235118,77.7489036 83.8587929,77.2574285 85.0469945,77.2574285 C86.2351961,77.2574285 87.3704772,77.7489036 88.1836066,78.6153005 C88.8740585,79.2353106 89.7650555,79.5853452 90.6928962,79.6010929 C91.6240185,79.6023098 92.520877,79.2499725 93.2021858,78.6153005 C94.038897,77.7308327 95.2112272,77.2423618 96.4284153,77.2710383 C97.6167778,77.2565512 98.7559577,77.7447711 99.5650273,78.6153005 C100.246336,79.2499725 101.143195,79.6023098 102.074317,79.6010929 C103.002158,79.5853452 103.893155,79.2353106 104.583607,78.6153005 L104.673224,78.6153005 C105.482094,77.809227 106.578283,77.3578555 107.720219,77.3606557 C108.908581,77.3461686 110.047761,77.8343886 110.856831,78.704918 C111.505617,79.3339697 112.372834,79.6872803 113.276503,79.6907104 L113.276503,80.5868982 C112.134567,80.5896855 111.038379,80.138314 110.229508,79.3322404 C109.571222,78.6532885 108.665902,78.270071 107.720219,78.270071 C106.774535,78.270071 105.869215,78.6532885 105.210929,79.3322404 L105.121311,79.3322404 C104.303363,80.1140903 103.205238,80.5339616 102.074317,80.4972678 Z" id="Path" fill="#FFFFFF"></path>
                <g id="Group-4" transform="translate(198.000000, 60.000000)">
                    <circle id="Oval" fill="#FFFFFF" cx="23.9387978" cy="24.3508197" r="20.9704918"></circle>
                    <path d="M11.5715847,43.8874317 C19.5474138,49.1106925 29.88446,49.014496 37.7617009,43.6437066 C45.6389418,38.2729171 49.5047407,28.6854577 47.5561547,19.3527563 C45.6075687,10.0200548 38.2284248,2.78044061 28.8602301,1.01034649 C19.4920355,-0.759747631 9.98011078,3.2883465 4.76065574,11.2666667 L4.67103825,11.2666667 C1.25834837,16.5069509 0.068097008,22.8886647 1.36232528,29.0068347 C2.65655355,35.1250047 6.32915527,40.4780381 11.5715847,43.8874317 Z M13.6327869,40.7508197 C6.6772089,36.2516725 3.2758762,27.8844246 5.11954975,19.8083329 C6.96322331,11.7322411 13.6586423,5.66995886 21.8775956,4.63497268 C26.5221721,4.00352191 31.2376552,5.08680856 35.1409836,7.68196721 C44.2759287,13.636077 46.8797724,25.8541127 40.9661202,35.0153005 C38.368746,38.9108799 34.5071191,41.7912737 30.0327869,43.1704918 C24.4736138,44.7762223 18.4913608,43.8935948 13.6327869,40.7508197 Z" id="Shape" fill="#077565" fillRule="nonzero"></path>
                    <path d="M42.8480874,23.7234973 L40.3387978,23.7234973 C40.0735767,23.7197917 39.8452748,23.9100432 39.8010929,24.1715847 C39.8010929,24.4685509 40.0418316,24.7092896 40.3387978,24.7092896 L42.8480874,24.7092896 C43.1096289,24.6651077 43.2998804,24.4368058 43.2961749,24.1715847 C43.2961749,23.9241128 43.0955593,23.7234973 42.8480874,23.7234973 Z" id="Path" fill="#333456"></path>
                    <path d="M39.3530055,15.926776 L40.6972678,15.1202186 C40.7868852,15.0306011 40.8765027,14.8513661 40.7868852,14.7617486 C40.702262,14.6489176 40.5694541,14.5825137 40.4284153,14.5825137 L39.084153,15.389071 C38.9945355,15.389071 38.904918,15.389071 38.904918,15.4786885 C38.904918,15.568306 38.904918,15.747541 38.9945355,15.747541 C38.9945355,15.926776 39.1737705,16.0163934 39.3530055,15.926776 Z" id="Path" fill="#333456"></path>
                    <path d="M9.42076503,32.5060109 L8.07650273,33.2229508 C7.9949666,33.3744647 8.03258153,33.5625393 8.16612022,33.6710383 L8.43497268,33.6710383 L9.77923497,32.8644809 C9.86885246,32.7748634 9.95846995,32.5956284 9.86885246,32.5060109 C9.73789163,32.4011584 9.55172586,32.4011584 9.42076503,32.5060109 Z" id="Path" fill="#333456"></path>
                    <path d="M15.3355191,7.86120219 C15.2459016,7.7715847 15.0666667,7.68196721 14.9770492,7.7715847 C14.7978142,7.86120219 14.7978142,7.95081967 14.7978142,8.04043716 L14.7978142,8.21967213 L15.6043716,9.56393443 C15.6939891,9.65355191 15.873224,9.7431694 15.9628415,9.65355191 C16.1292386,9.57279588 16.2068771,9.37869961 16.1420765,9.20546448 L15.3355191,7.86120219 Z" id="Path" fill="#333456"></path>
                    <path d="M33.1693989,38.8688525 C33.1693989,38.779235 33.1693989,38.6896175 33.0797814,38.6896175 C32.9901639,38.6896175 32.810929,38.6896175 32.810929,38.779235 C32.810929,38.8688525 32.631694,38.9584699 32.7213115,39.1377049 L33.4382514,40.4819672 C33.5467503,40.6155059 33.7348249,40.6531208 33.8863388,40.5715847 C33.9759563,40.4819672 34.0655738,40.3027322 33.9759563,40.2131148 L33.1693989,38.8688525 Z" id="Path" fill="#333456"></path>
                    <path d="M8.9726776,24.1715847 C8.9726776,23.9241128 8.77206202,23.7234973 8.52459016,23.7234973 L5.92568306,23.7234973 C5.6782112,23.7234973 5.47759563,23.9241128 5.47759563,24.1715847 C5.47389007,24.4368058 5.6641416,24.6651077 5.92568306,24.7092896 L8.52459016,24.7092896 C8.78613162,24.6651077 8.97638315,24.4368058 8.9726776,24.1715847 Z" id="Path" fill="#333456"></path>
                    <path d="M24.3868852,39.5857923 C24.1253438,39.6299743 23.9350923,39.8582761 23.9387978,40.1234973 L23.9387978,42.6327869 C23.9387978,42.8802587 24.1394134,43.0808743 24.3868852,43.0808743 C24.6343571,43.0808743 24.8349727,42.8802587 24.8349727,42.6327869 L24.8349727,40.1234973 C24.8386782,39.8582761 24.6484267,39.6299743 24.3868852,39.5857923 Z" id="Path" fill="#333456"></path>
                    <path d="M24.2972678,5.26229508 C24.0875476,5.36146163 23.9496746,5.56827122 23.9387978,5.8 L23.9387978,6.69617486 L23.9387978,8.30928962 C23.9829798,8.57083108 24.2112816,8.76108261 24.4765027,8.75737705 C24.7453552,8.75737705 24.8349727,8.48852459 24.8349727,8.21967213 L24.8349727,6.87540984 L24.8349727,5.71038251 C24.8349727,5.44153005 24.6557377,5.26229508 24.2972678,5.26229508 Z" id="Path" fill="#333456"></path>
                    <path d="M33.8863388,7.7715847 C33.7348249,7.69004857 33.5467503,7.7276635 33.4382514,7.86120219 L32.7213115,9.20546448 C32.631694,9.38469945 32.631694,9.56393443 32.810929,9.65355191 C32.9901639,9.7431694 33.0797814,9.65355191 33.1693989,9.56393443 L33.9759563,8.21967213 C34.0574924,8.06815826 34.0198775,7.88008361 33.8863388,7.7715847 Z" id="Path" fill="#333456"></path>
                    <path d="M15.9628415,38.779235 C15.9628415,38.6896175 15.873224,38.6 15.7836066,38.6896175 C15.6939891,38.779235 15.6043716,38.779235 15.6043716,38.8688525 L14.7978142,40.2131148 C14.7978142,40.3541535 14.8642181,40.4869614 14.9770492,40.5715847 C15.0666667,40.6612022 15.2459016,40.5715847 15.3355191,40.4819672 L16.1420765,39.1377049 C16.231694,38.9584699 16.1420765,38.779235 15.9628415,38.779235 Z" id="Path" fill="#333456"></path>
                    <path d="M9.77923497,15.389071 L8.43497268,14.5825137 C8.2557377,14.5825137 8.07650273,14.5825137 7.98688525,14.7617486 C7.89726776,14.8513661 7.98688525,15.0306011 8.07650273,15.1202186 L9.42076503,15.926776 C9.59400016,15.9915766 9.78809642,15.9139381 9.86885246,15.747541 C9.95846995,15.6579235 9.86885246,15.4786885 9.77923497,15.389071 Z" id="Path" fill="#333456"></path>
                    <path d="M40.6972678,33.2229508 L39.3530055,32.5060109 C39.1737705,32.4163934 38.9945355,32.4163934 38.9945355,32.5956284 C38.9945355,32.7748634 38.8153005,32.6852459 38.904918,32.7748634 C38.9945355,32.8644809 38.9945355,32.9540984 39.084153,32.9540984 L40.4284153,33.7606557 L40.7868852,33.7606557 C40.8765027,33.5814208 40.7868852,33.4918033 40.6972678,33.4021858 L40.6972678,33.2229508 Z" id="Path" fill="#333456"></path>
                    <path d="M27.2546448,24.1715847 L25.6415301,23.6338798 C25.6415301,23.5442623 25.5519126,23.5442623 25.5519126,23.4546448 L16.9486339,11.0874317 L23.042623,24.1715847 L23.3114754,24.7989071 C23.3114754,24.8885246 23.3114754,24.8885246 23.4010929,24.9781421 C23.7552571,25.6120478 24.5488253,25.8501183 25.1934426,25.515847 L25.4622951,25.336612 L34.9617486,26.9497268 L27.2546448,24.1715847 Z" id="Path" fill="#333456"></path>
                </g>
                <g id="Group-7" transform="translate(163.000000, 94.000000)">
                    <g id="Group-6">
                        <g id="Group-5">
                            <path d="M15.5639344,14.2786885 C14.3989071,17.9530055 18.7901639,17.1464481 20.9409836,15.8918033 C23.0918033,14.6371585 23.9879781,11.5005464 27.4830601,9.70819672 C35.2797814,5.85464481 33.3081967,5.85464481 31.4262295,4.24153005 C29.5442623,2.6284153 25.063388,6.93005464 23.0918033,7.37814208 C19.4744715,8.05968812 16.5568657,10.7341601 15.5639344,14.2786885 Z" id="Path" fill="#C46446"></path>
                            <path d="M22.1060109,7.64699454 C18.9073348,8.54736221 16.4207014,11.0680591 15.5639344,14.2786885 C14.3989071,17.9530055 18.5213115,17.863388 20.6721311,16.6983607 C22.8229508,15.5333333 24.6153005,13.4721311 24.9737705,12.1278689 C24.0188831,11.6230333 23.2381689,10.8423191 22.7333333,9.88743169 C21.8371585,8.18469945 22.1060109,7.64699454 22.1060109,7.64699454 Z" id="Path" fill="#E3636F"></path>
                            <path d="M41.104918,2.98688525 C41.104918,2.98688525 36.7136612,3.88306011 40.3879781,1.19453552 C41.5768866,0.382300501 43.1422391,0.382300501 44.3311475,1.19453552 C44.6,1.82185792 42.7180328,2.35956284 42.7180328,2.35956284 C42.7180328,2.35956284 43.9726776,2.09071038 44.0622951,2.26994536 C44.1519126,2.44918033 43.0765027,2.98688525 41.104918,2.98688525 Z" id="Path" fill="#E3636F"></path>
                            <path d="M29.6338798,6.12349727 C30.3508197,8.18469945 32.5016393,7.37814208 34.2939891,6.66120219 C36.0863388,5.9442623 37.3409836,4.77923497 39.8502732,3.43497268 C42.3595628,2.09071038 43.5245902,2.0010929 42.0907104,1.37377049 C40.6568306,0.746448087 38.147541,2.26994536 36.6240437,2.53879781 C35.1005464,2.80765027 28.6480874,3.16612022 29.6338798,6.12349727 Z" id="Path" fill="#E3636F"></path>
                            <path d="M16.3704918,33.8153005 C15.6535519,32.8295082 13.5027322,32.1125683 13.5027322,32.1125683 C13.5027322,32.1125683 9.2010929,30.947541 5.61639344,36.6830601 C2.03169399,42.4185792 7.58797814,50.663388 5.43715847,53.0830601 C1.49398907,57.4743169 2.21092896,63.389071 0.956284153,71.0065574 C0.508196721,74.4120219 2.30054645,73.6054645 2.47978142,73.0677596 C4.80983607,65.1814208 11.2622951,56.3989071 11.8896175,54.8754098 C14.936612,47.168306 19.5071038,40.2677596 18.9693989,37.220765 L16.3704918,33.8153005 Z" id="Path" fill="#DBA65E"></path>
                            <path d="M27.7519126,14.4579235 L25.242623,16.6087432 C24.2596418,16.1068762 23.1993606,15.773645 22.1060109,15.6229508 C20.4928962,15.4437158 20.8513661,14.726776 20.8513661,14.726776 C20.8513661,14.726776 22.1060109,14.4579235 22.2852459,13.3825137 C22.4644809,12.3071038 27.7519126,14.4579235 27.7519126,14.4579235 Z" id="Path" fill="#CC4C54"></path>
                            <path d="M24.0775956,13.3825137 C25.242623,15.4437158 28.7377049,15.2644809 29.3650273,15.1748634 C29.9923497,15.0852459 29.7234973,15.0852459 29.8131148,14.8163934 C29.9604984,14.4781503 30.0220662,14.1087434 29.9923497,13.7409836 C29.9923497,13.3825137 30.2612022,12.9344262 30.2612022,12.6655738 C30.2612022,12.3967213 30.1715847,12.3967213 30.2612022,12.2174863 C30.3508197,12.0382514 30.7989071,11.8590164 30.9781421,11.410929 C30.9220398,11.0740248 30.7659166,10.7617784 30.5300546,10.5147541 C30.2612022,10.2459016 29.8131148,9.88743169 29.8131148,9.52896175 L29.8131148,9.26010929 C29.9051503,8.56135294 29.8124535,7.85067736 29.5442623,7.1989071 C29.0306012,6.47008848 28.2637602,5.95886114 27.3934426,5.76502732 C26.0396104,5.42027197 24.6026732,5.7544434 23.5398907,6.66120219 C23.3606557,6.84043716 23.9879781,9.52896175 23.8087432,9.52896175" id="Path" fill="#E3636F"></path>
                            <path d="M20.5825137,11.410929 C17.5355191,4.42076503 25.3322404,3.52459016 26.9453552,4.68961749 C28.5584699,5.85464481 29.8131148,5.40655738 30.0819672,5.9442623 C30.3508197,6.48196721 29.7234973,6.66120219 29.7234973,6.66120219 C29.7234973,6.66120219 30.9781421,8.81202186 28.2896175,9.61857923 C25.6010929,10.4251366 25.9595628,12.3967213 25.9595628,12.3967213 L25.6010929,12.5759563 C25.6010929,12.5759563 25.242623,10.7836066 24.3464481,11.5005464 C23.4502732,12.2174863 24.884153,14.189071 25.1530055,14.4579235 C25.4218579,14.726776 23.9879781,14.4579235 22.6437158,13.7409836 C21.8207675,13.0960037 21.1222834,12.306413 20.5825137,11.410929 Z" id="Path" fill="#731443"></path>
                            <path d="M23.4502732,11.231694 C23.4502732,11.231694 20.4032787,15.8918033 19.0590164,14.0098361 C17.7147541,12.1278689 18.431694,10.9628415 20.0448087,8.99125683 C21.6579235,7.01967213 23.4502732,11.231694 23.4502732,11.231694 Z" id="Path" fill="#B8BAC2"></path>
                            <path d="M20.5825137,11.410929 C21.4786885,12.0382514 24.6153005,11.231694 27.2142077,8.45355191 C28.2,7.37814208 29.5442623,6.5715847 28.9169399,5.31693989 C28.2896175,4.06229508 25.063388,1.28415301 21.389071,5.13770492 C17.7147541,8.99125683 19.9551913,10.9628415 20.5825137,11.410929 Z" id="Path" fill="#3E7B8B"></path>
                            <path d="M2.74863388,72.2612022 C3.43561688,72.8774726 4.18672619,73.4182713 4.98907104,73.8743169 C5.79562842,74.4120219 8.48415301,76.2043716 3.37595628,75.4874317 C0.418579235,75.0393443 0.149726776,75.2185792 0.866666667,72.3508197 C1.46859755,72.6420169 2.17709699,72.6082788 2.74863388,72.2612022 Z" id="Path" fill="#333456"></path>
                            <path d="M16.9081967,33.4568306 C15.8333569,33.1316087 14.6959835,33.0701291 13.5923497,33.2775956 C13.5923497,33.2775956 9.2010929,34.263388 8.66338798,40.9846995 C8.12568306,47.7060109 16.8185792,52.4557377 16.0120219,55.5923497 C14.4885246,61.3278689 17.8939891,66.2568306 20.3136612,73.6054645 C21.389071,76.831694 22.7333333,75.3081967 22.6437158,74.7704918 C21.0306011,66.6153005 22.7333333,55.8612022 22.5540984,54.2480874 C21.747541,46.0032787 22.5540984,37.7584699 20.7617486,35.3387978 L16.9081967,33.4568306 Z" id="Path" fill="#DBA65E"></path>
                            <path d="M21.926776,38.1169399 C21.926776,38.1169399 12.8754098,41.8808743 7.94644809,33.904918 C7.94644809,33.904918 10.6349727,20.3726776 15.8327869,15.3540984 C18.5213115,12.8448087 27.9311475,15.7125683 27.8415301,21.2688525 C27.7519126,24.2262295 25.7803279,29.0655738 25.7803279,29.0655738 C25.7803279,29.0655738 22.0163934,33.4568306 21.926776,38.1169399 Z" id="Path" fill="#FFFFFF"></path>
                            <path d="M24.1672131,20.0142077 C25.6010929,16.5191257 28.4688525,19.9245902 29.2754098,22.2546448 C30.0819672,24.5846995 29.7234973,27.3628415 30.4404372,31.2163934 C30.7092896,33.0983607 31.336612,36.7726776 28.8273224,36.7726776 C26.3180328,36.7726776 26.7661202,31.8437158 25.5114754,30.2306011 C23.1775271,27.3490232 22.6581096,23.4014503 24.1672131,20.0142077 Z" id="Path" fill="#E3636F"></path>
                            <path d="M38.5956284,32.3814208 C38.5956284,32.3814208 34.2043716,34.3530055 38.7748634,34.3530055 C40.2087432,34.442623 43.4349727,34.6218579 43.4349727,33.5464481 C42.9646584,32.9483325 42.2980819,32.5356899 41.5530055,32.3814208 L40.2983607,32.3814208 C40.2983607,32.3814208 41.1945355,32.2918033 41.1945355,32.1125683 C41.1945355,31.9333333 40.3879781,31.5748634 38.5956284,32.3814208 Z" id="Path" fill="#E3636F"></path>
                            <path d="M27.4830601,35.4284153 C27.0349727,33.3672131 29.2754098,32.9191257 31.157377,32.6502732 C33.0393443,32.3814208 34.7420765,32.7398907 37.6098361,32.6502732 C38.9540984,32.5606557 41.463388,32.1125683 40.5672131,33.3672131 C39.6710383,34.6218579 36.7136612,34.5322404 35.1901639,35.0699454 C33.6666667,35.6076503 28.020765,38.4754098 27.4830601,35.4284153 Z" id="Path" fill="#E3636F"></path>
                        </g>
                        <path d="M27.9311475,4.24153005 C27.7321631,4.55197931 27.76906,4.95784492 28.020765,5.2273224 C28.4688525,5.76502732 28.379235,4.68961749 28.379235,4.68961749 L27.9311475,4.24153005 Z" id="Path" fill="#211A3D"></path>
                    </g>
                    <path d="M22.4644809,73.8743169 C23.3440427,74.1103348 24.2444412,74.2604013 25.1530055,74.3224044 C26.1387978,74.3224044 29.3650273,74.7704918 24.5256831,76.473224 C21.6579235,77.4590164 21.4786885,77.7278689 20.8513661,74.7704918 C21.5043108,74.7573716 22.1083891,74.4217725 22.4644809,73.8743169 Z" id="Path" fill="#333456"></path>
                </g>
                <g id="Group-8" transform="translate(236.000000, 132.000000)">
                    <circle id="Oval" fill="#ED4B54" cx="9.40983607" cy="9.40983607" r="9.40983607"></circle>
                    <g id="+" transform="translate(9.272200, 9.138880) scale(-1, 1) translate(-9.272200, -9.138880) ">
                        <use fill="#000000" xlinkHref="#path-1"></use>
                        <use fill="#FFFFFF" xlinkHref="#path-1"></use>
                    </g>
                </g>
                <g id="Group" opacity="0.7" transform="translate(436.765027, 150.398907)">
                    <path d="M0.179234973,18.2819672 C0.179234973,28.2295082 7.34863388,36.3846995 7.34863388,36.3846995 C7.34863388,36.3846995 14.5180328,28.2295082 14.5180328,18.2819672 C14.5180328,8.33442623 7.34863388,0.0896174863 7.34863388,0.0896174863 C7.34863388,0.0896174863 0.179234973,8.24480874 0.179234973,18.2819672 Z" id="Path" fill="#1D5F59"></path>
                    <rect id="Rectangle" fill="#333456" x="6.81092896" y="16.0415301" width="1.16502732" height="30.4609836"></rect>
                </g>
                <path d="M428.699454,174.326776 C428.699454,182.39235 434.434973,188.844809 434.434973,188.844809 C434.434973,188.844809 440.170492,182.39235 440.170492,174.326776 C440.170492,166.261202 434.434973,159.898361 434.434973,159.898361 C434.434973,159.898361 428.699454,166.35082 428.699454,174.326776 Z" id="Path" fill="#1D5F59"></path>
                <rect id="Rectangle" fill="#333456" x="433.986885" y="172.624044" width="1" height="24.3042623"></rect>
                <g id="Group" opacity="0.7" transform="translate(101.836066, 153.983607)">
                    <path d="M0.627322404,16.8480874 C0.627322404,26.0786885 7.25901639,33.6065574 7.25901639,33.6065574 C7.25901639,33.6065574 13.8907104,26.0786885 13.8907104,16.8480874 C13.8907104,7.61748634 7.25901639,0.179234973 7.25901639,0.179234973 C7.25901639,0.179234973 0.627322404,7.61748634 0.627322404,16.8480874 Z" id="Path" fill="#1D5F59"></path>
                    <rect id="Rectangle" fill="#333456" x="6.72131148" y="14.8765027" width="1.07540984" height="28.0323497"></rect>
                </g>
                <path d="M94.9355191,176.119126 C94.9355191,183.46776 100.222951,189.472131 100.222951,189.472131 C100.222951,189.472131 105.510383,183.46776 105.510383,176.119126 C105.510383,168.770492 100.222951,162.76612 100.222951,162.76612 C100.222951,162.76612 94.9355191,168.770492 94.9355191,176.119126 Z" id="Path" fill="#1D5F59"></path>
                <rect id="Rectangle" fill="#333456" x="99.7748634" y="174.506011" width="1" height="22.3685246"></rect>
                <path d="M123.43388,176.119126 C123.43388,183.46776 128.721311,189.472131 128.721311,189.472131 C128.721311,189.472131 134.008743,183.46776 134.008743,176.119126 C134.008743,168.770492 128.721311,162.76612 128.721311,162.76612 C128.721311,162.76612 123.43388,168.770492 123.43388,176.119126 Z" id="Path" fill="#1D5F59"></path>
                <rect id="Rectangle" fill="#333456" x="128.273224" y="174.506011" width="1" height="22.3685246"></rect>
            </g>
        </g>
    </g>
    </svg>
  );
}

Time.propTypes = {
  className: PropTypes.string,
};