import React from 'react';
import { Row } from '../../index.style';
import { AlertDot, AlertText, AlertCount } from './index.style';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

type AlertItemProps = {
  dotColor: string;
  title: string;
  titleStyle?: React.CSSProperties;
  onClick?: () => void;
  count?: number;
  style?: React.CSSProperties;
};

const AlertItem = ({ dotColor, title, onClick, count, titleStyle, style }: AlertItemProps) => {
  const hasCount = typeof count === 'number';
  return (
    <Row onClick={onClick} style={{ cursor: 'pointer', ...style }} rowcenter>
      <Row style={hasCount ? { width: '80%' } : undefined} rowcenter gap={'8px'}>
        <AlertDot $color={dotColor} />
        <AlertText style={titleStyle}>{title}</AlertText>
      </Row>
      {hasCount && (
        <AlertCount style={{ width: '20%' }}>
          <div>{count}</div>
          <KeyboardArrowRightIcon />
        </AlertCount>
      )}
    </Row>
  );
};

export default AlertItem;
