import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';

import Avatar from 'components/molecules/Avatar';
import Message from './Message';

import css from './styles.scss';

export default function MessageGroup(props) {
  const {
    messages,
    user,
    timestamp,
    isSender,
    otherParticipants,
    retryMessage,
    retrySend,
    withdrawMessage,
    thread,
    downloadMessageFile,
    activeItem,
    activeOption,
    setActiveItem,
    toggleOption,
    isLastGroup,
    seenBy,
    onImageLoaded,
  } = props;
  const profileLink = `/users/${user.userId}/profile`;
  const botMessage = messages.filter((m) => m.type === 'BOT_INVITE' || m.type === 'BOT_REMOVE');

  if (botMessage.length > 0) {
    return (
      <div className={css.messageWrapper}>
        {messages.map((message) => (
          <span key={message.id} className={css.botMessage}>
            {message.message}
          </span>
        ))}
      </div>
    );
  }
  return (
    <div className={css.messageWrapper}>
      <Link className={css.profilePic} to={profileLink}>
        <Avatar image={user.profileImageUrl} {...user} size="normal" showStudentType userId={user.userId} />
      </Link>
      <div className={css.info}>
        <div className={css.messageDetails}>
          <Link className={css.userName} to={profileLink}>{`${user.firstName} ${user.lastName}`}</Link>
          <div className={css.verticalLine} />
          <div className={css.timestamp}>{moment(timestamp).format('h:mm A')}</div>
        </div>
        {messages.map((message, i) => (
          <Message
            createdAt={moment(message.createdAt).format()}
            setActiveMessage={(id) => setActiveItem(id)}
            activeItem={activeItem}
            activeOption={activeOption}
            showMoreOptions={(id) => toggleOption(id)}
            seenBy={seenBy}
            {...message}
            key={message.id}
            type={message.type || message.messageType}
            metadata={message.metadata || message.messageMetadata}
            percent={message.percent}
            uploadStatus={message.uploadStatus}
            otherParticipants={otherParticipants}
            onImageLoaded={onImageLoaded}
            retryMessage={retryMessage}
            isSender={isSender}
            isLast={i === messages.length - 1}
            retrySend={retrySend}
            withdrawMessage={withdrawMessage}
            thread={thread.id}
            downloadMessageFile={downloadMessageFile}
            isLastGroup={isLastGroup}
          />
        ))}
      </div>
    </div>
  );
}

MessageGroup.propTypes = {
  messages: PropTypes.array.isRequired,
  user: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    profileImageUrl: PropTypes.string,
  }),
  timestamp: PropTypes.object.isRequired,
  isSender: PropTypes.bool,
  otherParticipants: PropTypes.array,
  retryMessage: PropTypes.func,
  retrySend: PropTypes.func,
  withdrawMessage: PropTypes.func,
  thread: PropTypes.object,
  onImageLoaded: PropTypes.func,
  downloadMessageFile: PropTypes.func,
  activeItem: PropTypes.string,
  activeOption: PropTypes.string,
  setActiveItem: PropTypes.func,
  toggleOption: PropTypes.func,
  isLastGroup: PropTypes.bool,
  seenBy: PropTypes.array,
};

MessageGroup.defaultProps = {
  user: {},
};
