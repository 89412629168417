import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import Button from '@material-ui/core/Button';
import { validateEmail } from 'utils/utils';
import { useMutation, useQuery } from '@apollo/client';
import { registerNewUser, getSchoolList, client } from 'graphql/api/partnershipSignUp';
import { LogoFull, RegisterSuccess } from 'components/atoms/graphics';
import LoadingCircle from 'components/molecules/LoadingCircle';
import InputField from './InputField';
import SelectDropdown from './SelectDropdown';
import css from './styles.scss';

const EMAIL_WARNING_MESSAGE = {
  EMPTY: 'Please enter your school email',
  VALID: 'Please enter a valid school email',
  SCHOOL_WARNING_EMPTY: 'Please enter your email before selecting a school',
  SCHOOL_WARNING_VALID: 'Please enter a valid school email before selecting a school',
};

const PartnershipsSignUp = () => {
  const [isRegistering, setIsRegistering] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [registerSuccess, setRegisterSuccess] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [schoolList, setSchooList] = useState([]);
  const [currentSchool, setCurrentSchool] = useState(null);
  const [signUpNewUserInvitation, { data }] = useMutation(registerNewUser, { client });
  const [domainName, setDomainName] = useState('');
  const [showDropdownList, setShowDropdownList] = useState(false);
  const { refetch: fetchSchoolList } = useQuery(getSchoolList, { client, skip: true });
  const [domainUniversityMap, setDomainUniversityMap] = useState(null);
  const [emailValidationMessage, setEmailValidationMessage] = useState('');
  useEffect(() => {
    document.title = 'Welcome to Crimson';
  }, []);
  useEffect(() => {
    (async () => {
      const { data: schoolData } = await fetchSchoolList();
      const domainUniversityMap = {};
      if (schoolData && schoolData.getSchoolList) {
        schoolData.getSchoolList.forEach((school) => {
          const { emailDomain } = school;
          if (domainUniversityMap[emailDomain]) {
            domainUniversityMap[emailDomain].push(school);
          } else {
            domainUniversityMap[emailDomain] = [school];
          }
        });
      }
      setDomainUniversityMap(domainUniversityMap);
    })();
  }, [fetchSchoolList]);

  if (data && data.registerNewUser && !registerSuccess) {
    setRegisterSuccess(true);
  }
  const handleFirstNameChange = (firstName) => {
    if (validationErrors.firstName && firstName !== '') {
      setValidationErrors({ ...validationErrors, firstName: false });
    }
    setFirstName(firstName);
  };
  const handleLastNameChange = (lastName) => {
    if (validationErrors.lastName && lastName !== '') {
      setValidationErrors({ ...validationErrors, lastName: false });
    }
    setLastName(lastName);
  };
  const handleEmailChange = (email) => {
    if (validationErrors.email && email !== '' && validateEmail(email)) {
      setValidationErrors({ ...validationErrors, email: false });
    }
    setEmail(email);
  };
  const onBlurEmailInput = (e) => {
    const emailInput = e.target.value;
    if (!validateEmail(emailInput)) {
      if (emailInput.trim() === '') {
        setEmailValidationMessage(EMAIL_WARNING_MESSAGE.EMPTY);
      } else {
        setEmailValidationMessage(EMAIL_WARNING_MESSAGE.VALID);
      }
      setValidationErrors({ ...validationErrors, email: true });
      setSchooList([]);
      setCurrentSchool(null);
      return;
    }
    if (validationErrors.email === true) {
      setValidationErrors({ ...validationErrors, email: false });
    }
    const newDomainName = emailInput.split('@')[1];
    if (newDomainName !== domainName) {
      setDomainName(newDomainName);
      setCurrentSchool(null);
    }
    const newSchoolList = domainUniversityMap[newDomainName];
    if (!newSchoolList) {
      setSchooList([]);
      setEmailValidationMessage(EMAIL_WARNING_MESSAGE.VALID);
      setValidationErrors({ ...validationErrors, email: true });
      return;
    }
    if (validationErrors.school === true) {
      setValidationErrors({ ...validationErrors, school: false });
    }
    const constructedOptionList = newSchoolList.map((school) => {
      return {
        key: school.tagId,
        value: school.tagName,
      };
    });
    setSchooList(constructedOptionList);
  };
  const handleSchoolSelected = (currentSchool) => {
    setCurrentSchool(currentSchool);
    setShowDropdownList(false);
  };
  const toggletShowDropdownList = () => {
    if (schoolList && schoolList.length === 0) {
      if (emailValidationMessage === EMAIL_WARNING_MESSAGE.VALID) {
        setEmailValidationMessage(EMAIL_WARNING_MESSAGE.SCHOOL_WARNING_VALID);
      } else {
        setEmailValidationMessage(EMAIL_WARNING_MESSAGE.SCHOOL_WARNING_EMPTY);
      }
      setValidationErrors({ ...validationErrors, email: true });
      return;
    }
    setShowDropdownList(!showDropdownList);
  };
  const createAccount = async () => {
    const validationErrors = {};
    if (firstName.trim() === '') {
      validationErrors.firstName = true;
    }
    if (lastName.trim() === '') {
      validationErrors.lastName = true;
    }
    if (!validateEmail(email)) {
      if (email.trim() === '') {
        setEmailValidationMessage(EMAIL_WARNING_MESSAGE.EMPTY);
      } else {
        setEmailValidationMessage(EMAIL_WARNING_MESSAGE.VALID);
      }
      validationErrors.email = true;
    }
    if (!currentSchool || Object.keys(currentSchool) === 0) {
      validationErrors.school = true;
    }
    if (Object.keys(validationErrors).length) {
      setValidationErrors(validationErrors);
      return;
    }
    const { key: tagId } = currentSchool;
    const variables = {
      firstName,
      lastName,
      email,
      tagId,
    };
    setIsRegistering(true);
    try {
      await signUpNewUserInvitation({
        variables,
      });
    } catch (err) {
      const { graphQLErrors } = err;
      if (graphQLErrors) {
        const errorMessage = graphQLErrors[0].message;
        setErrorMessage(errorMessage);
      }
    } finally {
      setIsRegistering(false);
    }
  };
  const SignUpForm = () => {
    return (
      <div className={css.container}>
        <div className={css.content}>
          <LogoFull className={css.logo} />
          <div className={css.signUpContainer}>
            <div className={css.formTitle}>Create an account</div>
            <div className={css.formSubTitle}>
              Welcome to our Crimson Education partnership school students. Fill in your details below to create a
              Crimson App account. Please use your school email when creating your account.
            </div>
            {errorMessage !== '' && <div className={css.errorMessage}>{errorMessage}</div>}
            <div className={css.form}>
              <div className={css.formInputContainer}>
                <InputField
                  className={css.inputContainer}
                  value={firstName}
                  placeholder="First Name"
                  onValueChange={handleFirstNameChange}
                  maxLength="255"
                  error={validationErrors.firstName}
                  validationMessage="Please enter your first name"
                  disabled={isRegistering}
                />
                <InputField
                  className={css.inputContainer}
                  value={lastName}
                  placeholder="Last Name"
                  onValueChange={handleLastNameChange}
                  maxLength="255"
                  error={validationErrors.lastName}
                  validationMessage="Please enter your last name"
                  disabled={isRegistering}
                />
                <InputField
                  className={css.inputContainer}
                  onBlur={onBlurEmailInput}
                  value={email}
                  placeholder="Email"
                  onValueChange={handleEmailChange}
                  maxLength="255"
                  error={validationErrors.email}
                  validationMessage={emailValidationMessage}
                  disabled={isRegistering}
                />
                <SelectDropdown
                  error={validationErrors.school}
                  onChange={handleSchoolSelected}
                  placeholder="School"
                  validationMessage="Please select a valid school"
                  value={currentSchool ? currentSchool.value : ''}
                  disabled={isRegistering}
                  options={schoolList}
                  showDropdownList={showDropdownList}
                  toggletShowDropdownList={toggletShowDropdownList}
                />
                <Button disabled={isRegistering} className={css.button} onClick={() => createAccount()}>
                  {isRegistering ? <LoadingCircle color="#babcc5" /> : 'CREATE'}{' '}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const SignUpSuccessForm = () => {
    return (
      <div className={css.successFormContainer}>
        <div className={css.successContent}>
          <div className={css.successLogo}>
            <RegisterSuccess />
          </div>
          <div className={css.successTitle}>Your Crimson app account has been created successfully!</div>
          <div className={css.successSubTitle}>
            An email has been sent to your email address {email} with information on how to log in for the first time.
            If you haven&apos;t received it please check your spam.
          </div>
        </div>
      </div>
    );
  };

  return <div>{!registerSuccess ? SignUpForm() : SignUpSuccessForm()}</div>;
};

export function Render(props) {
  ReactDOM.render(<PartnershipsSignUp {...props} />, document.getElementById('root'));
}
