import React from 'react';
import { Column, Row, HelloStaffText, HaveANiceDay } from './index.style';
import SelectRegion from './SelectRegion';
import { getCurrentUserJS } from 'selectors/user';
import { useSelector } from 'react-redux';

const DashboardHeader = () => {
  const userProfile = useSelector(getCurrentUserJS);
  return (
    <Column gap={'20px'} style={{ marginBottom: 30 }}>
      <Row gap={'22px'} alignitems={'flex-end'}>
        <HelloStaffText>{`Hello ${userProfile?.preferredName || userProfile?.firstName}!`}</HelloStaffText>
        <HaveANiceDay>{'Have a nice day!'}</HaveANiceDay>
      </Row>
      <SelectRegion />
    </Column>
  );
};

export default DashboardHeader;
