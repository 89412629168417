import { Application, ApplicationApproval, ApplicationGoal, ApplicationRegion, TUserInfo } from './type';
import moment from 'moment';
import { sortBy } from 'lodash';
import {
  US_ROUND_MAP,
  UK_ROUND_MAP,
  OTHER_REGION_ROUND_MAP,
  UNIVERSITY_REGIONS,
  UK_ROUND_GROUPS,
  US_ROUND_GROUPS,
  GOAL_OPTION_MAP,
  TRACKER_PAGE_GROUP_BUTTON_OPTIONS_MAP,
} from './constants';
import CryptoJs from 'crypto-js';

const _mapDeadlineToNumber = (deadline?: string | null) => {
  if (!deadline) return Number.MAX_SAFE_INTEGER;
  return moment(deadline).unix();
};

const _REGION_ROUNDS = {
  UK: UK_ROUND_GROUPS,
  US: US_ROUND_GROUPS,
};

const _sortRound = (a: Application, b: Application, region: ApplicationRegion) => {
  // non-US,UK applications, skip
  if (!['US', 'UK'].includes(region)) return 0;
  // one of apps doesn't set round, skip
  if (!a.round || !b.round) return 0;
  const ROUND_GROUPS = _REGION_ROUNDS[region as keyof typeof _REGION_ROUNDS];
  const KEYS = Object.keys(ROUND_GROUPS);
  const key1 = findKeyFromValue(ROUND_GROUPS, a.round);
  const key2 = findKeyFromValue(ROUND_GROUPS, b.round);
  // get round group, if one of them not in the group, skip
  if (!key1 || !key2) return 0;
  // sort by order of keys
  return KEYS.indexOf(key1) - KEYS.indexOf(key2);
};

const _sortDeadline = (a: Application, b: Application) =>
  // sort deadline ascending
  _mapDeadlineToNumber(a.deadline) - _mapDeadlineToNumber(b.deadline);

const _sortGoal = (a: Application, b: Application) => {
  const GOAL_KEYS = Object.keys(GOAL_OPTION_MAP);
  if (!a.goal || !b.goal) return 0;
  if (!GOAL_OPTION_MAP[a.goal] || GOAL_OPTION_MAP[b.goal]) return 0;
  // sort by order of keys
  return GOAL_KEYS.indexOf(a.goal) - GOAL_KEYS.indexOf(b.goal);
};

const _sortByAlphabet = (a: Application, b: Application) => a.university.localeCompare(b.university);

const _compareAppliction = (group: 'Round' | 'Goal' | 'Status' | 'Deadline', region: ApplicationRegion) => (
  a: Application,
  b: Application,
) => {
  let sortFuncs: ((a: Application, b: Application, region: ApplicationRegion) => number)[] = [];
  switch (group) {
    case 'Round':
      sortFuncs = [_sortRound, _sortDeadline, _sortGoal, _sortByAlphabet];
    case 'Goal':
      sortFuncs = [_sortGoal, _sortDeadline, _sortRound, _sortByAlphabet];
    case 'Deadline':
      sortFuncs = [_sortDeadline, _sortGoal, _sortRound, _sortByAlphabet];
    default:
      sortFuncs = [_sortDeadline, _sortByAlphabet];
  }
  for (const func of sortFuncs) {
    const order = func(a, b, region);
    // if order is not equal to 0, return sort;
    if (!!order) return order;
  }
  return 0;
};
export const sortApplications = (
  applications: Application[],
  group: 'Round' | 'Goal' | 'Status' | 'Deadline',
  region: ApplicationRegion,
) => {
  return applications.sort(_compareAppliction(group, region));
};

export const mapRoundValue = (round: string, region: string) => {
  let VALUE_MAP;
  if (region === 'US') {
    VALUE_MAP = US_ROUND_MAP;
  } else if (region === 'UK') {
    VALUE_MAP = UK_ROUND_MAP;
  } else {
    VALUE_MAP = OTHER_REGION_ROUND_MAP;
  }
  return getLabelFromOptionMap(VALUE_MAP, round);
};

export const getRoundsByRegion = (region: string) => {
  switch (region) {
    case 'US':
      return optionMapToOptions(US_ROUND_MAP);
    case 'UK':
      return optionMapToOptions(UK_ROUND_MAP);
    case 'EU':
      return [
        { label: 'Undergraduate', value: 'Undergraduate' },
        { label: 'Postgraduate', value: 'Postgraduate' },
        { label: 'Foundation', value: 'Foundation' },
        { label: 'Other', value: 'Other' },
      ];
    default:
      return [
        { label: 'Undergraduate', value: 'Undergraduate' },
        { label: 'Postgraduate', value: 'Postgraduate' },
        { label: 'Foundation', value: 'Foundation' },
        { label: 'Other', value: 'Other' },
      ];
  }
};

export const groupRoundsByRegion = (applications: Application[], region: ApplicationRegion) => {
  const grouped: { [key: string]: Application[] } = {};
  if (['EU', 'Other'].includes(region)) {
    getRoundsByRegion('EU').forEach(({ value }) => {
      grouped[value] = [];
    });
    applications.forEach((app) => {
      let key: string;
      if (!app.round || !grouped[app.round]) {
        key = 'Other';
      } else {
        key = app.round;
      }
      grouped[key].push(app);
    });
  }
  if (['UK', 'US'].includes(region)) {
    const REGION_GROUPS = region === 'US' ? US_ROUND_GROUPS : UK_ROUND_GROUPS;
    Object.keys(REGION_GROUPS).forEach((key) => {
      grouped[key] = [];
    });
    grouped['Other'] = [];
    applications.forEach((app) => {
      let key = 'Other';
      if (!!app.round) {
        key = findKeyFromValue(REGION_GROUPS, app.round) || key;
      }
      grouped[key].push(app);
    });
  }

  return Object.keys(grouped)
    .filter((key) => !!grouped[key].length)
    .map((key) => ({ label: key, applications: grouped[key] }));
};

export const mapApplicationRegion = (application: Application) =>
  mapUniversityRegion(application?.universityData?.country?.region?.toUpperCase());

export const mapUniversityRegion = (region?: string): ApplicationRegion => {
  if (
    region &&
    UNIVERSITY_REGIONS.slice(0, 3)
      .map((o) => o.value)
      .includes(region)
  ) {
    return region as ApplicationRegion;
  }
  return 'Other';
};

const _groupApplicationsByApplicationStatus = (applications: Application[]) => {
  type KeyofOptionMap = keyof typeof TRACKER_PAGE_GROUP_BUTTON_OPTIONS_MAP;
  const appsByStatus: { [key: string]: Application[] } = {};
  Object.keys(TRACKER_PAGE_GROUP_BUTTON_OPTIONS_MAP)
    .reverse()
    .forEach((key) => {
      appsByStatus[key] = [];
    });
  const _mapApplicationToKey = (application: Application): KeyofOptionMap => {
    const gotResult = hasAdmissionResult(application);
    if (gotResult) return 'GOT_RESULT';
    if (application.isSubmitted) return 'SUBMITTED';
    if (application.extraApplicationStatus === 'INVITED_TO_INTERVIEW') return 'INVITED_TO_INTERVIEW';
    return 'IN_PROGRESS';
  };
  const _labelMap = (key: KeyofOptionMap) => {
    if (key === 'GOT_RESULT') {
      return 'Got Result';
    }
    return TRACKER_PAGE_GROUP_BUTTON_OPTIONS_MAP[key];
  };
  applications.forEach((application) => {
    const key = _mapApplicationToKey(application);
    appsByStatus[key].push(application);
  });
  return Object.entries(appsByStatus)
    .filter(([_, value]) => !!value.length)
    .map(([key, values]) => ({
      label: _labelMap(key as KeyofOptionMap),
      applications: values,
    }));
};

export const groupApplications = (
  applications: Application[],
  group: 'Round' | 'Goal' | 'Status' | 'Deadline',
  region: ApplicationRegion,
  options: {
    deadlineGroupFormat: string;
  } = {
    deadlineGroupFormat: 'MMM, YYYY',
  },
): { label: string; applications: Application[] }[] => {
  applications = sortApplications(applications, group, region);
  const { deadlineGroupFormat } = options || {};
  switch (group) {
    case 'Round':
      return groupRoundsByRegion(applications, region);
    case 'Goal':
      const goalRes: Record<ApplicationGoal | 'Other', Application[]> = {} as Record<
        ApplicationGoal | 'Other',
        Application[]
      >;
      const GOAL_KEYS = Object.keys(GOAL_OPTION_MAP);
      GOAL_KEYS.forEach((key) => (goalRes[key as ApplicationGoal] = []));
      goalRes['Other'] = [];
      applications.forEach((application) => {
        const key = application.goal && GOAL_KEYS.includes(application.goal) ? application.goal : 'Other';
        goalRes[key].push(application);
      });
      return Object.entries(goalRes).map(([label, applications]) => ({
        label: getLabelFromOptionMap(GOAL_OPTION_MAP, label),
        applications,
      }));
    case 'Status':
      return _groupApplicationsByApplicationStatus(applications);
    case 'Deadline':
      const _applications: { [key: string]: Application[] } = {};
      applications.forEach((application) => {
        const deadline = application.deadline;
        let key: string;
        if (!deadline) {
          key = 'Other';
        } else {
          key = moment(deadline).format(deadlineGroupFormat || 'MMM, YYYY');
        }
        if (!_applications[key]) _applications[key] = [];
        _applications[key].push(application);
      });
      const res = Object.keys(_applications)
        .filter((a) => a !== 'Other')
        .sort((a, b) => _sortDeadline(_applications[a][0], _applications[b][0]))
        .map((key) => ({ label: key, applications: _applications[key] }));
      if (_applications['Other']) res.push({ label: 'Other', applications: _applications['Other'] });
      return res;
    default:
      return [
        {
          label: group,
          applications,
        },
      ];
  }
};

export const getCurrent = <T>(tab: string, listValue: T, trackerValue: T): T => {
  return tab === 'school_list' ? listValue : trackerValue;
};

export const getCurrentRegion = <T>(tab: string, listRegion: T, trackerRegion: T) => {
  return tab === 'school_list' ? listRegion : trackerRegion;
};

export const COLOR = ['#6C63FF', '#3B86FE', '#57AEC2', '#89AAFF', '#F4948D'];

export const getTagColor = (idx = 0) => {
  const color = COLOR[idx % COLOR.length];
  return {
    color,
    backgroundColor: color + '30',
  };
};

export const getApplicationStatusTextStyle = (status: string) => {
  const SUCCESS = [
    'Accepted',
    'Accepted with conditional offer',
    'Alternate admission',
    'Matriculation school',
    'Foundation year offer',
  ];
  const DEFAULT = ['Waitlisted', 'No responses', 'Others'];
  const DISABLED = ['Deferred', 'Application withdrawn', 'Accepted ED elsewhere'];
  const WARINING = ['Rejected'];

  if (SUCCESS.includes(status)) {
    return {
      color: '#12c39a',
    };
  }
  if (DEFAULT.includes(status)) {
    return {
      color: '#6d6d77',
    };
  }
  if (DISABLED.includes(status)) {
    return {
      color: '#babcc5',
    };
  }
  if (WARINING.includes(status)) {
    return {
      color: '#ff764c',
    };
  }
  return {
    color: '#6d6d77',
  };
};

export const hasAdmissionResult = (application?: Application | null) =>
  !!(application && application.isSubmitted && application.applicationStatus && !!application.applicationStatus.length);

export const getUserPrimaryRole = (userInfo: TUserInfo) => {
  return userInfo.roles.filter((o) => o.isPrimary)[0]?.role.name ?? userInfo.roles[0]?.role.name;
};

export const getApplicationYearsFromString = (yearOfApplication?: string) => {
  if (!yearOfApplication) return;
  const [yearA, yearB] = yearOfApplication
    .split('/')
    .slice(0, 2)
    .map((year) => (Number.parseInt(year).toString() === 'NaN' ? null : +year));
  if (!!yearA && !!yearB) return [yearA, yearB];
  if (!yearA && !!yearB) return [yearB - 1, yearB];
  if (!!yearA && !yearB) return [yearA, yearA + 1];
};

export const calculateSelectionHash = (application: Application[], region: ApplicationRegion, userId: string) => {
  const selection = application.map((application) => ({
    application_id: application.id,
    university: application.universityId,
    goal: application.goal,
    round: application.round,
  }));
  const sortedSelection = sortBy(selection, ['goal', 'round', 'university']);
  const seed = sortedSelection
    .map((s) =>
      ''
        .concat(s.goal || '')
        .concat(s.round || '')
        .concat(s.university)
        .concat(s.application_id),
    )
    .join('');
  return CryptoJs.MD5(userId + region + seed).toString(CryptoJs.enc.Hex);
};

export const STORAGE_KEY = {
  RESEND_APPROVAL_REMIND_LATER: (studentUserId: string, region: string) =>
    'resendApprovalRemindLater' + studentUserId + region,
};

export const openLink = (link: string) => {
  const a = document.createElement('a');
  a.href = link;
  a.target = '_blank';
  a.click();
};

export const ANIMATION_DURATION = 350;

export const isUCASSchool = (application: Application) => {
  return !!application.universityData?.applicationGroups?.includes('UCAS');
};

export const isUCSchool = (application: Application) => {
  return application.universityData?.applicationGroups?.includes('UC');
};

export const getUniversityColleges = (application: Application) =>
  (application.universityData?.colleges || []).map((o) => o.name);

/**
 *
 *
 * @template T
 * @param {T} optionMap
 * @param {*} [mapKeyValue=(key: string, value: string) => ({
 *     value,
 *     label: key,
 *   })]
 * @return {*}
 * transform {value1: label1, value2: label2}  to [{label: label1, value: value1}, {label: label2, value: value2}]
 */
export const optionMapToOptions = <T extends Record<string, string>>(
  optionMap: T,
  mapKeyValue = (key: string, value: string) => ({
    value: key,
    label: value,
  }),
) => {
  return Object.keys(optionMap).map((key) => mapKeyValue(key, optionMap[key]));
};
export function getLabelFromOptionMap<T extends Record<string, string>>(
  optionMap: T,
  value: string,
  fallback?: string,
): string;
export function getLabelFromOptionMap<T extends Record<string, string>>(
  optionMap: T,
  value: string | null,
  fallback?: string,
): null;
export function getLabelFromOptionMap<T extends Record<string, string>>(
  optionMap: T,
  value: string | null,
  fallback?: string,
): string | null {
  if (!value) return value;
  return optionMap[value] || fallback || value;
}

/**
 *
 *
 * @template T
 * @param {T} obj
 * @param {string} value
 * @return {*}
 * {
 *    key: [value1, value2, value3]
 * }
 * find key name from value, loop keys, check if list contains value, if true return key name
 */
const findKeyFromValue = <T extends Record<string, string[]>>(obj: T, value: string) => {
  for (const key in obj) {
    if (obj[key].includes(value)) {
      return key;
    }
  }
  return null;
};

type ApplicationCompare = {
  region: ApplicationRegion;
} & Pick<Application, 'hadCrimsonSupport' | 'group'>;
const _compareApplication = (application: Application, condition: Partial<ApplicationCompare>) => {
  return Object.entries(condition).every(([key, value]) => {
    if ((key as keyof ApplicationCompare) === 'region') {
      return value === mapApplicationRegion(application);
    }
    return value === application[key as keyof Application];
  });
};

export const filterApplicationsWithCondition = (
  applications: Application[],
  condition: Partial<ApplicationCompare>,
) => {
  return applications.filter((app) => _compareApplication(app, condition));
};

export const isXRegion = (application: Application, expectedRegion: ApplicationRegion) =>
  mapApplicationRegion(application) === expectedRegion;

export const isInvitedToInterview = <T extends Pick<Application, 'isSubmitted' | 'extraApplicationStatus'>>(
  application: T,
) => !application.isSubmitted && application.extraApplicationStatus === 'INVITED_TO_INTERVIEW';

export const isApplicationOverdue = (application: Application, nullDeadlineHandler = false) => {
  if (!application.deadline) return nullDeadlineHandler;
  return moment().isAfter(moment(application.deadline), 'day');
};

export const getLastApplicationStatus = (application: Application) => {
  if (!hasAdmissionResult(application)) return '';
  return application.applicationStatus[application.applicationStatus.length - 1];
};

export const isPromise = (result: any) => {
  return result instanceof Promise;
};

/**
 *
 *
 * @param {string} round
 * @return {*}
 * return 'Earyly' 'Regular' 'Other'
 */
export const roundGroupUS = (round: string): keyof typeof US_ROUND_GROUPS | null => {
  for (const key in US_ROUND_GROUPS) {
    if (US_ROUND_GROUPS[key as keyof typeof US_ROUND_GROUPS].includes(round)) {
      return key as keyof typeof US_ROUND_GROUPS;
    }
  }
  return null;
};

export const roundGroupUK = (round: string) => {
  for (const key in UK_ROUND_GROUPS) {
    if (UK_ROUND_GROUPS[key as keyof typeof UK_ROUND_GROUPS].includes(round)) {
      return key;
    }
  }
  return null;
};

export const isApplicationReceivedResult = (app: Application) => {
  const EXPECTED_STATUS = [
    'Accepted',
    'Accepted with conditional offer',
    'Alternate admission',
    'Matriculation school',
    'Foundation year offer',
    'Accepted ED elsewhere',
  ];
  return EXPECTED_STATUS.includes(getLastApplicationStatus(app));
};

export const isApplicationHasIncompleteResult = (app: Application) => {
  const NO_RESPONSE = 'No responses';
  return !app.applicationStatus?.length || getLastApplicationStatus(app) === NO_RESPONSE;
};

export const getDeadlineTextAndColor = <
  T extends Pick<Application, 'deadline' | 'isSubmitted' | 'extraApplicationStatus'>
>(
  application: T,
) => {
  const noDeadlineOrSubmitted = !application.deadline || application.isSubmitted || isInvitedToInterview(application);
  const isIn15Days = noDeadlineOrSubmitted
    ? false
    : moment(application.deadline).isAfter(moment()) && moment().add(15, 'days').isAfter(moment(application.deadline));
  const isOverdue = noDeadlineOrSubmitted ? false : moment().isAfter(moment(application.deadline));
  const isToday = noDeadlineOrSubmitted
    ? false
    : moment().format('YYYY-MM-DD') === moment(application.deadline).format('YYYY-MM-DD');
  const formatDate = (date: Date) => {
    if (isToday) return 'due today';
    if (isOverdue) {
      const diff = moment().diff(moment(date), 'days');
      if (diff === 0) return 'due today';
      if (diff > 30) return moment(date).format('MMM DD, YYYY');
      return `due ${diff} ${diff === 1 ? 'day' : 'days'} ago`;
    }
    if (isIn15Days) {
      const diff = moment(date).diff(moment(), 'days') + 1;
      return `In ${diff} ${diff === 1 ? 'day' : 'days'}`;
    }
    return moment(date).format('MMM DD, YYYY');
  };
  const getTextColor = (isIn15Days: boolean, isOverdue: boolean, isToday: boolean) => {
    if (isOverdue || isToday) return '#ED4B53';
    if (isIn15Days) return '#FF764C';
    return undefined;
  };
  return {
    text: !!application.deadline ? formatDate(new Date(application.deadline)) : undefined,
    color: getTextColor(isIn15Days, isOverdue, isToday),
    formatDate,
  };
};

export const isPreviewApproval = (approval?: ApplicationApproval) => {
  if (!approval) return false;
  return approval.type === 'preview' || approval.emailMetadata?.recipients?.every((o) => o.type === 'staff');
};

export const sleep = (time: number) => new Promise((resolve) => setTimeout(resolve, time));
