import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/molecules/Modal';
import Select from 'components/molecules/Select';
import css from './styles.scss';

export default function InvoiceFilterModal(props) {
  const { name, displayName, isOpen, onSubmit, onClose, options, value, onSelect, isMobile } = props;

  return (
    isOpen && (
      <Modal
        onSubmit={onSubmit}
        onClose={onClose}
        title={`Filter by ${displayName || name}`}
        onSubmitDataTestId={`invoice${name}Filter`}
        submitText="Select"
        className={css.invoiceFilterModal}
        isOpen
      >
        <Select
          name={`${name}-filter`}
          id={`invoice${name}Filter`}
          dataTestId={`invoice${name}Filter`}
          placeholder={`Select a ${displayName || name}...`}
          value={value || null}
          options={options}
          onChange={(option) => onSelect(name, option)}
          isClearable
          menuIsOpen
          isMobile={isMobile}
        />
      </Modal>
    )
  );
}

InvoiceFilterModal.propTypes = {
  name: PropTypes.string,
  displayName: PropTypes.string,
  isOpen: PropTypes.bool,
  onSubmit: PropTypes.func,
  onSelect: PropTypes.func,
  onClose: PropTypes.func,
  options: PropTypes.array,
  value: PropTypes.object,
  isMobile: PropTypes.bool,
};
