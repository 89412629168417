import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Card from 'components/molecules/Card';
import InfoIcon from '@material-ui/icons/Info';
import Button from 'components/molecules/Button';

import css from './styles.scss';

class IncompleteReportCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    // feedback means this report is for strategist or eduCo
    const { lesson, student, onClick, booking, feedback } = this.props;
    const overdueDays = moment().diff(moment(lesson.end), 'days');
    if (booking !== undefined && (student.fullName === null || student.fullName === undefined)) {
      const otherUser = booking.participants.find((x) => x.userId !== lesson.userId);
      student.fullName = `${otherUser.firstName} ${otherUser.lastName}`;
    }

    const subjectName = booking && booking.subject ? booking.subject.name : '';

    return (
      <Card className={css.cardContainer} onClick={() => onClick(lesson.eventId)}>
        {feedback ? null : (
          <div className={css.overdueContainer}>
            {overdueDays > 0 ? `${overdueDays} days overdue` : 'Overdue'}
            <div className={css.rightAligned}>
              <InfoIcon style={{ height: '1.4rem', width: '1.4rem' }} />
            </div>
          </div>
        )}
        <div className={css.lessonContainer}>
          {feedback ? (
            <div className={css.lessonBlockContainer}>
              <div className={css.title}>{lesson.title}</div>
              <div className={css.subtitle}>Session</div>
            </div>
          ) : (
            <div className={css.lessonBlockContainer}>
              <div className={css.title}>{lesson.title}</div>
              <div className={css.subtitle}>{subjectName}</div>
            </div>
          )}
          <div className={css.lessonBlockContainer}>
            <div className={css.title}>{student.fullName || ''}</div>
            <div className={css.subtitle}>Student</div>
          </div>
          <div className={css.lessonBlockContainer}>
            <div className={css.title}>{moment(lesson.start).format('dddd, DD MMMM')}</div>
            <div className={css.title}>
              {`${moment(lesson.start).format('h:mm')} - ${moment(lesson.end).format('h:mm a')}`}
            </div>
          </div>
        </div>
        <div className={css.buttonContainer}>
          <Button onClick={() => onClick(lesson.eventId)} small>
            Complete Session
          </Button>
        </div>
      </Card>
    );
  }
}

IncompleteReportCard.propTypes = {
  lesson: PropTypes.object,
  booking: PropTypes.object,
  student: PropTypes.object,
  onClick: PropTypes.func,
  feedback: PropTypes.bool,
};

export default IncompleteReportCard;
