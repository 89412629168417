import React, { useMemo } from 'react';
import { University } from '../type';
import { StyledSelect } from './index.style';

type UniversityMultiSelectProps = {
  universites: University[];
  selectedUniversitys: { label: string; value: string }[];
  setSelectedUniversity: (university: { label: string; value: string }[]) => void;
};

const UniversityMultiSelect = ({
  universites,
  selectedUniversitys,
  setSelectedUniversity,
}: UniversityMultiSelectProps) => {
  const onChange = (value: { label: string; value: string }[]) => {
    setSelectedUniversity(value);
  };

  const options = useMemo(() => {
    return universites.map((o) => ({
      label: o.name,
      value: o.id,
    }));
  }, [universites]);
  return (
    <StyledSelect
      placeholder={'Select a university'}
      dataTestId={'UniversityMultiSelector'}
      value={selectedUniversitys}
      isClearable
      onChange={onChange}
      isMulti
      closeMenuOnSelect={false}
      options={options}
      hideSelectedOptions={true}
    />
  );
};

export default UniversityMultiSelect;
