import React from 'react';
import { displayDate } from 'utils/utils';
import { Column } from 'components/molecules/Table';
import { columnNames } from '../columnMap';

const UserSince = () => (
  <Column
    key={columnNames.UserSince}
    name="User Since"
    flex="1.5 0 0"
    renderer={({ rowData }) => {
      const activatedAt = rowData.get('activatedAt');
      return activatedAt ? `${displayDate(activatedAt, true)}` : '—';
    }}
  />
);
export default UserSince;
