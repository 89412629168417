import React, { useState } from 'react';
import { PromptContainer, StyledErrorIcon, HintContainer } from './index.style';

type PromptProps = {
  prompt: string;
};

const Prompt = ({ prompt }: PromptProps) => {
  const [showHint, setShowHint] = useState(false);

  return (
    <PromptContainer onMouseEnter={() => setShowHint(true)} onMouseLeave={() => setShowHint(false)}>
      <StyledErrorIcon />
      {showHint && (
        <HintContainer>
          <div>{prompt}</div>
        </HintContainer>
      )}
    </PromptContainer>
  );
};

export default Prompt;
