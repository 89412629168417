import React from 'react';
import RoadmapV2 from 'components/pages/Roadmap';
import MyRoadmap from 'components/unique/MyRoadmap/loader';
import { withFeatureFlag } from 'utils/withFeatureFlag';
import { trackReactRoute } from 'utils/routeTracker';
import { useFeatureFlag } from 'featureSwitches';
const TrackRoadMap = trackReactRoute(MyRoadmap);

const RoadMap = ({
  featureFlags,
}: {
  featureFlags: {
    [key: string]: boolean;
  };
}): JSX.Element => {
  const ROADMAP_BETA = useFeatureFlag('ROADMAP_BETA');
  return <>{featureFlags?.ROADMAP_BETA && ROADMAP_BETA ? <RoadmapV2 /> : <TrackRoadMap />}</>;
};

export const RoadmapSwitch = withFeatureFlag(['ROADMAP_BETA'], RoadMap);
