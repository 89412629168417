import React from 'react';
import PropTypes from 'prop-types';

export default function Running(props) {
  return (
    <svg viewBox="0 0 24 24" fill="#232950" {...props}>
      <path
        style={{
          lineHeight: 'normal',
          textIndent: 0,
          textAlign: 'start',
          textDecorationLine: 'none',
          textDecorationStyle: 'solid',
          textDecorationColor: '#000',
          textTransform: 'none',
          blockProgression: 'tb',
          isolation: 'auto',
          mixBlendMode: 'normal',
        }}
        d="M 18 3 A 2 2 0 0 0 16 5 A 2 2 0 0 0 18 7 A 2 2 0 0 0 20 5 A 2 2 0 0 0 18 3 z M 10.009766 3.8769531 L 5.5527344 6.1054688 L 6.4472656 7.8945312 L 9.9902344 6.1230469 L 12.052734 7.1992188 L 9.7753906 9.3710938 C 9.0336999 10.078302 8.8372651 11.105707 9.1347656 11.986328 L 3 18.585938 L 4.4140625 20 L 10.753906 13.660156 L 12.734375 14.890625 L 9.296875 18.289062 L 10.703125 19.710938 L 15.457031 15.011719 L 13.039062 11.962891 L 14.96875 10.160156 L 16.541016 13.304688 L 21.410156 11.119141 L 20.589844 9.2929688 L 17.470703 10.695312 L 15.494141 6.7421875 L 10.009766 3.8769531 z"
        fontWeight="400"
        fontFamily="sans-serif"
      />
    </svg>
  );
}

Running.propTypes = {
  className: PropTypes.string,
};
