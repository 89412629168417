import styled from 'styled-components';
import { PrimaryButton, SecondaryButton } from '../index.style';

export const StyledPrimaryButton = styled(PrimaryButton)`
  width: 156px;
`;

export const StyledSecondaryButton = styled(SecondaryButton)`
  width: 156px;
`;
