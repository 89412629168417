import Immutable from 'immutable';
import createReducer from 'utils/createReducer';
import { ADD_ENTITIES, addEntitiesWithNormalisation } from 'ducks/normalizr';
import { sessionFileEntity } from 'schema';
import googleConnector from 'graphql/api/google';

const initialState = new Immutable.Map();

const CLEAR_SESSIONFILES = 'sessionFiles/CLEAR_SESSIONFILES';

export function clearSessionFiles() {
  return { type: CLEAR_SESSIONFILES, payload: null };
}

export default createReducer(initialState, {
  [ADD_ENTITIES]: (state, action) => {
    return state.mergeDeep(action.payload.entities.sessionFile);
  },
  [CLEAR_SESSIONFILES]: (/* state, action */) => {
    return new Immutable.Map();
  },
});

export function fetchResourcesForSession(sessionId, studentId) {
  return async (dispatch) => {
    const result = await googleConnector.sessionResources(sessionId, studentId);
    const sessionFiles = result.sessionResources;
    const sessionFilesWithId = sessionFiles.map((s) => ({ ...s, sessionId }));
    await dispatch(clearSessionFiles());
    await dispatch(addEntitiesWithNormalisation(sessionFilesWithId, [sessionFileEntity]));
  };
}

export function createCollaborativeFileInSession(sessionId, fileName, type, studentId) {
  return async (dispatch) => {
    await googleConnector.createCollaborativeFileInSession(sessionId, fileName, type, studentId);
    await dispatch(fetchResourcesForSession(sessionId, studentId));
  };
}

export function deleteCollaborativeFileFromSession(sessionId, fileId, studentId) {
  return async (dispatch) => {
    await googleConnector.deleteCollaborativeFileFromSession(sessionId, fileId, studentId);
    await dispatch(fetchResourcesForSession(sessionId, studentId));
  };
}
