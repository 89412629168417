import React, { Component } from 'react';
import moment from 'moment';
import range from 'lodash/range';
import PropTypes from 'prop-types';

import css from './styles.scss';

export default class CalendarTimes extends Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    const { timezone } = this.props;
    const hours = range(24);
    const times = hours.map((hour) => (
      <div key={`hour${hour}`} className={css.hourIndicator}>
        {timezone ? moment(hour, 'H').tz(timezone).format('h A') : moment(hour, 'H').format('h A')}
      </div>
    ));
    return <div className={css.times}>{times}</div>;
  }
}

CalendarTimes.propTypes = {
  timezone: PropTypes.string,
};
