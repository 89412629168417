import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import PanelWithTitle from '../PanelWithTitle';
import DeadlineCard from './DeadlineCard';
import ForwardBackwardButtons from './ForwardBackwardButtons';
import { Column } from '../../../index.style';
import { APPLICATION_DEADLINE_ANIMATION_DURATION } from '../../constants';
import { StyledContainer } from './index.style';
import { groupByOfficialDeadline, useStaffDashboardContext } from '../../helper';
import LoadingView from './LoadingView';
import { StudentsModal, Placeholder } from '../../components';
import { AppYearStudent } from 'components/organisms/ApplicationTracker/type';
import moment from 'moment';
import { mapApplicationRegion } from 'components/organisms/ApplicationTracker/util';

const UpcomingApplicationDeadlines = () => {
  const [currentIndex, setIndex] = useState(0);
  const [lastIndex, setLastIndex] = useState(currentIndex);
  const [animatingIdx, setAnimatingIdx] = useState<number[]>([]);
  const [actionType, setActionType] = useState<'forward' | 'backward' | null>(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalStudents, setModalStudents] = useState<AppYearStudent[]>([]);
  const [modalTitle, setModalTitle] = useState('');
  const animationTimer = useRef<null | any>(null);
  const { state } = useStaffDashboardContext();
  const { applications, students, region } = state;
  useEffect(() => {
    // reset state on region change
    setIndex(0);
    setLastIndex(0);
    setAnimatingIdx([]);
  }, [region]);
  const applicationsByRegion = useMemo(() => applications.filter((o) => mapApplicationRegion(o) === region), [
    applications,
    region,
  ]);
  const data = useMemo(() => groupByOfficialDeadline(applicationsByRegion, students, region), [
    applicationsByRegion,
    students,
    region,
  ]);
  const maxLength = data.length;
  useEffect(() => {
    return () => {
      animationTimer.current && clearTimeout(animationTimer.current);
    };
  }, []);
  const isAnimating = !!animatingIdx.length;
  const handleAnimation = useCallback(
    (currentIdx: number) => {
      if (currentIdx === lastIndex) return;
      setAnimatingIdx([currentIdx, lastIndex]);
      // try to clear last timeout
      animationTimer.current && clearTimeout(animationTimer.current);
      animationTimer.current = setTimeout(() => {
        // update last index
        setLastIndex(currentIdx);
        // empty animating card indexes
        setAnimatingIdx([]);
      }, APPLICATION_DEADLINE_ANIMATION_DURATION);
    },
    [lastIndex],
  );
  const forward = () => {
    if (isAnimating) return;
    if (maxLength === 0) return;
    setActionType('forward');
    const nextCurrentIdx = Math.min(currentIndex + 1, maxLength - 1);
    setIndex(nextCurrentIdx);
    handleAnimation(nextCurrentIdx);
  };
  const backward = () => {
    if (isAnimating) return;
    if (currentIndex === 0) return;
    setActionType('backward');
    const nextCurrentIdx = Math.max(currentIndex - 1, 0);
    setIndex(nextCurrentIdx);
    handleAnimation(nextCurrentIdx);
  };

  const onClickCard = (label: string, students: AppYearStudent[]) => {
    setModalOpen(true);
    setModalStudents(students);
    setModalTitle(moment(label).format('MMM DD, YYYY'));
  };

  const closeModal = () => {
    setModalOpen(false);
    setModalStudents([]);
    setModalTitle('');
  };

  return (
    <PanelWithTitle title={'Official Application Deadlines'}>
      {state.isLoading && <LoadingView />}
      {!state.isLoading && !!data.length && (
        <Column gap={'16px'} style={{ marginTop: 20 }}>
          <StyledContainer gap={'30px'}>
            {data.map((item, idx) => (
              <DeadlineCard
                key={'officialdeadline' + item.label}
                initialIndex={idx}
                currentIndex={currentIndex}
                deadline={item.label}
                students={item.students}
                isAnimating={animatingIdx.includes(idx)}
                actionType={actionType}
                onClick={() => onClickCard(item.label, item.students)}
              />
            ))}
          </StyledContainer>

          <ForwardBackwardButtons
            style={{ alignSelf: 'center' }}
            onClickBack={backward}
            onClickForward={forward}
            isFirst={currentIndex === 0}
            isLast={maxLength === 0 || currentIndex === maxLength - 1}
          />
        </Column>
      )}
      {!state.isLoading && !data.length && (
        <Placeholder iconName={'02'} iconSize={80} placeholderText={'No valid data yet.'} />
      )}
      <StudentsModal students={modalStudents} isOpen={isModalOpen} onCancel={closeModal} title={modalTitle} />
    </PanelWithTitle>
  );
};

export default UpcomingApplicationDeadlines;
