import Immutable from 'immutable';
import createReducer from 'utils/createReducer';

const initialState = new Immutable.Map();

export const UPDATE_UNREAD_MESSAGE = 'globalState/UPDATE_UNREAD_MESSAGE';
export const HAS_NEW_FRIEND = 'globalState/HAS_NEW_FRIEND';

export default createReducer(initialState, {
  [UPDATE_UNREAD_MESSAGE]: (state, action) => state.set('hasUnReadMessage', action.payload.hasUnReadMessage),
  [HAS_NEW_FRIEND]: (state, action) => state.set('hasNewFriend', action.payload.hasNewFriend),
});

export function updateUnReadMessageStatus(status) {
  return async (dispatch) => {
    const hasUnReadMessage = status;
    dispatch({ type: UPDATE_UNREAD_MESSAGE, payload: { hasUnReadMessage } });
  };
}

export function updateHasNewFriendStatus(count) {
  return async (dispatch) => {
    const hasNewFriend = count;
    dispatch({ type: HAS_NEW_FRIEND, payload: { hasNewFriend } });
  };
}
