import { connect } from 'react-redux';
import { compose } from 'redux';
import { withAppContext } from 'components/enhancers/AppContext';
import { fetchResourcesForSession } from 'ducks/google/sessionFiles.js';
import { updateHeaderHidden } from 'ducks/meta';
import { getFilesForSession } from 'selectors/google';
import { getIsHeaderHidden } from 'selectors/meta';
import { getSelectedBooking } from 'selectors/booking';

import WorkspaceComponent from './Workspace';

const mapStateToProps = (state, props) => {
  const {
    match: {
      params: { sessionId },
    },
  } = props;

  return {
    resources: getFilesForSession(state, sessionId),
    isHeaderHidden: getIsHeaderHidden(state),
    session: getSelectedBooking(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchResourcesForSession: (sessionId, studentId) => dispatch(fetchResourcesForSession(sessionId, studentId)),
  updateHeaderHidden: (isHidden) => dispatch(updateHeaderHidden(isHidden)),
});

export default compose(withAppContext, connect(mapStateToProps, mapDispatchToProps))(WorkspaceComponent);
