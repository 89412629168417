import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './styles.scss';

export default function ProgressBar(props) {
  const { percent, percentSecondary, showWarningColor, warningPercent, width, isUserActive } = props;

  const secondaryBar = percentSecondary !== undefined;

  return (
    <div
      className={classNames(
        css.progressBar,
        { [css.warning]: showWarningColor && percent <= warningPercent },
        { [css.disabled]: isUserActive !== undefined && !isUserActive },
        { [css.error]: percent < 0 },
      )}
      style={{ width }}
    >
      <div className={css.outerBar} />
      {secondaryBar && (
        <div
          className={css.innerBarSecondary}
          style={{
            width: `calc(${percentSecondary}%)`,
            visibility: percentSecondary > 0 ? 'visible' : 'hidden',
          }}
        />
      )}
      <div
        className={css.innerBar}
        style={{
          width: `calc(${percent}%)`,
          visibility: percent > 0 ? 'visible' : 'hidden',
        }}
      />
    </div>
  );
}

ProgressBar.propTypes = {
  percent: PropTypes.number.isRequired,
  percentSecondary: PropTypes.number,
  showWarningColor: PropTypes.bool,
  warningPercent: PropTypes.number,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isUserActive: PropTypes.bool,
};

ProgressBar.defaultProps = {
  showWarningColor: false,
  warningPercent: 10,
  width: '100%',
};
