import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './Header.scss';

export default function Header({ text, className }) {
  return <h2 className={classNames(css.heading, className)}>{text}</h2>;
}

Header.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
};
