import styled from 'styled-components';
import { default as AntInput } from 'antd/lib/input';
import { Row } from '../index.style';
export const ContactCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  img {
    width: 40px;
    height: 40px;
    border-radius: 999px;
    object-fit: cover;
  }
`;

export const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  div:nth-child(1) {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height, or 121% */
    display: flex;
    align-items: center;

    /* Dark navy */
    color: #1d1e2b;
  }

  div:nth-child(2) {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */

    color: #a9acc0;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

export const IconBackground = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 999px;
  background: #f4f5ff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  // transition: opacity 0.4s;
  // :hover {
  //   opacity: 0.7;
  // }
`;

export const AlertDot = styled.div<{ $color: string }>`
  width: 10px;
  height: 10px;
  border-radius: 999px;
  background-color: ${({ $color }) => $color};
  flex-shrink: 0;
`;

export const AlertText = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #1d1e2b;
`;

export const CustomScrollbarView = styled.div`
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 99px;
    background-color: #e3e7ed;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

export const AlertCount = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
  > div:first-child {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    color: #000000;
  }
  svg {
    font-size: 22px;
    color: #73747d;
  }
`;

export const StyledIcon = styled.div`
  cursor: pointer;
  display: flex;
  &:hover {
    opacity: 0.7;
  }
  svg {
    font-size: 23px;
    color: #73747d;
  }
`;

export const InputWrapper = styled(Row)`
  border: 1px solid #e3e7ed;
  border-radius: 4px;
  padding: 0 12px;
  height: 40px;
  gap: 10px;
  background: #ffffff;

  transition: border-color 0.4s;
  align-items: center;

  input {
    height: 100%;
  }
  &:active {
    border-color: #000000;
  }
  &:hover {
    border-color: #000000;
  }

  svg {
    font-size: 18px;
    color: #1d1e2b;
    align-self: center;
  }
`;
export const StyledInput = styled(AntInput)`
  &::placeholder {
    font-weight: 500;
    font-size: 14px;

    color: #ababb1;
  }
`;
