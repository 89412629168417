import React from 'react';
import PropTypes from 'prop-types';
import { ModalHeader, Caption } from 'components/atoms/typography';
import styles from './styles.scss';

/* AccordionSummary
 * You'll probably use this inside the summary section of an Accordion.
 * This allows you to pass in a title for the accordion, as well as an optional
 * items count indicating how many things the accordion holds. The actions
 * component will be displayed in the top right (e.g. a delete button) if
 * provided.
 */

export default function AccordionSummary(props) {
  const { title, resultsText, results, actions } = props;

  const resultsComponent = results && (
    <Caption className={styles.results}>
      {results} {resultsText}
    </Caption>
  );

  const actionsComponent = actions && <span className={styles.actions}>{actions}</span>;

  return (
    <span className={styles.accordionSummary}>
      <ModalHeader>{title}</ModalHeader>
      {resultsComponent}
      {actionsComponent}
    </span>
  );
}

AccordionSummary.defaultProps = {
  resultsText: 'results',
};

AccordionSummary.propTypes = {
  title: PropTypes.string.isRequired,
  resultsText: PropTypes.string,
  results: PropTypes.string,
  actions: PropTypes.node,
};
