import styled from 'styled-components';

export const PlacholderText = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Stone */
  color: #73747d;
`;
