import React from 'react';
import {
  Application,
  ApplicationApproval,
  AppYearStudent,
  TeamMember,
  SessionReport,
  StaffStudentRelationship,
  ApplicationOverview,
} from '../../type';
import { StaffDashboardRegion, AlertInState } from '../type';
import { TODAY } from '../alerts/util';
export const initialState: TStaffDashboardState = {
  applications: [],
  approvals: [],
  students: [],
  studentTeamMembers: {},
  sessionReports: [],
  alerts: {
    US: [],
    EU: [],
    UK: [],
  },
  region: 'US',
  schoolSelectionData: {
    US: [],
    EU: [],
    UK: [],
  },
  milestoneMeeting: {
    US: [],
    EU: [],
    UK: [],
  },
  relationships: {},
  staffInfos: [],
  overviews: [],
  activeAlertValue: null,
  teamMemberStudentId: null,
  alertModalOpen: false,
  contactModalPosition: null,
  contactModalOpen: false,
  contactModalStudentId: null,
  userAlertsMap: {
    US: {},
    UK: {},
    EU: {},
  },
  isLoading: true,
  today: TODAY().format('YYYY-MM-DD'),
  isCalculating: {
    alert: true,
    schoolSelction: true,
    meeting: true,
  },
};
export type TStaffDashboardState = {
  applications: Application[];
  approvals: ApplicationApproval[];
  students: AppYearStudent[];
  studentTeamMembers: Record<string, TeamMember[]>;
  sessionReports: SessionReport[];
  alerts: Record<StaffDashboardRegion, AlertInState[]>;
  region: StaffDashboardRegion;
  schoolSelectionData: Record<StaffDashboardRegion, AlertInState[]>;
  milestoneMeeting: Record<StaffDashboardRegion, AlertInState[]>;
  relationships: StaffStudentRelationship;
  staffInfos: TeamMember[];
  overviews: ApplicationOverview[];
  activeAlertValue: null | string;
  teamMemberStudentId: string | null;
  alertModalOpen: boolean;
  contactModalOpen: boolean;
  contactModalPosition: (Pick<React.CSSProperties, 'translate'> & { top: number; left: number }) | null;
  contactModalStudentId: string | null;
  userAlertsMap: Record<StaffDashboardRegion, Record<string, string[]>>;
  isLoading: boolean;
  today: string; // for triggering rerender
  isCalculating: Record<'alert' | 'schoolSelction' | 'meeting', boolean>; // avoid page flash.
};
export type TAction<N, K extends keyof TStaffDashboardState> = {
  name: N;
  payload: Pick<TStaffDashboardState, K>;
};
export type TActionWithCustomPayload<N, P> = {
  name: N;
  payload: P;
};

export type TActionWithoutPayload<N> = {
  name: N;
  payload: undefined;
};

type SetApplications = TAction<'staffDashboard/setApplications', 'applications'>;
type SetApplicationApprovals = TAction<'staffDashboard/setApplicationApprovals', 'approvals'>;
type SetStudents = TAction<'staffDashboard/setStudents', 'students'>;
type SetStudentTeamMembers = TActionWithCustomPayload<
  'staffDashboard/setStudentTeamMembers',
  Record<string, TeamMember[]>
>;
type SetSessionReports = TAction<'staffDashboard/setSessionReports', 'sessionReports'>;
type SetAlerts = TActionWithCustomPayload<'staffDashboard/setAlerts', Partial<TStaffDashboardState['alerts']>>;
type SetRegion = TAction<'staffDashboard/setRegion', 'region'>;
type SetSchoolSelectionData = TActionWithCustomPayload<
  'staffDashboard/setSchoolSelectionData',
  Partial<TStaffDashboardState['schoolSelectionData']>
>;
type SetMilestoneMeetingData = TActionWithCustomPayload<
  'staffDashboard/setMilestoneMeetingData',
  Partial<TStaffDashboardState['milestoneMeeting']>
>;
type SetRelationships = TAction<'staffDashboard/setRelationships', 'relationships'>;
type SetStaffInfo = TAction<'staffDashboard/setStaffInfo', 'staffInfos'>;
type SetOverviews = TAction<'staffDashboard/setOverviews', 'overviews'>;
type SetActiveAlert = TAction<'staffDashboard/setActiveAlert', 'activeAlertValue'>;
type SetTeamMemberStudentId = TAction<'staffDashboard/setTeamMemberStudentId', 'teamMemberStudentId'>;
type SetAlertModalOpen = TAction<'staffDashboard/setAlertModalOpen', 'alertModalOpen'>;
type SetContactModalOpen = TAction<
  'staffDashboard/setContactModalOpen',
  'contactModalPosition' | 'contactModalStudentId'
>;
type SetContactModalClose = TActionWithoutPayload<'staffDashboard/setContactModalClose'>;
type SetUserAlertsMap = TAction<'staffDashboard/setUserAlertsMap', 'userAlertsMap'>;
type SetLoading = TAction<'staffDashboard/setLoading', 'isLoading'>;
type SetBatchData = TAction<
  'staffDashboard/setBatchData',
  'students' | 'applications' | 'approvals' | 'sessionReports' | 'relationships' | 'staffInfos' | 'overviews'
>;
type SetToday = TAction<'staffDashboard/setToday', 'today'>;

type SetCalculating = TActionWithCustomPayload<
  'staffDashboard/setCalculating',
  Partial<TStaffDashboardState['isCalculating']>
>;

export type Actions =
  | SetApplications
  | SetApplicationApprovals
  | SetStudents
  | SetStudentTeamMembers
  | SetSessionReports
  | SetAlerts
  | SetRegion
  | SetSchoolSelectionData
  | SetMilestoneMeetingData
  | SetRelationships
  | SetStaffInfo
  | SetOverviews
  | SetActiveAlert
  | SetTeamMemberStudentId
  | SetAlertModalOpen
  | SetContactModalOpen
  | SetContactModalClose
  | SetUserAlertsMap
  | SetLoading
  | SetBatchData
  | SetToday
  | SetCalculating;

export const reducer = <A extends Actions>(state: TStaffDashboardState, action: A): TStaffDashboardState => {
  switch (action.name) {
    case 'staffDashboard/setApplications':
      const { applications } = action.payload;
      return {
        ...state,
        applications,
      };
    case 'staffDashboard/setApplicationApprovals':
      const { approvals } = action.payload;
      return {
        ...state,
        approvals,
      };
    case 'staffDashboard/setStudents':
      const { students } = action.payload;
      return {
        ...state,
        students,
      };

    case 'staffDashboard/setStudentTeamMembers':
      const { ...studentTeamMembers } = action.payload;
      return {
        ...state,
        studentTeamMembers: {
          ...state.studentTeamMembers,
          ...studentTeamMembers,
        },
      };

    case 'staffDashboard/setSessionReports':
      const { sessionReports } = action.payload;
      return {
        ...state,
        sessionReports,
      };
    case 'staffDashboard/setAlerts':
      const { ...regionAlerts } = action.payload;
      return {
        ...state,
        alerts: {
          ...state.alerts,
          ...regionAlerts,
        },
      };
    case 'staffDashboard/setRegion':
      const { region } = action.payload;
      return {
        ...state,
        region,
      };
    case 'staffDashboard/setSchoolSelectionData':
      const { ...schoolSelectionData } = action.payload;
      return {
        ...state,
        schoolSelectionData: {
          ...state.schoolSelectionData,
          ...schoolSelectionData,
        },
      };
    case 'staffDashboard/setMilestoneMeetingData':
      const { ...milestoneMeeting } = action.payload;
      return {
        ...state,
        milestoneMeeting: {
          ...state.milestoneMeeting,
          ...milestoneMeeting,
        },
      };
    case 'staffDashboard/setRelationships':
      const { relationships } = action.payload;
      return {
        ...state,
        relationships,
      };
    case 'staffDashboard/setStaffInfo':
      const { staffInfos } = action.payload;
      return {
        ...state,
        staffInfos,
      };
    case 'staffDashboard/setOverviews':
      const { overviews } = action.payload;
      return {
        ...state,
        overviews,
      };
    case 'staffDashboard/setActiveAlert':
      const { activeAlertValue } = action.payload;
      return {
        ...state,
        activeAlertValue,
      };
    case 'staffDashboard/setTeamMemberStudentId':
      const { teamMemberStudentId } = action.payload;
      return {
        ...state,
        teamMemberStudentId,
      };
    case 'staffDashboard/setAlertModalOpen':
      const { alertModalOpen } = action.payload;
      return {
        ...state,
        alertModalOpen,
      };
    case 'staffDashboard/setContactModalOpen':
      const { contactModalPosition, contactModalStudentId } = action.payload;
      return {
        ...state,
        contactModalOpen: true,
        contactModalPosition,
        contactModalStudentId,
      };
    case 'staffDashboard/setContactModalClose':
      return {
        ...state,
        contactModalOpen: false,
        contactModalPosition: null,
        contactModalStudentId: null,
      };
    case 'staffDashboard/setUserAlertsMap':
      const { userAlertsMap } = action.payload;
      return {
        ...state,
        userAlertsMap,
      };
    case 'staffDashboard/setLoading':
      const { isLoading } = action.payload;
      return {
        ...state,
        isLoading,
      };
    case 'staffDashboard/setBatchData':
      const { ...payload } = action.payload;
      return {
        ...state,
        ...payload,
      };
    case 'staffDashboard/setToday':
      const { today } = action.payload;
      return {
        ...state,
        today,
      };
    case 'staffDashboard/setCalculating':
      const { ...isCalculating } = action.payload;
      return {
        ...state,
        isCalculating: {
          ...state.isCalculating,
          ...isCalculating,
        },
      };
    default:
      return state;
  }
};
