/**
 * Types of form states.
 * @readOnly
 * @enum {string}
 */
const formState = {
  Success: 'successful',
  Fail: 'failed',
  Default: 'default',
  Loading: 'loading',
};

export default formState;
