import React from 'react';
import PropTypes from 'prop-types';

export default function LogoColored(props) {
  return (
    <svg viewBox="0 0 40 40" {...props}>
      <g fill="none" fillRule="evenodd">
        <g fill="#aa1e23">
          <path d="M8.533 5.931L7.468 4.054a.43.43 0 0 0-.75-.002L4.385 8.104a.882.882 0 0 0 0 .88l1.175 2.038a14.547 14.547 0 0 1 2.974-5.09M4.027.31l1.456 2.532a.895.895 0 0 1 0 .892L4.027 6.265a.452.452 0 0 1-.782.003L1.77 3.738a.895.895 0 0 1 0-.9L3.245.307c.176-.3.609-.3.782.003M1.847 6.772l.93 1.618a.895.895 0 0 1 0 .891l-.93 1.618a.451.451 0 0 1-.783.003L.122 9.286a.895.895 0 0 1 0-.901l.942-1.616c.176-.3.61-.3.783.003" />
        </g>
        <path
          d="M32.126 8.692c-2.96-5.073-8.823-8.056-14.96-7.011-3.276.557-6.13 2.21-8.244 4.527l1.772.445a.355.355 0 0 1 .22.522L8.3 11.725a.437.437 0 0 1-.493.205l-1.957-.528a14.477 14.477 0 0 0-.681 6.354c.964 8.271 8.554 14.01 16.666 12.738a14.44 14.44 0 0 0 3.628-1.07.79.79 0 0 0 .377-1.08l-1.725-3.39a.784.784 0 0 0-.967-.38c-.438.157-.89.286-1.355.386-5.582 1.196-2.86-1.149-4.31-2.433-.809-.716-2.757-1.337-4.258-1.399-3.365-.138-3.325-1.368-2.683-2.742.445-.951.24-1.478.328-1.891.088-.413.094-.57.298-.782.308-.32.063-.74-.201-1.11-.598-.836-.442-1.374.39-1.547.833-.174 2.535-1.066 2.662-1.764.127-.698 1.218-3.577 3.537-4.142 3.825-.933 7.757.266 9.944 4.016a.785.785 0 0 0 1.037.311l3.27-1.68a.794.794 0 0 0 .32-1.105"
          fill="#232950"
        />
      </g>
    </svg>
  );
}

LogoColored.defaultProps = {
  height: 31,
  width: 32,
};

LogoColored.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};
