import styled from 'styled-components';

export const StyledSector = styled.path`
  cursor: pointer;
  transition: opacity 0.4s;
  &:hover {
    opacity: 0.7;
  }
`;

export const StyledCircle = styled.circle`
  cursor: pointer;
  transition: opacity 0.4s;
  &:hover {
    opacity: 0.7;
  }
`;
