import React from 'react';
import PropTypes from 'prop-types';

import SessionReportView from 'components/molecules/SessionReportView';
import Card from 'components/molecules/Card';
import css from './styles.scss';

export default function SessionSummaryReport({ report }) {
  return report && report.lessonId ? (
    <Card className={css.sessionSummaryReport}>
      <div className={css.header}>
        <h1>Session report</h1>
        <span>Not visible to the student</span>
      </div>
      <SessionReportView report={report} />
    </Card>
  ) : null;
}

SessionSummaryReport.propTypes = {
  report: PropTypes.object,
};
