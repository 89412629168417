import createReducer from 'utils/createReducer';
import Immutable from 'immutable';
import { universityEntity } from 'schema';
import { ADD_ENTITIES, addEntitiesWithNormalisation } from 'ducks/normalizr';
import admissionService from 'graphql/admissionService';

const defaultState = new Immutable.Map();

export default createReducer(defaultState, {
  [ADD_ENTITIES]: (state, action) => {
    return state.mergeDeep(action.payload.entities.university);
  },
});

export function fetchAdmissionsUniversities() {
  return async (dispatch) => {
    const { getAdmissionsUniversities } = await admissionService.fetchAdmissionsUniversities();
    const mapped = getAdmissionsUniversities.map((item, index) => ({ id: index, name: item.name }));
    dispatch(addEntitiesWithNormalisation(mapped, [universityEntity]));
  };
}

// notes:
// do tests
// add connect fields
