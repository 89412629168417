import { normalize } from 'normalizr';

import { agendaEntity, agendaItemEntity } from 'schema';
import agendaService from 'graphql/api/agenda';
import * as Logger from '@crimson-education/browser-logger';
import { formatGraphQLRequestError } from 'utils/graphql';

export const FETCH_AGENDA_ITEMS = 'agenda/FETCH_AGENDA_ITEMS';
export const FETCH_AGENDA_ITEMS_COMPLETE = 'agenda/FETCH_AGENDA_ITEMS_COMPLETE';
export function fetchAgendaItems(eventId) {
  return async (dispatch) => {
    dispatch({
      type: FETCH_AGENDA_ITEMS,
      payload: { entities: normalize({ id: eventId, isLoading: true }, agendaEntity).entities },
    });

    const result = await agendaService.getAgendaByEvent(eventId.toString());
    const payload = normalize({ id: eventId, isLoading: false, items: result.agenda }, agendaEntity);
    delete payload.result;

    dispatch({
      type: FETCH_AGENDA_ITEMS_COMPLETE,
      payload,
    });
  };
}

export const SET_AGENDA_ITEM = 'agenda/SET_AGENDA_ITEM';
export const SET_AGENDA_ITEM_COMPLETE = 'agenda/SET_AGENDA_ITEM_COMPLETE';
export function setAgendaItem(eventId, id, text, html, version, overwrite) {
  return async (dispatch) => {
    try {
      if (text && text.trim() === '' && html && html.trim() !== '') {
        Logger.trackEvent({ message: 'suspicious session agenda', metadata: { eventId } });
      }
      dispatch({
        type: SET_AGENDA_ITEM,
        payload: { entities: normalize({ id, isUpdating: true }, agendaItemEntity).entities },
      });

      const result = await agendaService.setAgendaItem(eventId.toString(), id, text, html, version, overwrite);
      const payload = {
        id,
        eventId,
        entities: normalize({ ...result.setAgendaItem, isUpdating: false }, agendaItemEntity).entities,
      };

      dispatch({
        type: SET_AGENDA_ITEM_COMPLETE,
        payload,
      });
      return result.setAgendaItem;
    } catch (err) {
      Logger.reportError(err);
      Logger.trackEvent({
        message: 'failed to save session data',
        metadata: { eventId, err },
      });
      dispatch({
        type: SET_AGENDA_ITEM,
        payload: { entities: normalize({ id, isUpdating: false }, agendaItemEntity).entities },
      });
      return { error: true, message: formatGraphQLRequestError(err) };
    }
  };
}

export const DELETE_AGENDA_ITEMS = 'agenda/DELETE_AGENDA_ITEMS';
export const DELETE_AGENDA_ITEMS_COMPLETE = 'agenda/DELETE_AGENDA_ITEMS_COMPLETE';
export function deleteAgendaItems(ids) {
  return async (dispatch) => {
    const entities = normalize(
      ids.map((x) => ({ id: x, isDeleting: true })),
      [agendaItemEntity],
    ).entities;
    dispatch({
      type: DELETE_AGENDA_ITEMS,
      payload: { entities },
    });

    await agendaService.deleteAgendaItems(ids);

    Object.values(entities.agendaItem).forEach((x) => {
      x.isDeleting = false;
      x.isDeleted = true;
    });

    dispatch({
      type: DELETE_AGENDA_ITEMS_COMPLETE,
      payload: {
        entities,
      },
    });
  };
}
