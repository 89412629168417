import { connect } from 'react-redux';
import { compose } from 'redux';

import { unwrapImmutable } from 'utils/utils';
import {
  fetchThreads,
  prepareThread,
  getActiveThread,
  getThreads,
  getThreadsHasNextPage,
  getCommunityThreadsHasNextPage,
  getIsFetchingThreads,
  getOnlineUsers,
  getAllParticipantsId,
  markUserOnlineStatus,
  consumeThread,
} from 'ducks/messaging';
import { updateCurrentThreadId } from 'ducks/meta';
import { fetchingUserContacts } from 'ducks/user';
import { updateUnReadMessageStatus } from 'ducks/globalState';
import { getFilteredContacts, getLoginUser } from 'selectors/user';
import { getCachedContactListPageInfo } from 'selectors/meta';
import { withAppContext } from 'components/enhancers/AppContext';

import Conversations from './Conversations';

// export { default as FirebaseMessageListener } from './FirebaseMessageListener';

export default compose(
  connect(
    (state) => ({
      filteredContacts: getFilteredContacts(state).toList().toJS(),
      contactPageInfo: getCachedContactListPageInfo(state).toJS(),
      threads: getThreads(state).toJS(),
      threadsHasNextPage: getThreadsHasNextPage(state),
      activeThread: unwrapImmutable(getActiveThread(state)),
      currentUser: unwrapImmutable(getLoginUser(state)),
      isFetchingThreads: getIsFetchingThreads(state),
      onlineUsers: getOnlineUsers(state),
      otherParticipantsIds: getAllParticipantsId(state),
    }),
    (dispatch, ownProps) => ({
      fetchThreads: (userId, source, threadIds) => dispatch(fetchThreads(userId, source, threadIds)),
      prepareThread: (userIds, groupName) => dispatch(prepareThread(ownProps.history, userIds, groupName)),
      setContactsFilter: (filter) => dispatch(fetchingUserContacts(filter)),
      markUserOnlineStatus: (userId, status) => dispatch(markUserOnlineStatus(userId, status)),
      updateUnReadMessageStatus: (status) => dispatch(updateUnReadMessageStatus(status)),
      consumeThread: (threadId) => dispatch(consumeThread(threadId)),
      updateCurrentThreadId: (threadId) => dispatch(updateCurrentThreadId(threadId)),
    }),
  ),
)(withAppContext(Conversations));
