import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Acl, eventStatusTypes } from '@crimson-education/common-config';
import { getLessonsSummary, getTutorSummary, getRoleSummary } from 'utils/lessonUtils';
import MyLessonsTable from 'components/unique/MyLessonsTable';

export default class MyLessons extends Component {
  constructor() {
    super();
    this.makeTitlesForStudent = this.makeTitlesForStudent.bind(this);
    this.makeTitlesForTutor = this.makeTitlesForTutor.bind(this);
    this.makeTitlesForAdmin = this.makeTitlesForAdmin.bind(this);
    this.makeTitlesForStrategist = this.makeTitlesForStrategist.bind(this);
    this.makeTitles = this.makeTitles.bind(this);
  }

  componentDidMount() {
    const { fetchPackageItem, fetchLessons } = this.props;

    fetchLessons();
    fetchPackageItem();
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { fetchLessons, match, allLessons } = this.props;

    const oldLessonId = match.params.lessonId;
    const newLessonId = newProps.match.params.lessonId;

    // Check for edge case where tutor submits a report whilst someone is on the lessons page for a student
    if (oldLessonId !== newLessonId && newLessonId) {
      const newLesson = allLessons.find((lesson) => lesson.get('id') && lesson.get('id').toString() === newLessonId);
      if (!newLesson || newLesson.get('status') === eventStatusTypes.AWAITING_SESSION_REPORT) {
        fetchLessons();
      }
    }
  }

  makeTitlesForStudent() {
    const { packageItem, allLessons } = this.props;

    const title = packageItem.get('name');
    const tutorString = getTutorSummary(packageItem);
    const lessonString = getLessonsSummary(allLessons);

    return {
      title,
      subtitles: [tutorString, lessonString],
    };
  }

  makeTitlesForStrategist(studentFullName) {
    return {
      title: studentFullName,
    };
  }

  makeTitlesForTutor(studentFullName) {
    const { allLessons } = this.props;

    const lessonString = getLessonsSummary(allLessons);

    return {
      title: studentFullName,
      subtitles: [lessonString],
    };
  }

  makeTitlesForAdmin(studentFullName) {
    const { packageItem, allLessons } = this.props;

    const title = packageItem.get('name');
    const tutor = packageItem.getIn(['contract', 0, 'user']);

    const lessonString = getLessonsSummary(allLessons);
    const studentString = `Student: ${studentFullName}`;
    const tutorString = getRoleSummary(tutor, 'Current tutor');

    return {
      title,
      subtitles: [studentString, tutorString, lessonString],
    };
  }

  // Makes titles based on the user's role if sufficient information is present.
  makeTitles() {
    const { loginUserRoles, packageItem } = this.props;

    if (packageItem) {
      if (Acl.isStudent(loginUserRoles)) {
        return this.makeTitlesForStudent();
      }

      const studentFirstName = packageItem.getIn(['package', 'user', 'firstName']);
      const studentLastName = packageItem.getIn(['package', 'user', 'lastName']);
      const studentFullName = `${studentFirstName} ${studentLastName}`;

      if (Acl.isHeadTutor(loginUserRoles) || Acl.isTutor(loginUserRoles)) {
        return this.makeTitlesForTutor(studentFullName);
      }

      if (Acl.isStrategist(loginUserRoles) || Acl.isOperationalSupport(loginUserRoles)) {
        return this.makeTitlesForStrategist(studentFullName);
      }

      if (Acl.isAdmin(loginUserRoles)) {
        return this.makeTitlesForAdmin(studentFullName);
      }
    }

    return { title: '' };
  }

  render() {
    const titles = this.makeTitles();

    return <MyLessonsTable titles={titles} {...this.props} />;
  }
}

MyLessons.propTypes = {
  loginUserRoles: PropTypes.array.isRequired,
  loginUserPermissions: PropTypes.array.isRequired,
  allLessons: ImmutablePropTypes.map,
  packageItem: ImmutablePropTypes.map,
  fetchPackageItem: PropTypes.func.isRequired,
  fetchLessons: PropTypes.func.isRequired,
  lessonsFetched: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
};
