import React, { useMemo } from 'react';
import { DonutChart, AlertItem, Placeholder } from '../../components';
import PanelWithTitle from '../PanelWithTitle';
import { SchoolSelectionIconContainer, SchoolSelectionItemsContainer } from '../index.style';
import { useStaffDashboardContext } from '../../helper';
import SchoolSelectionPanelLoadingView from './SchoolSelectionPanelLoadingView';

const DEFAULT_SCHOOL_LOGO = '/static/applicationTracker/defaultSchoolLogo.png';
const SchoolSelectionPanel = () => {
  const { state, controller } = useStaffDashboardContext();
  const ALERTS = state.schoolSelectionData[state.region];
  const CHART_DATA = useMemo(() => {
    const totalUserIdCount = ALERTS.reduce((prev, curr) => prev + curr.userIds.length, 0);
    return ALERTS.map((o) => ({
      color: o.color,
      percentage: !!totalUserIdCount ? o.userIds.length / totalUserIdCount : 0,
      item: o,
      value: o.value,
    }));
  }, [ALERTS]);
  const isEmpty = ALERTS.every((o) => !o.userIds.length);
  const isCalculating = state.isCalculating['schoolSelction'];
  const isLoading = state.isLoading;
  return (
    <PanelWithTitle title={'School Selection Report'}>
      {isLoading && <SchoolSelectionPanelLoadingView />}
      {!isLoading && !isEmpty && (
        <>
          <div style={{ alignSelf: 'center', marginTop: 31, display: 'flex', position: 'relative' }}>
            <DonutChart
              onClickSector={controller.openAlertDetailModal}
              data={CHART_DATA}
              width={180}
              height={180}
              innerRadius={55}
              outerRadius={90}
            />
            <SchoolSelectionIconContainer>
              <img src={DEFAULT_SCHOOL_LOGO} alt={DEFAULT_SCHOOL_LOGO} />
            </SchoolSelectionIconContainer>
          </div>
          <SchoolSelectionItemsContainer style={{ marginTop: 30 }}>
            {ALERTS.map((item) => (
              <AlertItem
                key={item.value}
                onClick={() => !!item.userIds.length && controller.openAlertDetailModal(item.value)}
                title={item.label}
                dotColor={item.color}
                count={item.userIds.length}
                titleStyle={{ color: '#73747D' }}
              />
            ))}
          </SchoolSelectionItemsContainer>
        </>
      )}
      {!isLoading && isEmpty && !isCalculating && (
        <Placeholder
          iconSize={90}
          containerStyle={{ flexDirection: 'column', gap: 20 }}
          iconName={'01'}
          placeholderText={'No valid data yet.'}
        />
      )}
    </PanelWithTitle>
  );
};

export default SchoolSelectionPanel;
