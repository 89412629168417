import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import { getCurrentUserJS, getUserWithId } from 'selectors/user';
import { selectUserProfile } from 'selectors/meta';
import * as zoid from 'zoid/dist/zoid.frameworks.js';
import { getEnvironmentConfig } from '@crimson-education/common-config';
import { history } from '../../../app';
import { getBearer, authorize } from '../../../utils/auth/auth';
import { featureSwitches, getFeatureSwitchesAsString } from '../../../featureSwitches';
import styled from 'styled-components';

const { environment } = getEnvironmentConfig();

const StyledContainer = styled.div`
  & .zoid-visible {
    height: 100vh !important;
  }
`;

const environmentHostnames = {
  local: 'http://localhost:3015',
  staging: 'https://roadmap.staging.core-progress.crimsoneducation.org',
  production: 'https://roadmap.core-progress.crimsoneducation.org',
};
const { protocol, hostname, port } = window.location;

const domain = `${protocol}//${hostname}${port ? `:${port}` : ''}`;

const roadmapDomain = environmentHostnames[featureSwitches.ROADMAP_CONNECT_LOCAL() ? 'local' : environment];
// store all the registered event callbacks.
const eventEmitters = {};
// store the student center current url
let roadmapUrl;
// tag the url change original source from iframe of navbar.
// if it's true, the iframe will not refresh
let innerChange = false;

const RoadmapComponent = zoid.create({
  tag: 'roadmap-component',
  url: ({ props }) => {
    if (props && props.history) {
      const { location } = props.history;
      const { pathname } = location;
      const regex = /(\/roadmap|\/users.*roadmap)/i;
      return roadmapDomain + pathname.replace(regex, '') + location.search;
    }
    return roadmapDomain;
  },
  dimensions: {
    width: '100%',
    height: '99vh',
  },
  props: {
    token: {
      type: 'string',
      isRequired: false,
    },
    userId: {
      type: 'string',
      isRequired: true,
    },
    studentId: {
      type: 'string',
      isRequired: true,
    },
    authorize: {
      type: 'function',
      isRequired: false,
    },
    getBearer: {
      type: 'function',
      isRequired: false,
    },
  },
});

const DynamicReactComponent = RoadmapComponent.driver('react', {
  React,
  ReactDOM,
});

// registerEventEmitter
// a function to add more callbacks from iframe
const registerEventEmitter = (eventName, callback) => {
  eventEmitters[eventName] = callback;
};

const unregisterEventEmitter = (eventName) => {
  delete eventEmitters[eventName];
};

// invoke the event callbacks
// conditionally, if the innerChange is true, we should do something special
const performEvent = (eventName, data) => {
  if (innerChange) {
    innerChange = false;
    return;
  }
  eventEmitters[eventName] && eventEmitters[eventName](data);
};

const onMessage = ({ userId }) => ({ event, data }) => {
  // accept the route event from the url routing change of the iframe.
  if (event === 'route') {
    if (roadmapUrl === data) {
      return;
    }
    innerChange = true;
    roadmapUrl = data;
    window.history.pushState({}, '', `/users/${userId}/roadmap${data}`);
  }
};

function Roadmap() {
  const student = useSelector(getCurrentUserJS);
  const user = useSelector(selectUserProfile);
  const userDetails = useSelector(getUserWithId(user.get('userId')));

  // fix about the navbar button doesn't work issue.
  useEffect(() => {
    performEvent('refresh', `${history.location.pathname}${history.location.search}`);
  });
  return (
    <StyledContainer>
      <DynamicReactComponent
        registerEventEmitter={registerEventEmitter}
        unregisterEventEmitter={unregisterEventEmitter}
        history={history}
        onMessage={onMessage({ userId: student.userId })}
        token={getBearer() || ''}
        getBearer={getBearer}
        authorize={authorize}
        featureSwitches={JSON.parse(getFeatureSwitchesAsString())}
        userId={user.get('userId')}
        studentId={student.userId}
        user={{
          userId: user.get('userId'),
          email: user.get('email'),
          firstName: userDetails.get('firstName'),
          lastName: userDetails.get('lastName'),
          role: user.get('role'),
        }}
        domain={domain}
      />
    </StyledContainer>
  );
}

export default Roadmap;
