import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as Icons from 'components/atoms/icons';
import css from './style.scss';
import { handleEnter } from '../../../utils/accessibility';

export default function IconInCircle(props) {
  const { onClick, icon, size, primary, secondary, disabled } = props;
  const Icon = Icons[icon];

  return (
    <div
      role="button"
      tabIndex={0}
      data-ga-label={`icon - ${icon}`}
      className={classNames({
        [css.container]: true,
        [css.primary]: primary,
        [css.secondary]: secondary,
        [css.disabled]: disabled,
      })}
      onClick={onClick}
      onKeyDown={handleEnter(onClick)}
    >
      <div className={css.circle} />
      <Icon className={css.icon} style={{ height: size, width: size }} />
    </div>
  );
}

IconInCircle.defaultProps = {
  size: '1.4rem',
};

IconInCircle.propTypes = {
  icon: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.string,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  // tertiary: PropTypes.bool,
  disabled: PropTypes.bool,
};
