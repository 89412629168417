import React from 'react';
import PropTypes from 'prop-types';
import { Acl, permissionTypes } from '@crimson-education/common-config';
import Tag, { TAG_STYLE, TAG_SIZE } from 'components/molecules/Tag';
import { Star } from 'components/atoms/icons';

import css from './styles.scss';

export default function VIPStudentIndicator(props) {
  const { isCompact, loginUserPermissions, isVIP } = props;

  const canView = Acl.checkPermission(loginUserPermissions, permissionTypes['STUDENT_TYPE:VIEW']);
  const icon = <Star style={{ width: '10px', height: '10px' }} />;
  const text = !isCompact && <span>VIP</span>;

  return (
    canView &&
    isVIP && (
      <Tag
        autoWidth
        style={TAG_STYLE.FILL_CHEDDAR}
        size={isCompact ? TAG_SIZE.SMALL : TAG_SIZE.REGULAR}
        text={
          <div className={css.vipTagTitle}>
            {icon} {text}
          </div>
        }
      />
    )
  );
}

VIPStudentIndicator.defaultProps = {
  isCompact: true,
};

VIPStudentIndicator.propTypes = {
  userId: PropTypes.string.isRequired,
  loginUserPermissions: PropTypes.array.isRequired,
  isVIP: PropTypes.bool,
  isCompact: PropTypes.bool,
};
