import SelectFilter from './select.js';

export default class NumberOfStudentsFilter extends SelectFilter {
  constructor(...args) {
    super(...args);
    this.title = 'Number of students';
    this.name = 'Number of students';
    this.options = {
      'Less than 5': 5,
      'Less than 10': 10,
      'Less than 15': 15,
      'Show all': null,
    };

    this.state.all = Object.keys(this.options);
  }

  apply(tutor) {
    if (!this.active()) {
      return true;
    }
    const number = this.options[this.state.filtered];
    if (number === null) {
      return true;
    }
    return tutor.tutorInfo.studentCount < number;
  }

  data() {
    /* Doesn't use data */
  }

  value() {
    const number = this.options[this.state.filtered];
    if (number === null || !number) {
      return [];
    }
    return `less than ${number}`;
  }

  simpleValue() {
    return this.value();
  }
}
