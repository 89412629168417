import React from 'react';
import PropTypes from 'prop-types';

import Avatar from 'components/molecules/Avatar';
import { SubHeader } from 'components/atoms/typography';

import css from './profile.scss';

export default function Profile({ user }) {
  return (
    <section className={css.profile}>
      <Avatar
        firstName={user.firstName}
        lastName={user.lastName}
        colourIndex={user.colourIndex}
        image={user.profileImageUrl}
        size="normal"
        showStudentType
        userId={user.userId}
        className={css.dashboardAvatar}
      />
      <div className={css.details}>
        <SubHeader>
          {user.firstName} {user.lastName}
        </SubHeader>
      </div>
    </section>
  );
}

Profile.propTypes = {
  user: PropTypes.object,
};
