import React, { FC } from 'react';
import './styles.scss';
import { Container, ContentContainer, Header } from './index.styles';

const CallbackContainer: FC = ({ children }) => {
  return (
    <Container>
      <Header>
        <div role={'link'} onKeyDown={() => void 0} tabIndex={0} onClick={() => window.location.replace('/')}>
          <img src={'/static/logo/dark.png'} alt={'logo'} />
        </div>
      </Header>
      <ContentContainer>{children}</ContentContainer>
    </Container>
  );
};

export default CallbackContainer;
