import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/molecules/Modal';
import { Body } from 'components/atoms/typography';

export default function InvoiceActionModal(props) {
  const { isOpen, onSubmit, onClose, title, bodyText } = props;

  return (
    isOpen && (
      <Modal isOpen onSubmit={onSubmit} onClose={onClose} title={title} submitText="Confirm">
        <Body>{bodyText}</Body>
      </Modal>
    )
  );
}

InvoiceActionModal.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  bodyText: PropTypes.string,
};
