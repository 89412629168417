import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styled from 'styled-components';
import LoadingIndicator from 'components/molecules/LoadingIndicator';

import css from '../styles.scss';

const IFrameStyled = styled.iframe`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
`;

const ReportViewer = (props) => {
  const { student, studentReport, uxState } = props;
  if (!studentReport || !studentReport.generation || !studentReport.generation.url || uxState.loading) {
    return (
      <div>
        <h2 className={classnames('typography', 'bread-crumb', { [css.hidden]: student })}>Select a student</h2>
        <LoadingIndicator className={classnames({ [css.hidden]: !student || !uxState.loading })} colour="hint" />
      </div>
    );
  }

  return <IFrameStyled src={studentReport.generation.url} title="embed" />;
};

ReportViewer.propTypes = {
  student: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
  }),
  studentReport: PropTypes.shape({
    generation: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
  uxState: PropTypes.shape({
    loading: PropTypes.boolean,
  }),
};

export default ReportViewer;
