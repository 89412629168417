import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import fontFamily from './fontFamily.scss';
import font from './font.scss';
import fontColour from './fontColour.scss';

export default function ButtonText(props) {
  return (
    <div
      className={classNames(
        { [fontFamily.regular]: !props.bold },
        { [fontFamily.bold]: props.bold },
        font.buttonText,
        fontColour.primary,
        props.className,
      )}
      style={props.style}
    >
      {props.children}
    </div>
  );
}

ButtonText.defaultProps = {
  className: '',
  style: {},
  bold: false,
};

ButtonText.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  bold: PropTypes.bool,
};
