import React from 'react';
import PropTypes from 'prop-types';

export default function CrimsonHub({ className }) {
  return (
    <svg className={className} height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" transform="translate(5 4)">
        <g fill="#222950">
          <path d="m11.9539723 6.92133407-11.65207756 6.77303493c-.18610234.1066886-.30117545.3045336-.30189474.5190471v1.4035457c-.00007739.1072476.05714479.2063664.15005939.2599289.0929146.0535626.20736496.053408.30013452-.0004054l13.35963659-7.71261605c.0931811-.05343364.1506517-.15263864.1506517-.26005318 0-.10741455-.0574706-.20661955-.1506517-.26005319l-1.2541873-.72348809c-.1862884-.10736968-.4157617-.10696568-.6016709.00105928z" />
          <path d="m0 8.36831025v2.87700385c-.00030737.1613201.08572536.3104739.22552065.3909819.1397953.0805081.311982.080063.45135913-.0011669l4.1958072-2.43740278c.13922764-.08043622.22498545-.22902218.22498545-.38981496 0-.16079279-.08575781-.30937874-.22498545-.38981496l-2.47871468-1.43320554c-.23263834-.13456658-.5194503-.13456658-.75208864 0l-1.27113574.74149584c-.22793053.13382702-.36873334.37761702-.37074792.64192355z" />
          <path d="m0 .45125319v4.87268698c.00120283.16056262.08783149.3083309.22735016.38780632.13951867.07947543.31080577.07862629.44952962-.00222848l4.20322216-2.44269917c.13922764-.08043622.22498544-.22902217.22498544-.38981496s-.0857578-.30937874-.22498544-.38981496l-4.20428144-2.42575069c-.13931303-.08068509-.31110775-.08083713-.45056338-.00039877-.13945564.08043836-.22534363.22922243-.22525712.39021373z" />
        </g>
        <path
          d="m4.26571967 6.24127645 2.40774293 1.38553795c.27911885.16165551.62338753.16165551.90250638 0l2.42151357-1.40460498c.13922765-.08043622.22498545-.22902217.22498545-.38981496s-.0857578-.30937874-.22498545-.38981496l-2.40774294-1.38977507c-.28368735-.14364899-.61881902-.14364899-.90250637 0l-2.42257285 1.40884211c-.13911857.08062522-.22467469.22932775-.22445622.39012064.00021847.1607929.08617835.30926239.2255155.38950927z"
          fill="#aa1f24"
        />
      </g>
    </svg>
  );
}

CrimsonHub.propTypes = {
  className: PropTypes.string,
};
