import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import classNames from 'classnames';
import IconButton from 'components/molecules/IconButton';
import css from './styles.scss';

export default class UploadField extends Component {
  constructor() {
    super();

    this.state = {
      files: null,
      fileRejected: false,
    };

    this.onFileDrop = this.onFileDrop.bind(this);
    this.onDropRejected = this.onDropRejected.bind(this);
    this.openDropzone = this.openDropzone.bind(this);
    this.removeFile = this.removeFile.bind(this);

    this.dropzone = null;
  }

  onFileDrop(files) {
    this.setState({
      files,
      fileRejected: false,
    });
    this.props.onFileUploaded(files);
  }

  onDropRejected() {
    this.setState({
      fileRejected: true,
    });
  }

  openDropzone() {
    this.dropzone.open();
  }

  removeFile(file) {
    const { files } = this.state;
    const newFiles = (files && files.filter((f) => f !== file)) || null;
    this.setState({
      files: newFiles,
    });
    this.props.onFileUploaded(newFiles);
  }

  render() {
    const { id, className, label, placeholder, accept, dataTestId } = this.props;
    return (
      <div className={classNames(css.formItemContainer, className)}>
        {label && (
          <label className={css.formLabel} htmlFor={id}>
            {label}
          </label>
        )}
        <div className={css.formControlWrapper}>
          <Dropzone
            id={id}
            data-test-id={dataTestId}
            className={css.formInput}
            activeClassName={css.formInputActive}
            ref={(el) => {
              this.dropzone = el;
            }}
            maxSize={10 * 1024 * 1024}
            accept={accept}
            onDropRejected={this.onDropRejected}
            onDrop={this.onFileDrop}
          >
            {placeholder}
          </Dropzone>
          <IconButton icon="Publish" className={css.uploadButton} onClick={this.openDropzone} />
        </div>
        {this.state.files ? null : (
          <div className={classNames(css.inputMessage, { [css.uploadRejected]: this.state.fileRejected })}>
            Must be PDF or DOC, max size 10MB.
          </div>
        )}
        {this.state.files ? (
          <div className={css.filePreview}>
            {this.state.files.map((file, i) => {
              return (
                <div className={css.fileName} key={i}>
                  <span>&#8226; {file.name}</span>
                  <button className={css.removeFileBtn} onClick={() => this.removeFile(file)}>
                    &times;
                  </button>
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
    );
  }
}

UploadField.displayName = 'UploadField';

UploadField.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onFileUploaded: PropTypes.func.isRequired,
  accept: PropTypes.string,
  dataTestId: PropTypes.string,
};
