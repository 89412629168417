import { Acl, PermissionTypes } from '@crimson-education/common-config';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchGradesByUserId, createGrade, updateGrade, deleteGrades } from 'ducks/grade';
import { fetchAcademicsByUserId, deleteAcademics } from 'ducks/academic';
import { getUsersGradesByCurriculum } from 'selectors/grade';
import { getAcademicsForUser } from 'selectors/academic';
import { getSubjectCategoryById } from 'selectors/subjectCategory';
import { selectUserProfile } from 'selectors/meta';
import { getCurrentUser } from 'selectors/user';
import { showPageLoader, updateSelectedAcademic } from 'ducks/meta';
import { getDecendantsOfSubjectTree } from 'ducks/subjectCategory';
import { curriculumTemplates } from './configuration';
import Grades from './Grades';

const curriculumIds = Object.keys(curriculumTemplates);

const mapStateToProps = (state, ownProps) => {
  const loginUser = selectUserProfile(state).toJS();
  const viewedUser = getCurrentUser(state).toJS();
  const isStudent = Acl.isStudent(loginUser.userRoles);

  // If a student viewing their own profile: student id is the logged in user.
  // If viewing a student's profile: student id is a part of the route.
  const studentId = isStudent ? loginUser.userId : ownProps.match.params.userId;

  // Determine if grades should be shown. Only show for students.
  const showGrades = isStudent || Acl.isStudent(viewedUser.userRoles);

  return {
    studentId,
    showGrades,
    getSubjectCategoryById: (id) => getSubjectCategoryById(id)(state),
    getGradesByCurriculum: (subjectIds) => getUsersGradesByCurriculum(studentId, subjectIds)(state),
    canView:
      loginUser.userId === studentId
        ? Acl.checkPermission(loginUser.permissions, PermissionTypes.GRADE_VIEW_OWN) ||
          Acl.checkPermission(loginUser.permissions, PermissionTypes.GRADE_VIEW_ALL)
        : Acl.checkPermission(loginUser.permissions, PermissionTypes.GRADE_VIEW_ALL),
    canEdit:
      loginUser.userId === studentId
        ? Acl.checkPermission(loginUser.permissions, PermissionTypes.GRADE_EDIT_OWN) ||
          Acl.checkPermission(loginUser.permissions, PermissionTypes.GRADE_EDIT_ALL)
        : Acl.checkPermission(loginUser.permissions, PermissionTypes.GRADE_EDIT_ALL),
    decileRanks: getAcademicsForUser(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchGradesByStudentId: showPageLoader((id) => dispatch(fetchGradesByUserId(id)), dispatch),
  createGrade: (...data) => dispatch(createGrade(...data)),
  updateGrade: (...data) => dispatch(updateGrade(...data)),
  deleteGrades: (ids) => dispatch(deleteGrades(ids)),
  fetchSubjectTree: () => dispatch(getDecendantsOfSubjectTree(curriculumIds)),
  fetchAcademicsByUserId: (userId) => dispatch(fetchAcademicsByUserId(userId)),
  updateSelectedAcademic: (id) => dispatch(updateSelectedAcademic(id)),
  deleteAcademics: (ids) => dispatch(deleteAcademics(ids)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Grades));
