import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Header from 'components/molecules/Header';
import NotificationWrapper from 'components/unique/Notification/NotificationWrapper';

import css from './styles.scss';

export default class NotificationPage extends Component {
  UNSAFE_componentWillMount() {
    const { clearNotifications } = this.props;
    clearNotifications();
  }

  render() {
    const {
      unseen,
      notifications,
      hasMore,
      loadNotifications,
      setNotificationRead,
      showBackButton,
      closeDrawer,
    } = this.props;

    return (
      <div data-ga-category="Notifications">
        <Header title="Notifications" isBackButtonVisible={showBackButton} />
        <NotificationWrapper
          isOpen
          unseen={unseen}
          notifications={notifications}
          hasMore={hasMore}
          className={css.notificationPage}
          setNotificationRead={setNotificationRead}
          isNotificationPage
          onScrollToBottom={loadNotifications}
          closeDrawer={closeDrawer}
        />
      </div>
    );
  }
}

NotificationPage.propTypes = {
  notifications: ImmutablePropTypes.map.isRequired,
  hasMore: PropTypes.bool.isRequired,
  unseen: PropTypes.number.isRequired,
  loadNotifications: PropTypes.func.isRequired,
  clearNotifications: PropTypes.func.isRequired,
  setNotificationRead: PropTypes.func.isRequired,
  showBackButton: PropTypes.bool,
  closeDrawer: PropTypes.func,
};

NotificationPage.defaultProps = {
  showBackButton: true,
};
