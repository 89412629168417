import React from 'react';
import PropTypes from 'prop-types';
import { tutorCostIndicator } from 'components/pages/InviteTutor/InviteTutor';
import LoadingCircle from 'components/molecules/LoadingCircle';
import colours from 'components/atoms/colours.scss';
import StaticTooltip from 'components/molecules/StaticTooltip';
import css from './styles.scss';

const PayRateScale = (props) => {
  const { barCount, minScaleBarPercent, minValueHeightPercent, rates, rate, percentiles } = props;

  // Show Loading Circle if missing data.
  if (!rates || !percentiles) {
    return <LoadingCircle color={colours.crimsonRed} />;
  }

  // Don't show the Graph if all of the values are the same.
  if (percentiles[0] === percentiles[3]) {
    // Show message if all rates are the same.
    if (rates.length > 1) {
      return `All ${rates.length} tutors have the same rate for this subject.`;
    }
    return null;
  }

  // Get Tutor Rate.
  const tutorRate = rate && rate.rateInUSD;
  const hasTutorRate = tutorRate !== null && tutorRate !== undefined;
  const tutorRateFormatted = hasTutorRate ? tutorRate.toString() : null;

  // Get the Min/Max/Diff of the Rates.
  const minRate = Math.min(...rates);
  const maxRate = Math.max(...rates);
  const diffRate = maxRate - minRate;

  // Get Percent of Rate.
  const getRatePercentage = (r) => ((r - minRate) / diffRate) * barCount;

  // Build the Bar Array.
  const bars = Array(barCount).fill(0);

  // Calculate the Number of Entries per bar.
  rates.forEach((r) => {
    const base = getRatePercentage(r);
    const rateGroup = Math.floor(base);
    const index = rateGroup > 0 ? rateGroup - 1 : 0;
    bars[index] += 1;
  });

  // Get the Bar Min/Max/Diff
  const barMin = Math.min(...bars);
  const barMax = Math.max(...bars);
  const barDiff = barMax - barMin;

  // Get the Percentage of the Bar Width for the Percentile. (Overlapped by higher percentiles)
  const percentileBarPercentage = (percentile, isScaleBar = true) => {
    const percentilePercentage = getRatePercentage(percentile);
    let percent = (percentilePercentage / barCount) * 100;
    // Set the Percent of a Bar to the lowest percent, if it is less than that percentage.
    // This is useful for cases where the median is in the lowest bar, as that causes the scale bar to be 0% width.
    if (isScaleBar && percent < minScaleBarPercent) {
      percent = minScaleBarPercent;
    }
    // Don't show off the end of the Graph for Tooltip.
    if (!isScaleBar && percent >= 100) {
      percent = 99;
    }
    return `${percent}%`;
  };

  // The Position Class for Colouring.
  let tooltipColor;
  switch (tutorCostIndicator(tutorRate, percentiles)) {
    case 'high':
      tooltipColor = colours.orange;
      break;

    case 'above average':
      tooltipColor = colours.midOrange;
      break;

    default:
      tooltipColor = colours.tiffany;
      break;
  }

  return (
    <>
      <div
        title={hasTutorRate && `Tutor's Rate: $${tutorRateFormatted} USD/hour`}
        className={css.chart}
        hastooltip={hasTutorRate ? 'true' : undefined}
      >
        <div className={css.chartBars}>
          {bars.map((val, i) => {
            let barHeight = ((val - barMin) / barDiff) * 100;

            // Set the height of the bar to the min height of a bar with value, if the value is greater than 0, and less than the minValueHeightPercent.
            if (minValueHeightPercent && barHeight > 0 && barHeight < minValueHeightPercent) {
              barHeight = minValueHeightPercent;
            }

            return <div key={i} className={css.bar} style={{ height: `${barHeight}%` }} />;
          })}
        </div>
        <div className={css.rateScale}>
          <div style={{ width: percentileBarPercentage(percentiles[3]) }} />
          <div style={{ width: percentileBarPercentage(percentiles[2]) }} />
          <div style={{ width: percentileBarPercentage(percentiles[1]) }} />
        </div>
        {hasTutorRate && (
          <StaticTooltip
            label={<span>${tutorRateFormatted}</span>}
            tooltipColor={tooltipColor}
            tooltipClassName={css.tooltip}
            tooltipStyle={{ left: percentileBarPercentage(tutorRate, false) }}
          />
        )}
        <div className={css.key}>
          <div>${minRate}</div>
          <div>${maxRate}</div>
        </div>
      </div>
      <span className={css.median}>
        The median hourly rate is ${percentiles[1] && percentiles[1].toFixed(2)} USD/hour for this subject.
      </span>
    </>
  );
};

PayRateScale.propTypes = {
  rates: PropTypes.array.isRequired,
  rate: PropTypes.object,
  barCount: PropTypes.number,
  minScaleBarPercent: PropTypes.number,
  minValueHeightPercent: PropTypes.number,
  percentiles: PropTypes.array.isRequired,
};

PayRateScale.defaultProps = {
  barCount: 30,
  minScaleBarPercent: 4,
  minValueHeightPercent: 4,
};

export default PayRateScale;
