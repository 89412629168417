import React from 'react';
import { StyledSelect } from './index.style';
import HighlightOption from 'components/molecules/Select/HightlightOption';

const STYLES = {
  multiValue: (styles: any, _: any) => {
    return {
      ...styles,
      backgroundColor: '#F4F5FF',
      padding: '4px 10px',
      boxSizing: 'border-box',
      border: '1px solid #464AC9',
      borderRadius: 20,
    };
  },
  multiValueLabel: (styles: any, _: any) => ({
    ...styles,
    color: '#464AC9',
    fontSize: 14,
    padding: 0,
  }),
  multiValueRemove: (styles: any, _: any) => ({
    ...styles,
    color: '#464AC9',
    ':hover': {
      backgroundColor: '#464AC9',
      color: 'white',
    },
  }),
};

const Option = (props: any) => {
  return <HighlightOption key={props.data.value} {...props} />;
};

export type SelectProps = {
  className?: string;
  closeMenuOnSelect?: boolean;
  hideSelectedOptions?: boolean;
  options: { label: string; value: string }[];
  placeholder?: string;
  dataTestId?: string;
  isClearable?: boolean;
  getOptionLabel?: (o: any) => string;
  components?: {
    [key: string]: (props: any) => JSX.Element;
  };
  DropdownIndicatorProps?: {
    searchIcon: any;
    searchIconStyle?: React.CSSProperties;
  };
} & (
  | {
      value: { label: string; value: string }[] | null;
      onChange: (value: { label: string; value: string }[]) => void;
      isMulti: true;
    }
  | {
      value: { label: string; value: string } | null;
      onChange: (value: { label: string; value: string }) => void;
      isMulti?: boolean;
    }
);
const Select = ({
  className,
  placeholder,
  dataTestId,
  value,
  isMulti,
  onChange,
  options,
  components,
  ...restProps
}: SelectProps) => {
  return (
    <StyledSelect
      className={className}
      placeholder={placeholder}
      dataTestId={dataTestId}
      value={value}
      isClearable
      components={{
        Option,
        ...components,
      }}
      onChange={onChange}
      getOptionValue={(o: any) => o.value}
      isMulti={isMulti}
      options={options}
      styles={STYLES}
      {...restProps}
    />
  );
};

export default Select;
