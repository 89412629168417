import React from 'react';
import { StyledIcon } from './index.style';
import Tooltip from 'antd/lib/tooltip';
type IconWithToolTipProps = {
  hint: string;
  icon: JSX.Element;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  enableTooltip?: boolean;
};

const IconWithTooltip = ({ hint, icon, onClick, enableTooltip = true }: IconWithToolTipProps) => {
  const Wrapper = enableTooltip ? Tooltip : React.Fragment;
  const props = enableTooltip
    ? {
        overlayInnerStyle: {
          background: 'rgba(29, 30, 43, 0.8)',
          borderRadius: 8,
          padding: '12px 16px',
        },
        title: hint,
        getPopupContainer: (target: any) => target.parentElement!,
      }
    : undefined;
  return (
    <Wrapper {...props}>
      <StyledIcon onClick={onClick}>{icon}</StyledIcon>
    </Wrapper>
  );
};

export default IconWithTooltip;
