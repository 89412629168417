import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { ZoomIn, ZoomOut } from 'components/atoms/icons';
import pdfCss from './documents.scss';

const MIN_SCALE = 0.2;
const MAX_SCALE = 1.8;
const SCALE_STEP = 0.1;

const OperationBar = (props) => {
  const { changePageScale, changePageNumber, pageNumber, scale, numPages } = props;
  const [value, setValue] = useState(pageNumber);
  const zoomInClick = () => {
    const newPageScale = Math.min(MAX_SCALE, scale + SCALE_STEP);
    changePageScale(newPageScale);
  };
  const zoomOutClick = () => {
    const newPageScale = Math.max(MIN_SCALE, scale - SCALE_STEP);
    changePageScale(newPageScale);
  };
  const previousPage = () => {
    const value = pageNumber - 1;
    const newPageNumber = value >= 1 ? value : 1;
    setValue(newPageNumber);
    changePageNumber(newPageNumber);
  };
  const nextPage = () => {
    const value = pageNumber + 1;
    const newPageNumber = value <= numPages ? value : 1;
    setValue(newPageNumber);
    changePageNumber(newPageNumber);
  };
  const onChangePageNumberInput = (e) => {
    const value = e.target.value;
    setValue(value);
  };
  const onKeyUpPageNumberInput = (e) => {
    const value = e.target.value;
    if (e.key === 'Enter') {
      if (value < 1 || value > numPages) {
        setValue(pageNumber);
        return;
      }
      changePageNumber(parseInt(value, 10));
    }
  };
  return (
    <div className={`${pdfCss.zoomGroup}`}>
      <div className={pdfCss.pageButtonGroup}>
        <button onClick={previousPage}>
          <img src="/static/arrow_circle_up.svg" alt="arrow_circle_up" />
        </button>
        <div className={pdfCss.pageGroup}>
          <input
            className={pdfCss.pageNumInput}
            min={1}
            max={numPages}
            type="number"
            value={value}
            onInput={onChangePageNumberInput}
            onKeyUp={onKeyUpPageNumberInput}
          />
          / {numPages}
        </div>
        <button onClick={nextPage}>
          <img src="/static/arrow_circle_down.svg" alt="arrow_circle_down" />
        </button>
      </div>
      <div className={pdfCss.zoomButtonGroup}>
        <button title="Zoom In" onClick={zoomInClick} disabled={scale >= MAX_SCALE}>
          <ZoomIn />
        </button>
        <button onClick={zoomOutClick} title="Zoom Out" disabled={scale <= MIN_SCALE}>
          <ZoomOut />
        </button>
      </div>
    </div>
  );
};

OperationBar.propTypes = {
  changePageScale: PropTypes.func,
  changePageNumber: PropTypes.func,
  pageNumber: PropTypes.number,
  scale: PropTypes.number,
  numPages: PropTypes.number,
};

export default OperationBar;
