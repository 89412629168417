import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ButtonText } from 'components/atoms/typography';
import { Check } from 'components/atoms/icons';

import fontColour from 'components/atoms/typography/fontColour.scss';
import colours from 'components/atoms/colours.scss';
import LoadingCircle from 'components/molecules/LoadingCircle';
import css from './style.scss';

const DYNAMIC_STATES = {
  NORMAL: 0,
  SPINNER: 1,
  TICK: 2,
  FAILED: 3,
};

export default class Button extends Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
    this.buttonRef = React.createRef();
    this.state = {
      dynamicState: DYNAMIC_STATES.NORMAL,
    };
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  onClick(evt) {
    const { onClick, disabled, loading } = this.props;
    if (!disabled && !loading && onClick) {
      const returnValue = onClick(evt);
      if (returnValue && returnValue.then) {
        this.doDynamicButton(returnValue);
      }
    }
    if (this.buttonRef && this.buttonRef.current) {
      this.buttonRef.current.blur();
    }
  }

  doDynamicButton(promise) {
    const { simple } = this.props;
    this.setState({ dynamicState: DYNAMIC_STATES.SPINNER });
    promise
      .then(() => this.mounted && this.setState({ dynamicState: simple ? DYNAMIC_STATES.NORMAL : DYNAMIC_STATES.TICK }))
      .catch(() => this.mounted && this.setState({ dynamicState: DYNAMIC_STATES.FAILED }));
  }

  render() {
    const {
      children,
      disabled,
      secondary,
      small,
      className,
      loading,
      dataTestId,
      dataGaLabel,
      buttonTextStyle,
    } = this.props;
    const { dynamicState } = this.state;
    // decide if we want to show the dynamic button effect:
    const dynamic = dynamicState !== DYNAMIC_STATES.NORMAL && dynamicState !== DYNAMIC_STATES.FAILED;

    return (
      <button
        data-ga-label={dataGaLabel || undefined}
        ref={this.buttonRef}
        onClick={this.onClick}
        data-test-id={dataTestId}
        className={classNames(
          css.crimsonButton,
          {
            [css.smallButton]: small,
            [css.callToActionButton]: !secondary,
            [css.disabledButton]: disabled && !secondary,
            [css.secondaryButton]: secondary && !disabled,
            [css.disabledSecondaryButton]: secondary && disabled,
            [css.dynamicActive]: dynamic,
          },
          className,
        )}
      >
        <ButtonText
          bold
          className={classNames(secondary ? fontColour.callToAction : fontColour.contrast, {
            [fontColour.hint]: secondary && disabled,
          })}
        >
          {loading && !dynamic && (
            <div key="loadingCircle" className={css.buttonLoadingState}>
              <LoadingCircle />
            </div>
          )}
          {dynamicState === DYNAMIC_STATES.SPINNER && (
            <LoadingCircle className={css.buttonLoadingState} color={colours.attention} />
          )}
          {dynamicState === DYNAMIC_STATES.TICK && (
            <Check className={css.buttonLoadingState} style={{ color: colours.success }} />
          )}
          <div className={classNames(css.text, buttonTextStyle, { [css.hidden]: loading || dynamic })}>{children}</div>
        </ButtonText>
      </button>
    );
  }
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  secondary: PropTypes.bool,
  small: PropTypes.bool,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  dataGaLabel: PropTypes.string,
  loading: PropTypes.bool,
  simple: PropTypes.bool,
  buttonTextStyle: PropTypes.string,
};
