import React from 'react';
import { useQuery } from '@apollo/client';
import { queryMetabaseUrl, client } from 'graphql/api/metabase';
import { useSelector } from 'react-redux';
import { getCurrentUser } from 'selectors/user';
import { useLocation } from 'react-router-dom';

export default function MetabaseDashboard() {
  const location = useLocation();
  const user = useSelector(getCurrentUser);
  const { data } = useQuery(queryMetabaseUrl, {
    client,
    variables: {
      stratName: user.get('fullName'),
      dashboardName: location?.state?.type || '',
    },
  });
  return (
    <>
      {location?.state?.type && data ? (
        <iframe
          title="strategist-dashboard"
          src={data.getMetabaseDashboardUrl.metabaseUrl}
          frameBorder="0"
          width="100%"
          height="100%"
        ></iframe>
      ) : (
        <div></div>
      )}
    </>
  );
}
