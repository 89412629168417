import React from 'react';
import DefineEmailContentUS, { DefineEmailContentUSProps } from '../DefineEmailContentUS';
import { SectionTitleContainer, ClickableUnderlineText } from '../DefineEmailContentUS/index.style';
import { Container, OptionContainer } from './index.style';
import Select from '../../components/Select';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Row } from '../../index.style';
import { EMAIL_TEMPLATE_OPTIONS, EMAIL_TEMPLATE_LABEL } from '../../constants';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { components } from 'react-select';

const Option = (props: any) => {
  const { label, detail, value } = props.data;
  return (
    <components.Option key={value} {...props}>
      <OptionContainer>
        <div>{label}</div>
        <div
          dangerouslySetInnerHTML={{
            __html: detail,
          }}
        />
      </OptionContainer>
    </components.Option>
  );
};

type SelectEmailTemplateUKProps = {
  selectedTemplate: { label: string; value: string } | null;
  onSelectTemplate: (value: { label: string; value: string }) => void;
  onClickCheckTemplate: () => void;
} & DefineEmailContentUSProps;
const SelectEmailTemplateUK = ({
  selectedTemplate,
  onSelectTemplate,
  onClickCheckTemplate,
  ...restProps
}: SelectEmailTemplateUKProps) => {
  return (
    <DefineEmailContentUS
      {...restProps}
      isLoading={!selectedTemplate}
      clickableText={'View Template Additional Clauses'}
      title={'Confirm Email Content'}
    >
      <Container>
        <Row rowcenter justifycontent={'space-between'}>
          <SectionTitleContainer>{'Select Template'}</SectionTitleContainer>
          <ClickableUnderlineText onClick={() => onClickCheckTemplate()}>
            {'View Email Template'}
          </ClickableUnderlineText>
        </Row>
        <Select
          placeholder={'Select a template'}
          options={EMAIL_TEMPLATE_OPTIONS}
          value={selectedTemplate}
          onChange={onSelectTemplate}
          components={{ Option }}
          DropdownIndicatorProps={{
            searchIcon: ExpandMoreIcon,
            searchIconStyle: {
              color: '#A9ACC0',
            },
          }}
          getOptionLabel={(o: any) => EMAIL_TEMPLATE_LABEL[o.value as keyof typeof EMAIL_TEMPLATE_LABEL]}
        />
      </Container>
    </DefineEmailContentUS>
  );
};

export default SelectEmailTemplateUK;
