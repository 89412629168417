import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Column } from 'components/molecules/Table';
import RequestBookingModal from 'components/organisms/BookingModals/RequestBookingModal';
import classNames from 'classnames';
import { columnNames } from '../columnMap';

import css from '../styles.scss';

export default function TeamActionButton(params) {
  const [isOpen, setIsOpen] = useState(false);
  const [userName, setUserName] = useState('');
  const [userId, setUserId] = useState('');

  const renderMessageButton = (params, rowData) => {
    return (
      <div className={css.friendAction}>
        <Link to={`/messages/${rowData.get('threadId')}`}>
          <button className={classNames(css.commonButton, css.messageButton)}> Message</button>
        </Link>
        <button
          className={classNames(css.commonButton, css.bookButton)}
          onClick={() => {
            setIsOpen(true);
            setUserName(`${rowData.get('firstName')} ${rowData.get('lastName')}`);
            setUserId(rowData.get('userId'));
          }}
        >
          Book a session
        </button>
        {isOpen && (
          <RequestBookingModal
            userName={userName}
            isOpen={isOpen}
            onCancel={() => setIsOpen(false)}
            selectedUserId={[userId]}
          />
        )}
      </div>
    );
  };
  return (
    <Column
      key={columnNames.FriendActionButton}
      flex="3 1 0"
      name=""
      renderer={({ rowData }) => {
        return renderMessageButton(params, rowData);
      }}
    />
  );
}
