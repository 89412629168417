import styled from 'styled-components';
export const Content = styled.div`
  img {
    height: 209px;
    width: auto;
    object-fit: contain;
    align-self: center;
  }
  .input-container {
    margin-top: 37px;

    display: flex;
    flex-direction: column;
    position: relative;

    textarea {
      min-width: 620px;
      max-width: 620px;
      min-height: 144px;
      max-height: 144px;
      background: #ffffff;
      border: 1px solid #e3e7ed;
      border-radius: 4px;
      padding: 17px;
      ::placeholder {
        font-size: 14px;
        line-height: 20px;

        color: #a9abc0;
      }
    }
    .word-count {
      display: flex;
      position: absolute;
      font-size: 12px;
      line-height: 15px;
      color: #a9acc0;
      right: 15px;
      bottom: 70px;
    }
  }
  .title {
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    color: #000000;
    align-self: center;
    margin-top: 50px;
    text-align: center;
  }

  .content {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #1d1e2b;
    align-self: center;
    white-space: pre-wrap;
    margin-top: 35px;
  }
`;

export const StyledButton = styled.button<{ $disable?: boolean }>`
  cursor: pointer;

  margin-left: auto;
  width: 165px;
  margin-top: 23px;
  background: ${({ $disable }) => ($disable ? 'grey' : '#464ac9')};
  border-radius: 28px;
  padding: 7px 48px;
  border: none;

  transition: background 0.3s;

  font-weight: 700;
  font-size: 14px;
  line-height: 26px;
  text-transform: capitalize;

  color: #ffffff;
`;
