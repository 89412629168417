import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import {
  updateGlobalSelectedDay,
  toggleCalendarView,
  nextWeek,
  prevWeek,
  goToToday,
  DESKTOP_DAYS,
  MOBILE_DAYS,
} from 'ducks/meta';
import { getMetaItem, getCalendarDays } from 'selectors/meta';
import componentKeys from 'constants/componentKeys';
import { withAppContext } from 'components/enhancers/AppContext';

import CalendarHeader from './CalendarHeader';

function Container(props) {
  const { calendarDays, toggleCalendarView, app } = props;
  if (!calendarDays) {
    const days = app.isMobile ? DESKTOP_DAYS : MOBILE_DAYS; // Inverted because it is a toggle, not set
    toggleCalendarView(days);
    return null;
  }
  return <CalendarHeader {...props} />;
}

Container.propTypes = {
  app: PropTypes.object,
  calendarDays: PropTypes.number,
  toggleCalendarView: PropTypes.func.isRequired,
  hasSynced: PropTypes.bool,
};

export default compose(
  withAppContext,
  connect(
    (state) => ({
      selectedDay: getMetaItem(componentKeys.SELECTED_DAY)(state),
      calendarDays: getCalendarDays(state),
    }),
    (dispatch) => ({
      updateGlobalSelectedDay: (day) => dispatch(updateGlobalSelectedDay(day)),
      toggleCalendarView: (currentView) => dispatch(toggleCalendarView(currentView)),
      nextWeek: () => dispatch(nextWeek()),
      prevWeek: () => dispatch(prevWeek()),
      goToToday: () => dispatch(goToToday()),
    }),
  ),
)(Container);
