import React, { useContext } from 'react';
import { Row } from '../../index.style';
import { ToolBarTitle } from '../index.style';
import SelectRegion from '../../ToolBar/SelectRegion';
import GroupByButton from '../../ToolBar/GroupByButton';
import { AppTrackerContext } from '../../viewController';

const ToolBar = () => {
  const { state, controller } = useContext(AppTrackerContext);
  return (
    <Row justifycontent={'space-between'} rowcenter>
      <Row rowcenter gap={'30px'}>
        <ToolBarTitle>{'Application List'}</ToolBarTitle>
        <SelectRegion region={state.trackerRegion} selectRegion={controller.selectTrackerRegion} />
      </Row>
      <GroupByButton
        menuData={[
          {
            label: 'Application status',
            value: 'Status',
          },
          {
            label: 'Round',
            value: 'Round',
          },
          {
            label: 'Goal',
            value: 'Goal',
          },
          {
            label: 'Deadline',
            value: 'Deadline',
          },
        ]}
        groupBy={state.trackerGroupBy}
        onClickGroupByMenuItem={controller.onClickTrackerGroupByMenuItem as (v: string) => void}
      />
    </Row>
  );
};

export default ToolBar;
