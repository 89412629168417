import React from 'react';
import PropTypes from 'prop-types';

export default function AskCrimson({ className }) {
  return (
    <svg
      className={className}
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path
          id="a-971"
          d="m18.4 4h-12.8c-.8843752 0-1.6.7156248-1.6 1.6v14.4l3.2-3.2h11.2c.8843752 0 1.6-.7156248 1.6-1.6v-9.6c0-.8843752-.7156248-1.6-1.6-1.6zm-3.9187496 9.9187504-2.4812504-1.4375-2.4812496 1.5187496.6406248-2.8-2.1593752-1.9187496 2.8812504-.2406256 1.1187496-2.6406248 1.1187504 2.6406248 2.8812496.2406256-2.1593752 1.9187496z"
        />
        <mask id="b-971" fill="#fff">
          <use fill="none" href="#a-971" />
        </mask>
      </defs>
      <g fill="none" fillRule="evenodd">
        <use fill="#aa1e23" fillRule="nonzero" href="#a-971" />
      </g>
    </svg>
  );
}

AskCrimson.propTypes = {
  className: PropTypes.string,
};
