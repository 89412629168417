import React from 'react';
import PropTypes from 'prop-types';

export default function Notes({ className }) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#4F6987"
          d="M46.875 44.208L31.25 28.583v-1.24h1.239L48.114 42.97l-1.24 1.239zm-12.5 1.886a2.347 2.347 0 0 1-2.344 2.343H3.906a2.347 2.347 0 0 1-2.344-2.343V6.25h2.344v2.344a2.347 2.347 0 0 0 2.344 2.344 2.347 2.347 0 0 0 2.344-2.344V6.25h3.125v2.344a2.347 2.347 0 0 0 2.343 2.344 2.347 2.347 0 0 0 2.344-2.344V6.25h3.125v2.344a2.347 2.347 0 0 0 2.344 2.344 2.347 2.347 0 0 0 2.344-2.344V6.25h3.125v2.344a2.347 2.347 0 0 0 2.343 2.344 2.347 2.347 0 0 0 2.344-2.344V6.25h2.344v20.77l-1.01-1.01a.78.78 0 0 0-.553-.229H30.47v1.563h-.782v1.562c0 .208.082.407.23.553l4.458 4.458v12.177zM5.469 2.344a.782.782 0 0 1 1.562 0v6.25a.782.782 0 0 1-1.562 0v-6.25zm7.812 0a.782.782 0 0 1 1.563 0v6.25a.782.782 0 0 1-1.563 0v-6.25zm7.813 0a.782.782 0 0 1 1.562 0v6.25a.782.782 0 0 1-1.562 0v-6.25zm7.812 0a.782.782 0 0 1 1.563 0v6.25a.782.782 0 0 1-1.563 0v-6.25zm7.031 26.239V5.469a.781.781 0 0 0-.78-.782H32.03V2.345A2.347 2.347 0 0 0 29.687 0a2.347 2.347 0 0 0-2.343 2.344v2.344h-3.125V2.344A2.347 2.347 0 0 0 21.875 0a2.347 2.347 0 0 0-2.344 2.344v2.344h-3.125V2.344A2.347 2.347 0 0 0 14.062 0a2.347 2.347 0 0 0-2.343 2.344v2.344H8.594V2.344A2.347 2.347 0 0 0 6.25 0a2.347 2.347 0 0 0-2.344 2.344v2.344H.781a.781.781 0 0 0-.781.78v40.626A3.91 3.91 0 0 0 3.906 50h28.125a3.91 3.91 0 0 0 3.906-3.906V35.48l10.385 10.385a.78.78 0 0 0 1.105 0l2.344-2.344a.78.78 0 0 0 0-1.105L35.937 28.583z"
        />
        <path
          fill="#CD4B53"
          d="M3.41 17.045h28.408V15.91H3.41zM3.41 20.455h9.09v-1.137H3.41zM13.636 20.455h1.137v-1.137h-1.137zM17.045 20.455h1.137v-1.137h-1.137zM20.455 20.455h1.136v-1.137h-1.136zM23.864 20.455h9.09v-1.137h-9.09zM3.41 23.864h18.18v-1.137H3.41zM23.864 23.864H25v-1.137h-1.136zM26.136 23.864h5.682v-1.137h-5.682zM10.227 14.773h7.955v-1.137h-7.955zM19.318 14.773h12.5v-1.137h-12.5zM3.41 27.273h26.135v-1.137H3.41z"
        />
        <path
          fill="#4F6987"
          d="M42.188 23.41h6.25v-4.774h-6.25v4.773zm-3.126-2.387c0-1.038.654-1.922 1.563-2.25v4.5a2.389 2.389 0 0 1-1.563-2.25zm10.157-3.978h-7.813c-2.154 0-3.906 1.785-3.906 3.978 0 2.193 1.752 3.977 3.906 3.977h7.813a.788.788 0 0 0 .781-.795V17.84c0-.44-.35-.796-.781-.796z"
        />
        <path
          fill="#CD4B53"
          d="M23.864 45.455H25v-1.137h-1.136zM27.273 45.455h1.136v-1.137h-1.136zM30.682 45.455h1.136v-1.137h-1.136z"
        />
      </g>
    </svg>
  );
}

Notes.propTypes = {
  className: PropTypes.string,
};
