import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import MuiDialog from '@material-ui/core/Dialog';
import DialogTitle from './DialogTitle';

const Dialog = forwardRef(({ body, open = false, title, children, fullWidth = true, maxWidth = 'md', noHeader = false, ...rest }, ref) => {
  const [display, setDisplay] = useState(open);
  const dialId = useMemo(() => `dialog-${Math.random()}`, []);

  useEffect(() => () => setDisplay(open), [open]);

  const show = () => {
    setDisplay(true);
  };

  const hide = () => {
    setDisplay(false);
  };

  useImperativeHandle(
    ref, () => ({ show, hide })
  );

  const actualBody = useMemo(() => {
    if (!body && !children) { return null; }

    const content = body || children;

    const isFunc = typeof content === 'function';

    return isFunc ? content(hide) : content;
  }, [body, children]);

  return (
    <MuiDialog
      onClose={hide}
      aria-labelledby={dialId}
      open={display}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      {...rest}
    >
      { !noHeader && <DialogTitle
        id={dialId}
        title={title}
        onClose={hide}
      />}
      { actualBody }
    </MuiDialog>);
});

Dialog.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.symbol, PropTypes.func]),
  open: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.symbol, PropTypes.func]),
  fullWidth: PropTypes.bool,
  maxWidth: PropTypes.string
};

export default Dialog;