import React from 'react';
import Modal from 'components/molecules/Modal';

export default class Filter {
  constructor(component) {
    this.component = component;
    this.state = null;
    this.modalOpen = false;
    this.render = this.render.bind(this);
    this.title = 'Filter';
  }

  setState(state) {
    this.state = { ...this.state, ...state };
    this.component.forceUpdate();
  }

  modal() {
    return (
      <Modal
        isOpen={this.modalOpen}
        title={this.title}
        submitText="Apply Filter"
        onSubmit={() => {
          this.closeModal();
        }}
        isSubmitDisabled={!this.active()}
        onClose={() => {
          this.reset();
          this.closeModal();
        }}
        loading={false}
        onSubmitDataTestId="applyFilter"
      >
        {this.edit()}
      </Modal>
    );
  }

  openModal() {
    this.modalOpen = true;
    this.component.forceUpdate();
  }

  closeModal() {
    this.modalOpen = false;
    this.component.forceUpdate();
  }

  render() {
    return (
      <React.Fragment>
        {this.tag()}
        {this.modal()}
      </React.Fragment>
    );
  }

  apply() {
    console.warn('New filters need to override the apply method'); /* eslint-disable-line no-console */
  }
  reset() {
    console.warn('New filters need to override the reset method'); /* eslint-disable-line no-console */
  }
  active() {
    console.warn('New filters need to override the active method'); /* eslint-disable-line no-console */
  }
  data() {
    console.warn('New filters need to override the data method'); /* eslint-disable-line no-console */
  }
  edit() {
    console.warn('New filters need to override the edit method'); /* eslint-disable-line no-console */
  }
  tag() {
    console.warn('New filters need to override the tag method'); /* eslint-disable-line no-console */
  }
}
