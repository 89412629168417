import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import MagnifyingGlass from 'components/generic/Icons/MagnifyingGlass';
import Cross from 'components/generic/Icons/Cross';
import * as Logger from '@crimson-education/browser-logger';

import css from './styles.scss';
import { asButton } from '../../../utils/accessibility';

const KEY_ESC = 27;

/**
 * @property {React.ReactHTMLElement<HTMLInputElement>}
 */
export class SearchInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFocused: false,
      value: '',
    };

    this.focusHandler = this.focusHandler.bind(this);
    this.blurHandler = this.blurHandler.bind(this);
    this.focusBack = this.focusBack.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.itemClickHandler = this.itemClickHandler.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
  }

  componentDidMount() {
    this.textInput.addEventListener('keyup', (event) => {
      if (event.keyCode === KEY_ESC) {
        this.clearSearch();
        this.textInput.blur();
      }
    });
  }

  focusHandler() {
    Logger.trackEventSinceLastAction({ message: 'onfocus search input' });
    this.setState({ isFocused: true });
  }

  blurHandler() {
    this.setState({ isFocused: false });
  }

  changeHandler(value) {
    this.setState({ value });
    this.props.onChangeHandler(value);
  }

  itemClickHandler() {
    this.setState({ isFocused: false });
  }

  /** focus back on the text input for any click within the component. */
  focusBack() {
    this.textInput.focus();
  }

  clearSearch() {
    this.changeHandler('');
    this.setState({ isFocused: false });
  }

  render() {
    const { value } = this.state;

    return (
      <div className={classnames(css.searchInput, { [css.focus]: this.state.isFocused })}>
        <input
          type="text"
          className={classnames('search-input', css.inputText)}
          ref={(input) => {
            this.textInput = input;
          }}
          placeholder={this.props.placeholder}
          value={value}
          data-test-id={this.props.dataTestId}
          onFocus={this.focusHandler}
          onBlur={this.blurHandler}
          onChange={(ev) => this.changeHandler(ev.target.value)}
        />
        <div className={classnames(css.iconContainer, { [css.hidden]: value })} {...asButton(this.focusBack)}>
          <MagnifyingGlass className={css.magnifyingGlass} />
        </div>
        <div className={classnames(css.iconContainer, { [css.hidden]: !value })} {...asButton(this.clearSearch)}>
          <Cross className={css.crossIcon} />
        </div>
      </div>
    );
  }
}

export default SearchInput;

SearchInput.propTypes = {
  onChangeHandler: PropTypes.func,
  placeholder: PropTypes.string,
  dataTestId: PropTypes.string,
};
