import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import RequestBookingModal from './RequestBookingModal';
import bookingApi from 'graphql/api/booking';

// wrapper mainly used to handle recurring session states
const RequestBookingModalWrapper = (props) => {
  const {} = props;
  const [frequency, setFrequency] = useState({ value: 'weekly', label: `Weekly on ${moment().format('dddd')}` });
  const [occurrences, setOccurrences] = useState();
  const [freqItems, setFreqItems] = useState([]);
  const [selectedType, setSelectedType] = useState('individual');
  const [recurringSessionProps, setRecurringSessionProps] = useState({});
  const [firstSessionDay, setFirstSessionDay] = useState(moment());
  const [errorMessage, setErrorMessage] = useState('');
  const [subjectId, setSubjectId] = useState(null);
  const [remainingHours, setRemainingHours] = useState(null);

  const validateRecurringSettings = (duration) => {
    if (!Number.isInteger(occurrences)) {
      setErrorMessage('Please input an integer');
      return false;
    }

    if (occurrences < 2) {
      setErrorMessage('Occurences should be at least 2');
      return false;
    }

    if (remainingHours && remainingHours !== 'Unlimited' && occurrences * duration > remainingHours) {
      setErrorMessage('Not enough hours to make this booking, please try with less occurrences');
      return false;
    }

    if (occurrences > 40) {
      setErrorMessage('Occurences should be less than or equal to 40');
      return false;
    }

    return true;
  };

  const calculateStartEndTimeList = useCallback(
    (start, end) => {
      const mapRecurringOptionToDateStep = {
        weekly: { step: 1, duration: 'weeks' },
        fortnightly: { step: 2, duration: 'weeks' },
        weekday: { step: 1, duration: 'days' },
        daily: { step: 1, duration: 'days' },
      };
      const bookingTimeList = Array.from({ length: occurrences }, () => {
        return { start: null, end: null };
      });
      let curStart = start;
      let curEnd = end;
      if (frequency.value === 'monthly') {
        // monthly recurring session
        const ms = end.diff(start);
        const duration = moment.duration(ms);
        const day = start.isoWeekday();
        const hour = start.hour();
        const minute = start.minute();
        const nth = getNthWeekOfMonth(start, 'number');
        // for each occurrence, find the corrresponding datetime on the next month
        for (let i = 0; i < occurrences; i++) {
          bookingTimeList[i].start = curStart.format();
          bookingTimeList[i].end = curEnd.format();
          curStart = curStart.add(1, 'months');
          const startOfMonth = curStart.clone().startOf('month');
          const endOfMonth = curStart.clone().endOf('month');
          if (nth === 5) {
            // find the last xday of month
            for (let m = endOfMonth.clone(); m.diff(startOfMonth, 'days') >= 0; m.subtract(1, 'days')) {
              if (m.isoWeekday() === day) {
                curStart = m.clone().hour(hour).minute(minute);
                curEnd = curStart.clone().add(duration);
                break;
              }
            }
          } else {
            // find the nth (1-4) xday of month
            for (let m = startOfMonth.clone(), i = 0; m.diff(endOfMonth, 'days') <= 0; m.add(1, 'days')) {
              if (m.isoWeekday() === day) {
                i++;
              }
              if (i === nth) {
                curStart = m.clone().hour(hour).minute(minute);
                curEnd = curStart.clone().add(duration);
                break;
              }
            }
          }
        }
      } else {
        // weekly, daily, weekday recurring session
        const { step, duration } = mapRecurringOptionToDateStep[frequency.value];
        for (let i = 0; i < occurrences; i++) {
          bookingTimeList[i].start = curStart.format();
          bookingTimeList[i].end = curEnd.format();
          curStart = curStart.add(step, duration);
          curEnd = curEnd.add(step, duration);
        }
      }
      return bookingTimeList;
    },
    [occurrences, frequency],
  );

  const getNthWeekOfMonth = (day, type = 'string') => {
    if (!day) return false;
    const startOfMonth = day.clone().startOf('month');
    const endOfMonth = day.clone().endOf('month');
    let nth = 0;
    for (let m = startOfMonth.clone(); m.diff(endOfMonth, 'days') <= 0; m.add(1, 'days')) {
      if (m.isoWeekday() === day.isoWeekday()) {
        nth++;
      }
      if (m.format('Do') === day.format('Do')) {
        break;
      }
    }
    const map = {
      1: 'first',
      2: 'second',
      3: 'third',
      4: 'fourth',
      5: 'last',
    };
    nth = nth > 4 ? 5 : nth;
    if (type === 'string') {
      return map[nth];
    } else {
      return nth;
    }
  };

  useEffect(() => {
    setRecurringSessionProps({
      selectedType,
      setSelectedType,
      setFrequency,
      setOccurrences,
    });
  }, []);

  useEffect(() => {
    const day = firstSessionDay.format('dddd');
    const weekdays = [{ value: 'weekday', label: `Every weekday (Monday to Friday)` }];
    const nthWeekOfMonth = getNthWeekOfMonth(firstSessionDay);
    setFreqItems([
      { value: 'weekly', label: `Weekly on ${day}` },
      { value: 'fortnightly', label: `Fortnightly on ${day}` },
      ...(nthWeekOfMonth ? [{ value: 'monthly', label: `Monthly on the ${nthWeekOfMonth} ${day}` }] : []),
      ...(firstSessionDay.isoWeekday() > 5 ? [] : weekdays),
      { value: 'daily', label: `Everyday` },
    ]);
    setFrequency({ value: 'weekly', label: `Weekly on ${day}` });
  }, [firstSessionDay]);

  useEffect(() => {
    if (selectedType === 'individual') {
      setErrorMessage('');
    }
  }, [selectedType]);

  useEffect(() => {
    setRecurringSessionProps({
      selectedType,
      setSelectedType,
      setFrequency,
      setOccurrences,
      freqItems,
      errorMessage,
      remainingHours,
      frequency,
      occurrences,
    });
  }, [selectedType, freqItems, errorMessage, remainingHours, frequency, occurrences]);

  useEffect(() => {
    if (!subjectId) {
      setRemainingHours(null);
      return;
    }
    bookingApi.fetchRemainingHoursBySubjectId(subjectId).then((res) => {
      setRemainingHours(res.remainingHours === null ? 'Unlimited' : res.remainingHours);
    });
  }, [subjectId]);

  return (
    <RequestBookingModal
      {...props}
      setSubjectId={setSubjectId}
      setFirstSessionDay={setFirstSessionDay}
      calculateStartEndTimeList={calculateStartEndTimeList}
      validateRecurringSettings={validateRecurringSettings}
      recurringSessionProps={recurringSessionProps}
    />
  );
};

export default RequestBookingModalWrapper;
