import { connect } from 'react-redux';
import { getGroupedStudentsByTutorId } from 'selectors/contract';
import { withRouter } from 'react-router-dom';
import { getClientsFetched } from 'selectors/meta';
import { showPageLoader, updateMeta } from 'ducks/meta';
import componentKeys from 'constants/componentKeys';
import { fetchLanguages } from 'ducks/language';
import { fetchAllClients, acceptContract, declineContract } from 'ducks/contract';

import Students from './Students';

export default withRouter(
  connect(
    (state, props) => ({
      clientsFetched: getClientsFetched(state),
      allStudents: getGroupedStudentsByTutorId(props.userId)(state),
    }),
    (dispatch) => ({
      setClientsFetched: (fetched) => dispatch(updateMeta(componentKeys.CLIENTS_FETCHED, fetched)),
      fetchClients: showPageLoader(() => dispatch(fetchAllClients()), dispatch),
      fetchLanguages: () => dispatch(fetchLanguages()),
      acceptContract: (contractId) => dispatch(acceptContract(contractId)),
      declineContract: (contractId, reason) => dispatch(declineContract(contractId, reason)),
    }),
  )(Students),
);
