import { useState, useEffect, useMemo } from 'react';
import { valueCheckPairsFrom } from './ValueCheckedPair';

export class SelectionEventArgs {
  constructor(itemList = null, optionList = null, { value, selected } = {}, index = -1) {
    this.item = value;
    this.index = index;
    this.selected = selected;
    this.itemList = itemList;
    this.optionList = optionList;
  }
}

const triggerChange = (onChange, entries, index = null) => {
  const onlySelectedItems = entries
    .filter(({ selected }) => selected)
    .map(i => i.value);

  const entry = index >= 0 ? entries[index] : null;

  onChange && onChange(
    new SelectionEventArgs(onlySelectedItems, entries, entry, index));
};

export default function useForSelectLists({ items, onChange }, multi = false) {
  const [entries, setEntries] = useState();

  useEffect(() => {
    const newEntries = valueCheckPairsFrom(items);

    // if (multi && newEntries.filter(({ selected }) => selected).length > 1) {
    //   throw new Error('The single select can only have one item selected');
    // }

    setEntries(newEntries);

    const index = newEntries.findIndex(i => i.selected);

    if (index < 0) { return; }

    if (multi) {
      triggerChange(onChange, newEntries);
    } else {
      triggerChange(onChange, newEntries, index);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, multi]);

  const funcs = useMemo(() => ({
    select: (item, i = null) => {
      const index = i || entries
        .findIndex(e => e.value === item);

      const newEntries = entries.map((entry, j) => {
        entry.selected = index === j;
        return entry;
      });

      setEntries(newEntries);

      triggerChange(onChange, entries, index);
    },
    toggle: (item, i = false, emmit = true) => {      
      const index = i || entries
        .findIndex(e => e.value === item);

      const newEntries = [...entries];
      newEntries[index].selected = !newEntries[index].selected;

      setEntries(newEntries);

      if (emmit) {
        triggerChange(onChange, newEntries, index);
      }
    },
    toggleAll: (check) => {      
      const toggled = check
        ? entries.map(i => ({ selected: true, ...i }))
        : entries.map(i => ({ selected: false, ...i }));

      setEntries(toggled);

      triggerChange(onChange, toggled);
    }
  }), [entries, onChange]);


  return {
    entries,
    setEntries,
    ...funcs
  };
}
