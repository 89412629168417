import React from 'react';
import PropTypes from 'prop-types';
import Avatar from 'components/generic/Avatar';
import { SubHeader, Body } from 'components/atoms/typography';
import { Acl } from '@crimson-education/common-config';
import { Link } from 'react-router-dom';
import { useFeatureFlag } from 'featureSwitches';
import styles from './styles';

export default function ThreadStartsHere({ source, chatName, users, icon, type, threadId }) {
  if (source && source === 'COMMUNITY' && type && type === 'group') {
    return (
      <div className={styles.wrapper}>
        <Community chatName={chatName} icon={icon} threadId={threadId} />
        <Body className={styles.helloText}>Say hello!</Body>
      </div>
    );
  }
  return (
    <div className={styles.wrapper}>
      {users.length > 1 ? (
        <Group users={users} key={users.map((u) => u.userId).join(',')} />
      ) : (
        users.length && <Direct user={users[0]} />
      )}
      <Body className={styles.helloText}>Say hello!</Body>
    </div>
  );
}

function Community({ chatName, icon, threadId }) {
  return (
    <div>
      <div className={styles.avatar}>
        <Link className={styles.profilePic} to={`/community/${threadId}?message=true`}>
          <Avatar className={styles.avatar} avatar={icon} large />
        </Link>
      </div>
      <SubHeader className={styles.text}>This is the beginning of your {chatName} group chat.</SubHeader>
    </div>
  );
}

function Group({ users }) {
  const STUDENT_CENTER_BETA = useFeatureFlag('STUDENT_CENTER_BETA');
  return (
    <div>
      <div className={styles.avatars}>
        {users.slice(0, 3).map((user, i) => {
          if (i === 2) {
            return (
              <div className={styles.avatar} key={`avatar-${i}`}>
                <Avatar
                  className={styles.avatarUnderlay}
                  avatar={user.profileImageUrl}
                  firstName={user.firstName}
                  lastName={user.lastName}
                  userId={user.userId}
                  large
                />

                {i === 2 && <div className={styles.avatarOverlay}>+{users.length - 2}</div>}
              </div>
            );
          }
          const profileLink =
            STUDENT_CENTER_BETA && user.isBetaUser && Acl.isStudent(user.role)
              ? `/users/${user.userId}/student-center/personal-info`
              : `/users/${user.userId}/profile`;
          return (
            <Link className={styles.profilePic} to={profileLink} key={`link-avatar-${i}`}>
              <div className={styles.avatar} key={`avatar-${i}`}>
                <Avatar
                  className={styles.avatarUnderlay}
                  avatar={user.profileImageUrl}
                  firstName={user.firstName}
                  lastName={user.lastName}
                  userId={user.userId}
                  large
                />
              </div>
            </Link>
          );
        })}
      </div>
      <SubHeader className={styles.text}>This is the beginning of your groups history.</SubHeader>
    </div>
  );
}

function Direct({ user }) {
  const STUDENT_CENTER_BETA = useFeatureFlag('STUDENT_CENTER_BETA');
  const profileLink =
    STUDENT_CENTER_BETA && user.isBetaUser && Acl.isStudent(user.role)
      ? `/users/${user.userId}/student-center/personal-info`
      : `/users/${user.userId}/profile`;
  return (
    <div>
      <Link className={styles.profilePic} to={profileLink}>
        <Avatar
          className={styles.avatar}
          avatar={user.profileImageUrl}
          firstName={user.firstName}
          lastName={user.lastName}
          userId={user.userId}
          large
          showStudentType
        />
      </Link>
      <SubHeader className={styles.text}>This is the beginning of your chat history with {user.firstName}.</SubHeader>
    </div>
  );
}

ThreadStartsHere.propTypes = {
  source: PropTypes.string,
  chatName: PropTypes.string,
  users: PropTypes.array,
  icon: PropTypes.string,
  type: PropTypes.string,
  threadId: PropTypes.string,
};

Community.propTypes = {
  chatName: PropTypes.string,
  icon: PropTypes.string,
  threadId: PropTypes.string,
};
Group.propTypes = {
  users: PropTypes.array,
};

Direct.propTypes = {
  user: PropTypes.object,
};

ThreadStartsHere.defaultProps = {
  users: [],
};
