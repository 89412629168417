import React, { useMemo } from 'react';
import ListModal from '../components/Modals/ListModal';
import { useStaffDashboardContext } from '../helper';
import { ContactCard } from '../components';
import { useRequesterUserId } from '../../helper';
import { getTeamMemberTitle, getUserDisplayName } from '../util';
const ContactsModal = () => {
  const { state, controller } = useStaffDashboardContext();
  const { contactModalOpen, studentTeamMembers, contactModalStudentId, contactModalPosition } = state;
  const currentUserId = useRequesterUserId();
  const teamMembers = useMemo(
    () =>
      (contactModalStudentId
        ? studentTeamMembers[contactModalStudentId].filter((o) => o.userId !== currentUserId)
        : []) || [],
    [studentTeamMembers, contactModalStudentId, currentUserId],
  );
  return (
    <ListModal
      transitionName={''}
      renderItem={(item) => (
        <ContactCard
          enableTooltip={false}
          avatar={item.profileImageUrl}
          firstName={item.firstName}
          lastName={item.lastName}
          onClickName={() => controller.goToStudentProfile(item.userId)}
          onClickMessage={() => controller.onClickMessage(currentUserId, item.userId, getUserDisplayName(item))}
          title={getTeamMemberTitle(item)}
        />
      )}
      listContainerStyle={{
        marginBottom: 0,
        padding: 0,
      }}
      data={teamMembers}
      onCancel={controller.closeContactModal}
      width={272}
      isOpen={contactModalOpen}
      mask={false}
      maskClosable={true}
      destroyOnClose
      style={{
        ...contactModalPosition,
        position: 'absolute',
      }}
    />
  );
};

export default ContactsModal;
