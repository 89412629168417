import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import classnames from 'classnames';
import css from './style.scss';

export default function LoadingCircle(props) {
  return (
    <CircularProgress
      className={classnames({ [css.circle]: true }, { [css.contrast]: !props.color })}
      style={{ width: '1.3rem', height: '1.3rem', color: props.color, ...props.style }}
    />
  );
}

LoadingCircle.propTypes = {
  color: PropTypes.string,
  style: PropTypes.object,
};
