import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/molecules/Modal';
import css from './styles.scss';

export default function SyncErrorModal(props) {
  const { isOpen, onCancel, resync, calSource } = props;
  return (
    <Modal isOpen={isOpen} title="Sync error" onClose={onCancel} onSubmit={() => resync(calSource)} submitText="Resync">
      <div className={css.front}>
        <div className={css.modalBody}>
          <div className={css.bookingWithWrapper}>
            <div className={css.centerText}>
              Synchronization failed due to your Google/Apple authorization token needs updating. Please restart the
              synchronization process.{' '}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

SyncErrorModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  resync: PropTypes.func.isRequired,
  calSource: PropTypes.string,
};
