import styled from 'styled-components';
import { Column } from '../index.style';
import Drawer from '@material-ui/core/Drawer';
import MultiSelectSelector from './MultiSelectSelector';

export const StatusSelector = styled(MultiSelectSelector)`
  width: 250px;
`;

export const AddResultText = styled.div`
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: #1d1e2b;
  margin-top: 25px;
`;

export const UniversityText = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #73747d;
`;

export const SectionTitle = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #1d1e2b;
  margin-top: 25px;
  margin-bottom: 15px;
`;

export const Container = styled(Column)`
  padding: 30px 40px;
`;

export const StyledDrawer = styled(Drawer)`
  & .MuiDrawer-root {
    z-index: 100 !important;
  }
  z-index: 100 !important;
`;
