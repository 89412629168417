import { MissionControlUpdatesController } from './controller';
import { TStore, reducer, INITIAL_STORE, Actions } from './store';
import { TDispatch } from './type';
import React, { useReducer, useCallback, createContext, useEffect } from 'react';

type TContext<A extends { name: string; payload: unknown }> = {
  controller: MissionControlUpdatesController;
  state: TStore;
  dispatch: TDispatch<A>;
};

export type TMissionControlUpdatesContext = TContext<Actions>;

export const MissionControlUpdatesContext = createContext<TMissionControlUpdatesContext>(
  (null as unknown) as TMissionControlUpdatesContext,
);

export const withMissionControlUpdatesContext = <P,>(Comp: React.ComponentType<P>): ((p: P) => JSX.Element) => {
  const controller = new MissionControlUpdatesController();
  const EnhancedComp = (props: P): JSX.Element => {
    const [state, _dispatch] = useReducer(reducer, INITIAL_STORE);
    const dispatch = useCallback(
      <A extends Actions>(name: A['name'], payload?: A['payload']) => _dispatch({ name, payload } as A),
      [_dispatch],
    );
    useEffect(() => {
      controller.setParams(state, dispatch);
    }, [state, dispatch]);
    return (
      <MissionControlUpdatesContext.Provider
        value={{
          state,
          dispatch,
          controller,
        }}
      >
        <Comp {...props} />
      </MissionControlUpdatesContext.Provider>
    );
  };
  return EnhancedComp;
};
