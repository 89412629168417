import Immutable from 'immutable';
import createReducer from 'utils/createReducer';
import { normalize } from 'normalizr';
import moment from 'moment';
import 'moment-timezone';
import { toasterMessages } from '@crimson-education/common-config';
import { PermissionResourceType } from '@crimson-education/common-config/lib/authorization';
import profileAPI from 'graphql/api/profile';
import userAPI from 'graphql/api/user';
import userInvitationAPI from 'graphql/api/userInvitation';
import authorizationAPI from 'graphql/api/authorization';
import eventApi from 'graphql/api/event';
import availabilityApi from 'graphql/api/availability';
import { getBookingAs, selectedBookWith, getCurrentUserId } from 'selectors/user';
import {
  getCachedContactListPageInfo,
  getByComponentKey,
  getOurPeopleFilter,
  getUsersFetchedCount,
  getBookingWithFilter,
  getBookingForFilter,
  selectUserPermissionInfo,
  selectUserProfile,
} from 'selectors/meta';
import {
  ADD_ENTITIES,
  addEntitiesWithNormalisation,
  addEntities,
  SET_ENTITIES,
  setEntitiesWithNormalisation,
  compose,
  addMeta,
  addAggregate,
  addEntity,
} from 'ducks/normalizr';
import { userEntity, userRelationshipEntity, bookingEntity } from 'schema';
import componentKeys from 'constants/componentKeys';
import {
  formLoading,
  formLoaded,
  formSuccess,
  formFail,
  failForm,
  updateMeta,
  toggleUserSelectedInCalendar,
  contactsLoadingSucceeded,
  updateBookingWithCache,
  updateBookingForCache,
  updateAssignableUsersCache,
} from 'ducks/meta';
import { checkForSessionStartingSoon } from 'ducks/booking';
import { updateHasNewFriendStatus } from 'ducks/globalState';
import { getThisWeek, getSpecificDay, getAroundThreeMonth, getInOneHour } from 'utils/people';
import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';
import isEqual from 'lodash/isEqual';
import { isMatch } from 'lodash';
import { UserCountMap } from 'constants/ourPeople';
import { formatGraphQLRequestError } from 'utils/graphql';

// USER
const FETCH_USERS_FOR_BOOKING_AS_SUCCEEDED = 'user/FETCH_USERS_FOR_BOOKING_AS_SUCCEEDED';
const FETCH_EVENTS_FOR_BOOKING_AS_SUCCEEDED = 'user/FETCH_EVENTS_FOR_BOOKING_AS_SUCCEEDED';
const FETCH_EVENTS_FOR_BOOKING_WITH_SUCCEEDED = 'user/FETCH_EVENTS_FOR_BOOKING_WITH_SUCCEEDED';
const FETCH_USERS_FOR_BOOKING_WITH_SUCCEEDED = 'user/FETCH_USERS_FOR_BOOKING_WITH_SUCCEEDED';
const FETCH_AVAILABILITY_FOR_USERS_SUCCEEDED = 'user/FETCH_AVAILABILITY_FOR_USERS_SUCCEEDED';
const UPDATE_USER_RELATIONSHIPS = 'user/UPDATE_USER_RELATIONSHIPS';
const UPDATE_USER_ABOUT = 'user/UPDATE_USER_ABOUT';
const UPDATE_USER_RECORDING_CONSENT = 'user/UPDATE_USER_RECORDING_CONSENT';
// UPDATING REDUNDANT STORE DATA - REFACTOR
const UPDATE_BOOKING_FOR_USER = 'user/UPDATE_BOOKING_FOR_USER';
const REMOVE_BOOKING_FOR_USER = 'user/REMOVE_BOOKING_FOR_USER';
const CHANGE_BOOKING_STATUS_FOR_USER = 'user/CHANGE_BOOKING_STATUS_FOR_USER';
const UPDATE_HEXACO = 'user/UPDATE_HEXACO';
const UPDATE_USER_CONSENT = 'user/UPDATE_USER_CONSENT';
const UPDATE_USER_IS_TEST = 'user/UPDATE_USER_IS_TEST';
const UPDATE_PREFERRED_PRONOUN = 'user/UPDATE_PREFERRED_PRONOUN';
const UPDATE_PREFERRED_NAME = 'user/UPDATE_PREFERRED_NAME';
const ONBOARDING_QUIZ_SET_QUESTIONS = 'user/ONBOARDING_QUIZ_SET_QUESTIONS';
const ONBOARDING_QUIZ_SET_RESULT = 'user/ONBOARDING_QUIZ_SET_RESULT';
const UPDATE_STAFF = 'user/UPDATE_STAFF';
const config = getConfig();
const initialState = new Immutable.Map();

export const reducer = {
  [ADD_ENTITIES]: (state, action) => {
    return state.mergeDeep(action.payload.entities.user);
  },
  [SET_ENTITIES]: (state, action) => {
    return state.merge(action.payload.entities.user);
  },
  [UPDATE_USER_RELATIONSHIPS]: (state, action) => {
    return state.setIn([action.payload.userId, 'relationships'], action.payload.relationships);
  },
  [UPDATE_USER_ABOUT]: (state, action) => {
    return state.withMutations((ctx) => {
      ctx
        .setIn([action.payload.userId, 'citizenships'], action.payload.citizenships)
        .setIn([action.payload.userId, 'gender'], action.payload.gender)
        .setIn([action.payload.userId, 'dob'], action.payload.dob)
        .setIn([action.payload.userId, 'languages'], action.payload.languages)
        .setIn([action.payload.userId, 'primaryLanguage'], action.payload.primaryLanguage);
    });
  },
  [UPDATE_USER_RECORDING_CONSENT]: (state, action) => {
    return state.setIn([action.payload.userId, 'meetingRecordingConsent'], action.payload.meetingRecordingConsent);
  },
  [FETCH_USERS_FOR_BOOKING_AS_SUCCEEDED]: (state, action) => {
    const { users, bookingAs } = action.payload;
    const userIds = users.map((user) => user.userId);
    return state.setIn([bookingAs, 'bookFor'], userIds);
  },
  [FETCH_EVENTS_FOR_BOOKING_AS_SUCCEEDED]: (state, action) => {
    const { bookings, unavailable, bookingAs, block } = action.payload;

    const prevUnavailability = state.getIn([bookingAs.get('userId'), 'availability', 'unavailable']);
    const prevBookings = state.getIn([bookingAs.get('userId'), 'availability', 'bookings']);
    return state.setIn(
      [bookingAs.get('userId'), 'availability'],
      Immutable.fromJS({
        bookings: bookings || prevBookings,
        unavailable: unavailable || prevUnavailability,
        available: [],
        block,
      }),
    );
  },
  [FETCH_EVENTS_FOR_BOOKING_WITH_SUCCEEDED]: (state, action) => {
    const { bookings, userId } = action.payload;
    return state.setIn([userId, 'availability', 'bookings'], Immutable.fromJS(bookings));
  },
  [UPDATE_BOOKING_FOR_USER]: (state, action) => {
    const booking = action.payload.booking;
    const userId = action.payload.user.get('userId');

    if (!state.get(userId)) {
      return state; // User not in the state
    }

    const currentBookings = state.getIn([userId, 'availability', 'bookings']);

    if (!currentBookings || !currentBookings.size) {
      return state.setIn([userId, 'availability', 'bookings'], Immutable.fromJS([booking]));
    }

    // Update the booking in the user's list of bookings
    return state.setIn(
      [userId, 'availability', 'bookings'],
      currentBookings.filter((b) => b.get('id') !== booking.id).push(Immutable.fromJS(booking)),
    );
  },
  [CHANGE_BOOKING_STATUS_FOR_USER]: (state, action) => {
    const bookingId = action.payload.bookingId;
    const newStatus = action.payload.status;
    const userId = action.payload.user.get('userId');

    if (!state.get(userId)) {
      return state; // User not in the state
    }

    const currentBooking = state.getIn([userId, 'availability', 'bookings']).find((b) => b.get('id') === bookingId);

    if (!currentBooking || !currentBooking.size) {
      return state;
    }

    const newBooking = currentBooking.set('status', newStatus);

    // Update the booking in the user's list of bookings
    return state.setIn(
      [userId, 'availability', 'bookings'],
      state
        .getIn([userId, 'availability', 'bookings'])
        .filter((b) => b.get('id') !== bookingId)
        .push(Immutable.fromJS(newBooking)),
    );
  },
  [REMOVE_BOOKING_FOR_USER]: (state, action) => {
    const bookingId = action.payload.bookingId;
    const userId = action.payload.user.get('userId');

    if (!state.get(userId)) {
      return state; // User not in the state
    }

    const currentBookings = state.getIn([userId, 'availability', 'bookings']);

    if (!currentBookings || !currentBookings.size) {
      return state; // No bookings to remove
    }

    // Return list with booking removed
    return state.setIn(
      [userId, 'availability', 'bookings'],
      currentBookings.filter((b) => b.get('id') !== bookingId),
    );
  },
  [FETCH_USERS_FOR_BOOKING_WITH_SUCCEEDED]: (state, action) => {
    const { bookingAs, users } = action.payload;
    const canBookWithList = users.map((user) => user.userId);
    const bookWith = state.getIn([bookingAs.get('userId'), 'bookWith']);
    const newList = bookWith ? bookWith.toJS().concat(canBookWithList) : canBookWithList;
    return state.setIn([bookingAs.get('userId'), 'bookWith'], Immutable.fromJS(newList));
  },
  [FETCH_AVAILABILITY_FOR_USERS_SUCCEEDED]: (state, action) => {
    const availabilities = action.payload.availabilities;
    const unavailabilities = action.payload.unavailabilities;
    const blockCalendar = action.payload.blockCalendar;
    // Helper: retrieve any items in a list relevant to a given user.
    function relevant(userId, list) {
      return list.filter((item) => {
        return item.userId === userId;
      });
    }

    // Check each user for new availabilities information.
    let newState = state;
    state.forEach((user) => {
      // Filter out relevant items for that user.
      const userAvailable = relevant(user.get('userId'), availabilities);
      const userUnavailable = relevant(user.get('userId'), unavailabilities);
      const userBlockCalendar = relevant(user.get('userId'), blockCalendar);
      // Update user if new information is present.
      if (userAvailable.length) {
        newState = newState.setIn([user.get('userId'), 'availability', 'available'], Immutable.fromJS(userAvailable));
      }

      if (userUnavailable.length) {
        newState = newState.setIn(
          [user.get('userId'), 'availability', 'unavailable'],
          Immutable.fromJS(userUnavailable),
        );
      }
      if (userBlockCalendar.length) {
        newState = newState.setIn([user.get('userId'), 'availability', 'block'], Immutable.fromJS(userBlockCalendar));
      }
    });
    return newState;
  },
  [UPDATE_HEXACO]: (state, action) => {
    return state.setIn([action.payload.userId, 'hexaco'], action.payload.hexaco);
  },
  [UPDATE_USER_CONSENT]: (state, action) => {
    const newConsent = action.payload.consent;
    const userId = newConsent.userId;
    const consents = state.getIn([userId, 'consents']);

    const index = consents.findIndex((c) => c.get('id') === newConsent.id);

    if (index !== -1) {
      return state.setIn([userId, 'consents', index, 'value'], newConsent.value);
    }
    return state.setIn([userId, 'consents'], consents.push(Immutable.fromJS(newConsent)));
  },
  [UPDATE_USER_IS_TEST]: (state, action) => {
    return state.setIn([action.payload.userId, 'isTest'], action.payload.isTest);
  },
  [UPDATE_PREFERRED_PRONOUN]: (state, action) => {
    const { userId, pronoun } = action.payload;
    return state.setIn([`${userId}`, 'pronoun'], pronoun);
  },
  [UPDATE_PREFERRED_NAME]: (state, action) => {
    const { userId, preferredName } = action.payload;
    return state.setIn([`${userId}`, 'preferredName'], preferredName);
  },
  [ONBOARDING_QUIZ_SET_QUESTIONS]: (state, action) => {
    const { questions, userId } = action.payload;
    return state.setIn([userId, 'onboardingQuestions'], questions);
    // return state.set('onboardingQuestions', questions);
  },
  [ONBOARDING_QUIZ_SET_RESULT]: (state, action) => {
    const { result, userId } = action.payload;
    return state.setIn([userId, 'onboardingResult'], result);
    // return state.set('onboardingResult', result);
  },
  [UPDATE_STAFF]: (state, action) => {
    return state.setIn([action.payload.userId, 'staffInfo'], action.payload.staffInfo);
  },
};

export default createReducer(initialState, reducer);

export function fetchUsersForBookingAsSucceeded(payload) {
  return { type: FETCH_USERS_FOR_BOOKING_AS_SUCCEEDED, payload };
}

export function fetchEventsForBookingAsSucceeded(payload) {
  return { type: FETCH_EVENTS_FOR_BOOKING_AS_SUCCEEDED, payload };
}

export function fetchEventsForBookingWithSucceeded(payload) {
  return { type: FETCH_EVENTS_FOR_BOOKING_WITH_SUCCEEDED, payload };
}

export function fetchUsersForBookingWithSucceeded(payload) {
  return { type: FETCH_USERS_FOR_BOOKING_WITH_SUCCEEDED, payload };
}

export function fetchAvailabilityForUsersSucceeded(payload) {
  return { type: FETCH_AVAILABILITY_FOR_USERS_SUCCEEDED, payload };
}

export function updateBookingForUser(payload) {
  return { type: UPDATE_BOOKING_FOR_USER, payload };
}

export function removeBookingForUser(payload) {
  return { type: REMOVE_BOOKING_FOR_USER, payload };
}

export function changeBookingStatusForUser(payload) {
  return { type: CHANGE_BOOKING_STATUS_FOR_USER, payload };
}

export function updateUserRelationships(payload) {
  return { type: UPDATE_USER_RELATIONSHIPS, payload };
}

export function updateAboutDetailsSuccessful(payload) {
  return { type: UPDATE_USER_ABOUT, payload };
}

export function updateRecordingConsentSuccessful(payload) {
  return { type: UPDATE_USER_RECORDING_CONSENT, payload };
}

export function updateIsTest(userId, isTest) {
  return { type: UPDATE_USER_IS_TEST, payload: { userId, isTest } };
}

export function updateHexacoResultsSucceeded(userId, hexaco) {
  return { type: UPDATE_HEXACO, payload: { userId, hexaco } };
}

export function updatePreferredPronoun(payload) {
  return { type: UPDATE_PREFERRED_PRONOUN, payload };
}

export function updatePreferredName(payload) {
  return { type: UPDATE_PREFERRED_NAME, payload };
}

export function setOnboardingQuestions(questions, userId) {
  return {
    type: ONBOARDING_QUIZ_SET_QUESTIONS,
    payload: {
      questions: removeChildrenQuestionsFromList(questions),
      userId,
    },
  };
}

export function setOnboardingResult(result, userId) {
  return {
    type: ONBOARDING_QUIZ_SET_RESULT,
    payload: {
      result,
      userId,
    },
  };
}

export function updateStaffInfoSucceeded(userId, staffInfo) {
  return { type: UPDATE_STAFF, payload: { userId, staffInfo } };
}

export function fetchBookingWithEvents(userId) {
  return (dispatch, getState) => {
    const state = getState();
    const { from, to } = getAroundThreeMonth(state);
    eventApi.fetchEventsBetweenForUser(from, to, userId).then((response) => {
      const bookings = response;
      dispatch(
        fetchEventsForBookingWithSucceeded({
          bookings,
          userId,
        }),
      );
    });
  };
}

export function checkSessionAboutToStart() {
  return async (dispatch, getState) => {
    const state = getState();
    const { userId: loginUserId } = selectUserProfile(state);
    const { from, to } = getInOneHour();
    const events = await eventApi.fetchEventsBetweenForUser(from, to, loginUserId, false);
    dispatch(checkForSessionStartingSoon(events));
  };
}

// Fetch unavailabilities/bookings for the person the user is booking as.
export function fetchBookingAsEvents(includeUnavailabilities = true) {
  return async (dispatch, getState) => {
    // Determine who we are booking as.
    const state = getState();
    const loginUserPermissionsInfo = selectUserPermissionInfo(state);
    const bookingAs = getBookingAs(state);

    const includeBlockCalendar =
      loginUserPermissionsInfo.filter((p) => p.get('resourceType') === PermissionResourceType.Availability).length > 0;

    // If we are booking as someone:
    if (bookingAs && bookingAs.size) {
      // Define the start and end times for the visible week on the calendar.
      const { start, end } = getSpecificDay(state);
      const { from, to } = getThisWeek(state);

      // Retrieve bookings and unavailabilities in the timeframe.
      const userId = bookingAs.get('userId');
      Promise.all([
        eventApi.fetchEventsBetweenForUser(from, to, userId),
        includeBlockCalendar && availabilityApi.fetchBlockCalendarByUserIds([userId], start, end),
        includeUnavailabilities && availabilityApi.fetchCalculatedUnavailabilities([userId], from, to),
      ]).then(([bookings, blockCalendar, unavailabilities]) => {
        const events = bookings.filter((booking) => booking.source === 'crimson-app');
        dispatch(addEntitiesWithNormalisation(events, [bookingEntity]));
        dispatch(
          fetchEventsForBookingAsSucceeded({
            bookingAs,
            bookings,
            ...(includeUnavailabilities && { unavailable: unavailabilities }),
            ...(includeBlockCalendar && { block: blockCalendar }),
          }),
        );
      });
    }
  };
}

/**
 * Action creator for setting a user's role.
 *
 * @param {string} userId
 * @param {[string]} roles
 */
export function setUserRoles(userId, roles, primaryRole) {
  return async (dispatch) => {
    dispatch(formLoading());
    try {
      const result = await authorizationAPI.setUserRoles(userId, roles, primaryRole);
      if (result.setUserRoles !== 'OK') {
        throw new Error('User roles was not successfully set.');
      }

      dispatch(addEntitiesWithNormalisation({ userRoles: result.setUserPrimaryRole.userRoles, userId }, userEntity));
      dispatch(formSuccess(toasterMessages.userRoleAdded()));
    } catch (ex) {
      dispatch(formFail(toasterMessages.userRoleNotAdded()));
    }
  };
}

export function setIsTestUser(userId, isTest) {
  return (dispatch) => {
    return userAPI.editUser(userId, { isTest }).then(({ editUser: user }) => {
      if (user) {
        dispatch(updateIsTest(user.userId, user.isTest));
      }
    });
  };
}

export function updateBookingForUserAction(user, booking) {
  return updateBookingForUser({ user, booking });
}

export function removeBookingForUserAction(user, bookingId) {
  return removeBookingForUser({ user, bookingId });
}

export function changeBookingStatusForUserAction(user, bookingId, status) {
  return changeBookingStatusForUser({ user, bookingId, status });
}

// Fetch the people that the user we are booking as can book with.
export function fetchPeopleForBookingWith(filter, paginationObj) {
  return (dispatch, getState) => {
    dispatch(updateMeta(componentKeys.BOOKING_WITH_FILTER, filter));
    // Determine who we are booking as.
    const bookingAs = getBookingAs(getState());
    // If we are booking as someone, fetch people to book with.
    if (bookingAs && bookingAs.size) {
      const userId = bookingAs.get('userId');
      dispatch(updateMeta(componentKeys.BOOKING_WITH_FETCHED, false));
      const pagination = paginationObj || { pageSize: 20, pageNumber: 1 };
      return userAPI.fetchPeopleForBookingWith({ userId, filter, pagination }).then((response) => {
        // check if filter in state is same as the filter for which results have just been returned
        if (filter) {
          const currentFilter = getBookingWithFilter(getState());
          if (!isEqual(currentFilter, filter)) return;
        }
        const { pagedUsers } = response;
        dispatch(setEntitiesWithNormalisation(pagedUsers.results, [userEntity]));
        dispatch(fetchUsersForBookingWithSucceeded({ bookingAs, users: pagedUsers.results || [] }));
        dispatch(updateMeta(componentKeys.BOOKING_WITH_FETCHED, true));
        dispatch(updateBookingWithCache(pagedUsers.results, pagedUsers.pagination));
      });
    }
    return null;
  };
}

// Select a person to book on behalf of, then load their bookings, availability
// and other people they can book with.
export function selectBookingAs(userId, fetchBookingWith = true) {
  return (dispatch) => {
    // Clear currently selected users for booking with.
    dispatch(updateMeta(componentKeys.CALENDAR_SELECTED, new Immutable.List()));

    // Select a user to book as.
    dispatch(updateMeta(componentKeys.CALENDAR_BOOKING_AS, userId));

    // Load bookings and availabilities.
    return Promise.all([
      dispatch(fetchBookingAsEvents()),
      ...((fetchBookingWith && [dispatch(fetchPeopleForBookingWith())]) || []),
    ]);
  };
}

// Fetch the people that the user we are booking as can book with.
export function fetchPeopleForBookingFor(filterText = '', paginationObj) {
  return (dispatch, getState) => {
    dispatch(updateMeta(componentKeys.BOOKING_FOR_FILTER, filterText));
    const pagination = paginationObj || { pageSize: 20, pageNumber: 1 };
    return userAPI.fetchPeopleForBookingFor({ filter: { text: filterText }, pagination }).then((response) => {
      const currentFilter = getBookingForFilter(getState());
      if (!isEqual(currentFilter, filterText)) return;
      const { pagedUsersBookFor } = response;
      dispatch(addEntitiesWithNormalisation(pagedUsersBookFor.results, [userEntity]));
      dispatch(updateBookingForCache(pagedUsersBookFor.results, pagedUsersBookFor.pagination));
    });
  };
}

/**
 * Fetch users to assign for My Crimson Team
 * @param {object} filter: { roles: [String], text: String }
 * @param {object} paginationObj: { pageSize: Int, pageNumber: Int }
 */
export function fetchAssignableUsers(filter, paginationObj) {
  return (dispatch) => {
    const pagination = paginationObj || { pageSize: 20, pageNumber: 1 };
    return userAPI.fetchAssignableUsers({ filter, pagination }).then((response) => {
      const { assignableUsers } = response;
      dispatch(addEntitiesWithNormalisation(assignableUsers.results, [userEntity]));
      dispatch(updateAssignableUsersCache(assignableUsers.results, assignableUsers.pagination, filter.roles[0]));
    });
  };
}

// Fetch user availabilities for the week being viewed.
export function fetchPeopleAvailability(userIds) {
  return (dispatch, getState) => {
    // Define the start and end times for the visible week on the calendar.
    const { from, to } = getThisWeek(getState());
    const { start, end } = getSpecificDay(getState());

    // Don't fetch availabilities if there are no users.
    if (userIds.length > 0) {
      // Fetch the users' booking and availability information for the week.
      Promise.all([
        availabilityApi.fetchAvailabilitiesByUserIds(userIds, from, to),
        availabilityApi.fetchCalculatedUnavailabilities(userIds, from, to),
        availabilityApi.fetchBlockCalendarByUserIds(userIds, start, end),
      ]).then((results) => {
        dispatch(
          fetchAvailabilityForUsersSucceeded({
            availabilities: results.shift(),
            unavailabilities: results.shift(),
            blockCalendar: results.shift(),
          }),
        );
      });
    }
  };
}

// Show/hide the user's booking information in the calendar.
export function togglePersonSelected(userId) {
  return (dispatch, getState) => {
    // Toggle the user's selection state.
    dispatch(toggleUserSelectedInCalendar(userId));

    // Determine if the user was toggled into the selected state.
    const thisUserFilter = (user) => user.get('userId') === userId;
    const currentlySelected = selectedBookWith(getState());
    const isUserSelected = currentlySelected.filter(thisUserFilter).size !== 0;

    // Asynchronously fetch the person's availability/external calendar events if necessary.
    if (isUserSelected) {
      dispatch(fetchBookingWithEvents(userId));
      dispatch(fetchPeopleAvailability([userId]));
      dispatch(updateMeta(componentKeys.DAY_CALENDAR_VIEW, true));
    }
  };
}

export function queryUsers(params, filters, userId, { tabType, hasFilters }) {
  return (dispatch, getState) => {
    dispatch(updateMeta(componentKeys.OUR_PEOPLE_FILTER, filters));
    dispatch(updateMeta(componentKeys.FILTERED_QUERY, hasFilters || false));
    dispatch(formLoading());
    dispatch(updateMeta(componentKeys.USERS_FETCHED, false));
    userAPI
      .queryUsers(params, filters, userId)
      .then((res) => {
        // check if filter in state is same as the filter for which results have just been returned
        const currentFilter = getOurPeopleFilter(getState());
        if (!isEqual(currentFilter, filters)) return;
        const query = res.queryUsers;
        const users = query.results || [];
        const count = query.pagination.totalCount || 0;

        // Replace user objects with userId references in query. User objects
        // will be stored separately in the user section of the store.
        query.results = users.map((user) => user.userId);

        dispatch(addEntitiesWithNormalisation(users, [userEntity]));
        dispatch(updateMeta(componentKeys.OUR_PEOPLE_SEARCH, query));
        dispatch(updateMeta(componentKeys.USERS_FETCHED, true));
        // only update meta once to keep initial count of users before filtering
        if (tabType && !getUsersFetchedCount(getState()) && count) {
          const aggregateKey = UserCountMap[tabType].key;
          dispatch(updateMeta(componentKeys.USERS_FETCHED_COUNT, count));
          dispatch(compose(addEntity(), addAggregate(aggregateKey, count)));
        }
        dispatch(formLoaded());
      })
      .catch((err) => {
        dispatch(formFail(formatGraphQLRequestError(err)));
      });
  };
}

/**
 * A function to fetch ALL the users of a particular role.
 * This is dangerous as it does not have a limit and will return
 * then entire list of users with the selected role.
 *
 * @export
 * @param {string} roleId
 * @returns
 */
export function fetchUsersByRole(roleId) {
  return (dispatch) => {
    return userAPI.fetchUsersByRole(roleId).then((res) => {
      dispatch(addEntitiesWithNormalisation(res.users, [userEntity]));
    });
  };
}

// Fetch just the public user fields from the backend.
export function fetchUsersByIds(ids) {
  return async (dispatch) => {
    const users = await userAPI.fetchPublicUserInfo(ids);
    dispatch(addEntitiesWithNormalisation(users, [userEntity]));
  };
}

// Fetch a full user object from the backend.
export function fetchDetailedUsersByIds(ids) {
  return async (dispatch) => {
    const { users } = await userAPI.fetchUsersByIds(ids);
    dispatch(addEntitiesWithNormalisation(users, [userEntity]));
  };
}

export function fetchUserById(id) {
  return (dispatch) => {
    dispatch(formLoading());
    return userAPI
      .fetchUserById(id)
      .then(({ user, errors }) => {
        if (errors) {
          dispatch(formFail(toasterMessages.userNotFetched()));
          return;
        }
        dispatch(compose(addEntitiesWithNormalisation(user, userEntity)));
        dispatch(formLoaded());
      })
      .catch(() => {
        // Permission denied error.
        dispatch(failForm());
      });
  };
}

export function fetchStudentsByIds(ids) {
  return (dispatch) => {
    dispatch(formLoading());
    dispatch(updateMeta(componentKeys.CLIENTS_FETCHED, false));
    return userAPI
      .fetchStudentsByIds(ids)
      .then(({ users }) => {
        if (users) dispatch(addEntitiesWithNormalisation(users, [userEntity]));
        dispatch(updateMeta(componentKeys.CLIENTS_FETCHED, true));
        dispatch(formLoaded());
      })
      .catch(() => {
        dispatch(formFail(toasterMessages.userStudentsNotFetched()));
      });
  };
}

let isFetchingPublicUsers = false;
export function fetchPublicUsers(userIds) {
  return (dispatch) => {
    if (!isFetchingPublicUsers) {
      isFetchingPublicUsers = true;
      dispatch(formLoading());
      return userAPI
        .fetchPublicUserInfo(userIds)
        .then((res) => {
          isFetchingPublicUsers = false;
          const userIds = res.map((user) => user.userId);
          dispatch(
            compose(
              addEntitiesWithNormalisation(res, [userEntity]),
              addMeta(componentKeys.LESSON_DETAILS_CACHED_USERNAMES, userIds),
            ),
          );
          dispatch(formLoaded());
        })
        .catch(() => {
          isFetchingPublicUsers = false;
          dispatch(formFail(toasterMessages.userNotFetched()));
        });
    }
    return Promise.resolve();
  };
}

export function setUserStatus(userId, active) {
  return (dispatch) => {
    dispatch(formLoading());
    const action = active ? 'activated' : 'deactivated';
    return userAPI
      .setUserStatus(userId, active)
      .then(() => {
        dispatch(formSuccess(toasterMessages.userStatusUpdated(action)));
        dispatch(fetchUserById(userId));
        return true;
      })
      .catch(() => {
        dispatch(formFail(toasterMessages.userStatusNotUpdated(action)));
        return false;
      });
  };
}

export function updateAboutDetails(userId, aboutDetails = {}) {
  const { generalInfo = {}, studentInfo, tutorInfo } = aboutDetails;
  return (dispatch) => {
    return userAPI.editUser(userId, generalInfo, studentInfo, tutorInfo).then(({ editUser: user }) => {
      if (user) {
        dispatch(formSuccess(toasterMessages.userProfileUpdated()));
        dispatch(updateAboutDetailsSuccessful(user));
      } else {
        dispatch(formFail(toasterMessages.userProfileNotUpdated()));
      }
    });
  };
}

export function editUserById(id, userInfo = {}) {
  const { generalInfo = {}, studentInfo, tutorInfo } = userInfo;

  return (dispatch) => {
    dispatch(formLoading());
    return userAPI
      .editUser(id, generalInfo, studentInfo, tutorInfo)
      .then(({ editUser: user }) => {
        if (user) {
          dispatch(formSuccess(toasterMessages.userProfileUpdated()));
          dispatch(addEntitiesWithNormalisation(user, userEntity));
        } else {
          dispatch(formFail(toasterMessages.userProfileNotUpdated()));
        }
      })
      .catch((error) => {
        const message = formatGraphQLRequestError(error, toasterMessages.userProfileNotUpdated());
        dispatch(formFail(message));
      });
  };
}

export function uploadProfileImage(file, userId) {
  return (dispatch) => {
    dispatch(formLoading());
    // If file doesn't exist return false and dispatch toaster error
    if (!file) {
      dispatch(formFail(toasterMessages.userFileMissing()));
      return Promise.reject(new Error('No file selected'));
    } else if (!userId) {
      dispatch(formFail(toasterMessages.userFileNotUploaded()));
      return Promise.reject(new Error('UserId cannot be undefined'));
    }

    const uploadFailed = () => {
      return dispatch(formFail(toasterMessages.userFileNotUploaded()));
    };

    // Otherwise make a graphql request to get the presigned put URL
    return profileAPI
      .fetchProfileImageUrl({ userId })
      .then(({ getProfileImageUrl }) => {
        const { awsUrl } = getProfileImageUrl;

        if (awsUrl) {
          // change upload url of profile picture to a link to reverse proxy if traffic is from core app in China
          const uploadUrl =
            !config.dulwich && config.china
              ? `${config.api.endpoint}/${userId}/profilePicture.jpg${awsUrl.substring(awsUrl.indexOf('?'))}`
              : awsUrl;

          return fetch(uploadUrl, {
            method: 'PUT',
            body: file,
            mode: 'cors',
            headers: {
              'Content-Type': 'image/jpeg',
              'x-amz-acl': 'public-read',
            },
          }).then((res) => {
            if (!res.ok) {
              return uploadFailed();
            }

            // Acknowledge the upload was successful.
            return profileAPI.profileImageUploaded({ userId }).then(({ ackProfileImageUploaded }) => {
              const { profileImageUrl } = ackProfileImageUploaded;
              dispatch(addEntitiesWithNormalisation({ userId, profileImageUrl }, userEntity));
              return dispatch(formSuccess(toasterMessages.userProfileImageUploaded()));
            });
          });
        }

        // No presigned URL.
        return uploadFailed();
      })
      .catch(() => {
        // Server returned error.
        return uploadFailed();
      });
  };
}

export function fetchLoginUser(userId) {
  return (dispatch) => {
    return userAPI
      .fetchLoginUser(userId)
      .then(async ({ user }) => {
        // Detect the user's timezone when set to automatic.
        if (user.autoTimezone || !user.timezone) {
          Object.assign(user, { autoTimezone: true, timezone: moment.tz.guess() });
        }

        dispatch(
          compose(addEntitiesWithNormalisation(user, userEntity), addMeta(componentKeys.APP_LOGIN_USER, user.userId)),
        );

        // appcues flag should be before user activation
        if (window.Appcues) {
          window.Appcues.identify(userId, {
            first_time_login: Boolean(!user.activatedAt),
          });
        }
        // activate the user on first log in
        if (!user.activatedAt) {
          await userInvitationAPI.confirmUserInvitation();
        }
      })
      .catch((error) => {
        dispatch(formFail(formatGraphQLRequestError(error)));
      });
  };
}

export function editLoginUser(userId, userInfo = {}) {
  return (dispatch) => {
    return userAPI.updateLoginUser(userId, userInfo).then((response) => {
      const user = response.editUser;
      dispatch(addEntitiesWithNormalisation(user, userEntity));
    });
  };
}

export function guessAndUpdateLoginUserTimezone(userId) {
  return (dispatch) => {
    const timezone = moment.tz.guess();
    return userAPI.updateLoginUser(userId, { timezone }).then((response) => {
      const user = response.editUser;
      return dispatch(addEntitiesWithNormalisation(user, userEntity));
    });
  };
}

export function editTutorEducationByUserId(userId, education) {
  return (dispatch) => {
    dispatch(formLoading());
    return userAPI
      .editTutorEducation(userId, education)
      .then((res) => {
        const {
          editUser: { tutorInfo },
        } = res;
        dispatch(formSuccess(toasterMessages.userEducationUpdated()));
        dispatch(addEntitiesWithNormalisation({ tutorInfo, userId }, userEntity));
      })
      .catch(() => {
        dispatch(formFail(toasterMessages.userEducationUpdated()));
      });
  };
}

/**
 * assign relationships
 *
 * @export
 * @param {String} relationUserId
 * @param {[{principalUserId, type}]} principals
 * @returns
 *
 */
export function assignPrincipalsToRelation(relationUserId, principals) {
  if (!relationUserId || !Array.isArray(principals) || !principals.length) {
    return Promise.resolve();
  }
  return (dispatch) => {
    return userAPI
      .assignPrincipalsToRelation(relationUserId, principals)
      .then((response) => {
        const normalizedRelationships = normalize(response.relationships, [userRelationshipEntity]);
        dispatch(addEntities(normalizedRelationships));
        dispatch(formSuccess(toasterMessages.userRelationshipUpdated()));
        return dispatch(
          updateUserRelationships({ userId: relationUserId, relationships: normalizedRelationships.result }),
        );
      })
      .catch(() => {
        dispatch(formFail(toasterMessages.userRelationshipNotUpdated()));
      });
  };
}

export const fetchingTutorNote = (userId) => (dispatch) => {
  return userAPI
    .fetchTutorNote(userId)
    .then(({ user }) => dispatch(addEntitiesWithNormalisation({ userId, tutorInfo: user.tutorInfo }, userEntity)));
};

export const fetchingTutorNotes = (userIds) => (dispatch) => {
  return userAPI
    .fetchTutorNotes(userIds)
    .then(({ users }) => dispatch(addEntitiesWithNormalisation(users, [userEntity])));
};

export const fetchTutorsWithNotes = (userIds) => (dispatch) => {
  return userAPI
    .fetchTutorsWithNotes(userIds)
    .then(({ users }) => dispatch(addEntitiesWithNormalisation(users, [userEntity])));
};

export const fetchingStudentNote = (userId) => (dispatch) => {
  return userAPI
    .fetchStudentNote(userId)
    .then(({ user }) => dispatch(addEntitiesWithNormalisation({ userId, studentInfo: user.studentInfo }, userEntity)));
};

export const editingTutorNote = (note, userId) => (dispatch) => {
  return profileAPI
    .editTutorNote(note, userId)
    .then(({ editTutorNote: note }) =>
      dispatch(addEntitiesWithNormalisation({ userId, tutorInfo: { note } }, userEntity)),
    );
};

export const editingStudentNote = (note, userId) => (dispatch) => {
  return profileAPI
    .editStudentNote(note, userId)
    .then(({ editStudentNote: note }) =>
      dispatch(addEntitiesWithNormalisation({ userId, studentInfo: { note } }, userEntity)),
    );
};

export const SET_USER_STATUS = 'messages/SET_USER_STATUS';
export const SET_USER_IS_TYPING = 'messages/SET_USER_IS_TYPING';
export const fetchingUserContacts = (filterText, pageSize = 20) => async (dispatch, getState) => {
  const oldFilterText = getByComponentKey(componentKeys.MESSAGE_CONTACT_FILTER)(getState());
  dispatch(updateMeta(componentKeys.MESSAGE_CONTACT_FILTER, filterText));

  const cached = getCachedContactListPageInfo(getState());
  const hasNextPage = cached.get('hasNextPage');
  const endCursor = cached.get('endCursor');

  // the filter changed and we have already loaded that filter
  if (oldFilterText === filterText && !hasNextPage) return;

  // if no next page, no point loading
  if (!hasNextPage) return;
  sessionStorage.setItem('filterText', filterText);
  const { pageInfo, edges, totalCount, onlineUsers } = await userAPI.getContactsForUser(
    filterText,
    pageSize,
    endCursor,
  );

  if (sessionStorage.getItem('filterText') !== filterText && sessionStorage.getItem('filterText') !== '') {
    return;
  }

  const contacts = edges.map((e) => e.node);
  if (onlineUsers.length > 0) {
    const state = getState();
    const online = state.getIn(['messaging', 'onlineUsers']);
    const combined = new Set([...online]);
    const messaging = state.get('messaging');
    const mUsers = messaging.getIn(['entities', 'user']);
    onlineUsers.forEach((userId) => {
      combined.add(userId);
      const typing = {
        online: true,
      };
      const payload = {
        userId,
        typing,
      };
      mUsers
        .filter((x) => x.get('userId') === userId)
        .map((x) => {
          payload.user = x.merge(typing);
          return x;
        });

      dispatch({ type: SET_USER_IS_TYPING, payload });
    });
    const users = Array.from(combined);
    const payload = { users };
    dispatch({ type: SET_USER_STATUS, payload });
  }

  dispatch(
    contactsLoadingSucceeded(filterText, {
      pageInfo: { ...pageInfo, totalCount },
      contacts,
    }),
  );

  dispatch(addEntitiesWithNormalisation(contacts, [userEntity]));
};

export const saveAppVersion = (name, version, deviceId) => () => {
  return userAPI.saveAppVersion(name, version, deviceId);
};

export function updateRecordingConsent(userId, meetingRecordingConsent) {
  return (dispatch) => {
    return userAPI.editUser(userId, { meetingRecordingConsent }).then(({ editUser: user }) => {
      if (user) {
        dispatch(formSuccess(toasterMessages.userProfileUpdated()));
        dispatch(updateRecordingConsentSuccessful(user));
      } else {
        dispatch(formFail(toasterMessages.userProfileNotUpdated()));
      }
    });
  };
}

export function submitHexacoAnswers(hexaco) {
  return async (dispatch, getState) => {
    try {
      const userId = getCurrentUserId(getState());
      const answer = Object.values(hexaco).map((v) => parseInt(v, 10));

      const result = await profileAPI.submitHexacoTest(userId, answer);
      dispatch(formSuccess('Your psychometrics results have been updated successfully.'));
      dispatch(updateHexacoResultsSucceeded(userId, result.submitHexacoTest));
    } catch (err) {
      dispatch(formFail('Failed to update psychometrics'));
      return false;
    }
    return true;
  };
}

export function savePreferredPronoun(userId, pronoun) {
  return async (dispatch) => {
    try {
      await userAPI.updatePreferredPronoun(userId, pronoun);
      dispatch(updatePreferredPronoun({ userId, pronoun }));
      dispatch(formSuccess('Preferred Pronoun has been updated successfully.'));
    } catch (err) {
      dispatch(formFail('Failed to update preferred pronoun'));
    }
  };
}

export function queryMyFriends(params, status, name) {
  return (dispatch) => {
    dispatch(formLoading());
    dispatch(updateMeta(componentKeys.USERS_FETCHED, false));
    userAPI
      .getMyFriendsByStatus(params, status, name)
      .then((res) => {
        const query = res.getMyFriendsByStatus;
        const users = query.results || [];
        // const count = query.pagination.totalCount || 0;

        dispatch(addEntitiesWithNormalisation(users, [userEntity]));
        dispatch(updateMeta(componentKeys.MY_FRIENDS_SEARCH, query));
        dispatch(updateMeta(componentKeys.USERS_FETCHED, true));
        dispatch(formLoaded());
      })
      .catch((err) => {
        dispatch(formFail(formatGraphQLRequestError(err)));
      });
  };
}

export function queryMyPendingFriends() {
  return (dispatch) => {
    userAPI.getFriendPendingRequestCount().then((res) => {
      const count = res.getFriendPendingRequestCount;
      dispatch(updateHasNewFriendStatus(count));
    });
  };
}

export function acceptFriendRequest(requestId, params, status, name) {
  return (dispatch) => {
    dispatch(formLoading());
    return userAPI
      .acceptFriendRequest(requestId)
      .then(() => {
        dispatch(formSuccess(toasterMessages.friendAcceptSucceed()));
      })
      .catch(() => {
        dispatch(formFail(toasterMessages.friendAcceptFailed()));
      })
      .then(() => {
        dispatch(queryMyPendingFriends());
        dispatch(queryMyFriends(params, status, name));
      });
  };
}

export function blockFriend(friendId, params, status, name) {
  return (dispatch) => {
    dispatch(formLoading());
    return userAPI
      .blockFriend(friendId)
      .then(() => {
        dispatch(formSuccess(toasterMessages.friendBlockSucceed()));
      })
      .catch(() => {
        dispatch(formFail(toasterMessages.friendBlockFailed()));
      })
      .then(() => dispatch(queryMyFriends(params, status, name)));
  };
}

export function ignoreFriendRequest(requestId, params, status, name) {
  return (dispatch) => {
    dispatch(formLoading());
    return userAPI
      .ignoreFriendRequest(requestId)
      .then(() => {
        dispatch(formSuccess(toasterMessages.friendIgnoreSucceed()));
      })
      .catch(() => {
        dispatch(formFail(toasterMessages.friendIgnoreFailed()));
      })
      .then(() => {
        dispatch(queryMyPendingFriends());
        dispatch(queryMyFriends(params, status, name));
      });
  };
}

export function fetchStudentRegularQuestions(userId, category) {
  return (dispatch) => {
    return userAPI.fetchStudentQuestions(userId, category).then((data) => {
      dispatch(addEntitiesWithNormalisation({ userId, studentInfo: { regularQuestions: data.questions } }, userEntity));
    });
  };
}

export function editStudentExtraInfo(question) {
  const userId = question.userId;
  return (dispatch) => {
    let answers;
    if (question.hasLeaf) {
      answers = question.leafQuestion.filter((leaf) => leaf.answer).map((leaf) => leaf.answer);
    } else {
      answers = question.answer;
    }
    return userAPI
      .editStudentExtraInfo(answers)
      .then((data) => {
        dispatch(
          addEntitiesWithNormalisation({ userId, studentInfo: { regularQuestions: data.upsertAnswer } }, userEntity),
        );
      })
      .catch((error) => {
        dispatch(formFail(formatGraphQLRequestError(error)));
      });
  };
}

export function savePreferredName(userId, preferredName) {
  return async (dispatch) => {
    try {
      await userAPI.updatePreferredName(userId, preferredName);
      dispatch(updatePreferredName({ userId, preferredName }));
      dispatch(formSuccess('Preferred name has been updated successfully.'));
    } catch (err) {
      dispatch(formFail(formatGraphQLRequestError(err)));
    }
  };
}

export function setupOnboardingQuiz() {
  return async (dispatch, getState) => {
    try {
      const userId = getCurrentUserId(getState());
      const {
        data: { setupOnboardingQuiz },
      } = await userAPI.setupOnboardingQuiz();
      const { result, questions } = setupOnboardingQuiz;
      dispatch(setOnboardingQuestions(questions, userId));
      dispatch(setOnboardingResult(result, userId));
      return true;
    } catch (err) {
      dispatch(formFail(formatGraphQLRequestError(err, 'Failed to setup quiz')));
      return false;
    }
  };
}

/**
 *
 *
 * @export
 * @return {*}
 * get onboarding quiz result
 */
export function getOnboardingQuizResult(noCache = false) {
  return async (dispatch, getState) => {
    try {
      const userId = getCurrentUserId(getState());
      const {
        data: { getOnboardingQuizResult },
      } = await userAPI.getOnboardingQuizResult(userId, noCache);
      const { result, questions } = getOnboardingQuizResult;
      dispatch(setOnboardingResult(result, userId));
      dispatch(setOnboardingQuestions(questions, userId));
      return true;
    } catch (err) {
      dispatch(formFail('Failed to fetch quiz result'));
      return false;
    }
  };
}

/**
 *
 *
 * @export
 * @param {*} questionId
 * @param {*} optionId
 * @param {*} resultId
 * @return {*}
 * upload user's option every time user click on an option
 */
export function answerOnboardingQuestion(questionId, optionId, resultId) {
  return async (dispatch, getState) => {
    try {
      await userAPI.answerOnboardingQuestion(questionId, optionId, resultId);
      // do something?
      return true;
    } catch (err) {
      dispatch(formFail('Failed to upload choice'));
      return false;
    }
  };
}

/**
 *
 *
 * @export
 * @param {*} resultId
 * @return {*}
 * submit quiz
 */
export function submitOnboardingQuiz(resultId) {
  return async (dispatch, getState) => {
    try {
      const userId = getCurrentUserId(getState());
      const {
        data: { submitOnboardingQuiz },
      } = await userAPI.submitOnboardingQuiz(resultId);
      const { result } = submitOnboardingQuiz;
      dispatch(setOnboardingResult(result, userId));
      // do something
      return true;
    } catch (err) {
      dispatch(formFail('Failed to submit quiz'));
      return false;
    }
  };
}
/**
 *
 *
 * @param {*} questions
 * @return {*}
 * remove children questions from top level of array
 */
function removeChildrenQuestionsFromList(questions) {
  const childrenQuestions = questions
    .filter((o) => o.children && o.children.length)
    .map((o) => o.children)
    .flat();
  return questions.filter((o) => !childrenQuestions.some((cq) => isMatch(cq, { id: o.id })));
}

export function fetchStaffInfo(userId) {
  return async (dispatch) => {
    try {
      const result = await profileAPI.fetchStaffInformation(userId);
      dispatch(updateStaffInfoSucceeded(userId, result.getStaffInfo));
    } catch (err) {
      dispatch(formFail('Failed to fetch staff information'));
    }
  };
}

export function createStaffInfo(staffInfo) {
  return async (dispatch) => {
    try {
      const result = await profileAPI.createStaffInformation(staffInfo);
      dispatch(updateStaffInfoSucceeded(staffInfo.userId, result.createStaffInfo));
    } catch (err) {
      dispatch(formFail('Failed to create staff information'));
    }
  };
}

export function getUserKeyContacts(userId) {
  return async (dispatch) => {
    try {
      const { user } = await userAPI.getUserKeyContacts(userId);
      dispatch(addEntitiesWithNormalisation({ userId, studentInfo: user.studentInfo }, userEntity));
    } catch (error) {
      dispatch(formFail('Failed to retrieve key contact information'));
    }
  };
}
