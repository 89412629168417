/* eslint-disable */
import runtime from '@crimson-education/serviceworker-webpack-plugin/lib/runtime';
import Fingerprint2 from 'fingerprintjs2';
import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';
import { getMetaItem } from 'selectors/meta';
import componentKeys from 'constants/componentKeys';
import pushNotification from '../graphql/api/pushNotifications';

const { vapid } = getConfig();

const testApplicationServerPublicKey = '';
let isSubscribed = false;

function urlB64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

function updateSubscriptionOnServer(subscription, userId) {
  new Fingerprint2().get((result) => {
    pushNotification.subscribeServiceWorker({ userId, subscriptionDetails: JSON.stringify(subscription), device: result });
  });
}

function subscribeUser(swRegistration, userId) {
  const applicationServerKey = urlB64ToUint8Array(testApplicationServerPublicKey || vapid.publicKey);
  swRegistration.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: applicationServerKey,
  })
    .then((subscription) => {
      updateSubscriptionOnServer(subscription, userId);
      isSubscribed = true;
    })
    .catch((err) => {
      console.error('Failed to subscribe the user: ', err);
    });
}

async function checkSubscription(swRegistration, userId) {
  swRegistration.pushManager.getSubscription()
    .then(subscription => subscription && subscription.unsubscribe())
    .then(() => subscribeUser(swRegistration, userId));
}

// Checks to see if serviceworker is supported then checks if there is a current subscription
// if there is it de registers and re registers it - otherwise just registers it.
// It then send the subscription to our bbackend to save and be used later for 
// push notifications
export default function registerServiceWorker(userId) {
  if ('serviceWorker' in navigator && 'PushManager' in window) {
    runtime.register()
      .then((swReg) => {
        checkSubscription(swReg, userId);
      })
      .catch((error) => {
        console.error('Service Worker Error', error);
      });
  }
}

export async function unregisterServiceWorker() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      const appSw = registrations.find(r => r.scope.includes(window.location.origin));
      appSw && appSw.unregister();
    });
  }
}

export async function unsubscribePush() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then((reg) => {
      reg.pushManager.getSubscription().then((subscription) => {
        subscription.unsubscribe().then(successful => 'OK');
      })
    });
  }
}