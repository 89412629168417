import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { contractStatusTypes, Acl, permissionTypes } from '@crimson-education/common-config';

import Header from 'components/molecules/Header';
import SendRequestModal from 'components/organisms/SendRequestModal';
import { Ghost, SelectCard } from 'components/atoms/graphics';
import { Body } from 'components/atoms/typography';
import LoadingCircle from 'components/molecules/LoadingCircle';
import Modal from 'components/molecules/Modal';
import colours from 'components/atoms/colours.scss';
import Select from 'components/molecules/Select';

import Layout from './Layout';
import TutorCard from './Tutor/TutorCard';
import TutorDetail from './Tutor/TutorDetail';
import css from './style.scss';

import {
  CapacityFilter,
  NameFilter,
  CityFilter,
  CountryFilter,
  UniversityFilter,
  DegreeFilter,
  MajorFilter,
  CollegeFilter,
  LanguageFilter,
  ExpertiseFilter,
  // CurrencyFilter,
  NumberOfStudentsFilter,
  StarRatingFilter,
} from './filters';

const numOfDisplayedNewTutors = 5;

export const findValByPercentile = (arrSorted, percentile) => {
  let idx = arrSorted.length * percentile;
  if (Math.ceil(idx) !== idx) {
    idx = Math.ceil(idx) - 1;
    return arrSorted[idx];
  }

  idx = Math.ceil(idx) - 1;
  return (arrSorted[idx] + arrSorted[idx + 1]) / 2;
};

/*
  Low: 20% - 50%
  Medium: 50% - 80%
  High: 80% - 100%
*/
export const findPercentilesForPayRate = (payRates = []) => {
  const medianIdx = 0.5;
  const highIdx = 0.8;

  if (payRates.length === 0) return [0, 0, 0, 0];

  const sorted = payRates.filter((value) => value !== null);
  sorted.sort((a, b) => a - b);
  return [
    sorted[0],
    findValByPercentile(sorted, medianIdx),
    findValByPercentile(sorted, highIdx),
    sorted[sorted.length - 1],
  ];
};

export const tutorCostIndicator = (tutorPrice, cutoff) => {
  if (tutorPrice === undefined || tutorPrice === null) return null;
  if (tutorPrice <= cutoff[1]) return 'average';
  if (tutorPrice <= cutoff[2]) return 'above average';
  return 'high';
};

const sortDirections = [
  {
    label: 'Asc',
    value: 'asc',
  },
  {
    label: 'Desc',
    value: 'desc',
  },
];

const sortOptions = [
  {
    label: 'Sort by Smart Matching',
    value: 'smart_sort',
    defaultDirection: sortDirections[1],
  },
  {
    label: 'Sort by Price',
    value: 'price_sort',
    defaultDirection: sortDirections[0],
  },
  {
    label: 'Sort by Rating',
    value: 'rating_sort',
    defaultDirection: sortDirections[1],
  },
  {
    label: 'Sort by Student Count',
    value: 'student_sort',
    defaultDirection: sortDirections[0],
  },
  {
    label: 'Sort by New',
    value: 'new_sort',
    defaultDirection: sortDirections[0],
  },
];

const getNewOrderInfo = (tutorsListCopy, contracts) => {
  let alteredTutorsList = tutorsListCopy;
  const holder = [];
  if (Object.keys(contracts).length !== 0) {
    const contractKey = Object.keys(contracts);
    const allocatedKey = contractKey[0];
    const allocatedTutorContract = contracts[allocatedKey];
    const { userId: contractUserId } = allocatedTutorContract;

    alteredTutorsList = tutorsListCopy.filter((data) => {
      const {
        tutorInfo: { userId },
      } = data;
      if (userId === contractUserId) {
        holder.push(data);
      }
      return userId !== contractUserId;
    });
  }
  const topFive = [];
  const otherTutors = [];
  alteredTutorsList
    .sort((a, b) => {
      if (a.tutorInfo.onboardedAt > b.tutorInfo.onboardedAt) {
        return 1;
      }
      if (a.tutorInfo.onboardedAt < b.tutorInfo.onboardedAt) {
        return -1;
      }
      return 0;
    })
    .forEach((newTutor, index) => {
      if (index < numOfDisplayedNewTutors) {
        topFive.push(newTutor);
      } else {
        otherTutors.push(newTutor);
      }
    });
  return { otherTutors, holder, topFive };
};

const NewOrderFunction = (tutorsList, contracts, isDesc) => {
  const tutorsListCopy = tutorsList;
  const newOrderInfoObject = getNewOrderInfo(tutorsListCopy, contracts);
  const { otherTutors, holder, topFive } = newOrderInfoObject;
  const tutorsListlength = tutorsList.length;
  if (isDesc) {
    const fullJoinedList = [
      ...holder,
      ...topFive.sort((a, b) => {
        if (a.ranking > b.ranking) {
          return 1;
        }
        if (a.ranking < b.ranking) {
          return -1;
        }
        return 0;
      }),
      ...otherTutors.sort((a, b) => {
        if (a.ranking > b.ranking) {
          return 1;
        }
        if (a.ranking < b.ranking) {
          return -1;
        }
        return 0;
      }),
    ];
    tutorsList.push(...fullJoinedList);
    tutorsList.splice(0, tutorsListlength);
  } else {
    const fullJoinedList = [
      ...holder,
      ...topFive.sort((a, b) => {
        if (a.ranking < b.ranking) {
          return 1;
        }
        if (a.ranking > b.ranking) {
          return -1;
        }
        return 0;
      }),
      ...otherTutors.sort((a, b) => {
        if (a.ranking < b.ranking) {
          return 1;
        }
        if (a.ranking > b.ranking) {
          return -1;
        }
        return 0;
      }),
    ];
    tutorsList.push(...fullJoinedList);
    tutorsList.splice(0, tutorsListlength);
  }
};

export default class InviteTutor extends Component {
  /**
   * Add invitation information to a tutor.
   * @param {Object} tutor
   * @param {Object} contracts
   * @return {*}
   */
  static enrichTutor(tutor, contracts) {
    Object.values(contracts).forEach((contract) => {
      if (tutor.userId === contract.userId) {
        tutor.invitation = {
          contractId: contract.id,
          status: contract.status,
          notes: contract.notes,
        };

        if (contract.status === contractStatusTypes.ACTIVE) {
          tutor.invitation.timestamp = contract.createdAt;
        }

        if (contract.status === contractStatusTypes.PENDING) {
          tutor.invitation.timestamp = contract.createdAt;
        }

        if (contract.status === contractStatusTypes.DECLINED) {
          tutor.invitation.timestamp = contract.declinedAt;
        }
      }
    });

    return tutor;
  }

  /**
   * Sort invited tutors first and attach invitation details to tutor object.
   * @param {Object[]} tutors
   * @param {Object} contracts
   * @return {Object[]} sorted tutors
   */
  static transformTutors(tutors, contracts) {
    const activeTutors = [];
    const pendingTutors = [];
    const declinedTutors = [];
    const availableTutors = [];

    if (!tutors || !contracts) {
      return tutors;
    }

    tutors.forEach((tutor) => {
      InviteTutor.enrichTutor(tutor, contracts);

      const status = tutor.invitation ? tutor.invitation.status : undefined;
      switch (status) {
        case contractStatusTypes.ACTIVE:
          activeTutors.push(tutor);
          break;
        case contractStatusTypes.PENDING:
          pendingTutors.push(tutor);
          break;
        case contractStatusTypes.DECLINED:
          declinedTutors.push(tutor);
          break;
        default:
          availableTutors.push(tutor);
          break;
      }
    });

    return [].concat(activeTutors, pendingTutors, declinedTutors, availableTutors);
  }

  constructor(props) {
    super(props);

    const subject = props.subject && props.subject.toJS();
    const additionalNotes = (subject && subject.notes) || '';
    const preferredTimes = (subject && subject.preferredTimes) || '';
    const tutoringFrequency = (subject && subject.tutoringFrequency) || '';

    this.state = {
      filters: [
        new CapacityFilter(this),
        new NameFilter(this),
        new CityFilter(this),
        new CountryFilter(this),
        new UniversityFilter(this),
        new DegreeFilter(this),
        new MajorFilter(this),
        new CollegeFilter(this),
        new LanguageFilter(this),
        new ExpertiseFilter(this),
        // new CurrencyFilter(this),
        new NumberOfStudentsFilter(this),
        new StarRatingFilter(this),
      ],
      sortOption: sortOptions[0],
      sortDirection: sortOptions[0].defaultDirection,
      selectedTutors: [],
      isModalOpen: false,
      isHighCostConfirmationModalOpen: false,
      preferredTimes,
      additionalNotes,
      tutoringFrequency,
      formError: false,
      dataLoaded: false,
      modalLoading: false,
      highlightedTutorId: null,
      highCostTutor: null,
      objectOfTutorsAndFiltersActive: {},
      optionObject: {},
    };

    this.STUDENT_PACKAGE_PAGE_URL = `/users/${props.match.params.userId}/package`;
  }

  componentDidMount() {
    const {
      fetchStudent,
      fetchPackage,
      areLanguagesFetched,
      fetchLanguages,
      fetchContracts,
      fetchTutorRecommendations,
      history,
      userPermissions,
      fetchAllTutorExpertise,
      allExpertise,
      fetchTutorPayRatesForSubject,
    } = this.props;

    if (!Acl.checkPermission(userPermissions, permissionTypes.EDIT_SUBJECT_TUTOR)) {
      history.push('/unauthorized');
      return;
    }

    if (!areLanguagesFetched) {
      fetchLanguages();
    }

    if (!allExpertise || allExpertise.length === 0) {
      fetchAllTutorExpertise();
    }

    const subject = this.props.subject && this.props.subject.toJS();

    // Fetch all Tutor Rates for Subject.
    if (subject) {
      fetchTutorPayRatesForSubject(subject.subjectId);
    }

    Promise.all([fetchStudent(), fetchContracts(), fetchPackage(), fetchTutorRecommendations()]).then(() => {
      this.setState({ dataLoaded: true });
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { subject: nextSubject } = nextProps;
    const { subject: currentSubject, fetchTutorPayRatesForSubject } = this.props;
    const { tutoringFrequency, preferredTimes, additionalNotes } = this.state;

    const didReceiveSubject = !currentSubject && nextSubject;

    // Set subject fields once available.
    if (didReceiveSubject) {
      const subject = nextSubject.toJS();

      // Fetch all Tutor Rates for Subject.
      fetchTutorPayRatesForSubject(subject.subjectId);

      const newAdditionalNotes = additionalNotes === '' ? subject.notes : additionalNotes;
      const newPreferredTimes = preferredTimes === '' ? subject.preferredTimes : preferredTimes;
      const newTutoringFrequency = tutoringFrequency === '' ? subject.tutoringFrequency : tutoringFrequency;

      this.setState({
        preferredTimes: newPreferredTimes,
        additionalNotes: newAdditionalNotes,
        tutoringFrequency: newTutoringFrequency,
      });
    }

    const { languages, allTutors, allExpertise } = nextProps;
    if (
      allExpertise.length &&
      languages.length &&
      allTutors.length &&
      this.state.filters.length &&
      (languages !== this.props.languages || allTutors !== this.props.allTutors)
    ) {
      this.state.filters.forEach((filter) =>
        filter.data({
          tutors: allTutors,
          languages,
          expertise: allExpertise,
        }),
      );
    }
  }

  getAlgorithmData = (selectedTutors, tutorRecommendations) => {
    let algorithm;
    let version;
    let rankings;
    if (tutorRecommendations && tutorRecommendations.length) {
      algorithm = tutorRecommendations[0].algorithm;
      version = tutorRecommendations[0].version;
      rankings = selectedTutors.map((tutorUserId) => {
        const tutorData = tutorRecommendations.find((data) => data.userId === tutorUserId);
        return tutorData ? tutorData.ranking : null;
      });
    }
    return {
      algorithm,
      version,
      rankings,
    };
  };

  handleFieldChange = (field, input) => {
    this.setState({ [field]: input });
  };

  handleCheckHighCost = (tutor, tutorRateUSD, percentiles) => {
    if (
      !this.state.selectedTutors.includes(tutor.userId) &&
      tutorRateUSD &&
      tutorCostIndicator(tutorRateUSD, percentiles) === 'high'
    ) {
      this.openAllocModal(tutor);
    } else {
      this.handleSelectTutors(tutor.userId);
    }
  };

  handleConfirmAllocation = () => {
    if (this.state.highCostTutor) {
      this.handleSelectTutors(this.state.highCostTutor.userId);
    }
  };

  handleSelectTutors = (option) => {
    let selectedTutors = this.state.selectedTutors;
    const { sortOption, sortDirection } = this.state;
    const filterOption = sortOption || sortOptions[0];
    const { tutorRecommendations } = this.props;
    const RankingWithTutor =
      option &&
      [option].map((tutorUserId) => {
        const tutorData = tutorRecommendations.find((data) => data.userId === tutorUserId);
        const tutorRanking = tutorData ? tutorData.ranking : null;
        const newTutorId = tutorData && tutorData.tutorInfo && tutorData.tutorInfo.userId;
        const score = tutorData.score;
        return { tutorRank: tutorRanking, tutorId: newTutorId, score };
      });

    const optionObjectInfo = {
      filters: this.state.filters.map((filter) => {
        return {
          name: filter.title,
          value: filter.value().toString(),
        };
      }),
      sortByAlgorithm: filterOption.value,
      sortByOrder: sortDirection.value,
      tutorInfo: {
        rank: RankingWithTutor[0].tutorRank,
        score: RankingWithTutor[0].score,
      },
    };

    // Toggles the clicked option in the selected list

    if (!option) {
      selectedTutors = [];
    } else if (selectedTutors.includes(option)) {
      selectedTutors = selectedTutors.filter((selected) => selected !== option);
      const { [option]: deletedKey, ...otherKeys } = this.state.optionObject;
      this.setState({ optionObject: { otherKeys } });
    } else {
      selectedTutors.push(option);
      this.setState((prevState) => ({
        optionObject: { ...prevState.optionObject, [option]: optionObjectInfo },
      }));
    }
    this.setState({ selectedTutors, highCostTutor: null, isHighCostConfirmationModalOpen: false });
  };

  handleHighlightTutor = (tutorId) => {
    this.setState({ highlightedTutorId: tutorId });
  };

  openModal = () => {
    this.setState({ isModalOpen: true });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false, formError: false });
  };

  openAllocModal = (highCostTutor) => {
    this.setState({ highCostTutor, isHighCostConfirmationModalOpen: true });
  };

  closeAllocModal = () => {
    this.setState({ highCostTutor: null, isHighCostConfirmationModalOpen: false });
  };

  resetPage = () => {
    this.setState({
      showNotes: false,
      selectedTutors: [],
      highlightedTutorId: null,
    });
  };

  validateAndSubmitRequest = () => {
    const { tutoringFrequency, preferredTimes, selectedTutors } = this.state;
    const { tutorRecommendations } = this.props;
    const { algorithm, version, rankings } = this.getAlgorithmData(selectedTutors, tutorRecommendations);
    const refinedOptionList = [];
    const optionObjectKeys = Object.keys(this.state.optionObject);
    optionObjectKeys.forEach((key) => {
      refinedOptionList.push({
        tutorId: key,
        tutorInfo: this.state.optionObject[key].tutorInfo,
        filter: this.state.optionObject[key].filters,
        sortByAlgorithm: this.state.optionObject[key].sortByAlgorithm,
        sortByOrder: this.state.optionObject[key].sortByOrder,
      });
    });
    if (!(tutoringFrequency && preferredTimes)) {
      this.setState({ formError: true });
    } else {
      this.setState({ modalLoading: true });
      this.props
        .sendInvitations({
          userId: selectedTutors,
          itemId: this.props.subject.get('id').toString(),
          algorithm,
          version,
          rankings,
          studentId: this.props.student.get('userId'),
          invitationTutoringFrequency: this.state.tutoringFrequency,
          invitationPreferredTimes: this.state.preferredTimes,
          invitationNotes: this.state.additionalNotes,
          selectionInfo: { refinedOptionList },
        })
        .then(() => {
          this.closeModal();
          this.resetPage();
          this.props.history.push(this.STUDENT_PACKAGE_PAGE_URL);
        });
    }
  };

  handleModalFieldChange = (field, value) => {
    this.setState({ [field]: value });
  };

  handleSortChange = (option) => {
    const { sortOption, sortDirection } = this.state;
    const change = { sortOption: option };
    // Change the Sorting Direction if different from the Default.
    if (sortOption !== option && sortDirection !== option.defaultDirection) {
      change.sortDirection = option.defaultDirection;
    }
    this.setState(change);
  };

  handleSortDirectionChange = (direction) => {
    this.setState({ sortDirection: direction });
  };

  sortTutors = (tutorList, contracts) => {
    if (!tutorList || tutorList.length === 0) return;
    const { tutorPayRateForSubject } = this.props;
    const { sortOption, sortDirection } = this.state;
    const option = sortOption || sortOptions[0];
    const direction =
      (sortDirection && sortDirection.value) || (option.defaultDirection && option.defaultDirection.value) || 'asc';

    switch (option.value) {
      case 'smart_sort':
        if (direction === 'desc') {
          NewOrderFunction(tutorList, contracts, true);
        }
        if (direction === 'asc') {
          NewOrderFunction(tutorList, contracts, false);
        }
        break;

      case 'price_sort':
        tutorList.sort((a, b) => {
          const tutorARate = tutorPayRateForSubject(a.userId);
          const tutorBRate = tutorPayRateForSubject(b.userId);

          // Ensures that Tutors with no Rate are separated from Tutors with a Rate of $0.
          const tutorARateInUSD = tutorARate && typeof tutorARate.rateInUSD === 'number' ? tutorARate.rateInUSD : -1;
          const tutorBRateInUSD = tutorBRate && typeof tutorBRate.rateInUSD === 'number' ? tutorBRate.rateInUSD : -1;

          return direction === 'asc' ? tutorARateInUSD - tutorBRateInUSD : tutorBRateInUSD - tutorARateInUSD;
        });
        break;

      case 'rating_sort':
        tutorList.sort((a, b) => {
          // Ensures that Tutors with no Ratings are separated from Tutors with an Average of 0.
          const tutorARating =
            a.tutorInfo && typeof a.tutorInfo.averageRating === 'number' ? a.tutorInfo.averageRating : -1;
          const tutorBRating =
            b.tutorInfo && typeof b.tutorInfo.averageRating === 'number' ? b.tutorInfo.averageRating : -1;
          return direction === 'asc' ? tutorARating - tutorBRating : tutorBRating - tutorARating;
        });
        break;

      case 'student_sort':
        tutorList.sort((a, b) => {
          const tutorAStudentCount = (a.tutorInfo && a.tutorInfo.studentCount) || 0;
          const tutorBStudentCount = (b.tutorInfo && b.tutorInfo.studentCount) || 0;
          return direction === 'asc'
            ? tutorAStudentCount - tutorBStudentCount
            : tutorBStudentCount - tutorAStudentCount;
        });
        break;

      case 'new_sort':
        tutorList.sort((a, b) => {
          const minDate = moment('0001-01-01');
          const tutorAOnboarded =
            (a.tutorInfo && a.tutorInfo.onboardedAt && moment(a.tutorInfo.onboardedAt)) || minDate;
          const tutorBOnboarded =
            (b.tutorInfo && b.tutorInfo.onboardedAt && moment(b.tutorInfo.onboardedAt)) || minDate;
          return direction === 'asc' ? tutorBOnboarded.diff(tutorAOnboarded) : tutorAOnboarded.diff(tutorBOnboarded);
        });
        break;

      // No Sorting otherwise.
      default:
        break;
    }
  };

  render() {
    const {
      sortOption,
      sortDirection,
      selectedTutors,
      isModalOpen,
      isHighCostConfirmationModalOpen,
      tutoringFrequency,
      preferredTimes,
      additionalNotes,
      formError,
      dataLoaded,
      modalLoading,
      highlightedTutorId,
    } = this.state;

    const {
      student: immutableStudent,
      subject: immutableSubject,
      allTutors,
      contracts,
      getLanguages,
      tutorPayRateForSubject,
      payRatesForSubject,
    } = this.props;

    const sortedTutors = InviteTutor.transformTutors(allTutors, contracts);
    const student = immutableStudent && immutableStudent.size ? immutableStudent.toJS() : undefined;
    const subject = immutableSubject && immutableSubject.size ? immutableSubject.toJS() : undefined;
    const activeTutor = sortedTutors.find(
      (tutor) => tutor.invitation && tutor.invitation.status === contractStatusTypes.ACTIVE,
    );
    const hasActiveTutor = !!activeTutor;
    const highlightedTutor = sortedTutors.find((tutor) => tutor.userId === highlightedTutorId);
    const filteredTutorList = sortedTutors.filter((t) => this.state.filters.every((f) => f.apply(t)));
    const payRatePercentiles = payRatesForSubject && findPercentilesForPayRate(payRatesForSubject);
    let hours = 'N/A';
    if (subject) {
      hours = subject.isUnlimited ? 'Unlimited' : `${subject.remainingValue}`;
    }

    const subtitles = [subject && student ? `${subject.name} for ${student.fullName}` : ''];

    const newButtons = [
      {
        buttonText: `Preview request: ${selectedTutors.length}`,
        buttonAction: this.openModal,
        isButtonDisabled: selectedTutors.length === 0,
        dataTestId: 'sendTutorContractRequestButton',
      },
    ];

    const highlightedTutorRate = highlightedTutor && tutorPayRateForSubject(highlightedTutor.userId);
    /* Sort the Tutor List if Sortable */
    this.sortTutors(filteredTutorList, contracts);

    return (
      <div data-ga-category="Tutor Allocation" className={css.inviteTutorContainer}>
        <Header
          title="Request tutors"
          subtitles={subtitles}
          actionButtons={newButtons}
          isBackButtonVisible
          className={css.headerMobileFix}
        />
        <Layout.Container>
          <Layout.Header>
            {this.state.filters.map((F, idx) => (
              <F.render key={idx} />
            ))}
          </Layout.Header>
          <Layout.Sidebar
            header={
              <>
                <Select
                  value={sortOption}
                  options={sortOptions}
                  onChange={this.handleSortChange}
                  isSearchable={false}
                />
                <Select
                  value={sortDirection}
                  options={sortDirections}
                  onChange={this.handleSortDirectionChange}
                  isSearchable={false}
                />
              </>
            }
            headerClass={css.sortContainer}
          >
            {dataLoaded &&
              filteredTutorList.length > 0 &&
              filteredTutorList.map((tutor) => {
                const tutorRate = tutorPayRateForSubject(tutor.userId);
                const rateInUSD = tutorRate && tutorRate.rateInUSD;
                return (
                  <TutorCard
                    key={`${tutor.userId}-searchCard`}
                    tutor={tutor}
                    subjectPayRate={tutorRate}
                    subjectPayPercentiles={payRatePercentiles}
                    onClick={() => this.handleHighlightTutor(tutor.userId)}
                    selected={selectedTutors.indexOf(tutor.userId) !== -1}
                    highlighted={highlightedTutorId === tutor.userId}
                    onSelect={(e) => {
                      e.stopPropagation();
                      this.handleCheckHighCost(tutor, rateInUSD, payRatePercentiles);
                    }}
                  />
                );
              })}
            {dataLoaded && filteredTutorList.length === 0 && (
              <div className={css.emptyState}>
                <Ghost />
                <Body>No results found</Body>
              </div>
            )}
            {!dataLoaded && (
              <div style={{ margin: '8rem auto' }}>
                <LoadingCircle color={colours.crimsonRed} />
              </div>
            )}
          </Layout.Sidebar>
          <Layout.Main>
            {highlightedTutor && (
              <TutorDetail
                tutor={highlightedTutor}
                subjectPayRate={highlightedTutorRate}
                subjectPayRates={payRatesForSubject}
                subjectPayPercentiles={payRatePercentiles}
                student={student}
                subject={subject}
                selected={selectedTutors.indexOf(highlightedTutorId) !== -1}
                onSelect={() => {
                  this.handleCheckHighCost(
                    highlightedTutor,
                    highlightedTutorRate && highlightedTutorRate.rateInUSD,
                    payRatePercentiles,
                  );
                }}
              />
            )}
            {!highlightedTutor && filteredTutorList.length > 0 && (
              <div className={css.emptyState}>
                <SelectCard />
                <Body>Select a tutor on the left to view more information</Body>
              </div>
            )}
            {filteredTutorList.length === 0 && <div />}
          </Layout.Main>
        </Layout.Container>
        {dataLoaded && (
          <SendRequestModal
            isModalOpen={isModalOpen}
            submit={this.validateAndSubmitRequest}
            isSubmitDisabled={!selectedTutors.length}
            closeModal={this.closeModal}
            modalLoading={modalLoading}
            hasActiveTutor={hasActiveTutor}
            subjectName={subject ? subject.name : ''}
            hours={hours}
            student={student}
            tutoringFrequency={tutoringFrequency}
            preferredTimes={preferredTimes}
            additionalNotes={additionalNotes}
            handleFieldChange={this.handleModalFieldChange}
            allTutors={allTutors}
            selectedTutors={selectedTutors}
            handleSelectTutors={this.handleSelectTutors}
            formError={formError}
            getLanguages={getLanguages}
          />
        )}
        <Modal
          isOpen={isHighCostConfirmationModalOpen}
          title="Allocate this tutor?"
          submitText="Yes, confirm this allocation"
          onSubmit={this.handleConfirmAllocation}
          secondarySubmitText="No, cancel this allocation"
          onSecondarySubmit={this.closeAllocModal}
          onSubmitDataTestId="senderConfirmAllocationButton"
          onClose={this.closeAllocModal}
        >
          <div>
            <Body>You are trying to allocate tutor that is high cost.</Body>
            <Body>Please confirm this allocation with your team leader before proceeding.</Body>
          </div>
        </Modal>
      </div>
    );
  }
}

InviteTutor.propTypes = {
  subject: ImmutablePropTypes.map,
  student: ImmutablePropTypes.map,
  allTutors: PropTypes.array,
  fetchStudent: PropTypes.func,
  fetchPackage: PropTypes.func,
  fetchTutorRecommendations: PropTypes.func.isRequired,
  fetchTutorPayRatesForSubject: PropTypes.func.isRequired,
  fetchLanguages: PropTypes.func,
  areLanguagesFetched: PropTypes.bool,
  contracts: PropTypes.object,
  fetchContracts: PropTypes.func.isRequired,
  languages: PropTypes.array,
  sendInvitations: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  userPermissions: PropTypes.array.isRequired,
  match: PropTypes.object.isRequired,
  getLanguages: PropTypes.func.isRequired,
  fetchAllTutorExpertise: PropTypes.func.isRequired,
  allExpertise: PropTypes.array.isRequired,
  tutorRecommendations: PropTypes.array.isRequired,
  tutorPayRateForSubject: PropTypes.func.isRequired,
  payRatesForSubject: PropTypes.array,
};
