import React from 'react';
import PropTypes from 'prop-types';

export default function Reload({ className }) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="61" height="61" viewBox="0 0 61 61">
      <g
        xmlns="http://www.w3.org/2000/svg"
        id="Error-States"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Error-State/-Delayed-Authentication" transform="translate(-691.000000, -384.000000)" fill="#232950">
          <g id="Icon-/-Error-/-Time-is-Up" transform="translate(691.000000, 384.000000)">
            <g id="Empty-/-Icon-/-Refresh">
              <g id="Group">
                <path
                  d="M56.0666667,51.2333333 C56.0666667,53.8984333 53.8984333,56.0666667 51.2333333,56.0666667 C48.5682333,56.0666667 46.4,53.8984333 46.4,51.2333333 L46.4,47.3666667 C46.4,44.7015667 48.5682333,42.5333333 51.2333333,42.5333333 C53.8984333,42.5333333 56.0666667,44.7015667 56.0666667,47.3666667 L56.0666667,51.2333333 Z M1.93333333,13.5333333 L44.4666667,13.5333333 L44.4666667,7.73333333 L1.93333333,7.73333333 L1.93333333,13.5333333 Z M44.4666667,35.7666667 C44.4666667,37.3655333 43.1655333,38.6666667 41.5666667,38.6666667 L1.93333333,38.6666667 L1.93333333,15.4666667 L44.4666667,15.4666667 L44.4666667,35.7666667 Z M52.2,40.6773333 L52.2,5.8 C52.2,2.6013 49.5987,0 46.4,0 C43.2013,0 40.6,2.6013 40.6,5.8 L0.966666667,5.8 C0.4321,5.8 0,6.23306667 0,6.76666667 L0,39.6333333 C0,40.1669333 0.4321,40.6 0.966666667,40.6 L41.5666667,40.6 C44.2317667,40.6 46.4,38.4317667 46.4,35.7666667 L46.4,6.76666667 C46.4,6.23306667 45.9679,5.8 45.4333333,5.8 L42.5333333,5.8 C42.5333333,3.66753333 44.2675333,1.93333333 46.4,1.93333333 C48.5324667,1.93333333 50.2666667,3.66753333 50.2666667,5.8 L50.2666667,40.6773333 C46.9925667,41.1490667 44.4666667,43.964 44.4666667,47.3666667 L44.4666667,51.2333333 C44.4666667,54.9637 47.5029667,58 51.2333333,58 C54.9637,58 58,54.9637 58,51.2333333 L58,47.3666667 C58,43.964 55.4741,41.1490667 52.2,40.6773333 L52.2,40.6773333 Z"
                  id="Fill-588"
                />
                <polygon
                  fill="#AA1F24"
                  id="Fill-589"
                  points="50.2666667 48.3333333 52.2 48.3333333 52.2 45.4333333 50.2666667 45.4333333"
                />
                <polygon
                  fill="#AA1F24"
                  id="Fill-590"
                  points="3.86666667 11.6 5.8 11.6 5.8 9.66666667 3.86666667 9.66666667"
                />
                <polygon
                  fill="#AA1F24"
                  id="Fill-591"
                  points="7.73333333 11.6 9.66666667 11.6 9.66666667 9.66666667 7.73333333 9.66666667"
                />
                <polygon
                  fill="#AA1F24"
                  id="Fill-592"
                  points="11.6 11.6 13.5333333 11.6 13.5333333 9.66666667 11.6 9.66666667"
                />
                <path
                  fill="#AA1F24"
                  d="M22.2333333,33.8333333 C18.5029667,33.8333333 15.4666667,30.798 15.4666667,27.0666667 C15.4666667,23.3353333 18.5029667,20.3 22.2333333,20.3 L22.2333333,22.2333333 C19.5682333,22.2333333 17.4,24.4015667 17.4,27.0666667 C17.4,29.7317667 19.5682333,31.9 22.2333333,31.9 C24.8984333,31.9 27.0666667,29.7317667 27.0666667,27.0666667 C27.0666667,26.1850667 26.8211333,25.317 26.3571333,24.5552667 C25.9859333,23.9491667 25.4823,23.4242667 24.9023,23.0385667 C24.5475333,22.8027 24.3880333,22.3619 24.5117667,21.9539667 C24.6345333,21.5460333 25.0105667,21.2666667 25.4368667,21.2666667 L29,21.2666667 L29,23.2 L27.7791,23.2 C27.8583667,23.3140667 27.9337667,23.4291 28.0072333,23.548 C28.6568333,24.6142333 29,25.8303 29,27.0666667 C29,30.798 25.9637,33.8333333 22.2333333,33.8333333"
                  id="Fill-889"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

Reload.propTypes = {
  className: PropTypes.string,
};
