import MultiSelectFilter from './multiSelect.js';

export default class CountryFilter extends MultiSelectFilter {
  constructor(...args) {
    super(...args);
    this.title = 'Country';
    this.name = 'Country';
  }

  apply(tutor) {
    if (!this.active()) {
      return true;
    }
    return this.state.filtered.some((value) => tutor.country === value);
  }

  data({ tutors }) {
    this.setState({ all: tutors.map((t) => t.country) });
  }

  value() {
    return this.state.filtered;
  }

  simpleValue() {
    return this.value().join(',');
  }
}
