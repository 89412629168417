import React from 'react';
import css from './styles.scss';

const ThreeDots = () => {
  return (
    <div className={css.wave}>
      <span className={css.dot} />
      <span className={css.dot} />
      <span className={css.dot} />
    </div>
  );
};

export default ThreeDots;
