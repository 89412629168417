import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  svgStyle: {
    enableBackground: 'new 0 0 20 25',
  },
}));

export default function CGAReportsIcon() {
  const classes = useStyles();
  return (
    <svg className={classes.svgStyle} x="0px" y="0px" viewBox="0 0 20 25">
      <path
        d="M20,18.7c0-4.1,0-8.1,0-12.2c0-2.2-1.6-3.8-3.8-3.8l-0.7,0v1.3l0,0.2l0.2,0c0.4,0,0.8,0,1.3,0.1
        c0.9,0.3,1.4,1.1,1.4,2.1l0,7.3l0,3.7c0,0.4,0,0.8,0,1.2c0,0.9,0,1.8,0,2.7c0,0.6-0.2,1.1-0.6,1.5c-0.4,0.4-1,0.7-1.6,0.7
        c-1.8,0-3.7,0-5.5,0l-7,0c-1.3,0-2.2-0.9-2.2-2.2c0-4.8,0-9.6,0-14.5c0-0.4,0-0.7,0.1-1.1c0.3-0.9,0.9-1.3,1.7-1.4
        c0.2,0,0.6,0,0.9,0l0.2,0V2.7l-0.9,0c-0.3,0-0.5,0-0.8,0.1C1.2,3.2,0,4.7,0,6.4l0,9.2c0,1.9,0,3.9,0,5.8c0,0.3,0.1,0.7,0.1,1
        C0.5,23.7,1.7,25,3.7,25l0.1,0c1,0,1.9,0,2.9,0c0.4,0,0.8,0,1.3,0l0.8,0l3.7,0c1.4,0,2.8,0,4.2,0c1.7,0,3.3-1.5,3.4-3.2
        c0.1-0.8,0.1-1.6,0-2.4L20,18.7z"
      />
      <path
        d="M14.7,4.1c0,0.4-0.2,0.5-0.5,0.5H5.8C5.4,4.6,5.2,4.5,5.2,4c0-0.4,0-0.9,0-1.3c0-0.3,0.2-0.5,0.5-0.5
        c0.4,0,0.7,0,1.1-0.1c0.6-0.1,1.1-0.5,1.4-1C8.3,1,8.4,0.8,8.5,0.6C8.7,0.2,9.1,0,9.5,0c0.3,0,0.5,0,0.8,0c0.5,0,0.9,0.2,1.2,0.7
        c0.2,0.3,0.4,0.7,0.7,1c0.4,0.4,1,0.6,1.6,0.5c0.8,0,0.9,0,0.9,0.9C14.7,3.4,14.7,3.8,14.7,4.1z"
      />
      <path
        d="M9.8,17.6h-5c-0.4,0-0.8-0.4-0.8-0.8v0c0-0.4,0.4-0.8,0.8-0.8h5c0.4,0,0.8,0.4,0.8,0.8v0
        C10.6,17.2,10.2,17.6,9.8,17.6z"
      />
      <path
        d="M14.9,20.9H4.8c-0.4,0-0.8-0.4-0.8-0.8l0,0c0-0.4,0.4-0.8,0.8-0.8h10.2c0.4,0,0.8,0.4,0.8,0.8l0,0
        C15.7,20.5,15.4,20.9,14.9,20.9z"
      />
      <path
        d="M4.9,13.9c-0.7,0-1.2-0.5-1.2-1.2s0.5-1.2,1.2-1.2c0.1,0,0.2,0,0.2,0L6.8,10c0-0.1,0-0.1,0-0.2
        c0-0.7,0.5-1.2,1.2-1.2s1.2,0.5,1.2,1.2c0,0,0,0.1,0,0.1l1.1,1.1c0.1,0,0.2,0,0.2,0c0.1,0,0.1,0,0.2,0l2.6-2.6c0-0.1,0-0.1,0-0.2
        c0-0.7,0.5-1.2,1.2-1.2s1.2,0.5,1.2,1.2s-0.5,1.2-1.2,1.2c-0.1,0-0.1,0-0.2,0L11.7,12c0,0.1,0,0.1,0,0.2c0,0.7-0.5,1.2-1.2,1.2
        s-1.2-0.5-1.2-1.2c0,0,0-0.1,0-0.1L8.2,11C8.1,11,8,11,8,11c-0.1,0-0.2,0-0.2,0l-1.6,1.5c0,0.1,0,0.1,0,0.2
        C6.1,13.3,5.6,13.9,4.9,13.9z"
      />
    </svg>
  );
}
