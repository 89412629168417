import moment from 'moment';

export function sortByStartDate(bookings) {
  return bookings.sort((a, b) => {
    if (moment(a.get('start')).isSame(moment(b.get('start')))) {
      return 0;
    }
    if (moment(a.get('start')).isBefore(moment(b.get('start')))) {
      return -1;
    }
    return 1;
  });
}

export function processBookings(bookings) {
  if (!bookings) return undefined;

  const processedBookings = {};
  for (const [id, booking] of Object.entries(bookings)) {
    processedBookings[id] = Object.assign({}, booking, {
      start: moment(booking.start).toDate(),
      end: moment(booking.end).toDate(),
    });
  }

  return processedBookings;
}
