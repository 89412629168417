import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import uuid from 'uuid/v4';
import { toArray } from 'react-emoji-render';
import Markdown from 'components/molecules/Markdown';
import MessageFile from 'components/molecules/MessageFile';
import MessageImage from 'components/molecules/MessageImage';
import { messageTypes, messageStatus } from 'constants/messaging';
import IconButton from 'components/molecules/IconButton';
import FlagReportModal from 'components/organisms/FlagReportModal';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { toastr } from 'react-redux-toastr';

import { handleEnter } from '../../../../../utils/accessibility';
import Indicator from './Indicator';
import css from './styles.scss';

const showToaster = () => {
  const options = {
    timeOut: 5000,
    removeOnHover: false,
    onHideComplete: () => {
      toastr.removeByType('warning');
    },
    component: () => (
      <div
        role="button"
        tabIndex={0}
        style={{ cursor: 'pointer' }}
        onClick={() => {
          toastr.removeByType('warning');
        }}
        onKeyDown={handleEnter(() => {
          toastr.removeByType('warning');
        })}
      >
        <span>Thank you for your information, we will take care of it shortly.</span>
      </div>
    ),
  };
  toastr.success('', '', options);
};

function LinkRenderer(props) {
  if (/\.(gif|jpg|jpeg|tiff|png)$/i.test(props.children)) {
    return (
      <span className={css.imageBlock}>
        <a className={css.link} href={props.href} target="_blank" rel="noopener noreferrer">
          {props.children}
        </a>
        <img src={props.children} alt="" />
      </span>
    );
  }
  return (
    <a href={props.href} target="_blank" rel="noopener noreferrer">
      {props.children}
    </a>
  );
}

function TextRenderer(msg = {}) {
  const text = msg.value || '';
  if (/\.(gif|jpg|jpeg|tiff|png)$/i.test(text)) return text;
  const message = toArray(text, {
    className: css.emoji,
    props: { onlyEmojiClassName: css.largeEmoji },
  });

  return <span key={uuid()}>{message}</span>;
}

export default function Message(props) {
  const [isOpen, setFlagModalOpen] = useState(false);
  const [openModalName, setOpenModalName] = useState(false);
  const {
    id,
    retryMessage,
    senderId,
    message,
    status,
    otherParticipants,
    isLast,
    type,
    metadata,
    percent,
    uploadStatus,
    retrySend,
    withdrawMessage,
    withdraw,
    threadId,
    isSender,
    downloadMessageFile,
    setActiveMessage,
    showMoreOptions,
    createFlagReport,
    errorMessage,
    isLastGroup,
    createdAt,
    seenBy,
    onImageLoaded,
  } = props;
  const afterSeenBy = seenBy.filter((x) => x.lastSeenMessageId === id);
  // find the most recent lastConsumptionTimestamp
  let lastConsumptionTimestamp = null;
  seenBy.forEach((item) => {
    if (lastConsumptionTimestamp) {
      const isafter = moment(item.lastConsumptionTimestamp).isAfter(lastConsumptionTimestamp);
      if (isafter) {
        lastConsumptionTimestamp = item.lastConsumptionTimestamp;
      }
    } else {
      lastConsumptionTimestamp = item.lastConsumptionTimestamp;
    }
  });
  const isAfterReadMessage = lastConsumptionTimestamp ? moment(createdAt).isAfter(lastConsumptionTimestamp) : true;
  const canDelete =
    isSender &&
    status !== messageStatus.SENDING &&
    status !== messageStatus.ERROR &&
    status !== messageStatus.TIM_ERROR;
  let withdrawHint = 'You withdrew a message';
  if (!isSender && withdraw) {
    const withdrawer = otherParticipants.filter((o) => o.userId === senderId).map((o) => o.user);
    withdrawHint = `${withdrawer[0].firstName} ${withdrawer[0].lastName} withdrew a message`;
  }
  if (type === messageTypes.FILE) {
    const file = JSON.parse(metadata);
    return (
      <div>
        {withdraw ? (
          <span className={css.withdrawHint}>{withdrawHint}</span>
        ) : (
          <span className={css.messageText}>
            <MessageFile
              id={id}
              file={file}
              isLast={isLast}
              status={status}
              percent={percent}
              uploadStatus={uploadStatus}
              retryMessage={retryMessage}
              downloadMessageFile={downloadMessageFile}
            />
            <div className={css.rightBottom}>
              {canDelete ? (
                <div className={css.messageAction}>
                  <IconButton
                    className={css.deleteButton}
                    iconSize={{ height: '1.5rem', width: '1.5rem' }}
                    icon="Delete"
                    onClick={() => withdrawMessage(id, threadId)}
                  />
                  {/* <IconButton
                  iconSize={{ height: '1.5rem', width: '1.5rem' }}
                  icon="Star"
                /> */}
                </div>
              ) : null}
              {isSender && (
                <Indicator
                  seenBy={afterSeenBy}
                  isAfterReadMessage={isAfterReadMessage}
                  status={status}
                  retry={() => retryMessage(id)}
                />
              )}
            </div>
          </span>
        )}
        {status === messageStatus.ERROR ? (
          <div className={css.error}>
            <span className={css.errorMessage}>
              <Markdown
                className={css.markdown}
                markdownString={errorMessage}
                components={{ link: LinkRenderer, text: TextRenderer }}
              />
            </span>
          </div>
        ) : null}
      </div>
    );
  } else if (type === messageTypes.IMAGE) {
    const image = JSON.parse(metadata);
    return (
      <div>
        {withdraw ? (
          <span className={css.withdrawHint}>{withdrawHint}</span>
        ) : (
          <span className={css.messageText}>
            <MessageImage
              id={id}
              image={image}
              isLast={isLast}
              status={status}
              percent={percent}
              uploadStatus={uploadStatus}
              retryMessage={retryMessage}
              downloadMessageFile={downloadMessageFile}
              onImageLoaded={onImageLoaded}
            />
            <div className={css.rightBottom}>
              {canDelete ? (
                <div className={css.messageAction}>
                  <IconButton
                    className={css.deleteButton}
                    iconSize={{ height: '1.5rem', width: '1.5rem' }}
                    icon="Delete"
                    onClick={() => withdrawMessage(id, threadId)}
                  />
                  {/* <IconButton
                  iconSize={{ height: '1.5rem', width: '1.5rem' }}
                  icon="Star"
                /> */}
                </div>
              ) : null}
              {isSender && (
                <Indicator
                  seenBy={afterSeenBy}
                  isAfterReadMessage={isAfterReadMessage}
                  status={status}
                  retry={() => retryMessage(id)}
                />
              )}
            </div>
          </span>
        )}
        {status === messageStatus.ERROR ? (
          <div className={css.error}>
            <span className={css.errorMessage}>
              <Markdown
                className={css.markdown}
                markdownString={errorMessage}
                components={{ link: LinkRenderer, text: TextRenderer }}
              />
            </span>
          </div>
        ) : null}
      </div>
    );
  } else if (type === messageTypes.BOT_INVITE || type === messageTypes.BOT_REMOVE) {
    return (
      <div>
        <span className={css.botMessage}>
          <Markdown
            className={css.markdown}
            markdownString={message}
            components={{ link: LinkRenderer, text: TextRenderer }}
          />
          <div className={css.rightBottom}>
            {isSender && (
              <Indicator
                seenBy={afterSeenBy}
                status={status}
                isAfterReadMessage={isAfterReadMessage}
                retry={() => retryMessage(id, message)}
                retryTim={() => retrySend(id, message)}
              />
            )}
          </div>
        </span>
      </div>
    );
  }
  const selectedClass = props.activeItem === props.id ? css.message_list_item_selected : '';
  const selectedOption = props.activeOption === props.id ? css.option_item_selected : '';
  const isLastOption = isLastGroup && isLast ? css.is_last : '';
  const liClasses = `${css.message_list_item} ${selectedClass}`;
  const optionClasses = `${css.hover_message_container} ${selectedOption} ${isLastOption}`;
  const onCancelModal = () => {
    setFlagModalOpen(false);
  };
  const openReportModal = (modalName) => {
    setActiveMessage('');
    showMoreOptions('');
    setFlagModalOpen(true);
    setOpenModalName(modalName);
  };
  const submitFlagReport = (flagReason) => {
    const messageId = props.id;
    const flaggedPersonUserId = props.senderId;
    const flagType = openModalName;
    if (openModalName === 'message') {
      createFlagReport(flaggedPersonUserId, flagType, flagReason, messageId);
    } else {
      createFlagReport(flaggedPersonUserId, flagType, flagReason, '');
    }
    setFlagModalOpen(false);
    showToaster();
  };
  return (
    <div
      className={liClasses}
      onMouseLeave={() => {
        setActiveMessage('');
        showMoreOptions('');
      }}
      onMouseEnter={() => {
        setActiveMessage(props.id);
        showMoreOptions('');
      }}
    >
      {isOpen ? (
        <FlagReportModal isOpen onCancel={onCancelModal} reportType={openModalName} submitReport={submitFlagReport} />
      ) : (
        ''
      )}
      {withdraw ? (
        <span className={css.withdrawHint}>{withdrawHint}</span>
      ) : (
        <span className={css.messageText}>
          <Markdown
            className={css.markdown}
            markdownString={message}
            components={{ link: LinkRenderer, text: TextRenderer }}
          />
          <div className={css.rightBottom}>
            {!isSender ? (
              <div className={css.show_more_options}>
                <div
                  role="button"
                  tabIndex={0}
                  className={css.more_icon}
                  onClick={() => showMoreOptions(props.id)}
                  onKeyDown={handleEnter(() => showMoreOptions(props.id))}
                >
                  <MoreHorizIcon />
                  <div className={optionClasses}>
                    <div
                      role="button"
                      tabIndex={0}
                      onClick={() => openReportModal('message')}
                      onKeyDown={handleEnter(() => openReportModal('message'))}
                    >
                      Report this message
                    </div>
                    <div
                      role="button"
                      tabIndex={0}
                      onClick={() => openReportModal('person')}
                      onKeyDown={handleEnter(() => openReportModal('person'))}
                    >
                      Report this person
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {canDelete ? (
              <div className={css.messageAction}>
                <IconButton
                  className={css.deleteButton}
                  iconSize={{ height: '1.5rem', width: '1.5rem' }}
                  icon="Delete"
                  onClick={() => withdrawMessage(id, threadId)}
                />
                {/* <IconButton
                iconSize={{ height: '1.5rem', width: '1.5rem' }}
                icon="Star"ƒ
              /> */}
              </div>
            ) : null}
            {isSender && (
              <Indicator
                seenBy={afterSeenBy}
                isAfterReadMessage={isAfterReadMessage}
                status={status}
                retry={() => retryMessage(id, message)}
                retryTim={() => retrySend(id, message)}
              />
            )}
          </div>
        </span>
      )}

      {status === messageStatus.ERROR ? (
        <div className={css.error}>
          <span className={css.errorMessage}>
            <Markdown
              className={css.markdown}
              markdownString={errorMessage}
              components={{ link: LinkRenderer, text: TextRenderer }}
            />
          </span>
        </div>
      ) : null}
    </div>
  );
}

Message.propTypes = {
  createdAt: PropTypes.string,
  message: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  retryMessage: PropTypes.func.isRequired,
  otherParticipants: PropTypes.array,
  status: PropTypes.string,
  isLast: PropTypes.bool,
  type: PropTypes.string,
  metadata: PropTypes.string,
  percent: PropTypes.number,
  uploadStatus: PropTypes.bool,
  retrySend: PropTypes.func,
  withdrawMessage: PropTypes.func,
  withdraw: PropTypes.bool,
  threadId: PropTypes.string,
  isSender: PropTypes.bool,
  senderId: PropTypes.string,
  downloadMessageFile: PropTypes.func,
  activeItem: PropTypes.string,
  setActiveMessage: PropTypes.func,
  showMoreOptions: PropTypes.func,
  activeOption: PropTypes.string,
  createFlagReport: PropTypes.func,
  errorMessage: PropTypes.string,
  isLastGroup: PropTypes.bool,
  seenBy: PropTypes.array,
  onImageLoaded: PropTypes.func,
};

LinkRenderer.propTypes = {
  href: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
};
