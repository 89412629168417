import React from 'react';
import { SectionTitle } from '../index.style';

type WithTitleProps = {
  title: string;
  children: React.ReactNode;
};

const WithTitle = ({ title, children }: WithTitleProps) => {
  return (
    <>
      <SectionTitle>{title}</SectionTitle>
      {children}
    </>
  );
};

export default WithTitle;
