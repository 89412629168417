import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import VeryHappy from 'components/atoms/graphics/Emojis/VeryHappy';
import Happy from 'components/atoms/graphics/Emojis/Happy';
import Neutral from 'components/atoms/graphics/Emojis/Neutral';
import Sad from 'components/atoms/graphics/Emojis/Sad';
import VerySad from 'components/atoms/graphics/Emojis/VerySad';
import { Body } from 'components/atoms/typography';

import fontColour from 'components/atoms/typography/fontColour.scss';
import css from './style.scss';

const getRelevantEmoji = (averageRating) => {
  switch (Math.floor(averageRating)) {
    case 1:
      return VerySad;
    case 2:
      return Sad;
    case 3:
      return Neutral;
    case 4:
      return Happy;
    case 5:
      return VeryHappy;
    default:
      return null;
  }
};

export default function AverageRatingBadge({ averageRating }) {
  const Emoji = getRelevantEmoji(averageRating);
  return (
    <div className={css.averageRatingBadge}>
      <Body className={classNames(css.averageRating, fontColour.secondary)}>
        {averageRating ? averageRating.toFixed(2) : '—'}
      </Body>
      {Emoji && <Emoji className={css.emoji} />}
    </div>
  );
}

AverageRatingBadge.propTypes = {
  averageRating: PropTypes.number,
};
