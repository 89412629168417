import React, { useState, useEffect, useRef } from 'react';
import Agenda from './AgendaEditor/index';
import Notes from './NotesEditor/index';
import styled, { css } from 'styled-components';
import { Close, Minimize } from '@material-ui/icons';
import { OpenInFull } from './Icon';
import { Tooltip } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';
import { useStylesBootstrap } from './style';
import { ModalReturnType, popupModalInfo } from '../Modal/commonModal';
import FloatingSessionConfirm from '../Modal/FloatingSessionConfirm';
import { SAVE_AGENDA, client } from 'graphql/api/agenda';
import { SET_NOTES } from 'graphql/api/sessionNotes';
import eventApi from 'graphql/api/event';
import moment from 'moment';
import {
  getEventParams,
  getFloatingSessionContext,
  getSessionItem,
  removeSessionItem,
  setSessionItem,
  trackFloatingSessionEvent,
} from './index.util';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Skeleton } from 'antd';
import { featureSwitches } from 'featureSwitches';

export const SvgCommonStyle = css`
  width: 1em;
  height: 1em;
  font-size: 1.5em;
  fill: #73747d; //stone
  &:hover {
    fill: #464ac9; //indigo
  }
`;

export const FloatingSessionMinimumContainer = styled.div`
  width: 260px;
  padding: 0px 16px;
  height: 40px;
  background: white;
  flex-direction: row;
  border: 1px solid #ccc;
  position: fixed;
  right: 50px;
  bottom: 0;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  z-index: 1200 !important;
  display: flex;
  align-items: center;
  flex-direction: flex-end;
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #1d1e2b;
`;

export const FloatingSessionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 600px;
  height: 600px;
  background: #f3f6fa;
  position: fixed;
  right: 48px;
  bottom: 0;
  z-index: 1200 !important;
  & .quill {
    width: 100%;
  }
  border-radius: 8px 8px 0px 0px;
  box-shadow: 1px -5px 50px rgb(115 116 125 / 25%);
`;

export const FloatingHeader = styled.div`
  display: flex;
  width: 100%;
  padding: 24px 16px 12px 32px;
  background: white;
  align-items: flex-start;
  border-radius: 8px 8px 0px 0px;
`;

export const FloatingContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  background: #f3f6fa;
  padding-top: 20px;
  width: 100%;
  height: 100%;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const SessionName = styled.div`
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: black;
  margin-bottom: 4px;
`;

export const SessionTime = styled.div`
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #73747d;
  margin-bottom: 8px;
`;

export const SessionInfo = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #a9acc0;
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  transform: translateY(-16px);
  cursor: pointer;
`;

const iconStyle = {
  height: 'auto',
  width: '24px',
  cusor: 'pointer',
  color: '#73747D',
};

export type ModeType = 'minimum' | 'maximum';

export type getParamsType = () => {
  userId: string;
  url: string;
};

const FloatingSession = ({
  onUnFloating,
  getParams,
}: {
  onUnFloating: (changeHistory: boolean) => void;
  getParams: getParamsType;
}): JSX.Element => {
  const FLOATING_SESSION = featureSwitches.FLOATING_SESSION();
  const [mode, setMode] = useState<ModeType>('maximum');
  const { sessionId, bookAsFirstName, bookAsUserId } = getFloatingSessionContext();
  const [agendaEdited, setAgendaEdited] = useState(false);
  const [notesEdited, setNotesEdited] = useState(false);
  const localNotes = getSessionItem({
    sessionId,
    type: 'notes',
  });
  const localAgenda = getSessionItem({
    sessionId,
    type: 'agenda',
  });
  const [isLoading, setIsLoading] = useState<boolean | undefined>(undefined);
  const [floatingNotesText, setFloatingNotesText] = useState(localNotes?.notes?.html || '');
  const [floatingAgendaText, setFloatingAgendaText] = useState(localAgenda?.agenda?.html || '');
  const classes = useStylesBootstrap();

  const [timeline, setTimeline] = useState<{
    start: string;
    end: string;
  }>({
    start: '',
    end: '',
  });

  const [sessionTitle, setSessionTitle] = useState('');

  useEffect(() => {
    async function getEventInfo() {
      const { name, start, end } = await eventApi.fetchEventById(sessionId, bookAsUserId);
      setSessionTitle(name);
      setTimeline({
        start,
        end,
      });
      setIsLoading(false);
    }
    if (sessionId) {
      setIsLoading(true);
      getEventInfo();
    }
  }, [sessionId]);

  const saveAgenda = async ({ id, html, version }: { id: string; html: string; version: string }) => {
    return client.query(SAVE_AGENDA, { eventId: `${sessionId}`, id, text: '', html, version, overwrite: true });
  };

  const saveNotes = async ({ version, html }: { html: string; version: string }) => {
    return client.query(SET_NOTES, { eventId: `${sessionId}`, version, overwrite: true, html, text: '' });
  };
  const onOpenFull = () => {
    setSessionItem({
      sessionId,
      type: 'agenda',
      item: {
        agenda: {
          html: floatingAgendaText,
          reFetch: false,
        },
      },
    });
    setSessionItem({
      sessionId,
      type: 'notes',
      item: {
        notes: {
          html: floatingNotesText,
          reFetch: false,
        },
      },
    });
    trackFloatingSessionEvent({
      key: 'FullScreen',
      metadata: getEventParams({ ...getParams() }),
    });
    onUnFloating(true);
  };
  const confirmModal = useRef<ModalReturnType | null>(null);
  const onClose = (changeHistory: boolean) => {
    if (agendaEdited) {
      removeSessionItem({
        sessionId,
        type: 'agenda',
      });
    }
    if (notesEdited) {
      removeSessionItem({
        sessionId,
        type: 'notes',
      });
    }
    confirmModal.current?.destroy();
    onUnFloating(changeHistory);
  };

  const onMinimize = () => {
    if (agendaEdited) {
      setSessionItem({
        sessionId,
        type: 'agenda',
        item: {
          agenda: {
            html: floatingAgendaText,
          },
        },
      });
    }
    if (notesEdited) {
      setSessionItem({
        sessionId,
        type: 'notes',
        item: {
          notes: {
            html: floatingNotesText,
          },
        },
      });
    }
    trackFloatingSessionEvent({
      key: 'Minimize',
      metadata: getEventParams({ ...getParams() }),
    });
    setMode('minimum');
  };

  const onCloseFloatingSession = () => {
    trackFloatingSessionEvent({
      key: 'Close',
      metadata: getEventParams({ ...getParams() }),
    });
    if (agendaEdited || notesEdited) {
      confirmModal.current = popupModalInfo({
        width: '500px',
        wrapClassName: 'component-crimson-app-modal',
        zIndex: 1600,
        bodyStyle: {
          padding: '60px 52px',
          width: '500px',
          height: '269px',
        },
        style: {
          background: '#000',
          borderRadius: '12px',
        },
        content: (
          <FloatingSessionConfirm
            onCancel={() => onClose(false)}
            onOk={async () => {
              const { id = '', version = '' } = getSessionItem({
                sessionId,
                type: 'agenda',
              });
              await saveAgenda({
                id,
                version,
                html: floatingAgendaText,
              });
              await saveNotes({
                version,
                html: floatingNotesText,
              });
              setSessionItem({
                sessionId,
                type: 'agenda',
                item: {
                  agenda: {
                    html: floatingAgendaText,
                    reFetch: true,
                  },
                },
              });
              setSessionItem({
                sessionId,
                type: 'notes',
                item: {
                  notes: {
                    html: floatingNotesText,
                    reFetch: true,
                  },
                },
              });
              trackFloatingSessionEvent({
                key: 'SaveNotes',
                metadata: getEventParams({ ...getParams() }),
              });
              trackFloatingSessionEvent({
                key: 'SaveAgenda',
                metadata: getEventParams({ ...getParams() }),
              });
              trackFloatingSessionEvent({
                key: 'Close',
                metadata: getEventParams({ ...getParams() }),
              });
              onClose(false);
            }}
          />
        ),
      });
    } else {
      onClose(false);
    }
  };
  return FLOATING_SESSION ? (
    <>
      {mode === 'maximum' && (
        <FloatingSessionContainer>
          <FloatingHeader>
            {isLoading ? (
              <ContentContainer>
                <Skeleton.Input
                  active={true}
                  size="default"
                  style={{
                    height: '24px',
                    marginBottom: '4px',
                    width: '300px',
                  }}
                />
                <Skeleton.Input
                  active={true}
                  size="default"
                  style={{
                    height: '16px',
                    marginBottom: '8px',
                    width: '200px',
                  }}
                />
                <Skeleton.Input
                  active={true}
                  size="default"
                  style={{
                    height: '20px',
                    width: '300px',
                  }}
                />
              </ContentContainer>
            ) : (
              <ContentContainer>
                <SessionName>{sessionTitle}</SessionName>
                <SessionTime>
                  {moment(timeline.start).format('ddd, MMM D h:mma')} -{' '}
                  {moment(timeline.start).date() === moment(timeline.end).date()
                    ? moment(timeline.end).format('h:mma')
                    : moment(timeline.end).format('ddd, MMM D h:mma')}
                </SessionTime>
                {bookAsFirstName && (
                  <SessionInfo>
                    <InfoOutlinedIcon
                      style={{
                        width: '20px',
                        height: '20px',
                        marginRight: '4px',
                      }}
                    />
                    You are viewing this session on behalf of {bookAsFirstName}.
                  </SessionInfo>
                )}
              </ContentContainer>
            )}
            <IconContainer>
              <Tooltip
                title="Minimize"
                interactive
                TransitionComponent={Zoom}
                placement="top"
                arrow={true}
                classes={classes}
              >
                <Minimize
                  style={{
                    ...iconStyle,
                    marginRight: '8px',
                  }}
                  onClick={onMinimize}
                />
              </Tooltip>
              <OpenInFull onClick={onOpenFull} />
              <Close
                style={{
                  ...iconStyle,
                  marginLeft: '8px',
                }}
                onClick={onCloseFloatingSession}
              />
            </IconContainer>
          </FloatingHeader>
          <FloatingContent>
            <Agenda
              getParams={getParams}
              setSessionEdited={setAgendaEdited}
              onSave={async () => {
                const { id = '', version = '' } = getSessionItem({
                  sessionId,
                  type: 'agenda',
                });
                await saveAgenda({
                  id,
                  version,
                  html: floatingAgendaText,
                });
                setSessionItem({
                  sessionId,
                  type: 'agenda',
                  item: {
                    agenda: {
                      html: floatingAgendaText,
                      reFetch: true,
                    },
                  },
                });
              }}
              setFloatingAgendaText={setFloatingAgendaText}
            />
            <Notes
              getParams={getParams}
              setSessionEdited={setNotesEdited}
              onSave={async () => {
                const { version = '' } = getSessionItem({
                  sessionId,
                  type: 'notes',
                });
                await saveNotes({
                  version,
                  html: floatingNotesText,
                });
                setSessionItem({
                  sessionId,
                  type: 'notes',
                  item: {
                    notes: {
                      html: floatingNotesText,
                      reFetch: true,
                    },
                  },
                });
              }}
              setFloatingNotesText={setFloatingNotesText}
            />
          </FloatingContent>
        </FloatingSessionContainer>
      )}
      {mode === 'minimum' && (
        <FloatingSessionMinimumContainer>
          Session Notes
          <IconContainer
            style={{
              transform: 'inherit',
            }}
          >
            <Tooltip
              title="Maximize"
              interactive
              TransitionComponent={Zoom}
              placement="top"
              arrow={true}
              classes={classes}
            >
              <Minimize
                style={{
                  ...iconStyle,
                  marginRight: '8px',
                  transform: 'rotate(180deg)',
                }}
                onClick={() => {
                  trackFloatingSessionEvent({
                    key: 'Maximise',
                    metadata: getEventParams({ ...getParams() }),
                  });
                  setMode('maximum');
                }}
              />
            </Tooltip>
            <OpenInFull onClick={onOpenFull} />
            <Close
              style={{
                ...iconStyle,
                marginLeft: '8px',
              }}
              onClick={onCloseFloatingSession}
            />
          </IconContainer>
        </FloatingSessionMinimumContainer>
      )}
    </>
  ) : (
    <></>
  );
};

export default FloatingSession;
