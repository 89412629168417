import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import Button from 'components/molecules/Button';
import { CommentField } from 'components/generic/Forms';
import uploadFileTypes from 'constants/uploadFileTypes';
import { File } from 'components/generic/Comment';

import css from './styles.scss';

export default class PostForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      message: '',
      file: null,
      fileRejected: false,
      isPosting: false,
    };

    this.handePostClicked = this.handePostClicked.bind(this);
    this.handleAddFileClicked = this.handleAddFileClicked.bind(this);
    this.handleChanged = this.handleChanged.bind(this);
    this.handleFileSelected = this.handleFileSelected.bind(this);
    this.handleFileRejected = this.handleFileRejected.bind(this);
    this.handleFileRemove = this.handleFileRemove.bind(this);
  }

  handleFileRejected() {
    this.setState({
      fileRejected: true,
    });
  }

  handleFileSelected(files) {
    this.setState({
      file: files[0],
      fileRejected: false,
    });
  }

  handleFileRemove() {
    this.setState({
      file: null,
    });
  }

  handePostClicked() {
    const { onPostComment, lessonDetails } = this.props;
    const { message, file } = this.state;

    if (!message || !message.trim()) {
      this.setState({
        message: '',
      });
      return;
    }

    this.setState({
      isPosting: true,
    });
    onPostComment(message, file, lessonDetails).then(() => {
      this.setState({
        message: '',
        file: null,
        fileRejected: false,
        isPosting: false,
      });
    });
  }

  handleAddFileClicked() {
    this.dropzone.open();
  }

  handleChanged(value) {
    this.setState({
      message: value,
    });
  }

  render() {
    const { message, file, isPosting } = this.state;
    return (
      <div>
        <CommentField
          className="formItemNoMargin"
          formInputClassName={css.postFormInput}
          id="message"
          placeholder="Start a conversation or upload a file here…"
          onValueChange={this.handleChanged}
          value={message}
          autoSize
        />
        <footer className={css.footer}>
          <div>
            <Dropzone
              id="messageFile"
              className={css.uploader}
              ref={(el) => {
                this.dropzone = el;
              }}
              multiple={false}
              maxSize={10 * 1024 * 1024}
              accept={[]
                .concat(
                  uploadFileTypes.fileTypes.image,
                  uploadFileTypes.fileTypes.text,
                  uploadFileTypes.fileTypes.pdf,
                  uploadFileTypes.fileTypes.word,
                  uploadFileTypes.fileTypes.excel,
                  uploadFileTypes.fileTypes.powerpoint,
                )
                .join(',')}
              onDropRejected={this.handleFileRejected}
              onDrop={this.handleFileSelected}
            />
            {file && (
              <File
                className={css.selectedFile}
                fileName={file.name}
                fileSize={file.size}
                onDeleteClick={this.handleFileRemove}
              />
            )}
          </div>
          <div>
            <Button secondary onClick={this.handleAddFileClicked}>
              Add File
            </Button>
            <Button primary disabled={!message || !message.trim()} loading={isPosting} onClick={this.handePostClicked}>
              {isPosting ? 'Posting...' : 'Post'}
            </Button>
          </div>
        </footer>
      </div>
    );
  }
}

PostForm.displayName = 'LessonCommentPostForm';

PostForm.propTypes = {
  onPostComment: PropTypes.func.isRequired,
  lessonDetails: PropTypes.object.isRequired,
};
