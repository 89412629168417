import componentKeys from 'constants/componentKeys';
import { updateMeta, LOCATION_CHANGE } from 'ducks/meta';

const appLoadingMiddleware = (store) => (next) => (action) => {
  if (action.type === LOCATION_CHANGE) {
    store.dispatch(updateMeta(componentKeys.THUNKS_REMAINING, []));
    store.dispatch(updateMeta(componentKeys.IS_PAGE_LOADING, false));
  }
  next(action);
};

export default appLoadingMiddleware;
