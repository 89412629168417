import React from 'react';

export default function Messaging() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#4F6987"
          d="M1.583 42.417h21.021a2.192 2.192 0 0 0-2.062-1.459H3.77a.729.729 0 0 1-.73-.729V24.188c0-.951-.609-1.762-1.458-2.063v20.292zm21.875 1.458H.854a.729.729 0 0 1-.729-.73V21.272c0-.403.327-.73.73-.73A3.65 3.65 0 0 1 4.5 24.188V39.5h16.042a3.65 3.65 0 0 1 3.645 3.646c0 .403-.326.729-.729.729zM43.875 43.86h-1.458V30.37c0-4.076-3.155-7.4-7.211-7.633a10.139 10.139 0 0 1-3.696.706 7.665 7.665 0 0 0-4.406 6.927v3.282h4.375v-6.563h1.459v7.196a.745.745 0 0 1-.115.497.744.744 0 0 1-.615.328h-5.833a.729.729 0 0 1-.73-.73v-4.01a9.123 9.123 0 0 1 5.422-8.328.727.727 0 0 1 .296-.063l.112.005h.006a8.693 8.693 0 0 0 3.323-.657.651.651 0 0 1 .306-.054 9.08 9.08 0 0 1 8.765 9.097v13.49"
        />
        <path
          fill="#4F6987"
          d="M27.104 43.875h-1.458v-3.646c0-.403.326-.729.729-.729h8.72a2.22 2.22 0 0 0 2.218-2.217V27.104h1.458v10.179a3.68 3.68 0 0 1-3.676 3.675h-7.99v2.917"
        />
        <path
          fill="#4F6987"
          d="M35.095 40.958H22a3.65 3.65 0 0 1-3.646-3.645A3.65 3.65 0 0 1 22 33.666h9.48v-6.563h1.457V34.3a.745.745 0 0 1-.114.497.744.744 0 0 1-.615.328H22a2.19 2.19 0 0 0-2.188 2.188A2.19 2.19 0 0 0 22 39.5h13.095a2.22 2.22 0 0 0 2.218-2.217V27.104h1.458v10.179a3.68 3.68 0 0 1-3.676 3.675M32.938 12.52a1.46 1.46 0 0 0-1.459 1.46 1.46 1.46 0 0 0 1.459 1.457 1.46 1.46 0 0 0 1.458-1.458 1.46 1.46 0 0 0-1.459-1.458zm0 4.376a2.92 2.92 0 0 1-2.917-2.917 2.92 2.92 0 0 1 2.916-2.916 2.92 2.92 0 0 1 2.917 2.916 2.92 2.92 0 0 1-2.916 2.917z"
        />
        <path
          fill="#4F6987"
          d="M32.938 9.604a4.38 4.38 0 0 0-4.376 4.375 4.38 4.38 0 0 0 4.375 4.375 4.38 4.38 0 0 0 4.376-4.375 4.38 4.38 0 0 0-4.376-4.375zm0 10.209a5.84 5.84 0 0 1-5.834-5.834 5.84 5.84 0 0 1 5.834-5.833 5.84 5.84 0 0 1 5.833 5.833 5.84 5.84 0 0 1-5.834 5.834z"
        />
        <path
          fill="#4F6987"
          d="M30.02 4.602c-4.154.698-7.29 4.322-7.29 8.617 0 4.825 3.924 8.75 8.75 8.75 4.824 0 8.75-3.925 8.75-8.75a8.726 8.726 0 0 0-4.376-7.58v3.703a.73.73 0 0 1-1.042.658 4.348 4.348 0 0 0-3.75 0 .73.73 0 0 1-1.041-.658v-4.74zm1.46 18.825c-5.63 0-10.21-4.58-10.21-10.208 0-5.367 4.2-9.838 9.559-10.177a.744.744 0 0 1 .625.285.73.73 0 0 1 .111.677 1.457 1.457 0 0 0-.086.465v3.833c.951-.25 1.966-.25 2.917 0V4.469a.73.73 0 0 1 1.01-.673 10.185 10.185 0 0 1 6.282 9.423c0 5.629-4.58 10.208-10.209 10.208zM8.146 27.104h10.208v-1.002a.73.73 0 0 1 .184-.485l2.567-2.888h-2.022a.729.729 0 0 1-.729-.729v-2.188H8.146v7.292zm10.937 1.459H7.417a.729.729 0 0 1-.73-.73v-8.75c0-.403.327-.729.73-.729h11.666c.403 0 .73.326.73.73v2.187h2.916a.728.728 0 0 1 .546 1.213l-3.462 3.895v1.454c0 .404-.327.73-.73.73zM3.042 8.875h2.187c.403 0 .73.326.73.73v2.165l1.925-2.6a.73.73 0 0 1 .586-.295h9.155V1.583H3.042v7.292zm2.187 5.833a.73.73 0 0 1-.729-.729v-3.646H2.312a.729.729 0 0 1-.729-.729V.854c0-.403.327-.729.73-.729h16.041c.403 0 .73.326.73.73v8.75c0 .402-.327.728-.73.728H8.837l-3.022 4.08a.733.733 0 0 1-.586.295z"
        />
        <path fill="#CD4B53" d="M9.604 22.73h7.292v-1.46H9.604zM9.604 25.646h7.292v-1.459H9.604z" />
        <path
          fill="#4F6987"
          d="M32.938 8.146c.495 0 .982.063 1.458.187V4.5a1.46 1.46 0 0 0-1.459-1.458A1.46 1.46 0 0 0 31.48 4.5v3.833a5.73 5.73 0 0 1 1.459-.187zm2.187 1.956a.736.736 0 0 1-.313-.07 4.348 4.348 0 0 0-3.75 0 .73.73 0 0 1-1.041-.659V4.5a2.92 2.92 0 0 1 2.916-2.917A2.92 2.92 0 0 1 35.855 4.5v4.873a.73.73 0 0 1-.729.73z"
        />
        <path fill="#CD4B53" d="M4.5 4.5h11.667V3.042H4.5zM4.5 7.417h2.188V5.958H4.5zM8.146 7.417h8.02V5.958h-8.02z" />
      </g>
    </svg>
  );
}
