import React from 'react';
import { AlertsContainer } from '../index.style';
import PanelWithTitle from '../PanelWithTitle';
import { AlertItem, Placeholder } from '../../components';
import { useStaffDashboardContext } from '../../helper';
import AlertsPanelLoadingView from './AlertsPanelLoadingView';
const AlertsPanel = () => {
  const { state, controller } = useStaffDashboardContext();
  const alerts = state.alerts[state.region];
  const isCalculating = state.isCalculating['alert'];
  const isLoading = state.isLoading;
  return (
    <PanelWithTitle title={'Alerts'} style={{ gap: '20px' }}>
      {isLoading && <AlertsPanelLoadingView />}
      {!isLoading && !!alerts.length && (
        <AlertsContainer>
          {alerts.map((item, idx) => (
            <AlertItem
              key={item.value + idx}
              onClick={() => controller.openAlertDetailModal(item.value)}
              title={item.label}
              dotColor={idx > 3 ? 'blue' : 'red'}
              count={item.userIds.length}
              titleStyle={{ color: '#73747D' }}
            />
          ))}
        </AlertsContainer>
      )}
      {!isLoading && !alerts.length && !isCalculating && (
        <Placeholder
          iconSize={90}
          containerStyle={{ flexDirection: 'column', gap: 20, marginBottom: 20 }}
          iconName={'01'}
          placeholderText={'No valid data yet.'}
        />
      )}
    </PanelWithTitle>
  );
};

export default AlertsPanel;
