import { connect } from 'react-redux';
import { PermissionTypes } from '@crimson-education/common-config';
import { selectUserProfile, selectUserPermissions, getPackageItemsFetched } from 'selectors/meta';
import { fetchMyPackageItems } from 'ducks/packageItem';
import { getAllSubjects } from 'selectors/packageItem';
import AuthorisationCheck from 'components/wrappers/AuthorisationCheck';
import MySubjects from './MySubjects';
import { getUserKeyContacts } from 'ducks/user';

const mapPropsToState = (state) => ({
  allSubjects: getAllSubjects(state).toJS(),
  loggedInUser: selectUserProfile(state).toJS(),
  loginUserPermissions: selectUserPermissions(state),
  packageItemsFetched: getPackageItemsFetched(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchMyPackageItems: () => dispatch(fetchMyPackageItems()),
  getUserKeyContacts: (userId) => dispatch(getUserKeyContacts(userId)),
});

const withAuthorisation = AuthorisationCheck(PermissionTypes.VIEW_MY_SUBJECTS);

export default withAuthorisation(connect(mapPropsToState, mapDispatchToProps)(MySubjects));
