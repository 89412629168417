import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/molecules/Modal';

import EulaText from './EulaText';
import css from './styles.scss';

class Eula extends PureComponent {
  render() {
    const { isOpen, onClose } = this.props;

    return (
      <Modal isOpen={isOpen} onClose={onClose} title="End-User license agreement">
        <div
          className={css.eulaText}
          dangerouslySetInnerHTML={{ __html: EulaText }} /* eslint-disable-line react/no-danger */
        />
      </Modal>
    );
  }
}

Eula.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default Eula;
