import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import autoBind from 'auto-bind';
import moment from 'moment';
import classNames from 'classnames';
import Immutable from 'immutable';
import debounce from 'lodash/debounce';
import { Acl, permissionTypes } from '@crimson-education/common-config';
import { trackEvent } from '@crimson-education/browser-logger';

import Autocomplete from 'components/molecules/Autocomplete';

import { Error } from 'components/atoms/icons';
import EventType from 'constants/eventType';
import modalStates from 'constants/modalStates';
import Modal from 'components/molecules/Modal';
import { createTimeObject } from 'utils/calendarUtils';
import { validateProperties, isNotEmpty, stringLengthRule } from 'utils/validation';
import { CommentField, InputField, MultiSelectDropdown, TimeSelect } from 'components/generic/Forms';
import { Caption } from 'components/atoms/typography';
import Datepicker from 'components/molecules/Datepicker';
import ModalBanner from 'components/molecules/ModalBanner';
import TextWithIcon from 'components/molecules/TextWithIcon';
import PeopleListItem from 'components/molecules/PeopleListItem';
import CriteriaTooltip from './../../../generic/CriteriaTooltip/';
import RecurringSession from './RecurringSession';
import { MessageEventType } from 'utils/MessageEventType';

import Helpers from './helpers';
import css from './styles.scss';

const TIME_INCREMENT = 15;

function roundTo15Minutes(time) {
  const momentTime = time ? moment(time) : moment();
  const minutes = momentTime.minute();
  const quarter = Math.round(minutes / 15) * 15;
  return momentTime.minute(quarter);
}

function getDefaultState(props) {
  const roundedStartTime = roundTo15Minutes(props.startTime);
  const roundedEndTime = roundTo15Minutes(props.endTime || moment(props.startTime).add(1, 'hours'));

  return {
    modalHeader: '',
    modalAlertMessage: props.bookingError || '',
    footerType: null,

    bookingWithUsers: props.bookingWithUsers,
    selectedUser: null,
    subjects: null,
    selectedSubject: null,

    dateValue: roundedStartTime, // start date
    endDateValue: roundTo15Minutes(props.endTime), // end date
    startTime: createTimeObject(roundedStartTime),
    endTime: createTimeObject(roundedEndTime),
    sessionName: '',
    comment: '',

    isDirty: false,
    validationErrors: {},

    modalAlert: null,
    overnight: false,
    selectedUserId: props.selectedUserId,

    toggle: false,
  };
}

function checkTimezoneDifference(selectedUser, bookAsUser) {
  if (!selectedUser || !bookAsUser) {
    return false;
  }
  if (!selectedUser.get('timezone') || !bookAsUser.get('timezone')) {
    return false;
  }
  return selectedUser.get('timezone') !== bookAsUser.get('timezone');
}

function getTimezoneDifference(t1, t2, anchor = null) {
  const anchorDate = anchor || moment.utc();
  const offset1 = moment.tz.zone(t1).utcOffset(anchorDate);
  const offset2 = moment.tz.zone(t2).utcOffset(anchorDate);
  const timezoneOffset = (offset1 - offset2) / 60;

  return timezoneOffset > 0 ? `+${timezoneOffset}` : timezoneOffset;
}

function renderTimezoneDifference(user, bookerTimeZone, userTimeZone, startTime, endTime) {
  const startDate = moment.tz(moment(startTime.time).clone(), bookerTimeZone).tz(userTimeZone);
  const endDate = moment.tz(moment(endTime.time).clone(), bookerTimeZone).tz(userTimeZone);

  const timeFormat = 'hh:mma';
  const dateFormat = 'Do MMMM YYYY';
  const isSameDay = startDate.isSame(endDate, 'day');
  const start = startDate.format(timeFormat);
  const end = endDate.format(timeFormat);
  const date = startDate.format(dateFormat);
  const endDateFormat = endDate.format(dateFormat);

  return (
    <div>
      <div>
        <strong>
          {user.get('firstName')} {user.get('lastName')}
        </strong>
        &#39;s session will take place from
      </div>
      {isSameDay ? (
        <div>
          <strong>{start}</strong> to <strong>{end}</strong> on <strong>{date}</strong> in their local time.
        </div>
      ) : (
        <div>
          <strong>
            {start} {date}
          </strong>{' '}
          to{' '}
          <strong>
            {end} {endDateFormat}
          </strong>{' '}
          in their local time.
        </div>
      )}
    </div>
  );
}

export default class RequestBookingModal extends Component {
  constructor(props) {
    super(props);

    this.state = getDefaultState(props);
    autoBind(this);
  }

  UNSAFE_componentWillMount() {
    const { userName } = this.props;
    if (userName && userName.length > 0) {
      this.props.loadMoreContacts({ text: userName[0] });
    } else {
      this.props.loadMoreContacts();
    }
    this.updateBookingForm(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { bookingError, bookingWithUsers } = nextProps;
    const { selectedUserId } = this.state;
    if (selectedUserId && !this.state.selectedUser) {
      const user = bookingWithUsers.find((user) => user.get('userId') === selectedUserId[0]);
      if (user) {
        this.handleUserSelect(user);
      }
    }

    // booking error from store
    this.setModalState(null, bookingError);
  }

  componentDidUpdate(prevProps) {
    // When the component updates you can now check against MODAL_LOADING
    // to check whether the modal should be in the loading state. This
    // would be useful when implementing a loading spinner as feedback to
    // the user that their booking action is being processed.
    // console.log(this.props.modalState === MODAL_LOADING);
    if (this.props.modalState === modalStates.Success && this.props.modalState !== prevProps.modalState) {
      this.props.onCancel(this.props.resetModal);
    }

    if (this.props.sessionRedirectId !== prevProps.sessionRedirectId) {
      this.props.history.push(`/session/${this.props.sessionRedirectId}`);
    }
  }

  componentWillUnmount() {
    this.props.clearBookingErrors();
  }

  onClearAutocomplete() {
    this.handleFilterChange('');
    this.setState({
      selectedUser: null,
      selectedUserId: null,
    });
  }

  getBookForMessage() {
    const { loginUser, bookAsUser } = this.props;

    return loginUser.get('userId') !== bookAsUser.get('userId') ? `For ${bookAsUser.get('fullName')}\n` : null;
  }

  setBookingFormStatus(booking) {
    const { loginUserPermissions } = this.props;

    const canBookFor = Acl.checkPermission(loginUserPermissions, permissionTypes.VIEW_ADMIN_CALENDAR);

    const setting = Helpers.getBookingFormSetting(booking, canBookFor);

    this.setModalState(setting.header, setting.message);

    this.setState({
      footerType: setting.footerType,
    });
  }

  setModalState(modalHeader, modalAlertMessage) {
    if (modalHeader) {
      this.setState({ modalHeader });
    }
    if (modalAlertMessage) {
      this.setState({
        modalAlertMessage,
        modalAlert: {
          message: modalAlertMessage,
        },
      });
    }
  }
  setToggle(boolean) {
    this.setState({ toggle: boolean });
  }

  updateBookingForm(props) {
    const { booking } = props;

    // reset state
    this.setState(getDefaultState(this.props));

    if (booking) {
      this.props.setFirstSessionDay(moment(booking.start));
      const dateValue = moment(booking.start);
      const endDateValue = moment(booking.end);
      let bookingStart = moment(booking.start);
      let bookingEnd = moment(booking.end);

      if (!bookingStart.isSame(bookingEnd, 'day')) {
        bookingEnd = {
          time: bookingEnd,
          hour: 24,
          minute: 0,
        };
      } else bookingEnd = createTimeObject(bookingEnd);

      bookingStart = createTimeObject(bookingStart);
      // normalize the subjects under a user to book with
      booking.otherUser.subjects = booking.subject ? [booking.subject] : null;

      this.setState({
        bookingWithUsers: Immutable.fromJS([booking.otherUser]),
        selectedUser: Immutable.fromJS(booking.otherUser),
        subjects: Immutable.fromJS(booking.otherUser.subjects),
        selectedSubject: booking.subject ? Immutable.fromJS(booking.subject) : null,
        dateValue,
        endDateValue,
        startTime: bookingStart,
        endTime: bookingEnd,
        sessionName: booking.name,
        comment: booking.description,
        bookingWithFilter: booking.otherUser && `${booking.otherUser.firstName} ${booking.otherUser.lastName}`,
      });
    }
    this.setBookingFormStatus(booking);
  }

  loadMoreUsers() {
    const { bookingWithPagination, loadMoreContacts } = this.props;
    if (bookingWithPagination.get('hasNextPage')) {
      const pagination = {
        pageNumber: bookingWithPagination.get('pageNumber') + 1,
        pageSize: bookingWithPagination.get('pageSize'),
      };
      loadMoreContacts({ text: this.state.bookingWithFilter }, pagination);
    }
  }

  handleFilterChange(val) {
    this.setState({ bookingWithFilter: val });
    if (!this.onHandleFilterChangeFunc) {
      this.onHandleFilterChangeFunc = debounce((val) => {
        this.props.loadMoreContacts({ text: val });
      }, 500);
    }
    this.onHandleFilterChangeFunc(val);
  }

  handleUserSelect(val) {
    if (!val) return;

    const { booking, bookAsUser, setSubjectId } = this.props;
    setSubjectId(null);

    if (val) {
      this.setState({
        selectedUser: val,
        bookingWithFilter: `${val.get('firstName')} ${val.get('lastName')}`,
      });

      if (!booking) {
        // only set subject and session name when make a new booking
        const subjects = val.get('subjects') || null;
        // no subject use user name, only one subject use subject name, multiple subject don't set session name
        let selectedSubject = null;
        let sessionName = '';

        if (!subjects) {
          sessionName = `Session between ${val.get('firstName')} and ${bookAsUser.get('firstName')}`;
        } else if (subjects.size === 1) {
          selectedSubject = subjects.get(0);
          sessionName = selectedSubject.get('name');
          setSubjectId(selectedSubject.get('id'));
        }

        this.setState({
          subjects,
          selectedSubject,
          sessionName,
        });
      }
    }
  }

  handleSubjectSelect(val) {
    const { booking, setSubjectId } = this.props;

    if (val) {
      this.setState({ selectedSubject: val });
      setSubjectId(val.get('id'));

      if (!booking) {
        // only set default session name when make a new booking
        this.setState({ sessionName: val.get('name') });
      }
    }
  }

  handleStartTimeChange(timeObj) {
    const proposedEnd = {
      time: timeObj.time.clone().add(1, 'hour'),
      hour: timeObj.hour + 1,
      minute: timeObj.minute,
    };

    const isEndTimeSameDay = proposedEnd.time.isSame(timeObj.time, 'day');

    this.setState({
      startTime: timeObj,
      endTime: isEndTimeSameDay
        ? proposedEnd
        : {
            time: timeObj.time.clone().startOf('day').add(1, 'day'),
            hour: 24,
            minute: 0,
          },
      isDirty: true,
    });
  }

  handleEndTimeChange(timeObj) {
    this.setState({ endTime: timeObj, isDirty: true });
  }

  handleSessionNameChange(value) {
    this.setState({ sessionName: value, isDirty: true });
  }

  handleCommentChange(value) {
    this.setState({ comment: value, isDirty: true });
  }

  handleDateChange(dateValue) {
    this.setState({ dateValue, isDirty: true });
    this.props.setFirstSessionDay(dateValue);
  }

  handleEndDateChange(endDateValue) {
    this.setState({ endDateValue, isDirty: true });
  }

  submitBooking() {
    const {
      bookAsUser,
      isEditMode,
      requestBooking,
      editBooking,
      booking,
      calculateStartEndTimeList,
      validateRecurringSettings,
      recurringSessionProps,
      requestRecurringBookings,
    } = this.props;

    const {
      selectedUser,
      subjects,
      selectedSubject,
      dateValue,
      endDateValue,
      startTime,
      endTime,
      sessionName,
      comment,
      overnight,
    } = this.state;

    const validationErrors = {};
    let invalidateSubjectStart = '';

    if (!selectedUser) {
      validationErrors.selectedUser = true;
    }

    if (subjects && subjects.size && !selectedSubject) {
      validationErrors.selectedSubject = true;
    }

    validateProperties({ sessionName }, [isNotEmpty, stringLengthRule(255)]).forEach((k) => {
      validationErrors[k] = true;
    });

    validateProperties({ comment }, [stringLengthRule(255)]).forEach((k) => {
      validationErrors[k] = true;
    });

    const start = dateValue.clone().set('hours', startTime.hour).set('minutes', startTime.minute);
    let end = null;
    if (overnight) {
      end = endDateValue.clone().set('hours', endTime.hour).set('minutes', endTime.minute);
    } else {
      end = dateValue.clone().set('hours', endTime.hour).set('minutes', endTime.minute);
    }

    if (end.isSameOrBefore(start)) validationErrors.endTime = true;

    if (selectedSubject && start.isBefore(moment(selectedSubject.get('start')).startOf('day'))) {
      validationErrors.startTime = true;
      invalidateSubjectStart = moment(selectedSubject.get('start')).startOf('day').format('L');
    }

    this.setState({
      validationErrors,
      invalidateSubjectStart,
    });

    if (Object.keys(validationErrors).length) return;

    const commonBookingData = {
      senderUserId: bookAsUser.get('userId'),
      receiverUserId: selectedUser.get('userId'),
      name: sessionName,
      description: comment,
      type: selectedSubject ? EventType.Lesson : EventType.Session,
      itemId: selectedSubject ? selectedSubject.get('id') : undefined,
    };

    if (recurringSessionProps.selectedType === 'recurring') {
      // recurring setting validations
      if (!validateRecurringSettings(end.diff(start, 'hours', true))) {
        return;
      }
      const recurringBooking = {
        ...commonBookingData,
        startEndTimeList: calculateStartEndTimeList(start, end),
        recurringFrequency: recurringSessionProps.frequency.value,
      };
      trackEvent({
        message: MessageEventType.InitRecurringSession,
      });
      requestRecurringBookings(recurringBooking);
    } else {
      const individualBooking = {
        ...commonBookingData,
        start: start.format(),
        end: end.format(),
      };
      isEditMode
        ? editBooking(Object.assign({}, individualBooking, { id: booking.id }))
        : requestBooking(individualBooking);
    }
  }

  dealWithOvernight() {
    this.setState({
      overnight: !this.state.overnight,
      endDateValue: moment(this.state.dateValue).add(1, 'days'),
    });
  }

  render() {
    const {
      bookAsUser,
      booking,
      isOpen,
      onCancel,
      bookingWithUsers,
      loginUser,
      modalState,
      isEditMode,
      onBack,
    } = this.props;
    const {
      subjects,
      selectedUser,
      selectedSubject,
      dateValue,
      startTime,
      endTime,
      sessionName,
      comment,
      modalHeader,
      validationErrors,
      invalidateSubjectStart,
      bookingWithFilter,
      modalAlert,
      endDateValue,
      overnight,
      toggle,
    } = this.state;

    if (!bookAsUser) {
      return null;
    }

    const isCreator = !booking || booking.creatorUserId === bookAsUser.get('userId');
    const isBookingOnBehalf = loginUser.get('userId') !== bookAsUser.get('userId');
    const isTimezoneDifferent = checkTimezoneDifference(selectedUser, bookAsUser);
    const isLoading = modalState === modalStates.Loading;
    const callToActionDataTestId = isEditMode ? 'updateSessionModalButton' : 'sendBookingRequestButton';
    const bookerTimeZone = bookAsUser.get('timezone');
    const selectedUserTimeZone = selectedUser && selectedUser.get('timezone');

    return (
      <Modal
        className={css.bookingModal}
        isOpen={isOpen}
        onClose={onCancel}
        onSubmit={this.submitBooking}
        title={modalHeader}
        submitText="Send request"
        onBack={isEditMode ? onBack : null}
        loading={isLoading}
        alert={modalAlert}
        onSubmitDataTestId={callToActionDataTestId}
      >
        <div className={css.front}>
          {this.getBookForMessage() ? <div className={css.bookForMessage}>{`${this.getBookForMessage()}`}</div> : null}
          <div className={css.modalBody}>
            <div className={css.bookingWithWrapper}>
              <Caption className={css.bookingWithLabel}>{isCreator ? 'Booking with' : 'Booking from'}</Caption>
              <Autocomplete
                getItemValue={(item) => `${item.firstName} ${item.lastName}`}
                items={bookingWithUsers.toJS()}
                value={bookingWithFilter}
                onSelect={(value, item) => this.handleUserSelect(Immutable.fromJS(item))}
                onChange={(event) => this.handleFilterChange(event.target.value)}
                itemOutputFormat={(item) => <PeopleListItem person={item} />}
                multiItemDataTestId={(item) => `${item.role}_${item.firstName}`}
                inputDataTestId="bookingWithUserInput"
                keySelector="userId"
                placeholder="Select a user to make booking with"
                disabled={!!booking}
                loadMore={this.loadMoreUsers}
                hasMore={this.props.bookingWithPagination.get('hasNextPage')}
                emptyText="No person available."
                onClear={this.onClearAutocomplete}
              />
              {validationErrors.selectedUser && <p className={css.errorMessage}>Select a user to make booking with.</p>}
            </div>
            {subjects && subjects.size && (
              <MultiSelectDropdown
                id="bookingSubjectId"
                dataTestId="bookingSubjectDropdown"
                className={classNames('formItemLessMargin', css.formGroup)}
                label="Subject"
                placeholder="Select subject"
                onValueChange={this.handleSubjectSelect}
                options={subjects}
                optionLabelSelector={(subject) => subject.get('name')}
                defaultValue={selectedSubject}
                error={validationErrors.selectedSubject}
                validationMessage="Select a subject"
              />
            )}
            <InputField
              id="bookingSessionName"
              dataTestId="bookingSessionNameInput"
              value={sessionName}
              label="Session name"
              placeholder="Add session name"
              className={classNames('formItemLessMargin', css.formGroup)}
              onValueChange={this.handleSessionNameChange}
              maxLength="255"
              error={validationErrors.sessionName}
              validationMessage="Add session name"
            />
            <div className={classNames('formItemLessMargin', css.formGroup)}>
              <div className={css.timespan}>
                <div className={classNames({ [css.showOvernight]: overnight === true })}>
                  <Datepicker
                    label="Date"
                    timezone={bookerTimeZone}
                    date={dateValue}
                    dataTestId="bookingDateInput"
                    onChange={this.handleDateChange}
                  />
                </div>
                {selectedSubject && validationErrors.startTime && (
                  <div className={classNames(css.validationMessage, css.onlyShowOnMobile)}>
                    This subject is due to start on {invalidateSubjectStart}, you can not make a booking before this
                    date.
                  </div>
                )}
                <TimeSelect
                  id="startTimeInput"
                  dataTestId="bookingStartTimeInput"
                  label="Start time"
                  date={dateValue}
                  time={startTime}
                  allowLastSlot={false}
                  className={css.timePicker}
                  onChange={this.handleStartTimeChange}
                  minuteIncrement={TIME_INCREMENT}
                  showCustomize={false}
                />
                {!overnight ? (
                  <TimeSelect
                    id="endTimeInput"
                    dataTestId="bookingEndTimeInput"
                    label="End time"
                    date={dateValue}
                    time={endTime}
                    allowFirstSlot={false}
                    className={css.timePicker}
                    onChange={this.handleEndTimeChange}
                    minuteIncrement={TIME_INCREMENT}
                    showOvernight={this.dealWithOvernight}
                    showCustomize
                  />
                ) : null}
              </div>
              {overnight ? (
                <div className={classNames(css.timespan, css.showOvernightTimespan)}>
                  <div className={css.showOvernight}>
                    <Datepicker
                      label="Date"
                      timezone={bookerTimeZone}
                      date={endDateValue}
                      dataTestId="bookingDateInput"
                      onChange={this.handleEndDateChange}
                    />
                  </div>
                  {selectedSubject && validationErrors.startTime && (
                    <div className={classNames(css.validationMessage, css.onlyShowOnMobile)}>
                      This subject is due to start on {invalidateSubjectStart}, you can not make a booking before this
                      date.
                    </div>
                  )}
                  <TimeSelect
                    id="endTimeInput"
                    dataTestId="bookingEndTimeInput"
                    label="End time"
                    date={endDateValue}
                    time={endTime}
                    allowFirstSlot={false}
                    className={css.timePicker}
                    onChange={this.handleEndTimeChange}
                    minuteIncrement={TIME_INCREMENT}
                  />
                </div>
              ) : null}

              {isTimezoneDifferent && !isBookingOnBehalf && (
                <TextWithIcon
                  className={css.textTimezoneOffset}
                  icon="Info"
                  iconSize="small"
                  text={`${this.state.selectedUser.get('fullName')} is ${getTimezoneDifference(
                    bookerTimeZone,
                    selectedUserTimeZone,
                    startTime.time,
                  )}hrs from your local time.`}
                />
              )}
              {selectedSubject && validationErrors.startTime && (
                <div className={classNames(css.validationMessage, css.onlyShowOnDesktop)}>
                  This subject is due to start on {invalidateSubjectStart}, you can not make a booking before this date.
                </div>
              )}
              {validationErrors.endTime && (
                <div className={css.validationMessage}>The end time must be after the start time.</div>
              )}
            </div>

            <RecurringSession {...this.props.recurringSessionProps} isEditMode={isEditMode} dataTestId="sessionType" />

            <CommentField
              id="commentArea"
              dataTestId="bookingCommentArea"
              className={classNames('formItemLessMargin', css.formGroup)}
              label="Leave a quick note"
              placeholder=""
              defaultValue={comment}
              onValueChange={this.handleCommentChange}
              maxLength="255"
            />

            <div style={{ float: 'left' }}>
              <Error style={{ fontSize: 'small', color: 'grey' }} />
            </div>
            <span style={{ float: 'left' }}>&nbsp;</span>
            <div style={{ float: 'left', fontSize: 'x-small' }}>
              <CriteriaTooltip
                isOpen={toggle}
                arrowSize={10}
                preferPlace="below"
                onMouseEnter={() => this.setToggle(true)}
                onMouseLeave={() => this.setToggle(false)}
                targetStyling={css.seeCriteria}
                targetText="Cancellation policy"
                body={[
                  '1. \tIf a student cancels a booking session 24 hours in advance, the student will not be penalized.',
                  '2. \tIf a student does not show up to a session for the first time, the student will not be penalized.',
                  '3. \tIf a student does not show up to a second session with the same tutor/mentor or cancels within 24 hours, 0.5 hours will be deducted from the students package of that specific subject.',
                ]}
              />
            </div>

            {isTimezoneDifferent && (
              <div style={{ clear: 'both' }}>
                <ModalBanner type="warning" className={css.timezoneWarning}>
                  <TextWithIcon
                    icon="AccessTime"
                    iconSize="small"
                    dataTestId="timezoneWarningText"
                    text={
                      <div className={css.timezoneWarningText}>
                        {/* Administrator booking for a Tutor, show Tutor's info */}
                        {isBookingOnBehalf &&
                          renderTimezoneDifference(bookAsUser, bookerTimeZone, bookerTimeZone, startTime, endTime)}
                        {/* Tutor booking for a Student, or Student booking for a Student, show Selected User's Info */}
                        {renderTimezoneDifference(
                          selectedUser,
                          bookerTimeZone,
                          selectedUserTimeZone,
                          startTime,
                          endTime,
                        )}
                      </div>
                    }
                  />
                </ModalBanner>
              </div>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

RequestBookingModal.propTypes = {
  loginUser: ImmutablePropTypes.map.isRequired,
  loginUserPermissions: PropTypes.array.isRequired,
  bookAsUser: ImmutablePropTypes.map.isRequired,
  booking: PropTypes.object,
  isOpen: PropTypes.bool,
  bookingError: PropTypes.string,
  clearBookingErrors: PropTypes.func,
  selectedUserId: PropTypes.array,
  userName: PropTypes.array,
  modalState: PropTypes.string,
  resetModal: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  requestBooking: PropTypes.func.isRequired,
  editBooking: PropTypes.func.isRequired,
  bookingWithUsers: ImmutablePropTypes.list,
  bookingWithPagination: ImmutablePropTypes.map.isRequired,
  loadMoreContacts: PropTypes.func.isRequired,
  setSubjectId: PropTypes.func.isRequired,
  recurringSessionProps: PropTypes.object.isRequired,
  setFirstSessionDay: PropTypes.func.isRequired,
  calculateStartEndTimeList: PropTypes.func.isRequired,
  requestRecurringBookings: PropTypes.func.isRequired,
  validateRecurringSettings: PropTypes.func.isRequired,
  onBack: PropTypes.func,
  isEditMode: PropTypes.bool,
  history: PropTypes.object,
  sessionRedirectId: PropTypes.string,
};
