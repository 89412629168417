import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import moment from 'moment';
import autoBind from 'auto-bind';

import {
  Acl,
  permissionTypes,
  eventStatusTypes,
  feedbackType,
  feedbackQuestionType,
  bookingStatusTypes,
  EventTypes,
} from '@crimson-education/common-config';

import attendanceStatus from 'constants/attendanceStatus';
import { createTimeObject, incrementTimeObject } from 'utils/calendarUtils';
import SessionConfirm from 'components/unique/SessionFeedback/Confirm';
import SessionAbsence from 'components/unique/SessionFeedback/Absence';
import SessionReportModal from 'components/organisms/SessionReportModal';
import eventType from 'constants/eventType';
import AbsenceModal from 'components/organisms/BookingFeedbackModals/AbsenceModal';
import AutoComplete from './AutoComplete';
import Confirm from './Confirm';
import Feedback from './Feedback';

export const LESSON_CONFIRM = 'LESSON_CONFIRM';
export const SESSION_CONFIRM = 'SESSION_CONFIRM';
export const LESSON_AUTOCOMPLETE = 'LESSON_AUTOCOMPLETE';
export const LESSON_FEEDBACK = 'LESSON_FEEDBACK';
export const SESSION_FEEDBACK = 'SESSION_FEEDBACK';
export const LESSON_ABSENCE = 'LESSON_ABSENCE';
export const SESSION_ABSENCE = 'SESSION_ABSENCE';
export const SESSION_REPORT = 'SESSION_REPORT';

// Deduplicate a time if it matches a disputed time, or the original time.
// Time may be undefined if being manually reset.
export function deduplicateTime(newTime, disputed, original) {
  if (!newTime) return newTime;
  if (disputed && newTime.time.isSame(disputed)) return undefined;
  if (!disputed && newTime.time.isSame(original)) return undefined;
  return newTime;
}

export default class FeedbackModals extends Component {
  constructor(props) {
    super(props);

    const isSessionReport =
      props.booking &&
      (props.booking.get('status') === bookingStatusTypes.COMPLETED ||
        props.booking.get('status') === bookingStatusTypes.AUTO_COMPLETED);

    this.state = {
      modalIsOpen: true,
      currentModal: isSessionReport ? SESSION_REPORT : LESSON_CONFIRM,
      adjustedReason: undefined,
      adjustedStart: undefined,
      adjustedEnd: undefined,
      isTutor: Acl.isTutor(props.loginUser.get('userRoles')),
      isStudent: Acl.isStudent(props.loginUser.get('userRoles')),
      isStrategist: Acl.isStrategist(props.loginUser.get('userRoles')),
      isEduCos: Acl.isCaseManager(props.loginUser.get('userRoles')),
      isStrategistOrEduCo:
        Acl.isStrategist(props.loginUser.get('userRoles')) || Acl.isCaseManager(props.loginUser.get('userRoles')),
    };

    autoBind(this);
  }

  componentDidMount() {
    const { reasonsForAbsence, fetchReasonsForAbsence, booking } = this.props;
    if (reasonsForAbsence && !reasonsForAbsence.length) {
      fetchReasonsForAbsence(booking.get('id'));
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      const isSessionReport =
        this.props.booking &&
        (this.props.booking.get('status') === bookingStatusTypes.COMPLETED ||
          this.props.booking.get('status') === bookingStatusTypes.AUTO_COMPLETED);
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState((state) => ({
        modalIsOpen: true,
        currentModal: isSessionReport ? SESSION_REPORT : state.currentModal,
        adjustedReason: undefined,
        adjustedStart: undefined,
        adjustedEnd: undefined,
        isTutor: Acl.isTutor(this.props.loginUser.get('userRoles')),
        isStudent: Acl.isStudent(this.props.loginUser.get('userRoles')),
        isStrategistOrEduCo:
          Acl.isStrategist(this.props.loginUser.get('userRoles')) ||
          Acl.isCaseManager(this.props.loginUser.get('userRoles')),
      }));
    }
  }

  onAdjustedTimeChange(givenStart, givenEnd) {
    const { booking } = this.props;
    const { adjustedReason, adjustedStart } = this.state;
    const originalStart = moment(booking.get('start'));
    const originalEnd = moment(booking.get('end'));
    const isTimeDisagreed = !booking.getIn(['attendance', 'status']) === attendanceStatus.DISAGREE;

    let reason = adjustedReason;
    let newStart = createTimeObject(originalStart);
    let newEnd = createTimeObject(originalEnd);
    let disputedStart;
    let disputedEnd;

    // Define define the new values with respect to original day
    if (givenStart) {
      newStart = givenStart;
      newStart.time = originalStart.clone().set('hour', givenStart.hour).set('minute', givenStart.minute);
    }
    if (givenEnd) {
      newEnd = givenEnd;
      // Clone the start time as bookings can currently only be on a single day
      newEnd.time = originalEnd.clone().set('hour', givenEnd.hour).set('minute', givenEnd.minute);
    }

    if (isTimeDisagreed) {
      disputedStart = booking.getIn(['attendance', 'start']);
      disputedEnd = booking.getIn(['attendance', 'end']);
    }

    // If the adjusted time matches the original or disputed time, clear it.
    const start = deduplicateTime(newStart, disputedStart, originalStart);
    let end = deduplicateTime(newEnd, disputedEnd, originalEnd);

    const isOriginalStart = adjustedStart && !start;
    const isStartDifferent =
      start && (!adjustedStart || start.hour !== adjustedStart.hour || start.minute !== adjustedStart.minute);

    // Unfortunately without significant refactor to the way
    // this component handles the changing of time, this is the 'simplest'
    // solution for now. In future, hoist the control over disputed/original/adjusted
    // times far, far away from anything that needs to handle the time selections.
    // I have been making changes in this area for nearly a week in an attempt to
    // make it easier to work with, however a good amount of time needs to be allocated
    // to re-writing this properly to make future changes less difficult.
    if (isOriginalStart || isStartDifferent) {
      end = incrementTimeObject(newStart);
    } else if (end) {
      // Handle adjustments when original end date is on/after midnight
      // In the future, the feedback modal event summary should support date pickers for start and end dates
      const originalEndTimeObject = createTimeObject(originalEnd);
      if (originalEndTimeObject.hour === 0 && end.hour > 0) {
        end = { ...end, time: moment(end.time).subtract(1, 'day') };
      }
    }

    // If both times have been adjusted back to original or disputed time,
    // clear the reason that has been provided.
    if (!(start || end)) reason = undefined;

    this.setState({
      adjustedStart: start,
      adjustedEnd: end,
      adjustedReason: reason,
    });
  }

  onAdjustedReasonChange(changeEvent) {
    let newText = changeEvent.target.value;
    if (newText === '') {
      newText = undefined;
    }

    this.setState({
      adjustedReason: newText,
    });
  }

  setModal(modalMode) {
    this.setState({
      currentModal: modalMode,
      modalIsOpen: true,
    });
  }

  // Use the autocomplete modal if the student's lesson has been autocompleted.
  getConfirmType() {
    const currentEventStatus = this.props.booking.get('eventStatus');
    const currentBookingStatus = this.props.booking.get('status');
    const studentRole = !(this.state.isTutor || this.state.isStrategist || this.state.isCaseManager);

    // If the event status is complete, but this booking still needs to be
    // completed, then we can deduce that the booking has been autocompleted.
    if (
      studentRole &&
      currentEventStatus === eventStatusTypes.COMPLETED &&
      currentBookingStatus === eventStatusTypes.CONFIRMED
    ) {
      return LESSON_AUTOCOMPLETE;
    }
    const strategistOrEduCosRole = this.state.isStrategist || this.state.isEduCos;
    if (strategistOrEduCosRole) {
      return LESSON_CONFIRM;
    }

    return this.props.booking.get('type') === eventType.Lesson ? LESSON_CONFIRM : SESSION_CONFIRM;
  }

  createBookingDetails() {
    const { adjustedStart, adjustedEnd, adjustedReason } = this.state;
    const { booking } = this.props;

    // Determine the start and end times to report.
    let start = moment(booking.get('start'));
    let end = moment(booking.get('end'));

    if (booking.getIn(['attendance', 'status']) === attendanceStatus.DISAGREE) {
      start = moment(booking.getIn(['attendance', 'start']));
      end = moment(booking.getIn(['attendance', 'end']));
    }

    if (adjustedStart) {
      start = adjustedStart;
    }

    if (adjustedEnd) {
      end = adjustedEnd;
    }

    return {
      eventId: booking.get('id'),
      otherUserId: booking.get('otherUserId'),
      start: createTimeObject(start).time.format(),
      end: createTimeObject(end).time.format(),
      reason: adjustedReason || '',
      status: adjustedStart || adjustedEnd ? attendanceStatus.DISAGREE : attendanceStatus.AGREE,
    };
  }

  submitBookingAbsence(eventId, comment, reason) {
    const request = {
      eventId,
      receiverId: this.props.booking.get('otherUserId'),
      comment,
      reason,
      booking: this.createBookingDetails(),
    };
    this.props.submitAbsence(request);
  }

  async submitSessionReport(studentId, tutorId, eventId, report, type = EventTypes.LESSON) {
    if ((this.state.isStrategist || this.state.isEduCos) && type === EventTypes.SESSION) {
      const request = {
        studentId,
        reporterId: tutorId,
        eventId: eventId.toString(),
        ...report,
      };
      await this.props.createSessionFeedbackReport(request);
    } else {
      const request = {
        studentId,
        tutorId,
        eventId,
        ...report,
      };

      await this.props.submitSessionReport(request);
    }

    this.setState({ currentModal: SESSION_CONFIRM });
  }

  submitAutoComplete(eventId) {
    this.props.submitAutoComplete(eventId);
  }

  submitAttendance() {
    const { isEduCos } = this.state;
    const bookingToSubmit = this.createBookingDetails();
    if (isEduCos) {
      bookingToSubmit.status = attendanceStatus.COMPLETE;
    }
    this.props.completeAttendanceBooking(bookingToSubmit);
  }

  render() {
    const { modalIsOpen, isTutor, isStudent, isStrategistOrEduCo } = this.state;
    const { booking, loginUser, reasonsForAbsence } = this.props;
    const reportVersion = booking.get('reportVersion');
    const attendance = booking.get('attendance');
    const otherUser = booking.get('otherUser');
    const personName = `${otherUser.get('firstName')} ${otherUser.get('lastName')}`;
    const alertMessage = `${otherUser.get('firstName')} updated the session time.`;

    // Determine if confirm or autocomplete should be shown.
    let { currentModal } = this.state;
    if (currentModal === LESSON_CONFIRM || currentModal === SESSION_CONFIRM) {
      currentModal = this.getConfirmType();
    }

    // Determine if an alert message should be shown.
    let showAlertMessage = false;
    if (attendance && attendance.get('status') === attendanceStatus.DISAGREE) {
      showAlertMessage = true;
    }

    // Select modal to display.
    if (currentModal === LESSON_CONFIRM) {
      return (
        <Confirm
          isOpen={modalIsOpen}
          booking={booking}
          personName={personName}
          isTutor={isTutor}
          isStrategistOrEduCo={isStrategistOrEduCo}
          adjustedStart={this.state.adjustedStart}
          adjustedEnd={this.state.adjustedEnd}
          adjustedReason={this.state.adjustedReason}
          onAdjustedTimeChange={this.onAdjustedTimeChange}
          onAdjustedReasonChange={this.onAdjustedReasonChange}
          alert={showAlertMessage ? { message: alertMessage } : null}
          onIncorrect={() => this.setModal(LESSON_ABSENCE)}
          onCorrect={() => {
            if (isTutor && Acl.checkPermission(loginUser.get('permissions'), permissionTypes.VIEW_SESSION_REPORT)) {
              this.submitAttendance();
            } else if (isStrategistOrEduCo) {
              this.submitAttendance();
            } else if (isStudent) {
              this.setModal(LESSON_FEEDBACK);
            }
          }}
        />
      );
    } else if (currentModal === SESSION_CONFIRM) {
      return (
        <SessionConfirm
          isOpen={modalIsOpen}
          booking={booking}
          personName={personName}
          onConfirmCorrect={() => {
            this.setModal(SESSION_FEEDBACK);
          }}
          onConfirmIncorrect={() => this.setModal(SESSION_ABSENCE)}
        />
      );
    } else if (currentModal === LESSON_AUTOCOMPLETE) {
      return (
        <AutoComplete
          booking={booking}
          personName={personName}
          alert={showAlertMessage ? { message: alertMessage } : null}
          onSubmit={() => this.submitAutoComplete(booking.get('id'))}
        />
      );
    } else if (currentModal === LESSON_FEEDBACK || currentModal === SESSION_FEEDBACK) {
      const b = booking && booking.toJS ? booking.toJS() : booking;

      if (!b) {
        return null;
      }

      const type = currentModal === LESSON_FEEDBACK ? feedbackType.LESSON_FEEDBACK : feedbackType.SESSION_FEEDBACK;

      const onBack =
        currentModal === SESSION_FEEDBACK ? () => this.setModal(SESSION_CONFIRM) : () => this.setModal(LESSON_CONFIRM);

      const onSubmit = async (answers, comment) => {
        const feedback = {
          eventId: b.id.toString(),
          feedbackType: type,
          receiverId: b.otherUserId,
          comment,
          answers,
          booking: this.createBookingDetails(),
        };

        this.props.submitSessionFeedback(feedback);
        onBack();
      };

      return <Feedback booking={b} onSubmit={onSubmit} isOpen={modalIsOpen} onBack={onBack} />;
    } else if (currentModal === SESSION_REPORT) {
      return (
        <SessionReportModal
          booking={booking}
          version={reportVersion}
          isOpen={modalIsOpen}
          isStraOrEdu={isStrategistOrEduCo}
          role={loginUser.get('userRoles')}
          onSubmit={this.submitSessionReport}
          eventId={booking.get('id')}
          tutorId={loginUser.get('userId')}
          studentId={booking.get('otherUserId')}
        />
      );
    } else if (currentModal === LESSON_ABSENCE) {
      return (
        <AbsenceModal
          reasonsForAbsence={reasonsForAbsence}
          eventId={booking.get('id')}
          isTutor={this.state.isTutor}
          onSubmit={this.submitBookingAbsence}
          onBack={() => this.setModal(LESSON_CONFIRM)}
        />
      );
    } else if (currentModal === SESSION_ABSENCE) {
      const submitSessionAbsence = (comment, reason) => {
        const eventId = booking.get('id');
        const answer = {
          questionType: feedbackQuestionType.ABSENCE_REASON,
          question: "Why didn't your session happen?",
          answer: reason,
        };

        if (comment) {
          answer.answer += ` - ${comment}`;
        }

        const request = {
          eventId,
          receiverId: this.props.booking.get('otherUserId'),
          senderId: this.props.loginUser.get('userId'),
          booking: this.createBookingDetails(),
          feedbackType: feedbackType.SESSION_ABSENCE,
          answers: [answer],
        };

        this.props.submitSessionFeedback(request);
      };

      return (
        <SessionAbsence
          onSubmit={submitSessionAbsence}
          onBack={() => {
            this.setModal(SESSION_CONFIRM);
          }}
        />
      );
    }

    // Render nothing if an unknown modal type is specified.
    return null;
  }
}

FeedbackModals.propTypes = {
  loginUser: ImmutablePropTypes.map.isRequired,
  booking: ImmutablePropTypes.map.isRequired,
  submitSessionFeedback: PropTypes.func.isRequired,
  submitAbsence: PropTypes.func.isRequired,
  submitAutoComplete: PropTypes.func.isRequired,
  submitSessionReport: PropTypes.func,
  completeAttendanceBooking: PropTypes.func,
  fetchReasonsForAbsence: PropTypes.func,
  reasonsForAbsence: PropTypes.array,
  createSessionFeedbackReport: PropTypes.func,
};
