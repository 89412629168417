import React, { useState, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { Content, StyledButton } from './index.style';
import { getEnvironmentConfig } from '@crimson-education/common-config/lib/environment';
import Axios from 'axios';
import { CallbackContainer } from 'components/unique/CallbackContainer';
const { api } = getEnvironmentConfig();
type SchoolConfirmationProps = {
  confirmType: 'approve' | 'reject';
  id: string;
  hashstr: string;
};

const invokeApprovalApi = async (id: string, confirmType: string, hashstr: string, feedback?: string) => {
  const url = `${api.endpoint}/applications/${id}/${confirmType}/${hashstr}`;
  const res = await Axios.post(url, {
    message: feedback,
  });
  return res;
};

const SchoolConfirmation = ({ confirmType, id, hashstr }: SchoolConfirmationProps) => {
  const isApprove = confirmType === 'approve';
  const [feedback, setFeedback] = useState('');
  const [isLoading, setLoading] = useState(true);
  const [isExpired, setExpired] = useState(false);
  const [feedbackSent, setFeedbackSent] = useState(false);
  const requestApprovalOrReject = useCallback(async () => {
    try {
      const res = await invokeApprovalApi(id, confirmType, hashstr, isApprove ? undefined : feedback);
      if (res.status !== 200) {
        setExpired(true);
      }
      return res;
    } catch (err) {
      setExpired(true);
    } finally {
      setLoading(false);
      if (!!feedback.length) setFeedbackSent(true);
    }
  }, [confirmType, id, hashstr, isApprove, feedback]);
  useEffect(() => {
    requestApprovalOrReject();
  }, []);
  const onClickSend = () => {
    if (!feedback.length) return;
    requestApprovalOrReject();
  };
  return (
    <CallbackContainer>
      <Content>
        <img
          src={'/static/applicationTracker/schoolCofirmationIllustration.png'}
          alt={'schoolCofirmationIllustration'}
        />
        {!isLoading && (
          <>
            <div className={'title'}>
              {isExpired
                ? 'Sorry, the link has expired.'
                : isApprove
                ? 'Thank you for confirming!'
                : 'Thank you for letting us know you have a different view!'}
            </div>
            {isApprove && (
              <div className={'content'}>
                {isExpired
                  ? 'Please double check if there is a new email with updated \nschool list in your inbox.'
                  : "We will be moving on to the next steps in the application process. \nIf you have any questions about the application process,\nplease contact your student's Crimson team."}
              </div>
            )}
            {!isApprove && !isExpired && !feedbackSent && (
              <>
                <div className={'input-container'}>
                  <textarea
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)}
                    maxLength={1000}
                    placeholder={
                      'Please let us know why you have a different view. Your Strategist will reach out shortly to discuss your feedback in detail.'
                    }
                  />
                  <StyledButton $disable={!feedback.length} onClick={onClickSend}>
                    {'Send'}
                  </StyledButton>
                  <div className={'word-count'}>{`${feedback.length}/1000`}</div>
                </div>
              </>
            )}
          </>
        )}
      </Content>
    </CallbackContainer>
  );
};

export const Render = (props: SchoolConfirmationProps) => {
  ReactDOM.render(<SchoolConfirmation {...props} />, document.getElementById('root'));
};
