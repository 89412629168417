import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Tag from 'components/molecules/Tag';
import { formatStatusLabel } from 'utils/invoiceUtils';
import textBehaviour from 'components/atoms/behaviours/text.scss';

import css from './styles.scss';

export default function InvoiceStatusTag({ status }) {
  const tagClassName = `${status.toLowerCase()}Invoice`;
  return (
    <Tag
      style={classNames(textBehaviour.truncateText, css[tagClassName], css.invoiceTag)}
      text={formatStatusLabel(status)}
    />
  );
}

InvoiceStatusTag.propTypes = {
  status: PropTypes.string,
};
