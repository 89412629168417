import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { FileDrop } from 'react-file-drop';
import classNames from 'classnames';
import autoBind from 'auto-bind';
import IconButton from 'components/molecules/IconButton';
import * as Logger from '@crimson-education/browser-logger';
import moment from 'moment';

import css from './style.scss';

const IMAGE_TYPE = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'];
const IMAGE_SIZE = 20;
const FILE_SIZE = 100;
function getDefaultState() {
  return {
    values: {},
    isSuccess: false,
    showErrorMessage: false,
    errorMessage: '',
  };
}

export default class UploadFileModal extends Component {
  constructor(props) {
    super(props);
    this.state = getDefaultState();
    this.filePicker = React.createRef();
    autoBind(this);
  }

  UNSAFE_componentWillReceiveProps() {
    this.setState(getDefaultState());
  }

  checkImageSize(file) {
    if (file.size / 1024 / 1024 > IMAGE_SIZE) {
      return true;
    }
    return false;
  }

  checkFileSize(file) {
    if (file.size / 1024 / 1024 > FILE_SIZE) {
      return true;
    }
    return false;
  }
  checkFile(file) {
    const imgRegexp = /^image\/\w/g;
    if (imgRegexp.test(file.type)) {
      if (!IMAGE_TYPE.includes(file.type)) {
        this.setState({
          showErrorMessage: true,
          errorMessage: 'Sorry, only image formats jpg, png, jpeg, gif can be sent.',
        });
        return false;
      }
      if (this.checkImageSize(file)) {
        this.setState({
          showErrorMessage: true,
          errorMessage: `Sorry, this image exceeds our maximum file size of ${IMAGE_SIZE}MB.`,
        });
        return false;
      }
    }
    if (this.checkFileSize(file)) {
      this.setState({
        showErrorMessage: true,
        errorMessage: `Sorry, this file exceeds our maximum file size of ${FILE_SIZE}MB.`,
      });
      return false;
    }
    this.setState({
      showErrorMessage: false,
      errorMessage: '',
    });
    this.trackEventFileSize(file);
    return true;
  }
  handleDropFile(files) {
    const { threadId, handleStatus, uploadFileThroughSTS, onUploadingDone } = this.props;
    const file = {
      blob: files[0],
    };
    if (this.checkFile(files[0])) {
      handleStatus();
      const startTime = moment();
      uploadFileThroughSTS(threadId, file, startTime).then((res) => {
        onUploadingDone && onUploadingDone(res);
      });
    }
  }

  handleSendFileClick() {
    this.filePicker.current.click();
  }

  handleFileClick(event) {
    const { threadId, handleStatus, uploadFileThroughSTS, onUploadingDone } = this.props;
    const files = event.target.files;
    const file = {
      blob: files[0],
    };
    if (this.checkFile(files[0])) {
      handleStatus();
      // uploadFile(file, '', 'message', threadId);
      const startTime = moment();
      uploadFileThroughSTS(threadId, file, startTime).then((res) => {
        onUploadingDone && onUploadingDone(res);
      });
    }
  }
  trackEventFileSize(file) {
    const fileSize = file.size;
    let metadata = {};
    if (fileSize) {
      metadata = {
        fileSize,
      };
      sessionStorage.setItem('uploadFileSize', fileSize);
    }
    Logger.trackEventSinceLastAction({ message: 'upload file start', metadata });
  }

  cancelUpload(upload) {
    const { handleUploadCancel, abortMultipartUpload } = this.props;
    // upload.cancel();
    abortMultipartUpload(upload.tempId);
    handleUploadCancel();
  }
  render() {
    const { isUploadFileModalOpen, uploads, handleCloseModal, uploaded, showUploadFile, showSuccess } = this.props;

    const { showErrorMessage } = this.state;
    const successCompController = typeof showSuccess === 'boolean' ? showSuccess : uploaded.length > 0;
    return (
      isUploadFileModalOpen && (
        <ReactModal
          data={{ 'data-test-id': 'uploadFileModalTest' }}
          isOpen={isUploadFileModalOpen}
          overlayClassName={css.overlay}
          className={showUploadFile ? css.uploadFileModal : css.uploadingFileModal}
          onRequestClose={handleCloseModal}
          shouldCloseOnOverlayClick
        >
          <input
            type="file"
            id="filePicker"
            onChange={this.handleFileClick}
            ref={this.filePicker}
            style={{ display: 'none' }}
          />
          <div className={css.uploadDiv}>
            <FileDrop
              className={css['file-drop']}
              targetClassName={css['file-drop-target']}
              draggingOverFrameClassName={css['file-drop-dragging-over-frame']}
              draggingOverTargetClassName={css['file-drop-dragging-over-target']}
              onDrop={(files) => this.handleDropFile(files)}
            >
              {showUploadFile ? (
                <div>
                  <img src="/static/fileUpload.svg" alt="File Upload Icon" />
                  <div className={css.uploadFileTitle}>Upload File Or Image</div>
                  <div className={css.dropFileHere}>
                    Drop your file/image here or
                    <button className={css.clickButton} onClick={this.handleSendFileClick}>
                      browse
                    </button>
                  </div>
                  {showErrorMessage ? <span className={css.errorMessage}>{this.state.errorMessage}</span> : null}
                </div>
              ) : (
                <div className={css.uploading}>
                  {uploads.map((file) => {
                    const upload = file.toJS();
                    const isError = !!upload.error;
                    let styleWidth = upload.uploadPercentage;
                    if (isError) {
                      styleWidth = 100;
                    }
                    return (
                      <div key={upload.tempId}>
                        <div className={css.uploadFileTitle}>Uploading Files</div>
                        <div className={classNames(css.loadingBarOverlay, { [css.loadingError]: isError })}>
                          <div
                            className={classNames(css.loadingBar, { [css.loadingError]: isError })}
                            style={{ width: `${styleWidth}%` }}
                          >
                            <span className={css.fileName}>{upload.fileName}</span>
                            {isError && <span className={css.fileName}>{upload.error}</span>}
                            <span className={css.uploadingPercentage}>{upload.uploadPercentage}%</span>
                          </div>
                          <IconButton
                            className={css.clearFileButton}
                            icon="Clear"
                            iconSize={{ height: '1.5rem', width: '1.5rem' }}
                            onClick={() => this.cancelUpload(upload)}
                          />
                        </div>
                      </div>
                    );
                  })}
                  {successCompController ? (
                    <div className={css.fileUoloadSuccess}>
                      <img src="/static/Tick.svg" alt="Success Icon" />
                      <div className={css.fileUploadSuccessText}>File uploaded successfully!</div>
                    </div>
                  ) : null}
                </div>
              )}
            </FileDrop>
          </div>
        </ReactModal>
      )
    );
  }
}

UploadFileModal.propTypes = {
  isUploadFileModalOpen: PropTypes.bool,
  uploads: PropTypes.array,
  handleCloseModal: PropTypes.func,
  uploaded: PropTypes.array,
  threadId: PropTypes.string,
  showUploadFile: PropTypes.bool,
  handleStatus: PropTypes.func,
  handleUploadCancel: PropTypes.func,
  uploadFileThroughSTS: PropTypes.func,
  abortMultipartUpload: PropTypes.func,
  onUploadingDone: PropTypes.func,
  showSuccess: PropTypes.bool,
};
