import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';
import { gql } from '@apollo/client';
import { createClient } from '../utils';

const { api } = getConfig();

const client = createClient(api.graphQLEndpoint);

const note = gql`
  fragment note on noteType {
    id
    eventId
    text
    createdBy
    createdAt
    updatedAt
    html
    version
  }
`;

export const GET_NOTES_BY_EVENT = gql`
  ${note}
  query getNotesByEvent($eventId: String!) {
    sessionNote(eventId: $eventId) {
      ...note
    }
  }
`;

export const SET_NOTES = gql`
  ${note}
  mutation setNotes($eventId: String!, $version: Int, $overwrite: Boolean, $text: String, $html: String) {
    setSessionNote(eventId: $eventId, version: $version, overwrite: $overwrite, text: $text, html: $html) {
      ...note
    }
  }
`;

export default {
  getNotesByEvent: async (eventId) => {
    return client.query(GET_NOTES_BY_EVENT, { eventId });
  },
  setNotes: async (eventId, version, overwrite, notes) => {
    return client.query(SET_NOTES, { eventId, version, overwrite, ...notes });
  },
};
