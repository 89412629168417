import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import IconButton from 'components/molecules/IconButton';
import css from './style.scss';
import { handleEnter } from '../../../utils/accessibility';

export default function CreateMessageButton(props) {
  const { onClick, className, dataTestId } = props;

  return (
    <div
      className={classNames(className, css.createMessageButton)}
      data-test-id={dataTestId}
      onClick={onClick}
      onKeyDown={handleEnter(onClick)}
      role="button"
      tabIndex={0}
      data-ga-label="icon - createMessage"
    >
      {/* <CreateMessageIcon /> */}
      <IconButton icon="Create" className={css.createMessageIcon} />
    </div>
  );
}

CreateMessageButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
};
