import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Card from 'components/molecules/Card';
import SubjectSection from 'components/molecules/CardSection/Subject';
import UserSection from 'components/molecules/CardSection/User';
import Body from 'components/atoms/typography/Body';
import AccountCircle from '@material-ui/icons/AccountCircle';

import css from './styles.scss';
import { Button } from '@material-ui/core';
import { FeatureSwitchContext } from 'featureSwitches';
import oms from 'graphql/oms';

class SubjectCard extends Component {
  constructor(props) {
    super(props);
    this.getParentSubjectName = this.getParentSubjectName.bind(this);
    this.closePurchasedItem = this.closePurchasedItem.bind(this);
  }

  componentDidMount() {
    this.props.getAncestorsOfSubjectTree([this.props.subject.subjectId]);
    this.closePurchasedItem();
  }

  getParentSubjectName(subjectId) {
    if (!subjectId) return '';
    const category = this.props.subjectCategories[subjectId];
    if (!category) return '';
    const parentId = category.parentId;
    if (!parentId) return '';

    return this.props.subjectCategories[parentId].name;
  }

  closePurchasedItem() {
    const lastPurchase = this.props.subject.lastPurchase;
    if (lastPurchase && lastPurchase.status === 'CANCELLED') {
      oms.closeUserOrder(lastPurchase.id);
    }
  }

  render() {
    const { subject, hasPendingOrder } = this.props;
    const subjectName = this.getParentSubjectName(subject.subjectId);
    const showAddMore = !hasPendingOrder && (!subject.lastPurchase || subject.lastPurchase.status !== 'PENDING');
    const expiredLastPurchase = !hasPendingOrder && subject.lastPurchase && subject.lastPurchase.status === 'CANCELLED';
    const lastPurchasePending = hasPendingOrder || (subject.lastPurchase && subject.lastPurchase.status === 'PENDING');

    return (
      <FeatureSwitchContext.Consumer>
        {({ featureSwitches }) => {
          const canPurchaseHours = featureSwitches.IN_APP_PURCHASES && subject.upsellAvailable && !subject.isUnlimited;
          return (
            <Card className={css.container}>
              <SubjectSection subject={subject} parentSubject={subjectName} />
              {subject.tutors.length ? <UserSection user={subject.tutors[0].user} roleText="Tutor" /> : null}
              {!subject.tutors.length || canPurchaseHours ? (
                <div className={css.cardFooter}>
                  {!subject.tutors.length ? (
                    <div className={css.footerSpan}>
                      <Body className={css.text}>Tutor pending</Body>
                      <div className={css.icon}>
                        <AccountCircle style={{ width: '1.4rem', height: '1.4rem' }} />
                      </div>
                    </div>
                  ) : null}

                  {canPurchaseHours ? (
                    <div className={css.footerSecondary}>
                      {expiredLastPurchase ? <span className={css.footerSecondaryText}>Request Expired</span> : null}
                      {lastPurchasePending ? <span className={css.footerSecondaryText}>Pending Request</span> : null}
                      {showAddMore ? <Button onClick={this.props.onClickAddMoreHours}>Add More Hours</Button> : null}
                    </div>
                  ) : null}
                </div>
              ) : null}
            </Card>
          );
        }}
      </FeatureSwitchContext.Consumer>
    );
  }
}

SubjectCard.propTypes = {
  subject: PropTypes.object,
  subjectCategories: PropTypes.object,
  getAncestorsOfSubjectTree: PropTypes.func,
  onClickAddMoreHours: PropTypes.func,
  hasPendingOrder: PropTypes.bool,
};

export default SubjectCard;
