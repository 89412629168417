import React from 'react';
import ReactDOM from 'react-dom';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { createBrowserHistory } from 'history';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import moment from 'moment';
import 'antd/dist/antd.variable.css';
import 'autotrack/lib/plugins/url-change-tracker';
import 'autotrack/lib/plugins/event-tracker';

import 'serviceProvider';

import rootReducer from 'ducks';
import configureMiddleware from 'middleware';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ReduxToastr from 'react-redux-toastr';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Modal from 'react-modal';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import 'styles/toastr.scss'; // put toastr override css here to immediately override the default style imported above

import { subscribeServiceWorker } from 'middleware/serviceWorkerMiddleware';
import AppRouter from './routes';

import AppContext from './components/enhancers/AppContext';
import { FeatureToggle, useIdentifyUser, FeatureSwitchEnhancer } from './featureSwitches';
import { selectUserId } from 'selectors/meta';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import ConfigProvider from 'antd/lib/config-provider';
ConfigProvider.config({
  theme: {
    primaryColor: '#464ac9',
  },
});
moment.locale(navigator.language || navigator.languages[0]);

export const history = createBrowserHistory();

// create Redux store
const middlewares = configureMiddleware(history);

const composeEnhancers: any = window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__' as any] || compose; // eslint-disable-line no-underscore-dangle

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middlewares)));

store.dispatch(subscribeServiceWorker());

Modal.setAppElement('#root');

const SetUpLaunchDarklyUser = () => {
  const userId = useSelector(selectUserId);
  const identifyUser = useIdentifyUser();
  useEffect(() => {
    if (userId) {
      identifyUser(userId);
    }
  }, [userId, identifyUser]);
  return null;
};
const RootApp = () => {
  return (
    <Provider store={store}>
      <AppContext>
        <ConfigProvider>
          <div>
            <FeatureToggle />
            <AppRouter history={history} />
            <ReduxToastr
              position="top-center"
              transitionIn="fadeIn"
              transitionOut="fadeOut"
              timeOut={5000}
              progressBar={false}
              newestOnTop
            />
            <SetUpLaunchDarklyUser />
          </div>
        </ConfigProvider>
      </AppContext>
    </Provider>
  );
};

const Comp = FeatureSwitchEnhancer(RootApp);
ReactDOM.render(<Comp />, document.getElementById('root'));
