import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { InverseLogo, LogoFull } from 'components/atoms/graphics';
import Avatar from 'components/molecules/Avatar';
import { getAvatarColour } from 'utils/calendarUtils';
import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';

import css from './Header.scss';

const config = getConfig();

export default function Header(props) {
  const { isMobile, user, toggleMenu, showMenu } = props;
  const name = `${user.firstName} ${user.lastName}`;
  const userLink = `/users/${user.userId}`;

  return isMobile ? (
    <div className={css.header}>
      <Link to={userLink} className={css.link} onClick={() => toggleMenu(false)}>
        <Avatar
          firstName={user.firstName}
          lastName={user.lastName}
          image={user.profileImageUrl}
          colourIndex={getAvatarColour(user.userId)}
          className={css.avatar}
        />
        <span className={css.title}>{name}</span>
      </Link>
    </div>
  ) : (
    <div className={classNames(css.fixedHamburger, { [css.menuExpanded]: showMenu })}>
      <div className={css.hamburgerContainer}>
        {config.isDulwich && (
          <img
            className={classNames(css.crimsonLogo, css.dulwichLogo)}
            src="https://static.crimsoneduchina.cn/dulwich_full.svg"
            alt="Dulwich Logo"
          />
        )}
        {!config.isDulwich && (
          <React.Fragment>
            {showMenu ? <LogoFull className={css.crimsonLogo} /> : <InverseLogo className={css.crimsonLogo} />}
          </React.Fragment>
        )}
        <i className={classNames('zmdi zmdi-chevron-left', css.closeMenu)} />
      </div>
    </div>
  );
}

Header.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  showMenu: PropTypes.bool.isRequired,
};
