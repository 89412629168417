import TIM from 'tim-js-sdk';
import COS from 'cos-js-sdk-v5';
import moment from 'moment-timezone';
import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';
import * as Logger from '@crimson-education/browser-logger';
import { getBearer } from '../utils/auth/auth';
import { MessageEventType } from '../utils/MessageEventType';

const { api, timConfig } = getConfig();

const options = {
  SDKAppID: timConfig.sdkAppId,
};
const tim = TIM.create(options);

// tim.setLogLevel(0); // normal level, large log
tim.setLogLevel(1); // release level, output the key information, production

tim.registerPlugin({ 'cos-js-sdk': COS });

export async function loginToIM(userId) {
  const timUserId = `${timConfig.source}-${userId}`;
  const response = await fetch(`${api.endpoint}/getTimSig/${timUserId}`, {
    method: 'get',
    headers: new Headers({
      Authorization: `Bearer ${getBearer()}`,
    }),
  });
  const signature = await response.json();
  tim.login({ userID: `${timConfig.source}-${userId}`, userSig: signature.sig });
}

function retry(fn, retryFn, errFn, retries = 3, err = null) {
  if (!retries) {
    return errFn(err);
  }
  return fn().catch((err) => {
    return retry(retryFn, retryFn, errFn, retries - 1, err);
  });
}

export const sendMessageC2C = (recipient, content, currentUserId) => {
  const eventInfo = {
    from: currentUserId,
    to: recipient,
    threadId: content.threadId,
    type: content.type,
    errorMessage: '',
  };
  try {
    const message = tim.createCustomMessage({
      to: `${timConfig.source}-${recipient}`,
      conversationType: TIM.TYPES.CONV_C2C,
      payload: {
        data: JSON.stringify(content),
      },
    });
    retry(
      () => tim.sendMessage(message),
      () => tim.resendMessage(message),
      (imError) => {
        eventInfo.errorMessage = imError;
        if (content.type === 'consume') {
          Logger.trackEvent({ message: MessageEventType.TIMSendConsumeMessageError, metadata: eventInfo });
        }
      },
    );
  } catch (error) {
    eventInfo.errorMessage = error.message;
    if (content.type === 'consume') {
      Logger.trackEvent({ message: MessageEventType.TIMSendConsumeMessageError, metadata: eventInfo });
    }
    loginToIM(currentUserId);
  }
};

export const createGroupIM = (threadId, userId) => {
  try {
    tim
      .createGroup({
        type: TIM.TYPES.GRP_PRIVATE,
        groupID: `${threadId}`,
        name: `${userId}`,
      })
      .catch((imError) => {
        // eslint-disable-next-line no-console
        console.log(imError);
      });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Ops! Some error happens when create group:', error.message);
    loginToIM(userId);
  }
};

export const logutFromIM = () => {
  tim.updateMyProfile({
    nick: 'offline',
    profileCustomField: [
      {
        key: 'Tag_Profile_Custom_status',
        value: 'offline',
      },
    ],
  });
  tim.logout();
};

const onSdkReady = () => {
  tim.updateMyProfile({
    nick: 'online',
    profileCustomField: [
      {
        key: 'Tag_Profile_Custom_status',
        value: 'online',
      },
    ],
  });
};
tim.on(TIM.EVENT.SDK_READY, onSdkReady);

const onNetStateChange = (event) => {
  if (event.data.state === 'disconnected') {
    // tim.logout();
  } else {
    tim.login();
  }
};

tim.on(TIM.EVENT.NET_STATE_CHANGE, onNetStateChange);

export default tim;
