import { connect } from 'react-redux';
import { getReasonsForCancellation } from 'selectors/booking';
import { getBookingAs } from 'selectors/user';
import { showPageLoader } from 'ducks/meta';
import { cancelBooking } from 'ducks/booking';
import CancelConfirmedSessionModal from './CancelConfirmedSessionModal';

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch, ownProps) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CancelConfirmedSessionModal);
