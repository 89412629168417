import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Tabs from 'components/molecules/Tabs';
import eventApi from 'graphql/api/event';
import SessionList from './SessionList';
import css from './styles.scss';

const SWITCH_OPTIONS = ['Upcoming', 'Past'];
const PAGE_SIZE = 10;
export default function SessionScheduleView({ userId }) {
  const [isReverse, setIsReverse] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [bookings, setBookings] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [refetch, setRefetch] = useState(false);

  function initPagination(tabIndex) {
    setBookings([]);
    setPageNumber(1);
    setHasMore(true);
    setIsReverse(SWITCH_OPTIONS[tabIndex] === SWITCH_OPTIONS[1]);
  }

  useEffect(() => {
    async function getBookings() {
      setIsLoading(true);
      const bookingsData = await eventApi.fetchEventSchedule({
        userId,
        isReverse,
        pagination: { pageSize: PAGE_SIZE, pageNumber },
      });
      setIsLoading(false);
      if (bookingsData) {
        if (bookingsData.length > 0) {
          setBookings((bookings) => [...bookings, ...bookingsData]);
        }
        if (bookingsData.length !== PAGE_SIZE) {
          setHasMore(false);
        }
      }
    }
    if (hasMore) {
      getBookings();
    }
  }, [isReverse, eventApi, userId, pageNumber, setHasMore, hasMore]);

  useEffect(() => {
    if (refetch) {
      initPagination(0);
      setRefetch(false);
    }
  }, [refetch]);

  return (
    <div className={css.schedule}>
      <div className={css.timeTabWrapper}>
        <Tabs
          onTabClick={(tabIndex) => initPagination(tabIndex)}
          defaultActive={0}
          tabs={SWITCH_OPTIONS}
          className={css.timeTab}
          disabled={isLoading}
        />
      </div>
      <SessionList
        userId={userId}
        isPast={isReverse}
        bookings={bookings}
        loadMore={() => setPageNumber(pageNumber + 1)}
        hasMore={hasMore}
        setRefetchSessions={setRefetch}
      />
    </div>
  );
}

SessionScheduleView.propTypes = {
  userId: PropTypes.string,
};
