import moment from 'moment';
import { getSelectedDay, getSelectedSpecificDay } from 'selectors/meta';

// Helpers.
// Retrieve all people who are selected in the given map.
export function isSelected(people) {
  return people.filter((person) => person.get('isSelected')).toList();
}

export function getThisWeek(state) {
  // Define the start and end times for the visible week on the calendar.
  const timeCursor = moment(getSelectedDay(state)).startOf('day');
  return {
    from: timeCursor.format(),
    to: timeCursor.add(8, 'day').format(),
  };
}

export function getInOneHour() {
  return {
    from: moment().format(),
    to: moment().add(1, 'hour').format(),
  };
}

export function getAroundThreeMonth(state) {
  const timeCursor = moment(getSelectedDay(state)).subtract(1, 'week');
  return {
    from: timeCursor.format(),
    to: timeCursor.add(3, 'month').format(),
  };
}

export function getSpecificDay(state) {
  // Define the start and end times for the day on the calendar.
  const timeCursor = moment(getSelectedSpecificDay(state)).startOf('day');
  return {
    start: timeCursor.format(),
    end: timeCursor.add(1, 'day').format(),
  };
}

export function colorUsers(users) {
  const maxColors = 9;
  return users.map((user, i) => {
    return Object.assign({}, user, { colorIndex: i % maxColors });
  });
}

/**
 * Creates a 'city, country', 'city' or 'country'
 * location string given what's provided
 *
 * @export
 * @param {String} city
 * @param {String} country
 * @returns {String}
 */
export function getUserLocation(city, country) {
  return `${city || ''}${(city && country && ', ') || ''}${country || ''}`;
}
