import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './style.scss';

export default function ModalBanner(props) {
  const { className, children, type } = props;

  return <div className={classNames(css.modalBanner, css[type], className)}>{children}</div>;
}

ModalBanner.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  type: PropTypes.oneOf(['info', 'success', 'warning', 'error']),
};
