import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Card from 'components/molecules/Card';
import { Body } from 'components/atoms/typography';
import FileActions from './FileActions.js';

import { reset } from './reset.css';
import css from './styles.scss';

const File = ({ sessionId, src, selected, onSelect, studentInfo }) => (
  <Card
    className={classNames({
      [reset]: true,
      [css.file]: true,
      [css.selectedFile]: selected,
    })}
    onClick={() => onSelect(src.id, src.webViewLink)}
  >
    <img alt="document icon" src={src.iconLink} />
    <Body className={css.fileName}>{src.name}</Body>
    <FileActions sessionId={sessionId} fileId={src.id} fileName={src.name} studentInfo={studentInfo} />
  </Card>
);

File.propTypes = {
  sessionId: PropTypes.string,
  src: PropTypes.object,
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
  studentInfo: PropTypes.object,
};

export default File;
