import React, { useContext, useEffect, useState } from 'react';
import { AppTrackerContext } from '../viewController';
import { Content, HintText } from './index.style';
// import UniversitySelect from './UniversitySelect';
import UniversityMultiSelect from './UniversityMultiSelect';
import { University } from '../type';
import { useSelector, useDispatch } from 'react-redux';
import { getViewableUser, selectUserId } from 'selectors/meta';
import Dialogue from '../Dialogue';
import ChooseList from './ChooseList';
import { formInfo, formFail } from 'ducks/meta';
import { mapUniversityRegion } from '../util';
import { useSafeLoading, useIsStudent } from '../helper';

const SearchUniversityPopup = () => {
  const { state, controller } = useContext(AppTrackerContext);
  const { isAddUniversityPopupOpen } = state;
  const [universities, setUniversities] = useState<University[]>([]);
  const [selectedUniversitys, setSelectedUniversity] = useState<{ label: string; value: string }[]>([]);
  const [selectedList, setSelectedList] = useState<string>('PROSPECTIVE');
  const [loading, setLoading] = useSafeLoading(false);
  const dispatch = useDispatch();
  const isStudent = useIsStudent();
  useEffect(() => {
    controller.getUniversities().then(setUniversities);
  }, [controller, setUniversities]);
  useEffect(() => {
    if (!isAddUniversityPopupOpen) setSelectedList('PROSPECTIVE');
  }, [isAddUniversityPopupOpen]);

  const currentUserId = useSelector(getViewableUser);
  const requesterUserId = useSelector(selectUserId);
  const resetState = () => {
    setSelectedUniversity([]);
  };
  const closeModal = () => {
    controller.closeAddUniversityModal();
    resetState();
  };
  const onConfirm = async () => {
    setLoading(true);
    const isApplying = selectedList === 'APPLYING';
    const unaddedUniversities: Record<string, number> = {};
    const toAdd = selectedUniversitys
      .map((u) => ({
        universityId: u.value,
        universityName: u.label,
      }))
      .filter((u) => {
        if (!isApplying) return true;
        const found = universities.find((o) => o.id === u.universityId);
        if (!found) return false;
        const region = mapUniversityRegion(found.country?.region);
        if (!controller.canEditApplication(region)) {
          if (!unaddedUniversities[region]) unaddedUniversities[region] = 0;
          unaddedUniversities[region] += 1;
          return false;
        }
        return true;
      });
    if (toAdd.length !== 0) {
      const success = await controller.createMultiApplications(currentUserId, toAdd, selectedList, {
        addedBy: requesterUserId,
        addedFrom: 'school_list_tab',
      });
      if (success) closeModal();
    }
    if (Object.keys(unaddedUniversities).length !== 0) {
      dispatch(
        formFail(
          `The ${Object.keys(unaddedUniversities).join(
            ', ',
          )} final school list has already been sent out to parents/primary contacts for approval${
            isStudent ? ', Please contact your strategist!' : ''
          }`,
        ),
      );
    }
    setLoading(false);
  };
  const _setSelectedUniversity = (university: { label: string; value: string }[]) => {
    // maximum add 10 universities per time, avoid breaking UI if user select too many universities
    if (university.length > 10) return dispatch(formInfo('You can only add a maximum of 10 universities at a time'));
    setSelectedUniversity(university);
  };
  return (
    <Dialogue
      title={'Add Universities'}
      isOpen={isAddUniversityPopupOpen}
      closeModal={closeModal}
      isLoading={loading || !selectedUniversitys.length}
      onClickConfirm={onConfirm}
    >
      <Content>{'University'}</Content>
      <UniversityMultiSelect
        setSelectedUniversity={_setSelectedUniversity}
        selectedUniversitys={selectedUniversitys}
        universites={universities}
      />
      <HintText>{'You can add max 10 universities each time'}</HintText>
      <ChooseList selectedList={selectedList} onSelectList={setSelectedList} />
    </Dialogue>
  );
};

export default SearchUniversityPopup;
