import { useSelector } from 'react-redux';
import { getViewableUser, selectUserId } from 'selectors/meta';

export const useUserId = (): string => {
  return useSelector(getViewableUser);
};

export const useRequesterUserId = (): string => {
  return useSelector(selectUserId);
};
