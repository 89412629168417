import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
import autoBind from 'auto-bind';
import * as Logger from '@crimson-education/browser-logger';

import LoadingIndicator from 'components/molecules/LoadingIndicator';
import Contact from '../Contact';

import ThreadListItem from './ThreadListItem';
import css from './styles';

function sortThreads(a, b) {
  const lastSent = new Date(a.lastMessageSentAt) < new Date(b.lastMessageSentAt) ? 1 : -1;
  return lastSent;
}

export default class Threads extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      hasMore: props.threadsHasNextPage || props.contactsHasNextPage,
    };
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.threadsHasNextPage !== this.props.threadsHasNextPage ||
      nextProps.contactsHasNextPage !== this.props.contactsHasNextPage
    ) {
      this.setState({
        hasMore: nextProps.threadsHasNextPage || nextProps.contactsHasNextPage,
      });
    }
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.hasMore !== this.state.hasMore) {
      return false;
    }
    return true;
  }
  loadMore() {
    const { threadsHasNextPage, loadMoreThreads, contactsHasNextPage, loadMoreContacts } = this.props;
    setTimeout(() => {
      if (this.props.searchStatus === 'end') {
        this.setState({
          hasMore: false,
        });
        return;
      }
      if (threadsHasNextPage) {
        loadMoreThreads();
        return;
      }
      if (contactsHasNextPage) {
        loadMoreContacts();
      }
    }, 0);
  }
  clickThread(thread) {
    const { searchStatus, handleSearchStatus, consumeThread } = this.props;
    this.props.updateCurrentThreadId(thread.id);

    if (searchStatus === 'start') {
      handleSearchStatus('end');
    }
    if (thread && thread.unreadMessagesCount > 0) {
      consumeThread(thread.id);
    }
    Logger.trackEventSinceLastAction({ message: 'click thread item' });
  }
  render() {
    const { threads, contacts, activeThread, onContactSelect, onlineUsers } = this.props;

    const sortedThreads = threads.sort(sortThreads);
    // const otherThreads = sortedThreads.filter(x => !activeThread || activeThread.id !== x.id);
    return (
      <InfiniteScroll
        loadMore={this.loadMore}
        hasMore={this.state.hasMore}
        useWindow={false}
        loader={<LoadingIndicator key="loader" colour="hint" />}
      >
        {sortedThreads.slice(0, 200).map((thread) => {
          return (
            <Link
              data-ga-label="Message Thread"
              to={`/messages/${thread.id}`}
              key={thread.id}
              className={css.thread}
              onClick={() => this.clickThread(thread)}
            >
              <ThreadListItem
                {...thread}
                selected={activeThread && activeThread.id === thread.id}
                source={thread.source}
                icon={thread.icon}
                threadType={thread.type}
                dataTestId={`thread-${thread.id}`}
              />
            </Link>
          );
        })}
        {contacts.map((contact, idx) => (
          <Contact
            key={idx}
            contact={contact}
            onContactSelect={onContactSelect}
            onlineUsers={onlineUsers}
            activeThread={activeThread}
          />
        ))}
      </InfiniteScroll>
    );
  }
}

Threads.propTypes = {
  threads: PropTypes.array,
  contacts: PropTypes.array,
  activeThread: PropTypes.object,
  threadsHasNextPage: PropTypes.bool,
  contactsHasNextPage: PropTypes.bool,
  loadMoreThreads: PropTypes.func.isRequired,
  loadMoreContacts: PropTypes.func.isRequired,
  onContactSelect: PropTypes.func.isRequired,
  onlineUsers: PropTypes.array,
  searchStatus: PropTypes.string,
  handleSearchStatus: PropTypes.func,
  type: PropTypes.string,
  consumeThread: PropTypes.func,
  updateCurrentThreadId: PropTypes.func,
};
