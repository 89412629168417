import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import debounce from 'lodash/debounce';

import css from './styles.scss';
import Header from '../Header';
import Dropdown from './Dropdown';
import Details from '../Details';
import CancelButton from './CancelButton';

const HEADER_TEXT = 'BOOK FOR';

export default function BookingAs(props) {
  const { loadMoreContacts } = props;
  const debounceLoadContacts = debounce(loadMoreContacts, 500);

  let bookingAsOther = false;
  if (props.bookAs) {
    bookingAsOther = props.userId !== props.bookAs.get('userId');
  }

  return (
    <div className={css.bookingAs}>
      <Header headerText={HEADER_TEXT} />
      <Dropdown
        people={props.bookFor}
        selectBookingAs={props.selectBookingAs}
        dataTestId="searchBookingForUserInput"
        pagination={props.bookingForPagination}
        loadMoreContacts={debounceLoadContacts}
      />
      <div className={css.person}>
        <Details person={props.bookAs} userId={props.userId} />
        {bookingAsOther ? (
          <CancelButton
            selectBookingAs={props.selectBookingAs}
            userId={props.userId}
            dataTestId="cancelBookingForButton"
          />
        ) : (
          ''
        )}
      </div>
    </div>
  );
}

BookingAs.propTypes = {
  bookFor: ImmutablePropTypes.list.isRequired,
  bookAs: ImmutablePropTypes.map,
  userId: PropTypes.string.isRequired,
  selectBookingAs: PropTypes.func.isRequired,
  loadMoreContacts: PropTypes.func.isRequired,
  bookingForPagination: PropTypes.object,
};
