import { WebAuth } from 'auth0-js';
import pify from 'pify';
import { post } from 'utils/http';
import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';
import { clearNotesLocalStorage } from 'utils/utils';
import * as Logger from '@crimson-education/browser-logger';

import { MessageEventType } from '../../utils/MessageEventType';

export const AUTH_IMPERSONATE = 'auth.impersonate';
export const AUTH_ACCESS_TOKEN = 'auth.accessToken';
export const AUTH_ID_TOKEN = 'auth.idToken';
export const AUTH_REFRESH_TOKEN = 'auth.refreshToken';
export const AUTH_EXPIRATION_DATE = 'auth.expirationDate';
export const AUTH_DELEGATION_TOKEN = 'auth.delegation.token';
export const AUTH_DELEGATION_EXPIRY = 'auth.delegation.expiry';
export const WECHAT_OPENID = 'wechat.openid';
export const WECHAT_APPID = 'wechat.appid';
export const PROFILE_CACHE = 'auth.profile';

const { auth0: auth0Config, api, environment, namespace } = getConfig();

WebAuth.prototype.changePasswordAsync = pify(WebAuth.prototype.changePassword);
WebAuth.prototype.checkSessionAsync = pify(WebAuth.prototype.checkSession);
const auth = new WebAuth(auth0Config);

let authRefreshHandle;
function saveTokens(tokens) {
  localStorage.setItem(AUTH_ACCESS_TOKEN, tokens.access_token);
  localStorage.setItem(AUTH_ID_TOKEN, tokens.id_token);
  localStorage.setItem(AUTH_REFRESH_TOKEN, tokens.refresh_token);
  localStorage.setItem(AUTH_EXPIRATION_DATE, Date.now() + Number.parseInt(tokens.expires_in, 10) * 1000);

  if (authRefreshHandle) {
    clearTimeout(authRefreshHandle);
  }

  // eslint-disable-next-line no-use-before-define
  authRefreshHandle = setTimeout(refreshAccessTokens, 2 * 60 * 60 * 1000); // Refresh Auth every 2 hours
}

function clearAuthStorage() {
  // remove all the localStorage keys that starts with 'auth.' and 'com.auth0.auth'
  Object.keys(localStorage)
    .filter((key) => key.startsWith('auth.') || key.startsWith('com.auth0.auth.'))
    .forEach((key) => localStorage.removeItem(key));
}

export function logout() {
  if (authRefreshHandle) {
    clearTimeout(authRefreshHandle);
  }
  const userId = localStorage.getItem('currentUserId');
  Logger.trackEvent({ message: MessageEventType.AuthLogOut, metadata: { userId } });
  clearAuthStorage();
  clearNotesLocalStorage();
  const states = btoa(
    JSON.stringify({
      origin: window.location.origin,
      userId,
    }),
  );
  auth.logout({
    returnTo: `${api.endpoint}/signout?state=${states}`,
  });
}

export function parseHash(hash, redirect = '/') {
  const tokens = JSON.parse(atob(hash));
  saveTokens(tokens);
  history.replaceState(null, '', redirect); // eslint-disable-line no-restricted-globals
}

export async function authorize() {
  let isAuthorized = false;
  const refreshToken = localStorage.getItem(AUTH_REFRESH_TOKEN);

  const request = refreshToken ? `${api.endpoint}/authorize?refresh=${refreshToken}` : `${api.endpoint}/authorize`;

  const response = await fetch(request, {
    credentials: 'include',
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_ID_TOKEN)}`,
    },
  });
  if (response.ok) {
    try {
      const data = await response.text();

      // Handle JSON if data is not OK.
      if (data !== 'OK') {
        const tokens = JSON.parse(data);
        saveTokens(tokens);
      }
    } catch (err) {
      Logger.reportError({ message: err.message });
    }
    isAuthorized = true;
    Logger.trackEvent({ message: MessageEventType.AuthSuccess });
  } else {
    if (response.status === 401) {
      Logger.trackEvent({ message: MessageEventType.AuthFail });
      auth0Authorize();
    }

    isAuthorized = false;
  }
  return isAuthorized;
}

export async function refreshAccessTokens() {
  const refreshToken = localStorage.getItem(AUTH_REFRESH_TOKEN);

  const request = refreshToken
    ? `${api.endpoint}/refreshAccessTokens?refresh=${refreshToken}`
    : `${api.endpoint}/refreshAccessTokens`;

  const response = await fetch(request, {
    credentials: 'include',
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_ID_TOKEN)}`,
    },
  });
  if (response.ok) {
    const tokens = await response.json();
    saveTokens(tokens);
  } else if (response.status === 401) {
    // TODO
    // show error banner
    // window.location.pathname = "/";
    if (authRefreshHandle) {
      clearTimeout(authRefreshHandle);
    }
  }
}

export function getBearer() {
  return localStorage.getItem(AUTH_ID_TOKEN);
}

export function getRefreshToken() {
  return localStorage.getItem(AUTH_REFRESH_TOKEN);
}

/*
 * The tokens have expired, redirect to auth0 to get a new one.
 */
export async function auth0Authorize(redirectOverride) {
  const wechatOpenId = localStorage.getItem(WECHAT_OPENID)
    ? localStorage.getItem(WECHAT_OPENID)
    : sessionStorage.getItem(WECHAT_OPENID);
  const wechatAppId = localStorage.getItem(WECHAT_APPID)
    ? localStorage.getItem(WECHAT_APPID)
    : sessionStorage.getItem(WECHAT_APPID);

  let redirect = redirectOverride;
  if (!redirect) {
    redirect = '/';
    if (window.location.pathname !== '/auth-callback' && window.location.pathname !== '/wechat-auth-callback') {
      redirect = `${window.location.pathname}${window.location.search}${window.location.hash}`;
    }
  }

  const authorizeConfig = {
    state: btoa(
      JSON.stringify({
        origin: window.location.origin,
        redirect: encodeURIComponent(redirect),
        authRedirectUri: `${auth0Config.redirectUri}?location=app`,
        wechatOpenId,
        wechatAppId,
      }),
    ),
  };

  if (environment === 'test') {
    authorizeConfig.environment = environment;
    authorizeConfig.namespace = namespace;
  }

  const impersonator = localStorage.getItem(AUTH_IMPERSONATE);
  if (impersonator) {
    authorizeConfig.impersonate = impersonator;
  }

  auth.authorize(authorizeConfig);
}

export async function impersonateUser(userId) {
  if (!userId) {
    return;
  }

  clearAuthStorage();
  localStorage.setItem(AUTH_IMPERSONATE, userId);
  await auth0Authorize('/');
}

/**
 * Store delegation token in local storage
 * @param {object} result
 */
function saveDelegationToken(result) {
  localStorage.setItem(AUTH_DELEGATION_TOKEN, result.id_token);
  localStorage.setItem(AUTH_DELEGATION_EXPIRY, Date.now() + Number.parseInt(result.expires_in, 10) * 1000);
}

function getDelegationToken() {
  const expiry = localStorage.getItem(AUTH_DELEGATION_EXPIRY);
  if (!expiry || expiry < Date.now()) {
    return null;
  }

  return localStorage.getItem(AUTH_DELEGATION_TOKEN);
}

/**
 * Creates a delegation token for Firebase
 * @param {object} options
 * @returns {Promise<string>}
 */
export async function firebaseDelegation() {
  // try to get delegation token from localStorage first
  const token = getDelegationToken();
  if (token) {
    return token;
  }

  // if the token is not is localStorage, get one from Auth0
  const { domain, clientID } = auth0Config;

  const result = await post(`https://${domain}/delegation`, {
    client_id: clientID,
    grant_type: 'urn:ietf:params:oauth:grant-type:jwt-bearer',
    id_token: localStorage.getItem(AUTH_ID_TOKEN),
    scope: 'openid',
    api_type: 'firebase',
  });

  saveDelegationToken(result);
  return result.id_token;
}

/**
 * Send password reset instructions to the specified email
 * @param {string} email
 * @returns {Promise<any>}
 */
export async function changePassword(email) {
  return auth.changePasswordAsync({
    connection: 'Username-Password-Authentication',
    email,
  });
}

export async function handleWechatAuthCallback(queryString) {
  const urlParams = new URLSearchParams(queryString);
  const code = urlParams.get('code');
  const appId = urlParams.get('state');
  const response = await fetch(`${api.endpoint}/getWechatOpenid?code=${code}&appId=${appId}`);
  if (response.ok) {
    const result = await response.json();
    if (result.openid) {
      localStorage.setItem(WECHAT_OPENID, result.openid);
      sessionStorage.setItem(WECHAT_OPENID, result.openid);

      localStorage.setItem(WECHAT_APPID, appId);
      sessionStorage.setItem(WECHAT_APPID, appId);
    }
  }
}

export function storeProfile(token, profile) {
  return localStorage.setItem(PROFILE_CACHE, JSON.stringify({ token, profile }));
}

export function getCachedProfile(token) {
  const cache = JSON.parse(localStorage.getItem(PROFILE_CACHE) || '{}');
  if (cache.token === token) {
    return cache.profile;
  }

  return undefined;
}

export function getCurrentUserId() {
  return localStorage.getItem('currentUserId');
}
