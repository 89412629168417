import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autobind from 'auto-bind';
import { Body } from 'components/atoms/typography';
import FilterTag from 'components/molecules/FilterTag';

import InvoiceFilterModal from './InvoiceFilterModal';

import css from './styles.scss';
import { handleEnter } from '../../../../utils/accessibility';

export default class InvoiceFilters extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filterValues: props.activeFilter || {
        period: {},
        status: {},
        billingCountryName: {},
        tutorId: {},
      },
      filterName: null,
      filterDisplayName: null,
      filterOptions: [],
    };

    autobind(this);
  }

  openModal(name) {
    const { filters } = this.props;

    let options = [];
    let displayName = null;

    switch (name) {
      case 'billingCountryName':
        options = filters.countries;
        displayName = 'billing country';
        break;
      case 'period':
        options = filters.periods;
        break;
      case 'tutorId':
        options = filters.tutors;
        displayName = 'tutor';
        break;
      default:
        options = filters.statuses;
    }

    this.setState({
      filterName: name,
      filterOptions: options,
      filterDisplayName: displayName,
    });
  }

  closeModal() {
    this.setState({
      filterName: null,
      filterOptions: null,
    });
  }

  clearFilter(name) {
    const newState = this.state.filterValues;
    newState[name] = null;
    this.setState({ filterValues: newState });
    this.props.handleChange(name, null);
  }

  handleSelect(name, option) {
    const newState = this.state.filterValues;
    newState[name] = option;
    this.setState({ filterValues: newState });
  }

  handleConfirm(name) {
    this.props.handleChange(name, this.state.filterValues[name]);
    this.closeModal(name);
  }

  resetFilters() {
    this.setState({ filterValues: {} });
  }

  render() {
    const {
      showTutorFilter,
      activeFilter: { status, billingCountryName, tutorId, period },
      handleReset,
      app,
    } = this.props;

    const { filterName } = this.state;

    const isMobile = app && app.isMobile;

    return (
      <div className={css.invoiceFilters}>
        <Body bold>Filter by...</Body>
        <div className={css.filters}>
          {showTutorFilter && (
            <FilterTag
              label="Tutor"
              value={tutorId && tutorId.label}
              onClick={() => this.openModal('tutorId')}
              onClear={() => this.clearFilter('tutorId')}
            />
          )}
          <FilterTag
            label="Period"
            value={period && period.label}
            onClick={() => this.openModal('period')}
            onClear={() => this.clearFilter('period')}
          />
          <FilterTag
            label="Status"
            value={status && status.label}
            onClick={() => this.openModal('status')}
            onClear={() => this.clearFilter('status')}
          />
          <FilterTag
            label="Billing Country"
            value={billingCountryName && billingCountryName.label}
            onClick={() => this.openModal('billingCountryName')}
            onClear={() => this.clearFilter('billingCountryName')}
          />
          <div
            role="button"
            tabIndex={0}
            data-ga-label="Reset filter"
            className={css.clearFilters}
            onClick={() => {
              this.resetFilters();
              handleReset();
            }}
            onKeyDown={handleEnter(() => {
              this.resetFilters();
              handleReset();
            })}
          >
            Reset
          </div>
        </div>
        <InvoiceFilterModal
          isOpen={this.state.filterName !== null}
          name={this.state.filterName}
          displayName={this.state.filterDisplayName}
          onSubmit={() => this.handleConfirm(filterName)}
          onClose={this.closeModal}
          onSelect={this.handleSelect}
          options={this.state.filterOptions}
          value={this.state.filterValues[filterName] || null}
          isMobile={isMobile}
        />
      </div>
    );
  }
}

InvoiceFilters.propTypes = {
  handleChange: PropTypes.func,
  handleReset: PropTypes.func,
  filters: PropTypes.object,
  activeFilter: PropTypes.object,
  showTutorFilter: PropTypes.bool,
  app: PropTypes.object,
};
