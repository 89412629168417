import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import DatePicker from 'antd/lib/date-picker';
import { SimpleMenuDropdown } from 'components/organisms/ApplicationTracker/StaffDashboard/components';

export const Container = styled.div`
  padding: 24px 39px 17px 32px;
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 8px;
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 16px;
  color: #000000;
`;

export const Content = styled.div`
  font-weight: 500;
  font-size: 12px;

  /* Stone */
  color: #73747d;
`;

export const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 10px;
  right: 0px;
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

export const StyledDatePicker = styled(DatePicker.RangePicker)`
  background: #f3f6fa;
  /* Pale grey */
  border: 1px solid #e3e7ed;
  border-radius: 30px;
  width: 190px;
  padding-left: 20px;

  > .ant-picker-active-bar {
    margin-left: 20px;
  }
  &.ant-picker-focused {
    box-shadow: none;
  }
  input {
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    /* Stone */
    color: #73747d;
  }

  transition: border-color 0.4s;

  :hover,
  :active {
    border-color: var(--color_indigo);
  }
`;

export const StyledDropdownButton = styled(SimpleMenuDropdown)`
  height: 36px;

  background: #f3f6fa;
  border: 1px solid #e3e7ed;
  border-radius: 30px;

  padding-left: 20px;
  padding-right: 15px;

  transition: border-color 0.4s;
  > span {
    font-size: 14px;
    font-weight: 500;
    /* Stone */
    color: #73747d;
  }

  :hover,
  :active {
    border-color: var(--color_indigo);
  }
`;
