import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import { CALENDAR_FUTURE_LIMIT } from 'utils/calendarUtils';
import CalendarIcon from 'components/molecules/CalendarIcon';

import css from './styles.scss';
import { handleEnter } from '../../../../../utils/accessibility';

export default class CalendarHeader extends Component {
  static formatWeekDisplay(currentDate, days) {
    const start = moment(currentDate);
    const end = moment(currentDate).add(days - 1, 'd');
    if (start.isSame(end, 'month')) {
      return `${start.local().format('D')} - ${end.local().format('D MMMM YYYY')}`;
    }
    if (start.isSame(end, 'year')) {
      return `${start.local().format('D MMMM')} - ${end.local().format('D MMMM YYYY')}`;
    }
    return `${start.local().format('D MMMM YYYY')} - ${end.local().format('D MMMM YYYY')}`;
  }

  constructor(props) {
    super(props);
    this.state = {
      headerWidth: null,
    };

    this.renderPrevArrow = this.renderPrevArrow.bind(this);
    this.renderNextArrow = this.renderNextArrow.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.hasSynced !== this.props.hasSynced) {
      return true;
    }
    return false;
  }

  handleMouseEnter() {
    const header = this.weekHeader;

    this.setState({
      headerWidth: header.getBoundingClientRect().width,
    });
  }

  handleMouseOut() {
    setTimeout(() => {
      this.setState({
        headerWidth: null,
      });
    }, 125);
  }

  renderPrevArrow() {
    return (
      <button data-test-id="goToPreviousWeek" className={css.arrowButton} onClick={this.props.prevWeek}>
        &lt;
      </button>
    );
  }

  renderNextArrow() {
    const { selectedDay } = this.props;
    const showNext = moment(selectedDay).isBefore(moment().add(CALENDAR_FUTURE_LIMIT, 'd'), 'd');

    return (
      <button
        data-test-id="goToNextWeek"
        className={classNames(css.arrowButton, { [css.hidden]: !showNext })}
        onClick={this.props.nextWeek}
      >
        &gt;
      </button>
    );
  }

  render() {
    const { headerWidth } = this.state;
    const { selectedDay, calendarDays, toggleCalendarView, hasSynced } = this.props;
    return (
      <div className={css.calendarHeader}>
        <div
          role="button"
          data-ga-label="Today"
          tabIndex={0}
          onClick={this.props.goToToday}
          onKeyDown={handleEnter(this.props.goToToday)}
        >
          <div className={css.todayButton} data-test-id="goToToday">
            <CalendarIcon content={moment().date().toString()} />
            <div className={css.todayText}>Today</div>
          </div>
        </div>
        <div
          data-appcues-id="calendar-header"
          className={css.header}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseOut}
        >
          {this.renderPrevArrow()}
          <div
            data-test-id="currentWeekHeader"
            className={css.currentWeek}
            style={{ width: headerWidth || 'auto' }}
            ref={(div) => {
              this.weekHeader = div;
            }}
          >
            {CalendarHeader.formatWeekDisplay(selectedDay, calendarDays)}
          </div>
          {this.renderNextArrow()}
        </div>
        <div
          className={css.calendarHeaderRight}
          role="button"
          data-ga-label="Toggle Calendar View"
          tabIndex={0}
          data-test-id="toggleCalendarMode"
        >
          {hasSynced ? (
            <div className={css.externalCalendarNote}>
              <div className={css.externalCalendarBlock} />
              Other calendar
            </div>
          ) : null}
          <div
            role="button"
            tabIndex={0}
            onClick={() => toggleCalendarView(calendarDays)}
            onKeyDown={handleEnter(() => toggleCalendarView(calendarDays))}
            className={css.modeSwitcher}
          >
            {calendarDays === 7 ? '4 Days' : 'Week'}
          </div>
        </div>
      </div>
    );
  }
}

CalendarHeader.propTypes = {
  selectedDay: PropTypes.object,
  calendarDays: PropTypes.number.isRequired,
  toggleCalendarView: PropTypes.func.isRequired,
  nextWeek: PropTypes.func.isRequired,
  prevWeek: PropTypes.func.isRequired,
  goToToday: PropTypes.func.isRequired,
  hasSynced: PropTypes.bool,
};

CalendarHeader.defaultProps = {
  calendarDays: 7,
};

CalendarHeader.displayName = 'CalendarHeader';
