import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';
import { gql } from '@apollo/client';
import { createClient } from '../utils';

const { api } = getConfig();

const client = createClient(api.graphQLEndpoint);

const sessionEmailHistoryType = gql`
  fragment sessionEmailHistoryType on emailHistoryType {
    eventId
    email
    sentAt
    sentBy
  }
`;

// @FIXME: fix studentId eslint error
const sendSessionSummaryEmail = gql`
  mutation sendSessionSummaryEmail($eventId: String!, $studentId: String, $emails: [String]) {
    #eslint-disable-next-line @graphql-eslint/known-argument-names
    sendSessionSummaryEmail(eventId: $eventId, studentId: $studentId, emails: $emails)
  }
`;

const getSessionEmailHistory = gql`
  ${sessionEmailHistoryType}
  query getSessionEmailHistory($eventId: String!) {
    sessionEmailHistory(eventId: $eventId) {
      ...sessionEmailHistoryType
    }
  }
`;

const SessionLogType = gql`
  fragment SessionLogType on SessionLogType {
    id
    eventId
    userId
    log
    timestamp
  }
`;

export const sessionReportType = gql`
  fragment sessionReportType on sessionReportType {
    studentId
    reporterId
    eventId
    sessionType
    subjects
    concern
    elaborate
    createdAt
    version
  }
`;

const getSessionLogByEvent = gql`
  ${SessionLogType}
  query getSessionLogByEvent($eventId: String!) {
    sessionLog(eventId: $eventId) {
      ...SessionLogType
    }
  }
`;

const getSessionRecordingUrl = gql`
  query getSessionRecordingUrl($eventId: String!) {
    getSessionRecordingUrl(eventId: $eventId)
  }
`;

const hasViewRecordingPermission = gql`
  query hasViewRecordingPermission($eventId: String!) {
    hasViewRecordingPermission(eventId: $eventId)
  }
`;

const getMilestoneSessionReportsByUserId = gql`
  ${sessionReportType}
  query {
    getMilestoneSessionReportsByUserId {
      ...sessionReportType
    }
  }
`;

export default {
  sendSessionSummaryEmail: async ({ eventId, studentId, emails }) => {
    return client.query(sendSessionSummaryEmail, { eventId, studentId, emails });
  },
  getSessionEmailHistory: async (eventId) => {
    return client.query(getSessionEmailHistory, { eventId });
  },
  getSessionLogByEvent: async (eventId) => client.query(getSessionLogByEvent, { eventId }),
  getSessionRecordingUrl: async (eventId) => client.query(getSessionRecordingUrl, { eventId }),
  hasViewRecordingPermission: async (eventId) => client.query(hasViewRecordingPermission, { eventId }),
  getMilestoneSessionReportsByUserId: () => client.query(getMilestoneSessionReportsByUserId),
};
