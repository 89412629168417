import { connect } from 'react-redux';
import { getMissionById } from 'selectors/roadmap';

import TaskModal from './TaskModal';

const mapStateToProps = (state, ownProps) => {
  const hasMission = ownProps.taskToEdit && ownProps.taskToEdit.roadmapMissionId && ownProps.roadmapId;
  return {
    mission: hasMission && getMissionById(state)(ownProps.roadmapId, ownProps.taskToEdit.roadmapMissionId),
  };
};

export default connect(mapStateToProps)(TaskModal);
