import React, { useMemo, useState, forwardRef, useRef, useImperativeHandle, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import MultiSelectList from './MultiSelectList';
import SelectList from './SelectList';

const singleEmptyDefaultText = 'select one item';
const multiEmptyDefaultText = 'select items';

const Select = forwardRef((props, ref) => {
  const {
    label, emptyText, inputText, inputTextMap,
    color = 'primary', size, className, buttonClassName, disabled,
    multi, items, map, onChange, ...rest
  } = props;

  const selectListRef = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const [popupStyle, setPopupStyle] = useState();
  const [actualInputText, setInputContent] = useState();
  const [selected, setSelected] = useState();

  const [popId, selectId] = useMemo(() => {
    const selectId = `select-${Math.random()}`;
    return [
      `popover-for-${selectId}`,
      selectId
    ];
  }, []);

  const buttonClick = ({ currentTarget }) => {
    setPopupStyle({
      top: `${currentTarget.offsetHeight}px`,
      minWidth: `${currentTarget.offsetWidth}px`
    });
    setAnchorEl(currentTarget);
  };

  const close = () => {
    setAnchorEl(null);
  };

  useImperativeHandle(ref, () => selectListRef.current);

  useEffect(() => {
    const toggleChip = (e, item) => {
      e.stopPropagation();
      selectListRef.current && selectListRef.current.toggle(item);
    };

    // nothing was selected, try emptyText or a default text
    if (!selected ||
      (multi && (!selected.itemList || !selected.itemList.length)) ||
      (!multi && !selected.item)) {
      const content = emptyText || multi ?
        multiEmptyDefaultText :
        singleEmptyDefaultText;
      setInputContent(content);
      return;
    }

    // something was selected and inputText was provided
    if (inputText) {
      const content = typeof inputText === 'function' ?
        inputText(multi ? selected.itemList : selected.item) :
        inputText;
      setInputContent(content);
      return;
    }

    // no inputText was provided, and is a multi select
    if (multi) {
      const content = selected.itemList.map(
        (entry, i) => (<Chip
          key={`${selectId}-chip-${i}`}
          className="ds-chip delete bread-crumb"
          color={color}
          size="small"
          onClick={e => toggleChip(e, entry)}
          // if there is no mapping function for the items, try to string
          label={inputTextMap ? inputTextMap(entry) : entry.toString()}
        />));
      setInputContent(content);
    }
  }, [color, emptyText, inputText, inputTextMap, multi, selectId, selected]);

  const handleSelection = useCallback((args) => {
    setSelected(args);
    if (multi) {
      onChange && onChange(args.itemList);
    } else {
      close();
      onChange && onChange(args.item);
    }
  }, [multi, onChange]);

  const SelectBody = useMemo(() => multi ? MultiSelectList : SelectList, [multi]);

  return (
    <>
      <FormControl className={`ds-select ${className} ${anchorEl && 'ds-active'}`}>
        <InputLabel shrink>
          {label}
        </InputLabel>
        <Button
          color={color}
          size={size}
          onClick={buttonClick}
          disabled={disabled || !items || !items.length}
          endIcon={<KeyboardArrowDownIcon rounded="true" />}
          className={buttonClassName}
        >
          <span className="grow">
            { actualInputText }
          </span>
        </Button>
      </FormControl>
      <Popover
        id={anchorEl && popId}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={close}
        className="ds-select-popover"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        keepMounted
        PaperProps={{
          style: popupStyle
        }}
      >
        <SelectBody
          MultiSelectList
          ref={selectListRef}
          items={items}
          map={map}
          onChange={handleSelection}
          {...rest}
        />
      </Popover>
    </>
  );
});

Select.propTypes = {
  label: PropTypes.string,
  multi: PropTypes.bool,
  items: PropTypes.array,
  color: PropTypes.string,
  size: PropTypes.string,
  map: PropTypes.func,
  onChange: PropTypes.func,
  emptyText: PropTypes.string,
  inputText: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
  inputTextMap: PropTypes.func,
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Select;
