import React from 'react';
import { Column } from 'components/molecules/Table';
import { columnNames } from '../columnMap';

export default function StudentPackageSubjects() {
  return (
    <Column
      key={columnNames.StudentPackageSubjects}
      name="Subjects"
      renderer={({ rowData }) => {
        return (
          <div>
            {rowData.getIn(['studentInfo', 'packageSubjects'])
              ? rowData
                  .getIn(['studentInfo', 'packageSubjects'])
                  .map((studentInfo, i) => <div key={i}>{studentInfo.get('name')}</div>)
              : 'No Subjects Available'}
          </div>
        );
      }}
    />
  );
}
