import React from 'react';
import { Row } from '../../../index.style';
import { Count, Label, ProgressBarTrack, StyledColumn } from './index.style';
type ProgressBarProps = {
  total: number;
  current: number;
  tintColor: string;
  label: string;
  onClick?: () => void;
};

const ProgressBar = ({ label, tintColor, total, current, onClick }: ProgressBarProps) => {
  return (
    <StyledColumn gap={'4px'} onClick={onClick} style={{ cursor: onClick ? 'pointer' : undefined }}>
      <Row rowcenter justifycontent={'space-between'}>
        <Label>{label}</Label>
        <Count>
          {current}
          <span>{'/' + total}</span>
        </Count>
      </Row>
      <ProgressBarTrack>
        <div
          style={{ backgroundColor: tintColor, width: !total ? '0%' : `${((current / total) * 100).toFixed(0)}%` }}
        />
      </ProgressBarTrack>
    </StyledColumn>
  );
};

export default ProgressBar;
