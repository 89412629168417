import { Acl, roleTypes } from '@crimson-education/common-config';
import {
  UserCountMap,
  RELATION_TUTORS,
  RELATION_STUDENTS,
  ALL_USERS_ACTIVE,
  ALL_USERS_DEACTIVATED,
} from 'constants/ourPeople';

export function getNewUserCountFilters(type, user, viewableRoles = []) {
  const newFilters = Object.assign({}, UserCountMap[type].filters);

  switch (type) {
    case RELATION_TUTORS:
      return Object.assign(newFilters, user.categoryIds);
    case RELATION_STUDENTS:
      if (Acl.checkRole(user.roles, roleTypes.HEAD_TUTOR)) {
        return Object.assign(newFilters, user.categoryIds);
      }
      break;
    case ALL_USERS_ACTIVE:
    case ALL_USERS_DEACTIVATED: {
      const roles = viewableRoles.map((role) => role.id);
      return Object.assign(newFilters, { roles });
    }
    default:
      return newFilters;
  }

  return newFilters;
}

// No default export, but our eslint rules require one.
export default undefined;
