import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import titleCase from 'titlecase';
import ThumbUp from '@material-ui/icons/ThumbUp';
import ThumbDown from '@material-ui/icons/ThumbDown';

import { Caption } from 'components/atoms/typography';

import css from './styles.scss';

export default class TutorRatingSentiment extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      tab: 'total',
    };
  }

  render() {
    const { sentiment } = this.props;

    return (
      <div className={css.fields}>
        {sentiment
          .sort((a, b) => a.type.localeCompare(b.type))
          .map((x) => (
            <div key={x.type} className={css.field}>
              <Caption bold className={css.value}>
                {titleCase(x.type)}
              </Caption>
              <div className={css.fieldRating}>
                <div
                  className={classNames({
                    [css.negative]: x.totalNegative,
                  })}
                >
                  <ThumbDown className={css.icon} />
                  <span>{x.totalNegative}</span>
                </div>
                <div
                  className={classNames({
                    [css.positive]: x.totalPositive,
                  })}
                >
                  <ThumbUp className={css.icon} />
                  <span>{x.totalPositive}</span>
                </div>
              </div>
            </div>
          ))}
      </div>
    );
  }
}

TutorRatingSentiment.propTypes = {
  sentiment: PropTypes.array.isRequired,
};
