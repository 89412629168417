import React from 'react';
import createDropdown from '../DropdownBase';
import { MenuItem, Container } from './index.style';
export type DropdownMenuProps = {
  data: { label: string; value: string }[];
  onClickItem: <P extends string>(value: P) => any;
  closeDropdown: () => void;
  menuStyle?: React.CSSProperties;
  menuItemStyle?: React.CSSProperties;
  getMenuItemStyleByIndex?: (idx: number) => React.CSSProperties;
};
const Menu = ({
  data,
  onClickItem,
  closeDropdown,
  menuStyle,
  menuItemStyle,
  getMenuItemStyleByIndex,
}: DropdownMenuProps) => {
  return (
    <Container style={menuStyle}>
      {data.map(({ label, value }, idx) => {
        return (
          <MenuItem
            key={value}
            style={{ ...menuItemStyle, ...(getMenuItemStyleByIndex && getMenuItemStyleByIndex(idx)) }}
            onClick={(e) => {
              e.stopPropagation();
              closeDropdown();
              onClickItem(value);
            }}
          >
            <div>{label}</div>
          </MenuItem>
        );
      })}
    </Container>
  );
};

const DropdownMenu = createDropdown(Menu);

export default DropdownMenu;
