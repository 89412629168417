import { createSelector } from 'reselect';

import { getBookingById } from './booking';

const incompleteReport = (state) => state.get('incompleteReport');

export const getAllIncompleteReports = createSelector(incompleteReport, (incompleteReport) => {
  const reports = incompleteReport.toList().toJS();

  return reports.map((x) => {
    x.booking = getBookingById(x.eventId);
    return x;
  });
});
