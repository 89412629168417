import { createSelector } from 'reselect';
import examStatus from 'constants/examStatus';
import { addStatusToSections, addQuestionCountToSections, getExamInProgressMessage } from 'utils/examPrep';
import globalMessageTypes from 'constants/globalMessageTypes';
import { getUniqueValuesByKey, propertySort } from 'utils/utils';
import {
  selectUserPermissions,
  getAppLoginUser,
  getViewableUser,
  getRoutePathname,
  isUserOnline,
} from 'selectors/meta';
import isEmpty from 'lodash/isEmpty';
import sectionStatus from 'constants/sectionStatus';
import { List } from 'immutable';
import { Acl, PermissionTypes } from '@crimson-education/common-config';

export const examPrep = (state) => state.get('examPrep');

export const getAllSessionsAndExams = createSelector(examPrep, (examPrep) =>
  examPrep.reduce((accum, map) => accum.merge(map)),
);

export const getAllSortedExamsByUserId = createSelector([getAllSessionsAndExams, getViewableUser], (exams, userId) => {
  if (!exams) return new List();
  const order = [null, examStatus.STARTED, examStatus.COMPLETED];
  return exams
    .toList()
    .filter((exam) => exam.get('userId') === userId)
    .map((exam) => {
      if (!exam.get('dateComplete')) {
        return exam.setIn(['dateComplete'], null);
      }
      return exam;
    })
    .sort((a, b) => {
      const aStatusIndex = order.indexOf(a.get('status'));
      const bStatusIndex = order.indexOf(b.get('status'));

      // eslint-disable-next-line no-nested-ternary
      const orderResult = aStatusIndex < bStatusIndex ? -1 : aStatusIndex === bStatusIndex ? 0 : 1;

      if (orderResult === 0) {
        const aIsAvailable = a.get('isAvailable') || false;
        const bIsAvailable = b.get('isAvailable') || false;
        const aName = a.get('name');
        const bName = b.get('name');

        if (aIsAvailable === bIsAvailable && aName && bName) {
          return aName.localeCompare(bName);
        }
        return aIsAvailable ? 1 : -1;
      }
      return orderResult;
    });
});

export const getAvailableExamsByUserId = createSelector(getAllSortedExamsByUserId, (exams) => {
  return exams
    .filter((exam) => exam.get('examPrepSessionId') || (!exam.get('examPrepSessionId') && exam.get('isAvailable')))
    .toJS();
});

export const getAllExamsForLoggedInUser = createSelector([getAllSessionsAndExams, getAppLoginUser], (exams, userId) => {
  if (!exams) return [];
  return exams
    .toList()
    .filter((exam) => exam.get('userId') === userId)
    .toJS();
});

export const getAllExamsByUserId = createSelector(
  getAllSortedExamsByUserId,
  selectUserPermissions,
  (exams, loginUserPermissions) => {
    const canSendInvite = Acl.checkPermission(loginUserPermissions, PermissionTypes.EDIT_EXAM_PREP_AVAILABILITY);
    const result = exams
      .toJS()
      .filter(
        (exam) =>
          canSendInvite ||
          exam.status === examStatus.STARTED ||
          exam.status === examStatus.COMPLETED ||
          exam.isAvailable,
      );
    return result;
  },
);

export const getAllExamsGroupedByType = createSelector(getAllExamsByUserId, (exams) => {
  const examTypes = getUniqueValuesByKey(exams, 'examType');
  const allExamsGroupedByType = {};

  examTypes.forEach((type) => {
    allExamsGroupedByType[type] = exams.filter((exam) => exam.examType === type);
  });

  return allExamsGroupedByType;
});

export const getExamBySessionId = (sessionId) =>
  createSelector(getAllSessionsAndExams, (exams) => {
    if (!exams) return {};

    const exam = exams.find((exam) => exam.get('examPrepSessionId') === sessionId);

    if (!exam) return {};

    let result = exam.toJS();
    result = addStatusToSections(result);
    result = addQuestionCountToSections(result);
    return result;
  });

export const getExamBySessionIdForAnswerSheet = (sessionId) =>
  createSelector(getExamBySessionId(sessionId), (exam) => {
    if (isEmpty(exam)) return {};
    const nonBreakSections =
      exam.sections && exam.sections.filter((section) => section.questionsAndQuestionGroups.length);
    return Object.assign({}, exam, { sections: nonBreakSections });
  });

export const getExamInProgress = createSelector([getAllSessionsAndExams, getViewableUser], (exams, userId) => {
  if (!exams) return {};

  const examInProgress = exams.find((exam) => {
    return exam.get('status') === examStatus.STARTED && exam.get('userId') === userId;
  });

  if (!examInProgress) return {};

  let result = examInProgress.toJS();
  result = addStatusToSections(result);
  result = addQuestionCountToSections(result);
  return result;
});

export const getActiveSection = (sessionId) =>
  createSelector(getExamBySessionId(sessionId), (exam) => {
    if (!exam || isEmpty(exam) || !exam.sections || isEmpty(exam.sections)) {
      return {};
    }

    const activeSection = exam.sections.find((section) => section.status === sectionStatus.ACTIVE);
    return activeSection || {};
  });

export const getActiveSectionIndex = (sessionId) =>
  createSelector(getExamBySessionId(sessionId), (exam) => {
    if (!exam || isEmpty(exam) || !exam.sections || isEmpty(exam.sections)) {
      return -1;
    }
    return exam.sections.findIndex((section) => section.status === sectionStatus.ACTIVE);
  });

export const getLastCompletedSection = (sessionId) =>
  createSelector(getExamBySessionId(sessionId), (exam) => {
    if (!exam || isEmpty(exam)) {
      return {};
    }
    const completedSections = exam.sections
      ? exam.sections.filter((section) => section.status === sectionStatus.DONE)
      : null;
    return completedSections && completedSections.length ? completedSections[completedSections.length - 1] : {};
  });

export const getCompleteSessionsByUserId = createSelector(getAvailableExamsByUserId, (exams) => {
  return propertySort(
    exams.filter((exam) => exam.status === examStatus.COMPLETED),
    (obj) => obj.timeCompleted,
  );
});

export const getCompletedSessionsByType = (type) =>
  createSelector(getCompleteSessionsByUserId, (sessions) => {
    return sessions.filter((session) => session.examType === type && session.report);
  });

export const getExamInProgressBanner = createSelector(
  getAllExamsForLoggedInUser,
  getRoutePathname,
  (exams, pathname) => {
    if (!exams || !exams.length) return null;

    const examInProgress = exams.find((exam) => {
      return exam.status === examStatus.STARTED;
    });

    if (!examInProgress || isEmpty(examInProgress) || pathname.includes('/test-prep/session/')) {
      return null;
    }

    const data = {
      type: globalMessageTypes.EXAM_IN_PROGRESS,
      text: getExamInProgressMessage(examInProgress.name || 'Crimson Testing'),
      link: `/test-prep/session/${examInProgress.examPrepSessionId}`,
    };
    return data;
  },
);

export const getGlobalMessages = createSelector(getExamInProgressBanner, isUserOnline, (examsInProgress, isOnline) => {
  let messages = [];
  if (!isOnline) {
    messages = [
      {
        type: globalMessageTypes.USER_NOT_CONNECTED,
        text: "Uh oh, you've lost network connection. Please check your connection and refresh your page.",
      },
    ];
  } else if (examsInProgress) {
    messages = [examsInProgress];
  }
  return messages.filter((message) => message);
});
