import React, { useMemo } from 'react';
import { StyledDialogue, InfoText, ContactContainer } from './index.style';
import { KeyContact, Relationship, TUserInfo } from '../../type';
import { Separator } from '../../components/index.style';
import Hint from './Hint';
import { getViewableUser } from 'selectors/meta';
import { useSelector } from 'react-redux';
import { getKeyContactsInfo } from 'selectors/profile';
import { roleTypes } from '@crimson-education/common-config';
import { getUserWithId } from 'selectors/user';
import ContactOption from './ContactOption';
import { getUserPrimaryRole } from '../../util';
import { isContactSelected } from '../helper';
import { useUserId, useRequesterUserId } from '../../helper';
const mapKeyContact = (keyContact: KeyContact) => {
  return {
    email: keyContact.email!,
    name: keyContact.firstName + ' ' + keyContact.lastName + (keyContact.isPrimary ? ' (Primary contact)' : ''),
    relationship: keyContact.relationship!,
    id: keyContact.id.toString(),
    type: 'KEY_CONTACT',
  };
};

const mapRelationship = (userInfo: TUserInfo) => {
  return {
    email: userInfo.email,
    name: userInfo.fullName,
    relationship: getUserPrimaryRole(userInfo),
    id: userInfo.userId,
    type: 'STAFF',
  };
};

type SendParentForApprovalProps = {
  isOpen: boolean;
  closeModal: () => void;
  selectedContacts: { id: string; type: string }[];
  onSelectContact: (contact: { id: string; type: string }) => void;
  onClickConfirm: () => void;
};
const SendParentForApproval = ({
  isOpen,
  closeModal,
  selectedContacts,
  onSelectContact,
  onClickConfirm,
}: SendParentForApprovalProps) => {
  const userId = useUserId();
  const keyContacts: KeyContact[] = useSelector(getKeyContactsInfo(userId, roleTypes.STUDENT));
  const EMPTY_ARRAY = useMemo(() => [], []);
  const relationships: Relationship[] = useSelector(getUserWithId(userId)).toJS().relationships || EMPTY_ARRAY;
  const contactsWithEmails = useMemo(() => keyContacts.filter((o) => !!o.email), [keyContacts]);
  const hasKeyContacts = contactsWithEmails.length > 0;
  const confirmButtonDisabled = !selectedContacts.length;
  return (
    <StyledDialogue
      confirmButtonText={'Next'}
      onClickConfirm={onClickConfirm}
      isOpen={isOpen}
      closeModal={closeModal}
      title={'Send School Confirmation Agreement'}
      isLoading={confirmButtonDisabled}
    >
      <InfoText>
        {'Once you and the student have agreed on the final list,\nplease send this to the parents for approval.'}
      </InfoText>
      <ContactContainer style={{ marginTop: 20 }}>
        {contactsWithEmails.map((keyContact, idx) => {
          const { email, name, relationship, id, type } = mapKeyContact(keyContact);
          const checked = isContactSelected(selectedContacts, id, type);
          return (
            <ContactOption
              key={email + idx}
              onClick={() => onSelectContact({ id, type })}
              checked={checked}
              subTitle={relationship}
              title={name}
            />
          );
        })}
      </ContactContainer>
      {hasKeyContacts && <Separator $marginTop={'16px'} />}
      <ContactContainer>
        {relationships.map((_relationship, idx) => {
          const { email, name, relationship, id, type } = mapRelationship(_relationship.principalUser);
          const checked = isContactSelected(selectedContacts, id, type);
          return (
            <ContactOption
              key={email + idx}
              onClick={() => onSelectContact({ id, type })}
              checked={checked}
              subTitle={relationship}
              title={name}
            />
          );
        })}
      </ContactContainer>
      <Separator $marginTop={'16px'} />
      <Hint />
    </StyledDialogue>
  );
};

export default SendParentForApproval;
