import React from 'react';
import { MoreLessIcon } from '../index.style';
import { DropdownButton } from './index.style';
type ButtonWithDropdownIndicatorProps = {
  isOpen: boolean;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  placeholder?: string;
  iconStyle?: React.CSSProperties;
};

const ButtonWithDropdownIndicator = ({
  placeholder,
  isOpen,
  children,
  style,
  className,
  iconStyle,
}: ButtonWithDropdownIndicatorProps) => {
  return (
    <DropdownButton
      className={className}
      disableRipple
      style={style}
      endIcon={<MoreLessIcon style={iconStyle} open={isOpen} />}
    >
      {!!placeholder && !children ? (
        <div style={{ color: '#ABABB1', fontSize: 14, marginRight: 15 }}>{placeholder}</div>
      ) : (
        children
      )}
    </DropdownButton>
  );
};

export default ButtonWithDropdownIndicator;
