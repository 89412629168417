import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Caption } from 'components/atoms/typography';
import Modal from 'components/molecules/Modal';
import { SearchMission } from 'components/molecules/Autocomplete';

export default class SearchMissionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      missionSelected: props.missionSelected || null,
    };

    this.onMissionSelected = this.onMissionSelected.bind(this);
    this.submitSelectMission = this.submitSelectMission.bind(this);
  }

  onMissionSelected(missionSelected) {
    this.setState({ missionSelected });
  }

  submitSelectMission() {
    const { onSubmit } = this.props;
    const { missionSelected } = this.state;
    onSubmit(missionSelected);
  }

  render() {
    const { isOpen, onClose, onSecondarySubmit, userId, roadmapId } = this.props;

    const { missionSelected } = this.state;

    return (
      <Modal
        isOpen={isOpen}
        onSubmit={this.submitSelectMission}
        onSubmitDataTestId="submitSelectMissionButton"
        isSubmitDisabled={!missionSelected}
        onClose={onClose}
        onSecondarySubmit={onSecondarySubmit}
        title="Tag a mission"
        submitText="Save"
        secondarySubmitText="Close"
      >
        <Caption>Find a mission</Caption>
        <SearchMission
          dataTestId="searchMissionInput"
          roadmapId={roadmapId}
          userId={userId}
          missionSelected={missionSelected}
          onMissionSelected={this.onMissionSelected}
        />
      </Modal>
    );
  }
}

SearchMissionModal.propTypes = {
  userId: PropTypes.string,
  roadmapId: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSecondarySubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  missionSelected: PropTypes.object,
};
