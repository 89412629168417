import React, { useState, useContext, useMemo } from 'react';
import UniversityName from './UniversityName';
import Goal from './Goal';
import Round from './Round';
import Major from './Major';
import Deadline from './Deadline';
import ActionCell, { ActionCellProps } from './ActionCell';
import { Application } from '../type';
import { StyledTableRow } from './index.style';
import { UniversityNameProps } from './UniversityName';
import { ANIMATION_DURATION, getUniversityColleges } from '../util';
import { useEffect } from 'react';
import { AppTrackerContext } from '../viewController';
import { featureSwitches } from 'featureSwitches';

export type UniversityCardProps = {
  application: Application;
} & Pick<ActionCellProps, 'getExtraActionButtonProps' | 'getExtraDeleteAppButtonProps'> &
  Pick<UniversityNameProps, 'getExtraUniversityNameProps'>;

const UniversityCard = ({
  application,
  getExtraUniversityNameProps,
  getExtraActionButtonProps,
  ...restProps
}: UniversityCardProps) => {
  const [opacity, setOpacity] = useState(0);
  const [isAnimating, setIsAnimating] = useState(true);
  const { state } = useContext(AppTrackerContext);
  useEffect(() => {
    setTimeout(() => {
      setOpacity(1);
      setIsAnimating(false);
    }, Math.floor(Math.random() * ANIMATION_DURATION) + 100);
  }, []);
  useEffect(() => {
    if (state.unmountingAppIds.includes(application.id)) {
      setOpacity(0);
    } else {
      if (!isAnimating && !opacity) {
        setOpacity(1);
      }
    }
  }, [state.unmountingAppIds, application, isAnimating, opacity]);
  const hasColleges = useMemo(() => !!getUniversityColleges(application).length, [application]);
  return (
    <StyledTableRow
      $hasCollegeSelector={hasColleges && featureSwitches.NEW_APPLICATION_TRACKER_PHASE_3()}
      $opacity={opacity}
    >
      <UniversityName
        getExtraUniversityNameProps={getExtraUniversityNameProps}
        application={application}
        universityIconUrl={application.universityData?.logo}
      />
      <Goal application={application} goal={application.goal} />
      <Round application={application} round={application.round} />
      <Major applicationId={application.id} major={application.major} />
      <Deadline application={application} />
      <ActionCell
        application={application}
        hadCrimsonSupport={application.hadCrimsonSupport}
        group={application.group}
        getExtraActionButtonProps={getExtraActionButtonProps}
        {...restProps}
      />
    </StyledTableRow>
  );
};

export default UniversityCard;
