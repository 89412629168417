import styled from 'styled-components';
import { Column } from '../../index.style';

export const Container = styled(Column)`
  gap: 16px;
  margin-bottom: 30px;
  width: 360px;
`;

export const OptionContainer = styled(Column)`
  margin-top: 13px;
  gap: 6px;
  & :nth-child(1) {
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: #000000;
  }

  & :nth-child(2) {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #73747d;
    strong {
      font-weight: 600;
      color: inherit;
    }
  }
`;
