import { createSelector } from 'reselect';
import aggregateKeys from 'constants/aggregateKeys';

const aggregates = (state) => state.get('aggregate');

export const allAggregates = createSelector(aggregates, (aggregates) => aggregates.toJS());

export const getAggregate = (key) => {
  return createSelector(aggregates, (aggregates) => aggregates.get(key));
};

export const getAllPeopleCounts = createSelector(allAggregates, (aggregates) => ({
  [aggregateKeys.OUR_PEOPLE_ALL_TUTORS]: aggregates[aggregateKeys.OUR_PEOPLE_ALL_TUTORS],
  [aggregateKeys.OUR_PEOPLE_ALL_CASE_MANAGERS]: aggregates[aggregateKeys.OUR_PEOPLE_ALL_CASE_MANAGERS],
  [aggregateKeys.OUR_PEOPLE_RELATION_STUDENTS]: aggregates[aggregateKeys.OUR_PEOPLE_RELATION_STUDENTS],
  [aggregateKeys.OUR_PEOPLE_RELATION_TUTORS]: aggregates[aggregateKeys.OUR_PEOPLE_RELATION_TUTORS],
  [aggregateKeys.OUR_PEOPLE_ALL_USERS_ACTIVE]: aggregates[aggregateKeys.OUR_PEOPLE_ALL_USERS_ACTIVE],
  [aggregateKeys.OUR_PEOPLE_ALL_USERS_DEACTIVATED]: aggregates[aggregateKeys.OUR_PEOPLE_ALL_USERS_DEACTIVATED],
  [aggregateKeys.OUR_PEOPLE_RELATION_STUDENTS_AND_CONTRACT]:
    aggregates[aggregateKeys.OUR_PEOPLE_RELATION_STUDENTS_AND_CONTRACT],
  [aggregateKeys.OUR_PEOPLE_RELATION_STUDENTS_AND_CONTRACT_AS_HEAD_TUTOR]:
    aggregates[aggregateKeys.OUR_PEOPLE_RELATION_STUDENTS_AND_CONTRACT_AS_HEAD_TUTOR],
  [aggregateKeys.OUR_PEOPLE_RELATION_STUDENTS_AND_CONTRACT_AS_TUTOR]:
    aggregates[aggregateKeys.OUR_PEOPLE_RELATION_STUDENTS_AND_CONTRACT_AS_TUTOR],
}));
