import Immutable from 'immutable';
import { inAppMessageTypes } from '@crimson-education/common-config';
import createReducer from 'utils/createReducer';
import { updatePushNotification, clearPushNotifications, fetchPushNotifications } from 'utils/notifications';
import { ADD_ENTITIES, addEntities, addMeta, addEntity, compose } from 'ducks/normalizr';
import { hasNewFriend } from 'selectors/globalState';
import { getBookingForUser } from 'ducks/booking';
import { fetchContractById } from 'ducks/contract';
import { analyzeNotificationData, triggerAppcuesByFilterNotification } from 'ducks/appcuesNotification';
import { queryMyPendingFriends } from 'ducks/user';
import { updateHasNewFriendStatus } from 'ducks/globalState';
// This timeout is a work around for getting the latest booking from the frontend.
// Due to the way we are auditing/logging the event/booking transaction, the db write takes longer to complete
// than the firebase connection, so we use this timeout value to prevent the frontend from fetching a null event
// This should be removed when we remedy this issue for the backend.
const LIVE_UPDATE_TIMEOUT = 2000;

const initialState = new Immutable.Map();

// --------- Start of Live update stuff ---------
const checkAndUpdateBooking = (dispatch, data) => {
  if (data && data.type === inAppMessageTypes.BOOKING) {
    // Handles update booking time
    if (data.payload && Object.keys(data.payload).length) {
      if (data.payload.oldBookingId) {
        window.setTimeout(() => dispatch(getBookingForUser(data.payload.oldBookingId)), LIVE_UPDATE_TIMEOUT);
      }

      if (data.payload.eventId) {
        window.setTimeout(() => dispatch(getBookingForUser(data.payload.eventId)), LIVE_UPDATE_TIMEOUT);
      }
    }
  }
};

const checkAndUpdateAllocation = (dispatch, data) => {
  if (data && data.type === inAppMessageTypes.CONTRACT) {
    if (data.payload && data.payload.contractId) {
      window.setTimeout(() => dispatch(fetchContractById(data.payload.contractId)), LIVE_UPDATE_TIMEOUT);
    }
  }
};

// --------- End of live update stuff ---------

export default createReducer(initialState, {
  [ADD_ENTITIES]: (state, action) => {
    return state.mergeDeep(action.payload.entities.notification);
  },
});

export function loadNotifications(pageSize = 6) {
  return async (dispatch) => {
    const data = await fetchPushNotifications();
    const notificationsData = data
      .filter((n) => typeof n.notification === 'object') // filter out bad data
      .reduce((acc, cur) => {
        acc[cur.createdAt] = { timestamp: parseInt(cur.createdAt, 10), ...cur.notification };
        return acc;
      }, {});

    const newNotificationData = triggerAppcuesByFilterNotification(notificationsData);
    const sortedNotifications = Object.keys(newNotificationData).sort();
    dispatch(queryMyPendingFriends());

    dispatch(
      compose(
        addEntity('notification', newNotificationData),
        addMeta('lastLoadedKey', sortedNotifications[0] || ''),
        addMeta('hasMore', notificationsData.length >= pageSize),
      ),
    );

    return newNotificationData;
  };
}

export function processIncomingMessage({ data }) {
  return (dispatch, getState) => {
    if (data) {
      const event = JSON.parse(data);
      const { notification } = event;
      const shouldShow = analyzeNotificationData(notification);
      if (!shouldShow) {
        return;
      }
      dispatch(
        addEntities({
          entities: {
            notification: {
              [event.createdAt]: { timestamp: parseInt(event.createdAt, 10), ...event.notification },
            },
          },
          meta: {
            notifications: {
              new: event.notification ? 1 : 0,
              latest: event.notification || 0,
            },
          },
        }),
      );
      if (event.notification && event.notification.payload) {
        const actionName = event.notification.payload.actionName;
        if (actionName === 'FriendRequest') {
          const state = getState();
          const count = hasNewFriend(state);
          dispatch(updateHasNewFriendStatus(count + 1));
        }
      }
      checkAndUpdateBooking(dispatch, event.notification);
      checkAndUpdateAllocation(dispatch, event.notification);
    }
  };
}

export function clearNotifications() {
  return async (dispatch) => {
    await clearPushNotifications();
    dispatch(
      addEntities({
        entities: {},
        meta: {
          notifications: {
            new: 0,
          },
        },
      }),
    );
  };
}

export function setNotificationRead(notificationId) {
  return async (dispatch) => {
    if (notificationId) {
      const createdAt = notificationId;
      await updatePushNotification({
        createdAt,
        notification: { isRead: true },
      });

      dispatch(
        addEntities({
          entities: {
            notification: {
              [createdAt]: {
                isRead: true,
              },
            },
          },
        }),
      );
    }
  };
}
