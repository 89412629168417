import React, { useState, useRef, useEffect } from 'react';

type DraggableProps = {
  onMouseDown: React.MouseEventHandler<HTMLDivElement>;
  onMouseUp: React.MouseEventHandler<HTMLDivElement>;
  onMouseMove: React.MouseEventHandler<HTMLDivElement>;
};

type DraggableWrapperProps = {
  targetElementId: string;
  children: React.ReactNode | ((props: DraggableProps) => React.ReactNode);
};

const DraggableWrapper = ({ targetElementId, children }: DraggableWrapperProps) => {
  const [active, setActive] = useState(false);
  const originClientX = useRef(0);
  const originClientY = useRef(0);
  const targetElm = useRef<HTMLElement | null>(null);
  const onMouseDown: React.MouseEventHandler<HTMLDivElement> = (e) => {
    setActive(true);
    originClientX.current = e.clientX;
    originClientY.current = e.clientY;
    const elm = document.getElementById(targetElementId);
    targetElm.current = elm;
  };
  const onMouseUp = () => {
    setActive(false);
  };
  useEffect(() => {
    document.onmouseup = onMouseUp;
    return () => {
      document.onmouseup = null;
    };
  }, []);
  const onMouseMove: React.MouseEventHandler<HTMLDivElement> = (e) => {
    if (!targetElm.current || !active) return;
    e.preventDefault();
    // calculate the new cursor position:
    const clientXDiff = originClientX.current - e.clientX;
    const clientYDiff = originClientY.current - e.clientY;
    originClientX.current = e.clientX;
    originClientY.current = e.clientY;
    targetElm.current.offsetTop;
    // set the element's new position:
    targetElm.current.style.top = targetElm.current.offsetTop - clientYDiff + 'px';
    targetElm.current.style.left = targetElm.current.offsetLeft - clientXDiff + 'px';
  };
  return typeof children === 'function' ? children({ onMouseDown, onMouseUp, onMouseMove }) : children;
};

export default DraggableWrapper;
