import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'auto-bind';
import classNames from 'classnames';
import { trimSpecialCharacters } from 'utils/utils';

import { MenuList, MenuItem, ClickAwayListener, Grow, Paper, Popper, IconButton } from '@material-ui/core';

import * as Icons from 'components/atoms/icons';
import { Body } from 'components/atoms/typography';

import css from './styles.scss';

export default class PopoverMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };

    autoBind(this);
  }

  handleToggle() {
    this.setState((state) => ({ open: !state.open }));
  }

  handleClose(event) {
    if (this.anchorEl.contains(event.target)) {
      return;
    }

    this.setState({ open: false });
  }

  render() {
    const { icon, items, className, placement } = this.props;
    const { open } = this.state;

    const Icon = Icons[icon];

    return (
      <Fragment>
        <IconButton
          buttonRef={(node) => {
            this.anchorEl = node;
          }}
          aria-owns={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={this.handleToggle}
          data-test-id={this.props.dataTestId}
          className={classNames(css.iconButton, className)}
          data-ga-label={icon}
        >
          <Icon style={{ height: '2rem', width: '2rem' }} />
        </IconButton>
        <Popper open={open} anchorEl={this.anchorEl} transition disablePortal placement={placement}>
          {({ TransitionProps }) => (
            <Grow {...TransitionProps} id="menu-list-grow">
              <Paper className={css.menuWrapper}>
                <ClickAwayListener onClickAway={this.handleClose}>
                  <MenuList className={css.menu}>
                    {items.map((i, idx) => {
                      return (
                        <MenuItem
                          role="button"
                          className={css.menuItem}
                          key={idx}
                          data-test-id={`MenuItem_${trimSpecialCharacters(i.label)}`}
                          onClick={(e) => {
                            i.action(e);
                            this.handleClose(e);
                          }}
                        >
                          <Body className={css.labelText}>{i.label}</Body>
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Fragment>
    );
  }
}

const menuItemProp = PropTypes.shape({
  label: PropTypes.string,
  action: PropTypes.func,
});

PopoverMenu.propTypes = {
  items: PropTypes.arrayOf(menuItemProp),
  icon: PropTypes.string,
  className: PropTypes.string,
  placement: PropTypes.string,
  dataTestId: PropTypes.string,
};

PopoverMenu.defaultProps = {
  items: [],
  icon: 'MoreVert',
  placement: 'bottom-end',
};
