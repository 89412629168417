import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Body, Caption } from 'components/atoms/typography';

import css from './styles.scss';

export default function InvoiceSingleLine({ label, value, className, bold, caption }) {
  return (
    <div className={classNames(css.invoiceLine, className)}>
      <Body bold={bold}>{label}</Body>
      <div>
        {caption && <Caption>{caption}</Caption>}
        <Body className={css.monies} bold={bold}>
          {value}
        </Body>
      </div>
    </div>
  );
}

InvoiceSingleLine.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
  bold: PropTypes.bool,
  caption: PropTypes.string,
};
