import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';
import { gql } from '@apollo/client';
import { createClient } from '../utils';

const { api } = getConfig();

const client = createClient(api.graphQLEndpoint);

const DriveFileType = gql`
  fragment DriveFileType on DriveFile {
    id
    name
    mimeType
    webViewLink
    iconLink
    modifiedTime
    createdTime
  }
`;

const sessionResources = gql`
  ${DriveFileType}
  query sessionResources($eventId: String!, $studentId: String!) {
    sessionResources(eventId: $eventId, studentId: $studentId) {
      ...DriveFileType
    }
  }
`;

const createFileForSession = gql`
  ${DriveFileType}
  mutation createFileForSession($eventId: String!, $type: String!, $name: String!, $studentId: String) {
    createFileForSession(eventId: $eventId, type: $type, name: $name, studentId: $studentId) {
      ...DriveFileType
    }
  }
`;

const deleteSessionResource = gql`
  mutation deleteSessionResource($eventId: String!, $id: ID!, $studentId: String) {
    deleteSessionResource(eventId: $eventId, id: $id, studentId: $studentId)
  }
`;

export default {
  sessionResources: async (eventId, studentId) => {
    return client.query(sessionResources, { eventId, studentId });
  },
  createCollaborativeFileInSession: async (eventId, name, type, studentId) => {
    return client.query(createFileForSession, { eventId, name, type, studentId });
  },
  deleteCollaborativeFileFromSession: async (eventId, id, studentId) => {
    return client.query(deleteSessionResource, { eventId, id, studentId });
  },
};
