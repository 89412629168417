import get from 'lodash/get';
import Immutable from 'immutable';
import createReducer from 'utils/createReducer';
import { toasterMessages } from '@crimson-education/common-config';
import { formatGraphQLRequestError } from 'utils/graphql';
import componentKeys from 'constants/componentKeys';
import contractService from 'graphql/api/contract';
import { ADD_ENTITIES, addEntitiesWithNormalisation, compose } from 'ducks/normalizr';
import { formLoading, formSuccess, formFail, updateMeta } from 'ducks/meta';
import { archiveContractSucceeded, sendInvitationSucceeded, fetchPackageByUser } from 'ducks/package';
import { contractEntity, packageItemEntity } from 'schema';

const initialState = Immutable.fromJS({});

export default createReducer(initialState, {
  [ADD_ENTITIES]: (state, action) => {
    return state.mergeDeep(action.payload.entities.contract);
  },
});

let fetchAllClientsPromise;
export function fetchAllClients() {
  return (dispatch) => {
    if (!fetchAllClientsPromise) {
      dispatch(updateMeta(componentKeys.CLIENTS_FETCHED, false));
      fetchAllClientsPromise = contractService
        .myContracts()
        .then(({ myContracts }) => {
          dispatch(addEntitiesWithNormalisation(myContracts, [contractEntity]));
          dispatch(updateMeta(componentKeys.CLIENTS_FETCHED, true));
          fetchAllClientsPromise = undefined;
        })
        .catch((err) => {
          // Permission denied error.
          dispatch(formFail(formatGraphQLRequestError(err)));
        });
    }
    return fetchAllClientsPromise;
  };
}

export function fetchClientsForUserId(userId) {
  return (dispatch) => {
    dispatch(updateMeta(componentKeys.CLIENTS_FETCHED, false));
    return contractService
      .getContractsByUserId(userId)
      .then(({ getContractsByUserId }) => {
        dispatch(addEntitiesWithNormalisation(getContractsByUserId, [contractEntity]));
        dispatch(updateMeta(componentKeys.CLIENTS_FETCHED, true));
      })
      .catch(() => {
        // Permission denied error.
        dispatch(formFail('Could not fetch clients for user.'));
      });
  };
}

export function fetchClientForItemId(itemId) {
  return (dispatch) => {
    return contractService
      .getContractsByItemId(itemId)
      .then(({ getContractsByItemId }) => {
        dispatch(addEntitiesWithNormalisation(getContractsByItemId[0], contractEntity));
      })
      .catch(() => {
        dispatch(formFail('Could not fetch client for item.'));
      });
  };
}

export function fetchContractById(contractId) {
  return (dispatch) => {
    dispatch(updateMeta(componentKeys.CLIENTS_FETCHED, false));
    return contractService
      .getContractById(contractId)
      .then(({ getContractById }) => {
        dispatch(addEntitiesWithNormalisation(getContractById, contractEntity));
        dispatch(updateMeta(componentKeys.CLIENTS_FETCHED, true));
      })
      .catch(() => {
        // Permission denied error.
        dispatch(formFail('Could not fetch contract for user.'));
      });
  };
}

export function acceptContract(contractId) {
  return (dispatch) => {
    dispatch(formLoading());
    return contractService
      .acceptContract(contractId)
      .then(() => {
        dispatch(fetchAllClients());
        dispatch(formSuccess(toasterMessages.allocationAccepted()));
      })
      .catch((err) => dispatch(formFail(formatGraphQLRequestError(err, toasterMessages.allocationNotAccepted()))));
  };
}

export function declineContract(contractId, reason) {
  return (dispatch) =>
    contractService
      .declineContract(contractId, reason)
      .then(() => {
        dispatch(fetchAllClients());
        dispatch(formSuccess(toasterMessages.allocationDeclined()));
      })
      .catch(() => dispatch(formFail(toasterMessages.allocationNotDeclined())));
}

/**
 * removes a contract with a given Id
 *
 * @param contractId
 * @returns {function(*)}
 */
export function archiveContract(contractId) {
  return (dispatch) => {
    dispatch(formLoading());

    return contractService
      .archiveContract(contractId)
      .then((response) => {
        const contract = response.archiveContract;
        const tutor = `${get(contract, 'user.firstName')} ${get(contract, 'user.lastName')}`;
        const student = `${get(contract, 'item.package.user.firstName')} ${get(
          contract,
          'item.package.user.lastName',
        )}`;

        if (contract && contract.item && contract.item.package) {
          dispatch(
            archiveContractSucceeded({
              studentId: contract.item.package.userId,
              contractId: contract.id,
              itemId: contract.itemId,
            }),
          );
        }

        dispatch(formSuccess(toasterMessages.allocationRemoved(tutor, student)));
      })
      .catch((error) =>
        dispatch(formFail([toasterMessages.userSubjectNotUpdated(), formatGraphQLRequestError(error)])),
      );
  };
}

/**
 * removes a contract with a given Id
 *
 * @param contractId
 * @returns {function(*)}
 */
export function deleteOrRetractContract(contractId) {
  return (dispatch) => {
    dispatch(formLoading());

    return contractService
      .deleteOrRetractContract(contractId)
      .then((response) => {
        const contract = response.deleteOrRetractContract;
        const tutor = `${get(contract, 'user.firstName')} ${get(contract, 'user.lastName')}`;
        const student = `${get(contract, 'item.package.user.firstName')} ${get(
          contract,
          'item.package.user.lastName',
        )}`;

        if (contract && contract.item && contract.item.package) {
          dispatch(
            archiveContractSucceeded({
              // TODO: Need to update this, but for now does what we need
              studentId: contract.item.package.userId,
              contractId: contract.id,
              itemId: contract.itemId,
            }),
          );
        }

        dispatch(addEntitiesWithNormalisation(contract, contractEntity));
        dispatch(formSuccess(toasterMessages.allocationRemoved(tutor, student)));
      })
      .catch((error) => dispatch(formFail([toasterMessages.allocationNotRemoved(), formatGraphQLRequestError(error)])));
  };
}

/**
 * creates a contract in the pending state
 *
 * @param data
 * @returns {function(*)}
 */
export function sendSubjectInvitation(data) {
  return (dispatch) => {
    dispatch(formLoading());
    return contractService
      .createContract(data)
      .then((res) => {
        dispatch(
          sendInvitationSucceeded({
            userId: data.userId,
            contract: res.createContract,
          }),
        );
        dispatch(formSuccess(toasterMessages.allocationRequested()));
      })
      .catch((error) => dispatch(formFail(['Error', formatGraphQLRequestError(error)])));
  };
}

export function sendSubjectInvitations(data) {
  return (dispatch) => {
    dispatch(formLoading());
    return contractService
      .createContracts(data)
      .then(({ createContracts }) => {
        dispatch(
          compose(
            addEntitiesWithNormalisation(createContracts.contracts, [contractEntity]),
            addEntitiesWithNormalisation(createContracts.item, packageItemEntity),
          ),
        );
        dispatch(fetchPackageByUser(data.studentId));
        dispatch(formSuccess(toasterMessages.allocationRequested()));
      })
      .catch((error) => dispatch(formFail(['Error', formatGraphQLRequestError(error)])));
  };
}

export function fetchSubjectInvitationsByItem(data) {
  return (dispatch) => {
    return contractService
      .getContractsByItemId(data)
      .then((res) => {
        dispatch(addEntitiesWithNormalisation(res.getContractsByItemId, [contractEntity]));
      })
      .catch((error) => dispatch(formFail(['Error', formatGraphQLRequestError(error)])));
  };
}
