import Immutable from 'immutable';
import createReducer from 'utils/createReducer';
import { ADD_ENTITIES, addEntitiesWithNormalisation } from 'ducks/normalizr';
import progressReportStatus from 'constants/progressReportStatus';
import { progressReportEntity } from 'schema';
import { modalSuccess, formFail } from 'ducks/meta';
import progressReportApi from 'graphql/api/progressReport';
import { formatGraphQLRequestError } from 'utils/graphql';

const SET_HTML = 'progressReport/SET_HTML';
const UPDATE_REPORT = 'progressReport/UPDATE_REPORT';
const REPORT_SENT = 'progressReport/REPORT_SENT';

const initialState = new Immutable.Map();

export default createReducer(initialState, {
  [ADD_ENTITIES]: (state, action) => {
    return state.mergeDeep(action.payload.entities.progressReport);
  },
  [SET_HTML]: (state, action) => {
    const { html, reportId } = action.payload;
    return state.setIn([reportId, 'html'], html);
  },
  [UPDATE_REPORT]: (state, action) => {
    const { id } = action.payload;
    return state.mergeIn([id], action.payload);
  },
  [REPORT_SENT]: (state, action) => {
    const { reportId } = action.payload;
    return state.setIn([reportId, 'status'], progressReportStatus.SENT);
  },
});

export function updateProgressReportWithHtml(reportId, html) {
  return { type: SET_HTML, payload: { html, reportId } };
}

export function updateProgressReportSucceeded(report) {
  return { type: UPDATE_REPORT, payload: report };
}

export function sendProgressReportSucceeded(reportId) {
  return { type: REPORT_SENT, payload: { reportId } };
}

export function fetchProgressReportsByUserId(userId) {
  return async (dispatch) => {
    const res = await progressReportApi.getProgressReportsByUserId(userId);
    dispatch(addEntitiesWithNormalisation(res.progressReports, [progressReportEntity]));
  };
}

export function fetchProgressReportById(id) {
  return async (dispatch) => {
    const res = await progressReportApi.getProgressReportById(id);
    dispatch(addEntitiesWithNormalisation(res.progressReport, progressReportEntity));
  };
}

export function sendProgressReport(reportId, sendCopy, contactIds) {
  return async (dispatch) => {
    try {
      await progressReportApi.sendProgressReport(reportId, sendCopy, contactIds);
      dispatch(modalSuccess('Progress Report was successfully sent'));
      dispatch(sendProgressReportSucceeded(reportId));
    } catch (error) {
      dispatch(formFail(formatGraphQLRequestError(error)));
    }
  };
}

export function getProgressReportHtml(reportId) {
  return async (dispatch) => {
    try {
      const res = await progressReportApi.getProgressReportHtml(reportId);
      dispatch(updateProgressReportWithHtml(reportId, res.html));
    } catch (error) {
      dispatch(formFail(formatGraphQLRequestError(error)));
    }
  };
}

export function updateProgressReport(report) {
  return async (dispatch) => {
    try {
      const res = await progressReportApi.updateProgressReport(report);
      dispatch(updateProgressReportSucceeded(res.report));
    } catch (error) {
      dispatch(formFail(formatGraphQLRequestError(error)));
    }
  };
}
