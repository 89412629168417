import styled from 'styled-components';
import TableRow from '@material-ui/core/TableRow';
import { ANIMATION_DURATION } from '../util';

export const StyledTableRow = styled(TableRow)<{ $opacity: number; $hasCollegeSelector?: boolean }>`
  background: #ffffff;
  box-sizing: border-box;
  opacity: ${({ $opacity }) => $opacity};
  transition-duration: ${ANIMATION_DURATION}ms;
  transition-property: opacity, border-color;
  border: 0 solid;
  border-color: ${({ $opacity }) => ($opacity === 1 ? 'rgb(224, 224, 224)' : 'transparent')};
  border-bottom-width: 1px;
  td {
    border: none;
  }

  td:nth-child(n + 2):nth-child(-n + 5) {
    padding-bottom: ${({ $hasCollegeSelector }) => ($hasCollegeSelector ? '35px' : '12px')};
    padding-top: 12px;
  }

  td:first-child {
    padding-top: ${({ $hasCollegeSelector }) => ($hasCollegeSelector ? '10px' : '0')};
  }

  td:last-child {
    padding-bottom: ${({ $hasCollegeSelector }) => ($hasCollegeSelector ? '18px' : '0')};
  }
`;
