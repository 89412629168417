import React from 'react';
import PropTypes from 'prop-types';
import { getTimezoneDisplayName } from 'utils/timezones';
import SubItem from 'components/unique/ProfilePage/SubItem';
import css from 'components/unique/ProfilePage/profileItem.scss';

export default function Timezone(props) {
  const { timezone, autoTimezone } = props;

  return (
    <div className={css.itemBody}>
      {timezone ? (
        <SubItem
          name="Timezone"
          item={`${getTimezoneDisplayName(timezone)}${autoTimezone ? ' (Automatically detected)' : ''}`}
        />
      ) : (
        <div className={css.itemEmptyText}>Timezone hasn’t been added yet.</div>
      )}
    </div>
  );
}

Timezone.displayName = 'Timezone';

Timezone.propTypes = {
  timezone: PropTypes.string,
  autoTimezone: PropTypes.bool,
};
