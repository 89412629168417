import jwtDecode from 'jwt-decode';
import registerAppleDeviceToken from 'graphql/api/registerIOSToken';

function register(deviceToken) {
  if (!deviceToken) return;

  const decodedIdToken = jwtDecode(localStorage.getItem('auth.idToken'));
  const userId = decodedIdToken.sub.replace('|', '-');

  if (!userId) return;

  registerAppleDeviceToken({ deviceToken, userId });
}

export default register;
