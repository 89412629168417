import { connect } from 'react-redux';
import { compose } from 'redux';
import { getCachedContactListPageInfo } from 'selectors/meta';
import { fetchingUserContacts } from 'ducks/user';
import { getFilteredContacts, getLoginUser } from 'selectors/user';
import { unwrapImmutable } from 'utils/utils';
import { getThreads } from 'ducks/messaging';
import { withAppContext } from 'components/enhancers/AppContext';
import CreateNewChatModal from './index.js';

export default compose(
  connect(
    (state) => ({
      contactPageInfo: getCachedContactListPageInfo(state).toJS(),
      contacts: getFilteredContacts(state).toList().toJS(),
      loginUserId: unwrapImmutable(getLoginUser(state)).userId,
      threads: getThreads(state).toJS(),
    }),
    (dispatch) => ({
      setContactsFilter: (filter) => dispatch(fetchingUserContacts(filter)),
    }),
  ),
)(withAppContext(CreateNewChatModal));
