import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CommentField } from 'components/generic/Forms';

import css from './styles.scss';

export default class Form extends Component {
  constructor(props) {
    super(props);

    this.state = {
      summary: props.summary,
    };

    this.handleSaveClicked = this.handleSaveClicked.bind(this);
    this.handleCancelClicked = this.handleCancelClicked.bind(this);
    this.handleChanged = this.handleChanged.bind(this);
  }

  handleSaveClicked() {
    const { onSaveInfo } = this.props;
    const { summary } = this.state;

    const details = {
      summary,
    };

    onSaveInfo(details);
  }

  handleCancelClicked() {
    this.props.onCancel();
  }

  handleChanged(value) {
    this.setState({
      summary: value,
    });
  }

  render() {
    const { summary } = this.props;

    return (
      <div>
        <CommentField
          id="summary"
          placeholder="Enter lesson summary"
          defaultValue={summary || ''}
          onValueChange={this.handleChanged}
        />
        <footer className={css.footer}>
          <button className={css.cancelButton} onClick={this.handleCancelClicked}>
            Cancel
          </button>
          <button className={css.saveButton} onClick={this.handleSaveClicked}>
            Save
          </button>
        </footer>
      </div>
    );
  }
}

Form.displayName = 'LessonSummaryForm';

Form.propTypes = {
  summary: PropTypes.string,
  onSaveInfo: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
};
