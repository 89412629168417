import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectUserPermissions } from 'selectors/meta';

const UNAUTHORISED_ROUTE = '/unauthorized';

/**
 * Checks if the logged in user has legacy permission ids.
 * @param {string[]} permissions Legacy permission ids.
 * @param {boolean} some Only needs some of the permissions?
 * @deprecated Use components/wrappers/HasPermission for HOC or hooks/usePermissionCheck for Functional Components.
 */
export default function AuthorizationCheck(permissions, some = false) {
  return (Component) => {
    return function Check(props) {
      const history = useHistory();
      const userPermissions = useSelector(selectUserPermissions);

      let isPermitted = false;

      // Check a permission against the user's permissions.
      const checkPermission = (permission) => {
        return userPermissions.includes(permission);
      };

      // Check single permission.
      if (typeof permissions === 'string') {
        isPermitted = checkPermission(permissions);
      }

      // Check multiple permissions.
      if (Array.isArray(permissions)) {
        if (some) {
          isPermitted = permissions.some(checkPermission);
        } else {
          isPermitted = permissions.every(checkPermission);
        }
      }

      if (isPermitted) {
        return <Component {...props} />;
      }
      history.replace(UNAUTHORISED_ROUTE);
      return null;
    };
  };
}

/**
 * Checks if the logged in user has some legacy permission ids.
 * @param {string[]} permissions Legacy permission ids.
 * @deprecated Use components/wrappers/HasPermission for HOC or hooks/usePermissionCheck for Functional Components.
 */
export function SomeAuthorisationCheck(permissions) {
  return AuthorizationCheck(permissions, true);
}
