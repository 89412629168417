import Immutable from 'immutable';

import createReducer from 'utils/createReducer';
import {
  FETCH_THREADS,
  FETCH_THREADS_ERROR,
  FETCH_THREADS_COMPLETE,
  FETCH_COMMUNITY_THREADS,
  FETCH_COMMUNITY_THREADS_COMPLETE,
  PREPARE_THREAD,
  SEND_MESSAGE,
  SEND_MESSAGE_COMPLETE,
  RETRY_MESSAGE,
  FETCH_MESSAGES,
  FETCH_MESSAGES_COMPLETE,
  CONSUME_THREAD,
  CONSUME_THREAD_COMPLETE,
  SET_USER_IS_TYPING,
  SET_USER_IS_TYPING_END,
  SET_USER_STATUS,
  CONSUME_MESSAGE,
  UPDATE_CONVERSATION,
  UPLOAD_FAIL,
  UPDATE_CHAT_NAME,
  WITHDRAW_MESSAGE,
  UPDATE_THREAD,
  UPDATE_THREAD_PEOPLE,
  UPDATE_THREAD_PARTICIPANT,
  REMOVE_THREAD_PARTICIPANT,
  UPDATE_THREAD_USERS,
  REMOVE_THREAD,
  INACTIVE_THREAD_PARTICIPANT,
} from './actions';

const initialState = Immutable.fromJS({
  isFetchingThreads: false,
  consumingThreads: {},
  entities: {
    thread: {},
    threadParticipant: {},
    user: {},
    message: {},
  },
  threadPageInfo: {
    hasNextPage: true,
  },
  communityThreadPageInfo: {
    hasNextPage: true,
  },
  onlineUsers: [],
  conversations: {},
  currentThread: {},
});

export default createReducer(initialState, {
  [FETCH_THREADS]: (state) => state.set('isFetchingThreads', true),
  [FETCH_THREADS_ERROR]: (state) => state.set('isFetchingThreads', false),
  [FETCH_THREADS_COMPLETE]: (state, action) => state.set('isFetchingThreads', false).mergeDeep(action.payload),
  [FETCH_COMMUNITY_THREADS]: (state) => state.set('isFetchingCommunityThreads', true),
  [FETCH_COMMUNITY_THREADS_COMPLETE]: (state, action) =>
    state.set('isFetchingCommunityThreads', false).mergeDeep(action.payload),
  [PREPARE_THREAD]: (state, action) => state.mergeDeep(action.payload),

  [SEND_MESSAGE]: (state, action) => state.mergeDeep(action.payload),
  [SEND_MESSAGE_COMPLETE]: (state, action) =>
    state
      .mergeDeep(action.payload.thread)
      .mergeDeep(action.payload.message)
      .deleteIn(['entities', 'thread', action.payload.removeThread]),
  [RETRY_MESSAGE]: (state, action) => state.deleteIn(['entities', 'message', action.payload.messageId]),
  [FETCH_MESSAGES]: (state, action) =>
    state.setIn(['entities', 'thread', action.payload.threadId, 'isFetchingMessages'], true),
  [FETCH_MESSAGES_COMPLETE]: (state, action) => state.mergeDeep(action.payload),

  [CONSUME_THREAD]: (state, action) => state.setIn(['consumingThreads', action.payload.threadId], true),
  [CONSUME_THREAD_COMPLETE]: (state, action) =>
    state
      .mergeDeep(action.payload.data)
      .setIn(['consumingThreads', action.payload.threadId], false)
      .setIn(['entities', 'thread', action.payload.threadId, 'lastMessageIsRead'], true)
      .setIn(['entities', 'thread', action.payload.threadId, 'lastSeenMessageId'], action.payload.lastSeenMessageId),
  [SET_USER_IS_TYPING]: (state, action) =>
    state.updateIn(['entities', 'user'], (users) =>
      users.map((x) => {
        if (x.get('userId') === action.payload.userId) {
          return x.mergeDeep(action.payload.typing);
        }
        return x;
      }),
    ),
  [SET_USER_IS_TYPING_END]: (state, action) =>
    state.setIn(['entities', 'user', action.payload.userId, 'threads', action.payload.threadId, 'isTyping'], false),
  [SET_USER_STATUS]: (state, action) => state.set('onlineUsers', action.payload.users),
  [CONSUME_MESSAGE]: (state, action) =>
    state
      .setIn(
        ['entities', 'threadParticipant', action.payload.thread, 'lastSeenMessageId'],
        action.payload.lastSeenMessageId,
      )
      .setIn(['entities', 'thread', action.payload.threadId, 'lastSeenMessageId'], action.payload.lastSeenMessageId),
  [UPDATE_CONVERSATION]: (state, action) => state.updateIn(['conversations'], (x) => x.mergeDeep(action.payload)),
  [UPLOAD_FAIL]: (state, action) =>
    state.setIn(['entities', 'message', action.payload.messageId, 'uploadStatus'], false),
  [UPDATE_CHAT_NAME]: (state, action) =>
    state.setIn(['entities', 'thread', action.payload.threadId, 'chatName'], action.payload.chatName),
  [WITHDRAW_MESSAGE]: (state, action) =>
    state.setIn(['entities', 'message', action.payload.messageId, 'withdraw'], action.payload.withdraw),
  [UPDATE_THREAD]: (state, action) =>
    state
      .setIn(
        ['entities', 'thread', action.payload.messagePayload.threadId, 'lastMessageWithdraw'],
        action.payload.messagePayload.withdraw,
      )
      .setIn(
        ['entities', 'thread', action.payload.messagePayload.threadId, 'lastMessageSentAt'],
        action.payload.messagePayload.createdAt,
      )
      .setIn(
        ['entities', 'thread', action.payload.messagePayload.threadId, 'lastMessage'],
        action.payload.messagePayload.message,
      )
      .setIn(
        ['entities', 'thread', action.payload.messagePayload.threadId, 'lastMessageType'],
        action.payload.messagePayload.type,
      )
      .setIn(
        ['entities', 'thread', action.payload.messagePayload.threadId, 'lastSeenMessageId'],
        action.payload.messagePayload.messageId,
      ),
  [UPDATE_THREAD_PEOPLE]: (state, action) =>
    state.setIn(['entities', 'thread', action.payload.threadId, 'participants'], action.payload.participants),
  [UPDATE_THREAD_PARTICIPANT]: (state, action) =>
    state.updateIn(['entities', 'threadParticipant'], (x) => x.mergeDeep(action.payload.threadParticipants)),
  [REMOVE_THREAD_PARTICIPANT]: (state, action) =>
    state.deleteIn(['entities', 'threadParticipant', action.payload.participant]),
  [UPDATE_THREAD_USERS]: (state, action) =>
    state.updateIn(['entities', 'user'], (x) => x.mergeDeep(action.payload.newUsers)),
  [REMOVE_THREAD]: (state, action) => state.deleteIn(['entities', 'thread', action.payload.removeThread]),
  [INACTIVE_THREAD_PARTICIPANT]: (state, action) =>
    state.setIn(['entities', 'threadParticipant', action.payload.threadParticipantId, 'active'], false),
});
