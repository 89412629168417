import React from 'react';
import { ContactCardContainer, NameContainer, IconBackground, IconContainer } from './index.style';
import TextsmsOutlinedIcon from '@material-ui/icons/TextsmsOutlined';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import { Row } from '../../index.style';
import Avatar from './Avatar';
import { getUserDisplayName } from '../util';
import IconWithTooltip from './IconWithTooltip';

type ContactCardProps = {
  avatar: string;
  firstName: string;
  lastName: string;
  title?: string;
  onClickContacts?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onClickMessage: () => void;
  onClickName: () => void;
  enableTooltip?: boolean;
};
const ContactCard = ({
  avatar,
  firstName,
  lastName,
  title,
  onClickMessage,
  onClickContacts,
  onClickName,
  enableTooltip = true,
}: ContactCardProps) => {
  const name = getUserDisplayName({ firstName, lastName });
  return (
    <ContactCardContainer>
      <Row style={{ cursor: 'pointer' }} gap={'12px'} rowcenter onClick={onClickName}>
        <Avatar size={40} firstName={firstName} lastName={lastName} src={avatar} />
        <NameContainer>
          <div>{name}</div>
          {!!title && <div>{title}</div>}
        </NameContainer>
      </Row>
      <IconContainer>
        {onClickContacts && (
          <IconBackground onClick={onClickContacts}>
            <IconWithTooltip
              enableTooltip={enableTooltip}
              icon={
                <PeopleAltOutlinedIcon
                  style={{
                    color: '#464ac9',
                    fontSize: 20,
                  }}
                />
              }
              hint={'Crimson Team'}
            />
          </IconBackground>
        )}
        <IconBackground onClick={onClickMessage}>
          <IconWithTooltip
            enableTooltip={enableTooltip}
            icon={
              <TextsmsOutlinedIcon
                style={{
                  color: '#464ac9',
                  fontSize: 20,
                }}
              />
            }
            hint={'Message'}
          />
        </IconBackground>
      </IconContainer>
    </ContactCardContainer>
  );
};

export default ContactCard;
