import React from 'react';
import PropTypes from 'prop-types';

export default function PastStudents(props) {
  return (
    <svg viewBox="0 0 152.71 107.19" {...props}>
      <g data-name="Layer 2">
        <g data-name="Layer 1">
          <ellipse cx={72.02} cy={102.61} rx={36.04} ry={4.58} fill="#f2f3f6" />
          <path
            d="M115.68 36.18c0-.89-.07-1.9-1.1-2.11a4.68 4.68 0 0 0-2.29.65l-2.27.89c0-2-.1-3.78-.18-4.3a30.81 30.81 0 0 0-61.62 0v.13c0 1.45.35 16-1.36 19.19-1.33 2.46-7.39 12.86-8.59 18.57-.43 2 .67 4.6 2.91 4.65 4.27.1 4.55-1.94 7.76-2s4.75 3.2 9.6 3.2c5.23 0 7.53-3.19 12.45-3.19 2.28 0 6.36 3.77 11.89 3.77C86.39 75.6 89 72 93.7 72c4.15 0 4.22 3.12 8.29 3 2.75-.09 3.2-2.07 4.09-5.19 1.54-5.42 3-16.13 3.7-24.65 3.22-1.97 5.94-4.99 5.9-8.98z"
            fill="#e3e7ed"
          />
          <circle cx={83.86} cy={31.66} r={2.1} fill="#506a88" />
          <circle cx={100.45} cy={31.66} r={2.1} fill="#506a88" />
          <path
            d="M89 38a4.73 4.73 0 0 0 6.69 0"
            fill="none"
            stroke="#506a88"
            strokeLinecap="round"
            strokeMiterlimit={10}
          />
          <path
            d="M76 57.18a93.87 93.87 0 0 0-.37-10c0-.64-1-.67-1 0 .07 1.78.14 3.57.2 5.35 0 1.29.91 5.73-1 6.16-1.44.32-3-.8-3.92-1.78a12 12 0 0 1-1.65-2.54 26.39 26.39 0 0 1-2.06-5.63.5.5 0 0 0-1 .24c.91 3.63 3.19 11 8.06 10.78A2.58 2.58 0 0 0 76 57.18z"
            fill="#506a88"
          />
          <path
            fill="none"
            stroke="#e3e7ed"
            strokeLinecap="round"
            strokeMiterlimit={10}
            d="M10.79 56.75v4.43M13.01 58.96H8.57"
          />
          <path
            fill="none"
            stroke="#83a0bc"
            strokeLinecap="round"
            strokeMiterlimit={10}
            d="M2.72 32.12v4.43M4.94 34.33H.5"
          />
          <path
            fill="none"
            stroke="#dadae0"
            strokeLinecap="round"
            strokeMiterlimit={10}
            d="M27.18 18.98v4.44M29.4 21.2h-4.43"
          />
          <path
            fill="none"
            stroke="#e3e7ed"
            strokeLinecap="round"
            strokeMiterlimit={10}
            d="M127.91 16.47v-4.59M125.62 14.18h4.59"
          />
          <path
            fill="none"
            stroke="#83a0bc"
            strokeLinecap="round"
            strokeMiterlimit={10}
            d="M149.91 41.63v-4.59M147.61 39.33h4.6"
          />
          <path
            fill="none"
            stroke="#dadae0"
            strokeLinecap="round"
            strokeMiterlimit={10}
            d="M132.51 59.28v-4.59M130.21 56.99h4.59"
          />
          <path
            d="M109.64 45.16c-.72 8.52-2.15 19.23-3.7 24.65-.89 3.12-1.34 5.1-4.09 5.19-4.07.13-4.13-3-8.29-3-4.71 0-7.31 3.59-10.82 3.59-5.53 0-9.61-3.77-11.89-3.77-4.92.01-7.21 3.18-12.45 3.18-4.85 0-6.41-3.3-9.6-3.2S45.31 74 41 73.86c-2.24-.06-3.34-2.63-2.91-4.66 1.2-5.71 7.33-14 8.59-18.58 1-3.47 1.4-17.73 1.36-19.18v-.13A30.8 30.8 0 0 1 78.89.5c17 0 30.32 13.44 30.81 30.81 0 .51.14 2.21.18 4.12l2.55-1a4.67 4.67 0 0 1 2.28-.65c1 .21 1.1 1.22 1.11 2.11a9.23 9.23 0 0 1-2.9 6.63 15.51 15.51 0 0 1-3.28 2.64z"
            fill="none"
            stroke="#506a88"
            strokeMiterlimit={10}
          />
          <path
            d="M55 26.17h-.21A1 1 0 0 1 54 25a28 28 0 0 1 2.43-6.61 35.38 35.38 0 0 1 4.78-6.26 1 1 0 1 1 1.44 1.39 33.37 33.37 0 0 0-4.47 5.86 24.79 24.79 0 0 0-2.22 6 1 1 0 0 1-.96.79zM65.45 10.56a1 1 0 0 1-.83-.43 1 1 0 0 1 .26-1.39l.76-.53a1 1 0 1 1 1.14 1.64l-.76.53a1 1 0 0 1-.57.18z"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
}

PastStudents.propTypes = {
  className: PropTypes.string,
};
