import Immutable from 'immutable';
import createReducer from 'utils/createReducer';
import { ADD_ENTITIES, addEntitiesWithNormalisation } from 'ducks/normalizr';
import { eclEntity } from 'schema';
import eclConnector from 'graphql/api/ecl';
import { updateMeta, formFail, formSuccess } from 'ducks/meta';
import componentKeys from 'constants/componentKeys';

export const UPDATE_ECL_ACTIVITY_POSITION_OPTIMISTIC = 'ecl/UPDATE_ECL_ACTIVITY_POSITION_OPTIMISTIC';
export const UPDATE_ECL_ACTIVITY_SUCCEEDED = 'ecl/UPDATE_ECL_ACTIVITY_SUCCEEDED';
export const DELETE_ECL_ACTIVITY_SUCCEEDED = 'ecl/DELETE_ECL_ACTIVITY_SUCCEEDED';

const initialState = new Immutable.Map();

export default createReducer(initialState, {
  [ADD_ENTITIES]: (state, action) => {
    return state.mergeDeep(action.payload.entities.ecl);
  },
  [UPDATE_ECL_ACTIVITY_POSITION_OPTIMISTIC]: (state, action) => {
    const { id, position } = action.payload;

    const activityType = state.get(id).get('type');
    const allActivities = state
      .toList()
      .filter((a) => a.get('type') === activityType && a.get('id') !== id)
      .sort((a, b) => a.get('order') - b.get('order'))
      .splice(position, 0, state.get(id));

    let newState = state;
    allActivities.forEach((activity, index) => {
      newState = newState.setIn([activity.get('id'), 'order'], index);
    });
    return newState;
  },
  [UPDATE_ECL_ACTIVITY_SUCCEEDED]: (state, action) => {
    const { ecActivity } = action.payload;
    return state.setIn([ecActivity.id], Immutable.fromJS(ecActivity));
  },
  [DELETE_ECL_ACTIVITY_SUCCEEDED]: (state, action) => {
    const { eclActivityId } = action.payload;
    const newState = state;
    return newState.delete(eclActivityId);
  },
});

export function updateEcActivityPositionOptimistic(id, position) {
  return { type: UPDATE_ECL_ACTIVITY_POSITION_OPTIMISTIC, payload: { id, position } };
}

export function updateEcActivitySucceeded(ecActivity) {
  return { type: UPDATE_ECL_ACTIVITY_SUCCEEDED, payload: { ecActivity } };
}

export function deleteECActivitySucceeded(eclActivityId) {
  return { type: DELETE_ECL_ACTIVITY_SUCCEEDED, payload: { eclActivityId } };
}

export function fetchEclActivitiesByUserId(userId) {
  return async (dispatch) => {
    dispatch(updateMeta(componentKeys.ECL_ACTIVITIES_FETCHED, false));
    const result = await eclConnector.getECActivitiesByUserId(userId);
    const eclActivities = result.getECActivitiesByUserId;
    if (eclActivities) {
      dispatch(addEntitiesWithNormalisation(eclActivities, [eclEntity]));
    }
    dispatch(updateMeta(componentKeys.ECL_ACTIVITIES_FETCHED, true));
  };
}

export function createECActivity(eclActivity) {
  return async (dispatch) => {
    try {
      const result = await eclConnector.createECActivity(eclActivity);
      dispatch(addEntitiesWithNormalisation(result.createECActivity, eclEntity));
      dispatch(formSuccess('Activity has been added successfully'));
      return result.createECActivity;
    } catch (err) {
      dispatch(formFail('Failed to create activity'));
      return false;
    }
  };
}

export function createCCReport(type, name, content) {
  return async (dispatch) => {
    try {
      const result = await eclConnector.createCCReport(type, name, content);
      dispatch(formSuccess("Thank for your feedback, we'll look into it shortly."));
      return result.createCCReport;
    } catch (err) {
      dispatch(formFail('Something went wrong, please try again.'));
      return false;
    }
  };
}

export function updateECActivity(updates) {
  return async (dispatch) => {
    try {
      const result = await eclConnector.updateECActivity(updates);
      dispatch(updateEcActivitySucceeded(result.updateECActivity));
      dispatch(formSuccess('Activity has been updated successfully'));
    } catch (err) {
      dispatch(formFail('Failed to update activity'));
    }
  };
}

export function updateECActivitySortOrder(activityId, position) {
  return async (dispatch) => {
    try {
      dispatch(updateEcActivityPositionOptimistic(activityId, position));
      await eclConnector.updateECActivity({
        id: activityId,
        order: position,
      });
    } catch (err) {
      dispatch(formFail('Failed to save new order'));
    }
  };
}

export function deleteECActivity(id) {
  return async (dispatch) => {
    try {
      await eclConnector.deleteECActivity(id);
      dispatch(deleteECActivitySucceeded(id));
      dispatch(formSuccess('Activity has been removed successfully'));
      return true;
    } catch (err) {
      dispatch(formFail('Failed to delete activity'));
      return false;
    }
  };
}
