import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';
import { gql } from '@apollo/client';
import { createClient } from '../utils';

const { api } = getConfig();

const client = createClient(api.graphQLEndpoint);

const awardType = gql`
  fragment awardType on Award {
    id
    userId
    name
    award
    description
    year
    status
    order
    createdAt
  }
`;

const getECLAwardByUserId = gql`
  ${awardType}
  query getECLAwardByUserId($userId: String!) {
    getECLAwardByUserId(userId: $userId) {
      ...awardType
    }
  }
`;

const createECLAward = gql`
  ${awardType}
  mutation createECLAward($award: NewAward!) {
    createECLAward(award: $award) {
      ...awardType
    }
  }
`;

const updateECLAward = gql`
  ${awardType}
  mutation updateECLAward($award: EditAward!) {
    updateECLAward(award: $award) {
      ...awardType
    }
  }
`;

const deleteECLAward = gql`
  ${awardType}
  mutation deleteECLAward($awardId: String!) {
    deleteECLAward(awardId: $awardId) {
      ...awardType
    }
  }
`;

export default {
  getECLAwardByUserId: async (userId) => client.query(getECLAwardByUserId, { userId }),
  createECLAward: async (award) => client.query(createECLAward, { award }),
  updateECLAward: async (awardUpdates) => client.query(updateECLAward, { award: awardUpdates }),
  deleteECLAward: async (awardId) => client.query(deleteECLAward, { awardId }),
};
