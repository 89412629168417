import React from 'react';
import { Row, Column } from '../index.style';
import { Content, StyledRadio, ListText } from './index.style';

const ITEMS = [
  {
    label: 'Long List',
    value: 'PROSPECTIVE',
  },
  {
    label: 'Short List',
    value: 'SHORTLISTED',
  },
  {
    label: 'Final List',
    value: 'APPLYING',
  },
];

type ChooseListProps = {
  selectedList: string;
  onSelectList: (value: string) => void;
};

const ChooseList = ({ selectedList, onSelectList }: ChooseListProps) => {
  return (
    <Column>
      <Content style={{ marginTop: 0 }}>{'School list'}</Content>
      <Row style={{ margin: '10px 0 35px -5px' }} rowcenter justifycontent={'space-between'}>
        {ITEMS.map(({ value, label }) => {
          return (
            <Row style={{ cursor: 'pointer' }} rowcenter key={value} onClick={() => onSelectList(value)}>
              <StyledRadio color={'default'} checked={value === selectedList} />
              <ListText>{label}</ListText>
            </Row>
          );
        })}
      </Row>
    </Column>
  );
};

export default ChooseList;
