/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, { useState } from 'react';
import ExpandMore from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import css from './styles.scss';
import { handleEnter } from '../../../../utils/accessibility';

function SelectDropdown(props) {
  const {
    error,
    onChange,
    placeholder,
    validationMessage,
    value,
    options,
    disabled,
    showDropdownList,
    toggletShowDropdownList,
  } = props;
  const selectValue = (value) => {
    onChange(value);
  };
  return (
    <div className={css.selectComponent}>
      <div
        role="button"
        tabIndex={0}
        className={css.inputTab}
        onClick={toggletShowDropdownList}
        onKeyDown={handleEnter(toggletShowDropdownList)}
      >
        <input value={value} placeholder={placeholder} disabled={disabled} readOnly />
        <ExpandMore className={css.expandMoreIcon} />
      </div>
      {showDropdownList && (
        <ul className={css.dropdownList}>
          {options.map((option) => {
            return (
              <li
                role="button"
                tabIndex={0}
                key={option.key}
                onClick={() => selectValue(option)}
                onKeyDown={handleEnter(() => selectValue(option))}
              >
                {option.value}
              </li>
            );
          })}
        </ul>
      )}
      {error && validationMessage && <div className={css.validationMessage}>{validationMessage}</div>}
    </div>
  );
}

SelectDropdown.propTypes = {
  error: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  validationMessage: PropTypes.string,
  value: PropTypes.string,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  showDropdownList: PropTypes.bool,
  toggletShowDropdownList: PropTypes.func,
};

export default SelectDropdown;
