import { createSelector } from 'reselect';
import { Map, List } from 'immutable';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import {
  mergeMissionsAndCategories,
  findStartAndDuration,
  filterRoadmapMissions,
  appendTasksWithoutMissions,
} from 'utils/roadmapUtils';
import {
  selectUserId,
  getViewableUser,
  getLoadedRoadmapTemplate,
  getRoutePathname,
  getTasksListUser,
} from 'selectors/meta';
import { tasks, getAllTasksWithoutAMission } from 'selectors/task';
import roadmapEntities from 'constants/roadmapEntities';
import memoize from 'lodash.memoize';
import { UNCATEGORISED_GROUP_ID } from 'ducks/roadmap';

export const roadmap = (state) => state.get('roadmap');

const memoizeCacheKeyFunc = (...args) => JSON.stringify(args);

// ===================== Composable selectors based of active roadmap =====================

// Gets the ID of the roadmap OR template you are currently vewing
export const getActiveRoadmapId = createSelector(
  [roadmap, getRoutePathname, getViewableUser, getLoadedRoadmapTemplate],
  (roadmap, pathname, userId, roadmapTemplateId) => {
    if (pathname.includes('/roadmap-templates')) {
      return roadmapTemplateId || null;
    } else if (pathname.includes('/roadmap')) {
      const viewingRoadmap = roadmap.find((roadmapObj) => roadmapObj.get('userId') === userId);
      return viewingRoadmap ? viewingRoadmap.get('id') : null;
    }
    return null;
  },
);

// Get the ID of the roadmap of viewable user -> Used when you arent on roadmap route
export const getRoadmapIdByViewableUser = createSelector([roadmap, getViewableUser], (roadmap, userId) => {
  const retrievedRoadmap = roadmap.find((roadmapObj) => roadmapObj.get('userId') === userId);
  return retrievedRoadmap ? retrievedRoadmap.get('id') : null;
});

// Get the ID of the roadmap of viewable user -> Used when you arent on roadmap route
export const getRoadmapIdByTasksListUserId = createSelector([roadmap, getTasksListUser], (roadmap, userId) => {
  const retrievedRoadmap = roadmap.find((roadmapObj) => roadmapObj.get('userId') === userId);
  return retrievedRoadmap ? retrievedRoadmap.get('id') : null;
});

// Get the ID of the roadmap of loggedInUser
export const getRoadmapIdByLoggedInUser = createSelector([roadmap, selectUserId], (roadmap, userId) => {
  const retrievedRoadmap = roadmap.find((roadmapObj) => roadmapObj.get('userId') === userId);
  return retrievedRoadmap ? retrievedRoadmap.get('id') : null;
});

export const getActiveRoadmap = createSelector([roadmap, getActiveRoadmapId], (roadmap, activeRoadmapId) => {
  return roadmap.get(activeRoadmapId) ? roadmap.get(activeRoadmapId) : new Map();
});

export const getActiveRoadmapStatus = createSelector(getActiveRoadmap, (activeRoadmap) => {
  return activeRoadmap.get('status');
});

export const getActiveRoadmapIsTemplate = createSelector(getActiveRoadmap, (activeRoadmap) => {
  return activeRoadmap.get('isTemplate');
});

export const getActiveTemplateName = createSelector(getActiveRoadmap, (activeRoadmap) => {
  return activeRoadmap.get('templateName');
});

export const getActiveRoadmapCategories = createSelector(getActiveRoadmap, (roadmap) => {
  return roadmap.get('categories') ? roadmap.get('categories').toJS() : [];
});

export const getActiveRoadmapCategoriesImmutable = createSelector(getActiveRoadmap, (roadmap) => {
  return roadmap.get('categories') ? roadmap.get('categories') : new List();
});

export const getActiveRoadmapMissionsImmutable = createSelector(getActiveRoadmap, (roadmap) => {
  return roadmap.get('missions') ? roadmap.get('missions') : new List();
});

export const getRoadmapData = createSelector(
  getActiveRoadmapCategoriesImmutable,
  getActiveRoadmapMissionsImmutable,
  tasks,
  (categories, missions, tasks) => {
    return mergeMissionsAndCategories(categories, missions, tasks);
  },
);

export const getActiveRoadmapOpenElements = createSelector(getActiveRoadmap, (activeRoadmap) => {
  return activeRoadmap.get('openElements') ? activeRoadmap.get('openElements').toJS() : [];
});

export const getIsUncategorisedTasksSectionOpen = createSelector(getActiveRoadmapOpenElements, (openElements) => {
  return openElements.indexOf(UNCATEGORISED_GROUP_ID) !== -1;
});

export const getActiveRoadmapFocusedMission = createSelector(getActiveRoadmap, (activeRoadmap) => {
  return activeRoadmap.get('focusedMission') ? activeRoadmap.get('focusedMission').toJS() : {};
});

export const getFilteredRoadmapData = createSelector(
  [getRoadmapData, getActiveRoadmapOpenElements],
  (activeRoadmap, activeRoadmapOpenElements) => {
    return filterRoadmapMissions(activeRoadmap, activeRoadmapOpenElements);
  },
);

export const getAllRoadmapElementsData = createSelector(getRoadmapData, (roadmap) => {
  if (isEmpty(roadmap)) {
    return [];
  }
  return filterRoadmapMissions(
    roadmap,
    roadmap.get('groupsData').map((e) => e.get('id')),
  );
});

export const getAllRoadmapElements = createSelector(
  [
    getAllRoadmapElementsData,
    getAllTasksWithoutAMission,
    getIsUncategorisedTasksSectionOpen,
    getActiveRoadmapIsTemplate,
  ],
  (filteredRoadmapData, tasksWithoutMissions, isUncategorisedTasksOpen, isTemplate) => {
    if (isTemplate) {
      return filteredRoadmapData.groupsData;
    }
    const result = appendTasksWithoutMissions(filteredRoadmapData, tasksWithoutMissions, isUncategorisedTasksOpen);
    return result.groupsData;
  },
);

export const getFilteredRoadmap = createSelector(
  [getFilteredRoadmapData, getAllTasksWithoutAMission, getIsUncategorisedTasksSectionOpen, getActiveRoadmapIsTemplate],
  (filteredRoadmapData, tasksWithoutMissions, isUncategorisedTasksOpen, isTemplate) => {
    if (isTemplate) {
      return filteredRoadmapData;
    }
    return appendTasksWithoutMissions(filteredRoadmapData, tasksWithoutMissions, isUncategorisedTasksOpen);
  },
);

export const getEarliestMissionTimeForActiveRoadmap = createSelector(getActiveRoadmapMissionsImmutable, (missions) => {
  if (missions && missions.size > 0) {
    return findStartAndDuration(missions).get('start').valueOf();
  }
  return moment().valueOf();
});

export const getAllTemplates = createSelector(roadmap, (roadmap) => {
  return roadmap
    .filter((roadmapObj) => roadmapObj.get('isTemplate'))
    .toList()
    .sort((a, b) => a.get('templateName').toLowerCase().localeCompare(b.get('templateName').toLowerCase()));
});

// ===============================================================

// ============= Memoized selectors return functions =============
export const getCategoryById = createSelector(getActiveRoadmapCategories, (categories) => {
  return memoize((categoryId) => {
    if (categories) {
      return categories.find((category) => category.id === categoryId);
    }
    return {};
  });
});

export const getAllChildrenOfCategory = createSelector(
  getActiveRoadmapMissionsImmutable,
  getCategoryById,
  tasks,
  (roadmapMissions, getCategoryById, allTasks) => {
    return memoize((categoryId) => {
      const category = getCategoryById(categoryId);
      const missions = roadmapMissions.filter((mission) => mission.get('category').get('id') === categoryId).toJS();
      const missionIds = missions.map((m) => m.id);

      const tasks = allTasks
        .toList()
        .filter((t) => missionIds.includes(t.get('roadmapMissionId')))
        .toJS();

      return {
        [roadmapEntities.CATEGORY]: [category],
        [roadmapEntities.MISSION]: missions,
        [roadmapEntities.TASK]: tasks,
      };
    });
  },
);

export const getRoadmapById = createSelector(roadmap, (roadmap) => {
  return memoize((roadmapId) => {
    return roadmap.get(roadmapId) ? roadmap.get(roadmapId) : new Map();
  });
});

export const getMissionsByRoadmapId = createSelector(getRoadmapById, (getRoadmapById) => {
  return memoize((roadmapId) => {
    const roadmap = getRoadmapById(roadmapId);
    const missions = roadmap.get('missions') ? roadmap.get('missions').toJS() : [];
    return missions;
  });
});

export const getMissionsByRoadmapIdWithDateText = createSelector(getMissionsByRoadmapId, (getMissionsByRoadmapId) => {
  return memoize((roadmapId) => {
    const missions = getMissionsByRoadmapId(roadmapId);
    return missions.map((mission) => {
      const start = moment(mission.startDate);
      const end = moment(mission.endDate);
      let diff = end.diff(start, 'months');
      diff = diff > 0 ? `${diff} months` : `${end.diff(start, 'days')} days`;
      const dateText = `${start.format('D MMM')} - ${end.format('D MMM')} (${diff})`;

      return Object.assign({}, mission, { dateText });
    });
  });
});

export const getMissionById = createSelector(getMissionsByRoadmapId, (getMissionsByRoadmapId) => {
  return memoize((roadmapId, missionId) => {
    const missions = getMissionsByRoadmapId(roadmapId);
    if (missions) {
      const foundMission = missions.find((mission) => mission.id === missionId);
      return foundMission;
    }
    return {};
  }, memoizeCacheKeyFunc);
});

export const getMissionsByCategoryId = createSelector(getMissionsByRoadmapId, (getMissionsByRoadmapId) => {
  return memoize((roadmapId, categoryId) => {
    const missions = getMissionsByRoadmapId(roadmapId);
    if (missions) {
      return missions.filter((mission) => {
        return mission.category ? mission.category.id === categoryId : false;
      });
    }
    return [];
  }, memoizeCacheKeyFunc);
});

export const getAllChildrenOfMission = createSelector(getMissionById, tasks, (getMissionById, allTasks) => {
  return memoize((roadmapId, missionId) => {
    const mission = getMissionById(roadmapId, missionId);
    const tasks = allTasks
      .toList()
      .filter((t) => mission.id === t.get('roadmapMissionId'))
      .toJS();

    return {
      [roadmapEntities.MISSION]: [mission],
      [roadmapEntities.TASK]: tasks,
    };
  }, memoizeCacheKeyFunc);
});
