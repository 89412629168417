import { combineReducers } from 'redux-immutable';
import { reducer as toastrReducer } from 'react-redux-toastr';

import admissionResultReducer from 'ducks/admissionResult';
import aggregateReducer from 'ducks/aggregate';
import bookingReducer from 'ducks/booking';
import commentReducer from 'ducks/comment';
import contractReducer from 'ducks/contract';
import curriculumReducer from 'ducks/curriculum';
import feedbackReducer from 'ducks/feedback';
import fileReducer from 'ducks/file';
import languageReducer from 'ducks/language';
import lessonReducer from 'ducks/lesson';
import metaReducer from 'ducks/meta';
import notificationReducer from 'ducks/notification';
import packageItemReducer from 'ducks/packageItem';
import packageReducer from 'ducks/package';
import profileReducer from 'ducks/profile';
import roleReducer from 'ducks/role';
import subjectCategoryReducer from 'ducks/subjectCategory';
import tutorSubjectReducer from 'ducks/tutorSubject';
import incompleteReportReducer from 'ducks/incompleteReport';
import userReducer from 'ducks/user';
import userRelationshipReducer from 'ducks/userRelationship';
import billingReducer from 'ducks/billing';
import roadmapReducer from 'ducks/roadmap';
import taskReducer from 'ducks/task';
import invoicingReducer from 'ducks/invoicing';
import examPrepReducer from 'ducks/examPrep';
import gradeReducer from 'ducks/grade';
import gradeResultReducer from 'ducks/gradeResult';
import universityReducer from 'ducks/university';
import progressReportReducer from 'ducks/progressReport';
import eclReducer from 'ducks/ecl';
import applicationReducer from 'ducks/application';
import otherSessionReducer from 'ducks/otherSessions';
import globalStateReducer from 'ducks/globalState';
import salesPackageReducer from 'ducks/salesPackage';
import awardReducer from 'ducks/award';
import academicReducer from 'ducks/academic';
import messaging from './messaging';
import cachedFirebaseReducer from './messaging/firebaseReducer.js';
import agenda from './agenda';
import session from './session';

import googleReducer from './google/reducers.js';

export default combineReducers({
  admissionResult: admissionResultReducer,
  aggregate: aggregateReducer,
  booking: bookingReducer,
  comment: commentReducer,
  contract: contractReducer,
  curriculum: curriculumReducer,
  feedback: feedbackReducer,
  file: fileReducer,
  languages: languageReducer,
  lesson: lessonReducer,
  meta: metaReducer,
  notification: notificationReducer,
  packageItem: packageItemReducer,
  package: packageReducer,
  profile: profileReducer,
  role: roleReducer,
  subjectCategory: subjectCategoryReducer,
  toastr: toastrReducer,
  tutorSubject: tutorSubjectReducer,
  user: userReducer,
  userRelationship: userRelationshipReducer,
  billingInfo: billingReducer,
  roadmap: roadmapReducer,
  invoicing: invoicingReducer,
  task: taskReducer,
  examPrep: examPrepReducer,
  grade: gradeReducer,
  gradeResult: gradeResultReducer,
  university: universityReducer,
  progressReport: progressReportReducer,
  incompleteReport: incompleteReportReducer,
  ecl: eclReducer,
  application: applicationReducer,
  messaging,
  agenda,
  session,
  firebase: cachedFirebaseReducer,
  google: googleReducer,
  otherSession: otherSessionReducer,
  globalState: globalStateReducer,
  salesPackage: salesPackageReducer,
  award: awardReducer,
  academic: academicReducer,
});
