import moment from 'moment-timezone';
import getSymbolFromCurrency from 'currency-symbol-map';
import invoiceStatuses from 'constants/invoiceStatus';

const { PENDING, SUBMITTED, PAID } = invoiceStatuses;

export const formatDate = (date, loginUserTimezone, dateFormat) =>
  `${moment(date).tz(loginUserTimezone).format(dateFormat)}`;

export const formatCurrencyValue = ({ currency, amount }) => {
  // If currency unknown, currency-symbol-map returns undefined.
  const symbol = getSymbolFromCurrency(currency) || '';
  return `${symbol}${(amount || 0).toFixed(2)} ${currency}`;
};

export const formatStatusLabel = (status) => {
  switch (status) {
    case PENDING:
      return 'Awaiting Submission';
    case SUBMITTED:
      return 'Submitted';
    case PAID:
      return 'Paid';
    default:
      return '';
  }
};

export const formatPeriodValue = (periodValue, tz) => moment.tz(periodValue, tz).format('D MMM YYYY');

export const formatPeriodDate = (start, end) => {
  let startDate = '';

  if (!moment(start).isSame(end, 'year')) {
    startDate = moment(start).format('D MMM YYYY');
  } else if (!moment(start).isSame(end, 'month')) {
    startDate = moment(start).format('D MMM');
  } else {
    startDate = moment(start).format('D');
  }

  const endDate = moment(end).format('D MMM YYYY');
  return `${startDate} - ${endDate}`;
};

export const formatItemDate = (start, end) => {
  const endDate = moment(end).format('D MMM YYYY');

  if (!moment(start).isSame(end, 'day')) {
    const startDate = moment(start).format('D');
    return `${startDate} - ${endDate}`;
  }

  return endDate;
};

export const formatItemTime = (start, end) => {
  return `${moment(start).format('hh:mma')} - ${moment(end).format('hh:mma')}`;
};

export const formatDuration = (value) => {
  const duration = moment.duration(value, 'hours');
  const minutes = Math.round(duration.asMinutes() - Math.floor(duration.asHours()) * 60);
  return `${Math.floor(duration.asHours())}h ${minutes}m`;
};

export const pageSize = 50;
