import { createSelector } from 'reselect';

const grade = (state) => state.get('grade');

const subjectCategories = (state) => state.get('subjectCategory');

export const getAllGrades = createSelector(grade, (grade) => grade.toJS());

export const getGradeById = (id) => {
  return createSelector(grade, (grade) => grade.get(id).toJS());
};

export const getGradesByUserId = (userId) => {
  return createSelector(grade, (grade) => grade.filter((grade) => grade.get('userId') === userId).toJS());
};

// Pass in a list of subjectCategory ids and this selector will return all the user's grades
// grouped by the closest subjectCategory they are nested under.
export const getUsersGradesByCurriculum = (userId, curriculumIds) =>
  createSelector(getGradesByUserId(userId), subjectCategories, (grades, subjectCategories) => {
    // An object to make checking for curriculumIds faster:
    const inCurriculum = curriculumIds.reduce((obj, id) => {
      obj[id] = true;
      return obj;
    }, {});

    // Results is a map from groupId to an array of grades in that group.
    const result = {};
    Object.values(grades).forEach((grade) => {
      let cursor = subjectCategories.get(grade.subjectId);
      if (cursor === undefined) {
        return;
      }

      while (cursor !== null && cursor !== undefined) {
        if (inCurriculum[cursor.get('id')]) {
          result[cursor.get('id')] = result[cursor.get('id')] || [];
          result[cursor.get('id')].push(grade);
          break;
        }
        cursor = subjectCategories.get(cursor.get('parentId'));
      }
    });
    return result;
  });
