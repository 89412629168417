import styled from 'styled-components';

export const Container = styled.div<{ $open: boolean }>`
  width: 572px;
  height: 701px;
  display: ${({ $open }) => ($open ? 'flex' : 'none')};
  flex-direction: column;
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 100;
  background: #fff;
  box-shadow: 0px 25px 50px rgba(115, 116, 125, 0.25);
  border-radius: 8px 8px 0 0;

  animation-duration: 0.4s;
  animation-name: ${({ $open }) => ($open ? 'anim-show' : 'anim-hide')};
  @keyframes anim-show {
    0% {
      opacity: 0;
      transform: translateY(50%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes anim-hide {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;
