import React from 'react';
import PropTypes from 'prop-types';
import { RadioGroup as ReactRadioGroup } from 'react-radio-group';

export default function RadioGroup(props) {
  const { children, onChange, selectedValue, name, className } = props;

  return (
    <ReactRadioGroup className={className} name={name} selectedValue={selectedValue} onChange={onChange}>
      {children}
    </ReactRadioGroup>
  );
}

RadioGroup.propTypes = {
  className: PropTypes.string,
  children: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedValue: PropTypes.string,
  name: PropTypes.string.isRequired,
};
