import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Textarea from 'react-textarea-autosize';
import classNames from 'classnames';

import { withAppContext } from 'components/enhancers/AppContext';
import * as Logger from '@crimson-education/browser-logger';
import IconButton from 'components/molecules/IconButton';

import UploadFileModal from 'components/organisms/UploadFileModal';

import EmojiPicker from './EmojiPicker';
import css from './styles.scss';
import { MessageEventType } from '../../../../utils/MessageEventType';

class MessageField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      values: {},
      showEmojiPicker: false,
      isUploadFileModalOpen: false,
      showUploadFile: true,
    };

    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.onSelectEmoji = this.onSelectEmoji.bind(this);
    this.openPicker = this.openPicker.bind(this);
    this.closePicker = this.closePicker.bind(this);

    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleSendFileStatus = this.handleSendFileStatus.bind(this);
    this.handleUploadCancel = this.handleUploadCancel.bind(this);
  }

  onSelectEmoji({ native }) {
    const { id } = this.props;
    const { values } = this.state;
    this.closePicker();
    this.setState({
      values: Object.assign(values, { [id]: values[id] ? `${values[id]}${native}` : `${native}` }),
    });
  }

  onFocus(event) {
    // To avoid user editing and onfocusing on the textarea for multiple times, when onfocus happens, add a judgement to see if the textarea is blank. If so, track the event. If not, skip.
    const value = event.target.value;
    if (value.length === 0) {
      Logger.trackEventSinceLastAction({ message: 'onfocus message text area' });
    }
  }

  getInputTextForThread(key) {
    return this.state.values[key] || '';
  }

  openPicker() {
    if (this.state.showEmojiPicker) return;
    Logger.trackEvent({ message: 'emoji picker click' });
    this.setState({ showEmojiPicker: true });
  }

  closePicker() {
    this.setState({ showEmojiPicker: false });
  }

  handleKeyPress(event) {
    const { app, disabled } = this.props;

    if (disabled) return;

    if (event.key === 'Enter' && !event.shiftKey && !app.isMobile) {
      event.preventDefault();
      this.sendMessage();
    }
  }

  sendMessage() {
    const { onSubmit, onChange, id, disabled } = this.props;
    if (disabled) return;

    const trimmedValue = this.getInputTextForThread(id).trim();
    if (onSubmit && trimmedValue.length) {
      Logger.trackEventSinceLastAction({
        message: MessageEventType.PreSendMessage,
        metadata: {
          messageLength: trimmedValue.length,
        },
      });
      onSubmit(trimmedValue);
    }

    if (onChange) onChange('');

    this.setState({
      values: Object.assign(this.state.values, { [id]: '' }),
    });
    this.textArea.focus();
  }

  handleCloseModal() {
    this.setState({ isUploadFileModalOpen: false });
  }

  handleSendFileStatus() {
    this.setState({ showUploadFile: false });
  }

  handleUploadCancel() {
    this.setState({ showUploadFile: true });
  }

  sendFileToParticipants(file) {
    const { sendFile } = this.props;
    const imgRegexp = /^image\/\w/g;
    let fileType = 'FILE';
    if (imgRegexp.test(file.type)) {
      fileType = 'IMAGE';
    }
    setTimeout(() => {
      this.setState({ isUploadFileModalOpen: false, showUploadFile: true });
      sendFile(fileType, file.name);
    }, 1000);
  }

  render() {
    const { className, placeholder, onChange, fieldClass, disabled, id, uploadedFile, isMobile } = this.props;
    const { isUploadFileModalOpen, showUploadFile } = this.state;
    if (uploadedFile.toJS().id !== undefined) {
      this.sendFileToParticipants(uploadedFile.toJS());
    }
    let row = 1;
    if (isMobile) {
      row = 4;
    }
    return (
      <React.Fragment>
        <div className={isMobile ? classNames(css.field, fieldClass) : null}>
          <div className={css.inputContainer}>
            <Textarea
              onFocus={this.onFocus}
              inputRef={(textArea) => {
                this.textArea = textArea;
              }}
              disabled={disabled}
              className={className}
              placeholder={placeholder}
              onKeyPress={this.handleKeyPress}
              rows={row}
              onChange={(event) => {
                const value = event.target.value;
                if (onChange) onChange(value);
                this.setState({ values: Object.assign(this.state.values, { [id]: value }) });
              }}
              value={this.getInputTextForThread(id)}
              data-test-id="messageTextarea"
            />
          </div>
          {isMobile ? (
            <div className={css.actionIconContainer}>
              <IconButton
                icon="Send"
                dataTestId="sendMessage"
                style={{ height: '2.1rem', width: '2rem' }}
                onClick={this.sendMessage}
              />
            </div>
          ) : (
            <div className={classNames(css.field, fieldClass)}>
              <div className={css.inputContainer}>
                <div className={css.subtask}>
                  <div className={css.filePicker}>
                    <IconButton
                      icon="AttachFile"
                      iconSize={{ height: '1.5rem', width: '1.5rem' }}
                      onClick={() => this.setState({ isUploadFileModalOpen: true })}
                    />
                  </div>
                  <IconButton
                    icon="InsertEmoticon"
                    className={css.emojiButton}
                    iconSize={{ height: '1.5rem', width: '1.5rem' }}
                    onClick={this.openPicker}
                  />
                  {this.state.showEmojiPicker && (
                    <EmojiPicker
                      closePicker={this.closePicker}
                      onSelectEmoji={this.onSelectEmoji}
                      textArea={this.textArea}
                    />
                  )}
                  {/* <IconButton
                  icon="FormatBold"
                  className={css.emojiButton}
                  iconSize={{ height: '1.5rem', width: '1.5rem' }}
                />
                <IconButton
                  icon="FormatItalic"
                  className={css.emojiButton}
                  iconSize={{ height: '1.5rem', width: '1.5rem' }}
                />
                <IconButton
                  icon="FormatUnderlined"
                  className={css.emojiButton}
                  iconSize={{ height: '1.5rem', width: '1.5rem' }}
                /> */}
                  <div className={css.hintMessage}>
                    <p>Return/Enter to send | Return/Enter + Shift to add a new line</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <UploadFileModal
          isUploadFileModalOpen={isUploadFileModalOpen}
          handleCloseModal={this.handleCloseModal}
          onSubmit={this.sendFileToParticipants}
          threadId={id}
          isError={false}
          showUploadFile={showUploadFile}
          handleStatus={this.handleSendFileStatus}
          handleUploadCancel={this.handleUploadCancel}
        />
      </React.Fragment>
    );
  }
}

MessageField.propTypes = {
  app: PropTypes.object,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  fieldClass: PropTypes.string,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool,
  sendFile: PropTypes.func,
  uploadedFile: PropTypes.object,
  isMobile: PropTypes.bool,
};

MessageField.defaultProps = {
  placeholder: 'Type your message here',
  className: css.messageInputField,
};

export default withAppContext(MessageField);
