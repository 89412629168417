import styled from 'styled-components';

export const ToolBarTitle = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  color: #000000;
`;

export const AppStatusText = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-transform: capitalize;
  white-space: pre-wrap;
  color: #12c39a;
  width: 108px;
`;
