import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classnames from 'classnames';
import Dropzone from 'react-dropzone';
import uploadFileTypes from 'constants/uploadFileTypes';
import InitialsAvatar from 'components/generic/InitialsAvatar';
import { CommentField } from 'components/generic/Forms';
import Modal from 'components/molecules/Modal';
import { getAvatarColour } from 'utils/calendarUtils';
import { File } from '../';
import css from './styles.scss';
import cssComment from '../styles.scss';
import { handleEnter } from '../../../../utils/accessibility';

export default class CommentReply extends Component {
  constructor() {
    super();

    this.state = {
      oriMessage: '',
      message: '',
      file: null,
      fileMessage: '',
      fileRejected: false,
      isPosting: false,
    };

    this.handleMessageChanged = this.handleMessageChanged.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.postReply = this.postReply.bind(this);

    this.handleFileMessageChanged = this.handleFileMessageChanged.bind(this);
    this.handleAddFileClicked = this.handleAddFileClicked.bind(this);
    this.handleFileSelected = this.handleFileSelected.bind(this);
    this.handleFileRejected = this.handleFileRejected.bind(this);
    this.handleFileRemove = this.handleFileRemove.bind(this);
  }

  onKeyDown(event) {
    if (event.keyCode === 13 && !event.shiftKey) {
      // enter key will submit form. Shirt enter will insert new line. Only submit if has text.
      event.preventDefault();
      this.postReply();
    }
  }

  postReply() {
    const { onReply } = this.props;
    const { message, file, fileMessage } = this.state;

    const postMessage = file ? fileMessage : message;

    if (!postMessage || !postMessage.trim()) {
      this.setState({
        oriMessage: '',
        message: '',
        fileMessage: '',
      });
      return;
    }

    this.setState({
      isPosting: true,
    });

    const promise = onReply(postMessage, file);
    if (promise && promise.then) {
      promise.then(() => {
        this.resetState();
      });
    } else {
      this.resetState();
    }
  }

  resetState() {
    this.setState({
      oriMessage: '',
      message: '',
      file: null,
      fileMessage: '',
      fileRejected: false,
      isPosting: false,
    });
  }

  handleMessageChanged(value) {
    this.setState({
      message: value,
    });
  }

  handleFileMessageChanged(value) {
    this.setState({
      fileMessage: value,
    });
  }

  handleAddFileClicked() {
    this.dropzone.open();
  }

  handleFileRejected() {
    this.setState({
      fileRejected: true,
    });
  }

  handleFileSelected(files) {
    const { message } = this.state;
    const oriMessage = message.trim();
    this.setState({
      file: files[0],
      fileRejected: false,
      fileMessage: oriMessage,
      oriMessage,
      message: '',
    });
  }

  handleFileRemove() {
    const { oriMessage } = this.state;
    this.setState({
      file: null,
      fileMessage: '',
      message: oriMessage,
    });
  }

  render() {
    const { className, loginUser, supportFile } = this.props;
    const { message, file, fileMessage, isPosting } = this.state;

    return (
      <div className={classnames(cssComment.comment, className)}>
        <div className={cssComment.avatar}>
          <InitialsAvatar
            className={cssComment.personInitials}
            colorIndex={getAvatarColour(loginUser.get('userId'))}
            initials={loginUser.get('initials')}
          />
        </div>
        <div className={cssComment.commentSection}>
          <div className={css.replyContainer}>
            <CommentField
              placeholder="Type a reply"
              defaultValue={message}
              value={message}
              onValueChange={this.handleMessageChanged}
              className={classnames('formItemNoMargin', css.textFieldContainer)}
              formInputClassName={css.formInput}
              onKeyDown={this.onKeyDown}
              autoSize
              siblingComponent={
                supportFile && (
                  <div
                    role="button"
                    tabIndex={0}
                    className={css.plusButton}
                    title="Add File"
                    onClick={this.handleAddFileClicked}
                    onKeyDown={handleEnter(this.handleAddFileClicked)}
                  >
                    <i className={classnames('zmdi zmdi-plus', css.plusIcon)} />
                  </div>
                )
              }
            />
            {supportFile && (
              <Dropzone
                id="messageFile"
                className={css.uploader}
                ref={(el) => {
                  this.dropzone = el;
                }}
                multiple={false}
                maxSize={10 * 1024 * 1024}
                accept={[]
                  .concat(
                    uploadFileTypes.fileTypes.image,
                    uploadFileTypes.fileTypes.text,
                    uploadFileTypes.fileTypes.pdf,
                    uploadFileTypes.fileTypes.word,
                    uploadFileTypes.fileTypes.excel,
                    uploadFileTypes.fileTypes.powerpoint,
                  )
                  .join(',')}
                onDropRejected={this.handleFileRejected}
                onDrop={this.handleFileSelected}
              />
            )}
            {supportFile && file && (
              <Modal
                className={css.addFileModal}
                isOpen={!!file}
                onClose={this.handleFileRemove}
                onSubmit={this.postReply}
                submitLabel={isPosting ? 'Uploading...' : 'Upload file'}
                isSubmitDisabled={!fileMessage || !fileMessage.trim() || isPosting}
                title="ADD A FILE"
              >
                <div className={css.addFileModalContent}>
                  <File fileName={file.name} fileSize={file.size} />
                  <CommentField
                    label="Add comment"
                    placeholder="Add a comment to your file"
                    defaultValue={fileMessage}
                    value={fileMessage}
                    onValueChange={this.handleFileMessageChanged}
                    className={css.addFileModalTextFieldContainer}
                    formInputClassName={css.addFileModalTextFieldInput}
                  />
                </div>
              </Modal>
            )}
          </div>
        </div>
      </div>
    );
  }
}

CommentReply.propTypes = {
  className: PropTypes.string,
  supportFile: PropTypes.bool,
  loginUser: ImmutablePropTypes.map.isRequired,
  onReply: PropTypes.func.isRequired,
};
CommentReply.defaultProps = {
  supportFile: true,
};

CommentReply.displayName = 'CommentReply';
