import moment from 'moment';
import titleCase from 'titlecase';
import isEmpty from 'lodash/isEmpty';
import { keyBy } from 'lodash';

/**
 * Check if a String is null, undefined, or whitespace.
 * @param {string} str String to check.
 */
export function isEmptyOrWhiteSpace(str) {
  return str === null || str === undefined || str.match(/^ *$/) !== null;
}

export function sortAndColorUsers(users, propKey = 'firstName') {
  const maxColors = 9;
  let i = -1;
  return users
    .sortBy((user) => user.get(propKey))
    .map((user) => {
      i += 1;
      return user.set('colorIndex', i % maxColors);
    });
}

export function sortAndColorUsersArray(users, propKey = 'firstName') {
  const maxColors = 9;
  let i = -1;
  const sortedUsers = users.sort((a, b) => {
    if (a[propKey] < b[propKey]) return -1;
    if (a[propKey] > b[propKey]) return 1;
    return 0;
  });

  sortedUsers.forEach((user) => {
    i += 1;
    user.colorIndex = i % maxColors;
  });
  return sortedUsers;
}

/**
 * Given a user's full name return the initials.
 * @param {string[]} names - A list that contains user first, last, middle etc. names.
 * @returns {string} Name initials.
 */
export function getInitialsFromNames(names) {
  let initials;

  try {
    initials = names.reduce((acc, cur) => acc + cur.charAt(0), '').toUpperCase();
  } catch (err) {
    initials = '??';
  }

  return initials;
}

export function getFullNameFromNames(nameArray) {
  return titleCase(nameArray.join(' '));
}

export function formatTimestamp(time) {
  return moment(time).fromNow();
}

export function displayDate(eventDate, fullMonth) {
  const today = moment().startOf('day');
  const tomorrow = moment().add(1, 'days');
  const yesterday = moment().subtract(1, 'days');
  const eventDay = moment(eventDate).startOf('day');
  if (today.isSame(eventDay, 'day')) {
    return 'today';
  } else if (tomorrow.isSame(eventDay, 'day')) {
    return 'tomorrow';
  } else if (yesterday.isSame(eventDay, 'day')) {
    return 'yesterday';
  }
  if (fullMonth) {
    return eventDay.format('LL');
  }
  return eventDay.format('ll');
}

export function escapeDoubleQuotes(string) {
  return string.replace(/\\/g, '\\\\').replace(/"/g, '\\"');
}

export function buildUsersFullName(users) {
  const students = users.map((user) => {
    return Object.assign({}, user, {
      initials: getInitialsFromNames([user.firstName, user.lastName]),
      fullName: titleCase(`${user.firstName} ${user.lastName}`),
    });
  });
  return sortAndColorUsersArray(students, 'fullName');
}

export function trimAllSpaces(string) {
  if (typeof string !== 'string') {
    return string.toString().replace(/\s+/g, '');
  }
  return string.replace(/\s+/g, '');
}

export function trimSpecialCharacters(string) {
  if (typeof string === 'undefined' || string === null || !string) {
    return 'null';
  }
  if (typeof string !== 'string') {
    return string
      .toString()
      .replace(/[^\w\s]/gi, '')
      .replace(/\s+/g, '');
  }
  return string.replace(/[^\w\s]/gi, '').replace(/\s+/g, '');
}

export function buildCountString(number, object, objectPlural) {
  if (objectPlural) {
    return `${number} ${number === 1 ? object : objectPlural}`;
  }
  return `${number} ${object}${number === 1 ? '' : 's'}`;
}

export const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

/**
 * Given an array of objects and a key, return an array of unique values that correspond to the
 * passed in key.
 * @param {object[]} array - List of objects to look for unique values
 * @param {key} string - The key to look into the objects for the value
 * @returns {string[]} - Array of unique values
 */
export function getUniqueValuesByKey(array, key) {
  return [...new Set(array.map((item) => item[key]))];
}

/**
 * Returns array of objects in the format [{ value: 2000, label: '2000' }]
 * To be used in year pickers.
 */
export function getSelectableYearList(maxYearOffset, minYearOffset = 60) {
  const CURRENT_YEAR = new Date().getFullYear();
  const MAX_YEAR = CURRENT_YEAR + (maxYearOffset || 10);
  const MIN_YEAR = CURRENT_YEAR - minYearOffset;
  const years = [...Array(MAX_YEAR - MIN_YEAR)];

  return years.map((_, index) => {
    return {
      value: MAX_YEAR - index,
      label: `${MAX_YEAR - index}`,
    };
  });
}

/**
 * Returns array of objects in the format [{ value: '2000/2001', label: '2000/2001' }]
 * To be used in year cycle pickers.
 */
export function getSelectableYearCycleList(maxYearOffset, minYearOffset = 60) {
  const CURRENT_YEAR = new Date().getFullYear();
  const MAX_YEAR = CURRENT_YEAR + (maxYearOffset || 10);
  const MIN_YEAR = CURRENT_YEAR - minYearOffset;
  const years = [...Array(MAX_YEAR - MIN_YEAR)];

  return years.map((_, index) => {
    const currentYear = MAX_YEAR - index;
    const nextYear = MAX_YEAR + 1 - index;
    return {
      value: `${currentYear}/${nextYear}`,
      label: `${currentYear}/${nextYear}`,
    };
  });
}

export function validateEmail(email) {
  if (isEmptyOrWhiteSpace(email)) {
    return false;
  }

  // Ensure only one @ character
  const numAtCharacters = (email.match(/@/g) || []).length;

  // Hardcore official W3 email regex
  const re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  return numAtCharacters === 1 && re.test(email);
}

export function validatePhoneNumber(phoneNumber) {
  const re = /^[-+0-9\s()]*$/;
  return re.test(phoneNumber);
}

export function unwrapImmutable(immutableObj) {
  if (immutableObj === undefined || immutableObj === null) {
    return immutableObj;
  }

  return immutableObj.toJS();
}

export function validateIntegerRangeInclusive(value, min, max) {
  const num = Number(value);
  return Number.isInteger(num) && num >= min && num <= max;
}

// Concatenate two 'arrays'. Is able to handle null or undefined values to return empty arrays
export function safeConcatArray(arrOne, arryTwo) {
  return [...(arrOne || [])].concat([...(arryTwo || [])]);
}

/**
 * Check if an object or array has been updated based on a key.
 * @param before Before object or array
 * @param after After object or array
 * @param getBeforeKey Function that is called with `before` to retrieve value to compare
 * @param getAfterKey Function that is called with `after` to retrieve value to compare
 */
export function hasUpdated(before, after, getBeforeKey, getAfterKey) {
  // e.g. {} + {} or undefined + undefined
  if (isEmpty(before) && isEmpty(after)) {
    return false;
  }

  // e.g. {} + undefined or { userId: 1 } + { userId: 2 }
  if ((isEmpty(before) && !isEmpty(after)) || (isEmpty(after) && !isEmpty(before))) {
    return true;
  }

  // After previous two validations before and after must NOT be empty

  if (Array.isArray(before)) {
    if (before.length !== after.length) {
      return true;
    }
    return !before.map((s) => after.find((c) => getAfterKey(c) === getBeforeKey(s))).reduce((p, c) => p && c, true);
  }

  return getBeforeKey(before) !== getAfterKey(after);
}

/**
 * Gets a new object with the Key/Value pairs of the object that are changed only.
 * @param {*} before Initial Object.
 * @param {*} after Updated Object.
 */
export function diffObject(before, after) {
  const changed = {};
  Object.keys(before).forEach((key) => {
    if (before[key] !== after[key]) {
      changed[key] = after[key];
    }
  });

  return changed;
}

/**
 * Sorts an Array by a Property.
 * @param {Array} arr Array to Sort
 * @param {Function} propertyFunc Function to retrieve an entry's property.
 * @param {Boolean} reverse Reverse Sort direction?
 */
export function propertySort(arr, propertyFunc, reverse = false) {
  return arr.sort((a, b) => {
    let [propA, propB] = [propertyFunc(a), propertyFunc(b)];
    if (reverse) {
      [propA, propB] = [propB, propA];
    }

    // eslint-disable-next-line no-nested-ternary
    return propA < propB ? -1 : propA === propB ? 0 : 1;
  });
}

/**
 * Convert string array to string with specified delimeter
 * @param items string array
 * @param delimeter spliter
 */
export const convertToSeparatedString = (items, delimeter) => items && !isEmpty(items) && items.join(delimeter);

/**
 * Convert text new line sign to replacement string
 * @param text the text needs to be converted
 * @param replacement the replacement to
 */
export const conversionBreakline = (text, replacement) => text && text.replace(/\\n/g, replacement);

/**
 * Sort array by number it has
 * @param array array to change
 */
export const sortArrayByNumberAsc = (array) =>
  array && array.sort((a, b) => a.replace(/\D/g, '') - b.replace(/\D/g, ''));

/**
 * Remove empty string from array
 * @param array array to sanction
 */
export const sanctionArrayWithoutEmptyString = (array) => array && array.filter((c) => c !== '');

// Clear notes localStorage, except some is in drafting
export const clearNotesLocalStorage = () => {
  Object.keys(localStorage)
    .filter((key) => key.startsWith('event-'))
    .forEach((key) => {
      const item = localStorage.getItem(key);
      const event = JSON.parse(item);
      if (event && !event.isDraft) {
        localStorage.removeItem(key);
      }
    });
};

export const isPositiveInteger = (number) => {
  const re = /^[0-9]+$/;
  return re.test(number);
};
