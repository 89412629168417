import { schema } from 'normalizr';

const { Entity } = schema;

/** transforms a entity schema into a graphQL connection. */
export const connectionSchema = (nodeSchema) => ({
  edges: [{ node: nodeSchema }],
});

/**
 * Mergers the cursor of the parent edge into the entity object. If a processor
 * is provided, it applies as a normal processStrategy.
 * @param {*} processor a processStrategy.
 * @example processStrategy: mergeCursor()
 * @example processStrategy: mergeCursor((value, parent, key) => ... )
 * // when a process strategy is provided it's applied after merging the cursor.
 */
export const mergeCursor = (processor = (n) => n) => (value, parent, key) =>
  processor({ ...value, cursor: parent.cursor }, parent, key);

export const bookingEntity = new schema.Entity('booking');

export const feedbackEntity = new schema.Entity('feedback');

export const languageEntity = new schema.Entity('language');

export const universityEntity = new schema.Entity('university');

export const incompleteReportEntity = new schema.Entity(
  'incompleteReport',
  {},
  {
    idAttribute: 'eventId',
  },
);

export const lessonEntity = new schema.Entity('lesson');

export const curriculumEntity = new schema.Entity('curriculum');

export const packageItemEntity = new schema.Entity('packageItem');

export const subjectCategoryEntity = new schema.Entity('subjectCategory');

export const gradeResultEntity = new schema.Entity('gradeResult');

export const gradeEntity = new schema.Entity('grade', {
  results: [gradeResultEntity],
});

export const packageEntity = new schema.Entity(
  'package',
  {},
  {
    idAttribute: 'userId',
  },
);

export const salesPackageEntity = new schema.Entity('salesPackage');

export const profileEntity = new schema.Entity(
  'profile',
  {},
  {
    idAttribute: (value, parent, key) => {
      if (key === 'studentInfo') return `${parent.userId}-STUDENT`;
      else if (key === 'tutorInfo') return `${parent.userId}-TUTOR`;
      return `${parent.userId}-UNKNOWN`;
    },
    processStrategy: (value, parent, key) => {
      const entity = Object.assign({}, value);
      entity.profileType = key;
      return entity;
    },
  },
);

export const userRelationshipEntity = new schema.Entity(
  'userRelationship',
  {},
  {
    idAttribute: (value) => {
      const { principalUser, relationUser } = value;
      return `${principalUser && principalUser.userId}-${relationUser && relationUser.userId}`;
    },
  },
);

export const userEntity = new schema.Entity(
  'user',
  {
    relationships: [userRelationshipEntity],
    studentInfo: profileEntity,
    tutorInfo: profileEntity,
  },
  {
    idAttribute: 'userId',
  },
);

export const progressReportEntity = new schema.Entity(
  'progressReport',
  {
    student: userEntity,
    updatedBy: userEntity,
  },
  { idAttribute: (value) => value.id },
);

export const tutorSubjectEntity = new schema.Entity(
  'tutorSubject',
  {
    tutors: [userEntity],
  },
  {
    idAttribute: 'subjectId',
  },
);

export const roleEntity = new schema.Entity(
  'role',
  {},
  {
    idAttribute: (value, parent) => {
      return `${parent.userId}`;
    },
  },
);

export const fileEntity = new schema.Entity('file');

export const commentEntity = new schema.Entity(
  'comment',
  {},
  {
    idAttribute: (value) => value.lessonId,
  },
);

export const roadmapEntity = new schema.Entity(
  'roadmap',
  {},
  {
    idAttribute: (value) => value.id,
  },
);

export const taskEntity = new schema.Entity(
  'task',
  {},
  {
    idAttribute: (value) => value.id,
  },
);

export const eclEntity = new schema.Entity(
  'ecl',
  {},
  {
    idAttribute: (value) => value.id,
  },
);

export const awardEntity = new schema.Entity(
  'award',
  {},
  {
    idAttribute: (value) => value.id,
  },
);

export const applicationEntity = new schema.Entity(
  'application',
  {},
  {
    idAttribute: (value) => value.id,
  },
);

export const examSessionEntity = new schema.Entity(
  'examSession',
  {},
  {
    idAttribute: (value) => value.examPrepSessionId,
  },
);

export const examRelationshipEntity = new schema.Entity(
  'examRelationship',
  {},
  {
    idAttribute: (value) => `${value.userId}-${value.externalExamId}`,
  },
);

export const examPrepEntity = new schema.Array(
  {
    examSessions: examSessionEntity,
    examRelationships: examRelationshipEntity,
  },
  (value) => {
    if (value.examPrepSessionId) return 'examSessions';
    return 'examRelationships';
  },
);

export const contractEntity = new schema.Entity('contract');

export const notificationEntity = new schema.Entity('notification');

export const invoiceItemEntity = new schema.Entity('invoiceItem');

export const invoiceEntity = new schema.Entity('invoice', {
  items: [invoiceItemEntity],
  subjects: [packageItemEntity],
  users: [userEntity],
  tutor: userEntity,
});

export const messageEntity = new schema.Entity(
  'message',
  {},
  {
    processStrategy: mergeCursor(),
  },
);

export const threadParticipantEntity = new schema.Entity(
  'threadParticipant',
  {
    user: userEntity,
  },
  {
    idAttribute: (value) => {
      return `${value.threadId}-${value.userId}`;
    },
  },
);

export const threadEntity = new schema.Entity(
  'thread',
  {
    messages: connectionSchema(messageEntity),
    participants: connectionSchema(threadParticipantEntity),
  },
  {
    processStrategy: mergeCursor((thread) => {
      const {
        messages: { pageInfo: { hasNextPage, endCursor: messageCursor } } = { pageInfo: {} },
        isHistoryComplete = hasNextPage !== undefined ? !hasNextPage : undefined,
      } = thread;

      return {
        ...thread,
        ...(isHistoryComplete !== undefined ? { isHistoryComplete } : {}),
        ...(messageCursor !== undefined ? { messageCursor } : {}),
      };
    }),
  },
);

export const agendaItemEntity = new Entity('agendaItem');

export const agendaEntity = new Entity('agenda', {
  items: [agendaItemEntity],
});

export const notesEntity = new schema.Entity(
  'note',
  {},
  {
    idAttribute: (value) => value.eventId,
  },
);

export const sessionLogEntity = new Entity('sessionLog');

export const sessionEntity = new Entity('session', {
  sessionLog: [sessionLogEntity],
});

export const resourceEntity = new schema.Entity(
  'resource',
  {},
  {
    idAttribute: (value) => value.id,
  },
);

export const sessionFileEntity = new schema.Entity(
  'sessionFile',
  {},
  {
    idAttribute: (value) => `${value.sessionId}-${value.id}`,
  },
);

export const otherSessionEntity = new schema.Entity('otherSession');

export const globalStateEntity = new schema.Entity('globalState');

export const academicEntity = new schema.Entity(
  'academic',
  {},
  {
    idAttribute: (value) => value.id,
  },
);
