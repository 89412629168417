import sectionStatus from 'constants/sectionStatus';
import examType from 'constants/examType';
import moment from 'moment';

export const getExamInProgressMessage = (examName) =>
  `${examName} is in progress. Click this banner to go back and complete the test.`;

export const isGeneralExam = (type) => {
  return type === examType.GENERAL;
};

export function secondsToTime(totalSeconds) {
  const hours = Math.floor(totalSeconds / (60 * 60));
  const divisonForMins = totalSeconds % (60 * 60);
  const minutes = Math.floor(divisonForMins / 60);

  const divisorForSeconds = divisonForMins % 60;
  const seconds = Math.ceil(divisorForSeconds);

  return {
    hours,
    minutes,
    seconds,
  };
}

export function convertToTwoDigitString(number) {
  return number.toString().padStart(2, '0');
}

export function addStatusToSections(exam) {
  const newExam = Object.assign({}, exam);
  if (newExam.sections) {
    if (isGeneralExam(newExam.examType)) {
      for (let i = 0; i < newExam.sections.length; i += 1) {
        newExam.sections[i].status = i === 0 ? sectionStatus.ACTIVE : sectionStatus.AVAILABLE;
      }
    } else {
      let foundCurrentSection = false;
      for (let i = 0; i < newExam.sections.length; i += 1) {
        if (foundCurrentSection) {
          newExam.sections[i].status = sectionStatus.DISABLED;
        } else if (
          newExam.sections[i].timeCompleted ||
          (newExam.sections[i].expiryTime && moment(newExam.sections[i].expiryTime).isBefore(moment()))
        ) {
          newExam.sections[i].status = sectionStatus.DONE;
        } else {
          newExam.sections[i].status = sectionStatus.ACTIVE;
          foundCurrentSection = true;
        }
      }
    }
  }
  return newExam;
}

export function addQuestionCountToSections(exam) {
  if (!exam || !exam.sections) return exam;
  const newExam = Object.assign({}, exam);
  newExam.sections = newExam.sections.map((section) => {
    if (!section.questionsAndQuestionGroups) return section;
    section.numberOfQuestions = section.questionsAndQuestionGroups.reduce((total, questionOrQuestionGroup) => {
      return total + (questionOrQuestionGroup.questions ? questionOrQuestionGroup.questions.length : 1);
    }, 0);
    return section;
  });
  return newExam;
}

export function getCurrentSectionNumber(sections) {
  return (
    sections
      .filter((section) => section.questionsAndQuestionGroups && section.questionsAndQuestionGroups.length)
      .findIndex((section) => section.status === sectionStatus.ACTIVE) + 1
  );
}

export function convertFractionToFloat(fractionString) {
  if (!fractionString) {
    return null;
  } else if (fractionString.toString().indexOf('/') !== -1) {
    const fraction = fractionString.split('/');
    const value = parseInt(fraction[0], 10) / parseInt(fraction[1], 10);
    return parseFloat(value);
  }
  return parseFloat(fractionString);
}

export function validateNumericAnswer(givenAnswer, correctAnswer) {
  return convertFractionToFloat(givenAnswer) === convertFractionToFloat(correctAnswer);
}
