import Immutable from 'immutable';
import createReducer from 'utils/createReducer';
import { ADD_ENTITIES } from 'ducks/normalizr';
import { DELETE_GRADES_SUCCEEDED } from 'ducks/grade';

const initialState = new Immutable.Map();

export default createReducer(initialState, {
  [ADD_ENTITIES]: (state, action) => {
    return state.mergeDeep(action.payload.entities.gradeResult);
  },
  [DELETE_GRADES_SUCCEEDED]: (state, action) => {
    const { gradeIds } = action.payload;
    const newState = state.filter((gradeResult) => !gradeIds.includes(gradeResult.get('gradeId')));
    return newState;
  },
});
