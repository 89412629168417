import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';
import { gql } from '@apollo/client';
import { createClient } from '../utils';

const { api } = getConfig();

const client = createClient(api.graphQLEndpoint);

const getProgressReportHtml = gql`
  query getProgressReportHtml($progressReportId: String!) {
    html: getProgressReportHtml(progressReportId: $progressReportId)
  }
`;

const getProgressReportsByUserId = gql`
  query getProgressReportsByUserId($userId: String!) {
    progressReports: getProgressReportsByUserId(userId: $userId) {
      id
      startDate
      endDate
      sentDate
      successes
      challenges
      score
      student {
        userId
        firstName
        lastName
      }
      status
      updatedAt
      updatedBy {
        userId
        firstName
        lastName
      }
    }
  }
`;

const getProgressReportById = gql`
  query getProgressReportById($progressReportId: String!) {
    progressReport: getProgressReportById(progressReportId: $progressReportId) {
      id
      startDate
      endDate
      sentDate
      successes
      challenges
      score
      student {
        userId
        firstName
        lastName
        studentInfo {
          keyContacts {
            id
            email
            firstName
            lastName
            relationship
            isPrimary
          }
        }
      }
      status
      updatedAt
      updatedBy {
        userId
        firstName
        lastName
      }
    }
  }
`;

const sendProgressReport = gql`
  mutation sendProgressReport($reportId: String!, $sendCopy: Boolean, $contactIds: [Int]) {
    sendProgressReport(reportId: $reportId, sendCopy: $sendCopy, contactIds: $contactIds) {
      id
    }
  }
`;

const updateProgressReport = gql`
  mutation updateProgressReport($id: String!, $successes: String, $challenges: String, $score: Int) {
    report: updateProgressReport(id: $id, successes: $successes, challenges: $challenges, score: $score) {
      id
      startDate
      endDate
      sentDate
      student {
        firstName
        lastName
      }
      updatedBy {
        firstName
        lastName
      }
      updatedAt
      recipients
      status
      successes
      challenges
      score
    }
  }
`;

export default {
  getProgressReportsByUserId: async (userId) => {
    return client.query(getProgressReportsByUserId, { userId });
  },
  getProgressReportById: async (progressReportId) => {
    return client.query(getProgressReportById, { progressReportId });
  },
  sendProgressReport: async (reportId, sendCopy, contactIds) => {
    return client.query(sendProgressReport, { reportId, sendCopy, contactIds });
  },
  getProgressReportHtml: async (progressReportId) => {
    return client.query(getProgressReportHtml, { progressReportId });
  },
  updateProgressReport: async (report) => {
    return client.query(updateProgressReport, report);
  },
};
