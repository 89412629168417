import PropTypes from 'prop-types';

export default function Column() {
  return null;
}

Column.defaultProps = {
  align: 'left',
  flex: '1 1 0',
};

Column.propTypes = {
  name: PropTypes.string.isRequired,
  dataKey: PropTypes.string,
  align: PropTypes.string,
  renderer: PropTypes.func,
  flex: PropTypes.string,
};
