import React from 'react';
import PropTypes from 'prop-types';
import { toasterMessages } from '@crimson-education/common-config';
import Avatar from 'components/molecules/Avatar';

import { Link } from 'react-router-dom';

import Person from '@material-ui/icons/Person';
import Settings from '@material-ui/icons/Settings';
import ExitToApp from '@material-ui/icons/ExitToApp';
import Refresh from '@material-ui/icons/Refresh';
import Send from '@material-ui/icons/Send';
import { Acl, roleTypes } from '@crimson-education/common-config';
import Popover from '@material-ui/core/Popover';
import { changePassword, authorize } from 'utils/auth';
import { HasDevTools } from 'components/organisms/PermissionCondition';

import { logutFromIM } from '../../../../../tim/tim';

import css from './styles.scss';
import { handleEnter } from '../../../../../utils/accessibility';
import { useFeatureFlag } from 'featureSwitches';
export default function ProfilePopover({
  className,
  linkClassName,
  loginUser,
  onClick,
  formSuccess,
  history,
  fetchUserProfile,
}) {
  const [profileOptions, setProfileOptions] = React.useState(false);
  const [profileAnchor, setProfileAnchor] = React.useState(null);
  const STUDENT_CENTER_BETA = useFeatureFlag('STUDENT_CENTER_BETA');

  const user = loginUser.toJS();
  const isStudent = Acl.checkRole(user.role, roleTypes.STUDENT);
  const email = user.email;

  const handleChangePassword = React.useCallback(
    async (email) => {
      await changePassword(email);
      formSuccess(toasterMessages.passwordReset(email));
    },
    [formSuccess],
  );

  const refreshAuth = React.useCallback(async () => {
    await authorize();
    fetchUserProfile();
  }, [fetchUserProfile]);

  const userLink = `/users/${user.userId}${STUDENT_CENTER_BETA && isStudent ? '/student-center/personal-info' : ''}`;

  return (
    <div
      to={`/users/${user.userId}`}
      data-test-id="navProfile"
      role="button"
      tabIndex={0}
      onClick={(e) => {
        onClick(e);
        setProfileAnchor(e.currentTarget);
        setProfileOptions(!profileOptions);
      }}
      onKeyDown={handleEnter((e) => {
        onClick(e);
        setProfileAnchor(e.currentTarget);
        setProfileOptions(!profileOptions);
      })}
      className={className}
      data-appcues-id="profile"
    >
      <div className={linkClassName} data-tip="Profile Options" data-for="navigationToolTip">
        <Avatar
          firstName={user.firstName}
          lastName={user.lastName}
          colourIndex={user.colourIndex}
          image={user.profileImageUrl}
          size="normal"
          showStudentType
          userId={user.userId}
          className={css.avatarLink}
        />
        <span className={css.name}>{user.firstName}</span>
      </div>
      <Popover
        id="profile-popper"
        open={profileOptions}
        anchorEl={profileAnchor}
        onClose={() => setProfileOptions(false)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className={css.profilePopover}>
          <Link className={css.profileLink} to={userLink}>
            <Person />
            <span>View Profile</span>
          </Link>
          <Link className={css.profileLink} to="/settings">
            <Settings />
            <span>Settings</span>
          </Link>
          <div
            role="button"
            tabIndex={0}
            className={css.profileLink}
            onClick={() => handleChangePassword(email)}
            onKeyDown={handleEnter(() => handleChangePassword(email))}
          >
            <Send />
            <span>Change password</span>
          </div>
          <div
            role="button"
            tabIndex={0}
            className={css.profileLink}
            onClick={() => {
              logutFromIM();
              history.push('/logout');
            }}
            onKeyDown={handleEnter(() => {
              logutFromIM();
              history.push('/logout');
            })}
          >
            <ExitToApp />
            <span>Log out</span>
          </div>
          <HasDevTools>
            <div
              role="button"
              tabIndex={0}
              className={css.profileLink}
              onClick={() => refreshAuth()}
              onKeyDown={handleEnter(() => refreshAuth())}
            >
              <Refresh />
              <span>Refresh Auth</span>
            </div>
          </HasDevTools>
        </div>
      </Popover>
    </div>
  );
}

ProfilePopover.propTypes = {
  className: PropTypes.string,
  linkClassName: PropTypes.string,
  onClick: PropTypes.func,
  loginUser: PropTypes.object,
  formSuccess: PropTypes.func,
  history: PropTypes.object,
  fetchUserProfile: PropTypes.func,
};
