import React, { useContext, useMemo } from 'react';
import { KeyboardDatePicker, MuiPickersUtilsProvider as Provider, MuiPickersContext } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import propTypes from 'prop-types';

const DsDatePicker = ({
  disableToolbar = true,
  format = 'dd/MM/yyyy',
  className,
  id,
  ...props
}) => {
  const defaultId = useMemo(() => `ds-date-picker-${Math.random()}`, []);

  const utils = useContext(MuiPickersContext);

  const picker = (<KeyboardDatePicker
    className={`ds-datepicker ${className}`}
    disableToolbar={disableToolbar}
    variant="inline"
    format={format}
    id={id || defaultId}
    {...props}
    KeyboardButtonProps={{
      'aria-label': 'change date',
    }}
  />);

  return !utils ?
    (<Provider utils={DateFnsUtils} > { picker } </Provider>) :
    picker;
};

DsDatePicker.propTypes = {
  disableToolbar: propTypes.bool,
  format: propTypes.string,
  id: propTypes.string,
  className: propTypes.string,
};

export default DsDatePicker;