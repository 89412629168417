import { createSelector } from 'reselect';
import moment from 'moment-timezone';
import Immutable from 'immutable';
import { BookingStatusTypes } from '@crimson-education/common-config';
import { getSelectedSpecificDay } from 'selectors/meta';
import { selectedBookWith, getAllBookings, getUnavailableForBookingAs, allUsers, getBookingAs } from 'selectors/user';
import { mapBookingsToBlocks, foldBlocks, mapUserBlocksToBlocks } from 'utils/blockMapper';

const bookingTypesToShow = [
  BookingStatusTypes.TENTATIVE,
  BookingStatusTypes.CONFIRMED,
  BookingStatusTypes.COMPLETED,
  BookingStatusTypes.AUTO_COMPLETED,
];

function makeBookingsArray(allBookings, date, user) {
  const splitBookings = [];
  const activeBookings = allBookings
    .filter(
      (b) =>
        b.get('participants').some((p) => p.get('userId') === user.get('userId')) &&
        user.get('userId') !== b.get('otherUserId'),
    )
    .filter((b) => bookingTypesToShow.some((t) => t === b.get('status')));
  const mappedBookings = activeBookings
    .map((b) =>
      Object.assign(b.toJS(), {
        person: `${b.getIn(['otherUser', 'firstName'])} ${b.getIn(['otherUser', 'lastName'])}`,
      }),
    )
    .toArray();
  mappedBookings.forEach((b) => {
    const start = moment(b.start);
    const end = moment(b.end);
    if (start.date() !== end.date()) {
      const splitBookingsObj = Object.assign({}, b, {
        displayStart: moment([end.year(), end.month(), end.date()]),
      });
      if (!!end.hour() && !!end.minute()) {
        splitBookings.push(
          Object.assign(splitBookingsObj, {
            displayEnd: moment([start.year(), start.month(), start.date(), 23, 59]),
          }),
        );
      } else {
        splitBookings.push(splitBookingsObj);
      }
    }
  });
  mappedBookings.push(...splitBookings);

  const result = mappedBookings.filter((b) =>
    (b.displayStart ? moment(b.displayStart) : moment(b.start)).isBetween(
      moment(date).startOf('day'),
      moment(date).endOf('day'),
      null,
      '[)',
    ),
  );
  return result;
}

function makeAvailabilitiesArray(user, date, timezone) {
  // const unavailableBlocks = mapUnavailabilitiesIntoBlocks(unavailable, date, timezone);
  const blocks = mapUserBlocksToBlocks(user, date, timezone);
  //   const availabilitiesFlattened = flatten(availabilitiesUnFlattened);
  return blocks;
}

function makeDayObject(date, user, allBookings, unavailable, timezone) {
  const bookings = allBookings ? makeBookingsArray(allBookings, date, user) : [];
  const foldedUnavailabilities = allBookings ? makeAvailabilitiesArray(user, date, timezone) : [];

  const dayElements = foldBlocks(foldedUnavailabilities, mapBookingsToBlocks(Immutable.fromJS(bookings)));
  return {
    user,
    date,
    dayElements,
  };
}
export const getBookingAsUserCalendar = createSelector(
  getAllBookings,
  getSelectedSpecificDay,
  getBookingAs,
  getUnavailableForBookingAs,
  (allBookings, selectedDay, bookingAsUser, unavailable) => {
    const timezone = bookingAsUser.get('timezone');
    const userCalendars = makeDayObject(selectedDay, bookingAsUser, allBookings, unavailable, timezone);
    return userCalendars;
  },
);

export const getBookingWithUsersCalendar = createSelector(
  selectedBookWith,
  getAllBookings,
  getSelectedSpecificDay,
  getBookingAs,
  allUsers,
  (selectedUser, allBookings, selectedDay, bookingAsUser, allUsers) => {
    const timezone = bookingAsUser.get('timezone');
    const userCalendars = selectedUser.map((u) => {
      const user = allUsers.get(u.userId);
      const unavailable = user ? user.getIn(['availability', 'unavailable']) : new Immutable.List();
      return makeDayObject(selectedDay, u, allBookings, unavailable, timezone);
    });
    return Immutable.fromJS(userCalendars);
  },
);
