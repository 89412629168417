import { connect } from 'react-redux';
import { tutorCapacityTypes } from '@crimson-education/common-config';
import { fetchUserById } from 'ducks/user';
import { getCurrentUser, getUsersWithId } from 'selectors/user';
import { fetchPackageByUser } from 'ducks/package';
import { fetchTutorRecommendations } from 'ducks/packageItem';
import { getUserPackageSubject } from 'selectors/package';
import { fetchTutorPayRatesForSubject } from 'ducks/billing';
import { getPayRatesBySubjectId, getTutorPayRateBySubjectId } from 'selectors/billing';
import { getRecommendedTutorIds, getTutorRecommendations } from 'selectors/packageItem';
import { getMetaItem, selectUserPermissions, getAllTutorExpertise } from 'selectors/meta';
import { fetchLanguages } from 'ducks/language';
import { allLanguages, getLanguages } from 'selectors/language';
import { getContractsByItemId } from 'selectors/contract';
import { fetchSubjectInvitationsByItem, sendSubjectInvitations } from 'ducks/contract';
import { fetchAllTutorExpertise } from 'ducks/meta';
import componentKeys from 'constants/componentKeys';
import InviteTutor from './InviteTutor';

function getTutorsForPackageItem(state, packageItemId) {
  // Filter recommended tutors.
  const recommendedTutorIds = getRecommendedTutorIds(state, packageItemId);
  const tutorRecommendations = getUsersWithId(recommendedTutorIds)(state);
  const excludeCapacities = [tutorCapacityTypes.ONBOARDING, tutorCapacityTypes.RESIGNED];
  const filteredTutorIds = recommendedTutorIds.filter(
    (tutorId) => !excludeCapacities.includes(tutorRecommendations.getIn([tutorId, 'tutorInfo', 'capacity'])),
  );

  // Include current tutors who may not have been recommended, e.g. removed
  // from teaching the subject.
  const contracts = Object.values(getContractsByItemId(packageItemId)(state).toJS());
  contracts.forEach((contract) => {
    const tutorId = contract.userId;
    if (!filteredTutorIds.includes(tutorId)) filteredTutorIds.push(tutorId);
  });

  // Prepare tutor data in the correct sequence, hiding tutors who don't yet
  // have their data fetched.
  const tutorMap = getUsersWithId(filteredTutorIds)(state).toJS();
  const orderedTutors = filteredTutorIds.map((id) => tutorMap[id]).filter((tutor) => !!tutor);

  // Return the list of tutors.
  return orderedTutors;
}

const mapStateToProps = (state, ownProps) => {
  const { userId, packageItemId } = ownProps.match.params;
  const subject = getUserPackageSubject(userId, packageItemId)(state);
  const subjectId = subject && subject.get('subjectId');

  return {
    userPermissions: selectUserPermissions(state),
    student: getCurrentUser(state),
    subject,
    areLanguagesFetched: getMetaItem(componentKeys.LANGUAGES_FETCHED)(state),
    languages: allLanguages(state),
    allTutors: getTutorsForPackageItem(state, packageItemId),
    tutorRecommendations: getTutorRecommendations(state, packageItemId),
    payRatesForSubject: subject && getPayRatesBySubjectId(subjectId)(state),
    tutorPayRateForSubject: subject ? (tutorId) => getTutorPayRateBySubjectId(tutorId, subjectId)(state) : () => null,
    contracts: getContractsByItemId(packageItemId)(state).toJS(),
    getLanguages: (ids) => getLanguages(ids)(state),
    allExpertise: getAllTutorExpertise(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchStudent: () => dispatch(fetchUserById(ownProps.match.params.userId)),
    fetchPackage: () => dispatch(fetchPackageByUser(ownProps.match.params.userId)),
    fetchLanguages: () => dispatch(fetchLanguages()),
    fetchTutorRecommendations: () => dispatch(fetchTutorRecommendations(ownProps.match.params.packageItemId)),
    fetchTutorPayRatesForSubject: (subjectId) => dispatch(fetchTutorPayRatesForSubject(subjectId)),
    fetchContracts: () => dispatch(fetchSubjectInvitationsByItem(ownProps.match.params.packageItemId)),
    sendInvitations: (data) => dispatch(sendSubjectInvitations(data)),
    fetchAllTutorExpertise: () => dispatch(fetchAllTutorExpertise()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InviteTutor);
