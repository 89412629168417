import Immutable from 'immutable';
import { normalize } from 'normalizr';
import { commentEntity } from 'schema';
import lesson from 'graphql/api/lesson';
import firebaseClient from 'firebase/firebaseClient';
import notificationLessonService from 'graphql/api/lessonNotificationService';
import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';
import { put } from 'utils/http';

const { firebase: config } = getConfig();

/**
 * Send notification to lesson service based on the action
 * performed.
 *
 * @param {string} notificationType
 * @param {Object} lessonDetails
 * @param {Object} comment
 * @param {Object} file
 */
export function sendLessonNotification(notificationType, lessonDetails, comment, file) {
  // Preparing notification params
  const lesson = lessonDetails.lesson;
  // const loggedInUser = lessonDetails.loginUser;

  const lessonId = lesson.get('id');
  const itemId = lesson.get('itemId');
  const lessonName = lesson.get('title');
  const tutorId = lesson.getIn(['tutor', 'userId']);
  const studentId = lesson.getIn(['client', 'userId']);
  let commentText;
  let authoredUser;
  let fileName;

  if (Immutable.Map.isMap(comment)) {
    // comment isMap means deleting a comment
    fileName = comment.getIn(['file', 'name']);
    commentText = comment.get('message');
    authoredUser = comment.get('user_id');
  } else {
    // posting new comment
    commentText = comment;
    fileName = file ? file.name : null;
  }

  notificationLessonService.notifyLesson(
    tutorId,
    studentId,
    itemId,
    lessonId,
    lessonName,
    notificationType,
    fileName,
    commentText,
    authoredUser,
  );
}

export function getUploadUrl(lessonId, fileName, fileType) {
  return lesson.fetchUploadUrl(lessonId, fileName, fileType);
}

export function uploadFile(lessonId, file, userId) {
  if (!file || !file.name) {
    return Promise.resolve([]);
  }
  let urlData;
  return getUploadUrl(lessonId, file.name, file.type)
    .then((res) => {
      urlData = res.uploadInfo;
      return put(urlData.putUrl, file, { 'content-type': file.type });
    })
    .then(() => {
      return lesson.createFile(lessonId, file.name, urlData.bucket, urlData.key, userId);
    })
    .then((res) => {
      return Object.assign(urlData, { name: file.name, size: file.size, type: file.type, id: res.file.id });
    });
}

export function addToParticipantList(lessonId, userId) {
  firebaseClient.addIfNotExist(`/${config.lessonPath}/${lessonId}/participants/`, userId, { [userId]: true });
}

/**
 * Get a normalized comment object.
 *
 * @param {Object} message          message object
 * @param {string} lessonId         lesson id
 * @param {string} messageId        firebase message key
 * @param {number|null} created     creation unix timestamp
 * @param {number|null} modified    modification unix timestamp
 * @param {string|null} parentId    firebase message key of the parent message
 * @returns {Object}                a normalized object
 */
export function getCommentEntities(message, lessonId, messageId, created = null, modified = null, parentId = null) {
  const messageCopy = created ? Object.assign({}, message, { timestamp: created }) : Object.assign({}, message);

  const conversations = parentId
    ? { [parentId]: { replies: { [messageId]: messageCopy } } }
    : { [messageId]: messageCopy };

  let files = [];
  if (message.file && !modified) {
    files = [
      {
        name: message.file.name,
        createdAt: created,
        userId: message.user_id,
        id: message.file.id,
      },
    ];
  }

  return normalize({ lessonId, conversations, files }, commentEntity);
}
