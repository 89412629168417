import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/molecules/Modal';
import { CommentField } from 'components/generic/Forms';
import css from './styles.scss';

export default function FlagReportModal(props) {
  const [reasonText, setReasonText] = useState('');
  const [validationErrors, setValidationErrors] = useState({});

  const { isOpen, onCancel, reportType, submitReport } = props;
  const flagPersonLabel =
    'Let the admins know why you want to report this person. No one else will see your name or the content of this report.';
  const flagMessageLabel =
    'Let the admins know what’s wrong with this message. No one else will see your name or the content of this report.';
  const handleReasonChange = (value) => {
    setReasonText(value);
  };
  const submitFlagReport = () => {
    const validationErrors = {};
    if (reasonText.trim() === '') {
      validationErrors.selectedReasonText = true;
      setValidationErrors(validationErrors);
      return;
    }
    submitReport(reasonText);
  };
  const modalHeader = reportType === 'message' ? 'Report Message' : 'Report Person'; // Report Person
  const flagReasonLabel = reportType === 'message' ? flagMessageLabel : flagPersonLabel;
  return (
    <Modal
      className={css.flagReportModal}
      isOpen={isOpen}
      title={modalHeader}
      onClose={onCancel}
      onSubmit={submitFlagReport}
      submitText="Submit"
      onSubmitDataTestId="submitFlagReportButton"
      closeButtonClass={css.closeButtonClass}
      mainContentClass={css.mainContentClass}
    >
      <CommentField
        className={css.flagReasonAdd}
        id="reasonsArea"
        dataTestId="flagReasonsArea"
        label={flagReasonLabel}
        placeholder="Add your reasons here"
        onValueChange={handleReasonChange}
        maxLength="1000"
      />
      {validationErrors.selectedReasonText && <p className={css.errorMessage}>Please add your reasons.</p>}
    </Modal>
  );
}
FlagReportModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  reportType: PropTypes.string.isRequired,
  submitReport: PropTypes.func.isRequired,
};
