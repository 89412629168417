import { createSelector } from 'reselect';

// Find children of a node.
function filterChildren(subjects, id) {
  return Object.values(subjects).filter((subject) => subject.parentId === id);
}

// Shallow flatten array of arrays.
function flattenLists(lists) {
  return lists.reduce((all, current) => all.concat(current), []);
}

// Create a mapping of ids to objects.
function createMapping(list) {
  return list.reduce((map, element) => {
    map[element.id] = element;
    return map;
  }, {});
}

const subjectCategories = (state) => state.get('subjectCategory');

export const selectSubjectCategories = createSelector(subjectCategories, (subjectCategories) =>
  subjectCategories.toJS(),
);

export const getSubjectCategoryById = (id) =>
  createSelector(subjectCategories, (subjectCategories) => subjectCategories.get(id).toJS());

export const selectSubjectCategoriesByParentId = (parentId) => {
  return createSelector(subjectCategories, (subjectCategories) =>
    subjectCategories
      .filter((x) => x.get('parentId') === parentId)
      .toList()
      .toJS()
      .sort((a, b) => a.name.localeCompare(b.name)),
  );
};

export const getGradeTableConfigForSubject = (subjectId, config) =>
  createSelector(subjectCategories, (subjectCategories) => {
    let cursor = subjectCategories.get(subjectId);

    // Check for non-existent subject.
    if (!cursor) return undefined;

    // We keep walking the path of parents towards the root node and stop when
    // we get to a subject that has an entry in our config.
    while (cursor) {
      if (config[cursor.get('id')]) {
        // Found something with config!
        return cursor.toJS();
      }
      // Try the parent
      cursor = subjectCategories.get(cursor.get('parentId'));
    }

    // If we reach the top of the tree then there was no config.
    return null;
  });

// Find all subjects in a list of given curriculums.
export const getSubjectsByCurriculums = (curriculumIds) => {
  return createSelector(subjectCategories, (subjectCategories) => {
    const subjects = subjectCategories.toJS();

    // Given an id, fetch all children of this id and flatten them.
    const getDescendants = (id) => {
      const subject = subjects[id];

      // Check for missing subject.
      if (!subject) return [];

      // If a leaf, return this subject.
      if (subject.isLeaf) return [subject];

      // Otherwise, fetch all descendants of this subject.
      const children = filterChildren(subjects, id);
      const descendants = children.map((child) => getDescendants(child.id));
      return flattenLists(descendants);
    };

    // Fetch all descendants of the curriculums.
    const descendants = curriculumIds.map(getDescendants);
    return createMapping(flattenLists(descendants));
  });
};
