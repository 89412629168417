import React from 'react';
import PropTypes from 'prop-types';

export default function Books({ className, style }) {
  return (
    <svg
      className={className}
      style={style}
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path
          id="books"
          d="m4.8 0c-.884 0-1.6.716-1.6 1.6v9.6c0 .884.716 1.6 1.6 1.6h9.6c.884 0 1.6-.716 1.6-1.6v-9.6c0-.884-.716-1.6-1.6-1.6zm0 1.6h4v6.4l-2-1.2-2 1.2zm-4.8 1.6v11.2c0 .88.72 1.6 1.6 1.6h11.2v-1.6h-11.2v-11.2z"
        />
        <mask id="booksMask" fill="#fff">
          <use fill="none" xlinkHref="#books" />
        </mask>
      </defs>
      <g fill="#232950" fillRule="evenodd" transform="translate(4 4)">
        <use fillRule="nonzero" xlinkHref="#books" />
        <g mask="url(#booksMask)">
          <path d="m0 0h24v24h-24z" transform="translate(-4 -4)" />
        </g>
      </g>
    </svg>
  );
}

Books.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
};
