import styled from 'styled-components';
import { Dropdown, Menu } from 'antd';
import { NavLink } from 'react-router-dom';

export const StyledDropdown = styled(Dropdown)``;
export const StyledLinkContainer = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const StyledLeft = styled.div`
  display: flex;
  align-items: center;
`;
export const StyledLink = styled(NavLink)``;
export const StyledMenu = styled(Menu)`
  padding: 16px 0;
  li {
    display: flex;
    align-items: center;
    padding: 8px 32px;
    a {
      color: #73747d !important;
      &:hover {
        color: #464ac9 !important;
      }
    }
    &:hover {
      background: #f4f5ff;
    }
  }
`;
export const StyledLinkText = styled.div`
  display: inline-block;
  margin-left: 16px;
`;
