import {
  groupApplications,
  getLastApplicationStatus,
  isApplicationOverdue,
  isApplicationReceivedResult,
  isApplicationHasIncompleteResult,
} from '../../util';
import { difference } from 'lodash';
import { MILESTONE_MEETING_TYPE } from '../../constants';
import { DataInputs, processor, filterUserCountMap, Event, getAppYearStudents } from './util';

/**
 *
 *
 * @param {Application[]} applications
 * @param {string[]} studentUserIds
 * @return {*}
 * School selection not started => Users that dont have a appliation
 * return user ids
 */
export const schoolSelectionNotStarted = ({ applications, studentUserIds }: DataInputs) => {
  const userAppCount = processor(applications, studentUserIds, () => true, 'userId');
  return filterUserCountMap(userAppCount);
};

export const getSchoolSelectionData = ({
  applications,
  approvals,
  studentUserIds,
  ...res
}: Pick<DataInputs, 'applications' | 'studentUserIds' | 'approvals'>) => {
  const usersWithLongList = processor(applications, studentUserIds, (item) => item.group === 'PROSPECTIVE');
  const usersWithShortList = processor(applications, studentUserIds, (item) => item.group === 'SHORTLISTED');
  const usersWithFinalList = processor(applications, studentUserIds, (item) => item.group === 'APPLYING');

  const longListUserIds = filterUserCountMap(usersWithLongList, (v) => !!v);
  const shortListUserIds = filterUserCountMap(usersWithShortList, (v) => !!v);
  const finalListUserIds = filterUserCountMap(usersWithFinalList, (v) => !!v);

  const usersHasApprovals = processor(approvals, studentUserIds, (item) => true);
  const userIdsHasApproval = filterUserCountMap(usersHasApprovals, (v) => !!v);
  const finalListApprovedCountMap = processor(approvals, studentUserIds, (app) => app.responseType === 'approved');
  const finalListApproved = filterUserCountMap(finalListApprovedCountMap, (v) => !!v);
  const parentApprovalPending = getParentApprovalPendingUserIds({ approvals, studentUserIds });

  return {
    inExploration: difference(longListUserIds, shortListUserIds, finalListUserIds, userIdsHasApproval), // [long list users] - [short list users] - [final list users] - [users have approvals]
    shortListInProgress: difference(shortListUserIds, finalListUserIds, userIdsHasApproval), // [shor list users] - [final list users] - [users have approvals]
    finalListInProgress: difference(finalListUserIds, userIdsHasApproval), // [final list users] - [users have approvals]
    parentApprovalPending: parentApprovalPending,
    finalListApproved,
  };
};
/**
 *
 *
 * return {
 *  inExploration
 *  shortListInProgress
 *  finalListInProgress
 * }
 */
export const schoolSelectionDataBase: Omit<
  Event<
    Record<
      'inExploration' | 'shortListInProgress' | 'finalListInProgress' | 'parentApprovalPending' | 'finalListApproved',
      string[]
    >
  >,
  'region' | 'value' | 'label'
> = {
  priority: 0,
  availability: () => true,
  filterAppYearStudents: (students) => getAppYearStudents(students),
  resolve: (input: DataInputs) => {
    return getSchoolSelectionData(input);
  },
};

/**
 *
 *
 * @param {SessionReport[]} sessionReports
 * @param {string[]} studentUserIds
 * @return {*}
 * userIds have done milestone meeting1, meeting2, meeting3, meeting4
 */
export const milestoneMeetingBase: Omit<
  Event<Record<'meeting1' | 'meeting2' | 'meeting3' | 'meeting4', string[]>>,
  'region' | 'value'
> = {
  priority: 0,
  availability: () => true,
  label: 'Milestone Meeting',
  filterAppYearStudents: getAppYearStudents,
  resolve: ({ sessionReports, studentUserIds }: DataInputs) => {
    const meetings = Object.values(MILESTONE_MEETING_TYPE).map((sessionType) =>
      processor(sessionReports, studentUserIds, (item) => item.sessionType === sessionType, 'studentId'),
    );
    const [meeting1UserIds, meeting2UserId2, meeting3UserIds, meeting4UserIds] = meetings.map((m) =>
      filterUserCountMap(m, (v) => !!v),
    );
    return {
      meeting1: meeting1UserIds,
      meeting2: meeting2UserId2,
      meeting3: meeting3UserIds,
      meeting4: meeting4UserIds,
    };
  },
};

/**
 *
 *
 * @param {Application[]} applications
 * @param {string[]} studentUserIds
 * @param {ApplicationRegion} region
 * @return {*}
 * only display applications with deadlineType === 'university_deadline'
 * return {
 *  label: date 'YYYY-MM-DD'
 *  applications: Application[],
 *  studentUserIds: string[]
 * }
 */
export const sortByOfficialDeadlines = ({ applications, region }: DataInputs) => {
  const withOfficialDeadlines = applications.filter((o) => o.deadlineType === 'university_deadline');
  const grouped = groupApplications(withOfficialDeadlines, 'Deadline', region);
  return grouped.map(({ label, applications }) => ({
    label,
    applications,
    studentUserIds: applications.map((o) => o.userId),
  }));
};

type BaseEvent = Pick<Event, 'priority' | 'label' | 'resolve'>;

export const schoolConfirmationNotSendBase: BaseEvent = {
  priority: 0,
  label: 'School confirmation agreement not sent',
  resolve: (input: DataInputs) => {
    const { approvals } = input;
    // get student user ids from Final List In progress
    const _studentUserIds = schoolSelectionDataBase.resolve(input).finalListInProgress;
    const userApprovalCount = processor(approvals, _studentUserIds, () => true, 'userId');
    return filterUserCountMap(userApprovalCount);
  },
};

export const getParentApprovalPendingUserIds = ({
  approvals,
  studentUserIds,
}: Pick<DataInputs, 'approvals' | 'studentUserIds'>) => {
  const userCountMap = processor(approvals, studentUserIds, (item) => !item.responseType, 'userId');
  return filterUserCountMap(userCountMap, (v) => !!v);
};

export const parentApprovalPendingBase: BaseEvent = {
  priority: 0,
  label: 'Parent confirmation pending',
  resolve: ({ approvals, studentUserIds }: DataInputs) => {
    return getParentApprovalPendingUserIds({ approvals, studentUserIds });
  },
};

export const parentApprovalRejectedBase: BaseEvent = {
  priority: 0,
  label: 'Parent confirmation rejected',
  resolve: ({ studentUserIds, approvals }: DataInputs) => {
    const userWithRejectedApproval = processor(approvals, studentUserIds, (item) => item.responseType === 'rejected');
    return filterUserCountMap(userWithRejectedApproval, (v) => !!v);
  },
};

export const onlyReceiveRejectionBase: BaseEvent = {
  priority: 0,
  label: 'Only received rejections',
  resolve: ({ applications, studentUserIds }: DataInputs) => {
    const EXPECTED_STATUS = ['Rejected'];
    const usersWithRejected = processor(applications, studentUserIds, (item) =>
      EXPECTED_STATUS.includes(getLastApplicationStatus(item)),
    );
    const usersWithOtherStatus = processor(
      applications,
      studentUserIds,
      (item) => !EXPECTED_STATUS.includes(getLastApplicationStatus(item)),
    );
    const userIdsWithRejected = filterUserCountMap(usersWithRejected, (v) => !!v);
    const userIdsWithOtherStatus = filterUserCountMap(usersWithOtherStatus, (v) => !!v);
    return difference(userIdsWithRejected, userIdsWithOtherStatus);
  },
};
export const haventReceiveOfferBase: BaseEvent = {
  priority: 0,
  label: 'Yet to receive offer',
  resolve: ({ applications, studentUserIds }: DataInputs) => {
    const userCountMap = processor(applications, studentUserIds, isApplicationReceivedResult);
    return filterUserCountMap(userCountMap, (v) => !!v);
  },
};

export const incompleteResultsBase: BaseEvent = {
  label: 'Incomplete results',
  priority: 0,
  resolve: ({ applications, studentUserIds }) => {
    const countMap = processor(applications, studentUserIds, isApplicationHasIncompleteResult);
    return filterUserCountMap(countMap, (v) => !!v);
  },
};

export const submissionOverdueBase: BaseEvent = {
  priority: 0,
  label: 'Submission overdue',
  resolve: ({ applications, studentUserIds }: DataInputs) => {
    const userCountMap = processor(applications, studentUserIds, isApplicationOverdue, 'userId');
    return filterUserCountMap(userCountMap, (v) => !!v);
  },
};
