import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Body, Caption } from 'components/atoms/typography';
import { formatCurrencyValue, formatItemDate, formatItemTime } from 'utils/invoiceUtils';
import { getLessonState } from 'utils/lessonUtils';

import css from './styles.scss';

export default function InvoiceItem({ item, currency }) {
  const { amount, duration, end, start, status, studentName, subjectName } = item;
  const lessonState = getLessonState({ start, end, status });

  const hours = moment.duration(duration, 'hours');
  const minutes = Math.round(hours.asMinutes() - Math.floor(hours.asHours()) * 60);
  const durationFormatted = `${Math.floor(hours.asHours())}h ${minutes}m`;

  return (
    <div className={css.invoiceItem}>
      <div className={css.itemDetails}>
        <Body>{formatItemDate(start, end)}</Body>
        <Caption>{formatItemTime(start, end)}</Caption>
        <Body>Subject: {subjectName}</Body>
        <Body>Student: {studentName}</Body>
      </div>

      <div className={css.itemStatus}>
        <Body bold>
          <i className={`${lessonState.icon} ${css[lessonState.style]}`} /> {lessonState.statusName}
        </Body>
        <div>
          <Caption>(Duration: {durationFormatted})</Caption>
          <Body className={css.monies}>{formatCurrencyValue({ currency, amount })}</Body>
        </div>
      </div>
    </div>
  );
}

InvoiceItem.propTypes = {
  item: PropTypes.object,
  currency: PropTypes.string,
};
