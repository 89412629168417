import React, { useReducer, createContext, useCallback, useEffect } from 'react';
import { TAppTrackerState, Actions, reducer, initialState } from './state';
import { AppTrackerController } from './controller';
import { useDispatch } from 'react-redux';
type TContext = {
  state: TAppTrackerState;
  dispatch: <A extends Actions>(name: A['name'], payload?: A['payload']) => void;
  controller: AppTrackerController;
};

export const AppTrackerContext = createContext<TContext>(null as any);

export const withAppTrackerContext = <P,>(Comp: React.ComponentType<P>) => {
  const controller = new AppTrackerController();
  const EnhancedComp = (props: P): JSX.Element => {
    const [state, _dispatch] = useReducer(reducer, initialState);
    const dispatch = useCallback(
      <A extends Actions>(name: A['name'], payload?: A['payload']) => _dispatch({ name, payload } as A),
      [_dispatch],
    );
    const reduxDispatch = useDispatch();
    useEffect(() => {
      controller.setParams(state, dispatch, reduxDispatch);
    }, [state, dispatch, reduxDispatch]);
    return (
      <AppTrackerContext.Provider
        value={{
          state,
          dispatch,
          controller,
        }}
      >
        <Comp {...props} />
      </AppTrackerContext.Provider>
    );
  };
  return EnhancedComp;
};
