import React, { useMemo, useReducer, useCallback } from 'react';
import PropTypes from 'prop-types';

import api from 'graphql/api/cga';
import { reportTypes } from '../utils';
import reportViewReducer, { defaultReportViewState, actions } from './reducer';
import * as Logger from '@crimson-education/browser-logger';

export const ReportsContext = React.createContext();

const CGAReportsProvider = ({ children }) => {
  const [state, rawDispatch] = useReducer(reportViewReducer, defaultReportViewState);

  const dispatch = useCallback((type, payload = null) => {
    rawDispatch({ type, payload });
  }, []);

  const { dirtyStudentStreams, studentReports, reportGroup, intakes, student, statusId } = state;

  const { id: studentId } = student || {};

  const [reportIntake, reportType] = useMemo(
    () =>
      reportGroup
        ? [
            intakes?.find(({ id }) => id === reportGroup.intake_id),
            reportTypes.find(({ value }) => value === reportGroup.type),
          ]
        : [],
    [intakes, reportGroup],
  );

  const updateReportStatus = async (reportId) => {
    dispatch(actions.UPDATING_STATUS_START);

    const result = await api.updateStudentReportStatus({
      reportId,
      studentId,
      status: statusId,
    });

    if (!result) {
      Logger.reportError('Update of report status failed');
      return;
    }

    const reports = studentReports.map((studentReport) => {
      if (studentReport.student.id === studentId) {
        return { ...studentReport, status: statusId };
      }
      return studentReport;
    });

    dispatch(actions.UPDATING_STATUS_SUCCESS, { reports });
  };

  const updateReport = async (reportId) => {
    dispatch(actions.UPDATING_REPORT_START);
    const result = await api.updateStudentReport({
      reportId,
      studentId,
      status: statusId,
      studentReportStreams: dirtyStudentStreams.map((stream) => ({
        studentStreamId: stream.student_stream_id,
        enabled: stream.enabled,
      })),
    });
    if (!result) {
      Logger.reportError('Update of Report generation failed');
      return;
    }

    const { updateCGAStudentReport: updatedStudentReport } = result;
    const reports = studentReports.map((studentReport) => {
      if (studentReport.id === updatedStudentReport.id) {
        return { ...updatedStudentReport, status: updatedStudentReport.generation.status };
      }
      return studentReport;
    });

    dispatch(actions.UPDATING_REPORT_SUCCESS, { reports });
  };

  const updateAllReports = async (reportId) => {
    dispatch(actions.UPDATING_REPORT_START);
    const result = await api.updateAllReports(reportId);
    if (!result) {
      ReportError('Update of Report generation failed');
      return;
    }

    const { regenerateReports = [] } = result;
    const reports = studentReports.map((studentReport) => {
      const generatedReport = regenerateReports.find((r) => r.id === studentReport.id);
      if (generatedReport) {
        return { ...studentReport, status: generatedReport.generation.status };
      }
      return studentReport;
    });
    dispatch(actions.UPDATING_REPORT_SUCCESS, { reports });
  };

  const providerValues = {
    state,
    dispatch,
    updateReportStatus,
    updateReport,
    updateAllReports,
    reportIntake,
    reportType,
  };

  return <ReportsContext.Provider value={providerValues}>{children}</ReportsContext.Provider>;
};

export default CGAReportsProvider;

CGAReportsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
