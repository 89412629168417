import Select from 'components/molecules/Select';
import styled from 'styled-components';
import { SecondaryButton } from '../index.style';

export const StyledSelect = styled(Select)`
  background: #ffffff;
  border: 1px solid #e3e7ed;
  border-radius: 4px;
`;

export const ModalText = styled.span`
  font-weight: 500;
  font-size: 14px;
  font-family: 'Montserrat';
  line-height: 20px;
  color: #1d1e2b;
  margin: 40px 0;
  white-space: pre-wrap;
`;

export const Separator = styled.div<{ $marginTop?: string; $margin?: string }>`
  width: auto;
  height: 1px;
  background: #e3e7ed;
  margin-top: ${({ $marginTop }) => $marginTop ?? 'none'};
  margin: ${({ $margin }) => $margin ?? 'none'};
`;

export const DropdownButton = styled(SecondaryButton)`
  text-align: left;
  border: none;
  height: 25px;
  font-weight: 500;
  font-size: 12px;
  color: #73747d;
  font-family: 'Montserrat';
  padding: 0 5px;

  &.MuiButtonBase-root {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const Tag = styled.div<{ $selected: boolean }>`
  height: 26px;
  background: ${(props) => (props.$selected ? '#464ac9' : '#F4F5FF')};
  border: 1px solid #464ac9;
  border-radius: 20px;
  color: ${(props) => (props.$selected ? '#FFFFFF' : '#464ac9')};
  font-size: 14px;
  box-sizing: border-box;
  cursor: pointer;
  text-align: center;
  line-height: 26px;
  padding: 0 15px;
  transition: background, color 0.4s;
`;
