import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import FilledModalSection from 'components/molecules/FilledModalSection';
import WarningModalSection from 'components/molecules/WarningModalSection';
import TextArea from 'components/molecules/TextArea';
import TutorCard from 'components/molecules/TutorCard';
import Modal from 'components/molecules/Modal';
import { Cactus as CactusGraphic } from 'components/atoms/graphics';
import { Caption, Body } from 'components/atoms/typography';
import { getUserLocation } from 'utils/people';

import fontColour from 'components/atoms/typography/fontColour.scss';
import flex from 'components/atoms/flex.scss';
import css from './style.scss';

const VALIDATION_MESSAGE = 'Please complete this field';
const WARNING_BANNER_MESSAGE =
  'There can only be one tutor allocated. Once a tutor accepts this request, the current tutor will be automatically removed.';

export default function SendRequestModal(props) {
  const {
    isModalOpen,
    submit,
    isSubmitDisabled,
    closeModal,
    modalLoading,
    hasActiveTutor,
    subjectName,
    hours,
    tutoringFrequency,
    preferredTimes,
    additionalNotes,
    handleFieldChange,
    allTutors,
    selectedTutors,
    handleSelectTutors,
    formError,
    student,
    getLanguages,
  } = props;

  const location = student ? getUserLocation(student.city, student.country) : false;
  if (student && student.primaryLanguage) {
    student.languages.unshift(student.primaryLanguage);
  }
  const languages = student ? getLanguages(student.languages) : [];

  return (
    <Modal
      isOpen={isModalOpen}
      title="Send request"
      submitText="Send request"
      onSubmit={submit}
      isSubmitDisabled={isSubmitDisabled}
      onClose={closeModal}
      loading={modalLoading}
    >
      {hasActiveTutor && <WarningModalSection message={WARNING_BANNER_MESSAGE} />}

      <FilledModalSection heading={subjectName} subHeading={`${hours} hours`}>
        <div className={css.studentDetailsSection}>
          {/* This section is broken now that we have multiple schools.
              Jira ticket: https://crimsonconsulting.atlassian.net/browse/LMS-4850 */}
          {/* student && student.studentInfo.schoolYearLevel &&
          <div className={css.studentDetailEntry}>
            <Caption>Year level</Caption>
            <Body>{student.studentInfo.schoolYearLevel}</Body>
          </div> */}
          {languages && languages.length > 0 && (
            <div className={css.studentDetailEntry}>
              <Caption>Languages</Caption>
              <Body>{languages.join(', ')}</Body>
            </div>
          )}
          {student && student.studentInfo.schoolName && (
            <div className={css.studentDetailEntry}>
              <Caption>School</Caption>
              <Body>{student.studentInfo.schoolName}</Body>
            </div>
          )}
          {location && (
            <div className={css.studentDetailEntry}>
              <Caption>Location</Caption>
              <Body>{location}</Body>
            </div>
          )}
        </div>
      </FilledModalSection>

      <div className={css.formArea}>
        <Caption>Frequency of tutoring</Caption>
        <TextArea
          autoSize
          value={tutoringFrequency}
          isError={formError}
          validationMessage={VALIDATION_MESSAGE}
          onChange={(value) => handleFieldChange('tutoringFrequency', value)}
        />
      </div>

      <div className={css.formArea}>
        <Caption>Preferred dates and times</Caption>
        <TextArea
          autoSize
          value={preferredTimes}
          isError={formError}
          validationMessage={VALIDATION_MESSAGE}
          onChange={(value) => handleFieldChange('preferredTimes', value)}
        />
      </div>

      <div className={css.formArea}>
        <Caption>Additional notes</Caption>
        <TextArea
          autoSize
          value={additionalNotes}
          placeholder="Tutoring qualities, exams or tests coming up, student goals, etc."
          onChange={(value) => handleFieldChange('additionalNotes', value)}
        />
      </div>

      <div className={css.requestArea}>
        <Body bold className={classNames(fontColour.secondary)}>
          Send request to:
        </Body>
        <div>
          {allTutors && selectedTutors.length ? (
            selectedTutors.map((selectedTutorId) => {
              return (
                <TutorCard
                  key={`${selectedTutorId}-modalCard`}
                  tutor={allTutors.find((tutor) => tutor.userId === selectedTutorId)}
                  onClose={() => handleSelectTutors(selectedTutorId)}
                />
              );
            })
          ) : (
            <div className={classNames(flex.col1, css.noTutorsSelected)}>
              <div className={css.cactusGraphic}>
                <CactusGraphic />
              </div>
              <Body className={fontColour.secondary}>All tutors were removed.</Body>
              <Body className={fontColour.secondary}>You need to select someone to send this to.</Body>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}

SendRequestModal.propTypes = {
  isModalOpen: PropTypes.bool,
  submit: PropTypes.func.isRequired,
  isSubmitDisabled: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  modalLoading: PropTypes.bool,
  hasActiveTutor: PropTypes.bool,
  subjectName: PropTypes.string,
  hours: PropTypes.string,
  tutoringFrequency: PropTypes.string,
  preferredTimes: PropTypes.string,
  additionalNotes: PropTypes.string,
  handleFieldChange: PropTypes.func,
  allTutors: PropTypes.array,
  selectedTutors: PropTypes.array,
  handleSelectTutors: PropTypes.func.isRequired,
  formError: PropTypes.bool,
  student: PropTypes.object,
  getLanguages: PropTypes.func.isRequired,
};
