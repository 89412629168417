import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import MuiButton from '@material-ui/core/Button';
import Dialog from './Dialog';

function DialogButton({ label, title, body, color }) {
  const dialog = useRef();

  return (
    <div>
      <MuiButton
        onClick={() => dialog.current && dialog.current.show()}
        variant="contained"
        className="ds-button"
        color={color}
      >{ label }</MuiButton>
      <Dialog ref={dialog} title={title} body={body} />
    </div>
  );
}

DialogButton.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  label: PropTypes.string,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  color: PropTypes.string
};

export default DialogButton;