import React from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
import LoadingIndicator from 'components/molecules/LoadingIndicator';
import InvoiceListItem from './InvoiceListItem';

import css from './styles.scss';

export default function InvoiceListView(props) {
  const { invoices, hasNextPage, loadMore, onSelect, selectedInvoiceId, loginUserTimezone, showTutor } = props;

  return (
    invoices && (
      <div className={css.invoiceList}>
        <InfiniteScroll initialLoad={false} loadMore={loadMore} hasMore={hasNextPage} useWindow={false}>
          {invoices.map((invoice, idx) => (
            <InvoiceListItem
              onSelect={(e) => {
                e.preventDefault();
                onSelect(invoice, idx);
              }}
              key={idx}
              invoice={invoice}
              selected={selectedInvoiceId === invoice.id}
              loginUserTimezone={loginUserTimezone}
              showTutor={showTutor}
            />
          ))}
          {hasNextPage && <LoadingIndicator className={css.loadingIndicator} colour="hint" />}
        </InfiniteScroll>
      </div>
    )
  );
}

InvoiceListView.propTypes = {
  invoices: PropTypes.array,
  loginUserTimezone: PropTypes.string,
  hasNextPage: PropTypes.bool,
  loadMore: PropTypes.func,
  onSelect: PropTypes.func,
  selectedInvoiceId: PropTypes.string,
  showTutor: PropTypes.bool,
};
