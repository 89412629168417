import React from 'react';
import { Row } from './index.style';
import { Tag } from '../components';
import { useStaffDashboardContext } from './helper';
import { StaffDashboardRegion } from './type';
const REGIONS = ['US', 'UK', 'EU'];

const SelectRegion = () => {
  const { state, controller } = useStaffDashboardContext();
  return (
    <Row gap={'12px'}>
      {REGIONS.map((region) => (
        <Tag
          style={{ padding: '0 15px' }}
          key={region + 'staffbashboard'}
          $selected={state.region === region}
          onClick={() => controller.setRegion(region as StaffDashboardRegion)}
        >
          {region}
        </Tag>
      ))}
    </Row>
  );
};

export default SelectRegion;
