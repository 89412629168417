import React, { Component } from 'react';
import PageVisibility from 'react-page-visibility';
import moment from 'moment-timezone';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { PermissionResourceType } from '@crimson-education/common-config/lib/authorization';
import { userStatusTypes } from '@crimson-education/common-config';
import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';
import * as Logger from '@crimson-education/browser-logger';
import LoadingBar from 'components/molecules/LoadingBar';
import LeftNav from 'components/unique/LeftNav';
import TopNav from 'components/unique/TopNav';
import registerAppleDeviceToken from 'utils/registerIOSToken';
import AppcuesLoader from 'components/unique/AppcuesLoader';
import io from 'socket.io-client';

import TIM from 'tim-js-sdk';

import css from './styles.scss';

import tim, { loginToIM, logutFromIM } from '../../../tim/tim';

import { featureSwitches } from '../../../featureSwitches';

const config = getConfig();

function pollPeriodically(func) {
  func();
  setTimeout(() => pollPeriodically(func), 5 * 60 * 1000);
}

export default class AppWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      showMenu: false,
    };

    const { isMobile } = props.app;
    if (!isMobile) {
      this.state.showMenu = localStorage.getItem('showMainMenu') !== 'false';
    }

    this.fetchDataAndConfigure = this.fetchDataAndConfigure.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.openZendesk = this.openZendesk.bind(this);

    const { fetchBookingsRequiringFeedBack, history, saveAppVersion } = props;

    setInterval(() => {
      while (window.actionQueue.length) {
        const a = window.actionQueue.shift();
        console.debug('Processing queued action', a); /* eslint-disable-line no-console */
        const eventInfo = {
          category: 'action-queue',
          action: a.action,
        };

        // TODO: make this use a Redux action if it works
        switch (a.action) {
          case 'locationChange':
            history.push(a.data.path);
            eventInfo.label = a.data.source;
            eventInfo.value = a.data.path;
            break;
          case 'registerIOSToken':
            registerAppleDeviceToken(a.data.token);
            break;
          case 'checkForFeedback':
            fetchBookingsRequiringFeedBack();
            break;
          case 'registerAppVersionForDeviceId':
            a.data && saveAppVersion('IOS', a.data.appVersion, a.data.deviceId);
            break;
          default:
            console.debug(`No handler found for action ${a.action}`); /* eslint-disable-line no-console */
            return;
        }

        Logger.trackEvent({ message: 'event-triggered', metadata: eventInfo });
      }
    }, 200);

    // This fixes the issue when the keyboard is hidden in iOS
    // https://openradar.appspot.com/radar?id=5018321736957952
    if (props.app.isMobile) {
      document.addEventListener('focusout', () => {
        setTimeout(() => window.scrollTo(window.scrollX, window.scrollY), 100);
      });
    }
  }

  componentDidMount() {
    this.fetchDataAndConfigure();
    window.addEventListener('beforeunload', this.beforeunload);
    window.addEventListener('online', this.ononline);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { location, loginUser, locationChange, guessAndUpdateLoginUserTimezone } = this.props;

    const isAutoTimezoneChanging = nextProps.loginUser.get('autoTimezone') !== loginUser.get('autoTimezone');
    const isAutoTimezoneChangingToTrue = nextProps.loginUser.get('autoTimezone') === true;

    // Only guess the new timezone when autoTimezone has been changed to true.
    if (isAutoTimezoneChanging && isAutoTimezoneChangingToTrue) {
      guessAndUpdateLoginUserTimezone(nextProps.loginUser.get('userId'));
    }

    // Set timezone on Moment so that all date formating will be based on the
    // user's specified timezone.
    if (isAutoTimezoneChanging) {
      moment.tz.setDefault(nextProps.loginUser.get('timezone'));
    }

    // Check for and indicate location change.
    if (location.pathname !== nextProps.location.pathname) {
      locationChange(nextProps.location.pathname);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.isLoaded && this.state.isLoaded) {
      const { fetchBookingsRequiringFeedBack, loginUser, fetchThreads } = this.props;

      const userId = loginUser.get('userId');
      if (userId) {
        pollPeriodically(() => {
          fetchBookingsRequiringFeedBack();
        });
        this.cleanLocalStorage();
        localStorage.setItem('currentUserId', userId);
        loginToIM(userId);
        fetchThreads(userId, 'DIRECT');
      }
    }
  }

  onVisibilityChange = (isVisible) => {
    const userId = this.props.loginUser.get('userId');
    const location = window.location.pathname;
    if (userId && isVisible && location?.startsWith('/messages')) {
      this.props.fetchThreads(userId, 'DIRECT');
    }
  };

  onMessageReceived = (event) => {
    const { updateTypingStatus, markUserOnlineStatus, withdrawMessage, loginUser, fetchThreads } = this.props;
    const allData = event.data;
    const currentUserId = loginUser.get('userId');
    for (const data of allData) {
      if (data.type === 'TIMCustomElem') {
        const messages = JSON.parse(data.payload.data);
        const from = data.from.replace(`${config.timConfig.source}-`, '');
        const to = data.to.replace(`${config.timConfig.source}-`, '');
        const source = messages.source && messages.source === 'COMMUNITY' ? 'COMMUNITY' : 'DIRECT';
        if (messages.type === 'action') {
          updateTypingStatus(from, messages.threadId);
        } else if (messages.type === 'consume') {
          if (currentUserId === to) {
            fetchThreads(to, source, [messages.threadId]);
          }
        } else if (messages.type === 'state') {
          markUserOnlineStatus(messages.userId, messages.status);
        } else if (messages.type === 'withdraw') {
          withdrawMessage(messages.messageId, false);
          if (currentUserId === to) {
            fetchThreads(to, source, [messages.threadId]);
          }
        } else if (currentUserId === to) {
          // for other type of messages, fetch them from api
          fetchThreads(to, source, [messages.threadId]);
        }
      }
    }
  };
  ononline = () => {
    const { loginUser } = this.props;

    const userId = loginUser.get('userId');
    if (userId) {
      loginToIM(userId);
    }
  };

  beforeunload = () => {
    logutFromIM();
  };
  // Fetch user data and configure.
  async fetchDataAndConfigure() {
    const {
      fetchUserProfile,
      fetchLoginUser,
      refreshZendeskLoginUrl,
      location,
      locationChange,
      app,
      markUserOnlineStatus,
    } = this.props;

    const profile = await fetchUserProfile();

    const userRoles = profile && profile.get('userRoles').toArray();
    const userStatus = profile && profile.get('status');

    // Block access to users who don't have a profile in the Crimson App,
    // or users with only System Admin role as they should only be able to access admin panel.
    const canAccessApp = featureSwitches.SYSTEM_ADMIN_MULTIPLE_ROLES()
      ? userRoles?.length === 1 && userRoles[0] === 'SYSTEM_ADMIN'
      : userRoles.some((roleId) => roleId === 'SYSTEM_ADMIN');

    if (!profile || userStatus === userStatusTypes.INACTIVE || canAccessApp) {
      window.location = '/error?type=UnauthorizedApp';
      return;
    }

    const userId = profile.get('userId');
    const isMultiTenant = profile.get('isMultiTenant');
    const myTags = profile.get('myTags');

    if (isMultiTenant && myTags.size === 0) {
      window.location = '/error?type=IncompleteRecords&technicalDetails=No tenancy assigned to user';
      return;
    }

    const socket = io.connect(config.api.websocketEndpoint);
    app.setSocket(socket);
    tim.on(TIM.EVENT.MESSAGE_RECEIVED, this.onMessageReceived);

    const onProfileUpdated = (event) => {
      event.data.forEach((data) => {
        const userId = data.userID.replace(`${config.timConfig.source}-`, '');
        if (data.nick === 'online') {
          markUserOnlineStatus(userId, true);
        } else {
          markUserOnlineStatus(userId, false);
        }
      });
    };
    tim.on(TIM.EVENT.PROFILE_UPDATED, onProfileUpdated);

    await fetchLoginUser(profile.get('userId'));
    locationChange(location.pathname);

    // Run non-critical configuration.
    try {
      const contextMetadata = {
        isAnonymous: 'false',
        userId,
        userRoles: userRoles.toString(),
      };
      if (navigator.userAgent.includes('[Crimson iOS]')) {
        contextMetadata.dataSource = 'crimson-ios';
      }
      Logger.addMetadata(contextMetadata);

      const email = profile.get('email');
      const fullName = this.props.loginUser.get('fullName');
      Logger.setUser({
        id: userId,
        email,
        username: email,
        name: fullName,
      });
      Logger.recordSession();

      if (!config.dulwich) {
        refreshZendeskLoginUrl();

        window.Intercom('boot', {
          app_id: config.intercom.appId,
          name: fullName,
          first_name: this.props.loginUser.get('firstName'),
          last_name: this.props.loginUser.get('lastName'),
          user_id: profile.get('userId'),
        });
      }
    } catch (error) {
      // Report non-critical error.
      Logger.reportError(error);
    }

    this.setState({ isLoaded: true });
  }

  toggleMenu(open) {
    this.setState((state) => {
      const showMenu = open === undefined ? !state.showMenu : open;
      localStorage.setItem('showMainMenu', showMenu);
      return { showMenu };
    });
  }

  openZendesk() {
    const { refreshZendeskLoginUrl, zendeskLoginUrl } = this.props;

    refreshZendeskLoginUrl();
    window.open(zendeskLoginUrl, '_blank');
  }

  cleanLocalStorage = () => {
    const day = 60 * 60 * 24 * 1000;
    const keys = JSON.stringify(localStorage).match(/(event-agenda-\d*)|(event-notes-\d*)/g);

    if (!keys) {
      return;
    }

    for (const key of keys) {
      try {
        const lsItem = localStorage.getItem(key);
        if (lsItem) {
          const session = JSON.parse(lsItem);

          // delete session data updated more than 7 days ago
          if (session.updatedAt && (new Date() - new Date(session.updatedAt)) / day > 7) {
            localStorage.removeItem(key);
          }
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    }
  };

  render() {
    const {
      children,
      loginUser,
      isPageLoading,
      // newMessageCount,
      hasUnReadMessage,
      myUserProfile,
      loginUserRoles,
      loginUserPermissions,
      loginUserPermissionsInfo,
      reports,
      hasNewFriend,
    } = this.props;

    // Render nothing if auth has not completed.
    if (!this.state.isLoaded) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '95vh',
          }}
        >
          <img
            src="//images.ctfassets.net/4j9ymu8qg1je/2RYlC9p0lWEKou0Iyasysu/05e4183edfa28b91652f36add0e4d61b/loading.gif"
            style={{
              width: '80px',
              height: '80px',
            }}
            alt="Loading..."
          />
        </div>
      );
    }

    // We need set timezone for the user before everything, so we have to wait
    // for the user info. Currently, login user info is in both userProfile from
    // Auth0 and loginUser.
    if (!(myUserProfile && myUserProfile.size && loginUser && loginUser.size)) {
      return null;
    }

    const path = this.props.location.pathname;

    const loginUserPermissionResourceTypes = loginUserPermissionsInfo
      .filter(
        (p) =>
          (p.get('action') === 'view' || p.get('action') === 'edit') &&
          (p.get('resource') === '*' || p.get('resource') === 'own'),
      )
      .map((p) => p.get('resourceType'))
      .filter((v, i, a) => a.indexOf(v) === i);
    // add minimum resource types that users must have to view appcues
    const appCuesResourceTypes = [PermissionResourceType.LessonList];
    const appCuesAllowed = appCuesResourceTypes.every((r) => loginUserPermissionResourceTypes.includes(r));
    return (
      <div>
        <PageVisibility onChange={this.onVisibilityChange} />
        {/* <FirebaseMessageListener userId={loginUser.get('userId')} /> */}
        {appCuesAllowed && <AppcuesLoader userId={loginUser.get('userId')} />}
        <div className={css.app}>
          <LoadingBar isPageLoading={isPageLoading} />
          <LeftNav
            showMenu={this.state.showMenu}
            hasUnReadMessage={hasUnReadMessage}
            hasNewFriend={hasNewFriend}
            hasReports={!!reports.length}
            loginUserRoles={loginUserRoles}
            userId={myUserProfile.get('userId')}
            loginUserPermissions={loginUserPermissions}
            toggleMenu={this.toggleMenu}
            openZendesk={this.openZendesk}
          />
          <div
            className={classNames(
              css.body,
              { [css.autoHeight]: path && path.includes('roadmap') },
              { [css.menuOpen]: this.state.showMenu },
            )}
          >
            <TopNav
              toggleMenu={this.toggleMenu}
              openZendesk={this.openZendesk}
              loginUserPermissions={loginUserPermissions}
              isMobile={this.props.app.isMobile}
              history={this.props.history}
            />
            <main className={css.contents}>{children}</main>
          </div>
        </div>
      </div>
    );
  }
}

AppWrapper.propTypes = {
  app: PropTypes.object,
  isPageLoading: PropTypes.bool,
  loginUserRoles: PropTypes.array,
  reports: PropTypes.array,
  loginUser: ImmutablePropTypes.map,
  zendeskLoginUrl: PropTypes.string,
  children: PropTypes.node.isRequired,
  loginUserPermissions: PropTypes.array,
  myUserProfile: ImmutablePropTypes.map,
  location: PropTypes.object.isRequired,
  refreshZendeskLoginUrl: PropTypes.func,
  fetchLoginUser: PropTypes.func.isRequired,
  fetchUserProfile: PropTypes.func.isRequired,
  locationChange: PropTypes.func.isRequired,
  fetchBookingsRequiringFeedBack: PropTypes.func,
  guessAndUpdateLoginUserTimezone: PropTypes.func.isRequired,
  fetchThreads: PropTypes.func.isRequired,
  loginUserPermissionsInfo: PropTypes.array,
  // directAttachMessage: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  saveAppVersion: PropTypes.func,
  markUserOnlineStatus: PropTypes.func,
  updateTypingStatus: PropTypes.func,
  messageConsumed: PropTypes.func,
  withdrawMessage: PropTypes.func,
  hasUnReadMessage: PropTypes.bool,
  hasNewFriend: PropTypes.number,
};
