import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as Icons from 'components/atoms/icons';

import css from './style.scss';

export default function ModalAlert({ className, icon, message }) {
  const Icon = Icons[icon];

  return (
    <div className={classNames(css.modalAlertContainer, className)}>
      <div className={css.modalAlertIcon}>
        <Icon style={{ height: '2rem', width: '2rem' }} />
      </div>
      <div className={css.modalAlertMessage} data-test-id="modalAlertBannerMessageContent">
        {message}
      </div>
    </div>
  );
}

ModalAlert.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  message: PropTypes.string,
};

ModalAlert.defaultProps = {
  icon: 'Info',
};
