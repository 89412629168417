import React from 'react';
import { Row } from '../index.style';
import { StyledPrimaryButton, StyledSecondaryButton } from './index.style';

type ConfirmCancelButtonsProps = {
  onClickCancel?: () => void;
  onClickConfirm?: () => void;
  disabled?: boolean;
  cancelButtonText?: string;
  confirmButtonText?: string;
  style?: React.CSSProperties;
};

const ConfirmCancelButtons = ({
  onClickCancel,
  onClickConfirm,
  disabled,
  confirmButtonText,
  cancelButtonText,
  style,
}: ConfirmCancelButtonsProps) => {
  return (
    <Row style={{ gap: 8, justifyContent: 'center', alignSelf: 'center', ...style }}>
      <StyledSecondaryButton onClick={onClickCancel}>{cancelButtonText || 'Cancel'}</StyledSecondaryButton>
      <StyledPrimaryButton onClick={onClickConfirm} disabled={disabled}>
        {confirmButtonText || 'Confirm'}
      </StyledPrimaryButton>
    </Row>
  );
};

export default ConfirmCancelButtons;
