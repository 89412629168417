import styled from 'styled-components';
import Close from '@material-ui/icons/Close';
export const Dot = styled.div`
  background: #10b981;
  border-radius: 1000px;
  width: 8px;
  height: 8px;
`;

export const Text = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #73747d;
  margin-left: 8px;
`;

export const StyledClose = styled(Close)`
  font-size: 20px;
  color: #1d1e2b;
`;
