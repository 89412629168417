import Immutable from 'immutable';
import { createSelector } from 'reselect';

const packages = (state) => state.get('package');

export const getPackages = createSelector(packages, (packages) => packages);

export const getUserPackage = (userId) => {
  return createSelector(packages, (packages) => (packages.has(userId) ? packages.get(userId) : new Immutable.Map()));
};

export const getUserPackageSubjects = (userId) =>
  createSelector(packages, (allPackages) => {
    if (allPackages.has(userId)) {
      const userPackage = allPackages.get(userId);
      return userPackage && userPackage.get('subjects')
        ? userPackage
            .get('subjects')
            .groupBy((subject) => subject.get('id'))
            .map((groupedSubjects) => groupedSubjects.get(0))
            .map((item) => {
              return item.withMutations((item) => {
                item.set('tutors', item.get('tutors'));
                return item;
              });
            })
            .sortBy((item) => item.get('subject'))
        : new Immutable.OrderedMap({});
    }
    return new Immutable.OrderedMap({});
  });

export const getUserPackageSubject = (userId, itemId) =>
  createSelector(getUserPackage(userId), (userPackage) => {
    return userPackage.has('subjects')
      ? userPackage
          .get('subjects')
          .filter((subject) => `${subject.get('id')}` === itemId)
          .first()
      : undefined;
  });

export const getCanAllocateTutorsForPackage = createSelector(
  (userId, state) => getUserPackage(userId)(state),
  (userPackage) => {
    return userPackage.get('canAllocateTutors');
  },
);

export const getUserPackageId = (userId) =>
  createSelector(getUserPackage(userId), (userPackage) => {
    return userPackage.get('id');
  });
