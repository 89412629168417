import { Acl } from '@crimson-education/common-config';
import forOwn from 'lodash/forOwn';

export function isYear(str) {
  if (!str) {
    // We aren't checking for required here. Use isNotEmpty to check before this.
    return true;
  }
  return /^(19|20)\d{2}$/.test(str);
}

export function isEmail(str) {
  if (!str) {
    return true;
  }
  const regex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  return regex.test(str);
}

export function isNotEmpty(str) {
  return !!str || str === 0; // here we treat number 0 as NOT empty!
}

export function isDateInCorrectStrFormat(str) {
  if (str === null) {
    return true;
  }
  if (str.length === 0) {
    return true;
  }
  const regex = /^[0-3]?[0-9].[0-3]?[0-9].(?:[0-9]{2})?[0-9]{2}$/;
  return regex.test(str);
}

export const stringLengthRule = (length = 255) => (str) => {
  if (!str) {
    // We aren't checking for required here. If the value is undefined, it technically fits the rule of being not being greater than the length.
    return true;
  }
  return str.length <= length;
};

export function validateProperties(props, rules) {
  const ruleFuncArr = typeof rules === 'function' ? [rules] : rules;
  const failingProps = [];
  forOwn(props, (v, k) => {
    for (let i = 0, len = ruleFuncArr.length; i < len; i += 1) {
      if (!ruleFuncArr[i](v)) {
        failingProps.push(k);
        break;
      }
    }
  });
  return failingProps;
}

export function isNegative(value) {
  return value < 0;
}

/**
 * @deprecated Confirm how we handle this logic now with multi-roles.
 */
export function validateRoleAssociations(roleIds) {
  const isStudentSelected = Acl.isStudent(roleIds);
  const isTutorSelected = Acl.isTutor(roleIds);
  const isHeadTutorSelected = Acl.isHeadTutor(roleIds);
  const isAccountSelected = Acl.isAccounts(roleIds);
  const isOperationSelected = Acl.isOperationalSupport(roleIds);

  const validationErrors = {};

  // Check if the user has selected at least one role to assign
  if (roleIds.length === 0) {
    validationErrors.roleError = true;
    validationErrors.roleMessage = 'Please select at least one role';
  }

  // Prevent the user from setting a user to a student AND tutor
  if (isStudentSelected && (isTutorSelected || isHeadTutorSelected)) {
    validationErrors.roleError = true;
    validationErrors.roleMessage = 'Students cannot also be a Tutor or a Head Tutor';
  }

  if (isAccountSelected && !(isOperationSelected || isHeadTutorSelected)) {
    validationErrors.roleError = true;
    validationErrors.roleMessage = 'Accounts can only be associated with Head Tutor or Operation Support';
  }

  return validationErrors;
}
