import React from 'react';
import { Column } from 'components/molecules/Table';
import { columnNames } from '../columnMap';

export default function Location() {
  return (
    <Column
      key={columnNames.Location}
      name="Location"
      renderer={({ rowData }) => {
        if (rowData.get('city') && rowData.get('country')) {
          return `${rowData.get('city')}, ${rowData.get('country')}`;
        } else if (rowData.get('city')) {
          return `${rowData.get('city')}`;
        } else if (rowData.get('country')) {
          return `${rowData.get('country')}`;
        }
        return 'No location available';
      }}
    />
  );
}
