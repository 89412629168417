import { createLogger } from 'redux-logger';

const ignorePrefixes = ['WINDOW_RESIZE', 'conversationClient/'];

const predicate = (getState, action) => {
  if (process.env.NODE_ENV === 'production') return false;
  return !ignorePrefixes.find((prefix) => action.type.startsWith(prefix));
};

const summary = (item) => {
  if (item === null || item === undefined || item === false || Number.isNaN(item)) {
    return `${item}`;
  }
  if (typeof item === 'string') {
    return `"${item}"`;
  }
  if (item && item.toJS && item.toJS instanceof Function) {
    return summary(item.toJS());
  }
  if (item instanceof Array) {
    return `[${item.length}]`;
  }
  if (item instanceof Object) {
    return `{${Object.keys(item).length}}`;
  }
  return item.toString().substr(0, 20);
};

const baseTitleFormatter = (action, time, duration) => (extra) => {
  // return `action @ ${time} ${action.type} <${extra}> (in ${duration.toFixed(2)} ms)`;
  return `(${time} took ${duration.toFixed(0).padStart(4)} ms) ${action.type.padStart(50)} | ${extra}`;
};

const titleFormatter = (action, time, duration) => {
  const formatter = baseTitleFormatter(action, time, duration);
  switch (action.type) {
    case 'normalizr/ADD_ENTITIES':
      // if (action.payload.aggregate) { return formatter(Object.keys(action.payload.aggregate).join(', ')); };
      return formatter(Object.keys(action.payload.entities).join(', '));
    case 'meta/UPDATE_META':
      return formatter(`${action.payload.component}: ${summary(action.payload.meta)}`);
    case 'BATCHING_REDUCER.BATCH':
      return formatter(`Batch size: ${action.payload.length}`);
    default:
      return formatter('<custom>');
  }
};

const logger = createLogger({
  predicate,
  titleFormatter,
  stateTransformer: (state) => state,
  collapsed: () => true,
});

export default logger;
