/* eslint-disable jsx-a11y/no-onchange */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import DropdownIcon from 'components/generic/Icons/Dropdown';
import { trimSpecialCharacters } from 'utils/utils';
import css from './styles.scss';

export default class DropdownField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.defaultValue ? props.defaultValue : '',
    };

    this.handleOnChange = this.handleOnChange.bind(this);
  }

  // This was added in the tracking feature, but breaks drop downs for profile page.
  // It seems to work in other places where the default value is updated onValueChange,
  // but thats not the case in profile page.
  //
  // componentWillReceiveProps(nextProps) {
  //   this.setState({ value: nextProps.defaultValue });
  // }

  handleOnChange(event) {
    this.setState({
      value: event.target.value,
    });
    event.target.blur();
    this.props.onValueChange(event.target.value);
  }

  render() {
    const { id, className, label, options, placeholder, disabled, validationMessage, error, dataTestId } = this.props;
    const { value } = this.state;
    return (
      <div className={classNames(css.formItemContainer, className)}>
        {label && (
          <label className={css.formLabel} htmlFor={id}>
            {label}
          </label>
        )}
        <div className={css.formControlWrapper}>
          <select
            id={id}
            className={classNames(css.selectStyle, { [css.error]: error }, { [css.disabled]: disabled })}
            onChange={this.handleOnChange}
            data-test-id={dataTestId}
            disabled={disabled}
            value={value}
          >
            {placeholder && options ? <option value="">{placeholder}</option> : null}
            {options &&
              options.map((option) => (
                <option
                  value={option.value}
                  key={option.value}
                  data-test-id={`${dataTestId}_${trimSpecialCharacters(option.label)}`}
                >
                  {option.label}
                </option>
              ))}
          </select>
          <DropdownIcon className={css.dropdownIcon} />
        </div>
        {error && validationMessage && <div className={css.validationMessage}>{validationMessage}</div>}
      </div>
    );
  }
}

DropdownField.displayName = 'DropdownField';

DropdownField.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  options: PropTypes.array.isRequired,
  onValueChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  validationMessage: PropTypes.string,
  error: PropTypes.bool,
  dataTestId: PropTypes.string,
};
