import React from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/client';
import Divider from '@material-ui/core/Divider';
import { withAppContext } from 'components/enhancers/AppContext';
import {
  NotificationSwitchContainer,
  NotificationSwitchContainerHeader,
  NotificationSwitchSection,
  NotificationSwitchSectionHeader,
  NotificationSwitchSectionItemLine,
  NotificationSwitchContainerDescription,
  StyledSwitch,
  SpinLoading,
  SpinContainer,
  NoSpinContainer,
  RightPartContainer,
} from './style';
import {
  communityNotificationsDefault,
  useGetNotificationList,
  setValueForDisplay,
  settingCombination,
  getListForCommunityUpdates,
  getListForInteractions,
  getListForEmailNotifications,
} from './hooks';
import {
  updateNotificationSettings,
  getNotificationSettings,
  client,
} from '../../../../../graphql/api/notificationSettings';

// NotificationSwitchItem component
const NotificationSwitchItem = ({ startSpin, spin, data, isMobile, setter, defalutValue, spinLines }) => {
  return (
    <NotificationSwitchSectionItemLine>
      {!isMobile && data.icon}
      <div>{data.title}</div>
      <RightPartContainer
        className={spin || Object.keys(spinLines).find((key) => spinLines[key] === true) ? 'disable' : ''}
      >
        <StyledSwitch
          disabled={spin || Object.keys(spinLines).find((key) => spinLines[key] === true)}
          checkedIcon={
            spin ? (
              <SpinContainer>
                <SpinLoading color="#11c39a" />
              </SpinContainer>
            ) : (
              <NoSpinContainer />
            )
          }
          icon={
            spin ? (
              <SpinContainer>
                <SpinLoading color="#cccccc" />
              </SpinContainer>
            ) : (
              <NoSpinContainer />
            )
          }
          checked={data.key === 'emailNotifications' ? defalutValue : defalutValue[data.key]}
          onChange={(event) => {
            startSpin();
            if (data.key === 'emailNotifications') {
              setter(event.target.checked);
            } else {
              const newValue = { ...defalutValue };
              newValue[data.key] = event.target.checked;
              setter(newValue);
            }
          }}
          name={data.key}
          data-test-id={data.key}
        />
      </RightPartContainer>
    </NotificationSwitchSectionItemLine>
  );
};
NotificationSwitchItem.propTypes = {
  data: PropTypes.object,
  isMobile: PropTypes.bool,
  setter: PropTypes.func,
  defalutValue: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  spin: PropTypes.bool,
  startSpin: PropTypes.func,
  spinLines: PropTypes.object,
};

const communityNotifications = {
  title: 'Community notifications',
  description: 'You can specify which email notifications you receive for community below.',
  interactions: {
    title: 'Interactions',
    description: '(Suggest to keep it on)',
  },
  communityUpdates: {
    title: 'Community Updates',
    description: '',
  },
};

const emailNotifications = {
  title: 'Email notification',
  description:
    'Keep this on to get emails about important notifications like new messages, booking requests and session reminders. Community email notifications can be controlled in the community notifications section below.',
};

const spinLinesDefault = {
  MentionedInPostOrComment: false,
  CommentOnYourPostEvent: false,
  // LikeOnPost: false,
  FriendRequest: false,
  NewPost: false,
  NewEventMember: false,
  NewEventOthers: false,
  UpcomingEvent: false,
  // ChangeModeratorNew: false,
  CommunityWeeklyEmail: false,
};

const spinEmailLinesDefault = {
  emailNotifications: false,
};

// default export component
const NotificationSwitchView = (props) => {
  const [spinEmailLines, setSpinEmailLines] = React.useState({ ...spinEmailLinesDefault });
  const [spinLines, setSpinLines] = React.useState({ ...spinLinesDefault });
  const {
    app: { isMobile },
    userId,
    onSaveDetails,
    subscribedEmailDefault,
  } = props;
  const [subscribedEmail, setSubscribedEmail] = React.useState(subscribedEmailDefault);
  const { loading, refetch, error, data, called } = useQuery(getNotificationSettings, {
    client,
    variables: { userId },
  });
  const [setNotificationSettings] = useMutation(updateNotificationSettings, { client });
  const [communitySettings, setCommunitySettings] = React.useState([]);
  const { interactions, setInteractions, communityUpdates, setCommunityUpdates, savingData } = useGetNotificationList({
    data: communitySettings,
    displayData: communityNotificationsDefault,
  });
  const [interactionsList, setInteractionsList] = React.useState([]);
  const [communityUpdatesList, setCommunityUpdatesList] = React.useState([]);
  const [emailNotificationsList, setEmailNotificationsList] = React.useState([]);
  React.useEffect(() => {
    if (!loading && !error && called && data) {
      const {
        notificationSettings: { settings },
      } = data;
      setCommunitySettings([...settings.email]);
      const newDisplayData = setValueForDisplay({
        displayData: communityNotificationsDefault,
        data: [...settings.email],
        settingCombination,
      });
      setInteractions(newDisplayData.interactions);
      setCommunityUpdates(newDisplayData.communityUpdates);
    }
  }, [data, loading, error, called, setInteractions, setCommunityUpdates]);
  React.useEffect(() => {
    setInteractionsList(getListForInteractions(interactions));
  }, [interactions]);
  React.useEffect(() => {
    setCommunityUpdatesList(getListForCommunityUpdates(communityUpdates));
  }, [communityUpdates]);
  React.useEffect(() => {
    setEmailNotificationsList(getListForEmailNotifications(subscribedEmail));
  }, [subscribedEmail]);
  React.useEffect(() => {
    async function doSaveAction() {
      if (Object.keys(spinLines).find((key) => spinLines[key] === true)) {
        await setNotificationSettings({
          variables: {
            userId,
            settings: {
              email: savingData,
            },
          },
        });
        await refetch({ variables: { userId } });
        setSpinLines({ ...spinLinesDefault });
      }
    }
    const timer = setTimeout(() => {
      if (Object.keys(spinLines).find((key) => spinLines[key] === true)) {
        doSaveAction();
      }
    }, 2000);
    return () => clearTimeout(timer);
  }, [refetch, savingData, setNotificationSettings, userId, spinLines, setSpinLines]);

  React.useEffect(() => {
    async function saveDefails() {
      await onSaveDetails({ subscribedEmail });
      setSpinEmailLines({ ...spinEmailLinesDefault });
    }
    const timer = setTimeout(() => {
      if (Object.keys(spinEmailLines).find((key) => spinEmailLines[key] === true)) {
        saveDefails();
      }
    }, 2000);
    return () => clearTimeout(timer);
  }, [onSaveDetails, subscribedEmail, spinEmailLines, setSpinEmailLines]);

  const startSpin = ({ key, defaultValue, setter }) => {
    const newValue = { ...defaultValue };
    newValue[key] = true;
    setter(newValue);
  };
  return (
    <NotificationSwitchContainer>
      <NotificationSwitchContainerHeader style={{ marginTop: `${isMobile ? '10px' : '30px'}` }}>
        {emailNotifications.title}
      </NotificationSwitchContainerHeader>
      <NotificationSwitchContainerDescription>{emailNotifications.description}</NotificationSwitchContainerDescription>
      <NotificationSwitchSection isMobile={isMobile}>
        {!!emailNotificationsList.length &&
          emailNotificationsList.map((item) => (
            <NotificationSwitchItem
              startSpin={() => startSpin({ key: item.key, defaultValue: spinEmailLines, setter: setSpinEmailLines })}
              spinLines={spinEmailLines}
              spin={spinEmailLines[item.key]}
              key={item.key}
              setter={setSubscribedEmail}
              defalutValue={subscribedEmail}
              data={item}
              isMobile={isMobile}
            />
          ))}
      </NotificationSwitchSection>
      <NotificationSwitchContainerHeader style={{ marginTop: '31px' }}>
        {communityNotifications.title}
      </NotificationSwitchContainerHeader>
      <NotificationSwitchContainerDescription>
        {communityNotifications.description}
      </NotificationSwitchContainerDescription>
      <NotificationSwitchSection isMobile={isMobile}>
        <NotificationSwitchSectionHeader>
          {communityNotifications.interactions.title}
          {communityNotifications.interactions.description && (
            <div className="header-description">{communityNotifications.interactions.description}</div>
          )}
        </NotificationSwitchSectionHeader>
        {!!interactionsList.length &&
          interactionsList.map((item) => (
            <NotificationSwitchItem
              startSpin={() => startSpin({ key: item.key, defaultValue: spinLines, setter: setSpinLines })}
              spinLines={spinLines}
              spin={spinLines[item.key]}
              key={item.key}
              setter={setInteractions}
              defalutValue={interactions}
              data={item}
              isMobile={isMobile}
            />
          ))}
        <Divider />
        <NotificationSwitchSectionHeader style={{ marginTop: '29.5px' }}>
          {communityNotifications.communityUpdates.title}
        </NotificationSwitchSectionHeader>
        {!!communityUpdatesList.length &&
          communityUpdatesList.map((item) => (
            <NotificationSwitchItem
              startSpin={() => startSpin({ key: item.key, defaultValue: spinLines, setter: setSpinLines })}
              spinLines={spinLines}
              spin={spinLines[item.key]}
              key={item.key}
              setter={setCommunityUpdates}
              defalutValue={communityUpdates}
              data={item}
              isMobile={isMobile}
            />
          ))}
      </NotificationSwitchSection>
    </NotificationSwitchContainer>
  );
};

NotificationSwitchView.propTypes = {
  app: PropTypes.object,
  userId: PropTypes.string,
  onSaveDetails: PropTypes.func,
  subscribedEmailDefault: PropTypes.bool,
};
export default withAppContext(NotificationSwitchView);
