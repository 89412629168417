import Immutable from 'immutable';
import createReducer from 'utils/createReducer';
import { ADD_ENTITIES, addEntitiesWithNormalisation } from 'ducks/normalizr';
import { academicEntity } from 'schema';
import academicConnector from 'graphql/api/academic';
import { updateMeta, formFail, formSuccess } from 'ducks/meta';
import componentKeys from 'constants/componentKeys';
import { formatGraphQLRequestError } from 'utils/graphql';

export const UPDATE_ACADEMIC_SUCCEEDED = 'academic/UPDATE_ACADEMIC_SUCCEEDED';
export const DELETE_ACADEMICS_SUCCEEDED = 'academic/DELETE_ACADEMICS_SUCCEEDED';

const initialState = new Immutable.Map();

export default createReducer(initialState, {
  [ADD_ENTITIES]: (state, action) => {
    return state.mergeDeep(action.payload.entities.academic);
  },
  [UPDATE_ACADEMIC_SUCCEEDED]: (state, action) => {
    const { academic } = action.payload;
    return state.setIn([academic.id], Immutable.fromJS(academic));
  },
  [DELETE_ACADEMICS_SUCCEEDED]: (state, action) => {
    const { awardIds } = action.payload;
    let newState = state;
    awardIds.forEach((awardId) => {
      newState = newState.delete(awardId);
      return newState;
    });
    return newState;
  },
});

export function updateAcademicSucceeded(academic) {
  return { type: UPDATE_ACADEMIC_SUCCEEDED, payload: { academic } };
}

export function deleteAcademicSucceeded(awardIds) {
  return { type: DELETE_ACADEMICS_SUCCEEDED, payload: { awardIds } };
}

export function fetchAcademicsByUserId(userId) {
  return async (dispatch) => {
    dispatch(updateMeta(componentKeys.GRADE_ACADEMIC_FETCHED, false));
    const result = await academicConnector.getAcademicsByUserId(userId);
    const academics = result.getAcademicsByUserId;
    if (academics) {
      dispatch(addEntitiesWithNormalisation(academics, [academicEntity]));
    }
    dispatch(updateMeta(componentKeys.GRADE_ACADEMIC_FETCHED, true));
  };
}

export function createAcademic(academic) {
  return async (dispatch) => {
    try {
      const result = await academicConnector.createAcademic(academic);
      dispatch(addEntitiesWithNormalisation(result.createAcademic, academicEntity));
      dispatch(formSuccess('Academic rank has been added successfully'));
      return result.createAcademic;
    } catch (err) {
      dispatch(formFail(formatGraphQLRequestError(err)));
      return false;
    }
  };
}

export function updateAcademic(updates) {
  return async (dispatch) => {
    try {
      const result = await academicConnector.updateAcademic(updates);
      dispatch(updateAcademicSucceeded(result.updateAcademic));
      dispatch(formSuccess('Academic rank  has been updated successfully'));
    } catch (err) {
      dispatch(formFail(formatGraphQLRequestError(err)));
    }
  };
}

export function deleteAcademics(ids) {
  return async (dispatch) => {
    try {
      const result = await academicConnector.deleteAcademics(ids);
      const academicIds = result.deleteAcademics;
      dispatch(deleteAcademicSucceeded(academicIds));
      dispatch(formSuccess('Academic ranks have been removed successfully'));
      return true;
    } catch (err) {
      dispatch(formFail(formatGraphQLRequestError(err)));
      return false;
    }
  };
}
