import { getProfile } from 'utils/auth/profile';
import { LOCATION_CHANGE } from 'ducks/meta';

import registerServiceWorker from '../serviceworker';

export const SUBSCRIBE_SERVICE_WORKER = 'serviceWorker/subscribe';

export const subscribeServiceWorker = () => ({
  type: SUBSCRIBE_SERVICE_WORKER,
});

function extractParamsFromPath(pathname) {
  const [, activeThread] = pathname.match(/^\/messages\/(.{36})/) || [];
  const threadId = activeThread;
  return {
    pathname,
    params: {
      threadId,
    },
  };
}

const serviceWorkerMiddleware = () => (next) => (action) => {
  if (action.type === SUBSCRIBE_SERVICE_WORKER) {
    getProfile().then((user) => {
      if (user) {
        registerServiceWorker(user.userId);
      }
    });
  }

  if (action.type === LOCATION_CHANGE) {
    const message = extractParamsFromPath(action.payload.pathname);
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      registrations.forEach((reg) => reg.active && reg.active.postMessage(JSON.stringify(message)));
    });
  }
  return next(action);
};

export default serviceWorkerMiddleware;
