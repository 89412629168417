import React from 'react';
import PropTypes from 'prop-types';

import css from './style.scss';

export default function CalendarIcon(props) {
  const { content } = props;

  return <div className={css.calendarIcon}>{content || <div className={css.innerIcon} />}</div>;
}

CalendarIcon.defaultProps = {
  content: '',
};

CalendarIcon.propTypes = {
  content: PropTypes.string,
};
