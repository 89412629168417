import createReducer from 'utils/createReducer';
import Immutable from 'immutable';
import { languageEntity } from 'schema';
import { ADD_ENTITIES, addEntitiesWithNormalisation, addMeta, compose } from 'ducks/normalizr';
import componentKeys from 'constants/componentKeys';
import userAPI from 'graphql/api/language';

const defaultState = new Immutable.Map();

export default createReducer(defaultState, {
  [ADD_ENTITIES]: (state, action) => {
    return state.mergeDeep(action.payload.entities.language);
  },
});

export function fetchLanguages() {
  return (dispatch) =>
    userAPI.allLanguages().then(({ getAllLanguages }) => {
      dispatch(
        compose(
          addEntitiesWithNormalisation(getAllLanguages, [languageEntity]),
          addMeta(componentKeys.LANGUAGES_FETCHED, true),
        ),
      );
    });
}
