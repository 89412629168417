import React from 'react';
import PropTypes from 'prop-types';

import { createTimeObject } from 'utils/calendarUtils';
import TimeSelect from 'components/generic/Forms/TimeSelect';
import ErrorIcon from 'components/generic/Icons/Error';
import css from './styles.scss';

const START_LABEL = 'Start time';
const END_LABEL = 'End time';

const INVALID_TIME = 'The end time must be after the start time.';
const UNCHANGED_TIME = 'Start time and End time have not changed.';

export default function AdjustTime(props) {
  const { minuteIncrement, onAdjustedTimeChange, timesInvalid, timesUnchanged } = props;
  const start = createTimeObject(props.start);
  const end = createTimeObject(props.end);

  return (
    <div className={css.adjustTime}>
      <div className={css.timeSelectors}>
        <TimeSelect
          className={css.timeSelect}
          dataTestId="adjustStartTime"
          label={START_LABEL}
          time={start}
          allowLastSlot={false}
          onChange={(value) => onAdjustedTimeChange(value, end)}
          minuteIncrement={minuteIncrement}
        />
        <TimeSelect
          className={css.timeSelect}
          dataTestId="adjustEndTime"
          label={END_LABEL}
          time={end}
          allowFirstSlot={false}
          onChange={(value) => onAdjustedTimeChange(start, value)}
          minuteIncrement={minuteIncrement}
        />
      </div>

      {timesInvalid && (
        <div className={css.errorMessage}>
          <ErrorIcon className={css.alertIcon} />
          {INVALID_TIME}
        </div>
      )}
      {timesUnchanged && (
        <div data-test-id="timesUnchangedErrorMessage" className={css.errorMessage}>
          <ErrorIcon className={css.alertIcon} />
          {UNCHANGED_TIME}
        </div>
      )}
    </div>
  );
}

AdjustTime.propTypes = {
  start: PropTypes.object.isRequired,
  end: PropTypes.object.isRequired,
  timesInvalid: PropTypes.bool.isRequired,
  timesUnchanged: PropTypes.bool,
  onAdjustedTimeChange: PropTypes.func.isRequired,
  minuteIncrement: PropTypes.number,
};
