import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Caption } from 'components/atoms/typography';
import classNames from 'classnames';

import css from './style.scss';
import { handleEnter } from '../../../utils/accessibility';

class Tabs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: props.defaultActive,
    };

    this.tabs = [];

    this.handleTabClick = this.handleTabClick.bind(this);
  }

  handleTabClick(index) {
    const { onTabChange, onTabClick, disabled } = this.props;

    const { activeTab } = this.state;

    if (disabled) {
      return;
    }

    this.setState({ activeTab: index }, this.updateSliderStyles);

    if (activeTab !== index) {
      onTabChange && onTabChange(index);
    }
    onTabClick && onTabClick(index);
  }

  render() {
    const { tabs, className, labels, disabled } = this.props;

    const { activeTab } = this.state;

    return (
      <div
        className={classNames({
          [css.tabContainer]: true,
          [className]: className,
        })}
        style={disabled ? { pointerEvents: 'none', backgroundColor: 'grey', opacity: 0.2 } : {}}
      >
        {tabs.map((title, index) => {
          let tabContent;
          if (typeof title === 'string') {
            tabContent = (
              <Caption
                className={classNames({
                  [css.tabContent]: true,
                  [css.tabActiveText]: index === activeTab,
                })}
              >
                {title}
              </Caption>
            );
          } else {
            tabContent = (
              <div
                className={classNames({
                  [css.tabActiveText]: index === activeTab,
                })}
              >
                {title}
              </div>
            );
          }

          return (
            <div
              key={index}
              role="button"
              tabIndex={0}
              className={classNames(css.tab, { [css.tabActive]: index === activeTab })}
              onClick={() => this.handleTabClick(index)}
              onKeyDown={handleEnter(() => this.handleTabClick(index))}
              ref={(el) => {
                this.tabs.push(el);
              }}
              data-test-id={index}
            >
              {tabContent}
              {labels && labels[index] && <Caption className={css.label}>{labels[index]}</Caption>}
            </div>
          );
        })}
      </div>
    );
  }
}

Tabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  labels: PropTypes.array,
  onTabChange: PropTypes.func,
  onTabClick: PropTypes.func,
  defaultActive: PropTypes.number,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Tabs;
