import React, { useContext, useMemo } from 'react';
import TextCell from '../TextCell';
import DropdownMenu from '../../Dropdown/DropdownMenu';
import { AppTrackerContext } from '../../viewController';
import { Application, ApplicationGoal } from '../../type';
import { useIsAccessibleStaff, useIsStudent, useRequesterUserId } from '../../helper';
import { GOAL_OPTION_MAP } from '../../constants';
import { optionMapToOptions, getLabelFromOptionMap } from '../../util';
type GoalProps = {
  goal: string | null;
  application: Application;
};

const mapTextColor = (_goal: string | null) => {
  switch (_goal) {
    case 'Hard':
      return '#FF764C';
    case 'Reach':
      return '#6C63FF';
    case 'Target':
      return '#3B86FE';
    case 'Safety':
      return '#12C39A';
    case null:
      return '#A9ACC0';
    default:
      return undefined;
  }
};

const Goal = ({ goal, application }: GoalProps) => {
  const { controller } = useContext(AppTrackerContext);
  const isStudent = useIsStudent();
  const isAccessibleStaff = useIsAccessibleStaff();
  const requesterUserId = useRequesterUserId();
  const onClickItem = async (value: string) => {
    await controller.updateApplication(
      application.id,
      { goal: value as ApplicationGoal },
      { updatedBy: requesterUserId },
    );
  };
  const isApplying = application.group === 'APPLYING';
  const OPTIONS = useMemo(() => optionMapToOptions(GOAL_OPTION_MAP), []);
  const _preOpenDropdown = () => {
    if (!isAccessibleStaff) return false;
    if (isApplying) return controller.checkIfEditable(isStudent, isAccessibleStaff, application);
    return true;
  };
  return (
    <TextCell textColor={mapTextColor(goal)}>
      <DropdownMenu preOpenDropdown={_preOpenDropdown} data={OPTIONS} onClickItem={onClickItem}>
        {getLabelFromOptionMap(GOAL_OPTION_MAP, goal) || 'Select'}
      </DropdownMenu>
    </TextCell>
  );
};

export default Goal;
