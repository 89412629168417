import { useContext } from 'react';
import { StaffDashboardContext } from './viewController';
import { AppYearStudent, Application, ApplicationRegion, ApplicationOverview } from '../type';
import { StaffDashboardRegion, AlertInState, StudentInList, StudentListExtendedData } from './type';
import {
  mapApplicationRegion,
  groupApplications,
  isInvitedToInterview,
  sortApplications,
  isApplicationReceivedResult,
} from '../util';
import { Acl } from '@crimson-education/common-config';
import { useCurrentUserRole } from '../helper';
import { STUDENT_LIST_SERVICE_OPTIONS } from './constants';
import { uniq, flatten, sortBy } from 'lodash';
export const useStaffDashboardContext = () => useContext(StaffDashboardContext);

export const retriveUserInfoFromIds = (students: AppYearStudent[], studentUserIds: string[]): AppYearStudent[] => {
  return uniq(studentUserIds)
    .map((id) => students.find((o) => o.userId === id)!)
    .filter((o) => !!o);
};

export const constructSchoolSelectionStoreAlerts = (
  {
    inExploration,
    shortListInProgress,
    finalListApproved,
    finalListInProgress,
    parentApprovalPending,
  }: Record<
    'inExploration' | 'shortListInProgress' | 'finalListInProgress' | 'parentApprovalPending' | 'finalListApproved',
    string[]
  >,
  region: StaffDashboardRegion,
): AlertInState[] => {
  return [
    {
      userIds: inExploration,
      label: 'Exploration',
      value: 'SSexploration' + region,
      priority: 0,
      color: '#A0D673',
    },
    {
      userIds: shortListInProgress,
      label: 'Short List in Progress',
      value: 'SSshortListInProgress' + region,
      priority: 1,
      color: '#6C63FF',
    },
    {
      userIds: finalListInProgress,
      label: 'Final List in Progress',
      value: 'SSfinalListInProgress' + region,
      priority: 2,
      color: '#3B86FE',
    },
    {
      userIds: parentApprovalPending,
      label: 'Parent Approval Pending',
      value: 'SSparentApprovalPending' + region,
      priority: 3,
      color: '#FF764CCC',
    },
    {
      userIds: finalListApproved,
      label: 'Final List Approved',
      value: 'SSfinalListApproved' + region,
      priority: 4,
      color: '#FFD023',
    },
  ];
};

export const constructMilestoneMeetingData = (
  { meeting1, meeting2, meeting3, meeting4 }: Record<'meeting1' | 'meeting2' | 'meeting3' | 'meeting4', string[]>,
  region: StaffDashboardRegion,
): AlertInState[] => {
  return [
    {
      userIds: meeting1,
      label: 'Meeting 1',
      value: 'meeting1' + region,
      priority: 0,
      color: '#464AC9CC',
    },
    {
      userIds: meeting2,
      label: 'Meeting 2',
      value: 'meeting2' + region,
      priority: 1,
      color: '#3B86FE',
    },
    {
      userIds: meeting3,
      label: 'Meeting 3',
      value: 'meeting3' + region,
      priority: 2,
      color: '#FF764CCC',
    },
    {
      userIds: meeting4,
      label: 'Meeting 4',
      value: 'meeting4' + region,
      priority: 3,
      color: '#FFD023CC',
    },
  ];
};

export const groupByOfficialDeadline = (
  applications: Application[],
  students: AppYearStudent[],
  region: StaffDashboardRegion,
) => {
  const filtered = applications.filter((o) => !!o.deadline && o.deadlineType === 'university_deadline');
  const grouped = groupApplications(filtered, 'Deadline', region, {
    deadlineGroupFormat: 'YYYY-MM-DD',
  });
  return grouped.map((o) => ({
    ...o,
    students: retriveUserInfoFromIds(
      students,
      o.applications.map((app) => app.userId),
    ),
  }));
};

export const useStaffRole = () => {
  const roles = useCurrentUserRole();
  if (!roles) return 'Other';
  const roleIds = roles.map((o) => o.roleId);
  const isStrategist = Acl.isStrategist(roleIds);
  if (isStrategist) return 'Strategist';
  const isSSM = Acl.isCaseManager(roleIds);
  if (isSSM) return 'SSM';
  return 'Other';
};

const getStudentListExtendedData = (
  student: AppYearStudent,
  region: ApplicationRegion,
  applications: Application[],
  overviews: ApplicationOverview[],
): StudentListExtendedData => {
  const apps = applications.filter((o) => mapApplicationRegion(o) === region && o.userId === student.userId);
  const applicationIds = apps.map((o) => o.id);
  const groupedByRound = groupApplications(apps, 'Round', region);
  const appCountByRound = groupedByRound.map((o) => ({
    round: o.label,
    count: o.applications.length,
  }));
  const submittedAppCount = apps.filter((app) => app.isSubmitted || isInvitedToInterview(app)).length;
  const overview = overviews.filter((o) => o.userId === student.userId && o.region === region)[0];
  const upcomingApp = sortApplications(apps, 'Deadline', region)[0];
  const receivedResult = apps.filter(isApplicationReceivedResult);
  return {
    appCountByRound,
    applicationIds,
    totalAppCount: apps.length,
    upcomingAppId: upcomingApp?.id,
    submittedAppCount,
    overviewId: overview?.id,
    region,
    resultReceivedCount: receivedResult.length,
  };
};

const sortExtendedStudents = (data: StudentInList[]): StudentInList[] => {
  return sortBy(data, ['firstName', 'lastName']);
};
export const extendStudentData = (
  students: AppYearStudent[],
  applications: Application[],
  overviews: ApplicationOverview[],
): StudentInList[] => {
  const allStudentIds = students.map((o) => o.userId);
  const userIdsByRegion: Record<ApplicationRegion, string[]> = {
    US: [],
    UK: [],
    EU: [],
    Other: [],
  };
  // get student ids of each region
  applications.forEach((app) => {
    if (!allStudentIds.includes(app.userId)) return;
    userIdsByRegion[mapApplicationRegion(app)].push(app.userId);
  });
  // uniq student ids
  Object.entries(userIdsByRegion).forEach(([key, value]) => {
    userIdsByRegion[key as ApplicationRegion] = uniq(value);
  });
  const studentsWithExtendedData: StudentInList[] = flatten(
    Object.entries(userIdsByRegion).map(([region, userIds]) =>
      userIds.map((userId) => {
        const student = students.find((o) => o.userId === userId)!;
        return {
          ...student,
          ...getStudentListExtendedData(student, region as ApplicationRegion, applications, overviews),
        };
      }),
    ),
  );
  return sortExtendedStudents(studentsWithExtendedData);
};

export const groupStudentDataByService = (
  data: StudentInList[],
  {
    overviews,
  }: { schoolSelectionData: Record<StaffDashboardRegion, AlertInState[]>; overviews: ApplicationOverview[] },
) => {
  const res: Record<string, StudentInList[]> = {};
  Object.values(STUDENT_LIST_SERVICE_OPTIONS).forEach((o) => (res[o.value] = []));
  data.forEach((s) => {
    const overview = overviews.find((o) => o.id === s.overviewId);
    let key: string;
    if (!s.overviewId || !overview) {
      // default service status
      key = 'In Progress';
    } else {
      key = overview.serviceStatus;
    }
    if (!res[key]) res[key] = [];
    res[key].push(s);
  });
  return Object.entries(res)
    .filter(([key, value]) => !!value.length)
    .map(([key, value]) => ({
      label: key,
      students: value,
      ...getStudentListGroupTint(key),
    }));
};

export const groupStudentDataBySchoolSelection = (
  data: StudentInList[],
  {
    schoolSelectionData,
  }: { schoolSelectionData: Record<StaffDashboardRegion, AlertInState[]>; overviews: ApplicationOverview[] },
) => {
  const res: Record<string, StudentInList[]> = {};
  Object.entries(schoolSelectionData).forEach(([region, value]) => {
    const studentsInRegion = data.filter((s) => s.region === region);
    value.forEach((o) => {
      if (!res[o.label]) res[o.label] = [];
      res[o.label].push(...studentsInRegion.filter((s2) => o.userIds.includes(s2.userId)));
    });
  });
  return Object.entries(res)
    .filter(([key, value]) => !!value.length)
    .map(([key, value]) => ({
      label: key,
      students: value,
      ...getStudentListGroupTint(key),
    }));
};

const LABEL_COLORS = {
  grey: {
    color: '#A9ACC0',
    background: 'rgba(169, 172, 192, 0.15)',
  },
  purple: {
    color: '#464AC9',
    background: 'rgba(70, 74, 201, 0.15)',
  },
  blue: {
    color: '#3B86FE',
    background: 'rgba(59, 134, 254, 0.15)',
  },
  green: {
    color: '#12C39A',
    background: 'rgba(18, 195, 154, 0.15)',
  },
  orange: {
    color: '#FF764C',
    background: 'rgba(255, 118, 76, 0.15)',
  },
  yellow: {
    color: '#FDAA02',
    background: 'rgba(253, 170, 2, 0.15)',
  },
};

const GROUP_LABEL_COLOR_MAP: Record<string, { color: string; background: string }> = {
  Exploration: LABEL_COLORS.purple,
  'Short List in Progress': LABEL_COLORS.blue,
  'Final List in Progress': LABEL_COLORS.green,
  'Parent Approval Pending': LABEL_COLORS.orange,
  'Final List Approved': LABEL_COLORS.yellow,
  'In Progress': LABEL_COLORS.grey,
  'All School Submitted': LABEL_COLORS.purple,
  'Pre-Interview Meeting Completed': LABEL_COLORS.blue,
  'Post-Offers Meeting Completed': LABEL_COLORS.orange,
  'Post-All-UCAS-Offers Meeting Completed': LABEL_COLORS.orange,
  'Post-All-Offers Meeting Completed': LABEL_COLORS.purple,
  'Service Completed': LABEL_COLORS.yellow,
};
const getStudentListGroupTint = (label: string) => {
  return GROUP_LABEL_COLOR_MAP[label] || LABEL_COLORS.purple;
};

export const groupStudentList = (
  students: StudentInList[],
  groupBy: 'schoolSelectionData' | 'serviceStatus',
  payload: { schoolSelectionData: Record<StaffDashboardRegion, AlertInState[]>; overviews: ApplicationOverview[] },
): { label: string; students: StudentInList[]; color: string; background: string }[] => {
  switch (groupBy) {
    case 'schoolSelectionData':
      return groupStudentDataBySchoolSelection(students, payload);
    case 'serviceStatus':
      return groupStudentDataByService(students, payload);
    default:
      return [];
  }
};

export const getSessionNoteUrl = (studentUserId: string) => {
  return `/users/${studentUserId}/otherSessions`;
};

export const getUserProfileUrl = (studentUserId: string) => {
  return `/users/${studentUserId}/student-center`;
};
