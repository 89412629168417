import React from 'react';
import { Link } from 'react-router-dom';
import Body from 'components/atoms/typography/Body';
import Avatar from 'components/molecules/Avatar';
import PropTypes from 'prop-types';
import { useFeatureFlag } from 'featureSwitches';

import css from './styles.scss';

export default function UserSection({ user, roleText }) {
  const STUDENT_CENTER_BETA = useFeatureFlag('STUDENT_CENTER_BETA');
  const userLink =
    roleText === 'Student' && user.isBetaUser && STUDENT_CENTER_BETA
      ? `/users/${user.userId}/student-center/personal-info`
      : `/users/${user.userId}/profile`;
  return (
    <Link data-ga-label="Person" to={userLink} className={css.studentContainer}>
      <Avatar
        firstName={user.firstName}
        lastName={user.lastName}
        colourIndex={user.colourIndex}
        image={user.profileImageUrl}
        size="normal"
        showStudentType
        userId={user.userId}
      />
      <div className={css.nameContainer}>
        <Body className={css.nameText}>{`${user.firstName} ${user.lastName}`}</Body>
        <Body className={css.roleText}>{roleText}</Body>
      </div>
    </Link>
  );
}

UserSection.propTypes = {
  user: PropTypes.object,
  roleText: PropTypes.string,
};
