import React from 'react';
import PropTypes from 'prop-types';

const styleString = `
.a{fill:none;}.b,
.d{fill:#73747d;}
.c{fill:#fff;}
.d{stroke:#73747d;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-width:0.5px;}
`;
export default function University({ style }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <defs>
        <style> {styleString}</style>
      </defs>
      <g transform="translate(-756 -728)">
        <g transform="translate(756 728)">
          <path className="a" d="M0,0H24V24H0Z" />
          <path
            className="b"
            d="M15,10V8.7a1.974,1.974,0,0,0-.6-1.4L12.7,5.6a.967.967,0,0,0-1.4,0L9.6,7.2A2.035,2.035,0,0,0,9,8.7V10H5a2.006,2.006,0,0,0-2,2v7a2.006,2.006,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V12a2.006,2.006,0,0,0-2-2ZM7.9,18.7h-2v-2h2Zm0-4h-2v-2h2Zm5.1,4H11v-2h2Zm0-4H11v-2h2Zm5.1,4h-2v-2h2Zm0-4h-2v-2h2Z"
          />
          <circle className="c" cx="1.3" cy="1.3" r="1.3" transform="translate(10.7 7.5)" />
          <path className="d" d="M12,5.3V2.5l3.2.8L12,4.1" />
        </g>
      </g>
    </svg>
  );
}

University.propTypes = {
  style: PropTypes.object,
};
