import { connect } from 'react-redux';

import { getLoginUser } from 'selectors/user';

import Profile from './Profile';

export default connect(
  (state, ownProps) => ({
    user: ownProps.user || getLoginUser(state).toJS(),
  }),
  null,
)(Profile);
