import { roleTypes } from '@crimson-education/common-config';
import aggregateKeys from 'constants/aggregateKeys';

export const RELATION_STUDENTS = 'RELATION_STUDENTS';
export const RELATION_STUDENTS_AND_CONTRACT = 'RELATION_STUDENTS_AND_CONTRACT';
export const RELATION_STUDENTS_AND_CONTRACT_AS_HEAD_TUTOR = 'RELATION_STUDENTS_AND_CONTRACT_AS_HEAD_TUTOR';
export const RELATION_STUDENTS_AND_CONTRACT_AS_TUTOR = 'RELATION_STUDENTS_AND_CONTRACT_AS_TUTOR';
export const EXTERNAL_STUDENTS = 'EXTERNAL_STUDENTS';
export const RELATION_TUTORS = 'RELATION_TUTORS';
export const ALL_TUTORS = 'ALL_TUTORS';
export const ALL_CASE_MANAGERS = 'ALL_CASE_MANAGERS';
export const ALL_USERS_ACTIVE = 'ALL_USERS_ACTIVE';
export const ALL_USERS_DEACTIVATED = 'ALL_USERS_DEACTIVATED';
export const MY_FRIENDS = 'MY_FRIENDS';
export const CGA_STUDENTS = 'CGA_STUDENTS';

const { CASE_MANAGER, STUDENT, EXTERNAL_STUDENT, TUTOR, HEAD_TUTOR, CGA_STUDENT } = roleTypes;

export const UserCountMap = {
  [RELATION_STUDENTS]: {
    key: aggregateKeys.OUR_PEOPLE_RELATION_STUDENTS,
    filters: {
      roles: [STUDENT],
      related: true,
    },
  },
  [RELATION_STUDENTS_AND_CONTRACT]: {
    key: aggregateKeys.OUR_PEOPLE_RELATION_STUDENTS_AND_CONTRACT,
    filters: {
      roles: [STUDENT],
      related: true,
      contractRelated: true,
    },
  },
  [RELATION_STUDENTS_AND_CONTRACT_AS_HEAD_TUTOR]: {
    key: aggregateKeys.OUR_PEOPLE_RELATION_STUDENTS_AND_CONTRACT_AS_HEAD_TUTOR,
    filters: {
      getQueryAs: roleTypes.HEAD_TUTOR,
      roles: [STUDENT],
      related: true,
      contractRelated: true,
    },
  },
  [RELATION_STUDENTS_AND_CONTRACT_AS_TUTOR]: {
    key: aggregateKeys.OUR_PEOPLE_RELATION_STUDENTS_AND_CONTRACT_AS_TUTOR,
    filters: {
      getQueryAs: roleTypes.TUTOR,
      roles: [STUDENT],
      related: true,
      contractRelated: true,
    },
  },
  [EXTERNAL_STUDENTS]: {
    key: aggregateKeys.OUR_PEOPLE_EXTERNAL_STUDENTS,
    filters: {
      roles: [EXTERNAL_STUDENT],
    },
  },
  [RELATION_TUTORS]: {
    key: aggregateKeys.OUR_PEOPLE_RELATION_TUTORS,
    filters: {
      roles: [TUTOR],
      related: true,
    },
  },
  [ALL_TUTORS]: {
    key: aggregateKeys.OUR_PEOPLE_ALL_TUTORS,
    filters: {
      roles: [TUTOR, HEAD_TUTOR],
    },
  },
  [ALL_CASE_MANAGERS]: {
    key: aggregateKeys.OUR_PEOPLE_ALL_CASE_MANAGERS,
    filters: {
      roles: [CASE_MANAGER],
    },
  },
  [ALL_USERS_ACTIVE]: {
    key: aggregateKeys.OUR_PEOPLE_ALL_USERS_ACTIVE,
    filters: {
      active: true,
      includeSelf: true,
    },
  },
  [ALL_USERS_DEACTIVATED]: {
    key: aggregateKeys.OUR_PEOPLE_ALL_USERS_DEACTIVATED,
    filters: {
      active: false,
      includeSelf: true,
    },
  },
  [MY_FRIENDS]: {
    key: aggregateKeys.OUR_PEOPLE_MY_FRIENDS,
    filters: {
      active: false,
      includeSelf: false,
    },
  },
  [CGA_STUDENTS]: {
    key: aggregateKeys.OUR_PEOPLE_CGA_STUDENTS,
    filters: {
      roles: [CGA_STUDENT],
    },
  },
  getKey: (key) => {
    return UserCountMap[key].key;
  },
};
