import React from 'react';
import PropTypes from 'prop-types';
import { Caption } from 'components/atoms/typography';
import Select from 'components/molecules/Select';
import InputWarningMessage from 'components/molecules/InputWarningMessage';
import { getDateOptions } from './utils.js';
import styles from './styles.scss';

/* DateDropdown
 * Renders a dropdown with the past N years, optionally broken down by the
 * given months. E.g. January 2018, July 2018, January 2017, July 2017.
 *
 * Returns a date on change. This will be 1st January YEAR or 1st MONTH YEAR at
 * 00:00:00Z. Use the parts of the date that correspond to what was requested.
 *
 * Months are provided by number (1-12) or using the enum provided. Year is the
 * number of previous years you want. The now prop allows you to mock the
 * current time. Otherwise, it will be obtained from the system.
 */

export const Months = {
  JANUARY: 1,
  FEBRUARY: 2,
  MARCH: 3,
  APRIL: 4,
  MAY: 5,
  JUNE: 6,
  JULY: 7,
  AUGUST: 8,
  SEPTEMBER: 9,
  OCTOBER: 10,
  NOVEMBER: 11,
  DECEMBER: 12,
};

export default function DateDropdown(props) {
  const { isValid, validationMessage, now, years, months, onChange, value, label } = props;

  // Get a list of options based on the provided years and months, e.g.
  // options = [
  //   { label: 'May 2017', value: '2018-05-01T00:00:00.000Z' },
  //   { label: 'November 2017', value: '2018-11-01T00:00:00.000Z' }
  // ];
  const options = getDateOptions(now, years, months);
  const selected = value ? options.find((option) => option.value.getTime() === value.getTime()) : undefined;

  return (
    <div>
      {label && <Caption className={styles.fieldCaption}>{label}</Caption>}
      <Select options={options} value={selected} onChange={onChange} />
      {!isValid && <InputWarningMessage message={validationMessage} />}
    </div>
  );
}

DateDropdown.defaultProps = {
  now: new Date(),
  isValid: true,
};

DateDropdown.propTypes = {
  isValid: PropTypes.bool,
  validationMessage: PropTypes.string,
  years: PropTypes.number.isRequired,
  months: PropTypes.array,
  now: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  value: PropTypes.instanceOf(Date),
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};
