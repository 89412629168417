import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import classnames from 'classnames';
import { DropdownField, CheckboxField } from 'components/generic/Forms';
import Button from 'components/molecules/Button';
import css from 'components/unique/ProfilePage/profileItem.scss';

export default class TimezoneForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      timezone: props.timezone,
      autoTimezone: props.autoTimezone,
      timezoneError: false,
    };

    this.handleSaveClicked = this.handleSaveClicked.bind(this);
    this.handleCancelClicked = this.handleCancelClicked.bind(this);
    this.handleTimezoneSelect = this.handleTimezoneSelect.bind(this);
    this.handleAutoTimezoneChecked = this.handleAutoTimezoneChecked.bind(this);
  }

  handleSaveClicked() {
    const { onSaveDetails } = this.props;
    const { timezone, autoTimezone } = this.state;

    if (!autoTimezone && !timezone) {
      this.setState({
        timezoneError: true,
      });
      return;
    }

    const details = {
      timezone: autoTimezone ? moment.tz.guess() : timezone,
      autoTimezone,
    };

    onSaveDetails(details);
  }

  handleCancelClicked() {
    this.props.onCancel();
  }

  handleTimezoneSelect(value) {
    this.setState({
      timezone: value,
      timezoneError: false,
    });
  }

  handleAutoTimezoneChecked(value) {
    this.setState({
      autoTimezone: value,
      timezoneError: false,
    });
  }

  render() {
    const { timezone, timezones, isLoading } = this.props;

    const { autoTimezone, timezoneError } = this.state;

    return (
      <div className={css.itemBody}>
        <CheckboxField
          id="autoTimezone"
          label="Automatically detect my timezone."
          isChecked={autoTimezone}
          onClick={this.handleAutoTimezoneChecked}
        />
        <DropdownField
          id="timezone"
          label="Timezone"
          className={classnames('formItemLessMargin', css.formRow)}
          placeholder="Select your timezone"
          defaultValue={timezone}
          options={timezones}
          onValueChange={this.handleTimezoneSelect}
          disabled={autoTimezone}
          validationMessage={
            timezoneError ? 'Please select a timezone or check "Automatically detect my timezone" below.' : ''
          }
          error={timezoneError}
        />
        <footer className={css.footer}>
          <Button secondary onClick={this.handleCancelClicked}>
            Cancel
          </Button>
          <Button primary loading={isLoading} onClick={this.handleSaveClicked}>
            Save
          </Button>
        </footer>
      </div>
    );
  }
}

TimezoneForm.displayName = 'TimezoneForm';

TimezoneForm.propTypes = {
  timezone: PropTypes.string,
  autoTimezone: PropTypes.bool,
  isLoading: PropTypes.bool,
  timezones: PropTypes.array.isRequired,
  onSaveDetails: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
};
