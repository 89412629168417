import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as Icons from 'components/atoms/icons';
import css from './style.scss';

export default function IconButton(props) {
  const { className, onClick, icon, dataTestId, iconSize, dataGaLabel } = props;
  const Icon = Icons[icon];

  return (
    <button
      alt={`icon - ${icon}${dataGaLabel ? ` (${dataGaLabel})` : ''}`}
      className={classNames(css.iconButtonWrapper, className)}
      onClick={onClick}
      data-test-id={dataTestId}
    >
      <Icon className={css.iconButton} style={iconSize || { height: '2rem', width: '2rem' }} />
    </button>
  );
}

IconButton.propTypes = {
  icon: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  dataGaLabel: PropTypes.string,
  iconSize: PropTypes.shape({
    height: PropTypes.string,
    width: PropTypes.string,
  }),
};
