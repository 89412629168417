import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classNames from 'classnames';
import onClickOutside from 'react-onclickoutside';
import { Link, withRouter } from 'react-router-dom';
import Immutable from 'immutable';
import InfiniteScroll from 'react-infinite-scroller';
import moment from 'moment';
import { getRedirectUrl } from 'utils/notificationConstructor';

import NotificationItem from '../NotificationItem';
import css from './styles.scss';

class NotificationWrapper extends Component {
  static makeHeading({ renderedItem, heading }) {
    return (
      <div key={renderedItem.key}>
        <div className={css.groupHeading}>{heading}</div>
        {renderedItem}
      </div>
    );
  }

  static groupNotifications(notifications) {
    // Group notifications
    const startOfToday = moment().startOf('day');
    const startOfYesterday = moment(startOfToday).subtract(1, 'day');
    const startOfThisWeek = moment().startOf('week');
    const startOfThisMonth = moment().startOf('month');
    const mutableNotifications = {
      today: [],
      yesterday: [],
      thisWeek: [],
      thisMonth: [],
      older: [],
    };
    notifications.entrySeq().forEach((item) => {
      const notification = item[1];
      const timestamp = notification.get('timestamp');
      if (moment(timestamp).isSameOrAfter(startOfToday)) {
        mutableNotifications.today.push(item);
        return true;
      }
      if (moment(timestamp).isSameOrAfter(startOfYesterday)) {
        mutableNotifications.yesterday.push(item);
        return true;
      }
      if (moment(timestamp).isSameOrAfter(startOfThisWeek)) {
        mutableNotifications.thisWeek.push(item);
        return true;
      }
      if (moment(timestamp).isSameOrAfter(startOfThisMonth)) {
        mutableNotifications.thisMonth.push(item);
        return true;
      }
      mutableNotifications.older.push(item);
      return true;
    });
    return Immutable.fromJS(mutableNotifications);
  }

  static addMonthHeadings({ renderedItem, lastTimestamp, currentTimestamp }) {
    if (!moment(lastTimestamp).isSame(currentTimestamp, 'month')) {
      // Other months
      return NotificationWrapper.makeHeading({ renderedItem, heading: moment(currentTimestamp).format('MMMM YYYY') });
    }
    // No new heading
    return renderedItem;
  }

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      firstLoaded: false,
      savedUnseen: props.unseen || 0,
      firstOpen: false,
    };

    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.renderNotificationItem = this.renderNotificationItem.bind(this);
    this.renderNotifications = this.renderNotifications.bind(this);
    this.renderPageNotifications = this.renderPageNotifications.bind(this);
    this.handleScrollToBottom = this.handleScrollToBottom.bind(this);
    this.onNotificationItemClick = this.onNotificationItemClick.bind(this);
    this.renderNotificationGroup = this.renderNotificationGroup.bind(this);
  }

  componentDidMount() {
    const { notifications } = this.props;
    if (notifications.size === 0) {
      this.handleScrollToBottom(0);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { isNotificationPage, isOpen } = this.props;
    const { savedUnseen, firstOpen } = this.state;
    if (nextProps.unseen !== 0) {
      this.setState({
        savedUnseen: nextProps.unseen || 0,
      });
    } else if (!nextProps.isOpen) {
      this.setState({
        savedUnseen: 0,
      });
    }

    if (!isOpen && nextProps.isOpen) {
      if (!isNotificationPage && (!firstOpen || savedUnseen !== 0)) {
        // load the first page when open the widget the first time or when new notifications come.
        this.handleScrollToBottom(0);
      }
      this.setState({
        firstOpen: true,
      });
    }
  }
  onNotificationItemClick(notificationId, notificationType, redirect) {
    const { setNotificationRead, toggleNotificationWrapper, history, closeDrawer } = this.props;

    toggleNotificationWrapper && toggleNotificationWrapper(false);

    // if already at destination then do hard redirect/reload, otherwise do history redirect
    const destination = redirect || getRedirectUrl(notificationType);
    if (window.location.pathname === destination) {
      window.location.href = destination;
    } else {
      history.push(destination);
    }
    if (closeDrawer) {
      closeDrawer();
    }
    setNotificationRead(notificationId);
  }
  handleClickOutside() {
    const { isOpen, onClickOutside } = this.props;
    if (isOpen && onClickOutside) {
      onClickOutside();
    }
  }

  handleScrollToBottom(page) {
    const { onScrollToBottom, hasMore, isNotificationPage, isOpen } = this.props;
    const { loading } = this.state;

    if ((!page || (isOpen && hasMore)) && !loading) {
      this.setState({
        loading: true,
      });
      onScrollToBottom(page).then(() => {
        if (!isNotificationPage && !page && this.scrollWrapper) {
          this.scrollWrapper.scrollTop = 0;
        }
        this.setState({
          firstLoaded: true,
          loading: false,
        });
      });
    }
  }

  renderNotificationItem(item, lastTimestamp) {
    const notificationKey = item[0];
    const notification = item[1];
    const currentTimestamp = notification.get('timestamp');
    const renderedItem = (
      <NotificationItem
        isNotificationPage={this.props.isNotificationPage}
        key={notificationKey}
        notificationTime={currentTimestamp}
        notificationDesc={notification.get('message')}
        notificationType={notification.get('type')}
        notificationRedirect={notification.get('redirect')}
        notificationRead={!!notification.get('isRead')}
        onNotificationItemClick={() =>
          this.onNotificationItemClick(notificationKey, notification.get('type'), notification.get('redirect'))
        }
      />
    );
    if (lastTimestamp) {
      return {
        renderedItem: NotificationWrapper.addMonthHeadings({ renderedItem, lastTimestamp, currentTimestamp }),
        currentTimestamp,
      };
    }
    return renderedItem;
  }

  renderNotificationGroup(notificationGroup, heading) {
    return (
      <div>
        <div className={css.groupHeading}>{heading}</div>
        {notificationGroup.map((item) => this.renderNotificationItem([item.get(0), item.get(1)]))}
      </div>
    );
  }

  renderNotifications(notifications) {
    const r = notifications.entrySeq().map((item) => this.renderNotificationItem(item));
    return r;
  }

  renderPageNotifications(notifications) {
    let lastTimestamp = moment().add(1, 'day');
    const groupedNotifications = NotificationWrapper.groupNotifications(notifications);
    const r = (
      <div>
        {groupedNotifications.get('today').size > 0 &&
          this.renderNotificationGroup(groupedNotifications.get('today'), 'Today')}
        {groupedNotifications.get('yesterday').size > 0 &&
          this.renderNotificationGroup(groupedNotifications.get('yesterday'), 'Yesterday')}
        {groupedNotifications.get('thisWeek').size > 0 &&
          this.renderNotificationGroup(groupedNotifications.get('thisWeek'), 'This Week')}
        {groupedNotifications.get('thisMonth').size > 0 &&
          this.renderNotificationGroup(groupedNotifications.get('thisMonth'), 'This Month')}
        {groupedNotifications.get('older').map((item) => {
          const { renderedItem, currentTimestamp } = this.renderNotificationItem(
            [item.get(0), item.get(1)],
            lastTimestamp,
          );
          lastTimestamp = currentTimestamp;
          return renderedItem;
        })}
      </div>
    );
    return r;
  }

  render() {
    const { isOpen, className, notifications, isNotificationPage, hasMore, onClickOutside } = this.props;
    const { loading, firstLoaded, savedUnseen, firstOpen } = this.state;
    const loader = (
      <div key="loader" className={css.emptyItem}>
        Loading ...
      </div>
    );

    return (
      (isNotificationPage || firstOpen) && (
        <div
          data-ga-category="Notifications"
          className={classNames(css.notificationWrapper, { [css.wrapperHidden]: !isOpen }, className)}
        >
          {!isNotificationPage && (
            <div className={css.newNotifications}>
              {savedUnseen} NEW NOTIFICATION{savedUnseen !== 1 && 'S'}
            </div>
          )}
          <div
            ref={(el) => {
              this.scrollWrapper = el;
            }}
            className={classNames({ [css.notificationWidget]: !isNotificationPage })}
          >
            <InfiniteScroll
              pageStart={1}
              initialLoad
              loadMore={this.handleScrollToBottom}
              hasMore={hasMore}
              loader={loader}
              useWindow={!!isNotificationPage}
              threshold={20}
            >
              {isNotificationPage
                ? this.renderPageNotifications(notifications)
                : this.renderNotifications(notifications)}
            </InfiniteScroll>
            {!loading && firstLoaded && !notifications.size && (
              <div className={css.emptyItem}>No notifications to show right now.</div>
            )}
          </div>
          {!isNotificationPage && (
            <div className={css.viewAll}>
              <Link data-ga-label="View all" to="/notifications" onClick={onClickOutside}>
                View all
              </Link>
            </div>
          )}
        </div>
      )
    );
  }
}

NotificationWrapper.propTypes = {
  notifications: ImmutablePropTypes.map.isRequired,
  hasMore: PropTypes.bool.isRequired,
  isNotificationPage: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  className: PropTypes.string,
  onClickOutside: PropTypes.func,
  setNotificationRead: PropTypes.func.isRequired,
  unseen: PropTypes.number,
  onScrollToBottom: PropTypes.func.isRequired,
  toggleNotificationWrapper: PropTypes.func,
  history: PropTypes.object.isRequired,
  closeDrawer: PropTypes.func,
};

export default withRouter(onClickOutside(NotificationWrapper));
