import React from 'react';
import Skeleton from 'antd/lib/skeleton';
import { Column } from '../../index.style';

const AlertsPanelLoadingView = () => {
  return (
    <Column gap={'20px'}>
      {new Array(12).fill(null).map((_, idx) => (
        <Skeleton key={'alertpanelloading' + idx} active title={true} paragraph={false} />
      ))}
    </Column>
  );
};

export default AlertsPanelLoadingView;
