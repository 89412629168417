import { createSelector } from 'reselect';
import { roleTypes, studentType } from '@crimson-education/common-config';

const profiles = (state) => state.get('profile');

export const allProfiles = createSelector(profiles, (profiles) => profiles.toJS());

export const getProfile = (userId, role) => {
  return createSelector(profiles, (profiles) => {
    const profile = profiles.get(`${userId}-${role}`);
    return profile ? profile.toJS() : null;
  });
};

export const getKeyContactsInfo = (userId, role) =>
  createSelector(getProfile(userId, role), (profile) => {
    return profile && profile.keyContacts ? profile.keyContacts.sort((a, b) => b.isPrimary - a.isPrimary) : [];
  });

export const getYearOfApplication = (userId, role) =>
  createSelector(getProfile(userId, role), (profile) => {
    return profile ? profile.yearOfApplication : null;
  });

export const getStudentType = (userId) =>
  createSelector(getProfile(userId, roleTypes.STUDENT), (profile) => {
    return profile ? profile.studentType : null;
  });

export const isStudentVIP = (userId) =>
  createSelector(getProfile(userId, roleTypes.STUDENT), (profile) => {
    if (profile && profile.studentType === studentType.VIP) {
      return true;
    }
    return false;
  });

export const getPreferredPronoun = (userId, role) =>
  createSelector(getProfile(userId, role), (profile) => {
    return profile ? profile.pornoun : null;
  });
