import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './styles.scss';
import { asButton } from '../../../utils/accessibility';

export default function Card({ children, className, onClick, selected }) {
  return (
    <div
      className={classNames({ [css.card]: true, [className]: className, [css.selected]: selected })}
      {...asButton(onClick)}
    >
      {children}
    </div>
  );
}

Card.defaultProps = {
  selected: false,
};

Card.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
};
