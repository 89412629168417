import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import TableCell from '@material-ui/core/TableCell';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LoadingIndicator from 'components/molecules/LoadingIndicator';
import Checkbox from '@material-ui/core/Checkbox';

export const PrimaryButton = styled(Button)`
  height: 40px;
  padding: 7px 0px;
  background: #464ac9;
  border-radius: 28px;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  text-transform: none;
  font-family: 'Montserrat';
  &:hover {
    opacity: 0.8;
    background: #464ac9;
  }
  &.Mui-disabled {
    background: #d1d5db;
  }
`;

export const SecondaryButton = styled(Button)`
  height: 40px;
  padding: 7px 0px;
  background: #ffffff;
  border: 2px solid #464ac9;
  border-radius: 30px;
  color: #464ac9;
  font-size: 14px;
  font-weight: 700;
  font-family: 'Montserrat';
  text-transform: none;
`;

export const Row = styled.div<{
  justifycontent?: string;
  alignitems?: string;
  rowcenter?: boolean;
  columncenter?: boolean;
  gap?: string;
}>`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => props.justifycontent ?? (props.columncenter ? 'center' : 'none')};
  align-items: ${(props) => props.alignitems ?? (props.rowcenter ? 'center' : 'none')};
  gap: ${(props) => props.gap ?? 'none'};
`;

export const Column = styled.div<{ rowcenter?: boolean; columncenter?: boolean; gap?: string }>`
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.rowcenter ? 'center' : 'none')};
  align-items: ${(props) => (props.columncenter ? 'center' : 'none')};
  gap: ${(props) => props.gap ?? 'none'};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  z-index: 1;
  background-color: rgb(244, 246, 250);
  scrollbar-width: thin;
  scrollbar-gutter: stable;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    background-color: rgba(0, 0, 0, 0.1);
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 99px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
  * {
    font-family: 'Montserrat';
  }
`;

export const StyledTableCell = styled(TableCell)`
  padding: 0;
`;

export const MoreLessIcon = styled(ExpandMoreIcon)<{ open?: boolean }>`
  transform: rotate(${(props) => (props.open ? '180deg' : '0deg')});
  transition: 0.4s;
`;

export const LoadingView = styled.div`
  display: flex;
  align-items: center;
  jusitfy-content: center;
  flex: 1;
  background: transparent;
`;

export const StyledIndicator = styled(LoadingIndicator)`
  background: transparent;
`;

export const StyledCheckbox = styled(Checkbox)`
  padding: 5px;
  margin-top: -20px;
  &:hover {
    background: none !important;
  }
  svg {
    color: #464ac9;
    font-size: 20px;
  }
`;
