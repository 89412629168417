import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';

import css from '../styles.scss';
import { Typography, Divider } from '@material-ui/core';

import * as assets from '../assets';
import LoadingIndicator from 'components/molecules/LoadingIndicator';

// lazy import here since the report renderer has some fairly memory-hungry dependencies
const DisplayPDF = lazy(() => import('./DisplayPDf'));

const ReportDisplay = (props) => {
  const { reportState } = props;

  return (
    <div className={css.displayColumn}>
      <div>
        <Typography variant="h3">PDF Display</Typography>
      </div>
      <Divider />
      <Suspense
        fallback={
          <div>
            <LoadingIndicator />
          </div>
        }
      >
        {reportState ? (
          <DisplayPDF info={{ type: 'DaVinciReport', data: reportState }} assetPath="static/media/reports" />
        ) : (
          <p>The report will appear here once the information is entered</p>
        )}
      </Suspense>
    </div>
  );
};

ReportDisplay.propTypes = {
  reportState: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    preparedDate: PropTypes.instanceOf(Date).isRequired,
    student: PropTypes.shape({
      publicId: PropTypes.string,
      name: PropTypes.shape({
        firstNameLegal: PropTypes.string.isRequired,
        middleNamesLegal: PropTypes.string,
        lastNameLegal: PropTypes.string.isRequired,
        firstNamePreferred: PropTypes.string,
        middleNamesPreferred: PropTypes.string,
        lastNamePreferred: PropTypes.string,
      }),
    }).isRequired,
    subjects: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        curriculumType: PropTypes.string.isRequired,
        attendanceType: PropTypes.string,
        teachers: PropTypes.arrayOf(PropTypes.string),
        comments: PropTypes.arrayOf(PropTypes.string),
        examinationGrade: PropTypes.string,
        sessionEngagement: PropTypes.string,
        homework: PropTypes.string,
      }),
    ).isRequired,
  }),
};

export default ReportDisplay;
