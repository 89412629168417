import styled from 'styled-components';
import { Row } from '../../../index.style';
import IconButton from '@material-ui/core/IconButton';
import { APPLICATION_DEADLINE_ANIMATION_DURATION } from '../../constants';

export const CardContainer = styled(Row)<{
  $animationName?: string;
}>`
  background: #f4f5ff;
  border-radius: 8px;
  padding: 16px 12px;
  gap: 24px;
  height: 116px;
  width: 100%;
  cursor: pointer;

  animation-duration: ${APPLICATION_DEADLINE_ANIMATION_DURATION}ms;
  animation-name: ${({ $animationName }) => $animationName};
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;

  // click forward
  @keyframes fade-in-right {
    from {
      translate: 70% -25%;
      opacity: 0;
    }
    to {
      translate: 0 0;
      opacity: 1;
    }
  }

  @keyframes fade-out-left {
    from {
      translate: 0 0;
      opacity: 1;
    }
    to {
      translate: -70% -25%;
      opacity: 0;
    }
  }

  // click backward
  @keyframes fade-in-left {
    from {
      translate: -70% -25%;
      opacity: 0;
    }
    to {
      translate: 0 0;
      opacity: 1;
    }
  }

  @keyframes fade-out-right {
    from {
      translate: 0 0;
      opacity: 1;
    }
    to {
      translate: 70% -25%;
      opacity: 0;
    }
  }
`;

export const Deadline = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #1d1e2b;
`;

export const DueDayInfo = styled.div`
  padding: 2px 8px;
  min-width: 70px;
  height: 20px;
  background: #fff2ef;
  border-radius: 4px;
  color: #ff764c;
  text-align: center;
  line-height: 16px;
`;

export const StyledIconButton = styled(IconButton)<{ $disable: boolean }>`
  padding: 5px;
  svg {
    font-size: 25px;
    color: ${({ $disable }) => ($disable ? '#F3F6FA' : '#A9ACC0')};
  }
`;

export const StyledContainer = styled(Row)`
  position: relative;
  min-width: 328px;
  height: 116px;
  width: auto;
  transition: opacity 0.4s;
  &:hover {
    opacity: 0.7;
  }
`;
