import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';
import { gql } from '@apollo/client';
import { createClient } from '../utils';

const { api } = getConfig();

const client = createClient(api.graphQLEndpoint);

/** --- FRAGMENTS --- */
const conversationMessageType = gql`
  fragment conversationMessageType on ConversationMessage {
    id
    senderId
    threadId
    message
    isUpdated
    createdAt
    updatedAt
    index
    type
    metadata
    withdraw
  }
`;

const conversationParticipantType = gql`
  ${conversationMessageType}
  fragment conversationParticipantType on ConversationThreadParticipantType {
    userId
    threadId
    lastConsumptionTimestamp
    lastSeenMessageId
    active
    user {
      userId
      firstName
      lastName
      profileImageUrl
      email
      role
      isBetaUser
    }
    message {
      ...conversationMessageType
    }
  }
`;

const conversationThreadType = gql`
  ${conversationParticipantType}
  fragment conversationThreadType on ConversationThread {
    id
    name
    type
    chatName
    icon
    source
    createdAt
    updatedAt
    createdBy
    lastSeenMessageId
    lastMessageSentAt
    lastMessage
    lastMessageWithdraw
    lastMessageType
    lastMessageIsRead
    participants {
      edges {
        node {
          ...conversationParticipantType
        }
      }
      totalCount
    }
  }
`;

const communityThreadType = gql`
  fragment communityThreadType on CommunityThread {
    id
    name
    type
    chatName
    icon
    source
    createdAt
    updatedAt
    createdBy
    lastSeenMessageId
    lastMessageSentAt
    lastMessage
    lastMessageWithdraw
    lastMessageType
    lastMessageIsRead
  }
`;

/** --- MUTATIONS --- */
const createThread = gql`
  ${conversationThreadType}
  ${conversationMessageType}
  mutation createThread(
    $threadId: ID!
    $name: String
    $userIds: [ID]!
    $createdBy: String
    $senderId: ID!
    $messageId: ID!
    $message: String!
    $messageType: String
    $messageMetadata: String
    $chatName: String
  ) {
    createThread(
      name: $name
      userIds: $userIds
      createdBy: $createdBy
      id: $threadId
      messageId: $messageId
      message: $message
      senderId: $senderId
      messageType: $messageType
      messageMetadata: $messageMetadata
      chatName: $chatName
    ) {
      thread {
        ...conversationThreadType
      }
      message {
        ...conversationMessageType
      }
    }
  }
`;

const createMessage = gql`
  ${conversationMessageType}
  mutation createMessage(
    $threadId: ID!
    $message: String!
    $senderId: ID!
    $messageId: String!
    $messageType: String
    $messageMetadata: String
  ) {
    createMessage(
      threadId: $threadId
      message: $message
      senderId: $senderId
      id: $messageId
      type: $messageType
      metadata: $messageMetadata
    ) {
      ...conversationMessageType
    }
  }
`;

const consumeMessages = gql`
  ${conversationParticipantType}
  mutation consumeMessages($threadId: ID!) {
    consumeMessages(threadId: $threadId) {
      ...conversationParticipantType
    }
  }
`;

const queryMessages = gql`
  ${conversationMessageType}
  query messages($threadId: String!, $first: Int, $after: String) {
    thread(threadId: $threadId) {
      id
      messages(first: $first, after: $after) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        totalCount
        edges {
          node {
            ...conversationMessageType
          }
          cursor
        }
      }
    }
  }
`;

const queryThreadWithMessages = gql`
  ${conversationThreadType}
  ${conversationMessageType}
  query messagesWithThread($threadId: String!, $first: Int, $after: String) {
    thread(threadId: $threadId) {
      ...conversationThreadType
      messages(first: $first, after: $after) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        totalCount
        edges {
          node {
            ...conversationMessageType
          }
          cursor
        }
      }
    }
  }
`;

const queryThreads = gql`
  ${conversationThreadType}
  ${conversationMessageType}
  query queryThreads(
    $userId: String!
    $first: Int
    $after: String
    $messageCount: Int
    $threadIds: [ID]
    $source: String
  ) {
    threads(userId: $userId, first: $first, after: $after, threadIds: $threadIds, source: $source) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      onlineUsers
      edges {
        node {
          ...conversationThreadType
          messages(first: $messageCount) {
            pageInfo {
              hasNextPage
              hasPreviousPage
              startCursor
              endCursor
            }
            totalCount
            edges {
              node {
                ...conversationMessageType
              }
              cursor
            }
          }
        }
        cursor
      }
    }
  }
`;

const userStateChange = gql`
  mutation userStateChange($userId: String!, $status: Boolean!) {
    userStateChange(userId: $userId, status: $status)
  }
`;

const updateChatName = gql`
  mutation updateChatName($threadId: ID!, $chatName: String!) {
    updateChatName(threadId: $threadId, chatName: $chatName)
  }
`;

const withdrawMessage = gql`
  ${conversationMessageType}
  mutation withdrawMessage($messageId: ID!) {
    withdrawMessage(messageId: $messageId) {
      ...conversationMessageType
    }
  }
`;

const createFlagReport = gql`
  mutation createFlagReport($flaggedPersonUserId: ID!, $flagType: String!, $flagReason: String!, $messageId: String!) {
    createFlagReport(
      flaggedPersonUserId: $flaggedPersonUserId
      flagType: $flagType
      flagReason: $flagReason
      messageId: $messageId
    )
  }
`;

const addMorePeople = gql`
  ${conversationParticipantType}
  mutation addMorePeople($threadId: ID!, $userIds: [ID]!) {
    addMorePeople(threadId: $threadId, userIds: $userIds) {
      ...conversationParticipantType
    }
  }
`;

const removePeopleFromGroup = gql`
  ${conversationParticipantType}
  mutation removePeopleFromGroup($threadId: ID!, $userId: ID!) {
    removePeopleFromGroup(threadId: $threadId, userId: $userId) {
      ...conversationParticipantType
    }
  }
`;

const ackMessage = gql`
  mutation ackMessage($id: ID!, $from: String!, $to: String!, $span: Int!) {
    ackMessage(id: $id, from: $from, to: $to, span: $span)
  }
`;

const getOSSSecurityToken = gql`
  query getOSSSecurityToken {
    stsInfo: getOSSSecurityToken {
      accessKeyId
      accessKeySecret
      stsToken
      bucket
      region
    }
  }
`;

const getPresignedDownloadUrl = gql`
  query getPresignedDownloadUrl($fileId: String!) {
    getPresignedDownloadUrl(fileId: $fileId)
  }
`;

const queryCommunityThreads = gql`
  ${communityThreadType}
  query queryCommunityThreads($userId: String!, $name: String, $first: Int, $after: String) {
    communityThreads(userId: $userId, name: $name, first: $first, after: $after) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edges {
        node {
          ...communityThreadType
        }
        cursor
      }
    }
  }
`;

export default {
  createThread: async ({
    threadId,
    name,
    userIds,
    createdBy,
    messageId,
    senderId,
    message,
    messageType,
    messageMetadata,
    chatName,
  }) => {
    const result = await client.query(createThread, {
      threadId,
      name,
      userIds,
      createdBy,
      messageId,
      senderId,
      message,
      messageType,
      messageMetadata,
      chatName,
    });

    return result.createThread;
  },
  createMessage: async ({ messageId, threadId, message, senderId, messageType, messageMetadata }) => {
    const result = await client.query(createMessage, {
      messageId,
      threadId,
      message,
      senderId,
      messageType,
      messageMetadata,
    });
    return result.createMessage;
  },
  consumeMessages: async (threadId) => {
    const { consumeMessages: data } = await client.query(consumeMessages, {
      threadId,
    });
    return data;
  },
  getThread: async (threadId, first = 20) => {
    const { thread } = await client.query(queryThreadWithMessages, { threadId, first });
    return thread;
  },
  getMessages: async (threadId, first = 20, after) => {
    const { thread } = await client.query(queryMessages, { threadId, first, after });
    return thread;
  },
  getThreads: async (userId = '', first = 20, after, messageCount = 20, threadIds, source) => {
    const { threads } = await client.query(queryThreads, { userId, first, after, messageCount, threadIds, source });
    return threads;
  },
  userStateChange: async (userId, status) => {
    await client.query(userStateChange, {
      userId,
      status,
    });
  },
  editGroupName: async (threadId, chatName) => {
    await client.query(updateChatName, { threadId, chatName });
  },
  withdrawMessage: async (messageId) => {
    const result = await client.query(withdrawMessage, { messageId });
    return result.withdrawMessage;
  },
  createFlagReport: async (flaggedPersonUserId, flagType, flagReason, messageId) => {
    const result = await client.query(createFlagReport, { flaggedPersonUserId, flagType, flagReason, messageId });
    return result;
  },
  addMorePeople: async (userIds, threadId) => {
    const result = await client.query(addMorePeople, { userIds, threadId });
    return result.addMorePeople;
  },
  removePeopleFromGroup: async (userId, threadId) => {
    const result = await client.query(removePeopleFromGroup, { userId, threadId });
    return result.removePeopleFromGroup;
  },
  ackMessage: async (id, from, to, span) => {
    await client.query(ackMessage, { id, from, to, span });
  },
  fetchOSSSecurityToken: () => {
    return client.query(getOSSSecurityToken);
  },
  getPresignedDownloadUrl: (fileId) => {
    return client.query(getPresignedDownloadUrl, { fileId }).then((res) => {
      if (!res.getPresignedDownloadUrl) {
        return Promise.reject(new Error('failed to fetch file url'));
      }
      return res.getPresignedDownloadUrl;
    });
  },
  getCommunityThreads: async (userId = '', name, first = 20, after) => {
    const { communityThreads } = await client.query(queryCommunityThreads, { userId, name, first, after });
    return communityThreads;
  },
};
