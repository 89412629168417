import React from 'react';
import Immutable from 'immutable';
import Filter from 'components/unique/MyPeople/Filters/Filter';

export const filterNames = {
  Name: 'Name',
  Email: 'Email',
  UserType: 'UserType',
  SubjectName: 'SubjectName',
  Location: 'Location',
  University: 'University',
  ApplicationYear: 'ApplicationYear',
  TutorCapacity: 'TutorCapacity',
  Relationship: 'Relationship',
};

export default {
  [filterNames.Name]: (_, handleInputChange) => (
    <Filter
      key={filterNames.Name}
      label="Search by name"
      placeholder="Enter a name to search"
      inputType="text"
      filterName="nameFilter"
      defaultTextValue={_.prevFilters && _.prevFilters.name}
      onChange={(event) => handleInputChange('name', event.target)}
    />
  ),
  [filterNames.Email]: (_, handleInputChange) => (
    <Filter
      key={filterNames.Email}
      label="Email"
      placeholder="Search an email address"
      inputType="text"
      filterName="emailFilter"
      defaultTextValue={_.prevFilters && _.prevFilters.email}
      onChange={(event) => handleInputChange('email', event.target)}
    />
  ),
  [filterNames.UserType]: (args, handleInputChange) => (
    <Filter
      key={filterNames.UserType}
      label="User Type"
      placeholder="Select a user type"
      inputType="select"
      filterName="roleFilter"
      roles={args.prevFilters && args.prevFilters.roles}
      selectOptions={Immutable.fromJS(args.userRoles)}
      onChange={(event) => handleInputChange('roles', event.target)}
    />
  ),
  [filterNames.TutorCapacity]: (args, handleInputChange) => (
    <Filter
      key={filterNames.TutorCapacity}
      identifier="multiSelectTutorCapacity"
      label="Tutor Capacity"
      placeholder="Select a tutor capacity"
      inputType="multiSelect"
      filterName="tutorCapacityFilter"
      selectOptions={Immutable.fromJS(args.tutorCapacityCriteria)}
      defaultValue={Immutable.fromJS(args.tutorCapacityDefault)}
      onChange={(selectedObject, onMount) => {
        const selectedValue = selectedObject.toJS().map((x) => x.value);
        if (!onMount) {
          handleInputChange('tutorCapacity', selectedValue);
        }
      }}
    />
  ),
  [filterNames.SubjectName]: (_, handleInputChange) => (
    <Filter
      key={filterNames.SubjectName}
      label="Search by subject"
      placeholder="Enter a subject to search"
      inputType="text"
      filterName="subjectFilter"
      defaultTextValue={_.prevFilters && _.prevFilters.subjectName}
      onChange={(event) => handleInputChange('subjectName', event.target)}
    />
  ),
  [filterNames.Location]: (_, handleInputChange) => (
    <Filter
      key={filterNames.Location}
      label="Search by location"
      placeholder="Enter a location to search"
      inputType="text"
      filterName="locationFilter"
      defaultTextValue={_.prevFilters && _.prevFilters.location}
      onChange={(event) => handleInputChange('location', event.target)}
    />
  ),
  [filterNames.University]: (_, handleInputChange) => (
    <Filter
      key={filterNames.University}
      label="Search by university"
      placeholder="Enter a university to search"
      inputType="text"
      filterName="universityFilter"
      defaultTextValue={_.prevFilters && _.prevFilters.university}
      onChange={(event) => handleInputChange('university', event.target)}
    />
  ),
  [filterNames.ApplicationYear]: (_, handleInputChange) => (
    <Filter
      key={filterNames.ApplicationYear}
      label="Search by application year"
      placeholder="Enter an application year"
      inputType="text"
      filterName="applicationYearFilter"
      defaultTextValue={_.prevFilters && _.prevFilters.applicationYear}
      onChange={(event) => handleInputChange('applicationYear', event.target)}
    />
  ),
  [filterNames.Relationship]: (args, handleInputChange) => (
    <Filter
      key={filterNames.Relationship}
      label="Show"
      placeholder="Relationship with tutor"
      inputType="radioButton"
      filterName="relationshipFilter"
      related={args.prevFilters && args.prevFilters.relatedTutors}
      selectOptions={Immutable.fromJS(args.tutorRelationship)}
      onChange={(event) => handleInputChange('tutorRelationship', event.target)}
    />
  ),
};
