/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import * as React from 'react';
import { Tooltip } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';
import { useStylesBootstrap } from '../style';
import styled, { keyframes } from 'styled-components';

const flip_diagonal = keyframes`
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
`;

const CloseFullIcon = styled.div`
  :hover {
    fill: var(--color-indigo);
    animation: ${flip_diagonal} 0.6s ease-in-out both;
  }
`;

export const OpenInFull = ({
  fill = '#73747D',
  onClick,
}: {
  fill?: string;
  onClick: React.MouseEventHandler<HTMLDivElement>;
}): JSX.Element => {
  const classes = useStylesBootstrap();
  return (
    <Tooltip title="Full Screen" interactive TransitionComponent={Zoom} placement="top" arrow={true} classes={classes}>
      <div onClick={onClick} style={{ transform: 'translateY(15%)' }}>
        <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3 21v-7.5h1.5v4.95L18.45 4.5H13.5V3H21v7.5h-1.5V5.55L5.55 19.5h4.95V21H3Z" fill={fill} />
        </svg>
      </div>
    </Tooltip>
  );
};

export const CloseFull = ({
  fill = '#464AC9',
  onClick,
}: {
  fill?: string;
  onClick: React.MouseEventHandler<HTMLDivElement>;
}): JSX.Element => {
  const classes = useStylesBootstrap();
  return (
    <Tooltip
      title="Switch to floating mode"
      interactive
      TransitionComponent={Zoom}
      placement="top"
      arrow={true}
      classes={classes}
    >
      <CloseFullIcon
        style={{
          width: '28px',
          height: '28px',
          background: '#464AC9',
          boxShadow: '0px 2px 5px rgba(70, 74, 201, 0.25)',
          borderRadius: '4px',
          border: '1px solid #464AC9',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onClick={onClick}
      >
        <svg
          width={16}
          height={16}
          fill={fill}
          xmlns="http://www.w3.org/2000/svg"
          style={{
            background: '#464AC9',
          }}
        >
          <path
            d="M1.375 15.5.5 14.625l4.458-4.458H2.5v-1.25h4.583V13.5h-1.25v-2.458L1.375 15.5Zm7.563-8.438V2.48h1.25v2.458L14.625.5l.875.875-4.438 4.438h2.459v1.25H8.937Z"
            fill="#fff"
          />
        </svg>
      </CloseFullIcon>
    </Tooltip>
  );
};
