import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classnames from 'classnames';
import moment from 'moment';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import 'styles/tooltip.scss';
import InitialsAvatar from 'components/generic/InitialsAvatar';
import PopoverMenu from 'components/generic/PopoverMenu';
import { CommentField } from 'components/generic/Forms';
import { getAvatarColour } from 'utils/calendarUtils';
import css from './styles.scss';
import CommentFile from './CommentFile';
import CommentReply from './CommentReply';
import { handleEnter } from '../../../utils/accessibility';

export const File = CommentFile;
export const Reply = CommentReply;
export default class Comment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      messageInEditing: '',
      showMenu: false,
      editMode: false,
    };

    this.handleMessageChanged = this.handleMessageChanged.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);

    this.onMenuButtonClick = this.onMenuButtonClick.bind(this);
    this.onHideOptions = this.onHideOptions.bind(this);
    this.onDeleteOptionClick = this.onDeleteOptionClick.bind(this);
    this.toggleEditMode = this.toggleEditMode.bind(this);
    this.editComment = this.editComment.bind(this);
    this.resetState = this.resetState.bind(this);
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.editMode === 'boolean') {
      return { editMode: nextProps.editMode };
    }
    return null;
  }

  onMenuButtonClick() {
    const { showMenu } = this.state;

    this.setState({
      showMenu: !showMenu,
    });
  }

  onDeleteOptionClick(comment) {
    const { onCommentDelete } = this.props;
    this.onHideOptions();
    onCommentDelete(comment);
  }

  onHideOptions() {
    this.setState({
      showMenu: false,
    });
  }

  onKeyDown(event) {
    if (event.keyCode === 27) {
      // esc key will cancel the edit mode
      this.toggleEditMode(false);
    } else if (event.keyCode === 13 && !event.shiftKey) {
      // enter key will submit form. Shirt enter will insert new line. Only submit if has text.
      event.preventDefault();
      this.editComment();
    }
  }

  toggleEditMode(mode) {
    const { comment, onEditModeChange } = this.props;
    const { editMode, messageInEditing } = this.state;

    const newMode = typeof mode === 'boolean' ? mode : !editMode;

    if (newMode && !messageInEditing) {
      this.setState({
        messageInEditing: comment.get('message'),
      });
    }

    this.setState({
      editMode: newMode,
    });

    this.onHideOptions();

    onEditModeChange && onEditModeChange(newMode, this);
  }

  handleMessageChanged(value) {
    this.setState({
      messageInEditing: value,
    });
  }

  editComment() {
    const { comment, onCommentEdit } = this.props;
    const { messageInEditing, isPosting } = this.state;

    if (isPosting) {
      return;
    }

    const newMessage = messageInEditing.trim();

    if (!newMessage) {
      this.setState({
        messageInEditing: '',
      });
      return;
    }

    if (newMessage === comment.get('message')) {
      this.resetState();
      return;
    }

    this.setState({
      isPosting: true,
    });

    const promise = onCommentEdit(messageInEditing);
    if (promise && promise.then) {
      promise
        .then(() => {
          this.resetState();
        })
        .catch(() => {
          this.setState({
            isPosting: false,
          });
        });
    } else {
      this.resetState();
    }
  }

  resetState() {
    this.setState({
      messageInEditing: '',
      editMode: false,
      isPosting: false,
    });
  }

  render() {
    const { commentId, comment, author, className, canDelete, onFileDelete, onFileDownload, canEdit } = this.props;

    const { showMenu, editMode, messageInEditing } = this.state;

    const message = comment.get('message');
    const file = comment.get('file');

    const commentForCallback = comment.set('id', commentId);

    return (
      <div className={classnames(css.comment, className)}>
        <div className={css.avatar}>
          <InitialsAvatar
            className={css.personInitials}
            colorIndex={getAvatarColour(author.get('userId'))}
            initials={author.get('initials') || ''}
          />
        </div>
        <div className={css.commentSection}>
          <div className={css.commentMetaData}>
            <div>
              <span>{author.get('name')} </span>
              <span className={css.timestamp}>
                <span>{moment(comment.get('timestamp')).fromNow()}</span>
                {comment.get('editedTimestamp') && (
                  <span>
                    <span> • </span>
                    <Tooltip
                      overlayClassName={css.tooltip}
                      placement="top"
                      align={{ offset: [0, 5] }}
                      overlay={<span>{moment(comment.get('editedTimestamp')).fromNow()}</span>}
                    >
                      <span>edited</span>
                    </Tooltip>
                  </span>
                )}
              </span>
            </div>
            {(canEdit || canDelete) && (
              <div
                role="button"
                tabIndex={0}
                className={classnames(css.editButton, { [css.editIsClicked]: showMenu }, 'ignore-react-onclickoutside')}
                onClick={this.onMenuButtonClick}
                onKeyDown={handleEnter(this.onMenuButtonClick)}
              >
                <PopoverMenu className={css.menu} show={showMenu} onClickOutside={this.onHideOptions}>
                  {canEdit && (
                    <div
                      role="button"
                      tabIndex={0}
                      className={css.edit}
                      onClick={() => {
                        this.toggleEditMode();
                      }}
                      onKeyDown={handleEnter(() => this.toggleEditMode())}
                    >
                      Edit
                    </div>
                  )}
                  <div
                    role="button"
                    tabIndex={0}
                    className={css.delete}
                    onClick={() => {
                      this.onDeleteOptionClick(commentForCallback);
                    }}
                    onKeyDown={handleEnter(() => this.onDeleteOptionClick(commentForCallback))}
                  >
                    Delete
                  </div>
                </PopoverMenu>
                <i className={classnames('zmdi zmdi-edit', css.editIcon)} />
              </div>
            )}
          </div>
          {editMode ? (
            <CommentField
              className={css.commentEdit}
              formInputClassName={css.formInput}
              placeholder="Type a comment"
              defaultValue={messageInEditing}
              value={messageInEditing}
              onValueChange={this.handleMessageChanged}
              onKeyDown={this.onKeyDown}
              autoSize
            />
          ) : (
            <div className={css.commentText}>
              {message && message.split('\n').map((paragraph, i) => paragraph && <p key={i}>{paragraph}</p>)}
            </div>
          )}
          {file && (
            <File
              fileName={file.get('name')}
              fileSize={file.get('size')}
              onDeleteClick={
                canDelete && onFileDownload
                  ? () => {
                      onFileDelete(file, commentForCallback);
                    }
                  : null
              }
              onDownloadClick={
                onFileDownload
                  ? () => {
                      onFileDownload(file, commentForCallback);
                    }
                  : null
              }
            />
          )}
        </div>
      </div>
    );
  }
}

Comment.propTypes = {
  commentId: PropTypes.string,
  comment: ImmutablePropTypes.map.isRequired,
  author: ImmutablePropTypes.map.isRequired,
  className: PropTypes.string,
  editMode: PropTypes.string,
  onEditModeChange: PropTypes.func,
  canEdit: PropTypes.bool,
  canDelete: PropTypes.bool,
  onCommentEdit: PropTypes.func,
  onCommentDelete: PropTypes.func,
  onFileDelete: PropTypes.func,
  onFileDownload: PropTypes.func,
};

Comment.displayName = 'Comment';
