import styled from 'styled-components';
import { Row, Column } from '../../index.style';
import CancelIcon from '@material-ui/icons/Cancel';

export const Container = styled(Column)`
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 130px;
  height: 149px;

  background: #f3f6fa;
  border: 1px dashed #9ca3af;
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
  flex-shrink: 0;
`;

export const FileContainer = styled(Column)`
  width: 130px;
  height: 149px;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  position: relative;
  flex-shrink: 0;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }
`;

export const Text = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  white-space: pre-wrap;
  color: #1f2937;
`;

export const StyledImg = styled.img`
  width: 21px;
  height: 26px;
`;

export const FilesContainer = styled(Row)`
  overflow-x: auto;
  gap: 18px;
  padding: 8px 50px 8px 8px;
  margin-top: -8px;
  margin-left: -8px;
  width: 350px;
  box-sizing: border-box;
`;

export const StyledCancelIcon = styled(CancelIcon)`
  cursor: pointer;
  font-size: 20px;
  position: absolute;
  display: flex;
  right: -8px;
  top: -8px;
`;

export const DialogueText = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin: 40px 0;
  color: #1d1e2b;
`;

export const OtherFileContainer = styled(Column)`
  gap: 10px;
  box-sizing: border-box;
  padding: 10px;
  flex: 1;
`;

export const OtherFileText = styled.div`
  font-family: 'Montserrat';

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  width: 100%;
  line-height: 16px;
  max-height: calc(16px * 5);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
  color: #73747d;
`;
