import {
  constructFeatureSwitches,
  constructLDFeatureSwitch,
  transformAndFilterFeatureSwitches,
} from '@crimson-education/feature-switches-web';
import { getEnvironmentConfig } from '@crimson-education/common-config';

import productionFeatures from './features.production.json';
import stagingFeatures from './features.staging.json';
import devFeatures from './features.development.json';

const { isProduction, isStaging, isDev, launchDarkly } = getEnvironmentConfig();

/**
 * @type {import('@crimson-education/feature-switches-web/lib/types')
 * .EnvironmentBasedFeatureDefaults<{
 * RESOURCE_TAB: boolean;
 * NEW_NOTIFIACTION_SWITCH_ENABLE: boolean;
 * RESOURCE_CONNECT_LOCAL: boolean;
 * RESOURCE_OPEN_TO_ALL: boolean;
 * }>} FeatureFlags
 */
const defaults = {
  production: productionFeatures,
  staging: stagingFeatures,
  dev: devFeatures,
};

const { featureSwitches, getFeatureSwitchesAsString, FeatureToggle } = constructFeatureSwitches<typeof devFeatures>(
  defaults,
  {
    isProduction: () => isProduction,
    isStaging: () => isStaging,
    isDev: () => isDev,
  },
);
export { featureSwitches, getFeatureSwitchesAsString, FeatureToggle };

const {
  useFeatureFlag,
  enhancer,
  useFeatureSwitches,
  useIdentifyUser,
  FeatureSwitchContext,
} = constructLDFeatureSwitch(launchDarkly.clientSideId, transformAndFilterFeatureSwitches(featureSwitches), {
  hotReload: isDev,
  waitForReady: isDev,
  showLog: isDev,
  proxy: {
    enable: true,
  },
});

export { useFeatureFlag, enhancer as FeatureSwitchEnhancer, useFeatureSwitches, useIdentifyUser, FeatureSwitchContext };
