import borders from 'components/atoms/borders.scss';
import colours from 'components/atoms/colours.scss';

export const defaultSelectStyles = {
  control: (_, state) => {
    let borderStyle = borders.defaultInputBorderStyle;
    if (state.isFocused) {
      borderStyle = borders.activeInputBorderStyle;
    } else if (!state.selectProps.isValid) {
      borderStyle = borders.warningInputBorderStyle;
    }
    return {
      border: borderStyle,
      transition: 'border ease-in-out 100ms',
      borderRadius: state.selectProps.menuIsOpen ? '0.3rem 0.3rem 0 0' : '0.3rem',
      display: 'flex',
      minHeight: '4rem',
    };
  },
  indicatorSeparator: () => ({
    display: 'none',
  }),
  option: (base, state) => ({
    ...base,
    background: state.isFocused ? colours.background : colours.contrast,
    cursor: 'pointer',
    '&:hover': {
      background: colours.background,
    },
  }),
  menu: (base) => ({
    ...base,
    // border: borders.activeInputBorderStyle,
    // borderTop: 'none',
    // borderRadius: '0 0 0.3rem 0.3rem',
    // boxShadow: 'none',
    marginTop: '0',
    overflow: 'hidden',
    borderWidth: '0px 1px 1px 1px',
    borderStyle: 'solid',
    borderColor: '#E3E7ED',
    boxShadow: '0px 5px 15px rgba(61, 64, 144, 0.2)',
    borderRadius: '0px 0px 8px 8px',
  }),
  singleValue: (base, state) => {
    return {
      ...base,
      color: state.isDisabled ? colours.hint : colours.primary,
      fontSize: '1.2rem',
    };
  },
  input: (base) => ({
    ...base,
    color: colours.primary,
    fontSize: '1.2rem',
  }),
  valueContainer: (base, state) => ({
    ...base,
    cursor: state.selectProps.isSearchable ? 'text' : 'pointer',
    overflow: 'hidden',
  }),
  placeholder: (base) => ({
    ...base,
    color: colours.hint,
  }),
};

export const menuOpenStyles = Object.assign({}, defaultSelectStyles, {
  menu: (base) => ({
    ...base,
    border: 'none',
    borderRadius: '0 0 0.3rem 0.3rem',
    boxShadow: 'none',
    marginTop: '10',
    overflow: 'hidden',
  }),
});

export const menuOpenStylesMobile = Object.assign({}, defaultSelectStyles, menuOpenStyles, {
  menuList: (base) => ({
    ...base,
    maxHeight: 'calc(100vh - 18rem)',
  }),
});
