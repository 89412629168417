import React, { useContext } from 'react';
import { StyledAppBar, StyledTab, StyledTabs } from './index.style';
import { AppTrackerContext } from '../viewController';
import SelectAppCycle from './SelectAppCycle';

export type TabBarProps = {
  tabs: { value: string; label: React.ReactNode }[];
};

const TabBar = ({ tabs }: TabBarProps) => {
  const { state, controller } = useContext(AppTrackerContext);
  return (
    <StyledAppBar position={'sticky'}>
      <StyledTabs
        TabIndicatorProps={{
          style: { backgroundColor: '#464AC9', height: 4, overflow: 'visible' },
        }}
        indicatorColor={'secondary'}
        value={state.tabValue}
        onChange={controller.onTabChange}
      >
        {tabs.map(({ value, label }) => (
          <StyledTab key={value} value={value} label={label} $active={state.tabValue === value} />
        ))}
        <SelectAppCycle />
      </StyledTabs>
    </StyledAppBar>
  );
};

export default TabBar;
