import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'auto-bind';
import moment from 'moment';
import { SubHeader, Body } from 'components/atoms/typography';
import Modal from 'components/molecules/Modal';
import CountdownSimple from 'components/molecules/CountdownSimple';

import { Time } from 'components/atoms/illustrations';

import css from './styles.scss';

const SESSION_CHECK_INTERVAL = 60000;

export default class SessionPromptModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sessionHasStarted: false,
    };

    autoBind(this);

    // check for session starting soon every 1 minute
    const { checkSessionAboutToStart } = this.props;
    setInterval(() => {
      checkSessionAboutToStart();
    }, SESSION_CHECK_INTERVAL);
  }

  componentDidUpdate(prevProps) {
    // Handles session coming up
    if (this.props.session && prevProps.session) {
      if (this.props.session.id !== prevProps.session.id) {
        this.props.setPromptModalOpen(true);
      }
    }
  }

  setSessionStart() {
    this.setState({ sessionHasStarted: true });
  }

  closeModal() {
    this.props.setPromptModalOpen(false);
  }

  goToSession() {
    const { session, history } = this.props;
    this.closeModal();
    if (session) {
      history.push(`/session/${session.id}`);
    } else {
      history.push('/calendar');
    }
  }

  render() {
    const { session, isPromptModalOpen, location } = this.props;
    const isSessionRoute = location.pathname.includes('session');
    if (!session || !session.zoomMeetingUrl || isSessionRoute) {
      return null;
    }

    const hasStarted = this.state.sessionHasStarted || moment().isBetween(moment(session.start), moment(session.end));

    return (
      <Modal
        isOpen={(isPromptModalOpen && session !== null) || false}
        submitText="Go to session"
        onSubmit={this.goToSession}
        onSubmitDataTestId="SessionPromptModalSubmit"
        onSecondarySubmit={this.closeModal}
        secondarySubmitText="Close"
        renderHeaderImage={() => <Time />}
      >
        <div>
          {hasStarted ? (
            <SubHeader>You have a session in progress!</SubHeader>
          ) : (
            <SubHeader>
              You have a session starting in{' '}
              <CountdownSimple end={moment(session.start)} bold onFinish={this.setSessionStart} />.
            </SubHeader>
          )}
        </div>
        <div className={css.sessionMessage}>
          <Body>
            You can find the link to your video call on your session page. While you’re there, remember to add anything
            you want to cover to the agenda.
          </Body>
        </div>
      </Modal>
    );
  }
}

SessionPromptModal.propTypes = {
  session: PropTypes.shape(),
  history: PropTypes.object,
  location: PropTypes.object,
  setPromptModalOpen: PropTypes.func,
  isPromptModalOpen: PropTypes.bool,
  checkSessionAboutToStart: PropTypes.func,
};
