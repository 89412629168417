import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PermissionAction, PermissionResourceType } from '@crimson-education/common-config/lib/authorization';
import { roleTypes } from '@crimson-education/common-config';
import Immutable from 'immutable';
import { useHistory } from 'react-router';
import { useErrorHandler } from 'react-error-boundary';

import classNames from 'classnames';
import { RadioGroup, Radio } from 'react-radio-group';
import usePermissionCheck from 'hooks/usePermissionCheck';
import authorizationAPI from 'graphql/api/authorization';
import { isEmail, isNotEmpty, stringLengthRule, validateProperties, validateRoleAssociations } from 'utils/validation';

import { selectUserProfile } from 'selectors/meta';
import { onboardUser } from 'ducks/meta';

import Header from 'components/molecules/Header';
import InputField from 'components/generic/Forms/InputField';
import { MultiSelectDropdown } from 'components/generic/Forms';
import { Caption } from 'components/atoms/typography';
import MultiSelect from 'components/molecules/MultiSelect';
import CommentField from 'components/generic/Forms/CommentField';
import CheckboxField from 'components/generic/Forms/CheckboxField';
import { Container as InfoTextContainer, Info as InfoTextInfo } from 'components/generic/InfoText';
import Button from 'components/molecules/Button';
import LoadingIndicator from 'components/molecules/LoadingIndicator';
import Unauthorised from 'components/pages/Unauthorised';

import css from './styles.scss';

export default function OnboardUser() {
  const handleError = useErrorHandler();
  const history = useHistory();
  const dispatch = useDispatch();
  const loginUser = useSelector(selectUserProfile);
  const [stateFirstName, setStateFirstName] = useState('');
  const [stateLastName, setStateLastName] = useState('');
  const [stateEmail, setStateEmail] = useState('');
  const [stateNotes, setStateNotes] = useState('');
  const [stateUserRoleIds, setStateUserRoleIds] = useState([]);
  const [statePrimaryRole, setStatePrimaryRole] = useState(null);
  const [stateIsTestUser, setStateIsTestUser] = useState(false);
  const [stateUserTags, setStateUserTags] = useState([]);
  const [stateIsAllTags, setStateIsAllTags] = useState(false);
  const [stateIsStudent, setStateIsStudent] = useState(false);
  const [selectableRoles, setSelectableRoles] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false);

  const myTags = loginUser.get('myTags');
  const isMultiTenant = loginUser.get('isMultiTenant') || false;
  const enableAllTags = loginUser.get('isAllTags') || false;

  const canCreateUser = usePermissionCheck(
    {
      action: PermissionAction.Create,
      resourceType: PermissionResourceType.User,
    },
    [],
  );

  const hasCreatePermission = !canCreateUser.loading && canCreateUser.isPermitted;

  useEffect(() => {
    if (hasCreatePermission) {
      authorizationAPI.actionableRoles(PermissionAction.Associate).then((data) => {
        setSelectableRoles(Immutable.fromJS(data.actionableRoles));
      }, handleError);
    }
  }, [handleError, hasCreatePermission]);

  const onRolesChanged = useCallback(
    (roles) => {
      const newRoleIds = roles.map((r) => r.get('id'));
      if (!statePrimaryRole || !newRoleIds.includes(statePrimaryRole)) {
        setStatePrimaryRole(newRoleIds[0]);
      }
      setStateUserRoleIds(newRoleIds);
      const isStudent = newRoleIds.includes(roleTypes.STUDENT);
      if (isStudent !== setStateIsStudent) {
        setStateIsStudent(isStudent);
      }
    },
    [statePrimaryRole],
  );

  const validate = useCallback(() => {
    if (!hasAttemptedSubmit) {
      setHasAttemptedSubmit(true);
    }

    const validationErrors = {};
    validateProperties({ stateFirstName, stateLastName }, [isNotEmpty, stringLengthRule()]).forEach((k) => {
      validationErrors[k] = true;
    });

    validateProperties({ stateEmail }, [isNotEmpty, isEmail]).forEach((k) => {
      validationErrors[k] = true;
    });

    Object.assign(validationErrors, validateRoleAssociations(stateUserRoleIds));

    if (isMultiTenant && !stateIsAllTags && stateUserTags.length === 0) {
      validationErrors.userTags = true;
    }

    setValidationErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  }, [
    hasAttemptedSubmit,
    isMultiTenant,
    stateEmail,
    stateFirstName,
    stateIsAllTags,
    stateLastName,
    stateUserRoleIds,
    stateUserTags.length,
  ]);

  const clear = useCallback(async () => {
    setStateFirstName('');
    setStateLastName('');
    setStateEmail('');
    setStateNotes('');
    setStateUserRoleIds([]);

    setIsSubmitting(false);
  }, []);

  const submit = useCallback(async () => {
    setIsSubmitting(true);

    await dispatch(
      onboardUser(
        {
          firstName: stateFirstName,
          lastName: stateLastName,
          email: stateEmail,
          notes: stateNotes,
          roleIds: stateUserRoleIds,
          primaryRole: statePrimaryRole,
          isTest: stateIsTestUser,
          userTags: stateUserTags.map((tag) => tag.value),
          isAllTags: stateIsAllTags,
        },
        history,
      ),
    );

    setIsSubmitting(false);
  }, [
    dispatch,
    history,
    stateEmail,
    stateFirstName,
    stateIsAllTags,
    stateIsTestUser,
    stateLastName,
    stateNotes,
    statePrimaryRole,
    stateUserRoleIds,
    stateUserTags,
  ]);

  const setWithValidation = (set) => {
    return (value) => {
      set(value);
      if (hasAttemptedSubmit) {
        validate();
      }
    };
  };

  if (canCreateUser.loading || selectableRoles === null) {
    return <LoadingIndicator />;
  }

  if (!hasCreatePermission) {
    return <Unauthorised />;
  }

  const selectedRoles = stateUserRoleIds.map((roleId) => selectableRoles.find((r) => roleId === r.get('id')));

  const tagOptions = myTags.toJS().map((tag) => ({
    value: tag.id,
    label: tag.tagName,
  }));

  const info = {
    backText: 'Our People',
    backURL: '/our-people',
    title: 'Add a new user',
    subTitle: 'Add a new user',
    text: 'A profile will be created once they verify their email.',
  };

  return (
    <div data-ga-category="Onboarding">
      <Header title={info.title} isBackButtonVisible backButtonText="Our People" backButtonLink="/our-people" />
      <div className={css.container}>
        <InfoTextContainer>
          <InfoTextInfo text={info.text} />
        </InfoTextContainer>

        <div className={css.form}>
          <MultiSelectDropdown
            id="userRoles"
            dataTestId="onboardingUserRolesDropdown"
            options={selectableRoles}
            optionLabelSelector="name"
            placeholder="Select role of user"
            label="User role"
            onValueChange={setWithValidation(onRolesChanged)}
            multiple
            defaultValue={selectedRoles}
            error={validationErrors.roleError}
            validationMessage={validationErrors.roleMessage}
          />
          <div className={classNames({ [css.hide]: stateUserRoleIds.length < 2 })}>
            <label htmlFor="primaryRole" className={css.label}>
              Primary role
            </label>
            <RadioGroup name="primaryRole" selectedValue={statePrimaryRole} onChange={setStatePrimaryRole}>
              {stateUserRoleIds.map((roleId) => {
                const roleInfo = selectableRoles.find((r) => r.get('id') === roleId);

                return (
                  <label key={roleId} className={css.option}>
                    <Radio value={roleId} />
                    {roleInfo.get('name')}
                  </label>
                );
              })}
            </RadioGroup>
          </div>
          <InputField
            id="firstName"
            dataTestId="onboardingUserFirstnameInput"
            placeholder="First name"
            className={classNames(css.half, css.marginRight)}
            label="First name"
            onValueChange={setWithValidation(setStateFirstName)}
            value={stateFirstName}
            error={validationErrors.firstName}
            validationMessage="Please enter a first name"
          />
          <InputField
            id="lastName"
            dataTestId="onboardingUserLastnameInput"
            placeholder="Last name"
            className={classNames(css.half)}
            label="Last name"
            onValueChange={setWithValidation(setStateLastName)}
            value={stateLastName}
            error={validationErrors.lastName}
            validationMessage="Please enter a last name"
          />
          <InputField
            id="email"
            dataTestId="onboardingUserEmailInput"
            placeholder="Enter email"
            label="Email"
            onValueChange={setWithValidation(setStateEmail)}
            value={stateEmail}
            error={validationErrors.email}
            validationMessage="Please enter a valid email address"
          />
          {isMultiTenant && (
            <>
              <div className={classNames(css.warning, css.formItemContainer)} style={{ marginBottom: '1.6rem' }}>
                <Caption className={classNames(css.fieldCaption, css.formLabel)} style={{ marginBottom: '0.5rem' }}>
                  Tags
                </Caption>
                <MultiSelect
                  id="userTags"
                  name="tags-selector"
                  placeholder="Select tags for user"
                  value={stateUserTags}
                  onChange={setWithValidation(setStateUserTags)}
                  dataTestId="tagsSelector"
                  isValid={!validationErrors.userTags}
                  validationMessage="At least one tag required"
                  options={tagOptions}
                  cacheOptions
                  defaultOptions
                  isDisabled={stateIsAllTags}
                />
              </div>
              {enableAllTags && (
                <CheckboxField
                  id="isAllTags"
                  dataTestId="isAllTags"
                  label="Add user to all tags"
                  onClick={setWithValidation(setStateIsAllTags)}
                  isChecked={stateIsAllTags}
                  disabled={stateIsStudent}
                  dataGaLabel="isAllTags"
                />
              )}
            </>
          )}
          <CommentField
            id="notes"
            dataTestId="onboardingUserNotes"
            label="Notes"
            placeholder="Enter notes"
            onValueChange={setStateNotes}
            value={stateNotes}
          />
          <CheckboxField
            id="isTestUser"
            dataTestId="isTestUser"
            label="This is a test user"
            onClick={setStateIsTestUser}
            isChecked={stateIsTestUser}
            dataGaLabel="IsTestUser"
          />
          <div className={css.footer}>
            <Button secondary dataTestId="onboardingUserCancelButton" onClick={clear}>
              Clear
            </Button>
            <Button
              dataTestId="onboardingUserSubmitButton"
              loading={isSubmitting}
              primary
              onClick={() => validate() && submit()}
            >
              <div>Send invitation</div>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
