import React, { useContext } from 'react';
import { AddUniversityButton } from './index.style';
import { Row } from '../index.style';
import GroupByButton from './GroupByButton';
import SelectRegion from './SelectRegion';
import { AppTrackerContext } from '../viewController';
import { useSelector } from 'react-redux';
import { getYearOfApplication } from 'selectors/profile';
import { getViewableUser } from 'selectors/meta';
import { roleTypes } from '@crimson-education/common-config';
const ToolBar = () => {
  const { controller, state } = useContext(AppTrackerContext);
  const userId = useSelector(getViewableUser);
  const yearOfApplication = useSelector(getYearOfApplication(userId, roleTypes.STUDENT));
  const onClickAddUniversity = () => {
    if (!yearOfApplication) {
      return controller.openSelectAppCycleHint();
    }
    controller.openAddUniversityModal();
  };
  const GROUP_MENU_ITEMS = [
    {
      label: 'Round',
      value: 'Round',
    },
    {
      label: 'Goal',
      value: 'Goal',
    },
    {
      label: 'Deadline',
      value: 'Deadline',
    },
  ];
  return (
    <Row justifycontent={'space-between'} rowcenter>
      <SelectRegion region={state.listRegion} selectRegion={controller.selectListRegion} />
      <Row alignitems={'center'} gap={'12px'}>
        <AddUniversityButton onClick={onClickAddUniversity}>{'Add a University'}</AddUniversityButton>
        <GroupByButton
          groupBy={state.listGroupBy}
          onClickGroupByMenuItem={controller.onClickListGroupByMenuItem as (v: string) => void}
          menuData={GROUP_MENU_ITEMS}
        />
      </Row>
    </Row>
  );
};

export default ToolBar;
