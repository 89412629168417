import { Component } from 'react';
import { logout } from 'utils/auth';
import { unregisterServiceWorker } from 'serviceworker';

export default class Logout extends Component {
  componentDidMount() {
    unregisterServiceWorker();
    logout();
  }

  render() {
    return null;
  }
}
