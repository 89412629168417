import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import fontFamily from './fontFamily.scss';
import font from './font.scss';
import fontColour from './fontColour.scss';

export default function Tiny(props) {
  const { children, style, className, capitals, bold } = props;

  return (
    <div
      className={classNames(
        { [fontFamily.regular]: !bold },
        { [fontFamily.bold]: bold },
        font.tiny,
        fontColour.secondary,
        { [font.capitals]: capitals },
        className,
      )}
      style={style}
    >
      {children}
    </div>
  );
}

Tiny.defaultProps = {
  className: '',
  style: {},
};

Tiny.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  capitals: PropTypes.bool,
  bold: PropTypes.bool,
};
