import React from 'react';
import { Row } from '../index.style';
import { Tag } from '../components';
import { ApplicationRegion } from '../type';
import { UNIVERSITY_REGIONS } from '../constants';
type SelectRegionProps = {
  region: ApplicationRegion;
  selectRegion: (region: ApplicationRegion) => void;
};
const SelectRegion = ({ region, selectRegion }: SelectRegionProps) => {
  return (
    <Row gap={'12px'}>
      {UNIVERSITY_REGIONS.map(({ label, value }) => {
        return (
          <Tag key={value} $selected={value === region} onClick={() => selectRegion(value as ApplicationRegion)}>
            {label}
          </Tag>
        );
      })}
    </Row>
  );
};

export default SelectRegion;
