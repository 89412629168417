import React from 'react';
import PropTypes from 'prop-types';
import { GradeResultValueType, gradeValidations } from '@crimson-education/common-config';
import GradeInputTypes from 'constants/gradeInputTypes';
import GradeDateTypes from 'constants/gradeDateTypes';
import InputText from 'components/molecules/InputText';
import Datepicker from 'components/molecules/Datepicker';
import DateDropdown from 'components/molecules/DateDropdown';
import RadioGroup from 'components/molecules/RadioGroup';
import RadioButton from 'components/molecules/RadioButton';
import Select from 'components/molecules/Select';
import { Caption } from 'components/atoms/typography';
import GradeResultText from 'constants/gradeResultText';
import styles from './styles.scss';

const EXAM_DATE = 'Exam Date';
const EXAM_SESSION = 'Exam Session';
const EXAM_YEAR = 'Exam Year';

function getResult(gradeData, template) {
  // Result could potentially be undefined
  // If a new result type is added, existing results might not have the new type, resulting in undefined.
  return gradeData.results.find((result) => result.type === template)?.value;
}

/* GradeForm
 * Renders a form for entering details about a grade.
 */

export default function GradesForm(props) {
  const {
    onChange,
    gradeData,
    getGradeTableConfigForSubject,
    curriculumTemplates,
    resultTypesTemplates,
    eagerValidation,
  } = props;

  // Check for no selected subject. Don't render.
  const selectedSubject = gradeData.subjectId;
  if (!selectedSubject) return null;

  // Get the template for the curriculum of the selected grade's subject.
  const selectedCurriculum = getGradeTableConfigForSubject(selectedSubject, curriculumTemplates);
  const curriculumTemplate = curriculumTemplates[selectedCurriculum.id];

  // Missing template, we aren't configured to show this curriculum.
  if (!curriculumTemplate) return null;

  // Construct each field for that curriculum template.
  // To support grades for subjects that aren't part of a curriculum we treat
  // curriculumTemplates without overall results as though they have base results.
  const relevantTemplates =
    selectedCurriculum.id === gradeData.subjectId && curriculumTemplate.overallResultTypes.length > 0
      ? curriculumTemplate.overallResultTypes
      : curriculumTemplate.baseResultTypes;
  const resultTemplates = relevantTemplates.map((template) => {
    const resultTemplate = resultTypesTemplates[template];
    const gradeResultValidation = gradeValidations.gradeResultValidations[template];
    const value = getResult(gradeData, template) || '';
    const context = { data: gradeData?.results };
    // Validation message can be a string or a function that takes in a context object.
    const validationMessage =
      typeof gradeResultValidation.validationMessage === 'function'
        ? gradeResultValidation.validationMessage(context)
        : gradeResultValidation.validationMessage;
    const placeholder =
      typeof resultTemplate.placeholder === 'function'
        ? resultTemplate.placeholder(context)
        : resultTemplate.placeholder;
    if (resultTemplate.inputType === GradeInputTypes.TEXT) {
      const waitForInput = !value && !eagerValidation;
      const processedValue = resultTemplate.processor(value);
      const isValid = gradeResultValidation.validator(processedValue, context) || waitForInput;
      return (
        <InputText
          key={template}
          label={resultTemplate.displayName}
          placeholder={placeholder}
          className={styles.inputField}
          value={value}
          isValid={isValid}
          validationMessage={validationMessage}
          onChange={(event) => {
            onChange(template, event.target.value);
          }}
          onBlur={(event) => {
            onChange(template, resultTemplate.processor(event.target.value));
          }}
        />
      );
    } else if (resultTemplate.inputType === GradeInputTypes.RADIO) {
      const options = Object.values(GradeResultValueType[template]);

      const radioButtons = options.map((valueType) => {
        return (
          <RadioButton
            key={valueType}
            option={{ text: GradeResultText[valueType], value: valueType }}
            isChecked={valueType === value}
          />
        );
      });

      return (
        <div className={styles.inputField} key={template}>
          <Caption className={styles.fieldCaption}>{resultTemplate.displayName}</Caption>
          <RadioGroup
            name={resultTemplate.displayName}
            onChange={(value) => {
              onChange(template, value);
            }}
            selectedValue={value}
          >
            {radioButtons}
          </RadioGroup>
        </div>
      );
    } else if (resultTemplate.inputType === GradeInputTypes.SELECT) {
      const options = Object.values(GradeResultValueType[template]).map((value) => ({
        value,
        label: GradeResultText[value],
      }));
      return (
        <div className={styles.inputField} key={template}>
          <Caption className={styles.fieldCaption}>{resultTemplate.displayName}</Caption>
          <Select
            value={{ label: GradeResultText[value], value }}
            isValid={isValid}
            isSearchable={false}
            options={options}
            validationMessage={validationMessage}
            onChange={(data) => {
              onChange(template, data.value);
            }}
          />
        </div>
      );
    }

    // Otherwise, unknown input type in grades configuration.
    return null;
  });

  // Construct the date picker for the curriculum template.
  let datePicker;
  const date = gradeData.date && new Date(gradeData.date);
  const waitForInput = !date && !eagerValidation;
  const isValid = !!date || waitForInput;

  if (curriculumTemplate.dateType === GradeDateTypes.DATE) {
    datePicker = (
      <div className={styles.inputField}>
        <Datepicker
          isValid={isValid}
          validationMessage="Session date is required"
          allowEmpty
          date={date}
          label={EXAM_DATE}
          className={styles.inputField}
          onChange={(value) => {
            onChange('date', value.toString());
          }}
        />
      </div>
    );
  } else if (curriculumTemplate.dateType === GradeDateTypes.SESSION) {
    datePicker = (
      <div className={styles.inputField}>
        <DateDropdown
          isValid={isValid}
          validationMessage="Session date is required"
          value={date}
          label={EXAM_SESSION}
          years={3}
          months={curriculumTemplate.sessionMonths}
          onChange={(option) => {
            onChange('date', option.value.toString());
          }}
        />
      </div>
    );
  } else if (curriculumTemplate.dateType === GradeDateTypes.YEAR) {
    datePicker = (
      <div className={styles.inputField}>
        <DateDropdown
          isValid={isValid}
          validationMessage="Session date is required"
          value={date}
          label={EXAM_YEAR}
          years={3}
          onChange={(option) => {
            onChange('date', option.value.toString());
          }}
        />
      </div>
    );
  }

  // Return the completed grade form.
  return (
    <div>
      {resultTemplates}
      {datePicker}
    </div>
  );
}

GradesForm.defaultProps = {
  eagerValidation: false,
};

GradesForm.propTypes = {
  eagerValidation: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  gradeData: PropTypes.object.isRequired,
  curriculumTemplates: PropTypes.object.isRequired,
  resultTypesTemplates: PropTypes.object.isRequired,
  getGradeTableConfigForSubject: PropTypes.func.isRequired,
};
