import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import QuillEditor from 'components/molecules/QuillEditor';
import notesService from 'graphql/api/sessionNotes';
import { clearNotesLocalStorage } from 'utils/utils';
import {
  getSessionItem,
  setSessionItem,
  getFloatingSessionContext,
  IdEuqal,
} from 'components/pages/FloatingSession/index.util';
export default function Notes(props) {
  const {
    eventId,
    canEdit,
    setDraft,
    refreshNotes,
    recoverNotes,
    setEditedNote,
    setNoteVersion,
    noteVersion,
    toggleFloating,
  } = props;
  const [notesHtml, setNotesHtml] = useState('');
  const [currentVersion, setCurrentVersion] = useState(0);
  const { sessionId } = getFloatingSessionContext();
  const [editorVisible, setEditorVisible] = useState(IdEuqal(sessionId, eventId));
  useEffect(() => {
    const { sessionId } = getFloatingSessionContext();
    setEditorVisible(!IdEuqal(sessionId, eventId));
  }, [eventId, toggleFloating]);
  const thisOnChange = useCallback(
    (notes) => {
      const { version } = JSON.parse(localStorage.getItem(`event-notes-${eventId}`) || '{}');
      setDraft();
      setNotesHtml(notes.html);
      setEditedNote(notes);
      setNoteVersion(currentVersion);
      localStorage.setItem(
        `event-notes-${eventId}`,
        JSON.stringify({ notes, version: version || 0, isDraft: true, updatedAt: new Date() }),
      );
    },
    [setDraft, eventId, currentVersion, setEditedNote, setNoteVersion],
  );

  async function fetchNotes(eventId) {
    const localNotes = getSessionItem({
      sessionId: eventId,
      type: 'notes',
    });
    if (!localNotes?.notes?.reFetch && localNotes?.notes?.reFetch !== undefined) {
      setNotesHtml(localNotes?.notes?.html);
      setSessionItem({
        sessionId: eventId,
        type: 'notes',
        item: {
          ...localNotes,
        },
      });
    } else {
      const remoteNote = await notesService.getNotesByEvent(eventId.toString());
      const sessionNote = remoteNote && remoteNote.sessionNote;
      if (sessionNote) {
        const { html, text, version } = sessionNote;
        const notes = { html, text };
        setNotesHtml(html);
        setCurrentVersion(version || 0);
        try {
          localStorage.setItem(
            `event-notes-${eventId}`,
            JSON.stringify({ notes, version: version || 0, isDraft: false, updatedAt: new Date() }),
          );
        } catch (err) {
          // If the localStorage is full
          if (err.code === '22' || err.code === '1024') {
            clearNotesLocalStorage();
          }
        }
      } else {
        setNotesHtml('');
        setCurrentVersion(0);
        localStorage.setItem(
          `event-notes-${eventId}`,
          JSON.stringify({ notes: {}, version: 0, isDraft: false, updatedAt: new Date() }),
        );
      }
    }
  }
  useEffect(() => {
    fetchNotes(eventId);
  }, [refreshNotes, eventId]);
  useEffect(() => {
    if (refreshNotes) {
      recoverNotes();
    }
  }, [refreshNotes, recoverNotes]);
  useEffect(() => {
    setCurrentVersion(noteVersion);
  }, [noteVersion]);

  return editorVisible ? (
    <QuillEditor html={canEdit ? notesHtml : ''} onChange={thisOnChange} id="notes" disabled={!canEdit} />
  ) : (
    ''
  );
}

Notes.propTypes = {
  eventId: PropTypes.number.isRequired,
  canEdit: PropTypes.bool,
  setDraft: PropTypes.func,
  refreshNotes: PropTypes.bool,
  recoverNotes: PropTypes.func,
  toggleFloating: PropTypes.bool,
  setEditedNote: PropTypes.func,
  setNoteVersion: PropTypes.func,
  noteVersion: PropTypes.number,
};

Notes.defaultProps = {
  canEdit: true,
};
