/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import PropTypes from 'prop-types';
import Robot from '../Icons/Robot';
import BrokenSatellite from '../Icons/BrokenSatellite';
import Reload from '../Icons/Reload';
import Button from '../../molecules/Button';
import css from './styles.scss';

/**
 * Standard Help Url for the ErrorView to use.
 */
export const HelpUrl = 'https://crimsonapp.zendesk.com';
export const RaiseTicketUrl = 'https://crimsonapp.zendesk.com/hc/requests/new';

/**
 * Standard Headers for the ErrorView to use.
 */
export const Headers = {
  SystemicError: 'Oops! An error has occurred.',
  IntermittentError: 'Oh no! Something has gone wrong.',
  AccessExpired: 'Your access has expired',
};

/**
 * Standard Messages for the ErrorView to use.
 */
export const Messages = {
  SystemError: 'Seems like we have hit a technical snag.',
  UnauthorizedApp: 'Seems like you do not have permission to access this app.',
  UnauthorizedPage: 'Seems like you do not have permission to access this page.',
  LoginFailed: "Sorry, we couldn't log you in.",
  IncompleteError: 'Please take a screenshot of this page and raise a ticket with the screenshot attached.',
  AccessExpired:
    'If you were trying to set your password for the first time or you were trying to reset your password, this link has expired as this was not completed within 5 days.',
};

export const Presets = {
  SystemError: 'SystemError',
  UIError: 'UIError',
  UnauthorizedApp: 'UnauthorizedApp',
  UnauthorizedPage: 'UnauthorizedPage',
  AuthorizationFailed: 'AuthorizationFailed',
  AuthenticationError: 'AuthenticationError',
  IncompleteRecords: 'IncompleteRecords',
  AccessExpired: 'AccessExpired',
};

/**
 * Renders a JSX Element for more details.
 */
export const DetailRenderers = {
  TryAgainLogin: () => (
    <>
      Try refreshing the page or hit the button below to log in again.
      <br />
      If the issue persists please{' '}
      <a href={HelpUrl} target="_blank">
        let us know
      </a>
      .
    </>
  ),
  TryAgainReload: () => (
    <>
      You can try again by hitting the button below to reload the app, or refresh the page in your browser.
      <br />
      If the issue persists please{' '}
      <a href={HelpUrl} target="_blank">
        let us know
      </a>
      .
    </>
  ),
  UnauthorizedApp: () => (
    <>
      Please try again with a valid username and password to access this app or{' '}
      <a href={HelpUrl} target="_blank">
        contact us
      </a>{' '}
      for any help.
    </>
  ),
  UnauthorizedPage: () => (
    <>
      Please try again with a user that has permission to access this page or contact us if you think you should have
      access.
    </>
  ),
  LoginFailed: () => (
    <>
      This error might occur if you take too long to log in.
      <br />
      Do{' '}
      <a href={HelpUrl} target="_blank">
        get in touch
      </a>{' '}
      for any assistance.
    </>
  ),
  LoginFailedBookmark: () => (
    <>
      This error might occur if you bookmark the login page.
      <br />
      Do{' '}
      <a href={HelpUrl} target="_blank">
        get in touch
      </a>{' '}
      for any assistance.
    </>
  ),
  UseForgotPassword: () => <>Please use the &quot;forgot your password&quot; option on the login page again.</>,
};

/**
 * Standard Actions the ErrorView can take.
 */
export const ErrorActions = {
  Logout: {
    content: 'Logout',
    action: () => {
      window.location.pathname = '/logout';
    },
  },
  LogoutTryAgain: {
    content: 'Logout & Try again',
    action: () => {
      window.location.pathname = '/logout';
    },
  },
  ContactUs: {
    content: 'Contact Us',
    action: () => {
      window.open(HelpUrl);
    },
  },
  RaiseTicket: {
    content: 'Raise a Ticket',
    action: () => {
      window.open(RaiseTicketUrl);
    },
  },
  Reload: {
    content: 'Reload',
    action: () => {
      window.location.reload();
    },
  },
};

/**
 * Standard Error Icons.
 */
export const IconRenderers = {
  Error: () => <BrokenSatellite />,
  Unauthorized: () => <Robot />,
  Reload: () => <Reload />,
};

/**
 * An Error UI Presenter Component.
 * @param {Object} props
 */
export default function ErrorView(props) {
  const {
    preset,
    detailsType,
    technicalDetails,
    showIcon = true,
    iconType,
    actionButtonType,
    requestId,
    style,
  } = props;
  let { header, message, details, technicalDetailsHeader = 'Technical Details', actionButton, icon } = props;

  // Load Details Type if specified.
  if (detailsType) {
    const detailRenderer = DetailRenderers[detailsType];
    if (detailRenderer) {
      details = details || detailRenderer();
    }
  }

  // Load Icon Type if specified.
  if (iconType) {
    const iconRenderer = IconRenderers[iconType];
    if (iconRenderer) {
      icon = icon || iconRenderer();
    }
  }

  // Load Action Button if specified.
  if (actionButtonType) {
    actionButton = actionButton || ErrorActions[actionButtonType];
  }

  switch (preset) {
    case Presets.SystemError:
    default:
      icon = icon || IconRenderers.Error();
      header = header || Headers.SystemicError;
      message = message || Messages.SystemError;
      details = details || DetailRenderers.TryAgainLogin();
      actionButton = actionButton || ErrorActions.LogoutTryAgain;
      break;

    case Presets.UIError:
      icon = icon || IconRenderers.Error();
      header = header || Headers.IntermittentError;
      message = message || Messages.SystemError;
      details = details || DetailRenderers.TryAgainReload();
      actionButton = actionButton || ErrorActions.Reload;
      break;

    case Presets.UnauthorizedApp:
      icon = icon || IconRenderers.Unauthorized();
      header = header || Headers.IntermittentError;
      message = message || Messages.UnauthorizedApp;
      details = details || DetailRenderers.UnauthorizedApp();
      actionButton = actionButton || ErrorActions.LogoutTryAgain;
      break;

    case Presets.UnauthorizedPage:
      icon = icon || IconRenderers.Unauthorized();
      header = header || Headers.IntermittentError;
      message = message || Messages.UnauthorizedPage;
      details = details || DetailRenderers.UnauthorizedPage();
      actionButton = actionButton || ErrorActions.ContactUs;
      break;

    case Presets.AuthorizationFailed:
      icon = icon || IconRenderers.Reload();
      header = header || Headers.IntermittentError;
      message = message || Messages.LoginFailed;
      details = details || DetailRenderers.LoginFailed();
      actionButton = actionButton || ErrorActions.LogoutTryAgain;
      break;

    case Presets.AuthenticationError:
      icon = icon || IconRenderers.Reload();
      header = header || Headers.SystemicError;
      message = message || Messages.LoginFailed;
      technicalDetailsHeader = 'Error from Auth0';
      details = details || DetailRenderers.LoginFailedBookmark();
      break;

    case Presets.IncompleteRecords:
      icon = icon || IconRenderers.Error();
      header = header || Headers.IntermittentError;
      message = message || Messages.IncompleteError;
      actionButton = actionButton || ErrorActions.RaiseTicket;
      break;

    case Presets.AccessExpired:
      icon = icon || IconRenderers.Unauthorized();
      header = header || Headers.AccessExpired;
      message = message || Messages.AccessExpired;
      details = details || DetailRenderers.UseForgotPassword();
      actionButton = actionButton || ErrorActions.Logout;
      break;
  }

  return (
    <div className={`ErrorView ${css.errored}`} style={style}>
      {icon && showIcon && <div className={css.iconContainer}>{icon}</div>}
      <h1 className={css.heading}>{header}</h1>
      {message && <h2 className={css.message}>{message}</h2>}
      {details && <p className={css.details}>{details}</p>}
      {actionButton && (
        <div className={css.buttonContainer}>
          <Button onClick={actionButton.action}>{actionButton.content}</Button>
        </div>
      )}
      {requestId && <p className={css.requestId}>Request Id: {requestId}</p>}
      {technicalDetails && (
        <div className={css.technicalDetails}>
          <p>{technicalDetailsHeader}</p>
          <pre>{technicalDetails}</pre>
        </div>
      )}
    </div>
  );
}

ErrorView.propTypes = {
  style: PropTypes.object,
  preset: PropTypes.string,
  header: PropTypes.node,
  message: PropTypes.node,
  detailsType: PropTypes.string,
  details: PropTypes.node,
  technicalDetails: PropTypes.string,
  actionButton: PropTypes.shape({
    content: PropTypes.node,
    action: PropTypes.func,
  }),
  actionButtonType: PropTypes.string,
  technicalDetailsHeader: PropTypes.node,
  requestId: PropTypes.string,
  showIcon: PropTypes.bool,
  icon: PropTypes.node,
  iconType: PropTypes.string,
};
