import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import componentKeys from 'constants/componentKeys';
import { getMetaItem } from 'selectors/meta';
import { getLoginUser } from 'selectors/user';
import { getRoadmapIdByLoggedInUser } from 'selectors/roadmap';
import { withAppContext } from 'components/enhancers/AppContext';
import { getAllExamsForLoggedInUser } from 'selectors/examPrep';
import { getNewNotificationCount } from 'selectors/notification';

import Navigation from './Navigation';
import Drawer from './Drawer';
import MiniDrawer from './MiniDrawer';
import NotificationDrawer from './NotificationDrawer';

function LeftNav(props) {
  const { app, showMenu, toggleMenu, user, userExams } = props;

  const userJS = user.toJS();

  const [notificationOpen, toggleNotificationMenu] = React.useState(false);

  const navigationProps = { ...props, hasExams: !isEmpty(userExams), user: userJS, toggleNotificationMenu };

  return (
    <React.Fragment>
      {app.isMobile ? (
        <Drawer showMenu={showMenu} toggleMenu={toggleMenu} user={userJS}>
          <Navigation {...navigationProps} />
        </Drawer>
      ) : (
        <MiniDrawer showMenu={showMenu} toggleMenu={toggleMenu}>
          <Navigation {...navigationProps} />
        </MiniDrawer>
      )}

      <NotificationDrawer toggleMenu={toggleNotificationMenu} showMenu={notificationOpen} />
    </React.Fragment>
  );
}

LeftNav.propTypes = {
  app: PropTypes.object,
  toggleMenu: PropTypes.func.isRequired,
  showMenu: PropTypes.bool,
  user: PropTypes.object,
  userExams: PropTypes.array,
};

const mapStateToProps = (state) => ({
  user: getLoginUser(state),
  roadmapId: getRoadmapIdByLoggedInUser(state),
  userExams: getAllExamsForLoggedInUser(state),
  isCalendarView: getMetaItem(componentKeys.SESSION_IS_CALENDAR_VIEW)(state),
  newNotificationCount: getNewNotificationCount(state),
});

export default compose(withRouter, withAppContext, connect(mapStateToProps))(LeftNav);
