import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';
import { gql } from '@apollo/client';
import { createClient } from '../utils';

const { api } = getConfig();

const client = createClient(api.graphQLEndpoint);

const getDocumentUrl = gql`
  query getDocumentUrl($objectName: String!, $region: String!) {
    getDocumentUrl(objectName: $objectName, region: $region) {
      signedUrl
    }
  }
`;
export default {
  getDocumentUrl: async (objectName, region) => client.query(getDocumentUrl, { objectName, region }),
};
