import { getEnvironmentConfig as getConfig } from '@crimson-education/common-config/lib/environment';
import { gql } from '@apollo/client';
import { escapeString } from 'utils/graphql';
import { createClient } from '../utils';

const { api } = getConfig();

const client = createClient(api.graphQLEndpoint);

const createSessionAbsence = gql`
  mutation createSessionAbsence(
    $eventId: String!
    $absenceReasonId: String!
    $absenceReasonComment: String
    $receiverId: String
  ) {
    createSessionAbsence(
      absence: {
        eventId: $eventId
        absenceReasonId: $absenceReasonId
        absenceReasonComment: $absenceReasonComment
        receiverId: $receiverId
      }
    )
  }
`;

const recordAttendance = gql`
  mutation recordAttendance(
    $eventId: String!
    $otherUserId: String!
    $newStart: String
    $newEnd: String
    $status: String!
    $reason: String!
    $absence: Boolean!
  ) {
    booking: recordAttendance(
      eventId: $eventId
      otherUserId: $otherUserId
      newStart: $newStart
      newEnd: $newEnd
      status: $status
      reason: $reason
      absence: $absence
    )
  }
`;

const completeAttendanceBooking = gql`
  mutation completeAttendanceBooking(
    $eventId: String!
    $userId: String!
    $otherUserId: String!
    $newStart: String
    $newEnd: String
    $status: String!
    $reason: String!
    $absence: Boolean!
  ) {
    attendanceCompleteBooking(
      eventId: $eventId
      userId: $userId
      otherUserId: $otherUserId
      newStart: $newStart
      newEnd: $newEnd
      status: $status
      reason: $reason
      absence: $absence
    )
  }
`;

export default {
  createSessionAbsence: ({ eventId, absenceReasonId, absenceReasonComment, receiverId }) =>
    client.query(createSessionAbsence, {
      eventId,
      absenceReasonId,
      absenceReasonComment: escapeString(absenceReasonComment),
      receiverId,
    }),
  recordAttendance: ({ eventId, otherUserId, start, end, status, reason }) =>
    client.query(recordAttendance, { eventId, otherUserId, newStart: start, newEnd: end, status, reason }),
  completeAttendanceBooking: ({ eventId, userId, otherUserId, newStart, newEnd, status, reason, absence }) =>
    client.query(completeAttendanceBooking, {
      eventId,
      userId,
      otherUserId,
      newStart,
      newEnd,
      status,
      reason,
      absence,
    }),
};
