import React from 'react';
import PropTypes from 'prop-types';
import Body from 'components/atoms/typography/Body';
import Modal from 'components/molecules/Modal';

export default function ConfirmationModal(props) {
  const { isOpen, onCancel, onConfirm, actionText, modalBodyText } = props;

  return (
    <Modal
      title="Are you sure?"
      submitText={actionText}
      secondarySubmitText="Cancel"
      isOpen={isOpen}
      onClose={onCancel}
      onSubmit={onConfirm}
      onSecondarySubmit={onCancel}
    >
      <Body>{modalBodyText}</Body>
    </Modal>
  );
}

ConfirmationModal.propTypes = {
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  isOpen: PropTypes.bool,
  actionText: PropTypes.string,
  modalBodyText: PropTypes.string,
};
