import styled from 'styled-components';
import { Upload as AntUpload } from 'antd';

export const Upload = styled(AntUpload)<{ length: number }>`
  border: none;
  .ant-upload-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 0 8px 8px 8px;
    // this is the parent of UploadFileItemContainer
    .ant-upload-list-text-container {
      width: 33%;
      text-overflow: ellipsis !important;
      white-space: nowrap;
      overflow: hidden !important;
    }
  }
`;

export const UploadFileItemContainer = styled.div<{ showHover: boolean }>`
  width: 100%;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: flex-end;
  padding: 8px 16px 16px 16px;
  .hover-effect-container {
    display: flex;
    width: 93%;
    padding: 8px 8px 8px 12px;
    background: #ffffff;
    border: 1px solid #e3e7ed;
    border-radius: 4px;
    box-shadow: ${({ showHover }) => (showHover ? '0px 5px 15px rgba(61, 64, 144, 0.2)' : 'none')};
    img.document-type-icon {
      max-width: 36px;
      height: 22px;
      margin-right: 10px;
    }
    .upload-list-item-node-container {
      text-overflow: ellipsis !important;
      white-space: nowrap;
      overflow: hidden !important;
      .ant-upload-list-item {
        margin: 0;
        color: #292c38;
        font-size: 14px;
        height: 20px;
        &:hover .ant-upload-list-item-info {
          background-color: unset;
        }
        .anticon.anticon-paper-clip {
          display: none;
        }
        .ant-upload-list-item-name {
          padding: 0 3px 0 3px;
        }
        .ant-upload-span {
          pointer-events: none;
          width: 100%;
          .ant-upload-text-icon {
            display: none;
          }
          span.ant-upload-list-item-name {
            padding: 0;
          }
        }
      }
      .essay-type-text {
        font-size: 12px;
        line-height: 16px;
        color: #96a0b5;
      }
    }
  }
  .upload-list-item-remove {
    position: absolute;
    z-index: 1;
    right: 2%;
    top: 25px;
    svg {
      color: #e3e7ed;
    }
  }
`;
