import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import titlecase from 'titlecase';
import Immutable from 'immutable';
import { Acl } from '@crimson-education/common-config';
import Comment, { Reply } from 'components/generic/Comment';
import { getInitialsFromNames } from 'utils/utils';

import css from './styles.scss';

export default class Comments extends Component {
  static setInitialsAndColourIndex(lesson) {
    let client = lesson.get('client');
    let tutor = lesson.get('tutor');
    client = client
      .set('colorIndex', 2)
      .set('name', titlecase(`${client.get('firstName')} ${client.get('lastName')}`))
      .set('initials', `${client.get('firstName')[0].toUpperCase()}${client.get('lastName')[0].toUpperCase()}`);
    tutor = tutor
      .set('colorIndex', 0)
      .set('name', titlecase(`${tutor.get('firstName')} ${tutor.get('lastName')}`))
      .set('initials', `${tutor.get('firstName')[0].toUpperCase()}${tutor.get('lastName')[0].toUpperCase()}`);
    return lesson.set('client', client).set('tutor', tutor);
  }

  static formatLoggedinUser(loginUser, formattedLesson) {
    let formattedLogginUser;
    const loginUserId = loginUser.get('userId');

    // use avatar info from formatted lesson if posting as client or tutor, otherwise construct admin avatar
    if (loginUserId === formattedLesson.getIn(['client', 'userId'])) {
      formattedLogginUser = formattedLesson.get('client');
    } else if (loginUserId === formattedLesson.getIn(['tutor', 'userId'])) {
      formattedLogginUser = formattedLesson.get('tutor');
    } else {
      const userMetaData = loginUser.get('user_metadata');
      formattedLogginUser = Immutable.fromJS({
        initials: `${userMetaData.get('firstName')[0].toUpperCase()}${userMetaData.get('lastName')[0].toUpperCase()}`,
        name: titlecase(
          `${loginUser.getIn(['user_metadata', 'firstName'])} ${loginUser.getIn(['user_metadata', 'lastName'])}`,
        ),
        colorIndex: 4,
      });
    }
    return formattedLogginUser;
  }

  constructor(props) {
    super(props);

    this.state = {
      currentEditingComment: null,
    };

    this.renderComments = this.renderComments.bind(this);
    this.renderReplies = this.renderReplies.bind(this);
    this.handleFileDelete = this.handleFileDelete.bind(this);
    this.handleCommentDelete = this.handleCommentDelete.bind(this);
    this.returnAuthor = this.returnAuthor.bind(this);
    this.handleCommentEditModeChange = this.handleCommentEditModeChange.bind(this);
  }

  handleCommentEditModeChange(isActive, comment) {
    if (isActive) {
      const { currentEditingComment } = this.state;

      if (currentEditingComment && currentEditingComment !== comment) {
        currentEditingComment.toggleEditMode(false);
      }

      this.setState({
        currentEditingComment: comment,
      });
    }
  }

  handleFileDelete(file, comment, reply) {
    const { openDeleteModal } = this.props;
    openDeleteModal(file.get('name'), file.get('id'), comment.get('id'), reply && reply.get('id'), reply || comment);
  }

  handleCommentDelete(comment, reply) {
    const { openDeleteModal } = this.props;
    openDeleteModal(null, null, comment.get('id'), reply && reply.get('id'), reply || comment);
  }

  // Return the author if stored in the lesson otherwise
  // check the people cache store for the info instead.
  returnAuthor(submittedUserId, client, tutor) {
    const { getCachedUsers, loginUser } = this.props;

    if (submittedUserId === loginUser.get('userId')) {
      const userMetaData = loginUser.get('user_metadata');
      return Immutable.fromJS({
        initials: `${userMetaData.get('firstName')[0].toUpperCase()}${userMetaData.get('lastName')[0].toUpperCase()}`,
        name: titlecase(
          `${loginUser.getIn(['user_metadata', 'firstName'])} ${loginUser.getIn(['user_metadata', 'lastName'])}`,
        ),
        colorIndex: 4,
      });
    } else if (submittedUserId === client.get('userId')) {
      return client;
    } else if (submittedUserId === tutor.get('userId')) {
      return tutor;
    } else if (getCachedUsers.get(submittedUserId)) {
      const author = getCachedUsers.get(submittedUserId);
      return getCachedUsers
        .get(submittedUserId)
        .set('initials', getInitialsFromNames([author.get('firstName'), author.get('lastName')]))
        .set('colorIndex', '4');
    }
    return null;
  }

  renderReplies(parentComment, parentKey, formattedLesson) {
    const { onReplyComment, loginUser, onDownloadCommentFile, onEditComment, lessonDetails } = this.props;

    const replyToParent = (reply, file) => onReplyComment(parentKey, reply, file, lessonDetails);
    const formattedLoggedinUser = Comments.formatLoggedinUser(loginUser, formattedLesson);
    const replies = parentComment.get('replies');
    const parentCommentForCallBack = parentComment.set('id', parentKey);

    return (
      <div className={css.reply} key={`reply-${parentKey}`}>
        {replies &&
          replies
            .sortBy(
              (c) => c.get('timestamp'),
              (a, b) => a - b,
            )
            .map((reply, key) => {
              const client = formattedLesson.get('client');
              const tutor = formattedLesson.get('tutor');
              const submittedUserId = reply.get('user_id');
              const author = this.returnAuthor(submittedUserId, client, tutor);
              const canEdit = reply.get('user_id') === loginUser.get('userId');
              const canDelete =
                reply.get('user_id') === loginUser.get('userId') || Acl.isAdmin(loginUser.get('userRoles'));

              return (
                <Comment
                  key={key}
                  commentId={key}
                  comment={reply}
                  author={author || Immutable.fromJS({})}
                  canEdit={canEdit}
                  canDelete={canDelete}
                  onCommentEdit={(editedMessage) => onEditComment(parentKey, key, editedMessage)}
                  onCommentDelete={(comment) => this.handleCommentDelete(parentCommentForCallBack, comment)}
                  onFileDelete={(file, comment) => this.handleFileDelete(file, parentCommentForCallBack, comment)}
                  onFileDownload={(file) => onDownloadCommentFile(file.get('id'))}
                  onEditModeChange={this.handleCommentEditModeChange}
                />
              );
            })
            .toList()}
        <Reply onReply={replyToParent} loginUser={formattedLoggedinUser} />
      </div>
    );
  }

  renderComments() {
    const { loginUser, comments, lesson, onDownloadCommentFile, onEditComment } = this.props;
    const formattedLesson = Comments.setInitialsAndColourIndex(lesson);
    const conversations = comments.get('conversations') || new Immutable.Map({});
    return conversations
      .sortBy(
        (c) => c.get('timestamp'),
        (a, b) => b - a,
      )
      .map((comment, key) => {
        const client = formattedLesson.get('client');
        const tutor = formattedLesson.get('tutor');
        const submittedUser = comment.get('user_id');
        const author = this.returnAuthor(submittedUser, client, tutor);
        const canEdit = comment.get('user_id') === loginUser.get('userId');
        const canDelete = comment.get('user_id') === loginUser.get('userId') || Acl.isAdmin(loginUser.get('userRoles'));

        return (
          <div key={`comment-${key}`}>
            <Comment
              key={key}
              commentId={key}
              comment={comment}
              author={author || Immutable.fromJS({})}
              canEdit={canEdit}
              canDelete={canDelete}
              onCommentEdit={(editedMessage) => onEditComment(key, null, editedMessage)}
              onCommentDelete={(comment) => this.handleCommentDelete(comment, null)}
              onFileDelete={(file, comment) => this.handleFileDelete(file, comment)}
              onFileDownload={(file) => onDownloadCommentFile(file.get('id'))}
              onEditModeChange={this.handleCommentEditModeChange}
            />
            {this.renderReplies(comment, key, formattedLesson)}
          </div>
        );
      })
      .toList();
  }

  render() {
    return <div className={css.comments}>{this.renderComments()}</div>;
  }
}

Comments.propTypes = {
  comments: ImmutablePropTypes.map.isRequired,
  lesson: ImmutablePropTypes.map.isRequired,
  loginUser: ImmutablePropTypes.map.isRequired,
  onReplyComment: PropTypes.func.isRequired,
  lessonDetails: PropTypes.object.isRequired,
  openDeleteModal: PropTypes.func.isRequired,
  onDownloadCommentFile: PropTypes.func.isRequired,
  getCachedUsers: ImmutablePropTypes.map,
  onEditComment: PropTypes.func.isRequired,
};

Comments.displayName = 'Comments';
