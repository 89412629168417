import React from 'react';
import { Row } from '../../index.style';
import {
  Container,
  SectionTitleContainer,
  ClickableUnderlineText,
  TextCount,
  StyledDialogue,
  StyledTextArea,
} from './index.style';
export type DefineEmailContentUSProps = {
  clickableText?: string;
  clause: string;
  onClauseChange: (value: string) => void;
  isOpen: boolean;
  closeModal: () => void;
  onClickConfirm: () => void;
  children?: React.ReactNode;
  title?: string;
  onClickClickableText: () => void;
  isLoading?: boolean;
};
const DefineEmailContentUS = ({
  clause,
  onClauseChange,
  title = 'Confirm Email Content',
  clickableText = 'View Email Template',
  isOpen,
  closeModal,
  onClickConfirm,
  children,
  onClickClickableText,
  isLoading,
}: DefineEmailContentUSProps) => {
  return (
    <StyledDialogue
      title={title}
      isOpen={isOpen}
      closeModal={closeModal}
      onClickConfirm={onClickConfirm}
      confirmButtonText={'Send'}
      isLoading={isLoading}
    >
      <Container>
        {children}
        <Row justifycontent={'space-between'}>
          <SectionTitleContainer>
            <span>{'Add additional Clause(s) '}</span>
            <span>{'(optional)'}</span>
          </SectionTitleContainer>
          <ClickableUnderlineText onClick={onClickClickableText}>{clickableText}</ClickableUnderlineText>
        </Row>
        <StyledTextArea
          placeholder={
            "You can use this space to add additional clauses to the standard School Choice Confirmation Agreement email template to account for the student's individual circumstances (for example, if the student is applying to reach only schools)"
          }
          charLimit={2000}
          value={clause}
          onChange={onClauseChange}
        />
        <TextCount>{`${clause.length}/1000`}</TextCount>
      </Container>
    </StyledDialogue>
  );
};

export default DefineEmailContentUS;
