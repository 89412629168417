import React, { useState, useRef } from 'react';
import { InputProps as AntInputProps, InputRef } from 'antd/lib/input';
import { StyledInput, InputWrapper } from './index.style';

type InputProps = {
  rightIcon?: JSX.Element | ((focused: boolean) => JSX.Element | null);
} & AntInputProps;
const Input = ({ rightIcon, onFocus, onBlur, ...restProps }: InputProps) => {
  const [focused, setFocused] = useState(false);
  const _onFocus: React.FocusEventHandler<HTMLInputElement> = (e) => {
    setFocused(true);
    onFocus && onFocus(e);
  };
  const _onBlur: React.FocusEventHandler<HTMLInputElement> = (e) => {
    setFocused(false);
    onBlur && onBlur(e);
  };
  const inputRef = useRef<InputRef>(null);
  return (
    <InputWrapper>
      <StyledInput ref={inputRef} onFocus={_onFocus} onBlur={_onBlur} bordered={false} {...restProps} />
      {typeof rightIcon === 'function' ? rightIcon(focused) : rightIcon}
    </InputWrapper>
  );
};
export default Input;
