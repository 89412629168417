import Immutable from 'immutable';
import createReducer from 'utils/createReducer';
import { ADD_ENTITIES, addEntitiesWithNormalisation } from 'ducks/normalizr';
import { applicationEntity } from 'schema';
import applicationConnector from 'graphql/api/application';
import { updateMeta, formFail, formSuccess } from 'ducks/meta';
import componentKeys from 'constants/componentKeys';

export const UPDATE_APPLICATION_POSITION_OPTIMISTIC = 'application/UPDATE_APPLICATION_POSITION_OPTIMISTIC';
export const UPDATE_APPLICATION_SUCCEEDED = 'application/UPDATE_APPLICATION_SUCCEEDED';
export const DELETE_APPLICATION_SUCCEEDED = 'application/DELETE_APPLICATION_SUCCEEDED';

const initialState = new Immutable.Map();

export default createReducer(initialState, {
  [ADD_ENTITIES]: (state, action) => {
    return state.mergeDeep(action.payload.entities.application);
  },
  [UPDATE_APPLICATION_SUCCEEDED]: (state, action) => {
    const { application } = action.payload;
    return state.setIn([application.id], Immutable.fromJS(application));
  },
  [UPDATE_APPLICATION_POSITION_OPTIMISTIC]: (state, action) => {
    const { id, position } = action.payload;

    const userId = state.get(id).get('userId');
    const allApplications = state
      .toList()
      .filter((a) => a.get('userId') === userId && a.get('id') !== id)
      .sort((a, b) => a.get('order') - b.get('order'))
      .splice(position, 0, state.get(id));

    let newState = state;
    allApplications.forEach((application, index) => {
      newState = newState.setIn([application.get('id'), 'order'], index);
    });
    return newState;
  },
  [DELETE_APPLICATION_SUCCEEDED]: (state, action) => {
    const { applicationId } = action.payload;
    const newState = state;
    return newState.delete(applicationId);
  },
});

export function updateApplicationPositionOptimistic(id, position) {
  return { type: UPDATE_APPLICATION_POSITION_OPTIMISTIC, payload: { id, position } };
}

export function updateApplicationSucceded(application) {
  return { type: UPDATE_APPLICATION_SUCCEEDED, payload: { application } };
}

export function deleteApplicationSucceeded(applicationId) {
  return { type: DELETE_APPLICATION_SUCCEEDED, payload: { applicationId } };
}

export function fetchApplicationsByUserId(userId, groups) {
  return async (dispatch) => {
    dispatch(updateMeta(componentKeys.APPLICATIONS_FETCHED, false));
    const result = await applicationConnector.getApplicationsByUserId(userId, groups);
    const applications = result.getApplicationsByUserId;
    if (applications) {
      dispatch(addEntitiesWithNormalisation(applications, [applicationEntity]));
    }
    dispatch(updateMeta(componentKeys.APPLICATIONS_FETCHED, true));
  };
}

export function createApplication(application) {
  return async (dispatch) => {
    try {
      const result = await applicationConnector.createApplication(application);
      dispatch(addEntitiesWithNormalisation(result.createApplication, applicationEntity));
      dispatch(formSuccess('University has been added'));
      return result.createApplication;
    } catch (err) {
      dispatch(formFail('Failed to create university'));
      return false;
    }
  };
}

export function updateApplication(application) {
  return async (dispatch) => {
    try {
      const result = await applicationConnector.updateApplication(application);
      dispatch(updateApplicationSucceded(result.updateApplication));
      dispatch(formSuccess('University has been updated'));
      return result.updateApplication;
    } catch (err) {
      dispatch(formFail('Failed to update university'));
      return false;
    }
  };
}

export function deleteAdmissionResult(id) {
  return async (dispatch) => {
    try {
      const result = await applicationConnector.deleteAdmissionResult(id);
      dispatch(updateApplicationSucceded(result.deleteAdmissionResult));
      dispatch(formSuccess('Admission result has been removed'));
      return result.deleteAdmissionResult;
    } catch (err) {
      dispatch(formFail('Failed to delete Admission result'));
      return false;
    }
  };
}

export function createAdmissionResult(admissionResult) {
  return async (dispatch) => {
    try {
      const result = await applicationConnector.createAdmissionResult(admissionResult);
      dispatch(updateApplicationSucceded(result.createAdmissionResult));
      dispatch(formSuccess('Admission result has been added'));
      return result.createAdmissionResult;
    } catch (error) {
      dispatch(formFail('Failed to add admission result'));
      return false;
    }
  };
}

export function updateAdmissionResult(admissionResult) {
  return async (dispatch) => {
    try {
      const result = await applicationConnector.updateApplication(admissionResult);
      dispatch(updateApplicationSucceded(result.updateApplication));
      dispatch(formSuccess('Admission result has been updated'));
      return result.updateApplication;
    } catch (err) {
      dispatch(formFail('Failed to update admission result'));
      return false;
    }
  };
}

export function updateApplicationSortOrder(applicationId, position) {
  return async (dispatch) => {
    try {
      dispatch(updateApplicationPositionOptimistic(applicationId, position));
      await applicationConnector.updateApplication({
        id: applicationId,
        order: position,
      });
    } catch (err) {
      dispatch(formFail('Failed to save new order'));
    }
  };
}

export function deleteApplication(id) {
  return async (dispatch) => {
    try {
      await applicationConnector.deleteApplication(id);
      dispatch(deleteApplicationSucceeded(id));
      dispatch(formSuccess('University has been removed'));
      return true;
    } catch (err) {
      dispatch(formFail('Failed to delete university'));
      return false;
    }
  };
}
