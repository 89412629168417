import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { deleteCollaborativeFileFromSession } from 'ducks/google/sessionFiles.js';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import Modal from 'components/molecules/Modal';
import { MoreVert, Delete } from 'components/atoms/icons';
import Body from 'components/atoms/typography/Body';

import css from './styles.scss';
import { handleEnter } from '../../../../utils/accessibility';

const FileActions = ({ sessionId, deleteCollaborativeFileFromSession, fileId, fileName, studentInfo }) => {
  const studentId = studentInfo && studentInfo.userId;
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);

  return (
    <ClickAwayListener onClickAway={() => setDropdownOpen(false)}>
      <div className={css.fileActions}>
        <MoreVert onClick={() => setDropdownOpen(!dropdownOpen)} />
        {dropdownOpen && (
          <div className={css.fileActionsDropDown}>
            <div
              role="button"
              tabIndex={0}
              className={css.fileAction}
              onClick={() => setModalOpen(true)}
              onKeyDown={handleEnter(() => setModalOpen(true))}
            >
              <Delete />
              <Body>Delete</Body>
            </div>
          </div>
        )}
        <Modal
          title="Delete File"
          isOpen={modalOpen}
          onSubmit={async () => {
            await deleteCollaborativeFileFromSession(sessionId, fileId, studentId);
            setTimeout(() => setModalOpen(false), 300);
          }}
          onClose={() => setModalOpen(false)}
          submitText="Yes"
          onSecondarySubmit={() => setModalOpen(false)}
          secondarySubmitText="No"
        >
          Are you sure you want to delete {fileName}?
        </Modal>
      </div>
    </ClickAwayListener>
  );
};

FileActions.propTypes = {
  sessionId: PropTypes.string,
  fileId: PropTypes.string,
  fileName: PropTypes.string,
  deleteCollaborativeFileFromSession: PropTypes.func,
  studentInfo: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => ({
  deleteCollaborativeFileFromSession: (sessionId, fileId, studentId) =>
    dispatch(deleteCollaborativeFileFromSession(sessionId, fileId, studentId)),
});

export default connect(() => ({}), mapDispatchToProps)(FileActions);
