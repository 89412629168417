import React from 'react';
import Skeleton from 'antd/lib/skeleton';
import { Column } from '../../../index.style';

const MeetingPanelLoadingView = () => {
  return (
    <Column style={{ marginTop: 20 }} gap={'15px'}>
      {new Array(4).fill(null).map((_, idx) => (
        <Skeleton key={'meetingpanel' + idx} active title={true} paragraph={false} />
      ))}
    </Column>
  );
};

export default MeetingPanelLoadingView;
