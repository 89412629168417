import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

import CheckboxField from 'components/generic/Forms/CheckboxField';

import Details from '../../Details';
import css from './styles.scss';

export default class Person extends PureComponent {
  render() {
    const { person, selected, togglePersonSelected, canSelectMorePeople } = this.props;

    // The checkbox should be disabled if we can't select more people and the
    // current user isn't currently selected; we must still allow deselection.
    const isDisabled = !(canSelectMorePeople || selected);
    const role = person.get('role');
    const firstName = person.get('firstName');

    return (
      <div className={css.person}>
        <Details person={person} />
        <CheckboxField
          label=""
          dataGaLabel="Person"
          id={`bookWith_${role}_${firstName}_Checkbox`}
          isChecked={selected}
          className={css.personCheckbox}
          disabled={isDisabled}
          onClick={() => {
            togglePersonSelected(person.get('userId'));
          }}
          dataTestId={`bookWith_${role}_${firstName}_Checkbox`}
        />
      </div>
    );
  }
}

Person.propTypes = {
  person: ImmutablePropTypes.map.isRequired,
  selected: PropTypes.bool.isRequired,
  togglePersonSelected: PropTypes.func.isRequired,
  canSelectMorePeople: PropTypes.bool.isRequired,
};
