import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'auto-bind';
import moment from 'moment';
import * as Logger from '@crimson-education/browser-logger';
import packageService from 'graphql/api/package';
import { Acl } from '@crimson-education/common-config';
import InputWithTags from 'components/molecules/InputWithTags';

import Modal from 'components/molecules/Modal';
import Checkbox from 'components/molecules/Checkbox';

import { Body, Caption } from 'components/atoms/typography';
import css from './styles.scss';
import { handleEnter } from '../../../../utils/accessibility';
import { MessageEventType } from 'utils/MessageEventType';

export default class SendSessionSummaryModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      emails: [],
      otherEmails: [],
      studentTutors: {},
    };

    autoBind(this);

    props.fetchUserById(props.studentId);
    props.fetchSessionSummaryEmailHistory(props.eventId);
  }

  componentDidMount() {
    const { session, loginUser } = this.props;
    const userId = loginUser.get('userId');
    const { participantUserIds } = session;
    if (participantUserIds.indexOf(userId) > -1) {
      const userRoles = loginUser.get('userRoles');
      if (!Acl.isStrategist(userRoles) && !Acl.isCaseManager(userRoles)) {
        return;
      }
      packageService.allPackages(this.props.studentId).then((res) => {
        const { allPackages } = res;
        const studentTutors = {};
        allPackages.forEach((packageItem) => {
          const { subjects } = packageItem;
          subjects.forEach((subject) => {
            const { tutors } = subject;
            tutors.forEach((tutor) => {
              if (tutor.status === 'active') {
                const { user } = tutor;
                if (studentTutors[user.userId]) {
                  studentTutors[user.userId].subject.push(subject.subject);
                } else {
                  studentTutors[user.userId] = {
                    user,
                    subject: [subject.subject],
                  };
                }
              }
            });
          });
        });
        this.setState({
          studentTutors,
        });
      });
    }
  }

  getLastSentTime(email) {
    const { sessionEmailHistory } = this.props;
    if (!sessionEmailHistory) {
      return null;
    }
    const history = sessionEmailHistory.find((s) => s.email === email);
    return history ? (
      <div className={css.emailHistory}>
        <span>{`Last sent: ${moment(history.sentAt).format('Do MMM, YYYY')}`}</span>
        <i className="zmdi zmdi-mail-send" />
      </div>
    ) : null;
  }

  addOrRemoveEmail(email) {
    const emailState = this.state.emails;
    if (this.isEmailInList(email)) {
      const index = emailState.indexOf(email);
      emailState.splice(index, 1);
    } else {
      emailState.push(email);
    }
    this.setState({ emails: emailState });
  }

  isEmailInList(email) {
    const { emails } = this.state;
    return emails.some((e) => e === email);
  }

  handleTagChange(tags) {
    this.setState({ otherEmails: tags, invalidEmails: null });
  }

  handleRejectedTags(tags) {
    if (tags.find((t) => t !== '')) {
      this.setState({ invalidEmails: tags });
    } else {
      this.setState({ invalidEmails: null });
    }
  }

  async submit() {
    const { emails, otherEmails } = this.state;
    const { studentId, eventId, sendSessionSummaryEmail, onClose, saveSessionSummary, setFailForm } = this.props;

    if (!emails.length && !otherEmails.length) {
      return;
    }

    const isSuccess = await saveSessionSummary();
    if (!isSuccess) {
      setFailForm('Session summary has not been sent. Please try again later.');
      return;
    }

    const recipients = emails.concat(otherEmails);
    const metadata = {
      emails: recipients,
      eventId,
    };

    Logger.trackEvent({ message: MessageEventType.SendSessionSummary, metadata });

    sendSessionSummaryEmail({
      eventId,
      studentId,
      emails: recipients,
    });

    onClose();
  }

  render() {
    const { onClose, keyContactInfo, userOnBehalf } = this.props;
    const { invalidEmails, emails, otherEmails, studentTutors } = this.state;

    const isSubmitDisabled = !emails.length && !otherEmails.length;

    return (
      <Modal
        isOpen
        title="Send session summary"
        onClose={onClose}
        onSubmit={this.submit}
        submitText="Send summary"
        onSubmitDataTestId="sendSessionSummarySubmitBtn"
        isSubmitDisabled={isSubmitDisabled}
      >
        <div className={css.keyContacts}>
          {keyContactInfo.map((contact, idx) => (
            <div
              role="button"
              tabIndex={0}
              className={css.keyContact}
              key={idx}
              onClick={() => this.addOrRemoveEmail(contact.email)}
              onKeyDown={handleEnter(() => this.addOrRemoveEmail(contact.email))}
            >
              <div className={css.contactWrapper}>
                <Checkbox isChecked={this.isEmailInList(contact.email)} />
                <div className={css.keyContactDetails}>
                  <Body>
                    {contact.firstName} {contact.lastName}
                  </Body>
                  <Caption bold>
                    {contact.relationship} {contact.isPrimary && '(Primary contact)'}
                  </Caption>
                </div>
              </div>
              {this.getLastSentTime(contact.email)}
            </div>
          ))}

          <hr className={css.divider} />
        </div>

        <div className={css.otherContacts}>
          <div
            role="button"
            tabIndex={0}
            className={css.keyContact}
            onClick={() => this.addOrRemoveEmail(userOnBehalf.email)}
            onKeyDown={handleEnter(() => this.addOrRemoveEmail(userOnBehalf.email))}
          >
            <div className={css.contactWrapper}>
              <Checkbox isChecked={this.isEmailInList(userOnBehalf.email)} />
              <div className={css.keyContactDetails}>
                <Body>
                  {userOnBehalf.firstName} {userOnBehalf.lastName}
                </Body>
                <Caption bold>Student</Caption>
              </div>
            </div>
            {this.getLastSentTime(userOnBehalf.email)}
          </div>

          {userOnBehalf.relationships &&
            userOnBehalf.relationships.map((relationship, idx) => {
              const { principalUser } = relationship;
              const principalPrimaryRole = principalUser.roles.find((r) => r.isPrimary) || principalUser.roles[0];

              return (
                <div
                  role="button"
                  tabIndex={0}
                  className={css.keyContact}
                  key={idx}
                  onClick={() => this.addOrRemoveEmail(principalUser.email)}
                  onKeyDown={handleEnter(() => this.addOrRemoveEmail(principalUser.email))}
                >
                  <div className={css.contactWrapper}>
                    <Checkbox isChecked={this.isEmailInList(principalUser.email)} />
                    <div className={css.keyContactDetails}>
                      <Body>
                        {principalUser.firstName} {principalUser.lastName}
                      </Body>
                      <Caption bold>{principalPrimaryRole.role.name}</Caption>
                    </div>
                  </div>
                  {this.getLastSentTime(principalUser.email)}
                </div>
              );
            })}

          {Object.keys(studentTutors).length > 0 &&
            Object.values(studentTutors).map((tutor, idx) => {
              const { user } = tutor;
              return (
                <div
                  role="button"
                  tabIndex={0}
                  className={css.keyContact}
                  key={idx}
                  onClick={() => this.addOrRemoveEmail(user.email)}
                  onKeyDown={handleEnter(() => this.addOrRemoveEmail(user.email))}
                >
                  <div className={css.contactWrapper}>
                    <Checkbox isChecked={this.isEmailInList(user.email)} />
                    <div className={css.keyContactDetails}>
                      <Body>
                        {user.firstName} {user.lastName}
                      </Body>
                      <Caption bold>
                        Tutor (
                        {tutor.subject.map((item, index) => {
                          if (index > 0) {
                            return `, ${item.name}`;
                          } else {
                            return item.name;
                          }
                        })}
                        )
                      </Caption>
                    </div>
                  </div>
                  {this.getLastSentTime(user.email)}
                </div>
              );
            })}
          <hr className={css.divider} />
        </div>
        <div className={css.manualEmail}>
          <Caption className={css.inputCaption}>Anyone else to send to?</Caption>
          <InputWithTags
            value={this.state.otherEmails}
            onChange={this.handleTagChange}
            placeholder="name@email.com"
            addOnBlur
            validationRegex={/\S+@\S+\.\S+/}
            onValidationReject={(tags) => this.handleRejectedTags(tags)}
          />
          {invalidEmails && invalidEmails.length && (
            <div className={css.tagError}>
              <strong>{invalidEmails[0]}</strong> is not a valid email address.
            </div>
          )}
        </div>
      </Modal>
    );
  }
}

SendSessionSummaryModal.propTypes = {
  onClose: PropTypes.func,
  keyContactInfo: PropTypes.array,
  userOnBehalf: PropTypes.object,
  studentId: PropTypes.string,
  eventId: PropTypes.number,
  fetchUserById: PropTypes.func,
  sendSessionSummaryEmail: PropTypes.func,
  fetchSessionSummaryEmailHistory: PropTypes.func,
  sessionEmailHistory: PropTypes.array,
  saveSessionSummary: PropTypes.func,
  setFailForm: PropTypes.func,
  loginUser: PropTypes.object.isRequired,
  session: PropTypes.object.isRequired,
};
