import { createSelector } from 'reselect';

const userRelationships = (state) => state.get('userRelationship');

export const allUserRelationships = createSelector(
  userRelationships,
  (userRelationships) => userRelationships && userRelationships.toJS(),
);

export const getUserRelationship = (userId, otherUserId) => {
  return createSelector(userRelationships, (userRelationships) =>
    userRelationships.get(`${userId}-${otherUserId}`).toJS(),
  );
};

export const getUserRelationshipById = (relationshipId) => {
  return createSelector(userRelationships, (userRelationships) => userRelationships.get(relationshipId).toJS());
};
