import Immutable from 'immutable';
import { push } from 'react-router-redux';
import moment from 'moment';
import createReducer from 'utils/createReducer';
import { toastr } from 'react-redux-toastr';
import { toasterMessages } from '@crimson-education/common-config';
import modalState from 'constants/modalStates';
import formState from 'constants/formStates';
import { getProfile } from 'utils/auth/profile';
import { fetchPeopleAvailability, fetchBookingAsEvents } from 'ducks/user';
import { selectedBookWith } from 'selectors/user';
import { getThunksRemaining, getMetaItem } from 'selectors/meta';
import { escapeString, formatGraphQLRequestError } from 'utils/graphql';
import { CALENDAR_FUTURE_LIMIT } from 'utils/calendarUtils';
import profileAPI from 'graphql/api/profile';
import userAPI from 'graphql/api/user';
import userInvitationAPI from 'graphql/api/userInvitation';
import authorizationAPI from 'graphql/api/authorization';
import availabilityApi from 'graphql/api/availability';
import bookingApi from 'graphql/api/booking';
import { ADD_ENTITIES } from 'ducks/normalizr';
import componentKeys from 'constants/componentKeys';
import feedback from 'graphql/api/feedback';
import { PermissionAction } from '@crimson-education/common-config/lib/authorization';

export const MAXIMUM_PEOPLE_SELECTED = 3;

export const LOCATION_CHANGE = 'meta/LOCATION_CHANGE';

const UPDATE_META = 'meta/UPDATE_META';
const BULK_UPDATE_META = 'meta/BULK_UPDATE_META';
const TOGGLE_USER_SELECTED_IN_CALENDAR = 'meta/TOGGLE_USER_SELECTED_IN_CALENDAR';
const CONTACTS_LOADING_SUCCEEDED = 'meta/CONTACTS_LOADING_SUCCEEDED';
const UPDATE_BOOKING_WITH_CACHE = 'meta/UPDATE_BOOKING_WITH_CACHE';
const UPDATE_BOOKING_FOR_CACHE = 'meta/UPDATE_BOOKING_FOR_CACHE';
const UPDATE_ASSIGNABLE_USERS_CACHE = 'meta/UPDATE_ASSIGNABLE_USERS_CACHE';
const UPDATE_REASONS_FOR_CANCELLATION = 'meta/UPDATE_REASONS_FOR_CANCELLATION';
const UPDATE_REASONS_FOR_ABSENCE = 'meta/UPDATE_REASONS_FOR_ABSENCE';
const TOGGLE_USER_UNSELECTED_IN_CALENDAR = 'meta/TOGGLE_USER_UNSELECTED_IN_CALENDAR';

// Calendar constants
export const MOBILE_DAYS = 4;
export const DESKTOP_DAYS = 7;
const MOMENT_START_OF_WEEK = moment().startOf('week');

export const INITIAL_STATE = Immutable.fromJS({
  // ----- CALENDAR & BOOKING --------
  [componentKeys.CALENDAR_DAYS]: null,
  [componentKeys.CALENDAR_SELECTED]: new Immutable.List(),
  [componentKeys.CALENDAR_SCROLL]: 0,
  [componentKeys.CALENDAR_SCROLL_HEIGHT]: 0,
  [componentKeys.SELECTED_DAY]: MOMENT_START_OF_WEEK.toDate(), // sets the default calendar date to the first of this week, plus some extra logic for 4 days view
  [componentKeys.BOOKING_WITH_FETCHED]: false,
  [componentKeys.BOOKING_WITH_CACHE]: new Immutable.Map({
    users: new Immutable.List(),
    pagination: new Immutable.Map(),
  }),
  [componentKeys.BOOKING_FOR_CACHE]: new Immutable.Map({
    users: new Immutable.List(),
    pagination: new Immutable.Map(),
  }),
  [componentKeys.REASONS_FOR_CANCELLATION]: new Immutable.Map(),
  [componentKeys.UNAVAILABILITIES]: new Immutable.List(),
  [componentKeys.SESSION_SELECTED_ID]: null,
  [componentKeys.SESSION_REDIRECT_ID]: null,
  [componentKeys.SESSION_PROMPT_MODAL_OPEN]: false,
  [componentKeys.SESSION_IS_CALENDAR_VIEW]: false,
  [componentKeys.SESSION_SCHEDULE_TAB_IS_PAST]: false,
  [componentKeys.SESSION_FEEDBACK_REPORT]: new Immutable.Map(),
  [componentKeys.BOOKING_WITH_FILTER]: new Immutable.Map(),
  [componentKeys.BOOKING_FOR_FILTER]: null,
  [componentKeys.SELECTED_SPECIFIC_DAY]: moment().startOf('day'),
  // ----- ECL --------
  [componentKeys.ECL_SELECTED_ACTIVITY_ID]: null,

  // ----- INVOICE --------
  [componentKeys.INVOICE_FETCHED]: false,
  [componentKeys.INVOICES_FETCHED]: false,
  [componentKeys.INVOICE_SUBMITTING]: false,
  [componentKeys.INVOICE_PAYING]: false,
  [componentKeys.INVOICE_FILTER]: new Immutable.Map(),
  [componentKeys.INVOICE_SELECTED_ID]: null,
  [componentKeys.INVOICE_FETCHING_LOAD]: false,

  // ----- MESSAGES --------
  [componentKeys.CONTACT_LIST_CACHE]: new Immutable.Map(),
  [componentKeys.MESSAGE_CONTACT_FILTER]: '',
  [componentKeys.ACTIVE_MESSAGE_THREAD]: '',

  // ----- MODAL --------
  [componentKeys.MODAL_STATE]: modalState.Default,
  [componentKeys.MODAL_ERROR]: '',

  // ----- PROFILE --------
  [componentKeys.STUDENT_INTERESTS]: new Immutable.List(),
  [componentKeys.TUTOR_EXPERTISE]: new Immutable.List(),
  [componentKeys.LANGUAGES_FETCHED]: false,
  [componentKeys.USER_PROFILE]: null,

  // ----- REPORT --------
  [componentKeys.ACTIVE_SESSION_REPORT]: null,
  [componentKeys.SHOW_INCOMPLETE_REPORT_REMINDER]: false,
  [componentKeys.NEXT_BILLING_PERIOD]: null,

  // ----- ROADMAP --------
  [componentKeys.ROADMAP_FETCHED]: false,
  [componentKeys.LOADED_ROADMAP_TEMPLATE]: null,
  [componentKeys.CURRENT_ROADMAP_TAB]: 0,

  // ----- STUDENT & TUTOR --------
  [componentKeys.CLIENTS_FETCHED]: false,
  [componentKeys.LESSONS_FETCHED]: false,
  [componentKeys.PACKAGE_ITEMS_FETCHED]: false,
  [componentKeys.ASSIGNABLE_USERS_CACHE]: new Immutable.Map({
    users: new Immutable.List(),
    pagination: new Immutable.Map(),
  }),

  // ----- USER --------
  [componentKeys.IS_USER_ONLINE]: true,
  [componentKeys.USERS_FETCHED]: false,
  [componentKeys.OUR_PEOPLE_FILTER]: new Immutable.Map(),
  [componentKeys.FILTERED_QUERY]: false,
  [componentKeys.USERS_FETCHED_COUNT]: 0,

  // ----- EXAM --------
  [componentKeys.EXAM_LIST_FETCHED]: false,
  // ----- TASKS --------
  [componentKeys.TASKS_FETCHED]: false,

  // ----- OTHERS --------
  [componentKeys.THUNKS_REMAINING]: [],
  [componentKeys.ZENDESK_LOGIN_URL]: null,
  [componentKeys.ROUTE_PATHNAME]: '',
  [componentKeys.FILES_UPLOADING]: new Immutable.Map(),
  [componentKeys.FILE_UPLOADED]: new Immutable.Map(),
  [componentKeys.GLOBAL_MESSAGES]: new Immutable.Map(),
  [componentKeys.FORM_STATE]: formState.Default,
  [componentKeys.NOTIFICATIONS]: new Immutable.Map(),
  [componentKeys.ACTIVE_SESSION_REPORT]: null,
  [componentKeys.SHOW_INCOMPLETE_REPORT_REMINDER]: false,
  [componentKeys.NEXT_BILLING_PERIOD]: null,
  [componentKeys.CALENDAR_DAYS]: null,
  [componentKeys.REASONS_FOR_CANCELLATION]: new Immutable.Map(),
  [componentKeys.ECL_SELECTED_ACTIVITY_ID]: null,
  [componentKeys.INVOICE_SELECTED_ID]: null,
  [componentKeys.INVOICE_FETCHING_LOAD]: false,
  [componentKeys.IS_ROADMAP_BULK_DELETE_MODE]: false,
  [componentKeys.IS_HEADER_HIDDEN]: false,
  [componentKeys.SESSION_SCHEDULE_HAS_MORE]: false,
  [componentKeys.IS_MULTI_TENANT]: false,
  [componentKeys.MY_TAGS]: new Immutable.List(),
  [componentKeys.OTHER_SESSION_HAS_MORE]: false,
  [componentKeys.DAY_CALENDAR_VIEW]: false,
  [componentKeys.CACHED_EVENT_IDS]: new Immutable.List(),
});

export default createReducer(INITIAL_STATE, {
  [UPDATE_META]: (state, { payload: { component, meta } }) => {
    return state.set(component, Immutable.fromJS(meta));
  },
  // Accepts array of { component, meta }
  [BULK_UPDATE_META]: (state, { payload: updates }) => {
    return state.withMutations((state) => {
      updates.forEach((u) => state.set(u.component, Immutable.fromJS(u.meta)));
    });
  },
  [ADD_ENTITIES]: (state, action) => {
    return state.mergeDeep(action.payload.meta);
  },
  [TOGGLE_USER_SELECTED_IN_CALENDAR]: (state, action) => {
    const userId = action.payload.userId;
    const selectedUserIds = state.get(componentKeys.CALENDAR_SELECTED);

    const isBeingSelected = !selectedUserIds.contains(userId);
    const isMaximumSelected = selectedUserIds.size >= MAXIMUM_PEOPLE_SELECTED;
    // Prevent more users than maximum from being selected.
    if (isBeingSelected && isMaximumSelected) return state;
    const newSelectedUserIds = isBeingSelected
      ? selectedUserIds.push(userId)
      : selectedUserIds.filter((id) => id !== userId);
    return state.set(componentKeys.CALENDAR_SELECTED, newSelectedUserIds);
  },
  [CONTACTS_LOADING_SUCCEEDED]: (state, { payload }) => {
    const { filterText, contacts: newContacts, pageInfo } = payload;
    return state.withMutations((state) => {
      const contacts = state.getIn(
        [componentKeys.CONTACT_LIST_CACHE, filterText, 'contacts'],
        new Immutable.OrderedSet(),
      );

      state.setIn([componentKeys.CONTACT_LIST_CACHE, filterText, 'pageInfo'], Immutable.fromJS(pageInfo));

      state.setIn(
        [componentKeys.CONTACT_LIST_CACHE, filterText, 'contacts'],
        contacts.concat(newContacts.map(({ userId }) => userId)),
      );
    });
  },
  [LOCATION_CHANGE]: (state, { payload }) => {
    const { pathname } = payload;
    return state.set(componentKeys.ROUTE_PATHNAME, pathname);
  },
  [UPDATE_BOOKING_WITH_CACHE]: (state, { payload }) => {
    return state.withMutations((state) => {
      const { users, pagination } = payload;

      if (pagination.pageNumber === 1) {
        state.setIn([componentKeys.BOOKING_WITH_CACHE, 'users'], Immutable.fromJS(users.map((u) => u.userId)));
      } else {
        const cacheUsers = state.getIn([componentKeys.BOOKING_WITH_CACHE, 'users']).toJS();
        state.setIn(
          [componentKeys.BOOKING_WITH_CACHE, 'users'],
          Immutable.fromJS(cacheUsers.concat(users.map((u) => u.userId))),
        );
      }

      state.setIn(
        [componentKeys.BOOKING_WITH_CACHE, 'pagination'],
        Immutable.fromJS(Object.assign({}, pagination, { hasNextPage: users.length === pagination.pageSize })),
      );
    });
  },
  [UPDATE_BOOKING_FOR_CACHE]: (state, { payload }) => {
    return state.withMutations((state) => {
      const { users, pagination } = payload;

      if (pagination.pageNumber === 1) {
        state.setIn([componentKeys.BOOKING_FOR_CACHE, 'users'], Immutable.fromJS(users.map((u) => u.userId)));
      } else {
        const cacheUsers = state.getIn([componentKeys.BOOKING_FOR_CACHE, 'users']).toJS();
        state.setIn(
          [componentKeys.BOOKING_FOR_CACHE, 'users'],
          Immutable.fromJS(cacheUsers.concat(users.map((u) => u.userId))),
        );
      }

      state.setIn(
        [componentKeys.BOOKING_FOR_CACHE, 'pagination'],
        Immutable.fromJS(Object.assign({}, pagination, { hasNextPage: users.length === pagination.pageSize })),
      );
    });
  },
  [UPDATE_ASSIGNABLE_USERS_CACHE]: (state, { payload }) => {
    return state.withMutations((state) => {
      const { users, pagination } = payload;

      if (pagination.pageNumber === 1) {
        state.setIn([componentKeys.ASSIGNABLE_USERS_CACHE, 'users'], Immutable.fromJS(users.map((u) => u.userId)));
      } else {
        const cacheUsers = state.getIn([componentKeys.ASSIGNABLE_USERS_CACHE, 'users']).toJS();
        state.setIn(
          [componentKeys.ASSIGNABLE_USERS_CACHE, 'users'],
          Immutable.fromJS(cacheUsers.concat(users.map((u) => u.userId))),
        );
      }

      state.setIn(
        [componentKeys.ASSIGNABLE_USERS_CACHE, 'pagination'],
        Immutable.fromJS(Object.assign({}, pagination, { hasNextPage: users.length === pagination.pageSize })),
      );
    });
  },
  [UPDATE_REASONS_FOR_CANCELLATION]: (state, { payload }) => {
    const reasons = payload.reasons;
    return state.setIn([componentKeys.REASONS_FOR_CANCELLATION, reasons[0].type], reasons);
  },
  [UPDATE_REASONS_FOR_ABSENCE]: (state, { payload }) => {
    const reasons = payload.reasons;
    return state.setIn([componentKeys.REASONS_FOR_ABSENCE, reasons[0].type], reasons);
  },
  [TOGGLE_USER_UNSELECTED_IN_CALENDAR]: (state) => {
    return state.set(componentKeys.CALENDAR_SELECTED, new Immutable.List());
  },
});

/**
 * Payload for UPDATE_STATE action.
 * @typedef {Object} UpdateStatePayload
 * @property {string} component - component key
 * @property meta - meta value
 */

/**
 * Return object of action creators for action UPDATE_STATE.
 * @typedef {Object} UpdateStateAction
 * @property {string} type - action type
 * @property {UpdateStatePayload} payload - action payload
 */

/**
 * Update key-value meta store.
 * @param {string} component meta key
 * @param meta meta value
 * @return {UpdateStateAction} action definition
 */
export function updateMeta(component, meta) {
  return { type: UPDATE_META, payload: { component, meta } };
}

export function bulkUpdateMeta(updates) {
  return { type: BULK_UPDATE_META, payload: updates };
}

export function locationChange(pathname) {
  return { type: LOCATION_CHANGE, payload: { pathname } };
}

export function toggleUserSelectedInCalendar(userId) {
  return { type: TOGGLE_USER_SELECTED_IN_CALENDAR, payload: { userId } };
}

export function toggleUserUnSelectedInCalendar() {
  return { type: TOGGLE_USER_UNSELECTED_IN_CALENDAR };
}

export function updateBookingWithCache(users, pagination) {
  return { type: UPDATE_BOOKING_WITH_CACHE, payload: { users, pagination } };
}

export function updateBookingForCache(users, pagination) {
  return { type: UPDATE_BOOKING_FOR_CACHE, payload: { users, pagination } };
}

export function updateAssignableUsersCache(users, pagination) {
  return { type: UPDATE_ASSIGNABLE_USERS_CACHE, payload: { users, pagination } };
}

export function updateReasonsForCancellation(reasons) {
  return { type: UPDATE_REASONS_FOR_CANCELLATION, payload: { reasons } };
}

export function updateReasonsForAbsence(reasons) {
  return { type: UPDATE_REASONS_FOR_ABSENCE, payload: { reasons } };
}

/**
 * Set form state to loading.
 * @return {UpdateStateAction} action definition
 */
export function formLoading() {
  return updateMeta(componentKeys.FORM_STATE, formState.Loading);
}

/**
 * Set form state to default.
 * @return {UpdateStateAction} action definition
 */
export function formLoaded() {
  return updateMeta(componentKeys.FORM_STATE, formState.Default);
}

/**
 * Set form state to success.
 * @return {UpdateStateAction} action definition
 */
export function successForm() {
  return updateMeta(componentKeys.FORM_STATE, formState.Success);
}

/**
 * Set form state to failed.
 * @return {UpdateStateAction} action definition
 */
export function failForm() {
  return updateMeta(componentKeys.FORM_STATE, formState.Fail);
}

/**
 * Set modal state to successful.
 * @return {UpdateStateAction} action definition
 */
export function successModal() {
  return updateMeta(componentKeys.MODAL_STATE, modalState.Success);
}

/**
 * Set modal state to loading.
 * @return {UpdateStateAction} action definition
 */
export function modalLoading() {
  return updateMeta(componentKeys.MODAL_STATE, modalState.Loading);
}

/**
 * Set modal state to default.
 * @return {UpdateStateAction} action definition
 */
export function resetModal() {
  return updateMeta(componentKeys.MODAL_STATE, modalState.Default);
}

/**
 * Set modal state to failed.
 * @return {UpdateStateAction} action definition
 */
export function failModal() {
  return updateMeta(componentKeys.MODAL_STATE, modalState.Fail);
}

/**
 * Set error message of a modal error.
 * @param {string} message error message
 * @return {UpdateStateAction} action definition
 */
export function modalError(message) {
  return updateMeta(componentKeys.MODAL_ERROR, message);
}

/**
 * Clear error message of a modal error.
 * @return {UpdateStateAction} action definition
 */
export function clearBookingError() {
  return updateMeta(componentKeys.MODAL_ERROR, '');
}

/**
 * @param {string} filterText
 * @param {Object} response
 * @param {{ hasNextPage: boolean, endCursor: string }} response.pageInfo
 * @param {String[]} response.contacts
 */
export function contactsLoadingSucceeded(filterText, response) {
  return { type: CONTACTS_LOADING_SUCCEEDED, payload: { filterText, ...response } };
}

/**
 * Dispatch model failure.
 * @param {Error[]} errors array of errors
 * @return {Function} thunk.
 */
export function modalFail(errors) {
  return (dispatch) => {
    const errorMessages = errors.map((e) => {
      return e.message ? e.message : e;
    });

    const message = ['Booking unsuccessful'].concat(errorMessages).join('. ');
    dispatch(failModal());
    dispatch(modalError(message));
  };
}

/**
 * Dispatch form success.
 * @param {string} [message] success message.
 * @return {Function} thunk.
 */
export function formSuccess(message) {
  return (dispatch) => {
    toastr.success('', message || 'Action Successful!');
    dispatch(successForm());
    dispatch(formLoaded());
  };
}

/**
 * Dispatch form failure.
 * @param {string[]|string} [errors] error message/s.
 * @return {Function} thunk.
 */
export function formFail(errors) {
  return (dispatch) => {
    toastr.error('', Array.isArray(errors) ? errors.join(', ') : errors || 'Error occured, please try again later.');
    dispatch(failForm());
    dispatch(formLoaded());
  };
}

export function formInfo(message) {
  return (dispatch) => {
    toastr.info('', message);
    dispatch(formLoaded());
  };
}
/**
 * Dispatch model success.
 * @param {string} [message] success message.
 * @return {Function} thunk.
 */
export function modalSuccess(message) {
  return (dispatch) => {
    toastr.success('', message || 'Action Successful!');
    dispatch(successModal());
  };
}

/**
 * Dispatch fetch user profile.
 * @return {Function} thunk.
 * @todo refactor the thunk and dependent compoentns to not return
 * a promise/immutable.
 */
export function fetchUserProfile() {
  return async (dispatch) => {
    const res = await getProfile();
    dispatch(updateMeta(componentKeys.USER_PROFILE, res));
    return Immutable.fromJS(res);
  };
}

/**
 * Fetch all types of student interests.
 * @return {Function} thunk.
 */
export function fetchAllStudentInterests() {
  return async (dispatch) => {
    const { allStudentInterests } = await profileAPI.fetchAllStudentInterests();
    dispatch(updateMeta(componentKeys.STUDENT_INTERESTS, allStudentInterests));
  };
}

/**
 * Fetch all types of tutor expertise.
 * @return {Function} thunk.
 */
export function fetchAllTutorExpertise() {
  return async (dispatch) => {
    const { getAllTutorExpertise } = await profileAPI.fetchAllTutorExpertise();

    dispatch(updateMeta(componentKeys.TUTOR_EXPERTISE, getAllTutorExpertise));
  };
}

/**
 * Fetch unavailabilities for a user
 * @param {number} userId user id.
 * @return {Function} thunk.
 */
export function fetchUnavailableByUserId(userId) {
  return async (dispatch) => {
    const { unavailable } = await availabilityApi.fetchUnavailable({ userId });

    dispatch(updateMeta(componentKeys.UNAVAILABILITIES, unavailable || []));
  };
}

/**
 * Fetch unavailabilities for a user
 * @param {number} userId user id.
 * @param {Object[]} unavailableList list of unavailabilities.
 * @return {Function} thunk.
 * @todo The calling component depends on this to return a promise and resolves
 * it to get data. we need to refactor it.
 */
export function setUnavailableByUserId(userId, unavailableList) {
  return async (dispatch) => {
    const list = unavailableList.map(({ dayOfWeek, start, end, name, description }) => ({
      dayOfWeek,
      start,
      end,
      name,
      description,
    }));

    try {
      const res = await availabilityApi.setUnavailable({ userId, list });

      if (res.errors) {
        const errors = res.errors.map((e) => e.message);
        dispatch(formFail(errors));
        return;
      }

      dispatch(updateMeta(componentKeys.UNAVAILABILITIES, unavailableList));

      dispatch(formSuccess(toasterMessages.userAvailibilityUpdated()));
    } catch (err) {
      dispatch(formFail(toasterMessages.userAvailibilityNotUpdated()));
    }
  };
}

/**
 * Onboard user with given details.
 * @param {Object} user user details.
 * @param {string} user.firstName first name.
 * @param {string} user.lastName first name.
 * @param {string} user.email first name.
 * @param {string} user.notes first name.
 * @param {string[]} user.roleIds first name.
 * @return {Function} thunk.
 */
export function onboardUser({ firstName, lastName, email, notes, roleIds, primaryRole, isTest, userTags, isAllTags }) {
  return async (dispatch) => {
    dispatch(formLoading());

    try {
      const { fetchAuthUserByEmail: user } = await userAPI.fetchAuthUserByEmail(email);
      /** @type {Error[]} */
      let errors;
      let userId;

      if (user) {
        ({ errors, addUserToLms: userId } = await userInvitationAPI.addUserToLms(
          user.userId,
          firstName,
          lastName,
          email,
          user.phone || '',
          user.country || '',
          escapeString(notes),
          roleIds,
          userTags,
          isAllTags,
        ));
      } else {
        ({ errors, createNewUserInvitation: userId } = await userInvitationAPI.createNewUserInvitation(
          firstName,
          lastName,
          email,
          escapeString(notes),
          roleIds,
          isTest,
          userTags,
          isAllTags,
        ));
      }

      if (errors) {
        return dispatch(formFail(errors[0].message));
      }

      if (userId) {
        await authorizationAPI.setUserPrimaryRole(userId, primaryRole);
        dispatch(push(`/users/${userId}`));
      }

      return dispatch(formSuccess(toasterMessages.userOnboarded()));
    } catch (err) {
      return dispatch(formFail(formatGraphQLRequestError(err, toasterMessages.userNotOnboarded())));
    }
  };
}

/**
 * Send invite to a given user.
 * @param {Object} user user details.
 * @param {string} user.userId user id.
 * @return {Function} thunk.
 */
export function sendUserInvite({ userId }) {
  return async (dispatch) => {
    dispatch(formLoading());
    try {
      await userInvitationAPI.inviteNewUser(userId);
      return dispatch(formSuccess(toasterMessages.userInvitationSent()));
    } catch (err) {
      return dispatch(formFail(toasterMessages.userInvitationNotSent()));
    }
  };
}

/**
 * Refresh zendesk login url.
 * @return {Function} thunk.
 */
export function refreshZendeskLoginUrl() {
  return async (dispatch) => {
    try {
      // clean existing zendesk url
      dispatch(updateMeta(componentKeys.ZENDESK_LOGIN_URL, null));
      const { fetchZendeskLoginUrl: url } = await userAPI.fetchZendeskLoginUrl();
      dispatch(updateMeta(componentKeys.ZENDESK_LOGIN_URL, url));
    } catch (err) {
      dispatch(formFail(toasterMessages.zendeskLoginUrlNotFetched()));
    }
  };
}

export const showPageLoader = (thunk, dispatch) => (...args) => {
  // Generate a 'uid' for each thunk we want to listen to
  const thunkID = new Date().getTime();

  // Add thunk to list of thunks app is listening to
  dispatch(updateMeta(componentKeys.IS_PAGE_LOADING, true));
  dispatch((dispatch, getState) => {
    const thunksRemaining = getThunksRemaining(getState());
    dispatch(updateMeta(componentKeys.THUNKS_REMAINING, thunksRemaining.push(thunkID)));
  });

  // Called when thunk promise resolves
  const finishLoading = (id) => (result) => {
    dispatch((dispatch, getState) => {
      let thunksRemaining = getThunksRemaining(getState());

      // Stop listening to this thunk sinces its completed
      const idx = thunksRemaining.indexOf(id);
      if (idx !== -1) {
        thunksRemaining = thunksRemaining.splice(idx, 1);
      }
      dispatch(updateMeta(componentKeys.THUNKS_REMAINING, thunksRemaining));

      // Check if this was the last thunk and if it was stop show loader
      if (thunksRemaining.size <= 0) {
        dispatch(updateMeta(componentKeys.IS_PAGE_LOADING, false));
      }
    });
    return result;
  };

  return Promise.resolve(thunk(...args)).then(finishLoading(thunkID), finishLoading(thunkID));
};

export function showIncompleteReportBanner() {
  return updateMeta(componentKeys.SHOW_INCOMPLETE_REPORT_REMINDER, true);
}

export function hideIncompleteReportBanner() {
  return updateMeta(componentKeys.SHOW_INCOMPLETE_REPORT_REMINDER, false);
}

export function doIncompleteReportLater() {
  return (dispatch) => {
    dispatch(hideIncompleteReportBanner());
    const delayTime = 1000 * 60 * 60; // 1 hour
    setTimeout(() => dispatch(showIncompleteReportBanner()), delayTime);
  };
}

// Calendar
export function toggleCalendarView(currentMode) {
  const newMode = currentMode === DESKTOP_DAYS ? MOBILE_DAYS : DESKTOP_DAYS;
  return updateMeta(componentKeys.CALENDAR_DAYS, newMode);
}

/**
 * Set selected day on the calendar.
 * @param {string} day selected day.
 * @return {Function} thunk.
 */
export function updateGlobalSelectedDay(day) {
  return (dispatch, getState) => {
    // Update the starting day for the week.
    dispatch(updateMeta(componentKeys.SELECTED_DAY, day));

    // Asynchronously fetch bookings and availabilities for this week.
    const selectedPeople = selectedBookWith(getState())
      .map((person) => {
        return person.get('userId');
      })
      .toJS();

    dispatch(fetchPeopleAvailability(selectedPeople));
    dispatch(fetchBookingAsEvents());
  };
}

export function nextWeek() {
  return (dispatch, getState) => {
    const currentDate = getMetaItem(componentKeys.SELECTED_DAY)(getState());
    const canGoNext = moment(currentDate).isBefore(moment().add(CALENDAR_FUTURE_LIMIT, 'd'), 'd');

    if (!canGoNext) return;

    const calendarDays = getMetaItem(componentKeys.CALENDAR_DAYS)(getState());
    const nextWeek = moment(currentDate).add(calendarDays, 'days');
    dispatch(updateGlobalSelectedDay(nextWeek.toDate()));
  };
}

export function prevWeek() {
  return (dispatch, getState) => {
    const currentDate = getMetaItem(componentKeys.SELECTED_DAY)(getState());
    const calendarDays = getMetaItem(componentKeys.CALENDAR_DAYS)(getState());
    const prevWeek = moment(currentDate).subtract(calendarDays, 'days');

    dispatch(updateGlobalSelectedDay(prevWeek.toDate()));
  };
}

export function scrollToCurrentTime() {
  return (dispatch, getState) => {
    const currentCalendarScrollPos = getMetaItem(componentKeys.CALENDAR_SCROLL)(getState());
    const calendarScrollHeight = getMetaItem(componentKeys.CALENDAR_SCROLL_HEIGHT)(getState());
    const hoursSince = moment().hour();
    const heightDiff = Math.floor(calendarScrollHeight / 24) * hoursSince;

    heightDiff !== currentCalendarScrollPos
      ? dispatch(updateMeta(componentKeys.CALENDAR_SCROLL, heightDiff))
      : dispatch(updateMeta(componentKeys.CALENDAR_SCROLL, currentCalendarScrollPos + 1));
  };
}

export function goToToday() {
  return (dispatch, getState) => {
    const calendarDays = getMetaItem(componentKeys.CALENDAR_DAYS)(getState());

    const isExtraDaysNeeded = moment().diff(moment().startOf('week'), 'days') >= calendarDays;
    const today = isExtraDaysNeeded ? moment().startOf('week').add(calendarDays, 'days') : moment().startOf('week');
    dispatch(updateGlobalSelectedDay(today.toDate()));
    dispatch(scrollToCurrentTime());
  };
}

export function fetchReasonsForCancellation(userId, eventId) {
  return async (dispatch) => {
    const results = await bookingApi.fetchCancellationReasonOptions(userId, eventId);
    results && results.reasons && results.reasons.length && dispatch(updateReasonsForCancellation(results.reasons));
  };
}

export function fetchReasonsForAbsence(eventId) {
  return async (dispatch) => {
    const results = await bookingApi.fetchReasonsForAbsence(eventId);
    results && results.reasons && results.reasons.length && dispatch(updateReasonsForAbsence(results.reasons));
  };
}

export function updateSelectedEclActivity(id) {
  return updateMeta(componentKeys.ECL_SELECTED_ACTIVITY_ID, id);
}

export function updateSelectedApplication(id) {
  return updateMeta(componentKeys.APPLICATION_SELECTED_ID, id);
}

export function updateHeaderHidden(isHidden) {
  return updateMeta(componentKeys.IS_HEADER_HIDDEN, isHidden);
}

export function fetchSessionReportByEventId(eventId) {
  return async (dispatch) => {
    const { fetchSessionFeedbackReport } = await feedback.fetchSessionFeedbackReport(eventId.toString());
    dispatch(updateMeta(componentKeys.SESSION_FEEDBACK_REPORT, fetchSessionFeedbackReport));
  };
}

export function updateSelectedSpecificDay(day) {
  return (dispatch, getState) => {
    // Update the specific day.
    dispatch(updateMeta(componentKeys.SELECTED_SPECIFIC_DAY, day));

    // Asynchronously fetch bookings and availabilities for this week.
    const selectedPeople = selectedBookWith(getState())
      .map((person) => {
        return person.get('userId');
      })
      .toJS();

    dispatch(fetchPeopleAvailability(selectedPeople));
    dispatch(fetchBookingAsEvents());
  };
}
export function goToday() {
  return (dispatch) => {
    dispatch(updateSelectedSpecificDay(moment().startOf('day')));
  };
}

export function nextDay() {
  return (dispatch, getState) => {
    const currentDate = getMetaItem(componentKeys.SELECTED_SPECIFIC_DAY)(getState());
    const canGoNext = moment(currentDate).isBefore(moment().add(CALENDAR_FUTURE_LIMIT, 'd'), 'd');

    if (!canGoNext) return;

    const nextDay = moment(currentDate).add(1, 'days');
    dispatch(updateSelectedSpecificDay(nextDay));
  };
}

export function prevDay() {
  return (dispatch, getState) => {
    const currentDate = getMetaItem(componentKeys.SELECTED_SPECIFIC_DAY)(getState());
    const prevDay = moment(currentDate).subtract(1, 'days');
    dispatch(updateSelectedSpecificDay(prevDay));
  };
}

export function updateDayCalendarViewHidden(isHidden) {
  return (dispatch) => {
    dispatch(updateMeta(componentKeys.DAY_CALENDAR_VIEW, isHidden));
    dispatch(updateSelectedSpecificDay(moment().startOf('day')));
    dispatch(toggleUserUnSelectedInCalendar());
  };
}

export function fetchViewableRoles() {
  return async (dispatch) => {
    const { actionableRoles } = await authorizationAPI.actionableRoles(PermissionAction.View);
    dispatch(updateMeta(componentKeys.VIEWABLE_ROLES, Immutable.fromJS(actionableRoles)));
  };
}

export function updateSelectedEclAward(id) {
  return updateMeta(componentKeys.ECL_SELECTED_AWARD_ID, id);
}

export function updateSelectedAcademic(id) {
  return updateMeta(componentKeys.GRADE_SELECTED_ACADEMIC_ID, id);
}

export function updateCurrentThreadId(id) {
  return updateMeta(componentKeys.CURRENT_THREAD_ID, id);
}
