import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from 'components/unique/ProfilePage/profileItem.scss';
import ComponentSwitcher from 'components/generic/Forms/ComponentSwitcher';
import Timezone from './Timezone';
import TimezoneForm from './TimezoneForm';

export default class TimezoneSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editing: false,
      isLoading: false,
    };

    this.renderView = this.renderView.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.updateEditingStatus = this.updateEditingStatus.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  onSave(details) {
    const { onSaveDetails } = this.props;
    this.setState(
      {
        isLoading: true,
      },
      async () => {
        await onSaveDetails(details);
        this.setState({
          editing: false,
          isLoading: false,
        });
      },
    );
  }

  updateEditingStatus() {
    const { editing } = this.state;
    this.setState({
      editing: !editing,
    });
  }

  renderView() {
    const { timezone, autoTimezone } = this.props;
    return <Timezone timezone={timezone} autoTimezone={autoTimezone} />;
  }

  renderForm() {
    const { timezone, autoTimezone, timezones } = this.props;
    const { isLoading } = this.state;
    return (
      <TimezoneForm
        timezone={timezone}
        autoTimezone={autoTimezone}
        timezones={timezones}
        onSaveDetails={this.onSave}
        onCancel={this.updateEditingStatus}
        isLoading={isLoading}
      />
    );
  }

  render() {
    const { canEdit } = this.props;
    const { editing } = this.state;
    return (
      <div className={classNames(css.profileItem, css.rightItem)}>
        <div className={css.itemTitle}>
          <i className={classNames('zmdi zmdi-time', css.itemIcon)} />
          <div className={css.itemName}>Timezone</div>
        </div>
        <ComponentSwitcher
          className={classNames(css.itemSwitcher, { [css.itemSwitcherEditing]: editing })}
          primary={this.renderView()}
          alternate={this.renderForm()}
          toggleButtonShowOnHover
          toggleButtonClassName={css.toggleButton}
          canEdit={canEdit}
          signalOnEdit={this.updateEditingStatus}
          isAlternateVisible={editing}
          hideToggleButtonInEditMode
        />
      </div>
    );
  }
}

TimezoneSection.displayName = 'TimezoneSection';

TimezoneSection.propTypes = {
  timezone: PropTypes.string,
  autoTimezone: PropTypes.bool,
  timezones: PropTypes.array.isRequired,
  onSaveDetails: PropTypes.func.isRequired,
  canEdit: PropTypes.bool,
};
